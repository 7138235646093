import React, { useEffect, useState, useRef } from "react";
import PhotoIcon from "../../assets/images/hba-photo-icon.svg";

import UploadIcon from "../../assets/images/hba-upload-icon.svg";
import DatePicker from "react-datepicker";
import Button from "../../shared/Button";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/images/hba-calendar-icon.svg";

import momentTZ from "moment-timezone";
import {
  timezoneFormatter,
  datePickerDateFormatter,
} from "../../shared/userPreferredDateTime";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import uploadIcon from "../../assets/images/upload-icon.png";
import progressPhotoIcon from "../../assets/images/progressPhoto-icon.png";
import { IoCloseOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { HiOutlinePlusSm } from "react-icons/hi";
import TimePicker from "react-time-picker";
import { MdClose } from "react-icons/md";
import DeleteMatterModal from "../dashboard/delete-matters-modal";

const ProgressPhotosModal = ({
  setShowRightPanel,
  matterId,
  uploadImage,
  setUploadImage,
  imageURL,
  setImageURL,
  handleDragOver,
  handleDrop,
  handleUpload,
  datePicked,
  setDatePicked,
  setDescription,
  description,
  saveButtonClicked,
  selectedFiles,
}) => {
  const { height, width } = useWindowDimensions();
  const hiddenFileInput = useRef(null);

  function handleClick(event) {
    hiddenFileInput.current.click();
  }

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setDescription(value);
    console.log("VALUE ->", value);
  };

  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const handleModalClose = () => {
    setshowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    handleModalClose();
    setImageURL(null);
    setUploadImage([]);
    selectedFiles.current = [];
  };

  return (
    <div className="outline-none focus:outline-none relative w-full h-full rounded-sm border-0 bg-white shadow-lg flex flex-col justify-between content-between	overflow-y-auto">
      {!isMobileDevice(width) ? (
        <div className="p-3 z-10">
          <div className="px-2 w-full relative">
            <img src={PhotoIcon} className="mb-5" alt="icon" />
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 flex">
            <h3 className="pb-2 text-lg font-semibold text-gray-900">
              Property Photo
            </h3>
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 flex flex-col mb-2">
            <div className="flex">
              <div className="pb-2  text-gray-900">Property Image</div>
            </div>
            {imageURL === null ? (
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="justify-center items-center h-36 mx-auto border w-full px-3 rounded-md border-gray-400"
              >
                <img src={UploadIcon} className="my-3 mx-auto" alt="icon" />
                <div className="text-center w-full mb-3">
                  <div className="mx-auto text-sm text-center  text-hbBlue">
                    <button
                      className="cursor-pointer hover:underline font-semibold"
                      onClick={handleClick}
                    >
                      {" "}
                      Click to Upload{" "}
                    </button>
                    <span className="text-gray-400 font-normal mx-auto">
                      {" "}
                      or drag & drop
                    </span>
                  </div>

                  <p className="text-gray-400 font-normal mx-auto">
                    SVG, PNG, JPG or GIF &#40;max: 800x400px&#41;
                  </p>
                </div>
                {/* <input type="file" value=""/> */}
              </div>
            ) : (
              <>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className="justify-center items-center h-36 mx-auto border w-full border-dashed rounded-md border-gray-400"
                >
                  <button
                    className="p-1 rounded-full absolute right-5 z-30 bg-danger transition-opacity"
                    onClick={() => {
                      // setLogoURL(null);
                      // setBuilderLogo(null);
                      // setLogo(null);
                      setshowDeleteModal(true);
                    }}
                  >
                    <MdClose className="text-white" />
                  </button>
                  <img
                    src={imageURL}
                    className="rounded-md p-1 w-full h-full object-cover"
                    alt="upload"
                  />
                </div>
              </>
            )}
          </div>
          <input
            onChange={(e) => {
              setUploadImage(e.target.files);
              setImageURL(URL.createObjectURL(e.target.files[0]));
            }}
            ref={hiddenFileInput}
            type="file"
            multiple={false}
            hidden
          />
          <div className=" items-start justify-between w-full py-2 px-2 mb-2">
            <p className="pb-2  text-gray-900">Description</p>
            <div className="justify-center items-center mx-auto">
              <textarea
                value={description}
                className="p-3 h-64 border rounded-md border-gray-400 w-full"
                style={{ resize: "none" }}
                onChange={handleDescriptionChange}
              ></textarea>
            </div>
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 mb-2">
            <p className="pb-2  text-gray-900">Date of Photo Taken</p>
            <div className="justify-center items-center mx-auto"></div>

            <div className="justify-start items-center mx-auto custom-datepicker-container border rounded-md border-gray-400 mb-5 z-50 flex ">
              <div>
                <img
                  src={CalendarIcon}
                  className="ml-2 inline-block"
                  alt="calendar"
                />
              </div>
              {/* <DatePicker
                utcOffset={0}
                style={{
                  outline: "0px",
                  border: "0px",
                }}
                className={"m-2 text-sm focus:border-white w-3/4"}
                selected={
                  //datePicked !== null : null
                  datePicked !== null
                    ? timezoneFormatter(
                        datePicked,
                        (localStorage.getItem("preferredTimezone") == "null" ? datePicked : localStorage.getItem("preferredTimezone"))
                      )
                    : null
                }
                onChange={(selected) => {setDatePicked(selected); console.log(datePicked)}}
                placeholderText="Select Date"
              /> */}
              <DatePicker
                popperProps={{
                  positionFixed: true,
                }}
                className="p-2 w-full border-2 text h-7 rounded-lg text-black border-gray-100"
                dateFormat={datePickerDateFormatter(
                  localStorage.getItem("userpreferredDateFormatType") === null
                    ? "MM-DD-YYYY"
                    : localStorage.getItem("userpreferredDateFormatType")
                )}
                // selected={
                //   datePicked && timezoneFormatter(
                //     datePicked,
                //         localStorage.getItem("preferredTimezone")
                //       )
                // }
                selected={
                  datePicked === null
                    ? new Date()
                    : timezoneFormatter(
                        datePicked,
                        localStorage.getItem("preferredTimezone")
                      )
                }
                placeholderText={"From"}
                onChange={(selected) => {
                  setDatePicked(selected);
                  console.log(datePicked);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        //progress photo mobile version
        <div className="z-10 p-3 pt-4">
          <div className="flex justify-between p-3 pt-4">
            <div className="flex items-center w-full pl-2">
              <img
                src={progressPhotoIcon}
                alt="Progress Photos Icon"
                className="pb-2"
              />
              <h3 className="pb-2 text-lg font-semibold text-gray-900 pl-2">
                Progress Photos
              </h3>
            </div>
            <IoCloseOutline
              className="text-2xl text-gray-500"
              onClick={() => setShowRightPanel(false)}
            />
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 flex flex-col">
            <p className="pb-1 font-open-sans font-normal text-gray-900">
              Property Image
            </p>
            {imageURL === null ? (
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="flex justify-center flex-col items-center h-32 mx-auto border w-full px-3 rounded-xl border-gray-300 content-center"
              >
                <img src={uploadIcon} className="mb-2" alt="icon" />
                <div className="flex flex-col justify-center text-center items-center">
                  <p className="text-sm text-center font-open-sans">
                    <span
                      className="text-hbBlue font-semibold cursor-pointer hover:underline font-open-sans"
                      onClick={handleClick}
                    >
                      Click to upload
                    </span>{" "}
                    or drag and drop
                  </p>
                  <p className="text-gray-400 font-normal font-open-sans mx-auto">
                    SVG, PNG, JPG or GIF &#40;max: 160x160px&#41;
                  </p>
                </div>
                <input
                  onChange={(e) => {
                    setUploadImage(e.target.files);
                    setImageURL(URL.createObjectURL(e.target.files[0]));
                    console.log("will upload", uploadImage);
                  }}
                  ref={hiddenFileInput}
                  type="file"
                  multiple={false}
                  hidden
                />
              </div>
            ) : (
              <>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className="justify-center items-center h-32 mx-auto border w-full border-dashed rounded-md border-gray-400"
                >
                  <button
                    className="p-1 rounded-full absolute right-5 z-30 bg-danger transition-opacity"
                    onClick={() => {
                      // setLogoURL(null);
                      // setBuilderLogo(null);
                      // setLogo(null);
                      setshowDeleteModal(true);
                    }}
                  >
                    <MdClose className="text-white" />
                  </button>

                  <img
                    src={imageURL}
                    className="rounded-md px-1 w-full h-full object-cover"
                    alt="upload"
                  />
                </div>
              </>
            )}
          </div>
          <div className=" items-start justify-between w-full py-2 px-2">
            <p className="pb-1 font-open-sans font-normal text-gray-900">
              Description
            </p>
            <div className="justify-center items-center mx-auto">
              <textarea
                value={description}
                className="py-2 px-3 h-16 border rounded-xl border-gray-300 w-full font-open-sans"
                style={{ resize: "none" }}
                onChange={handleDescriptionChange}
                placeholder="Say something about this..."
              ></textarea>
            </div>
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 mb-2">
            <p className="pb-1 font-open-sans font-normal text-gray-900">
              Date of Photo Taken
            </p>
            <div className="flex flex-row justify-between items-center border w-full rounded-lg py-2 px-3 border-gray-300">
              {/* <DatePicker
                  popperProps={{
                      positionFixed: true,
                  }}
                  className="w-full font-open-sans"
                  dateFormat={datePickerDateFormatter(
                      localStorage.getItem("userpreferredDateFormatType") == "null" ? "MM-DD-YYYY" : localStorage.getItem("userpreferredDateFormatType")
                  )}
                  selected={
                    //datePicked !== null : null
                    datePicked !== null
                      ? timezoneFormatter(
                          datePicked,
                          (localStorage.getItem("preferredTimezone") == "null" ? moment(props.input.value, 'DD-MM-YYYY') : localStorage.getItem("preferredTimezone"))
                        )
                      : null
                  }
                  onChange={(selected) => {setDatePicked(selected); console.log(datePicked)}}
                />
                <FaChevronDown className="text-gray-500 text-sm z-100 float-right" /> */}

              <DatePicker
                popperProps={{
                  positionFixed: true,
                }}
                className="w-full font-open-sans"
                dateFormat={datePickerDateFormatter(
                  localStorage.getItem("userpreferredDateFormatType") === null
                    ? "MM-DD-YYYY"
                    : localStorage.getItem("userpreferredDateFormatType")
                )}
                // selected={
                //   datePicked && timezoneFormatter(
                //     datePicked,
                //         localStorage.getItem("preferredTimezone")
                //       )
                // }
                selected={
                  datePicked === null
                    ? new Date()
                    : timezoneFormatter(
                        datePicked,
                        localStorage.getItem("preferredTimezone")
                      )
                }
                placeholderText={"From"}
                onChange={(selected) => {
                  setDatePicked(selected);
                  console.log(datePicked);
                }}
              />
            </div>
          </div>
          <div className=" items-start justify-between w-full py-2 px-2 mb-4">
            <p className="pb-1 font-open-sans font-normal text-gray-900">
              Time
            </p>
            <div className="flex flex-row justify-between items-center border w-full rounded-lg py-2 px-3 border-gray-300">
              <TimePicker
                className="w-full font-open-sans h-5 text-gray-500"
                value={
                  datePicked !== null
                    ? timezoneFormatter(
                        datePicked,
                        localStorage.getItem("preferredTimezone") == "null"
                          ? momentTZ.tz.guess()
                          : localStorage.getItem("preferredTimezone")
                      )
                    : new Date()
                }
                onChange={(e) => {
                  const [hour, minute] = e.split(":").map(Number);
                  let temp = datePicked === null ? new Date() : datePicked;
                  temp.setHours(hour);
                  temp.setMinutes(minute);
                  setDatePicked(temp);
                  console.log(datePicked);
                }}
                clearIcon
                disableClock
                format="h:mm a"
              />
              {/* <div className="z-50 float-right flex flex-row items-center">
                  <span>AM</span>
                  <FaChevronDown className="text-gray-500 text-sm pl-2" />
                </div> */}
            </div>
          </div>
          {/* <div className="flex flex-row w-full items-center px-2">
            <hr className="w-2/5"></hr>
            <div className="w-4/12 flex flex-row items-center justify-center">
              <span className="text-xs font-open-sans font-semibold pr-2">Add more</span>
              <HiOutlinePlusSm />
            </div>
            <hr className="w-2/5"></hr>
          </div> */}
        </div>
      )}

      <div className={`p-4 gap-x-2 flex border-t `}>
        <Button
          className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
          block={"full"}
          onClick={() => {
            setShowRightPanel(false);
            setImageURL(null);
            setUploadImage([]);
            setDatePicked(new Date());
            setDescription("");
            selectedFiles.current = [];
          }}
        >
          <span className="h-fit w-fit font-semibold"> Cancel </span>
        </Button>

        <Button
          id="saveButton"
          variant="hbBlue"
          className="py-2 cursor-pointer shadow-none"
          block={"full"}
          onClick={(e) => {
            handleUpload(matterId);
            //setProgressPhotosDone(true);
            //console.log("PROGRESS PHOTOS DONE")
          }}
          disabled={!imageURL || saveButtonClicked || description === ""}
        >
          <span className="h-fit w-fit font-semibold"> Save </span>
        </Button>
      </div>

      {showDeleteModal && (
        <DeleteMatterModal
          handleSave={handleDeleteModal}
          handleModalClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default ProgressPhotosModal;
