import React, { lazy, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { FiInbox, FiArchive } from "react-icons/fi";
import { TfiWrite } from "react-icons/tfi";
import { CiInboxOut } from "react-icons/ci";
import { RiMailCheckLine } from "react-icons/ri";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";

const TabsRender = ({
  setIsModal,
  setOpenTab,
  setEmailContentOpen,
  openTab,
  savedEmails,
  unSavedEmails,
  archivedEmails,
  unSavedEmailsCount,
  savedEmailsCount,
  archivedEmailsCount,
  selectedUnsavedItemsCtr,
  selectedSavedItemsCtr,
  selectedArchivedItemsCtr,
  unSavedForTaskListCtr,
  savedForTaskListCtr,
  toggleSent,
  settoggleSent,
  showDetailsCopy,
  setShowDetailsCopy,
  setIsReply,
  setThreadId,
  searchGmail
}) => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      <div
        className={
          width > 844 ? "pl-1 pr-3 z-20 w-full" : "w-full pl-1 pr-1 pt-1  mt-1"
        }
        style={width > 844 ? { top: "73px" } : {}}
      >
        <div className="z-30 flex w-full">
          <ul className="flex w-full justify-between" role="tablist">
            {/* unsaved tab */}
            <li
              className={
                "flex gap-x-2 items-center py-2  cursor-pointer " +
                (openTab === 1 ? "bg-white rounded-t-xl clip_path " : "") +
                (width > 844 ? "mx-3" : "px-3")
              }
              style={{
                borderBottom:
                  openTab === 1 ? "2px solid black" : "2px solid transparent",
                color: openTab === 1 ? "black" : "#6b7280",
              }}
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
                setIsReply(false);
                setThreadId("");
                if (width > 844) setEmailContentOpen(false);
                // handleSpanClick(e, "inbox");
              }}
            >
              <span
                className={"inline-flex text-lg items-center gap-1"}
                data-toggle="tab"
                href="#unsaved"
                role="tablist"
              >
                {" "}
                {width > 844 ? (
                  <p className="inline-flex items-center gap-1 text-md border-none">
                    <FiInbox />
                    Unsaved
                  </p>
                ) : (
                  <FiInbox />
                )}
                <span
                  className={
                    width > 844
                      ? `inline-block truncate py-1 px-2 ml-1 text-xs text-center rounded-full ${
                          (searchGmail && searchGmail.trim() !== "") && unSavedEmailsCount !== 0
                            ? "bg-green-400 text-white font-medium"
                            : "bg-gray-100"
                        } [here]`
                      : `inline-block sm:w-12 truncate px-1 py-0 ml-1 text-xs text-center border-2 rounded-full ${
                          (searchGmail && searchGmail.trim() !== "") && unSavedEmailsCount !== 0
                            ? "bg-green-400 text-white font-medium"
                            : "bg-gray-50"
                        }`
                  }
                >
                  {unSavedEmailsCount}
                  
                </span>

              </span>
            </li>

            {/* saved tab */}
            <li
              className={
                "flex gap-x-2  items-center py-2 px-3 cursor-pointer " +
                (openTab === 2
                  ? "bg-white rounded-t-xl clip_path border-b-2 border-solid"
                  : "")
              }
              style={{
                borderBottom:
                  openTab === 2 ? "2px solid black" : "2px solid transparent",
                color: openTab === 2 ? "black" : "#6b7280",
              }}
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
                setIsReply(false);
                setThreadId("");
                if (width > 844) setEmailContentOpen(false);
                // handleSpanClick(e, "saved");
                setShowDetailsCopy(false);
              }}
            >
              <span
                className={"inline-flex text-lg items-center gap-1"}
                data-toggle="tab"
                href="#saved"
                role="tablist"
              >
                {" "}
                {width > 844 ? (
                  <p className="inline-flex items-center gap-1 text-md">
                    <RiMailCheckLine />
                    Saved
                  </p>
                ) : (
                  <RiMailCheckLine />
                )}
                <span
                  className={
                    width > 844
                      ? `inline-block truncate py-1 px-2 ml-1 text-xs text-center rounded-full ${
                          (searchGmail && searchGmail.trim() !== "") && savedEmailsCount !== 0
                            ? "bg-green-400 text-white font-medium"
                            : "bg-gray-100"
                        } [here]`
                      : `inline-block sm:w-12 truncate px-1 py-0 ml-1 text-xs text-center border-2 rounded-full ${
                          (searchGmail && searchGmail.trim() !== "") && savedEmailsCount !== 0
                            ? "bg-green-400 text-white font-medium"
                            : "bg-gray-50"
                        }`
                  }
                >
                  {savedEmailsCount}
                </span>
              </span>
            </li>

            {/* archive tab */}
            <li
              className={
                "flex gap-x-2  items-center py-2 px-3 cursor-pointer " +
                (openTab === 3 ? "bg-white rounded-t-xl clip_path" : "")
              }
              style={{
                borderBottom:
                  openTab === 3 ? "2px solid black" : "2px solid transparent",
                color: openTab === 3 ? "black" : "#6b7280",
              }}
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
                setIsReply(false);
                setThreadId("");
                if (width > 844) setEmailContentOpen(false);
                // handleSpanClick(e, "archive");
                setShowDetailsCopy(false);
              }}
            >
              <span
                className={"inline-flex text-lg items-center gap-1"}
                data-toggle="tab"
                href="#archive"
                role="tablist"
              >
                {" "}
                {width > 844 ? (
                  <p className={"inline-flex text-md items-center gap-1"}>
                    <FiArchive />
                    Archive
                  </p>
                ) : (
                  <FiArchive />
                )}
                <span
                  className={
                    width > 844
                      ? `inline-block truncate py-1 px-2 ml-1 text-xs text-center rounded-full ${
                          (searchGmail && searchGmail.trim() !== "") && archivedEmailsCount !== 0
                            ? "bg-green-400 text-white font-medium"
                            : "bg-gray-100"
                        } [here]`
                      : `inline-block sm:w-12 truncate px-1 py-0 ml-1 text-xs text-center border-2 rounded-full ${
                          (searchGmail && searchGmail.trim() !== "") && archivedEmailsCount !== 0
                            ? "bg-green-400 text-white font-medium"
                            : "bg-gray-50"
                        }`
                  }
                >
                  {archivedEmailsCount}
                </span>
              </span>
            </li>

            {width < 844 && (
              <li className="flex items-center justify-end w-full gap-x-1">
                {(selectedUnsavedItemsCtr.length !== 0 ||
                  unSavedForTaskListCtr !== 0) &&
                  openTab === 1 && (
                    <>
                      <span
                        className="inline-block px-1 py-0 text-xs text-center text-blue-500 border-2 border-blue-200 rounded-full bg-blue-50"
                        onClick={() => {
                          ReactTooltip.show();
                        }}
                        data-tip={`${selectedUnsavedItemsCtr} total selected emails.`}
                      >
                        {selectedUnsavedItemsCtr}
                      </span>

                      <span
                        className="inline-block px-1 py-0 text-xs text-center text-green-500 border-2 border-green-200 rounded-full bg-green-50"
                        onClick={() => {
                          ReactTooltip.show();
                        }}
                        data-tip={`${unSavedForTaskListCtr} selected for Task List.`}
                      >
                        {unSavedForTaskListCtr}
                      </span>
                      <ReactTooltip
                        globalEventOff="scroll"
                        scrollHide
                        type="dark"
                        place="bottom"
                        effect="solid"
                      />
                    </>
                  )}

                {(selectedSavedItemsCtr.length !== 0 ||
                  savedForTaskListCtr !== 0) &&
                  openTab === 2 && (
                    <>
                      <span
                        className="inline-block px-1 py-0 text-xs text-center text-blue-500 border-2 border-blue-200 rounded-full bg-blue-50"
                        onClick={() => {
                          ReactTooltip.show();
                        }}
                        data-tip={`${selectedSavedItemsCtr} total selected emails.`}
                      >
                        {selectedSavedItemsCtr}
                      </span>

                      {/* <span 
                          className="w-5 px-1 py-0 text-xs text-center text-green-500 border-2 border-green-200 rounded-full bg-green-50"
                          onClick={() => {
                            ReactTooltip.show();
                          }}
                          data-tip={`${savedForTaskListCtr} selected for Task List.`}
                          >
                          {savedForTaskListCtr}
                        </span> */}
                      <ReactTooltip
                        globalEventOff="scroll"
                        scrollHide
                        type="dark"
                        place="bottom"
                        effect="solid"
                      />
                    </>
                  )}

                {selectedArchivedItemsCtr.length !== 0 && openTab === 3 && (
                  <>
                    <span
                      className="inline-block px-1 py-0 text-xs text-center text-blue-500 border-2 border-blue-200 rounded-full bg-blue-50"
                      onClick={() => {
                        ReactTooltip.show();
                      }}
                      data-tip={`${selectedArchivedItemsCtr} total selected emails.`}
                    >
                      {selectedArchivedItemsCtr}
                    </span>
                    <ReactTooltip
                      globalEventOff="scroll"
                      scrollHide
                      type="dark"
                      place="bottom"
                      effect="solid"
                    />
                  </>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TabsRender;
