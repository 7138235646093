import {
  MATTER_REQUEST,
  MATTER_SUCCESS,
  MATTER_ERROR,
  SEARCH_MATTER_REQUEST,
  SEARCH_MATTER_SUCCESS,
  SEARCH_MATTER_ERROR,
  CREATE_MATTER_REQUEST,
  CREATE_MATTER_SUCCESS,
  CREATE_MATTER_ERROR,
  DELETE_MATTER_REQUEST,
  DELETE_MATTER_SUCCESS,
  DELETE_MATTER_ERROR,
  CREATE_MATTER_DUPLICATE, //mma1010
} from "./constants";
import { API } from "aws-amplify";
import { getBgList } from "../../shared/graphql/Briefs/queries";
import {
  setDefaultColumnBriefs,
  createContractBackground,
  createDefaultBriefs,
} from "../../shared/HBA-Functions.jsx";

var moment = require("moment");

const qUserClientMatterAccess = `query getUserClientMatters($id: String, $companyId: String) {
  user(id: $id) {
    clientMatterAccess(companyId: $companyId) {
      items {
        clientMatter {
          id
        }
      }
    }
  }
}`;

export const listClientMatters = `
query listClientMatters($companyId: String, $limit: Int, $nextToken: String, $search: String) {
  company(id: $companyId) {
    clientMatters (
      limit: $limit, 
      nextToken: $nextToken,
      search: $search
    ) {
      items {
        id
        createdAt
        updatedAt
        lastOpenedAt
        description
        matterNumber
        client {
          id
          name
        }
        matter {
          id
          name
        }
      }
      nextToken
    }
  }
}
`;

const createClientMatter = `
  mutation createClientMatter($companyId: String, $userId: ID, $client: ClientInput, $matter:MatterInput) {
    clientMatterCreate(companyId: $companyId, userId:$userId, client: $client, matter:$matter) {
      id
    }
}
`;

const UPDATE_BRIEF_PRESET_MUTATION = `
  mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    briefUpdate(id: $id, columnIds: $columnIds){
      id
  }
}
`;

const UPDATE_BACKGROUND_TABLE_MUTATION = `
	mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
	  backgroundTableUpdate(id: $id, input: $input) {
		id
		columns {
		  id
		  accessorKey
		  headerText
		  type
		  enabled
		  optionsText
		  order
		  presets {
			id
			name
		  }
		}
  }
}
`;

const BACKGROUND_TABLE_QUERY = `
  query getBackgroundTable($clientMatterId: ID!) {
    backgroundTable(clientMatterId: $clientMatterId) {
      id
      columns {
      id
      accessorKey
      headerText
      enabled
      type
      optionsText
      order
      presets {
        id
        name
      }
      }
      createdAt
      updatedAt
    }
  }`;

export const BRIEFS_QUERY = `
  query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
      briefs{
          items {
              id
              name
              userTypeAccess
              date
              order
              createdAt
              createdBy {
                  id
                  firstName
                  lastName
              }
              updatedAt
              updatedBy {
                  id
                  firstName
                  lastName
              }
              backgrounds(limit: 50, sortOrder: ORDER_ASC) {
                  items {
                      id
                      description
                      date
                      dynamicData
                      files{
                        items{
                          id
                          name
                        }
                      }
                  }
              }
              hasCommentAccess
              columnIds
              assistantId
          }
      }
  }
  }
  `;

const deleteClientMatter = `
  mutation deleteClientMatter($id: ID, $isDeleted: Boolean) {
    clientMatterSoftDeleteOrRestore(id: $id, isDeleted: $isDeleted)
  }
`;

const mCreateBrief = `
  mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
      order
      columnIds
    }
  }
  `;

const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

const BACKGROUND_TABLE_CREATE = `
  mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
    backgroundTableCreate(
      clientMatterId: $clientMatterId
      initialPresets: $initialPresets
    ) {
      id
      columns {
        id
        accessorKey
        headerText
        enabled
        type
        optionsText
        order
      }
      createdAt
      updatedAt
    }
  }
  `;

const mAddUserAcces = `
  mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
    briefAccessTypeTag(briefId:$briefId, userType:$userType) {
      id
    }
  }
  `;

//fetch user client matter access
const getUserClientMatterAccess = async () => {
  const userClientMatterAccess = await API.graphql({
    query: qUserClientMatterAccess,
    variables: {
      id: localStorage.getItem("userId"),
      companyId: localStorage.getItem("companyId"),
    },
  });

  console.log("USER DATA IS:", userClientMatterAccess.data);

  const clientMatterIds =
    userClientMatterAccess.data.user.clientMatterAccess.items.map(
      (i) => i.clientMatter.id
    );

  return clientMatterIds;
};

export const filterMatterList = async (result) => {
  try {
    const userClientMatterAccess = await getUserClientMatterAccess();
    result = userClientMatterAccess.reduce((filtered, id) => {
      const filterLabel = result.find((item) => item.id === id);
      if (filterLabel) {
        filtered.push(filterLabel);
      }

      let outputArray = Array.from(new Set(filtered))
      return outputArray;
    }, []);
  } catch (e) {
    console.error(e.errors[0].message);
  }

  const dummyPersonResponsible = {
    id: localStorage.getItem("userId"),
    name:
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName"),
    email: localStorage.getItem("email"),
    profile_picture:
      "https://as1.ftcdn.net/v2/jpg/03/64/62/36/1000_F_364623643_58jOINqUIeYmkrH7go1smPaiYujiyqit.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
  };

  var filtered = result?.filter(function (el) {
    return el.client != null;
  });

  var apdPr = filtered.map((v) => ({
    ...v,
    substantially_responsible: dummyPersonResponsible,
  }));
  var apdMn = apdPr.map((v) => ({
    ...v,
    matter_number: `{${v.matter?.name?.charAt(2)}-${v.matter?.id?.slice(
      -4
    )}/${v.client?.id?.slice(-4)}}`,
  }));

  return apdMn;
};

// export const getMatterList = async (dispatch, companyId) => {
//   try {
//     dispatch({
//       type: MATTER_REQUEST,
//       payload: { loading: true },
//     });

//     const clientMattersOpt = await API.graphql({
//       query: listClientMatters,
//       variables: {
//         companyId: companyId,
//         limit: 5
//       },
//     });

//     let result = clientMattersOpt.data.company.clientMatters.items;
//     let mostRecentBrief = null;

//     // Fetch briefs for each client matter and merge them into result
//     await appendBriefsToClientMatter(result, mostRecentBrief);

//     const apdMn = await filterMatterList(result);

//     dispatch({
//       type: MATTER_SUCCESS,
//       payload: { matterlist: apdMn || [], mostRecentBrief: mostRecentBrief },
//     });
//   } catch (error) {
//     dispatch({
//       type: MATTER_ERROR,
//       payload: error,
//     });
//   }
// };

export const getMatterList = async (dispatch, companyId, nextToken) => {
  try {
    dispatch({
      type: MATTER_REQUEST,
      payload: { loading: true },
    });

    console.log("DISPLAY NEXTTOKEN", nextToken);

    const clientMattersOpt = await API.graphql({
      query: listClientMatters,
      variables: {
        companyId: companyId,
        //limit: 16,
        nextToken: nextToken ? nextToken : null // Pass nextToken if it's available
      },
    });

    let result = clientMattersOpt.data.company.clientMatters.items;
    let mostRecentBrief = null;

    // Fetch briefs for each client matter and merge them into result
    // await appendBriefsToClientMatter(result, mostRecentBrief);

    const hasNextPage = clientMattersOpt.data.company.clientMatters.nextToken !== null;

    console.log("hasNextPage", hasNextPage)

    // if (hasNextPage) {
    //   // Recursively fetch the next page of items
    //   const nextResult = await getMatterList(dispatch, companyId, clientMattersOpt.data.company.clientMatters.nextToken);
    //   result = [...result, ...nextResult];
    // }

    const apdMn = await filterMatterList(result);

    console.log("apdMn", result)

    dispatch({
      type: MATTER_SUCCESS,
      payload: { matterlist: apdMn || [], mostRecentBrief: mostRecentBrief, nextToken: clientMattersOpt.data.company.clientMatters.nextToken },
    });

    return result;
  } catch (error) {
    dispatch({
      type: MATTER_ERROR,
      payload: error,
    });
  }
};



async function appendBriefsToClientMatter(result, mostRecentBrief) {
  // Fetch all briefs concurrently
  const briefsPromises = result.map(async (item) => {
    try {
      const briefs = await getBgList(item.id);
      return { item, briefs };
    } catch (error) {
      console.error("Error fetching briefs list:", error);
      return { item, briefs: [] }; // Handle error case
    }
  });

  // Wait for all briefs to be fetched
  const briefsData = await Promise.all(briefsPromises);

  // Process fetched briefs data
  briefsData.forEach(({ item, briefs }) => {
    // Sort briefs by updatedAt in descending order
    briefs.sort((a, b) => {
      const dateA = a.updatedAt ? new Date(a.updatedAt) : null;
      const dateB = b.updatedAt ? new Date(b.updatedAt) : null;
      return dateB - dateA;
    });

    // Add briefs to the item's briefs array
    item.briefs = briefs;

    // Update mostRecentBrief if it's the most recent one
    if (
      briefs.length > 0 &&
      (!mostRecentBrief ||
        new Date(briefs[0].updatedAt) > new Date(mostRecentBrief.updatedAt))
    ) {
      mostRecentBrief = briefs[0];
    }

    // Add the most recent brief directly to the item
    item.mostRecentBrief = briefs[0];
  });

  return mostRecentBrief; // Return the updated mostRecentBrief
}


//mma 1010

export const isDupe = async (
  client,
  matter,
  companyId,
  userId,
  dispatch,
  setError
) => {
  // dispatch({
  //   type: MATTER_REQUEST,
  //   payload: { loading: true },
  // });
  let result = [];

  const clientMattersOpt = await API.graphql({
    query: listClientMatters,
    variables: {
      companyId: companyId,
    },
  });

  if (clientMattersOpt.data.company.clientMatters.items !== null) {
    result = clientMattersOpt.data.company.clientMatters.items;

    const dummyPersonResponsible = {
      id: localStorage.getItem("userId"),
      name:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
      email: localStorage.getItem("email"),
      profile_picture:
        "https://as1.ftcdn.net/v2/jpg/03/64/62/36/1000_F_364623643_58jOINqUIeYmkrH7go1smPaiYujiyqit.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
    };

    var filtered = result?.filter(function (el) {
      return el.client != null;
    });

    var apdPr = filtered.map((v) => ({
      ...v,
      substantially_responsible: dummyPersonResponsible,
    }));
    var apdMn = apdPr.map((v) => ({
      ...v,
      matter_number: `{${v.matter?.name?.charAt(2)}-${v.matter?.id?.slice(
        -4
      )}/${v.client?.id?.slice(-4)}}`,
    }));
  }

  const dup = result?.some((cmatter) => {
    if (
      cmatter?.client?.id === client?.id &&
      cmatter?.client?.name === client?.name &&
      cmatter?.matter?.name === matter?.name &&
      cmatter?.matter?.id === matter?.id
    ) {
      return true;
    } else {
      return false;
    }
  });

  if (dup) {
    dispatch({
      type: CREATE_MATTER_DUPLICATE,
      payload: {
        matterlist: apdMn,
        message: `Client ${client.name} and Matter ${matter.name} already exists`,
      },
    });

    return true;
  } else {
    return false;
  }
};

//add user acc
const handleAddUserAccess = async (e, dataId, dataInfo) => {
  console.log("event", e);
  console.log("dataInfo", dataInfo);
  var bId = dataId;
  var temp = [];

  e.map((item) => (temp = [...temp, item.label]));

  const params = {
    query: mAddUserAcces,
    variables: {
      briefId: bId,
      userType: temp,
    },
  };

  await API.graphql(params).then((ua) => {
    console.log("uaa", ua);
  });
};

export const addClientMatter = async (
  client,
  matter,
  companyId,
  userId,
  dispatch
) => {
  try {
    dispatch({
      type: CREATE_MATTER_REQUEST,
      payload: { loading: true },
    });

    const [clientmatter, clientMattersOpt] = await Promise.all([
      API.graphql({
        query: createClientMatter,
        variables: {
          companyId: companyId,
          userId: userId,
          client: client,
          matter: matter,
        },
      }),
      API.graphql({
        query: listClientMatters,
        variables: {
          companyId: companyId,
        },
      }),
    ]);

    const newClientMatter = clientmatter?.data.clientMatterCreate;
    const createdClientMatterId = newClientMatter?.id;

    // create default background on client/matter create
    let currentDate = moment
      .utc(moment(new Date(), "YYYY-MM-DD"))
      .toISOString();

    let {
      Contracts,
    } = await createDefaultBriefs(createdClientMatterId, currentDate);

    await createContractBackground(Contracts);

    // Creation of Mirrored Email Page
    const defaultColumnIds = [0, 1, 2, 3, 4];

    const paramsMirroredEmailPage = {
      clientMatterId: createdClientMatterId,
      name: "Mirrored Email Page",
      date: null,
      order: 0,
      columnIds: defaultColumnIds,
    };

    await API.graphql({
      query: mCreateBrief,
      variables: paramsMirroredEmailPage,
    }).then(async (response) => {
      const briefId = response.data?.briefCreate?.id;
      // MMA-1700 Background: Manage User Access needs to be triggered for the users to see the content of the briefs
      var defaultUserAccessValues = [
        {
          label: "LEGALADMIN",
          value: "LEGALADMIN",
        },
        {
          label: "LAWYER",
          value: "LAWYER",
        },
      ];
      handleAddUserAccess(defaultUserAccessValues, briefId, response.data);
    });

    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: createdClientMatterId,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      console.log(
        "Proc setDefaultBriefColumnIds(briefsList, createdClientMatterId)",
        briefsList,
        createdClientMatterId
      );
      await setDefaultBriefColumnIds(
        sortByOrder(briefsList),
        createdClientMatterId
      );
    });

    let apdMn = [];

    if (clientMattersOpt?.data?.company?.clientMatters?.items) {
      const cm = clientMattersOpt.data.company.clientMatters.items;
      apdMn = await filterMatterList(cm);
    }

    //dispatch data to reducers
    dispatch({
      type: CREATE_MATTER_SUCCESS,
      payload: {
        matterlist: apdMn,
        message: `Successfully created Client ${client.name} and Matter ${matter.name}`,
      },
    });

    return createdClientMatterId;
  } catch (error) {
    dispatch({
      type: CREATE_MATTER_ERROR,
      payload: error,
    });
  }
};

const sortByOrder = (arr) => {
  let sort;

  if (arr) {
    sort = arr.sort((a, b) =>
      a.order === null || b.order === null
        ? a
        : a.order - b.order === 0
        ? new Date(b.createdAt) - new Date(a.createdAt)
        : a.order - b.order
    );
  } else {
    sort = arr;
  }
  return sort;
};

async function setDefaultBriefColumnIds(briefs, clientMatterId) {
  const savePromises = [];
  let briefIds = [];
  const defaultColumnIds = [0, 1, 2, 3, 4];

  console.log("SETTING DEFAULT COLS", briefs);
  console.log("Created Client matter ID: ", clientMatterId);

  for (const { columnIds, id, name } of briefs) {
    if (!columnIds || columnIds?.length === 0) {
      console.log("columnIds", columnIds);
      savePromises.push(
        API.graphql({
          query: UPDATE_BRIEF_PRESET_MUTATION,
          variables: { columnIds: defaultColumnIds, id },
        })
      );
    }

    briefIds.push({ id: id, name: name });
  }

  try {
    const updatedBriefIds = await Promise.all(savePromises);
    const backgroundTable = await API.graphql({
      query: BACKGROUND_TABLE_QUERY,
      variables: {
        clientMatterId: clientMatterId,
      },
    });

    console.log("Background Table for", clientMatterId, backgroundTable);

    if (backgroundTable?.data?.backgroundTable) {
      await setDefaultColumnBriefs(
        backgroundTable?.data?.backgroundTable,
        briefs
      );
    } else {
      console.log("Create Background Table for ", clientMatterId);

      const initialPresets = [];
      for (const item of briefs) {
        const preset = { id: item.id, name: item.name };
        initialPresets.push(preset);
      }

      let backgroundCreate = await API.graphql({
        query: BACKGROUND_TABLE_CREATE,
        variables: {
          clientMatterId: clientMatterId,
          initialPresets: initialPresets,
        },
      });
      await setDefaultColumnBriefs(
        backgroundCreate?.data?.backgroundTableCreate,
        briefs
      );
    }
  } catch (error) {
    console.error(error);
  }
}

// async function setDefaultColumnBriefs(backgroundTables, briefs) {
//   console.group("setDefaultColumnBriefs");
//   console.log(backgroundTables, briefs);
//   const defaultColumnIds = [0, 1, 2, 3, 4];

//   try {
//     const { id, columns } = backgroundTables;

//     let invoicePreset;
//     let variationPreset;
//     let builderDetailsPreset;
//     let provisionalSumPreset;
//     let contractDetailsPreset;
//     let budgetPreset;
//     let primeCostPreset;

//     console.log("Current columns columns", columns);
//     console.log("defaultColumnIds:", defaultColumnIds);

//     if (id) {
//       let presets = [];
//       const commentsColPresets = [];

//       for (const item of briefs) {
//         console.log("ITEM IS:", item);
//         const preset = { id: item.id, name: item.name };
//         if (item.name == "Invoices") {
//           invoicePreset = { id: item.id, name: item.name };
//         }
//         if (item.name == "Variations") {
//           variationPreset = { id: item.id, name: item.name };
//         }
//         if (item.name == "Provisional Sum") {
//           provisionalSumPreset = { id: item.id, name: item.name };
//         }
//         if (item.name == "Prime Cost") {
//           primeCostPreset = { id: item.id, name: item.name };
//         }
//         if (item.name == "Budget") {
//           budgetPreset = { id: item.id, name: item.name };
//         }
//         if (item.name == "Builder Details") {
//           builderDetailsPreset = { id: item.id, name: item.name };
//         }
//         if (item.name == "Contract Details") {
//           contractDetailsPreset = { id: item.id, name: item.name };
//         }

//         presets.push(preset);

//         if (item.hasCommentAccess) {
//           const commentPreset = { ...preset };
//           commentsColPresets.push(commentPreset);
//         }
//       }
//       console.log("presets", presets);
//       let isDefaultPresent = false;
//       let isLabelPresent = false;
//       let isInvoicePresent = false;
//       let isVariationPresent = false;
//       let isProvisionalSumPresent = false;
//       let isBuilderDetailsPresent = false;
//       let isContractDetailsPresent = false;
//       let isBudgetPresent = false;
//       let isPrimeCostPresent = false;
//       let breakDownPresetList;

//       // Filters out null columns
//       let filteredColumns = [];
//       columns.map((obj) => {
//         if (obj.headerText === null) return false;

//         // If column is not duplicate
//         if (!filteredColumns.some((e) => e.headerText === obj.headerText)) {
//           // Remove null and undefined presets
//           let filteredCol = {...obj}
//           if (filteredCol?.presets) {
//             filteredCol.presets = obj.presets.filter(
//               (preset) => preset !== null && preset !== undefined
//             );
//           }

//           filteredColumns.push(filteredCol);
//         }
//       });

//       console.log("filteredColumns", filteredColumns)
//       let defaultCols = [
//         filteredColumns.find((col) => col.headerText === "Date"),
//         filteredColumns.find((col) => col.headerText === "Gmail"),
//         filteredColumns.find((col) => col.headerText === "Description of Background"),
//         filteredColumns.find((col) => col.headerText === "Comments"),
//         filteredColumns.find((col) => col.headerText === "Document"),
//       ].filter((col) => col !== undefined && col !== null && col);

//       // Sorts column with default column containing their default IDs.
//       let sortedColumns = [
//         ...defaultCols,
//         ...filteredColumns.filter((col) =>
//           !defaultCols.some((defaultCol) =>
//               col.headerText === defaultCol.headerText)).sort((a, b) =>
//                 a.order - b.order
//               )
//       ]

//       console.log("sortedColumns", sortedColumns);
//       let reorganizedColumns = sortedColumns.map((col, index) => ({
//           ...col,
//           accessorKey: index.toString(),
//           id: index.toString(),
//           order: index + 1,
//         })
//       )

//       console.log("reorganizedColumns", reorganizedColumns)
//       const defaultColumns = reorganizedColumns.map((obj) => {
//         var tag;
//         if (
//           obj.headerText == "Date" ||
//           obj.headerText == "Gmail" ||
//           obj.headerText == "Description of Background" ||
//           obj.headerText == "Comments" ||
//           obj.headerText == "Document"
//         ) {
//           isDefaultPresent = true;
//         }
//         if (obj.headerText == "Label") {
//           isLabelPresent = true;
//         }
//         if (
//           obj.headerText == "Claim Status" ||
//           obj.headerText == "Payment Status" ||
//           obj.headerText == "Claim Amount ($)" ||
//           obj.headerText == "Due Date for Claim"
//         ) {
//           tag = "Invoices";
//           isInvoicePresent = true;
//         }
//         if (
//           obj.headerText == "Variation Status" ||
//           obj.headerText == "Variation Cost" ||
//           obj.headerText == "Variation Cost ($)"
//         ) {
//           tag = "Variations";
//           isVariationPresent = true;
//         }
//         if (
//           obj.headerText == "Provisional Amount" ||
//           obj.headerText == "Cost Amount" ||
//           obj.headerText == "Description of Work" ||
//           obj.headerText == "Paid to Date Amount" ||
//           obj.headerText == "Action"
//         ) {
//           tag = "Provisional Sum";
//           isProvisionalSumPresent = true;
//         }
//         if (
//           obj.headerText == "Builder Name" ||
//           obj.headerText == "Builder Logo" ||
//           obj.headerText == "Builder Contact Name" ||
//           obj.headerText == "Builder Contact Email" ||
//           obj.headerText == "Builder Contact Number"
//         ) {
//           tag = "Builder Details";
//           isBuilderDetailsPresent = true;
//         }

//         if (
//           obj.headerText == "Start Date" ||
//           obj.headerText == "Contract Price" ||
//           obj.headerText == "Contract Value"
//         ) {
//           isContractDetailsPresent = true;
//           tag = "Contract Details";
//         }

//         console.log("obj.presets", obj.preset);
//         breakDownPresetList = presets.filter((preset) =>
//           preset.name.includes("_")
//         );
//         console.log("Break Down Preset List:", breakDownPresetList);
//         return {
//           ...obj,
//           presets:
//             obj.headerText == "Label"
//               ? presets?.length
//                 ? presets.filter(
//                     (preset) =>
//                       preset.name !== "Contract Details" &&
//                       preset.name !== "Builder Details" &&
//                       preset.name !== "Variations" &&
//                       preset.name !== "Provisional Sum" &&
//                       preset.name !== "Budget" &&
//                       preset.name !== "Prime Cost" &&
//                       !preset.name.includes("_")
//                   )
//                 : []
//               : obj.headerText === "Cost Amount"
//               ? [...obj.presets, ...breakDownPresetList]
//               : obj.headerText == "Gmail" || obj.headerText === "Email Date"
//               ? presets?.length
//                 ? presets.filter(
//                     (preset) =>
//                       preset.name !== "Contract Details" &&
//                       preset.name !== "Builder Details" &&
//                       !preset.name.includes("_")
//                   )
//                 : []
//               : isVariationPresent ||
//                 isInvoicePresent ||
//                 isProvisionalSumPresent ||
//                 isPrimeCostPresent
//               ? obj.presets
//               : isBuilderDetailsPresent || isContractDetailsPresent
//               ? obj.presets.filter((preset) => preset.name === tag)
//               : obj.headerText === "Description of Background" ||
//                 obj.headerText === "Date"
//               ? presets?.length
//                 ? presets.filter(
//                     (preset) =>
//                       preset.name !== "Contract Details" &&
//                       preset.name !== "Builder Details"
//                   )
//                 : []
//               : obj.headerText === "Document"
//               ? presets?.length
//                 ? presets?.filter(
//                     (preset) =>
//                       preset.name !== "Contract Details" &&
//                       !preset.name.includes("_")
//                   )
//                 : []
//               : presets,
//         };
//       });
//       console.log("Default Columns:", defaultColumns);

//       // console.log("INVOICE PRESET:", invoicePreset);

//       let newColumns = [...defaultColumns];

//       console.log("isInvoicePresent", isInvoicePresent);
//       console.log("isVariationPresent", isVariationPresent);
//       console.log("isBuilderDetailsPresent", isBuilderDetailsPresent);
//       console.log("isContractDetailsPresent", isContractDetailsPresent);
//       console.log("isProvisionalSumPresent", isProvisionalSumPresent);
//       console.log("isBudgetPresent", isBudgetPresent);
//       console.log("isPrimeCostPresent", isPrimeCostPresent);

//       if (
//         !isDefaultPresent ||
//         !isLabelPresent ||
//         !isInvoicePresent ||
//         !isVariationPresent ||
//         !isProvisionalSumPresent ||
//         !isBuilderDetailsPresent ||
//         !isContractDetailsPresent ||
//         !isPrimeCostPresent
//       ) {
//         if (!isDefaultPresent) {
//           let newColumnsAdjusted = newColumns.map((col) => ({
//             ...col,
//             id: (parseInt(col.id) + 5).toString(),
//             accessorKey: (parseInt(col.accessorKey) + 5).toString(),
//             order: col.order + 5,
//           }));

//           newColumns = [
//             {
//               accessorKey: "0",
//               id: "0",
//               presets: presets.filter(
//                 (preset) =>
//                   preset.name !== "Contract Details" &&
//                   preset.name !== "Builder Details"
//               ),
//               headerText: "Date",
//               type: "DATE",
//               enabled: true,
//               order: 0,
//             },
//             {
//               accessorKey: "1",
//               id: "1",
//               presets: presets.filter(
//                 (preset) =>
//                   preset.name !== "Contract Details" &&
//                   preset.name !== "Builder Details" &&
//                   !preset.name.includes("_")
//               ),
//               headerText: "Gmail",
//               type: "READONLY",
//               enabled: true,
//               order: 1,
//             },
//             {
//               accessorKey: "2",
//               id: "2",
//               presets: presets.filter(
//                 (preset) =>
//                   preset.name !== "Contract Details" &&
//                   preset.name !== "Builder Details" &&
//                   !preset.name.includes("_")
//               ),
//               headerText: "Description of Background",
//               type: "TEXTAREA",
//               enabled: true,
//               order: 2,
//             },
//             {
//               accessorKey: "3",
//               id: "3",
//               presets: commentsColPresets,
//               headerText: "Comments",
//               type: "TEXTAREA",
//               enabled: true,
//               order: 3,
//             },
//             {
//               accessorKey: "4",
//               id: "4",
//               presets: presets.filter(
//                 (preset) =>
//                   preset.name !== "Contract Details" &&
//                   !preset.name.includes("_")
//               ),
//               headerText: "Document",
//               type: "READONLY",
//               enabled: true,
//               order: 4,
//             },
//             ...newColumnsAdjusted,
//           ];
//         }
//         if (!isLabelPresent) {
//           newColumns = [
//             ...newColumns,
//             {
//               accessorKey: newColumns.length.toString(),
//               id: newColumns.length.toString(),
//               presets: presets,
//               headerText: "Label",
//               type: "READONLY",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length,
//             },
//           ];
//         }
//         if (
//           !isInvoicePresent &&
//           !isVariationPresent &&
//           !isProvisionalSumPresent &&
//           !isPrimeCostPresent
//         ) {
//           newColumns = [
//             ...newColumns,
//             {
//               accessorKey: newColumns.length.toString(),
//               id: newColumns.length.toString(),
//               presets: [invoicePreset],
//               headerText: "Due Date for Claim",
//               type: "DATE",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length,
//             },
//             {
//               accessorKey: newColumns.length.toString(),
//               id: newColumns.length.toString(),
//               presets: [invoicePreset],
//               headerText: "Claim Status",
//               type: "DROPDOWN",
//               enabled: true,
//               optionsText: ["Claimed", "Unclaimed"],
//               order: newColumns.length + 1,
//             },
//             {
//               accessorKey: newColumns.length.toString(),
//               id: newColumns.length.toString(),
//               presets: [invoicePreset],
//               headerText: "Payment Status",
//               type: "DROPDOWN",
//               enabled: true,
//               optionsText: ["Paid", "Unpaid"],
//               order: newColumns.length + 2,
//             },
//             {
//               accessorKey: (newColumns.length + 1).toString(),
//               id: (newColumns.length + 1).toString(),
//               presets: [invoicePreset],
//               headerText: "Claim Amount ($)",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 3,
//             },
//             {
//               accessorKey: (newColumns.length + 2).toString(),
//               id: (newColumns.length + 2).toString(),
//               presets: [variationPreset],
//               headerText: "Variation Status",
//               type: "DROPDOWN",
//               enabled: true,
//               optionsText: ["Approved", "For Approval"],
//               order: newColumns.length + 4,
//             },
//             {
//               accessorKey: (newColumns.length + 3).toString(),
//               id: (newColumns.length + 3).toString(),
//               presets: [variationPreset],
//               headerText: "Variation Cost ($)",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 5,
//             },
//             {
//               accessorKey: (newColumns.length + 4).toString(),
//               id: (newColumns.length + 3).toString(),
//               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
//               headerText: "Provisional Amount",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 6,
//             },
//             {
//               accessorKey: (newColumns.length + 5).toString(),
//               id: (newColumns.length + 3).toString(),
//               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
//               headerText: "Cost Amount",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 7,
//             },
//             {
//               accessorKey: (newColumns.length + 6).toString(),
//               id: (newColumns.length + 3).toString(),
//               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
//               headerText: "Description of Work",
//               type: "TEXT",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 8,
//             },
//             {
//               accessorKey: (newColumns.length + 7).toString(),
//               id: (newColumns.length + 3).toString(),
//               // to add budget preset
//               presets: [
//                 provisionalSumPreset,
//                 variationPreset,
//                 budgetPreset,
//                 primeCostPreset,
//               ],
//               headerText: "Paid to Date Amount",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 9,
//             },
//             {
//               accessorKey: (newColumns.length + 8).toString(),
//               id: (newColumns.length + 3).toString(),
//               // to add budget preset
//               presets: [
//                 provisionalSumPreset,
//                 variationPreset,
//                 budgetPreset,
//                 primeCostPreset,
//               ],
//               headerText: "Action",
//               type: "TEXT",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 10,
//             },
//           ];
//         }
//         if (!isBuilderDetailsPresent) {
//           newColumns = [
//             ...newColumns,
//             {
//               accessorKey: newColumns.length.toString(),
//               id: newColumns.length.toString(),
//               presets: [builderDetailsPreset],
//               headerText: "Builder Name",
//               type: "TEXT",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length,
//             },
//             {
//               accessorKey: (newColumns.length + 1).toString(),
//               id: (newColumns.length + 1).toString(),
//               presets: [builderDetailsPreset],
//               headerText: "Builder Contact Name",
//               type: "TEXT",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 1,
//             },
//             {
//               accessorKey: (newColumns.length + 2).toString(),
//               id: (newColumns.length + 2).toString(),
//               presets: [builderDetailsPreset],
//               headerText: "Builder Contact Email",
//               type: "TEXT",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 2,
//             },
//             {
//               accessorKey: (newColumns.length + 3).toString(),
//               id: (newColumns.length + 3).toString(),
//               presets: [builderDetailsPreset],
//               headerText: "Builder Contact Number",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 3,
//             },
//           ];
//         }
//         if (!isContractDetailsPresent) {
//           newColumns = [
//             ...newColumns,
//             {
//               accessorKey: newColumns.length.toString(),
//               id: newColumns.length.toString(),
//               presets: [contractDetailsPreset],
//               headerText: "Start Date",
//               type: "DATE",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length,
//             },
//             {
//               accessorKey: (newColumns.length + 1).toString(),
//               id: (newColumns.length + 1).toString(),
//               presets: [contractDetailsPreset],
//               headerText: "Contract Price",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 1,
//             },
//             {
//               accessorKey: (newColumns.length + 2).toString(),
//               id: (newColumns.length + 2).toString(),
//               presets: [contractDetailsPreset],
//               headerText: "Contract Value",
//               type: "NUMBER",
//               enabled: true,
//               optionsText: [],
//               order: newColumns.length + 2,
//             },
//           ];
//         }
//       } else {
//         //Filter Columns & set it to default HBA presets
//         let filterBriefs = [];
//         defaultColumns.map((obj) => {
//           if (
//             obj.headerText == "Claim Status" ||
//             obj.headerText == "Payment Status" ||
//             obj.headerText == "Claim Amount ($)" ||
//             obj.headerText == "Due Date for Claim"
//           ) {
//             filterBriefs.push({
//               ...obj,
//               presets: [invoicePreset],
//             });
//           }

//           if (
//             obj.headerText == "Variation Status" ||
//             obj.headerText == "Variation Cost" ||
//             obj.headerText == "Variation Cost ($)"
//           ) {
//             filterBriefs.push({
//               ...obj,
//               presets: [variationPreset],
//             });
//           }

//           if (obj.headerText == "Cost Amount") {
//             filterBriefs.push({
//               ...obj,
//               presets: [
//                 provisionalSumPreset,
//                 budgetPreset,
//                 primeCostPreset,
//                 ...breakDownPresetList,
//               ],
//             });
//           }

//           if (
//             obj.headerText == "Provisional Amount" ||
//             obj.headerText == "Description of Work"
//           ) {
//             filterBriefs.push({
//               ...obj,
//               presets: [provisionalSumPreset, budgetPreset, primeCostPreset],
//             });
//           }

//           if (
//             obj.headerText == "Builder Name" ||
//             obj.headerText == "Builder Logo" ||
//             obj.headerText == "Builder Contact Name" ||
//             obj.headerText == "Builder Contact Email" ||
//             obj.headerText == "Builder Contact Number"
//           ) {
//             filterBriefs.push({
//               ...obj,
//               presets: [builderDetailsPreset],
//             });
//           }

//           if (
//             obj.headerText == "Start Date" ||
//             obj.headerText == "Contract Price" ||
//             obj.headerText == "Contract Value"
//           ) {
//             filterBriefs.push({
//               ...obj,
//               presets: [contractDetailsPreset],
//             });
//           }

//           if (
//             obj.headerText === "Paid to Date Amount" ||
//             obj.headerText === "Action"
//           ) {
//             filterBriefs.push({
//               ...obj,
//               // to add budget preset
//               presets: [
//                 provisionalSumPreset,
//                 variationPreset,
//                 budgetPreset,
//                 primeCostPreset,
//               ],
//             });
//           }

//           if (
//             obj.headerText !== "Start Date" &&
//             obj.headerText !== "Contract Price" &&
//             obj.headerText !== "Contract Value" &&
//             obj.headerText !== "Claim Status" &&
//             obj.headerText !== "Payment Status" &&
//             obj.headerText !== "Claim Amount ($)" &&
//             obj.headerText !== "Due Date for Claim" &&
//             obj.headerText !== "Variation Status" &&
//             obj.headerText !== "Variation Cost" &&
//             obj.headerText !== "Variation Cost ($)" &&
//             obj.headerText !== "Description of Work" &&
//             obj.headerText !== "Provisional Amount" &&
//             obj.headerText !== "Cost Amount" &&
//             obj.headerText !== "Paid to Date Amount" &&
//             obj.headerText !== "Action" &&
//             obj.headerText !== "Builder Name" &&
//             obj.headerText !== "Builder Logo" &&
//             obj.headerText !== "Builder Contact Name" &&
//             obj.headerText !== "Builder Contact Email" &&
//             obj.headerText !== "Builder Contact Number" &&
//             obj.headerText !== "Start Date" &&
//             obj.headerText !== "Contract Price" &&
//             obj.headerText !== "Contract Value"
//           ) {
//             filterBriefs.push({
//               ...obj,
//             });
//           }
//         });
//         console.log("filtered Briefs:", filterBriefs);
//         newColumns = filterBriefs.map(col => ({
//           ...col,
//           presets: col.presets.filter(preset =>
//             preset !== undefined && preset !== null && preset)
//         }));
//       }

//       console.log(id, "new columns:", newColumns);

//       const variables = {
//         id,
//         input: {
//           columns: newColumns,
//         },
//       };

//       console.log("VARIABLE IS:", variables);

//       const updateBriefTable = await API.graphql({
//         query: UPDATE_BACKGROUND_TABLE_MUTATION,
//         variables,
//       });

//       console.log("updateBriefTable", updateBriefTable);
//     }
//   } catch (error) {
//     console.error(error);
//     console.groupEnd("setDefaultColumnBriefs");
//   }
//   console.groupEnd("setDefaultColumnBriefs");
// }

//delete client and matter
export const deleteMatterClient = async (
  selectedClientMatter,
  dispatch,
  companyId
) => {
  try {
    // dispatch({
    //   type: DELETE_MATTER_REQUEST,
    //   payload: { loading: true },
    // });

    if (selectedClientMatter !== null && selectedClientMatter !== undefined) {
      await API.graphql({
        query: deleteClientMatter,
        variables: {
          id: selectedClientMatter,
          isDeleted: true,
        },
      });
    }

    let result = [];

    // const clientMattersOpt = await API.graphql({
    //   query: listClientMatters,
    //   variables: {
    //     companyId: companyId,
    //   },
    // }).then(async (response) => {
      // console.log("DELETE CM RESPONSE", response);
      // // clientMattersOpt.data.company.clientMatters.items !== null ------ old condition
      // if (response.data.company.clientMatters.items !== null) {
      //   // result = clientMattersOpt.data.company.clientMatters.items;
      //   var result1 = response.data.company.clientMatters.items;

      //   const dummyPersonResponsible = {
      //     id: localStorage.getItem("userId"),
      //     name:
      //       localStorage.getItem("firstName") +
      //       " " +
      //       localStorage.getItem("lastName"),
      //     email: localStorage.getItem("email"),
      //     profile_picture:
      //       "https://as1.ftcdn.net/v2/jpg/03/64/62/36/1000_F_364623643_58jOINqUIeYmkrH7go1smPaiYujiyqit.jpg?auto=compress&cs=tinysrgb&h=650&w=940",
      //   };

      //   var filtered = result1?.filter(function (el) {
      //     return el.client != null;
      //   });

      //   var apdPr = filtered.map((v) => ({
      //     ...v,
      //     substantially_responsible: dummyPersonResponsible,
      //   }));

      //   // var apdMn = apdPr.map((v) => ({
      //   //   ...v,
      //   //   matter_number: `{${v.matter?.name?.charAt(2)}-${v.matter?.id?.slice(
      //   //     -4
      //   //   )}/${v.client?.id?.slice(-4)}}`
      //   // }));

      //   var apdMn = await filterMatterList(result1);

      //   console.log("APDMN", apdMn);

      //   dispatch({
      //     type: DELETE_MATTER_SUCCESS,
      //     payload: {
      //       matterlist: apdMn ? apdMn : [],
      //       message: `Successfully deleted`,
      //     },
      //   });
      // }
      //dispatch data to reducers
    // });
    // dispatch({
    //   type: DELETE_MATTER_REQUEST,
    //   payload: { loading: false },
    // });
    return;
  } catch (error) {
    dispatch({
      type: DELETE_MATTER_ERROR,
      payload: error,
    });
  }
};

//search client matter
export const searchMatterClient = async (companyId, searchMatter, dispatch) => {
  try {
    dispatch({
      type: SEARCH_MATTER_REQUEST,
      payload: { loading: true },
    });

    let result = [];
    let mostRecentBrief = null;

    let params = {
      query: listClientMatters,
      variables: {
        companyId: companyId,
        search: searchMatter.toLowerCase()
      },
    }
    console.log("Dashboard Search: Running search with parameters", params)
    const clientMattersOpt = await API.graphql(params);

    if (clientMattersOpt.data.company.clientMatters.items !== null) {
      result = clientMattersOpt.data.company.clientMatters.items;
      console.log("Dashboard Search: Retrieved items from search", result.length)

      // Fetch briefs for each client matter and merge them into result
      // await appendBriefsToClientMatter(result, mostRecentBrief);

      const unblankedResult = result.filter(
        (x) => x.matter !== null && x.client !== null
      );
      var apdMn = await filterMatterList(unblankedResult);
      console.log("APDMN", apdMn);
    }

    // const matterslists = apdMn.filter(
    //   (x) =>
    //     x?.matter?.name?.toUpperCase().includes(searchMatter.toUpperCase()) ||
    //     x?.client?.name?.toUpperCase().includes(searchMatter.toUpperCase())
    // );

    const matterslists = apdMn;
    console.log("Dashboard Search: Retrieved Items", matterslists);

    dispatch({
      type: SEARCH_MATTER_SUCCESS,
      payload: {
        matterlist: matterslists,
      },
    });

    //dispatch data to reducers
  } catch (error) {
    dispatch({
      type: SEARCH_MATTER_ERROR,
      payload: error,
    });
  }
};
