import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../shared/Button";
import { AiOutlineDollar } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { API, Auth } from "aws-amplify";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import invoicesIcon from "../../assets/images/invoices-icon.png";
import { FiEdit } from "react-icons/fi";

export default function Invoices({ items, redirectUrl, invoicesRef }) {
  const history = useHistory();
  const [background, setBackground] = useState(items?.backgrounds);
  const [showMore, setShowMore] = useState(false);
  const [backgroundList, setBackgroundList] = useState(null);
  const [paidInvoices, setPaidInvoices] = useState(0);
  const [paidInvoiceList, setPaidInvoiceList] = useState([]);
  const [unpaidInvoiceList, setUnpaidInvoiceList] = useState([]);

  function visitBrief() {
    history.push(redirectUrl);
  }

  useEffect(() => {
    if (backgroundList === null) {
      getBackgrounds(items.id);
    }
  }, [backgroundList]);

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
        query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
            brief(id: $id) {
            id
            backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
                items {
                id
                description
          dynamicData
                comments
                date
                createdAt
                updatedAt
                order
                files {
                    items {
                    id
                    name
                    }
                }
                }
                nextToken
            }
            }
        }
        `;
      
        const getValueDynamicData = (dynamicData, type) => {
          if (dynamicData !== null && dynamicData !== undefined && type !== "") {
              const item = JSON.parse(dynamicData);
              const values = [];
      
              console.log('Dynamic Data', item);
      
              // Define the fixed positions for each type
              const typeIndexMap = {
                  "Due Date for Claim": 6,
                  "Claim Status": 7,
                  "Payment Status": 8,
                  "Claim Amount ($)": 9
              };
      
              const index = typeIndexMap[type];
              if (index !== undefined) {
                  return item[index.toString()] || undefined;
              } else {
                  console.warn(`Invalid type: ${type}`);
                  return undefined;
              }
          } else {
              return undefined;
          }
        };
      
      
      

  const getBackgrounds = async (id) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });
    var bgList = backgroundOpt.data.brief.backgrounds.items;
    setBackgroundList(bgList);

    var paidInvoiceCount = 0;
    var paidInvoices = [];
    var unpaidInvoices = [];

    bgList.map((x) => {
      if (
        x.dynamicData !== null ||
        x.dynamicData !== undefined ||
        x.dynamicData
      ) {
        //Getting Dynamic data per type
        const claimStatus = getValueDynamicData(x.dynamicData, "Claim Status");
        const paidStatus = getValueDynamicData(x.dynamicData, "Payment Status");
        const dueDateClaim = getValueDynamicData(x.dynamicData, "Due Date for Claim");
        const claimAmount = getValueDynamicData(x.dynamicData, "Claim Amount ($)");

        console.log('Claim Status >>> ', claimStatus);
        console.log('Paid Status >>> ', paidStatus);
        console.log('Due Date Claim >>> ', dueDateClaim);
        console.log('Claim Amount >>> ', claimAmount);
        
        if (claimStatus || paidStatus) {
          if (claimStatus === "Claimed" || paidStatus === "Paid") {
            paidInvoiceCount = paidInvoiceCount + 1;
            paidInvoices = [...paidInvoices, x];
          } else {
            unpaidInvoices = [...unpaidInvoices, x];
          }
        }
      }
    });

    console.group('Invoices');
    console.log("Invoices Details, full id", items);
    console.log('Getting Dynamic Data for Invoices')
    console.log("Paid invoices::", paidInvoices);
    console.log("Unpaid invoices::", unpaidInvoices);
    console.log('Paid Invoices::', paidInvoiceCount);
    console.groupEnd();

    var sortedListPaid = paidInvoices?.sort(
      (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
    );

    var sortedListUnpaid = unpaidInvoices?.sort(
      (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
    );

    setUnpaidInvoiceList(sortedListUnpaid);
    setPaidInvoiceList(sortedListPaid);
    setPaidInvoices(paidInvoiceCount);
  };

  return (
    <>
      <div
        className="bg-white shadow rounded-lg border border-neutral-200 overflow-hidden"
        ref={invoicesRef}
        id="Invoices"
      >
        <div className="w-full pt-6 px-8">
          <div className="relative">
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img src={invoicesIcon} alt="Invoices Icon" className="pb-2 w-11 h-auto"/>
            </div>
            <div 
              className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
              onClick={visitBrief}
            >
              <FiEdit className="w-4 h-4"/>
            </div>
          </div>

          <div className="gap-1 z-20 relative">
            <div className="justify-start items-center gap-2 pb-5">
              <div className="text-black text-lg font-semibold leading-7 py-2">
                Invoices
              </div>

              {background?.items?.length === 0 ? (
                <div
                  className="w-full text-sm font-normal text-gray-600 pt-6 leading-tight font-open-sans"
                  style={{ color: "#5A5A5A" }}
                >
                  Access and manage all your payment invoices in one piece.
                  Review detailed breakdown of costs and track your payments
                  with ease.
                </div>
              ) : (
                <>
                  <div className="w-full">
                    <div
                      className="text-sm w-full justify-center items-center flex"
                      style={{ width: 100, height: 100, margin: "auto" }}
                    >
                      <CircularProgressbarWithChildren
                        value={paidInvoices}
                        maxValue={background?.items?.length}
                        // text={`${paidInvoices}/${background?.items?.length}`}
                        styles={buildStyles({
                          textColor: "#184067",
                          pathColor: "#184067",
                          textColor: "black",
                        })}
                      >
                        <div style={{ fontSize: 12 }}>CLAIMS</div>
                        <div className="font-semibold" style={{ fontSize: 16 }}>
                          {`${paidInvoices}/${background?.items?.length}`}
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                    <div className="self-stretch text-gray-700 text-xs font-semibold leading-none mt-5 flex m-auto justify-center items-center">
                      <div className="flex self-stretch text-gray-700 text-xs leading-none font-open-sans">
                        <BsCircleFill size={10} style={{ color: "#184067" }} />{" "}
                        &nbsp; Total Claims Made
                      </div>{" "}
                      &nbsp;&nbsp;
                      <div className="flex self-stretch text-gray-700 text-xs leading-none font-open-sans">
                        <BsCircleFill
                          size={10}
                          style={{ color: "RGB(214, 215, 215)" }}
                        />{" "}
                        &nbsp; Remaining
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="w-full px-4 flex-col justify-start items-start gap-3 inline-flex pb-6">
          {paidInvoiceList?.length > 0 && (
            <>
              <div className="w-full h-full flex">
                <div className="w-8 items-stretch">
                  <BsCircleFill size={10} style={{ color: "#184067" }} />
                  <div className="ml-1 h-full border-l-2 border-gray-300">
                    {" "}
                    &nbsp;
                  </div>
                </div>
                <div className="w-full h-full font-open-sans">
                  <div className="text-black text-md font-semibold  w-full -mt-1 font-open-sans">
                    Current Claim
                  </div>
                  {paidInvoiceList[0]?.description} <br />
                  <p 
                    className={`rounded-3xl text-xs text-left px-3 py-1 font-open-sans uppercase ${
                      getValueDynamicData(paidInvoiceList[0]?.dynamicData, "Payment Status") === "Paid" 
                      ? "text-green-700 bg-green-100" 
                      : "text-gray-700 bg-gray-100"
                    }`}>
                    {getValueDynamicData(paidInvoiceList[0]?.dynamicData, "Claim Status") ===
                      undefined ||
                      getValueDynamicData(paidInvoiceList[0]?.dynamicData, "Claim Amount ($)")  ===
                      undefined ? (
                      <>Please input data</>
                    ) : (
                      <>
                        {
                          `${
                            getValueDynamicData(paidInvoiceList[0]?.dynamicData, "Payment Status") 
                            || getValueDynamicData(paidInvoiceList[0]?.dynamicData, "Claim Status")
                          } ($${
                            getValueDynamicData(paidInvoiceList[0]?.dynamicData, "Claim Amount ($)")
                          })`
                        }

                      </>
                    )}
                  </p>
                </div>
              </div>
            </>
          )}

          {unpaidInvoiceList?.length > 0 &&
            unpaidInvoiceList?.map((item, index) => (
              <>
                {paidInvoiceList?.length === 0 ? (
                  <>
                    {index === 0 ? (
                      <>
                        <div className="w-full h-auto flex">
                          <div className="w-8 items-stretch">
                            <BsCircleFill
                              size={10}
                              style={{ color: "#184067" }}
                            />
                            <div className="ml-1 h-full border-l-2 border-gray-300">
                              {" "}
                              &nbsp;
                            </div>
                          </div>
                          <div className="w-full h-full font-open-sans">
                            <div className="text-black text-md font-semibold  w-full -mt-1 font-open-sans">
                              Current Claim
                            </div>
                            {item.description} <br />
                            <p 
                              className={`rounded-3xl text-xs text-left px-3 py-1 font-open-sans uppercase ${
                                getValueDynamicData(item?.dynamicData, "Payment Status") === "Paid" 
                                ? "text-green-700 bg-green-100" 
                                : "text-gray-700 bg-gray-100"
                              }`}
                            >
                              {/* {`${JSON.parse(item?.dynamicData)["5"]} ($${JSON.parse(item?.dynamicData)["6"]})`}  */}

                              {getValueDynamicData(item?.dynamicData, "Claim Status") ===
                                undefined ||
                                getValueDynamicData(item?.dynamicData, "Claim Amount ($)") ===
                                undefined ? (
                                <>Please input data</>
                              ) : (
                                <>
                                  {`${ getValueDynamicData(item?.dynamicData, "Payment Status") || getValueDynamicData(item?.dynamicData, "Claim Status") } ($${
                                    getValueDynamicData(item?.dynamicData, "Claim Amount ($)")
                                  })`}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : index === 1 ? (
                      <>
                        <div className="w-full h-auto flex">
                          <div className="w-8 items-stretch">
                            <BsCircleFill
                              size={10}
                              style={{ color: "#d7d6d6" }}
                            />
                            <div className="ml-1 h-full border-l-2 border-gray-300">
                              {" "}
                              &nbsp;
                            </div>
                          </div>
                          <div className="w-full h-full font-open-sans">
                            <div className="text-black text-md font-semibold  w-full -mt-1 font-open-sans">
                              Next Claim
                            </div>
                            {item.description}
                          </div>
                        </div>
                      </>
                    ) : index > 1 && showMore === true ? (
                      <>
                        <div className="w-full h-full flex">
                          <div className="w-8 items-stretch">
                            <BsCircleFill
                              size={10}
                              style={{ color: "#d7d6d6" }}
                            />
                            <div className="ml-1 h-full border-l-2 border-gray-300">
                              {" "}
                              &nbsp;
                            </div>
                          </div>
                          <div className="w-full h-full font-open-sans">
                            <div className="text-black text-md font-semibold  w-full -mt-1 font-open-sans">
                              Next Claim
                            </div>
                            {item.description}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {index === 0 || index === 1 ? (
                      <>
                        <div className="w-full h-full flex">
                          <div className="w-8 items-stretch">
                            <BsCircleFill
                              size={10}
                              style={{ color: "#d7d6d6" }}
                            />
                            <div className="ml-1 h-full border-l-2 border-gray-300">
                              {" "}
                              &nbsp;
                            </div>
                          </div>
                          <div className="w-full h-full font-open-sans">
                            <div className="text-black text-md font-semibold  w-full -mt-1 font-open-sans">
                              Next Claim
                            </div>
                            {item.description}
                          </div>
                        </div>
                      </>
                    ) : index > 1 && showMore === true ? (
                      <>
                        <div className="w-full h-full flex">
                          <div className="w-8 items-stretch">
                            <BsCircleFill
                              size={10}
                              style={{ color: "#d7d6d6" }}
                            />
                            <div className="ml-1 h-full border-l-2 border-gray-300">
                              {" "}
                              &nbsp;
                            </div>
                          </div>
                          <div className="w-full h-full font-open-sans">
                            <div className="text-black text-md font-semibold  w-full -mt-1 font-open-sans">
                              Next Claim
                            </div>
                            {item.description}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ))}

          {unpaidInvoiceList?.length > 2 ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowMore(!showMore);
              }}
            >
              {showMore === false ? (
                <>
                  <div className="w-full h-full flex">
                    <div className="w-8 items-stretch">
                      <BsCircleFill size={10} style={{ color: "#d7d6d6" }} />
                      <div className="ml-1 h-full border-l-2 border-gray-300">
                        {" "}
                        &nbsp;
                      </div>
                    </div>
                    <div className="w-full h-full font-semibold font-open-sans">
                      {unpaidInvoiceList?.length - 2} more..
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full h-full flex">
                    <div className="w-8 items-stretch">
                      <BsCircleFill size={10} style={{ color: "#d7d6d6" }} />
                      <div className="ml-1 h-full border-l-2 border-gray-300">
                        {" "}
                        &nbsp;
                      </div>
                    </div>
                    <div className="w-full h-full font-semibold font-open-sans">
                      Hide
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
