import React, { useMemo, useRef } from "react";
import { BiCopy, BiPaste } from "react-icons/bi";
import { GrAttachment } from "react-icons/gr";
import "react-loading-skeleton/dist/skeleton.css";
import {
  dateFormatter,
  timeFormatter,
} from "../../../../shared/userPreferredDateTime";
import "../../../../assets/styles/Inbox.css";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { AiFillCheckCircle } from "react-icons/ai";

const TableUnsavedWebEmailList = (props) => {
  //GS MMA 2497
  //Memoize props reduce rerendering
  useMemo(() => props, [props]);

  var moment = require("moment");
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 20,
    })
  );
  return (
    <div
      style={{
        position: "relative",
        height: props.rowVirtualizer.getTotalSize(),
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          transform: `translateY(${props?.virtualRows[0]?.start || 0}px)`,
        }}
      ></div>

      {props.virtualRows.map((virtualRow) => {
        const row = props.rows[virtualRow.index];
        const item = row.original;
        const index = row.index;

        return (
          <tr
            id={"desktopUnsaved" + row.id}
            ref={(ref) => virtualRow.measureElement(ref)}
            data-index={virtualRow.index}
            style={{
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
              background: props.saveLoading ? "#f3f4f6" : "white",
            }}
            className={
              "absolute top-0 left-0 w-full border-gray-200 border-b border-r " +
              (props.lastCounter === index + 1 ? "tr-child" : "")
            }
            key={virtualRow.key}
          >
            {/* LEFT SIDE OF SCREEN */}
            <td
              className={
                `w-1/12 py-5 pr-2 text-center align-top cursor-pointer ` +
                `${props.selectedEmail === item.id ? "bg-gray-200" : ""} ` +
                `${
                  props.prevSelectedEmails.includes(item.id)
                    ? "bg-gray-100"
                    : ""
                } ` +
                `${
                  props.copyId === item.id
                    ? "border-blue-500 bg-blue-100"
                    : props.pastedIds.includes(item.id)
                    ? "border-green-500 bg-green-100"
                    : "border-gray-200 bg-gray-100"
                }` +
                `${item.clientMatters?.items?.length > 0 ? "" : "pb-7"}`
              }
              onClick={() => {
                props.handleRowClick(item.id, false, item, index);
                props.detailsDivRef?.current?.scrollTo({
                  top: 0,
                  behavior: "instant",
                });
              }}
            >
              <div className="flex w-full">
                <div className="w-10 flex justify-center items-center">
                  <input
                    disabled={props.saveLoading ? true : false}
                    key={item.id}
                    className={props.saveLoading ? "" : "cursor-pointer"}
                    onChange={(e) =>
                      props.handleSelectItem(
                        e,
                        item.clientMatters?.items?.length,
                        item.priority,
                        item.notes
                      )
                    }
                    type="checkbox"
                    value={item.id}
                    id={item.id}
                    checked={props.selectedUnsavedItems.includes(item.id)}
                  />
                </div>
                <div className="w-full grow">
                  <div className="flex">
                    <div
                      className={
                        `w-full mr-2 text-left text-sm pb-0.5 ` +
                        `${props.selectedEmail === item.id ? "font-medium" : ""}
                      `
                      }
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <props.Highlight
                        text={item?.from?.split("<")[0]}
                        highlight={props.searchGmail}
                      />
                    </div>
                    <div className="mr-2 flex flex-none flex-row justify-end">
                      <div>
                        {(props.selectedTaskList.includes(item?.id) ||
                          !item?.taskList?.id == null ||
                          !item?.taskList?.id == "") && (
                          <AiFillCheckCircle
                            size={16}
                            className="text-green-500"
                          />
                        )}
                      </div>
                      <div>
                        {item?.attachments?.items?.some(
                          (attachment) =>
                            !attachment.details.includes("EMAIL_PDF_VERSION")
                        ) ? (
                          <GrAttachment size={14} className="fill-gray-300" />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="w-18 flex-none content-end text-right text-xs">
                        {moment(item?.internalDate, "x")
                          .local()
                          .isSame(props.currentDate, "day")
                          ? timeFormatter(new Date(item?.date))
                          : dateFormatter(new Date(item?.date))}
                      </div>
                    </div>
                  </div>
                  <div
                    className="grid grid-cols-2 w-full"
                    style={{
                      gridTemplateColumns: "1fr 35px",
                    }}
                  >
                    <div
                      className="w-auto inline-block max-w-full rounded-sm text-left text-xs font-light overflow-hidden"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <props.Highlight
                        text={
                          item?.snippet !== "" ? item?.snippet : item?.subject
                        }
                        highlight={props.searchGmail}
                      />
                    </div>
                    <div className="flex justify-end mr-2">
                      {props.showCopy === true ? (
                        <>
                          {item.clientMatters?.items?.length > 0 ? (
                            <BiCopy
                              size={19}
                              className="font-bold text-gray-500"
                              onClick={() => {
                                props.autoAdjustAllRow();
                                props.handleCopy(
                                  item.id,
                                  item.clientMatters,
                                  item
                                );
                              }}
                            />
                          ) : (
                            <div className="h-4 mt-0.5"></div>
                          )}
                        </>
                      ) : (
                        <>
                          {props.copyId === item.id &&
                          item.clientMatters?.items?.length > 0 ? (
                            <div className="h-4 mt-0.5"></div>
                          ) : (
                            <>
                              {props.pastedIds.includes(item.id) ? (
                                <BiPaste
                                  size={18}
                                  className={`font-bold ${
                                    props.loadLabels
                                      ? "text-gray-100"
                                      : "text-gray-500"
                                  }`}
                                  onClick={() => {
                                    props.handlePaste(item.id, index);
                                  }}
                                  disabled={props.loadLabels ? true : false}
                                />
                              ) : (
                                <BiPaste
                                  size={18}
                                  className="font-bold text-gray-500"
                                  onClick={() => {
                                    props.handlePaste(item.id, index);
                                  }}
                                  disabled={props.loadLabels ? true : false}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            {/* END OF LEFT SIDE OF SCREEN */}
          </tr>
        );
      })}

      {/* {props.paddingBottom > 0 && (
        <tr>
          <td style={{
            background: "blue",
            height: `${props.paddingBottom}px`
            }} />
        </tr>
      )} */}
    </div>
    // <AutoSizer>
    //   {({ width, height }) => (
    //     <List
    //       className="inboxScroll overflow-y-scroll"
    //       ref={props.bindList}
    //       width={width}
    //       height={height}
    //       rowHeight={
    //         props.unSavedEmails.length === 1 ? "100" : cache.current.rowHeight
    //       }
    //       deferredMeasurementCache={cache.current}
    //       rowCount={props.unSavedEmails.length}
    //       rowRenderer={({ key, index, style, parent }) => {
    //         const item = props.unSavedEmails[index];
    //         const contacts = props.clientMatterState;
    //         let emailOnly = item?.from?.substring(
    //           item.from.indexOf("<") + 1,
    //           item.from.lastIndexOf(">")
    //         );
    //         let contactFound = false;
    //         let foundIndex = null;
    //         contacts.map((item, i) => {
    //           if (contacts[i].email.includes(emailOnly)) {
    //             contactFound = true;
    //             foundIndex = i;
    //             i = contacts.length;
    //           }
    //         });

    //         return (
    //           <div className="h-screen">
    //             <CellMeasurer
    //               key={item.id + "-" + index}
    //               cache={cache.current}
    //               parent={parent}
    //               rowIndex={index}
    //               columnIndex={0}
    //             >
    //               <tr
    //                 style={{
    //                   ...style,
    //                   background: props.saveLoading ? "#f3f4f6" : "white",
    //                 }}
    //                 className={"w-full h-full border-gray-200 border-b border-r" +
    //                   (props.lastCounter === index + 1 ? "tr-child" : "")
    //                 }
    //                 key={key}
    //               >
    //                 {/* LEFT SIDE OF SCREEN */}
    //                 <td
    //                   className={
    //                     `checkBox h-full w-2/5 py-5 pr-2 text-center align-top cursor-pointer ` +
    //                     `${
    //                       props.selectedEmail === item.id ? "bg-gray-200" : ""
    //                     } ` +
    //                     `${
    //                       props.prevSelectedEmails.includes(item.id)
    //                         ? "bg-gray-100"
    //                         : ""
    //                     } ` +
    //                     `${
    //                       props.copyId === item.id
    //                         ? "border-blue-500 bg-blue-100"
    //                         : props.pastedIds.includes(item.id)
    //                         ? "border-green-500 bg-green-100"
    //                         : "border-gray-200 bg-gray-100"
    //                     }` +
    //                     `${item.clientMatters?.items?.length > 0 ? "" : "pb-7"}`
    //                   }
    //                   onClick={() => {
    //                     props.handleRowClick(item.id, false, item, index);
    //                     props.detailsDivRef?.current?.scrollTo({
    //                       top: 0,
    //                       behavior: "instant",
    //                     });
    //                   }}
    //                 >
    //                   <div className="flex w-full">
    //                     <div className="w-10 flex justify-center items-center">
    //                       <input
    //                         disabled={props.saveLoading ? true : false}
    //                         key={item.id}
    //                         className={
    //                           props.saveLoading ? "" : "cursor-pointer"
    //                         }
    //                         onChange={(e) =>
    //                           props.handleSelectItem(
    //                             e,
    //                             item.clientMatters?.items?.length,
    //                             item.priority,
    //                             item.notes
    //                           )
    //                         }
    //                         type="checkbox"
    //                         value={item.id}
    //                         id={item.id}
    //                         checked={props.selectedUnsavedItems.includes(
    //                           item.id
    //                         )}
    //                       />
    //                     </div>
    //                     <div
    //                       onClick={() => {
    //                         props.handleEmailOpen(true);
    //                       }}
    //                       className="w-full grow"
    //                     >
    //                       <div className="flex">
    //                         <div className={`w-full text-left text-sm pb-0.5 `
    //                         + `${
    //                           props.selectedEmail === item.id ? "font-medium" : ""
    //                         }
    //                         `}>
    //                           {item?.from?.split("<")[0]}
    //                         </div>
    //                         {/* <div className="w-full text-left text-xs font-normal">
    //                           {item?.subject}
    //                         </div> */}
    //                         <div className="mr-2 flex flex-none flex-row justify-end">
    //                           <div>
    //                             {(props.selectedTaskList.includes(item?.id) ||
    //                               !item?.taskList?.id == null ||
    //                               !item?.taskList?.id == "") && (
    //                               <AiFillCheckCircle
    //                                 size={16}
    //                                 className="text-green-500"
    //                               />
    //                             )}
    //                           </div>
    //                           <div>
    //                             {item?.attachments?.items?.some(
    //                               (attachment) =>
    //                                 !attachment.details.includes(
    //                                   "EMAIL_PDF_VERSION"
    //                                 )
    //                             ) ? (
    //                               <GrAttachment
    //                                 size={14}
    //                                 className="fill-gray-300"
    //                               />
    //                             ) : (
    //                               <></>
    //                             )}
    //                           </div>
    //                           <div className="w-18 flex-none content-end text-right text-xs">
    //                             {moment(item?.internalDate, "x")
    //                               .local()
    //                               .isSame(props.currentDate, "day")
    //                               ? timeFormatter(new Date(item?.date))
    //                               : dateFormatter(new Date(item?.date))}
    //                           </div>
    //                         </div>
    //                       </div>
    //                       <div className="grid grid-cols-2 w-full"
    //                         style={{
    //                           gridTemplateColumns: "1fr 35px"
    //                         }}
    //                       >

    //                         <div
    //                           className="w-auto whitespace-auto inline-block max-w-full whitespace-normal rounded-sm text-left text-xs font-light"
    //                           dangerouslySetInnerHTML={{
    //                             __html: item?.snippet !== ""
    //                               ? item?.snippet
    //                               : item?.subject
    //                             // __html: `${item?.snippet
    //                             //   ?.split(" ")
    //                             //   .slice(0, 10)
    //                             //   .join(" ")}${
    //                             //   item?.snippet?.split(" ").length > 10
    //                             //     ? "..."
    //                             //     : ""
    //                             // }`,
    //                           }}
    //                           style={{
    //                             whiteSpace: "nowrap",
    //                             overflow: "hidden",
    //                             textOverflow: "ellipsis"
    //                           }}
    //                         ></div>
    //                         <div className="flex justify-end mr-2">
    //                         {props.showCopy === true ? (
    //                             <>
    //                               {item.clientMatters?.items?.length > 0 ? (
    //                                   <BiCopy
    //                                     size={19}
    //                                     className="font-bold text-gray-500"
    //                                     onClick={() => {
    //                                       props.autoAdjustAllRow();
    //                                       props.handleCopy(
    //                                         item.id,
    //                                         item.clientMatters,
    //                                         item
    //                                       );
    //                                       //props.handleCopyToClipboard(item);
    //                                     }}
    //                                   />
    //                               ) : (
    //                                 <div className="h-4 mt-0.5"></div>
    //                               )}
    //                             </>
    //                           ) : (
    //                             <>
    //                               {props.copyId === item.id &&
    //                               item.clientMatters?.items?.length > 0 ? (
    //                                 <div className="h-4 mt-0.5"></div>
    //                               ) : (
    //                                 <>
    //                                   {props.pastedIds.includes(item.id) ? (
    //                                     <BiPaste
    //                                       size={18}
    //                                       className={`font-bold ${
    //                                         props.loadLabels
    //                                           ? "text-gray-100"
    //                                           : "text-gray-500"
    //                                       }`}
    //                                       onClick={() => {
    //                                         props.handlePaste(item.id, index);
    //                                       }}
    //                                       disabled={
    //                                         props.loadLabels ? true : false
    //                                       }
    //                                     />
    //                                   ) : (
    //                                     <BiPaste
    //                                       size={18}
    //                                       className="font-bold text-gray-500"
    //                                       onClick={() => {
    //                                         props.handlePaste(item.id, index);
    //                                       }}
    //                                       disabled={
    //                                         props.loadLabels ? true : false
    //                                       }
    //                                     />
    //                                   )}
    //                                 </>
    //                               )}
    //                             </>
    //                           )}
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </td>
    //                 {/* END OF LEFT SIDE OF SCREEN */}
    //               </tr>
    //             </CellMeasurer>
    //           </div>
    //         );
    //       }}
    //     />
    //   )}
    // </AutoSizer>
  );
};

export default TableUnsavedWebEmailList;
