import { useState } from "react";
import { Auth } from "aws-amplify";
import "../../assets/styles/Auth.css";
import Button from "../../shared/Button";
import { MdCheck, MdOutlineClose } from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ProgressNotification from "./progress-notification";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import { FaTimes } from "react-icons/fa";

const Modal = ({ showModal, setShowModal }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-full">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={() => setShowModal(false)}
        >
          <FaTimes size={24} />
        </button>
        <p className="font-semibold mb-4 text-center">Effective Date: June 2024</p>
        <h2 className="text-3xl font-semibold mb-4 text-center">Privacy Policy</h2>
        <div className="text-sm text-gray-700 space-y-2">
          <div className="overflow-y-auto max-h-96"> {/* Adjust max-h-* as needed */}
            <p className="font-semibold">1. Introduction Welcome to Homebuilding App.</p>
            <p>We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our software and services.</p>
            <p className="font-semibold">2. Information We Collect</p>
            <ul className="ml-4 list-disc list-inside">
              <li><span className="font-semibold">Personal Information:</span> We collect personal information that you provide to us when you register for an account, such as your name, email address, and payment information.</li>
              <li><span className="font-semibold">Usage Data:</span> We automatically collect information about your interactions with our software, including but not limited to IP addresses, browser type, operating system, access times, and the pages you have viewed directly before and after accessing our software.</li>
              <li><span className="font-semibold">Email Data:</span> We collect and process the content of emails that you receive, including attachments and any metadata associated with those emails.</li>
            </ul>
            <p className="font-semibold">3. How We Use Your Information</p>
            <ul className="ml-4 list-disc list-inside">
              <li><span className="font-semibold">To Provide and Manage Services:</span> To deliver our email management services, including summarizing email content, categorizing emails, and managing tasks.</li>
              <li><span className="font-semibold">Improvement and Development:</span> To improve our software’s functionality, user experience, and to develop new features.</li>
              <li><span className="font-semibold">Communication:</span> To send you service-related notifications and updates, and to respond to your inquiries.</li>
              <li><span className="font-semibold">Security:</span> To protect against fraud, unauthorized transactions, claims, and other liabilities, and to manage risk exposure, including by identifying potential hackers and other unauthorized users.</li>
              <li><span className="font-semibold">Third-Party Services:</span> We may use third-party applications to help us with data collection, tracking, and analysis to enhance our services.</li>
            </ul>
            <p className="font-semibold">4. How We Share Your Information</p>
            <ul className="ml-4 list-disc list-inside">
              <li><span className="font-semibold">Service Providers:</span> We may share your information with third-party service providers that perform services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.</li>
              <li><span className="font-semibold">Legal Requirements:</span> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
              <li><span className="font-semibold">Business Transfers:</span> In the event of a merger, acquisition, reorganization, sale of assets, or bankruptcy, we may transfer or share your personal data as part of such a transaction.</li>
            </ul>
            <p className="font-semibold">5. Data Security</p>
            <p>We implement and maintain reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. Our software uses AWS best security practices, including:</p>
            <ul className="ml-4 list-disc list-inside">
              <li><span className="font-semibold">Data Encryption:</span> All data is encrypted in transit and at rest using AES-256 encryption.</li>
              <li><span className="font-semibold">Access Control:</span> Strict access controls are enforced using AWS Identity and Access Management (IAM) policies.</li>
              <li><span className="font-semibold">Monitoring and Logging:</span> Continuous monitoring and logging of system activity using AWS CloudTrail and Amazon CloudWatch.</li>
              <li><span className="font-semibold">Regular Audits:</span> Regular security audits and vulnerability assessments are conducted to ensure compliance with best practices.</li>
              <li><span className="font-semibold">Backup and Recovery:</span> Regular data backups and disaster recovery plans are in place to prevent data loss.</li>
            </ul>
            <p className="font-semibold">6. Your Data Protection Rights</p>
            <ul className="ml-4 list-disc list-inside">
              <li><span className="font-semibold">Access:</span> The right to request copies of your personal data.</li>
              <li><span className="font-semibold">Rectification:</span> The right to request that we correct any information you believe is inaccurate.</li>
              <li><span className="font-semibold">Erasure:</span> The right to request that we erase your personal data, under certain conditions.</li>
              <li><span className="font-semibold">Restriction:</span> The right to request that we restrict the processing of your personal data, under certain conditions.</li>
              <li><span className="font-semibold">Object to Processing:</span> The right to object to our processing of your personal data, under certain conditions.</li>
              <li><span className="font-semibold">Data Portability:</span> The right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
              <li><span className="font-semibold">Withdraw Consent:</span> The right to withdraw your consent at any time where we rely on your consent to process your personal data.</li>
            </ul>
            <p>To exercise any of these rights, please contact us at [Contact Information]. We will respond to your request within a reasonable timeframe and in accordance with applicable laws.</p>
            <p className="font-semibold">7. International Data Transfers</p>
            <p>We may transfer your personal data to countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country. However, we take steps to ensure that such transfers comply with applicable data protection laws and that your personal data remains protected.</p>
            <p className="font-semibold">8. Data Retention</p>
            <p>We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy, or as required by law. When we no longer need to use your personal data, we will securely delete or anonymize it.</p>
            <p className="font-semibold">9. Children’s Privacy</p>
            <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal data from children under 13. If we learn that we have collected personal data from a child under 13, we will delete that information as quickly as possible.</p>
            <p className="font-semibold">10. Changes to This Privacy Policy</p>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on our website and updating the “Effective Date” at the top of this policy.</p>
            <p className="font-semibold">11. Contact Us</p>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            <p>Email: john@homebuilding.app</p>
            <p>Address: Sydney</p>
            <p>By using our services, you acknowledge that you have read and understood this Privacy Policy.</p>
            <p className="font-semibold">12. Third-Party Services</p>
            <p>We use third-party services to assist with various aspects of our operations, including data collection, tracking, and analysis. These third parties may have access to your data as necessary to perform their functions but are not permitted to use it for other purposes.</p>
            <p className="font-semibold">13. User Consent</p>
            <p>We obtain user consent for data collection and processing where required by law. By using our services, you consent to the collection and use of your information as described in this Privacy Policy.</p>
            <p className="font-semibold">14. Compliance and Risk Management</p>
            <p>We are committed to complying with all applicable data protection regulations and managing risks associated with data processing. We conduct regular audits and reviews of our data protection practices to ensure ongoing compliance.</p>
            <p className="font-semibold">15. User Responsibilities</p>
            <p>Users are responsible for maintaining the confidentiality of their account information and for any activities that occur under their account. Please notify us immediately of any unauthorized use of your account or other security breaches.</p>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={() => setShowModal(false)} variant="default">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};




export default function SignUp({
  user,
  setUser,
  AuthState,
  setNextAuthState,
  notifMessage,
  notifShow,
  notifVariant,
  setNotifMessage,
  setNotifShow,
  setNotifVariant,
  loading,
  setLoading,
}) {
  const [customCompanyName, setCustomCompanyName] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordReq, setPasswordReq] = useState({
    minimum: false,
    uppercase: false,
    lowercase: false,
    numeral: false,
    symbol: false,
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { width, height } = useWindowDimensions();
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function handlePasswordVisibility(event) {
    event.preventDefault();
    setPasswordVisibility(!passwordVisibility);
  }

  async function handleSignUp(event) {
    event.preventDefault();

    if (!isPrivacyPolicyAccepted) {
      setNotifMessage("You must accept the Privacy Policy to create an account.");
      setNotifVariant("error");
      setNotifShow(true);
      window.scrollTo(0, 0);
      return;
    }

    setLoading(true);
    setNotifShow(false);
    try {
      const response = await Auth.signUp({
        username: event.target.email.value,
        password: event.target.password.value,
        attributes: {
          given_name: event.target.given_name.value,
          family_name: event.target.family_name.value,
          email: event.target.email.value,
          "custom:company_name": `${event.target.given_name.value}'s Portal`,
        },
        autoSignIn: {
          enabled: true,
          clientMetaData: {
            sourceAuthState: "ConfirmSignUp",
          },
        },
      })
        .then((response) => {
          setLoading(false);
          console.log("SignUp", response.user);
          setUser(response.user);
          window.scrollTo(0, 0);
          setNextAuthState(AuthState.ConfirmSignUp);
        })
        .catch((error) => {
          console.log("error signing up:", error);

          const customErrorMessage = error?.message
            .replace("PreSignUp failed with error ", "")
            .replace(/\.*$/, ".")
            .trim();
          setLoading(false);
          setNotifMessage(customErrorMessage);
          setNotifVariant("error");
          setNotifShow(true);
          window.scrollTo(0, 0);
        });
    } catch (error) {
      console.error(error);
    }
  }

  function clean_up(v) {
    let c = v.replace(/\s+/g, " ");
    if (c === " ") {
      c = "";
    }

    return c;
  }

  const minimumRegex = new RegExp("^(?=.{8,})");
  const lowerRegex = new RegExp("^(?=.*[a-z])");
  const upperRegex = new RegExp("^(?=.*[A-Z])");
  const numeralRegex = new RegExp("^(?=.*[0-9])");
  const symbolRegex = new RegExp("[^A-Za-z0-9]");

  function handlePasswordRequirements(event) {
    setPassword(event.target.value);
    let minimum = false;
    let lowercase = false;
    let uppercase = false;
    let numeral = false;
    let symbol = false;

    if (minimumRegex.test(event.target.value)) {
      console.log("MINIMUM TRUE!");
      minimum = true;
    }

    if (lowerRegex.test(event.target.value)) {
      lowercase = true;
    }

    if (upperRegex.test(event.target.value)) {
      uppercase = true;
    }

    if (numeralRegex.test(event.target.value)) {
      numeral = true;
    }

    if (symbolRegex.test(event.target.value)) {
      symbol = true;
    }

    setPasswordReq({
      minimum: minimum,
      lowercase: lowercase,
      uppercase: uppercase,
      numeral: numeral,
      symbol: symbol,
    });
  }

  function handleCheckmark(req) {
    return req === true ? (
      <MdCheck className="text-secondary w-3 flex-none" />
    ) : (
      <MdOutlineClose className="text-danger w-3 flex-none" />
    );
  }

  return (
    <>
      <div
        className="flex flex-col items-center md:items-start z-40 py-14 lg:px-10 fade-in-fwd w-full"
      >
        <div className="flex flex-col gap-3 w-full">
          <h1 className="font-semibold text-gray-900 text-2xl md:text-3xl md:mx-0 mx-auto">
            Sign up
          </h1>
          <p className="font-normal text-base text-gray-500 md:mx-0 mx-auto">
            Start your 30-day free trial.
          </p>
        </div>
        {/* Input Fields */}
        <div className="mt-8 w-full">
          <form onSubmit={(event) => handleSignUp(event)}>
            <div
              className="flex flex-col gap-4 md:mx-0 mx-auto"
              style={{ maxWidth: "22.5rem" }}
            >
              <ProgressNotification
                className=""
                variant={notifVariant}
                message={notifMessage}
                show={notifShow}
              />
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="signUp-given_name"
                  className="text-sm font-normal text-gray-700"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  id="signUp-given_name"
                  key="signUp-given_name"
                  name="given_name"
                  value={firstName}
                  className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                  onChange={(event) => {
                    let i = clean_up(event.target.value);
                    setFirstName(i);
                  }}
                  required
                  placeholder="Enter your first name"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="signUp-family_name"
                  className="text-sm font-normal text-gray-700"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  id="signUp-family_name"
                  key="signUp-family_name"
                  name="family_name"
                  value={lastName}
                  className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                  onChange={(event) => {
                    let i = clean_up(event.target.value);
                    setLastName(i);
                  }}
                  required
                  placeholder="Enter your last name"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="signUp-email"
                  className="text-sm font-normal text-gray-700"
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="signUp-email"
                  key="signUp-email"
                  name="email"
                  className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                  required
                  autoComplete="new-username"
                  placeholder="Enter your email"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="signUp-password"
                  className="text-sm font-normal text-gray-700"
                >
                  Password *
                </label>
                <div className="relative flex flex-col">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    id="signUp-password"
                    key="signUp-password"
                    name="password"
                    value={password}
                    onChange={(event) => handlePasswordRequirements(event)}
                    className="px-3.5 py-2.5 border border-gray-300 outline-none rounded-lg text-base text-gray-500 font-normal placeholder-gray-500 focus:outline-none focus:ring-2 ring-hbBlue"
                    required
                    autoComplete="new-password"
                    placeholder="Create a password"
                  />
                  <button
                    className="absolute right-6 top-1/2 transform -translate-y-1/2"
                    type="button"
                    onClick={(e) => handlePasswordVisibility(e)}
                  >
                    {passwordVisibility ? (
                      <FaEyeSlash className="text-2xl text-hbGray" />
                    ) : (
                      <FaEye className="text-2xl text-hbGray" />
                    )}
                  </button>
                </div>
                <div className="mt-4">
                  <ul className="ml-2">
                    <li className="text-xs flex items-center gap-2">
                      {handleCheckmark(passwordReq.minimum)}
                      Minimum of 8 characters
                    </li>
                    <li className="text-xs tracking-tight flex items-center gap-2">
                      {handleCheckmark(passwordReq.lowercase)}
                      Must include at least 1 lowercase character (a-z)
                    </li>
                    <li className="text-xs tracking-tight flex items-center gap-2">
                      {handleCheckmark(passwordReq.uppercase)}
                      Must include at least 1 uppercase character (A-Z)
                    </li>
                    <li className="text-xs tracking-tight flex items-center gap-2">
                      {handleCheckmark(passwordReq.numeral)}
                      Must include at least 1 numeral (0-9)
                    </li>
                    <li className="text-xs flex items-center gap-2">
                      {handleCheckmark(passwordReq.symbol)}
                      Must include at least 1 symbol
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="privacyPolicy"
                  name="privacyPolicy"
                  checked={isPrivacyPolicyAccepted}
                  onChange={(event) => setIsPrivacyPolicyAccepted(event.target.checked)}
                  required
                />
                <label
                  htmlFor="privacyPolicy"
                  className="text-sm font-normal text-gray-700"
                >
                  I have read and agree to the <span onClick={() => setShowModal(true)} className="text-blue-600 cursor-pointer">Privacy Policy</span>
                </label>
              </div>
              <div className="flex flex-col gap-8 items-center">
                <div className="flex justify-center w-full">
                  <Button
                    type="submit"
                    variant="secondary"
                    block={"full"}
                    size={"xl"}
                    className="rounded-lg w-full"
                  >
                    <span className="relative text-base font-normal">
                      {loading ? (
                        <AiOutlineLoading className="absolute rotate-center -left-6 top-1" />
                      ) : null}
                      Create Account
                    </span>
                  </Button>
                </div>
                <div className="flex items-center">
                  <span className="text-base font-normal text-hbGray">
                    Already have an account?
                  </span>
                  <button
                    className="ml-1 text-base font-semibold text-green-800"
                    type="button"
                    onClick={() => {
                      setNotifShow(false);
                      window.location.href = "/";
                    }}
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
