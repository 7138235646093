/* External Components/Packages */
import React, {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { getBgList } from "../../shared/graphql/Briefs/queries";
import ScrollToTop from "react-scroll-to-top";
import { BiArrowToTop, BiMenu } from "react-icons/bi";
import { dateTimeNoSecondsFormatter } from "../../shared/userPreferredDateTime";
import { API, Auth } from "aws-amplify";
import { ListUsers, GetUserCM } from "../../shared/graphql/queries";
import CreatableSelect from "react-select/creatable";
import isMSdoc from "../../shared/msFileTypeChecker";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { obaDomains, hbaDomains } from "../../constants/AppDomains";
import "../../assets/styles/BoxPulse.css";
import { PulsingBorderDiv } from "../../shared/PulsingBorderDiv";

import {
  WindowScroller,
  AutoSizer,
  defaultTableHeaderRenderer,
  defaultTableRowRenderer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  List,
  Table,
} from "react-virtualized";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Storage } from "aws-amplify";

/* Shared Components */
import ToastNotification from "../toast-notification";
import AccessControl from "../../shared/accessControl";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import Button from "../../shared/Button";
import {
  timezoneFormatter,
  datePickerDateFormatter,
  dateFormatter,
} from "../../shared/userPreferredDateTime";

import ProgressBar from "@ramonak/react-progress-bar";
import RightPanel from "../hba-dashboard//right-panel.jsx";
import contractsIcon from "../../assets/images/contracts-icon-blue.png";

/* Custom Functional Components */
// import { ClientMatters } from "./matters-list";
// import DeleteMatterModal from "./delete-matters-modal";
// import CreateMatterModal from "./create-matter-modal";
// import { Welcome } from "./welcome";
// import { clientMatterReducers } from "./reducers";
// import { initialState } from "./initialState";
// import {
// 	addClientMatter,
// 	deleteMatterClient,
// 	getMatterList,
// 	searchMatterClient,
// 	isDupe,
// } from "./actions";

/* Assets & Icons */
import * as FaIcons from "react-icons/fa";
import * as LiaIcons from "react-icons/lia";
import { CgMathPlus } from "react-icons/cg";
import { LuUpload } from "react-icons/lu";
import {
  BsChevronUp,
  BsFileEarmarkPdfFill,
  BsFillCheckSquareFill,
  BsFillFileEarmarkExcelFill,
  BsFillFileEarmarkFill,
  BsFillFileEarmarkFontFill,
  BsFillFileEarmarkImageFill,
  BsFillFileEarmarkMusicFill,
  BsFillFileEarmarkPlayFill,
  BsFillFileEarmarkPptFill,
  BsFillFileEarmarkWordFill,
} from "react-icons/bs";

import { FaChevronRight, FaChevronDown } from "react-icons/fa";

import imgDocs from "../../assets/images/docs.svg";
import dashboardGradient from "../../assets/images/dashboard-gradient.png";
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Mobile.css";
import { AppRoutes } from "../../constants/AppRoutes";
import HbaFileUpload from "../hba-dashboard/file-upload";
import { AiOutlineDownload } from "react-icons/ai";

import ProgressPhotos from "../hba-dashboard/progress-photos.jsx";
import ProgressOfWork from "../hba-dashboard/progress-of-work.jsx";
import Invoices from "../hba-dashboard/invoices.jsx";
import Contracts from "../hba-dashboard/contracts.jsx";
import Variations from "../hba-dashboard/variations.jsx";
import ProvisionalSum from "../hba-dashboard/provisional-sum.jsx";
import Budget from "../hba-dashboard/budget.jsx";
import ProjectLocation from "../hba-dashboard/project-location";
import GetStartedTile from "../hba-dashboard/get-started-tile.jsx";
import SiteDiary from "../hba-dashboard/sitediary.jsx";
import TaskList from "../hba-dashboard/tasklist.jsx";
import EmailInteg from "../hba-dashboard/email-integration-tile.jsx";

export const MatterContext = createContext();

export default function OBADashboard() {
  let history = useHistory();
  //const matter_id = useParams();

  const propertyPhotoRef = useRef(null);
  const propertyDetailsRef = useRef(null);
  const completionDateRef = useRef(null);
  const invoicesRef = useRef(null);
  const variationsRef = useRef(null);
  const provisionalSumRef = useRef(null);
  const budgetRef = useRef(null);
  const uploadContractsRef = useRef(null);
  const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");
  const [userInfo, setuserInfo] = useState(null);
  const [defaultList, setDefaultList] = useState(null);
  var moment = require("moment");
  const [background, setBackground] = useState([]);
  const [showBackgrounds, setShowBackgrounds] = useState(false);
  const [showBriefId, setShowBriefId] = useState(null);
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);

  const [hideChecklist, setHideChecklist] = useState(true);
  var clientMatterId = "";
  const [matterId, setMatterId] = useState("");
  const [matter_id, setMatter_Id] = useState("");
  const [contractBrief, setContractBrief] = useState({});
  const [progressPhotoBrief, setProgressPhotoBrief] = useState({});
  const [projectLocationBrief, setProjectLocationBrief] = useState(null);
  const [projectLocationPhoto, setProjectLocationPhoto] = useState(null);
  const [briefs, setBriefs] = useState(null);
  const [BriefsCopy, setBriefsCopy] = useState();

  const [numCompletedActions, setNumCompletedActions] = useState(0);
  const [buildInfo, setBuildInfo] = useState(null);
  const [contractInfo, setContractInfo] = useState(null);

  const [contractTC, setContractTC] = useState(null);
  const [archiDrawings, setArchiDrawings] = useState(null);
  const [engDrawings, setEngDrawings] = useState(null);
  const [specifications, setSpecifications] = useState(null);
  const [buildInsurance, setBuildInsurance] = useState(null);
  const [tilePulse, setTilePlulse] = useState(null);

  // Get Started States
  const [getStartedProgress, setGetStartedProgress] = useState({
    builderDetails: false,
    contractDetails: false,
    propertyPhoto: false,
    propertyDetails: false,
    completionDate: false,
    invoices: false,
    variations: false,
    // provisionalSum: false,
    contracts: false,
    // budget: false
  });

  useEffect(() => {
    console.log("Get Started Progress", getStartedProgress);
  }, [getStartedProgress]);

  // File Upload States
  const [showUploadModal, setShowUploadModal] = useState(null);
  const [dropTrigger, setDropTrigger] = useState([]);
  const [directDrop, setDirectDrop] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [isToastError, setIsToastError] = useState(false);
  const [SelectedIndex, setSelectedIndex] = useState(0);
  const [fileTypeCollection, setFileTypeCollection] = useState([]);

  const [logoURL, setLogoURL] = useState(null);
  const [builderLogo, setBuilderLogo] = useState(null);

  //Progress Photos States
  const [selectedFiles, _setSelectedFiles] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [datePicked, setDatePicked] = useState(new Date());
  const [description, setDescription] = useState("");
  const [progressPhotosDetails, setProgressPhotosDetails] = useState([]);
  const [progressPhotoBriefID, setProgressPhotoBriefID] = useState(null);
  const [photos, setPhotos] = useState([]);

  const [isInitDone, setIsInitDone] = useState(false);

  var activeUserType = localStorage.getItem("userType");
  const defaultColumnIds = [0, 1, 2, 3, 4];

  const fileList = [
    "Contract Terms And Conditions",
    "Architectural Drawings",
    "Engineer Drawings",
    "Specifications",
    "Builder's Insurance",
  ];

  const fileFormatIcons = {
    default: <BsFillFileEarmarkFill className="text-blue-400" />,
    txt: <BsFillFileEarmarkFontFill />,
    pdf: <BsFileEarmarkPdfFill className="text-red-600" />,
    docx: <BsFillFileEarmarkWordFill className="text-blue-500" />,
    "vnd.openxmlformats-officedocument.wordprocessingml.document": (
      <BsFillFileEarmarkWordFill className="text-blue-500" />
    ),
    xlsx: <BsFillFileEarmarkExcelFill className="text-green-500" />,
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
      <BsFillFileEarmarkExcelFill className="text-green-500" />
    ),
    pptx: <BsFillFileEarmarkPptFill className="text-orange-600" />,
    "vnd.openxmlformats-officedocument.presentationml.presentation": (
      <BsFillFileEarmarkPptFill className="text-orange-600" />
    ),
    csv: <FaIcons.FaFileCsv className="text-green-400" />,
    png: <BsFillFileEarmarkImageFill className="text-blue-700" />,
    jpg: <BsFillFileEarmarkImageFill className="text-orange-700" />,
    jpeg: <BsFillFileEarmarkImageFill className="text-orange-700" />,
    mp4: <BsFillFileEarmarkPlayFill className="text-blue-400" />,
    mov: <BsFillFileEarmarkPlayFill className="text-blue-400" />,
    mp3: <BsFillFileEarmarkMusicFill />,
  };

  //FORCE REDIRECTION TO HBA DASHBOARD
  const [redirectDashboard, setRedirectDashboard] = useState(false);

  // useEffect(() => {
  //   if (redirectDashboard === false) {
  //     if (obaDomains.includes(domain)) {
  //       console.log("Valid access"); //OBA
  //       setRedirectDashboard(true);
  //     } else if (hbaDomains.includes(domain)) {
  //       history.push(AppRoutes.HBADASHBOARD); //HBA
  //     } else {
  //       history.push(AppRoutes.DASHBOARD); //MMA
  //     }
  //   }
  // }, [redirectDashboard]);
  // RIGHT MODAL PROPERTIES
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [modalState, setModalState] = useState(null);
  const modalChoicesEnum = {
    BUILDER_DETAILS: "BUILDER_DETAILS",
    CONTRACT_DETAILS: "CONTRACT_DETAILS",
    PROGRESS_PHOTO: "PROGRESS_PHOTO",
  };

  var domain = window.location.hostname;

  useEffect(() => {
    var status = localStorage.getItem("onboardingProgress");
    console.log(status);
    console.log(defaultList);

    let buildersIdx;
    let contractsIdx;

    defaultList?.forEach((item, x) => {
      if (item.name === "Builder Details") {
        buildersIdx = x;
      } else if (item.name === "Contract Details") {
        contractsIdx = x;
      }
    });

    if (isInitDone == true && defaultList) {
      var contractDetailsOnboarding;
      var builderDetailsOnboarding;

      if (defaultList[contractsIdx]) {
        contractDetailsOnboarding =
          defaultList[contractsIdx].backgrounds.items.length == 0
            ? false
            : true;
      } else {
        contractDetailsOnboarding = true;
      }

      if (defaultList[buildersIdx]) {
        builderDetailsOnboarding =
          defaultList[buildersIdx].backgrounds.items.length == 0 ? false : true;
      } else {
        builderDetailsOnboarding = true;
      }
    }

    console.log(buildersIdx, builderDetailsOnboarding);
    console.log(contractsIdx, contractDetailsOnboarding);

    if (
      status == "false" ||
      contractDetailsOnboarding == false ||
      builderDetailsOnboarding == false
    ) {
      console.log("REDIRECT TO GETTING STARTED");
      history.push(AppRoutes.GETTINGSTARTED);
    }
  }, [isInitDone]);

  useEffect(() => {
    //force reload to rerender localStorage
    if (
      localStorage.getItem("onboardingProgress") == "true" &&
      localStorage.getItem("userProgress") == "true"
    ) {
      localStorage.setItem("userProgress", "false");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    console.log(datePicked);
  }, [datePicked]);

  useEffect(() => {
    if (redirectDashboard === false) {
      if (obaDomains.includes(domain)) {
        console.log("Valid access"); //OBA
        setRedirectDashboard(true);
      } else if (hbaDomains.includes(domain)) {
        history.push(AppRoutes.HBADASHBOARD); //HBA
      } else {
        history.push(AppRoutes.DASHBOARD); //MMA
      }
    }
  }, [redirectDashboard]);

  function countProgress(obj) {
    const progressCount = Object.keys(obj).reduce(
      (count, key) => (obj[key] === true ? count + 1 : count),
      0
    );
    //console.log("Progress Bar", progressCount);
    const totalProgress = Object.keys(obj).length;
    //console.log("Total Progress", totalProgress);
    const percentage = (progressCount / totalProgress) * 100;
    return percentage;
  }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function b64_to_utf8(str) {
    try {
      // Convert base64 to percent-encoded string
      const percentEncodedStr = atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("");

      // Decode percent-encoded string to utf-8
      return decodeURIComponent(percentEncodedStr);
    } catch (e) {
      console.error("Error decoding base64 string", e);
      return null;
    }
  }

  const matter_name = getParameterByName("matter_name");
  const client_name = getParameterByName("client_name");

  const backPage = getParameterByName("back_page");

  //QUERIES
  const qGetBackgroundFilesbyBriefID = `
	query getBackgroundFilesByBriefID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
		brief(id: $id) {
		id
		name
		backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
			items {
			id
			description
			date
			createdAt
			order
			files {
				items {
				id
				name
				details
				isGmailPDF
				isGmailAttachment
				s3ObjectKey
				order
				createdAt
				downloadURL
				}
			}
			}
			nextToken
		}
		}
	}`;

  const mUpdateBackgroundDesc = `
		mutation updateBackground($id: ID, $description: String) {
		backgroundUpdate(id: $id, description: $description) {
			id
			description
		}
		}
	`;

  const mUpdateBackgroundDate = `
		mutation updateBackground($id: ID, $date: AWSDateTime) {
		backgroundUpdate(id: $id, date: $date) {
			id
			date
		}
		}
	`;

  const listClientMatters = `
        query listClientMatters($companyId: String) {
        company(id: $companyId) {
            clientMatters (sortOrder: CREATED_DESC, isDeleted:false) {
            items {
                id
                createdAt
                updatedAt
                lastOpenedAt
                description
                matterNumber
                client {
                id
                name
                }
                matter {
                id
                name
                }
            }
            }
        }
        }
        `;

  const listClient = `
        query listClient($companyId: String) {
          company(id: $companyId) {
            clients {
              items {
                id
                name
              }
            }
          }
        }
        `;

  const listMatter = `
        query listMatter($companyId: String) {
          company(id: $companyId) {
            matters {
              items {
                id
                name
              }
            }
          }
        }
        `;

  const BRIEFS_QUERY = `
        query getBriefsByClientMatter($id: ID) {
        clientMatter(id: $id) {
            briefs{
                items {
                    id
                    name
                    userTypeAccess
                    date
                    order
                    createdAt
                    createdBy {
                        id
                        firstName
                        lastName
                    }
                    updatedAt
                    updatedBy {
                        id
                        firstName
                        lastName
                    }
                    backgrounds(limit: 50, sortOrder: ORDER_ASC) {
                        items {
                            id
                            description
                            date
                            dynamicData
                            files{
                              items{
                                id
                                name
                              }
                            }
                        }
                    }
                    hasCommentAccess
                    columnIds
                    assistantId
                }
            }
        }
        }
    `;

  const FILE_DOWNLOAD_LINK_QUERY = `
        query getFileDownloadLink($id: ID) {
          file(id: $id) {
            downloadURL
            s3ObjectKey
            type
          }
        }
    `;

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
        query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
        brief(id: $id) {
        id
        backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
            items {
            id
            description
            comments
            date
            createdAt
            order
            dynamicData
            files {
                items {
                id
                name
                }
            }
            }
            nextToken
        }
        }
    }
    `;

  const qlistBackgroundFiles = `
					query getBackgroundByID($id: ID) {
					  background(id: $id) {
						id
						files {
						  items {
							createdAt
							id
							details
							name
							order
						  }
						}
						labels {
						  items {
							id
							name
						  }
						}
					  }
					}`;

  const BACKGROUND_TABLE_QUERY = `
					query getBackgroundTable($clientMatterId: ID!) {
						backgroundTable(clientMatterId: $clientMatterId) {
						  id
						  columns {
							id
							accessorKey
							headerText
							enabled
							type
							optionsText
							order
							presets {
							  id
							  name
							}
						  }
						  createdAt
						  updatedAt
						}
					}`;

  const qGetFilesByMatter = `
					query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
					  matterFiles(isDeleted: $isDeleted, matterId: $matterId, nextToken: $nextToken, limit: $limit, sortOrder: $sortOrder) {
						items {
						  id
						  name
						  type
						}
					  }
					}
					`;

  //MUTATIONS
  const addClientq = `
        mutation addClient($companyId: String, $name: String) {
            clientCreate(companyId:$companyId, name:$name) {
                id
                name
            }
        }
        `;

  const addMatterq = `
        mutation addMatter($companyId: String, $name: String) {
            matterCreate(companyId:$companyId, name:$name) {
                id
                name
            }
        }
        `;

  const createClientMatterq = `
        mutation createClientMatter($companyId: String, $userId: ID, $client: ClientInput, $matter:MatterInput) {
          clientMatterCreate(companyId: $companyId, userId:$userId, client: $client, matter:$matter) {
            id
          }
        }
        `;

  const CREATE_BRIEF_MUTATION = `
        mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int], $assistantId: String) {
          briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds, assistantId: $assistantId) {
            id
            name
            date
            createdAt,
            assistantId,
            order
            columnIds
          }
        }
        `;

  const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

  const mBulkCreateMatterFile = `
    mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
      matterFileBulkCreate(files: $files) {
        id
        name
        order
      }
    }
  `;

  const BACKGROUNDFILE_TAG_MUTATION = `
  mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
	  backgroundFileTag(backgroundId: $backgroundId, files: $files) {
		id
	  }
	}
  `;

  const mCreateBackground = `
  mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
	backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
	  id
	  createdAt
	  date
	  description
	  order
	  briefs {
		id
		name
	  }
	}
  }
	`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
	mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
	  backgroundTableUpdate(id: $id, input: $input) {
		id
		columns {
		  id
		  accessorKey
		  headerText
		  type
		  enabled
		  optionsText
		  order
		  presets {
			id
			name
		  }
		}
	  }
	}
	`;

  const BACKGROUND_TABLE_CREATE = `
    mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
      backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
        }
        createdAt
        updatedAt
      }
    }
    `;

  const mAddUserAcces = `
    mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
      briefAccessTypeTag(briefId:$briefId, userType:$userType) {
        id
      }
    }
    `;

  const UPDATE_BRIEF_PRESET_MUTATION = `
  	mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    	briefUpdate(id: $id, columnIds: $columnIds){
				id
    }
  }
  `;

  //Tasklist data
    const TASKLIST_DATA_QUERY = `query getTaskLists($companyId: ID!, $isDeleted: Boolean, $assignee: [String]) {
      taskListData(
        companyId: $companyId
        assignee: $assignee
        isDeleted: $isDeleted
      ) {
        items {
          id
          data
        }
      }
    }`;

  // useEffect(() => {
  //   if (taskListData === null) {
  //     getTasks();
  //   }
  // }, [taskListData]);

  const qGetCompanyEmailToken = `
  query getCompanyEmailToken($companyId: String) {
    company(id: $companyId) {
      gmailToken {
        id
        refreshToken
        isOutOfSync
      }
      outlookToken {
        email
        refreshToken
        isOutOfSync
      }
    }
  }`;

  const qOutlookMessages = `
  query qOutlookMessages($id: String, $email: String, $startDate: String, $endDate: String, $limit: Int){
    company (id: $id) {
      outlookMessages(
        email: $email,
        startDate: $startDate,
        endDate: $endDate,
        limit: $limit
        ) {
        items {
          id
          date
          description
          subject
          outlookLabelIds
        }
      }
    }
  }`;

  const qGmailMessagesbyCompany = `
  query gmailMessagesByCompany($id: String, $email: String, $startDate: String, $endDate: String, $limit: Int) {
    company (id: $id) {
      gmailMessages(
        email: $email,
        startDate: $startDate,
        endDate: $endDate,
        limit: $limit
      ) {
        items {
          id
          date
          description
          subject
          gmailLabelIds
        }
      }
    }
  }`;

  // const getTasks = async () => {
  //   var fname = localStorage.getItem("firstName");
  //   var lname = localStorage.getItem("lastName");
  //   var userId = localStorage.getItem("userId");
  //   var userHistory = JSON.parse(localStorage.getItem("history"));

  //   //console.log("TEST NAME", `${fname} ${lname}`);

  //   let assignees = [`${fname} ${lname}`, userId];

  //   if (userHistory) {
  //     for (let pastUser of userHistory) {
  //       assignees.push(`${pastUser.firstName + " " + pastUser.lastName}`);
  //     }
  //   }

  //   //get 2 latest only
  //   let vars = {
  //     companyId: companyId,
  //     isDeleted: false,
  //     assignee: assignees,
  //     dateSubmittedEnd: "",
  //     dateSubmittedStart: "",
  //     status: "",
  //     limit: 2,
  //   };

  //   const { data } = await API.graphql({
  //     query: TASKLIST_DATA_QUERY,
  //     variables: vars,
  //   }).catch((e) => {
  //     console.error(e.errors[0].message);
  //   });

  //   const dataTasks = data.taskListData.items;

  //   dataTasks.map((x) => {
  //     x.data = JSON.parse(x.data);
  //   });

  //   settTaskListData(dataTasks);

  //   console.log("Tasklist", dataTasks);
  // };

  const [showPanel, setShowPanel] = useState(true);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  //FUNCTIONS
  useEffect(() => {
    if (userInfo === null) {
      let ls = {
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        company: localStorage.getItem("company"),
        userType: localStorage.getItem("userType"),
        // access: JSON.parse(localStorage.getItem("access")),
      };
      setuserInfo(ls);
      console.log("userInfo", ls);
    }
  }, [userInfo]);

  useEffect(async () => {
    console.log("DEFAULT LIST IS:", defaultList);
    if (defaultList === null) {
      //Get MatterList
      let result = [];
      const clientMattersOpt = await API.graphql({
        query: listClientMatters,
        variables: {
          companyId: companyId,
        },
      });
      console.log("CLIENT MATTER IS:", clientMattersOpt);
      if (clientMattersOpt.data.company.clientMatters.items !== null) {
        result = clientMattersOpt.data.company.clientMatters.items;
        console.log("Matterlist", result);
        // console.log("DATA IS:", clientMattersOpt);

        //Check if default client matter is existing
        var createClientMatter = true;
        result.map((x) => {
          if (x.client && x.client.name === "Default") {
            createClientMatter = false; //Do not create new
            clientMatterId = x.id; //client matter id
            setMatterId(x.matter.id);
            getBriefs(x.matter.id);
          }
        });

        setMatter_Id(clientMatterId);

        if (createClientMatter === true) {
          console.log("CREATE CLIENT MATTER TRUE");
          //create a new client matter (new login/first time) !!MIGHT TAKE TIME!!

          //==========CHECK FOR DUPLICATE CLIENT/MATTER==========//
          //check or create matter named "Default"
          var createClient = true;
          var defaultClient = [];

          const clientsOpt = await API.graphql({
            query: listClient,
            variables: {
              companyId: companyId,
            },
          });

          // console.log("Client list", clientsOpt.data.company.clients.items);

          //check if Default client exists
          clientsOpt?.data?.company?.clients?.items.map((x) => {
            if (x.name === "Default") {
              defaultClient = [{ id: x.id, name: x.name }]; //use this existing client
              createClient = false;
            }
          });

          //Create new client if Default does not exist
          if (createClient === true) {
            const addClient = await API.graphql({
              query: addClientq,
              variables: {
                companyId: companyId,
                name: "Default",
              },
            }).then((result) => {
              defaultClient = [...defaultClient, result?.data?.clientCreate]; //use this new client
              console.log("MATTERRRR ID", defaultClient);
            });
          }

          //check or create matter named "Default"
          var createMatter = true;
          var defaultMatter = [];

          const mattersOpt = await API.graphql({
            query: listMatter,
            variables: {
              companyId: companyId,
            },
          });

          // console.log("Matter list", clientsOpt.data.company.clients.items);

          //check if Default matter exists
          mattersOpt?.data?.company?.matters?.items.map((x) => {
            if (x.name === "Default") {
              defaultMatter = [{ id: x.id, name: x.name }]; //use this existing matter
              createMatter = false;
              console.log("MATTERRRR ID 22", defaultMatter);
            }
          });

          //Create new matter if Default does not exist
          if (createMatter === true) {
            const addMatter = await API.graphql({
              query: addMatterq,
              variables: {
                companyId: companyId,
                name: "Default",
              },
            }).then((result) => {
              defaultMatter = [...defaultMatter, result?.data?.matterCreate];
              console.log("MATTERRRR ID", defaultMatter);
            }); //use this new matter
          }

          setMatterId(defaultMatter[0]?.id);

          // console.log("client in use", defaultClient);
          // console.log("matter in use", defaultMatter);

          //==========CREATE NEW CLIENT MATTER PROPER==========//

          await Promise.all([
            API.graphql({
              query: createClientMatterq,
              variables: {
                companyId: companyId,
                userId: userId,
                client: defaultClient[0],
                matter: defaultMatter[0],
              },
            }),
            API.graphql({
              query: listClientMatters,
              variables: {
                companyId: companyId,
              },
            }),
          ]).then(async (res) => {
            // console.log("///", res);
            const newClientMatter = res[0].data.clientMatterCreate;
            clientMatterId = newClientMatter?.id;
            //==========CREATE DEFAULT BACKGROUNDS==========//
            //BACKGROUNDS: VARIATIONS, PROGRESS CLAIMS, PROGRESS PHOTOS, DELAY CLAIMS, CONTRACTS, PROJECT LOCATION, SITE DIARY

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                // assistantId: assistantId,
                name: "Variations",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
              },
            });

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                // assistantId: assistantId,
                name: "Provisional Sum",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3],
              },
            });

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                // assistantId: assistantId,
                name: "Budget",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3],
              },
            });

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                // assistantId: assistantId,
                name: "Site Diary",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
              },
            });

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                name: "Invoices",
                // assistantId: assistantId,
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
              },
            });

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                name: "Progress Photos",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
              },
            });

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                name: "Progress of work",
                // assistantId: assistantId,
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
              },
            });

            let contractBrief = await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                // assistantId: assistantId,
                name: "Contracts",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
              },
            });
            console.log("CONTRACT BRIEF IS:", contractBrief);

            //

            // Create background rows for contract files
            fileList.forEach(async (file, index) => {
              await API.graphql({
                query: mCreateBackground,
                variables: {
                  briefs: [
                    {
                      id: contractBrief.data.briefCreate.id,
                      name: contractBrief.data.briefCreate.name,
                    },
                  ],
                  description: file,
                  comments: "",
                  date: null,
                  order: index + 1,
                },
              });
            });

            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                name: "Project Location",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
              },
            });

            const params = {
              query: BRIEFS_QUERY,
              variables: {
                id: clientMatterId,
              },
            };

            await API.graphql(params).then((brief) => {
              const briefsList = brief?.data?.clientMatter?.briefs?.items;
              console.log("briefsList", briefsList);
              setDefaultList(briefsList);
              setContractBrief(briefsList[0]);
              setProgressPhotoBrief(briefsList[2]);
              setDefaultBriefColumnIds(sortByOrder(briefsList), clientMatterId);

              const projectLocationIndex = briefsList.findIndex(
                (item) => item.name === "Project Location"
              );
              setProjectLocationBrief(briefsList[projectLocationIndex]);

              window.location.reload();
            });
          });
        } else {
          console.log("CREATE CLIENT MATTER FALSE");
          //JUST DISPLAY BACKGROUNDS
          // console.log("will enter here", clientMatterId);
          const params = {
            query: BRIEFS_QUERY,
            variables: {
              id: clientMatterId,
            },
          };

          await API.graphql(params).then(async (brief) => {
            const briefsList = brief?.data?.clientMatter?.briefs?.items;
            console.log("briefsList from up", briefsList);
            setDefaultList(briefsList);
            setContractBrief(briefsList[0]);
            setDefaultBriefColumnIds(sortByOrder(briefsList), clientMatterId);

            const progressPhotoIndex = briefsList.findIndex(
              (item) => item.name === "Progress Photos"
            );
            let rowArray = [];
            if (progressPhotoIndex !== -1) {
              setProgressPhotoBrief(briefsList[progressPhotoIndex]);

              const response = await API.graphql({
                query: qGetBackgroundFilesbyBriefID,
                variables: {
                  id: briefsList[progressPhotoIndex].id,
                  limit: null,
                  nextToken: null,
                  sortOrder: "DATE_DESC",
                },
              });
              let brief = response.data;

              // const filteredItems = brief.backgrounds.items.filter(item =>
              // 	item.files.items && item.files.items.length > 0
              // );

              let retrievedRow = brief.brief.backgrounds.items;

              // Filter the retrievedRow array to only contain rows with images
              if (retrievedRow.length > 0) {
                const filteredItems = retrievedRow
                  .filter((item) => {
                    // Check if at least one file in the files array is an image
                    return item.files.items.some((file) =>
                      isImageFile(file.s3ObjectKey)
                    );
                  })
                  .sort((a, b) => a.order - b.order);

                setPhotos(filteredItems);
                // console.log("YOU ARE HERE " + filteredItems)
                // console.log("YOU ARE HERE " + JSON.stringify(retrievedRow))
              }
            }

            const projectLocationIndex = briefsList.findIndex(
              (item) => item.name === "Project Location"
            );
            if (projectLocationIndex === -1) {
              // ADDED TEMPORARY CODE TO ADD BRIEF TO ONGOING TESTING ACCOUNTS
              await API.graphql({
                query: CREATE_BRIEF_MUTATION,
                variables: {
                  clientMatterId: clientMatterId,
                  name: "Project Location",
                  date: moment
                    .utc(moment(new Date(), "YYYY-MM-DD"))
                    .toISOString(),
                  order: 0,
                  columnIds: [0, 1, 2, 3, 4],
                },
              });
              const params = {
                query: BRIEFS_QUERY,
                variables: {
                  id: clientMatterId,
                },
              };

              await API.graphql(params).then((brief) => {
                const briefsList = brief?.data?.clientMatter?.briefs?.items;
                const projectLocationIndex = briefsList.findIndex(
                  (item) => item.name === "Project Location"
                );
                setProjectLocationBrief(briefsList[projectLocationIndex]);
              });
            } else {
              setProjectLocationBrief(briefsList[projectLocationIndex]);
              const response = await API.graphql({
                query: qGetBackgroundFilesbyBriefID,
                variables: {
                  id: briefsList[projectLocationIndex].id,
                  limit: null,
                  nextToken: null,
                  sortOrder: "DATE_DESC",
                },
              });
              let brief = response.data;

              // const filteredItems = brief.backgrounds.items.filter(item =>
              // 	item.files.items && item.files.items.length > 0
              // );
              if (brief.brief.backgrounds?.items?.length > 0) {
                rowArray.push(brief.brief.backgrounds.items);
                console.log(
                  "setting project location photo",
                  brief.brief.backgrounds.items[0]
                );
                setProjectLocationPhoto(brief.brief.backgrounds.items[0]);
              }
            }
            console.log("Briefs List: " + briefsList);
          });
          //end
        }
      }
    }
    setIsInitDone(true);
  }, []);

  // Tasklist Preview States
  const [taskListData, settTaskListData] = useState(null);
  const [taskCount, setTaskCount] = useState(null);
  const [oTasks, setOTasks] = useState(null);
  const [cTasks, setCTasks] = useState(null);

  useEffect(() => {
    if (taskCount === null) {
      getTaskCount();
    }
  }, [taskCount]);

  const getTaskCount = async () => {
    console.log("Tasklist Preview: Getting tasks");
    var fname = localStorage.getItem("firstName");
    var lname = localStorage.getItem("lastName");
    var userId = localStorage.getItem("userId");
    var userHistory = JSON.parse(localStorage.getItem("history"));

    var companyId = localStorage.getItem("companyId")
      //console.log("TEST NAME", `${fname} ${lname}`);

    let assignees = [
      `${fname} ${lname}`,
        userId
    ]

    if (userHistory) {
      for (let pastUser of userHistory) {
        assignees.push(`${pastUser.firstName + " " + pastUser.lastName}`)
      }
    }

    //get 2 latest only
    let params = {
      query: TASKLIST_DATA_QUERY,
      variables: {
        companyId: companyId,
        isDeleted: false,
        assignee: assignees,
      },
    };

    console.log("Tasklist Preview: Running Query", params);
    const result = await API.graphql(params).catch((e) => {
      console.error(e.errors[0].message);
    });

    const dataTasks = result?.data ? result.data.taskListData.items : [];

    dataTasks.map((x)=>{
      x.data = JSON.parse(x.data);
    })

    let limitedTasks = dataTasks.length > 2 ? dataTasks.slice(0, 2) : dataTasks;
    settTaskListData(limitedTasks)
    console.log("Tasklist Preview: Tasklist inside", limitedTasks);

    setTaskCount(dataTasks?.length)
    console.log("Tasklist Preview: Amount of All Tasks", dataTasks?.length);

    var outstanding = [];
    var completed = [];

    dataTasks.map((x)=>
    (x.data[7] === "Outstanding") ? outstanding = [...outstanding, x]
      : (x.data[7] === "Completed") ? completed = [...completed, x]
      : x
    )
    setOTasks(outstanding?.length)
    setCTasks(completed?.length)
    console.log("Tasklist Preview: Amount of Outstanding tasks", outstanding?.length);
    console.log("Tasklist Preview: Amount of Completed tasks", completed?.length);
  }

  //Get Started Functions
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChangeGetStartedProgress = (getStartedProgressKey) => {
    if (
      getStartedProgress[getStartedProgressKey] !== undefined &&
      getStartedProgress[getStartedProgressKey] === false
    ) {
      const newProgressObject = {
        ...getStartedProgress,
        [getStartedProgressKey]: true,
      };
      console.log("newProgressObject", newProgressObject);
      setGetStartedProgress(newProgressObject);
      //localStorage.setItem('getStartedCompletion', JSON.stringify(newProgressObject))
    }
  };

  //Check if file is image file
  const isImageFile = (fileName) => {
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".webp",
      ".svg",
      ".avif",
      ".apng",
    ];
    const extension = fileName
      .toLowerCase()
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    return imageExtensions.includes("." + extension);
  };

  const previewAndDownloadFile = async (id) => {
    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  const previewOnlyFile = (id) => {
    console.log("preview and download file: ", id);
    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    return API.graphql(params).then((result) => {
      return result.data.file; // Assuming the data structure returned has a "file" property
    });
  };

  const sortByOrder = (arr) => {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }
    return sort;
  };

  const getBackgrounds = async (id, state) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });

    if (backgroundOpt.data.brief.backgrounds.items !== null) {
      var result = backgroundOpt.data.brief.backgrounds.items.map(
        ({ id, description, date, createdAt, order, files, dynamicData }) => ({
          createdAt: createdAt,
          id: id,
          description: description,
          date: date,
          order: order,
          files: files,
          dynamicData: dynamicData,
        })
      );
      console.log("Background Data", result);
      setBackground(sortByOrder(result));
      setShowBackgrounds(state);
      setShowBriefId(id);
    }
  };

  let getMatterFiles = async () => {
    const params = {
      query: qGetFilesByMatter,
      variables: {
        matterId: matterId,
        isDeleted: false,
        nextToken: null,
        sortOrder: "DATE_ASC",
      },
    };
    await API.graphql(params).then((files) => {
      let matterFilesList = files?.data?.matterFiles?.items;
      setFileTypeCollection(matterFilesList);
    });
  };

  useEffect(() => {
    getMatterFiles();
  }, [matterId]);

  async function bulkCreateMatterFile(param) {
    console.log("bulkCreateMatterFile");
    let idTag = [];

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    setTimeout(async () => {
      const request = await API.graphql({
        query: mBulkCreateMatterFile,
        variables: {
          files: param,
        },
      });

      console.log("result", request);

      if (request.data.matterFileBulkCreate !== null) {
        request.data.matterFileBulkCreate.map((i) => {
          return (idTag = [...idTag, { id: i.id }]);
        });
      }

      console.log("iDTag", idTag);

      let arrFiles = [];
      let arrFileResult = [];
      const seen = new Set();

      // console.log("MID/BID", background_id);

      const backgroundFilesOpt = await API.graphql({
        query: qlistBackgroundFiles,
        variables: {
          id: selectedRowId,
        },
      });

      console.log("CHECKDATA", backgroundFilesOpt);

      if (backgroundFilesOpt.data.background.files !== null) {
        arrFileResult = backgroundFilesOpt.data.background.files.items.map(
          ({ id }) => ({
            id: id,
          })
        );

        idTag.push(...arrFileResult);
        console.log("updatedidtag", idTag);

        const filteredArr = idTag.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });

        console.log("rowid", selectedRowId);

        API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: selectedRowId,
            files: filteredArr,
          },
        });
      } else {
        API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: selectedRowId,
            files: idTag,
          },
        });
      }
    }, 1000);
    //return request;
  }

  const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; //list of rejected files

  useEffect(() => {
    var counter = 0;
    var dCounter = 0;
    if (uploadImage.length > 0 && counter === 0) {
      onSelectFile(uploadImage);
      counter = 1;
    }
  }, [uploadImage]);

  useEffect(() => {
    console.log("Selected files are:" + JSON.stringify(selectedFiles[0]));
    console.log("Rejected files are:" + JSON.stringify(invalidFiles[0]));
  }, [selectedFiles, invalidFiles]);
  const onSelectFile = (retrievedFiles) => {
    console.log("You are here " + retrievedFiles);
    if (!retrievedFiles || retrievedFiles.length === 0) {
      return;
    }
    const tempArr = [];

    [...retrievedFiles].forEach((file) => {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];

      const result = rejectFiles.find((item) =>
        item.includes(re.exec(file.name)[0])
      );
      const fileSize = file.size;

      if (result || fileSize > 2147483648) {
        invalidFiles.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      } else {
        tempArr.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      }
    });

    setSelectedFiles([...myCurrentRef.current, ...tempArr]);
  };

  const myCurrentRef = useRef(selectedFiles);
  const setSelectedFiles = (data) => {
    myCurrentRef.current = data;
    _setSelectedFiles(data);
  };

  const handleUpload = async (bucketName) => {
    setUploadStart(true);
    if (!saveButtonClicked) {
      setSaveButtonClicked(true);
    }
    var tempArr = [];
    var temp = [];
    selectedFiles.forEach((uf) => {
      if (isImageFile(uf.data.name)) {
        tempArr = [...tempArr, uf];
        handleProgressPhotoUpload({ datePicked, description });
      } else {
        alert(
          `File ${uf.data.name} is not an image. Only images can be uploaded.`
        );
        setImageURL(null); // Set image URL back to null
        setSaveButtonClicked(false);
        return;
      }
    });
    setSelectedFiles(tempArr);
    _setSelectedFiles(tempArr);
    var idxx = 0;
    tempArr.map(async (uf, index) => {
      if (uf.data.name.split(".").pop() == "docx") {
        var name = uf.data.name,
          size = uf.data.size,
          type =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          key = `${bucketName}/${Number(new Date())}${name
            ?.replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      } else {
        var name = uf.data.name,
          size = uf.data.size,
          type = uf.data.type,
          key = `${bucketName}/${Number(new Date())}${name
            ?.replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      }
      idxx = idxx + 1;

      try {
        await Storage.put(key, uf.data, {
          contentType: type,
          progressCallback(progress) {
            const progressInPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            //console.log(`Progress: ${progressInPercentage}%, ${uf.data.name}`);

            if (temp.length > selectedFiles.length) {
              for (var i = 0; i < selectedFiles.length; i++) {
                console.log(uf.data.name === temp[i].name);
                if (temp[i].name === uf.data.name) {
                  temp[i].prog = progressInPercentage;
                }
              }
            } else {
              temp = [
                ...temp,
                { prog: progressInPercentage, name: uf.data.name },
              ];
            }
            //console.log(temp);
          },
          errorCallback: (err) => {
            console.error("204: Unexpected error while uploading", err);
          },
        })
          .then((fd) => {
            var fileData = {
              s3ObjectKey: fd.key,
              size: parseInt(size),
              type: type,
              name: name.split(".").slice(0, -1).join("."),
              oderSelected: orderSelected,
              order: orderSelected,
            };

            setUploadedFiles((prevState) => ({
              files: [...prevState.files, fileData],
            }));
          })
          .catch((err) => {
            console.error("220: Unexpected error while uploading", err);
          });
      } catch (e) {
        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };
        console.error("228: Unexpected error while uploading", response);
      }
      setUploadStart(false);
    });
  };

  useEffect(() => {
    console.log(uploadStart);
  }, [uploadStart]);

  const uploadAndCheck = async () => {
    if (
      uploadedFiles.files.length === selectedFiles.length &&
      selectedFiles.length !== 0
    ) {
      const result = await handleUploadLink(uploadedFiles, progressPhotoBrief);
      console.log(result);
      //setProgressPhotosDone(true);
      if (result === "done") {
        setShowRightPanel(false);
        setModalState(null);
      }
    }
  };
  useEffect(() => {
    if (uploadStart == false) {
      uploadAndCheck();
    }
  }, [selectedFiles, uploadedFiles, uploadStart]);

  const [logo, setLogo] = useState([]);
  const handleLogoDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const areAllFilesImages = Array.from(files).every((file) =>
        /\.(jpg|jpeg|png|gif)$/i.test(file.name)
      );

      if (areAllFilesImages) {
        setShowRightPanel(true);
        setModalState(modalChoicesEnum.BUILDER_DETAILS);
        console.log("Images uploaded are " + files[0]);
        // setBuilderLogo(URL.createObjectURL(files[0]));
        setLogo(files);
        setLogoURL(URL.createObjectURL(files[0]));
      } else {
        alert("Please drop only image files (JPEG, PNG, GIF, etc.).");
      }
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const file = files[0];
      if (!isImageFile(file.name)) {
        alert(
          `File ${file.name} is not an image. Only images can be uploaded.`
        );
        setImageURL(null); // Set image URL back to null
        return;
      }

      setShowRightPanel(true);
      setModalState(modalChoicesEnum.PROGRESS_PHOTO);
      console.log("Images uploaded are " + file.name);
      setImageURL(URL.createObjectURL(file));
      setUploadImage(files);
    }
  };

  const handleProgressPhotoUpload = async ({ datePicked, description }) => {
    let uploadToBrief = await API.graphql({
      query: mCreateBackground,
      variables: {
        briefs: [
          {
            id: progressPhotoBrief.id,
            name: progressPhotoBrief.name,
          },
        ],
        description: description !== null ? description : "",
        comments: "",
        date: datePicked,
        order: 1,
      },
    });
    console.log("Uploaded to Brief is:", uploadToBrief);
    setSelectedRowId(uploadToBrief.data.backgroundCreate?.id);
  };
  //UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
  const handleUploadLink = async (uf, brief) => {
    await Promise.all(
      uf?.files.map(async (file) => {
        // console.log("Uploaded file - ", file);
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matterId,
            briefId: brief?.id,
            activity: `added a background file named ${file.name}-(${file.size} byte)`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: selectedRowId,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          // console.log("addActivity result", result);
        });
      })
    );
    // console.log("handleUploadLink uf", uf);
    var uploadedFiles = uf.files.map((f) => ({ ...f, matterId: matterId }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
    // console.log("SF",sortedFiles);
    // console.log("AO", addOrder);

    //insert in matter file list
    bulkCreateMatterFile(addOrder);

    //set background content

    const backgroundFilesOptReq = API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: selectedRowId,
      },
    }).then((result) => {
      var newFilesResult = result.data.background.files.items.map(
        ({ createdAt, id, name, description, order }) => ({
          createdAt: createdAt,
          id: id,
          name: name,
          description: description,
          order: order,
        })
      );

      // Sort the newFilesResult array by createdAt in descending order
      newFilesResult?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      console.log(newFilesResult);

      var updateArrFiles = background.map((obj) => {
        if (obj.id === selectedRowId) {
          return { ...obj, files: { items: newFilesResult } };
        }
        return obj;
      });

      // Update order number based on uploaded file
      updateArrFiles.forEach((item) => {
        const orderFilesPerBackground = item?.files?.items?.map(
          ({ id }, index) => ({
            id: id,
            order: index + 1,
          })
        );

        // For FE purposes: Update existing background array with the updated order data
        const orderFilesPerBackgroundFE = item?.files?.items?.map(
          (
            {
              id,
              details,
              gmailMessage,
              gmailMessageId,
              isGmailAttachment,
              isGmailPDF,
              name,
              s3ObjectKey,
            },
            index
          ) => ({
            id: id,
            name: name,
            details: details,
            gmailMessage: gmailMessage,
            gmailMessageId: gmailMessageId,
            isGmailAttachment: isGmailAttachment,
            isGmailPDF: isGmailPDF,
            s3ObjectKey: s3ObjectKey,
            order: index + 1,
          })
        );

        if (
          orderFilesPerBackground !== undefined &&
          orderFilesPerBackground !== null
        ) {
          item.files.items = orderFilesPerBackgroundFE;
          // updateBackgroundFilesOrder(orderFilesPerBackground);
          console.log("Items", orderFilesPerBackground);
        }
      });

      console.log("Set New Background", updateArrFiles);
      setBackground(updateArrFiles);
    });

    // setGoToFileBucket(true);

    setShowUploadModal(null);
    await triggerToast(
      `Photo has been uploaded! View it at Progress Photos Background`,
      false
    );
    if (uploadStart == false) {
      window.location.reload();
    }
    //getBackground();
    return "done";
  };
  async function triggerToast(message, isError) {
    setalertMessage(message);
    setShowToast(true);
    setIsToastError(isError);
    // setGoToFileBucket(true);

    await new Promise((resolve) => {
      setTimeout(() => {
        setShowToast(false);
        resolve();
      }, 1500);
    });
  }

  function handleHideChecklist() {
    if (
      Array.isArray(contractTC) &&
      Array.isArray(archiDrawings) &&
      Array.isArray(engDrawings) &&
      Array.isArray(specifications) &&
      Array.isArray(buildInsurance)
    ) {
      console.log(
        "CHECKING IS:",
        contractTC?.length > 0 &&
          archiDrawings?.length > 0 &&
          engDrawings?.length > 0 &&
          specifications?.length > 0 &&
          buildInsurance?.length > 0
      );
      setHideChecklist(
        contractTC?.length > 0 &&
          archiDrawings?.length > 0 &&
          engDrawings?.length > 0 &&
          specifications?.length > 0 &&
          buildInsurance?.length > 0
      );
      setLoading(false);
    } else {
      setTimeout(() => {
        if (fileCounter.current < 5) {
          setLoading(false);
        }
      }, 1000);
      setHideChecklist(false);
    }
  }

  function handleUploadClick(index) {
    if (index == showUploadModal) {
      handleCloseUploadModal();
    } else {
      setShowUploadModal(index);
      setSelectedIndex(index);
      setSelectedRowId(contractBrief?.backgrounds?.items[index]?.id);
    }
  }

  function handleCloseUploadModal() {
    setShowUploadModal(null);
    setSelectedIndex(null);
    setSelectedRowId(null);
  }

  const fileCounter = useRef(0);
  useEffect(() => {
    if (contractBrief && Object.keys(contractBrief).length > 0) {
      if (
        contractBrief.backgrounds &&
        contractBrief.backgrounds.hasOwnProperty("items")
      ) {
        fileList.forEach(async (file, index) => {
          if (contractBrief.backgrounds.items[index]) {
            let backgroundFilesOpt = await API.graphql({
              query: qlistBackgroundFiles,
              variables: {
                id: contractBrief.backgrounds.items[index].id,
              },
            });

            // let rowFiles = backgroundFilesOpt.data.background?.files?.items;
            // let tempFiles = fileTypeCollection.filter((fileCollection) => {
            // 	for (const rowFile of rowFiles) {
            // 		if (rowFile.id == fileCollection?.id) {
            // 			return true;
            // 		}
            // 	}
            // 	return false;
            // });

            let tempFiles = backgroundFilesOpt.data.background?.files?.items;
            // console.log("FILE IS:", file);
            // console.log("FILE LIST IS:", fileList[0]);
            console.log("BACKGROUND FILE IS:", backgroundFilesOpt);
            // console.log("ROW FILES ARE:", rowFiles);
            console.log("TEMP FILES ARE:", tempFiles);
            if (file == fileList[0]) {
              setContractTC(tempFiles);
            } else if (file == fileList[1]) {
              setArchiDrawings(tempFiles);
            } else if (file == fileList[2]) {
              setEngDrawings(tempFiles);
            } else if (file == fileList[3]) {
              setSpecifications(tempFiles);
            } else if (file == fileList[4]) {
              setBuildInsurance(tempFiles);
            }
            fileCounter.current++;
          }
        });
      }
    }
  }, [contractBrief]);

  useEffect(() => {
    handleHideChecklist();
  }, [contractTC, archiDrawings, engDrawings, specifications, buildInsurance]);

  useEffect(() => {
    // console.log("HELLO HERE:", !hideChecklist && !loading);
    // console.log("CHECKLIST HIDE ISL", hideChecklist);
    // console.log("LOADING IS:", loading);
  }, [hideChecklist, loading]);

  const handleActivity = (item) => {
    var str = "Created";

    const { createdAt, createdBy, updatedAt, updatedBy } = item;

    if (createdBy && createdBy.id !== "") {
      str += ` by ${createdBy.firstName} `;
    }
    if (createdAt) {
      str += ` on ${dateTimeNoSecondsFormatter(item.createdAt)} `;
    }

    if ((updatedBy && updatedBy.id !== "") || updatedAt) {
      str += ` / Updated`;
      if (updatedBy && updatedBy.id !== "") {
        str += ` by ${updatedBy.firstName} `;
      }
      if (updatedAt) {
        str += ` on ${dateTimeNoSecondsFormatter(item.updatedAt)} `;
      }
    }

    // return str?.replace(/\s\s/g, " ");
    return str.trim();
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  const handleAddUserAccess = async (e, dataId, dataInfo) => {
    console.log("event", e);
    console.log("dataInfo", dataInfo);
    var bId = dataId;
    var temp = [];

    e.map((item) => (temp = [...temp, item.label]));

    const params = {
      query: mAddUserAcces,
      variables: {
        briefId: bId,
        userType: temp,
      },
    };

    await API.graphql(params).then((ua) => {
      console.log("uaa", ua);
    });
  };

  const defaultUserAccessValues = [
    {
      label: "LEGALADMIN",
      value: "LEGALADMIN",
    },
    {
      label: "LAWYER",
      value: "LAWYER",
    },
  ];

  async function setDefaultColumnBriefs(backgroundTables, briefs) {
    console.group("setDefaultColumnBriefs");
    try {
      const { id, columns } = backgroundTables;

      let invoicePreset;
      let variationPreset;
      let builderDetailsPreset;
      let provisionalSumPreset;
      let contractDetailsPreset;
      let budgetPreset;

      console.log("Current columns columns", columns);
      console.log("defaultColumnIds:", defaultColumnIds);

      if (id) {
        let presets = [];
        const commentsColPresets = [];

        for (const item of briefs) {
          console.log("ITEM IS:", item);
          const preset = { id: item.id, name: item.name };
          if (item.name == "Invoices") {
            invoicePreset = { id: item.id, name: item.name };
          }
          if (item.name == "Variations") {
            variationPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Provisional Sum") {
            provisionalSumPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Budget") {
            budgetPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Builder Details") {
            builderDetailsPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Contract Details") {
            contractDetailsPreset = { id: item.id, name: item.name };
          }

          presets.push(preset);

          if (item.hasCommentAccess) {
            const commentPreset = { ...preset };
            commentsColPresets.push(commentPreset);
          }
        }
        console.log("presets", presets);
        let isInvoicePresent = false;
        let isVariationPresent = false;
        let isProvisionalSumPresent = false;
        let isBuilderDetailsPresent = false;
        let isContractDetailsPresent = false;
        let isBudgetPresent = false;

        const defaultBriefs = columns.map((obj) => {
          var tag;
          if (
            obj.headerText == "Claim Status" ||
            obj.headerText == "Payment Status" ||
            obj.headerText == "Claim Amount ($)" ||
            obj.headerText == "Due Date for Claim"
          ) {
            tag = "Invoices";
            isInvoicePresent = true;
          }
          if (
            obj.headerText == "Variation Status" ||
            obj.headerText == "Variation Cost" ||
            obj.headerText == "Variation Cost ($)"
          ) {
            tag = "Variations";
            isVariationPresent = true;
          }
          if (
            obj.headerText == "Provisional Amount" ||
            obj.headerText == "Cost Amount" ||
            obj.headerText == "Description of Work" ||
            obj.headerText == "Paid to Date Amount" ||
            obj.headerText == "Action"
          ) {
            tag = "Provisional Sum";
            isProvisionalSumPresent = true;
          }

          if (
            obj.headerText == "Builder Name" ||
            obj.headerText == "Builder Logo" ||
            obj.headerText == "Builder Contact Name" ||
            obj.headerText == "Builder Contact Email" ||
            obj.headerText == "Builder Contact Number"
          ) {
            tag = "Builder Details";
            isBuilderDetailsPresent = true;
          }

          if (
            obj.headerText == "Start Date" ||
            obj.headerText == "Contract Price" ||
            obj.headerText == "Contract Value"
          ) {
            isContractDetailsPresent = true;
            tag = "Contract Details";
          }

          console.log("obj.presets", obj.preset);

          return {
            ...obj,
            presets:
              obj.headerText == "Label"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details" &&
                        preset.name !== "Variations" &&
                        preset.name !== "Provisional Sum" &&
                        preset.name !== "Budget"
                    )
                  : []
                : isVariationPresent ||
                  isInvoicePresent ||
                  isProvisionalSumPresent ||
                  isBudgetPresent
                ? obj.presets
                : isBuilderDetailsPresent || isContractDetailsPresent
                ? obj.presets.filter((preset) => preset.name === tag)
                : obj.headerText == "Gmail" ||
                  obj.headerText === "Email Date" ||
                  obj.headerText === "Description of Background" ||
                  obj.headerText === "Date"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details"
                    )
                  : []
                : obj.headerText === "Document"
                ? presets?.length
                  ? presets?.filter(
                      (preset) => preset.name !== "Contract Details"
                    )
                  : []
                : obj.headerText === "Paid to Date Amount" ||
                  obj.headerText === "Action"
                ? presets?.length
                  ? presets?.filter(
                      // to add budget preset
                      (preset) =>
                        preset.name === "Variations" &&
                        preset.name === "Provisional Sum" &&
                        preset.name === "Budget"
                    )
                  : []
                : presets,
          };
        });
        console.log("Default Briefs:", defaultBriefs);

        // console.log("INVOICE PRESET:", invoicePreset);

        let newColumns = [...defaultBriefs];

        console.log("isInvoicePresent", isInvoicePresent);
        console.log("isVariationPresent", isVariationPresent);
        console.log("isBuilderDetailsPresent", isBuilderDetailsPresent);
        console.log("isContractDetailsPresent", isContractDetailsPresent);
        console.log("isBudgetPresent", isBudgetPresent);

        if (
          !isInvoicePresent ||
          !isVariationPresent ||
          !isProvisionalSumPresent ||
          !isBuilderDetailsPresent ||
          !isContractDetailsPresent
        ) {
          if (
            !isInvoicePresent &&
            !isVariationPresent &&
            !isProvisionalSumPresent
          ) {
            newColumns = [
              ...newColumns,
              {
                accessorKey: newColumns.length.toString(),
                id: newColumns.length.toString(),
                presets: invoicePreset,
                headerText: "Due Date for Claim",
                type: "DATE",
                enabled: true,
                optionsText: [],
                order: newColumns.length,
              },
              {
                accessorKey: newColumns.length.toString(),
                id: newColumns.length.toString(),
                presets: invoicePreset,
                headerText: "Claim Status",
                type: "DROPDOWN",
                enabled: true,
                optionsText: ["Claimed", "Unclaimed"],
                order: newColumns.length + 1,
              },
              {
                accessorKey: newColumns.length.toString(),
                id: newColumns.length.toString(),
                presets: invoicePreset,
                headerText: "Payment Status",
                type: "DROPDOWN",
                enabled: true,
                optionsText: ["Paid", "Unpaid"],
                order: newColumns.length + 2,
              },
              {
                accessorKey: (newColumns.length + 1).toString(),
                id: (newColumns.length + 1).toString(),
                presets: invoicePreset,
                headerText: "Claim Amount ($)",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 3,
              },
              {
                accessorKey: (newColumns.length + 2).toString(),
                id: (newColumns.length + 2).toString(),
                presets: variationPreset,
                headerText: "Variation Status",
                type: "DROPDOWN",
                enabled: true,
                optionsText: ["Approved", "For Approval"],
                order: newColumns.length + 4,
              },
              {
                accessorKey: (newColumns.length + 3).toString(),
                id: (newColumns.length + 3).toString(),
                presets: variationPreset,
                headerText: "Variation Cost ($)",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 5,
              },
              {
                accessorKey: (newColumns.length + 4).toString(),
                id: (newColumns.length + 3).toString(),
                presets: [provisionalSumPreset, budgetPreset],
                headerText: "Provisional Amount",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 6,
              },
              {
                accessorKey: (newColumns.length + 5).toString(),
                id: (newColumns.length + 3).toString(),
                presets: [provisionalSumPreset, budgetPreset],
                headerText: "Cost Amount",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 7,
              },
              {
                accessorKey: (newColumns.length + 6).toString(),
                id: (newColumns.length + 3).toString(),
                presets: [provisionalSumPreset, budgetPreset],
                headerText: "Description of Work",
                type: "TEXT",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 8,
              },
              {
                accessorKey: (newColumns.length + 7).toString(),
                id: (newColumns.length + 3).toString(),
                // to add budget preset
                presets: [provisionalSumPreset, variationPreset, budgetPreset],
                headerText: "Paid to Date Amount",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 9,
              },
              {
                accessorKey: (newColumns.length + 8).toString(),
                id: (newColumns.length + 3).toString(),
                // to add budget preset
                presets: [provisionalSumPreset, variationPreset, budgetPreset],
                headerText: "Action",
                type: "TEXT",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 10,
              },
            ];
          }
          if (!isBuilderDetailsPresent) {
            newColumns = [
              ...newColumns,
              {
                accessorKey: newColumns.length.toString(),
                id: newColumns.length.toString(),
                presets: builderDetailsPreset,
                headerText: "Builder Name",
                type: "TEXT",
                enabled: true,
                optionsText: [],
                order: newColumns.length,
              },
              {
                accessorKey: (newColumns.length + 1).toString(),
                id: (newColumns.length + 1).toString(),
                presets: builderDetailsPreset,
                headerText: "Builder Contact Name",
                type: "TEXT",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 1,
              },
              {
                accessorKey: (newColumns.length + 2).toString(),
                id: (newColumns.length + 2).toString(),
                presets: builderDetailsPreset,
                headerText: "Builder Contact Email",
                type: "TEXT",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 2,
              },
              {
                accessorKey: (newColumns.length + 3).toString(),
                id: (newColumns.length + 3).toString(),
                presets: builderDetailsPreset,
                headerText: "Builder Contact Number",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 3,
              },
            ];
          }
          if (!isContractDetailsPresent) {
            newColumns = [
              ...newColumns,
              {
                accessorKey: newColumns.length.toString(),
                id: newColumns.length.toString(),
                presets: contractDetailsPreset,
                headerText: "Start Date",
                type: "DATE",
                enabled: true,
                optionsText: [],
                order: newColumns.length,
              },
              {
                accessorKey: (newColumns.length + 1).toString(),
                id: (newColumns.length + 1).toString(),
                presets: contractDetailsPreset,
                headerText: "Contract Price",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 1,
              },
              {
                accessorKey: (newColumns.length + 2).toString(),
                id: (newColumns.length + 2).toString(),
                presets: contractDetailsPreset,
                headerText: "Contract Value",
                type: "NUMBER",
                enabled: true,
                optionsText: [],
                order: newColumns.length + 2,
              },
            ];
          }
        } else {
          //Filter Columns & set it to default HBA presets of old client & matters
          let filterBriefs = [];
          defaultBriefs.map((obj) => {
            if (
              obj.headerText == "Claim Status" ||
              obj.headerText == "Payment Status" ||
              obj.headerText == "Claim Amount ($)" ||
              obj.headerText == "Due Date for Claim"
            ) {
              filterBriefs.push({
                ...obj,
                presets: [invoicePreset],
              });
            }

            if (
              obj.headerText == "Variation Status" ||
              obj.headerText == "Variation Cost" ||
              obj.headerText == "Variation Cost ($)"
            ) {
              filterBriefs.push({
                ...obj,
                presets: [variationPreset],
              });
            }

            if (
              obj.headerText == "Provisional Amount" ||
              obj.headerText == "Cost Amount" ||
              obj.headerText == "Description of Work"
            ) {
              filterBriefs.push({
                ...obj,
                presets: [provisionalSumPreset, budgetPreset],
              });
            }

            if (
              obj.headerText == "Builder Name" ||
              obj.headerText == "Builder Logo" ||
              obj.headerText == "Builder Contact Name" ||
              obj.headerText == "Builder Contact Email" ||
              obj.headerText == "Builder Contact Number"
            ) {
              filterBriefs.push({
                ...obj,
                presets: [builderDetailsPreset],
              });
            }

            if (
              obj.headerText == "Start Date" ||
              obj.headerText == "Contract Price" ||
              obj.headerText == "Contract Value"
            ) {
              filterBriefs.push({
                ...obj,
                presets: [contractDetailsPreset],
              });
            }

            if (
              obj.headerText === "Paid to Date Amount" ||
              obj.headerText === "Action"
            ) {
              filterBriefs.push({
                ...obj,
                // to add budget preset
                presets: [provisionalSumPreset, variationPreset, budgetPreset],
              });
            }

            if (
              obj.headerText !== "Start Date" &&
              obj.headerText !== "Contract Price" &&
              obj.headerText !== "Contract Value" &&
              obj.headerText !== "Claim Status" &&
              obj.headerText !== "Payment Status" &&
              obj.headerText !== "Claim Amount ($)" &&
              obj.headerText !== "Due Date for Claim" &&
              obj.headerText !== "Variation Status" &&
              obj.headerText !== "Variation Cost" &&
              obj.headerText !== "Variation Cost ($)" &&
              obj.headerText !== "Provisional Amount" &&
              obj.headerText !== "Cost Amount" &&
              obj.headerText !== "Paid to Date Amount" &&
              obj.headerText !== "Action" &&
              obj.headerText !== "Description of Work" &&
              obj.headerText !== "Builder Name" &&
              obj.headerText !== "Builder Logo" &&
              obj.headerText !== "Builder Contact Name" &&
              obj.headerText !== "Builder Contact Email" &&
              obj.headerText !== "Builder Contact Number" &&
              obj.headerText !== "Start Date" &&
              obj.headerText !== "Contract Price" &&
              obj.headerText !== "Contract Value"
            ) {
              filterBriefs.push({
                ...obj,
              });
            }
          });
          console.log("filtered Briefs:", filterBriefs);
          newColumns = filterBriefs.map((col) => ({
            ...col,
            presets: col.presets.filter(
              (preset) => preset !== undefined && preset !== null && preset
            ),
          }));
        }

        console.log(id, "new columns:", newColumns);

        const variables = {
          id,
          input: {
            columns: newColumns,
          },
        };

        console.log("VARIABLE IS:", variables);

        const updateBriefTable = await API.graphql({
          query: UPDATE_BACKGROUND_TABLE_MUTATION,
          variables,
        });

        console.log("updateBriefTable", updateBriefTable);
      }
    } catch (error) {
      console.error(error);
      console.groupEnd("setDefaultColumnBriefs");
    }
    console.groupEnd("setDefaultColumnBriefs");
  }

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    console.log("SETTING DEFAULT COLS", briefs);

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        console.log("columnIds", columnIds);
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);

      console.log("updatedBriefIds", updatedBriefIds);
      const newDefaultColumn = updatedBriefIds?.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      // const newDefaultArrayColumnBriefs = [
      // 	...new Set([...newDefaultColumn, ...briefIds]),
      // ];

      // const newDefaultColumnBriefs = Array.from(
      // 	new Set(newDefaultArrayColumnBriefs.map(JSON.stringify)),
      // 	JSON.parse
      // );

      // if (updatedBriefIds?.length !== 0) {
      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      console.log("Background Table for", clientMatterId, backgroundTable);

      if (backgroundTable?.data?.backgroundTable) {
        await setDefaultColumnBriefs(
          backgroundTable?.data?.backgroundTable,
          briefs
        );
      } else {
        console.log("Create Background Table for ", clientMatterId);

        const initialPresets = [];
        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          initialPresets.push(preset);
        }

        let backgroundCreate = await API.graphql({
          query: BACKGROUND_TABLE_CREATE,
          variables: {
            clientMatterId: clientMatterId,
            initialPresets: initialPresets,
          },
        });

        await setDefaultColumnBriefs(
          backgroundCreate?.data?.backgroundTableCreate,
          briefs
        );
      }
      // }
    } catch (error) {
      console.error(error);
    }
  }

  const getBriefs = async () => {
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (briefsList) => {
      const briefsLists = briefsList?.data?.clientMatter?.briefs?.items;
      const contractDetailsBrief = briefsLists?.find(
        (brief) => brief.name === "Contract Details"
      );
      const builderDetailsBrief = briefsLists?.find(
        (brief) => brief.name === "Builder Details"
      );

      const progressPhotoBrief = briefsLists?.find(
        (brief) => brief.name === "Progress Photos"
      );

      setProgressPhotoBriefID(progressPhotoBrief?.id);
      setProgressPhotosDetails(progressPhotoBrief);
      console.log("progressPhotoBrief", progressPhotoBrief);

      if (contractDetailsBrief) {
        let unparsedData = contractDetailsBrief?.backgrounds?.items;

        const parsedData = unparsedData?.map((item) => {
          if (item?.dynamicData) {
            try {
              const dynamicDataObject = JSON.parse(item.dynamicData);
              if (typeof dynamicDataObject === "object") {
                const dynamicDataArray = Object.keys(dynamicDataObject).map(
                  (key) => ({
                    id: key,
                    value: dynamicDataObject[key],
                  })
                );

                return {
                  ...item,
                  dynamicData: dynamicDataArray,
                };
              } else {
                console.warn(
                  "dynamicData is not a valid JSON object:",
                  dynamicDataObject
                );
              }
            } catch (error) {
              console.error("Error parsing dynamicData:", error);
            }
          } else {
            console.warn("dynamicData is missing or null for the item:", item);
          }
        });

        // Filter out items with dynamicData errors
        const validParsedData = parsedData.filter((item) => item);

        if (validParsedData !== "undefined" && validParsedData.length !== 0) {
          // let contractInfoTemp = {};
          // let dynamicDataTemp = validParsedData[0]?.dynamicData;

          // let contractStartDate = dynamicDataTemp.find((e) => e.id === "16");
          // if (contractStartDate) {
          //   contractInfoTemp.contractStartDate = contractStartDate.value;
          // }

          // let contractPrice = dynamicDataTemp.find((e) => e.id === "17");
          // if (contractPrice) {
          //   contractInfoTemp.contractPrice = contractPrice.value;
          // }

          // let contractValue = dynamicDataTemp.find((e) => e.id === "18");
          // if (contractValue) {
          //   contractInfoTemp.contractValue = contractValue.value;
          // }

          // setContractInfo(contractInfoTemp);
          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId,
            },
          });

          if (backgroundTable?.data?.backgroundTable) {
            let contractInfo = {
              contractStartDate: null,
              contractPrice: null,
              contractValue: null,
            };

            let backgroundTableInfo =
              backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
                if (obj.headerText == "Start Date") {
                  console.log("Start Date", obj.accessorKey);
                  contractInfo.contractStartDate = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Contract Price") {
                  console.log("Contract Price", obj.accessorKey);
                  contractInfo.contractPrice = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Contract Value") {
                  contractInfo.contractValue = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                return getValueById(parsedData, obj.accessorKey);
              });

            setContractInfo(contractInfo);
          } else {
            setContractInfo(false);
          }
        } else {
          setContractInfo(false);
        }
      }

      let buildInfo = {
        builderName: null,
        builderLogo: null,
        builderContactName: null,
        builderContactEmail: null,
        builderContactNumber: null,
      };

      if (builderDetailsBrief) {
        let unparsedData = builderDetailsBrief?.backgrounds?.items;
        console.log("getFiles", unparsedData);

        const parsedData = unparsedData?.map((item) => {
          let arrFileResult = item.files.items.map(({ id }) => {
            previewOnlyFile(id)
              .then((fileData) => {
                // Do something with the fileData
                console.log("preview link", fileData.downloadURL);
                // buildInfo.builderLogo = fileData.downloadURL;
                setBuilderLogo(fileData.downloadURL);
              })
              .catch((error) => {
                // Handle any errors
                console.log("error");
              });
          });

          if (item?.dynamicData) {
            try {
              const dynamicDataObject = JSON.parse(item.dynamicData);
              if (typeof dynamicDataObject === "object") {
                const dynamicDataArray = Object.keys(dynamicDataObject).map(
                  (key) => ({
                    id: key,
                    value: dynamicDataObject[key],
                  })
                );

                return {
                  ...item,
                  dynamicData: dynamicDataArray,
                };
              } else {
                console.warn(
                  "dynamicData is not a valid JSON object:",
                  dynamicDataObject
                );
              }
            } catch (error) {
              console.error("Error parsing dynamicData:", error);
            }
          } else {
            console.warn("dynamicData is missing or null for the item:", item);
          }
        });

        // Filter out items with dynamicData errors
        const validParsedData = parsedData.filter((item) => item);
        if (validParsedData !== "undefined" && validParsedData.length !== 0) {
          // let buildInfoTemp = {};
          // let dynamicDataTemp = validParsedData[0]?.dynamicData;

          // let builderName = dynamicDataTemp.find((e) => e.id === "12");
          // if (builderName) {
          //   buildInfoTemp.builderName = builderName.value;
          // }

          // // let builderLogo = dynamicDataTemp.find((e) =>
          // //   e.id === '13'
          // // );
          // // if (builderLogo) {
          // //   buildInfoTemp.builderLogo = builderLogo.value;
          // // }

          // let builderContactName = dynamicDataTemp.find((e) => e.id === "14");
          // if (builderContactName) {
          //   buildInfoTemp.builderContactName = builderContactName.value;
          // }

          // let builderContactEmail = dynamicDataTemp.find((e) => e.id === "15");
          // if (builderContactEmail) {
          //   buildInfoTemp.builderContactEmail = builderContactEmail.value;
          // }

          // let builderContactNumber = dynamicDataTemp.find((e) => e.id === "16");
          // if (builderContactNumber) {
          //   buildInfoTemp.builderContactNumber = builderContactNumber.value;
          // }

          // setBuildInfo(buildInfoTemp);
          //       return getValueById(parsedData, obj.accessorKey);
          //     });
          // // const backgroundTable = builderDetailsBrief.backgrounds
          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId,
            },
          });

          console.log("builder details bg", backgroundTable);
          if (backgroundTable?.data?.backgroundTable) {
            let backgroundTableInfo =
              backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
                if (obj.headerText == "Builder Name") {
                  console.log("Builder Name", obj.accessorKey);
                  buildInfo.builderName = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                // if ( obj.headerText == "Builder Logo" ) {
                //   console.log("Builder Logo", obj.accessorKey);
                //   buildInfo.builderLogo = getValueById(parsedData, obj.accessorKey);
                // }

                if (obj.headerText == "Builder Contact Name") {
                  console.log("Builder Contact Name", obj.accessorKey);
                  buildInfo.builderContactName = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Builder Contact Email") {
                  console.log("Builder Contact Email", obj.accessorKey);
                  buildInfo.builderContactEmail = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                if (obj.headerText == "Builder Contact Number") {
                  console.log("Builder Contact Number", obj.accessorKey);
                  buildInfo.builderContactNumber = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                }

                return getValueById(parsedData, obj.accessorKey);
              });

            console.log("BUILD INFOS: ", buildInfo);
            setBuildInfo(buildInfo);
          } else {
            setBuildInfo(false);
          }
        } else {
          setBuildInfo(false);
        }
      }
    });
  };

  // Get a value by ID based on columns
  function getValueById(data, id) {
    const item = data[0]; // Assuming there's only one item in the array (only 1 row per brief)
    const dynamicData = item.dynamicData;
    const matchingObject = dynamicData?.find((obj) => obj.id === id);

    if (matchingObject) {
      return matchingObject.value;
    } else {
      return null; // ID not found
    }
  }

  function visitBrief(id, name) {
    // history.push(`${AppRoutes.BACKGROUND}/${id}`);
    const back_id = "1";

    if (name !== "Progress of work") {
      history.push(
        `${
          AppRoutes.BACKGROUND
        }/${back_id}/${matter_id}/${id}/?matter_name=${utf8_to_b64(
          matter_name
        )}&client_name=${utf8_to_b64(client_name)}&url=hba`
      );
    }
  }

  const handleChangeDate = async (selected, id, origDate, index) => {
    const data = {
      date:
        moment.utc(selected).toISOString() !== null
          ? String(moment.utc(selected).toISOString())
          : null,
    };
    console.log("DATE", data);

    const origDateString =
      moment.utc(origDate).toISOString() !== null
        ? String(moment.utc(origDate).toISOString())
        : null;

    await updateBackgroundDate(id, data).then(async () => {
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
          briefId: showBriefId,
          activity: "changed the background date",
          field: "Date",
          current:
            data.date !== null ? moment.utc(data.date).toISOString() : null,
          previous: origDateString,
          appModule: "BACKGROUND",
          rowId: id,
        },
      };
      const addActivity = await API.graphql(params).then((result) => {
        console.log("addActivity result", result);
      });
    });

    const updatedOSArray = background.map((p) =>
      p.id === id ? { ...p, date: data.date } : p
    );

    setBackground(updatedOSArray);
  };

  async function updateBackgroundDate(id, data) {
    console.log("updateBackgroundDate:", data);

    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDate,
          variables: {
            id: id,
            date:
              data.date !== null ? moment.utc(data.date).toISOString() : null,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  //DESCRIPTION
  const [textDesc, setTextDesc] = useState("");
  const [saving, setSaving] = useState(false);

  // reference for MMA-2342 GMAIL Integration
  const handleDescContent = (e, description, id, index) => {
    const cleanDescription = handleDescription(description);
    setTextDesc(cleanDescription);
  };

  const handleDescription = (description) => {
    if (description) {
      var parsedDescription = description?.replace(
        /style="[^\"]*"/gi,
        `className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[div]+/g,
        `<div className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[span]+/g,
        `<span className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[p ]+/g,
        `<p className="${""}"`
      );
      parsedDescription = parsedDescription.replace(
        /<[font ]+/g,
        `<p className="${""}"`
      );
      return parsedDescription;
    }
  };

  const handleChangeDesc = (event, index) => {
    setTextDesc(event.currentTarget.textContent);
  };

  const handleSaveDesc = async (e, description, date, id, index) => {
    if (e.target !== document.activeElement) {
      const origDescription = description;
      // let newDescription = e.target.innerHTML;
      let newDescription = e.currentTarget.innerText;
      // newDescription = extractText(newDescription);
      // newDescription = newDescription?.replace(/\&nbsp;/g, "");

      if (textDesc?.length <= 0) {
        const data = {
          description: newDescription,
        };
        const success = await updateBackgroundDesc(id, data).then(async () => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: showBriefId,
              activity: "updated the background description",
              field: "Description",
              current: data.description,
              previous: origDescription,
              appModule: "BACKGROUND",
              rowId: id,
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
          });
        });
        console.log("THIS IS ID", id);
      } else if (textDesc === description) {
        console.log("2nd condition");
      } else {
        console.log("3rd Condition");

        console.log(
          "ROWW ID --->",
          id,
          "\nmatter_id --->",
          matter_id,
          "\nshowBrief",
          showBriefId
        );

        const data = {
          description: newDescription,
        };
        const success = await updateBackgroundDesc(id, data)
          .then(async (res) => {
            console.log(res);
            const params = {
              query: mCreateActivity,
              variables: {
                companyId: localStorage.getItem("companyId"),
                clientMatterId: matter_id,
                briefId: showBriefId,
                activity: "updated the background description",
                field: "Description",
                current: data.description,
                previous: origDescription,
                appModule: "BACKGROUND",
                rowId: id,
              },
            };
            const addActivity = await API.graphql(params).then((result) => {
              console.log("addActivity result", result);
            });
          })
          .catch((err) => {
            console.log("ERR", err);
          });
      }

      let foundIndex = background.findIndex((x) => x.id == id);
      background[foundIndex].description = e.target.innerHTML;
    }
  };

  function extractText(desc) {
    const regex = /(<([^>]+)>)/gi;
    const newString = desc?.replace(regex, "");

    return newString;
  }

  async function updateBackgroundDesc(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDesc,
          variables: {
            id: id,
            description: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }
  //Virtualize
  const bindlistM = useRef();
  const cacheM = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 30,
      minHeight: 30,
    })
  );

  useEffect(() => {
    console.log("DEFAULT LIST IS:", defaultList);
  }, [defaultList]);

  function formatCurrency(amount, locale = "en-US", currency = "USD") {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    });
    return formatter.format(amount);
  }

  // mobile main header variables
  const [headerRead, setHeaderRead] = useState(false);

  // Email Integration Tile States
  const [isOutlookIntegrated, setIsOutlookIntegrated] = useState(null);
  const [connectedGmailAccount, setConnectedGmailAccount] = useState(
    localStorage.getItem("connectedGmailAccount")
  );
  const [connectedOutlookAccount, setConnectedOutlookAccount] = useState(
    localStorage.getItem("connectedOutlookAccount")
  );
  const [recentEmails, setRecentEmails] = useState(null);

  var momentTZ = require("moment-timezone");
  const userTimeZone = momentTZ.tz.guess();


  useEffect(() => {
    if (isOutlookIntegrated !== null) getUnSavedEmails();
    else getSavedAuthToken();
  }, [isOutlookIntegrated]);

  const getSavedAuthToken = async () => {
    // Check if theres an outlook token in DB
    console.log("Email Integration Preview: Getting email token");
    const emailParams = {
      query: qGetCompanyEmailToken,
      variables: {
        companyId: companyId,
      },
    };

    const emailResult = await API.graphql(emailParams);
    console.log(
      "Email Integration Preview: Email Auth Result",
      emailResult.data.company
    );

    const gmailToken = emailResult.data.company.gmailToken;
    if (gmailToken.id) {
      localStorage.setItem("connectedGmailAccount", gmailToken.id);
      setIsOutlookIntegrated(false);
      setConnectedGmailAccount(gmailToken.id);
      return;
    }

    const outlookToken = emailResult.data.company.outlookToken;
    if (outlookToken.email) {
      localStorage.setItem("connectedOutlookAccount", outlookToken.email);
      setIsOutlookIntegrated(true);
      setConnectedOutlookAccount(outlookToken.email);
      return;
    }
  };

  const getUnSavedEmails = async (filters, batchSize = 20, action) => {
    console.log("Email Integration Preview: Getting Unsaved Emails");

    try {
      let result;
      let emailList = [];

      const params = {
        query: isOutlookIntegrated ? qOutlookMessages : qGmailMessagesbyCompany,
        variables: {
          id: companyId,
          email: isOutlookIntegrated
            ? connectedOutlookAccount
            : connectedGmailAccount,
          startDate: momentTZ(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            userTimeZone
          ).format("YYYY-MM-DD"),
          endDate: momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
          folder: "INBOX",
          limit: batchSize,
        },
      };

      result = await API.graphql(params);

      console.log(
        "Email Integration Preview: Received Emails",
        result.data?.company
      );
      emailList = isOutlookIntegrated
        ? result.data?.company?.outlookMessages?.items || []
        : result.data?.company?.gmailMessages?.items || [];

      let filteredEmails = isOutlookIntegrated
        ? emailList?.filter((om) => om.outlookLabelIds?.includes("UNREAD"))
        : emailList?.filter((gm) => gm.gmailLabelIds?.includes("UNREAD"));

      // ONLY DISPLAY 3 EMAILS
      let numberOfEmails = 3;
      let limitedEmails = filteredEmails.slice(0, numberOfEmails);

      console.log("Email Integration Preview: Most Recent 3 Emails", limitedEmails);

      setRecentEmails(limitedEmails);
    } catch (error) {
      console.log("Error fetching emails:", error);
    }
  };

  return userInfo ? (
    <>
      {showToast && (
        <ToastNotification title={alertMessage} error={isToastError} />
      )}
      <div>
        <ScrollToTop
          smooth
          component={
            <BiArrowToTop
              style={{ color: "white", display: "block", margin: "auto" }}
            />
          }
          className="sm:hidden scrollButton"
          style={{ borderRadius: "50%" }}
        />

        {/* MAIN DIV */}
        <div className="py-5 flex-none p-0 bg-white h-auto">
          <div className="flex flex-col gap-8 px-3 py-5 font-open-sans bg-white rounded-lg contentDiv pl-8 pr-8">
            {/* HEADER */}
            {/* <div className="overflow-hidden bg-gray-100 border flex rounded-2xl">
							<div className="flex-1 pl-8 py-14">
								<div className="flex flex-col items-start justify-start w-full h-auto gap-3">
									<div className="text-lg font-semibold text-gray-900">
										{`Good day, ${userInfo.firstName}!`}
									</div>
								</div>
							</div>
							<div className="flex-1">
								<img
									className="h-full"
									src={dashboardGradient}
									alt="rightside-illustration"
								/>
							</div>
						</div> */}
            {/* END OF HEADER */}

            {width > 844 ? (
              // web main header
              <div
                className={`overflow-hidden bg-gradient-to-r from-white via-gray-100 to-blue-100 flex flex-row justify-between items-end rounded-2xl p-12`}
              >
                <div className="w-auto">
                  {/* CIRCLE DESIGN IN LOGO */}
                  <div className="relative">
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.75)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.50)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.25)",
                      }}
                    ></div>
                  </div>

                  <p className="font-semibold text-base mb-4 relative">
                    Welcome to your home construction dashboard!<br></br>
                  </p>
                  <p className="mb-2 relative">
                    You have{" "}
                    <span className="text-red-500 font-semibold">
                      {8 -
                        Object.keys(getStartedProgress).reduce(
                          (count, key) =>
                            getStartedProgress[key] === true
                              ? count + 1
                              : count,
                          0
                        )}
                    </span>{" "}
                    outstanding actions to complete. Make sure to complete{" "}
                    <br></br>
                    the 'Getting Started with HBA' steps located at the top of
                    your page to <br></br>
                    optimise your experience.
                  </p>
                  <div className="relative">
                    <ProgressBar
                      completed={countProgress(getStartedProgress)}
                      bgColor="#1e3a8a"
                      height="10px"
                      animateOnRender={true}
                      labelSize="10px"
                      labelAlignment="outside"
                      labelClassName="text-black ml-2"
                      customLabel={`${countProgress(
                        getStartedProgress
                      )}% Completed`}
                      width="73%"
                    />
                  </div>
                </div>
                <div className="flex items-end">
                  <div className="w-52 justify-start ">
                    {(buildInfo && buildInfo?.builderName) ||
                    buildInfo?.builderContactName ||
                    buildInfo?.builderContactEmail ||
                    buildInfo?.builderContactNumber ||
                    builderLogo ? (
                      <>
                        {builderLogo ? (
                          <img
                            src={builderLogo}
                            className="w-5 h-5 mb-2"
                            alt="logo"
                          ></img>
                        ) : (
                          <FaIcons.FaSquare className="mb-2" />
                        )}
                        <p className="font-semibold mb-2 text-xs">
                          {buildInfo?.builderName
                            ? `${buildInfo?.builderName}`
                            : ""}
                        </p>
                        <p className="text-xs">
                          {buildInfo?.builderContactName
                            ? `${buildInfo?.builderContactName}`
                            : ""}
                        </p>
                        <p className="text-xs">
                          {buildInfo?.builderContactEmail
                            ? `${buildInfo?.builderContactEmail}`
                            : ""}
                        </p>
                        <p className="text-xs">
                          {buildInfo?.builderContactNumber
                            ? `${buildInfo?.builderContactNumber}`
                            : ""}
                        </p>
                        <span
                          className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                          onClick={(e) => {
                            setModalState(modalChoicesEnum.BUILDER_DETAILS);
                            setShowRightPanel(true);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ) : (
                      <>
                        <p className="text-xs text-gray-400">
                          Help us keep track of <br></br>
                          your building progress by <br></br>
                          providing us with your builder's <br></br>
                          contact details
                        </p>
                        <span
                          className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                          onClick={(e) => {
                            setModalState(modalChoicesEnum.BUILDER_DETAILS);
                            setShowRightPanel(true);
                          }}
                        >
                          Add
                        </span>
                      </>
                    )}
                  </div>
                  <div className="ml-8">
                    {contractInfo?.contractStartDate ||
                    contractInfo?.contractPrice ||
                    contractInfo?.contractValue ? (
                      <>
                        <LiaIcons.LiaFileContractSolid className="mb-4" />
                        <div className="flex">
                          <div>
                            {contractInfo.contractStartDate && (
                              <p className="text-xs font-semibold">
                                Contract Start Date:{" "}
                              </p>
                            )}
                            {/* <p className="text-xs font-semibold">
                              Days to Completion:{" "}
                            </p> */}
                            <p className="text-xs font-semibold">
                              Contract Price:{" "}
                            </p>
                            {/* <p className="text-xs font-semibold">
                              Contract Value:{" "}
                            </p> */}
                          </div>
                          <div className="ml-4">
                            {contractInfo.contractStartDate && (
                              <p className="text-xs text-right">
                                {dateFormatter(contractInfo.contractStartDate)}
                              </p>
                            )}
                            {/* <p className="text-xs text-right">509</p> */}
                            <p className="text-xs text-right">
                              {contractInfo.contractPrice !== null
                                ? formatCurrency(contractInfo.contractPrice)
                                : formatCurrency(0)}
                            </p>
                            {/* <p className="text-xs text-right">
                              {contractInfo.contractValue !== null
                                ? formatCurrency(contractInfo.contractValue)
                                : formatCurrency(0)}
                            </p> */}
                          </div>
                        </div>

                        <span
                          className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                          onClick={(e) => {
                            setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                            setShowRightPanel(true);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ) : (
                      <>
                        <p className="text-xs text-gray-400">
                          Provide your contract details to <br></br>
                          allow us to assist you in tracking <br></br>
                          important milestones and dates in <br></br>
                          your construction journey
                        </p>
                        <span
                          className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                          onClick={(e) => {
                            setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                            setShowRightPanel(true);
                          }}
                        >
                          Add
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              //mobile main header
              <div
                className={`overflow-hidden bg-gradient-to-r from-white via-gray-100 to-blue-100 flex flex-col rounded-2xl p-8`}
              >
                <div className="w-auto">
                  {/* CIRCLE DESIGN IN LOGO */}
                  <div className="relative">
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.75)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.50)",
                      }}
                    ></div>
                    <div
                      className="absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                      style={{
                        borderColor: "rgb(243, 244, 246, 0.25)",
                      }}
                    ></div>
                  </div>

                  <p className="font-semibold text-base mb-2 relative font-open-sans">
                    Welcome to your home construction dashboard!<br></br>
                  </p>
                  <p className="mb-2 relative text-xs font-open-sans font-medium">
                    You have{" "}
                    <span className="text-red-500 text-xs font-bold">
                      {8 -
                        Object.keys(getStartedProgress).reduce(
                          (count, key) =>
                            getStartedProgress[key] === true
                              ? count + 1
                              : count,
                          0
                        )}
                    </span>{" "}
                    outstanding actions to complete. Tackling this task will
                    provide you with a clearer, more organised view of your
                    project's progress.
                  </p>
                  <div className="relative">
                    <ProgressBar
                      completed={countProgress(getStartedProgress)}
                      bgColor="#1e3a8a"
                      height="8px"
                      animateOnRender={true}
                      labelSize="10px"
                      labelAlignment="outside"
                      labelClassName="text-black ml-2 text-xs font-normal font-open-sans"
                      customLabel={`${countProgress(
                        getStartedProgress
                      )}% Completed`}
                      width="60%"
                    />
                  </div>
                </div>
                {!headerRead ? (
                  //read more button
                  <Button
                    variant={"hbBlue"}
                    className="py-2 cursor-pointer mt-8"
                    block={"full"}
                    onClick={() => setHeaderRead(!headerRead)}
                  >
                    <div className="flex items-center">
                      <span className="font-open-sans">Read more</span>
                      <FaChevronRight className="ml-2 text-sm" />
                    </div>
                  </Button>
                ) : (
                  <div className="flex flex-col mt-8">
                    <div className=" ">
                      {(buildInfo && buildInfo?.builderName) ||
                      buildInfo?.builderContactName ||
                      buildInfo?.builderContactEmail ||
                      buildInfo?.builderContactNumber ||
                      builderLogo ? (
                        <>
                          {builderLogo ? (
                            <img
                              src={builderLogo}
                              className="w-7 h-7"
                              alt="logo"
                            ></img>
                          ) : (
                            <FaIcons.FaSquare className="" />
                          )}
                          <p className="font-semibold mt-2 text-xs mb-1 font-open-sans">
                            {buildInfo?.builderName
                              ? `${buildInfo?.builderName}`
                              : ""}
                          </p>
                          <p className="text-xs mb-1 font-normal font-open-sans">
                            {buildInfo?.builderContactName
                              ? `${buildInfo?.builderContactName}`
                              : ""}
                          </p>
                          <p className="text-xs mb-1 font-normal font-open-sans">
                            {buildInfo?.builderContactEmail
                              ? `${buildInfo?.builderContactEmail}`
                              : ""}
                          </p>
                          <p className="text-xs mb-1 font-normal font-open-sans">
                            {buildInfo?.builderContactNumber
                              ? `${buildInfo?.builderContactNumber}`
                              : ""}
                          </p>

                          <span
                            className="text-xs text-blue-900 mt-2 font-medium cursor-pointer hover:text-blue-400"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.BUILDER_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Edit
                          </span>
                        </>
                      ) : (
                        <div className="flex flex-row justify-between font-open-sans items-center">
                          <p className="text-xs font-normal text-gray-500 font-open-sans">
                            Help us keep track of your building progress by
                            providing us with your builder's contact details
                          </p>
                          <Button
                            variant={"hbBlue"}
                            className="cursor-pointer text-xs px-4 py-2 rounded-lg ml-6"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.BUILDER_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      {contractInfo?.contractStartDate ||
                      contractInfo?.contractPrice ||
                      contractInfo?.contractValue ? (
                        <div>
                          <img src={contractsIcon} alt="Contracts Icon" />
                          <div className="flex flex-row justify-between mt-2">
                            <div>
                              <p className="text-xs mb-1 font-semibold">
                                Contract Start Date:
                              </p>
                              {/* <p className="text-xs mb-1 font-semibold">
                                Days to Completion:
                              </p> */}
                              <p className="text-xs mb-1 font-semibold">
                                Contract Price:
                              </p>
                              {/* <p className="text-xs mb-1 font-semibold">
                                Contract Value:
                              </p> */}
                            </div>
                            <div className="ml-4">
                              <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                {contractInfo.contractStartDate
                                  ? dateFormatter(
                                      contractInfo.contractStartDate
                                    )
                                  : "Day Month Year"}
                              </p>
                              {/* <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                509
                              </p> */}
                              <p className="text-xs mb-1 font-normal text-right font-open-sans">
                                {contractInfo.contractPrice &&
                                contractInfo.contractPrice !== null
                                  ? formatCurrency(contractInfo.contractPrice)
                                  : formatCurrency(0)}
                              </p>
                              {/* <p className="text-xs font-normal text-right font-open-sans">
                                {contractInfo.contractPrice && contractInfo.contractValue !== null
                                  ? formatCurrency(contractInfo.contractValue)
                                  : formatCurrency(0)}
                              </p> */}
                            </div>
                          </div>

                          <span
                            className="text-xs text-blue-900 font-medium cursor-pointer hover:text-blue-400"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Edit
                          </span>
                        </div>
                      ) : (
                        <div className="flex flex-row justify-between font-open-sans items-center">
                          <p className="text-xs font-normal text-gray-500 font-open-sans">
                            Provide your contract details to allow us to assist
                            you in tracking important milestones and dates in
                            your construction journey
                          </p>
                          <Button
                            variant={"hbBlue"}
                            className="cursor-pointer text-xs px-4 py-2 rounded-lg ml-6"
                            onClick={(e) => {
                              setModalState(modalChoicesEnum.CONTRACT_DETAILS);
                              setShowRightPanel(true);
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                    <Button
                      variant={"hbBlue"}
                      className="py-2 cursor-pointer mt-8"
                      block={"full"}
                      onClick={() => setHeaderRead(!headerRead)}
                    >
                      <div className="flex items-center">
                        <span className="font-open-sans">Read less</span>
                        <FaChevronDown className="ml-2 text-sm" />
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              // end of mobile main header
            )}

            {/* GET STARTED WITH HBA */}

            {!isMobileDevice(width) && (
              <GetStartedTile
                isInitDone={isInitDone}
                matter_id={matter_id}
                showPanel={showPanel}
                setShowPanel={setShowPanel}
                scrollToSection={scrollToSection}
                getStartedStates={{
                  getStartedProgress,
                  setGetStartedProgress,
                }}
                infoDetails={{
                  buildInfo,
                  contractInfo,
                  defaultList,
                }}
                infoRefs={{
                  propertyDetailsRef,
                  propertyPhotoRef,
                  completionDateRef,
                  invoicesRef,
                  variationsRef,
                  uploadContractsRef,
                }}
                infoModal={{
                  modalChoicesEnum,
                  setShowRightPanel,
                  setModalState,
                }}
                setTilePlulse={setTilePlulse}
              />
            )}

            {/* END OF GET STARTED WITH HBA */}

            {/* BRIEF TILES */}
            <div
              className={`flex rounded-1xl h-full ${
                width < 844 ? "flex-wrap" : "px-11 py-8 bg-gray-100  border"
              }`}
            >
              {/* LEFT SIDE */}
              <div
                className={`h-full ${
                  hideChecklist ? "w-full" : width > 1024 ? "w-full" : ""
                }`}
              >
                <div
                  className="flex w-full "
                  style={
                    width < 844
                      ? {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                      : null
                  }
                >
                  <div
                    className={`flex flex-col rounded-2xl overflow-hidden
                        ${width > 844 ? "w-1/3 mr-2" : "w-full"}`}
                  >
                    <EmailInteg
                      width={width}
                      isOutlookIntegrated={isOutlookIntegrated}
                      recentEmails={recentEmails}
                      redirectUrl={AppRoutes.INBOX}
                    />
                    {defaultList?.map((item) => {
                      const isSiteDiary = item.name === "Site Diary";
                      if (isSiteDiary) {
                        return (
                          <div>
                            {isSiteDiary && (
                              <div className={`mb-2 w-full`}>
                                <SiteDiary
                                  briefId={item.id}
                                  redirectUrl={`${
                                    AppRoutes.BACKGROUND
                                  }/${1}/${matter_id}/${
                                    item.id
                                  }/?matter_name=${utf8_to_b64(
                                    matter_name
                                  )}&client_name=${utf8_to_b64(
                                    client_name
                                  )}&url=hba`}
                                  getBackgrounds={getBackgrounds}
                                  background={background}
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}

                    <div className={`w-full ${width < 844 ? "mt-6" : "mb-2"}`}>
                      <TaskList
                        redirectUrl={AppRoutes.TASKS}
                        taskListData={taskListData}
                        taskCount={taskCount}
                        oTasks={oTasks}
                        cTasks={cTasks}
                      />
                    </div>

                    {defaultList?.map((item) => {
                      const isProgressOfWork = item.name === "Progress of work";

                      if (isProgressOfWork) {
                        return (
                          <div
                            className={`${
                              width < 844 ? "mt-6" : "mb-2"
                            } rounded`}
                          >
                            {isProgressOfWork && (
                              <div key={`brief-${item.id}`} data-info={item.id}>
                                <div
                                  onClick={() => visitBrief(item.id, item.name)}
                                >
                                  {tilePulse === "ProgressOfWork" ? (
                                    <PulsingBorderDiv>
                                      <ProgressOfWork
                                        items={item}
                                        completionDateRef={completionDateRef}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          item.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}`}
                                        handleChangeGetStartedProgress={
                                          handleChangeGetStartedProgress
                                        }
                                      />
                                    </PulsingBorderDiv>
                                  ) : (
                                    <ProgressOfWork
                                      items={item}
                                      completionDateRef={completionDateRef}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        item.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                      handleChangeGetStartedProgress={
                                        handleChangeGetStartedProgress
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>

                  {/* Render TaskList only once, right after SiteDiary or as first item if ProgressOfWork */}

                  <div
                    className={`h-full
                  ${width > 844 ? "w-2/3" : "w-full"}
                `}
                  >
                    {defaultList?.map(
                      (items) =>
                        items.name === "Progress Photos" && (
                          <>
                            {/* PROGRESS PHOTOS */}
                            {/* Right side ProgressPhotos component */}
                            {items.name === "Progress Photos" && (
                              <div
                                className={`flex-grow rounded-2xl ${
                                  width < 844
                                    ? "w-full mt-6"
                                    : "w-full max-h-full"
                                }`}
                                ref={propertyPhotoRef}
                                // style={
                                //   width < 844 ?
                                //   {
                                //     width:"75vw",
                                //     marginTop:"1.5rem"
                                //   } :
                                //   {
                                //     width: "53%",
                                //     maxHeight: "100%"
                                //   }
                                // }
                              >
                                {tilePulse === "ProgressPhoto" ? (
                                  <PulsingBorderDiv>
                                    <ProgressPhotos
                                      //visitBrief={visitBrief}
                                      progressPhotosDetails={
                                        progressPhotosDetails
                                      }
                                      setShowRightPanel={setShowRightPanel}
                                      setModalState={setModalState}
                                      modalChoicesEnum={modalChoicesEnum}
                                      progressPhotoBrief={progressPhotoBrief}
                                      handleUploadLink={handleUploadLink}
                                      handleProgressPhotoUpload={
                                        handleProgressPhotoUpload
                                      }
                                      matterId={matterId}
                                      uploadImage={uploadImage}
                                      setUploadImage={setUploadImage}
                                      imageURL={imageURL}
                                      setImageURL={setImageURL}
                                      datePicked={datePicked}
                                      setDatePicked={setDatePicked}
                                      description={description}
                                      setDescription={setDescription}
                                      uploadedFiles={uploadedFiles}
                                      setUploadedFiles={setUploadedFiles}
                                      invalidFiles={invalidFiles}
                                      setInvalidFiles={setInvalidFiles}
                                      selectedFiles={selectedFiles}
                                      _setSelectedFiles={_setSelectedFiles}
                                      uploadStart={uploadStart}
                                      setUploadStart={setUploadStart}
                                      photos={photos}
                                      setPhotos={setPhotos}
                                      handleChangeGetStartedProgress={
                                        handleChangeGetStartedProgress
                                      }
                                      isImageFile={isImageFile}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        items.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                    />
                                  </PulsingBorderDiv>
                                ) : (
                                  <ProgressPhotos
                                    //visitBrief={visitBrief}
                                    progressPhotosDetails={
                                      progressPhotosDetails
                                    }
                                    setShowRightPanel={setShowRightPanel}
                                    setModalState={setModalState}
                                    modalChoicesEnum={modalChoicesEnum}
                                    progressPhotoBrief={progressPhotoBrief}
                                    handleUploadLink={handleUploadLink}
                                    handleProgressPhotoUpload={
                                      handleProgressPhotoUpload
                                    }
                                    matterId={matterId}
                                    uploadImage={uploadImage}
                                    setUploadImage={setUploadImage}
                                    imageURL={imageURL}
                                    setImageURL={setImageURL}
                                    datePicked={datePicked}
                                    setDatePicked={setDatePicked}
                                    description={description}
                                    setDescription={setDescription}
                                    uploadedFiles={uploadedFiles}
                                    setUploadedFiles={setUploadedFiles}
                                    invalidFiles={invalidFiles}
                                    setInvalidFiles={setInvalidFiles}
                                    selectedFiles={selectedFiles}
                                    _setSelectedFiles={_setSelectedFiles}
                                    uploadStart={uploadStart}
                                    setUploadStart={setUploadStart}
                                    photos={photos}
                                    setPhotos={setPhotos}
                                    handleChangeGetStartedProgress={
                                      handleChangeGetStartedProgress
                                    }
                                    isImageFile={isImageFile}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      items.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                  />
                                )}
                              </div>
                            )}
                            {/* END OF PROGRESS PHOTOS */}
                          </>
                        )
                    )}
                    <div
                      className={`flex mt-6 gap-2 w-full`}
                      style={
                        width < 844
                          ? {
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                          : null
                      }
                    >
                      <>
                        <div
                          className={`h-full
                    ${width > 844 ? "w-2/3" : "w-full"}
                  `}
                        >
                          {defaultList?.map(
                            (items) =>
                              items.name === "Invoices" && (
                                <div
                                  className={`h-full mb-5 rounded cursor-pointer`}
                                  key={`brief-${items.id}`}
                                  data-info={items.id}
                                >
                                  {tilePulse === "Invoices" ? (
                                    <PulsingBorderDiv>
                                      <div
                                        onClick={(e) =>
                                          visitBrief(items.id, items.name)
                                        }
                                      >
                                        <Invoices
                                          items={items}
                                          invoicesRef={invoicesRef}
                                          redirectUrl={`${
                                            AppRoutes.BACKGROUND
                                          }/${1}/${matter_id}/${
                                            items.id
                                          }/?matter_name=${utf8_to_b64(
                                            matter_name
                                          )}&client_name=${utf8_to_b64(
                                            client_name
                                          )}`}
                                          handleChangeGetStartedProgress={
                                            handleChangeGetStartedProgress
                                          }
                                        />
                                      </div>
                                    </PulsingBorderDiv>
                                  ) : (
                                    <div
                                      onClick={(e) =>
                                        visitBrief(items.id, items.name)
                                      }
                                    >
                                      <Invoices
                                        items={items}
                                        invoicesRef={invoicesRef}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          items.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}`}
                                        handleChangeGetStartedProgress={
                                          handleChangeGetStartedProgress
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                          )}

                          {defaultList?.map((item) => {
                            const isBudget = item.name === "Budget";
                            if (isBudget) {
                              return (
                                <div
                                  className={`h-full -mt-3 mb-5 rounded cursor-pointer`}
                                  key={`brief-${item.id}`}
                                  data-info={item.id}
                                >
                                  <Budget
                                    briefId={item.id}
                                    budgetRef={budgetRef}
                                    redirectUrl={`${
                                      AppRoutes.BACKGROUND
                                    }/${1}/${matter_id}/${
                                      item.id
                                    }/?matter_name=${utf8_to_b64(
                                      matter_name
                                    )}&client_name=${utf8_to_b64(client_name)}`}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div
                          className={`h-full ${
                            width > 844 ? "w-2/3" : "w-full"
                          }`}
                        >
                          {defaultList?.map(
                            (items) =>
                              items.name === "Variations" && (
                                <div
                                  className={`h-full mb-5 rounded cursor-pointer`}
                                  key={`brief-${items.id}`}
                                  data-info={items.id}
                                >
                                  {tilePulse === "Variations" ? (
                                    <PulsingBorderDiv>
                                      <div
                                        onClick={(e) =>
                                          visitBrief(items.id, items.name)
                                        }
                                      >
                                        <Variations
                                          briefId={items.id}
                                          variationsRef={variationsRef}
                                          redirectUrl={`${
                                            AppRoutes.BACKGROUND
                                          }/${1}/${matter_id}/${
                                            items.id
                                          }/?matter_name=${utf8_to_b64(
                                            matter_name
                                          )}&client_name=${utf8_to_b64(
                                            client_name
                                          )}`}
                                        />
                                      </div>
                                    </PulsingBorderDiv>
                                  ) : (
                                    <div
                                      onClick={(e) =>
                                        visitBrief(items.id, items.name)
                                      }
                                    >
                                      <Variations
                                        briefId={items.id}
                                        variationsRef={variationsRef}
                                        redirectUrl={`${
                                          AppRoutes.BACKGROUND
                                        }/${1}/${matter_id}/${
                                          items.id
                                        }/?matter_name=${utf8_to_b64(
                                          matter_name
                                        )}&client_name=${utf8_to_b64(
                                          client_name
                                        )}`}
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                          )}
                          {defaultList?.map(
                            (items) =>
                              items.name === "Provisional Sum" && (
                                <div
                                  className={`h-full mb-5 -mt-3 rounded cursor-pointer`}
                                  key={`brief-${items.id}`}
                                  data-info={items.id}
                                >
                                  <div
                                    onClick={(e) =>
                                      visitBrief(items.id, items.name)
                                    }
                                  >
                                    <ProvisionalSum
                                      briefId={items.id}
                                      provisionalSumRef={provisionalSumRef}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        items.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                    />
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div
                  className="flex grid-cols-3 gap-3 mt-6"
                  style={
                    width < 844
                      ? {
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                      : null
                  }
                >
                  {defaultList && !loading ? (
                    <>
                      {/* DELAY CLAIMS, PROGRESS CLAIMS, VARIATIONS */}
                      {/* {defaultList?.map(
                        (items) =>
                          (items.name === "Progress of work" ||
                            items.name === "Invoices" ||
                            items.name === "Variations") && (
                            <>
                              {items.name === "Progress of work" && (
                                <div
                                  className={`h-full mb-5 rounded cursor-pointer w-full`}
                                  key={`brief-${items.id}`}
                                  data-info={items.id}
                                >
                                  <div
                                    onClick={(e) =>
                                      visitBrief(items.id, items.name)
                                    }
                                  >
                                    <ProgressOfWork
                                      items={items}
                                      completionDateRef={completionDateRef}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        items.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                      handleChangeGetStartedProgress={
                                        handleChangeGetStartedProgress
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {items.name === "Invoices" && (
                                <div
                                  className={`h-full mb-5 rounded cursor-pointer w-full`}
                                  key={`brief-${items.id}`}
                                  data-info={items.id}
                                >
                                  <div
                                    onClick={(e) =>
                                      visitBrief(items.id, items.name)
                                    }
                                  >
                                    <Invoices
                                      items={items}
                                      invoicesRef={invoicesRef}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        items.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                      handleChangeGetStartedProgress={
                                        handleChangeGetStartedProgress
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {items.name === "Variations" && (
                                <div
                                  className={`h-full mb-5 rounded cursor-pointer w-full`}
                                  key={`brief-${items.id}`}
                                  data-info={items.id}
                                >
                                  <div
                                    onClick={(e) =>
                                      visitBrief(items.id, items.name)
                                    }
                                  >
                                    <Variations
                                      briefId={items.id}
                                      variationsRef={variationsRef}
                                      redirectUrl={`${
                                        AppRoutes.BACKGROUND
                                      }/${1}/${matter_id}/${
                                        items.id
                                      }/?matter_name=${utf8_to_b64(
                                        matter_name
                                      )}&client_name=${utf8_to_b64(
                                        client_name
                                      )}`}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )
                      )} */}
                      {/* END OF DELAY CLAIMS, PROGRESS CLAIMS, VARIATIONS */}
                    </>
                  ) : (
                    <div className="p-5"> Loading List... </div>
                  )}
                </div>
              </div>
              {/* END OF LEFT SIDE */}

              {/* RIGHT SIDE / CHECK LIST */}
              <div className={width < 844 ? "w-full" : "ml-4 w-1/4"}>
                {tilePulse === "ProjectLocation" ? (
                  <PulsingBorderDiv>
                    <div
                      className={`w-full h-98 shadow-md rounded mb-3`}
                      style={
                        width < 844
                          ? {
                              width: "100%",
                            }
                          : null
                      }
                      id={"ProjectLocation"}
                      ref={propertyDetailsRef}
                    >
                      <ProjectLocation
                        projectLocationBrief={projectLocationBrief}
                        userInfo={userInfo}
                        handleChangeGetStartedProgress={
                          handleChangeGetStartedProgress
                        }
                        invalidFiles={invalidFiles}
                        rejectFiles={rejectFiles}
                        clientMatterId={matter_id}
                        projectLocationPhoto={projectLocationPhoto}
                        triggerToast={triggerToast}
                        modalChoicesEnum={modalChoicesEnum}
                        setModalState={setModalState}
                        setShowRightPanel={setShowRightPanel}
                        isInitDone={isInitDone}
                      />
                    </div>
                  </PulsingBorderDiv>
                ) : (
                  <div
                    className={`w-full h-98 shadow-md rounded mb-3`}
                    style={
                      width < 844
                        ? {
                            width: "100%",
                          }
                        : null
                    }
                    id={"ProjectLocation"}
                    ref={propertyDetailsRef}
                  >
                    <ProjectLocation
                      projectLocationBrief={projectLocationBrief}
                      userInfo={userInfo}
                      handleChangeGetStartedProgress={
                        handleChangeGetStartedProgress
                      }
                      invalidFiles={invalidFiles}
                      rejectFiles={rejectFiles}
                      clientMatterId={matter_id}
                      projectLocationPhoto={projectLocationPhoto}
                      triggerToast={triggerToast}
                      modalChoicesEnum={modalChoicesEnum}
                      setModalState={setModalState}
                      setShowRightPanel={setShowRightPanel}
                      isInitDone={isInitDone}
                    />
                  </div>
                )}

                {/* CONTRACTS */}
                {defaultList?.map((items) =>
                  items.name === "Contracts" ? (
                    <>
                      {tilePulse === "Contracts" ? (
                        <PulsingBorderDiv>
                          <div className="mb-5 rounded cursor-pointer w-full">
                            <Contracts
                              visitBrief={visitBrief}
                              uploadContractsRef={uploadContractsRef}
                              briefDetails={defaultList.filter((item) => {
                                return item?.name == "Contracts";
                              })}
                              isComplete={
                                contractTC?.length > 0 &&
                                archiDrawings?.length > 0 &&
                                engDrawings?.length > 0 &&
                                specifications?.length > 0 &&
                                buildInsurance?.length > 0
                              }
                            />
                          </div>
                        </PulsingBorderDiv>
                      ) : (
                        <div className="mb-5 rounded cursor-pointer w-full">
                          <Contracts
                            visitBrief={visitBrief}
                            uploadContractsRef={uploadContractsRef}
                            briefDetails={defaultList.filter((item) => {
                              return item?.name == "Contracts";
                            })}
                            isComplete={
                              contractTC?.length > 0 &&
                              archiDrawings?.length > 0 &&
                              engDrawings?.length > 0 &&
                              specifications?.length > 0 &&
                              buildInsurance?.length > 0
                            }
                          />
                        </div>
                      )}
                    </>
                  ) : null
                )}
              </div>
              {/* END OF CONTRACTS */}
            </div>
            {/* END OF RIGHT SIDE */}

            {/* END OF BRIEF TILES */}
          </div>

          {showRightPanel ? (
            <RightPanel
              modalChoicesEnum={modalChoicesEnum}
              setShowRightPanel={setShowRightPanel}
              buildInfo={buildInfo}
              setBuildInfo={setBuildInfo}
              contractInfo={contractInfo}
              setContractInfo={setContractInfo}
              whichModal={modalState}
              progressPhotoBrief={progressPhotoBrief}
              matterId={matterId}
              uploadImage={uploadImage}
              setUploadImage={setUploadImage}
              imageURL={imageURL}
              setImageURL={setImageURL}
              handleUploadLink={handleUploadLink}
              handleProgressPhotoUpload={handleProgressPhotoUpload}
              handleUpload={handleUpload}
              datePicked={datePicked}
              setDatePicked={setDatePicked}
              description={description}
              setDescription={setDescription}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              logoURL={logoURL}
              setLogoURL={setLogoURL}
              clientMatterId={matter_id}
              saveButtonClicked={saveButtonClicked}
              handleChangeGetStartedProgress={handleChangeGetStartedProgress}
              handleLogoDrop={handleLogoDrop}
              builderLogo={builderLogo}
              setBuilderLogo={setBuilderLogo}
              logo={logo}
              setLogo={setLogo}
            />
          ) : (
            <></>
          )}
        </div>
        {/* END OF MAIN DIV */}
      </div>
    </>
  ) : null;
}
