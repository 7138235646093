import React, { useState, useRef, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { Storage, API } from "aws-amplify";
import { BiCloudUpload } from "react-icons/bi";
import { AiOutlineLoading, AiOutlineCheck } from "react-icons/ai";
import { chunkLargePdfsForTextract, handleTextractSubscription } from "../../shared/utils/textExtractionUtils";
import { PDFDocument, error } from "pdf-lib";

const BackgroundPopupFileUpload = ({
  briefId,
  matterId,
  item,
  setIsSaving,
  displayFiles,
  setDisplayFiles,
  triggerToast,
  //   setIsSaving
}) => {
  const rejectFiles = [
    "xlsx",
    "xls",
    "docx",
    "doc",
    ".config",
    ".exe",
    ".7z",
    ".dll",
    ".exe1",
    ".zvz",
  ];
  const hiddenFileInput = useRef(null);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [uploadStart, setUploadStart] = useState(false);
  const [filesReadyForUpload, setFilesReadyForUpload] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [columnIndex, setColumnIndex] = useState(0);
  const [uploadBtnClicked2, setUploadBtnClicked2] = useState(false);
  const [openFilesPopup, setOpenFilesPopup] = useState(false);
  const [selectedRowId, setSelectedRowID] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [dropTrigger, setDropTrigger] = useState([]);
  const [directDrop, setDirectDrop] = useState([]);
  const [percent, setPercent] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [extractingFile, setExtractingFile] = useState(false);
  const [progressCircleValue, setProgressCircleValue] = useState(0);

  const myCurrentRef = useRef(selectedFiles);
  const subscriptionRef = useRef(null);

  useEffect(() => {
    getFilesUploaded();
  }, []);

  const updateSelectedFiles = (data) => {
    myCurrentRef.current = data;
    setSelectedFiles(data);
  };

  const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

  //append in existing
  const qlistBackgroundFiles = `
  query getBackgroundByID($id: ID) {
    background(id: $id) {
      id
      date
      files {
        items {
          createdAt
          id
          details
          name
          gmailMessageId
          s3ObjectKey
          order
          size
          extractedFileContent
        }
      }
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

  const BACKGROUND_BY_BRIEF_ID_QUERY_EXTRACT = `
      query getBackgroundFile($backgroundId: ID, $fileId: ID) {
        backgroundFile(fileId: $fileId, backgroundId: $backgroundId) {
          extractedFileContent
        } 
      }
    `;

  useEffect(() => {
    var counter = 0;
    var dCounter = 0;
    if (dropTrigger?.length > 0 && counter === 0) {
      onSelectFile(dropTrigger);
      counter = 1;
    }

    if (directDrop?.length > 0 && dCounter === 0) {
      onSelectFile(directDrop);
      dCounter = 1;
    }
  }, [dropTrigger, directDrop]);

  const onSelectFile = (retrievedFiles) => {
    if (!retrievedFiles || retrievedFiles.length === 0) {
      return;
    }
    const tempArr = [];

    [...retrievedFiles].forEach((file) => {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];
      const fileType = file.type;

      const validFileTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];

      if (!validFileTypes.includes(fileType)) {
        setInvalidFiles((prev) => [
          ...prev,
          {
            data: file,
            url: URL.createObjectURL(file),
          },
        ]);
      } else {
        tempArr.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      }
    });

    updateSelectedFiles([...myCurrentRef.current, ...tempArr]);
    setFilesReadyForUpload(true);
  };

  const handleUpload = async (bucketName) => {
    setUploadStart(true);
    setIsSaving(true);

    var tempArr = [...selectedFiles];
    updateSelectedFiles(tempArr);

    let currDate = Number(new Date());
    var idxx = 0;
    tempArr.map(async (uf, index) => {
      const isDocx = uf.data.name.split(".").pop() === "docx";
      const name = uf.data.name;
      const size = uf.data.size;
      const type = isDocx
        ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        : uf.data.type;
      const key = `${bucketName}/${currDate}${name
        ?.replaceAll(/\s/g, "")
        .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`;
      const orderSelected = idxx;
      const order = idxx;
      idxx++;

      let pageCount; 
      if (!isDocx && type === "application/pdf") {
        // Extract page count for PDF files
        const pdfBytes = await uf.data.arrayBuffer();
        pageCount = await chunkLargePdfsForTextract({pdfBytes, bucketName, name, currDate, type});
      }

      try {
        await Storage.put(key, uf.data, {
          contentType: type,
          ...(pageCount && {
            metadata: {
              "x-amz-meta-page-count": pageCount.toString(),
            },
          }),
          progressCallback(progress) {
            const progressInPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            setPercent((prev) => {
              const newTemp = [...prev];
              const index = newTemp.findIndex(
                (item) => item.name === uf.data.name
              );
              if (index !== -1) {
                newTemp[index].prog = progressInPercentage;
              } else {
                newTemp.push({
                  prog: progressInPercentage,
                  name: uf.data.name,
                });
              }
              return newTemp;
            });
          },
          errorCallback: (err) => {
            console.error("204: Unexpected error while uploading", err);
          },
        })
          .then((fd) => {
            var fileData = {
              s3ObjectKey: fd.key,
              size: parseInt(size),
              type: type,
              name: name.split(".").slice(0, -1).join("."),
              oderSelected: orderSelected,
              order: orderSelected,
            };

            setUploadedFiles((prevState) => ({
              files: [...prevState.files, fileData],
            }));
            setDirectDrop([]);
          })
          .catch((err) => {
            console.error("220: Unexpected error while uploading", err);
          });
      } catch (e) {
        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };
        console.error("228: Unexpected error while uploading", response);
      }
    });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const clickPopupUploadButton = () => {
    handleUpload(matterId);
    setSelectedRowID(item.id);
    setSelectedIndex(columnIndex);
  };

  useEffect(() => {
    const uploadAndCheck = async () => {
      if (
        uploadedFiles.files.length === selectedFiles?.length &&
        selectedFiles?.length !== 0
      ) {
        const result = await handleUploadLink(uploadedFiles);
        if (result === "done") {
          setUploadedFiles({ files: [] });
          updateSelectedFiles([]);
          hiddenFileInput.current && (hiddenFileInput.current.value = "");
          setOpenFilesPopup(false);
          setUploadBtnClicked2(false);
        }
      }
    };

    uploadAndCheck();
  }, [selectedFiles, uploadedFiles]);

  useEffect(() => {
    if (filesReadyForUpload) {
      clickPopupUploadButton();
      setFilesReadyForUpload(false);
    }
  }, [filesReadyForUpload]);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const { files } = e.dataTransfer;

    if (files && files.length) {
      onSelectFile(files);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const progressIntervalRef = useRef(null);
  const currExecutionArn = useRef(null);

  var idTag = [];
  //UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
  const handleUploadLink = async (uf) => {
    await Promise.all(
      uf?.files.map(async (file) => {
        console.log("Uploaded file - ", file);
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matterId,
            briefId: briefId,
            activity: `added a background file named ${file.name}-(${file.size} byte)`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: selectedRowId,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          // console.log("addActivity result", result);
        });
      })
    );
    // console.log("handleUploadLink uf", uf);
    var uploadedFiles = uf.files.map((f) => ({ ...f, matterId: matterId }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
    // console.log("SF",sortedFiles);
    // console.log("AO", addOrder);

    //insert in matter file list
    await bulkCreateMatterFile(addOrder, item.items[0].id);

    setExtractingFile(true);
    // setIsSaving(true)

    // Set progress to 0 initially
    setProgressCircleValue(0);

    // Simulate progress from 0 to 80
    let targetValue = 79.99;
    let duration = 60000 * 6; // Total duration in milliseconds
    let updateInterval = 100; // Interval duration in milliseconds

    let progress = 0;
    const steps = duration / updateInterval; // Total number of steps
    let currentStep = 0;

    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    progressIntervalRef.current = setInterval(() => {
      if (selectedFiles.length === 0 && extractingFile) {
        clearInterval(progressIntervalRef.current);
        return;
      }

      if (currentStep < steps) {
        // Calculate the parabolic increment based on the current step
        const t = currentStep / steps; // Normalized time from 0 to 1
        const easedProgress = targetValue * (1 - Math.pow(1 - t, 7));

        progress = easedProgress;
        currentStep++;

        setProgressCircleValue(parseFloat(progress.toFixed(2)));
        return;
      }
      progress = targetValue; // Ensure we reach exactly the target value

      clearInterval(progressIntervalRef.current);

      setTimeout(() => {
        setProgressCircleValue(targetValue);
        triggerToast("Error occured when analyzing file.", true);
        setExtractingFile(false);
      }, 1000);
    }, updateInterval);

    //set background content
    const result = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: item.items[0].id,
      },
    });

    var newFilesResult = result.data.background.files.items.map(
      ({
        createdAt,
        id,
        name,
        description,
        gmailMessageId,
        s3ObjectKey,
        order,
        size,
        extractedFileContent,
      }) => ({
        createdAt: createdAt,
        id: id,
        name: name,
        description: description,
        gmailMessageId: gmailMessageId,
        s3ObjectKey: s3ObjectKey,
        order: order,
        size: size,
        extractedFileContent: extractedFileContent,
      })
    );

    // Sort the newFilesResult array by createdAt in descending order
    newFilesResult?.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    // Extract the files
    const files = result.data.background.files.items.map((file) => ({
      id: file.id,
      s3ObjectKey: `public/${file.s3ObjectKey}`,
      s3Bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET, // Ensure s3Bucket is set
      ...(file.extractedFileContent && {
        extractedFileContent: file.extractedFileContent,
      }),
    }));

    // Create the final structure with merged files
    const transformedData = [
      {
        backgroundId: item.items[0].id,
        files: files,
      },
    ];

    async function processTransformedData(transformedData) {
      for (let i = transformedData.length - 1; i >= 0; i--) {
        const item = transformedData[i];
        const { backgroundId, files } = item;

        console.log("Files >>> ", item);

        if (!files || files.length === 0) {
          // Remove the whole item if files is empty or null
          transformedData.splice(i, 1);
          continue;
        }

        for (let j = files.length - 1; j >= 0; j--) {
          const { id: fileId, extractedFileContent } = files[j];
          // const result = await getBulkExtractedTextContent(
          //   backgroundId,
          //   fileId
          // );

          if (extractedFileContent !== null && extractedFileContent) {
            // If extractedContent exists, remove the file from the array
            files.splice(j, 1);
          } else if (extractedFileContent === null) {
            console.log(`Error extracting text for fileId: ${fileId}`);
          }
        }

        if (files.length === 0) {
          // Remove the whole item if files is empty after processing
          transformedData.splice(i, 1);
        }
      }
      return transformedData;
    }

    // Call the function with the transformed data
    processTransformedData(transformedData)
      .then(async (updatedData) => {
        // Call textract API
        console.log("updatedData", updatedData);
        await processExtraction(updatedData);
      })
      .catch((error) => {
        console.error(error);
      });

    return "done";
  };

  async function processExtraction(checkBulkExtract) {
    if (!checkBulkExtract || checkBulkExtract.length === 0) {
      console.error("Invalid input: checkBulkExtract is null or empty");
      return;
    }

    const BULK_BACKGROUND_TEXTRACT_MUTATION = `
      mutation bulkBackgroundFileTextract($input: [BackgroundFileTextractInput]!) {
        bulkBackgroundFileTextract(input: $input)
      }`;

    const params = {
      query: BULK_BACKGROUND_TEXTRACT_MUTATION,
      variables: {
        input: checkBulkExtract,
      },
    };

    console.log("Params for GraphQL mutation:", params);

    try {
      const { data } = await API.graphql(params);

      if (data) {
        // console.log("Preparing to extract text files", data);
        const parsed = JSON.parse(data.bulkBackgroundFileTextract);
        // console.log("backgroundFileTextract", parsed);
        const body = JSON.parse(parsed.body);
        // console.log("body", body);

        const executionArn = body.executionArn;
        console.log("executionArn", executionArn);

        currExecutionArn.current = executionArn;

        const response = await handleTextractSubscription(executionArn);

        if (currExecutionArn.current === executionArn) {
          if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
          }

          setProgressCircleValue(100);

          setTimeout(() => {
            setExtractingFile(false);
            setIsSaving(false);
            getFilesUploaded();
          }, 1000);

          return response;
        }

        // const subscription = await API.graphql({
        //   query: ON_TEXTRACT_PUBLISHED,
        //   variables: { executionArn },
        // }).subscribe({
        //   next: async ({ value }) => {
        //     const result = value?.data?.onBackgroundFileTextractPublished;
        //     console.log("Received text extraction response", result);

        //     if (
        //       resultArray.length === 0 &&
        //       result.nextIndex === -1 &&
        //       result.fileId === "[ERROR IN TEXTRACTION]"
        //     ) {
        //       console.error("Error: Failed to extract text from file/s");
        //       setExtractingFile(false);
        //       //   setIsSaving(false);
        //     } else if (currExecutionArn.current !== executionArn) {
        //       console.log("Subscription is outdated");
        //     } else {
        //       resultArray.push(result);

        //       if (result.nextIndex === -1) {
        //         console.log("Text extraction complete", resultArray);

        //         let combinedArray =
        //           combineTextractSubscriptionResponse(resultArray);

        //         console.log("Combined Text Extraction array", combinedArray);

        //         if (progressIntervalRef.current) {
        //           clearInterval(progressIntervalRef.current);
        //         }

        //         setProgressCircleValue(100);

        //         setTimeout(() => {
        //           setExtractingFile(false);
        //           setIsSaving(false);
        //           getFilesUploaded();
        //         }, 1000);

        //         if (subscription) subscription.unsubscribe();

        //         return combinedArray;
        //       }
        //     }
        //   },
        //   error: (error) => console.warn(error),
        // });
      }
    } catch (error) {
      console.error("Error processing extraction", error);
      //   setIsSaving(false);
      setExtractingFile(false);
    }
  }

  function combineTextractSubscriptionResponse(arr) {
    // Create a map to store combined file contents
    const fileMap = new Map();

    // Iterate through the array of objects
    arr.forEach(({ fileId, extractedFileContent, nextIndex }) => {
      // If fileId exists in the map, append the content
      if (fileMap.has(fileId)) {
        const fileData = fileMap.get(fileId);
        fileData.push({ extractedFileContent, nextIndex });
      } else {
        // Otherwise, add a new entry for the fileId
        fileMap.set(fileId, [{ extractedFileContent, nextIndex }]);
      }
    });

    // Combine the content for each fileId, placing the -1 (last element) at the end
    const result = [];
    fileMap.forEach((fileData, fileId) => {
      // Separate the last element
      const lastElement = fileData.find((item) => item.nextIndex === -1);
      // Filter out the last element and sort the remaining elements
      const sortedData = fileData
        .filter((item) => item.nextIndex !== -1)
        .sort((a, b) => a.nextIndex - b.nextIndex);

      // Combine all the contents, including the last element at the end
      let combinedContent = sortedData
        .map((item) => item.extractedFileContent)
        .join("");

      if (lastElement) {
        combinedContent += lastElement.extractedFileContent;
      }

      result.push({ fileId, extractedFileContent: combinedContent });
    });

    return result;
  }

  const getBulkExtractedTextContent = async (backgroundId, fileId) => {
    try {
      const {
        data: {
          backgroundFile: { extractedFileContent = "" },
        },
      } = await API.graphql({
        query: BACKGROUND_BY_BRIEF_ID_QUERY_EXTRACT,
        variables: {
          backgroundId: backgroundId,
          fileId: fileId,
        },
      });

      console.log(
        `extracted file ${fileId} content >>> ${extractedFileContent}`
      );

      if (extractedFileContent) {
        return extractedFileContent;
      } else {
        console.log("No extracted content found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching extracted file contents:", error);
      return null;
    }
  };

  const mBulkCreateMatterFile = `
    mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
      matterFileBulkCreate(files: $files) {
        id
        name
        order
      }
    }
  `;

  async function bulkCreateMatterFile(param) {
    console.log("bulkCreateMatterFile");

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    if (request.data.matterFileBulkCreate !== null) {
      request.data.matterFileBulkCreate.map((i) => {
        return (idTag = [...idTag, { id: i.id }]);
      });
    }

    const BACKGROUNDFILE_TAG_MUTATION = `
    mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
        backgroundFileTag(backgroundId: $backgroundId, files: $files) {
          id
        }
      }
    `;

    let arrFileResult = [];
    const seen = new Set();

    const backgroundFilesOpt = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: item.items[0].id,
      },
    });

    if (backgroundFilesOpt.data.background.files !== null) {
      arrFileResult = backgroundFilesOpt.data.background.files.items.map(
        ({ id }) => ({
          id: id,
        })
      );

      idTag.push(...arrFileResult);

      const filteredArr = idTag.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });

      await API.graphql({
        query: BACKGROUNDFILE_TAG_MUTATION,
        variables: {
          backgroundId: item.items[0].id,
          files: filteredArr,
        },
      });
    } else {
      await API.graphql({
        query: BACKGROUNDFILE_TAG_MUTATION,
        variables: {
          backgroundId: item.items[0].id,
          files: idTag,
        },
      });
    }
  }

  const getFilesUploaded = () => {
    const backgroundFilesOptReq = API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: item.items[0].id,
      },
    }).then(async (result) => {
      var newFilesResult = result.data.background.files.items?.map(
        ({
          createdAt,
          id,
          name,
          description,
          gmailMessageId,
          s3ObjectKey,
          order,
          size,
          extractedFileContent,
        }) => ({
          createdAt: createdAt,
          id: id,
          name: name,
          description: description,
          gmailMessageId: gmailMessageId,
          s3ObjectKey: s3ObjectKey,
          order: order,
          size: size,
          extractedFileContent: extractedFileContent,
        })
      );

      // Sort the newFilesResult array by createdAt in descending order
      newFilesResult?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      // // Extract the files
      // const promisesArr = result.data.background.files.items.map(async file => {
      //   const result = await getBulkExtractedTextContent(
      //     item.items[0].id,
      //     file.id
      //   );

      //   return {
      //     id: file.id,
      //     name: file.name,
      //     s3ObjectKey: `public/${file.s3ObjectKey}`,
      //     s3Bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET, // Ensure s3Bucket is set
      //     ...(result  ? { extractedFileContent: result } : {})
      //   }
      // });

      // const files = await Promise.all(promisesArr);
      console.log("get updated files >> ", newFilesResult);

      setDisplayFiles(newFilesResult || []);
    });
  };

  return (
    <>
      <input
        onChange={(e) => onSelectFile(e.target.files)}
        ref={hiddenFileInput}
        type="file"
        multiple
        accept="application/pdf, image/png, image/jpeg, image/gif"
        hidden
      />

      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className={`inline-flex flex-wrap w-full px-2 justify-center items-center mx-auto border border-dashed rounded-md transition-colors duration-300 ease-in-out ${
          isDragging ? "bg-gray-200" : "border-cyan-600"
        } hover:bg-gray-100`}
        onClick={handleClick}
      >
        <div className="inline-flex justify-center items-center">
          <BiCloudUpload className="px-2" size={50} />
          <p className="text-sm text-cyan-400 font-semibold">
            Drag & drop your files here or click to browse.
          </p>
        </div>
      </div>

      <div className="mt-2 max-h-40 overflow-y-scroll">
        {selectedFiles?.map((file, index) => (
          <div key={index} className="flex justify-between items-center">
            <p className="pr-4 w-full truncate">{file.data.name}</p>
            <CircularProgressbar
              value={percent[index] ? parseInt(percent[index].prog) : 0}
              text={percent[index] ? `${parseInt(percent[index].prog)}%` : "0%"}
              className="w-7 h-7"
            />
          </div>
        ))}

        {selectedFiles.length === 0 && extractingFile ? (
          <>
            <div className="flex items-center">
              <CircularProgressbar
                value={progressCircleValue}
                text={`${progressCircleValue}%`}
                className="w-8 h-8"
              />
              <span className="ml-2 text-sm font-normal">
                Uploading and extracting content...
              </span>
            </div>
          </>
        ) : null}
      </div>

      {/* Display Successful Files */}
      {displayFiles?.length > 0 && (
        <div className="mt-2 max-h-40 overflow-y-scroll">
          <span className="text-sm font-normal">File Ready</span>
          {displayFiles?.map((file, index) => (
            <div key={index} className="flex items-center">
              <AiOutlineCheck className="text-green-600" />
              <p className="ml-1 pr-4 w-full truncate">{file.name}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BackgroundPopupFileUpload;
