import React, { useEffect, useState } from "react";
// import { pageSelectedLabels } from "./index";
import { GrClose } from "react-icons/gr";
import Button from "../../shared/Button";
import { BsFillTrashFill } from "react-icons/bs";
import { RiAddCircleLine } from "react-icons/ri";
import ToastNotification from "../toast-notification";
import { API } from "aws-amplify";

import "../../assets/styles/multiselect-custom.css";
import "../../assets/styles/filter-labels.css";
import RemoveSummaryModal from "./remove-summary-prompt-modal";

const createPrompt = `
  mutation createPrompt($companyId: ID, $clientMatterId: ID, $name: String, $prompt: String, $description: String) {
    openAIPromptsCreate(
      companyId: $companyId
      name: $name
      prompt: $prompt
      description: $description
      clientMatterId: $clientMatterId
    ) {
      id
      companyId
      name
      description
      prompt
    }
  }
`;

const updatePromptName = `
  mutation updatePrompt($companyId: ID!, $id: ID!, $name: String) {
    openAIPromptsUpdate(
      companyId: $companyId
      id: $id
      name: $name
    ) {
      companyId
      id
      name
      description
      prompt
    }
  }
`;

const updatePromptContent = `
  mutation updatePrompt($companyId: ID!, $id: ID!, $prompt: String) {
    openAIPromptsUpdate(
      companyId: $companyId
      id: $id
      prompt: $prompt
    ) {
      companyId
      id
      name
      description
      prompt
    }
  }
`;

const deletePrompt = `
  mutation deletePrompt($companyId: ID, $id: ID) {
    openAIPromptsDelete(
      companyId: $companyId
      id: $id
    )
  }
`;

export default function PromptsModal({
  openAiPrompts,
  setOpenAiPrompts,
  show,
  handleSetShow,
}) {
  const [objectCountRef, setObjectCountRef] = useState({
    current: 0,
    state: "hidden",
  });
  const [tempPrompts, setTempPrompts] = useState(openAiPrompts || []);
  const [showHideCreatePromptsState, setShowHideCreatePromptsState] =
    useState(false);
  const [loading, setLoading] = useState(false);

  // TOAST STATES
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isToastError, setIsToastError] = useState(false);
  const [showRemoveSumModal, setShowRemoveSumModal] = useState(false);
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    if (openAiPrompts && openAiPrompts !== tempPrompts)
      setTempPrompts(openAiPrompts);
  }, [openAiPrompts]);

  const handleShowHideCreatePrompt = () => {
    if (showHideCreatePromptsState) {
      setShowHideCreatePromptsState(false);
    } else {
      setShowHideCreatePromptsState(true);
    }
  };

  function handleHide() {
    setOpenAiPrompts(tempPrompts);
    handleSetShow();
  }

  const handleToastNotification = (message, isError) => {
    setResultMessage(message);
    setShowToast(true);
    setIsToastError(isError);
    setTimeout(() => {
      hideToast();
    }, 2000);
  };

  const hideToast = () => {
    setShowToast(false);
  };

  function freezeBodyScroll() {
    if (objectCountRef.current === 0 && objectCountRef.state === "hidden") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 1, state: "auto" });
  }

  function freeBodyScroll() {
    if (objectCountRef.current === 1 && objectCountRef.state === "auto") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 0, state: "hidden" });
  }

  useEffect(() => {
    if (show) {
      freezeBodyScroll();
    } else {
      freeBodyScroll();
    }
  }, [show]);

  async function handleDelete(id) {
    console.log("Delete item", id);

    if (id) {
      setLoading(true);
      const deletePromptRes = await API.graphql({
        query: deletePrompt,
        variables: {
          companyId: localStorage.getItem("companyId"),
          id,
        },
      });

      setTempPrompts((prevPrompts) =>
        prevPrompts.filter((item) => item.id !== id)
      );
      setLoading(false);
      handleToastNotification("Prompt deleted successfully", false);
      handleModalClose();
    }
  }

  async function handleModalClose() {
    setShowRemoveSumModal(false);
    setSelectedId();
  }

  async function handleAdd(title, prompt) {
    const newPromptRes = await API.graphql({
      query: createPrompt,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: " ",
        name: title,
        prompt,
      },
    });

    const newPrompt = newPromptRes.data.openAIPromptsCreate;
    setTempPrompts((prevPrompts) => [...prevPrompts, newPrompt]);
    handleToastNotification("Successfully created prompt", false);
  }

  function handleStageEdits(type, index, value) {
    setTempPrompts((prevPrompts) => {
      const updatedPrompts = [...prevPrompts];
      if (updatedPrompts[index][type] !== value) {
        updatedPrompts[index] = { ...updatedPrompts[index], [type]: value };
      }
      return updatedPrompts;
    });
  }

  async function handleSaveEdits(type, id) {
    let prompt = openAiPrompts.find((item) => item.id === id);
    let editedPrompt = tempPrompts.find((item) => item.id === id);

    if (
      prompt?.name === editedPrompt?.name &&
      prompt?.prompt === editedPrompt?.prompt
    ) {
      return;
    }
    setLoading(true);

    let queryName;

    switch (type) {
      case "name":
        queryName = updatePromptName;
        break;
      case "prompt":
        queryName = updatePromptContent;
        break;
      default:
        break;
    }
    await API.graphql({
      query: queryName,
      variables: {
        companyId: localStorage.getItem("companyId"),
        id: editedPrompt.id,
        [type]: editedPrompt[type],
      },
    });

    setTempPrompts((prevPrompts) =>
      prevPrompts.map((prompt) =>
        prompt.id === editedPrompt.id ? editedPrompt : prompt
      )
    );

    handleToastNotification("Successfully edited prompt", false);
    setLoading(false);
  }

  return (
    show && (
      <>
        {!showRemoveSumModal && (
          <div
            id="filePreviewPopup"
            className="fixed inset-0 z-50 flex items-center justify-center max-w-none bg-black bg-opacity-50 overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex-col flex bg-white rounded p-8 max-w-none mx-auto overflow-hidden w-11/12 h-5/6">
              <div className="flex items-start justify-between rounded-t">
                <h3 className="text-xl font-semibold">Manage Prompts</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => handleHide()}
                >
                  <GrClose />
                </button>
              </div>
              <div className="flex w-full py-3 items-end	border-b border-solid border-blueGray-200">
                <div className="text-lg font-medium text-gray-400 w-56 mr-2">
                  Title
                </div>
                <div className="text-lg font-medium text-gray-400 flex flex-grow ">
                  Prompt
                </div>
                <Button
                  block={true}
                  size="large"
                  variant="secondary-l"
                  disabled={loading}
                  onClick={() => handleAdd()}
                  className="h-10"
                >
                  <RiAddCircleLine className="h-4 w-4 mx-1" />
                </Button>
              </div>
              <div className="h-full overflow-y-auto flex flex-col">
                {tempPrompts.map((data, index) => (
                  <div className="flex w-full pt-4" key={data.id}>
                    <input
                      type="text"
                      value={data.name}
                      onChange={(e) => {
                        handleStageEdits("name", index, e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (e.target.value !== "") {
                            handleSaveEdits("name", data.id);
                          } else {
                            setTempPrompts(openAiPrompts);
                            handleToastNotification(
                              "Prompt cannot be empty",
                              true
                            );
                          }
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          handleSaveEdits("name", data.id);
                        } else {
                          setTempPrompts(openAiPrompts);
                          handleToastNotification(
                            "Prompt cannot be empty",
                            true
                          );
                        }
                      }}
                      className="rounded-md p-2 border border-gray-300 outline-0 block w-56 mr-2"
                    />
                    <input
                      type="text"
                      value={data.prompt}
                      onChange={(e) => {
                        handleStageEdits("prompt", index, e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (e.target.value !== "") {
                            handleSaveEdits("prompt", data.id);
                          } else {
                            setTempPrompts(openAiPrompts);
                            handleToastNotification(
                              "Prompt cannot be empty",
                              true
                            );
                          }
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          handleSaveEdits("prompt", data.id);
                        } else {
                          setTempPrompts(openAiPrompts);
                          handleToastNotification(
                            "Prompt cannot be empty",
                            true
                          );
                        }
                      }}
                      className="rounded-md p-2 border border-gray-300 outline-0 flex-grow mr-2"
                    />
                    <Button
                      block={true}
                      size="large"
                      // type="submit"
                      variant="danger-l"
                      disabled={loading}
                      onClick={
                        (event) => {
                          setShowRemoveSumModal(true);
                          setSelectedId(data.id);
                        }
                        //  handleDelete(event, data.id)
                      }
                    >
                      <BsFillTrashFill className="h-4 w-4 mx-1" />
                    </Button>
                  </div>
                ))}
              </div>
              {/* <div className="pt-4 flex gap-x-2 justify-center">
                <Button
                  block={true}
                  size="large"
                  variant="danger"
                  disabled={loading}
                  onClick={() => {
                    handleHide();
                  }}
                >
                  <span className="w-36 text-base font-semibold">Close</span>
                </Button>
              </div> */}
            </div>
          </div>
        )}
        {showHideCreatePromptsState && (
          <CreatePromptsModal
            handleSetShow={handleShowHideCreatePrompt}
            handleAdd={handleAdd}
            handleToastNotification={handleToastNotification}
          />
        )}

        {showRemoveSumModal && (
          <RemoveSummaryModal
            handleSave={handleDelete}
            handleModalClose={handleModalClose}
            selectedPromptId={selectedId}
            openAiPrompts={openAiPrompts}
          />
        )}

        {showToast && (
          <ToastNotification
            title={resultMessage}
            hideToast={hideToast}
            error={isToastError}
          />
        )}
      </>
    )
  );
}

export function CreatePromptsModal({
  handleSetShow,
  handleAdd,
  handleToastNotification,
}) {
  const [tempTitle, setTempTitle] = useState("");
  const [tempPrompt, setTempPrompt] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSavingNewPrompt() {
    setLoading(true);
    if (!tempTitle) {
      handleToastNotification("Title is required", true);
      setLoading(false);
      return;
    }
    if (!tempPrompt) {
      handleToastNotification("Prompt is required", true);
      setLoading(false);
      return;
    }

    await handleAdd(tempTitle, tempPrompt);
    setLoading(false);
    handleSetShow();
  }

  return (
    <>
      <div
        id="filePreviewPopup"
        className="fixed inset-0 z-50 flex items-center justify-center max-w-none bg-black bg-opacity-50 overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex-col flex bg-white rounded p-8 max-w-none mx-auto overflow-hidden w-96">
          <div className="flex items-start justify-between rounded-t">
            <h3 className="text-xl font-semibold">Create Prompt</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => handleSetShow()}
            >
              <GrClose />
            </button>
          </div>
          <div className="flex w-full py-3 border-b border-solid border-blueGray-200"></div>
          <div className="h-full flex">
            <div className="flex w-full pt-4 flex-col">
              <div className="text-base font-medium text-gray-400 flex grow">
                Title
              </div>
              <input
                type="text"
                value={tempTitle}
                onChange={(e) => {
                  setTempTitle(e.target.value);
                }}
                className="rounded-md p-2 border border-gray-300 outline-0 flex-grow mr-2"
              />
            </div>
          </div>
          <div className="h-full flex">
            <div className="flex w-full pt-4 flex-col">
              <div className="text-base font-medium text-gray-400 flex grow ">
                Prompt
              </div>
              <input
                type="text"
                value={tempPrompt}
                onChange={(e) => {
                  setTempPrompt(e.target.value);
                }}
                className="rounded-md p-2 border border-gray-300 outline-0 flex-grow mr-2"
              />
            </div>
          </div>
          <div className="pt-4 flex gap-x-2 justify-end">
            <Button
              block={true}
              size="large"
              variant="primary"
              onClick={() => {
                handleSavingNewPrompt();
              }}
              disabled={loading}
            >
              <span className="w-14  text-base font-semibold">Create</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
