import React, { useState, useEffect, useRef } from "react";
import ToastNotification from "../toast-notification";
import { API } from "aws-amplify";
import RemoveFileModal from "../file-bucket/remove-file-modal";
import BriefModal from "../background/create-minibrief-modal";
import {
  AiFillFile,
  AiFillEye,
  AiFillSave,
  AiOutlinePlus,
  AiOutlineEye,
  AiOutlineInbox,
} from "react-icons/ai";
import { BsFillFilterSquareFill, BsFillTrashFill } from "react-icons/bs";
import { RiAddCircleLine } from "react-icons/ri";
import {
  FaChevronDown,
  FaRegCopy,
  FaSearchengin,
  FaHighlighter,
} from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import Button from "../../shared/Button";
import classNames from "classnames";
import FiltersModal from "./filters-modal.jsx";
import PromptsModal from "./prompts-modal.jsx";
import "../../assets/styles/multiselect-custom.css";
import "../../assets/styles/filter-labels.css";
import { useParams } from "react-router-dom";
//import DownloadButton from "../action-buttons/download-button";
import CreateDocument from "../action-buttons/create-document";
import ShareLinkButton from "../action-buttons/shareLink-button";
import { updateBackgroundOrders } from "../../shared/graphql/Backgrounds/mutations";
import ContextualSummaryModal from "./contextual-memory-modal";
import { FaBell } from "react-icons/fa";

let selectedFilters = [];

const ActionButtons = (props) => {
  const {
    background,
    setSelectedItems,
    checkAllState,
    setcheckAllState,
    setCheckedState,
    settotalChecked,
    setId,
    matterId,
    selectedItems,
    getBackground,
    selectedRowsBG,
    setSelectedRowsBG,
    setShowModalParagraph,
    showDeleteButton,
    setShowDeleteButton,
    activateButton,
    handleManageFiles,
    checkDate,
    setCheckDate,
    checkDesc,
    setCheckDesc,
    checkDocu,
    setCheckDocu,
    checkedStateShowHide,
    selectRow,
    setSelectRow,
    pasteButton,
    setPasteButton,
    setBackground,
    setPreviousBackground,
    setMaxLoading,
    sortByOrder,
    briefId,
    briefName,
    matter_name,
    client_name,
    holdDelete,
    setHoldDelete,
    setTargetRow,
    targetRow,
    highlightRow,
    setHighlightRow,
    cache,
    bindList,
    setMoveButton,
    moveButton,
    handleSearchDescriptionChange,
    fontSize,
    setFontSize,
    fileView,
    setFileView,
    allowDeleteRows,
    allowAddRows,
    gmailFilter,
    setgmailFilter,
    actionButtonsPos,
    actionButtonsRef,
    allowAddParagraph,
    allowManageFiles,
    allowManagePrompts,
    allowSummarize,
    handleShowHideManagePrompts,
    showHideManagePromptsState,
    setShowHideManagePromptsState,
    contextualLibrary,
    setContextualLibrary,
    handleShowHideContextualMemory,
    showHideContextualMemoryState,
    setShowHideContextualMemoryState,
    allowDownload,
    allowCreateDocument,
    briefs,
    activeColumns,
    setActiveColumns,
    tableId,
    setTableId,
    createDocumentState,
    pressedQSearch,
    setPressedQSearch,
    showMenu,
    reducedTabsArray,
    setReducedTabsArray,
    reduceTabFilesToArray,
    columnCount,
    setColumnCount,
    setBaseTablePadding,
    updateLabel,
    updateLabelValue,
    backId,
    loading,
    openAiPrompts,
    setOpenAiPrompts,
    willBulkSummarise,
    setWillBulkSummarise,
    showNotificationDropdown,
    setShowNotificationDropdown,
  } = props;

  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState();

  const [showRemoveFileModal, setshowRemoveFileModal] = useState(false);
  const [showhideState, setShowHideState] = useState(false);
  const [tableColumnList, setTableColumnList] = useState(null);
  const [showBriefModal, setShowBriefModal] = useState(false);
  const [deletePermanently, setDeletePermanently] = useState(true);
  const [addRowState, setAddRowState] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredLabels, setFilteredLabels] = useState([]);
  const [labels, setLabels] = useState([]);
  const [invalidState, setInvalidState] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showHideFontMenuState, setShowHideFontMenuState] = useState(false);
  const [showHideFileMenuState, setShowHideFileMenuState] = useState(false);

  //tab markers
  const [holdReducedTabsArrayCopy, setHoldReducedTabsArrayCopy] =
    useState(null);

  const mCreateBackground = `
    mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
      backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
        id
        createdAt
        date
        description
        order
      }
    }
    `;

  const mUpdateBackgroundFile = `
    mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
      backgroundFileTag(backgroundId: $backgroundId, files: $files) {
        id
      }
    }
  `;

  const mBulkUpdateBackgroundOrder = `
    mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
      backgroundBulkUpdateOrders(arrangement: $arrangement) {
        id
        order
      }
    }`;

  const mCreateActivity = `
    mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
      activityCreate(
        activity: $activity
        briefId: $briefId
        clientMatterId: $clientMatterId
        companyId: $companyId
        previous: $previous
        field: $field
        current: $current
        appModule: $appModule
        rowId: $rowId
      ) {
        id
      }
    }`;

  const bool = useRef(true);

  var moment = require("moment");

  const hideToast = () => {
    setShowToast(false);
  };

  const autoAdjustRowHeight = (index) => {
    //bindList and cache must not be null
    if (bindList && cache) {
      //clear first
      cache?.current.clear(index);
      bindList?.current?.recomputeRowHeights(index);
      //bindList?.current?.forceUpdateGrid(index); //remove becuase recomputeRowHeights already calls forceUpdate by default
    } else {
      console("List reference not found || cache not found!");
    }
  };

  const autoAdjustAllRow = async () => {
    try {
      if (bindList && cache) {
        cache?.current.clearAll();
        //while loop is recommended fastest loop

        var i = 0,
          len = background.length;
        while (i < len) {
          setTimeout(() => {
            bindList?.current?.recomputeRowHeights(len);
          }, 100);

          i++;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const autoAdjustAllRow2 = () => {
    if (bindList && cache) {
      cache?.current.clearAll();
      //while loop is recommended fastest loop

      var i = 0,
        len = background.length;
      while (i < len) {
        bindList?.current?.recomputeRowHeights(len);
        i++;
      }
    }
  };

  var holdDeleteBrief;

  const handleDelete = (item) => {
    var bgCopy = background;

    setHoldReducedTabsArrayCopy([...reducedTabsArray]);

    for (var i = 0; i < item.length; i++) {
      bgCopy.map((x, index) =>
        x.id === item[i].id ? bgCopy.splice(index, 1) : x
      );
    }

    //new function for multiple tabs reference
    const itemsToBeDeleted = item.map((i) => i.id);
    console.log("Items to be deleted", itemsToBeDeleted);
    const tempBackground = background.filter(
      (bgRow) => !itemsToBeDeleted.includes(Object.keys(bgRow)[0])
    );

    const outputArray = reduceTabFilesToArray(tempBackground);
    setReducedTabsArray(outputArray);
    //new function for multiple tabs reference

    setBackground(bgCopy);
    setPreviousBackground(bgCopy);
    setalertMessage(`Rows Deleted. Click HERE to undo action`);
    setShowToast(true);
    setHoldDelete(true);
    setshowRemoveFileModal(false);
    setcheckAllState(false);
    const newArr = Array(background.length).fill(false);
    setCheckedState(newArr);
    window.onbeforeunload = function () {
      return "Changes you saved might not be saved.";
    };
    if (item.length === 0) {
      window.alert("Please select row.");
    } else {
      holdDeleteBrief = setTimeout(async () => {
        setShowToast(false);
        //Delete file permanently after 4 seconds
        if (bool.current) {
          //FIX FOR MMA-2255: Copied delete query from Filebucket to also delete labels
          const mDeleteFileAttachment = `
            mutation deleteBackgroundAttachments($backgroundId: ID, $files: [FileInput]) {
              backgroundFileTag(backgroundId: $backgroundId, files: $files) {
                id
              }
            }
          `;
          const mDeleteBackground = `
            mutation untagBriefBackground($briefId: ID, $background: [BackgroundInput]) {
              briefBackgroundUntag(briefId: $briefId, background: $background) {
                id
              }
            }
          `;
          const deleteFiles = await Promise.all(
            item.map((bg) => {
              API.graphql({
                query: mDeleteFileAttachment,
                variables: {
                  backgroundId: bg.id,
                  files: [],
                },
              });
            })
          );

          const deleteBgs = await API.graphql({
            query: mDeleteBackground,
            variables: {
              briefId: briefId,
              background: item,
            },
          }).then(async () => {
            if (item?.length > 1) {
              const params = {
                query: mCreateActivity,
                variables: {
                  companyId: localStorage.getItem("companyId"),
                  clientMatterId: matter_id,
                  briefId: briefId,
                  activity: "removed multiple backgrounds",
                  field: "Background",
                  appModule: "BACKGROUND",
                },
              };
              const addActivity = await API.graphql(params).then((result) => {
                console.log("addActivity result", result);
              });
            } else {
              const params = {
                query: mCreateActivity,
                variables: {
                  companyId: localStorage.getItem("companyId"),
                  clientMatterId: matter_id,
                  briefId: briefId,
                  activity: "removed a background",
                  field: "Background",
                  appModule: "BACKGROUND",
                },
              };
              const addActivity = await API.graphql(params).then((result) => {
                console.log("addActivity result", result);
              });
            }
          });
          setalertMessage(`Successfully Deleted`);
          setShowToast(true);
        }
        window.onbeforeunload = null;
        //** ADDED WINDOW RELOAD TO SIMULATE REFETCHING OF BACKGROUND AFTER SAVING DESC */
        // Removed for MMA 2309
        // window.location.reload();
        getBackground();
      }, 4000);

      const rowArrangement = bgCopy.map(({ id }, index) => ({
        id: id,
        order: index + 1,
      }));

      updateBackgroundOrders(rowArrangement);

      // For FE purposes: Update existing background array with the updated order data
      bgCopy.forEach((item, index) => {
        item.order = index + 1;
      });

      setSelectedRowsBG([]);
      autoAdjustAllRow();
    }

    setMaxLoading(false);
    setSelectedRowsBG([]);
    setSelectRow([]);
    setSelectedItems([]);

    if (temp.length > 0) {
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  };

  function undoAction() {
    clearTimeout(holdDeleteBrief);
    bool.current = false;
    setalertMessage(`Restoring Rows..`);
    setShowToast(true);
    const newArr = Array(background.length).fill(false);
    setCheckedState(newArr);
    setSelectedRowsBG([]);
    setSelectedItems([]);
    setcheckAllState(false);
    setHoldDelete(false);

    //Added new function to update background rows after undoing delete items
    if (holdReducedTabsArrayCopy) {
      setReducedTabsArray(holdReducedTabsArrayCopy);
    }
    //Added new function to update background rows after undoing delete items

    setTimeout(() => {
      setShowToast(false);
      getBackground("Action Buttons undo action get background");
      autoAdjustAllRow();
      window.onbeforeunload = null;
    }, 2000);
  }

  const handleAddRow = async () => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: briefId, name: briefName }];
      console.log(tempBriefDetails);

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
        },
      });

      if (createBackgroundRow) {
        const result = {
          createdAt: dateToday,
          id: createBackgroundRow.data.backgroundCreate.id,
          description: "",
          date: null,
          order: 0,
          files: { items: [] },
          labels: { items: [] },
          briefs: [{ id: briefId, name: briefName }],
          dynamicData: null,
        };
        setBackground([]);

        setBackground((background) => sortByOrder(background.concat(result)));
        setPreviousBackground((background) =>
          sortByOrder(background.concat(result))
        );
        background.splice(0, 0, result);

        // Log Activity
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: "updated the background briefs",
            field: "Background",
            appModule: "BACKGROUND",
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });

        const rowArrangement = background.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement);

        // For FE purposes: Update existing background array with the updated order data
        background.forEach((item, index) => {
          item.order = index + 1;
        });

        setcheckAllState(false);
        setCheckedState(new Array(background.length).fill(false));
        setSelectedRowsBG([]);
        setShowDeleteButton(false);
        setMaxLoading(false);
        // setBackground(background);
        // setPreviousBackground(background);
        setBackground(sortByOrder(background));
        setPreviousBackground(sortByOrder(background));
        autoAdjustAllRow();
      }
    } catch (error) {
      console.error(error);
    }
  };

  var temp = [];
  const handleCheckAllChange = (ischecked) => {
    setcheckAllState(!checkAllState);

    if (ischecked) {
      setCheckedState(new Array(background.length).fill(true));
      settotalChecked(0);
      //insert row
      // remove order after migration
      background.map(
        (data) =>
          (temp = [...temp, { id: data.id, fileName: "x", order: data.order }])
      );
      setSelectedRowsBG(temp);
      if (temp.length > 0) {
        setShowDeleteButton(true);
      } else {
        setShowDeleteButton(false);
      }
    } else {
      setCheckedState(new Array(background.length).fill(false));
      settotalChecked(background.length);
      setId(background.map((s) => s.id));
      setShowDeleteButton(false);
      setSelectedRowsBG([]);
    }
  };

  useEffect(() => {
    getLabels();
  }, []);

  useEffect(() => {
    setTableColumnList(activeColumns);
  }, [activeColumns]);

  // Optional: If you want to log the updated values
  useEffect(() => {
    if (columnCount === 4) {
      setBaseTablePadding("p-4 pl-12 pr-12");
    } else if (columnCount === 3) {
      setBaseTablePadding("p-4 pl-24 pr-24");
    } else if (columnCount === 2) {
      setBaseTablePadding("p-4 pl-48 pr-48");
    } else if (columnCount === 1) {
      setBaseTablePadding("p-4 pl-64 pr-64");
    }
  }, [activeColumns, tableColumnList]);

  const handleModalClose = () => {
    setshowRemoveFileModal(false);
    setShowBriefModal(false);
  };

  const handleColumnCheckChanged = async (event, accessorKey, data) => {
    console.log("handleColumnCheckChanged()");
    console.log("event", event);
    console.log("data", data);

    const newColumnSettings = tableColumnList.map((column, index) => {
      if (column.accessorKey === accessorKey) {
        column.visible = event.target.checked;
      }
      return column;
    });

    const checkedCount = newColumnSettings.filter(
      (column) => column.visible
    ).length;
    setColumnCount(checkedCount);

    setShowHideState(true);
    console.log("newColumnSettings", newColumnSettings);

    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: matter_id,
        briefId: briefId,
        activity: `updated the background columns`,
        field: "Background",
        appModule: "BACKGROUND",
      },
    };

    const addActivity = await API.graphql(params).then((result) =>
      console.log("add activity result", result, data.header)
    );

    setTableColumnList(newColumnSettings);
    setActiveColumns(newColumnSettings);
    autoAdjustAllRow(); //Added fix overlapping files
  };

  const handleShowHide = () => {
    if (showhideState) {
      setShowHideState(false);
    } else {
      setShowHideState(true);
    }
  };

  const handleShowHideFontMenu = () => {
    if (showHideFontMenuState) {
      setShowHideFontMenuState(false);
    } else {
      setShowHideFontMenuState(true);
    }
  };

  const handleShowHideFileMenu = () => {
    if (showHideFileMenuState) {
      setShowHideFileMenuState(false);
      autoAdjustAllRow();
    } else {
      setShowHideFileMenuState(true);
      autoAdjustAllRow();
    }
  };

  const handleAddRowState = () => {
    if (addRowState) {
      setAddRowState(false);
    } else {
      setAddRowState(true);
    }
  };

  const qGetDefaultColumnSettings = `
  query getColumnSettings($tableName: ViewTable) {
    columnSettings(tableName: $tableName) {
      id
      label
      name
      createdAt
    }
  }`;
  const qGetUserColumnSettings = `
  query getUserColumnSettings($tableName: ViewTable, $userId: ID) {
    userColumnSettings(userId: $userId, tableName: $tableName) {
      id
      isVisible
      userId
      columnSettings {
        id
        label
        name
        tableName
      }
    }
  }`;

  const mCreateDefaultUserColumnSettings = `
  mutation createDefaultColumnSettings($columnSettings: [ColumnSettingsInput], $userId: ID) {
    userColumnSettingsCreate(userId: $userId, columnSettings: $columnSettings) {
      userId
    }
  }`;

  const getColumnSettings = async () => {
    /**
    const tableName = "BACKGROUNDS";

    const userColumnSettings = await API.graphql({
      query: qGetUserColumnSettings,
      variables: {
        tableName: tableName,
        userId: localStorage.getItem("userId"),
      },
    });

    userColumnSettings.data.userColumnSettings.map(
      (x) => x.columnSettings.name === "DATE" && setCheckDate(x.isVisible)
    );
    userColumnSettings.data.userColumnSettings.map(
      (x) => x.columnSettings.name === "DOCUMENT" && setCheckDocu(x.isVisible)
    );
    userColumnSettings.data.userColumnSettings.map(
      (x) =>
        x.columnSettings.name === "DESCRIPTIONOFBACKGROUND" &&
        setCheckDesc(x.isVisible)
    );

    if (
      tableColumnList === null &&
      userColumnSettings.data.userColumnSettings.length === 0
    ) {
      // no default user column settings

      const defaultColumnSettings = await API.graphql({
        query: qGetDefaultColumnSettings,
        variables: {
          tableName: tableName,
        },
      });

      if (defaultColumnSettings.data.columnSettings !== null) {
        const defaultColumnSettingsIds =
          defaultColumnSettings.data.columnSettings.map((i) => {
            return { id: i.id };
          });

        await API.graphql({
          query: mCreateDefaultUserColumnSettings,
          variables: {
            columnSettings: defaultColumnSettingsIds,
            userId: localStorage.getItem("userId"),
          },
        }).then((data) => {
          getColumnSettings();
        });
      }
    } else {
      setTableColumnList(userColumnSettings.data.userColumnSettings);
    }
    */
  };

  const mUpdateUserColumnSettings = `
  mutation UpdateUserColumnSettings($isVisible: Boolean, $id: ID) {
    userColumnSettingsUpdate(id: $id, isVisible: $isVisible) {
      id
    }
  }
  `;

  const qlistBackgroundFiles = `
  query getBackgroundByID($id: ID) {
    background(id: $id) {
      id
      files {
        items {
          id
          details
          name
        }
      }
    }
  }`;

  async function updateUserColumnSettings(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateUserColumnSettings,
          variables: {
            id: id,
            isVisible: data.isVisible,
          },
        });

        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const handleCopyRow = (action) => {
    if (action === "copy" || action === "move") {
      // Set the pasteButton state to true if action is "copy", otherwise set it to false
      setPasteButton(action === "copy");

      // Set the moveButton state to true if action is "move", otherwise set it to false
      setMoveButton(action === "move");

      console.log("copying this", selectRow);

      localStorage.setItem("selectedRows", JSON.stringify(selectRow));
      autoAdjustAllRow2();
    }
  };

  const handleShowBrief = () => {
    setShowBriefModal(true);
  };

  const handleCheckAll = (e) => {
    console.log("ACTION BUTTONS CHECK ALL:", background);
    const ids = background.map((item, index) => ({
      id: item.id,
      files: item.files,
      order: index + 1,
    }));
    console.log("IDS ARE:", ids);
    if (e.target.checked) {
      setSelectedItems(background.map((x) => x.id));
      setcheckAllState(true);
      setShowDeleteButton(true);
      setSelectRow(background.map((x) => x));
      setSelectedRowsBG(ids);
    } else {
      setSelectedItems([]);
      setcheckAllState(false);
      setShowDeleteButton(false);
      setSelectRow([]);
      setSelectedRowsBG([]);
    }
    //Overlapping files

    autoAdjustAllRow();
  };

  const handlePasteRow = async (action) => {
    console.log("test123");
    if (action === "add") {
      if (targetRow !== "" || targetRow !== undefined) {
        let tempBackground = [...background];
        let arrFileResultBG = [];

        const tempBriefDetails = [{ id: briefId, name: briefName }];
        console.log(tempBriefDetails);

        const createBackgroundRow = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: tempBriefDetails,
            description: "",
            comments: "",
            date: null,
            order: targetRow,
          },
        });

        var arrResult = {
          createdAt: createBackgroundRow.data.backgroundCreate.createdAt,
          id: createBackgroundRow.data.backgroundCreate.id,
          date: createBackgroundRow.data.backgroundCreate.date,
          description: createBackgroundRow.data.backgroundCreate.description,
          files: { items: [] },
          order: createBackgroundRow.data.backgroundCreate.order,
          briefs: tempBriefDetails,
        };

        console.log("test123");

        arrFileResultBG.push(...[arrResult]);

        sortByOrder(arrFileResultBG)
          .splice(0)
          .reverse()
          .map(async function (x) {
            var resultBG = {
              createdAt: x.createdAt,
              id: x.id,
              files: { items: x.files.items },
              date: x.date,
              description: x.description,
              order: x.order,
              briefs: x.briefs,
            };

            tempBackground.splice(targetRow - 1, 0, resultBG);

            const result = tempBackground.map(({ id }, index) => ({
              id: id,
              order: index,
            }));

            if (result && result.length > 0) {
              await API.graphql({
                query: mBulkUpdateBackgroundOrder,
                variables: {
                  arrangement: result,
                },
              });
            }
          });

        setAddRowState(false);

        const rowArrangement = tempBackground.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement);

        // For FE purposes: Update existing background array with the updated order data
        tempBackground.forEach((item, index) => {
          item.order = index + 1;
        });

        setHighlightRow(targetRow);
        setBackground(tempBackground);
        setPreviousBackground(tempBackground);
        setTimeout(() => {
          autoAdjustAllRow();
        }, 400);
        //Adjust all row
        setTimeout(() => {
          setHighlightRow(null);
        }, 3000);
      }

      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
          briefId: briefId,
          activity: `insert a row at row ${targetRow}`,
          field: "Background",
          appModule: "BACKGROUND",
        },
      };

      const addActivity = await API.graphql(params).then((result) =>
        console.log("addActivity result", result, targetRow)
      );
    } else if (action === "move") {
    }
  };

  const handleChangeRow = (e) => {
    setTargetRow(e.target.value);
  };

  const handleSizeButton = (currentClass, fontSize, value) => {
    return classNames(currentClass, {
      "bg-gray-300": fontSize === value,
    });
  };

  const handleFontSize = (currentClass, fontSize) => {
    return classNames(currentClass, {
      "text-sm": fontSize === 0,
      "text-base": fontSize === 1,
      "text-lg": fontSize === 2,
    });
  };

  const handleClearFilter = () => {
    setEndDate(null);
    setStartDate(null);
    getBackground("Handle clear filter get background");
    setShowDateFilter(false);
  };

  const handleFilterChange = (evt) => {
    // filesToSend = evt; //filter Labels, send data to index
    selectedFilters = evt; //save for UI display of selected labels
  };

  const handleRemoveChange = (evt) => {
    // filesToSend = evt; //filter Labels, send data to index
    selectedFilters = evt; //save for UI display of selected labels
  };

  const [originalBackground, setOriginalBackground] = useState([]);

  const handleSave = () => {
    // setTimeout(()=>{
    var myStartDate = new Date(startDate);
    // myStartDate.setDate(myStartDate.getDate() + parseInt(1));
    var myEndDate = new Date(endDate);
    // myEndDate.setDate(myEndDate.getDate() + parseInt(1));
    var startD = myStartDate.toISOString().split("T")[0]; //yyyy-mm-dd format
    var endD = myEndDate.toISOString().split("T")[0];

    var newBackground = [];
    console.log("bg", background);

    console.log("start", startD);
    console.log("end", endD);
    console.log("Labels", filteredLabels);

    //iterate in background
    for (var i = 0; i < background.length; i++) {
      if (background[i].date !== null) {
        var currDate = moment.utc(background[i].date).local();
        //new Date(background[i].date);
        //currDate.setDate(currDate.getDate() + parseInt(1));
        var compareDate = currDate.toISOString().split("T")[0];
        console.log("compare", compareDate);

        if (startD === endD) {
          if (startD === compareDate) {
            newBackground = [...newBackground, background[i]];
          }
        } else if (startD <= compareDate && endD >= compareDate) {
          newBackground = [...newBackground, background[i]];
        }
      }
    }

    console.log("will update", newBackground);
    setBackground(newBackground);
    setPreviousBackground(newBackground);
    setShowDateFilter(false);
    autoAdjustAllRow();

    // }, 1000);
  };

  useEffect(() => {
    // if (labels.length === 0) {
    //   getLabels();
    // }

    if (
      startDate !== null &&
      startDate !== "" &&
      endDate !== null &&
      endDate !== ""
    ) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        setInvalidDateRange(true);
        setInvalidState(true);
      } else {
        setInvalidDateRange(false);
        setInvalidState(false);
      }
    } else {
      setInvalidState(true);
    }
  }, [startDate, endDate]);

  const handleDateFilterModal = () => {
    setStartDate(null);
    setEndDate(null);
    if (showDateFilter === true) {
      setShowDateFilter(false);
    } else {
      setShowDateFilter(true);
    }
  };

  //labels filter
  const { matter_id } = useParams();

  const listBriefs = `
  query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          labelId
        }
      }
    }
  }
  `;

  const getLabels = async () => {
    var labelsList = [];

    const params = {
      query: listBriefs,
      variables: {
        id: matter_id,
        limit: 100,
        nextToken: null,
      },
    };

    try {
      await API.graphql(params).then((brief) => {
        const briefsList = brief.data.clientMatter.briefs.items;
        for (var i = 0; i < briefsList.length; i++) {
          labelsList = [...labelsList, briefsList[i].name]; //name and briefid
        }
        setLabels(labelsList);
      });
    } catch (error) {
      console.error("ERROR FETCHING BRIEFS LIST: RESTART PAGE", error);
    }
  };

  const handleFilterLabels = async () => {
    var filLabels = filteredLabels;
    var filteredBg = [];

    console.log("labels seelcted", filLabels);

    console.log("bg", background);

    if (filLabels.length > 0) {
      for (var i = 0; i < background.length; i++) {
        for (var j = 0; j < background[i].briefs.items.length; j++) {
          if (filLabels.includes(background[i].briefs.items[j].name)) {
            filteredBg = [...filteredBg, background[i]];
            break;
          }
        }
      }

      setBackground(filteredBg);
      autoAdjustAllRow();
      console.log("filteredBg", filteredBg);
    } else {
      getBackground("Filter Label get background");
      autoAdjustAllRow();
    }
  };

  return (
    <>
      <div
        className="py-1 grid-cols-1 gap-1.5 bg-white sticky hidden sm:grid z-20 -mt-10"
        ref={actionButtonsRef}
        style={{
          top: actionButtonsPos,
          backgroundColor: "#F9F9F9",
        }}
      >
        {showMenu && (
          <div
            className="col-span-2 z-50 p-2"
            style={{
              backgroundColor: "#FFF",
            }}
          >
            <input
              name="check_all"
              id="check_all"
              aria-describedby="checkbox-1"
              type="checkbox"
              checked={checkAllState ? true : false}
              onChange={handleCheckAll}
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />

            {allowAddRows && !showDeleteButton && (
              <>
                <div className="inline-flex rounded-md shadow-sm" role="group">
                  <button
                    type="button"
                    onClick={handleAddRow}
                    className="ml-2 transition-colors justify-center items-center inline-flex flex-1 py-2 px-4 rounded-l-md text-md border-solid border font-medium shadow-md whitespace-nowrap text-secondary border-secondary bg-secondary-l
                    hover:text-secondary-l hover:bg-secondary "
                  >
                    <AiOutlinePlus className="h-4 w-4 ml-1" />
                    &nbsp; Add row
                  </button>

                  <button
                    type="button"
                    className="transition-colors justify-center items-center inline-flex flex-1 rounded-r-md text-md border-solid border shadow-md py-2 px-4 text-secondary border-secondary bg-secondary-l
                    hover:text-secondary-l hover:bg-secondary"
                    onClick={handleAddRowState}
                  >
                    <FaChevronDown className="" />
                  </button>
                </div>
                {addRowState && (
                  <div className="w-64 h-38 z-500 bg-white absolute mt-1 ml-32 rounded border-0 shadow outline-none z-40">
                    <p className="px-2 py-2 text-gray-400 font-semibold">
                      MORE OPTIONS
                    </p>

                    <div className="px-2 py-1 inline-flex align-middle">
                      <span className=" mr-2">Add New Row</span>
                      <input
                        className="w-28  mr-1 rounded"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={targetRow}
                        onChange={handleChangeRow}
                        placeholder="Insert Row"
                        style={{ border: "solid 1px gray" }}
                      />
                      <AiFillSave
                        className="text-blue-400 cursor-pointer"
                        onClick={() => handlePasteRow("add")}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {allowAddParagraph && !showDeleteButton && (
              <Button
                onClick={() => setShowModalParagraph(true)}
                className="py-2 px-4 ml-2 mb-1"
              >
                <AiOutlinePlus className=" h-4 w-4 ml-1" />
                &nbsp; Add Paragraph
              </Button>
            )}
            <div className="inline-flex">
              {!showDeleteButton && (backId === "0" || backId === "3") && (
                <Button
                  onClick={() => handleShowHide()}
                  className="py-2 px-4 ml-2 mb-1"
                >
                  <AiOutlineEye /> &nbsp; Show/Hide Columns
                </Button>
              )}

              {showhideState && (
                <div className="w-64 h-38 z-500 bg-white absolute mt-10 ml-2 rounded border-0 shadow outline-none z-40">
                  <p className="px-2 py-2 text-gray-400 text-x font-semibold">
                    TABLE COLUMN OPTIONS
                  </p>

                  {tableColumnList !== null &&
                    tableColumnList?.map((data) => (
                      <div className="px-2 py-1" key={data.accessorKey}>
                        <input
                          type="checkbox"
                          name={data.header}
                          className="cursor-pointer"
                          checked={data.visible}
                          onChange={(event) =>
                            handleColumnCheckChanged(
                              event,
                              data.accessorKey,
                              data
                            )
                          }
                        />
                        &nbsp; {data.header}
                      </div>
                    ))}
                </div>
              )}
            </div>
            {allowManageFiles && !showDeleteButton && (
              <Button
                onClick={() => handleManageFiles()}
                className={
                  activateButton === true
                    ? "py-2 px-4 ml-2 bg-green-200"
                    : "py-2 px-4 ml-2"
                }
                disabled={loading}
              >
                <AiOutlineInbox />{" "}
                {activateButton === true ? (
                  <>&nbsp; Done</>
                ) : (
                  <> &nbsp; Manage Files</>
                )}
              </Button>
            )}

            {allowSummarize && !showDeleteButton && (
              <Button
                onClick={() => setWillBulkSummarise(!willBulkSummarise)}
                className="py-2 px-4 ml-2"
                disabled={loading}
              >
                <AiOutlineInbox /> Bulk Summarise
              </Button>
            )}

            {showDeleteButton && (
              <>
                <Button
                  onClick={() => {
                    handleShowBrief();
                  }}
                  className="py-2 px-4 mx-2 mb-1"
                >
                  Create Brief +
                </Button>

                <Button
                  onClick={() => handleCopyRow("move")}
                  className="py-2 px-4 ml-2"
                >
                  {moveButton ? "Move" : "Move"} {selectRow.length}
                  &nbsp;{selectRow.length >= 2 ? "Rows" : "Row"}
                  <FaRegCopy className="h-4 w-4 mx-1" />
                </Button>

                <Button
                  onClick={() => handleCopyRow("copy")}
                  className="py-2 px-4 ml-2"
                >
                  {pasteButton ? "PASTE" : "COPY"} {selectRow.length}
                  &nbsp;{selectRow.length >= 2 ? "ROWS" : "ROW"}
                  <FaRegCopy className="h-4 w-4 mx-1" />
                </Button>

                {allowDeleteRows && (
                  <Button
                    disabled={pasteButton ? true : false}
                    onClick={() => {
                      setshowRemoveFileModal(true);
                      console.log(selectedRowsBG);
                    }}
                    className="py-2 px-4 ml-2"
                    variant="danger"
                  >
                    Delete
                    <BsFillTrashFill className="h-4 w-4 mx-1" />
                  </Button>
                )}
              </>
            )}

            {/** {allowDownload && (
              <DownloadButton briefId={briefId} />
            )}*/}

            {/* {allowCreateDocument && (
              <CreateDocument
                showState={createDocumentState.showDocumentCreator}
                changeState={createDocumentState.setDocumentCreator}
              />
            )} */}
            <div
              className={`relative inline-flex ${
                !(backId === "0" || backId === "3") ? "hidden" : null
              }`}
            >
              <Button
                onClick={() => handleShowHideFontMenu()}
                className="py-2 px-4 ml-2 mb-1"
              >
                <AiOutlinePlus /> &nbsp; Font Size
              </Button>

              {showHideFontMenuState && (
                <div className="w-72 bg-white absolute mt-10 ml-2 rounded border shadow z-50">
                  <p className="px-2 py-2 text-gray-400 font-semibold text-xs">
                    Font Size
                  </p>
                  <div className="p-2">
                    <div
                      className={`px-2 py-1 rounded-lg ${
                        fontSize === 0 ? "bg-gray-600 text-white" : ""
                      } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                      onClick={() => setFontSize(0)}
                    >
                      Small{" "}
                      <span className="mt-1 float-right italic text-xs">
                        (default)
                      </span>
                    </div>
                    <div
                      className={`px-2 py-1 rounded-lg ${
                        fontSize === 1 ? "bg-gray-600 text-white" : ""
                      } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                      onClick={() => setFontSize(1)}
                    >
                      Medium
                    </div>
                    <div
                      className={`px-2 py-1 rounded-lg ${
                        fontSize === 2 ? "bg-gray-600 text-white" : ""
                      } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                      onClick={() => setFontSize(2)}
                    >
                      Large
                    </div>
                  </div>
                </div>
              )}

              {/* <Button
                onClick={() => handleShowHideFileMenu()}
                className="py-2 px-4 ml-2 mb-1"
              >
                File Display
              </Button>

              {showHideFileMenuState && (
                <div className="w-72 bg-white absolute mt-10 ml-32 rounded border shadow z-50">
                  <p className="px-2 py-2 text-gray-400 font-semibold text-xs">
                    View As
                  </p>
                  <div className="p-2">
                    <div
                      className={`px-2 py-1 rounded-lg ${
                        fileView === 0 ? "bg-gray-600 text-white" : ""
                      } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                      onClick={() => setFileView(0)}
                    >
                      Icons{" "}
                      <span className="mt-1 float-right italic text-xs">
                        (default)
                      </span>
                    </div>
                    <div
                      className={`px-2 py-1 rounded-lg ${
                        fileView === 1 ? "bg-gray-600 text-white" : ""
                      } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                      onClick={() => setFileView(1)}
                    >
                      Lists
                    </div>
                  </div>
                </div>
              )}
                */}

              {allowManagePrompts && !showDeleteButton && (
                <>
                  <Button
                    onClick={() => handleShowHideManagePrompts()}
                    className="py-2 px-4 ml-2 mb-1"
                  >
                    Manage Prompts
                  </Button>
                  <Button
                    onClick={() => handleShowHideContextualMemory()}
                    className="py-2 px-4 ml-2 mb-1"
                  >
                    Contextual Summary
                  </Button>
                </>
              )}

              {/* <div>
                <Button
                    onClick={() => setShowNotificationDropdown(!showNotificationDropdown)}
                    className="py-2 px-4 ml-2 mb-1"
                  >
                   <FaBell />
                </Button>

                <div className="absolute h-48  bg-white border border-black -ml-56 rounded-md w-72 bg-white rounded shadow z-50">

                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>

      {/* {showToast && (
        <ToastNotification title={alertMessage} hideToast={hideToast} />
      )} */}

      {showToast && (
        <div onClick={holdDelete ? () => undoAction() : null}>
          <ToastNotification title={alertMessage} hideToast={hideToast} />
        </div>
      )}

      {showRemoveFileModal && (
        <RemoveFileModal
          handleSave={handleDelete}
          handleModalClose={handleModalClose}
          selectedRowsBG={selectedRowsBG}
        />
      )}

      {showBriefModal && (
        <BriefModal
          setIsCreateBriefClicked={props.setIsCreateBriefClicked}
          updateLabel={updateLabel}
          updateLabelValue={updateLabelValue}
          selectedRowsBG={selectedRowsBG}
          handleModalClose={handleModalClose}
          matterId={matterId}
          briefId={briefId}
          matter_name={matter_name}
          client_name={client_name}
          setSelectedItems={setSelectedItems}
          setcheckAllState={setcheckAllState}
          setSelectRow={setSelectRow}
        />
      )}

      {showDateFilter && (
        <FiltersModal
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          filteredLabels={filteredLabels}
          setFilteredLabels={setFilteredLabels}
          labels={labels}
          handleSave={handleSave}
          handleClearFilter={handleClearFilter}
          invalidState={invalidState}
          setInvalidState={setInvalidState}
          invalidDateRange={invalidDateRange}
          setInvalidDateRange={setInvalidDateRange}
          handleDateFilterModal={handleDateFilterModal}
          showDateFilter={showDateFilter}
          setShowDateFilter={setShowDateFilter}
          getBackground={() => getBackground()}
          handleFilterLabels={handleFilterLabels}
          gmailFilterProp={gmailFilter}
          setgmailFilterProp={setgmailFilter}
        />
      )}

      <PromptsModal
        openAiPrompts={openAiPrompts}
        setOpenAiPrompts={setOpenAiPrompts}
        show={showHideManagePromptsState}
        handleSetShow={handleShowHideManagePrompts}
      />

      <ContextualSummaryModal
        matterId={matterId}
        contextualLibrary={contextualLibrary}
        setContextualLibrary={setContextualLibrary}
        show={showHideContextualMemoryState}
        handleSetShow={handleShowHideContextualMemory}
      />
    </>
  );
};

export default ActionButtons;
