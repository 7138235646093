import HBAImage from "./new-hba.png";
import HBAArchitect from "./usertypes/img-architect.png";
import HBAHomeBuilder from "./usertypes/img-homebuilder.png";
import HBAOwnerBuilder from "./usertypes/img-ownerbuilder.png";
import { BsCalendarCheck } from "react-icons/bs";
import { IoConstructOutline } from "react-icons/io5";
import momentTZ from "moment-timezone";

export const Questions = [
  {
    mainHeader: "It's great to have you onboard.",
    subheader:
      "Let's get started with setting up your personalised dashboard to manage your dream home project.",
    order: 1,
    redirectTo: 2,
    category: "Introductory Screen",
    type: "none",
    // style: 'grid'
    properties: {
      isRequired: false,
      isSkippable: false,
    },
    image: HBAImage,
  },
  {
    mainHeader:
      "Which of the following roles best describes you?",
    // subheader:
    //   "We’ll streamline your setup experience based on your selection.",
    questions: [
      {
        question: "I’m an Owner-Builder",
        subText:
          "I am directly involved in managing and executing the construction project.",
        icons: HBAOwnerBuilder,
        type: "button",
        order: 1,
      },
      {
        question: "I’m an Architect Superintendent",
        subText:
          "I am responsible for administering the construction contract.",
        icons: HBAArchitect,
        type: "button",
        order: 2,
      },
      {
        question: "I’m a Homeowner",
        subText:
          "I am the owner of the property undergoing construction or renovation.",
        icons: HBAHomeBuilder,
        type: "button",
        order: 3,
      }
      
    ],
    order: 2,
    redirectTo: 3,
    category: "About User Types",
    type: "single",
    style: "grid",
    properties: {
      isRequired: true,
      isSkippable: false,
    },
  },
  {
    mainHeader: "How do you intend to use HBApp?",
    // subheader: "Select all that apply.",
    questions: [
      { question: "Manage Budget", type: "button", order: 1 },
      { question: "Handle Invoices", type: "button", order: 2 },
      { question: "Track Project Milestones", type: "button", order: 3 },
      { question: "Manage Variations", type: "button", order: 4 },
      { question: "Manage Contracts", type: "button", order: 5 },
      { question: "Manage Price Risks", type: "button", order: 6 },
      { question: "Track Estimated Costs", type: "button", order: 7 },
      { question: "Monitor Actual Costs", type: "button", order: 8 },
      { question: "Integrate Project Emails", type: "button", order: 9 },
      { question: "Document Defects", type: "button", order: 10 },
      { question: "Manage Permits", type: "button", order: 11 },
      { question: "Organize Warranties", type: "button", order: 12 },
      { question: "Prioritise Tasks", type: "button", order: 13 },
      { question: "Track Progress Photos", type: "button", order: 14 },
      { question: "Manage Insurance", type: "button", order: 15 },
      { question: "Store Property Details", type: "button", order: 16 },
      { question: "Track Development Approvails", type: "button", order: 17 },
      { question: "Log Daily Activities", type: "button", order: 18 },
      { question: "Manage Bids", type: "button", order: 19 },
      { question: "Ensure Work Safety", type: "button", order: 20 },
      { question: "Maintain Quality of Work", type: "button", order: 21 },
      { question: "Manage Communication", type: "button", order: 22 },
    ],
    order: 3,
    redirectTo: 4,
    category: "Usage HBApp",
    type: "multiple",
    style: "discombobulated",
    properties: {
      isRequired: true,
      isSkippable: false,
    },
  },
  //TIMEZONE INSERT
  {
    mainHeader: "Set up Your Locale Preferences",
    subheader: "Customise your experience by setting your local timezone and preferred date format. You can always update these settings later in your Profile Settings." ,
    questions: [
      {
        question: localStorage.getItem("preferredTimezone") ? 
        `It looks like you’re from ${localStorage.getItem("preferredTimezone").replace('_' , ' ')}. Is this your current location?`
        : `It looks like you’re from ${momentTZ.tz.guess().replace('_' , ' ')}. Is this your current location?`,
        type: "dropdown",
        order: 1,
      },
      {
        question: "What is your preferred date format?",
        subheader: "Select the format that you prefer to see dates displayed.",
        type: "dropdowndate",
        order: 2,
      },
    ],
    order: 4,
    redirectTo: 5,
    category: "Timezone",
    type: "single",
    style: "list",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "Where are you on your home building journey? Choose one:",
    questions: [
      {
        question: "Have started construction",
        type: "button",
        icon: <IoConstructOutline className=" text-hbBlue-l" />,
        order: 1,
      },
      {
        question: "Still planning",
        type: "button",
        icon: <BsCalendarCheck className=" text-hbBlue-l" />,
        order: 2,
      },
    ],
    order: 5,
    redirectTo: 6,
    category: "About Project Status",
    type: "single",
    style: "list",
    properties: {
      isRequired: false,
      isSkippable: false,
    },
  },
  {
    mainHeader: "What is the name of your Business?",
    questions: [
      {
        type: "text",
        subheader: "Enter your business name here.",
        placeHolder: "Business Name",
        order: 1,
      },
    ],
    order: 6,
    redirectTo: 7,
    category: "Business Name",
    type: "multiple",
    properties: {
      isRequired: true,
      isSkippable: false,
    },
  },
  // {
  //   mainHeader: "Have you secured your property location?",
  //   questions: [
  //     {
  //       question: "Yes, I have the location",
  //       type: "button",
  //       order: 1,
  //     },
  //     {
  //       question: "No, still looking",
  //       type: "button",
  //       order: 2,
  //     },
  //   ],
  //   order: 6,
  //   redirectTo: 7,
  //   category: "About Property Address",
  //   type: "single",
  //   style: "list",
  //   properties: {
  //     isRequired: false,
  //     isSkippable: false,
  //   },
  // },
  // {
  //   mainHeader: "Let's keep moving forward, enter your property address:",
  //   questions: [
  //     {
  //       type: "text",
  //       subheader: "This helps us personalise your experience even more.",
  //       placeHolder: "123 Smith Street Sydney, NSW 2000",
  //       order: 1,
  //     },
  //   ],
  //   order: 7,
  //   redirectTo: "end",
  //   category: "Property Address",
  //   type: "multiple",
  //   properties: {
  //     isRequired: false,
  //     isSkippable: false,
  //   },
  // },
];

// export const Questions = [
//   {
//     mainHeader: "Welcome to Home Building App",
//     subheader:
//       "Let's set up your personalized dashboard to manage your dream home project.",
//     order: 1,
//     redirectTo: 2,
//     category: "Introductory Screen",
//     type: "none",
//     // style: 'grid'
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//     image: HBAImage,
//   },
//   {
//     mainHeader:
//       "Before we set up your dashboard, could you tell us more about your role?",
//     subheader:
//       "We’ll streamline your setup experience based on your selection.",
//     questions: [
//       {
//         question: "I’m an Architect Superintendent",
//         subText:
//           "I am responsible for administering the construction contract.",
//         icons: HBAArchitect,
//         type: "button",
//         order: 1,
//       },
//       {
//         question: "I’m a Homeowner",
//         subText:
//           "I am the owner of the property undergoing construction or renovation.",
//         icons: HBAHomeBuilder,
//         type: "button",
//         order: 2,
//       },
//       {
//         question: "I’m an Owner-Builder",
//         subText:
//           "I am directly involved in managing and executing the construction project.",
//         icons: HBAOwnerBuilder,
//         type: "button",
//         order: 3,
//       },
//     ],
//     order: 2,
//     redirectTo: 3,
//     category: "About User Types",
//     type: "single",
//     style: "grid",
//     properties: {
//       isRequired: true,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Where are you on your home building journey? Choose one:",
//     questions: [
//       {
//         question: "Still planning",
//         type: "button",
//         order: 1,
//       },
//       {
//         question: "Have started construction",
//         type: "button",
//         order: 2,
//       },
//     ],
//     order: 3,
//     redirectTo: 4,
//     category: "About Project Status",
//     type: "single",
//     style: "list",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Have you secured your property location?",
//     questions: [
//       {
//         question: "Yes, I have the location",
//         type: "button",
//         order: 1,
//       },
//       {
//         question: "No, still looking",
//         type: "button",
//         order: 2,
//       },
//     ],
//     order: 4,
//     redirectTo: 5,
//     category: "About Property Address",
//     type: "single",
//     style: "list",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Have you teamed up with a builder?",
//     questions: [
//       {
//         question: "Yes, I have a builder",
//         type: "button",
//         order: 1,
//       },
//       {
//         question: "No, still choosing",
//         type: "button",
//         order: 2,
//       },
//     ],
//     order: 5,
//     redirectTo: 6,
//     category: "About Builders",
//     type: "single",
//     style: "list",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Do you have a signed construction contract?",
//     questions: [
//       {
//         question: "Yes, I have a contract signed",
//         type: "button",
//         order: 1,
//       },
//       {
//         question: "No, not yet",
//         type: "button",
//         order: 2,
//       },
//     ],
//     order: 6,
//     redirectTo: 7,
//     category: "About Contracts",
//     type: "single",
//     style: "list",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Enter your property address here:",
//     questions: [
//       {
//         type: "text",
//         subheader: "This is the location where your house will be built.",
//         placeHolder: "123 Smith Street Sydney, NSW 2000",
//         order: 1,
//       },
//     ],
//     order: 7,
//     redirectTo: 8,
//     category: "Property Address",
//     type: "multiple",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Share your contract details:",
//     questions: [
//       {
//         question: "When is the start date of the contract?",
//         placeHolder: "Start Date",
//         type: "datepicker",
//         subheader:
//           "This is the first day that work will be performed on site. ",
//         order: 1,
//       },
//       {
//         question: "What is the contract price?",
//         placeHolder: "Contract Price",
//         type: "number",
//         subheader:
//           "This is the original price indicated in the contract, without variations.",
//         order: 2,
//       },
//     ],
//     order: 8,
//     redirectTo: 9,
//     category: "Contract Details",
//     type: "multiple",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "When is the Completion Date under the contract?",
//     questions: [
//       {
//         placeHolder: "Completion Date",
//         type: "datepickerToggleable",
//         subheader:
//           "This is the date when the project is scheduled to be completed as per your contract. ",
//         order: 1,
//         toggleText: "I don't have a target completion date yet.",
//       },
//     ],
//     order: 9,
//     redirectTo: 10,
//     category: "Project Completion Date",
//     type: "multiple",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Share your builder's details:",
//     questions: [
//       {
//         question: "Who is your builder?",
//         placeHolder: "Builder's Name",
//         type: "text",
//         subheader:
//           "Please provide the name of the company or individual responsible for construction.",
//         order: 1,
//       },
//       {
//         question: "Who is your main point of contact at the building company?",
//         placeHolder: "Builder Representative's Name",
//         type: "text",
//         subheader:
//           "This could be a project manager or a representative you'll be in direct contact with.",
//         order: 2,
//       },
//       {
//         question: "What's their email address?",
//         placeHolder: "Email Address",
//         type: "email",
//         order: 3,
//       },
//       {
//         question: "What's their phone number?",
//         placeHolder: "Phone Number",
//         type: "number",
//         order: 4,
//       },
//     ],
//     order: 10,
//     redirectTo: 11,
//     category: "Builder Details",
//     type: "multiple",
//     style: "list",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
//   {
//     mainHeader: "Upload your signed contract here:",
//     subheader:
//       "Don't worry if you don't have them right now — you can always add them later.",
//     questions: [
//       { type: "uploadBox", placeHolder: "Contract", order: 1 },
//       { type: "uploadBox", placeHolder: "Architectural Drawings", order: 2 },
//       { type: "uploadBox", placeHolder: "Engineer Drawings", order: 3 },
//       { type: "uploadBox", placeHolder: "Specifications", order: 4 },
//       { type: "uploadBox", placeHolder: "Builder's Insurance", order: 5 },
//     ],
//     order: 11,
//     redirectTo: "end",
//     category: "Contracts",
//     type: "multiple",
//     properties: {
//       isRequired: false,
//       isSkippable: false,
//     },
//   },
// ];
