import React, { useEffect, useState, useRef } from "react";
import { API, Storage } from "aws-amplify";
import config from "../../aws-exports";
import { LuHardHat } from "react-icons/lu";
import UploadIcon from "../../assets/images/hba-upload-icon.svg";
import Button from "../../shared/Button";
// import isMSdoc from "../../shared/msFileTypeChecker";
import ToastNotification from "../toast-notification";
import buildersIcon from "../../assets/images/builder-icon.png";
import uploadIcon from "../../assets/images/upload-icon.png";
import { IoCloseOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import DeleteMatterModal from "../dashboard/delete-matters-modal";
import { FaRegCopy } from "react-icons/fa";

export default function OwnerDetails({
  ownerDetailsInfo,
  setOwnerDetailsInfo,
  setShowRightPanel,
  clientMatterId,
  ownerName,
  setOwnerName,
  ownerAddress,
  setOwnerAddress,
  ownerEmail,
  setOwnerEmail,
  ownerNumber,
  setOwnerNumber,
  handleChangeGetStartedProgress,
  isSandbox,
}) {
  Storage.configure({
    region: config.aws_user_files_s3_bucket_region,
    bucket: config.aws_user_files_s3_bucket,
    identityPoolId: config.aws_user_pools_id,
  });

  var moment = require("moment");
  const [backgroundList, setBackgroundList] = useState([]);
  const [backgroundTableCols, setBackgroundTableCols] = useState([]);
  // const [column, setcolumn] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const hideToast = () => {
    setShowToast(false);
  };

  //LOGO
  const [uploadedLogo, setUploadedLogo] = useState(null);

  //Builder email and phone copy func
  const [builderEmailCopy, setBuilderEmailCopy] = useState("Copy");
  const [builderContactCopy, setBuilderContactCopy] = useState("Copy");

  //For activity log
  const [hasEdits, setHasEdits] = useState(false);

  useEffect(() => {
    if (backgroundList.length === 0) {
      getBriefs();
    }
  }, []);

  const defaultColumnIds = [0, 1, 2, 3, 4];

  const mUpdateBackgroundFile = `mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileTag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }`;

  const mUpdateBackgroundDynamicData = `mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
    backgroundUpdate(id: $id, dynamicData: $dynamicData) {
      id
      dynamicData
    }
  }`;

  const mBulkCreateMatterFile = `mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
    matterFileBulkCreate(files: $files) {
      id
      name
      order
    }
  }`;

  const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
    clientMatter(id: $id) {
      briefs {
        items {
          id
          name
          userTypeAccess
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          backgrounds(limit: 50, sortOrder: ORDER_ASC) {
            items {
              id
              description
              date
              dynamicData
              files {
                items {
                  createdAt
                  id
                  name
                }
              }
            }
          }
          hasCommentAccess
          columnIds
        }
      }
    }
  }`;

  const BACKGROUND_TABLE_QUERY = `query getBackgroundTable($clientMatterId: ID!) {
    backgroundTable(clientMatterId: $clientMatterId) {
      id
      columns {
        id
        accessorKey
        headerText
        enabled
        type
        optionsText
        order
        presets {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }`;

  const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(
      clientMatterId: $clientMatterId
      date: $date
      name: $name
      order: $order
      columnIds: $columnIds
    ) {
      id
      name
      date
      createdAt
      order
      columnIds
    }
  }`;

  const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
    backgroundCreate(
      description: $description
      comments: $comments
      date: $date
      order: $order
      briefs: $briefs
    ) {
      id
      createdAt
      date
      description
      order
    }
  }
  `;

  const UPDATE_BRIEF_PRESET_MUTATION = `mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    briefUpdate(id: $id, columnIds: $columnIds){
      id
    }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }`;

  // const BACKGROUND_TABLE_CREATE = `
  //   mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
  //   backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
  //       id
  //       columns {
  //       id
  //       accessorKey
  //       headerText
  //       enabled
  //       type
  //       optionsText
  //       order
  //       }
  //       createdAt
  //       updatedAt
  //   }
  //   }
  // `;

  const qGetFileDownloadLink = `query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const UNTAG_BACKGROUND_FILE_MUTATION = `mutation untagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileUntag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }`;

  const { height, width } = useWindowDimensions();


  const handleAddRow = async (briefId, briefName) => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: briefId, name: briefName }];
      console.log(tempBriefDetails);

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
        },
      });

      console.log("created new row", createBackgroundRow);
      getBriefs();
    } catch (error) {
      console.error(error);
    }
  };

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);
      const newDefaultColumn = updatedBriefIds.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      if (backgroundTable?.data?.backgroundTable) {
        // await setDefaultColumnBriefs(
        //   backgroundTable.data.backgroundTable,
        //   briefs
        // );
        setBackgroundTableCols(backgroundTable?.data?.backgroundTable?.columns);
        // const taggedTableColumns =
        //   backgroundTable?.data?.backgroundTable?.columns
        //     .map((c) => {
        //       let columnObj;
        //       if (c.type === "DROPDOWN") {
        //         columnObj = {
        //           accessorKey: c.id,
        //           header: c.headerText,
        //           type: c.type,
        //           enabled: c.enabled,
        //           order: c.order,
        //           presets: c.presets,
        //           optionsText: c.optionsText ? c.optionsText : [],
        //         };
        //       } else {
        //         columnObj = {
        //           accessorKey: c.id,
        //           header: c.headerText,
        //           type: c.type,
        //           enabled: c.enabled,
        //           presets: c.presets,
        //           order: c.order,
        //         };
        //       }

        //       if (columnObj.presets && parseInt(columnObj.accessorKey) !== 3) {
        //         if (columnObj.presets.length === 0) {
        //           return columnObj;
        //         }
        //         if (
        //           columnObj.presets.some(
        //             (preset) => preset.name === "Builder Details"
        //           )
        //         ) {
        //           return columnObj;
        //         }
        //       }
        //     })
        //     .filter((obj) => obj !== undefined);

        // const columnIds = taggedTableColumns.map((obj) =>
        //   parseInt(obj.accessorKey)
        // );

        // const mergedWithDefaultCols = columnIds;

        // // Exclude disabled/hidden columns on the briefs settings
        // // Unticks Email Date column on first load:

        // const filteredTaggedCols = taggedTableColumns
        //   .filter(
        //     (obj) =>
        //       mergedWithDefaultCols.includes(parseInt(obj.accessorKey)) &&
        //       obj.enabled
        //   )
        //   .map((column) => ({
        //     ...column,
        //     visible: column.header === "Email Date" ? false : true,
        //   }));

        // setcolumn(filteredTaggedCols);
      } else {
        // const initialPresets = [];
        // for (const item of briefs) {
        //   const preset = { id: item.id, name: item.name };
        //   initialPresets.push(preset);
        // }
        // let backgroundCreate = await API.graphql({
        //   query: BACKGROUND_TABLE_CREATE,
        //   variables: {
        //     clientMatterId: clientMatterId,
        //     initialPresets: initialPresets,
        //   },
        // });
        // await setDefaultColumnBriefs(
        //   backgroundCreate.data.backgroundTableCreate,
        //   briefs
        // );
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getValueById(data, id) {
    const item = data[0]; // Assuming there's only one item in the array (only 1 row per brief)
    const dynamicData = item?.dynamicData;
    const matchingObject = dynamicData?.find((obj) => obj.id === id);

    if (matchingObject) {
      return matchingObject.value;
    } else {
      return null; // ID not found
    }
  }

  const getBriefs = async () => {
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      const ownerDetailsBrief = briefsList?.find(
        (brief) => brief.name === "Owner Details"
      );

      let tempOwnerDetailsInfo = {
        ownerName: null,
        ownerAddress: null,
        ownerEmail: null,
        ownerNumber: null
      }

      if (ownerDetailsBrief) {
        let unparsedData = ownerDetailsBrief?.backgrounds?.items;
        setBackgroundList(unparsedData);

        // console.log"getFiles", unparsedData);

        const parsedData = unparsedData?.map((item) => {
          if (item?.dynamicData) {
            try {
              const dynamicDataObject = JSON.parse(item.dynamicData);
              if (typeof dynamicDataObject === "object") {
                const dynamicDataArray = Object.keys(dynamicDataObject).map(
                  (key) => ({
                    id: key,
                    value: dynamicDataObject[key],
                  })
                );

                return {
                  ...item,
                  dynamicData: dynamicDataArray,
                };
              } else {
                console.warn(
                  "dynamicData is not a valid JSON object:",
                  dynamicDataObject
                );
              }
            } catch (error) {
              console.error("Error parsing dynamicData:", error);
            }
          } else {
            console.warn("dynamicData is missing or null for the item:", item);
          }
        });

        // Filter out items with dynamicData errors
        const validParsedData = parsedData.filter((item) => item);
        if (validParsedData !== "undefined" && validParsedData.length !== 0) {
          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId,
            },
          });

          // console.log"builder details bg", backgroundTable);
          if (backgroundTable?.data?.backgroundTable) {
            let backgroundTableInfo =
              backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
                let parsedValue = getValueById(
                  parsedData,
                  obj.accessorKey
                );

                switch(obj.headerText) {
                  case "Full Name":
                    setOwnerName(parsedValue);
                    tempOwnerDetailsInfo.ownerName = parsedValue
                    allDynamicData.current[obj.accessorKey] = parsedValue
                      ? parsedValue
                      : "";
                    break;
                  case "Email Address":
                    setOwnerEmail(parsedValue);
                    tempOwnerDetailsInfo.ownerEmail = parsedValue
                    allDynamicData.current[obj.accessorKey] = parsedValue
                      ? parsedValue
                      : "";
                    break;
                  case "Address":
                    setOwnerAddress(parsedValue);
                    tempOwnerDetailsInfo.ownerAddress = parsedValue
                    allDynamicData.current[obj.accessorKey] = parsedValue
                      ? parsedValue
                      : "";
                    break;
                  case "Phone Number":
                    setOwnerNumber(parsedValue);
                    tempOwnerDetailsInfo.ownerNumber = parsedValue
                    allDynamicData.current[obj.accessorKey] = parsedValue
                      ? parsedValue
                      : "";
                    break;
                  default:
                    break;
                }

                return parsedValue;
              });
            setOwnerDetailsInfo(tempOwnerDetailsInfo);
          }
        }
      }

      if (!ownerDetailsBrief) {
        let createBuilderDetails = await API.graphql({
          query: CREATE_BRIEF_MUTATION,
          variables: {
            clientMatterId: clientMatterId,
            name: "Owner Details",
            date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
            order: 0,
            columnIds: defaultColumnIds,
          },
        });

        const briefCreateData = createBuilderDetails?.data?.briefCreate;

        handleAddRow(briefCreateData.id, briefCreateData.name);

        console.log("return ->", briefCreateData.id);

        getBriefs();
      } else {
        console.log("has briefs", briefsList);
        setDefaultBriefColumnIds(briefsList, clientMatterId);
      }
    });
  };


  const allDynamicData = useRef({});

  const updateDynamicData = async (type, accessorKey, value, row, id) => {
    console.log("ACCESSORKEY", accessorKey);
    console.log("TYPE", type);
    console.log("ROW", row);
    console.log("ID", id);
    console.log("VALUE", value);
    const tempParsed =
      typeof row.dynamicData === "string" && row.dynamicData !== null
        ? JSON.parse(row.dynamicData)
        : row.dynamicData || {};

    const tempDynamic = { ...tempParsed, [accessorKey]: value };

    const tempRow = { ...row, dynamicData: tempDynamic };
    console.log("TEMPORARY ROW", tempRow.dynamicData[accessorKey]);
    allDynamicData.current = {
      ...allDynamicData.current,
      [accessorKey]: tempRow.dynamicData[accessorKey],
    };

    handleSaveInfo(id);
    // setAllDynamicData((prevState) => ({ ...prevState, [accessorKey]: tempRow.dynamicData[accessorKey] }) );
  };

  const handleSaveOwnerDetailsInfo = async (header, val) => {
    console.log("handleSaveOwnerDetailsInfo");

    const relevantHeaders = [
      "Full Name",
      "Email Address",
      "Address",
      "Phone Number",
    ];

    // Filter out the columns that match the header we are interested in.
    const interestedColumns = backgroundTableCols.filter(
      (obj) =>
        relevantHeaders.includes(obj.headerText) && obj.headerText === header
    );

    // Execute updateDynamicData for each matched column
    for (const obj of interestedColumns) {
      // Since this function is assumed to be asynchronous, we need to await its result.
      await updateDynamicData(
        obj.type,
        obj.accessorKey,
        val,
        backgroundList[0],
        backgroundList[0].id
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  };

  const handleToastNotification = () => {
    setResultMessage("Details successfully updated");
    setShowToast(true);

    setTimeout(() => {
      hideToast();
    }, 1500);
  };

  async function handleSaveInfo(id) {
    // if (ownerNumber && !validateEmail(ownerEmail)) {
    //   window.alert("Email address is not in the correct format");
    //   return;
    // }

    let tempOwnerDetailsInfo = {
      ownerName: null,
      ownerAddress: null,
      ownerEmail: null,
      ownerNumber: null
    }

    try {
      const dynamicDataResult = await API.graphql({
        query: mUpdateBackgroundDynamicData,
        variables: {
          id: id,
          dynamicData: JSON.stringify(allDynamicData.current),
        },
      });
      console.log("Dynamic Data", allDynamicData.current);
      console.log(
        "SUCCESS CHANGING DYNAMIC DATA",
        JSON.parse(dynamicDataResult.data.backgroundUpdate.dynamicData)
      );

      handleChangeGetStartedProgress("builderDetails");
      handleToastNotification();
      getBriefs();
      // console.log("INFO: ", buildInfo);
      // setBuildInfo(buildInfo);
    } catch (error) {
      console.error("Error updating builder info:", error);
    }
  }

  const handleRecordActivity = () => {
    //KJMF MMA 3586 RECORD ACTIVITY IF SANDBOX DATA IS CHANGED
    const mRecordSandboxDataEdit = `
      mutation createActivity(
        $companyId: ID,
        $activity: String,
        $appModule: AppModules,
        $field: String,
        $current: String,
        $previous: String,
        $rowId: String
      ) {
        activityCreate(
          activity: $activity
          companyId: $companyId
          appModule: $appModule
          previous: $previous
          field: $field
          current: $current
          rowId: $rowId
        ) {
          id
        }
      }
    `;
    
    //add activity when user exits the page
    var status = localStorage.getItem("isSandbox");

    if(status === "true"){
      if(hasEdits === true){
        const params = {
          query: mRecordSandboxDataEdit,
          variables: {
            companyId: localStorage.getItem("companyId"),
            activity: `Modified data in Owner Details Modal`,
            userId: localStorage.getItem("userId"),
            appModule: "SANDBOX",
          },
        };
  
        const addActivity = API.graphql(params).then((result) => {
          console.log(`Modified data in Owner Details Modal`, result);
        });
      }
    }

    setShowRightPanel(false);
  }

  return (
    <>
      {width > 844 ? (
        <div className="p-3 z-10">
          <div className="flex items-start justify-between w-full pl-2">
            <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-red-100 rounded-3xl border-4 border-red-50">
              <div className="text-justify text-red-600 text-lg font-normal leading-tight">
                <LuHardHat />
              </div>
            </div>
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 flex relative">
            <h3 className="pb-2 text-lg font-semibold text-gray-900">
              Owner Details
            </h3>
          </div>

          {/* INPUT OWNER'S NAME */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1 text-gray-900">
              {isSandbox === true ? "What are the owner/s name?" : "Owner/s Name"}
            </p>
            <div className="justify-center items-center mx-auto">
              <input
                placeholder="Owner/s Name"
                type="text"
                className="p-3 border rounded-md border-gray-400 w-full"
                defaultValue={
                  ownerDetailsInfo?.ownerName ? ownerDetailsInfo.ownerName : ""
                }
                onBlur={(e) => {
                  setOwnerName(e.target.value);
                  setHasEdits(true);
                  handleSaveOwnerDetailsInfo("Full Name", e.target.value);
                }}
                style={{ resize: "none" }}
              ></input>
              {isSandbox === true && (
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  Please provide the name of the owner or individual
                  responsible for construction.
                </p>
              )}
            </div>
          </div>

          {/* INPUT OWNER'S ADDRESS */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1 text-gray-900">
              {isSandbox === true ? "Where is their address?" : "Address"}
            </p>
            <div className="justify-center items-center mx-auto">
              <input
                placeholder="Address"
                type="text"
                className="p-3 border rounded-md border-gray-400 w-full"
                defaultValue={
                  ownerDetailsInfo?.ownerAddress ? ownerDetailsInfo.ownerAddress : ""
                }
                onBlur={(e) => {
                  setOwnerAddress(e.target.value);
                  setHasEdits(true);
                  handleSaveOwnerDetailsInfo("Address", e.target.value);
                }}
                style={{ resize: "none" }}
              ></input>
              {isSandbox === true && (
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  Please provide the address of the owner
                </p>
              )}
            </div>
          </div>
          
          {/* INPUT OWNER'S EMAIL */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">
              {isSandbox === true ? "What's their email address?" : "Email"}
            </p>
            <div className="justify-center items-center mx-auto">
              <div className="flex">
                <input
                  id="builder-email"
                  placeholder="Email"
                  type="text"
                  className={
                    isSandbox === true
                      ? "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto pr-16"
                      : "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto"
                  }
                  defaultValue={
                    ownerDetailsInfo?.ownerEmail
                      ? ownerDetailsInfo.ownerEmail
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setOwnerEmail(e.target.value);
                    handleSaveOwnerDetailsInfo(
                      "Email Address",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
                {isSandbox === true && (
                  <button
                    className="bg-white absolute h-11 right-0 w-14 p-1 rounded-r-md border border-gray-400 mr-2 flex items-center align-center"
                    onClick={() => {
                      console.log(
                        document.getElementById("builder-email").value
                      );
                      navigator.clipboard.writeText(
                        document.getElementById("builder-email").value
                      );
                      setBuilderContactCopy("Copy");
                      setBuilderEmailCopy("Copied");
                    }}
                  >
                    {builderEmailCopy === "Copy" && <FaRegCopy size={12} />}
                    <p className="text-xs font-normal font-open-sans ml-1">
                      {builderEmailCopy}
                    </p>
                  </button>
                )}
              </div>
              {isSandbox === true && (
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  This could be a project manager or a representative you'll be
                  in direct contact with.
                </p>
              )}
            </div>
          </div>

          {/* INPUT OWNER'S NUMBER */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">Phone Number</p>
            <div className="justify-center items-center mx-auto">
              <div className="flex">
                <input
                  id="builder-contact"
                  placeholder="Phone Number"
                  type="text"
                  className={
                    isSandbox === true
                      ? "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto pr-16"
                      : "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto"
                  }
                  defaultValue={
                    ownerDetailsInfo?.ownerNumber
                      ? ownerDetailsInfo.ownerNumber
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setOwnerNumber(e.target.value);
                    handleSaveOwnerDetailsInfo(
                      "Phone Number",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
                {isSandbox === true && (
                  <button
                    className="bg-white absolute h-11 right-0 w-14 p-1 rounded-r-md border border-gray-400 mr-2 flex items-center align-center"
                    onClick={() => {
                      console.log(
                        document.getElementById("builder-contact").value
                      );
                      navigator.clipboard.writeText(
                        document.getElementById("builder-contact").value
                      );
                      setBuilderContactCopy("Copied");
                      setBuilderEmailCopy("Copy");
                    }}
                  >
                    {builderContactCopy === "Copy" && <FaRegCopy size={12} />}
                    <p className="text-xs font-normal font-open-sans ml-1">
                      {builderContactCopy}
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* BOTTOM BUTTONS */}
          <div className="p-4 gap-x-2 flex border-t">
            <Button
              className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
              block={"full"}
              onClick={() => {
                // handleSaveInfo(backgroundList[0]?.id);
                // setShowRightPanel(false);
                handleRecordActivity();
              }}
            >
              <span className="h-fit w-fit font-semibold"> Close </span>
            </Button>
          </div>
        </div>
      ) : (
        //mobile builder details
        <div className="z-10">
          <div className="p-3 pt-4 pb-16">
            <div className="flex justify-between pb-3">
              <div className="flex items-center w-full pl-2">
                <img src={buildersIcon} alt="Builder' Icon" className="pb-2" />
                <h3 className="pb-2 text-lg font-semibold text-gray-900 pl-2">
                  Builder Details
                </h3>
              </div>
              <IoCloseOutline
                className="text-2xl text-gray-500"
                onClick={() => setShowRightPanel(false)}
              />
            </div>

            {/* INPUT BUILDER'S NAME */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal">
                Who is your builder?
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  placeholder="Builder's Name"
                  type="text"
                  className="py-2 px-3 border rounded-md border-gray-300 w-full mb-1"
                  defaultValue={
                    ownerDetailsInfo?.ownerName ? ownerDetailsInfo.ownerName : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setOwnerName(e.target.value);
                    handleSaveOwnerDetailsInfo("Full Name", e.target.value);
                  }}
                  style={{ resize: "none" }}
                ></input>
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  Please provide the name of the company or individual
                  responsible for construction.
                </p>
              </div>
            </div>

            {/* INPUT BUILDER'S CONTACT EMAIL */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                What's their email address?
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  placeholder="Email Address"
                  type="text"
                  className="py-2 px-3 mb-1 border rounded-md border-gray-300 w-full"
                  defaultValue={
                    ownerDetailsInfo?.ownerEmail
                      ? ownerDetailsInfo.ownerEmail
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setOwnerEmail(e.target.value);
                    handleSaveOwnerDetailsInfo(
                      "Email Address",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  This could be a project manager or a representative you'll be
                  in direct contact with.
                </p>
              </div>
            </div>

            {/* INPUT BUILDER'S CONTACT NUMBER */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                What's their phone number?
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  placeholder="Phone Number"
                  type="number"
                  className="py-2 px-3 mb-1 border rounded-md border-gray-300 w-full"
                  defaultValue={
                    ownerDetailsInfo?.ownerNumber
                      ? ownerDetailsInfo.ownerNumber
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setOwnerNumber(e.target.value);
                    handleSaveOwnerDetailsInfo(
                      "Phone Number",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
              </div>
            </div>
          </div>
          {/* BOTTOM BUTTONS */}
          <div className="fixed p-4 px-6 gap-x-4 flex bottom-0 border-t w-full justify-center bg-white">
            <Button
              className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
              block={"full"}
              onClick={() => {
                // handleSaveInfo(backgroundList[0]?.id);
                // setShowRightPanel(false);
                handleRecordActivity();
              }}
            >
              <span className="h-fit w-fit font-semibold"> Close </span>
            </Button>
          </div>
        </div>
      )}

      {showToast ? (
        <ToastNotification
          title={resultMessage}
          error={
            resultMessage === "Details successfully updated" ? false : true
          }
        />
      ) : null}
    </>
  );
}
