import { Menu, Transition } from "@headlessui/react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  // useSortBy,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { API, Storage } from "aws-amplify";
import classNames from "classnames";
import html2pdf from "html2pdf.js";
import { Base64 } from "js-base64";
import {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useColumnContext } from "../context/ColumnContext";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CreatableSelect from "react-select/creatable";
import ReactTooltip from "react-tooltip";
import {
  dateFormatter,
  datePickerDateFormatter,
  dateTimeFormatter,
  timezoneFormatter,
} from "../../../shared/userPreferredDateTime";

//NEW ICONS FOR MOBILE
import { BsFolder, BsGraphUpArrow } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import { BsTags } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";

import {
  AiFillCheckCircle,
  AiFillDatabase,
  AiFillFolder,
  AiOutlineCheck,
  AiOutlineFileDone,
  AiOutlineSetting,
} from "react-icons/ai";
import { LuArchiveRestore } from "react-icons/lu";
import {
  BiCopy,
  BiDuplicate,
  BiPaste,
  BiPencil,
  BiTrash,
  BiUndo,
} from "react-icons/bi";
import { BsCheckAll, BsPeopleFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import {
  HiOutlineChevronDoubleDown,
  HiOutlineChevronDoubleUp,
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineMenuAlt4,
  HiOutlineMinus,
} from "react-icons/hi";
import { MdClose, MdDescription } from "react-icons/md";
import { RiNumbersFill } from "react-icons/ri";

// import Select from "react-select";
import React from "react";
import googleLogin from "../../../assets/images/gmail-print.png";
import "../../../assets/styles/Inbox.css";
import config from "../../../aws-exports";
import Button from "../../../shared/Button";
import EmailParser from "../../../shared/EmailParser";
import convertHTMLEntity from "../../../shared/convertHTMLEntity";
import { useWindowDimensions } from "../../../shared/mobileViewFunctions";
import AddClientMatter from "../../inbox/addClientMatterModal";
import styles from "../styles.module.css";
import EmailModal from "./EmailModal";
import MobileEmailPreview from "./MobileEmailPreview";
import EmailPreviewModal from "./email-preview-modal";
import { hbaDomains, obaDomains } from "../../../constants/AppDomains";

import TaskList from "..";

const TASK_LIST_DATA_UPDATE = `
  mutation taskListDataUpdate($id: ID!, $input: TaskListDataInput!) {
    taskListDataUpdate(id: $id, input: $input) {
      data
      id
      createdAt
    }
  }
`;

const EMAIL_DOWNLOAD = `
  query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      isGmailPDF
      isGmailAttachment
      s3ObjectKey
    }
  }`;

const ATTACHMENT_DOWNLOAD = `
query getAttachmentDownloadLink($id: String) {
  gmailAttachment(id: $id) {
    downloadURL
  }
}`;

const EMAIL_ATTTACHMENT = `
  query getFileAttachments($id: ID) {
    gmailMessage(id: $id) {
      attachments {
        items {
          id
          name
          details
          isDeleted
        }
      }
    }
  }
`;

// const ACTIVITY_GET = `
// query getActivities($companyId: ID!, $appModule: AppModules, $rowId: String){
//     activities(companyId: $companyId, appModule: $appModule, rowId: $rowId) {
// 		appModule
//     	rowId
// 		field
// 		activity
// 		user{
// 			id
// 			firstName
// 			lastName
// 		}
//     }
// }
// `;

const COMPANY_NAME_QUERY = `
query companyName($companyId: String!){
  company(id: $companyId){
    name
  }
}
`;

const mgetUserById = `
    query user($id: String) {
      user(id: $id) {
        company {
          id
          name
        }
        id
        email
        firstName
        lastName
        userType
        profilePictureKey
        profilePicture
        businessName
        preferredTimezone
        preferredDateFormat
        isTwoFactorEnabled
      }
    }
  `;

const MESSAGE_PAYLOAD_QUERY = `
  query getPayloadByMessageId($id: ID) {
    gmailMessage(id: $id) {
      payload {
        content
      }
    }
  }
`;

const FILE_OBJECT_KEY_UPDATE = `
  mutation updateMatterFile ($id: ID, $s3ObjectKey: String, $size: Int, $type: String) {
    matterFileUpdate(id: $id, s3ObjectKey: $s3ObjectKey, size: $size, type: $type) {
      id
    }
  }
`;

const EMAIL_PREVIEW = `
  query getEmailDetails($id: ID) {
    gmailMessage(id: $id) {
      id
      from
      date
      to
      cc
      bcc
      subject
      recipient
      receivedAt
      payload {
        id
        content
      }
    }
  }
`;

const TASK_LIST_DATA_CREATE = `
  mutation taskListDataCreate($companyId: ID!, $input: TaskListDataInput!) {
    taskListDataCreate(companyId: $companyId, input: $input) {
      data
      id
      createdAt
    }
  }
`;

const TASKLIST_DATA_COUNT = `
query getTaskListsCount($companyId: ID!) {
  taskListDataCount(companyId: $companyId) {
    status
    count
  }
}`;

const ACTIVITY_CREATE = `
  mutation createActivity($activity: String, $appModule: AppModules,$clientMatterId: ID, $briefId: ID, $companyId: ID, $current: String, $field: String, $previous: String, $rowId: String) {
    activityCreate(
      companyId: $companyId
	  briefId: $briefId
      clientMatterId: $clientMatterId
      activity: $activity
      appModule: $appModule
      current: $current
      field: $field
      previous: $previous
      rowId: $rowId
    ) {
      id
    }
  }
`;

const UPDATE_TASK_LIST_TABLE_MUTATION = `
  mutation taskListTableUpdate(
      $id: ID!
      $input: TaskListTableInput!
      ) {
    taskListTableUpdate(
      id: $id
      input: $input
    ) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          label
        }
      }
    }
  }
`;

const TASKLIST_PRESETS_QUERY = `
  query taskListPresets($companyId: ID) {
    taskListPresets(companyId: $companyId) {
      id
      columnIds
      label
      name
    }
  }
`;

const CREATE_TASKLIST_PRESET_MUTATION = `
  mutation createTasklistPresets($companyId: ID, $label: String, $columnIds: [Int]) {
    taskListPresetsCreate(
      companyId: $companyId
      label: $label
      columnIds: $columnIds
    ) {
      id
    }
  }
`;

const UPDATETASKLISTPRESET_MUTATION = `
  mutation updateTasklistPresets($columnIds: [Int], $id: ID) {
    taskListPresetsUpdate(id: $id, columnIds: $columnIds) {
      id
    }
  }
`;

var moment = require("moment");
const companyId = localStorage.getItem("companyId");
var firstName = localStorage.getItem("firstName"),
  lastName = localStorage.getItem("lastName");
// const currentUser = `${firstName} ${lastName}`;
const userId = localStorage.getItem("userId");
const userName = `${firstName} ${lastName}`;
const userHistory = JSON.parse(localStorage.getItem("history"));

const EditableCell = ({
  table,
  getValue,
  row,
  column,
  people,
  clientMatters,
  setShowModal,
  ValueCopy,
  LabelCopy,
  setRowTarget,
  RowTarget,
  setValueCopy,
  setPrioLevel,
  setshowEmailPreview,
  setemailPreviewState,
  taskRegisters,
  setTaskRegisters,
  lastUpdatedPriorityValue,
  updatePriorityValue,
  isCurrentUserAssigned,
  parsePeople,
  fetchTaskListData,
  fetchCompletedTasks,
  fetchOutsandingTasks,
  fetchDataWithFilterBy,
  allTasks,
  setaAllTasks,
  outstandingTasks,
  setOutstandingTasks,
  completedTasks,
  setCompletedTasks,
  setOutstandingCount,
  setCompletedCount,
  taskRegisterChoices,
  presetsChoices,
}) => {
  const userType = localStorage.getItem("userType");
  const initialValue = getValue();
  const initialDate =
    initialValue === undefined ? new Date() : new Date(initialValue);
  const [value, setValue] = useState(initialValue);
  const [date, setDate] = useState(initialDate);
  const [label, setLabel] = useState();
  const [editablePeople, setEditablePeople] = useState(false);
  const [showEditPeople, setShowEditPeople] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [snippetId, setSnippetId] = useState(null);
  const [show, setShow] = useState(false);

  const [preview, setPreview] = useState([]);
  const [from, setFrom] = useState(null);
  const [dateEmail, setDateEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCC] = useState(null);
  const [columns, setColumns] = useColumnContext();
  const tableId = localStorage.getItem("task-list-table-id");
  const [presetsLibrary, setPresetsLibrary] = useState(presetsChoices);
  const [taskRegistersOptions, setTaskRegistersOptions] =
    useState(taskRegisterChoices);
  const [rowRegisters, setRowRegisters] = useState(row.original.registers);
  // const [creatorInitials, setCreatorInitials] = useState("");
  // const [creator, setCreator] = useState([]);
  const [showRegister, setShowRegister] = useState(true);

  const [currUrl, setCurrUrl] = useState(window.location.hostname);

  // // MMA-1100
  // useEffect(() => {
  //   if (column.columnDef.header == "Task Created By") {
  //     const params = {
  //       query: ACTIVITY_GET,
  //       variables: {
  //         companyId: companyId,
  //         appModule: "TASKS",
  //         rowId: row.original.id,
  //       },
  //     };
  //     API.graphql(params).then((result) => {
  //       let tempCreator = result?.data?.activities?.filter(
  //         (activity) => activity.field == "Create a task"
  //       );
  //       let tempInitials = "";
  //       if (tempCreator?.length > 0) {
  //         setCreator(tempCreator);
  //         if (
  //           (
  //             tempCreator[0].user.firstName +
  //             " " +
  //             tempCreator[0].user.lastName
  //           ).toLowerCase() === "dane mariel sta. ana"
  //         ) {
  //           tempInitials = "DM";
  //         } else if (
  //           (
  //             tempCreator[0].user.firstName +
  //             " " +
  //             tempCreator[0].user.lastName
  //           ).toLowerCase() === "john dela cruz"
  //         ) {
  //           tempInitials = "JDC";
  //         } else {
  //           tempInitials += tempCreator[0].user.firstName
  //             ? tempCreator[0].user.firstName?.charAt(0)
  //             : "";
  //           tempInitials += tempCreator[0].user.lastName
  //             ? tempCreator[0].user.lastName?.charAt(0)
  //             : "";
  //         }
  //         console.log("CREATOR INITIALS ARE:", tempInitials);
  //         setCreatorInitials(tempInitials);
  //       }
  //     });
  //   }
  // }, []);

  const priorityRef = useRef();

  // MMA-1303
  const handlePriorityChange = (e) => {
    lastUpdatedPriorityValue = e.target.value;
  };

  // Edit Task Registers

  useEffect(() => {
    setTaskRegistersOptions(
      taskRegisters.map((item) => ({ label: item.label, value: item.id }))
    );
  }, []);

  useEffect(() => {
    // getPresetsLibrary();
  }, []);

  async function getPresetsLibrary() {
    //	console.group("ColumnSettingTab: getPresetsLibrary()");
    const { data } = await API.graphql({
      query: TASKLIST_PRESETS_QUERY,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    });

    // console.log("taskListPresets", data);
    const tlPresets = sortArrayByLabel(data?.taskListPresets);
    const presetOpt = tlPresets.map((x) => {
      return {
        label: x.label,
        value: x.id,
      };
    });

    setPresetsLibrary(tlPresets);
    setTaskRegistersOptions(presetOpt);
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (initialValue !== value) {
      // for Primitives that needs staging
      if (column.columnDef.type === "TEXTAREA") {
        table.options.meta.stageEdit(row, column, value);
      } else if (column.columnDef.type === "TEXT") {
        table.options.meta.stageEdit(row, column, value);
      } else if (column.columnDef.type === "PHONE") {
        table.options.meta.stageEdit(row, column, value);
      } else if (column.columnDef.type === "NUMBER") {
        table.options.meta.stageEdit(row, column, value);
      } else if (column.columnDef.type === "CHECKBOX") {
        table.options.meta.stageEdit(row, column, value);
      } else {
        updateKeyRowItems();
      }
    }
  }, [value]);

  useEffect(() => {
    if (ValueCopy !== undefined) {
      if (RowTarget === row.original.id) {
        updateClientMatter();
      }
    }
  }, [ValueCopy]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // function for generating uniqueID with prefix of company (first 3 letters of company) and prefix of client/matter (first 3 letters of client and matter)
  // returns new unique ID with prefix (ex: LOP-CLI/MAT-4)
  function generateUniqueID(companyName, clientMatter, nextCount) {
    let companyPrefix = companyName
      .replaceAll(" ", "")
      .substring(0, 3)
      .toUpperCase();
    let clientMatterSections = clientMatter
      ?.replaceAll(" ", "")
      ?.toUpperCase()
      ?.split("/");
    if (clientMatterSections) {
      var clientPrefix = clientMatterSections[0].substring(0, 3);
      var matterPrefix = clientMatterSections[1].substring(0, 3);
    }

    let newUniqueID = "";
    if (nextCount) {
      let count =
        nextCount.length > 10 ? nextCount.substring(12) : nextCount.toString();
      newUniqueID =
        companyPrefix +
        "-" +
        (clientPrefix ? clientPrefix : "CLI") +
        "/" +
        (matterPrefix ? matterPrefix : "MAT") +
        "-" +
        count;
    } else {
      newUniqueID =
        companyPrefix +
        "-" +
        (clientPrefix ? clientPrefix : "CLI") +
        "/" +
        (matterPrefix ? matterPrefix : "MAT") +
        "-0";
    }
    console.log("new unique id:", newUniqueID);
    return newUniqueID;
  }

  async function queryUpdateKeyRowItems(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const taskListQuery = {
          query: TASK_LIST_DATA_UPDATE,
          variables: {
            id: data.id,
            input: data.input,
          },
        };
        const request = await API.graphql(taskListQuery).then((result) =>
          console.log("THIS IS UPDATE KEY ROW REQUEST", result)
        );
        resolve(request);
      } catch (error) {
        reject("ERROR IS: ", error);
      }
    });
  }

  const updateKeyRowItems = async () => {
    console.group("updateKeyRowItems()");
    let newRow = row.original;

    /** Client / Matter */
    let clientMatterId = null;
    if (column.id === "0") {
      clientMatterId = value;
      newRow["clientMatterId"] = value;
      newRow[0] = label;
    } else {
      newRow[column.id] = value;
    }
    console.log("ROW IS:", row);

    //temporarily fetches company name every render since uniqueID with prefix is not saved in DB
    let companyNameResult = await API.graphql({
      query: COMPANY_NAME_QUERY,
      variables: {
        companyId,
      },
    });
    let companyName = companyNameResult.data.company.name;

    let newUniqueID = generateUniqueID(
      companyName,
      label,
      row.original.uniqueId
    );

    /** Date Completed */
    let dateCompleted = null,
      completedBy = null;
    if (column.id === "7") {
      if (value === "Completed") {
        console.log("Task is Completed");
        dateCompleted = new Date();
        completedBy = userId;
      }
      newRow["dateCompleted"] = dateCompleted;
      newRow[12] = dateCompleted;
      newRow["completedBy"] = completedBy;
    }

    newRow["updatedAt"] = new Date();
    const data = {
      id: row.original.id,
      input: {
        data: JSON.stringify(newRow),
        clientMatterId: clientMatterId,
        dateCompleted: dateCompleted,
      },
    };

    const success = await queryUpdateKeyRowItems(data).then(async (result) => {
      const params = {
        query: ACTIVITY_CREATE,
        variables: {
          companyId: companyId,
          briefId: null,
          clientMatterId: row.original.clientMatterId,
          activity: `Update a ClientMatter`,
          field: "ClientMatter",
          appModule: "TASKS",
        },
      };
      const addActivity = await API.graphql(params).then((result) =>
        console.log("add activity result", result)
      );
    });

    // const request = await API.graphql({
    // 	query: TASK_LIST_DATA_UPDATE,
    // 	variables: {
    // 		id: row.original.id,
    // 		input: {
    // 			data: JSON.stringify(newRow),
    // 			clientMatterId: clientMatterId,
    // 			dateCompleted: dateCompleted,
    // 		},
    // 	},
    // });

    // console.log("REQUEST IS:", request);

    console.groupEnd("updateKeyRowItems()");
  };

  async function queryUpdateClientMatter(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const taskListQuery = {
          query: TASK_LIST_DATA_UPDATE,
          variables: {
            id: data.id,
            input: data.input,
          },
        };
        const request = await API.graphql(taskListQuery).then((result) =>
          console.log("THIS IS UPDATE CLIENT MATTER REQUEST", result)
        );
        resolve(request);
      } catch (error) {
        reject("ERROR IS: ", error);
      }
    });
  }

  const updateClientMatter = async () => {
    console.group("updateClientMatter()");
    if (RowTarget === row.original.id) {
      let newRow = row.original;

      /** Client / Matter */
      let clientMatterId = null;
      if (column.id === "0") {
        clientMatterId = ValueCopy;
        newRow["clientMatterId"] = ValueCopy;
        newRow[0] = LabelCopy;
      }

      newRow["updatedAt"] = new Date();

      const data = {
        id: row.original.id,
        input: {
          data: JSON.stringify(newRow),
          clientMatterId: clientMatterId,
        },
      };

      const success = await queryUpdateClientMatter(data).then(
        async (result) => {
          const params = {
            query: ACTIVITY_CREATE,
            variables: {
              companyId: companyId,
              briefId: null,
              clientMatterId: row.original.clientMatterId,
              activity: `Update a ClientMatter`,
              field: "ClientMatter",
              appModule: "TASKS",
            },
          };
          const addActivity = await API.graphql(params).then((result) =>
            console.log("add activity result", result)
          );
        }
      );

      setValueCopy(undefined); //Clear valuecopy to make sure
    }
  };

  const getEmailPayload = async (messageId) => {
    console.group("getEmailPayload()");
    const params = {
      query: MESSAGE_PAYLOAD_QUERY,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);
    if (result) {
      let newPayload = { payload: [{ content: "" }] };
      console.log("getEmailPayload()", result);

      result?.data?.gmailMessage.payload?.forEach((data, index) => {
        newPayload = {
          payload: [
            {
              content:
                newPayload.payload[0].content +
                result?.data?.gmailMessage?.payload[index]?.content,
            },
          ],
        };
      });
      console.log("NEW PAYLOAD IS:", newPayload);
      return newPayload;
    }
    // return result?.data?.gmailMessage;
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const handleDownloadLink = async (id, gmailMessageId, emailSubject) => {
    console.group("handleDownloadLink()");
    console.log("handleDonwloadLink()", id, gmailMessageId, emailSubject);

    var subjectTitle = emailSubject
      .replace(/[^a-z0-9]/gi, " ")
      .replace(/\s+/g, " ");
    var content = ``;

    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: config.aws_user_gmail_attachments_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    const email_params = {
      query: EMAIL_PREVIEW,
      variables: {
        id: gmailMessageId,
      },
    };

    await API.graphql(email_params).then((result) => {
      console.log("EMAIL_PREVIEW", result.data.gmailMessage);

      content += `<span>`;
      content += `<img src=${googleLogin} alt="" /><hr></hr>`;
      content += `<h2><b>${subjectTitle}</b></h2><hr></hr><br />`;
      content += `<p>From: ${result.data.gmailMessage.from}</p>`;
      content += `<p>Date: ${moment(result.data.gmailMessage.date).format(
        "DD MMM YYYY, hh:mm A"
      )}</p>`;
      content += `<p>To: ${result.data.gmailMessage.to}</p>`;
      content += result.data.gmailMessage.cc
        ? `<p>CC: ${result.data.gmailMessage.cc}</p>`
        : "";
      content += `</span>`;
    });

    const params = {
      query: EMAIL_DOWNLOAD,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { downloadURL, isGmailPDF, isGmailAttachment, s3ObjectKey } =
        result.data.file;

      if (isGmailAttachment && isGmailPDF && s3ObjectKey === null) {
        var opt = {
          margin: [30, 50, 30, 50],
          filename: subjectTitle,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 96,
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            backgroundColor: "#FFF",
          },
          jsPDF: { unit: "pt", format: "a4", orientation: "p" },
          pagebreak: {
            before: ".page-break",
            mode: ["avoid-all", "css", "legacy"],
          },
        };

        console.log("content:", content);

        const emailPayload = await getEmailPayload(gmailMessageId);

        if (emailPayload) {
          const parsedEmail = EmailParser(
            JSON.parse(emailPayload.payload[0].content)
          );
          const htmlContent = parsedEmail.html;
          // const htmlContent = emailPayload.payload
          //   .map((em) => em.content)
          //   .join("")
          //   .split('data":"')
          //   .pop()
          //   .split('"}')[0];

          content += htmlContent;

          // content += Base64.decode(htmlContent).replace("body{color:", "");

          await html2pdf()
            .from(content)
            .set(opt)
            .toPdf()
            .output("datauristring")
            .then(function (pdfAsString) {
              const preBlob = dataURItoBlob(pdfAsString);
              const file = new File([preBlob], subjectTitle, {
                type: "application/pdf",
              });
              console.log(file);

              var key = `${gmailMessageId}/${Number(new Date())}${file.name
                  .replaceAll(/\s/g, "")
                  .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
                type = "application/pdf",
                size = file.size;

              // put objects to s3
              try {
                Storage.put(key, file, {
                  contentType: type,
                  progressCallback(progress) {
                    console.log(progress);
                  },
                  errorCallback: (err) => {
                    console.error("204: Unexpected error while uploading", err);
                  },
                })
                  .then((fd) => {
                    const params = {
                      query: FILE_OBJECT_KEY_UPDATE,
                      variables: {
                        id: id,
                        s3ObjectKey: fd.key,
                        size: parseInt(size),
                        type: type,
                      },
                    };

                    API.graphql(params).then(async (res) => {
                      console.log("Get Details", res);
                      const params = {
                        query: EMAIL_DOWNLOAD,
                        variables: {
                          id: id,
                        },
                      };
                      await API.graphql(params).then(async (result) => {
                        const { downloadURL } = result.data.file;
                        window.open(downloadURL);
                      });
                    });
                  })
                  .catch((err) => {
                    console.error("Unexpected error while uploading", err);
                  });
              } catch (e) {
                const response = {
                  error: e.message,
                  errorStack: e.stack,
                  statusCode: 500,
                };
                console.error("Unexpected error while uploading", response);
              }
            });
        }
      } else {
        // PDF is already generated
        window.open(downloadURL);
      }

      console.groupEnd();
    });
  };

  const handleDownloadAttachment = async (id) => {
    const params = {
      query: ATTACHMENT_DOWNLOAD,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then((result) => {
      window.open(result.data.gmailAttachment.downloadURL);
    });
  };

  async function getAttachments(id) {
    const params = {
      query: EMAIL_ATTTACHMENT,
      variables: {
        id: id,
      },
    };

    var response = await API.graphql(params);
    var result = response.data.gmailMessage?.attachments?.items;
    var filteredResult = result.filter((u) => u.isDeleted !== true);
    var filteredResult = filteredResult.filter(
      (u) => u.details !== "EMAIL_PDF_VERSION"
    );
    setAttachments(filteredResult);
  }

  const workDays = (startDate, endDate) => {
    startDate = moment.utc(startDate);
    endDate = moment.utc(endDate);
    var workDays = 0;
    for (
      var i = moment(startDate).add(1, "d");
      moment(i).isBefore(endDate);
      i = moment(i).add(1, "d")
    ) {
      if (moment(i).day() === 0 || moment(i).day() === 6) {
        // 0 = sunday, 6 = saturday
        continue;
      }
      workDays++;
    }
    //console.log("workdays: " + workDays)
    return workDays;
  };

  // WIP - AQS
  const getDayTimeDifference = (startDate, endDate) => {
    /* Converting the startDate and endDate to UTC */
    endDate = moment.utc(endDate).toISOString();
    startDate = moment.utc(startDate).toISOString();
    var diffInMinutes = moment(endDate).diff(moment(startDate), "minutes");

    const totalDays = workDays(startDate, endDate);

    var hrs = 1440; // mins in 24hrs
    // var hrs = 480; // mins in 8hrs (work hours)
    // var inDays = Math.floor(diffInMinutes / hrs);
    var inDays = totalDays;
    var inHours = Math.floor((diffInMinutes % hrs) / 60);
    var inMinutes = (diffInMinutes % hrs) % 60;
    // const version = 1 // version 1 - 8 hours, version 2 - 24 hours
    // if (inHours > 8 && version === 1) {
    //   var newDays = Math.floor(inHours / 8)
    //   inHours = inHours - (newDays*8)
    //   inDays = inDays + newDays
    // }

    var dateDifference = [];

    inDays !== 0 &&
      dateDifference.push(
        <>
          <strong>{inDays}</strong>
          {inDays > 1 ? <>days</> : <>day</>}
        </>
      );

    inHours !== 0 &&
      dateDifference.push(
        <>
          &nbsp;<strong>{inHours}</strong>
          {inHours > 1 ? <>hrs</> : <>hr</>}
        </>
      );

    inMinutes !== 0 &&
      dateDifference.push(
        <>
          &nbsp;<strong>{inMinutes}</strong>
          {inMinutes > 1 ? <>mins</> : <>min</>}
        </>
      );
    return dateDifference;
  };

  // MMA-1981 Update Task Register Field
  // KJMFEDIT1 - Task register - April 17
  const updateTaskRegisters = async (id, row, newValues) => {
    console.log("data", newValues);

    // Orig registers
    const origRegisters = row.registers;
    // Set New Registers
    const newRow = Object.fromEntries(
      Object.entries(row).filter(([key]) => !isNaN(key))
    );
    console.log("newRow", newRow);
    newRow.registers = newValues;
    // Set Updated Dates
    newRow["updatedAt"] = new Date();

    const newCols = await Promise.allSettled(
      newValues.map(async (r) => {
        if (r?.__isNew__) {
          console.log("ok so far");
          const { label } = r;
          const objChecker = findObjectByLabel(taskRegistersOptions, label);
          if (!objChecker) {
            const newId = await createNewRegister(label);
            return { value: newId, label };
          }
        }
        return r;
      })
    );

    // const updatedCols = newCols.map((r) => {
    //   const v = r?.value;
    //   return { value: v.value, label: v.label };
    // });

    try {
      await API.graphql({
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: row.id,
          input: {
            data: JSON.stringify(newRow),
          },
        },
      }).then(async (res) => {
        console.log("Updated Registers", res);
        const params = {
          query: ACTIVITY_CREATE,
          variables: {
            companyId: companyId,
            briefId: null,
            clientMatterId: row.clientMatterId,
            activity: `Updated Task Register`,
            field: "Task Register",
            current: newValues,
            previous: origRegisters,
            appModule: "TASKS",
            rowId: row.id,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result, "UPDATED TASK REGISTERS");
        });
      });
    } catch (error) {
      console.log("Error in updating: ", error);
    }

    var allTasksCopy = allTasks;
    var outstandingTasksCopy = outstandingTasks;
    var completedTasksCopy = completedTasks;

    allTasksCopy.map((x) => {
      if (x.id === id) {
        x.registers = newValues;
      }
    });

    outstandingTasksCopy.map((x) => {
      if (x.id === id) {
        x.registers = newValues;
      }
    });

    completedTasksCopy.map((x) => {
      if (x.id === id) {
        x.registers = newValues;
      }
    });

    setaAllTasks(allTasksCopy);
    setOutstandingTasks(outstandingTasksCopy);
    setCompletedTasks(completedTasksCopy);

    // setRowRegisters(updatedCols);
    // updateRegisters(id, updatedCols);
  };

  useEffect(() => {
    if (column.columnDef.type === "PEOPLE" && row.original[9] !== undefined) {
      // check if the row.original has a comma
      // if it does, split it into an array
      // if it doesn't, just make it an array

      let peopleArray;
      if (peopleArray !== null || peopleArray !== undefined) {
        // console.log("row.original[9]", row.original[9]);
        peopleArray = row.original[9]?.split(",");

        let peopleArray2 = [];
        if (peopleArray && peopleArray.length != 0) {
          peopleArray.forEach((person) => {
            peopleArray2.push({ label: person, value: person });
          });
        }

        setSelectedPeople(peopleArray2);
      } else {
        peopleArray = [];
        setSelectedPeople(peopleArray);
      }
    }
  }, []);

  function sortByAccesorKey(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.accessorKey - b.accessorKey);
    } else {
      sort = arr;
    }

    return sort;
  }

  async function saveColumns(newColumnSettingsWithId) {
    // console.log("saveColumns()");
    const columnsToSetApi = sortByAccesorKey(newColumnSettingsWithId).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    if (columnsToSetApi.length > 0) {
      const { data } = await API.graphql({
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      });

      // console.log("Saved Columns", data.taskListTableUpdate.columns);

      const tableColumns = data.taskListTableUpdate.columns.map((c) => {
        if (c.type === "DROPDOWN") {
          return {
            accessorKey: c.accessorKey,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            order: c.order,
            presets: c.presets,
            optionsText: c.optionsText ? c.optionsText : [],
          };
        }
        return {
          accessorKey: c.accessorKey,
          header: c.headerText,
          type: c.type,
          order: c.order,
          presets: c.presets,
          enabled: c.enabled,
        };
      });

      // console.log("setColumns", tableColumns);
      setColumns(tableColumns);
    }
  }

  async function groupByPresetsId(arr) {
    return arr.reduce((acc, curr) => {
      curr?.presets?.forEach((preset) => {
        if (!acc[preset.id]) {
          acc[preset.id] = [];
        }

        // console.log("curr.accessorKey", curr.accessorKey);
        if (curr.accessorKey !== "") {
          acc[preset.id].push(parseInt(curr.accessorKey, 10));
        }
      });
      return acc;
    }, {});
  }

  function sortArrayByLabel(arr) {
    arr.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return arr;
  }

  async function runPresets(preset) {
    console.group("runPresets()");
    const presetColumns = presetsLibrary?.find((obj) => obj.id === preset);
    // console.log("PRESET COLUMNS", presetColumns);
    const presetColumnIds = new Set(presetColumns?.columnIds || []);

    // console.log("presetColumnIds", presetColumnIds);
    const newColumnSettingsWithId = columns.map((column) => ({
      ...column,
      enabled: presetColumnIds.has(parseInt(column.accessorKey)),
    }));

    // console.log("newColumnSettingsWithId", newColumnSettingsWithId);

    const columnsToSetApi = sortByAccesorKey(newColumnSettingsWithId).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    // console.log("columnsToSetApi", columnsToSetApi);

    if (columnsToSetApi.length > 0) {
      const { data } = await API.graphql({
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      });

      // console.log("Updated Cols ", data.taskListTableUpdate.columns);

      const tableColumns = data.taskListTableUpdate.columns.map((c) => {
        if (c.type === "DROPDOWN") {
          return {
            accessorKey: c.accessorKey,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            order: c.order,
            presets: c.presets,
            optionsText: c.optionsText ? c.optionsText : [],
          };
        }
        return {
          accessorKey: c.accessorKey,
          header: c.headerText,
          type: c.type,
          order: c.order,
          presets: c.presets,
          enabled: c.enabled,
        };
      });

      setColumns(tableColumns);

      console.groupEnd();
    }
  }

  async function updateRegisters(accessorKey, registers) {
    // console.log("updateRegisters()");
    const presets = registers.map((item) => {
      const { value, ...rest } = item;
      return { id: value, ...rest };
    });

    const newColumnSettingsWithId = columns.map((column, index) => {
      if (column.accessorKey === accessorKey) {
        column.presets = presets;
      }
      return column;
    });

    // console.log("Columns:", columns);
    // console.log("Columns with Presets:", newColumnSettingsWithId);

    await saveColumns(newColumnSettingsWithId);

    const groupedPresets = await groupByPresetsId(newColumnSettingsWithId);
    // console.log("groupedPresets", groupedPresets);

    const mappedPresets = Object.keys(groupedPresets).map((key) => ({
      presetId: key,
      columnIds: groupedPresets[key],
    }));

    // console.log("mappedPresets", mappedPresets);

    const savePromises = mappedPresets.map(async ({ columnIds, presetId }) => {
      try {
        await API.graphql({
          query: UPDATETASKLISTPRESET_MUTATION,
          variables: { columnIds, id: presetId },
        });
      } catch (error) {
        console.error(error);
      }
    });

    await Promise.allSettled(savePromises);
    // setPreset("");
    await getPresetsLibrary();
  }

  async function createNewRegister(label) {
    const companyId = localStorage.getItem("companyId");
    const { data } = await API.graphql({
      query: CREATE_TASKLIST_PRESET_MUTATION,
      variables: {
        companyId,
        label,
        columnIds: null,
      },
    });
    // console.log("Newly Added ID is", data?.taskListPresetsCreate?.id);
    return data?.taskListPresetsCreate?.id;
  }

  function findObjectByLabel(arr, label) {
    return arr.find((obj) => obj.label === label);
  }

  // kjmfedit task status
  async function updateStatus(data, e, oldStatus) {
    console.log("entered as", oldStatus);
    return new Promise((resolve, reject) => {
      try {
        const statusQuery = {
          query: TASK_LIST_DATA_UPDATE,
          variables: {
            id: data.id,
            input: {
              data: JSON.stringify(data.temp),
            },
          },
        };
        const request = API.graphql(statusQuery).then((res) => {
          console.log("Updated Status", data.temp["7"]);

          var allTasksCopy = allTasks;
          var outstandingTasksCopy = outstandingTasks;
          var completedTasksCopy = completedTasks;

          console.log("old status", oldStatus);

          if (oldStatus === e.value) {
            console.log("no changes");
          } else {
            if (e.value === "Outstanding") {
              allTasksCopy.map((x) => {
                if (x.id === data.id) {
                  if (x["7"] !== e.value) {
                    x["7"] = e.value;
                  }
                  outstandingTasksCopy.unshift(x);
                }
              });

              var newCompleted = completedTasksCopy.filter(
                (obj) => obj.id !== data.id
              );

              console.log("newcompleted", newCompleted);

              setCompletedTasks(newCompleted);
              setaAllTasks(allTasksCopy);
              setOutstandingTasks(outstandingTasksCopy);
              setOutstandingCount(outstandingTasksCopy?.length);
              setCompletedCount(newCompleted?.length);
            } else if (e.value === "Completed") {
              allTasksCopy.map((x) => {
                if (x.id === data.id) {
                  if (x["7"] !== e.value) {
                    x["7"] = e.value;
                  }
                  completedTasksCopy.unshift(x);
                }
              });

              var newOutstanding = outstandingTasksCopy.filter(
                (obj) => obj.id !== data.id
              );

              console.log("newoutstanding", newOutstanding);
              console.log("newCompleted", completedTasksCopy);

              setOutstandingTasks(newOutstanding);
              setaAllTasks(allTasksCopy);
              setCompletedTasks(completedTasksCopy);
              setOutstandingCount(newOutstanding?.length);
              setCompletedCount(completedTasksCopy?.length);
            } else {
              allTasksCopy.map((x) => {
                if (x.id === data.id) {
                  if (x["7"] !== e.value) {
                    x["7"] = e.value;
                  }
                }
              });

              var newOutstanding = outstandingTasksCopy.filter(
                (obj) => obj.id !== data.id
              );
              var newCompleted = completedTasksCopy.filter(
                (obj) => obj.id !== data.id
              );

              setOutstandingTasks(newOutstanding);
              setaAllTasks(allTasksCopy);
              setCompletedTasks(newCompleted);
              setOutstandingCount(newOutstanding?.length);
              setCompletedCount(newCompleted?.length);
            }
          }

          //fetchDataWithFilterBy("Filters");
        });
        resolve(request);
      } catch (error) {
        reject("ERROR: ", error);
      }
    });
  }

  const [tempOptions, setTempOptions] = useState([
    { label: "test1", value: "test1" },
  ]);

  const updateRegistersRow = async (rowDetails, e) => {
    console.log("rowDetails", rowDetails);
    console.log("rowRegisters", rowRegisters);
  };

  const saveStatusDesktop = async (e, rowDetails) => {
    // var data = !loading ? data : [...Array(5).keys()]
    console.group("saveStatus()", rowDetails["7"]);

    var oldStatus = rowDetails["7"];
    console.log("input", rowDetails);
    var rowId = rowDetails.id;
    // data.map((x) => (x.id === rowId ? (x[7] = e.label) : x));

    var temp = rowDetails;
    temp[7] = e.label;
    temp["updatedAt"] = new Date();

    const data = {
      id: rowDetails.id,
      temp: temp,
    };

    const success = await updateStatus(data, e, oldStatus).then(
      async (result) => {
        const params = {
          query: ACTIVITY_CREATE,
          variables: {
            companyId: companyId,
            clientMatter: rowDetails.clientMatterId,
            briefId: null,
            activity: `Update Status`,
            field: "Status",
            appModule: "TASKS",
            rowId: rowDetails.id,
          },
        };

        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result, "UPDATED STATUS");
        });
      }
    );
  };

  // console.log("COLUMN TYPE:", column.columnDef.header);
  // default columns
  if (column.columnDef.type === "NUMBER") {
    return (
      <input
        type="number"
        className="p-2 w-20"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          table.options.meta.commitStagedEdits();
        }}
      />
    );
  }

  if (column.columnDef.type === "PHONE") {
    return (
      <input
        type="number"
        className="p-2 w-36"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          table.options.meta.commitStagedEdits();
        }}
      />
    );
  }

  if (column.columnDef.type === "DROPDOWN") {
    if (
      column.columnDef.header ===
      (hbaDomains.includes(currUrl) ? "Project Name" : "Client/Matter")
    ) {
      if (userType == "OWNER") {
        return (
          <div id={`cm-${row.id}`}>
            {/*MMA1060 Change*/}
            <CreatableSelect
              options={clientMatters}
              className="w-64"
              onCreateOption={() => {
                setShowModal(true);
                setRowTarget(row?.original?.id);
              }}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              defaultValue={
                row.original.clientMatterId
                  ? clientMatters.find(
                      (clientMatter) =>
                        clientMatter.value === row.original.clientMatterId
                    )
                  : "this is testing null"
              }
              onChange={(e) => {
                setValue(e.value);
                console.log("e.value", e.value);
                setLabel(e.label);
              }}
              placeholder={
                hbaDomains.includes(currUrl)
                  ? "Project Name"
                  : "Select Client/Matter"
              }
            />
          </div>
        );
      } else if (row.original.hasOwnProperty(9)) {
        let assignees = row.original[9].split(",");
        if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
          // RETURN EDITABLE
          return (
            <div id={`cm-${row.id}`}>
              {/*MMA1060 Change*/}
              <CreatableSelect
                options={clientMatters}
                className="w-64"
                onCreateOption={() => {
                  setShowModal(true);
                  setRowTarget(row?.original?.id);
                }}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                defaultValue={
                  row.original.clientMatterId
                    ? clientMatters.find(
                        (clientMatter) =>
                          clientMatter.value === row.original.clientMatterId
                      )
                    : "this is testing null"
                }
                onChange={(e) => {
                  setValue(e.value);
                  console.log("e.value", e.value);
                  setLabel(e.label);
                }}
                placeholder={
                  hbaDomains.includes(currUrl)
                    ? "Project Name"
                    : "Select Client/Matter"
                }
              />
            </div>
          );
        } else {
          // RETURN TEXT
          let clientMatterText = row.original.clientMatterId
            ? clientMatters.find(
                (clientMatter) =>
                  clientMatter.value === row.original.clientMatterId
              )
            : { label: "N/A" };
          return (
            <div className="w-64" id={`cm-${row.id}`}>
              <span className="w-full">
                {clientMatterText?.label == "" ||
                clientMatterText?.label == undefined ||
                clientMatterText?.label == null
                  ? "N/A"
                  : clientMatterText.label}
              </span>
            </div>
          );
        }
      } else {
        // UNASSIGNED ... RETURN EDITABLE
        return (
          <>
            {/*MMA1060 Change*/}
            <CreatableSelect
              options={clientMatters}
              className="w-64"
              onCreateOption={() => {
                setShowModal(true);
                setRowTarget(row?.original?.id);
              }}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              defaultValue={
                row.original.clientMatterId
                  ? clientMatters.find(
                      (clientMatter) =>
                        clientMatter.value === row.original.clientMatterId
                    )
                  : "this is testing null"
              }
              onChange={(e) => {
                setValue(e.value);
                console.log("e.value", e.value);
                setLabel(e.label);
              }}
              placeholder={
                hbaDomains.includes(currUrl)
                  ? "Project Name"
                  : "Select Client/Matter"
              }
            />
          </>
        );
      }
    }
    if (column.columnDef.header === "Task Registers") {
      if (userType == "OWNER") {
        return (
          <>
            {/* MMA-1981 Change */}
            {/* KJMF EDIT TR */}

            <div id={`taskreg-${row.id}`}>
              <CreatableSelect
                options={taskRegistersOptions}
                className="w-64"
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                isMulti
                defaultValue={
                  row.original.registers
                  //rowRegisters ? rowRegisters : null
                  // row.original.registers && Array.isArray(row.original.registers)
                  // 	? taskRegisters
                  // 			.filter((register) =>
                  // 				row.original.registers.find(
                  // 					(item) => item?.label === register.label
                  // 				)
                  // 			)
                  // 			.map((value) => ({
                  // 				label: value.label,
                  // 				value: value.id,
                  // 			}))
                  // 	: null
                }
                onChange={(e) => {
                  updateTaskRegisters(row.original.id, row.original, e);
                  //updateRegistersRow(row.original, e)
                }}
                placeholder="Select Task Register"
                openMenuOnClick={true}
                openMenuOnFocus={true}
              />
            </div>
          </>
        );
      } else if (row.original.hasOwnProperty(9)) {
        let assignees = row.original[9].split(",");
        if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
          return (
            <>
              {/* MMA-1981 Change */}
              <div id={`taskreg-${row.id}`}>
                <CreatableSelect
                  options={taskRegistersOptions}
                  className="w-64"
                  styles={{
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  isMulti
                  defaultValue={
                    rowRegisters ? rowRegisters : null
                    // row.original.registers && Array.isArray(row.original.registers)
                    // 	? taskRegisters
                    // 			.filter((register) =>
                    // 				row.original.registers.find(
                    // 					(item) => item?.label === register.label
                    // 				)
                    // 			)
                    // 			.map((value) => ({
                    // 				label: value.label,
                    // 				value: value.id,
                    // 			}))
                    // 	: null
                  }
                  onChange={(e) => {
                    updateTaskRegisters(column.accessorKey, row.original, e);
                  }}
                  placeholder="Select Task Register"
                  openMenuOnClick={true}
                  openMenuOnFocus={true}
                />
              </div>
            </>
          );
        } else {
          return (
            <>
              {/* MMA-1981 Change */}
              <div id={`taskreg-${row.id}`}>
                <CreatableSelect
                  options={taskRegistersOptions}
                  className="w-64"
                  styles={{
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  isMulti
                  defaultValue={
                    rowRegisters ? rowRegisters : null
                    // row.original.registers && Array.isArray(row.original.registers)
                    // 	? taskRegisters
                    // 			.filter((register) =>
                    // 				row.original.registers.find(
                    // 					(item) => item?.label === register.label
                    // 				)
                    // 			)
                    // 			.map((value) => ({
                    // 				label: value.label,
                    // 				value: value.id,
                    // 			}))
                    // 	: null
                  }
                  onChange={(e, action) => {
                    console.log("ACTIOOOOON TASK REGISTER ->>>", action);
                    updateTaskRegisters(column.accessorKey, row.original, e);
                  }}
                  placeholder="Select Task Register"
                  openMenuOnClick={true}
                  openMenuOnFocus={true}
                />
              </div>
            </>
          );
        }
      } else {
        return (
          <>
            {/* MMA-1981 Change */}
            <div id={`taskreg-${row.id}`}>
              <CreatableSelect
                options={taskRegistersOptions}
                className="w-64"
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                isMulti
                defaultValue={
                  rowRegisters ? rowRegisters : null
                  // row.original.registers && Array.isArray(row.original.registers)
                  // 	? taskRegisters
                  // 			.filter((register) =>
                  // 				row.original.registers.find(
                  // 					(item) => item?.label === register.label
                  // 				)
                  // 			)
                  // 			.map((value) => ({
                  // 				label: value.label,
                  // 				value: value.id,
                  // 			}))
                  // 	: null
                }
                onChange={(e, action) => {
                  console.log("ACTIOOOOON TASK REGISTER ->>>", action);
                  updateTaskRegisters(column.accessorKey, row.original, e);
                }}
                placeholder="Select Task Register"
                openMenuOnClick={true}
                openMenuOnFocus={true}
              />
            </div>
          </>
        );
      }
    }
    if (column.columnDef.header === "Priority") {
      if (userType == "OWNER") {
        return (
          <div className="flex items-center gap-2" id={`priority-${row.id}`}>
            <span className="inline-block">
              {row.original[8] === "1" || row.original[8] === 1 ? (
                <HiOutlineChevronDoubleUp className="text-danger" />
              ) : row.original[8] === "2" || row.original[8] === 2 ? (
                <HiOutlineChevronUp className="text-orange-400" />
              ) : row.original[8] === "3" || row.original[8] === 3 ? (
                <HiOutlineMenuAlt4 className="text-warning" />
              ) : row.original[8] === "4" || row.original[8] === 4 ? (
                <HiOutlineChevronDown className="text-gray-500" />
              ) : row.original[8] === "5" || row.original[8] === 5 ? (
                <HiOutlineChevronDoubleDown className="text-gray-300" />
              ) : row.original[8] === "" || row.original[8] === "" ? (
                <HiOutlineMinus className="text-gray-300" />
              ) : null}
            </span>
            <select
              suppressContentEditableWarning={true}
              contentEditable={true}
              //defaultValue={value}
              //onChange={(e) => setValue(e.target.value)}
              className="p-2 rounded-lg border bg-transparent max-w-xs inline-block"
              defaultValue={initialValue}
              onChange={(e) => {
                table.options.meta.updateData(row, column, e.target.value);
                updatePriorityValue(e.target.value);
                console.log(
                  `Last Updated Priority Value: ${lastUpdatedPriorityValue}`
                );
              }}
            >
              <option value="">Select</option>
              {column.columnDef.optionsText.map((opt, i) => {
                return (
                  <option key={`${i}_${opt}`} value={opt}>
                    {opt}
                  </option>
                );
              })}
            </select>
          </div>
        );
      } else if (row.original.hasOwnProperty(9)) {
        let assignees = row.original[9].split(",");
        if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
          return (
            <div className="flex items-center gap-2">
              <span className="inline-block">
                {row.original[8] === "1" || row.original[8] === 1 ? (
                  <HiOutlineChevronDoubleUp className="text-danger" />
                ) : row.original[8] === "2" || row.original[8] === 2 ? (
                  <HiOutlineChevronUp className="text-orange-400" />
                ) : row.original[8] === "3" || row.original[8] === 3 ? (
                  <HiOutlineMenuAlt4 className="text-warning" />
                ) : row.original[8] === "4" || row.original[8] === 4 ? (
                  <HiOutlineChevronDown className="text-gray-500" />
                ) : row.original[8] === "5" || row.original[8] === 5 ? (
                  <HiOutlineChevronDoubleDown className="text-gray-300" />
                ) : row.original[8] === "" || row.original[8] === "" ? (
                  <HiOutlineMinus className="text-gray-300" />
                ) : null}
              </span>
              <select
                suppressContentEditableWarning={true}
                contentEditable={true}
                //defaultValue={value}
                //onChange={(e) => setValue(e.target.value)}
                className="p-2 rounded-lg border bg-transparent max-w-xs inline-block"
                defaultValue={initialValue}
                onChange={(e) => {
                  table.options.meta.updateData(row, column, e.target.value);
                  updatePriorityValue(e.target.value);
                  console.log(
                    `Last Updated Priority Value: ${lastUpdatedPriorityValue}`
                  );
                }}
              >
                <option value="">Select</option>
                {column.columnDef.optionsText.map((opt, i) => {
                  return (
                    <option key={`${i}_${opt}`} value={opt}>
                      {opt}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        } else {
          return (
            <div className="flex items-center gap-2">
              <span className="inline-block">
                {row.original[8] === "1" || row.original[8] === 1 ? (
                  <HiOutlineChevronDoubleUp className="text-danger" />
                ) : row.original[8] === "2" || row.original[8] === 2 ? (
                  <HiOutlineChevronUp className="text-orange-400" />
                ) : row.original[8] === "3" || row.original[8] === 3 ? (
                  <HiOutlineMenuAlt4 className="text-warning" />
                ) : row.original[8] === "4" || row.original[8] === 4 ? (
                  <HiOutlineChevronDown className="text-gray-500" />
                ) : row.original[8] === "5" || row.original[8] === 5 ? (
                  <HiOutlineChevronDoubleDown className="text-gray-300" />
                ) : row.original[8] === "" || row.original[8] === "" ? (
                  <HiOutlineMinus className="text-gray-300" />
                ) : null}
              </span>
              <span className="p-2">{initialValue}</span>
            </div>
          );
        }
      } else {
        return (
          <div className="flex items-center gap-2">
            <span className="inline-block">
              {row.original[8] === "1" || row.original[8] === 1 ? (
                <HiOutlineChevronDoubleUp className="text-danger" />
              ) : row.original[8] === "2" || row.original[8] === 2 ? (
                <HiOutlineChevronUp className="text-orange-400" />
              ) : row.original[8] === "3" || row.original[8] === 3 ? (
                <HiOutlineMenuAlt4 className="text-warning" />
              ) : row.original[8] === "4" || row.original[8] === 4 ? (
                <HiOutlineChevronDown className="text-gray-500" />
              ) : row.original[8] === "5" || row.original[8] === 5 ? (
                <HiOutlineChevronDoubleDown className="text-gray-300" />
              ) : row.original[8] === "" || row.original[8] === "" ? (
                <HiOutlineMinus className="text-gray-300" />
              ) : null}
            </span>
            <select
              suppressContentEditableWarning={true}
              contentEditable={true}
              //defaultValue={value}
              //onChange={(e) => setValue(e.target.value)}
              className="p-2 rounded-lg border bg-transparent max-w-xs inline-block"
              defaultValue={initialValue}
              onChange={(e) => {
                table.options.meta.updateData(row, column, e.target.value);
                updatePriorityValue(e.target.value);
                console.log(
                  `Last Updated Priority Value: ${lastUpdatedPriorityValue}`
                );
              }}
            >
              <option value="">Select</option>
              {column.columnDef.optionsText.map((opt, i) => {
                return (
                  <option key={`${i}_${opt}`} value={opt}>
                    {opt}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }
    } else {
      // STATUS
      if (userType == "OWNER") {
        return (
          //MMA-2227
          <CreatableSelect
            id={`status-${row.id}`}
            className="p-2 w-max"
            options={column.columnDef.optionsText.map((opt) => {
              return {
                value: opt,
                label: opt,
              };
            })}
            onChange={(e) => {
              saveStatusDesktop(e, row.original);
              //console.log("clicked")
            }}
            defaultValue={{
              label: row.original[7],
              value: row.original[7],
            }}
            placeholder="Select Option"
            noOptionsMessage={() => "No result found"}
            isValidNewOption={() => false}
          />
        );
      } else if (row.original.hasOwnProperty(9)) {
        let assignees = row.original[9].split(",");
        if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
          return (
            //MMA-2227
            <CreatableSelect
              id={`status-${row.id}`}
              className="p-2 w-max"
              options={column.columnDef.optionsText.map((opt) => {
                return {
                  value: opt,
                  label: opt,
                };
              })}
              onChange={(e) => {
                saveStatusDesktop(e, row.original);
              }}
              defaultValue={{
                label: row.original[7],
                value: row.original[7],
              }}
              placeholder="Select Option"
              noOptionsMessage={() => "No result found"}
              isValidNewOption={() => false}
            />
          );
        } else {
          return <span className="p-6">{row.original[7]}</span>;
        }
      } else {
        return (
          //MMA-2227
          <CreatableSelect
            id={`status-${row.id}`}
            className="p-2 w-max"
            options={[
              {
                label: "Completed",
                value: "Completed",
              },
              {
                label: "Outstanding",
                value: "Outstanding",
              },
            ]}
            defaultValue={{
              label: row.original[7],
              value: row.original[7],
            }}
            onChange={(e) => {
              saveStatusDesktop(e, row.original);
            }}
            placeholder="Select Status"
            noOptionsMessage={() => "No result found"}
            isValidNewOption={() => false}
          />
        );
      }
    }
  }

  if (column.columnDef.type === "CHECKBOX") {
    let checked = false;
    if (value) {
      checked = value;
    }
    if (checked) {
      return (
        <input
          onChange={(e) => setValue(e.target.checked)}
          type="checkbox"
          checked
          onClick={() => {
            table.options.meta.commitStagedEdits();
          }}
        />
      );
    }
    return (
      <input
        onChange={(e) => setValue(e.target.checked)}
        type="checkbox"
        onClick={() => {
          table.options.meta.commitStagedEdits();
        }}
      />
    );
  }

  if (column.columnDef.type === "PEOPLE") {
    if (column.columnDef.header == "Task Created By") {
      let createdBy =
        row?.original?.createdBy ||
        parsePeople(row?.original[column.columnDef.accessorKey]);

      return (
        <div className="flex" id={`createdBy-${row.id}`}>
          <div
            className="flex bg-gray-500 text-white w-10 h-10 rounded-full text-md font-medium justify-center items-center"
            data-tip={
              createdBy?.firstName && createdBy?.lastName
                ? createdBy.firstName + " " + createdBy.lastName
                : "None"
            }
          >
            {!createdBy?.profilePicture ? (
              <>
                {(
                  (createdBy?.firstName ? createdBy.firstName.charAt(0) : "") +
                  (createdBy?.lastName ? createdBy.lastName.charAt(0) : "")
                ).toUpperCase()}
              </>
            ) : (
              <img
                className="rounded-full w-10 h-10"
                src={createdBy.profilePicture}
              />
            )}
          </div>
          <ReactTooltip globalEventOff="scroll" scrollHide />
        </div>
      );
    }

    //console.log(column.columnDef.type + "is triggered. value is:", value);
    /* Checking if value is truthy, if it is, it splits the string into an array.
    If it is not, it returns an empty array. */

    let x = value ? value.split(",") : [];

    /* Filtering out undefined values from an array. */
    const y = x.filter((element) => {
      return element !== undefined && element !== "undefined";
    });

    /* Trimming the whitespace from each element in the array. */
    const data = y.map((x) => x.trim());

    //console.log(data);
    let editIcon = <></>;

    if (userType == "OWNER") {
      editIcon = (
        <BiPencil
          key={row.id + "-" + column.id}
          className="text-green-700 inline-block p-2 cursor-pointer hover:bg-black hover:bg-opacity-5 w-10 h-10 rounded-full"
          onClick={() => {
            setEditablePeople(true);
            ReactTooltip.hide();
          }}
        />
      );
    } else if (row.original.hasOwnProperty(9)) {
      let assignees = row.original[9].split(",");
      if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
        editIcon = (
          <BiPencil
            key={row.id + "-" + column.id}
            className="text-green-700 inline-block p-2 cursor-pointer hover:bg-black hover:bg-opacity-5 w-10 h-10 rounded-full"
            onClick={() => {
              setEditablePeople(true);
              ReactTooltip.hide();
            }}
          />
        );
      }
    } else {
      editIcon = (
        <BiPencil
          key={row.id + "-" + column.id}
          className="text-green-700 inline-block p-2 cursor-pointer hover:bg-black hover:bg-opacity-5 w-10 h-10 rounded-full"
          onClick={() => {
            setEditablePeople(true);
            ReactTooltip.hide();
          }}
        />
      );
    }

    if (data && !editablePeople) {
      return (
        <div
          className="flex justify-end flex-wrap w-24"
          id={`assigneeDiv-${row.id}`}
        >
          {data.map((x, index) => {
            const assignee = parsePeople(x);

            var assigneeInitials = "";
            if (x.toLowerCase() === "dane mariel sta. ana") {
              assigneeInitials = "DM";
            } else if (x.toLowerCase() === "john dela cruz") {
              assigneeInitials = "JDC";
            } else if (typeof assignee === "string") {
              return;
            } else {
              assigneeInitials += assignee.firstName
                ? assignee.firstName?.charAt(0)
                : "";
              assigneeInitials += assignee.lastName
                ? assignee.lastName?.charAt(0)
                : "";
            }

            return (
              assignee && (
                <div className="flex" key={index}>
                  <div
                    className="flex bg-gray-500 text-white w-10 h-10 rounded-full text-md font-medium justify-center items-center"
                    data-tip={assignee.firstName + " " + assignee.lastName}
                  >
                    {assignee.profilePicture === null ? (
                      <>{assigneeInitials.toUpperCase()}</>
                    ) : (
                      <img
                        className="rounded-full w-10 h-10"
                        src={assignee.profilePicture}
                      />
                    )}
                  </div>
                  <ReactTooltip globalEventOff="scroll" scrollHide />
                  {/* <div
                    className="inline-block p-2 border-2 bg-gray-200 mx-0.5 rounded-full w-10 h-10 items-center uppercase justify-center text-center font-semibold cursor-pointer"
                    data-tip={assignee.firstName + " " + assignee.lastName}
                  >
                    {assigneeInitials.toUpperCase()}
                  </div> */}
                </div>
              )
            );
          })}
          {editIcon}
        </div>
      );
    }

    let arrPeople = value?.split(",").map((p) => parsePeople(p));
    let filteredArrPeople = arrPeople?.filter((e) => e !== "") ?? [];

    return (
      <CreatableSelect
        isMulti
        // autoFocus
        options={
          people &&
          people.map((p) => {
            let item = `${p.firstName} ${p.lastName}`;
            return {
              value: p.id,
              label: item,
            };
          })
        }
        className="w-64"
        defaultValue={
          filteredArrPeople &&
          filteredArrPeople.map((p) => {
            return typeof p === "string"
              ? {
                  value: p,
                  label: p,
                }
              : {
                  value: p.id,
                  label: `${p.firstName} ${p.lastName}`,
                };
          })
        }
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
        }}
        onBlur={() => {
          setEditablePeople(false);
          table.options.meta.updateData(
            row,
            column,
            selectedPeople.map((x) => x.value).join(",")
          );
          console.log("edited")
        }}
        onChange={(e) => {
         // setValue(e.map((x) => x.value).join(","));
          setSelectedPeople(e);
        }}
        placeholder="Select People"
      />

      // <select
      //   defaultValue={initialValue}
      //   onChange={(e) => {
      //     table.options.meta.updateData(row, column, e.target.value);
      //     setEditablePeople(false);
      //     setShowEditPeople(false);
      //   }}
      //   className="p-2 rounded-lg border bg-transparent"
      // >
      //   <option value="">Select</option>
      //   {people.map((p, i) => {
      //     let person = `${p.firstName} ${p.lastName}`;
      //     return (
      //       <option key={person + i} value={person}>
      //         {person}
      //       </option>
      //     );
      //   })}
      // </select>
    );
  }

  if (column.columnDef.type === "DATE") {
    let hasDate = false;
    if (value) {
      hasDate = true;
    }

    if (userType == "OWNER") {
      if (hasDate) {
        return (
          <div
            id={
              column.columnDef.header === "Due Date"
                ? `dueDate-${row.id}`
                : null
            }
          >
            <DatePicker
              className="w-auto"
              selected={timezoneFormatter(
                value,
                localStorage.getItem("preferredTimezone")
              )}
              dateFormat={datePickerDateFormatter(
                localStorage.getItem("userpreferredDateFormatType")
              )}
              //onChange={(date) => setValue(date)}
              onChange={(date) => {
                table.options.meta.updateData(row, column, date);
              }}
            />
          </div>
        );
      }
      return (
        <div
          id={
            column.columnDef.header === "Due Date" ? `dueDate-${row.id}` : null
          }
        >
          <DatePicker
            className="w-auto"
            placeholderText="Select Date"
            dateFormat={datePickerDateFormatter(
              localStorage.getItem("userpreferredDateFormatType")
            )}
            //onChange={(date) => setValue(date)}
            onChange={(date) => {
              table.options.meta.updateData(row, column, date);
            }}
          />
        </div>
      );
    } else if (row.original.hasOwnProperty(9)) {
      let assignees = row.original[9].split(",");
      if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
        if (hasDate) {
          return (
            <div
              id={
                column.columnDef.header === "Due Date"
                  ? `dueDate-${row.id}`
                  : null
              }
            >
              <DatePicker
                className="w-auto"
                selected={timezoneFormatter(
                  value,
                  localStorage.getItem("preferredTimezone")
                )}
                dateFormat={datePickerDateFormatter(
                  localStorage.getItem("userpreferredDateFormatType")
                )}
                //onChange={(date) => setValue(date)}
                onChange={(date) => {
                  table.options.meta.updateData(row, column, date);
                }}
              />
            </div>
          );
        }
        return (
          <div
            id={
              column.columnDef.header === "Due Date"
                ? `dueDate-${row.id}`
                : null
            }
          >
            <DatePicker
              className="w-auto"
              placeholderText="Select Date"
              dateFormat={datePickerDateFormatter(
                localStorage.getItem("userpreferredDateFormatType")
              )}
              //onChange={(date) => setValue(date)}
              onChange={(date) => {
                table.options.meta.updateData(row, column, date);
              }}
            />
          </div>
        );
      } else {
        if (hasDate) {
          return (
            <div
              id={
                column.columnDef.header === "Due Date"
                  ? `dueDate-${row.id}`
                  : null
              }
            >
              <DatePicker
                className="w-auto"
                placeholderText="Select Date"
                dateFormat={datePickerDateFormatter(
                  localStorage.getItem("userpreferredDateFormatType")
                )}
                selected={timezoneFormatter(
                  value,
                  localStorage.getItem("preferredTimezone")
                )}
                readOnly={true}
                disabled={true}
                //onChange={(date) => setValue(date)}
                onChange={(date) => {
                  table.options.meta.updateData(row, column, date);
                }}
              />
            </div>
          );
        } else {
          return <span>N/A</span>;
        }
      }
    } else {
      if (hasDate) {
        return (
          <div
            id={
              column.columnDef.header === "Due Date"
                ? `dueDate-${row.id}`
                : null
            }
          >
            <DatePicker
              id={
                column.columnDef.header === "Due Date"
                  ? `dueDate-${row.id}`
                  : null
              }
              className="w-auto"
              selected={timezoneFormatter(
                value,
                localStorage.getItem("preferredTimezone")
              )}
              dateFormat={datePickerDateFormatter(
                localStorage.getItem("userpreferredDateFormatType")
              )}
              //onChange={(date) => setValue(date)}
              onChange={(date) => {
                table.options.meta.updateData(row, column, date);
              }}
            />
          </div>
        );
      }
      return (
        <div
          id={
            column.columnDef.header === "Due Date" ? `dueDate-${row.id}` : null
          }
        >
          <DatePicker
            id={
              column.columnDef.header === "Due Date"
                ? `dueDate-${row.id}`
                : null
            }
            className="w-auto"
            placeholderText="Select Date"
            dateFormat={datePickerDateFormatter(
              localStorage.getItem("userpreferredDateFormatType")
            )}
            //onChange={(date) => setValue(date)}
            onChange={(date) => {
              table.options.meta.updateData(row, column, date);
            }}
          />
        </div>
      );
    }
  }

  if (column.columnDef.type === "TEXTAREA") {
    if (userType == "OWNER") {
      return (
        <textarea
          id={`description-${row.id}`}
          rows={4}
          className={`border-none p-2 resize-none`}
          defaultValue={convertHTMLEntity(value ?? "")}
          cols={`${column.columnDef.header === "Description" ? 70 : 50}`}
          suppressContentEditableWarning={true}
          contentEditable={true}
          placeholder="N/A"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            table.options.meta.commitStagedEdits();
          }}
        />
      );
    } else if (row.original.hasOwnProperty(9)) {
      let assignees = row.original[9].split(",");
      if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
        return (
          <textarea
            id={`description-${row.id}`}
            rows={4}
            className={`border-none p-2 resize-none`}
            defaultValue={convertHTMLEntity(value ?? "")}
            cols={`${column.columnDef.header === "Description" ? 70 : 50}`}
            suppressContentEditableWarning={true}
            contentEditable={true}
            placeholder="N/A"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              table.options.meta.commitStagedEdits();
            }}
          />
        );
      } else {
        return (
          <textarea
            id={`description-${row.id}`}
            rows={4}
            className={`border-none p-2 resize-none`}
            defaultValue={convertHTMLEntity(value ?? "")}
            cols={`${column.columnDef.header === "Description" ? 70 : 50}`}
            suppressContentEditableWarning={true}
            contentEditable={true}
            placeholder="N/A"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              table.options.meta.commitStagedEdits();
            }}
            disabled
          />
        );
      }
    } else {
      return (
        <textarea
          id={`description-${row.id}`}
          rows={4}
          className={`border-none p-2 resize-none`}
          defaultValue={convertHTMLEntity(value ?? "")}
          cols={`${column.columnDef.header === "Description" ? 70 : 50}`}
          suppressContentEditableWarning={true}
          contentEditable={true}
          placeholder="N/A"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => {
            table.options.meta.commitStagedEdits();
          }}
        />
      );
    }
  }

  if (column.columnDef.type === "TEXT") {
    return (
      <input
        value={value}
        placeholder="N/A"
        className="p-2"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          table.options.meta.commitStagedEdits();
        }}
      />
    );
  }

  if (column.columnDef.type === "READONLY") {
    // Instance of Read-Only Dates
    if (
      column.columnDef.header === "Date Received" ||
      column.columnDef.header === "Date Submitted" ||
      column.columnDef.header === "Date Completed" ||
      column.columnDef.header === "Day and Time to Complete" ||
      column.columnDef.header === "Outstanding Task Aging"
    ) {
      let dateValue = value;

      if (
        column.columnDef.header === "Date Completed" &&
        dateValue === undefined &&
        row.original["7"] === "Completed"
      ) {
        dateValue = row.original.updatedAt;
      }

      if (column.columnDef.header === "Date Received") {
        return (
          <p id={`dateReceived-${row.id}`}>
            {dateValue ? dateTimeFormatter(dateValue) : dateValue}
          </p>
        );
      }

      if (column.columnDef.header === "Date Submitted") {
        return (
          <p id={`dateSubmitted-${row.id}`}>
            {dateValue ? dateTimeFormatter(dateValue) : dateValue}
          </p>
        );
      }

      if (
        column.columnDef.header === "Day and Time to Complete" &&
        row.original["7"] === "Completed"
      ) {
        const createdAt = row.original.createdAt;
        const completedAt = row.original.dateCompleted
          ? row.original.dateCompleted
          : row.original.updatedAt;

        var dayTimeToComplete = getDayTimeDifference(createdAt, completedAt);

        return (
          <p className="text-center" id={`dateComplete-${row.id}`}>
            {dayTimeToComplete}
          </p>
        );
      }

      if (
        column.columnDef.header === "Outstanding Task Aging" &&
        row.original["7"] === "Outstanding"
      ) {
        const createdAt = row.original.createdAt;
        const currentDate = new Date();

        var taskAge = getDayTimeDifference(createdAt, currentDate);

        return (
          <p className="text-center" id={`taskAge-${row.id}`}>
            {taskAge}
          </p>
        );
      }

      if (dateValue && new Date(dateValue) instanceof Date) {
        let formattedDateValue = dateTimeFormatter(dateValue);

        if (
          column.columnDef.header === "Date Completed" &&
          row.original["7"] === "Completed" &&
          row.original.completedBy
        ) {
          const firstName = row.original.completedBy.split(" ")[0];
          formattedDateValue += `<br/><sub><i> by ${firstName}</i></sub>`;
        }

        return <div dangerouslySetInnerHTML={{ __html: formattedDateValue }} />;
      } else {
        return null;
      }
    } else if (column.columnDef.header === "Preview Email") {
      /*var arr_attachments = "";
		const params = {
		  query: EMAIL_ATTTACHMENT,
		  variables: {
			id: row.original.gmailMessageId,
		  },
		};

		API.graphql(params).then((result) => {
		  arr_attachments = result.data.gmailMessage?.attachments?.items?.map(({ id, name }) => ({
			id: id,
			name: name
		  }));
		  //arr_attachments.push(_attachments);
		  console.log(arr_attachments);
		});
		*/

      return (
        <div id={`preview-${row.id}`}>
          {/* {items.s3ObjectKey === null && items.isGmailPDF && items.isGmailAttachment ? */}

          {row.original.gmailMessageId !== null &&
          row.original.gmailMessageId !== "0" &&
          value ? (
            <>
              <span
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer"
                onClick={(e) => {
                  handleDownloadLink(
                    value,
                    row.original.gmailMessageId,
                    row.original[1]
                  );
                }}
              >
                Email PDF
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    } else if (column.columnDef.header === "Attachments") {
      return (
        <div id={`attach-${row.id}`}>
          {row.original.gmailMessageId !== null &&
          row.original.gmailMessageId !== "0" ? (
            <>
              <Menu
                as="div"
                className="relative inline-block text-left"
                onFocus={(e) => {
                  getAttachments(row.original.gmailMessageId);
                }}
              >
                <div>
                  <Menu.Button className="whitespace-nowrap inline-flex w-full justify-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 p-1">
                    Show Attachments
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 origin-top-right w-96 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {attachments.length !== 0 ? (
                        attachments?.map((item) => (
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer border-2 border-gray-100 border-b-gray-800 hover:text-blue-400"
                                )}
                                onClick={(e) => {
                                  handleDownloadAttachment(item.id);
                                }}
                              >
                                {item.name > 10
                                  ? item.name.substring(0, 10)
                                  : item.name}
                              </span>
                            )}
                          </Menu.Item>
                        ))
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}
                            >
                              No Attachments for this Task
                            </span>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </>
          ) : (
            // MMA-1948 Tasklist: Alignment issues due to attachments.
            // When there is no attachments for the task we create a div which is a container with a fix size
            // this will solve the uneven alignment when there are attachments
            <>
              <div className="w-36 h-7 flex justify-center items-center text-sm text-gray-700 rounded-md bg-white">
                N/A
              </div>
            </>
          )}
        </div>
      );
    } else if (column.columnDef.header === "Email Subject") {
      const handleSnippetEmail = async (gmailMessageId) => {
        setShow(true);
        setshowEmailPreview(true);
        setSnippetId(gmailMessageId);
        console.log("gmailMessageId", gmailMessageId);

        const params = {
          query: EMAIL_PREVIEW,
          variables: {
            id: gmailMessageId,
          },
        };

        await API.graphql(params).then((result) => {
          console.log("clear filter result", result);
          console.log(result?.data?.gmailMessage);
          let newPayload = { payload: [{ content: "" }] };

          result?.data?.gmailMessage?.payload?.forEach((data, index) => {
            newPayload = {
              payload: [
                {
                  content:
                    newPayload?.payload[0]?.content +
                    result?.data?.gmailMessage?.payload[index]?.content,
                },
              ],
            };
          });

          let parsedEmail = null;
          try {
            // let emailContent = "vgij5xyxrl";
            let emailContent = JSON.parse(newPayload?.payload[0]?.content);

            console.log("email content is:", emailContent);
            parsedEmail = EmailParser(emailContent);

            setPreview(parsedEmail.html);
            setFrom(result.data.gmailMessage.from);
            setDateEmail(result.data.gmailMessage.date);
            setSubject(result.data.gmailMessage.subject);
            setTo(result.data.gmailMessage.to);
            setCC(result.data.gmailMessage.cc);

            //MMA-1277 - Email Preview Modal
            setemailPreviewState({
              preview: parsedEmail.html,
              from: result.data.gmailMessage.from,
              dateEmail: result.data.gmailMessage.date,
              subject: result.data.gmailMessage.subject,
              to: result.data.gmailMessage.to,
              cc: result.data.gmailMessage.cc,
            });
          } catch (e) {
            console.log("ERROR IS:", e);
            toast.error(`Email Cannot Be Accessed, Please Refresh Page`);
            setShow(false);
            setshowEmailPreview(false);
            setSnippetId(null);
          }
        });
      };

      const handleRootClose = () => {
        setShow(false);
        setshowEmailPreview(false);
        setSnippetId(null);

        setemailPreviewState({
          preview: [],
          from: null,
          dateEmail: null,
          subject: null,
          to: null,
          cc: null,
        });
      };

      return (
        <div className="w-36" id={`emailDiv-${row.id}`}>
          <span id={`email-${row.id}`}>{value}</span>
          <br />
          {row.original.gmailMessageId !== null &&
          row.original.gmailMessageId !== "0" ? (
            <>
              {show ? (
                <button
                  className="p-0 text-red-500 text-opacity-90 text-[12px] font-normal inline-flex items-center gap-x-2 rounded primary_light hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs"
                  type="button"
                  aria-expanded="false"
                  onClick={handleRootClose}
                >
                  close email
                  <MdClose size={11} />
                </button>
              ) : (
                <button
                  className="text-blue-600 underline text-opacity-90 text-[12px] font-normal inline-flex items-center gap-x-2 rounded primary_light hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs"
                  onClick={(e) => {
                    handleSnippetEmail(row.original.gmailMessageId);
                  }}
                  id="eye-icon"
                >
                  read more
                  <FaEye size={11} />
                </button>
              )}

              {/* {show && snippetId === row.original.gmailMessageId && (
                <div
                  className="absolute rounded shadow-2xl bg-white p-6 z-50 w-2/5 max-w-4xl overflow-auto"
                  style={{
                    maxHeight: "450px",
                    minHeight: "450px",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {from ? (
                    <>
                      <p>{`From: ${from}`}</p>
                      <p>
                        {`Date: ${moment(dateEmail).format(
                          "DD MMM YYYY, hh:mm A"
                        )}`}
                      </p>
                      <p>{`Subject: ${subject}`}</p>
                      <p>{`To: ${to}`}</p>
                      {cc && <p>{`CC: ${cc}`}</p>}

                      <p
                        className="mt-8 p-2"
                        dangerouslySetInnerHTML={{
                          __html: Base64.decode(
                            preview
                              ?.map((em) => em.content)
                              .join("")
                              .split('data":"')
                              .pop()
                              .split('"}')[0]
                          ).replace("body{color:", ""),
                        }}
                      ></p>
                    </>
                  ) : (
                    <>
                      <SkeletonTheme width={"40%"}>
                        <p>
                          <Skeleton count={4} />
                        </p>
                      </SkeletonTheme>
                      <p className="mt-8 p-2">
                        <Skeleton count={15} />
                      </p>
                    </>
                  )}
                </div>
              )} */}
            </>
          ) : (
            <></>
          )}
        </div>
      );
    } else {
      return <>{value}</>;
    }
  }
  return null;
};

export const DataTable = ({
  toggleSidebar,
  data: initialData,
  setData: setInitialData,
  setAllCount,
  setOutstandingCount,
  setCompletedCount,
  allCount,
  outstandingCount,
  completedCount,
  columns,
  people,
  clientMatters,
  fetchTaskListData,
  fetchCompanyClientMatters, //MMA1060
  fetchOutsandingTasks,
  fetchCompletedTasks,
  fetchDataWithFilterBy,
  activeTabIndex,
  loading,
  setLoading,
  setshowEmailPreview,
  setemailPreviewState,
  taskRegisters,
  setTaskRegisters,
  preset,
  lastUpdatedPriorityValue,
  updatePriorityValue,
  selectedRows,
  setSelectedRows,
  selectedDivs,
  setSelectedDivs,
  setTableHeader,
  archivedCount,
  setArchivedCount,
  setaAllTasks,
  setOutstandingTasks,
  setCompletedTasks,
  setArchivedTasks,
  allTasks,
  outstandingTasks,
  completedTasks,
  archivedTasks,
  isFetchingNextPage,
  handleFetchNextData,
  tableContainerRef,
}) => {
  const userType = localStorage.getItem("userType");
  const [data, setData] = useState([]);
  const stagedEdits = useRef(null);
  const [ShowModal, setShowModal] = useState(false); // MMA1060 Change
  const [ValueCopy, setValueCopy] = useState();
  const [LabelCopy, setLabelCopy] = useState("");
  const [RowTarget, setRowTarget] = useState("");
  const [sorting, setSorting] = useState([]);
  //mobile const
  const { height, width } = useWindowDimensions();
  const [contentHeight, setContentHeight] = useState();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [descValue, setDescValue] = useState("");

  const [emailPreview, setEmailPreview] = useState("");
  const [ShowModalEmail, setShowModalEmail] = useState(false);
  const [showAssignee, setShowAssignee] = useState(false);
  const [assigneeId, setAssigneeId] = useState("");

  const [showDescription, setShowDescription] = useState(false);
  const [descriptionId, setDescriptionId] = useState("");

  const [showClientMatter, setShowClientMatter] = useState(false);
  const [clientmatterId, setClientmatterId] = useState("");

  const [showStatus, setShowStatus] = useState(false);
  const [statusId, setStatusId] = useState("");

  const [showPriority, setShowPriority] = useState(false);
  const [priorityId, setPriorityId] = useState("");

  const [showTaskRegisters, setShowTaskRegisters] = useState(false);
  const [taskRegistersId, setTaskRegistersId] = useState("");

  const [taskRegistersOptions, setTaskRegistersOptions] = useState([]);
  const [columnsMobile, setColumnsMobile] = useColumnContext();
  const tableId = localStorage.getItem("task-list-table-id");
  const [presetsLibrary, setPresetsLibrary] = useState([]);

  //mobile copy paste const
  const [showCopy, setShowCopy] = useState(true);
  const [copyId, setCopyId] = useState("");
  const [copyDesc, setCopyDesc] = useState("");
  const [copyAssignee, setCopyAssignee] = useState("");

  const [pastedIds, setPastedIds] = useState([]);
  const [oldDetails, setOldDetails] = useState([]);

  const [snippetId, setSnippetId] = useState(null);
  const [show, setShow] = useState(false);

  const [preview, setPreview] = useState([]);
  const [from, setFrom] = useState(null);
  const [dateEmail, setDateEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCC] = useState(null);
  const [id, setId] = useState(null);
  const [prioLevel, setPrioLevel] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [currUrl, setCurrUrl] = useState(window.location.hostname);

  const getUserById = async (userId) => {
    const response = await API.graphql({
      query: mgetUserById,
      variables: {
        id: userId,
      },
    }).then((response) => {
      localStorage.setItem(
        "preferredTimezone",
        response.data.user.preferredTimezone == "null" ||
          response.data.user.preferredTimezone == null
          ? moment.tz.guess()
          : response.data.user.preferredTimezone
      );

      localStorage.setItem(
        "userpreferredDateFormatType",
        response.data.user.preferredDateFormat == "null" ||
          response.data.user.preferredDateFormat == null
          ? "MM-DD-YYYY"
          : response.data.user.preferredDateFormat
      );
    });
  };

  useEffect(() => {
    setTaskRegistersOptions(
      taskRegisters.map((item) => ({ label: item.label, value: item.id }))
    );

    getUserById(localStorage.getItem("userId"));
  }, []);

  const handleSnippetEmail = async (id, gmailMessageId) => {
    setShow(true);
    setSnippetId(gmailMessageId);
    setId(id);

    const params = {
      query: EMAIL_PREVIEW,
      variables: {
        id: gmailMessageId,
      },
    };

    await API.graphql(params).then((result) => {
      console.log(result.data.gmailMessage);

      let newPayload = { payload: [{ content: "" }] };
      result?.data?.gmailMessage?.payload?.forEach((data, index) => {
        newPayload = {
          payload: [
            {
              content:
                newPayload.payload[0].content +
                result?.data?.gmailMessage?.payload[index]?.content,
            },
          ],
        };
      });
      const parsedEmail = EmailParser(
        JSON.parse(newPayload.payload[0].content)
      );

      setPreview(parsedEmail.html);
      setFrom(result.data.gmailMessage.from);
      setDateEmail(result.data.gmailMessage.date);
      setSubject(result.data.gmailMessage.subject);
      setTo(result.data.gmailMessage.to);
      setCC(result.data.gmailMessage.cc);
    });
  };

  const handleClose = () => {
    setId(null);
    setShow(false);
    setPreview([]);
    setFrom(null);
    setDateEmail(null);
    setSubject(null);
    setTo(null);
    setCC(null);
  };

  function isCurrentUserAssigned(assignees) {
    for (let user of assignees) {
      if (userId === user) {
        return true;
      }

      if (userName === user) {
        return true;
      }

      if (userHistory) {
        for (let pastUser of userHistory) {
          if (`${pastUser.firstName} ${pastUser.lastName}` === user) {
            return true;
          }
        }
      }
    }
    return false;
  }
  const getEmailPayload = async (messageId) => {
    console.log("getEmailPayload()");
    const params = {
      query: MESSAGE_PAYLOAD_QUERY,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);
    if (result) {
      let newPayload = { payload: [{ content: "" }] };
      console.log("getEmailPayload()", result);

      result?.data?.gmailMessage.payload?.forEach((data, index) => {
        newPayload = {
          payload: [
            {
              content:
                newPayload.payload[0].content +
                result?.data?.gmailMessage?.payload[index]?.content,
            },
          ],
        };
      });
      return newPayload;
    }

    // return result?.data?.gmailMessage;
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const handleDownloadLink = async (id, gmailMessageId, emailSubject) => {
    console.group("handleDownloadLink()");
    console.log("handleDonwloadLink()", id, gmailMessageId, emailSubject);

    var subjectTitle = emailSubject
      .replace(/[^a-z0-9]/gi, " ")
      .replace(/\s+/g, " ");
    var content = ``;

    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: config.aws_user_gmail_attachments_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    const email_params = {
      query: EMAIL_PREVIEW,
      variables: {
        id: gmailMessageId,
      },
    };

    await API.graphql(email_params).then((result) => {
      console.log("EMAIL_PREVIEW", result.data.gmailMessage);

      content += `<span>`;
      content += `<img src=${googleLogin} alt="" /><hr></hr>`;
      content += `<h2><b>${subjectTitle}</b></h2><hr></hr><br />`;
      content += `<p>From: ${result.data.gmailMessage.from}</p>`;
      content += `<p>Date: ${moment(result.data.gmailMessage.date).format(
        "DD MMM YYYY, hh:mm A"
      )}</p>`;
      content += `<p>To: ${result.data.gmailMessage.to}</p>`;
      content += result.data.gmailMessage.cc
        ? `<p>CC: ${result.data.gmailMessage.cc}</p>`
        : "";
      content += `</span>`;
    });

    const params = {
      query: EMAIL_DOWNLOAD,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { downloadURL, isGmailPDF, isGmailAttachment, s3ObjectKey } =
        result.data.file;

      if (isGmailAttachment && isGmailPDF && s3ObjectKey === null) {
        var opt = {
          margin: [30, 50, 30, 50],
          filename: subjectTitle,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 96,
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            backgroundColor: "#FFF",
          },
          jsPDF: { unit: "pt", format: "a4", orientation: "p" },
          pagebreak: {
            before: ".page-break",
            mode: ["avoid-all", "css", "legacy"],
          },
        };

        console.log("content:", content);

        const emailPayload = await getEmailPayload(gmailMessageId);

        if (emailPayload) {
          const parsedEmail = EmailParser(
            JSON.parse(emailPayload.payload[0].content)
          );
          const htmlContent = parsedEmail.html;

          // const htmlContent = emailPayload.payload
          //   .map((em) => em.content)
          //   .join("")
          //   .split('data":"')
          //   .pop()
          //   .split('"}')[0];

          content += htmlContent;
          // content += Base64.decode(htmlContent).replace("body{color:", "");

          await html2pdf()
            .from(content)
            .set(opt)
            .toPdf()
            .output("datauristring")
            .then(function (pdfAsString) {
              const preBlob = dataURItoBlob(pdfAsString);
              const file = new File([preBlob], subjectTitle, {
                type: "application/pdf",
              });
              console.log(file);

              var key = `${gmailMessageId}/${Number(new Date())}${file.name
                  .replaceAll(/\s/g, "")
                  .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
                type = "application/pdf",
                size = file.size;

              // put objects to s3
              try {
                Storage.put(key, file, {
                  contentType: type,
                  progressCallback(progress) {
                    console.log(progress);
                  },
                  errorCallback: (err) => {
                    console.error("204: Unexpected error while uploading", err);
                  },
                })
                  .then((fd) => {
                    const params = {
                      query: FILE_OBJECT_KEY_UPDATE,
                      variables: {
                        id: id,
                        s3ObjectKey: fd.key,
                        size: parseInt(size),
                        type: type,
                      },
                    };

                    API.graphql(params).then(async (res) => {
                      console.log("Get Details", res);
                      const params = {
                        query: EMAIL_DOWNLOAD,
                        variables: {
                          id: id,
                        },
                      };
                      await API.graphql(params).then(async (result) => {
                        const { downloadURL } = result.data.file;
                        window.open(downloadURL);
                      });
                    });
                  })
                  .catch((err) => {
                    console.error("Unexpected error while uploading", err);
                  });
              } catch (e) {
                const response = {
                  error: e.message,
                  errorStack: e.stack,
                  statusCode: 500,
                };
                console.error("Unexpected error while uploading", response);
              }
            });
        }
      } else {
        // PDF is already generated
        window.open(downloadURL);
      }

      console.groupEnd();
    });
  };

  const handleDownloadAttachment = async (id) => {
    const params = {
      query: ATTACHMENT_DOWNLOAD,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then((result) => {
      window.open(result.data.gmailAttachment.downloadURL);
    });
  };

  async function getAttachments(id) {
    const params = {
      query: EMAIL_ATTTACHMENT,
      variables: {
        id: id,
      },
    };

    var response = await API.graphql(params);
    var result = response.data.gmailMessage?.attachments?.items;
    var filteredResult = result.filter((u) => u.isDeleted !== true);
    setAttachments(filteredResult);
  }

  const columnVisibility = columns
    .map((column) => ({
      [column.accessorKey]: column.enabled,
    }))
    .reduce(function (result, item) {
      let key = Object.keys(item);
      result[key] = item[key];
      return result;
    }, {});

  // useEffect(() => {
  //     console.log("defaultColumn", defaultColumn);
  // });

  const updateData = async (row, column, value) => {
    console.group("updateData()");
    setData((old) =>
      old.map((row, index) => {
        if (index === row.index) {
          return {
            ...old[row.index],
            [column.id]: value,
          };
        }
        return row;
      })
    );
    // Prepare new row
    let newRow = row.original;
    newRow[column.id] = value;

    console.log("newRow", newRow)
    console.log("column.id", column.id)
    console.log("value", value)

    /** Date Completed */
    let dateCompleted = null,
      completedBy = null;

    if (column.id === "7") {
      if (value === "Completed") {
        console.log("Task is Completed");
        dateCompleted = new Date();
        completedBy = userId;
      }
      newRow["dateCompleted"] = dateCompleted;
      newRow[12] = dateCompleted;
      newRow["completedBy"] = completedBy;
    }

    newRow["updatedAt"] = new Date();

    // API Call
    await API.graphql({
      query: TASK_LIST_DATA_UPDATE,
      variables: {
        id: row.original.id,
        input: {
          data: JSON.stringify(newRow),
        },
      },
    }).then((res) => console.log("DATA UPDATE RESULT", res, column, value));
    console.groupEnd("updateData()");
  };

  const defaultColumn = {
    cell: (props) => {
      return (
        <EditableCell
          {...props}
          people={people}
          clientMatters={clientMatters}
          setShowModal={setShowModal} // MMA1060 Change
          ValueCopy={ValueCopy}
          LabelCopy={LabelCopy}
          setRowTarget={setRowTarget}
          RowTarget={RowTarget}
          setValueCopy={setValueCopy}
          updateData={updateData}
          setPrioLevel={setPrioLevel}
          parsePeople={parsePeople}
          isCurrentUserAssigned={isCurrentUserAssigned}
          setshowEmailPreview={setshowEmailPreview}
          setemailPreviewState={setemailPreviewState}
          taskRegisters={taskRegisters}
          setTaskRegisters={setTaskRegisters}
          lastUpdatedPriorityValue={lastUpdatedPriorityValue}
          updatePriorityValue={updatePriorityValue}
          fetchTaskListData={fetchTaskListData}
          fetchCompletedTasks={fetchCompletedTasks}
          fetchOutsandingTasks={fetchOutsandingTasks}
          fetchDataWithFilterBy={fetchDataWithFilterBy}
          allTasks={allTasks}
          setaAllTasks={setaAllTasks}
          outstandingTasks={outstandingTasks}
          setOutstandingTasks={setOutstandingTasks}
          completedTasks={completedTasks}
          setCompletedTasks={setCompletedTasks}
          setCompletedCount={setCompletedCount}
          setOutstandingCount={setOutstandingCount}
          taskRegisterChoices={taskRegistersOptions ? taskRegistersOptions : []}
          presetsChoices={presetsLibrary ? presetsLibrary : []}
        />
      );
    },
  };

  const handleDuplicate = async (row, table) => {
    await API.graphql({
      query: TASKLIST_DATA_COUNT,
      variables: {
        companyId,
      },
    }).then(async function (tl) {
      const tlDataCount = tl?.data?.taskListDataCount?.find(
        (obj) => obj.status === "total"
      );

      const nextUniqueId = tlDataCount?.count
        ? parseInt(tlDataCount?.count, 10) + 1
        : null;

      let columns = table.getAllFlatColumns();
      let createdBy = columns.find(
        (col) => col.columnDef.header === "Task Created By"
      );

      let dynamicData = Object.fromEntries(
        Object.entries(row.original).filter(([key]) => !isNaN(key))
      );
      let newDynamicData = {
        ...dynamicData,
        3: "",
        4: dynamicData[4] ? dynamicData[4] : "",
        7: dynamicData[7] ? dynamicData[7] : "Outstanding",
        8: dynamicData[8] ? dynamicData[8] : 1,
        10: new Date(),
        11: new Date(),
        registers: row.original?.registers ? row.original.registers : [],
        ...(createdBy?.columnDef?.accessorKey
          ? {
              [createdBy.columnDef.accessorKey]: localStorage.getItem("userId"),
            }
          : {}),
      };
      await API.graphql({
        query: TASK_LIST_DATA_CREATE,
        variables: {
          companyId,
          input: {
            isDeleted: false,
            dueDate: new Date(),
            priority: row.original[8] ? row.original[8] : 1,
            uniqueId: nextUniqueId,
            clientMatterId: row.original.clientMatterId,
            gmailMessageId: row.original.gmailMessageId,
            data: JSON.stringify(newDynamicData),
          },
        },
      }).then(async function (result) {
        const variables = {
          companyId: companyId,
          briefId: null,
          clientmatterId: row.original.clientMatterId,
          rowId: result.data.taskListDataCreate.id,
          activity: `Duplicate Task`,
          field: "Duplicate",
          current: null,
          previous: null,
          appModule: "TASKS",
          userId: localStorage.getItem("userId"),
        };

        const params = {
          query: ACTIVITY_CREATE,
          variables,
        };
        console.log("variables -> ", variables);
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result, "DUPLICATE TASK");
        });

        toast.success(`Sucessfully Duplicated Task.`);
        fetchDataWithFilterBy("filters");
      });
    });
  };

  const PriorityClassNames = (prioLevels) => {
    return classNames({
      "border-l-4 h-full": true,
      "border-danger": prioLevels === 1 || prioLevels === "1",
      "border-warning": prioLevels === 3 || prioLevels === "3",
      "border-gray-500": prioLevels === 4 || prioLevels === "4",
      "border-gray-300": prioLevels === 5 || prioLevels === "5",
      "border-orange-400": prioLevels === 2 || prioLevels === "2", //does not work when put on 2nd slot
    });
  };

  const table = useReactTable({
    data: !loading ? data : [...Array(5).keys()],
    defaultColumn,
    enableRowSelection: true,
    columns: !loading
      ? [
          // Condition for loading states - Skeleton Loader

          {
            //MMA-2351: Add a checkbox on each row in the Task List
            id: "check_box",
            header: ({ table }) => (
              <input
                type="checkbox"
                checked={table.getIsAllRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
                onClick={() => {
                  handleSelectAll(table);
                }}
              />
            ),
            enableSorting: false,
            cell: ({ row }) => {
              return (
                <>
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      id={row.original.uniqueId}
                      className="check_box"
                      checked={row.getIsSelected()}
                      onChange={() => {
                        row.getToggleSelectedHandler();
                        handleRowSelection(row);
                      }}
                    />
                  </div>
                </>
              );
            },
          },

          {
            accessorKey: "table_result_number",
            header: "No.",
            cell: ({ row, table }) => {
              return (
                <>
                  <div
                    className={PriorityClassNames(row.original[8])}
                    id={`num-${row.index + 1}`}
                  >
                    <p className="text-center w-10" id={`num-${row.id}`}>
                      {row.index + 1}
                    </p>
                    {activeTabIndex !== 3 ? (
                      <BiDuplicate
                        onClick={() => handleDuplicate(row, table)}
                        className="text-gray-400 cursor-pointer ml-1"
                      />
                    ) : null}
                  </div>
                </>
              );
            },
          },
          {
            accessorKey: "uniqueId",
            header: "ID",
            cell: ({ row }) => {
              return (
                <p className="text-center w-10" id={`id-${row.id}`}>
                  {row.original.uniqueId}
                </p>
              );
            },
          },

          ...columns.map((col) => {
            if (
              col?.header === "Client/Matter" &&
              hbaDomains.includes(currUrl)
            ) {
              return {
                ...col,
                header: "Project Name",
              };
            }
            return col;
          }),
          {
            accessorKey: "edit",
            header: (
              <div className="flex items-center justify-center">
                <button>
                  <AiOutlineSetting
                    className="text-gray-400 cursor-pointer"
                    onClick={() => toggleSidebar((b) => !b)}
                  />
                </button>
              </div>
            ),
            cell: ({ row }) => {
              if (userType == "OWNER") {
                return activeTabIndex !== 3 ? (
                  <BiTrash
                    className="text-gray-400 hover:text-red-700 cursor-pointer"
                    onClick={() =>
                      archiveTasklist(row.original.id, row.index, row)
                    }
                  />
                ) : (
                  <LuArchiveRestore
                    className="text-gray-400 hover:text-green-700 cursor-pointer"
                    onClick={() =>
                      restoreTasklist(row.original.id, row.index, row)
                    }
                  />
                );
              } else if (row.original.hasOwnProperty(9)) {
                let assignees = row.original[9].split(",");
                if (
                  isCurrentUserAssigned(assignees) ||
                  row.original[9].length == 0
                ) {
                  return activeTabIndex !== 3 ? (
                    <BiTrash
                      className="text-gray-400 hover:text-red-700 cursor-pointer"
                      onClick={() =>
                        archiveTasklist(row.original.id, row.index, row)
                      }
                    />
                  ) : (
                    <LuArchiveRestore
                      className="text-gray-400 hover:text-green-700 cursor-pointer"
                      onClick={() =>
                        restoreTasklist(row.original.id, row.index, row)
                      }
                    />
                  );
                } else {
                  return null;
                }
              } else {
                return activeTabIndex !== 3 ? (
                  <BiTrash
                    className="text-gray-400 hover:text-red-700 cursor-pointer"
                    onClick={() =>
                      archiveTasklist(row.original.id, row.index, row)
                    }
                  />
                ) : (
                  <LuArchiveRestore
                    className="text-gray-400 hover:text-green-700 cursor-pointer"
                    onClick={() =>
                      restoreTasklist(row.original.id, row.index, row)
                    }
                  />
                );
              }
            },
            /*cell: ({ row }) => (
	  <BiDotsHorizontalRounded className="text-gray-400" />
	),*/
          },
        ]
      : [
          // Skeleton Loader -- For loading states
          {
            accessorKey: "loading_number",
            header: "No.",
            cell: ({ row }) => {
              return (
                <>
                  <div className={PriorityClassNames(row.original[8])}>
                    <p className="text-center w-5 ml-1">
                      <Skeleton count={1} />
                    </p>
                  </div>
                </>
              );
            },
          },
          {
            accessorKey: "loading_clientmatter",
            header: hbaDomains.includes(currUrl)
              ? "Project Name"
              : "Client/Matter",
            cell: ({ row }) => {
              return (
                <>
                  <SkeletonTheme width={"256px"} height={"38px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </>
              );
            },
          },
          {
            accessorKey: "loading_Subject",
            header: "Email Subject",
            cell: ({ row }) => {
              return (
                <>
                  <SkeletonTheme>
                    <Skeleton count={3} />
                  </SkeletonTheme>
                </>
              );
            },
          },
          {
            accessorKey: "loading_Priority",
            header: "Priority",
            cell: ({ row }) => {
              return (
                <>
                  <SkeletonTheme width={"109px"} height={"40px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </>
              );
            },
          },
          {
            accessorKey: "loading_Email",
            header: "Preview Email",
            cell: ({ row }) => {
              return (
                <>
                  <SkeletonTheme>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </>
              );
            },
          },
          {
            accessorKey: "loading_Description",
            header: "Description",
            cell: ({ row }) => {
              return (
                <>
                  <SkeletonTheme width={"677px"}>
                    <Skeleton count={6} />
                  </SkeletonTheme>
                </>
              );
            },
          },
          {
            accessorKey: "loading_Assignee",
            header: "Assignee",
            cell: ({ row }) => {
              return (
                <>
                  <div className="w-24">
                    <SkeletonTheme
                      className="flex gap-2"
                      inline={true}
                      width={"40px"}
                      height={"40px"}
                    >
                      <Skeleton className="mr-2" count={2} circle={true} />
                    </SkeletonTheme>
                  </div>
                </>
              );
            },
          },
          {
            accessorKey: "loading_Status",
            header: "Status",
            cell: ({ row }) => {
              return (
                <>
                  <SkeletonTheme width={"127px"} height={"40px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </>
              );
            },
          },
          {
            accessorKey: "loading_Attachments",
            header: "Attachments",
            cell: ({ row }) => {
              return (
                <>
                  <SkeletonTheme width={"103px"} height={"40px"}>
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </>
              );
            },
          },
        ],
    state: {
      columnVisibility,
      // useSortBy,
    },
    meta: {
      saveStatus: async (e, rowDetails) => {
        //console.group("saveStatus()");
        console.log("input", rowDetails);
        var rowId = rowDetails.id;
        data.map((x) => (x.id === rowId ? (x[7] = e) : x));

        var temp = rowDetails;
        temp[7] = e;
        temp["updatedAt"] = new Date();

        const result = await API.graphql({
          query: TASK_LIST_DATA_UPDATE,
          variables: {
            id: rowDetails.id,
            input: {
              data: JSON.stringify(temp),
            },
          },
        });

        console.log("updated", result);
        //	console.groupEnd();
      },
      updateData: async (row, column, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === row.index) {
              return {
                ...old[row.index],
                [column.id]: value,
              };
            }
            return row;
          })
        );
        // Prepare new row
        let newRow = row.original;
        newRow[column.id] = value;

        /** Date Completed */
        let dateCompleted = null,
          completedBy = null;
        if (column.id === "7") {
          if (value === "Completed") {
            console.log("Task is Completed");
            dateCompleted = new Date();
            completedBy = userId;
          }
          newRow["dateCompleted"] = dateCompleted;
          newRow[12] = dateCompleted;
          newRow["completedBy"] = completedBy;
        }

        newRow["updatedAt"] = new Date();

        // API Call
        await API.graphql({
          query: TASK_LIST_DATA_UPDATE,
          variables: {
            id: row.original.id,
            input: {
              data: JSON.stringify(newRow),
            },
          },
        });

        // MMA-1946 Added this line of code so that it will update the state of our task
        // whenever we assign them to their appropriate status
        if (activeTabIndex === 0) {
          // All Task
          fetchDataWithFilterBy("all");
        } else if (activeTabIndex === 1) {
          // Outstanding
          // fetchOutsandingTasks()
          fetchDataWithFilterBy("all");
        } else {
          // Completed
          // fetchCompletedTasks();
          fetchDataWithFilterBy("all");
        }
      },
      stageEdit: (row, column, value) => {
        stagedEdits.current = { row, column, value };
      },
      commitStagedEdits: commitStagedEdits,
    },
    gonSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  //Sync table data states here
  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  // MMA-1981 Filtering Rows according to Task Registers
  // useEffect(() => {
  // 	console.log("Preset Changed: ", preset);
  // 	const filteredData = initialData.filter((item) =>
  // 		item.registers && Array.isArray(item.registers)
  // 			? item.registers.find((register) => register.value === preset)
  // 			: null
  // 	);
  // 	console.log("filteredData", filteredData);

  // 	if (preset === "") {
  // 		setData(initialData);
  // 	} else {
  // 		setData(filteredData);
  // 	}
  // }, [preset]);

  useEffect(() => {
    console.log("Preset Changed: ", preset);

    const filteredData =
      preset === ""
        ? initialData
        : initialData.filter((item) =>
            item.registers?.some((register) => register.value === preset)
          );

    console.log("filteredData", filteredData);

    setData(filteredData);
  }, [preset]);

  // MMA-2528: Task List Mobile: using the search bar causes whitescreen

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    parentRef: tableContainerRef,
    // Added condition for loading
    //getItemKey: (index) => (data.length !== 0 ? data[index]?.id : index),
    count: rows.length,
    overscan: width > 844 ? 5 : 2,
    estimateSize: useCallback(() => 138, []),
    //initialOffset: 0,
    //getTotalSize: () => height,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  /**
   * Commits Staged Edits
   */
  async function commitStagedEdits() {
    if (stagedEdits.current) {
      console.log("commiting..");
      const { row, column, value } = stagedEdits.current;
      updateData(row, column, value);
      // reset the staged data
      stagedEdits.current = null;
    }
  }

  useEffect(() => {
    // Commit edits
    commitStagedEdits();

    // Pagination
    const [lastItem] = [...virtualRows].reverse();

    if (
      loading ||
      !lastItem ||
      !(lastItem.index >= rows.length - 1) ||
      isFetchingNextPage.current
    ) {
      return;
    }

    handleFetchNextData(activeTabIndex);
  }, [virtualRows]);

  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  const archiveTasklist = async (id, index, row) => {
    // if (window.confirm("Are you sure to archive the item?")) {
    console.log(id);
    // mutation here..
    try {
      await API.graphql({
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: id,
          input: {
            isDeleted: true,
          },
        },
      }).then(async function (result) {
        console.log(result);
        toast.success(`Task successfully archived.`);

        setInitialData(initialData.filter((x) => x.id !== id));

        setArchivedCount(archivedCount + 1);
        setArchivedTasks([...archivedTasks, row.original]);
        setaAllTasks(allTasks.filter((x) => x.id !== id));
        setAllCount(allCount - 1);

        if (row.original["7"] === "Outstanding") {
          setOutstandingCount((count) => count - 1);
          setOutstandingTasks((arr) => arr.filter((x) => x.id !== id));
        }

        if (row.original["7"] === "Completed") {
          setCompletedCount((count) => count - 1);
          setCompletedTasks((arr) => arr.filter((x) => x.id !== id));
        }

        row.original.isDeleted = true;

        const params = {
          query: ACTIVITY_CREATE,
          variables: {
            companyId: companyId,
            clientmatterId: row?.original?.clientMatterId,
            activity: `archive task`,
            rowId: id,
            field: "Archive task",
            appModule: "TASKS",
          },
        };
        const addActivity = await API.graphql(params).then((res) =>
          console.log("ADD ACTIVITY RESULT", res)
        );
      });
    } catch (e) {
      toast.error(`${e.errors[0].message}`);
    }
    // }
  };

  const restoreTasklist = async (id, index, row) => {
    // if (window.confirm("Are you sure to archive the item?")) {
    console.log(id);
    // mutation here..
    try {
      let temp = { ...row.original };
      temp["updatedAt"] = new Date();

      let params = {
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: id,
          input: {
            isDeleted: false,
            data: JSON.stringify(temp),
          },
        },
      };

      await API.graphql(params).then(async function (result) {
        toast.success(`Task successfully restored.`);

        setInitialData(initialData.filter((x) => x.id !== id));

        setArchivedCount((count) => count - 1);
        setArchivedTasks((arr) => arr.filter((x) => x.id !== id));
        setaAllTasks((arr) => [...arr, row.original]);
        setAllCount(allCount + 1);

        if (row.original["7"] === "Outstanding") {
          setOutstandingCount((count) => count + 1);
          setOutstandingTasks((arr) => [...arr, row.original]);
        }

        if (row.original["7"] === "Completed") {
          setCompletedCount((count) => count + 1);
          setCompletedTasks((arr) => [...arr, row.original]);
        }

        row.original.isDeleted = true;

        const params = {
          query: ACTIVITY_CREATE,
          variables: {
            companyId: companyId,
            clientmatterId: row?.original?.clientMatterId,
            activity: `archive task`,
            rowId: id,
            field: "Archive task",
            appModule: "TASKS",
          },
        };
        const addActivity = await API.graphql(params).then((res) =>
          console.log("ADD ACTIVITY RESULT", res)
        );
      });
    } catch (e) {
      toast.error(`${e.errors[0].message}`);
    }
    // }
  };
  //mobile functions
  function testDisplay(celldata) {
    console.log("celldata", celldata);
  }

  function sortArrayByLabel(arr) {
    arr.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return arr;
  }

  //MMA-2352: Adds the row the selectedRows array when selected.
  const handleRowSelection = (row) => {
    const isSelected = !row.getIsSelected();
    row.toggleSelected(isSelected);
    const header = document.getElementById("table-header");
    setTableHeader(header.cloneNode(true));

    // Update the selectedRows state based on the selected rows
    if (isSelected) {
      console.log("Selected Row " + row.original.uniqueId);
      setSelectedRows([...selectedRows, row.original]);

      const copy = document.getElementById("div" + row.id);
      console.log(copy);
      setSelectedDivs([...selectedDivs, copy.cloneNode(true)]);
    } else {
      console.log("Unselected Row " + row.original.uniqueId);
      setSelectedRows(selectedRows.filter((r) => r !== row.original));
      setSelectedDivs(selectedDivs.filter((d) => d.id !== "div" + row.id));
    }
  };

  useEffect(() => {
    console.log("SELECTED ROWS: " + JSON.stringify(selectedRows));
  }, [selectedRows]);

  const workDays = (startDate, endDate) => {
    startDate = moment.utc(startDate);
    endDate = moment.utc(endDate);
    var workDays = 0;
    for (
      var i = moment(startDate).add(1, "d");
      moment(i).isBefore(endDate);
      i = moment(i).add(1, "d")
    ) {
      if (moment(i).day() === 0 || moment(i).day() === 6) {
        // 0 = sunday, 6 = saturday
        continue;
      }
      workDays++;
    }
    //console.log("workdays: " + workDays)
    return workDays;
  };

  // WIP - AQS
  const getDayTimeDifference = (startDate, endDate) => {
    /* Converting the startDate and endDate to UTC */
    endDate = moment.utc(endDate).toISOString();
    startDate = moment.utc(startDate).toISOString();
    var diffInMinutes = moment(endDate).diff(moment(startDate), "minutes");

    const totalDays = workDays(startDate, endDate);

    var hrs = 1440; // mins in 24hrs
    var inDays = totalDays;
    var inHours = Math.floor((diffInMinutes % hrs) / 60);
    var inMinutes = (diffInMinutes % hrs) % 60;

    var dateDifference = "";

    if (inDays !== 0)
      dateDifference += `<strong>${inDays}</strong>${
        inDays > 1 ? "days" : "day"
      }`;
    if (inHours !== 0)
      dateDifference += `<strong>${inHours}</strong>${
        inHours > 1 ? "hrs" : "hr"
      }`;
    if (inMinutes !== 0)
      dateDifference += `<strong>${inMinutes}</strong>${
        inMinutes > 1 ? "mins" : "min"
      }`;

    return dateDifference;
  };

  const [firstRow, setFirstRow] = useState(null);
  const [firstRowData, setFirstRowData] = useState(null);

  useEffect(() => {
    var first;
    // console.log("Rows", rows);
    if (rows.length > 0) {
      rows.map((row) => {
        if (row.index == 0) {
          first = row;
        }
      });
      // console.log("first", first);
      var temp = document.getElementById("div" + first.id);
      if (temp != null && first?.original !== firstRowData?.original) {
        //var copy = temp.cloneNode(true);
        setFirstRow(temp);
        setFirstRowData(first);
        console.log("Setting FirstRowData", first);
      }
    }
  }, [virtualRows]);

  //MMA-2352: Check all boxes functionality
  const handleSelectAll = (table) => {
    const header = document.getElementById("table-header");
    setTableHeader(header.cloneNode(true));

    // Toggle selection for all rows
    if (!table.getIsAllRowsSelected()) {
      // All rows are selected, so update selectedRows with all rows
      setSelectedRows(rows.map((row) => row.original));
      var divs = [];
      var lastRender;
      var lastRenderRow;
      if (firstRow != null && firstRowData != null) {
        let firstRowClone = firstRow.cloneNode(true);
        const cm = firstRowClone.querySelector(`#cm-${firstRowData.id}`);
        const status = firstRowClone.querySelector(
          `#status-${firstRowData.id}`
        );
        const preview = firstRowClone.querySelector(
          `#preview-${firstRowData.id}`
        );
        const email = firstRowClone.querySelector(
          `#emailDiv-${firstRowData.id}`
        );
        const prio = firstRowClone.querySelector(
          `#priority-${firstRowData.id}`
        );
        const taskreg = firstRowClone.querySelector(
          `#taskreg-${firstRowData.id}`
        );
        const attach = firstRowClone.querySelector(
          `#attach-${firstRowData.id}`
        );
        const assignee = firstRowClone.querySelector(
          `#assigneeDiv-${firstRowData.id}`
        );
        const createdBy = firstRowClone.querySelector(
          `#createdBy-${firstRowData.id}`
        );
        const dueDate = firstRowClone.querySelector(
          `#dueDate-${firstRowData.id}`
        );
        const eyeIcon = firstRowClone.querySelector("#eye-icon");
        console.log(firstRowData);
        console.log(firstRowData.original);

        console.log(cm);
        if (cm) {
          console.log("cm true");
          cm.innerHTML = firstRowData.original[0]
            ? firstRowData.original[0]
            : "Select Client/Matter";
        }
        if (status) status.innerHTML = firstRowData.original[7];
        if (firstRowData.original[1] && email && eyeIcon) eyeIcon.remove();
        if (prio)
          prio.innerHTML = firstRowData.original[8]
            ? firstRowData.original[8]
            : "Select";
        if (preview)
          preview.innerHTML =
            firstRowData.original.gmailMessageId !== null &&
            firstRowData.original.gmailMessageId !== "0"
              ? "Email PDF"
              : "";
        if (attach)
          attach.innerHTML =
            firstRowData.original.gmailMessageId !== null &&
            firstRowData.original.gmailMessageId !== "0"
              ? "Show Attachments"
              : "N/A";
        var taskRegs = "";
        if (taskreg) {
          if (
            firstRowData.original.registers &&
            firstRowData.original.registers.length > 0 &&
            firstRowData.original.registers[0] != null
          ) {
            var temp = "";
            if (Array.isArray(firstRowData.original.registers)) {
              firstRowData.original.registers.forEach(function (x) {
                temp += x.label + ", ";
              });
            } else {
              temp = firstRowData.original.registers.label;
            }
            taskreg.innerHTML = temp;
          } else {
            taskreg.innerHTML = "Select Task Register";
          }
        }
        if (assignee) {
          assignee.innerHTML = "";
          ReactDOM.render(getAssigneeHTML(firstRowData.original[9]), assignee);
        }
        if (createdBy) {
          createdBy.innerHTML = "";
          ReactDOM.render(
            getCreatedByHTML(firstRowData.original[14]),
            createdBy
          );
        }
        if (dueDate) {
          dueDate.innerHTML = firstRowData.original[11]
            ? dateFormatter(firstRowData.original[11])
            : "";
        }
        divs.push(firstRowClone);

        console.log("ROWSITEMS", rows);

        rows.map((row) => {
          if (row.index > 0) {
            var currRow = firstRow.cloneNode(true);

            var cm = currRow.querySelector(`#cm-${firstRowData.id}`);
            var status = currRow.querySelector(`#status-${firstRowData.id}`);
            var checkbox = currRow.querySelector(".check_box");
            var num = currRow.querySelector(`#num-${firstRowData.id}`);
            var id = currRow.querySelector(`#id-${firstRowData.id}`);
            var email = currRow.querySelector(`#emailDiv-${firstRowData.id}`);
            var desc = currRow.querySelector(`#description-${firstRowData.id}`);
            var assignee = currRow.querySelector(
              `#assigneeDiv-${firstRowData.id}`
            );
            var createdBy = currRow.querySelector(
              `#createdBy-${firstRowData.id}`
            );
            var dueDate = currRow.querySelector(`#dueDate-${firstRowData.id}`);
            const prio = currRow.querySelector(`#priority-${firstRowData.id}`);
            const taskreg = currRow.querySelector(
              `#taskreg-${firstRowData.id}`
            );
            const dateComplete = currRow.querySelector(
              `#dateComplete-${firstRowData.id}`
            );
            const taskAge = currRow.querySelector(
              `#taskAge-${firstRowData.id}`
            );
            const attach = currRow.querySelector(`#attach-${firstRowData.id}`);
            const dateReceived = currRow.querySelector(
              `#dateReceived-${firstRowData.id}`
            );
            const dateSubmitted = currRow.querySelector(
              `#dateSubmitted-${firstRowData.id}`
            );
            const preview = firstRow.querySelector(
              `#preview-${firstRowData.id}`
            );

            if (checkbox) checkbox.id = row.id;
            if (num) num.innerHTML = row.index + 1;
            if (id) id.innerHTML = row.original.uniqueId;
            if (email) {
              if (row.original[1]) {
                currRow.querySelector(`#email-${firstRowData.id}`).innerHTML =
                  row.original[1];
              } else {
                currRow.querySelector(
                  `#emailDiv-${firstRowData.id}`
                ).innerHTML = "";
              }
            }
            if (cm)
              cm.innerHTML = row.original[0]
                ? row.original[0]
                : "Select Client/Matter";
            if (desc) desc.value = row.original[6] ? row.original[6] : "";
            if (status) status.innerHTML = row.original[7];
            if (prio)
              prio.innerHTML = row.original[8] ? row.original[8] : "Select";
            if (preview)
              preview.innerHTML =
                row.original.gmailMessageId !== null &&
                row.original.gmailMessageId !== "0"
                  ? "Email PDF"
                  : "";
            if (dateReceived)
              dateReceived.innerHTML = row.original[3]
                ? dateTimeFormatter(row.original[3])
                : "";
            if (dateSubmitted)
              dateSubmitted.innerHTML = row.original[10]
                ? dateTimeFormatter(row.original[10])
                : "";
            if (dateComplete)
              dateComplete.innerHTML = getDayTimeDifference(
                row.original.createdAt,
                row.original.dateCompleted
                  ? row.original.dateCompleted
                  : row.original.updatedAt
              );
            if (taskAge)
              taskAge.innerHTML = getDayTimeDifference(
                row.original.createdAt,
                new Date()
              );
            if (attach)
              attach.innerHTML =
                row.original.gmailMessageId !== null &&
                row.original.gmailMessageId !== "0"
                  ? "Show Attachments"
                  : "N/A";
            if (taskreg) {
              if (
                row.original.registers &&
                row.original.registers.length > 0 &&
                row.original.registers[0] != null
              ) {
                var temp = "";
                if (Array.isArray(row.original.registers)) {
                  row.original.registers.forEach(function (x) {
                    temp += x.label + ", ";
                  });
                } else {
                  temp = row.original.registers.label;
                }
                taskreg.innerHTML = temp;
              } else {
                taskreg.innerHTML = "Select Task Register";
              }
            }
            if (assignee) {
              assignee.innerHTML = "";
              ReactDOM.render(getAssigneeHTML(row.original[9]), assignee);
            }
            if (createdBy) {
              ReactDOM.render(getCreatedByHTML(row.original[14]), createdBy);
            }
            if (dueDate) {
              dueDate.innerHTML = row.original[11]
                ? dateFormatter(row.original[11])
                : "";
            }
            divs.push(currRow);
          }
        });
      }
      setSelectedDivs(divs);
    } else {
      // Some rows are deselected, so clear selectedRows
      setSelectedRows([]);
      setSelectedDivs([]);
    }
  };

  const getAssigneeHTML = (value) => {
    let x = value ? value.split(",") : [];

    /* Filtering out undefined values from an array. */
    const y = x.filter((element) => {
      return element !== undefined && element !== "undefined";
    });

    /* Trimming the whitespace from each element in the array. */
    const data = y.map((x) => x.trim());

    return (
      <div className="flex justify-end flex-wrap w-24">
        {data.map((x, index) => {
          const assignee = parsePeople(x);

          var assigneeInitials = "";
          if (x.toLowerCase() === "dane mariel sta. ana") {
            assigneeInitials = "DM";
          } else if (x.toLowerCase() === "john dela cruz") {
            assigneeInitials = "JDC";
          } else if (typeof assignee === "string") {
            return;
          } else {
            assigneeInitials += assignee.firstName
              ? assignee.firstName?.charAt(0)
              : "";
            assigneeInitials += assignee.lastName
              ? assignee.lastName?.charAt(0)
              : "";
          }

          return (
            assignee && (
              <div className="flex" key={index}>
                <div
                  className="flex bg-gray-500 text-white w-10 h-10 rounded-full text-md font-medium justify-center items-center"
                  data-tip={assignee.firstName + " " + assignee.lastName}
                >
                  {assignee.profilePicture === null ? (
                    <>{assigneeInitials.toUpperCase()}</>
                  ) : (
                    <img
                      className="rounded-full w-10 h-10"
                      src={assignee.profilePicture}
                    />
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  const getCreatedByHTML = (user) => {
    const createdBy = parsePeople(user);

    return (
      <div className="flex">
        <div
          className="flex bg-gray-500 text-white w-10 h-10 rounded-full text-md font-medium justify-center items-center"
          data-tip={
            createdBy ? createdBy.firstName + " " + createdBy.lastName : "None"
          }
        >
          {!createdBy?.profilePicture ? (
            <>
              {(
                (createdBy?.firstName ? createdBy.firstName.charAt(0) : "") +
                (createdBy?.lastName ? createdBy.lastName.charAt(0) : "")
              ).toUpperCase()}
            </>
          ) : (
            <img
              className="rounded-full w-10 h-10"
              src={createdBy?.profilePicture}
            />
          )}
        </div>
        <ReactTooltip globalEventOff="scroll" scrollHide />
      </div>
    );
  };

  async function getPresetsLibrary() {
    //	console.group("ColumnSettingTab: getPresetsLibrary()");

    const { data } = await API.graphql({
      query: TASKLIST_PRESETS_QUERY,
      variables: {
        companyId: localStorage.getItem("companyId"),
      },
    });

    // console.log("taskListPresets", data);
    const tlPresets = sortArrayByLabel(data?.taskListPresets);
    const presetOpt = tlPresets.map((x) => {
      return {
        label: x.label,
        value: x.id,
      };
    });

    setPresetsLibrary(tlPresets);
    setTaskRegistersOptions(presetOpt);
  }

  function sortByAccesorKey(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.accessorKey - b.accessorKey);
    } else {
      sort = arr;
    }

    return sort;
  }

  async function saveColumns(newColumnSettingsWithId) {
    // console.log("saveColumns()");
    const columnsToSetApi = sortByAccesorKey(newColumnSettingsWithId).map(
      ({ accessorKey, header, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: header,
          ...rest,
        };
      }
    );

    if (columnsToSetApi.length > 0) {
      const { data } = await API.graphql({
        query: UPDATE_TASK_LIST_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      });

      // console.log("Saved Columns", data.taskListTableUpdate.columns);

      const tableColumns = data.taskListTableUpdate.columns.map((c) => {
        if (c.type === "DROPDOWN") {
          return {
            accessorKey: c.accessorKey,
            header: c.headerText,
            type: c.type,
            enabled: c.enabled,
            order: c.order,
            presets: c.presets,
            optionsText: c.optionsText ? c.optionsText : [],
          };
        }
        return {
          accessorKey: c.accessorKey,
          header: c.headerText,
          type: c.type,
          order: c.order,
          presets: c.presets,
          enabled: c.enabled,
        };
      });

      // console.log("setColumns", tableColumns);
      setColumnsMobile(tableColumns);
    }
  }

  function findObjectByLabel(arr, label) {
    return arr.find((obj) => obj.label === label);
  }

  async function groupByPresetsId(arr) {
    return arr.reduce((acc, curr) => {
      curr?.presets?.forEach((preset) => {
        if (!acc[preset.id]) {
          acc[preset.id] = [];
        }

        // console.log("curr.accessorKey", curr.accessorKey);
        if (curr.accessorKey !== "") {
          acc[preset.id].push(parseInt(curr.accessorKey, 10));
        }
      });
      return acc;
    }, {});
  }

  async function updateRegisters(accessorKey, registers) {
    // console.log("updateRegisters()");
    const presets = registers.map((item) => {
      const { value, ...rest } = item;
      return { id: value, ...rest };
    });

    const newColumnSettingsWithId = columns.map((column, index) => {
      if (column.accessorKey === accessorKey) {
        column.presets = presets;
      }
      return column;
    });

    // console.log("Columns:", columns);
    // console.log("Columns with Presets:", newColumnSettingsWithId);

    await saveColumns(newColumnSettingsWithId);

    const groupedPresets = await groupByPresetsId(newColumnSettingsWithId);
    // console.log("groupedPresets", groupedPresets);

    const mappedPresets = Object.keys(groupedPresets).map((key) => ({
      presetId: key,
      columnIds: groupedPresets[key],
    }));

    // console.log("mappedPresets", mappedPresets);

    const savePromises = mappedPresets.map(async ({ columnIds, presetId }) => {
      try {
        await API.graphql({
          query: UPDATETASKLISTPRESET_MUTATION,
          variables: { columnIds, id: presetId },
        });
      } catch (error) {
        console.error(error);
      }
    });

    await Promise.allSettled(savePromises);
    // setPreset("");
    await getPresetsLibrary();
  }

  async function createNewRegister(label) {
    const companyId = localStorage.getItem("companyId");
    const { data } = await API.graphql({
      query: CREATE_TASKLIST_PRESET_MUTATION,
      variables: {
        companyId,
        label,
        columnIds: null,
      },
    });
    // console.log("Newly Added ID is", data?.taskListPresetsCreate?.id);
    return data?.taskListPresetsCreate?.id;
  }

  // MMA-1981 Update Task Register Field
  // KJMFEDIT2
  const updateTaskRegisters = async (id, row, newValues) => {
    // Set New Registers
    const newRow = row;
    newRow.registers = newValues;

    // Set Updated Dates
    newRow["updatedAt"] = new Date();
    console.log("newRow", newRow);

    const newCols = await Promise.allSettled(
      newValues.map(async (r) => {
        if (r?.__isNew__) {
          console.log("ok so far");
          const { label } = r;
          const objChecker = findObjectByLabel(taskRegistersOptions, label);

          console.log("objChecker:-->", objChecker);
          if (!objChecker) {
            const newId = await createNewRegister(label);
            return { value: newId, label };
          }
        }
        return r;
      })
    );

    const updatedCols = newCols.map((r) => {
      const v = r?.value;
      return { value: v.value, label: v.label };
    });

    try {
      await API.graphql({
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: row.id,
          input: {
            data: JSON.stringify(newRow),
          },
        },
      });
    } catch (error) {
      console.log("Error in updating: ", error);
    }

    updateRegisters(id, updatedCols);
    //fetchDataWithFilterBy("Filters");
  };

  const saveDescription = async (rowDetails) => {
    const previousValue = rowDetails[6];

    rowDetails[6] = descValue;
    rowDetails["updatedAt"] = new Date();

    const [result, logActivity] = await Promise.all([
      API.graphql({
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: rowDetails.id,
          input: {
            data: JSON.stringify(rowDetails),
          },
        },
      }),
      API.graphql({
        query: ACTIVITY_CREATE,
        variables: {
          companyId: localStorage.getItem("companyId"),
          appModule: "TASKS",
          field: "Description",
          activity: "updated the description",
          rowId: rowDetails.id,
          current: descValue,
          previous: previousValue,
        },
      }),
    ]);

    setDescValue("");
  };

  const saveAssignee = async (e, rowDetails) => {
    console.log("CHANGED ASSIGNEE");
    const previousValue = rowDetails[9];

    var rowId = rowDetails.id;
    let newPeople = "";

    if (e.length > 0) {
      for (var i = 0; i < e.length; i++) {
        newPeople = newPeople + `${e[i].value},`; //convert to string
      }
      newPeople = newPeople.substr(0, newPeople.length - 1); //remove last comma
    } else {
      newPeople = "";
    }

    data.map((x) => (x.id === rowId ? (x[9] = newPeople) : x));

    var temp = rowDetails;
    temp[9] = newPeople;

    temp["updatedAt"] = new Date();

    console.log("row assignee", temp);

    const [result, logActivity] = await Promise.all([
      API.graphql({
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: rowDetails.id,
          input: {
            data: JSON.stringify(temp),
          },
        },
      }),
      API.graphql({
        query: ACTIVITY_CREATE,
        variables: {
          companyId: localStorage.getItem("companyId"),
          appModule: "TASKS",
          field: "Assignee",
          activity: "changed the assignee",
          rowId: rowDetails.id,
          current: newPeople,
          previous: previousValue,
        },
      }),
    ]);
  };

  const saveStatus = async (e, rowDetails) => {
    //console.group("saveStatus()");
    console.log("input", rowDetails);
    var rowId = rowDetails.id;
    const val = rowDetails[7];
    data.map((x) => (x.id === rowId ? (x[7] = e.label) : x));

    var temp = rowDetails;
    temp[7] = e.label;
    temp["updatedAt"] = new Date();

    const result = await API.graphql({
      query: TASK_LIST_DATA_UPDATE,
      variables: {
        id: rowDetails.id,
        input: {
          data: JSON.stringify(temp),
        },
      },
    });

    console.log(rowDetails[7]);

    if (val === "Completed") {
      console.log(initialData);
      console.log(rowDetails.id);
      setInitialData(initialData.filter((x) => x.id !== rowDetails.id));
      setCompletedCount(completedCount - 1);
      if (e.label === "Outstanding") {
        setOutstandingCount(outstandingCount + 1);
      }
    } else if (val === "Outstanding") {
      setInitialData(initialData.filter((x) => x.id !== rowDetails.id));
      setOutstandingCount(outstandingCount - 1);
      if (e.label === "Completed") {
        setCompletedCount(completedCount + 1);
      }
    } else {
      if (e.label === "Outstanding" || e.label === "Completed") {
        if (e.label === "Completed") {
          setCompletedCount(completedCount + 1);
        } else {
          setOutstandingCount(outstandingCount + 1);
        }
      }
    }
    setShowStatus(false);

    console.log("updated", result);
    //console.groupEnd();
  };

  const savePriority = async (e, rowDetails) => {
    //	console.group("savePriority()");
    console.log("input", rowDetails);
    var rowId = rowDetails.id;
    data.map((x) => (x.id === rowId ? (x[8] = e.label) : x));

    var temp = rowDetails;
    temp[8] = e.label;
    temp["updatedAt"] = new Date();

    const result = await API.graphql({
      query: TASK_LIST_DATA_UPDATE,
      variables: {
        id: rowDetails.id,
        input: {
          data: JSON.stringify(temp),
        },
      },
    });

    console.log("updated", result);
    //console.groupEnd();
  };

  const saveClientMatter = async (e, rowDetails) => {
    //	console.group("saveClientMatter()");
    console.log("input", rowDetails);

    for (var i = 0; i < data.length; i++) {
      if (data[i].id === rowDetails.id) {
        data[i][0] = e.label;
        data[i]["clientMatterId"] = e.value;
      }
    }

    var temp = rowDetails;
    temp[0] = e.label;
    temp["clientMatterId"] = e.value;
    temp["updatedAt"] = new Date();

    const result = await API.graphql({
      query: TASK_LIST_DATA_UPDATE,
      variables: {
        id: rowDetails.id,
        input: {
          data: JSON.stringify(temp),
        },
      },
    });

    console.log("updated", result);
    //	console.groupEnd();
  };

  function mobileShowDeleteIcon(row, activeTabIndex) {
    if (userType == "OWNER") {
      return (
        <div className="ml-auto items-center">
          {activeTabIndex !== 3 ? (
            <Button
              variant="danger-l"
              onClick={() => archiveTasklist(row.original.id, row.index, row)}
              className="hover:bg-danger-l"
            >
              <BiTrash className="text-danger cursor-pointer" />
            </Button>
          ) : (
            <Button
              variant="primary-l"
              onClick={() => restoreTasklist(row.original.id, row.index, row)}
              className="hover:bg-primary-l"
            >
              <LuArchiveRestore className="text-primary cursor-pointer" />
            </Button>
          )}
        </div>
      );
    } else if (row.original.hasOwnProperty(9) && activeTabIndex !== 3) {
      let assignees = row.original[9].split(",");
      if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
        return (
          <div className="ml-auto items-center">
            {activeTabIndex !== 3 ? (
              <Button
                variant="danger-l"
                onClick={() => archiveTasklist(row.original.id, row.index, row)}
                className="hover:bg-danger-l"
              >
                <BiTrash className="text-danger cursor-pointer" />
              </Button>
            ) : (
              <Button
                variant="primary-l"
                onClick={() => restoreTasklist(row.original.id, row.index, row)}
                className="hover:bg-primary-l"
              >
                <LuArchiveRestore className="text-primary cursor-pointer" />
              </Button>
            )}
          </div>
        );
      }
    }
    return <></>;
  }

  function checkIfAssignee(row) {
    if (userType == "OWNER") {
      return true;
    } else if (row.original.hasOwnProperty(9)) {
      let assignees = row.original[9].split(",");
      if (isCurrentUserAssigned(assignees) || row.original[9].length == 0) {
        return true;
      }
    }
    return false;
  }

  //copy paste func
  const copyDetails = (rowId, rowAssignee, rowDesc) => {
    setCopyId(rowId);
    setCopyAssignee(rowAssignee);
    setCopyDesc(rowDesc);
    setShowCopy(false);
  };

  const pasteDetails = async (rowId, rowDetails) => {
    let newPeople = "";

    var dataCopy = data;

    //save old details for undo func
    var oldData = [];
    oldData = [
      ...oldDetails,
      { id: rowId, oldDesc: rowDetails[6], oldAssignee: rowDetails[9] },
    ];
    setOldDetails(oldData);
    console.log("oldd", oldData);

    for (var i = 0; i < dataCopy.length; i++) {
      console.log(dataCopy[i][9]);
      if (dataCopy[i].id === rowId) {
        dataCopy[i][9] = copyAssignee;
        dataCopy[i][6] = copyDesc;
      }
    }

    // data.map((x) => (x.id === rowId ? (x[9] = copyAssignee) : x));
    // data.map((x) => (x.id === rowId ? (x[6] = copyDesc) : x));

    var temp = rowDetails;
    temp[9] = copyAssignee;
    temp[6] = copyDesc;

    temp["updatedAt"] = new Date();
    setData(dataCopy);

    const result = await API.graphql({
      query: TASK_LIST_DATA_UPDATE,
      variables: {
        id: rowDetails.id,
        input: {
          data: JSON.stringify(temp),
        },
      },
    });

    console.log("updated", result);

    setShowAssignee(true);
    setShowAssignee(false);
    setAssigneeId(rowId);
    setAssigneeId("");

    var pasted = pastedIds;
    pasted = [...pasted, rowId];
    setPastedIds(pasted);
  };

  const undoDetails = async (rowId, rowDetails) => {
    if (pastedIds.includes(rowId)) {
      var oldAssignee, oldDesc;

      //check if pasted so you can undo
      for (var i = 0; i < oldDetails.length; i++) {
        if (oldDetails[i].id === rowId) {
          console.log(
            "check",
            oldDetails[i].oldAssignee,
            oldDetails[i].oldDesc
          );
          oldAssignee = oldDetails[i].oldAssignee;
          oldDesc = oldDetails[i].oldDesc;
        }
      }

      //update ui
      var dataCopy = data;
      for (var i = 0; i < dataCopy.length; i++) {
        console.log(dataCopy[i][9]);
        if (dataCopy[i].id === rowId) {
          dataCopy[i][9] = oldAssignee;
          dataCopy[i][6] = oldDesc;
        }
      }
      setData(dataCopy);

      var temp = rowDetails;
      temp[6] = oldDesc;
      temp[9] = oldAssignee;
      temp["updatedAt"] = new Date();

      console.log("truee", temp);

      const result = await API.graphql({
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: rowDetails.id,
          input: {
            data: JSON.stringify(temp),
          },
        },
      });
      console.log("succ", result);

      //remove in pasted ids if undo
      var pasted = pastedIds;
      // const rem = pasted.findIndex(rowId);
      // if(rem){
      //   pasted.splice(rem, 1);
      // }

      for (var i = 0; i < pasted.length; i++) {
        if (pasted[i] === rowId) {
          pasted.splice(i, 1);
        }
      }
      setPastedIds(pasted);
      // console.log("remmm", pastedIds);

      //update states
      setShowAssignee(true);
      setShowAssignee(false);
      setAssigneeId(rowId);
      setAssigneeId("");
    } else {
      console.log("do nothing");
    }
  };

  //CREATED UNDO ALL FUNCTION
  function undoAllDetails() {
    virtualRows.map((virtualRow) => {
      const row = rows[virtualRow.index];
      if (pastedIds.includes(row.original.id)) {
        undoDetails(row.original.id, row.original);
      }
    });
  }

  const parsePeople = (assignee) => {
    if (!assignee || assignee === "") {
      return "";
    }

    var nameObject = people.find(
      (u) => `${u.firstName} ${u.lastName}` === assignee
    );

    if (!nameObject) {
      nameObject = people.find((u) => u.id === assignee);
    }

    if (!nameObject) {
      nameObject = people.find((p) => {
        if (!p.history) return false;

        let history = p.history.map((h) => JSON.parse(h));
        let nameMatches = history.find(
          (h) => `${h.firstName} ${h.lastName}` === assignee
        );
        return nameMatches;
      });
    }

    return nameObject ? nameObject : assignee;
  };

  return (
    <div
      ref={tableContainerRef}
      className={`${styles.taskTable} -mt-12 w-full ${
        width > 844 ? "" : "test123"
      }`}
      style={{ overflow: "auto", height: height - 80 }}
      //style={{ overflow: "auto", height: '90vh' }}
    >
      <table className="h-1">
        <thead
          id="table-header"
          className="sticky"
          style={{ top: "0px", zIndex: "100" }}
        >
          {width > 844 && (
            <>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="flex-nowrap bg-gray-200"
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "p-2 cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted().toString()] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </>
          )}
        </thead>

        <tbody className="bg-white">
          <tr className="bg-white rounded-lg ">
            <td
              style={{
                height: `${paddingTop}px`,
                zIndex: 100,
                background: "white",
              }}
              className="bg-white rounded-lg "
            />
          </tr>
          {virtualRows.map((virtualRow, index) => {
            const row = rows[virtualRow.index];

            // console.log("logss - ", virtualRow);

            // console.log("ROW VALUE IS:", row.original[7]);
            return (
              <tr
                id={"div" + row.id}
                ref={(e) => virtualRow.measureElement(e)}
                key={row.id}
                className="overflow-visible"
                style={{
                  height: 138,
                  background: row.getIsSelected() ? "#F5FDFA" : "white",
                }}
              >
                {/* console.log(virtualRow.size) */}
                {/* mobile view -kjmf edit*/}
                <>
                  {width < 844 && (
                    <>
                      <div
                        className={
                          index === 0
                            ? `w-full border-gray-200 border-2 bg-white mt-1 py-2 cursor-pointer block test123 px-2 overflow-x-hidden rounded-t-xl ${
                                copyId === row.original.id
                                  ? "border-blue-300 bg-blue-100"
                                  : pastedIds.includes(row.original.id)
                                  ? "border-green-300 bg-green-100"
                                  : ""
                              }`
                            : `w-full border-gray-200 border-2 bg-white -mt-1 py-2 cursor-pointer block test123 px-2 overflow-x-hidden ${
                                copyId === row.original.id
                                  ? "border-blue-300 bg-blue-100"
                                  : pastedIds.includes(row.original.id)
                                  ? "border-green-300 bg-green-100"
                                  : ""
                              }`
                        }
                      >
                        <div className="flex">
                          {/* index */}
                          <div className="mt-1 pr-3 font-sans text-gray-900">
                            {virtualRow.index + 1}
                          </div>
                          <div className="px-2 w-full">
                            <div>
                              <div className="flex">
                                {/* Unique ID */}
                                <p className="ml-1 mt-1 flex-1 font-sans text-gray-900">
                                  {row?.original?.uniqueId}
                                </p>
                                <div className="w-fit float-left flex items-center gap-4">
                                  {activeTabIndex !== 3 && showCopy ? (
                                    <BiCopy
                                      size={16}
                                      onClick={() =>
                                        copyDetails(
                                          row.original.id,
                                          row.original[9],
                                          row.original[6]
                                        )
                                      }
                                    />
                                  ) : activeTabIndex !== 3 &&
                                    copyId !== row.original.id ? (
                                    <>
                                      {pastedIds.includes(row.original.id) ? (
                                        <BiUndo
                                          size={16}
                                          onClick={() =>
                                            undoDetails(
                                              row.original.id,
                                              row.original
                                            )
                                          }
                                        />
                                      ) : (
                                        <BiPaste
                                          size={16}
                                          onClick={() =>
                                            pasteDetails(
                                              row.original.id,
                                              row.original
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  ) : activeTabIndex !== 3 &&
                                    copyId === row.original.id ? (
                                    <AiFillCheckCircle
                                      size={16}
                                      onClick={() => {
                                        setCopyAssignee("");
                                        setCopyId("");
                                        setCopyDesc("");
                                        setShowCopy(true);
                                        setPastedIds([]);
                                      }}
                                      className={
                                        showCopy ? "font-blue-300" : ""
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {mobileShowDeleteIcon(row, activeTabIndex)}
                                </div>
                              </div>

                              {/* email subject */}
                              <div>
                                <p
                                  className="ml-1 mt-1 flex-1 font-semibold font-sans text-gray-900"
                                  onClick={
                                    row.original[4]
                                      ? () => {
                                          handleSnippetEmail(
                                            row.original[4],
                                            row.original.gmailMessageId
                                          );
                                          console.log(row.original);
                                        }
                                      : null
                                  }
                                >
                                  {row.original[1] ? (
                                    row.original[1]
                                  ) : (
                                    <p className="font-semibold font-sans text-gray-900">
                                      No Subject
                                    </p>
                                  )}
                                </p>
                              </div>

                              {/* task description */}
                              <div className="flex">
                                <div
                                  rows={4}
                                  className={`p-1 resize-none w-full font-sans h-full py-1 mt-1 text-gray-900`}
                                  value={row.original[6] ? row.original[6] : ""}
                                  suppressContentEditableWarning={true}
                                  contentEditable={checkIfAssignee(row)}
                                  placeholder="N/A"
                                  onClick={() => {
                                    if (userType == "OWNER") {
                                      setDescValue(
                                        row.original[6] ? row.original[6] : ""
                                      );
                                      setDescriptionId(row.original.id);
                                      setShowDescription(true);
                                    } else if (row.original.hasOwnProperty(9)) {
                                      let assignees =
                                        row.original[9].split(",");
                                      if (
                                        isCurrentUserAssigned(assignees) ||
                                        row.original[9].length == 0
                                      ) {
                                        setDescValue(
                                          row.original[6] ? row.original[6] : ""
                                        );
                                        setDescriptionId(row.original.id);
                                        setShowDescription(true);
                                      }
                                    }
                                  }}
                                  onInput={(event) => {
                                    setDescValue(
                                      event.currentTarget.textContent
                                    );
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: row.original[6]
                                      ? row.original[6]
                                      : "",
                                  }}
                                  onBlur={() => {
                                    saveDescription(row.original);
                                    setShowDescription(false);
                                  }}
                                ></div>
                                {showDescription === true &&
                                descriptionId === row.original.id ? (
                                  <Button
                                    variant="secondary-l"
                                    block={true}
                                    className="font-semibold aspect-square ml-1 mt-1 "
                                    onClick={() => setDescriptionId("")}
                                  >
                                    <AiOutlineCheck size={16} />
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </div>

                              {/* email pdf button */}
                              <div
                                className="py-1"
                                onClick={
                                  row?.original[4]
                                    ? () => {
                                        handleSnippetEmail(
                                          row?.original[4],
                                          row?.original?.gmailMessageId
                                        );
                                        console.log(row?.original);
                                      }
                                    : null
                                }
                              >
                                <p className="font-sans font-semibold text-gray-900 ml-1">
                                  Email PDF
                                </p>
                              </div>
                            </div>

                            <div className="w-full mt-1 justify-start">
                              {/* clientmatter */}
                              <div className="flex item-center">
                                <BsFolder className="m-1 w-6" size={16} />
                                {showClientMatter === true &&
                                clientmatterId === row.original.id ? (
                                  <>
                                    <CreatableSelect
                                      options={clientMatters}
                                      className="flex-1 font-sans"
                                      onCreateOption={() => {
                                        setShowModal(true);
                                        setRowTarget(row?.original?.id);
                                      }}
                                      styles={{
                                        menuPortal: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      defaultValue={
                                        row.original.clientMatterId
                                          ? clientMatters.find(
                                              (clientMatter) =>
                                                clientMatter.value ===
                                                row.original.clientMatterId
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        saveClientMatter(e, row.original)
                                      }
                                      placeholder={
                                        hbaDomains.includes(currUrl)
                                          ? "Project Name"
                                          : "Select Client/Matter"
                                      }
                                      menuPlacement="top"
                                      menuPortalTarget={document.body}
                                    />
                                    <Button
                                      size={14}
                                      variant="secondary-l"
                                      className="ml-1 mt-1 font-bold self-end aspect-square"
                                      onClick={() => {
                                        setClientmatterId("");
                                        setShowClientMatter(false);
                                      }}
                                    >
                                      <AiOutlineCheck />
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <p
                                      className="font-sans text-gray-900 ml-2 flex-1 mt-1/2"
                                      onClick={() => {
                                        if (userType == "OWNER") {
                                          setShowClientMatter(true);
                                          setClientmatterId(row.original.id);
                                        } else if (
                                          row.original.hasOwnProperty(9)
                                        ) {
                                          let assignees =
                                            row.original[9].split(",");
                                          if (
                                            isCurrentUserAssigned(assignees) ||
                                            row.original[9].length == 0
                                          ) {
                                            setShowClientMatter(true);
                                            setClientmatterId(row.original.id);
                                          }
                                        }
                                      }}
                                    >
                                      {row.original[0]
                                        ? row.original[0]
                                        : hbaDomains.includes(currUrl)
                                        ? "Not Assigned to Project"
                                        : "Not Assigned to Client/Matter"}
                                    </p>
                                  </>
                                )}
                              </div>

                              {/* assignee */}
                              <div className="flex items-center mt-1">
                                {" "}
                                <BiUser
                                  className="m-1 w-6"
                                  size={16}
                                  onClick={() => {
                                    if (userType == "OWNER") {
                                      setShowAssignee(true);
                                      setAssigneeId(row.original.id);
                                    } else if (row.original.hasOwnProperty(9)) {
                                      let assignees =
                                        row.original[9].split(",");
                                      if (
                                        isCurrentUserAssigned(assignees) ||
                                        row.original[9].length == 0
                                      ) {
                                        setShowAssignee(true);
                                        setAssigneeId(row.original.id);
                                      }
                                    }
                                  }}
                                />
                                {showAssignee === true &&
                                assigneeId === row.original.id ? (
                                  <>
                                    <CreatableSelect
                                      options={
                                        people &&
                                        people.map((p) => {
                                          let item = `${p.firstName} ${p.lastName}`;
                                          return {
                                            value: p.id,
                                            label: item,
                                          };
                                        })
                                      }
                                      defaultValue={
                                        row.original[9]
                                          ? () => {
                                              let value = row.original[9];
                                              let arrPeople = value
                                                ?.split(",")
                                                .map((p) => parsePeople(p));
                                              let filteredArrPeople =
                                                arrPeople?.filter(
                                                  (e) => e !== ""
                                                ) ?? [];

                                              return (
                                                filteredArrPeople &&
                                                filteredArrPeople.map((p) => {
                                                  return typeof p === "string"
                                                    ? {
                                                        value: p,
                                                        label: p,
                                                      }
                                                    : {
                                                        value: p.id,
                                                        label: `${p.firstName} ${p.lastName}`,
                                                      };
                                                })
                                              );
                                            }
                                          : null
                                      }
                                      // {value: row.original[9], label: row.original[9]}
                                      className="flex-1"
                                      styles={{
                                        menuPortal: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      // noOptionsMessage={() => "No result found"}
                                      // isValidNewOption={() => false}
                                      onChange={(e) => {
                                        saveAssignee(e, row.original);
                                      }}
                                      placeholder="Select Assignee"
                                      isMulti
                                      menuPlacement="top"
                                      menuPortalTarget={document.body}
                                    />
                                    <Button
                                      size={14}
                                      variant="secondary-l"
                                      className="ml-1 font-bold self-end aspect-square"
                                      onClick={() => {
                                        setAssigneeId("");
                                        setShowAssignee(false);
                                      }}
                                    >
                                      <AiOutlineCheck />
                                    </Button>
                                    {/* <BiPencil className="w-6 mt-2"/> */}
                                  </>
                                ) : (
                                  <p
                                    className="font-sans text-gray-900 ml-2 flex-1"
                                    onClick={() => {
                                      if (userType == "OWNER") {
                                        setShowAssignee(true);
                                        setAssigneeId(row.original.id);
                                      } else if (
                                        row.original.hasOwnProperty(9)
                                      ) {
                                        let assignees =
                                          row.original[9].split(",");
                                        if (
                                          isCurrentUserAssigned(assignees) ||
                                          row.original[9].length == 0
                                        ) {
                                          setShowAssignee(true);
                                          setAssigneeId(row.original.id);
                                        }
                                      }
                                    }}
                                  >
                                    {row.original[9]
                                      .split(",")
                                      .map((x, index) => {
                                        const assignee = parsePeople(x);

                                        var assigneeInitials = "";
                                        if (
                                          x.toLowerCase() ===
                                          "dane mariel sta. ana"
                                        ) {
                                          assigneeInitials = "DM";
                                        } else if (
                                          x.toLowerCase() === "john dela cruz"
                                        ) {
                                          assigneeInitials = "JDC";
                                        } else if (
                                          typeof assignee === "string"
                                        ) {
                                          return;
                                        } else {
                                          assigneeInitials += assignee.firstName
                                            ? assignee.firstName?.charAt(0)
                                            : "";
                                          assigneeInitials += assignee.lastName
                                            ? assignee.lastName?.charAt(0)
                                            : "";
                                        }

                                        return (
                                          assignee && (
                                            <div className="flex" key={index}>
                                              <div
                                                className="flex bg-gray-500 text-white w-5 h-5 mb-1 rounded-full text-md font-medium justify-center items-center"
                                                data-tip={
                                                  assignee.firstName +
                                                  " " +
                                                  assignee.lastName
                                                }
                                              >
                                                {assignee.profilePicture ===
                                                null ? (
                                                  <p className="w5- h-5">
                                                    {assigneeInitials.toUpperCase()}
                                                  </p>
                                                ) : (
                                                  <img
                                                    className="rounded-full w-5 h-5"
                                                    src={
                                                      assignee.profilePicture
                                                    }
                                                  />
                                                )}
                                              </div>
                                              <p className="ml-2 font-sans text-gray-900">
                                                {" " +
                                                  assignee.firstName +
                                                  " " +
                                                  assignee.lastName}
                                              </p>
                                              <ReactTooltip
                                                globalEventOff="scroll"
                                                scrollHide
                                              />
                                            </div>
                                          )
                                        );
                                      })}
                                    {/* {row.original[9] ? row.original[9] : "none"} */}
                                  </p>
                                )}
                              </div>

                              {/* status */}
                              <div className="flex items-center mt-1">
                                <BsGraphUpArrow className="m-1 w-6" size={16} />
                                {showStatus === true &&
                                statusId === row.original.id ? (
                                  // status dropdown
                                  <>
                                    <CreatableSelect
                                      options={[
                                        {
                                          label: "Completed",
                                          value: "Completed",
                                        },
                                        {
                                          label: "Outstanding",
                                          value: "Outstanding",
                                        },
                                        {
                                          label: "Follow Up",
                                          value: "Follow Up",
                                        },
                                      ]}
                                      defaultValue={{
                                        label: row.original[7],
                                        value: row.original[7],
                                      }}
                                      className="flex-1"
                                      styles={{
                                        menuPortal: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      noOptionsMessage={() => "No result found"}
                                      isValidNewOption={() => false}
                                      onChange={(e) => {
                                        saveStatus(e, row.original);
                                      }}
                                      placeholder="Select Status"
                                      menuPlacement="top"
                                      menuPortalTarget={document.body}
                                    />
                                    <Button
                                      size={16}
                                      variant="secondary-l"
                                      className="ml-1 font-bold self-end aspect-square"
                                      onClick={() => {
                                        setStatusId("");
                                        setShowStatus(false);
                                      }}
                                    >
                                      <AiOutlineCheck />
                                    </Button>
                                    {/* <BiPencil className="w-6 mt-2"/> */}
                                  </>
                                ) : showPriority === true &&
                                  priorityId === row.original.id ? (
                                  // priority dropdown
                                  <>
                                    <CreatableSelect
                                      options={[
                                        { label: "1", value: "1" },
                                        { label: "2", value: "2" },
                                        { label: "3", value: "3" },
                                      ]}
                                      defaultValue={
                                        row.original[8]
                                          ? [
                                              {
                                                label: row.original[8],
                                                value: row.original[8],
                                              },
                                            ]
                                          : [
                                              {
                                                label: row.original[8],
                                                value: row.original[8],
                                              },
                                            ]
                                      }
                                      className="flex-1"
                                      styles={{
                                        menuPortal: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      noOptionsMessage={() => "No result found"}
                                      isValidNewOption={() => false}
                                      onChange={(e) => {
                                        savePriority(e, row.original);
                                        updatePriorityValue(
                                          row.original[8],
                                          row.original
                                        );
                                      }}
                                      placeholder="Select Priority"
                                      menuPlacement="top"
                                      menuPortalTarget={document.body}
                                    />
                                    <Button
                                      size={16}
                                      variant="secondary-l"
                                      className="ml-1 font-bold self-end aspect-square"
                                      onClick={() => {
                                        setPriorityId("");
                                        setShowPriority(false);
                                      }}
                                    >
                                      <AiOutlineCheck />
                                    </Button>
                                  </>
                                ) : (
                                  <div
                                    className={
                                      "font-sans text-gray-900 ml-2 flex"
                                    }
                                  >
                                    {/* status text */}
                                    <p
                                      onClick={() => {
                                        if (userType == "OWNER") {
                                          setShowStatus(true);
                                          setStatusId(row.original.id);
                                        } else if (
                                          row.original.hasOwnProperty(9)
                                        ) {
                                          let assignees =
                                            row.original[9].split(",");
                                          if (
                                            isCurrentUserAssigned(assignees) ||
                                            row.original[9].length == 0
                                          ) {
                                            setShowStatus(true);
                                            setStatusId(row.original.id);
                                            setPriorityId("");
                                            setShowPriority(false);
                                          }
                                        }
                                      }}
                                      className={
                                        row?.original[7] === "Outstanding"
                                          ? "font-sans border border-orange-300 text-orange-500 rounded-xl bg-orange-100 px-1"
                                          : row?.original[7] === "Completed"
                                          ? "font-sans border border-green-300 text-green-500 rounded-xl bg-green-100 px-1"
                                          : row?.original[7] === "Follow Up"
                                          ? "font-sans border border-blue-300 text-blue-500 rounded-xl bg-blue-100 px-1"
                                          : "font-sans border border-gray-300 text-gray-500 rounded-xl bg-gray-100 px-1 "
                                      }
                                    >
                                      {row.original[7]}
                                    </p>

                                    {/* priority text */}
                                    <p
                                      className="flex font-sans  ml-2 flex-1 items-center gap-1"
                                      onClick={() => {
                                        if (userType == "OWNER") {
                                          setShowPriority(true);
                                          setPriorityId(row.original.id);
                                        } else if (
                                          row.original.hasOwnProperty(9)
                                        ) {
                                          let assignees =
                                            row.original[9].split(",");
                                          if (
                                            isCurrentUserAssigned(assignees) ||
                                            row.original[9].length == 0
                                          ) {
                                            setShowPriority(true);
                                            setPriorityId(row.original.id);
                                            setStatusId("");
                                            setShowStatus(false);
                                          }
                                        }
                                        console.log(row.original[8]);
                                      }}
                                      onChange={() => {
                                        console.log(row.original);
                                      }}
                                    >
                                      <span className="font-sans text-gray-500 border border-gray-300 bg-gray-100 rounded-xl px-1 flex">
                                        P{row.original[8]}
                                        {row.original[8] === "1" ||
                                        row.original[8] === 1 ? (
                                          <HiOutlineChevronDoubleUp className="text-danger" />
                                        ) : row.original[8] === "2" ||
                                          row.original[8] === 2 ? (
                                          <HiOutlineChevronUp className="text-orange-400" />
                                        ) : row.original[8] === "3" ||
                                          row.original[8] === 3 ? (
                                          <HiOutlineMenuAlt4 className="text-warning" />
                                        ) : row.original[8] === "4" ||
                                          row.original[8] === 4 ? (
                                          <HiOutlineChevronDown className="text-gray-500" />
                                        ) : row.original[8] === "5" ||
                                          row.original[8] === 5 ? (
                                          <HiOutlineChevronDoubleDown className="text-gray-300" />
                                        ) : row.original[8] === "" ||
                                          row.original[8] === "" ? (
                                          <HiOutlineMinus className="text-gray-500" />
                                        ) : null}
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>

                              {/* Task Registers */}
                              <div className="flex items-end mt-1">
                                <BsTags
                                  className="m-1 w-6 self-center"
                                  size={16}
                                />
                                {showTaskRegisters === true &&
                                taskRegistersId === row.original.id ? (
                                  <>
                                    <CreatableSelect
                                      options={taskRegistersOptions}
                                      noOptionsMessage={() =>
                                        "No Task Registers"
                                      }
                                      className="flex-1 mt-1"
                                      styles={{
                                        menuPortal: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      isMulti
                                      defaultValue={row.original.registers}
                                      onChange={(e, action) => {
                                        console.log("AACTIOON", action);
                                        updateTaskRegisters(
                                          undefined,
                                          row.original,
                                          e
                                        );
                                      }}
                                      placeholder="Select Task Register"
                                      menuPlacement="top"
                                      menuPortalTarget={document.body}
                                    />
                                    <Button
                                      size={16}
                                      variant="secondary-l"
                                      className="ml-1 mt-2 font-bold self-end aspect-square"
                                      onClick={() => {
                                        setTaskRegistersId("");
                                        setShowTaskRegisters(false);
                                      }}
                                    >
                                      <AiOutlineCheck />
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="flex flex-1 gap-1 ml-2"
                                      onClick={() => {
                                        if (userType == "OWNER") {
                                          setShowTaskRegisters(true);
                                          setTaskRegistersId(row.original.id);
                                        } else if (
                                          row.original.hasOwnProperty(9)
                                        ) {
                                          let assignees =
                                            row.original[9].split(",");
                                          if (
                                            isCurrentUserAssigned(assignees) ||
                                            row.original[9].length == 0
                                          ) {
                                            setShowTaskRegisters(true);
                                            setTaskRegistersId(row.original.id);
                                          }
                                        }
                                      }}
                                    >
                                      {row.original.registers?.length > 0 ? (
                                        row.original.registers.map(
                                          (register) => (
                                            <div
                                              className="font-sans border border-gray-300 text-gray-500 rounded-xl bg-gray-100 px-1"
                                              key={register?.label}
                                            >
                                              {register?.label}
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <p className="font-sans text-gray-900">
                                          No Task Register
                                        </p>
                                      )}
                                      <div className="font-sans font-bold border border-gray-300 text-gray-500 rounded-xl bg-gray-100 px-2">
                                        <HiOutlinePlus
                                          size={14}
                                          className="mt-1"
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
                <>
                  {/* end of mobile view */}

                  {width > 844 && (
                    <>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <>
                            <td key={cell.id} className={`align-top h-max`}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              </tr>
            );
          })}
          <tr>
            <td style={{ height: `${paddingBottom}px` }} />
          </tr>
        </tbody>
      </table>

      <div
        className="w-full flex gap-2 fixed bottom-0 text-center bg-white font-medium p-2 items-center justify-center z-10"
        style={{
          height: "50px",
          lineHeight: "50px",
          bottom: !showCopy ? "0" : "-50px",
          transition: "all 0.2s ease",
        }}
      >
        <button
          className=" bg-blue-400 hover:bg-blue-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring"
          onClick={() => undoAllDetails()}
        >
          Undo
        </button>
        <button
          type="button"
          className={
            pastedIds.length == 0
              ? "w-full  bg-red-400 hover:bg-red-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring disabled:opacity-25"
              : "w-full  bg-green-400 hover:bg-green-500 text-white text-sm py-2 px-4 rounded items-center border-0 shadow outline-none focus:outline-none focus:ring"
          }
          onClick={() => {
            setCopyAssignee("");
            setCopyId("");
            setCopyDesc("");
            setShowCopy(true);
            setPastedIds([]);
          }}
        >
          {pastedIds.length == 0
            ? "Cancel Copying"
            : pastedIds.length == 1
            ? "Paste to 1 item"
            : "Paste to " + pastedIds.length + " items"}
        </button>
      </div>

      {show && (
        <MobileEmailPreview
          show={show}
          setShow={setShow}
          snippetId={snippetId}
          gmailMessageId={snippetId}
          preview={preview}
          from={from}
          dateEmail={dateEmail}
          subject={subject}
          to={to}
          cc={cc}
          handleClose={handleClose}
          handleDownloadLink={handleDownloadLink}
          id={id}
          getAttachments={getAttachments}
          attachments={attachments}
          handleDownloadAttachment={handleDownloadAttachment}
        />
      )}

      {ShowModal && (
        <AddClientMatter
          close={() => setShowModal(false)}
          fetchCompanyClientMatters={fetchCompanyClientMatters}
          clientMatters={clientMatters}
        />
      )}

      {ShowModalEmail && (
        <EmailModal
          close={() => setShowModalEmail(false)}
          emailPreview={emailPreview}
        />
      )}
    </div>
  );
};

export default EditableCell;
