import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import ToastNotification from "../toast-notification";
import Loading from "../loading/loading";
import CreatableSelect from "react-select/creatable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useRootClose } from "react-overlays";
import { FaEye } from "react-icons/fa";
import { BsFillCircleFill } from "react-icons/bs";
import { GrAttachment } from "react-icons/gr";
import { CiCircleAlert } from "react-icons/ci";
import { AiFillCheckCircle, AiFillDatabase } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { Base64 } from "js-base64";
import EmailParser from "../../shared/EmailParser";
import html2pdf from "html2pdf.js";
import googleLogin from "../../assets/images/gmail-print.png";
import { MdArrowBackIos } from "react-icons/md";
import { MdClose, MdDescription, MdOutlineCancel } from "react-icons/md";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import Select from "react-dropdown-select";
import { BsPeopleFill } from "react-icons/bs";
import { RiNumbersFill } from "react-icons/ri";
import ActionButtons from "./action-buttons";
import arrowUp from "../../assets/images/arrow-up.svg";
import arrowDown from "../../assets/images/arrow-down.svg";
import { dateTimeNoSecondsFormatter } from "../../shared/userPreferredDateTime";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  WindowScroller,
} from "react-virtualized";
import {
  timeFormatter,
  dateFormatter,
} from "../../shared/userPreferredDateTime";
import isMSdoc from "../../shared/msFileTypeChecker";
import { useMsal } from "@azure/msal-react";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";
var moment = require("moment");

const mUpdateAttachmentDescription = `mutation MyMutation($details: String, $id: ID) {
    gmailMessageAttachmentUpdate(id: $id, details: $details) {
      id
      details
    }
  }`;

const mTagEmailClientMatter = `
  mutation tagGmailMessageClientMatter($clientMatterId: ID, $gmailMessageId: String) {
    gmailMessageClientMatterTag(
      clientMatterId: $clientMatterId
      gmailMessageId: $gmailMessageId
    ) {
      id
    }
  }`;

const mUpdateRowDescription = `mutation saveGmailDescription($id: String, $description: String) {
  gmailMessageUpdate(id: $id, description: $description) {
    id
  }
}`;

const qGetFileDownloadLink = `
query getAttachmentDownloadLink($id: String) {
  gmailAttachment(id: $id) {
    downloadURL
    s3ObjectKey
    type
  }
}`;

const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
  gmailMessage(id: $id) {
    payload {
      content
    }
  }
}
`;

const qGetTeams = `
  query getTeamsByCompany($id: String) {
    company(id: $id) {
      teams {
        items {
          id
          name
        }
      }
    }
  }
    `;

const COMPANY_USERS_QUERY = `
  query companyUsers($id: String!){
    company(id: $id){
      users {
        items {
          id
          firstName
          lastName
          profilePicture
        }
      }
    }
  }
  `;

const qGetTeamsWithMembers = `
  query getTeamMembers($id: ID) {
    team(id: $id) {
      id
      name
      members {
        items {
          user {
            id
            firstName
            lastName
            userType
            profilePicture
          }
        }
      }
    }
  }`;

// OUTLOOK QUERIES AND MUTATIONS

const mUpdateAttachmentDescriptionOutlook = `mutation updateOutlookAttachment($details: String, $id: ID) {
  outlookMessageAttachmentUpdate(id: $id, details: $details) {
    id
    details
  }
}`;

const mTagOutlookEmailClientMatter = `mutation tagOutlookMessageClientMatter($clientMatterId: ID, $outlookMessageId: String) {
  outlookMessageClientMatterTag(
    clientMatterId: $clientMatterId
    outlookMessageId: $outlookMessageId
  ) {
    id,
  }
}`;

const mUpdateRowDescriptionOutlook = `mutation saveOutlookDescription($id: String, $description: String) {
  outlookMessageUpdate(id: $id, description: $description) {
    id
  }
}`;

const qOutlookGetFileDownloadLink = `query getOutlookAttachmentDownloadLink($id: String) {
  outlookAttachment(id: $id) {
    downloadURL
    s3ObjectKey
    type
  }
}`;

const qOutlookGetMessagePayload = `query qOutlookGetMessagePayload($id: ID) {
  outlookMessage(id: $id) {
    payload {
      content
    }
    threadId
  }
}`;

const TableSavedInfo = ({
  taskListColumns,
  vTablePaddingTop,
  vTablePaddingBottom,
  virtualRows,
  rowVirtualizer,
  rows,
  totalSize,
  tableContainerRef,
  selectedSavedItems,
  setSelectedSavedItems,
  savedEmails,
  setSavedEmails,
  matterList,
  maxLoadingSavedEmail,
  waitSaved,
  selectedTaskList,
  setSelectedTaskList,
  setshowAddContactModal,
  tasklistGmailIds,
  setTasklistGmailIds,
  show,
  saveLoading,
  setShow,
  setShowToast,
  setResultMessage,
  setEmailContent,
  setShowPreview,
  setEmailInfo,
  setSavedForTaskListCtr,
  isOutOfSync,
  mobileLoading,
  setMobileLoading,
  taskRegisterList,
  isOutlookIntegrated,

  emailFilters,
  searchGmail,
  setShowCopy,
  showDetailsCopy,
  setShowDetailsCopy,
  showCopy,
  selectedTaskRegister,
  setSelectedTaskRegister,
  displayImageAttachments,
  setSaveLoading,
  sortByDate,
  archivedEmails,
  setArchivedEmails,
  unSavedEmails,
  setUnsavedEmails,
  getUnSavedEmails,
  selectedUnsavedItems,
  setSelectedUnsavedItems,
  selectedArchivedItems,
  setSelectedArchivedItems,

  unSavedForTaskListCtr,
  savedForTaskListCtr,
  getArchivedEmails,
  setDoneAllocatingEmails,
  setpendingMessage,
  setPreviousUnsavedEmails,
  setPreviousSavedEmails,
  setPreviousArchivedEmails,
  setSearchGmail,
  selectAllArchived,
  selectAllSaved,
  selectAllUnsaved,
  setDisplayImageAttachments,
  selectAllCheck,
  setSelectAllCheck,
  handleExecuteFilter,
  getSavedEmails,
  openTab,
  fetchData,
  setWaitUnSaved,
  setWaitSaved,
  setWaitArchived,
  setOpenTab,

  saveProgress,
  setSaveProgress,

  setUnsavedEmailsCount,
  setSavedEmailsCount,
  setArchivedEmailsCount,
  Highlight,
}) => {
  //Outlook integration
  const { accounts } = useMsal();

  useEffect(() => {
    /* Creating an array of all the elements with the class name 'saved-for-tasklist' */
    const forTaskListCheckboxClass = Array.from(
      document.getElementsByClassName("saved-for-tasklist")
    );

    let count = 0;
    forTaskListCheckboxClass.forEach((checkbox) => {
      if (checkbox.checked && !checkbox.disabled) count += 1;
    });

    setSavedForTaskListCtr(count);
  });

  const ref = useRef([]);
  const [snippetId, setSnippetId] = useState(null);
  const [emailPreview, setEmailPreview] = useState(null);
  const handleRootClose = () => {
    setShow(false);
    setSnippetId(null);
    setShowIndex(-1);
    setEmailPreview(null);
  };

  const [selectedClientMatter, setSelectedClientMatter] = useState();
  const [isShiftDown, setIsShiftDown] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const [emailHTML, setEmailHTML] = useState("");

  const companyId = localStorage.getItem("companyId");

  const [ShowIndex, setShowIndex] = useState(-1);

  // const { height, width } = useWindowDimensions();
  var width = window.innerWidth;
  var height = window.innerHeight;
  const [heightOffset, setHeightOffset] = useState(160); // CHANGE DEFAULT VALUE IF FRONTEND CHANGES
  const detailsDivRef = useRef(null);

  const openScreenSize = height - 180;
  const [forcescrollTop, setForceScrollTop] = useState();

  const [people, setPeople] = useState([]);
  const [showNote, setShowNote] = useState(false);
  const [noteId, setNoteId] = useState("");
  //Add tasklist button
  const [clicked, setClicked] = useState(true);
  const handleClick = () => {
    setClicked(!clicked);
  };

  const customAssigneePlaceholder = (
    <div style={{ display: "flex" }}>
      <BsFillPeopleFill />
      <span style={{ marginLeft: "10px" }}>Assignee</span>
    </div>
  );

  const customPriorityPlaceholder = (
    <div style={{ display: "flex" }}>
      <CiCircleAlert />
      <span style={{ marginLeft: "10px" }}>Priority</span>
    </div>
  );

  const [saved, setSaved] = useState(false);
  const [showRemoveButton, setShowRemoveButton] = useState(false);

  const [selectSavedEmailsDetails, setSelectSavedEmailsDetails] = useState([]);
  const [selectedSavedIndex, setSelectedSavedIndex] = useState(null);

  function handleRemove() {
    // Perform remove logic here
    setShowRemoveButton(false); // Hide the "Remove to TaskList" button
  }

  function handlePop() {
    //handleSave
    // Perform save logic here
    setShowRemoveButton(true); // Show the "Remove to TaskList" button
  }

  function saveButton() {
    handlePop();
    handleSave();
  }

  const handleSave = () => {
    setSaved(true); // Set saved state to true
  };

  const [emailContentOpen, setEmailContentOpen] = useState(false);
  const [loaderEmail, setLoaderEmail] = useState(null);

  const [clientMatterState, setClientMatterState] = useState([]);
  const [prompt, setPrompt] = useState("");

  const handleSavePromptDesc = async (id, val, index) => {
    const data = {
      id: id,
      description: val,
    };
    const success = await updateRowDesc(data);
    if (success) {
      setResultMessage("Successfully updated.");
      setShowToast(true);

      const newArrDescription = savedEmails.map((emails) => {
        if (emails.id === id) {
          return { ...emails, description: val };
        }

        return emails;
      });
      setSavedEmails(newArrDescription);
      setMobileLoading(false);
      autoAdjustRowHeight(index);
      setLoaderEmail(null);
    }
  };

  const handleOpenAI = async (id, index) => {
    const fwdEmail = "---------- Forwarded message ---------";
    let promptEmail = convertHTMLEntity(prompt);
    // if (promptEmail.includes(fwdEmail)) {
    //   promptEmail = promptEmail.substring(0, promptEmail.indexOf(fwdEmail));
    // }

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 2200;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (promptEmail !== "") {
      setLoaderEmail(id);
      // Use template literals for instruction
      const instruction = `Summarize the email from a third-person point of view in one paragraph, with a maximum of 10 sentences, while excluding the signature of the original sender: \n\n """\n${promptEmail}\n"""`;
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        // Destructure choices directly in the function call
        handleSavePromptDesc(
          id,
          (await response.json()).choices[0]?.message?.content
            ?.trim()
            .replace(/(\n)+$/, "")
            .replace(/\s+/g, " "),
          index
        );
      } catch (error) {
        console.error("Error", error);
      } finally {
        setLoaderEmail(null);
      }
    } else {
      setLoaderEmail(null);
    }
  };

  const convertHTMLEntity = function (text) {
    const sp = document.createElement("span");

    if (!text) return "";

    const output = text
      .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        sp.innerHTML = entity;
        return sp.innerText;
      })
      .replace(/<br ?\/?>/g, "\n")
      .replace(/(<([^>]+)>)/gi, "");

    return output;
  };

  useEffect(() => {
    autoAdjustAllRow();
  }, [selectedSavedItems]);

  const bindList = useRef(null);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  const handleMobileSnippet = async (msgId, index) => {
    setShowPreview(true);
    const messageId = msgId.id;
    setSnippetId(messageId);
    const msgPreview = await getEmailPayload(messageId);
    setEmailInfo(msgId);
    setEmailContent(msgPreview);
    const parsedEmail = EmailParser(JSON.parse(msgPreview.payload[0].content));
    setPrompt(parsedEmail.html);
  };

  const handleSnippet = async (msgId, index) => {
    setShow(true);
    if (width <= 844) {
      setShowPreview(true);
    }
    const messageId = msgId.id;
    setSnippetId(messageId);
    const msgPreview = await getEmailPayload(messageId);
    const parsedEmail = EmailParser(JSON.parse(msgPreview.payload[0].content));

    const modifiedEmailContent = await replaceCidWithUrl(
      parsedEmail.html,
      selectSavedEmailsDetails?.attachments?.items
    );

    if (messageId) {
      if (width > 844) {
        setEmailPreview(msgPreview);
        setShowIndex(index);
        setPrompt(modifiedEmailContent);
      } else {
        setEmailInfo(msgId);
        setEmailContent(msgPreview);
        setPrompt(modifiedEmailContent);
      }
    }
  };

  // Use the shared EmailParser function ../../shared/EmailParser
  /**
  function EmailParser(response) {
    function decode(input) {
      // const text = new Buffer.from(input, 'base64').toString('ascii')
      // return decodeURIComponent(escape(text))
      return Base64.decode(input.replace(/-/g, "+").replace(/_/g, "/"));
    }

    function decode_alternative(input) {
      // this way does not escape special "B" characters
      // const text = Buffer.from(input, 'base64').toString('ascii')
      // return decodeURIComponent(escape(text))

      return Base64.decode(input.replace(/-/g, "+").replace(/_/g, "/"));
    }

    const result = {
      text: "",
      html: "",
      attachments: [],
    };

    let parts = [response];

    while (parts.length) {
      let part = parts.shift();

      if (part.parts) parts = parts.concat(part.parts);

      if (part.mimeType === "text/plain") result.text = decode(part.body.data);

      if (part.mimeType === "text/html") result.html = decode(part.body.data);

      if (part.body.attachmentId) {
        result.attachments.push({
          partId: part.partId,
          mimeType: part.mimeType,
          filename: part.filename,
          body: part.body,
        });
      }
    }

    return result;
  }
  */

  function hasContactCheck(item) {
    const contacts = clientMatterState;
    let emailOnly = item.from.substring(
      item.from.indexOf("<") + 1,
      item.from.lastIndexOf(">")
    );
    let contactFound = false;
    contacts.map((item, i) => {
      if (contacts[i].email.includes(emailOnly)) {
        contactFound = true;
      }
    });
    return contactFound;
  }

  const getInlineAttachmentUrl = async (id) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetFileDownloadLink
        : qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    const { data } = await API.graphql(params);

    const response = isOutlookIntegrated
      ? data?.outlookAttachment?.downloadURL
      : data?.gmailAttachment?.downloadURL;

    return response;
  };

  const removeAttachmentPartIds = (input) => {
    const lastDashIndex = input.lastIndexOf("-");
    const lastDotIndex = input.lastIndexOf(".");
    if (
      lastDashIndex !== -1 &&
      lastDotIndex !== -1 &&
      lastDashIndex < lastDotIndex
    ) {
      const beforeDash = input.substring(0, lastDashIndex);
      const afterDot = input.substring(lastDotIndex + 1);
      return beforeDash + "." + afterDot;
    }
    return input;
  };

  const replaceCidWithUrl = async (content, attachments) => {
    const mapping = []; // Array to store the mapping of attachment URLs and names

    if (attachments) {
      for (const attachment of attachments) {
        // Get the inline attachment URL asynchronously
        const url = await getInlineAttachmentUrl(attachment.id);

        // Add the URL and name to the mapping array
        mapping.push({
          url: url,
          name: attachment.isInline
            ? attachment.s3ObjectKey.substring(
                attachment.s3ObjectKey.lastIndexOf("/") + 1
              )
            : removeAttachmentPartIds(attachment.name),
        });
      }
    }

    let modifiedContent = content; // Variable to store the modified content

    if (isOutlookIntegrated) {
      mapping.forEach((item) => {
        const regexSrc = new RegExp(`src="cid:${item.name}"`, "g");
        modifiedContent = modifiedContent.replace(
          regexSrc,
          `src="${item.url}" alt="${item.name}`
        );
      });
    } else {
      mapping.forEach((item) => {
        // const srcRegex = new RegExp(`src="cid:${item.name}"`, "g");
        const regexSrc = new RegExp(`src="cid:[^"]*?" alt="${item.name}"`, "g");
        const regexAlt = new RegExp(`alt="${item.name}"`, "g");
        // Remove the original src attribute value
        // modifiedContent = modifiedContent.replace(srcRegex, "");
        // const altRegex = new RegExp(`alt="${item.name}"`, "g");
        // Replace the alt attribute with the new src attribute and the corresponding URL and name
        modifiedContent = modifiedContent.replace(
          regexSrc,
          `src="${item.url}"`
        );
        modifiedContent = modifiedContent.replace(
          regexAlt,
          `alt="${item.name}"`
        );
      });
    }
    // console.log("emailProc modifiedContent is: \n" + modifiedContent)
    return modifiedContent; // Return the modified content
  };

  // Handling email content to remove unsupportive characters
  useEffect(() => {
    async function setEmailContent() {
      if (!emailPreview) return;

      const parsedEmail = EmailParser(
        JSON.parse(emailPreview.payload[0].content)
      );

      const modifiedEmailContent = await replaceCidWithUrl(
        parsedEmail.html,
        selectSavedEmailsDetails?.attachments?.items
      );

      setEmailHTML(modifiedEmailContent);

      // const messageIdHeader = JSON.parse(emailPreview.payload[0]?.headers ?? "[]")
      //   .find(header => header.name.toLowerCase() === "message-id");
      // if (messageIdHeader) {
      //   setMessageId(messageIdHeader.value);
      // }
    }

    setEmailContent();
  }, [emailPreview]);

  const handleAddContact = (msgId, index) => {
    setEmailInfo(msgId);
    setshowAddContactModal(true);
  };

  const getEmailPayload = async (messageId) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetMessagePayload
        : qGetMessagePayload,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);
    let newPayload = { payload: [{ content: "" }] };

    const message = isOutlookIntegrated
      ? result?.data?.outlookMessage
      : result?.data?.gmailMessage;

    message.payload?.forEach((data, index) => {
      newPayload = {
        payload: [
          {
            content: newPayload.payload[0].content + data?.content,
          },
        ],
      };
    });

    return newPayload;
  };

  useRootClose(ref, handleRootClose, {
    disabled: !show,
  });

  const handleKeyUp = (e) => {
    if (e.key === "Shift" && isShiftDown) {
      setIsShiftDown(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Shift" && !isShiftDown) {
      setIsShiftDown(true);
    }
  };

  const autoAdjustAllRow = async () => {
    try {
      if (bindList && cache) {
        cache?.current.clearAll();
        //while loop is recommended fastest loop

        var i = 0,
          len = savedEmails.length;
        while (i < len) {
          setTimeout(() => {
            bindList?.current?.recomputeRowHeights(len);
          }, 100);

          i++;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const autoAdjustRowHeight = (index) => {
    //bindList and cache must not be null
    if (bindList && cache) {
      cache?.current.clear(index);
      bindList?.current?.recomputeRowHeights(index);
      console.log("success readjust");
    } else {
      console("List reference not found || cache not found!");
    }
    // if (width < 844 && searchGmail && (searchGmail !== null || "")) {
    //   filterRecord(searchGmail)
    // }
  };

  useEffect(() => {
    autoAdjustAllRow();
  }, [savedEmails]);

  function handleDescription(description) {
    if (description) {
      var parsedDescription = description.replace(
        /style="[^\"]*"/gi,
        `class="break-words whitespace-normal"`
      );
      parsedDescription = parsedDescription.replace(
        /<[div]+/g,
        `<div class="break-words whitespace-normal"`
      );
      parsedDescription = parsedDescription.replace(
        /<[span]+/g,
        `<span class="break-words whitespace-normal"`
      );
      parsedDescription = parsedDescription.replace(
        /<[p ]+/g,
        `<p class="break-words whitespace-normal"`
      );
      parsedDescription = parsedDescription.replace(
        /<[font ]+/g,
        `<p class="break-words whitespace-normal"`
      );
      return parsedDescription;
    }
  }

  /*const handleSelectItem = (e, position) => {
    const { value } = e.target;
    const checkedId = selectedSavedItems.some((x) => x.id === value);

    if (!checkedId && e.target.checked) {
      const x = selectedSavedItems;
      x.push({ id: value });
      setSelectedSavedItems(x);

      const updatedCheckedState = selectedSavedItems.map((item, index) =>
        index === position ? !item : item
      );
      setSelectedSavedItems(updatedCheckedState);
    } else {
        setSelectedSavedItems([]);
    }
  }*/

  const handleSelectItem = (e) => {
    const defaultRegister = taskRegisterList.filter(
      (register) =>
        register?.label?.toLowerCase().replace(/ /g, "") === "tasklist"
    );
    console.log("Default Register", defaultRegister);
    console.log("items", savedEmails);
    const { id, checked } = e.target;
    setSelectedSavedItems([...selectedSavedItems, id]);
    if (!checked) {
      setSelectedSavedItems(selectedSavedItems.filter((item) => item !== id));
    }
  };

  const handleClientMatterChanged = (newValue) => {
    console.log(newValue);
  };

  const hideToast = () => {
    setShowToast(false);
  };

  const handleSaveDesc = async (e, id) => {
    const data = {
      id: id,
      description: e.target.innerHTML,
    };
    console.log("POKS HANDLE SAVE DESC" + JSON.stringify(data));
    const success = await updateAttachmentDesc(data);
    if (success) {
      setResultMessage("Successfully updated.");
      setShowToast(true);
    }
  };

  async function updateAttachmentDesc(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: isOutlookIntegrated
            ? mUpdateAttachmentDescriptionOutlook
            : mUpdateAttachmentDescription,
          variables: {
            id: data.id,
            details: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const handleSaveMainDesc = async (e, id, rowId) => {
    const data = {
      id: id,
      description: e.target.innerHTML,
    };
    const success = await updateRowDesc(data);
    if (success) {
      setResultMessage("Successfully updated.");
      setShowToast(true);

      const newArrDescription = savedEmails.map((emails) => {
        if (emails.id === id) {
          return { ...emails, description: e.target.innerHTML };
        }

        return emails;
      });
      setSavedEmails(newArrDescription);
      setMobileLoading(false);
      autoAdjustRowHeight(rowId);
    }
  };

  async function updateRowDesc(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: isOutlookIntegrated
            ? mUpdateRowDescriptionOutlook
            : mUpdateRowDescription,
          variables: {
            id: data.id,
            description: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const handleClientMatter = async (e, gmailMessageId) => {
    const request = API.graphql({
      query: isOutlookIntegrated
        ? mTagOutlookEmailClientMatter
        : mTagEmailClientMatter,
      variables: {
        clientMatterId: e.value,
        gmailMessageId: gmailMessageId,
      },
    });
  };

  /**
  const handleDownload = async (gmailMessageId, subject) => {
    var opt = {
      margin: [30, 30, 30, 30],
      filename: subject,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        dpi: 96,
        scale: 1,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: "#FFF",
      },
      jsPDF: { unit: "pt", format: "a4", orientation: "p" },
      pagebreak: { before: ".page-break", avoid: "img" },
    };

    const emailPayload = await getEmailPayload(gmailMessageId);

    if (emailPayload) {
      const parsedEmail = EmailParser(
        JSON.parse(emailPayload.payload[0].content)
      );
      const htmlContent = parsedEmail.html;
      // const htmlContent = emailPayload.payload
      //   .map((em) => em.content)
      //   .join("")
      //   .split('data":"')
      //   .pop()
      //   .split('"}')[0];

      var content = document.getElementById("preview_" + gmailMessageId);
      // content.innerHTML += Base64.decode(htmlContent).replace(
      //   "body{color:",
      //   ""
      // );
      content.innerHTML += htmlContent;

      html2pdf()
        .set(opt)
        .from(content)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          var i = 0;

          for (i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              pdf.internal.pageSize.width - 100,
              pdf.internal.pageSize.height - 30,
              "Page " + i + " of " + totalPages
            );
          }
          window.open(pdf.output("bloburl"), "_blank");
        });
    }
  };
  */

  const previewAndDownloadFile = async (id) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetFileDownloadLink
        : qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const attachment = isOutlookIntegrated
        ? result.data.outlookAttachment
        : result.data.gmailAttachment;
      const { type, downloadURL, s3ObjectKey } = attachment;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  async function fetchCompanyUsers() {
    var assignees = [];
    if (companyId === "7a7d3c83-4cb3-49cb-acef-82b7b71054eb") {
      //lop company id
      //master companyid
      const res = await API.graphql({
        query: qGetTeams,
        variables: {
          id: companyId,
        },
      });

      var teamNames = res.data.company.teams.items;
      var specialTeamId = "";

      /* Finding the team id of the team named "Operations" */
      for (var i = 0; i < teamNames.length; i++) {
        if (teamNames[i].name?.toLowerCase() === "operations") {
          specialTeamId = teamNames[i].id;
          break;
        } else {
          specialTeamId = "0"; //placeholder
        }
      }

      if (specialTeamId === "0") {
        const { data } = await API.graphql({
          query: COMPANY_USERS_QUERY,
          variables: {
            id: companyId,
          },
        });

        data.company.users.items.map(
          (x) =>
            (assignees = [
              ...assignees,
              {
                label: `${x.firstName} ${x.lastName}`,
                value: `${x.firstName} ${x.lastName}`,
              },
            ])
        );

        setPeople(assignees); //set regular list as options, null catcher
        console.log("peoplelist", assignees);
      } else {
        const res1 = await API.graphql({
          query: qGetTeamsWithMembers,
          variables: {
            id: specialTeamId,
          },
        });

        var members = res1.data.team.members.items;

        for (var j = 0; j < members.length; j++) {
          assignees = [
            ...assignees,
            {
              label: `${members[j].user.firstName} ${members[j].user.lastName}`,
              value: `${members[j].user.firstName} ${members[j].user.lastName}`,
            },
          ];
        }

        setPeople(assignees); //filtered people
        console.log("peoplelist", assignees);
      }
    } else {
      const { data } = await API.graphql({
        query: COMPANY_USERS_QUERY,
        variables: {
          id: companyId,
        },
      });

      data.company.users.items.map(
        (x) =>
          (assignees = [
            ...assignees,
            {
              label: `${x.firstName} ${x.lastName}`,
              value: `${x.firstName} ${x.lastName}`,
            },
          ])
      );

      setPeople(assignees); //set regular list as options
      console.log("peoplelist", assignees);
    }
  }

  const removeNotesIdLocalStorage = async (gmailId) => {
    const modifiedNotesTaskList = Array.isArray(
      JSON.parse(localStorage.getItem("modifiedTaskNotes"))
    )
      ? JSON.parse(localStorage.getItem("modifiedTaskNotes"))
      : [];

    localStorage.setItem(
      "modifiedTaskNotes",
      JSON.stringify(modifiedNotesTaskList.filter((item) => item !== gmailId))
    );

    setSelectedTaskList(
      modifiedNotesTaskList.filter((item) => item !== gmailId)
    );
  };

  const saveNotesIdLocalStorage = async (gmailId) => {
    const modifiedTaskNotes = Array.isArray(
      JSON.parse(localStorage.getItem("modifiedTaskNotes"))
    )
      ? JSON.parse(localStorage.getItem("modifiedTaskNotes"))
      : [];

    const notIncludesNoteId = !modifiedTaskNotes.includes(gmailId);
    if (notIncludesNoteId) {
      localStorage.setItem(
        "modifiedTaskNotes",
        JSON.stringify([...modifiedTaskNotes, gmailId])
      );
      console.log(
        "local Storage ->",
        localStorage.getItem("modifiedTaskNotes")
      );
      console.log("selectedTaskList ->", selectedTaskList);
    }
  };

  const handleSelectForTaskList = async (e, counter) => {
    console.log("handleSelectForTaskList", selectedTaskList);
    const { id, checked } = e.target;
    setSelectedTaskList([...selectedTaskList, id]);
    await saveNotesIdLocalStorage(id);

    if (!checked) {
      setSelectedTaskList(selectedTaskList.filter((item) => item !== id));
      await removeNotesIdLocalStorage(id);
    }

    console.log("will add", selectedTaskList);

    if (width <= 844) {
      autoAdjustAllRow();
    }
  };

  //Notes
  const [notesChanged, setNotesChanged] = useState("");

  const mSaveNotes = `mutation updateGmailMessage($id: String, $notes: String) {
        gmailMessageUpdate(id: $id, notes: $notes) {
          id
        }
      }`;

  const stripHtml = (html) =>{
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const saveNotes = async (gmailId) => {
    let newNotes = stripHtml(notesChanged);

    savedEmails.map((x) => (x.id === gmailId ? (x.notes = newNotes) : x));
    console.log("CHANGING NOTE OF ", gmailId, " TO ", newNotes);
    const result = await API.graphql({
      query: mSaveNotes,
      variables: {
        id: gmailId,
        notes: newNotes,
      },
    });

    console.log("save notes", result);
  };

  const handleChangeNotes = (e) => {
    console.log("CHANGING NOTE TO", stripHtml(e.target.innerHTML));
    setNotesChanged(e.target.innerHTML);
  };
  //End of Notes

  const selectStyle = {
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 20,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(180deg)",
    }),
  };

  const getAssignee = (data) => {
    if (data) {
      if (data.length > 0 || data !== null || data !== undefined) {
        var people = [];
        //for some cases which returns: undefined,undefined
        try {
          people = data.split(",");
          var temp = [];
          people.map((x) => {
            temp = [...temp, { label: x, value: x }];
          });
          return temp;
        } catch (e) {
          console.error(e);
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const mSaveAssignees = `mutation saveGmailAssignee($id: String, $assignee: String) {
    gmailMessageUpdate(id: $id, assignee: $assignee) {
      id
    }
  }`;

  const saveAssignee = async (e, gmailId) => {
    let newPeople = "";

    if (e.length > 0) {
      for (var i = 0; i < e.length; i++) {
        newPeople = newPeople + `${e[i].label},`; //convert to string
      }
      newPeople = newPeople.substr(0, newPeople.length - 1); //remove last comma
    } else {
      newPeople = "";
    }

    savedEmails.map((x) => (x.id === gmailId ? (x.assignee = newPeople) : x));
    console.log("New people for assignee: ", newPeople);

    const result = await API.graphql({
      query: mSaveAssignees,
      variables: {
        id: gmailId,
        assignee: newPeople,
      },
    }).then((result) => {
      console.log("save Assignee: ", result);
    });

    autoAdjustAllRow();
  };

  const mSavePriority = `mutation updateGmailMessage($id: String, $priority: Int) {
    gmailMessageUpdate(id: $id, priority: $priority) {
      id
    }
  }`;

  const savePriority = async (e, gmailId, notes) => {
    let newPriority = parseInt(e.value, 10);
    let newNotes = notes;

    savedEmails.forEach((x) => (x.id === gmailId ? (x.priority = newPriority) : x));

    const result = await API.graphql({
      query: mSavePriority,
      variables: {
        id: gmailId,
        priority: newPriority,
      },
    });

    // console.log("success", result);
    // // console.log("unsaved", unSavedEmails);

    autoAdjustAllRow();
  };

  const handleOnKeyupRows = (e, rowId) => {
    //autoAdjustRowHeight();
    autoAdjustAllRow();
  };

  useEffect(() => {
    if (people.length === 0) {
      fetchCompanyUsers();
    }
  }, []);

  function checkStatus(itemId, tasklistId) {
    if (tasklistId !== "") {
      return true;
    } else if (selectedTaskList.includes(itemId)) {
      return true;
    } else {
      return false;
    }
  }

  //Changes color of highlighted emails
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [prevSelectedEmails, setPrevSelectedEmails] = useState([]);

  useEffect(() => {
    const storedSelectedSavedEmails =
      localStorage.getItem("selectedSavedEmail");
    if (storedSelectedSavedEmails) {
      setPrevSelectedEmails(JSON.parse(storedSelectedSavedEmails));
    }
  }, []);

  const CustomClearIndicator = () => null;

  useEffect(() => {
    //console.log("useEffectWidth:", window.innerWidth);
    width = window.innerWidth;
    if (width > 844) {
      let tableHeight = document.getElementById("emailTableBody").offsetTop;
      setHeightOffset(tableHeight + 1);
    }
  }, [window.innerWidth]);

  const handleEmailClick = (id) => {
    setPrevSelectedEmails(prevSelectedEmails.concat(id));
    setSelectedEmail(id);
    localStorage.setItem(
      "selectedSavedEmail",
      JSON.stringify(prevSelectedEmails.concat(id))
    );
  };

  // Time format
  const currentDate = moment().local().format("YYYY-MM-DD");
  const itemDate = moment(selectSavedEmailsDetails?.internalDate, "x")
    .local()
    .format("YYYY-MM-DD");

  // MMA-1981 Show Selected Task Registers
  const TASK_LIST_DATA_QUERY = `
    query taskListData($companyId: ID!, $limit: Int, $nextToken: String) {
      taskListData(companyId: $companyId, limit: $limit, nextToken: $nextToken) {
        items {
          id
          data
          clientMatterId
          gmailMessageId
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
    }
  `;
  async function fetchTaskListData() {
    const { data } = await API.graphql({
      query: TASK_LIST_DATA_QUERY,
      variables: {
        companyId,
        isDeleted: false,
        nextToken: null,
      },
    });

    const tasks = data.taskListData.items?.map(({ data, ...rest }, i) => ({
      no: i + 1,
      ...rest,
      ...JSON.parse(data),
    }));
    return tasks;
  }

  useEffect(() => {
    // Retrieving tasklist registers per email
    fetchTaskListData().then((tasks) => {
      let newSavedEmails = [];
      savedEmails.forEach((email) => {
        if (email.taskList?.id !== "") {
          newSavedEmails.push({
            ...email,
            registers: tasks.find((task) => task.gmailMessageId === email.id)
              ? tasks.find((task) => task.gmailMessageId === email.id).registers
              : [],
          });
        } else {
          newSavedEmails.push({
            ...email,
            registers: [],
          });
        }
      });

      console.log("Saved Emails with Registers", newSavedEmails);
      setSavedEmails(newSavedEmails);
      // console.log(tasks)
      // console.log(savedEmails.map(email => email.taskList.id))
    });
  }, []);

  useEffect(() => {
    const modifiedNotesTaskList = Array.isArray(
      JSON.parse(localStorage.getItem("modifiedTaskNotes"))
    )
      ? JSON.parse(localStorage.getItem("modifiedTaskNotes"))
      : [];

    savedEmails.forEach((email) => {
      const isInModifiedTaskList = modifiedNotesTaskList.includes(email.id);

      if (isInModifiedTaskList) {
        // Remove the email from modifiedNotesTaskList
        const index = modifiedNotesTaskList.indexOf(email.id);
        if (index > -1) {
          modifiedNotesTaskList.splice(index, 1);
        }

        // Update the local storage
        localStorage.setItem(
          "modifiedTaskNotes",
          JSON.stringify(modifiedNotesTaskList)
        );
        setSelectedTaskList(
          modifiedNotesTaskList.filter((item) => item !== email.id)
        );
      }
    });
  }, [savedEmails]);

  return isOutOfSync ? (
    <>
      <table
        id="table-el"
        className="border-slate-100 table-fixed divide-y divide-gray-200 border-b-2 border-l-2 border-r-2 text-xs"
      >
        <tbody>
          <tr>
            <td className="p-4">
              An error has occurred. Please re-login your account to continue
              syncing emails.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  ) : (
    <>
      {width > 844 ? (
        <>
          {/* <table className="min-w-full text-xs border-b-2 border-l-2 border-r-2 divide-y divide-gray-200 table-fixed border-slate-100"> */}

          <div
            id="emailTableBody"
            className="grid grid-cols-4 flex overflow-y-hidden"
            style={{ height: height - heightOffset + "px" }}
          >
            {/* left-hand-screen */}
            {/* <table className="border-slate-100 w-1/3 table-fixed divide-y divide-gray-200 border-b-2 border-l-2 border-r-2 text-xs"> */}
            <div
              ref={tableContainerRef}
              className={
                "overflow-y-auto overflow-x-hidden inboxScroll " +
                (waitSaved ? "overflow-y-hidden" : "overflow-y-auto")
              }
              style={{
                height: height - heightOffset + "px",
              }}
            >
              <table
                id="table-el"
                className="divide-y w-full table-fixed divide-gray-200 text-xs"
                // style={{ width: width * 0.28 + "px" }}
              >
                <thead
                  className=" hidden bg-white"
                  style={{ position: "sticky", top: "120px" }}
                >
                  <tr>
                    <th className="w-8 whitespace-nowrap px-2 py-4 text-center font-medium"></th>
                    <th className="w-3/12 whitespace-nowrap px-2 py-4 text-center font-medium">
                      Email Details
                    </th>
                    <th className="w-3/12 whitespace-nowrap px-2 py-4 text-center font-medium">
                      Attachments and Description
                    </th>
                    <th className="w-2/12 whitespace-nowrap px-2 py-4 text-center font-medium">
                      Background Labels
                    </th>
                    <th className="w-3/12 whitespace-nowrap px-2 py-4 text-center font-medium">
                      Client Matter
                    </th>
                    <th className="w-1/12 whitespace-nowrap px-1 py-2 text-center font-medium">
                      Add to <br />
                      TaskList
                    </th>
                  </tr>
                </thead>
                <tbody
                  className="divide-y divide-gray-200 bg-white"
                  style={{ width: "100%" }}
                >
                  {waitSaved ? (
                    <>
                      {[...Array(10).keys()].map((data, index) => {
                        return (
                          <tr className="flex">
                            <td className="w-10 flex p-2 items-center justify-center">
                              <div className="align-center">
                                <SkeletonTheme width={"20px"} height={"20px"}>
                                  <p>
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>

                            <td className="flex-1 grow p-2 align-top">
                              <div>
                                <SkeletonTheme height="40px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="w-20 flex-none p-2 align-top">
                              <div>
                                <SkeletonTheme height="55px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        height: rowVirtualizer.getTotalSize(),
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          transform: `translateY(${
                            virtualRows[0]?.start || 0
                          }px)`,
                        }}
                      ></div>
                      {virtualRows.map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        const item = row.original;
                        const index = row.index;

                        return (
                          <tr
                            id={"desktopSaved" + row.id}
                            ref={(e) => virtualRow.measureElement(e)}
                            data-index={virtualRow.index}
                            style={{
                              height: `${virtualRow.size}px`,
                              transform: `translateY(${virtualRow.start}px)`,
                            }}
                            className="absolute top-0 left-0 w-full h-full border-gray-200 border-b border-r"
                            key={virtualRow.key}
                          >
                            {/* LEFT SIDE OF SCREEN */}
                            <td
                              className={`checkBox h-full w-2/5 py-5 pr-2 text-center align-top cursor-pointer ${
                                selectedEmail === item.id ? "bg-gray-200" : ""
                              } ${
                                prevSelectedEmails.includes(item.id)
                                  ? "bg-gray-100"
                                  : ""
                              }`}
                              onClick={() => {
                                handleEmailClick(item.id);
                                handleSnippet(item, index);
                                setSelectSavedEmailsDetails(item);
                                setSelectedSavedIndex(index);
                                detailsDivRef?.current?.scrollTo({
                                  top: 0,
                                  behavior: "instant",
                                });
                              }}
                            >
                              <div className="flex w-full">
                                <div className="w-10 flex justify-center items-center">
                                  <input
                                    disabled={saveLoading ? true : false}
                                    key={item.id}
                                    className={
                                      saveLoading ? "" : "cursor-pointer"
                                    }
                                    onChange={(e) =>
                                      handleSelectItem(
                                        e,
                                        item.clientMatters?.items?.length
                                      )
                                    }
                                    type="checkbox"
                                    value={item.id}
                                    id={item.id}
                                    checked={selectedSavedItems.includes(
                                      item.id
                                    )}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    setEmailContentOpen(true);
                                  }}
                                  className="w-full grow"
                                >
                                  <div className="flex">
                                    <div
                                      className={
                                        "w-full text-left text-sm pb-0.5 text-left " +
                                        (selectedEmail === item.id
                                          ? "font-medium"
                                          : "")
                                      }
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {/* {item?.from?.split("<")[0]} */}
                                      <Highlight text={item?.from?.split("<")[0]} highlight={searchGmail} />
                                    </div>
                                    <div className="mr-2 flex w-20 flex-none flex-row justify-end">
                                      <div>
                                        {item?.taskList?.id == null ||
                                        item?.taskList?.id == "" ? (
                                          <></>
                                        ) : (
                                          <AiFillCheckCircle
                                            size={16}
                                            className="text-green-500"
                                          />
                                        )}
                                      </div>
                                      <div>
                                        {item?.attachments?.items?.some(
                                          (attachment) =>
                                            !attachment.details.includes(
                                              "EMAIL_PDF_VERSION"
                                            )
                                        ) ? (
                                          <GrAttachment
                                            size={14}
                                            className="fill-gray-300"
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="w-18 flex-none content-end text-right text-xs">
                                        {moment(item?.internalDate, "x")
                                          .local()
                                          .isSame(currentDate, "day")
                                          ? timeFormatter(new Date(item?.date))
                                          : dateFormatter(new Date(item?.date))}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="w-full text-left text-xs font-normal">
                                    {item?.subject}
                                  </div> */}
                                  <div className="grid grid-cols-1 w-full mr-2">
                                    {/* <div
                                      className="mr-1 pb-px mb-1 w-auto whitespace-auto inline-block max-w-full whitespace-normal rounded-sm text-left text-xs font-light"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item?.snippet !== ""
                                            ? item?.snippet
                                            : item?.subject,
                                        // __html: `${item?.snippet
                                        //   ?.split(" ")
                                        //   .slice(0, 10)
                                        //   .join(" ")}${
                                        //   item?.snippet?.split(" ").length >
                                        //   10
                                        //     ? "..."
                                        //     : ""
                                        // }`,
                                      }}
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    ></div> */}
                                    <div
                                      className="w-auto inline-block max-w-full rounded-sm text-left text-xs font-light overflow-hidden"
                                      style={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <Highlight
                                        text={item?.snippet !== "" ? item?.snippet : item?.subject}
                                        highlight={searchGmail}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            {/* END OF LEFT SIDE OF SCREEN */}
                          </tr>
                        );
                      })}
                    </div>
                    // <AutoSizer>
                    //   {({ width, height }) => (
                    //     <List
                    //       className="inboxScroll overflow-y-scroll"
                    //       width={width}
                    //       height={height}
                    //       //rowHeight={cache.current.rowHeight}
                    //       rowHeight /*{cache.current.rowHeight}*/={
                    //         savedEmails.length === 1
                    //           ? "100"
                    //           : cache.current.rowHeight
                    //       }
                    //       deferredMeasurementCache={cache.current}
                    //       rowCount={savedEmails.length}
                    //       rowRenderer={({ key, index, style, parent }) => {
                    //         const item = savedEmails[index];
                    //         return (
                    //           <CellMeasurer
                    //             key={item.id + "-" + index}
                    //             cache={cache.current}
                    //             parent={parent}
                    //             rowIndex={index}
                    //             columnIndex={0}
                    //           >
                    //             <tr
                    //               style={{
                    //                 ...style,
                    //               }}
                    //               key={key}
                    //               className="w-full h-full border-gray-200 border-b"
                    //             >
                    //               {/* LEFT SIDE OF SCREEN */}
                    //               <td
                    //                 className={`checkBox h-full w-2/5 py-5 pr-2 text-center align-top cursor-pointer ${
                    //                   selectedEmail === item.id
                    //                     ? "bg-gray-200"
                    //                     : ""
                    //                 } ${
                    //                   prevSelectedEmails.includes(item.id)
                    //                     ? "bg-gray-100"
                    //                     : ""
                    //                 }`}
                    //                 onClick={() => {
                    //                   handleEmailClick(item.id);
                    //                   handleSnippet(item, index);
                    //                   setSelectSavedEmailsDetails(item);
                    //                   setSelectedSavedIndex(index);
                    //                   detailsDivRef?.current?.scrollTo({
                    //                     top: 0,
                    //                     behavior: "instant",
                    //                   });
                    //                 }}
                    //               >
                    //                 <div className="flex w-full">
                    //                   <div className="w-10 flex justify-center items-center">
                    //                     <input
                    //                       disabled={saveLoading ? true : false}
                    //                       key={item.id}
                    //                       className={
                    //                         saveLoading ? "" : "cursor-pointer"
                    //                       }
                    //                       onChange={(e) =>
                    //                         handleSelectItem(
                    //                           e,
                    //                           item.clientMatters?.items?.length
                    //                         )
                    //                       }
                    //                       type="checkbox"
                    //                       value={item.id}
                    //                       id={item.id}
                    //                       checked={selectedSavedItems.includes(
                    //                         item.id
                    //                       )}
                    //                     />
                    //                   </div>
                    //                   <div
                    //                     onClick={() => {
                    //                       setEmailContentOpen(true);
                    //                     }}
                    //                     className="w-full grow"
                    //                   >
                    //                     <div className="flex">
                    //                       <div className="w-full text-left text-sm pb-0.5 text-left">
                    //                         {item?.from?.split("<")[0]}
                    //                       </div>
                    //                       <div className="mr-2 flex w-20 flex-none flex-row justify-end">
                    //                         <div>
                    //                           {item?.taskList?.id == null ||
                    //                           item?.taskList?.id == "" ? (
                    //                             <></>
                    //                           ) : (
                    //                             <AiFillCheckCircle
                    //                               size={16}
                    //                               className="text-green-500"
                    //                             />
                    //                           )}
                    //                         </div>
                    //                         <div>
                    //                           {item?.attachments?.items?.some(
                    //                             (attachment) =>
                    //                               !attachment.details.includes(
                    //                                 "EMAIL_PDF_VERSION"
                    //                               )
                    //                           ) ? (
                    //                             <GrAttachment
                    //                               size={14}
                    //                               className="fill-gray-300"
                    //                             />
                    //                           ) : (
                    //                             <></>
                    //                           )}
                    //                         </div>
                    //                         <div className="w-18 flex-none content-end text-right text-xs">
                    //                           {moment(item?.internalDate, "x")
                    //                             .local()
                    //                             .isSame(currentDate, "day")
                    //                             ? timeFormatter(new Date(item?.date))
                    //                             : dateFormatter(new Date(item?.date))}
                    //                         </div>
                    //                       </div>
                    //                     </div>

                    //                     {/* <div className="w-full text-left text-xs font-normal">
                    //                       {item?.subject}
                    //                     </div> */}
                    //                     <div className="grid grid-cols-1 w-full mr-2">
                    //                       <div
                    //                         className="mr-2 w-auto whitespace-auto inline-block max-w-full whitespace-normal rounded-sm text-left text-xs font-light"
                    //                         dangerouslySetInnerHTML={{
                    //                         __html: item?.snippet !== ""
                    //                           ? item?.snippet
                    //                           : item?.subject
                    //                         // __html: `${item?.snippet
                    //                         //   ?.split(" ")
                    //                         //   .slice(0, 10)
                    //                         //   .join(" ")}${
                    //                         //   item?.snippet?.split(" ").length >
                    //                         //   10
                    //                         //     ? "..."
                    //                         //     : ""
                    //                         // }`,
                    //                         }}
                    //                         style={{
                    //                           whiteSpace: "nowrap",
                    //                           overflow: "hidden",
                    //                           textOverflow: "ellipsis"
                    //                         }} >
                    //                       </div>
                    //                     </div>
                    //                   </div>
                    //                 </div>
                    //               </td>
                    //               {/* END OF LEFT SIDE OF SCREEN */}
                    //             </tr>
                    //           </CellMeasurer>
                    //         );
                    //       }}
                    //     />
                    //   )}
                    // </AutoSizer>
                  )}
                </tbody>
              </table>
            </div>

            {/* RIGHT SIDE OF SCREEN */}
            {emailContentOpen ? (
              <div
                className="overflow-hidden h-full col-span-3 flex flex-col border-t border-l border-gray-200 p-4 pb-0"
                style={
                  {
                    // position: "sticky",
                    // top: "40px",
                    // width: (width * 0.62 - 40)+ "px",
                    // height: height * heightMul + "px",
                  }
                }
                ref={detailsDivRef}
              >
                {/* <div className="px-5 py-5"> */}
                <div className="px-2">
                  <div className="flex pt-4">
                    <span className="flex-grow text-left font-bold">
                      {/* {selectSavedEmailsDetails.subject} */}
                      <Highlight text={selectSavedEmailsDetails?.subject} highlight={searchGmail} classNames="flex-grow text-left font-bold" />
                    </span>
                    <span className="text-right">
                      {dateTimeNoSecondsFormatter(
                        new Date(selectSavedEmailsDetails?.date)
                      )}
                    </span>
                  </div>
                  <div className="my-2 flex">
                    <span className="flex-grow text-left">
                      {/* {selectSavedEmailsDetails?.from} */}
                      <Highlight text={selectSavedEmailsDetails?.from} highlight={searchGmail} classNames="flex-grow text-left" />
                    </span>
                  </div>
                </div>
                <hr />

                <div
                  className="inboxScroll h-full overflow-y-hidden grid"
                  style={{ gridTemplateColumns: "1fr 1px 1fr" }}
                >
                  <div className="inboxScrollInner overflow-y-auto p-5 pr-4">
                    {/* <div className="mb-11">
                        <div className="mt-11 flex">
                          <span className="flex-grow text-left">
                            Describe Email
                          </span>
                          <button
                            className="rounded bg-green-500 px-4 py-2 text-right text-xs font-bold text-white hover:bg-green-700"
                            onClick={() =>
                              handleOpenAI(
                                selectUnsavedEmailsDetails?.id,
                                selectedUnsavedIndex
                              )
                            }
                          >
                            {`Summarize Email`}
                          </button>
                        </div>
                      </div> */}

                    {/* CLIENT MATTERS */}
                    <div className="mb-5">
                      <p>
                        {hbaDomains.includes(window.location.hostname)
                          ? "Project Name"
                          : "Client Matter"}
                      </p>
                      <div className="mt-3 w-full">
                        <p className="font-semibold">
                          {
                            selectSavedEmailsDetails?.clientMatters?.items[0]
                              ?.client?.name
                          }{" "}
                          /{" "}
                          {
                            selectSavedEmailsDetails?.clientMatters?.items[0]
                              ?.matter?.name
                          }
                        </p>
                      </div>
                    </div>

                    {/* EMAIL LABEL */}
                    <div className="mt-5 mb-5">
                      <p>Label</p>
                      <div className="mb-1" disabled={true}>
                        <Select
                          values={selectSavedEmailsDetails?.labels?.items.map(
                            (item_label) => ({
                              value: item_label?.id,
                              label: item_label?.name,
                            })
                          )}
                          multi={true}
                          options={[]}
                          disabled={true}
                          placeholder="Labels"
                          color="#8a8a8a"
                          className="outline-none background-slate-900 placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 mt-2 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                        />
                      </div>
                    </div>

                    {/* DESCRIPTION EMAIL */}
                    <div className="mt-5">
                      <p>Description</p>
                      <p
                        className="font-poppins mt-2 mb-2 w-full whitespace-normal break-words rounded bg-gray-200 p-2"
                        style={{
                          border: "solid 1px #c4c4c4",
                          outlineColor: "rgb(204, 204, 204, 0.5)",
                          outlineWidth: "thin",
                          overflow: "auto",
                          height: "100px",
                          maxWidth: width <= 540 ? "18rem" : "50rem",
                        }}
                        suppressContentEditableWarning
                        dangerouslySetInnerHTML={{
                          __html: selectSavedEmailsDetails?.description,
                        }}
                        onBlur={(e) => {
                          handleSaveMainDesc(
                            e,
                            selectSavedEmailsDetails?.id,
                            selectedSavedIndex
                          );
                        }}
                        contentEditable={false}
                      ></p>
                    </div>

                    {/* TASKLIST */}
                    {selectSavedEmailsDetails?.taskList?.id == null ||
                    selectSavedEmailsDetails?.taskList?.id == "" ? (
                      <></>
                    ) : (
                      <p className="flex text-sm font-semibold text-green-500">
                        Already in Tasklist{" "}
                        <AiFillCheckCircle className="ml-1 rounded-full text-green-500" />
                      </p>
                    )}
                    <hr className="mt-5" />

                    {/* SAVE AND ARCHIVE EMAIL */}
                    <ActionButtons
                      setUnsavedEmailsCount={setUnsavedEmailsCount}
                      setSavedEmailsCount={setSavedEmailsCount}
                      setArchivedEmailsCount={setArchivedEmailsCount}
                      taskListColumns={taskListColumns}
                      taskRegisterList={taskRegisterList}
                      setResultMessage={setResultMessage}
                      setShowToast={setShowToast}
                      saveLoading={saveLoading}
                      setSelectedTaskList={setSelectedTaskList}
                      selectedTaskList={selectedTaskList}
                      setEmailContentOpen={setEmailContentOpen}
                      setMobileLoading={setMobileLoading}
                      isOutlookIntegrated={isOutlookIntegrated}
                      emailFilters={emailFilters}
                      searchGmail={searchGmail}
                      setShowCopy={setShowCopy}
                      showDetailsCopy={showDetailsCopy}
                      setShowDetailsCopy={setShowDetailsCopy}
                      showCopy={showCopy}
                      selectedTaskRegister={selectedTaskRegister}
                      setSelectedTaskRegister={setSelectedTaskRegister}
                      displayImageAttachments={displayImageAttachments}
                      setSaveLoading={setSaveLoading}
                      sortByDate={sortByDate}
                      archivedEmails={archivedEmails}
                      setArchivedEmails={setArchivedEmails}
                      unSavedEmails={unSavedEmails}
                      setUnsavedEmails={setUnsavedEmails}
                      getUnSavedEmails={getUnSavedEmails}
                      selectedUnsavedItems={selectedUnsavedItems}
                      setSelectedUnsavedItems={setSelectedUnsavedItems}
                      selectedArchivedItems={selectedArchivedItems}
                      setSelectedArchivedItems={setSelectedArchivedItems}
                      tasklistGmailIds={tasklistGmailIds}
                      setTasklistGmailIds={setTasklistGmailIds}
                      unSavedForTaskListCtr={unSavedForTaskListCtr}
                      savedForTaskListCtr={savedForTaskListCtr}
                      getArchivedEmails={getArchivedEmails}
                      setDoneAllocatingEmails={setDoneAllocatingEmails}
                      setpendingMessage={setpendingMessage}
                      setPreviousUnsavedEmails={setPreviousUnsavedEmails}
                      setPreviousSavedEmails={setPreviousSavedEmails}
                      setPreviousArchivedEmails={setPreviousArchivedEmails}
                      setSearchGmail={setSearchGmail}
                      selectAllArchived={selectAllArchived}
                      selectAllSaved={selectAllSaved}
                      selectAllUnsaved={selectAllUnsaved}
                      setDisplayImageAttachments={setDisplayImageAttachments}
                      selectAllCheck={selectAllCheck}
                      setSelectAllCheck={setSelectAllCheck}
                      handleExecuteFilter={handleExecuteFilter}
                      savedEmails={savedEmails}
                      setSavedEmails={setSavedEmails}
                      getSavedEmails={getSavedEmails}
                      selectedSavedItems={selectedSavedItems}
                      setSelectedSavedItems={setSelectedSavedItems}
                      openTab={openTab}
                      fetchData={fetchData}
                      setWaitUnSaved={setWaitUnSaved}
                      setWaitSaved={setWaitSaved}
                      setWaitArchived={setWaitArchived}
                      setOpenTab={setOpenTab}
                      saveProgress={saveProgress}
                      setSaveProgress={setSaveProgress}
                    />
                  </div>
                  <div className="my-5 border-l border-gray-300"></div>
                  <div className="inboxScrollInner overflow-y-auto p-5 pr-5">
                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: emailHTML,
                      }}
                    ></p> */}
                    <Highlight text={emailHTML} highlight={searchGmail} classNames={"inboxScrollInner overflow-y-auto p-5 pr-4"} />
                    <hr className="border mt-5" />
                    <div className="mt-5 mb-5 flex w-full flex-col">
                      <div className="flex justify-between">
                        <p>Attachments and Labels</p>
                        <ActionButtons
                          setUnsavedEmailsCount={setUnsavedEmailsCount}
                          setSavedEmailsCount={setSavedEmailsCount}
                          setArchivedEmailsCount={setArchivedEmailsCount}
                          taskListColumns={taskListColumns}
                          selectedUnsavedItems={selectedUnsavedItems}
                          setSelectedUnsavedItems={setSelectedUnsavedItems}
                          selectedSavedItems={selectedSavedItems}
                          setSelectedSavedItems={setSelectedSavedItems}
                          selectedArchivedItems={selectedArchivedItems}
                          setSelectedArchivedItems={setSelectedArchivedItems}
                          taskRegisterList={taskRegisterList}
                          openTab={openTab}
                          fetchData={fetchData}
                          setWaitUnSaved={setWaitUnSaved}
                          setWaitSaved={setWaitSaved}
                          setWaitArchived={setWaitArchived}
                          setOpenTab={setOpenTab}
                          getUnSavedEmails={getUnSavedEmails}
                          getSavedEmails={getSavedEmails}
                          unSavedEmails={unSavedEmails}
                          setUnsavedEmails={setUnsavedEmails}
                          savedEmails={savedEmails}
                          setSavedEmails={setSavedEmails}
                          setResultMessage={setResultMessage}
                          setShowToast={setShowToast}
                          saveLoading={saveLoading}
                          setSaveLoading={setSaveLoading}
                          setDoneAllocatingEmails={setDoneAllocatingEmails}
                          setpendingMessage={setpendingMessage}
                          sortByDate={sortByDate}
                          getArchivedEmails={getArchivedEmails}
                          archivedEmails={archivedEmails}
                          setArchivedEmails={setArchivedEmails}
                          setSelectedTaskList={setSelectedTaskList}
                          selectedTaskList={selectedTaskList}
                          selectedTaskRegister={selectedTaskRegister}
                          setSelectedTaskRegister={setSelectedTaskRegister}
                          tasklistGmailIds={tasklistGmailIds}
                          setTasklistGmailIds={setTasklistGmailIds}
                          unSavedForTaskListCtr={unSavedForTaskListCtr}
                          savedForTaskListCtr={savedForTaskListCtr}
                          displayImageAttachments={displayImageAttachments}
                          setDisplayImageAttachments={
                            setDisplayImageAttachments
                          }
                          selectAllCheck={selectAllCheck}
                          setSelectAllCheck={setSelectAllCheck}
                          setEmailContentOpen={setEmailContentOpen}
                          selectAllArchived={selectAllArchived}
                          selectAllSaved={selectAllSaved}
                          selectAllUnsaved={selectAllUnsaved}
                          setMobileLoading={setMobileLoading}
                          showDetailsCopy={showDetailsCopy}
                          setShowDetailsCopy={setShowDetailsCopy}
                          showCopy={showCopy}
                          setShowCopy={setShowCopy}
                          searchGmail={searchGmail}
                          setSearchGmail={setSearchGmail}
                          isOutlookIntegrated={isOutlookIntegrated}
                          setPreviousUnsavedEmails={setPreviousUnsavedEmails}
                          setPreviousSavedEmails={setPreviousSavedEmails}
                          setPreviousArchivedEmails={setPreviousArchivedEmails}
                          emailFilters={emailFilters}
                          handleExecuteFilter={handleExecuteFilter}
                          hideAttachmentOnly={true}
                          saveProgress={saveProgress}
                          setSaveProgress={setSaveProgress}
                        />
                      </div>
                      {selectSavedEmailsDetails?.attachments?.items?.map(
                        (item_attach, indexAttachments) => (
                          <React.Fragment key={item_attach.id}>
                            <div className="flex-grow mb-4">
                              <div className="align-center mt-2 flex justify-between">
                                <div
                                  className="focus:outline-none mr-1 inline-flex w-16 flex-none cursor-pointer items-center rounded bg-gray-100 px-2 py-1 text-xs font-semibold text-opacity-90 hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                                  id={item_attach?.id}
                                  title={item_attach?.name}
                                  onClick={() =>
                                    previewAndDownloadFile(item_attach?.id)
                                  }
                                >
                                  {item_attach?.name.substring(0, 10)}
                                  {item_attach?.name?.length >= 10 ? "..." : ""}
                                </div>
                                <Select
                                  values={item_attach?.labels?.items?.map(
                                    (att) => ({
                                      value: att?.id,
                                      label: att?.name,
                                    })
                                  )}
                                  multi={true}
                                  options={[]}
                                  disabled={true}
                                  color="#8a8a8a"
                                  placeholder="Labels"
                                  className="flex-1 grow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                                />
                              </div>
                              <div
                                className={
                                  "font-poppins mt-1 h-full w-full whitespace-normal rounded-sm bg-gray-200 p-2"
                                }
                                style={{
                                  border: "solid 1px #c4c4c4",
                                  cursor: "auto",
                                  outlineColor: "rgb(204, 204, 204, 0.5)",
                                  outlineWidth: "thin",
                                }}
                                suppressContentEditableWarning
                                dangerouslySetInnerHTML={{
                                  __html: item_attach.details,
                                }}
                                contentEditable={false}
                              ></div>
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                </div>
                {/* <br />
                  <div className="flex">
                    <span className="flex-grow text-left font-bold">
                      {selectSavedEmailsDetails.subject}
                    </span>
                    <span className="text-right">
                      {dateTimeNoSecondsFormatter(
                        new Date(selectSavedEmailsDetails?.date)
                      )}
                    </span>
                  </div>
                  <div className="my-2 flex">
                    <span className="flex-grow text-left">
                      {selectSavedEmailsDetails?.from}
                    </span>
                  </div>
                  <hr />
                  <br />
                  <div className="mb-11">
                    <p
                      className="mt-8 p-2"
                      dangerouslySetInnerHTML={{
                        __html: emailHTML,
                      }}
                    ></p>
                    <div className="mt-11 flex">
                      <span className="flex-grow text-left">
                        Describe Email
                      </span>
                      <button
                        className="rounded bg-green-500 px-4 py-2 text-right text-xs font-bold text-white hover:bg-green-700"
                        onClick={() =>
                          handleOpenAI(
                            selectSavedEmailsDetails?.id,
                            selectedSavedIndex
                          )
                        }
                      >
                        {`Summarize Email`}
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-5">
                    <p>Description</p>
                    <p
                      className="font-poppins mt-2 mb-2 w-full whitespace-normal break-words rounded bg-gray-200 p-2"
                      style={{
                        border: "solid 1px #c4c4c4",
                        outlineColor: "rgb(204, 204, 204, 0.5)",
                        outlineWidth: "thin",
                        overflow: "auto",
                        height: "100px",
                        maxWidth: width <= 540 ? "18rem" : "50rem",
                      }}
                      suppressContentEditableWarning
                      dangerouslySetInnerHTML={{
                        __html: selectSavedEmailsDetails?.description,
                      }}
                      onBlur={(e) => {
                        handleSaveMainDesc(
                          e,
                          selectSavedEmailsDetails?.id,
                          selectedSavedIndex
                        );
                      }}
                      contentEditable={false}
                    ></p>
                  </div>

                  <hr className="border-2 border-dotted" />
                  <div className="mt-5 mb-5">
                    <p>Client Matter</p>
                    <div className="mt-3 w-full">
                      <p className="font-semibold">
                        {
                          selectSavedEmailsDetails?.clientMatters?.items[0]
                            ?.client?.name
                        }{" "}
                        /{" "}
                        {
                          selectSavedEmailsDetails?.clientMatters?.items[0]
                            ?.matter?.name
                        }
                      </p>
                    </div>
                  </div>

                  <hr className="border-2 border-dotted" />
                  <div className="mt-5 mb-5">
                    <p>Label</p>
                    <div className="mb-1" disabled={true}>
                      <Select
                        values={selectSavedEmailsDetails?.labels?.items.map(
                          (item_label) => ({
                            value: item_label?.id,
                            label: item_label?.name,
                          })
                        )}
                        multi={true}
                        options={[]}
                        disabled={true}
                        placeholder="Labels"
                        color="#8a8a8a"
                        className="outline-none background-slate-900 placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 mt-2 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                      />
                    </div>
                  </div>

                  <hr className="border-2 border-dotted" />
                  <div className="mt-5 mb-5">
                    <p>Attachments and Labels</p>
                    {selectSavedEmailsDetails?.attachments?.items?.map(
                      (item_attach, indexAttachments) => (
                        <React.Fragment key={item_attach.id}>
                          <div className="flex-grow">
                            <div className="align-center mt-2 flex">
                              <div
                                className="focus:outline-none mr-1 inline-flex w-16 flex-none cursor-pointer items-center rounded bg-gray-100 px-2 py-1 text-xs font-semibold text-opacity-90 hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                                id={item_attach?.id}
                                title={item_attach?.name}
                                onClick={() =>
                                  previewAndDownloadFile(item_attach?.id)
                                }
                              >
                                {item_attach?.name.substring(0, 10)}
                                {item_attach?.name?.length >= 10 ? "..." : ""}
                              </div>
                              <div
                                className={
                                  "font-poppins float-right mr-2 h-full w-full whitespace-normal rounded-sm bg-gray-200 p-2"
                                }
                                style={{
                                  border: "solid 1px #c4c4c4",
                                  cursor: "auto",
                                  outlineColor: "rgb(204, 204, 204, 0.5)",
                                  outlineWidth: "thin",
                                }}
                                suppressContentEditableWarning
                                dangerouslySetInnerHTML={{
                                  __html: item_attach.details,
                                }}
                                contentEditable={false}
                              ></div>

                              <Select
                                values={item_attach?.labels?.items?.map(
                                  (att) => ({
                                    value: att?.id,
                                    label: att?.name,
                                  })
                                )}
                                multi={true}
                                options={[]}
                                disabled={true}
                                color="#8a8a8a"
                                placeholder="Labels"
                                className="outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 mb-1 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )}
                  </div>
                  <br /> */}
                {/* </div> */}
              </div>
            ) : (
              //  END OF RIGHT SIDE OF SCREEN
              //NOTE: Default Message
              <>
                <div className="border-gray-200 border-t border-l  w-full col-span-3 flex flex-col items-center justify-start ">
                  <>
                    {selectedSavedItems.length > 0 ? (
                      <div className="mt-10 inline-flex flex-wrap gap-2">
                        <span className="text-lg font-semibold text-gray-500">
                          {selectedSavedItems.length}
                        </span>
                        <span className="text-lg font-semibold text-gray-500">
                          Total Selected Items
                        </span>
                      </div>
                    ) : (
                      <span className="mt-10 text-lg font-semibold text-gray-500">
                        No Emails Selected
                      </span>
                    )}
                  </>
                  <div className="mt-7 h-2 w-64 rounded-3xl bg-gray-300"></div>
                  <div className="mt-9 text-center">
                    {/* <p>0 / 80000</p> */}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* {maxLoadingSavedEmail ? (
        <>
          <div className="flex items-center justify-center mt-5">
            All Data has been loaded
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-center mt-5">
            <img src={imgLoading} alt="" width={50} height={100} />
          </div>
        </>
      )} */}

          {/* {showToast && resultMessage && (
            <ToastNotification title={resultMessage} hideToast={hideToast} />
          )} */}
        </>
      ) : (
        <>
          {/* //mobile view -kjmf// */}
          <table
            id="table-el"
            className="border-slate-100 min-w-full table-fixed h-full divide-y divide-gray-200 border-b-2 border-l-2 border-r-2 text-xs"
          >
            <tbody
              className="divide-y divide-gray-200 bg-white"
              style={{ width: "100%", height: totalSize }}
            >
              {!mobileLoading && !waitSaved ? (
                <>
                  {virtualRows.map((row) => {
                    const item = savedEmails[row.index];
                    return (
                      <div
                        className="pb-1"
                        id="mobileContent"
                        ref={(e) => row.measureElement(e)}
                        scrollToRow={forcescrollTop}
                        // width={width}
                        // height={totalSize}
                      >
                        <tr
                          className={
                            show
                              ? "test123 hidden w-full px-1"
                              : "test123 block w-full px-1"
                          }
                          key={row.key}
                        >
                          <div
                            className="mt-1 block w-full rounded-lg border border-gray-200 bg-gray-100 px-2 py-2"
                            style={{
                              width: "95vw",
                            }}
                          >
                            <div className="block">
                              <div className="flex">
                                <div className="w-5 flex-none">
                                  {/* Remove condition clientmatters on checkbox saved table */}
                                  <input
                                    key={item.id}
                                    className="mr-1 cursor-pointer"
                                    onChange={(e) =>
                                      handleSelectItem(
                                        e,
                                        item.clientMatters?.items.length
                                      )
                                    }
                                    type="checkbox"
                                    value={item.id}
                                    id={item.id}
                                    checked={selectedSavedItems.includes(
                                      item.id
                                    )}
                                  />
                                </div>
                                <div className="w-90 flex-1">
                                  <div
                                    onClick={(e) =>
                                      // handleSnippet(item, index)
                                      handleMobileSnippet(item, row.index)
                                    }
                                  >
                                    <p className="font-semibold">
                                      {/* {item.subject} */}
                                      <Highlight text={item?.subject} highlight={searchGmail} classNames="font-semibold" />
                                    </p>
                                    <p className="text-xs">
                                      <Highlight text={item?.from} highlight={searchGmail} classNames="text-xs" /> at{" "}
                                      {dateTimeNoSecondsFormatter(item?.date)}
                                    </p>
                                  </div>
                                  {/* Email Description */}
                                  {/* {item.description !== null && item.description !== undefined && item.description !== "" ?
                                    (
                                    <div
                                      className="items-center w-full mt-1 text-xs break-words whitespace-normal rounded-sm font-poppins whitespace-auto"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    >
                                    </div>
                                    ) : ""}   */}
                                  <p className="mt-1 text-xs font-semibold">
                                    {item.clientMatters !== null &&
                                    item.clientMatters?.items.length > 0 ? (
                                      <span className="nowrap flex">
                                        <BsFillCircleFill className="w-4 pt-0.5 text-xs text-green-400" />
                                        <p className="break-words pl-1 text-xs font-semibold text-green-500">
                                          Assigned
                                          <span
                                            className="text-xs font-semibold"
                                            style={{
                                              color: "#454545",
                                            }}
                                          >
                                            {" "}
                                            to clientmatter:{" "}
                                            {
                                              item.clientMatters.items[0].client
                                                .name
                                            }{" "}
                                            /{" "}
                                            {
                                              item.clientMatters.items[0].matter
                                                .name
                                            }
                                          </span>
                                        </p>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                  <p className="text-xs font-semibold">
                                    {item.taskList.id ? (
                                      <span className="nowrap flex flex-col">
                                        <div className="mt-2 flex">
                                          <BsFillCircleFill className="w-4 pt-0.5 text-xs text-green-400" />
                                          <p
                                            className="break-words text-xs font-semibold"
                                            style={{
                                              paddingLeft: "0.2rem",
                                            }}
                                          >
                                            Added in tasklist
                                            {/* {item.assignee !== null ||
                                              item.assignee !==
                                                undefined
                                                ? `, Assigned to ${item.assignee}`
                                                : "Not assigned to anyone"} */}
                                          </p>
                                        </div>
                                        <div className="flex flex-col pl-4">
                                          <div className="">
                                            <div className="flex w-full">
                                              <BsPeopleFill
                                                className="w-3"
                                                style={{
                                                  marginTop: "0.45rem",
                                                }}
                                              />
                                              <p className="className=z-40 ml-2 mt-2 flex-1 text-xs font-semibold text-gray-600">
                                                {!item.assignee
                                                  ? "No Assignees Yet"
                                                  : item.assignee
                                                      .split(",")
                                                      .map((assignee) =>
                                                        assignee.trim()
                                                      )
                                                      .join(", ")}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="">
                                            <div className="flex w-full">
                                              <RiNumbersFill
                                                className="w-3"
                                                style={{
                                                  marginTop: "0.45rem",
                                                }}
                                              />
                                              <p className="className=z-40 ml-2 mt-2 flex-1 text-xs font-semibold text-gray-600">
                                                {!item.priority
                                                  ? "No Priority Yet"
                                                  : item.priority}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="">
                                            <div className="flex w-full">
                                              <MdDescription
                                                className="w-3"
                                                style={{
                                                  marginTop: "0.4rem",
                                                }}
                                              />
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: !item.notes
                                                    ? "No Notes Yet"
                                                    : `${item.notes}`,
                                                }}
                                                className="className=z-40 ml-2 mt-2 flex-1 text-xs text-gray-600"
                                              ></p>
                                            </div>
                                          </div>
                                          <div className="">
                                            <div className="flex w-full">
                                              <AiFillDatabase
                                                className="w-3"
                                                style={{
                                                  marginTop: "0.45rem",
                                                }}
                                              />
                                              <div className="className=z-40 ml-2 mt-2 flex-1 text-xs font-semibold text-gray-600 gap-1 flex">
                                                {item.registers &&
                                                item.registers.length > 0 ? (
                                                  item.registers.map(
                                                    (reg, index) => (
                                                      <div
                                                        className="font-semibold px-1.5 py-0.5 border border-black rounded-md inline-flex"
                                                        key={index}
                                                      >
                                                        {reg?.label}
                                                      </div>
                                                    )
                                                  )
                                                ) : (
                                                  <div className="font-semibold px-1.5 py-0.5 border border-black rounded-md inline-flex">
                                                    {
                                                      taskRegisterList.find(
                                                        (register) =>
                                                          register?.label
                                                            ?.toLowerCase()
                                                            .replace(
                                                              / /g,
                                                              ""
                                                            ) === "tasklist"
                                                      )?.label
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                    ) : (
                                      <div className="mt-1 text-xs font-semibold">
                                        {selectedTaskList.includes(item.id) && (
                                          <>
                                            <div className="mt-2 flex w-full">
                                              <div>
                                                <BsPeopleFill
                                                  className="text-green-500"
                                                  style={{
                                                    marginTop: "0.45rem",
                                                  }}
                                                />
                                              </div>
                                              <div className="ml-2 w-full">
                                                <CreatableSelect
                                                  options={people}
                                                  defaultValue={() =>
                                                    getAssignee(item.assignee)
                                                  }
                                                  className="focus:z-40 ml-1 flex-1"
                                                  noOptionsMessage={() =>
                                                    "No result found"
                                                  }
                                                  isValidNewOption={() => false}
                                                  menuPlacement="top"
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                  styles={selectStyle}
                                                  menuShouldScrollIntoView={
                                                    true
                                                  }
                                                  placeholder="Select Assignee"
                                                  isMulti
                                                  components={{
                                                    ClearIndicator:
                                                      CustomClearIndicator,
                                                  }}
                                                  closeMenuOnSelect={false}
                                                  blurInputOnSelect={false}
                                                  onChange={(e) =>
                                                    saveAssignee(e, item.id)
                                                  }
                                                />
                                              </div>
                                              {/* <Select
                                                    options={people}
                                                    values={getAssignee(
                                                      item.assignee
                                                    )}
                                                    className="z-40 flex-1 bg-white"
                                                    noDataRenderer={() =>
                                                      "No result found"
                                                    }
                                                    color="#8a8a8a"
                                                    dropdownPosition="top"
                                                    dropdownGap={0}
                                                    dropdownHeight="300px"
                                                    style={selectStyle}
                                                    keepSelectedInList={
                                                      true
                                                    }
                                                    placeholder="Select Assignee"
                                                    multi={true}
                                                    onChange={(
                                                      values
                                                    ) =>
                                                      saveAssignee(
                                                        values,
                                                        item.id
                                                      )
                                                    }
                                                  /> */}
                                            </div>
                                            <div>
                                              <div className="mt-2 flex w-full">
                                                <div>
                                                  <RiNumbersFill
                                                    className="text-green-500"
                                                    style={{
                                                      marginTop: "0.45rem",
                                                    }}
                                                  />
                                                </div>
                                                <div className="ml-2 w-full">
                                                  <CreatableSelect
                                                    options={[
                                                      {
                                                        value: 1,
                                                        label: 1,
                                                      },
                                                      {
                                                        value: 2,
                                                        label: 2,
                                                      },
                                                      {
                                                        value: 3,
                                                        label: 3,
                                                      },
                                                    ]}
                                                    noOptionsMessage={() =>
                                                      "No result found"
                                                    }
                                                    isValidNewOption={() =>
                                                      false
                                                    }
                                                    // defaultValue={
                                                    //   item?.priority !==
                                                    //   null
                                                    //     ? {
                                                    //         label:
                                                    //           item?.priority,
                                                    //         value:
                                                    //           item?.priority,
                                                    //       }
                                                    //     : {
                                                    //         label: 1,
                                                    //         value: 1,
                                                    //       }
                                                    // }
                                                    className="focus:z-40 flex-1 ml-1"
                                                    menuPlacement="top"
                                                    menuPortalTarget={
                                                      document.body
                                                    }
                                                    styles={selectStyle}
                                                    menuShouldScrollIntoView={
                                                      true
                                                    }
                                                    placeholder="Select Priority"
                                                    onChange={(e) =>
                                                      savePriority(
                                                        e,
                                                        item?.id,
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="mt-2 flex items-center">
                                              {showNote &&
                                              noteId === item.id ? (
                                                <>
                                                  <div
                                                    className="h-full w-full"
                                                    onClick={(e) => {
                                                      setNoteId("");
                                                      setShowNote(false);
                                                    }}
                                                  >
                                                    <p
                                                      className={
                                                        "ml-2 mt-1 font-semibold text-red-500"
                                                      }
                                                    >
                                                      Close Note
                                                    </p>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <MdDescription
                                                    size={20}
                                                    className={
                                                      "text-green-500"
                                                      // item.notes !==
                                                      // null
                                                      //   ? "text-green-500"
                                                      //   : ""
                                                    }
                                                  />
                                                  {/* <p
                                                    className={
                                                      item.description !==
                                                      null
                                                        ? "font-semibold ml-2 mt-1 text-green-500"
                                                        : "font-semibold ml-2 mt-1"
                                                    }
                                                    onClick={() => {
                                                      setNoteId(
                                                        item.id
                                                      );
                                                      setShowNote(true);
                                                    }}
                                                  >
                                                    Add Note
                                                  </p> */}
                                                  <p
                                                    className="font-poppins ml-3 w-full whitespace-normal break-words rounded-sm p-2"
                                                    style={{
                                                      border:
                                                        "solid 1px #c4c4c4",
                                                      cursor: "auto",
                                                      outlineColor:
                                                        "rgb(204, 204, 204, 0.5)",
                                                      outlineWidth: "thin",
                                                      minHeight: "35px",
                                                      //maxHeight: "35px",
                                                      overflow: "auto",
                                                      backgroundColor: "white",
                                                    }}
                                                    suppressContentEditableWarning
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        item.notes !== null
                                                          ? item.notes
                                                          : "",
                                                    }}
                                                    onBlur={(e) =>
                                                      saveNotes(item.id)
                                                    }
                                                    onInput={(e) => {
                                                      handleChangeNotes(e);
                                                      autoAdjustAllRow();
                                                    }}
                                                    contentEditable={true}
                                                  ></p>
                                                </>
                                              )}
                                            </div>
                                            {/* {showNote &&
                                              noteId === item.id && (
                                                <div
                                                  className="absolute z-50 w-11/12 p-6 -ml-3 overflow-auto overflow-x-hidden bg-white rounded shadow-2xl"
                                                  style={{
                                                    maxHeight: "120px",
                                                    minHeight: "120px",
                                                  }}
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <div
                                                    rows={4}
                                                    className={`p-2 border border-2 overflow resize-none w-full font-poppins h-max-4 py-1 ml-1 mt-1`}
                                                    suppressContentEditableWarning
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        item.description,
                                                    }}
                                                    onBlur={(e) =>
                                                      handleSaveMainDesc(
                                                        e,
                                                        item.id,
                                                        index
                                                      )
                                                    }
                                                    onInput={(e) =>
                                                      handleOnKeyupRows(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                    contentEditable={
                                                      true
                                                    }
                                                  ></div>
                                                </div>
                                              )} */}
                                          </>
                                        )}
                                        {item.clientMatters !== null &&
                                        item.clientMatters?.items.length > 0 ? (
                                          <div className="flex">
                                            <label
                                              className={
                                                selectedTaskList.includes(
                                                  item.id
                                                )
                                                  ? "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold text-green-500"
                                                  : "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold "
                                              }
                                            >
                                              Add to Tasklist
                                              <input
                                                key={item.id}
                                                className="unsaved-for-tasklist mr-1 cursor-pointer text-lg"
                                                onChange={(e) =>
                                                  handleSelectForTaskList(
                                                    e,
                                                    item.clientMatters.items
                                                      .length
                                                  )
                                                }
                                                type="checkbox"
                                                value={item.id}
                                                id={item.id}
                                                checked={selectedTaskList.includes(
                                                  item.id
                                                )}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    )}
                                  </p>
                                </div>
                                <div className="w-10 flex-none">
                                  <p className="flex">
                                    <GrAttachment className="mt-1 text-xs" />{" "}
                                    {
                                      item?.attachments?.items?.filter(
                                        (attachment) =>
                                          !attachment.details.includes(
                                            "EMAIL_PDF_VERSION"
                                          )
                                      ).length
                                    }{" "}
                                    {" >"}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </tr>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {(virtualRows?.length > 10
                    ? virtualRows
                    : [...Array(5).keys()]
                  )?.map((data, index) => {
                    let height = 310;
                    if (data.size) {
                      height = data.size;
                    }
                    return (
                      <tr
                        className="test123 block w-full p-1"
                        style={{ height: `${height}px` }}
                      >
                        <div className="h-full w-full rounded-lg border border-gray-200 bg-gray-100 p-2 pr-4">
                          <div className="h-full w-full flex flex-row gap-2">
                            <div>
                              <SkeletonTheme width={"15px"}>
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            </div>
                            <div className="flex w-full flex-col">
                              <SkeletonTheme>
                                <Skeleton className="text-xs mb-1" count={2} />
                              </SkeletonTheme>
                              <SkeletonTheme>
                                <Skeleton
                                  containerClassName="h-full grow block mb-2"
                                  className="h-full"
                                  count={1}
                                />
                              </SkeletonTheme>
                            </div>
                            <div className="gap-2 flex flex-col">
                              <SkeletonTheme width={"28px"} height={"16px"}>
                                <Skeleton className="mb-1" count={1} />
                              </SkeletonTheme>
                            </div>
                          </div>
                        </div>
                      </tr>
                    );
                  })}
                </>
              )}
              {/* iterate items here end */}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default TableSavedInfo;
