import React, { useState, useEffect, useReducer, useCallback, useRef } from "react";
import { API, Storage } from "aws-amplify";
import { Questions } from "./questions.js";
import { InvoicesData, ContractsData, BuilderDetailsData, SiteDiaryData, VariationData, ProgressPhotosData, BuildersHeaderData, ContractHeaderData, ProjectLocationData } from "./sandbox-data.js";
import MultiStepForm from "./multi-step-form.jsx";
import ToastNotification from "../../toast-notification";
import HBAImage from "./hba.png";
import { AppRoutes } from "../../../constants/AppRoutes";
import {
  setDefaultColumnBriefs,
  createContractBackground,
  createDefaultBriefs,
} from "../../../shared/HBA-Functions.jsx";
import axios from 'axios';

// temporary hide onboarding
import { useHistory } from "react-router-dom";
import { isMobileDevice, useWindowDimensions } from "../../../shared/mobileViewFunctions.jsx";



const CREATE_CLIENT_MUTATION = `mutation addClient($companyId: String, $name: String) {
  clientCreate(companyId:$companyId, name:$name) {
    id
    name
  }
}`;

const CREATE_MATTER_MUTATION = `mutation addMatter($companyId: String, $name: String) {
  matterCreate(companyId:$companyId, name:$name) {
    id
    name
  }
}`;

const CREATE_CLIENTMATTER_MUTATION = `mutation createClientMatter($companyId: String, $userId: ID, $client: ClientInput, $matter:MatterInput) {
  clientMatterCreate(companyId: $companyId, userId:$userId, client: $client, matter:$matter) {
    id
  }
}`;

const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int], $assistantId: String, $phase: [Phase]) {
  briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds, assistantId: $assistantId, phase: $phase) {
    id
    name
    date
    createdAt,
    assistantId,
    order
    columnIds
  }
}`;

const CLIENTMATTER_QUERY = `query listClientMatters($companyId: String) {
  company(id: $companyId) {
    clientMatters (sortOrder: CREATED_DESC, isDeleted:false) {
      items {
        id
        client {
          name
        }
        matter {
          name
        }
      }
    }
  }
}`;

const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput], $dynamicData: AWSJSON) {
	backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs, dynamicData: $dynamicData) {
    id
    createdAt
    date
    description
    order
    briefs {
      id
      name
    }
    dynamicData
	}
}`;

const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs{
      items {
        id
        name
        userTypeAccess
        date
        order
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        backgrounds(limit: 50, sortOrder: ORDER_ASC) {
          items {
            id
            description
            date
            dynamicData
            files{
              items{
                id
                name
              }
            }
          }
        }
        hasCommentAccess
        columnIds
        assistantId
      }
    }
  }
}`;

const UPDATE_BRIEF_PRESET_MUTATION = `mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
  briefUpdate(id: $id, columnIds: $columnIds){
    id
  }
}`;

const BACKGROUND_TABLE_QUERY = `query getBackgroundTable($clientMatterId: ID!) {
  backgroundTable(clientMatterId: $clientMatterId) {
    id
    columns {
      id
      accessorKey
      headerText
      enabled
      type
      optionsText
      order
      presets {
        id
        name
      }
    }
    createdAt
    updatedAt
  }
}`;

const BACKGROUND_TABLE_CREATE = `mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
  backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
    id
    columns {
      id
      accessorKey
      headerText
      enabled
      type
      optionsText
      order
    }
    createdAt
    updatedAt
  }
}`;

const UPDATE_BACKGROUND_TABLE_MUTATION = `mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
  backgroundTableUpdate(id: $id, input: $input) {
    id
    columns {
      id
      accessorKey
      headerText
      type
      enabled
      optionsText
      order
      presets {
        id
        name
      }
    }
  }
}`;

const qGetBackgroundFilesbyBriefID = `
	query getBackgroundFilesByBriefID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
		brief(id: $id) {
		id
		name
		backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
			items {
			id
			description
			date
			createdAt
			order
			files {
				items {
				id
				name
				details
				isGmailPDF
				isGmailAttachment
				s3ObjectKey
				order
				createdAt
				downloadURL
				}
			}
			}
			nextToken
		}
		}
	}`;

  const mCreateMatterFile = `
      mutation createMatterFile ($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int, $details: String) {
        matterFileCreate(matterId: $matterId, s3ObjectKey: $s3ObjectKey, size: $size, type: $type, name: $name, order: $order, details: $details) {
          id
          name
        }
      }
  `;

  const mTagBackgroundFile = `
  mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileTag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }
`;

const mUpdateBackground = `
mutation updateBackground($id: ID, $description: String, $date: AWSDateTime, $dynamicData: AWSJSON) {
  backgroundUpdate(id: $id, description: $description, date: $date, dynamicData: $dynamicData) {
    id
    date
    description
    dynamicData
  }
}
`;

const initialStates = Questions.reduce(
  (header, page) => {
    header[`page_${page.order}`] = {
      headerText: page.mainHeader,
      type: page.type,
      questions: page.questions,
      order: page.order,
      style: page.style,
      subheader: page.subheader,
      category: page.category,
      redirectTo: page.redirectTo,
      // answers: ansTemp ? JSON.parse(ansTemp) : [],
      properties: page.properties,
      answers: [],
      image: page.image,
    };
    return header;
  },
  {
    Progress: "page 1",
  }
);

const updateAnswers = (state, action) => {
  if (
    state[action.page].answers.length > 0 &&
    state[action.page].answers.some(
      (item) => item.order === action.payload.order
    )
  ) {
    return (() => {
      if (
        action.inputType !== "input" &&
        action.inputType !== "textarea" &&
        action.inputType !== "datepicker" &&
        action.inputType !== "uploadBox" &&
        action.inputType !== "dropdown"
      ) {
        return {
          ...state[action.page],
          answers: state[action.page].answers.filter(
            (item) =>
              item.order !== action.payload.order &&
              !item?.answer?.includes("Skip")
          ),
        };
      } else {
        return {
          ...state[action.page],
          answers: state[action.page].answers.map((item) => {
            if (item.order === action.payload.order) {
              console.log("here", action.payload);
              return action.payload;
            }
            console.log("reached", item);
            return item;
          }),
        };
      }
    })(action.payload);
  } else {
    return (() => {
      if (
        typeof eventAnswer === "string" &&
        (action.payload.answer?.includes("Skip") ||
          state[action.page].answers.some((x) => x?.answer?.includes("Skip")))
      ) {
        return {
          answers: [action.payload],
        };
      } else {
        return {
          ...state[action.page],
          answers: [...state[action.page].answers, action.payload],
        };
      }
    })(action.payload);
  }
};

function reducer(state, action) {
  switch (action.type) {
    /* ACTIONS FOR STATE */
    case "RESET_STATE": {
      return action.payload;
    }
    // case "CHANGE_STATE": {
    //   let newState = action.payload;
    //   localStorage.setItem(
    //     "main_onboarding_state",
    //     btoa(encodeURIComponent(JSON.stringify(newState)))
    //   );
    //   console.log("Mainonboarding>>>", )
    //   return newState;
    // }

    /* ACTIONS FOR PROGRESS */
    case "CHANGE_PROGRESS": {
      return { ...state, Progress: action.payload };
    }

    /* ACTIONS FOR EMAIL */
    case "CHANGE_EMAIL": {
      return { ...state, Email: action.payload };
    }

    case "CHANGE_MAIN_ANSWER": {
      // console.log("ANSWER PAYLOAD ", action.payload);
      // console.log("CONDITIONAL ", state[action.page].answers);
      let newState = {
        ...state,
        [action.page]: {
          ...state[action.page],
          answers: [action.payload],
        },
      };

      // localStorage.setItem(
      //   "main_onboarding_state",
      //   btoa(encodeURIComponent(JSON.stringify(newState)))
      // );
      return newState;
    }
    case "CHANGE_MAIN_ANSWER_MULTIPLE": {

      var temp;

      //Check if action is meant to delete item in object answer
      if (
        action.payload.answer.isRemove &&
        action.payload.answer.isRemove !== undefined &&
        action.payload.answer.isRemove === true
      ) {
        //Get details of file to remove in object
        const toRemoveFileName = action.payload.answer.fileName;
        const toRemoveFileKey = action.payload.answer.fileKey;
        let ActionPayloadAnswers = {
          ...action.payload.answer,
        };

        //delete unnecessary keys in object after cloning
        delete ActionPayloadAnswers.fileName;
        delete ActionPayloadAnswers.isRemove;
        delete ActionPayloadAnswers.fileKey;

        //Filter for objects
        Object.filter = (obj, predicate) =>
          Object.fromEntries(Object.entries(obj).filter(predicate));
        var filteredActionAnswer = Object.filter(
          ActionPayloadAnswers,
          ([fileItem, item]) => fileItem !== toRemoveFileKey
        );

        //Upon Filtering arrange the names of answer files
        var arrangeNamingConvention = {};
        Object.keys(filteredActionAnswer).forEach(function (key, index) {
          arrangeNamingConvention[`file_${index + 1}`] =
            filteredActionAnswer[key];
        });

        //Populated finished filtering & pass it updateState function
        let filteredAction = {
          ...action,
          payload: {
            ...action.payload,
            answer: {
              ...arrangeNamingConvention,
            },
          },
        };

        temp = updateAnswers(state, filteredAction);
      }

      //Check if action is doesnt want to delete item in object answer
      if (
        !action.payload.answer.isRemove &&
        action.payload.answer.isRemove === undefined
      ) {
        temp = updateAnswers(state, action);
      }

      let newState = {
        ...state,
        [action.page]: temp,
      };

      // if (action.inputType !== "uploadBox") {
      //   localStorage.setItem(
      //     "main_onboarding_state",
      //     btoa(encodeURIComponent(JSON.stringify(newState)))
      //   );
      // }
      return newState;
    }
    case "CHANGE_COREQUISITE_ANSWER": {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          answers: state[action.page].answers.map((answer) => {
            if (answer.order === action.answerIndex) {
              return {
                ...answer,
                corequisite: Array.isArray(answer.corequisite)
                  ? answer.corequisite.some(
                      (x) => x.order === action.payload.order
                    )
                    ? answer.corequisite.map((corequisiteAnswer) => {
                        if (corequisiteAnswer.order === action.payload.order) {
                          return action.payload;
                        }
                        return corequisiteAnswer;
                      }) // Already exists, don't change
                    : [...answer.corequisite, action.payload]
                  : [action.payload], // Initialize as an array if not already
              };
            }
            return answer; // Keep other answers unchanged
          }),
        },
      };
    }
    case "CHANGE_MAIN_OPTIONS": {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          options: [...action.payload],
        },
      };
    }
    default:
      return state;
  }
}

export default function GettingStarted() {
  const [state, dispatch] = useReducer(reducer, initialStates);
  const page = state?.Progress;
  const pageURL = window.location.href.split("/");
  const pageNumber = pageURL[pageURL.length - 1];
  const [clientMatterId, setClientMatterId] = useState(null);
  const [defaultList, setDefaultList] = useState(null);
  const defaultColumnIds = [0, 1, 2, 3, 4];

  const [builderDetailsBrief, setBuilderDetailsBrief] = useState();
  const [contractDetailsBrief, setContractDetailsBrief] = useState();
  const [variationsBrief, setVariationsBrief] = useState();
  const [provisionalSumBrief, setProvisionalSumBrief] = useState();
  const [budgetBrief, setBudgetBrief] = useState();
  const [invoicesBrief, setInvoicesBrief] = useState();
  const [progressOfWorkBrief, setProgressOfWorkBrief] = useState();
  const [progressPhotoBrief, setProgressPhotoBrief] = useState();
  const [projectLocationBrief, setProjectLocationBrief] = useState();
  const [contractsBrief, setContractsBrief] = useState();
  const [contractsBriefFiles, setContractsBriefFiles] = useState();
  const [siteDiaryBrief, setSiteDiaryBrief] = useState();

  const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");
  const moment = require("moment");
  const currentDate = moment(new Date(), "YYYY-MM-DD").toISOString();

  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  let history = useHistory();

  const fileList = [
    "Contract Terms And Conditions",
    "Architectural Drawings",
    "Engineer Drawings",
    "Specifications",
    "Builder's Insurance",
  ];


  useEffect(() => {
    console.log("PAGE NUMBER", pageNumber);
    getClientMatters();
    getProgress();
    setupSandbox();
  }, []);

  const getProgress = () => {
    let mainState = localStorage.getItem("main_onboarding_state");
    if (!mainState) {
      localStorage.setItem(
        "main_onboarding_state",
        btoa(encodeURIComponent(JSON.stringify(state)))
      );
    } else {
      let newState = decodeURIComponent(atob(mainState));
      dispatch({
        type: "CHANGE_STATE",
        payload: {
          ...JSON.parse(newState),
        },
      });
    }
  };

  // Start Sandbox Functions
  const createSandboxClient = async () => {
    const createClient = await API.graphql({
      query: CREATE_CLIENT_MUTATION,
      variables: {
        companyId: companyId,
        name: "Sandbox",
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    console.log("Sandbox Client Created:", createClient.data.clientCreate);
    return createClient.data.clientCreate;
  };

  const createSandboxMatter = async () => {
    const companyId = localStorage.getItem("companyId");
    const createMatter = await API.graphql({
      query: CREATE_MATTER_MUTATION,
      variables: {
        companyId: companyId,
        name: "Sandbox",
      },
    }).catch((error) => {
      console.error("Error while creating matter", error);
      throw error;
    });

    console.log("Sandbox Matter Created:", createMatter.data.matterCreate);
    return createMatter.data.matterCreate;
  };

  const createSandboxClientMatter = async (client, matter) => {
    const createClientMatter = await API.graphql({
      query: CREATE_CLIENTMATTER_MUTATION,
      variables: {
        companyId: companyId,
        userId: userId,
        client: client,
        matter: matter,
      },
    }).catch((error) => {
      console.error("Error while creating client matter", error);
      throw error;
    });

    console.log(
      "Sandbox Client / Matter Created:",
      createClientMatter.data.clientMatterCreate.id
    );
    localStorage.setItem("sandboxId", createClientMatter.data.clientMatterCreate.id);

    return createClientMatter.data.clientMatterCreate.id;
  };

  const createSandboxBackground = async (clientMatterId) => {
    console.group("createSandboxBackground");
    console.log("CREATING SANDBOX BACKGROUND", clientMatterId);
    let {
      Builder_Details,
      Contract_Details,
      Variations,
      Provisional_Sum,
      Budget,
      Invoices,
      Progress_Photos,
      Progress_of_work,
      Project_Location,
      Site_Diary,
      Contracts,
    } = await createDefaultBriefs(clientMatterId, currentDate);

    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      console.log("briefsList For Sandbox", briefsList);

      setDefaultBriefColumnIds(sortByOrder(briefsList), clientMatterId);

      // Filter briefsList where brieflist.name === "Site Diary"
      const siteDiaryBriefs = briefsList.filter(brieflist => brieflist.name === "Site Diary");

      if (siteDiaryBriefs.length > 0) {
        const brief = siteDiaryBriefs[0];

        createDefaultBackgroundData(SiteDiaryData, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged Site Diary Briefs found");
      }

      // Filter briefsList where brieflist.name === "Invoices"
      const invoicesBriefs = briefsList.filter(brieflist => brieflist.name === "Invoices");

      if (invoicesBriefs.length > 0) {
        const brief = invoicesBriefs[0];

        createDefaultBackgroundData(InvoicesData, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged Invoices Briefs found");
      }

      // Filter briefsList where brieflist.name === "Builder Details"
      const builderDetailsBriefs = briefsList.filter(brieflist => brieflist.name === "Builder Details");
      if (builderDetailsBriefs.length > 0) {
        const brief = builderDetailsBriefs[0];
        const backgroundId = brief.backgrounds && brief.backgrounds.items.length > 0 ? brief.backgrounds.items[0].id : null;

        updateDefaultBackgroundData(BuildersHeaderData, backgroundId, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged BuilderDetails Briefs found");
      }

      // Filter briefsList where brieflist.name === "Contract Details"
      const contractDetailsBriefs = briefsList.filter(brieflist => brieflist.name === "Contract Details");

      if (contractDetailsBriefs.length > 0) {
        const brief = contractDetailsBriefs[0];
        const backgroundId = brief.backgrounds && brief.backgrounds.items.length > 0 ? brief.backgrounds.items[0].id : null;

        updateDefaultBackgroundData(ContractHeaderData, backgroundId, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged BuilderDetails Briefs found");
      }

      // Filter briefsList where brieflist.name === "Contracts"
      const contractsBriefs = briefsList.filter(brieflist => brieflist.name === "Contracts");

      if (contractsBriefs.length > 0) {
        const brief = contractsBriefs[0];
        createDefaultBackgroundData(ContractsData, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged Contracts Briefs found");
      }

      // Filter briefsList where brieflist.name === "Variation"
      const variationBriefs = briefsList.filter(brieflist => brieflist.name === "Variations");

      if (variationBriefs.length > 0) {
        const brief = variationBriefs[0];
        createDefaultBackgroundData(VariationData, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged Variations found");
      }

      // Filter briefsList where brieflist.name === "ProgressPhotos"
      const progressPhotosBriefs = briefsList.filter(brieflist => brieflist.name === "Progress Photos");

      if (progressPhotosBriefs.length > 0) {
        const brief = progressPhotosBriefs[0];
        createDefaultBackgroundData(ProgressPhotosData, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged ProgressPhotos found");
      }

      // Filter briefsList where brieflist.name === "Project Location"
      const projectLocationBriefs = briefsList.filter(brieflist => brieflist.name === "Project Location");

      if (projectLocationBriefs.length > 0) {
        const brief = projectLocationBriefs[0];
        createDefaultBackgroundData(ProjectLocationData, brief.id, brief.name, clientMatterId);
      } else {
        console.error("No tagged Project Location found");
      }

    });
  };


  const createDefaultBackgroundData = async (array, briefId, briefName, clientMatterId) => {
      try {
          const results = await Promise.all(array.map(async (item) => {
              const saveBackground = await API.graphql({
                  query: mCreateBackground,
                  variables: {
                      briefs: [{ id: briefId, name: briefName }],
                      date: item.date,
                      description: item.description,
                      dynamicData: item.dynamicData,
                      order: item.order,
                  },
              });

              const backgroundId = saveBackground.data.backgroundCreate.id;

              const uploadedFilesData = await uploadFilesToS3(item.files, backgroundId, clientMatterId);
          }));

          return results;
      } catch (error) {
          console.error("Error:", error);
          return error;
      }
  };

  const updateDefaultBackgroundData = async (array, backgroundId, briefId, briefName, clientMatterId) => {
    console.log("Background ID >>> ", backgroundId);
    try {
        const results = await Promise.all(array.map(async (item) => {
            const saveBackground = await API.graphql({
                query: mUpdateBackground,
                variables: {
                    id: backgroundId,
                    date: item.date,
                    description: item.description,
                    dynamicData: item.dynamicData,
                },
            });

            console.log(saveBackground)

            const uploadedFilesData = await uploadFilesToS3(item.files, backgroundId, clientMatterId);

            console.log("All files uploaded:", uploadedFilesData);
        }));

        return results;
    } catch (error) {
        console.error("Error:", error);
        return error;
    }
};

  const uploadFilesToS3 = async (files, backgroundId, clientMatterId) => {
      const uploadedFilesData = [];

      console.log("files", files);

      for (const file of files ? files.items : []) {
          if (!file || !file.name.trim()) continue; // Check if file name is blank

          try {

              const fileContent = await getFileContent(file.name);
              const uploadedFile = new File([fileContent], file.name);

              const uploadResult = await handleUploadFiles(uploadedFile, 0, clientMatterId);

              const createMatterFile = await API.graphql({
                  query: mCreateMatterFile,
                  variables: {
                      matterId: clientMatterId,
                      ...uploadResult,
                  },
              });

              uploadedFilesData.push(createMatterFile?.data?.matterFileCreate);

          } catch (error) {
              console.error("Error uploading file to S3:", error);
              throw error;
          }
      }

      const filesForTagging = uploadedFilesData.map((file) => ({
          id: file.id,
          name: file.name
      }));

      // Tag all uploaded files for this backgroundId in one mutation
      const taggedFilesResult = await API.graphql({
          query: mTagBackgroundFile,
          variables: {
              backgroundId: backgroundId,
              files: filesForTagging,
          },
      }).catch((error) => {
          console.error(`An error occurred while tagging background files for backgroundId ${backgroundId}:`, error);
          // You may choose to handle errors here or simply return null
          return null;
      });

      // console.log("Uploaded Files Data:", taggedFilesResult);

      return uploadedFilesData;
  };

  const handleUploadFiles = async (file, index, clientMatterId) => {
      try {
          const extension = file?.name?.split(".").pop();
          let type;

          switch (extension.toLowerCase()) {
              case "docx":
                  type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                  break;
              case "pdf":
                  type = "application/pdf";
                  break;
              case "jpg":
              case "jpeg":
                  type = "image/jpeg";
                  break;
              case "png":
                  type = "image/png";
                  break;
              default:
                  type = file.type;
                  break;
          }

          const sanitizedFilename = file?.name
              ?.replaceAll(/\s/g, "")
              ?.replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "");

          const key = `${clientMatterId}/${Number(new Date())}${sanitizedFilename}`;

          const fd = await Storage.put(key, file, {
              contentType: type,
              errorCallback: (err) => {
                  console.error("204: Unexpected error while uploading", err);
              },
          });

          var fileData = {
              s3ObjectKey: fd.key,
              size: parseInt(file.size),
              type: type,
              name: file?.name,
              order: index,
          };

          return fileData;
      } catch (e) {
          console.error("Unexpected error while uploading:", e);
      }
  };

  const getFileContent = async (fileName) => {
      try {
          const response = await fetch(`https://mma-public-assets-dev.s3.ap-southeast-1.amazonaws.com/sandbox-files/${fileName}`);
          if (!response.ok) {
              throw new Error(`File not found: ${fileName}`);
          }
          const blob = await response.blob();
          // console.log("blob", blob);
          // Decode the file name
          const decodedFileName = decodeURIComponent(fileName);
          const file = new File([blob], decodedFileName);
          return file;
      } catch (error) {
          console.error("Error getting file content:", error);
          throw error;
      }
  };

  // Call the functions to create Sandbox client, matter, associate them, and set default briefs and columns
  const setupSandbox = async () => {
    const sandboxClient = await createSandboxClient();
    const sandboxMatter = await createSandboxMatter();
    const sandboxClientMatter = await createSandboxClientMatter(sandboxClient, sandboxMatter);
    await createSandboxBackground(sandboxClientMatter);
  };
  // End Sandbox Functions

  const getClientMatters = async () => {
    const clientMatters = await API.graphql({
      query: CLIENTMATTER_QUERY,
      variables: {
        companyId: companyId,
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    const clientMattersResult =
      clientMatters?.data?.company?.clientMatters?.items;
    console.log("clientMattersResult", clientMattersResult);
    if (clientMattersResult.length === 0) {
      localStorage.removeItem("answerpage_1");
      localStorage.removeItem("answerpage_2");
      localStorage.removeItem("answerpage_3");
      localStorage.removeItem("answerpage_4");
      localStorage.removeItem("answerpage_5");
      localStorage.removeItem("clientMatterId");
      console.log("No Client Matters, ");
      console.log("Proceed to generating default client matter");
      createClient().then((client) => {
        createMatter().then((matter) => {
          createClientMatter(client, matter).then((cmID) => {
            setClientMatterId(cmID);
            localStorage.setItem("clientMatterId", cmID);
            createDefaultBackground(cmID);
          });
        });
      });
    } else {
      console.log(localStorage.getItem("answerpage_1"));
      console.log(localStorage.getItem("clientMatterId"));
      console.log("clientMattersResult", clientMattersResult);

      // Check if client matter is sandbox
      if (clientMattersResult[0].client.name == "Sandbox") {
        console.log(clientMattersResult[0].id);
        setClientMatterId(clientMattersResult[0].id);
        localStorage.setItem("clientMatterId", clientMattersResult[0].id);
        console.log(clientMatterId);

        const params = {
          query: BRIEFS_QUERY,
          variables: {
            id: clientMattersResult[0].id,
          },
        };

        console.log(params);

        await API.graphql(params).then(async (brief) => {
          const briefsList = brief?.data?.clientMatter?.briefs?.items;
          console.log("briefsList from up", briefsList);
          setDefaultList(briefsList);
          setDefaultBriefColumnIds(
            sortByOrder(briefsList),
            clientMattersResult[0].id
          );

          console.log(defaultList);
          console.log(briefsList);

          const progressPhotoIndex = briefsList.findIndex(
            (item) => item.name === "Progress Photos"
          );
          let rowArray = [];
          if (progressPhotoIndex !== -1) {
            setProgressPhotoBrief(briefsList[progressPhotoIndex]);

            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[progressPhotoIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;
            let retrievedRow = brief.brief.backgrounds.items;

            // Filter the retrievedRow array to only contain rows with images
            if (retrievedRow.length > 0) {
              const filteredItems = retrievedRow.filter((item) => {
                // Check if at least one file in the files array is an image
                return item.files.items.some((file) =>
                  isImageFile(file.s3ObjectKey)
                );
              });
            }
          }

          const projectLocationIndex = briefsList.findIndex(
            (item) => item.name === "Project Location"
          );
          if (projectLocationIndex === -1) {
            // ADDED TEMPORARY CODE TO ADD BRIEF TO ONGOING TESTING ACCOUNTS
            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                name: "Project Location",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
                phase:[
                  "PRECONSTRUCTION", "CONSTRUCTION", "HANDOVER"
                ]
              },
            });
            const params = {
              query: BRIEFS_QUERY,
              variables: {
                id: clientMatterId,
              },
            };

            await API.graphql(params).then((brief) => {
              const briefsList = brief?.data?.clientMatter?.briefs?.items;
              const projectLocationIndex = briefsList.findIndex(
                (item) => item.name === "Project Location"
              );
              setProjectLocationBrief(briefsList[projectLocationIndex]);
            });
          } else {
            setProjectLocationBrief(briefsList[projectLocationIndex]);
            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[projectLocationIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;

            if (brief.brief.backgrounds?.items?.length > 0) {
              rowArray.push(brief.brief.backgrounds.items);
              console.log(
                "setting project location photo",
                brief.brief.backgrounds.items[0]
              );
            }
          }

          setContractsBrief(briefsList[0]);
          const response = await API.graphql({
            query: qGetBackgroundFilesbyBriefID,
            variables: {
              id: briefsList[0].id,
              limit: null,
              nextToken: null,
              sortOrder: "ORDER_ASC",
            },
          });
          console.log(response.data);

          const contractFiles = [];
          fileList.map((file, index) => {
            contractFiles.push({
              id: response.data.brief.backgrounds.items[index]?.id,
              order: response.data.brief.backgrounds.items[index]?.order,
            });
          });
          setContractsBriefFiles(contractFiles);

          setContractDetailsBrief(briefsList[7]);
          setProgressOfWorkBrief(briefsList[3]);
          setBuilderDetailsBrief(briefsList[8]);

          setSiteDiaryBrief(briefsList[1]);
          setProgressPhotoBrief(briefsList[4]);
          setInvoicesBrief(briefsList[5]);
          setVariationsBrief(briefsList[6]);
        });
      }

      // Check if client matter is default
      if (clientMattersResult[0].client.name == "Default") {
        console.log(clientMattersResult[0].id);
        setClientMatterId(clientMattersResult[0].id);
        localStorage.setItem("clientMatterId", clientMattersResult[0].id);
        console.log(clientMatterId);

        const params = {
          query: BRIEFS_QUERY,
          variables: {
            id: clientMattersResult[0].id,
          },
        };

        console.log(params);

        await API.graphql(params).then(async (brief) => {
          const briefsList = brief?.data?.clientMatter?.briefs?.items;
          console.log("briefsList from up", briefsList);
          setDefaultList(briefsList);
          setDefaultBriefColumnIds(
            sortByOrder(briefsList),
            clientMattersResult[0].id
          );

          console.log(defaultList);
          console.log(briefsList);

          const progressPhotoIndex = briefsList.findIndex(
            (item) => item.name === "Progress Photos"
          );
          let rowArray = [];
          if (progressPhotoIndex !== -1) {
            setProgressPhotoBrief(briefsList[progressPhotoIndex]);

            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[progressPhotoIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;

            let retrievedRow = brief.brief.backgrounds.items;

            // Filter the retrievedRow array to only contain rows with images
            if (retrievedRow.length > 0) {
              const filteredItems = retrievedRow.filter((item) => {
                // Check if at least one file in the files array is an image
                return item.files.items.some((file) =>
                  isImageFile(file.s3ObjectKey)
                );
              });

            }
          }

          const projectLocationIndex = briefsList.findIndex(
            (item) => item.name === "Project Location"
          );
          if (projectLocationIndex === -1) {
            // ADDED TEMPORARY CODE TO ADD BRIEF TO ONGOING TESTING ACCOUNTS
            await API.graphql({
              query: CREATE_BRIEF_MUTATION,
              variables: {
                clientMatterId: clientMatterId,
                name: "Project Location",
                date: moment
                  .utc(moment(new Date(), "YYYY-MM-DD"))
                  .toISOString(),
                order: 0,
                columnIds: [0, 1, 2, 3, 4],
                phase:[
                  "PRECONSTRUCTION", "CONSTRUCTION", "HANDOVER"
                ]
              },
            });
            const params = {
              query: BRIEFS_QUERY,
              variables: {
                id: clientMatterId,
              },
            };

            await API.graphql(params).then((brief) => {
              const briefsList = brief?.data?.clientMatter?.briefs?.items;
              const projectLocationIndex = briefsList.findIndex(
                (item) => item.name === "Project Location"
              );
              setProjectLocationBrief(briefsList[projectLocationIndex]);
            });
          } else {
            setProjectLocationBrief(briefsList[projectLocationIndex]);
            const response = await API.graphql({
              query: qGetBackgroundFilesbyBriefID,
              variables: {
                id: briefsList[projectLocationIndex].id,
                limit: null,
                nextToken: null,
                sortOrder: "DATE_DESC",
              },
            });
            let brief = response.data;

            if (brief.brief.backgrounds?.items?.length > 0) {
              rowArray.push(brief.brief.backgrounds.items);
              console.log(
                "setting project location photo",
                brief.brief.backgrounds.items[0]
              );
              //setProjectLocationPhoto(brief.brief.backgrounds.items[0]);
            }
          }

          setContractsBrief(briefsList[0]);
          const response = await API.graphql({
            query: qGetBackgroundFilesbyBriefID,
            variables: {
              id: briefsList[0].id,
              limit: null,
              nextToken: null,
              sortOrder: "ORDER_ASC",
            },
          });
          console.log(response.data);

          const contractFiles = [];
          fileList.map((file, index) => {
            contractFiles.push({
              id: response.data.brief.backgrounds.items[index]?.id,
              order: response.data.brief.backgrounds.items[index]?.order,
            });
          });
          setContractsBriefFiles(contractFiles);

          setContractDetailsBrief(briefsList[7]);
          setProgressOfWorkBrief(briefsList[3]);
          setBuilderDetailsBrief(briefsList[8]);

          setSiteDiaryBrief(briefsList[1]);
          setProgressPhotoBrief(briefsList[4]);
          setInvoicesBrief(briefsList[5]);
          setVariationsBrief(briefsList[6]);
        });
      }
    }
  };

  const isImageFile = (fileName) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = fileName
      .toLowerCase()
      .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    return imageExtensions.includes("." + extension);
  };



  const createClient = async () => {
    const createClient = await API.graphql({
      query: CREATE_CLIENT_MUTATION,
      variables: {
        companyId: companyId,
        name: "Default",
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    console.log("Default Client Created:", createClient.data.clientCreate);
    return createClient.data.clientCreate;
  };

  const createMatter = async () => {
    const companyId = localStorage.getItem("companyId");
    const createMatter = await API.graphql({
      query: CREATE_MATTER_MUTATION,
      variables: {
        companyId: companyId,
        name: "Default",
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    console.log("Default Matter Created:", createMatter.data.matterCreate);
    return createMatter.data.matterCreate;
  };

  const createClientMatter = async (client, matter) => {
    const createClientMatter = await API.graphql({
      query: CREATE_CLIENTMATTER_MUTATION,
      variables: {
        companyId: companyId,
        userId: userId,
        client: client,
        matter: matter,
      },
    }).catch((error) => {
      console.error("Error while creating client", error);
      throw error;
    });

    console.log(
      "Default Client / Matter Created:",
      createClientMatter.data.clientMatterCreate.id
    );
    return createClientMatter.data.clientMatterCreate.id;
  };

  //BACKGROUNDS: VARIATIONS, PROGRESS CLAIMS, PROGRESS PHOTOS, DELAY CLAIMS, CONTRACTS, PROJECT LOCATION
  const createDefaultBackground = async (clientMatterId) => {
    console.group("createDefaultBackground");
    console.log("CREATING DEFAULT BACKGROUND", clientMatterId);
    let {
      Builder_Details,
      Contract_Details,
      Variations,
      Provisional_Sum,
      Budget,
      Invoices,
      Progress_Photos,
      Progress_of_work,
      Project_Location,
      Site_Diary,
      Contracts,
    } = await createDefaultBriefs(clientMatterId, currentDate);

    setBuilderDetailsBrief(Builder_Details);
    setContractDetailsBrief(Contract_Details);
    setVariationsBrief(Variations);
    setProvisionalSumBrief(Provisional_Sum);
    setBudgetBrief(Budget);
    setInvoicesBrief(Invoices);
    setProgressPhotoBrief(Progress_Photos);
    setProgressOfWorkBrief(Progress_of_work);
    setProjectLocationBrief(Project_Location);
    setSiteDiaryBrief(Site_Diary);
    setContractsBrief(Contracts);

    let files = await createContractBackground(Contracts);

    setContractsBriefFiles(files);

    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then((brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      console.log("briefsList", briefsList);
      setDefaultList(briefsList);
      setDefaultBriefColumnIds(sortByOrder(briefsList), clientMatterId);
    });
  };

  const sortByOrder = (arr) => {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }
    return sort;
  };

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    console.log("SETTING DEFAULT COLS", briefs);

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        console.log("columnIds", columnIds);
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);

      console.log("updatedBriefIds", updatedBriefIds);
      const newDefaultColumn = updatedBriefIds?.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      console.log("Background Table for", clientMatterId, backgroundTable);

      if (backgroundTable?.data?.backgroundTable) {
        await setDefaultColumnBriefs(
          backgroundTable?.data?.backgroundTable,
          briefs
        );
      } else {
        console.log("Create Background Table for ", clientMatterId);

        const initialPresets = [];
        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          initialPresets.push(preset);
        }

        let backgroundCreate = await API.graphql({
          query: BACKGROUND_TABLE_CREATE,
          variables: {
            clientMatterId: clientMatterId,
            initialPresets: initialPresets,
          },
        });
        await setDefaultColumnBriefs(
          backgroundCreate?.data?.backgroundTableCreate,
          briefs
        );
      }
      // }
    } catch (error) {
      console.error(error);
    }
  }




  const dataWrapper = {
    questions: Questions,
    initialStates: initialStates,
    state: state,
    dispatch: dispatch,
    reducer: reducer,
    builderDetailsBrief: builderDetailsBrief,
    contractDetailsBrief: contractDetailsBrief,
    variationsBrief: variationsBrief,
    invoicesBrief: invoicesBrief,
    progressOfWorkBrief: progressOfWorkBrief,
    progressPhotoBrief: progressPhotoBrief,
    projectLocationBrief: projectLocationBrief,
    contractsBrief: contractsBrief,
    contractsBriefFiles: contractsBriefFiles,
    siteDiaryBrief: siteDiaryBrief,
    page: page,
    pageNumber: pageNumber,
    clientMatterId: clientMatterId,
    showToast: showToast,
    setShowToast: setShowToast,
    setAlertMessage: setAlertMessage,
  };

  const [saving, setSaving] = useState(false);

  const [forceRedirect, setForceRedirect] = useState(
    localStorage.getItem("onboardingProgress")
  );

  useEffect(() => {
    console.log(
      "onboardingProgress",
      localStorage.getItem("onboardingProgress")
    );
    if (forceRedirect === true) {
      setForceRedirect(true);
      console.log("done woth onboarding");
      history.push(`${AppRoutes.HBADASHBOARD}`);
    }
  }, [forceRedirect]);

  const { height, width } = useWindowDimensions();

 

 

  const sendEmail = async () => {
        // Trigger email sending
        const fullUrl = window.location.href;
      
        const bodyTemplate = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
              <div style="background-color:rgb(229,231,235);height:100vh;padding-top:2.5rem">
                <table align="center" width="100%" data-id="__react-email-container" role="presentation" cellSpacing="0" cellPadding="0" border="0" style="max-width:54em;background-color:rgb(255,255,255);padding-left:1rem;padding-right:1rem;padding-top:1.5rem">
                  <tbody>
                    <tr style="width:100%">
                      <td><img data-id="react-email-img" alt="Home Building App" src="https://www.contractsspecialist.com.au/wp-content/uploads/2023/02/hbaapp-logo.png" width="160" style="display:block;outline:none;border:none;text-decoration:none;margin:auto;padding-top:2.5rem" /><br />
                        <p data-id="react-email-text" style="font-size:14px;line-height:24px;margin:16px 0;color:rgb(31,41,55)">Hi {given_name},<br /><br />You’re almost there! Just a few more steps to complete your setup and fully benefit from HBApp.<br /><br />🛠️ <b>Complete Your Setup:</b><br />Unlock all the features and streamline your project management.<br /><a href="${fullUrl}" data-id="react-email-link" target="_blank" style="color:rgb(24,64,103);text-decoration:none"><b>Complete Setup</b></a><br /><br />If you have any questions or need help, don’t hesitate to contact our support team at <a href="mailto:hello@homebuilding.app" data-id="react-email-link" target="_blank" style="color:rgb(24,64,103);text-decoration:none">hello@homebuilding.app</a><br /><br /><br />Warm regards,<br />The HBApp Team<br /><br /></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table align="center" width="100%" data-id="__react-email-container" role="presentation" cellSpacing="0" cellPadding="0" border="0" style="max-width:54em;background-color:rgb(243,244,246);padding-left:0.5rem;padding-right:0.5rem;padding-bottom:0.5rem;padding-top:1rem">
                  <tbody>
                    <tr style="width:100%">
                      <td><img data-id="react-email-img" alt="Home Building App" src="https://www.contractsspecialist.com.au/wp-content/uploads/2023/02/hbaapp-logo.png" width="90" style="display:block;outline:none;border:none;text-decoration:none;margin:auto" />
                        <p data-id="react-email-text" style="font-size:0.75rem;line-height:1rem;margin:16px 0;text-align:center;color:rgb(75,85,99)">© 2024 Home Building App<br />Project Management Software for Homeowners, Owner Builders, and Architect Superintendents</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>`;

              const emailData = {
                fromName: "HBApp Team",
                fromEmail: process.env.REACT_APP_EMAIL_SENDER,
                to: [localStorage.getItem("email")],
                subject: "📋 Let's Complete Your HBApp Onboarding!",
                body: bodyTemplate
                  .replace("{given_name}", localStorage.getItem("firstName")),
                  // .replace("{redirect_link}", window.location.href),
                bcc: [
                  "no-reply@homebuilding.app", 
                  "hello@homebuilding.app",
                  "john@homebuilding.app",
                  "dev@lophils.com"
                ],
              };

    try {
      console.log("body>>", bodyTemplate)
      const response = await axios.post(
        process.env.REACT_APP_EMAIL_SENDER_ENDPOINT,
        emailData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log('Email sent:', response.data);
      console.log("URL>>>", window.location.href)
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  // KJMF SHOWS POPUP WHEN USER EXITS PAGE
  // useEffect(async () => {
  //     const handleBeforeUnload = async (e) => {
  //       // const confirmationMessage = 'Are you sure you want to leave? Any unsaved changes will be lost.';
  //       // e.preventDefault();
  //       // e.returnValue = confirmationMessage; // This line is required for some browsers
      
  //       // if (window.confirm(confirmationMessage)) {
  //         sendEmail();
  //         console.log("Email Sent!")
  //       // }
        
  //       // return confirmationMessage;
  //     }

  //       window.addEventListener('beforeunload', handleBeforeUnload);
    
  //       return () => {
  //         window.removeEventListener('beforeunload', handleBeforeUnload);
  //       };
     
  // }, []);
 
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Trigger the email sending function
  //     sendEmail();

  //     // Set the event.returnValue property to show a warning dialog
  //     // Note: In most modern browsers, this message is not customizable.
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // useEffect(() => {

  //   const handleBeforeUnload = (event) => {
  //     sendEmail();
  //     // The following is necessary for some older browsers
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const [isLeaving, setIsLeaving] = useState(false);

  useEffect(() => {

    const handleBeforeUnload = (event) => {
      sendEmail();
      console.log("Email sent >>>>")
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  return (
    <div className="flex flex-col gap-5 py-5 relative h-full">
      <div className={`mx-auto w-3/4 px-8 ${isMobileDevice(width) ? "flex justify-center" : ""} `}>

        {/* <button onClick={()=>sendEmail(emailData)}>Test</button> */}
        <img
          className="object-scale-down h-12 w-32"
          src={process.env.REACT_APP_HEADER_LOGO}
          alt="app-logo"
        />
      </div>
      <MultiStepForm dataWrapper={dataWrapper} />

      {showToast && (
        <div className="absolute">
          <ToastNotification title={alertMessage} error={true} />
        </div>
      )}
    </div>
  );
}
