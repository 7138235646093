import React, { useState, componentDidUpdate, useEffect } from "react";
import Switch from "react-switch";
import { usePopper } from "react-popper";
import { FaChevronDown } from "react-icons/fa";
import { AiOutlineLoading3Quarters, AiOutlineDownload } from "react-icons/ai";
import { CircularProgressbar } from "react-circular-progressbar";

import { API } from "aws-amplify";

const BACKGROUND_FILES_EXPORT_MUTATION = `
mutation backgroundFilesExport($briefId: ID!, $input: BackgroundFilesExportInput!) {
  backgroundFilesExport(briefId: $briefId, input: $input)
}
`;
const ON_EXPORT_PUBLLISHED = `
subscription onBackgroundFilesExportResultPublished($executionArn: String) {
  onBackgroundFilesExportResultPublished(executionArn: $executionArn){
    briefId
    exportDownloadUrl
    unsupportedFiles
  }
}
`;
const mCreateActivity = `
mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
	activity: $activity
	briefId: $briefId
	clientMatterId: $clientMatterId
	companyId: $companyId
	previous: $previous
	field: $field
	current: $current
	appModule: $appModule
	rowId: $rowId
  ) {
	id
  }
}`;

export default function DownloadButton(props) {
  const [visibility, setVisibility] = useState("false");
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [isDownloading, setDownloading] = useState(false);
  const [executionArn, setExecutionArn] = useState();
  const [progressCircleValue, setProgressCircleValue] = useState(0);
  const [textNotifMessage, setTextNotifMessage] = useState("Downloading file, please wait...");
  

  useEffect(() => {
    if (executionArn) {
      console.log("executionArn", executionArn);

      // Simulate progress from 0 to 49
      let progress = 0;
      const interval = setInterval(() => {
        if (progress < 80) {
          progress += 0.2;
          progress = parseFloat(progress.toFixed(2)); // Limiting to 2 decimals
          setProgressCircleValue(progress);
        }
      }, 50); // Adjust the interval timing as needed

      const sub = API.graphql({
        query: ON_EXPORT_PUBLLISHED,
        variables: { executionArn },
      }).subscribe({
        next: ({ value }) => {

          clearInterval(interval);

          setProgressCircleValue(100);
          console.log("export complete", value);

          setTextNotifMessage("Successfully downloaded file!");

          setTimeout(() => {
            setDownloading(false);
            window.open(
              value.data.onBackgroundFilesExportResultPublished.exportDownloadUrl,
              "_blank"
            );
            setProgressCircleValue(0);
          }, 2000);
        },
        error: (error) => console.warn(error),
      });

      return () => {
        sub.unsubscribe();
      };
    }
  }, [executionArn]);

  const defaultToggleStates = {
    descriptions: true,
    attachments: false,
  };
  const [toggleStates, setToggleStates] = useState(defaultToggleStates);

  const {
    styles: popStyles,
    attributes,
    state: popState,
    update: popUpdate,
    setOptions,
  } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "arrow",
        options: { element: arrowElement, placement: "bottom" },
      },
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const visibilityToggle = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setVisibility(!visibility);
    popUpdate();
  };

  const changeToggle = function (type) {
    let newState = Object.assign({}, toggleStates);
    newState[type] = !newState[type];
    setToggleStates(newState);
  };

  const getDocuments = async function (type, props) {
    console.log(
      localStorage.getItem("companyId"),
      props.matter_id,
      props.briefId
    );
    console.log("export request");
    console.log(props.briefId);
    const { data } = await API.graphql({
      query: BACKGROUND_FILES_EXPORT_MUTATION,
      variables: {
        briefId: props.briefId,
        input: {
          exportFormat: type,
          includeFiles: toggleStates.attachments,
          includeDescriptions: toggleStates.descriptions,
          briefName: props.briefName,
          sorting: props.ascDesc === true ? "asc" : (props.ascDesc === false ? "desc" : null)
        },
      },
    });
    if (data) {
      setDownloading(true);
      setExecutionArn(
        JSON.parse(JSON.parse(data.backgroundFilesExport).body).executionArn
      );
      console.log("preparing export files");
    }

    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: props.matter_id,
        briefId: props.briefId,
        activity: `exported background files`,
        field: "Background",
        appModule: "BACKGROUND",
        previous: null,
        current: null
      },
    };

    const addActivity = await API.graphql(params).then((result) =>
      console.log(`export file as ${type}`, result)
    );
  };

  return (
    <>
      <div className="inline-flex rounded-md ml-2">
        <button
          onClick={(e) => {
            visibilityToggle(e);
          }}
          type="button"
          className="border-blue-300 border h-9 w-48 rounded-md"
          ref={setReferenceElement}
        >
          <div className="flex flex-row text-blue-300 h-full justify-center items-center">
            <div className=" w-4/6 text-xs border-blue-400 items-center font-semibold flex gap-1 pl-2">
              <AiOutlineDownload />
              {isDownloading ? "Downloading" : "Download"}
            </div>
            <div className="h-full border-blue-300 border-r"></div>
            <div className="flex w-1/4 justify-center">
              {isDownloading ? (
                <AiOutlineLoading3Quarters className="w-3 animate-spin" />
              ) : (
                <FaChevronDown />
              )}
            </div>
          </div>
        </button>
      </div>

      <div
        className={`${
          visibility ? "hidden" : ""
        } w-64 h-64 shadow-md bg-white p-3 z-50`}
        ref={setPopperElement}
        style={popStyles.popper}
        {...attributes.popper}
      >

        {isDownloading ? (  
          <div className="flex flex-col items-center">
            <CircularProgressbar
              value={progressCircleValue}
              text={`${progressCircleValue}%`}
              className="w-10 h-10"
            />
            <div className={`mt-2 font-normal ${progressCircleValue === 100 ? 'text-green-500' : ''}`}>
              {textNotifMessage}
            </div>
          </div>
        ) : (
          <>
          <div className="font-normal text-gray-400 tracking-wider text-xs">
          Include in the export
          </div>
          <div className="grid md:grid-rows-3 h-32 border-b border-gray-300">
            <div className="h-10 flex items-center border-b border-dashed border-gray-300">
              <div className="w-3/4 font-normal text-gray-400 tracking-wider">
                Descriptions
              </div>
              <div className="flex justify-end w-1/4">
                <label className="switch">
                  <Switch
                    onChange={() => {
                      changeToggle("descriptions");
                    }}
                    checked={toggleStates.descriptions}
                    onColor="#93c5fd"
                    boxShadow="#93c5fd"
                    activeBoxShadow="#93c5fd"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={12}
                    width={16}
                    handleDiameter={4}
                  />
                </label>
              </div>
            </div>
            <div className="h-10 flex items-center border-b border-dashed border-gray-300">
              <div className="w-3/4 font-normal text-gray-400 tracking-wider">
                Attachments
              </div>
              <div className="flex justify-end w-1/4">
                <label className="switch">
                  <Switch
                    onChange={() => {
                      changeToggle("attachments");
                    }}
                    checked={toggleStates.attachments}
                    onColor="#93c5fd"
                    boxShadow="#93c5fd"
                    activeBoxShadow="#93c5fd"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={12}
                    width={16}
                    handleDiameter={4}
                  />
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="font-normal text-gray-400 tracking-wider text-xs  mt-3">
              Export As
            </div>
            <div>
              <div className="flex text-sm border-b border-dashed border-gray-300 h-8">
                <button
                  className="w-full text-left hover:bg-blue-300"
                  onClick={() => getDocuments("PDF", props)}
                >
                  PDF
                </button>
              </div>
              <div className=" flex text-sm h-8">
                <button
                  className="w-full text-left hover:bg-blue-300"
                  onClick={() => getDocuments("DOCX", props)}
                >
                  Microsoft Word
                </button>
              </div>
            </div>
          </div>
          </>
        )}
      </div>
    </>
  );
}
