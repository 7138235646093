import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import React, { useState, useEffect } from "react";

const MobileHeader = ({
  client_name,
  matter_name,
  height,
  width,
  setContentHeight,
  background_name,
}) => {
  const [headerReadMore, setHeaderReadMore] = useState(false);
  const [headerLines, setHeaderLines] = useState();
  const isUrl = getParameterByName("url");

  function countLines(tag) {
    var divHeight = tag.offsetHeight;
    var lineHeight = parseInt(
      window.getComputedStyle(tag).getPropertyValue("line-height")
    );
    var lines = Math.round(divHeight / lineHeight);
    return lines;
  }

  function getParameterByName(name, url = window.location.href) {
    name = name?.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2]?.replace(/\+/g, " "));
  }

  // ...

  const checkFormatShortest = (str) => {
    var check = str || ""; // Handle null or undefined
    check = check.replace("%20", " "); //returns my_name
    var newCheck = check.split("");
    if (newCheck.length > 15) {
      newCheck = newCheck.join("");
      check = newCheck.substring(0, 14) + "...";
    }

    return check;
  };

  const checkFormatShorter = (str) => {
    var check = str || ""; // Handle null or undefined
    check = check.replace("%20", " "); //returns my_name
    var newCheck = check.split("");
    if (newCheck.length > 28) {
      newCheck = newCheck.join("");
      check = newCheck.substring(0, 27) + "...";
    }

    return check;
  };

  const formatModular = (str, numberOfLines) => {
    var check = str || ""; // Handle null or undefined
    check = check.replace("%20", " "); //returns my_name
    var newCheck = check.split("");
    if (newCheck.length > numberOfLines) {
      newCheck = newCheck.join("");
      check = newCheck.substring(0, numberOfLines) + "...";
    }

    return check;
  };

  const checkFormat = (str) => {
    return (str ?? "")
      .replace(/null/g, "")
      .replace(/undefined/g, "")
      .replace(/%20/g, " ");
  };

  useEffect(() => {
    var headerTag = document.getElementById("headerTag");
    setHeaderLines(countLines(headerTag));
    if (headerReadMore) {
      setContentHeight(height - 88 - headerTag.offsetHeight);
    } else {
      setContentHeight(
        height -
          88 -
          parseInt(
            window.getComputedStyle(headerTag).getPropertyValue("line-height")
          )
      );
    }
  }, [height, width, headerReadMore]);

  return (
    <>
      <div className="pt-5 sm:bg-white sm:z-40 justify-end">
        <p className="hidden sm:block font-bold text-3xl">
          File Bucket&nbsp;<span className="text-3xl">of</span>&nbsp;
          {localStorage.getItem("cmNameOrder") === null || localStorage.getItem("cmNameOrder") === "Client/Matter"
            ? (<>
                <span className="font-semibold text-3xl">
                  {checkFormat(client_name)}
                </span>
                /
                <span className="font-semibold text-3xl">
                  {checkFormat(matter_name)}
                </span>
            </>)
            : (<>
                <span className="font-semibold text-3xl">
                  {checkFormat(matter_name)}
                </span>
                /
                <span className="font-semibold text-3xl">
                  {checkFormat(client_name)}
                </span>    
            </>)}
          
        </p>
        <div
          className="flex justify-end px-5"
          style={{
            position: headerLines > 1 ? "absolute" : "static",
            zIndex: headerLines > 1 ? "-50" : "auto",
          }}
        >
          {isUrl === "hba" ? (
            <>
              <p
                id="headerTag"
                className="sm:hidden font-bold"
                style={{ lineHeight: "24px" }}
              >
                {/* <span className="font-semibold text-base">
                  {checkFormat(client_name)}
                </span>
                &nbsp;
                <span className="font-light text-base text-gray-500">
                  {checkFormat(matter_name)}
                </span> */}
              </p>
            </>
          ) : (
            <>
              <p
                id="headerTag"
                className="sm:hidden font-bold"
                style={{ lineHeight: "24px" }}
              >
                {localStorage.getItem("cmNameOrder") === null || localStorage.getItem("cmNameOrder") === "Client/Matter"
                  ? (<>
                      <span className="font-semibold text-base">
                        {checkFormat(client_name)}
                      </span>
                      &nbsp;
                      <span className="font-light text-base text-gray-500">
                        {checkFormat(matter_name)}
                      </span>
                  </>)
                  : (<>
                      <span className="font-semibold text-base">
                        {checkFormat(matter_name)}
                      </span>
                      &nbsp;
                      <span className="font-light text-base text-gray-500">
                        {checkFormat(client_name)}
                      </span> 
                  </>)}
               
              </p>
            </>
          )}
          {width < 301 ? (
            <>
              <button className="invisible font-semibold rounded inline-flex items-center border-0 w-5 h-5 rounded-full outline-none focus:outline-none active:bg-current">
                {!headerReadMore ? <FiChevronDown /> : <FiChevronUp />}
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* IF HEADER LINES IS LONG, THEN OVERLAY WITH READMORE */}
        {headerLines > 1 ? (
          <div className="sm:hidden flex flex-row justify-items-end items-end w-full justify-end">
            <div
              className={
                "sm:hidden justify-items-end items-end w-full justify-end " +
                (headerReadMore ? "" : "pl-10")
              }
            >
              <p
                className={
                  "flex-auto pl-14 sm:hidden " +
                  (headerReadMore ? "" : "truncate")
                }
              >
                {localStorage.getItem("cmNameOrder") === null || localStorage.getItem("cmNameOrder") === "Client/Matter"
                  ? (<>
                    <span className="font-semibold text-base">
                      {formatModular(client_name, 30)}
                    </span>
                    &nbsp;
                    <span className="font-light text-base text-gray-500">
                      {formatModular(matter_name, 55)}
                    </span>
                  </>)
                  : (<>
                    <span className="font-semibold text-base">
                      {formatModular(matter_name, 30)}
                    </span>
                    &nbsp;
                    <span className="font-light text-base text-gray-500">
                      {formatModular(client_name, 55)}
                    </span>
                  </>)}
                
              </p>
            </div>
            <button
              onClick={() => setHeaderReadMore(!headerReadMore)}
              className="shrink-0 hover:bg-gray-100 text-gray-500 font-semibold inline-flex items-center border-0 w-5 h-5 ml-3 mr-5 rounded-full outline-none focus:outline-none active:bg-current"
            >
              {!headerReadMore ? <FiChevronDown /> : <FiChevronUp />}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MobileHeader;
