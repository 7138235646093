// Sandbox data for InvoicesData, ContractsData, BuilderDetailsData, SiteDiaryData, VariationData, ProgressPhotosData, BuildersHeaderData, ContractHeaderData, ProjectLocationData

export const SiteDiaryData = [
        {
            "createdAt": "2024-06-18T06:28:13.239Z",
            "id": "2a9facf6-6d27-4fc7-af02-8b4baf94bbb2",
            "description": "Homeowners confirm the site visit and acknowledge Builder’s transparency and effort despite setbacks. \nHomeowners said they also wanted to discuss Invoice 5 (about Variation 3) in person..",
            "date": "2024-03-27T04:30:02.000Z",
            "order": 1,
            "files": {
                "items": [
                    {
                        "id": "45d2df95-e000-4cf2-871c-3a50202fb66c",
                        "name": "Confirmation of Site Visit and DiscussionPoints.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.239Z",
            "id": "c983f66b-b499-4ad4-a04e-e45d52c49184",
            "description": "Builder presents an updated timeline and invites the Homeowners for a site visit to discuss progress.",
            "date": "2024-03-26T04:29:38.000Z",
            "order": 2,
            "files": {
                "items": [
                    {
                        "id": "b7481b91-ae94-4e96-8870-85888725f7e0",
                        "name": "Detailed Updated Project Timeline and Invitation for SiteVisit.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.238Z",
            "id": "f751b4cf-5d23-4075-bfa8-ba5cc0a57f26",
            "description": "In response, Builder provides a more detailed explanation of the supply chain issues and reassures the Homeowners that the Builder is doing everything possible to expedite the process and minimize any further delays.",
            "date": "2024-03-26T04:29:19.000Z",
            "order": 3,
            "files": {
                "items": [
                    {
                        "id": "bd5234c7-5d16-4a12-8460-3e27a07d7909",
                        "name": "Concerns Regarding Additional ConstructionDelay.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.242Z",
            "id": "2a007f05-4664-44f6-8657-a7e56ea02e58",
            "description": "Homeowners express their concerns over the new delay in an email to Builder.",
            "date": "2024-03-25T04:28:53.000Z",
            "order": 4,
            "files": {
                "items": [
                    {
                        "id": "fc986f7d-892c-405d-a5ab-958ca7cdf46f",
                        "name": "Concerns Regarding Additional Construction Delay1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.242Z",
            "id": "204bcd64-038a-4c0c-9f2a-c6b263bc54ee",
            "description": "<div>Because of this, the Builder sends a second request for EOT, for an additional 45 calendar days to accommodate the new timeline for window delivery and installation.</div>",
            "date": "2024-03-25T04:28:19.000Z",
            "order": 5,
            "files": {
                "items": [
                    {
                        "id": "a7c84822-b275-404a-a5bd-93665b50d4ca",
                        "name": "Second Extension of Time EOT Request Due to Supply ChainDelay.pdf"
                    },
                    {
                        "id": "a78752d2-b43d-4615-b4c0-af6606637da1",
                        "name": "2 Row 36 EOT Request 21.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.240Z",
            "id": "6b2e32b7-6309-414a-89d1-e1d67af99014",
            "description": "As the project advances and the team prepares for the installation of internal linings and windows, the Builder discovers that the custom windows ordered for the Homeowners’ house have been delayed at the manufacturer due to a supply chain issue. \n﻿\n﻿\n",
            "date": null,
            "order": 6,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.240Z",
            "id": "e126602f-c06e-4d28-8ad3-0d856dbf2461",
            "description": "Homeowners requested for clarification of Invoice 5 to the Builder, stating they never approved the suggested change",
            "date": "2024-03-25T04:27:26.000Z",
            "order": 7,
            "files": {
                "items": [
                    {
                        "id": "e051259c-126b-4202-b234-26ab0993c63a",
                        "name": "Clarification Needed on InvoiceNo.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.240Z",
            "id": "117acb07-5986-44b8-8392-38116a74e24d",
            "description": "Despite awaiting the Homeowners' approval, the Builder, anticipating consent due to the benefits, prematurely issues an invoice for the yet-to-be-approved advanced insulation materials ($7,500).",
            "date": "2024-03-22T04:27:07.000Z",
            "order": 8,
            "files": {
                "items": [
                    {
                        "id": "1cd8b5e7-e53f-493d-b2b3-6afaabd0c96d",
                        "name": "2 Row 33 Invoice 51.pdf"
                    },
                    {
                        "id": "8cec4961-c826-4916-b7e4-34201de88999",
                        "name": "Invoice for Anticipated Insulation Upgrade AdvancedMaterials.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.243Z",
            "id": "7743916c-c0b6-4966-8acb-79a0947564ba",
            "description": "The Homeowners express interest in the insulation upgrade but request more detailed information on the costs and savings before making a decision.",
            "date": "2024-03-12T04:26:34.000Z",
            "order": 9,
            "files": {
                "items": [
                    {
                        "id": "49cee433-dc7e-49a1-8e77-2f9b5580d73a",
                        "name": "Inquiry About Detailed Information for Proposed InsulationUpgrade.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.248Z",
            "id": "5d033782-fdb1-43a7-afec-e003981f24c6",
            "description": "Builder sends an email to Homeowners outlining the benefits of the advanced insulation materials and the associated costs. The Builder suggests this upgrade could significantly enhance the home's energy efficiency.",
            "date": "2024-03-12T04:26:11.000Z",
            "order": 10,
            "files": {
                "items": [
                    {
                        "id": "a4d1c13c-0f6b-48bd-971c-96d5aea7223f",
                        "name": "Proposed Upgrade for Enhanced Energy Efficiency Advanced InsulationMaterials.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.244Z",
            "id": "9cae9fd8-2a6b-4d43-8597-eeadeb84d375",
            "description": "As the construction progresses, Builder identifies an opportunity to enhance the Property's energy efficiency by adding advanced insulation materials to the walls and roof. The Builder believes this upgrade will provide long-term savings for the Homeowners and improve the home's comfort.\n﻿\n﻿\n",
            "date": null,
            "order": 11,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.244Z",
            "id": "c3eb4905-3091-489b-8205-35d5f18f416d",
            "description": "The Homeowners, understanding yet disappointed by the delay, respond to Builder, approving the EOT request 1.",
            "date": "2024-03-07T04:25:38.000Z",
            "order": 12,
            "files": {
                "items": [
                    {
                        "id": "32f09744-f9ba-468b-9c6e-9676d755ff1a",
                        "name": "Approval of EOT Request1.pdf"
                    },
                    {
                        "id": "c82e37ba-ffa8-42e3-8b04-fca0c1b987c0",
                        "name": "2 Row 29 EOT Request 1 Signed1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.245Z",
            "id": "a8c84be1-f1c7-479e-af40-5df9b82c2392",
            "description": "Builder sends a letter for EOT request, requesting a 30 calendar day extension to manage the impact of the weather on the construction timeline.",
            "date": "2024-03-06T04:24:43.000Z",
            "order": 13,
            "files": {
                "items": [
                    {
                        "id": "3f3e9304-2f44-4a0e-96cf-40a307f03854",
                        "name": "2 Row 28 EOT Request 11.pdf"
                    },
                    {
                        "id": "ad985221-ed78-4d6d-bbb9-fccaff08e7c8",
                        "name": "Extension of Time EOT Request Due to WeatherConditions.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.244Z",
            "id": "5b6f1633-9acf-46f2-862a-b775a13ab170",
            "description": "After the brickwork is complete, the Builder encounters delays due to heavy rain.\n﻿\n﻿\n",
            "date": null,
            "order": 14,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.246Z",
            "id": "13f810a7-7c81-4afd-a46d-7c930c880811",
            "description": "The Homeowners pay Invoice 4.",
            "date": "2024-02-12T04:24:05.000Z",
            "order": 15,
            "files": {
                "items": [
                    {
                        "id": "e4ff49ae-1c4f-4474-b998-5f07f248ebda",
                        "name": "Payment Confirmation for InvoiceNo.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.244Z",
            "id": "70d2884c-cd72-4946-ba97-354b00d67504",
            "description": "Homeowners visit the site and took photos of the completed brickwork.\n﻿\n﻿\n",
            "date": "2024-02-08T16:00:00.000Z",
            "order": 16,
            "files": {
                "items": [
                    {
                        "id": "bab96871-5b46-4ce2-b8c1-cf336194711c",
                        "name": "Dalle3.jpg"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.243Z",
            "id": "2138edcb-89fe-4fce-b34d-56fa658bbcfa",
            "description": "Builder issues an invoice for $93,665.00 (Brickwork) and $7,500.00 (Variation 2), for a total of $101,165.00. This is Invoice No .4",
            "date": "2023-02-06T16:00:00.000Z",
            "order": 17,
            "files": {
                "items": [
                    {
                        "id": "d8381d0d-1f25-4a90-ac10-49632df88cdf",
                        "name": "2 Row 24 Invoice 41.pdf"
                    },
                    {
                        "id": "5c9983af-199f-487d-ad0c-49cbe4e814d3",
                        "name": "InvoiceNo.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.249Z",
            "id": "edd1cd00-52b6-476e-a630-3002fb104a10",
            "description": "Builder completes the brickwork. \n﻿\n﻿\n",
            "date": null,
            "order": 18,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.309Z",
            "id": "c93820c4-8713-4111-b30d-46fee3599632",
            "description": "Homeowners email the signed Variation No. 2 to the Builder.",
            "date": "2024-01-24T04:22:13.000Z",
            "order": 19,
            "files": {
                "items": [
                    {
                        "id": "d1fe3dfb-3e49-4c7b-af8f-c2eb7ee01cdb",
                        "name": "Signed VariationNo.pdf"
                    },
                    {
                        "id": "26692f53-1886-4b00-a4b1-c52c3a5fe87d",
                        "name": "2 Row 22 Variation 2 Signed1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.248Z",
            "id": "dd460a69-038f-49c5-b673-c9e57998b0d9",
            "description": "Following their phone conversation, Builder sends Variation No. 2 to the Homeowners for signing.",
            "date": "2024-01-24T04:21:24.000Z",
            "order": 20,
            "files": {
                "items": [
                    {
                        "id": "e3e61492-2f9f-40d2-bc01-d275b619be36",
                        "name": "VariationNo.pdf"
                    },
                    {
                        "id": "87868d0b-6650-4f6d-84fa-ee1546f311df",
                        "name": "2 Row 21 Variation 21.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.247Z",
            "id": "5e9c8958-3824-4f16-8c75-60f47b2cec40",
            "description": "Homeowners schedule a phone call with the Builder.",
            "date": "2024-01-23T04:20:45.000Z",
            "order": 21,
            "files": {
                "items": [
                    {
                        "id": "867831ab-7782-41e4-82a8-e35efb56b137",
                        "name": "Kitchen Countertop UpgradeOptions.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.247Z",
            "id": "6f3165cb-6d36-445d-924e-2b00ad115718",
            "description": "Builder provides choices for the variation and scheduled a phone call with the Homeowners to discuss.",
            "date": "2024-01-23T04:20:25.000Z",
            "order": 22,
            "files": {
                "items": [
                    {
                        "id": "66623ce8-b5d9-4e47-b4cf-bddeaaabf817",
                        "name": "Kitchen Countertop Upgrade Options1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.246Z",
            "id": "58684202-1dcb-415e-b8dc-39c55be0588f",
            "description": "Homeowner inquires with the Builder about a possible upgrade on the kitchen countertops.",
            "date": "2024-01-22T04:19:29.000Z",
            "order": 23,
            "files": {
                "items": [
                    {
                        "id": "13d9b448-f5fd-43b5-8a15-ac9d218e7189",
                        "name": "Inquiry About Kitchen Countertop UpgradeOptions.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.335Z",
            "id": "e46a32b0-33b6-492c-9bba-f76c2942e186",
            "description": "As the interior starts taking shape, Homeowner chooses to upgrade to higher-quality kitchen countertops.\n",
            "date": null,
            "order": 24,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.249Z",
            "id": "5c1851d1-46cc-4ca9-8014-fad35042d1ef",
            "description": "The Homeowners pay Invoice 3.",
            "date": "2024-01-16T04:18:29.000Z",
            "order": 25,
            "files": {
                "items": [
                    {
                        "id": "06004cdf-a1c3-4675-9168-296ccce6f6a5",
                        "name": "Payment Confirmation for Invoice No1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.249Z",
            "id": "73dbe7d5-7e32-463d-87f8-6d20e9aa35da",
            "description": "Homeowners visit the site and took photos of the completed framework.\n﻿\n﻿\n",
            "date": "2024-01-15T16:00:00.000Z",
            "order": 26,
            "files": {
                "items": [
                    {
                        "id": "85b09201-b7af-45cd-8851-ed6d7ed50d5c",
                        "name": "DALL E 2024 02 12 18 58 18 Create a wide angle image showing a residential construction site with completed houseframeworks.jpg"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.248Z",
            "id": "ee3715b6-403e-40a9-a1f7-67878fb20643",
            "description": "Builder issues an invoice for $93,665.00 (Framework Completion). ",
            "date": "2024-03-15T04:17:09.000Z",
            "order": 27,
            "files": {
                "items": [
                    {
                        "id": "9efedcf9-029d-4016-9f9e-05e1c725af86",
                        "name": "Invoice No1.pdf"
                    },
                    {
                        "id": "a9d92035-6614-4564-904f-e475b1b83750",
                        "name": "2 Row 15 Invoice 31.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.250Z",
            "id": "37feddac-615b-4525-99d8-4b753bbf8530",
            "description": "Builder completes the frames.\n﻿\n﻿\n",
            "date": "2023-12-05T16:00:00.000Z",
            "order": 28,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.250Z",
            "id": "d93e23a0-2ecc-4360-883a-f672fb13fcbb",
            "description": "The Homeowners pay Invoice 2.",
            "date": "2023-12-06T04:16:22.000Z",
            "order": 29,
            "files": {
                "items": [
                    {
                        "id": "a2eaed40-3e60-4229-93a7-60ef0e443d03",
                        "name": "Payment Confirmation for Invoice No2.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.258Z",
            "id": "1b92c718-1980-4743-9271-0a5f0e67c1c3",
            "description": "Builder issues an invoice for $56,199.00 (Slab Completion) and $6,000.00 (Variation 1), for a total of $62,199.00. This invoice is Invoice No. 2.",
            "date": "2023-12-05T04:14:46.000Z",
            "order": 30,
            "files": {
                "items": [
                    {
                        "id": "037a158b-920b-4ec5-af8c-73c2d381797d",
                        "name": "Invoice No2.pdf"
                    },
                    {
                        "id": "3df9c315-b190-4a3e-ae4f-8d1b416301a2",
                        "name": "2 Row 11 Invoice 21.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.269Z",
            "id": "7330f3a6-8557-41b4-869a-55080fe6d44d",
            "description": "Homeowners visit the site and took photos of the completed slab.\n﻿\n﻿\n",
            "date": "2023-12-05T16:00:00.000Z",
            "order": 31,
            "files": {
                "items": [
                    {
                        "id": "e91aabfe-6213-4a40-a5a7-65d36e904ac3",
                        "name": "DALL E 2024 02 12 18 36 50 Create a wide angle image showing a newly completed house slab on a residential constructionsite.jpg"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.304Z",
            "id": "c57c1938-530b-4308-839b-a858d63b955a",
            "description": "Builder completes the slab work.\n﻿\n﻿\n",
            "date": null,
            "order": 32,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.325Z",
            "id": "8520fb65-2fa8-486d-bd33-6aef4c19aae3",
            "description": "Homeowners email the signed Variation 1 to the Builder.",
            "date": "2023-11-30T04:13:47.000Z",
            "order": 33,
            "files": {
                "items": [
                    {
                        "id": "d74603ff-3ffa-43e5-999c-59137970a117",
                        "name": "2 Row 9 Variation 1 Signed1.pdf"
                    },
                    {
                        "id": "4bc1c86d-4216-471e-b8d8-4ac25b72f13b",
                        "name": "Signed Variation No1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.317Z",
            "id": "c4a71a44-6a34-47ac-964f-dccc8a15f80e",
            "description": "Following their phone conversation, Builder sends Variation 1 to the Homeowners for signing.",
            "date": "2023-11-29T04:12:56.000Z",
            "order": 34,
            "files": {
                "items": [
                    {
                        "id": "b1d296f8-a4a9-48fa-83c4-2c3b3a285338",
                        "name": "2 Row 8 Variation 11.pdf"
                    },
                    {
                        "id": "d779fdae-6579-474b-b4eb-2572b7a7bbc7",
                        "name": "Variation No1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.339Z",
            "id": "c5c4d638-48b7-45af-8d54-6c6f6ad1dc1b",
            "description": "Builder provides choices for the variation and scheduled a phone call with the Homeowners to discuss.\n\n",
            "date": "2023-11-27T04:11:43.000Z",
            "order": 35,
            "files": {
                "items": [
                    {
                        "id": "95cce419-9916-4d2f-b52f-f6c184f544f4",
                        "name": "2 Row 7 Selections for the requested variation1.pdf"
                    },
                    {
                        "id": "90e3c3e2-521b-4cf0-aed8-9bd447ee3105",
                        "name": "Deck Upgrade Options andDiscussion.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.333Z",
            "id": "144acd0b-bc26-4e67-86cf-665e656523aa",
            "description": "Homeowners inquire with the Builder about a possible upgrade on the deck.",
            "date": "2023-11-24T04:10:45.000Z",
            "order": 36,
            "files": {
                "items": [
                    {
                        "id": "037aabea-ab6d-4a65-b804-b107fbf0fd2f",
                        "name": "Inquiry About Deck UpgradeOptions.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.310Z",
            "id": "a798d556-ac76-436e-9a47-bb09b03685ad",
            "description": "Inspired by the site's potential, Homeowners decide to expand their outdoor living space with a larger deck. \n﻿\n﻿\n",
            "date": null,
            "order": 37,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.344Z",
            "id": "cd045188-3315-479a-8094-43af4b481d4a",
            "description": "Homeowners pay the contract deposit.\n",
            "date": "2023-11-21T04:10:17.000Z",
            "order": 38,
            "files": {
                "items": [
                    {
                        "id": "50f5fd93-72b6-4472-8011-549e2c46cfa4",
                        "name": "Payment Confirmation Contract Deposit for 42 Seabreeze Lane1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.333Z",
            "id": "171311b9-fd08-4c95-b7c7-afdb6e9b8702",
            "description": "Builder gives the Homeowners an invoice (Invoice No. 1) for the contract deposit of  $18,733.00.",
            "date": "2023-11-21T00:49:37.000Z",
            "order": 39,
            "files": {
                "items": [
                    {
                        "id": "6e1568f3-8938-4a65-9218-4da4588578ce",
                        "name": "Invoice No3.pdf"
                    },
                    {
                        "id": "a2114157-3085-4459-bbaf-fed6d38ca6ff",
                        "name": "2 Row 3 Invoice 1 Deposit1.pdf"
                    }
                ]
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.299Z",
            "id": "abe80a55-3617-4ba4-91a9-6bb0f8e6b7f0",
            "description": "Homeowners sign a contract with Dynamic Builders Pty Ltd. (Builder) to build their dream home at 42 Seabreeze Lane, Shell Cove, NSW 2529 (Property).\n﻿\n",
            "date": "2024-11-03T16:00:00.000Z",
            "order": 40,
            "files": {
                "items": []
            },
            "dynamicData": null
        },
        {
            "createdAt": "2024-06-18T06:28:13.323Z",
            "id": "32bd7b80-49ed-47a2-a7d0-cadfe1e746a1",
            "description": "Liam and Ava Wilson (Homeowners) decide to build their dream home.\n﻿\n",
            "date": "2023-07-31T16:00:00.000Z",
            "order": 41,
            "files": {
                "items": []
            },
            "dynamicData": null
        }
];

export const BuilderDetailsData = [
    {
        "id": "a30ff096-ef97-4ce0-910a-b1297d1c42a3",
        "description": null,
        "comments": null,
        "date": null,
        "createdAt": "2024-03-19T08:44:41.973Z",
        "order": 1,
        "dynamicData": "{\"17\":\"Dynamic Builders Pty Ltd\",\"18\":\"Ethan Turner\",\"19\":\"ethan.turner@gmail.com\",\"20\":\"0451798123\"}",
        "email": null,
        "files": {
            "items": [
                {
                    "id": "d8d2d255-116d-4858-bce7-111c79dc84e7",
                    "name": "Dalle1.jpg",
                    "details": "",
                    "gmailMessageId": "",
                    "gmailMessage": null,
                    "isGmailPDF": false,
                    "isGmailAttachment": false,
                    "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1711345025230ebd237d742c8452673f136d7fa7e6088.jpeg",
                    "order": 0,
                    "createdAt": "2024-03-25T05:37:06.317Z"
                }
            ]
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "e7f5690e-63f8-496a-a8ac-be43ca79b525",
                "name": "Builder Details"
            }
        ],
        "labels": {
            "items": []
        }
    }
];


export const ContractsData = [
    {
        "id": "fbe14b0b-3fcd-4157-93fa-d2d2956e67d7",
        "description": "Contract Terms And Conditions",
        "comments": null,
        "date": "2023-11-03T16:00:00.000Z",
        "createdAt": "2024-03-05T04:05:21.447Z",
        "order": 1,
        "dynamicData": null,
        "email": null,
        "files": {
            "items": [
                {
                    "id": "8c3d0d74-af29-4adf-bb92-718c5f1c329f",
                    "name": "Dummy HIA Contract.pdf",
                    "details": "",
                    "gmailMessageId": "",
                    "gmailMessage": null,
                    "isGmailPDF": false,
                    "isGmailAttachment": false,
                    "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709611636053DummyHIAContract.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:07:21.816Z"
                }
            ]
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "9d9d53fd-0d44-4cdd-ac9a-b972b93cb631",
                "name": "Contracts"
            }
        ],
        "labels": {
            "items": []
        }
    },
    {
        "id": "27c676c6-6659-40be-99aa-11b0a63d4782",
        "description": "Architectural Drawings",
        "comments": null,
        "date": "2023-11-03T16:00:00.000Z",
        "createdAt": "2024-03-05T04:05:21.376Z",
        "order": 2,
        "dynamicData": null,
        "email": null,
        "files": {
            "items": [
                {
                    "id": "e91ef0ab-29b8-4b40-9dd3-f28475e096e6",
                    "name": "Dummy Architectural Plans.pdf",
                    "details": "",
                    "gmailMessageId": "",
                    "gmailMessage": null,
                    "isGmailPDF": false,
                    "isGmailAttachment": false,
                    "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709611646787DummyArchitecturalPlans.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:07:29.674Z"
                }
            ]
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "9d9d53fd-0d44-4cdd-ac9a-b972b93cb631",
                "name": "Contracts"
            }
        ],
        "labels": {
            "items": []
        }
    },
    {
        "id": "79fa9ced-38e9-4b99-a0d7-bd3c6e215d63",
        "description": "Engineer Drawings",
        "comments": null,
        "date": "2023-11-03T16:00:00.000Z",
        "createdAt": "2024-03-05T04:05:21.585Z",
        "order": 3,
        "dynamicData": null,
        "email": null,
        "files": {
            "items": [
                {
                    "id": "ba4d4157-2d45-4e11-9143-187352a81d93",
                    "name": "Dummy Engineering Plans.pdf",
                    "details": "",
                    "gmailMessageId": "",
                    "gmailMessage": null,
                    "isGmailPDF": false,
                    "isGmailAttachment": false,
                    "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709611655668DummyEngineeringPlans.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:07:39.358Z"
                }
            ]
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "9d9d53fd-0d44-4cdd-ac9a-b972b93cb631",
                "name": "Contracts"
            }
        ],
        "labels": {
            "items": []
        }
    },
    {
        "id": "30f71a3d-5dd1-4664-be85-d67e842f69a9",
        "description": "Specifications",
        "comments": null,
        "date": "2023-11-03T16:00:00.000Z",
        "createdAt": "2024-03-05T04:05:21.569Z",
        "order": 4,
        "dynamicData": null,
        "email": null,
        "files": {
            "items": [
                {
                    "id": "cdb1fdd0-ef01-4c4a-b4f6-d3a0d1a1a403",
                    "name": "Dummy Specifications.pdf",
                    "details": "",
                    "gmailMessageId": "",
                    "gmailMessage": null,
                    "isGmailPDF": false,
                    "isGmailAttachment": false,
                    "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709611663835DummySpecifications.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:07:46.743Z"
                }
            ]
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "9d9d53fd-0d44-4cdd-ac9a-b972b93cb631",
                "name": "Contracts"
            }
        ],
        "labels": {
            "items": []
        }
    },
    {
        "id": "a6130eb8-adec-4618-830a-cc20d836c593",
        "description": "Builder's Insurance",
        "comments": null,
        "date": "2023-11-03T16:00:00.000Z",
        "createdAt": "2024-03-05T04:05:21.609Z",
        "order": 5,
        "dynamicData": null,
        "email": null,
        "files": {
            "items": [
                {
                    "id": "e2c7210e-3af8-4ea3-ab6f-575a46149ce3",
                    "name": "Dummy Insurance.pdf",
                    "details": "",
                    "gmailMessageId": "",
                    "gmailMessage": null,
                    "isGmailPDF": false,
                    "isGmailAttachment": false,
                    "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709611673569DummyInsurance.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:07:56.660Z"
                }
            ]
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "9d9d53fd-0d44-4cdd-ac9a-b972b93cb631",
                "name": "Contracts"
            }
        ],
        "labels": {
            "items": []
        }
      }
]

export const InvoicesData = [
    // {
    //     "id": "9dfe26b2-59ad-4210-aeb8-bce99fdc95b8",
    //     "description": "Builder issues an invoice for $93,665.00 (Brickwork) and $7,500.00 (Variation 2), for a total of $101,165.00. This is Invoice No .4",
    //     "comments": "",
    //     "date": "2023-02-06T16:00:00.000Z",
    //     "createdAt": "2024-03-05T05:02:59.945Z",
    //     "order": 1,
    //     "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Paid\",\"9\":\"101165\"}",
    //     "email": null,
    //     "files": {
    //         "items": [
    //             {
    //                 "id": "8b5ce8d1-c171-45e4-8893-07246370ff26",
    //                 "name": "2 Row 24 Invoice 4 1.pdf",
    //                 "details": "",
    //                 "gmailMessageId": "18e0cd9ec7f37705",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "UNREAD",
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0cd9ec7f37705/2. Row 24 - Invoice 4-1.pdf",
    //                 "order": 1,
    //                 "createdAt": "2024-03-05T04:47:54.252Z"
    //             },
    //             {
    //                 "id": "2a2ddbb0-d834-4e3b-b29c-a68c11f5cac1",
    //                 "name": "Invoice No.pdf",
    //                 "details": "EMAIL_PDF_VERSION",
    //                 "gmailMessageId": "18e0cd9ec7f37705",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "UNREAD",
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0cd9ec7f37705/invoiceno.pdf",
    //                 "order": 2,
    //                 "createdAt": "2024-03-05T04:47:54.276Z"
    //             }
    //         ]
    //     },
    //     "tabFiles": null,
    //     "briefs": [
    //         {
    //             "id": "6fdf9632-0cf0-4610-b367-5f207263b25c",
    //             "name": "Invoices"
    //         }
    //     ],
    //     "labels": {
    //         "items": []
    //     }
    // },
    // {
    //     "id": "80ab30fc-c88b-4485-a490-a7abdb0329da",
    //     "description": "Builder issues an invoice for $93,665.00 (Framework Completion). ",
    //     "comments": "",
    //     "date": "2024-03-15T04:17:09.000Z",
    //     "createdAt": "2024-03-05T05:02:33.096Z",
    //     "order": 2,
    //     "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Paid\",\"9\":\"93665\"}",
    //     "email": null,
    //     "files": {
    //         "items": [
    //             {
    //                 "id": "3539fe64-0483-400c-92d9-75ebd840cc92",
    //                 "name": "2 Row 15 Invoice 3 1.pdf",
    //                 "details": "",
    //                 "gmailMessageId": "18e0cd49431aad17",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "UNREAD",
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0cd49431aad17/2. Row 15 - Invoice 3-1.pdf",
    //                 "order": 1,
    //                 "createdAt": "2024-03-05T04:43:53.629Z"
    //             },
    //             {
    //                 "id": "592cb70d-e011-4799-a6b8-4f17bc71ec0e",
    //                 "name": "Invoice No.pdf",
    //                 "details": "EMAIL_PDF_VERSION",
    //                 "gmailMessageId": "18e0cd49431aad17",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "UNREAD",
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0cd49431aad17/invoiceno.pdf",
    //                 "order": 2,
    //                 "createdAt": "2024-03-05T04:43:53.621Z"
    //             }
    //         ]
    //     },
    //     "tabFiles": null,
    //     "briefs": [
    //         {
    //             "id": "6fdf9632-0cf0-4610-b367-5f207263b25c",
    //             "name": "Invoices"
    //         }
    //     ],
    //     "labels": {
    //         "items": []
    //     }
    // },
    // {
    //     "id": "766929e7-9684-47c2-80d2-29ed87f943fd",
    //     "description": "Builder issues an invoice for $56,199.00 (Slab Completion) and $6,000.00 (Variation 1), for a total of $62,199.00. This invoice is Invoice No. 2.",
    //     "comments": "",
    //     "date": "2023-12-05T04:14:46.000Z",
    //     "createdAt": "2024-03-05T05:01:49.262Z",
    //     "order": 3,
    //     "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Paid\",\"9\":\"62199\"}",
    //     "email": null,
    //     "files": {
    //         "items": [
    //             {
    //                 "id": "bf9b5521-da69-459c-84a9-b6d472537f7f",
    //                 "name": "2 Row 11 Invoice 2 1.pdf",
    //                 "details": "",
    //                 "gmailMessageId": "18e0cd2679a26125",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "UNREAD",
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0cd2679a26125/2. Row 11 - Invoice 2-1.pdf",
    //                 "order": 1,
    //                 "createdAt": "2024-03-05T04:41:27.544Z"
    //             },
    //             {
    //                 "id": "f140baf0-d558-4fc2-9ac6-2c6ac0336d7e",
    //                 "name": "Invoice No.pdf",
    //                 "details": "EMAIL_PDF_VERSION",
    //                 "gmailMessageId": "18e0cd2679a26125",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "UNREAD",
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0cd2679a26125/invoiceno.pdf",
    //                 "order": 2,
    //                 "createdAt": "2024-03-05T04:41:27.541Z"
    //             }
    //         ]
    //     },
    //     "tabFiles": null,
    //     "briefs": [
    //         {
    //             "id": "6fdf9632-0cf0-4610-b367-5f207263b25c",
    //             "name": "Invoices"
    //         }
    //     ],
    //     "labels": {
    //         "items": []
    //     }
    // },
    // {
    //     "id": "6f7e950c-f043-4cd2-beee-560c1eaa9ada",
    //     "description": "Builder gives the Homeowners an invoice (Invoice No. 1) for the contract deposit of  $18,733.00.",
    //     "comments": "",
    //     "date": "2023-11-21T00:49:37.000Z",
    //     "createdAt": "2024-03-05T05:01:16.885Z",
    //     "order": 4,
    //     "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Paid\",\"9\":\"18733\"}",
    //     "email": null,
    //     "files": {
    //         "items": [
    //             {
    //                 "id": "ea417c31-c1f8-487a-8780-5dabd63a3627",
    //                 "name": "2 Row 3 Invoice 1 Deposit 1.pdf",
    //                 "details": "",
    //                 "gmailMessageId": "18e0c1697f7b1a1f",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0c1697f7b1a1f/2. Row 3 - Invoice 1 (Deposit)-1.pdf",
    //                 "order": 1,
    //                 "createdAt": "2024-03-05T04:38:11.913Z"
    //             },
    //             {
    //                 "id": "df7cf9e7-84f0-4e63-aecb-1c2ac2639b8b",
    //                 "name": "Invoice No.pdf",
    //                 "details": "EMAIL_PDF_VERSION",
    //                 "gmailMessageId": "18e0c1697f7b1a1f",
    //                 "gmailMessage": {
    //                     "gmailLabelIds": [
    //                         "CATEGORY_PERSONAL",
    //                         "INBOX"
    //                     ]
    //                 },
    //                 "isGmailPDF": false,
    //                 "isGmailAttachment": true,
    //                 "s3ObjectKey": "18e0c1697f7b1a1f/invoiceno.pdf",
    //                 "order": 2,
    //                 "createdAt": "2024-03-05T04:38:11.949Z"
    //             }
    //         ]
    //     },
    //     "tabFiles": null,
    //     "briefs": [
    //         {
    //             "id": "6fdf9632-0cf0-4610-b367-5f207263b25c",
    //             "name": "Invoices"
    //         }
    //     ],
    //     "labels": {
    //         "items": []
    //     }
    // }
        {
            "id": "2598a719-354e-46e2-a2c9-7b3c8d4f37a2",
            "description": "Builder issues an invoice for $93,665.00 (Brickwork) and $7,500.00 (Variation 2), for a total of $101,165.00. This is Invoice No .4",
            "comments": null,
            "date": "2023-02-06T16:00:00.000Z",
            "createdAt": "2024-05-20T00:19:51.799Z",
            "order": 1,
            "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Unclaimed\",\"8\":\"Unpaid\",\"9\":\"101165\"}",
            "email": null,
            "files": {
                "items": [
                    {
                        "id": "a82fa691-1c4e-4d1e-be7c-2f4cb492f4d2",
                        "name": "2 Row 24 Invoice 4 1.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/17161643913772Row24Invoice41.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:56.256Z"
                    },
                    {
                        "id": "c9fc0906-6b5d-48f5-a112-fd8504808909",
                        "name": "Invoice No.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/1716164395714InvoiceNo.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:57.677Z"
                    }
                ]
            },
            "tabFiles": null,
            "briefs": [
                {
                    "id": "ff7cf843-7403-44a3-b9ce-f16682d56a91",
                    "name": "Invoices"
                }
            ],
            "labels": {
                "items": []
            }
        },
        {
            "id": "108be72d-ace3-442a-913d-1064a02c1730",
            "description": "Builder issues an invoice for $93,665.00 (Framework Completion). ",
            "comments": null,
            "date": "2024-03-15T04:17:09.000Z",
            "createdAt": "2024-05-20T00:19:51.805Z",
            "order": 2,
            "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Unclaimed\",\"8\":\"Unpaid\",\"9\":\"93665\"}",
            "email": null,
            "files": {
                "items": [
                    {
                        "id": "16e2a3ff-a942-463e-b139-d5ec4ff429c9",
                        "name": "2 Row 15 Invoice 3 1.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/17161643912702Row15Invoice31.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:55.893Z"
                    },
                    {
                        "id": "f43e818c-1a77-417f-9566-d5373b5085b4",
                        "name": "Invoice No.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/1716164395350InvoiceNo.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:57.104Z"
                    }
                ]
            },
            "tabFiles": null,
            "briefs": [
                {
                    "id": "ff7cf843-7403-44a3-b9ce-f16682d56a91",
                    "name": "Invoices"
                }
            ],
            "labels": {
                "items": []
            }
        },
        {
            "id": "62781474-8034-4df8-af70-8804bcdf3fde",
            "description": "Builder issues an invoice for $56,199.00 (Slab Completion) and $6,000.00 (Variation 1), for a total of $62,199.00. This invoice is Invoice No. 2.",
            "comments": null,
            "date": "2023-12-05T04:14:46.000Z",
            "createdAt": "2024-05-20T00:19:51.801Z",
            "order": 3,
            "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Unclaimed\",\"8\":\"Unpaid\",\"9\":\"62199\"}",
            "email": null,
            "files": {
                "items": [
                    {
                        "id": "c261639c-6d72-4ee6-ae3b-4d69d968727a",
                        "name": "2 Row 11 Invoice 2 1.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/17161643912612Row11Invoice21.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:54.522Z"
                    },
                    {
                        "id": "5523e22e-a870-42d7-b6e0-2b50e3d97271",
                        "name": "Invoice No.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/1716164394085InvoiceNo.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:56.374Z"
                    }
                ]
            },
            "tabFiles": null,
            "briefs": [
                {
                    "id": "ff7cf843-7403-44a3-b9ce-f16682d56a91",
                    "name": "Invoices"
                }
            ],
            "labels": {
                "items": []
            }
        },
        {
            "id": "031a3c8c-fb47-41a5-bc52-0deffb34a81e",
            "description": "Builder gives the Homeowners an invoice (Invoice No. 1) for the contract deposit of  $18,733.00.",
            "comments": null,
            "date": "2023-11-21T00:49:37.000Z",
            "createdAt": "2024-05-20T00:19:51.746Z",
            "order": 4,
            "dynamicData": "{\"6\":\"Claimed\",\"7\":\"Paid\",\"9\":\"18733\"}",
            "email": null,
            "files": {
                "items": [
                    {
                        "id": "18309c4e-181e-4de5-ad0f-bdcbc1df1172",
                        "name": "2 Row 3 Invoice 1 Deposit 1.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/17161643912352Row3Invoice1Deposit1.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:55.346Z"
                    },
                    {
                        "id": "b5a3f0ac-f2cd-4cd2-85f7-f424bb256ea3",
                        "name": "Invoice No.pdf",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "04c9de39-4955-45f4-b6c9-381ed6ad4c5b/1716164395066InvoiceNo.pdf",
                        "order": 0,
                        "createdAt": "2024-05-20T00:19:56.435Z"
                    }
                ]
            },
            "tabFiles": null,
            "briefs": [
                {
                    "id": "ff7cf843-7403-44a3-b9ce-f16682d56a91",
                    "name": "Invoices"
                }
            ],
            "labels": {
                "items": []
            }
        }
]

export const VariationData = [
    {
        "id": "e69a14c3-e4da-4f8a-9b7e-c3d65c57b8c7",
        "description": "Builder sends an email to Homeowners outlining the benefits of the advanced insulation materials and the associated costs. The Builder suggests this upgrade could significantly enhance the home's energy efficiency. ",
        "comments": "",
        "date": "2024-03-12T04:26:11.000Z",
        "createdAt": "2024-05-15T07:59:03.826Z",
        "order": 1,
        "dynamicData": "{\"10\":\"For Approval\",\"11\":\"7500\"}",
        "email": null,
        "files": {
            "items": [
                {
                    "id": "fb1dcd83-d32d-4a49-8cc5-5a82e0ed680a",
                    "name": "Proposed Upgrade for Enhanced Energy Efficiency Advanced Insulation Materials.pdf",
                    "details": "EMAIL_PDF_VERSION",
                    "gmailMessageId": "18e0cdcd9c439bb8",
                    "gmailMessage": {
                        "gmailLabelIds": [
                            "UNREAD",
                            "CATEGORY_PERSONAL",
                            "INBOX"
                        ]
                    },
                    "isGmailPDF": false,
                    "isGmailAttachment": true,
                    "s3ObjectKey": "18e0cdcd9c439bb8/rgyefficiencyadvancedinsulationmaterials.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:51:34.732Z"
                }
            ]
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "eea6a4db-ec91-4a80-8176-6149b39c3578",
                "name": "Variations"
            }
        ],
        "labels": {
            "items": []
        }
    },
    {
        "id": "b91d2727-76e2-47b8-9e17-103e489fb541",
        "description": "Following their phone conversation, Builder sends Variation 1 to the Homeowners for signing. ",
        "comments": "",
        "date": "2023-11-29T04:12:56.000Z",
        "createdAt": "2024-03-05T05:30:42.204Z",
        "order": 2,
        "dynamicData": "{\"10\":\"Approved\",\"11\":\"6000.00\"}",
        "email": null,
        "files": {
            "items": [
                {
                    "id": "014e7723-d36a-4b47-8f50-3c41feb057ee",
                    "name": "2 Row 8 Variation 1 1.pdf",
                    "details": "",
                    "gmailMessageId": "18e0cd0b9a9ffe66",
                    "gmailMessage": {
                        "gmailLabelIds": [
                            "UNREAD",
                            "CATEGORY_PERSONAL",
                            "INBOX"
                        ]
                    },
                    "isGmailPDF": false,
                    "isGmailAttachment": true,
                    "s3ObjectKey": "18e0cd0b9a9ffe66/2. Row 8 - Variation 1-1.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:40:29.173Z"
                },
                {
                    "id": "a8da5b57-755b-458e-b692-260bbaed80ba",
                    "name": "Variation No.pdf",
                    "details": "EMAIL_PDF_VERSION",
                    "gmailMessageId": "18e0cd0b9a9ffe66",
                    "gmailMessage": {
                        "gmailLabelIds": [
                            "UNREAD",
                            "CATEGORY_PERSONAL",
                            "INBOX"
                        ]
                    },
                    "isGmailPDF": false,
                    "isGmailAttachment": true,
                    "s3ObjectKey": "18e0cd0b9a9ffe66/variationno.pdf",
                    "order": 2,
                    "createdAt": "2024-03-05T04:40:29.180Z"
                }
            ]
        },
        "tabFiles": [],
        "briefs": [
            {
                "id": "eea6a4db-ec91-4a80-8176-6149b39c3578",
                "name": "Variations"
            }
        ],
        "labels": {
            "items": []
        },
        "totalCost": null
    },
    {
        "id": "387df9c2-313e-4a9b-aeb0-9ec01bab2fe7",
        "description": "Following their phone conversation, Builder sends Variation No. 2 to the Homeowners for signing. ",
        "comments": "",
        "date": "2024-01-24T04:21:24.000Z",
        "createdAt": "2024-03-05T05:02:55.004Z",
        "order": 3,
        "dynamicData": "{\"10\":\"Approved\",\"11\":\"7500.00\"}",
        "email": null,
        "files": {
            "items": [
                {
                    "id": "51338515-3715-4be4-8ffa-5698cfbecde2",
                    "name": "2 Row 21 Variation 2 1.pdf",
                    "details": "",
                    "gmailMessageId": "18e0cd8791dfb9d7",
                    "gmailMessage": {
                        "gmailLabelIds": [
                            "UNREAD",
                            "CATEGORY_PERSONAL",
                            "INBOX"
                        ]
                    },
                    "isGmailPDF": false,
                    "isGmailAttachment": true,
                    "s3ObjectKey": "18e0cd8791dfb9d7/2. Row 21 - Variation 2-1.pdf",
                    "order": 1,
                    "createdAt": "2024-03-05T04:45:30.594Z"
                },
                {
                    "id": "856ad541-8181-4c9f-b787-ae2884f8692f",
                    "name": "Variation No.pdf",
                    "details": "EMAIL_PDF_VERSION",
                    "gmailMessageId": "18e0cd8791dfb9d7",
                    "gmailMessage": {
                        "gmailLabelIds": [
                            "UNREAD",
                            "CATEGORY_PERSONAL",
                            "INBOX"
                        ]
                    },
                    "isGmailPDF": false,
                    "isGmailAttachment": true,
                    "s3ObjectKey": "18e0cd8791dfb9d7/variationno.pdf",
                    "order": 2,
                    "createdAt": "2024-03-05T04:45:30.595Z"
                }
            ]
        },
        "tabFiles": [],
        "briefs": [
            {
                "id": "eea6a4db-ec91-4a80-8176-6149b39c3578",
                "name": "Variations"
            }
        ],
        "labels": {
            "items": []
        },
        "totalCost": 0
    }
]

export const ProgressPhotosData = [
        {
            "id": "b926330c-8f0d-4d49-8cee-3571a3b1e2c2",
            "description": "Homeowners visit the site and took photos of the completed brickwork.\n﻿\n﻿\n",
            "comments": "",
            "date": "2024-02-08T16:00:00.000Z",
            "createdAt": "2024-03-05T05:10:30.352Z",
            "order": 1,
            "dynamicData": null,
            "email": null,
            "files": {
                "items": [
                    {
                        "id": "278ae525-2586-46b8-91a5-eaf38aadaedd",
                        "name": "Dalle1.jpg",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709614179620Dalle3.jpg",
                        "order": 0,
                        "createdAt": "2024-03-05T04:49:41.803Z"
                    }
                ]
            },
            "tabFiles": null,
            "briefs": [
                {
                    "id": "da63258d-fb3a-4604-9ba5-4d1f6936b2f0",
                    "name": "Progress Photos"
                }
            ],
            "labels": {
                "items": []
            }
        },
        {
            "id": "de3ac994-348e-4ef1-acdb-378ea50980a3",
            "description": "Homeowners visit the site and took photos of the completed framework.\n﻿\n﻿\n",
            "comments": "",
            "date": "2024-01-15T16:00:00.000Z",
            "createdAt": "2024-03-05T05:10:16.214Z",
            "order": 2,
            "dynamicData": null,
            "email": null,
            "files": {
                "items": [
                    {
                        "id": "727337ae-42f5-4687-9c0c-f2fd8caea468",
                        "name": "Dalle2.jpg",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709613856125DALLE20240212185818CreateawideangleimageshowingaresidentialconstructionsitewithcompletedhouseframeworksThephotoshouldbetakenasifbythehomeowners.jpg",
                        "order": 1,
                        "createdAt": "2024-03-05T04:44:19.119Z"
                    }
                ]
            },
            "tabFiles": null,
            "briefs": [
                {
                    "id": "da63258d-fb3a-4604-9ba5-4d1f6936b2f0",
                    "name": "Progress Photos"
                }
            ],
            "labels": {
                "items": []
            }
        },
        {
            "id": "85216e4f-0eef-4af3-bec3-3b78dc551d56",
            "description": "Homeowners visit the site and took photos of the completed slab.\n﻿\n﻿\n",
            "comments": "",
            "date": "2023-12-05T16:00:00.000Z",
            "createdAt": "2024-03-05T05:10:06.978Z",
            "order": 3,
            "dynamicData": null,
            "email": null,
            "files": {
                "items": [
                    {
                        "id": "773d50ce-32bb-48cc-9eee-a18ce91ac708",
                        "name": "Dalle3.jpg",
                        "details": "",
                        "gmailMessageId": "",
                        "gmailMessage": null,
                        "isGmailPDF": false,
                        "isGmailAttachment": false,
                        "s3ObjectKey": "316a3a59-6092-4e56-8f9b-9133b33b4923/1709613738042DALLE20240212183650CreateawideangleimageshowinganewlycompletedhouseslabonaresidentialconstructionsiteThephotoshouldbetakenasifbythehomeowners.jpg",
                        "order": 1,
                        "createdAt": "2024-03-05T04:42:21.106Z"
                    }
                ]
            },
            "tabFiles": null,
            "briefs": [
                {
                    "id": "da63258d-fb3a-4604-9ba5-4d1f6936b2f0",
                    "name": "Progress Photos"
                }
            ],
            "labels": {
                "items": []
            }
        }
]


export const BuildersHeaderData = [
    {
        "id": "a30ff096-ef97-4ce0-910a-b1297d1c42a3",
        "description": null,
        "comments": null,
        "date": null,
        "createdAt": "2024-03-19T08:44:41.973Z",
        "order": 1,
        "dynamicData": "{\"17\":\"Dynamic Builders Pty Ltd\",\"18\":\"Ethan Turner\",\"19\":\"ethan.turner@gmail.com\",\"20\":\"0451798123\"}",
        "email": null,
        "files": {
            "items": []
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "e7f5690e-63f8-496a-a8ac-be43ca79b525",
                "name": "Builder Details"
            }
        ],
        "labels": {
            "items": []
        }
    }
]

export const ContractHeaderData = [
    {
        "id": "2e79c191-1ca5-46e2-9838-2044b6c16e7b",
        "description": null,
        "comments": null,
        "date": null,
        "createdAt": "2024-03-05T04:06:50.123Z",
        "order": 1,
        "dynamicData": "{\"21\":\"2023-11-03T16:00:00.000Z\",\"22\":\"374660\",\"23\":null}",
        "email": null,
        "files": {
            "items": []
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "8f5dbf1a-d1a9-4d08-9bc4-ee4d61ab3c68",
                "name": "Contract Details"
            }
        ],
        "labels": {
            "items": []
        }
    }
]


export const ProjectLocationData = [
    {
        "id": "88c79890-bfd7-4af4-95ec-08e99e18fa36",
        "description": "42 Seabreeze Lane, Shell Cove, NSW 2529",
        "comments": null,
        "date": null,
        "createdAt": "2024-03-05T04:06:49.411Z",
        "order": 1,
        "dynamicData": null,
        "email": null,
        "files": {
            "items": []
        },
        "tabFiles": null,
        "briefs": [
            {
                "id": "bbb12ea6-f349-452d-8468-5546d0f5d5b1",
                "name": "Project Location"
            }
        ],
        "labels": {
            "items": []
        }
    }
]