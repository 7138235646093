import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";
import { API } from "aws-amplify";
import { AppRoutes } from "../../constants/AppRoutes";
import { useHistory } from "react-router-dom";
import { hbaDomains } from "../../constants/AppDomains";

const getDefaults = `query getDefaults {
  defaultUserType
  pages {
    id
    name
    features {
      id
      name
    }
  }
}`;

const mCreateCompany = `mutation createCompany($name: String, $representative: RepresentativeInput){
  companyCreate(
    name: $name
    representative: $representative
  ) {
    id
    name
  }
}`;

const mCreateCompanyAccessType = `mutation createCompanyAccessType($companyId: String, $userType: [UserType], $access: [AccessInput]){
  companyAccessTypeCreate(
    companyId: $companyId
    userType: $userType
    access: $access
  ) {
    id
  }
}`;

const mUpdateUser = `mutation updateUser($id: ID!, $email: AWSEmail!, $userType: UserType, $company: CompanyInput, $businessName: String, $hasPortalAccess: Boolean) {
  userUpdate(
    id: $id
    email: $email
    userType: $userType
    company: $company
    businessName: $businessName
    hasPortalAccess: $hasPortalAccess
  ) {
    id
  }
}`;

const mUserCompanyCreate = `mutation createUserCompany($companyId: ID, $hasPortalAccess: Boolean, $userId: ID, $userType: UserType, $userCategory: UserCategory) {
  userCompanyCreate(
    companyId: $companyId
    hasPortalAccess: $hasPortalAccess
    userId: $userId
    userType: $userType
    userCategory: $userCategory
  ) {
    id
  }
}`;

function CreatePortalModal({
  close,
  setshowToast,
  organisationslist,
  // setIsInitializingPortal,
  selectCompany,
}) {
  const [error, setError] = useState(null);
  const [companyName, setcompanyName] = useState("");
  const [pages, setPages] = useState([]);
  const [usertypes, setUsertypes] = useState([]);

  const [btnDisabled, setbtnDisabled] = useState(false);

  const domain = window.location.hostname;

  // Initialize a constant `userCategory`
  // Attempt to retrieve the 'userCategory' from the local storage
  // If not found in local storage, evaluate the following condition:
  // Check if the current domain is included in the list of 'hbaDomains'
  // If true, set 'userCategory' to "HBA_HOMEOWNER"
  // If false, set 'userCategory' to "OWNERBUILDER"

  const getDefaultCategory = () =>{
    let category
    const hbaUserCategories = ["HBA_ARCHITECT_SUPERINTENDENT", "HBA_HOMEOWNER"];
    if(hbaDomains.includes(domain) && localStorage.getItem('userCategory') !== null && !hbaUserCategories.includes(localStorage.getItem('userCategory')) ) {
      category = "OWNERBUILDER"
    }else{
      category = "HBA_HOMEOWNER"
    }
    return category
  }
  const userCategory =
    localStorage.getItem("userCategory") ?? getDefaultCategory()


  let history = useHistory();

  function StopPropagate(e) {
    e.stopPropagation();
  }

  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });

    // if (pages.length === 0 && usertypes.length === 0) {
    //   getDefaultPages();
    // }
  }, []);

  useEffect(() => {
    if (pages.length === 0 && usertypes.length === 0) {
      getDefaultPages();
    }
  }, [pages, usertypes]);

  const getDefaultPages = async () => {
    const dafaults = await API.graphql({
      query: getDefaults,
    });

    setPages(dafaults?.data?.pages);
    setUsertypes(dafaults?.data?.defaultUserType);
  };

  const checkRegistration = () => {
    if (!companyName?.trim()) {
      setshowToast({
        toast: true,
        error: true,
        message: "Please provide a Company Name",
      });
      setTimeout(() => {
        setshowToast({
          toast: false,
          error: null,
          message: "",
        });
      }, 3000);
    } else {
      const companyExists = organisationslist.some(
        (item) => item.company.name === companyName.trim()
      );

      if (!companyExists) {
        const company = {
          name: companyName.trim(),
          representative: {
            id: localStorage.getItem("userId"),
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
          },
        };

        if (!pages || !usertypes) return;
        close();
        // setIsInitializingPortal(true);
        createAccount(company, pages, usertypes).then(() => {
          // setIsInitializingPortal(false);
        });
      } else {
        setshowToast({
          toast: true,
          error: true,
          message: "The portal you are attempting to create already exists.",
        });
        setTimeout(() => {
          setshowToast({
            toast: false,
            error: null,
            message: "",
          });
        }, 3000);
      }
    }
  };

  const Cancel = () => {
    const [isHover, setisHover] = useState(false);
    return (
      <button
        onClick={() => close()}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className="px-16 py-1 flex flex-row font-medium text-md justify-center items-center bg-white rounded-md gap-2 border border-gray-300 hover:bg-gray-700 hover:text-white"
      >
        Cancel
        {/* <CgClose /> */}
      </button>
    );
  };

  const Confirm = () => {
    const [isHover, setisHover] = useState(false);

    return (
      <button
        disabled={btnDisabled}
        onClick={() => {
          checkRegistration();
        }}
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        className={
          btnDisabled
            ? `px-16 py-1 flex flex-row font-medium text-md justify-center
        items-center text-white bg-green-300 rounded-md gap-2 `
            : `px-16 py-1 flex flex-row font-medium text-md justify-center
        items-center text-white bg-green-500 rounded-md gap-2 hover:bg-white
        hover:text-green-500 border border-green-500`
        }
      >
        Submit
        {/* <CgTrash /> */}
      </button>
    );
  };

  async function createAccount(company, pageAcess, usertypes) {
    try {
      setbtnDisabled(true);
      const companyData = await createCompany(company);
      const companyId = companyData?.data?.companyCreate?.id;
      await createCompanyAccessType(companyId, pageAcess, usertypes);

      const userInput = {
        id: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        userType: "OWNER",
        company: {
          id: companyId,
          name: companyName,
        },
        businessName: companyName,
        hasPortalAccess: true,
      };

      const userCompanyInput = {
        userId: localStorage.getItem("userId"),
        companyId: companyId,
        hasPortalAccess: true,
        userType: "OWNER",
        userCategory: userCategory,
      };

      const [userData, userCompanyData] = await Promise.all([
        updateUser(userInput),
        createUserCompany(userCompanyInput),
      ]);

      if (userData && userCompanyData) {
        // setshowToast({
        //   toast: true,
        //   error: null,
        //   message: "Company successfully registered.",
        // });
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("company", companyName);
        localStorage.setItem("userType", "OWNER");
        setcompanyName("");
        setbtnDisabled(false);
        setshowToast({
          toast: false,
          error: null,
          message: "",
        });

        selectCompany(companyId, companyName, "OWNER", userCategory);

        close();
      }
    } catch (error) {
      setbtnDisabled(false);
      throw error;
    }
  }

  async function createCompany(company) {
    try {
      const request = await API.graphql({
        query: mCreateCompany,
        variables: company,
      });

      return request;
    } catch (e) {
      setError(e.errors[0].message);
      throw e;
    }
  }

  async function createCompanyAccessType(companyId, pageAccess, usertypes) {
    try {
      const request = await API.graphql({
        query: mCreateCompanyAccessType,
        variables: {
          companyId: companyId,
          access: pageAccess,
          userType: usertypes,
        },
      });

      return request;
    } catch (e) {
      setError(e.errors[0].message);
      throw e;
    }
  }

  async function updateUser(user) {
    try {
      const request = await API.graphql({
        query: mUpdateUser,
        variables: user,
      });

      return request;
    } catch (e) {
      setError(e.errors[0].message);
      throw e;
    }
  }

  async function createUserCompany(userCompany) {
    try {
      const request = await API.graphql({
        query: mUserCompanyCreate,
        variables: userCompany,
      });

      return request;
    } catch (e) {
      setError(e.errors[0].message);
      throw e;
    }
  }

  return error ? (
    <p>{`Oops! Something went wrong. ${error}`}</p>
  ) : (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="px-8 pt-6 pb-8 flex flex-col bg-white rounded-2xl items-center justify-center opacity-0 scale-90"
          onClick={StopPropagate}
        >
          <div className="font-semibold text-lg pb-3">Register New Company</div>
          <div className="w-full pb-7 pt-5">
            <input
              type="text"
              name="newcompanyname"
              placeholder="Company Name"
              value={companyName}
              onChange={(e) => {
                setcompanyName(e.target.value);
              }}
              className="w-full h-10 border-2 border-zinc-600 pl-3 pr-3 text-xs rounded"
            />
          </div>
          <div className="flex flex-row gap-3">
            <Cancel />
            <Confirm />
          </div>
        </div>
      </div>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default CreatePortalModal;
