import React from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../shared/Button";
import { CgClose } from "react-icons/cg";
import DatePicker from "react-datepicker";

import {
  datePickerDateFormatter,
  timezoneFormatter,
} from "../../shared/userPreferredDateTime";

export default function FilterBriefsModal({
  handleModalClose,
  handleFilter,
  filterBriefs,
}) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      filterType: filterBriefs?.filterType || "DATE_CREATED",
      dateStart: filterBriefs?.dateRange.start || new Date(),
      dateEnd: filterBriefs?.dateRange.end || new Date(),
    },
  });

  const filterType = watch("filterType");

  const preferredTimezone = localStorage.getItem("preferredTimezone");
  const preferredDateFormatType = localStorage.getItem(
    "userpreferredDateFormatType"
  );

  const onSubmit = (data) => {
    const filterData = {
      filterType: data.filterType,
      dateRange: {
        start: data.dateStart,
        end: data.dateEnd,
      },
    };

    handleFilter(filterData);
    handleModalClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-10">
            <div className="flex items-start justify-between rounded-t">
              <h3 className="font-semibold text-gray-900 text-lg pb-2">
                Filter Briefs By Date
              </h3>
              <button
                className="ml-auto h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300"
                onClick={(e) => {
                  e.preventDefault();
                  handleModalClose();
                }}
                type="button"
              >
                <CgClose />
              </button>
            </div>
            <div className="relative py-4 flex-auto p-1">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Filter by:
                </label>
                <Controller
                  name="filterType"
                  control={control}
                  rules={{ required: "Please select a filter type" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className="w-full rounded-md p-2 border border-gray-300 outline-0"
                    >
                      <option value="DATE_CREATED">Date Created</option>
                      <option value="DATE_UPDATED">Date Updated</option>
                    </select>
                  )}
                />
                {errors.filterType && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.filterType.message}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <h4 className="font-medium text-gray-700 mb-2">
                  {filterType === "DATE_CREATED"
                    ? "Date Created"
                    : "Date Updated"}
                </h4>
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      From
                    </label>
                    <Controller
                      name="dateStart"
                      control={control}
                      rules={{ required: "Start date is required" }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className="w-full rounded-md p-2 border border-gray-300 outline-0"
                          selected={
                            preferredTimezone &&
                            preferredTimezone !== "null" &&
                            value &&
                            timezoneFormatter(value, preferredTimezone)
                          }
                          dateFormat={
                            preferredDateFormatType &&
                            preferredDateFormatType !== "null" &&
                            datePickerDateFormatter(preferredDateFormatType)
                          }
                          onChange={(date) => onChange(date)}
                        />
                      )}
                    />
                    {errors.dateStart && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.dateStart.message}
                      </p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      To
                    </label>
                    <Controller
                      name="dateEnd"
                      control={control}
                      rules={{ required: "End date is required" }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className="w-full rounded-md p-2 border border-gray-300 outline-0"
                          selected={
                            preferredTimezone &&
                            preferredTimezone !== "null" &&
                            value &&
                            timezoneFormatter(value, preferredTimezone)
                          }
                          dateFormat={
                            preferredDateFormatType &&
                            preferredDateFormatType !== "null" &&
                            datePickerDateFormatter(preferredDateFormatType)
                          }
                          onChange={(date) => onChange(date)}
                        />
                      )}
                    />
                    {errors.dateEnd && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.dateEnd.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center p-3 rounded-b">
              <Button
                size="medium"
                classname="px-6 mr-auto ml-auto gap-1"
                variant="secondary"
                type="submit"
              >
                Apply Filters
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </form>
  );
}
