import React, { useState, useEffect, useRef } from "react";
import { Base64 } from "js-base64";
import "../../assets/styles/BackgroundPage.css";
import "../../assets/styles/Mobile.css";
import "../../assets/styles/BlankState.css";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";
import Button from "../../shared/Button";
import { MdArrowBackIos } from "react-icons/md";
import { MdClose } from "react-icons/md";
import isMSdoc from "../../shared/msFileTypeChecker";
import { API, Storage } from "aws-amplify";

var moment = require("moment");

function MobileEmailPreview({
  show,
  setShow,
  snippetId,
  gmailMessageId,
  preview,
  from,
  dateEmail,
  subject,
  to,
  cc,
  downloadLink,
  setDownloadLink,
  selectedFile,
  setSelectedFile,
  previewAndDownloadFile,
  previewAndDownloadPDF,
  handleClose,
}) {
  const [iframeLoad, setIframeLoad] = useState(true);

  const qGetFileDownloadLink = `
  query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const downloadImage = async (id) => {
    console.log("downloadImage: ", id);
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;
  
      console.log("s3ObjectKey", s3ObjectKey);
  
      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        // Force file download by creating a blob
        const response = await fetch(downloadURL);
        const blob = await response.blob();
        const downloadLink = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        downloadLink.href = objectUrl;
        downloadLink.download = s3ObjectKey || "downloaded-file";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(objectUrl); // Clean up the URL object
      }
    });
  }

  return (
    <>
      <div>
        <div
          className="justify-center items-center flex-none overflow-x-hidden overflow-y-hidden max-h-screen fixed inset-0 outline-none focus:outline-none"
          style={{ zIndex: 70 }}
        >
          <div
            className="w-full mx-auto h-screen max-h-screen pt-16"
            onClick={(e) => handleClose()}
          >
            <div
              className="items-center h-full border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Heading Div */}
              <div className="w-full p-4" id="emailHeader">
                {/* Button Divs */}
                <div className="flex justify-between">
                  {/* <Button
                    className="border-gray-300 mb-2"
                    onClick={(e) => handleClose()}
                  >
                    <MdArrowBackIos className="text-xl float-left" />
                    Close
                  </Button> */}
                  
                  {subject || iframeLoad === false ? (
                    <>
                      {selectedFile.s3ObjectKey === null &&
                      selectedFile.isGmailPDF &&
                      selectedFile.isGmailAttachment ? (
                        <>
                        <button
                              onClick={(e) =>
                                downloadImage(selectedFile.id)
                              }
                              variant="secondary-l"
                              className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700 mr-2"
                            >
                              Download
                        </button>
                        <Button
                          onClick={(e) =>
                            previewAndDownloadPDF(
                              selectedFile.id,
                              selectedFile.gmailMessageId,
                              selectedFile.name
                            )
                          }
                          variant="primary-l"
                          className="bg-blue-500 hover:bg-blue-700 text-white"
                        >
                          Download Email PDF
                        </Button>
                        <button
                          className="inline-flex px-4 py-2 text-xs font-bold text-white bg-red-500 rounded hover:bg-red-700"
                          type="button"
                          aria-expanded="false"
                          onClick={(e) => handleClose()}
                          >
                          Close
                          <MdClose
                          size={11}
                          style={{
                              marginLeft: "5px",
                              marginTop: "3px",
                          }}
                          />
                        </button>
                        </>
                      ) : (
                        <div className="flex relative bg-red-100 w-full">
                          <div className="absolute right-0 flex">
                            <button
                              onClick={(e) =>
                                downloadImage(selectedFile.id)
                              }
                              variant="secondary-l"
                              className="px-4 py-2 text-xs font-bold text-white bg-green-500 rounded hover:bg-green-700 mr-2"
                            >
                              Download
                            </button>
                            <button
                              onClick={(e) => 
                                // console.log("clicked")
                                previewAndDownloadFile(selectedFile.id)
                              }
                              variant="primary-l"
                              className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700 "
                            >
                              Preview on new tab
                            </button>

                            <button
                              className="ml-2 flex px-4 py-2 text-xs font-bold text-white bg-red-500 rounded hover:bg-red-700  "
                              type="button"
                              aria-expanded="false"
                              onClick={(e) => handleClose()}
                              >
                              Close
                              <MdClose
                              size={11}
                              style={{
                                  marginLeft: "5px",
                                  marginTop: "3px",
                              }}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <SkeletonTheme height={"32px"} width={"150px"}>
                      <Skeleton count={1} />
                    </SkeletonTheme>
                  )}
                </div>
                {selectedFile.isGmailPDF ? (
                  <>
                    <div>
                      {subject && subject !== undefined ? (
                        <>
                          <p>{`From: ${from}`}</p>
                          <p>
                            {`Date: ${moment(dateEmail).format(
                              "DD MMM YYYY, hh:mm A"
                            )}`}
                          </p>
                          <p className="font-bold">{`Subject: ${subject}`}</p>
                          <p>{`To: ${to}`}</p>
                          {cc && <p>{`CC: ${cc}`}</p>}
                        </>
                      ) : (
                        <Skeleton count={4} />
                      )}
                    </div>
                  </>
                ) : null}
              </div>
              {/* Email Body */}
              {selectedFile.isGmailPDF ? (
                <>
                  <div className="overflow-x-auto test123 overflow-y-auto w-full mt-10 h-full">
                    {subject && subject !== undefined ? (
                      <p
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: preview,
                        }}
                      ></p>
                    ) : (
                      <Skeleton count={25} />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="overflow-x-auto overflow-y-auto w-full h-full mt-10">
                    {iframeLoad && (
                      <SkeletonTheme  width={"100%"}>
                        <p>
                          <Skeleton count={1} />
                        </p>
                      </SkeletonTheme>
                    )}
                    {downloadLink && (
                      <iframe
                        title={selectedFile.name}
                        src={downloadLink}
                        // style={{
                        //   minHeight: "500px",
                        // }}
                        className="w-full h-full"
                        onLoad={() => setIframeLoad(false)}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="opacity-25 fixed inset-0 bg-black"
          style={{ zIndex: 60 }}
          onClick={() => setShow(false)}
        ></div>
      </div>
    </>
  );
}

export default MobileEmailPreview;
