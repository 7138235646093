import React, { useEffect, useState, useRef, useMemo } from "react";
import { API } from "aws-amplify";
import Select from "react-select";
import { RiFileInfoLine, RiFilterFill, RiFilterOffLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Button from "../../shared/Button";
import { CgClose } from "react-icons/cg";
import { right } from "@popperjs/core";
import { handleTextractSubscription } from "../../shared/utils/textExtractionUtils";
import {
  BsFillFileEarmarkFill,
  BsFillFileEarmarkFontFill,
  BsFileEarmarkPdfFill,
  BsFillFileEarmarkExcelFill,
  BsFillFileEarmarkWordFill,
  BsFillFileEarmarkSlidesFill,
  BsFillFileEarmarkImageFill,
  BsFillFileEarmarkPlayFill,
  BsFillFileEarmarkMusicFill,
} from "react-icons/bs";
import { FaFileCsv } from "react-icons/fa";
import Spinner from "../../shared/Spinner";
import { CircularProgressbar } from "react-circular-progressbar";
import { AiOutlineDownload, AiFillCloseCircle } from "react-icons/ai";
import BackgroundFilePopupOptions from "./table-info.jsx";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import {
  FaChevronDown,
  FaChevronUp,
  FaSync,
  FaCheckCircle,
  FaSpinner,
} from "react-icons/fa";
import { dateTimeFormatter } from "../../shared/userPreferredDateTime";
import isImageFile from "../../shared/imageFileTypeChecker";
import isPDFFile from "../../shared/pdfFileTypeChecker";
import { isValid } from "js-base64";
import { set } from "lodash";

const RightPanel = ({
  show,
  handleCopyToBackground,
  loaderEmail,
  showRightPanel,
  setShowRightPanel,
  rightPanelItems,
  setRightPanelItems,
  handleOpenAISummaryLogs,
  setOpenAiPrompts,
  previewAndDownloadFile,
  fileTypeCollection,
  openAiPrompts,
  items,
  handleSuggestedPrompts,
  summariseStart,
  setSummariseStart,
  handleSummariseFiles,
  background,
  setBackground,
  setPreviousBackground,
  addPromptHistory,
  handleSavePrompt,
  allowSummarize,
  handleCopyToMemory,
  coreMemory,
  interfaceRightPanel,
  selectedFilesBulkSummary,
  updateBackgroundHistory,
  rightPanelItemsBulkSummary,
  setalertMessage,
  setShowToast,
  setUnreadNotifications,
  setWillBulkSummarise,
  setSelectedFilesBulkSummary,
  setRightPanelItemsBulkSummary,
  getBackground,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCustomPromptInputs, setShowCustomPromptInputs] = useState(false);
  const [customPromptTitle, setCustomPromptTitle] = useState("");
  const [customPrompt, setCustomPrompt] = useState("");
  // const [promptOptions, setPromptOptions] = useState(openAiPrompts);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([
    "Summarize the email",
    "What is the main point?",
    "Action items",
    "Important dates",
  ]);
  const [refresh, setRefresh] = useState(false);
  const [suggestedPrompt, setSuggestedPrompt] = useState("");
  const [executionArn, setExecutionArn] = useState(null);

  const [progressCircleValue, setProgressCircleValue] = useState(0);
  const [messageExtraction, setMessageExtraction] = useState(
    "Extracting text from your attachment now..."
  );
  const [messageSummary, setMessageSummary] = useState("");
  const [extractedText, setExtractedText] = useState(null);
  const [summaryContent, setSummaryContent] = useState("");
  const [defaultPrompt, setDefaultPrompt] = useState([]); //Change to true when file summarization is ongoing

  const [isSummarisingFile, setIsSummarisingFile] = useState(false); //Change to true when file summarization is ongoing
  const [isValidFileToExtract, setIsValidFileToExtract] = useState(true);
  const [totalFilesCount, setTotalFilesCount] = useState(0);
  const [paramBulkExtract, setParamBulkExtract] = useState([]);
  const [checkBulkExtract, setCheckBulkExtract] = useState([]);
  const [renderBulkArr, setRenderBulkArr] = useState(null);
  const [promptBulk, setPromptBulk] = useState([]);

  const createPrompt = `
  mutation createPrompt($companyId: ID, $clientMatterId: ID, $name: String, $prompt: String, $description: String) {
    openAIPromptsCreate(
      companyId: $companyId
      clientMatterId: $clientMatterId
      name: $name
      prompt: $prompt
      description: $description
    ) {
      id
      companyId
      name
      description
      prompt
    }
  }
`;

  const BACKGROUND_TEXTRACT_MUTATION = `
    mutation backgroundFileTextract($backgroundId: ID, $files: [FileTextractInput]!, $emailObject: AWSJSON) {
      backgroundFileTextract(
        backgroundId: $backgroundId,
        emailObject: $emailObject
        files: $files
      )
    }
  `;

  const BULK_BACKGROUND_TEXTRACT_MUTATION = `
    mutation bulkBackgroundFileTextract($input: [BackgroundFileTextractInput]) {
      bulkBackgroundFileTextract(input: $input)
    }`;

  const ON_TEXTRACT_PUBLLISHED = `
    subscription onBackgroundFileTextractPublished($executionArn: String) {
      onBackgroundFileTextractPublished(executionArn: $executionArn) {
        executionArn
        fileId
        extractedFileContent
        nextIndex
      }
    }
  `;

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
    query getBackgroundFile($backgroundId: ID, $fileId: ID) {
      backgroundFile(fileId: $fileId, backgroundId: $backgroundId) {
        extractedFileContent
      }
    }
  `;

  useEffect(() => {
    if (!showRightPanel) {
      setMessageSummary("");
      setExtractedText("");
      setSummariseStart(false);
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }
    }

    // Set Total Files Selected
    let fileCount = 0;

    rightPanelItemsBulkSummary.forEach((item) => {
      fileCount += item.files.length;
    });

    setTotalFilesCount(fileCount);

    // Initial transformation of data
    const transformedData = rightPanelItemsBulkSummary.map((item) => ({
      backgroundId: item.row,
      files: item.files.map((file) => ({
        id: file.id,
        s3ObjectKey: `public/${file.s3ObjectKey}`,
        s3Bucket: file.s3Bucket,
      })),
    }));

    setParamBulkExtract(transformedData);

    // Function to check extracted content and remove files with data
    async function processTransformedData(transformedData) {
      for (let i = transformedData.length - 1; i >= 0; i--) {
        const item = transformedData[i];
        const { backgroundId, files } = item;

        if (!files || files.length === 0) {
          // Remove the whole item if files is empty or null
          transformedData.splice(i, 1);
          continue;
        }

        for (let j = files.length - 1; j >= 0; j--) {
          const { id: fileId } = files[j];
          const result = await getBulkExtractedTextContent(
            backgroundId,
            fileId
          );
          if (result !== null && result) {
            // If extractedContent exists, remove the file from the array
            // files.splice(j, 1);
          } else if (result === null) {
            console.log(`Error extracting text for fileId: ${fileId}`);
          }
        }

        if (files.length === 0) {
          // Remove the whole item if files is empty after processing
          transformedData.splice(i, 1);
        }
      }
      return transformedData;
    }

    // Call the function with the transformed data
    processTransformedData(transformedData)
      .then((updatedData) => {
        console.log("Updated Data:", updatedData);
        setCheckBulkExtract(updatedData);
      })
      .catch((error) => {
        console.error(error);
      });

    const renderBulkSummaryArr = rightPanelItemsBulkSummary.map((items) => ({
      item: items.rowDetails,
      backgroundId: items.row,
      files: items.files.map((file) => ({
        id: file.id,
        name: file.name,
        s3ObjectKey: `public/${file.s3ObjectKey}`,
        s3Bucket: file.s3Bucket,
      })),
    }));

    console.log("renderBulk OUTSIDE", renderBulkSummaryArr);

    setRenderBulkArr(renderBulkSummaryArr);
  }, [showRightPanel]);

  // useEffect(() => {
  //   if (rightPanelItems && interfaceRightPanel === "single") {
  //     getExtractedTextContent();
  //   }
  // }, [rightPanelItems]);

  const getExtractedTextContent = async () => {
    try {
      const {
        data: {
          backgroundFile: { extractedFileContent = "" },
        },
      } = await API.graphql({
        query: BACKGROUND_BY_BRIEF_ID_QUERY,
        variables: {
          backgroundId: rightPanelItems[0].id,
          fileId: rightPanelItems[4].id,
        },
      });

      if (extractedFileContent) {
        setExtractedText(extractedFileContent);
        console.log("has extractedContent state >>>> ", extractedFileContent);
        return extractedFileContent;
      } else {
        setExtractedText("");
        console.log("No extractedContent state >>>> ", extractedFileContent);
      }

      setIsSummarisingFile(false);

      const fileKey = rightPanelItems[4].s3ObjectKey;
      const isSupportedFile = isImageFile(fileKey) || isPDFFile(fileKey);

      if (!isSupportedFile) {
        setSummaryContent(
          "We currently only support summarisation for PDF and image files. Check your file type and try again."
        );
        setExtractedText("");
        setIsValidFileToExtract(false);
      } else {
        setSummaryContent("");
        setIsValidFileToExtract(true);
      }
    } catch (error) {
      console.error("Error fetching extracted file content:", error);
    }
  };

  const getBulkExtractedTextContent = async (backgroundId, fileId) => {
    try {
      const {
        data: {
          backgroundFile: { extractedFileContent = "" },
        },
      } = await API.graphql({
        query: BACKGROUND_BY_BRIEF_ID_QUERY,
        variables: {
          backgroundId: backgroundId,
          fileId: fileId,
        },
      });

      if (extractedFileContent) {
        return extractedFileContent;
      } else {
        console.log("No extracted content found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching extracted file contents:", error);
      return null;
    }
  };

  async function summarize(option = null) {
    if (!option) {
      setShowDropdown(!showDropdown);
      return;
    }

    setShowRightPanel(true);

    let { message, promptHistory } = await handleOpenAISummaryLogs(
      rightPanelItems[0],
      rightPanelItems[1],
      rightPanelItems[3],
      option
    );

    let clone = [...rightPanelItems];
    clone[0].description = message;
    clone[0].promptHistory = promptHistory;
    setRightPanelItems(clone);
  }

  async function handleGenerateSummary() {
    console.group("handleGenerateSummary");
    if (customPromptTitle && customPrompt) {
      const newPrompt = {
        name: customPromptTitle,
        prompt: customPrompt,
      };

      // Check if the prompt already exists to avoid duplication
      const existingPrompt = openAiPrompts?.find(
        (option) =>
          option.name === newPrompt.name && option.prompt === newPrompt.prompt
      );

      console.log("NewPrompts", newPrompt);

      if (!existingPrompt) {
        // summarize(newPrompt);
        setShowCustomPromptInputs(false);

        try {
          const newPromptRes = await API.graphql({
            query: createPrompt,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: " ",
              name: customPromptTitle,
              prompt: customPrompt,
            },
          });

          if (newPromptRes.errors) {
            console.error("Error in API response:", newPromptRes.errors);
          } else {
            const createdPrompt = newPromptRes.data.openAIPromptsCreate;
            console.log("createdPrompt", createdPrompt);
            setOpenAiPrompts((prevPrompts) => [...prevPrompts, createdPrompt]);
          }
        } catch (error) {
          console.error("API call failed:", error);
        }
      }

      // Clear customPrompt and customPromptTitle after handling the generation of summary
      setCustomPrompt("");
      setCustomPromptTitle("");

      setShowDropdown(false);
      setShowCustomPromptInputs(false);
    }
    console.groupEnd("handleGenerateSummary");
  }

  const [objectCountRef, setObjectCountRef] = useState({
    current: 0,
    state: "hidden",
  });

  function freezeBodyScroll() {
    if (objectCountRef.current === 0 && objectCountRef.state === "hidden") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 1, state: "auto" });
  }

  function freeBodyScroll() {
    if (objectCountRef.current === 1 && objectCountRef.state === "auto") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 0, state: "hidden" });
  }

  useEffect(() => {
    if (show) {
      console.log("items in right", rightPanelItems);
      refreshSuggestions();
      freezeBodyScroll();
    } else {
      freeBodyScroll();
    }
  }, [show]);

  const [progressBarValue, setProgressBarValue] = useState(0);
  const [loadingState, setLoadingState] = useState(false);
  const progressBarInterval = useRef(null);

  function updateProgressBarInterval(interval) {
    if (progressBarInterval.current !== null) {
      clearInterval(progressBarInterval.current);
    }
    progressBarInterval.current = interval;
  }
  function getRandomValue(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (loaderEmail !== null) {
      setLoadingState(true);
      setProgressBarValue(0);
      startProgressBar();
    } else {
      stopProgressBar();
    }

    return () => {
      // Cleanup the interval when the component unmounts or loaderEmail changes
      updateProgressBarInterval(null);
    };
  }, [loaderEmail]);

  function startProgressBar() {
    updateProgressBarInterval(
      setInterval(() => {
        setProgressBarValue((prevValue) => {
          const maxIncrement = Math.max(1, 25 * Math.exp(-0.05 * prevValue));
          const increment =
            Math.floor(Math.random() * Math.ceil(maxIncrement)) + 1;

          let newValue = prevValue + increment;

          if (newValue >= 90) {
            newValue = 90;
            clearInterval(progressBarInterval.current);
          }
          return newValue;
        });
      }, 1000)
    );
  }

  function stopProgressBar() {
    clearInterval(progressBarInterval.current);
    setProgressBarValue(100);
    setTimeout(() => {
      setLoadingState(false);
      setProgressBarValue(0);
    }, 1000);
  }

  const fileId = rightPanelItems;

  const historyArr = useMemo(() => {
    function isJSONParsable(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }

    const jsonString = rightPanelItems[0]?.promptHistory;
    var hist;

    if (isJSONParsable(jsonString)) {
      hist = rightPanelItems[0]?.promptHistory
        ? JSON.parse(rightPanelItems[0]?.promptHistory)
        : null;
    } else {
      hist = rightPanelItems[0]?.promptHistory
        ? rightPanelItems[0]?.promptHistory
        : null;
    }

    return hist
      ? Object.keys(hist)
          .map((key) => ({
            id: parseInt(key, 10),
            ...hist[key],
          }))
          .sort((a, b) => b.id - a.id) // Sort the array by id
      : [];
  }, [rightPanelItems]);

  const description = useMemo(() => {
    const matchedItem = historyArr?.find(
      (item) => item?.fileId === rightPanelItems[4]?.id
    );
    return matchedItem?.response || "";
  }, [historyArr]);
  // useEffect(() => {
  //   if (openAiPrompts !== null)
  //     setPromptOptions(openAiPrompts)
  // }, [openAiPrompts])

  const [copiedItems, setCopiedItems] = useState([]);
  const handleSaveCopyDetails = (name, message) => {
    setCopiedItems([...copiedItems, { name: name, message: message }]);
  };

  const [copiedMemoryItems, setCopiedMemoryItems] = useState([]);
  const handleSaveCopyMemoryDetails = (name, message) => {
    setCopiedMemoryItems([
      ...copiedMemoryItems,
      { name: name, message: message },
    ]);
  };

  function FileFormat() {
    const fileFormatIcons = {
      default: <BsFillFileEarmarkFill className="text-blue-400" size={40} />,
      txt: <BsFillFileEarmarkFontFill />,
      pdf: <BsFileEarmarkPdfFill className="text-red-600" size={40} />,
      docx: <BsFillFileEarmarkWordFill className="text-blue-500" size={40} />,
      xlsx: <BsFillFileEarmarkExcelFill className="text-green-500" size={40} />,
      pptx: (
        <BsFillFileEarmarkSlidesFill className="text-orange-600" size={40} />
      ),
      csv: <FaFileCsv className="text-green-400" size={40} />,
      png: <BsFillFileEarmarkImageFill className="text-blue-700" size={40} />,
      jpeg: (
        <BsFillFileEarmarkImageFill className="text-orange-700" size={40} />
      ),
      mp4: <BsFillFileEarmarkPlayFill className="text-blue-400" size={40} />,
      mov: <BsFillFileEarmarkPlayFill className="text-blue-400" size={40} />,
      mp3: <BsFillFileEarmarkMusicFill size={40} />,
    };

    const fileType = (items) =>
      fileTypeCollection
        ?.filter(
          (fileItem) =>
            fileItem.id ===
            rightPanelItems[0]?.files?.items[rightPanelItems[2]]?.id
        )[0]
        ?.type?.split("/")[1];

    return (
      <>
        {
          //File Icon Implementation
          fileFormatIcons[
            Object.keys(fileFormatIcons).filter((key) =>
              key.includes(fileType(rightPanelItems[0]?.files.items))
            ).length
              ? fileTypeCollection
                  ?.filter(
                    (ftc) =>
                      ftc.id ===
                      rightPanelItems[0]?.files?.items[rightPanelItems[2]]?.id
                  )[0]
                  ?.type?.split("/")[1]
              : "default"
          ]
        }
      </>
    );
  }

  const [copyClicked, setCopyClicked] = useState(false);

  const refreshSuggestions = async () => {
    setRefresh(true);
    // Simulate an async operation like fetching suggestions
    let prompt =
      "Read and analyse the email. Suggest 3 prompts that I should ask you (the AI) to help me understand the email below. It should be generic but concise. My goal is to summarise the email without losing context. Provide your answer in 1 sentence each.";

    const newPrompt = {
      name: prompt,
      prompt: prompt,
    };

    let { message } = await handleSuggestedPrompts(
      rightPanelItems[0],
      rightPanelItems[1],
      rightPanelItems[3],
      newPrompt
    );

    // Initialize an array to hold the prompts
    const prompts = [];

    // Regular expression to match the prompt lines
    const promptRegex = /(\d+\.\s*[^\d.]+)/g;

    // Extract matches
    const matches = message.match(promptRegex);

    if (matches) {
      matches.forEach((match) => {
        prompts.push(match.trim().replace(/^\d+\.\s*/, ""));
      });
    }

    console.log("PROMPTS", prompts);

    // Example: setting new suggestions
    setSuggestions(prompts);
    setRefresh(false);
  };

  async function addPromptSuggestion(prompt) {
    if (prompt) {
      const newPrompt = {
        name: prompt,
        prompt: prompt,
      };

      // Check if the prompt already exists to avoid duplication
      const existingPrompt = openAiPrompts?.find(
        (option) =>
          option.name === newPrompt.name && option.prompt === newPrompt.prompt
      );

      console.log("Added Prompts", newPrompt);

      if (!existingPrompt) {
        let { message, error, aiApiResultId, aiApiTokenUsage } =
          await handleSummariseFiles(extractedText, newPrompt, coreMemory);

        console.log("Summarise text", message);

        let newPromptHistory;
        let name = newPrompt.name;
        let prompt = newPrompt.prompt;

        if (!error) {
          let promptHistoryParsed = rightPanelItems[0]?.promptHistory
            ? JSON.parse(rightPanelItems[0]?.promptHistory)
            : {};

          let lastKey = Object.keys(promptHistoryParsed).length;

          newPromptHistory = JSON.stringify({
            ...promptHistoryParsed,
            [lastKey]: {
              name,
              prompt,
              date: Math.floor(Date.now() / 1000),
              response: message,
              fileName: rightPanelItems[4]?.name,
              fileId: rightPanelItems[4]?.id,
              aiApiResultId: aiApiResultId,
              aiApiTokenUsage: aiApiTokenUsage,
            },
          });
        } else {
          let message =
            "Error encounted during summarization. Please try again.";
          setSummaryContent("Text extraction failed. Please try again.");
          newPromptHistory = addPromptHistory(
            rightPanelItems[0],
            message,
            newPrompt,
            rightPanelItems[4].name,
            rightPanelItems[4].id,
            aiApiResultId,
            aiApiTokenUsage
          );
        }

        //update prompt history
        const data = {
          promptHistory: newPromptHistory,
        };

        const request = await API.graphql({
          query: mUpdateBackgroundDescAndHistory,
          variables: {
            id: rightPanelItems[0]?.id,
            promptHistory: data.promptHistory,
          },
        }).then((req) => {
          const newArrDescription = background.map((bg) => {
            if (bg.id === rightPanelItems[0]?.id) {
              return {
                ...bg,
                promptHistory: newPromptHistory,
              };
            }
            return bg;
          });

          setBackground(newArrDescription);
          setPreviousBackground(newArrDescription);

          let clone = [...rightPanelItems];
          clone[0].promptHistory = data.promptHistory;
          setRightPanelItems(clone);
        });

        // try {
        //   const newPromptRes = await API.graphql({
        //     query: createPrompt,
        //     variables: {
        //       companyId: localStorage.getItem("companyId"),
        //       name: newPrompt.name,
        //       prompt: newPrompt.prompt,
        //       fileId: rightPanelItems[4].id,
        //       fileName: rightPanelItems[4].name,
        //     },
        //   });

        //   if (newPromptRes.errors) {
        //     console.error("Error in API response:", newPromptRes.errors);
        //   } else {
        //     const createdPrompt = newPromptRes.data.openAIPromptsCreate;
        //     console.log("Created Prompts >> ", createdPrompt);
        //     setOpenAiPrompts((prevPrompts) => [...prevPrompts, createdPrompt]);
        //   }
        // } catch (error) {
        //   console.error("API call failed:", error);
        // }
      }
    }
  }

  const subscriptionRef = useRef(null);

  const handleExtractedTextFile = async (prompts) => {
    console.group("handleExtractedTextFile");
    setIsSummarisingFile(true);

    // setDefaultPrompt(prompts);
    try {
      let resultArray = [];

      // Start Extracting text from file from single file
      var params = {};
      if (interfaceRightPanel === "single") {
        params = {
          query: BACKGROUND_TEXTRACT_MUTATION,
          variables: {
            backgroundId: rightPanelItems[0].id,
            files: [
              {
                id: rightPanelItems[4].id,
                s3ObjectKey: `public/${rightPanelItems[4].s3ObjectKey}`,
                s3Bucket: rightPanelItems[4].gmailMessageId
                  ? process.env.REACT_APP_S3_GMAIL_ATTACHMENT_BUCKET
                  : process.env.REACT_APP_S3_UPLOAD_BUCKET,
              },
            ],
          },
        };

        const { data } = await API.graphql(params);

        if (data) {

          // Set progress to 0 initially
          setProgressCircleValue(0);

          // Simulate progress from 0 to 49
          let progress = 0;
          const interval = setInterval(() => {
            if (progress < 49) {
              progress += 0.1;
              progress = parseFloat(progress.toFixed(2)); // Limiting to 2 decimals
              setProgressCircleValue(progress);
            }
          }, 50); // Adjust the interval timing as needed
          
          // console.log("preparing extract text files", data);
          let parsed = JSON.parse(data.backgroundFileTextract);
          // console.log("backgroundFileTextract", parsed);
          let body = JSON.parse(parsed.body);
          // console.log("body", body);

          const executionArn = JSON.parse(
            JSON.parse(data.backgroundFileTextract).body
          ).executionArn;

          console.log("executionArn NEW EXTRACT>>>", executionArn);
          
          const response = await handleTextractSubscription(executionArn);
          
          clearInterval(interval);

          setExtractedText(response[0].extractedFileContent);
          setIsSummarisingFile(true);
          // Update progress to 50% when text extraction is complete
          setProgressCircleValue(50);
          setSummariseStart(true);

          return response;

          // const subscription = await API.graphql({
          //   query: ON_TEXTRACT_PUBLLISHED,
          //   variables: { executionArn },
          // }).subscribe({
          //   next: ({ value }) => {              
          //     const result = value?.data?.onBackgroundFileTextractPublished;
          //     console.log("Received text extraction response", result);

          //     if (
          //       resultArray.length === 0 &&
          //       result.nextIndex === -1 &&
          //       result.fileId === "[ERROR IN TEXTRACTION]"
          //     ) {
          //       handleTextExtractionError();
          //       setIsSummarisingFile(false);
          //     } else {
          //       resultArray.push(result);

          //       if (result.nextIndex === -1) {
          //         console.log("Text extraction complete", resultArray);
          //         let combinedArray =
          //           combineTextractSubscriptionResponse(resultArray);

          //         clearInterval(interval);

          //         setExtractedText(combinedArray[0].extractedFileContent);
          //         setIsSummarisingFile(true);
          //         // Update progress to 50% when text extraction is complete
          //         setProgressCircleValue(50);
          //         setSummariseStart(true);

          //         if (subscription) subscription.unsubscribe();

          //         return result;
          //       }
          //     }
          //   },
          //   error: (error) => console.warn(error),
          // });

          // return () => {
          //   if (subscription) subscription.unsubscribe();
          //   clearInterval(interval); // Clean up interval
          // };
        }
      } else {
        console.log("checkParamsBulk", checkBulkExtract);

        setMessageExtraction("Extracting and summarising files...");

        if (checkBulkExtract && checkBulkExtract.length > 0) {
          params = {
            query: BULK_BACKGROUND_TEXTRACT_MUTATION,
            variables: {
              input: checkBulkExtract,
            },
          };

          const { data } = await API.graphql(params);

          if (data) {
            // console.log("preparing extract text files", data);
            let parsed = JSON.parse(data.bulkBackgroundFileTextract);
            // console.log("backgroundFileTextract", parsed);
            let body = JSON.parse(parsed.body);
            // console.log("body", body);

            const executionArn = JSON.parse(
              JSON.parse(data.bulkBackgroundFileTextract).body
            ).executionArn;

            console.log("executionArn", executionArn);

            // Set progress to 0 initially
            setProgressCircleValue(0);

            // Simulate progress from 0 to 49
            let progress = 0;
            const interval = setInterval(() => {
              if (progress < 49) {
                progress += 0.1;
                progress = parseFloat(progress.toFixed(2)); // Limiting to 2 decimals
                setProgressCircleValue(progress);
              }
            }, 50); // Adjust the interval timing as needed

            const response = await handleTextractSubscription(executionArn);

            // Update progress to 50% when text extraction is complete
            setProgressCircleValue(50);
            processCheckFilesSummary(prompts);
            setIsSummarisingFile(true);
            setSummariseStart(true);
            // setTimeout(() => {
            //   processCheckFilesSummary(prompts);
            //   setExtractedText(result);
            //   setSummariseStart(true);
            // }, 3000);

            clearInterval(interval);

            return response;  

            // subscriptionRef.current = await API.graphql({
            //   query: ON_TEXTRACT_PUBLLISHED,
            //   variables: { executionArn },
            // }).subscribe({
            //   next: async ({ value }) => {
            //     const result = value?.data?.onBackgroundFileTextractPublished;
            //     console.log("Received text extraction response", result);
    
            //     if (
            //       resultArray.length === 0 &&
            //       result.nextIndex === -1 &&
            //       result.fileId === "[ERROR IN TEXTRACTION]"
            //     ) {
                  
            //       handleTextExtractionError();
            //       setIsSummarisingFile(false);

            //       // setTimeout(() => {
            //       //   setSummariseStart(false);
            //       // }, 3000);
            //     } else {
            //       resultArray.push(result);

            //       if (result.nextIndex === -1) {
            //         console.log("Text extraction complete", resultArray);
      
            //         let combinedArray =
            //           combineTextractSubscriptionResponse(resultArray);
    
            //         console.log("Combined Text Extraction array", combinedArray);
  
            //         // Update progress to 50% when text extraction is complete
            //         setProgressCircleValue(50);
            //         processCheckFilesSummary(prompts);
            //         setIsSummarisingFile(true);
            //         setSummariseStart(true);
            //         // setTimeout(() => {
            //         //   processCheckFilesSummary(prompts);
            //         //   setExtractedText(result);
            //         //   setSummariseStart(true);
            //         // }, 3000);

            //         clearInterval(interval);

            //         if (subscriptionRef.current) subscriptionRef.current.unsubscribe();

            //         return combinedArray;  
            //       }
            //     }
            //   },
            //   error: (error) => console.warn(error),
            // });

            // return () => {
            //   if (subscriptionRef.current)
            //     subscriptionRef.current.unsubscribe();
            //   clearInterval(interval); // Clean up interval
            // };
          }
        } else {
          processCheckFilesSummary(prompts);
          setMessageSummary("Summarisation complete.");
          setProgressCircleValue(50);
        }
      }
    } catch (error) {
      handleTextExtractionError();
      setIsSummarisingFile(false);
      console.error("Error in text extraction", error);
    }
    console.groupEnd("handleExtractedTextFile");
  };

  const isRunningProcessCheckFilesSummary = useRef(false);

  async function processCheckFilesSummary(prompts) {
    if (isRunningProcessCheckFilesSummary.current) {
      return;
    }
    console.group("processCheckFilesSummary");

    isRunningProcessCheckFilesSummary.current = true;

    // Introduce a delay
    await new Promise((resolve) => setTimeout(resolve, 0));

    try {
      let errors = [];
      let success = [];
      let filesProcessed = 0;
      let summaryResultGrouped = {};

      console.log("renderBulkArr returned >>", renderBulkArr);

      for (let entry of renderBulkArr) {
        const backgroundId = entry?.backgroundId;
        let items = entry?.item || {}; // Ensure items is an object even if initially undefined
        console.log(`Processing entry with backgroundId: ${backgroundId}`);
        console.log(`Entry details: `, entry);

        const description = entry?.item?.description;
        const promises = entry.files.map(async (file) => {
          filesProcessed++;
          const fileId = file?.id;
          const fileName = file?.name;

          try {
            const result = await getBulkExtractedTextContent(
              backgroundId,
              fileId
            );
            if (result === null) {
              console.log(
                `Error extracting text for fileId: ${fileId}, fileName: ${fileName}`
              );
              errors.push({
                message: "Error extracting",
                fileName,
                fileId,
                backgroundId,
              });
              return { fileId, fileName, status: "error", step: "extraction" };
            }

            console.log("Here are the rowDetails >>> ", items);

            const summaryResult = await handleBulkSummary(
              result,
              prompts,
              coreMemory,
              backgroundId,
              fileId,
              fileName,
              items
            );
            if (summaryResult === null) {
              console.log(
                `Error summarising text for fileId: ${fileId}, fileName: ${fileName}`
              );
              errors.push({
                message: "Error summarising",
                fileName,
                fileId,
                backgroundId,
              });
              return {
                fileId,
                fileName,
                status: "error",
                step: "summarisation",
              };
            }

            success.push({
              fileName,
              fileId,
              backgroundId,
              summarizedContent: summaryResult.message,
              items,
            });

            return { fileId, fileName, status: "success", summaryResult };
          } catch (err) {
            console.error(
              `Error processing file with fileId: ${fileId}, fileName: ${fileName}`,
              err
            );
            errors.push({
              message: "Error processing file",
              fileName,
              fileId,
              backgroundId,
              error: err.message,
            });
            return {
              fileId,
              fileName,
              status: "error",
              step: "processing",
              error: err.message,
            };
          }
        });

        const results = await Promise.all(promises);
        console.log("All promise results:", results);

        const mergedSummaryResults = results.reduce((acc, result) => {
          if (result.status === "success") {
            let {
              rowDetails,
              message,
              option,
              fileName,
              fileId,
              aiApiResultId,
              aiApiTokenUsage,
            } = result.summaryResult;
            if (!acc) {
              // Initialize accumulator with rowDetails if not already set
              acc = rowDetails;
            }
            let updatedPromptHistory = addPromptHistory(
              acc,
              message,
              option,
              fileName,
              fileId,
              aiApiResultId,
              aiApiTokenUsage
            );
            acc = {
              ...acc,
              promptHistory: updatedPromptHistory,
            };
          }
          return acc;
        }, null);

        // Update summaryResultGrouped
        summaryResultGrouped[backgroundId] = mergedSummaryResults;

        console.log("test summary merged >>> ", mergedSummaryResults);

        // Save the merged prompHistory to the background
        await handleSavePrompt(
          backgroundId,
          0,
          mergedSummaryResults?.promptHistory,
          true
        );
      }

      if (errors.length === filesProcessed) {
        setUnreadNotifications((arr) => [
          ...arr,
          {
            message: `Failed bulk summarisation for ${errors.length} files`,
            bulkResult: errors,
          },
        ]);
      } else {
        console.log("\n\n\n\n\n summaryResultGrouped success >>> ", success);
        setUnreadNotifications((arr) => [
          ...arr,
          {
            message: `Summarisation completed for ${
              filesProcessed - errors.length
            } files`,
            bulkResult: [...success, ...errors],
            summaryResultGrouped,
          },
        ]);
      }
      setIsSummarisingFile(false);
      setalertMessage(
        `Summarization completed for selected files. Check the History Logs for details.`
      );
      setShowToast(true);
      setBackground([]);
      getBackground();

      setTimeout(() => {
        setShowToast(false);
        setalertMessage("");
        setShowRightPanel(false);
        setIsSummarisingFile(false);
        setRenderBulkArr([]);
        setRightPanelItemsBulkSummary([]);
        setSelectedFilesBulkSummary([]);
        setWillBulkSummarise(false);
      }, 3000);
    } catch (error) {
      console.error("Error processing files:", error);
    }
    isRunningProcessCheckFilesSummary.current = false;
    console.groupEnd("processCheckFilesSummary");
  }

  function correctAndParseJsonArray(jsonArray) {
    // Array to store the parsed and corrected JSON objects
    const correctedJsonArray = jsonArray.map((jsonString) => {
      // Remove comments (both single-line and multi-line)
      jsonString = jsonString.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, "");

      // Remove trailing commas before closing brackets
      jsonString = jsonString.replace(/,\s*([\]}])/g, "$1");

      // Ensure all keys are double-quoted
      jsonString = jsonString.replace(/(\w+):/g, '"$1":');

      try {
        // Parse the cleaned-up string
        const data = JSON.parse(jsonString);
        return data;
      } catch (error) {
        console.error(`Invalid JSON format in: ${jsonString}`);
        console.error(`Error: ${error.message}`);
        return null;
      }
    });

    return correctedJsonArray.filter((item) => item !== null);
  }

  const mUpdateBackgroundDescAndHistory = `
    mutation updateBackground($id: ID,$ promptHistory: AWSJSON) {
      backgroundUpdate(id: $id, promptHistory: $promptHistory) {
        id
        promptHistory
      }
    }
  `;

  const handleTextExtractionError = async () => {
    let promptHistory = addPromptHistory(
      rightPanelItems[0],
      "Error encountered during text extraction. Please try again.",
      {
        name: "Text Extraction",
        prompt: "Text Extraction Error",
      },
      rightPanelItems[4].name,
      rightPanelItems[4].id
    );

    // Destructure choices directly in the function call
    await handleSavePrompt(
      rightPanelItems[0].id,
      rightPanelItems[2],
      promptHistory
    ).then(async () => {
      // const params = {
      //   query: mCreateActivity,
      //   variables: {
      //     companyId: localStorage.getItem("companyId"),
      //     clientMatterId: matter_id,
      //     briefId: briefId,
      //     activity: "updated the background history log",
      //     field: "History Log",
      //     current: description,
      //     previous: origDescription,
      //     appModule: "BACKGROUND",
      //     rowId: id,
      //   },
      // };
      // const addActivity = await API.graphql(params).then((result) => {
      //   console.log("addActivity result", result);
      // });
    });

    let clone = [...rightPanelItems];
    clone[0].promptHistory = promptHistory;
    setRightPanelItems(clone);
    setSummaryContent("Text extraction failed. Please try again.");
  };

  async function handleSummary(extractedText, option, coreMemory) {
    console.log("entered1", option)
    const { message, error, aiApiResultId, aiApiTokenUsage } =
      await handleSummariseFiles(extractedText, option, coreMemory);

    setSummariseStart(true);

    let promptHistory;
    if (!error) {
      console.log("noerrorentered", option)
      promptHistory = addPromptHistory(
        rightPanelItems[0],
        message,
        option,
        rightPanelItems[4].name,
        rightPanelItems[4].id,
        aiApiResultId,
        aiApiTokenUsage
      );
    } else {
      console.log("errorentered", option)
      let message = "Error encounted during summarization. Please try again.";
      setSummaryContent("Text extraction failed. Please try again.");
      promptHistory = addPromptHistory(
        rightPanelItems[0],
        message,
        option,
        rightPanelItems[4].name,
        rightPanelItems[4].id,
        aiApiResultId,
        aiApiTokenUsage
      );
    }

    await handleSavePrompt(
      rightPanelItems[0].id,
      rightPanelItems[2],
      promptHistory
    ).then(async () => {});

    let clone = [...rightPanelItems];
    clone[0].promptHistory = promptHistory;
    setRightPanelItems(clone);

    // handleGenerateSummary();

    console.log("handleSummary()");
  }

  async function handleBulkSummary(
    content,
    option,
    coreMemory,
    rowId,
    fileId,
    fileName,
    rowDetails
  ) {
    const { message, error, aiApiResultId, aiApiTokenUsage } =
      await handleSummariseFiles(content, option, coreMemory);
    let returnValue = {
      rowDetails,
      option,
      fileName,
      fileId,
      aiApiResultId, // Add aiApiResultId to the return value
      aiApiTokenUsage,
    };

    console.log("aiApiResultId >>> ", aiApiResultId);
    console.log("aiApiTokenUsage >>> ", aiApiTokenUsage);

    let promptHistory;
    if (!error) {
      returnValue["message"] = message;
      // promptHistory = await addPromptHistory(rowDetails, message, option, fileName, fileId);
    } else {
      let errorMessage =
        "Error encountered during summarization. Please try again.";
      setSummaryContent("Text extraction failed. Please try again.");
      // promptHistory = await addPromptHistory(rowDetails, errorMessage, option, fileName, fileId);
      returnValue["message"] = errorMessage;
    }

    setProgressCircleValue(100);

    setTimeout(() => {
      setSummariseStart(false);
    }, 3000);

    if (error) {
      return null;
    }

    return returnValue; // Return the promptObject
  }

  useEffect(async () => {
    if (isSummarisingFile && progressCircleValue < 99) {
      setMessageSummary("Text extraction complete; starting summarisation...");

      // Simulate progress from 0 to 49


      let progress = 50;
      setProgressCircleValue(progress)


        if (interfaceRightPanel === "single" && extractedText !== "") {
              var { message, error, aiApiResultId, aiApiTokenUsage } =
                await handleSummariseFiles(extractedText, defaultPrompt, coreMemory);

              var newPromptHistory;
              var name = customPromptTitle;
              var prompt = customPrompt;

              if (!error) {
                var promptHistoryParsed = rightPanelItems[0]?.promptHistory
                  ? JSON.parse(rightPanelItems[0]?.promptHistory)
                  : {};

                var lastKey = Object.keys(promptHistoryParsed).length;

                console.log("ishere", name, prompt)

                newPromptHistory = JSON.stringify({
                  ...promptHistoryParsed,
                  [lastKey]: {
                    name,
                    prompt,
                    date: Math.floor(Date.now() / 1000),
                    response: message,
                    fileName: rightPanelItems[4]?.name,
                    fileId: rightPanelItems[4]?.id,
                    aiApiResultId: aiApiResultId,
                    aiApiTokenUsage: aiApiTokenUsage,
                  },
                });

                setProgressCircleValue(100);

                setTimeout(() => {
                  setSummaryContent(message);
                  setIsSummarisingFile(false);
                }, 3000);
              } else {
                var optionStart = {name: customPromptTitle, prompt: customPrompt}
                var message =
                  "Error encounted during summarization. Please try again.";
                setSummaryContent("Text extraction failed. Please try again.");
                newPromptHistory = addPromptHistory(
                  rightPanelItems[0],
                  message,
                  // { name, prompt },
                  optionStart,
                  rightPanelItems[4].name,
                  rightPanelItems[4].id,
                  aiApiResultId,
                  aiApiTokenUsage
                );
              }

              //update prompt history
              const data = {
                promptHistory: newPromptHistory,
              };

              const request = await API.graphql({
                query: mUpdateBackgroundDescAndHistory,
                variables: {
                  id: rightPanelItems[0]?.id,
                  promptHistory: data.promptHistory,
                },
              }).then((req) => {
                const newArrDescription = background.map((bg) => {
                  if (bg.id === rightPanelItems[0]?.id) {
                    return {
                      ...bg,
                      promptHistory: newPromptHistory,
                    };
                  }
                  return bg;
                });

                setBackground(newArrDescription);
                setPreviousBackground(newArrDescription);

                let clone = [...rightPanelItems];
                clone[0].promptHistory = data.promptHistory;
                setRightPanelItems(clone);
              });

              console.log("updated successfully", request);  
         

          
        }

        if (isSummarisingFile && progressCircleValue === 50) {
          setMessageSummary("Summarisation complete.");
          setProgressCircleValue(51);
        }
    } else if (progressCircleValue === 100) {
      setMessageSummary("Summarisation complete.");
    }
  }, [isSummarisingFile, extractedText]);

  return (
    show && (
      <>
        <div
          className="outline-none focus:outline-none h-full fixed inset-0 z-50 flex"
          style={{ zIndex: 70 }}
        >
          <div
            className="fixed left-0 w-3/4 h-full bg-transparent"
            onClick={() => {
              setShowRightPanel(false);
              setShowDropdown(false);
              setIsSummarisingFile(false);
              setProgressCircleValue(0);
              setMessageSummary(
                "Text extraction complete; starting summarisation..."
              );
              setSummariseStart(false);
              setExtractedText("");
            }}
          ></div>
          <div className="fixed right-0 w-1/4 h-full">
            <div className="flex flex-col outline-none focus:outline-none relative w-full h-full rounded-sm border-0 bg-white p-3 shadow-lg">
              <div className="flex items-start justify-between w-full">
                <h3 className="pb-2 text-lg font-semibold text-gray-900">
                  {interfaceRightPanel === "bulk"
                    ? "Bulk Summary"
                    : "Email Details"}
                </h3>
                <button
                  className="ml-auto mt-2 flex h-4 w-4 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-100 hover:bg-gray-300"
                  onClick={() => {
                    setShowRightPanel(false);
                    setShowDropdown(false);
                    setIsSummarisingFile(false);
                    setProgressCircleValue(0);
                    setMessageSummary(
                      "Text extraction complete; starting summarisation..."
                    );
                    setSummariseStart(false);
                    setExtractedText("");
                  }}
                >
                  <CgClose />
                </button>
              </div>

              {interfaceRightPanel === "bulk" ? (
                <>
                  {isSummarisingFile ? (
                    <>
                      <div>
                        <div className="w-full h-20 flex items-center justify-center">
                          <CircularProgressbar
                            value={progressCircleValue}
                            text={`${progressCircleValue}%`}
                            className="w-10 h-10"
                          />
                        </div>
                        <div className="mt-1 flex flex-col">
                          <div className="flex items-center">
                            {progressCircleValue >= 50 ? (
                              <FaCheckCircle
                                size="1em"
                                className={"text-blue-400"}
                              />
                            ) : (
                              <FaRegCheckCircle
                                size="1em"
                                className={"text-gray-700"}
                              />
                            )}
                            <p
                              className={
                                progressCircleValue >= 50
                                  ? "ml-2 text-sm text-blue-400"
                                  : "ml-2 text-sm text-gray-700"
                              }
                            >
                              {messageExtraction}
                            </p>
                          </div>
                          {progressCircleValue >= 50 && (
                            <div className="flex items-center mt-2">
                              {progressCircleValue >= 100 ? (
                                <FaCheckCircle
                                  size="1em"
                                  className={"text-blue-400"}
                                />
                              ) : (
                                <FaRegCheckCircle
                                  size="1em"
                                  className={"text-gray-700"}
                                />
                              )}
                              {/* <FaCheckCircle size="1em" className={progressCircleValue  ? "text-blue-600" : "text-gray-700"} /> */}
                              <p
                                className={
                                  progressCircleValue >= 100
                                    ? "ml-2 text-sm text-blue-400"
                                    : "ml-2 text-sm text-gray-700"
                                }
                              >
                                {messageSummary}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}

                  <span className="pt-4 pr-4 pb-3 text-xs">
                    You have selected <b>{totalFilesCount}</b> files in your
                    documents.
                  </span>

                  <span className="pr-4 pb-4 text-xs">
                    Please select or create a prompt that you wish to use in
                    bulk summarisation.
                  </span>
                </>
              ) : (
                <>
                  <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

                  <div className=" items-start justify-between w-full py-2 px-2 flex">
                    <FileFormat className="w-1/5" size={20} />
                    <div className="w-4/5">
                      <p className="text-xs font-semibold text-gray-900 ">
                        {
                          rightPanelItems[0]?.files?.items[rightPanelItems[2]]
                            ?.name
                        }
                      </p>
                      <h7
                        className="text-xs font-normal text-blue-400 cursor-pointer"
                        onClick={() =>
                          previewAndDownloadFile(
                            rightPanelItems[0]?.files?.items[rightPanelItems[2]]
                              ?.id
                          )
                        }
                      >
                        Preview File In New Tab
                      </h7>
                    </div>
                  </div>

                  <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

                  {/* Show summary when done summarising; show summarising loader if not*/}
                  {isSummarisingFile === false ? (
                    <>
                      <div className="overflow-y-scroll w-full overflow-x-hidden">
                        <div
                          className={
                            "items-start justify-between w-full py-2 px-2 overflow-y-scroll overflow-x-hidden max-h-64"
                          }
                        >
                          {/* WILL DISPLAY FILE EXTRACTION RESULT HERE KJMF*/}

                          {/* {loadingState ? (
                          <div className=" w-full h-40 flex items-center justify-center">
                            <CircularProgressbar
                              value={progressBarValue}
                              text={`${progressBarValue}%`}
                              className="w-10 h-10"
                            />
                          </div>
                        ) : rightPanelItems[0].description !== null && rightPanelItems[0].description.includes(
                            "Enter some text..."
                          ) ? (
                          <></>
                        ) : (
                          <>
                          {rightPanelItems[0].description} <br/>
                          </>
                        )} */}

                          {description ? (
                            description ===
                            "Error encountered during text extraction. Please try again." ? (
                              <span className="font-medium">
                                Text extraction failed. Please try again.
                              </span>
                            ) : description ===
                              "Error encounted during summarization. Please try again." ? (
                              <span className="font-medium">
                                Failed to summarize the content. Please try
                                again.
                              </span>
                            ) : (
                              description
                            )
                          ) : summaryContent ? (
                            summaryContent
                          ) : (
                            <>No Recent summary to display</>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div className="w-full h-20 flex items-center justify-center">
                          <CircularProgressbar
                            value={progressCircleValue}
                            text={`${progressCircleValue}%`}
                            className="w-10 h-10"
                          />
                        </div>
                        <div className="mt-1 flex flex-col">
                          <div className="flex items-center">
                            {progressCircleValue >= 50 ? (
                              <FaCheckCircle
                                size="1em"
                                className={"text-blue-400"}
                              />
                            ) : (
                              <FaRegCheckCircle
                                size="1em"
                                className={"text-gray-700"}
                              />
                            )}
                            <p
                              className={
                                progressCircleValue >= 50
                                  ? "ml-2 text-sm text-blue-400"
                                  : "ml-2 text-sm text-gray-700"
                              }
                            >
                              {messageExtraction}
                            </p>
                          </div>
                          {progressCircleValue >= 50 && (
                            <div className="flex items-center mt-2">
                              {progressCircleValue >= 100 ? (
                                <FaCheckCircle
                                  size="1em"
                                  className={"text-blue-400"}
                                />
                              ) : (
                                <FaRegCheckCircle
                                  size="1em"
                                  className={"text-gray-700"}
                                />
                              )}
                              {/* <FaCheckCircle size="1em" className={progressCircleValue  ? "text-blue-600" : "text-gray-700"} /> */}
                              <p
                                className={
                                  progressCircleValue >= 100
                                    ? "ml-2 text-sm text-blue-400"
                                    : "ml-2 text-sm text-gray-700"
                                }
                              >
                                {messageSummary}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {loaderEmail === null && (
                <>
                  {allowSummarize && isValidFileToExtract && (
                    <>
                      <div className="w-full h-20 flex-none">
                        <Button
                          size="xs"
                          variant="primary"
                          className={
                            isSummarisingFile === true
                              ? "px-3 py-2 float-left mt-3 flex bg-gray-200"
                              : "px-3 py-2 float-left mt-3 flex"
                          }
                          type="button"
                          onClick={() => {
                            isSummarisingFile === true
                              ? console.log("disabled")
                              : setShowDropdown(!showDropdown);

                            // console.log("from gmail", rightPanelItems.gmailMessageId);
                            // setIsSummarisingFile(true);
                            // handleExtractedTextFile();
                          }}
                        >
                          {isSummarisingFile ? (
                            <>Summarizing..</>
                          ) : (
                            <>
                              Summarise
                              <p className="border-l border-solid ml-2 font-xs text-xs">
                                {showDropdown ? (
                                  <FaChevronUp className="ml-2" />
                                ) : (
                                  <FaChevronDown className="ml-2" />
                                )}
                              </p>
                            </>
                          )}
                        </Button>
                      </div>

                      {showDropdown && (
                        <div className="w-full -mt-5 ">
                          <div className="w-full block bg-white border rounded shadow-lg">
                            {showCustomPromptInputs ? (
                              <>
                                <div className="p-4 w-full">
                                  <input
                                    type="text"
                                    placeholder="Enter custom prompt title"
                                    value={customPromptTitle}
                                    onChange={(e) =>
                                      setCustomPromptTitle(e.target.value)
                                    }
                                    className="block w-full px-2 py-1 mb-2 border rounded"
                                  />
                                  <input
                                    type="text"
                                    placeholder="Enter custom prompt"
                                    value={customPrompt}
                                    onChange={(e) =>
                                      setCustomPrompt(e.target.value)
                                    }
                                    className="block w-full px-2 py-1 mb-2 border rounded"
                                  />
                                  <button
                                    onClick={() => {
                                      {extractedText === ""
                                        ? handleExtractedTextFile({name: customPromptTitle, prompt: customPrompt, })
                                        : handleSummary(
                                            extractedText,
                                            {
                                              name: customPromptTitle,
                                              prompt: customPrompt,
                                            },
                                            coreMemory
                                          );
                                     
                                          handleGenerateSummary();
                                          setShowDropdown(false);
                                          setPromptBulk({
                                              name: customPromptTitle,
                                              prompt: customPrompt,
                                            });
                                      }
                                         
                                    }}
                                    className="w-full px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                                  >
                                    Generate Summary
                                  </button>
                                </div>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-300 bg-gray-200"
                                  onClick={() =>
                                    setShowCustomPromptInputs(
                                      !showCustomPromptInputs
                                    )
                                  }
                                >
                                  {!showCustomPromptInputs
                                    ? "Add Custom Prompt"
                                    : "Hide Custom Prompt"}
                                </button>
                              </>
                            ) : (
                              <>
                                <div className="h-36 w-full overflow-x-hidden overflow-y-scroll">
                                  {openAiPrompts &&
                                    openAiPrompts.map((option, index) => (
                                      <>
                                        <button
                                          key={index}
                                          className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                                          onClick={() => {
                                            extractedText === "" || true
                                              ? handleExtractedTextFile({
                                                name: option.name,
                                                prompt: option.prompt,
                                              })
                                              : handleSummary(
                                                  extractedText,
                                                  {
                                                    name: option.name,
                                                    prompt: option.prompt,
                                                  },
                                                  coreMemory
                                                );
                                            setPromptBulk(option);
                                            setShowDropdown(false);
                                          }}
                                        >
                                          {option.name}
                                        </button>
                                        <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                                      </>
                                    ))}
                                </div>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-300 bg-gray-200"
                                  onClick={() =>
                                    setShowCustomPromptInputs(
                                      !showCustomPromptInputs
                                    )
                                  }
                                >
                                  {!showCustomPromptInputs
                                    ? "Add Custom Prompt"
                                    : "Hide Custom Prompt"}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {interfaceRightPanel === "single" ? (
                        <>
                          <a
                            href="#"
                            className="ml-1 text-xs text-blue-500 hover:underline flex items-center"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowSuggestions(!showSuggestions);
                            }}
                          >
                            {showSuggestions ? (
                              <>
                                Hide Prompt Suggestions{" "}
                                <FaChevronUp className="ml-2 text-xs" />
                                <FaSync
                                  className={`text-xs ml-2 text-blue-500 cursor-pointer ${
                                    refresh ? "animate-spin" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    refreshSuggestions();
                                  }}
                                  title="Refresh suggestions"
                                />
                              </>
                            ) : (
                              <>
                                Show Prompt Suggestions{" "}
                                <FaChevronDown className="ml-2 text-xs" />
                                <FaSync
                                  className={`text-xs ml-2 text-blue-500 cursor-pointer ${
                                    refresh ? "animate-spin" : ""
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    refreshSuggestions();
                                  }}
                                  title="Refresh suggestions"
                                />
                              </>
                            )}
                          </a>
                        </>
                      ) : null}
                      {showSuggestions && (
                        <div className="dropdown-menu mt-2 p-2 border border-solid bg-white shadow-lg rounded text-xs">
                          <div className="flex flex-col space-y-2">
                            {suggestions.map((suggestion, index) => (
                              <div
                                key={index}
                                className="p-2 border border-solid rounded-lg shadow-sm bg-gray-100 hover:bg-gray-200 cursor-pointer text-xs"
                                onClick={() => {
                                  extractedText === ""
                                    ? handleExtractedTextFile(suggestion)
                                    : handleSummary(
                                        extractedText,
                                        suggestion,
                                        coreMemory
                                      );
                                  addPromptSuggestion(suggestion);
                                  setShowSuggestions(false);
                                }}
                              >
                                {suggestion}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {interfaceRightPanel === "single" ? (
                    <>
                      {historyArr && historyArr.length > 0 ? (
                        //&&
                        // rightPanelItems[0]?.files?.items[rightPanelItems[2]]
                        //   .details === "EMAIL_PDF_VERSION"
                        <div className="pb-4">
                          <p className="px-2 pt-2 text-base font-medium">
                            History Logs
                          </p>
                          <div className="p-2 max-h-48 overflow-y-auto">
                            {historyArr.map((data) => (
                              <div
                                key={data.id}
                                className={
                                  copiedItems.some(
                                    (e) =>
                                      e.name === data.name &&
                                      e.message === data.response
                                  )
                                    ? " px-2 bg-gray-200 rounded-sm"
                                    : "px-2 bg-transparent rounded-sm"
                                }
                              >
                                <hr class="h-px mb-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                                <div className="flex justify-between">
                                  <p
                                    title={data.prompt}
                                    className="font-medium"
                                  >
                                    Prompt: {data?.name}
                                  </p>
                                  <p className="pl-2 text-gray-500">
                                    {dateTimeFormatter(
                                      new Date(data.date * 1000)
                                    )}
                                  </p>
                                </div>
                                <div className="font-medium">
                                  Filename: {data?.fileName}
                                </div>
                                <div className=" inline-block text-xs pb-2">
                                  {data.response}

                                  {/* Start Copy to Memory */}
                                  <button
                                    disabled={copiedMemoryItems.some(
                                      (e) =>
                                        e.name === data.name &&
                                        e.message === data.response
                                    )}
                                    onClick={() => {
                                      handleSaveCopyMemoryDetails(
                                        data.name,
                                        data.response
                                      );
                                      handleCopyToMemory(
                                        rightPanelItems[0],
                                        rightPanelItems[0].id,
                                        data,
                                        rightPanelItems[1],
                                        rightPanelItems[0]?.promptHistory
                                      );
                                    }}
                                    className={
                                      "px-2 text-xs text-blue-400 float-right"
                                    }
                                  >
                                    {copiedMemoryItems.some(
                                      (e) =>
                                        e.name === data.name &&
                                        e.message === data.response
                                    )
                                      ? `Copied To Memory`
                                      : `Copy To Memory`}
                                  </button>
                                  {/* End to Copy to Memory */}

                                  <button
                                    disabled={copiedItems.some(
                                      (e) =>
                                        e.name === data.name &&
                                        e.message === data.response
                                    )}
                                    onClick={() => {
                                      handleSaveCopyDetails(
                                        data.name,
                                        data.response
                                      );
                                      handleCopyToBackground(
                                        rightPanelItems[0],
                                        rightPanelItems[0].id,
                                        data.response,
                                        rightPanelItems[1],
                                        rightPanelItems[0]?.promptHistory
                                      );
                                    }}
                                    className={
                                      "px-2 text-xs text-blue-400 float-right"
                                    }
                                  >
                                    {copiedItems.some(
                                      (e) =>
                                        e.name === data.name &&
                                        e.message === data.response
                                    )
                                      ? `Copied To Background`
                                      : `Copy To Background`}
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <>No history log</>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>

            {!(
              (historyArr && historyArr.length > 0)
              // &&
              // rightPanelItems[0]?.files?.items[rightPanelItems[2]].details ===
              //   "EMAIL_PDF_VERSION"
            ) && (
              <>
                <div className="absolute inset-x-0 bottom-0">
                  <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  <div className=" w-full px-2 py-2 flex  float-bottom">
                    <div className=" p-1 px-4">
                      <Button
                        size="lg"
                        variant="primary"
                        className="w-36 py-1"
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            rightPanelItems[0].description
                          );
                          setCopyClicked(true);
                        }}
                      >
                        {copyClicked === false ? "Copy" : "Copied"}
                      </Button>
                    </div>
                    <div className=" p-1 px-4">
                      <Button
                        size="lg"
                        variant="secondary"
                        className="w-36 py-1"
                        type="button"
                        onClick={() => {
                          previewAndDownloadFile(
                            rightPanelItems[0]?.files?.items[rightPanelItems[2]]
                              ?.id
                          );
                        }}
                      >
                        Download
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="fixed inset-0 bg-black opacity-25"
          style={{ zIndex: 60 }}
        ></div>
      </>
    )
  );
};

export default RightPanel;
