export const AppRoutes = {
  DASHBOARD: "/dashboard",
  ARCHIVES: "/archive",
  MATTERSOVERVIEW: "/matters-overview",
  CONTACTINFORMATION: "/contact-information",
  WITNESSAFFIDAVIT: "/witness-affidavit",
  MATTERSRFI: "/matters-rfi",
  USERTYPEACCESS: "/usertype-access",
  CONTACTS: "/contacts",
  ACCOUNTSETTINGS: "/account-settings",
  POSTREGISTRATION: "/post-registration",
  POSTAUTHENTICATION: "/post-authentication",
  SIGNOUT: "/signout",
  FILEBUCKET: "/file-bucket",
  BACKGROUND: "/background",
  BACKGROUNDAPPER: "/background-apper",
  INBOX: "/inbox",
  LABELS: "/labels",
  RFIPAGE: "/rfi",
  BRIEFS: "/briefs",
  ARCHIVEDBACKGROUND: "/archived-briefs",
  AUTH: "/email-integration-auth",
  TASKS: "/tasks",
  DESIGN: "/design",
  ADMIN: "/admin",
  // PORTALSLIST: "/choose-portal",
  CLIENTMATTERLIBRARY: "/clientmatter-library",
  CATEGORYLIBRARY: "/category-library",
  ONBOARDINGFORMBUILDER: "/public/form-builder-onboarding", //Temporary Route for Onboarding Form Builder
  ONBOARDING: "/public/onboarding",
  ONBOARDINGSUBMISSIONS: "/public/onboarding-submissions",
  TYPEFORM: "/type-form",
  FORM: "/public/:companyId/form/:id",
  FORMRESULTS: "/form/:id",
  FORMDASHBOARD: "/form-dashboard", //Temporary Route for Form Dashboard
  FORMBUILDER: "/form/:id/form-builder", //Temporary Route for Form Builder
  ERROR: "/404",
  HBADASHBOARD: "/hba-dashboard",
  OBADASHBOARD: "/oba-dashboard",
  CONTENTMACHINE: "/content-machine",
  GETTINGSTARTED: "/getting-started",
  REGISTRATION_WITH_SLASH: "/registration/",
  REGISTRATION_WITHOUT_SLASH: "/registration",
  REGISTRATION_BUDGET: "/registration/budget",
};
