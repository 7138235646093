import React, { useEffect, useRef, useState } from "react";
import AccessControl from "../../shared/accessControl";
import AboutTab from "./contact-information-modal-tabs/about";
import ClientMatterTab from "./contact-information-modal-tabs/clientmatter";
import { FiEdit } from "react-icons/fi";
import TeamTab from "./contact-information-modal-tabs/team";
import anime from "animejs";
import Button from "../../shared/Button";
import { isMobileDevice } from "../../shared/mobileViewFunctions";
import { CgClose } from "react-icons/cg";
import { API } from "aws-amplify";
import { toast } from "react-hot-toast";
import { GetTeamCM, GetUserCM } from "../../shared/graphql/queries";
import { hbaDomains } from "../../constants/AppDomains";

const UPDATE_USER_MUTATION = `
  mutation updateUser($contactNumber: String, $email: AWSEmail!, $firstName: String, $id: ID!, $lastName: String, $profilePicture: String, $userType: UserType, $businessName: String, $hasPortalAccess: Boolean, $company: CompanyInput) {
    userUpdate(
      contactNumber: $contactNumber
      firstName: $firstName
      id: $id
      lastName: $lastName
      profilePicture: $profilePicture
      userType: $userType
      email: $email
      businessName: $businessName
      hasPortalAccess: $hasPortalAccess
      company: $company
    ) {
      id
    }
  }
  `;

const TAG_USER_CLIENTMATTER_MUTATION = `
    mutation tagUserClientMatterAccess($clientMatterAccess: [UserClientMatterAccessInput], $userId: ID, $companyId: ID) {
      userClientMatterTag(userId: $userId, clientMatterAccess: $clientMatterAccess, companyId: $companyId) {
        id
      }
    }
  `;

const TAG_ALL_USER_CLIENTMATTER_MUTATION = `mutation tagAlluserClientMatter($companyId: ID, $userId: ID, $userType: UserType) {
	userClientMatterTagAll(userId: $userId, userType: $userType, companyId: $companyId) {
	  id
	}
  }`;

const DELETE_CLIENTMATTER_CONTACT_MUTATION = `
  mutation deleteClientMatterContacts($id: ID) {
    clientMatterContactDetailsDelete(id: $id)
  }`;

const CREATE_CLIENTMATTER_CONTACT_MUTATION = `
  mutation createClientMatterContacts($type: ContactType, $clientMatterId: String, $details: ContactDetailsInput) {
    clientMatterContactDetailsCreate(
      clientMatterId: $clientMatterId
      type: $type
      details: $details
    ) {
      id
    }
  }`;

const RESEND_INVITE_MUTATION = `mutation resendUserInvite($email: String) {
	userResendInvite(email: $email) {
	  isEnabled
	  userAttributes
	  userStatus
	}
  }`;

const INVITE_USER_MUTATION = `mutation inviteUser($email: AWSEmail, $firstName: String, $lastName: String, $userType: UserType, $company: CompanyInput, $businessName: String, $hasPortalAccess:Boolean, $userCategory: UserCategory) {
    userInvite(
      email: $email
      firstName: $firstName
      lastName: $lastName
      userType: $userType
      company: $company
      businessName: $businessName
      hasPortalAccess: $hasPortalAccess
      userCategory: $userCategory
    ) {
      id
      email
    }
  }
`;

const INVITE_EXISTING_USER_MUTATION = `mutation inviteCompanyUser($email: String, $company: String, $invitee: String) {
	userCompanyInvite(email: $email, company: $company, invitee: $invitee)
  }`;

const ExitButton = ({ handleCloseModal }) => {
  return (
    <button
      onClick={handleCloseModal}
      className="h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300 absolute top-5 right-5"
    >
      <CgClose />
    </button>
  );
};
const Tabs = ["About", "Teams", "Client/Matter involved"];

const domain = window.location.hostname;

export default function ContactInformationModal({
  handleCloseModal,
  close,
  user,
  proxyUser,
  image,
  ContactList,
  setContactList,
  tagTeamMember,
  TeamOptions,
  UserTypes,
  TeamList,
  setalertMessage,
  setShowToast,
  seterrortoast,
  setTeamOptions,
  getTeams,
  width,
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  const [isEditing, setisEditing] = useState(false);
  const [SelectedTab, setSelectedTab] = useState("About");

  const [originaluserClientMatter, setoriginaluserClientMatter] = useState([]);
  const [InputData, setInputData] = useState([]);
  const [allowTagClientMatter, setAllowTagClientMatter] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /* ABOUT TAB */
  const {
    firstName,
    lastName,
    address,
    email,
    userType,
    contactNumber,
    businessName,
    hasPortalAccess,
    cognito,
    clientMatterAccess,
  } = proxyUser.current; //Responsible for maintaining data when switching tabs

  var activeUserId = localStorage.getItem("userId");
  const [Firstname, setFirstname] = useState(firstName || "");
  const [Lastname, setLastname] = useState(lastName || "");
  const [Address, setAddress] = useState(address || "");
  const [Email, setEmail] = useState(email || "");

  const [UserType, setUserType] = useState({
    value: userType || "",
    label: userType || "",
  });
  const [Mobile, setMobile] = useState(contactNumber || "");
  const [Company, setCompany] = useState(localStorage.getItem("company"));
  const [BusinessName, setBusinessName] = useState(businessName || "");
  const [isDisabled, setisDisabled] = useState(true);
  const [hasPortalAccessState, sethasPortalAccessState] = useState(
    hasPortalAccess === true
  );

  const currentHasPortalAccess = user?.hasPortalAccess === true;
  const [aboutShowToast, setAboutShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const hideToast = () => {
    setShowToast(false);
  };

  /* TEAMS TAB */
  const [RemoveMember, setRemoveMember] = useState([]);

  /* CLIENTMATTER TAB */
  const companyId = localStorage.getItem("companyId");

  const clientMatters = user?.clientMatterAccess?.items ?? [];

  const userClientMatter = clientMatters.map(({ userType, clientMatter }) => ({
    userType,
    clientMatter,
  }));

  const [UserClientMatter, setUserClientMatter] = useState(userClientMatter);

  async function editUser(user) {
    try {
      console.log("editUser:", user);

      const { userStatus } = user.cognito;

      const updateUser = await API.graphql({
        query: UPDATE_USER_MUTATION,
        variables: user,
      });

      if (updateUser) {
        // console.log("currentHasPortalAccess:", currentHasPortalAccess);
        // console.log("hasPortalAccess", hasPortalAccess);
        if (!currentHasPortalAccess && hasPortalAccess) {
          console.log("give access to user");
          if (userStatus === "UserNotFoundException") {
            // user does not exist
            console.log("user does not exist, create user and send invite");
            await inviteUser(user);
          } else {
            if (userStatus === "FORCE_CHANGE_PASSWORD") {
              // has pending invitation, resend invite
              console.log("user has pending invitation");
              console.log("resend invite, then add this user to company");
              await resendUserInvite(user.email);
            } else if (userStatus === "CONFIRMED") {
              console.log("user already exist, add this user to company");
              console.log("Send invite to Company Email");
              await userCompanyInvite(user);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
      close();
    }
  }

  async function resendUserInvite(email) {
    console.log("resendUserInvite");
    const request = await API.graphql({
      query: RESEND_INVITE_MUTATION,
      variables: {
        email: email,
      },
    });

    return request;
  }

  async function userCompanyInvite(data) {
    console.log("userCompanyInvite", data);

    var firstName = localStorage.getItem("firstName"),
      lastName = localStorage.getItem("lastName"),
      companyName = localStorage.getItem("company");

    const request = await API.graphql({
      query: INVITE_EXISTING_USER_MUTATION,
      variables: {
        email: data.email,
        company: companyName,
        invitee: `${firstName} ${lastName}`,
      },
    });

    if (
      request &&
      (data.userType === "OWNER" || data.userType === "LEGALADMIN")
    ) {
      const params = {
        query: TAG_ALL_USER_CLIENTMATTER_MUTATION,
        variables: {
          companyId: localStorage.getItem("companyId"),
          userId: data.id,
          userType: data.userType,
        },
      };
      let result = await API.graphql(params);
      console.log("RESULT FROM TAGGING IS:", result);
    }

    return request;
  }

  async function inviteUser(data) {
    console.log("inviteUser");

    // userCategory cannot be null or blank, otherwise it will cause an error in the backend
    const category = localStorage.getItem("userCategory");
      const userCategory =
        category && category !== "null" && hbaDomains.includes(domain)
          ? "HBA_HOMEOWNER"
          : "OWNERBUILDER";

    const request = await API.graphql({
      query: INVITE_USER_MUTATION,
      variables: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        userType: data.userType,
        userCategory: userCategory,
        company: data.company, //{id: companyID, name: companyName}
        businessName: data.businessName,
        hasPortalAccess: data.hasPortalAccess,
      },
    });
    // console.log("REQUEST IS:", request);

    if (
      request &&
      (data.userType === "OWNER" || data.userType === "LEGALADMIN")
    ) {
      const params = {
        query: TAG_ALL_USER_CLIENTMATTER_MUTATION,
        variables: {
          companyId: localStorage.getItem("companyId"),
          userId: request.data.userInvite.id,
          userType: data.userType,
        },
      };
      let result = await API.graphql(params);
      console.log("RESULT FROM TAG IS:", result);
    }

    return request;
  }

  useEffect((e) => {
    console.log("\n\nuseEffect()\nproxyUser:", proxyUser);

    featureAccessFilters();
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const featureAccessFilters = async () => {
    const { data, status, message } = await AccessControl("CONTACTS");
    const contactsFeatures = data?.features;
    console.log("contactsFeatures", contactsFeatures);
    if (status !== "restrict") {
      setAllowTagClientMatter(contactsFeatures.includes("TAGCLIENTANDMATTER"));
    } else {
      console.log(message);
    }
  };

  const MiniNav = () => {
    return Tabs.map((tab) => {
      const isSelected = SelectedTab === tab;
      const buttonClassName = `font-medium text-gray-${
        isSelected ? "900" : "400"
      } ${
        isSelected ? "" : "hover:text-black hover:border-cyan-500"
      } cursor-pointer`;

      return (
        <button
          key={tab}
          onClick={() => setSelectedTab(tab)}
          className={buttonClassName}
        >
          {tab}
        </button>
      );
    });
  };

  const EditContactButton = () => {
    return isMobileDevice(width) ? (
      <FiEdit onClick={() => setisEditing(true)} />
    ) : (
      <Button
        variant="primary"
        onClick={() => setisEditing(true)}
        className="ml-auto gap-2  px-4 py-1.5 "
      >
        Edit Contact <FiEdit />
      </Button>
    );
  };

  const CancelButton = () => {
    return (
      <Button
        variant="default"
        onClick={() => setisEditing(false)}
        className="ml-auto gap-2 px-2 sm:px-4 py-1 sm:py-1.5 "
      >
        Cancel Editing <FiEdit />
      </Button>
    );
  };

  const handleInitials = (firstName, lastName) => {
    const fullName = `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`;
    let assigneeInitials = "";

    if (fullName === "john dela cruz") {
      assigneeInitials = "JDC";
    } else {
      assigneeInitials += firstName ? firstName.charAt(0) : "";
      assigneeInitials += lastName ? lastName.charAt(0) : "";
    }

    return assigneeInitials.toUpperCase();
  };

  const aboutTabSave = async () => {
    console.log("aboutTabSave()");
    /*************/
    /* ABOUT TAB */
    /*************/

    try {
      const aboutFoundIndex = ContactList.findIndex(
        (x) => x.id === proxyUser.current.id
      );

      console.log("aboutFoundIndex", aboutFoundIndex);

      if (aboutFoundIndex === -1) {
        return;
      }
      const item = {
        id: proxyUser.current.id,
        firstName: Firstname,
        lastName: Lastname,
        email: Email,
        company: {
          id: localStorage.getItem("companyId"),
        },
        contactNumber: Mobile,
        userType: UserType.value,
        businessName: BusinessName,
        hasPortalAccess: hasPortalAccessState,
        cognito: cognito,
      };

      await editUser(item);

      // const updatedSelectedContact = {
      //   ...ContactList[aboutFoundIndex],
      //   ...item,
      // };

      // ContactList[aboutFoundIndex] = updatedSelectedContact;

      // setContactList(ContactList);

      setContactList((prevContactList) => {
        const newContactList = [...prevContactList];
        newContactList[aboutFoundIndex] = {
          ...newContactList[aboutFoundIndex],
          ...item,
        };
        return newContactList;
      });

      if (proxyUser.current.id === activeUserId) {
        localStorage.setItem("email", Email);
        localStorage.setItem("firstName", Firstname);
        localStorage.setItem("lastName", Lastname);
        localStorage.setItem("userType", UserType.value);
        localStorage.setItem("hasPortalAccess", hasPortalAccessState);
      }

      setalertMessage("Successfully Updated Contacts");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      close();
    }
  };

  const mTagClientMatter = `
    mutation tagUserClientMatterAccess($clientMatterAccess: [UserClientMatterAccessInput], $userId: ID, $companyId: ID) {
      userClientMatterTag(userId: $userId, clientMatterAccess: $clientMatterAccess, companyId: $companyId) {
        id
      }
    }
  `;

  const teamsTabSave = async () => {
    console.log("teamsTabSave()");
    /*************/
    /* TEAMS TAB */
    /*************/

    try {
      let teamsFoundIndex = ContactList.findIndex((x) => x.id === user.id);

      let check = checkUserInTeam(user.id);
      if (check.userExist === true) {
        //delete first
        let teamId = check.teamId;
        let findTeam = TeamList.findIndex((x) => x.id === teamId);

        let members = TeamList[findTeam].members.items.filter(
          (u) => u.user.id !== user.id
        );

        members = members.map((u) => {
          return { userId: u.user.id, userType: u.user.userType };
        });

        // then proceed to tagging
        if (members) {
          await tagTeamMember(teamId, members);
          await getTeams();
        }
      }

      //Now add the the member into the team
      let teams = InputData.map((input) => {
        return {
          id: input.teamId,
          name: input.team,
        };
      });

      //KJMF TAG EXISTING CLIENTMATTERS OF NEW TEAM ADDED TO USER
      //check user's original teams
      let userOriginalTeams = ContactList[teamsFoundIndex]?.teams?.items;
      console.log("userOriginalTeams", userOriginalTeams);

      //subtract the original teams array from the new teams array
      console.log("user's tagged teams", teams);

      var newArr = teams?.slice(userOriginalTeams?.length); //get the new teams added

      //check user's old tagged client matter access
      let userOriginalClientMatters =
        ContactList[teamsFoundIndex]?.clientMatterAccess?.items;
      console.log("userOriginalClientMatters", userOriginalClientMatters);

      if (newArr?.length !== 0) {
        newArr.map(async (x) => {
          const response = await GetTeamCM(
            x.id,
            localStorage.getItem("companyId")
          ).then(async (response) => {
            console.log("TLMS", response);
            var newClientMatterAccess = response.data.team.clientMatters.items;
            var combinedArray = [];

            userOriginalClientMatters.map(
              (x) =>
                (combinedArray = [
                  ...combinedArray,
                  { clientMatterId: x.clientMatter.id, userType: "OWNER" },
                ])
            );
            newClientMatterAccess.map(
              (x) =>
                (combinedArray = [
                  ...combinedArray,
                  { clientMatterId: x.id, userType: "OWNER" },
                ])
            );

            console.log("will save", combinedArray);
            //tag team's client matters to user
            //KJMFEDIT
            const params = {
              query: mTagClientMatter,
              variables: {
                clientMatterAccess: combinedArray,
                userId: user.id,
                companyId,
              },
            };

            const result = await API.graphql(params).then((result) => {
              console.log("Tagged", result);
            });
          });
        });
      }
      //END

      teams = teams.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );

      InputData.map(async (x, i) => {
        const final = TeamList.filter((t) => t.id === x.teamId);

        // console.log("Current Team", final);
        // console.log("Target Team", x.teamId);
        // console.log(TeamList)

        let memFinal = final[0]?.members?.items.map((user) => {
          if (user.user.id !== x.userId) {
            return {
              userId: user.user.id,
              userType: user.user.userType,
            };
          }
        });

        memFinal = memFinal?.filter((e) => e !== undefined);

        const memAppend = memFinal?.concat([
          {
            userId: x.userId,
            userType: x.userType,
          },
        ]);

        // console.log("memAppend", memAppend, "index", i);

        if (!memAppend) {
          await tagTeamMember(x.teamId, []);
        } else {
          await tagTeamMember(x.teamId, memAppend);
        }

        //tagTeamMember(x.teamId, {userId: x.userId, userType: x.userType})
      });

      //If there are pending deletes
      if (RemoveMember) {
        RemoveMember.map(async (r, i) => {
          const final = TeamList.filter((t) => t.id === r.teamId);
          let memFinal = final[0]?.members?.items.map((user) => {
            if (user.user.id !== r.userId) {
              return {
                userId: user.user.id,
                userType: user.user.userType,
              };
            }
          });
          memFinal = memFinal?.filter((e) => e !== undefined);

          const memAppend = memFinal?.filter((a) => a.userId !== r.userId);

          // console.log("memAppend", memAppend, "index", i);
          await tagTeamMember(r.teamId, memAppend);
        });
      }

      let final = { items: teams };

      let contactListItem = {
        ...ContactList[teamsFoundIndex],
        teams: final,
      };

      ContactList[teamsFoundIndex] = contactListItem;

      setContactList(ContactList);

      setalertMessage("Successfully Updated Contacts");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
      close();
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
      close();
    }
  };

  const clientMatterTabSave = async () => {
    console.log("clientMatterTabSave()");
    /********************/
    /* CLIENTMATTER TAB */
    /********************/
    try {
      const clientMatter = UserClientMatter.map(
        ({ userType, clientMatter }) => ({
          userType,
          clientMatterId: clientMatter.id,
        })
      );

      console.log("client matter access:", clientMatter);

      const origCM = originaluserClientMatter.map(
        ({ userType, clientMatter }) => ({
          userType,
          clientMatterId: clientMatter.id,
        })
      );

      let toAdd = [];
      let toRemove = [];
      // console.log("===============");
      // console.log("NEW CM:\n", clientMatter);
      // console.log("OLD CM:\n", origCM);
      // console.log("===============");
      var newCMArray = clientMatter.map((newElem) => {
        return newElem.clientMatterId;
      });
      var oldCMArray = origCM.map((oldElem) => {
        return oldElem.clientMatterId;
      });
      // console.log(newCMArray);
      // console.log(oldCMArray);
      toAdd = newCMArray.filter((elem) => {
        return !oldCMArray.includes(elem);
      });
      toRemove = oldCMArray.filter((elem) => {
        return !newCMArray.includes(elem);
      });
      // console.log("toAdd:", toAdd);
      // console.log("toRemove:", toRemove);

      // UPDATE MATTERS OVERVIEW
      origCM.map((oldElem) => {
        let matchIdx = newCMArray.indexOf(oldElem.clientMatterId);
        if (matchIdx > -1) {
          // console.log(oldElem);
          // console.log("VS");
          // console.log(clientMatter[matchIdx]);

          // Check if there is a change in user type
          if (oldElem.userType != clientMatter[matchIdx].userType) {
            // console.log(
            //   "In CM with ID:",
            //   oldElem.clientMatterId,
            //   "there is a change of type from",
            //   oldElem.userType,
            //   "to",
            //   clientMatter[matchIdx].userType
            // );

            // Delete part of Update
            var update_toRemoveId;
            let update_idxRemove = oldCMArray.indexOf(oldElem.clientMatterId);
            originaluserClientMatter[
              update_idxRemove
            ].clientMatter.contacts.items.map((item) => {
              item.details.map((contact) => {
                if (contact.email == user.email) {
                  update_toRemoveId = contact.id;
                }
              });
            });
            deleteClientMatterContacts(update_toRemoveId);

            // Add part of Update
            let update_idxAdd = newCMArray.indexOf(oldElem.clientMatterId);

            let update_userTypeTEMP = [
              "CLIENT",
              "BARRISTER",
              "EXPERT",
            ].includes(clientMatter[update_idxAdd].userType)
              ? clientMatter[update_idxAdd].userType
              : "OTHERPARTY";

            addContact(oldElem.clientMatterId, update_userTypeTEMP, user.email);
          }
        }
      });

      // ADDING TO MATTERS OVERVIEW
      toAdd.map((elem) => {
        //listClientMatterContacts(id);
        let idxAdd = newCMArray.indexOf(elem);
        // console.log("toAddType:\n", clientMatter[idxAdd]);

        let userTypeTEMP = ["CLIENT", "BARRISTER", "EXPERT"].includes(
          clientMatter[idxAdd].userType
        )
          ? clientMatter[idxAdd].userType
          : "OTHERPARTY";

        addContact(elem, userTypeTEMP, user.email);
      });

      // REMOVING FROM MATTERS OVERVIEW
      toRemove.map((elem) => {
        var toRemoveId;
        let idxRemove = oldCMArray.indexOf(elem);

        originaluserClientMatter[idxRemove].clientMatter.contacts.items.map(
          (item) => {
            item.details.map((contact) => {
              if (contact.email == user.email) {
                toRemoveId = contact.id;
              }
            });
          }
        );
        // console.log("TO REMOVE ID:", toRemoveId);
        deleteClientMatterContacts(toRemoveId);
      });

      const apiVariable = {
        clientMatterAccess: clientMatter,
        userId: user.id,
        companyId: companyId,
      };

      const tagClient = await API.graphql({
        query: TAG_USER_CLIENTMATTER_MUTATION,
        variables: apiVariable,
      }).then(() => {
        setalertMessage("Successfully Updated Contacts");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
        close();
      });
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
      close();
    }
  };

  const handleSave = async () => {
    // switch (SelectedTab) {
    //   case "About":
    //     await aboutTabSave();
    //     break;
    //   case "Teams":
    //     await teamsTabSave();
    //     break;
    //   case "Client/Matter involved":
    //     await clientMatterTabSave();
    //     break;
    //   default:
    //     // handle default case if needed
    //     break;
    // }
    try {
      await Promise.all([
        aboutTabSave(),
        teamsTabSave(),
        clientMatterTabSave(),
      ]);
    } catch (error) {
      console.error("An error occurred while saving:", error);
    }
  };

  const checkUserInTeam = (userId) => {
    // console.log("TeamList", TeamList);
    // console.log("userId", userId);
    let userExist = false;
    let teamId = "";
    TeamList.map((team) => {
      team.members.items.map((mem) => {
        if (mem.user.id === userId) {
          userExist = true;
          teamId = team.id;
        }
      });
    });

    return { teamId: teamId, userExist: userExist };
  };

  const deleteClientMatterContacts = async (contactId) => {
    try {
      await API.graphql({
        query: DELETE_CLIENTMATTER_CONTACT_MUTATION,
        variables: {
          id: contactId,
        },
      });

      // If needed, handle responses or side effects here
    } catch (error) {
      // Handle the error more explicitly, possibly logging it if necessary
      console.error("Failed to delete contact", error);
    }
  };

  async function addContact(id, ContactType, email) {
    const details = {
      name: user.firstName + " " + user.lastName,
      number: "",
      email: email,
    };
    try {
      const createClientMatter = await API.graphql({
        query: CREATE_CLIENTMATTER_CONTACT_MUTATION,
        variables: {
          type: ContactType,
          clientMatterId: id,
          details: details,
        },
      }).then((response) => {
        // console.log("Create Contact: ", response);
      });
    } catch {}
  }

  return (
    <>
      <div
        ref={modalContainer}
        onClick={handleCloseModal}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className="p-10 flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen overflow-y-auto relative max-w-screen-lg"
          onClick={(e) => e.stopPropagation()}
        >
          {/*Header*/}
          <div className={`flex flex-row semi`}>
            <div className="font-medium text-base">Contact Information</div>
            <ExitButton handleCloseModal={handleCloseModal} />
          </div>
          {/* <div className="">
            Contacts with access to the portal will automatically receive
            invitation via email.
          </div> */}

          {/*Profile*/}
          <div className="flex flex-row items-center py-8 gap-4">
            <div className="w-13 h-13 sm:w-16 sm:h-16">
              {user.profilePicture !== null ? (
                <img
                  className="rounded-full w-full h-full object-cover"
                  src={user.profilePicture}
                  alt={`prop`}
                />
              ) : (
                <div className="flex bg-gray-500 text-white w-13 h-13 rounded-full text-2xl font-medium justify-center items-center">
                  <h1 className="p-3">
                    {handleInitials(user.firstName, user.lastName)}
                  </h1>
                </div>
              )}
            </div>

            <div
              className={`flex flex-col justify-start gap-1 items-start text-center sm:text-left`}
            >
              <div className="text-base font-semibold">{user.name}</div>
              <div className="font-medium">{user.email}</div>
              <div className="px-2 uppercase rounded-full bg-gray-200 font-semibold p-0.5 text-xs">
                {user.userType}
              </div>
            </div>

            {!allowTagClientMatter &&
            SelectedTab === "Client/Matter involved" ? null : (
              <div className="mt-2 sm:mt-0 ml-auto">
                {isEditing ? <CancelButton /> : <EditContactButton />}
              </div>
            )}
          </div>

          {/*MiniNav */}
          <div className="flex flex-col">
            <div className="flex flex-row gap-5 py-3">
              <MiniNav />
            </div>
            <div className="flex flex-row mb-4 w-full h-0.5">
              <div
                className={
                  SelectedTab === "About"
                    ? "bg-cyan-500 w-28"
                    : "bg-gray-300 w-28"
                }
              ></div>
              <div
                className={
                  SelectedTab === "Teams"
                    ? "bg-cyan-500  w-32"
                    : "bg-gray-300  w-32"
                }
              ></div>
              <div
                className={
                  SelectedTab === "Client/Matter involved"
                    ? "bg-cyan-500  w-80"
                    : "bg-gray-300  w-80"
                }
              ></div>
              <div className="bg-gray-300 w-full"></div>
            </div>
          </div>
          {/*Tabs */}
          {SelectedTab === "About" ? (
            <AboutTab
              close={close}
              user={user}
              proxyUser={proxyUser}
              isEditing={isEditing}
              UserTypes={UserTypes}
              ContactList={ContactList}
              setContactList={setContactList}
              width={width}
              handleSave={handleSave}
              Firstname={Firstname}
              setFirstname={setFirstname}
              Lastname={Lastname}
              setLastname={setLastname}
              Address={Address}
              setAddress={setAddress}
              Email={Email}
              setEmail={setEmail}
              UserType={UserType}
              setUserType={setUserType}
              Mobile={Mobile}
              setMobile={setMobile}
              Company={Company}
              setCompany={setCompany}
              BusinessName={BusinessName}
              setBusinessName={setBusinessName}
              isDisabled={isDisabled}
              setisDisabled={setisDisabled}
              hasPortalAccessState={hasPortalAccessState}
              sethasPortalAccessState={sethasPortalAccessState}
              setAboutShowToast={setAboutShowToast}
              setResultMessage={setResultMessage}
              aboutShowToast={aboutShowToast}
              resultMessage={resultMessage}
              hideToast={hideToast}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              UserClientMatter={UserClientMatter}
              setUserClientMatter={setUserClientMatter}
              setoriginaluserClientMatter={setoriginaluserClientMatter}
            />
          ) : SelectedTab === "Teams" ? (
            <TeamTab
              close={close}
              user={user}
              isEditing={isEditing}
              InputData={InputData}
              setInputData={setInputData}
              ContactList={ContactList}
              setContactList={setContactList}
              tagTeamMember={tagTeamMember}
              TeamOptions={TeamOptions}
              UserTypes={UserTypes}
              TeamList={TeamList}
              setalertMessage={setalertMessage}
              setShowToast={setShowToast}
              setTeamOptions={setTeamOptions}
              getTeams={getTeams}
              width={width}
              RemoveMember={RemoveMember}
              setRemoveMember={setRemoveMember}
              handleSave={handleSave}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
            />
          ) : (
            <ClientMatterTab
              close={close}
              UserClientMatter={UserClientMatter}
              setUserClientMatter={setUserClientMatter}
              isEditing={isEditing && allowTagClientMatter} // only allow editing if has an access to client matter
              clientmatter={user.clientMatterAccess?.items}
              user={user}
              originaluserClientMatter={originaluserClientMatter}
              setoriginaluserClientMatter={setoriginaluserClientMatter}
              setalertMessage={setalertMessage}
              setShowToast={setShowToast}
              seterrortoast={seterrortoast}
              companyId={companyId}
              handleSave={handleSave}
              UserType={UserType}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
            />
          )}
        </div>
      </div>
    </>
  );
}
