import React, { useEffect, useState, useMemo, useRef } from "react";
// import { pageSelectedLabels } from "./index";
import { GrClose } from "react-icons/gr";
import Button from "../../shared/Button";
import { BsFillTrashFill } from "react-icons/bs";
import { RiAddCircleLine } from "react-icons/ri";
import ToastNotification from "../toast-notification";
import { API } from "aws-amplify";
import BackgroundPopupFileUpload from "./form-file-upload.jsx";
import UploadLinkModal from "../file-bucket/file-upload-modal";
import "../../assets/styles/multiselect-custom.css";
import "../../assets/styles/filter-labels.css";
import RemoveSummaryModal from "./remove-summary-prompt-modal";
import isMSdoc from "../../shared/msFileTypeChecker";

const createPrompt = `
  mutation createPrompt($companyId: ID, $clientMatterId: ID, $name: String, $prompt: String, $description: String) {
    openAIPromptsCreate(
      companyId: $companyId
      clientMatterId: $clientMatterId
      name: $name
      prompt: $prompt
      description: $description
    ) {
      id
      companyId
      name
      description
      prompt
    }
  }
`;

const updatePromptName = `
  mutation updatePrompt($companyId: ID!, $id: ID!, $name: String) {
    openAIPromptsUpdate(
      companyId: $companyId
      id: $id
      name: $name
    ) {
      id
    }
  }
`;

const getFile = `
  query getMatterFile($id: ID) {
    file(id: $id) {
      id
      name
      type
      s3ObjectKey
    }
  }
`;

const updatePromptDescription = `
  mutation updatePrompt($companyId: ID!, $id: ID!, $description: String) {
    openAIPromptsUpdate(
      companyId: $companyId
      id: $id
      description: $description
    ) {
      id
    }
  }
`;

const updatePromptFile = `
  mutation updatePrompt($companyId: ID!, $id: ID!, $fileId: ID) {
    openAIPromptsUpdate(
      companyId: $companyId
      id: $id
      fileId: $fileId
    ) {
      id
    }
  }
`;

const updatePromptExtractedContent = `
  mutation updatePrompt($companyId: ID!, $id: ID!, $extractedFileContent: String) {
    openAIPromptsUpdate(
      companyId: $companyId
      id: $id
      extractedFileContent: $extractedFileContent
    ) {
      id
    }
  }
`;

const deletePrompt = `
  mutation deletePrompt($companyId: ID, $id: ID) {
    openAIPromptsDelete(
      companyId: $companyId
      id: $id
    )
  }
`;

export default function ContextualSummaryModal({
  matterId,
  contextualLibrary,
  setContextualLibrary,
  show,
  handleSetShow,
}) {
  const [objectCountRef, setObjectCountRef] = useState({
    current: 0,
    state: "hidden",
  });
  const [tempMemories, setTempMemories] = useState(contextualLibrary || []);
  const [showHideCreateMemoryState, setShowHideCreateMemoryState] =
    useState(false);
  const [loading, setLoading] = useState(false);

  // TOAST STATES
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [isToastError, setIsToastError] = useState(false);
  const [showRemoveSumModal, setShowRemoveSumModal] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const fileArrayRef = useRef([]);
  const [fileArray, setFileArray] = useState([]);

  useEffect(async () => {
    if (!contextualLibrary) return;

    let currentFileArr = fileArrayRef.current;

    let newFilesArr = [];
    contextualLibrary.map((memory) => {
      if (
        memory.fileId &&
        !currentFileArr.some((fileObj) => fileObj.id === memory.fileId)
      ) {
        newFilesArr.push(memory.fileId);
      }
    });

    if (newFilesArr.length === 0) return;

    let newFilesObjArr = await Promise.all(
      newFilesArr.map(async (fileId) => {
        return await API.graphql({
          query: getFile,
          variables: {
            id: fileId,
          },
        });
      })
    );

    let newFilesObjectArrFormatted = newFilesObjArr.map((obj) => obj.data.file);

    let allFiles = [...currentFileArr, ...newFilesObjectArrFormatted];

    fileArrayRef.current = allFiles;
    setFileArray(allFiles);
  }, [contextualLibrary]);

  useEffect(() => {
    if (contextualLibrary && contextualLibrary !== tempMemories)
      setTempMemories(contextualLibrary);
  }, [contextualLibrary]);

  const handleShowHideCreatePrompt = () => {
    if (showHideCreateMemoryState) {
      setShowHideCreateMemoryState(false);
    } else {
      setShowHideCreateMemoryState(true);
    }
  };

  function handleHide() {
    setContextualLibrary(tempMemories);
    handleSetShow();
  }

  const handleToastNotification = (message, isError) => {
    setResultMessage(message);
    setShowToast(true);
    setIsToastError(isError);
    setTimeout(() => {
      hideToast();
    }, 2000);
  };

  const hideToast = () => {
    setShowToast(false);
  };

  function freezeBodyScroll() {
    if (objectCountRef.current === 0 && objectCountRef.state === "hidden") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 1, state: "auto" });
  }

  function freeBodyScroll() {
    if (objectCountRef.current === 1 && objectCountRef.state === "auto") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 0, state: "hidden" });
  }

  useEffect(() => {
    if (show) {
      freezeBodyScroll();
    } else {
      freeBodyScroll();
    }
  }, [show]);

  async function handleDelete(id) {
    console.log("Delete item", id);

    if (id) {
      setLoading(true);
      const deletePromptRes = await API.graphql({
        query: deletePrompt,
        variables: {
          companyId: localStorage.getItem("companyId"),
          id,
        },
      });

      setTempMemories((prevPrompts) =>
        prevPrompts.filter((item) => item.id !== id)
      );
      setLoading(false);
      handleToastNotification("Core memory deleted successfully", false);
      handleModalClose();
    }
  }

  async function handleModalClose() {
    setShowRemoveSumModal(false);
    setSelectedId();
  }

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  async function handleAdd(title, prompt) {
    const newPromptRes = await API.graphql({
      query: createPrompt,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: matterId,
        name: title,
        prompt,
      },
    });

    const newPrompt = newPromptRes.data.openAIPromptsCreate;
    console.log("Created new Memory", newPrompt);

    setTempMemories((prevPrompts) => [...prevPrompts, newPrompt]);
    handleToastNotification("Successfully created core memory", false);
  }

  function handleStageEdits(type, index, value) {
    setTempMemories((prevPrompts) => {
      const updatedPrompts = [...prevPrompts];
      if (updatedPrompts[index][type] !== value) {
        updatedPrompts[index] = { ...updatedPrompts[index], [type]: value };
      }
      return updatedPrompts;
    });
  }

  function handleSaveEdits(type, id) {
    let prompt = contextualLibrary.find((item) => item.id === id);
    let editedPrompt = tempMemories.find((item) => item.id === id);

    if (
      prompt?.name === editedPrompt?.name &&
      prompt?.prompt === editedPrompt?.prompt &&
      prompt?.description === editedPrompt?.description &&
      prompt?.fileId === editedPrompt?.fileId &&
      prompt?.extractedFileContent === editedPrompt?.extractedFileContent
    ) {
      return;
    }

    handleSaveEditsDirectly(editedPrompt, type);
  }

  async function handleSaveEditsDirectly(editedPrompt, type) {
    setLoading(true);
    let queryName;

    switch (type) {
      case "name":
        queryName = updatePromptName;
        break;
      // case "prompt":
      //   queryName = updatePromptContent;
      // break;
      case "description":
        queryName = updatePromptDescription;
        break;
      case "fileId":
        queryName = updatePromptFile;
        break;
      case "extractedFileContent":
        queryName = updatePromptExtractedContent;
        break;
      default:
        break;
    }

    console.log("Saving ", {
      query: queryName,
      variables: {
        companyId: localStorage.getItem("companyId"),
        id: editedPrompt.id,
        [type]: editedPrompt[type],
      },
    });

    await API.graphql({
      query: queryName,
      variables: {
        companyId: localStorage.getItem("companyId"),
        id: editedPrompt.id,
        [type]: editedPrompt[type],
      },
    });

    setTempMemories((prevPrompts) => {
      let res = prevPrompts.map((prompt) =>
        prompt.id === editedPrompt.id
          ? { ...prompt, [type]: editedPrompt[type] }
          : prompt
      );

      setContextualLibrary(res);
      return res;
    });

    handleToastNotification("Successfully edited core memory", false);
    setLoading(false);
  }

  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleUploadLink = async (uf) => {
    var uploadedFiles = uf?.files?.map((f) => ({
      ...f,
      matterId: matterId,
    }));

    await createMatterFile(uploadedFiles);
    handleModalClose();
  };

  const mBulkCreateMatterFile = `
  mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
    matterFileBulkCreate(files: $files) {
      id
      name
      order
    }
  }
`;

  async function createMatterFile(param) {
    param.forEach(function (i) {
      delete i.oderSelected;
    });

    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    console.log("createMatterFile", request);

    return request;
  }

  //KJMF temporary display the IDS
  const [uploadedIdList, setUploadedIdList] = useState([]);

  // GraphQL queries and mutations
  const qGetFileDownloadLink = `query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const previewAndDownloadFile = async (id) => {
    // has PDF already
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  return (
    <>
      <div
        id="filePreviewPopup"
        className={`fixed inset-0 z-50 items-center justify-center max-w-none bg-black bg-opacity-50 overflow-hidden ${
          show && !showRemoveSumModal ? "flex" : "hidden"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex-col flex bg-white rounded p-8 max-w-none mx-auto overflow-hidden w-11/12 h-5/6">
          <div className="flex items-start justify-between rounded-t">
            <h3 className="text-xl font-semibold">Manage Contextual Summary</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => handleHide()}
            >
              <GrClose />
            </button>
          </div>
          <div className="flex w-full py-3 items-end	border-b border-solid border-blueGray-200">
            <div className="text-lg font-medium text-gray-400 flex flex-grow ">
              Core Memory
            </div>
            <Button
              block={true}
              size="large"
              variant="secondary-l"
              disabled={loading}
              onClick={() => handleAdd()}
              className="h-10"
            >
              <RiAddCircleLine className="h-4 w-4 mx-1" />
            </Button>
          </div>
          <div className="h-full overflow-y-auto flex flex-col">
            {tempMemories.map((data, index) => (
              <div className="flex w-full pt-4" key={data.id}>
                {/* <input
                  type="text"
                  value={data.name}
                  onChange={(e) => {
                    handleStageEdits("name", index, e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (e.target.value !== "") {
                        handleSaveEdits("name", data.id);
                      } else {
                        setTempMemories(contextualLibrary);
                        handleToastNotification(
                          "Prompt cannot be empty",
                          true
                        );
                      }
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      handleSaveEdits("name", data.id);
                    } else {
                      setTempMemories(contextualLibrary);
                      handleToastNotification(
                        "Prompt cannot be empty",
                        true
                      );
                    }
                  }}
                  className="rounded-md p-2 border border-gray-300 outline-0 block w-56 mr-2"
                /> */}
                <input
                  type="text"
                  value={data.description}
                  onChange={(e) => {
                    handleStageEdits("description", index, e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (e.target.value !== "") {
                        handleSaveEdits("description", data.id);
                      } else {
                        setTempMemories(contextualLibrary);
                        handleToastNotification(
                          "Response cannot be empty",
                          true
                        );
                      }
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      handleSaveEdits("description", data.id);
                    } else {
                      setTempMemories(contextualLibrary);
                      handleToastNotification("Response cannot be empty", true);
                    }
                  }}
                  className="rounded-md p-2 border border-gray-300 outline-0 flex-grow mr-2"
                />
                {!data?.fileId &&
                (data?.description === null || data.description === "") ? (
                  <BackgroundPopupFileUpload
                    matterId={matterId}
                    rowId={data.id}
                    data={data}
                    handleSaveEditsDirectly={handleSaveEditsDirectly}
                    setUploadedIdList={setUploadedIdList}
                    uploadedIdList={uploadedIdList}
                    setTempMemories={setTempMemories}
                    setContextualLibrary={setContextualLibrary}
                    handleToastNotification={handleToastNotification}
                    setLoading={setLoading}
                  />
                ) : (
                  <div>
                    <div
                      className="px-2 cursor-pointer font-semibold mt-2"
                      onClick={() => previewAndDownloadFile(data.fileId)}
                    >
                      {fileArray.find((file) => file.id === data.fileId)?.name}
                    </div>
                  </div>
                )}

                <Button
                  block={true}
                  size="large"
                  // type="submit"
                  variant="danger-l"
                  disabled={loading}
                  onClick={
                    (event) => {
                      setShowRemoveSumModal(true);
                      setSelectedId(data.id);
                    }
                    //  handleDelete(event, data.id)
                  }
                >
                  <BsFillTrashFill className="h-4 w-4 mx-1" />
                </Button>
              </div>
            ))}
          </div>
          {/* <div className="pt-4 flex gap-x-2 justify-center">
            <Button
              block={true}
              size="large"
              variant="danger"
              disabled={loading}
              onClick={() => {
                handleHide();
              }}
            >
              <span className="w-36 text-base font-semibold">Close</span>
            </Button>
          </div> */}
        </div>
      </div>

      {showHideCreateMemoryState && (
        <CreateMemoryModal
          handleSetShow={handleShowHideCreatePrompt}
          handleAdd={handleAdd}
          handleToastNotification={handleToastNotification}
        />
      )}

      {showRemoveSumModal && (
        <RemoveSummaryModal
          handleSave={handleDelete}
          handleModalClose={handleModalClose}
          selectedPromptId={selectedId}
          openAiPrompts={contextualLibrary}
        />
      )}

      {showToast && (
        <ToastNotification
          title={resultMessage}
          hideToast={hideToast}
          error={isToastError}
        />
      )}
    </>
  );
}

export function CreateMemoryModal({
  handleSetShow,
  handleAdd,
  handleToastNotification,
}) {
  // const [tempTitle, setTempTitle] = useState("");
  const [tempPrompt, setTempPrompt] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSavingNewPrompt() {
    setLoading(true);
    // if (!tempTitle) {
    //   handleToastNotification("Prompt is required", true);
    //   setLoading(false);
    //   return;
    // }
    if (!tempPrompt) {
      handleToastNotification("Response is required", true);
      setLoading(false);
      return;
    }

    // await handleAdd(tempTitle, tempPrompt);
    await handleAdd("", tempPrompt);

    setLoading(false);
    handleSetShow();
  }

  return (
    <>
      <div
        id="filePreviewPopup"
        className="fixed inset-0 z-50 flex items-center justify-center max-w-none bg-black bg-opacity-50 overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex-col flex bg-white rounded p-8 max-w-none mx-auto overflow-hidden w-96">
          <div className="flex items-start justify-between rounded-t">
            <h3 className="text-xl font-semibold">Create Prompt</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => handleSetShow()}
            >
              <GrClose />
            </button>
          </div>
          <div className="flex w-full py-3 border-b border-solid border-blueGray-200"></div>
          {/* <div className="h-full flex">
            <div className="flex w-full pt-4 flex-col">
              <div className="text-base font-medium text-gray-400 flex grow">
                Prompt
              </div>
              <input
                type="text"
                value={tempTitle}
                onChange={(e) => {
                  setTempTitle(e.target.value);
                }}
                className="rounded-md p-2 border border-gray-300 outline-0 flex-grow mr-2"
              />
            </div>
          </div> */}
          <div className="h-full flex">
            <div className="flex w-full pt-4 flex-col">
              <div className="text-base font-medium text-gray-400 flex grow ">
                Response
              </div>
              <input
                type="text"
                value={tempPrompt}
                onChange={(e) => {
                  setTempPrompt(e.target.value);
                }}
                className="rounded-md p-2 border border-gray-300 outline-0 flex-grow mr-2"
              />
            </div>
          </div>
          <div className="pt-4 flex gap-x-2 justify-end">
            <Button
              block={true}
              size="large"
              variant="primary"
              onClick={() => {
                handleSavingNewPrompt();
              }}
              disabled={loading}
            >
              <span className="w-14  text-base font-semibold">Create</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
