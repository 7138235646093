import React from 'react';
import PropTypes from 'prop-types';

const Highlight = ({ text, highlight, classNames }) => {
  if (typeof text !== 'string' || typeof highlight !== 'string') {
    return null; // Return null if text or highlight is not a string
  }

  if (!highlight.trim()) {
    return <span className={classNames} dangerouslySetInnerHTML={{ __html: text }} />;
  }

  const lowerText = text.toLowerCase();
  const lowerHighlight = highlight.toLowerCase();
  let highlightedText = '';
  let lastIndex = 0;
  let index;

  while ((index = lowerText.indexOf(lowerHighlight, lastIndex)) !== -1) {
    highlightedText += text.substring(lastIndex, index);
    highlightedText += `<span class="highlight">${text.substring(index, index + highlight.length)}</span>`;
    lastIndex = index + highlight.length;
  }
  highlightedText += text.substring(lastIndex);

  return <span className={classNames} dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

Highlight.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  classNames: PropTypes.string, // Add classNames to propTypes
};

Highlight.defaultProps = {
  classNames: '', // Default value for classNames
};

export default Highlight;
