import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import BlankState from "../dynamic-blankstate";
import ReactTooltip from "react-tooltip";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import { AppRoutes } from "../../constants/AppRoutes";
import ToastNotification from "../toast-notification";
import ContentEditable from "react-contenteditable";
import AccessControl from "../../shared/accessControl";
import { API } from "aws-amplify";
import ContactInformation from "./contact-information";
import Button from "../../shared/Button";
import "../../assets/styles/MattersOverview.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import BlurredFeature from "../../shared/BlurredFeature";
import BlankList from "../../assets/images/RFI_Blank_List.svg";

export default function MattersOverview() {
  const { id } = useParams();
  const textareaRef = useRef(null);
  //console.log("matter id: ", id);

  const [client, setClient] = useState("");
  const [clientID, setClientID] = useState("");
  const [matter, setMatter] = useState("");
  const [matterID, setMatterID] = useState("");
  const [matterNumber, setMatterNumber] = useState("");
  // const [people, setPeople] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [description, setDescription] = useState("");
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [allowOpenFileBucket, setAllowOpenFileBucket] = useState(false);
  const [allowOpenRFI, setAllowOpenRFI] = useState(false);
  const [allowOpenBackground, setAllowOpenBackground] = useState(false);
  const [allowOpenMatter, setAllowOpenMattersOverview] = useState(false);
  const [allowOpenLabelsLibrary, setAllowOpenLabelsLibrary] = useState(false);
  const [allowOpenDashboard, setAllowOpenDashboard] = useState(false);
  const [allowOpenContactInfo, setAllowOpenContactInfo] = useState(false);
  const [blurredHeader, setBlurredHeader] = useState("");
  const [blurredSubHeader, setBlurredSubHeader] = useState("");
  const hideToast = () => {
    setShowToast(false);
  };
  var activeUserType = localStorage.getItem("userType");

  const { height, width } = useWindowDimensions();

  const contentDiv = {
    margin: "0 0 0 65px",
  };

  const mainGrid = {
    display: "grid",
    gridtemplatecolumn: "1fr auto",
  };

  const qgetClientMatterById = `
  query clientMatterById($id: ID) {
    clientMatter (id: $id) {
      id
      client {
        name
        id
      }
      matter {
        name
        id
      }
      description
      matterNumber
    }
  }`;

  // const qgetUserAccess = `
  // query companyUsers($companyId: String) {
  //   company(id: $companyId) {
  //     name
  //     users {
  //       items {
  //         id
  //         firstName
  //         lastName
  //         profilePicture
  //         clientMatterAccess(companyId: $companyId) {
  //           items {
  //             userType
  //             clientMatter {
  //               id
  //               client {
  //                 name
  //                 id
  //               }
  //               matter {
  //                 name
  //                 id
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // `;

  const mupdateClientMatter = `
  mutation updateClientMatter($description: String, $id: ID) {
    clientMatterUpdate(description: $description, id: $id) {
      id
      description
    }
  }`;

  const mUpdateDateLastOpened = `mutation updateClientMatterLastOpened($id: ID, $lastOpenedAt: AWSDateTime) {
    clientMatterUpdate(id: $id, lastOpenedAt: $lastOpenedAt) {
      id
    }
  }`;

  const mupdateClientMatterNumber = `
  mutation updateClientMatterNumber($id: ID, $matterNumber:String) {
    clientMatterUpdate(id: $id, matterNumber: $matterNumber) {
      id
      matterNumber
    }
  }`;

  /*
   this function was added last August 2, 2023 for the ticket MMA-1764-Dashboard: Matter number for each client/matter
   this function will generate the matter number for each client matter make sure that client and matter is not null
   or atleast have a value if there is none it will cause an error thats why there is a conditional statement that
   checks client and matter to make sure its not empty to not accidently run the code with an empty client and matter.
  */
  // const generateClientMatterNumber = async () =>{
  //   try {
  //     // if the client && matter are not yet queried do not run it / or the state of client and matter is not yet initialized
  //     if(client && matter){
  //       // This code will only run if matterNumber is empty or null
  //       if(!matterNumber){
  //         let matterNumberString;
  //         if(client.length == 1){
  //           matterNumberString = client.substring(0, 1);
  //         }
  //         else if(client.length == 2){
  //           matterNumberString = client.substring(0, 2);
  //         }
  //         else if(client.length >= 3){
  //           matterNumberString = client.substring(0, 3);
  //         }
  //         matterNumberString = matterNumberString.toUpperCase().trim() + "-" + clientID.split('-')[0] + "/" + matterID.split('-')[0];
  //         const response = await API.graphql({
  //           query: mupdateClientMatterNumber,
  //           variables: {
  //             id: id,
  //             matterNumber: matterNumberString,
  //           },
  //         });
  //         console.log("GenerateClientMatterNumber Mutation response:", response.data);
  //       }
  //     }
  //   } catch (error) {
  //     console.log("Error occurred:", error);
  //   }
  // };

  const updateClientMatterLastOpened = async (clientmatter_id) => {
    await API.graphql({
      query: mUpdateDateLastOpened,
      variables: {
        id: clientmatter_id,
        lastOpenedAt: new Date().toISOString(),
      },
    });
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  const getClientMatterById = async (clientmatter_id) => {
    setSkeletonLoad(true);

    const response = await API.graphql({
      query: qgetClientMatterById,
      variables: {
        id: clientmatter_id,
      },
    }).then((response) => {
      console.log("response from getClientMatter ", response);
      setMatterNumber(response.data.clientMatter.matterNumber);
      setClient(response.data.clientMatter.client.name);
      setClientID(response.data.clientMatter.client.id);
      setMatter(response.data.clientMatter.matter.name);
      setMatterID(response.data.clientMatter.matter.id);
      setDescription(response.data.clientMatter.description);
    });
    setSkeletonLoad(false);
  };

  // const getUserAccess = async () => {
  //   const response = await API.graphql({
  //     query: qgetUserAccess,
  //     variables: {
  //       companyId: localStorage.getItem("companyId"),
  //       id: id,
  //     },
  //   }).then((response) => {
  //     console.log(
  //       "response from getUserAccess:",
  //       response.data.company.users.items
  //     );
  //     sortPeople(response.data.company.users.items);
  //   });
  // };

  useEffect(() => {
    getClientMatterById(id);
    // getUserAccess();
    updateClientMatterLastOpened(id);
  }, []);

  useEffect(() => {
    console.log("client + matter: ", client, matter);
    featureAccessFilters();
  }, [client, matter]);

  useEffect(() => {
    handleInput();

    // // generateClientMatterNumber() must be placed in the useEffect to properly get the matter and client so the function runs smoothly
    // // this code was added last August 2, 2023 for the ticket MMA-1764-Dashboard: Matter number for each client/matter
    // if(!matterNumber){
    // this part will generate the matter number of the client/matter card
    // generateClientMatterNumber();
    // }
  }, [description, width]);

  // Start feature access //
  const featureAccessFilters = async () => {
    // Check access control for "MATTERSOVERVIEW" feature
    const mattersOverviewAccess = await AccessControl("MATTERSOVERVIEW");

    if (mattersOverviewAccess.status !== "restrict") {
      const mattersOverviewFeatures = mattersOverviewAccess.data?.features;
      setAllowOpenMattersOverview(true); // Allow opening "MATTERSOVERVIEW"
      await Promise.all([
        setAllowOpenDashboard(
          mattersOverviewFeatures.includes("SHOWDASHBOARDTAB")
        ),
        setAllowOpenContactInfo(
          mattersOverviewFeatures.includes("SHOWCONTACTINFORMATIONTAB")
        ),
      ]);
    } else {
      console.log(mattersOverviewAccess.message); // Log access control message
      setBlurredHeader("Matters Overview is not available.");
      setBlurredSubHeader(
        "Sorry, your user type is not authorised to access Matters Overview. Kindly contact your Admin to grant you access."
      );
    }

    // Check access control for "FILEBUCKET" feature
    const fileBucketAccess = await AccessControl("FILEBUCKET");
    if (fileBucketAccess.status !== "restrict") {
      setAllowOpenFileBucket(true); // Allow opening "FILEBUCKET"
    } else {
      console.log(fileBucketAccess.message); // Log access control message
    }

    // Check access control for "MATTERSRFI" feature
    const RFIAccess = await AccessControl("MATTERSRFI");
    if (RFIAccess.status !== "restrict") {
      setAllowOpenRFI(true); // Allow opening "MATTERSRFI"
    } else {
      console.log(RFIAccess.message); // Log access control message
    }

    // Check access control for "BACKGROUND" feature
    const backgroundAccess = await AccessControl("BACKGROUND");
    if (backgroundAccess.status !== "restrict") {
      setAllowOpenBackground(true); // Allow opening "BACKGROUND"
    } else {
      console.log(backgroundAccess.message); // Log access control message
    }

    // Check access control for "MANAGE USERACCESS" feature
    const labelsLibraryAccess = await AccessControl("LABELS");
    if (labelsLibraryAccess.status !== "restrict") {
      setAllowOpenLabelsLibrary(true);
    } else {
      console.log(labelsLibraryAccess.message);
    }
  };

  // function sortPeople(people) {
  //   const filteredUsers = people.filter((user) => {
  //     return user.clientMatterAccess.items.some(
  //       (clientMatterAccess) => clientMatterAccess.clientMatter.id === id
  //     );
  //   });
  //   setPeople(filteredUsers);
  //   console.log("filteredUsers", filteredUsers);
  // }

  // FOR DESCRIPTION
  function handleInput() {
    if (!skeletonLoad) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }

  async function handleDescription(e) {
    const currentDesc = e.target.value;
    const response = await API.graphql({
      query: mupdateClientMatter,
      variables: {
        id: id,
        description: currentDesc,
      },
    }).then((response) => {
      console.log("Update description:", response);
      setResultMessage("Successfully updated description");
      setToastError(false);
      setShowToast(true);
      setTimeout(hideToast, 4000);
    });
  }

  return (
    <>
      <div className="contentDiv block relative">
        <header className="bg-gray-100 py-10 pl-10 sm:pl-5 sticky top-0 z-10">
          <div className="flex items-center gap-2">
            <Link to={`${AppRoutes.DASHBOARD}`}>
              <div className="flex flex-nowrap items-center gap-2">
                <span>
                  <MdArrowBackIos className="text-xl font-bold" />
                </span>
                <div className="flex flex-wrap gap-2">
                  {skeletonLoad ? (
                    <>
                      <div className="flex">
                        <div>
                          <SkeletonTheme height={"28px"} width={"120px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="mx-3">
                          <SkeletonTheme height={"28px"} width={"120px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                       {localStorage.getItem("cmNameOrder") === null || localStorage.getItem("cmNameOrder") === "Client/Matter"
                      ? (<>
                          <span className="text-xl font-bold">{client}</span>
                          <span className="text-xl font-extralight text-gray-500">
                            {" "}
                            {matter}
                          </span>
                        </>)
                      : (<>
                          <span className="text-xl font-bold">{matter}</span>
                          <span className="text-xl font-extralight text-gray-500">
                            {" "}
                            {client}
                          </span>
                        </>)}
                    </>
                  )}
                </div>
              </div>
            </Link>
          </div>
        </header>

        <main className="lg:px-6 font-sans rounded-lg">
          {/* Matter Description */}
          <section className="mt-6 mx-6 lg:mx-0">
            {/* <div className="mb-2">
              <span className="text-2xl font-semibold text-darkGray leading-9">{matter}</span>
            </div>
            <div className="mb-8">
              <span className="text-2xl font-semibold text-mysteryGray">{client}</span>
            </div> */}
            <div className="mb-6">
              {skeletonLoad ? (
                <SkeletonTheme>
                  <Skeleton count={3} />
                </SkeletonTheme>
              ) : (
                <textarea
                  className="text-base font-normal w-full"
                  ref={textareaRef}
                  key={`{$id}-description`}
                  dir="ltr"
                  defaultValue={description}
                  placeholder={"Click to add Description"}
                  onInput={handleInput}
                  onFocus={handleInput}
                  onBlur={(e) => {
                    handleInput(e);
                    handleDescription(e);
                  }}
                  style={{ resize: "none" }}
                  spellCheck={false}
                ></textarea>
              )}
            </div>

            {width < 640 ? (
              <>
                {allowOpenFileBucket ||
                allowOpenRFI ||
                allowOpenBackground ||
                allowOpenLabelsLibrary ||
                allowOpenDashboard ||
                allowOpenContactInfo ||
                activeUserType === "OWNER" ? (
                  <div className="mb-6">
                    <div className="flex flex-wrap mb-6 sm:mb-0">
                      {/* Dashboard Button */}
                      {allowOpenDashboard || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${AppRoutes.MATTERSOVERVIEW}/${id}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Dashboard
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Contact Information Button */}
                      {allowOpenContactInfo || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${AppRoutes.CONTACTINFORMATION}/${id}`}
                            className="w-full cursor-not-allowed"
                          >
                            <Button
                              size="medium"
                              variant="default-s"
                              className="w-full shadow pointer-events-none"
                            >
                              Contact Information
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Background Button */}
                      {allowOpenBackground || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${
                              AppRoutes.BRIEFS
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Background
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* File Bucket Button */}
                      {allowOpenFileBucket || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${
                              AppRoutes.FILEBUCKET
                            }/${id}/000/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              File Bucket
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* RFI Button */}
                      {allowOpenRFI || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${
                              AppRoutes.MATTERSRFI
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              RFI List
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/** Manage User Access Button */}
                      {allowOpenLabelsLibrary || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${AppRoutes.LABELS}/${id}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              Manage User Access
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Add additional buttons here with w-full p-1 */}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <div className="grid grid-cols-6 gap-x-1 mb-6 sm:mb-0">
                  {allowOpenFileBucket ||
                  allowOpenRFI ||
                  allowOpenBackground ||
                  allowOpenLabelsLibrary ||
                  allowOpenDashboard ||
                  allowOpenContactInfo ||
                  activeUserType === "OWNER" ? (
                    <>
                      {/* Contact Information Button */}
                      {allowOpenDashboard || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${AppRoutes.MATTERSOVERVIEW}/${id}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Dashboard
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Contact Information Button */}
                      {allowOpenContactInfo || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${AppRoutes.CONTACTINFORMATION}/${id}`}
                            className="w-full cursor-not-allowed"
                          >
                            <Button
                              size="medium"
                              variant="default-s"
                              className="w-full shadow pointer-events-none"
                            >
                              Contact Information
                            </Button>
                          </Link>
                        </div>
                      ) : null}
                      {/* Background Button */}
                      {allowOpenBackground || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${
                              AppRoutes.BRIEFS
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Background
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* File Bucket Button */}
                      {allowOpenFileBucket || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${
                              AppRoutes.FILEBUCKET
                            }/${id}/000/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              File Bucket
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* RFI Button */}
                      {allowOpenRFI || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${
                              AppRoutes.MATTERSRFI
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              RFI List
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/** Manage User Access Button */}
                      {allowOpenLabelsLibrary || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${AppRoutes.LABELS}/${id}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              Manage User Access
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Add additional buttons here with col-span-1 */}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </section>
          {/* Contact Information */}
          <div className="text-center my-6 mx-4 sm:mx-0">
            <div
              className="separator-title relative text-center text-mysteryGray font-semibold"
              style={{ fontSize: "10px", letterSpacing: "1px" }}
            >
              SUMMARY
            </div>
          </div>
          <section className="mb-6">
            {skeletonLoad ? (
              <div className="pr-6">
                {[...Array(6).keys()].map((data, index) => (
                  <div className="flex mb-5" key={index}>
                    <div className="flex flex-col flex-1 gap-1 mr-2">
                      {/* Skeleton */}
                      <SkeletonTheme>
                        <Skeleton />
                      </SkeletonTheme>
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                    <div className="flex flex-col flex-1 gap-1 mr-2">
                      <SkeletonTheme>
                        <Skeleton />
                      </SkeletonTheme>
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                    <div className="flex flex-col flex-1 gap-1 mr-2">
                      <SkeletonTheme>
                        <Skeleton />
                      </SkeletonTheme>
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                    <div className="flex flex-col gap-1 w-full lg:w-24 pt-6">
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {!allowOpenContactInfo ? (
                  <>
                    {/*<BlurredFeature
                    header={blurredHeader}
                    subheader={blurredSubHeader}
                  >
                    <ContactInformation
                      id={id}
                      setShowToast={setShowToast}
                      setResultMessage={setResultMessage}
                      setToastError={setToastError}
                      hideToast={hideToast}
                    />
                </BlurredFeature>*/}
                    <BlankState
                      displayText={blurredSubHeader}
                      noLink={true}
                      iconDisplay={BlankList}
                    />
                  </>
                ) : (
                  <ContactInformation
                    id={id}
                    clientFromIndex={client}
                    matter={matter}
                    clientID={clientID}
                    matterID={matterID}
                    setShowToast={setShowToast}
                    setResultMessage={setResultMessage}
                    setToastError={setToastError}
                    hideToast={hideToast}
                  />
                )}
              </>
            )}
          </section>
        </main>
      </div>
      {showToast && resultMessage ? (
        <ToastNotification
          title={resultMessage}
          error={toastError}
          hideToast={hideToast}
        />
      ) : null}

      {/** Test Section */}
    </>
  );
}
