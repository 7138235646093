import { API } from "aws-amplify";

const getAllPages = `
    query getAllPages($companyId: String, $userType: UserType) {
      pages {
        id
        name
        features {
          id
          name
        }
      }
      companyAccessType(companyId: $companyId, userType: $userType) {
        id
        userType
        access {
          id
          features {
            id
          }
        }
      }
    }
  `;

const AccessControl = async (pagename) => {
  console.log("Getting Access Control for", pagename);

  let result,
    warningPrompt =
      "Sorry, your user type is not authorised to access this page. Kindly contact your Admin to grant you access.";

  const user_type = localStorage.getItem("userType"),
    company_id = localStorage.getItem("companyId");

  const params = {
    query: getAllPages,
    variables: {
      companyId: company_id,
      userType: user_type,
    },
  };

  const page_access = await API.graphql(params);

  // If pagename is array, filter page access of pages within the array
  if (pagename.constructor === Array) {
    result = [];
    for (let page of pagename) {
      result.push(filterPageAccess(page, page_access, warningPrompt));
    }
  } else {
    result = filterPageAccess(pagename, page_access, warningPrompt);
  }

  // console.log("AccessControl()", user_type, pagename, result);
  return result;
};

function filterPageAccess(pagename, page_access, warningPrompt) {
  let result;
  const page = page_access.data.pages.filter((f) => f.name === pagename);

  if (page.length !== 0) {
    var userAccess = page_access.data.companyAccessType[0].access;

    userAccess = userAccess.find((p) => p.id === page[0].id);

    if (userAccess !== undefined) {
      const userAccessIDs = userAccess.features.map((f) => f.id);

      var retainedPageNames = page[0].features.filter(
        (f) => userAccessIDs.includes(f.id) && f
      );

      retainedPageNames = retainedPageNames.map((f) => f.name);

      result = {
        status: "allow",
        data: {
          name: page[0].name,
          features: retainedPageNames,
        },
      };
    } else {
      result = {
        status: "restrict",
        message: warningPrompt,
      };
    }
  } else {
    result = {
      status: "restrict",
      message: warningPrompt,
    };
  }
  return result;
}

export default AccessControl;
