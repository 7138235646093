import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { API, label } from "aws-amplify";
import Button from "../../shared/Button";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import classNames from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Select from "react-dropdown-select";
import {
  GetUserCM,
  ListUsers,
  ListClientMattersIdAndName,
} from "../../shared/graphql/queries";

const companyId = localStorage.getItem("companyId");
const userId = localStorage.getItem("userId");

var moment = require("moment");

function ContactInformation({
  id,
  clientFromIndex,
  clientID,
  matter,
  matterID,
  setShowToast,
  setResultMessage,
  setToastError,
  hideToast,
}) {
  const [clientMatterContacts, setClientMatterContacts] = useState({});
  const [selfClientMatter, setSelfClientMatter] = useState();
  const [client, setClient] = useState([]);
  const [solicitor, setSolicitor] = useState([]);
  const [barrister, setBarrister] = useState([]);
  const [expert, setExpert] = useState([]);
  const [otherparty, setOtherParty] = useState([]);
  const [otherparty_solicitor, setOtherparty_solicitor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);

  const { height, width } = useWindowDimensions();

  const [userClientMatter, setUserClientMatter] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const prevDropdownValue = useRef();
  const currUserTypeSelected = useRef();
  const cancelOnChange = useRef(false);
  useEffect(() => {
    getOwnClientMatter();
    getCompanyUsers();
  }, []);

  let getCompanyUsers = async () => {
    var usersList = [];
    await ListUsers(companyId).then((users) => {
      if (users) {
        if (users.data.company == null) {
          setCompanyUsers([]);
        } else {
          users.data.company.users.items.map((user, idx) => {
            //let name = user.firstName + " " + user.lastName;
            let temp = {
              //value: name,
              email: user.email,
              id: user.id,
              userType: user.userType,
              name: user.firstName + " " + user.lastName,
            };
            usersList.push(temp);
          });
          setCompanyUsers(usersList);
        }
      }
    });
  };

  const getOwnClientMatter = async (e) => {
    try {
      const { data } = await ListClientMattersIdAndName(companyId);

      const clientMattersList = data?.company?.clientMatters?.items;

      //console.log("clientMattersList:", clientMattersList);
      const len = clientMattersList.length;
      let j = 0;
      for (let i = 0; i < len; i++) {
        //console.log(clientID == data?.company?.clientMatters?.items[i].client.id);
        if (
          clientMattersList[i].matter &&
          clientMattersList[i].client &&
          clientMattersList[i].client.id === clientID &&
          clientMattersList[i].matter.id === matterID
        ) {
          setSelfClientMatter(clientMattersList[i]);
          break;
        }
        // try {
        //   console.log("clientMatter\n", clientMattersList[i].matter.id);
        // }
        // catch {
        //   console.log("XXX\n", clientMattersList[i]);
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getContactClientMatter = async (contactEmail) => {
    console.log("contactEmail", contactEmail);
    console.log("contactEmailLen", contactEmail.length);
    var deletePrevContact = false;
    if (contactEmail.length == 0) {
      contactEmail = prevDropdownValue.current;
      console.log("contactEmailNEW", contactEmail);
      deletePrevContact = true;
    }
    // SET NEW EMAIL FROM BLANK
    if (prevDropdownValue.current.length == 0 || deletePrevContact) {
      let idxContact = companyUsers
        .map((user) => user.email)
        .indexOf(contactEmail);
      let contactId = companyUsers[idxContact].id;
      const { data } = await GetUserCM(contactId, companyId);

      const clientMatters = data?.user?.clientMatterAccess?.items ?? [];

      const userClientMatter = clientMatters.map(
        ({ userType, clientMatter }) => ({
          userType,
          clientMatter,
        })
      );
      console.log("IMPORTANT:\n", userClientMatter);

      //setUserClientMatter(userClientMatter);
      //console.log("GET CONTACT CLIENT MATTERS\n:", userClientMatter.length, userClientMatter);
      //console.log("USER TO UPDATE:",companyUsers[idxContact]);

      const newEntry = {
        userType: ["CLIENT", "BARRISTER", "EXPERT"].includes(
          currUserTypeSelected.current
        )
          ? currUserTypeSelected.current
          : companyUsers[idxContact].userType,
        clientMatter: selfClientMatter,
      };

      if (deletePrevContact) {
        userClientMatter.splice(userClientMatter.indexOf(newEntry), 1);
      } else {
        userClientMatter.push(newEntry);
      }

      TaggingMutation(contactId, userClientMatter);
    }
    // REPLACE NEW EMAIL
    else {
      let idxContactTD = companyUsers
        .map((user) => user.email)
        .indexOf(prevDropdownValue.current);
      let contactIdTD = companyUsers[idxContactTD].id;
      let { dataTD } = await GetUserCM(contactIdTD, companyId);

      const clientMattersTD = dataTD?.user?.clientMatterAccess?.items ?? [];

      const userClientMatterTD = clientMattersTD.map(
        ({ userType, clientMatter }) => ({
          userType,
          clientMatter,
        })
      );

      const toDelete = {
        userType: companyUsers[idxContactTD].userType,
        clientMatter: selfClientMatter,
      };

      userClientMatterTD.splice(userClientMatterTD.indexOf(toDelete), 1);

      //console.log("Client from Index:", userClientMatter);
      TaggingMutation(contactIdTD, userClientMatterTD);

      let idxContactTR = companyUsers
        .map((user) => user.email)
        .indexOf(contactEmail);
      let contactIdTR = companyUsers[idxContactTR].id;
      let { dataTR } = await GetUserCM(contactIdTR, companyId);

      const clientMattersTR = dataTR?.user?.clientMatterAccess?.items ?? [];

      const userClientMatterTR = clientMattersTR.map(
        ({ userType, clientMatter }) => ({
          userType,
          clientMatter,
        })
      );

      const toReplace = {
        userType: companyUsers[idxContactTR].userType,
        clientMatter: selfClientMatter,
      };

      userClientMatterTR.push(toReplace);

      //console.log("Client from Index:", userClientMatter);
      TaggingMutation(contactIdTR, userClientMatterTR);
    }
  };

  const TaggingMutation = async (contactId, updatedClientMatter) => {
    try {
      const clientMatter = updatedClientMatter.map(
        ({ userType, clientMatter }) => ({
          userType,
          clientMatterId: clientMatter.id,
        })
      );

      const apiVariable = {
        clientMatterAccess: clientMatter,
        userId: contactId,
        companyId: companyId,
      };

      const tagClient = await API.graphql({
        query: mTagClientMatter,
        variables: apiVariable,
      }).then(() => {
        // refetch userClientMatter
        //toast.success("Client Matter Access Saved");
        //close();
      });
    } catch (error) {
      console.error(error);
      //toast.error("Something went wrong. Please try again.");
      //close();
    }
  };

  const qGetContacts = `
  query companyUsers($companyId: String) {
    company(id: $companyId) {
      name
      users {
        items {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
  `;

  // HERE
  const qUserClientMatter = `
    query getUserClientMatter($companyId: String, $userId: String) {
      user (id: $userId) {
        clientMatterAccess (companyId: $companyId) {
          items {
            userType
            clientMatter {
              id
              client {
                id
                name
              }
              matter {
                id
                name
              }
            }
          }
        }
      }
    }
  `;

  const qlistClientMatterContacts = `
  query listClientMatterContacts($id: ID) {
    clientMatter(id: $id) {
      contacts {
        items {
          type
          details {
            id
            name
            email
            number
            createdAt
          }
        }
      }
    }
  }`;

  const mcreateClientMatterContacts = `
  mutation createClientMatterContacts($type: ContactType, $clientMatterId: String, $details: ContactDetailsInput) {
    clientMatterContactDetailsCreate(
      clientMatterId: $clientMatterId
      type: $type
      details: $details
    ) {
      id
    }
  }`;

  const mupdateClientMatterContacts = `
  mutation updateClientMatterContacts($id: ID, $details: ContactDetailsInput) {
    clientMatterContactDetailsUpdate(id: $id, details: $details) {
      id 
      name
      email
      number
    }
  }
  `;

  const mdeleteClientMatterContacts = `
  mutation deleteClientMatterContacts($id: ID) {
    clientMatterContactDetailsDelete(id: $id)
  }`;

  const mTagClientMatter = `
      mutation tagUserClientMatterAccess($clientMatterAccess: [UserClientMatterAccessInput], $userId: ID, $companyId: ID) {
        userClientMatterTag(userId: $userId, clientMatterAccess: $clientMatterAccess, companyId: $companyId) {
          id
          userType
          user {
            id
          }
        }
      }
    `;

  const setClientValues = (response) => {
    const clientArray = response.data.clientMatter.contacts.items.filter(
      (contacts) => {
        return contacts.type === "CLIENT";
      }
    );
    setClient(clientArray);

    const solicitorArray = response.data.clientMatter.contacts.items.filter(
      (contacts) => {
        return contacts.type === "SOLICITOR";
      }
    );
    setSolicitor(solicitorArray);

    const barristerArray = response.data.clientMatter.contacts.items.filter(
      (contacts) => {
        return contacts.type === "BARRISTER";
      }
    );
    setBarrister(barristerArray);

    const expertArray = response.data.clientMatter.contacts.items.filter(
      (contacts) => {
        return contacts.type === "EXPERT";
      }
    );
    setExpert(expertArray);

    const otherpartyArray = response.data.clientMatter.contacts.items.filter(
      (contacts) => {
        return contacts.type === "OTHERPARTY";
      }
    );
    setOtherParty(otherpartyArray);

    const otherparty_solicitorArray =
      response.data.clientMatter.contacts.items.filter((contacts) => {
        return contacts.type === "OTHERPARTY_SOLICITOR";
      });
    setOtherparty_solicitor(otherparty_solicitorArray);

    const emptyArray = [];

    if (clientArray.length === 0) {
      emptyArray.push("CLIENT");
    }
    if (solicitorArray.length === 0) {
      emptyArray.push("SOLICITOR");
    }
    if (barristerArray.length === 0) {
      emptyArray.push("BARRISTER");
    }
    if (expertArray.length === 0) {
      emptyArray.push("EXPERT");
    }
    if (otherpartyArray.length === 0) {
      emptyArray.push("OTHERPARTY");
    }
    if (otherparty_solicitorArray.length === 0) {
      emptyArray.push("OTHERPARTY_SOLICITOR");
    }
    console.log(emptyArray);
    if (emptyArray.length > 0) {
      Promise.all(emptyArray.map((value) => createInitialContact(value))).then(
        () => listClientMatterContacts()
      );
    }
  };

  async function createInitialContact(ContactType) {
    const details = {
      name: "",
      number: "",
      email: "",
    };
    try {
      const createClientMatter = await API.graphql({
        query: mcreateClientMatterContacts,
        variables: {
          type: ContactType,
          clientMatterId: id,
          details: details,
        },
      }).then((response) => {
        console.log("Create Contact: ", response);
        setResultMessage("Successfully added contact");
        setToastError(false);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 4000);
        setLoading(false);
      });
    } catch {
      setResultMessage("Failed to update contact");
      setToastError(true);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 4000);
    }
  }

  async function addContact(ContactType) {
    const details = {
      name: "",
      number: "",
      email: "",
    };
    try {
      const createClientMatter = await API.graphql({
        query: mcreateClientMatterContacts,
        variables: {
          type: ContactType,
          clientMatterId: id,
          details: details,
        },
      }).then((response) => {
        console.log("Create Contact: ", response);
        listClientMatterContacts();
        setResultMessage("Successfully added contact");
        setToastError(false);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 4000);
        setLoading(false);
      });
    } catch {
      listClientMatterContacts();
      setResultMessage("Failed to update contact");
      setToastError(true);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 4000);
    }
  }

  const listClientMatterContacts = async () => {
    const listClientMatter = await API.graphql({
      query: qlistClientMatterContacts,
      variables: {
        id: id,
      },
    }).then((response) => {
      setClientMatterContacts(response);
      console.log("get list response: ", response);
      const clientArray = response.data.clientMatter.contacts.items.filter(
        (contacts) => {
          return contacts.type === "CLIENT";
        }
      );
      setClientValues(response);
    });
    setSkeletonLoad(false);
  };

  useEffect(() => {
    listClientMatterContacts();
  }, []);

  // const createClientMatterContacts = async(ContactType, details, event) => {
  //   try {
  //     const createClientMatter = await API.graphql({
  //       query: mcreateClientMatterContacts,
  //       variables: {
  //         type: ContactType,
  //         clientMatterId: id,
  //         details: details,
  //       }
  //     }).then(response => {
  //       console.log("Create Contact: ", response);
  //       listClientMatterContacts();
  //       setResultMessage("Successfully added contact");
  //       setToastError(false);
  //       setShowToast(true);
  //       setTimeout(() => setShowToast(false), 4000);
  //       event.target.name.value="";
  //       event.target.number.value="";
  //       event.target.email.value="";
  //       setLoading(false)
  //     })
  //   } catch {
  //   listClientMatterContacts();
  //   setResultMessage("Failed to update contact")
  //   setToastError(true);
  //   setShowToast(true);
  //   setTimeout(() => setShowToast(false), 4000);
  //   }
  // }

  // const handleSubmit = (event, ContactType) => {
  //   setLoading(true);
  //   event.preventDefault();
  //   console.log("contacts: ", clientMatterContacts);
  //   console.log("ContactType: ", ContactType);
  //   const details = {
  //     "email": event.target.email.value,
  //     "number": event.target.number.value,
  //     "name": event.target.name.value,
  //   }
  //   createClientMatterContacts(ContactType, details, event)
  // }

  const deleteClientMatterContacts = async (contactId) => {
    try {
      const createClientMatter = await API.graphql({
        query: mdeleteClientMatterContacts,
        variables: {
          id: contactId,
        },
      }).then((response) => {
        console.log("Delete Contact: ", response);
        listClientMatterContacts();
        setResultMessage("Successfully deleted contact");
        setToastError(false);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 4000);
      });
    } catch {
      listClientMatterContacts();
      setResultMessage("Failed to archive contact");
      setToastError(true);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 4000);
    }
  };

  const handleDelete = (event, contactId) => {
    event.preventDefault();
    const deletePrompt = window.confirm(
      "Are you sure you want to archive this contact?"
    );
    if (deletePrompt) {
      deleteClientMatterContacts(contactId);
    }
  };

  const updateClientMatterContacts = async (
    isDelete,
    contactId,
    details,
    inputType
  ) => {
    console.group("updateClientMatterContacts");
    console.log("contactId", contactId);
    console.log("details", details);
    console.log("inputType", inputType);
    console.log("isDelete", isDelete);

    if (Object.keys(details)[0] === "email") {
      getContactClientMatter(details.email);
    }
    try {
      const updateClientMatter = await API.graphql({
        query: mupdateClientMatterContacts,
        variables: {
          id: contactId,
          details: details,
        },
      }).then(async (response) => {
        console.log("Change response: ", response);

        const params = {
          query: qGetContacts,
          variables: {
            companyId: companyId,
          },
        };

        await API.graphql(params)
          .then(async (companyUsers) => {
            var temp = companyUsers.data.company.users.items;
            temp.sort((a, b) => a.firstName.localeCompare(b.firstName));
            temp.map(
              (x) =>
                (x.firstName =
                  x.firstName.charAt(0).toUpperCase() +
                  x.firstName.slice(1).toLowerCase())
            );

            let filteredEmail = temp.filter(
              (item) => item.email === details.email
            );

            if (filteredEmail[0]?.id)
              await taggingClientMatterUserType(
                filteredEmail[0].id,
                inputType.toUpperCase()
              );
          })
          .catch((error) => console.error("ERROR", error));

        listClientMatterContacts();
        if (!isDelete) {
          setResultMessage("Successfully updated contact");
          setToastError(false);
          setShowToast(true);
          setTimeout(() => setShowToast(false), 4000);
        }
      });
    } catch (err) {
      listClientMatterContacts();
      setResultMessage("Failed to update contact");
      setToastError(true);
      setShowToast(true);
      console.groupEnd("updateClientMatterContacts");
      console.error(err);
      setTimeout(() => setShowToast(false), 4000);
    }
    console.groupEnd("updateClientMatterContacts");
  };

  const taggingClientMatterUserType = async (userId, userType) => {
    let temp = [];
    const userTypesAvailable = ["BARRISTER", "EXPERT", "CLIENT"];

    if (userTypesAvailable.includes(userType)) {
      const { data } = await API.graphql({
        query: qUserClientMatter,
        variables: {
          companyId: companyId,
          userId: userId,
        },
      });

      data.user.clientMatterAccess?.items.map((item) => {
        temp.push({
          userType: item.userType,
          clientMatterId: item.clientMatter.id,
        });
      });

      temp.push({
        userType: userType,
        clientMatterId: id,
      });

      //const removedDuplicates = [...new Map(temp.map((m) => [m.userType || m.clientMatterId, m])).values()];
      let removedDuplicates = temp.filter((e, i) => {
        return (
          temp.findIndex((x) => {
            return (
              x.userType === e.userType && x.clientMatterId === e.clientMatterId
            );
          }) === i
        );
      });
      console.log("removed duplicates - ", removedDuplicates);

      try {
        const apiVariable = {
          clientMatterAccess: removedDuplicates,
          userId: userId,
          companyId: companyId,
        };

        console.log("params:", apiVariable);

        const tagClient = await API.graphql({
          query: mTagClientMatter,
          variables: apiVariable,
        });

        console.log("result-tagging", tagClient);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChange = (event, contactId, inputType) => {
    event.preventDefault();
    const nameProps = event.target.name.toString();
    const details = event.target.name.includes("name")
      ? {
          name: event.target.value,
        }
      : event.target.name.includes("email")
      ? {
          email: event.target.value,
        }
      : event.target.name.includes("number")
      ? {
          number: event.target.value,
        }
      : null;
    console.log("Change contactId: ", contactId);
    console.log("Change details: ", details);
    updateClientMatterContacts(false, contactId, details, inputType);
  };

  const handleChangeDropdown = (event, inputName, contactId, inputType) => {
    let val;
    if (event.length == 0) {
      val = "";
    } else {
      val = event[0]?.value;
    }
    if (val != prevDropdownValue.current) {
      const details =
        inputName === "name"
          ? {
              name: val,
            }
          : inputName === "email"
          ? {
              email: val,
            }
          : inputName === "number"
          ? {
              number: val,
            }
          : null;
      console.log("Change contactId: ", contactId);
      console.log("Change details: ", details);
      updateClientMatterContacts(false, contactId, details, inputType);
    } else {
      console.log("SAME CONTACT");
    }
  };

  const contactForms = [
    {
      ClientTypeName: "Client",
      ClientType: "CLIENT",
    },
    {
      ClientTypeName: "Solicitor",
      ClientType: "SOLICITOR",
    },
    {
      ClientTypeName: "Barrister",
      ClientType: "BARRISTER",
    },
    {
      ClientTypeName: "Expert",
      ClientType: "EXPERT",
    },
    {
      ClientTypeName: "Other Party",
      ClientType: "OTHERPARTY",
    },
    {
      ClientTypeName: "Other Party Solicitor",
      ClientType: "OTHERPARTY_SOLICITOR",
    },
  ];

  return (
    <>
      <div className="text-xl font-semibold mt-6 px-5 lg:px-0">
        Contact Information
      </div>
      <div className="flex flex-col lg:gap-2 w-full">
        {contactForms.map((contact, contactIndex) => {
          const customerData =
            contact.ClientType === "CLIENT"
              ? client
              : contact.ClientType === "SOLICITOR"
              ? solicitor
              : contact.ClientType === "BARRISTER"
              ? barrister
              : contact.ClientType === "EXPERT"
              ? expert
              : contact.ClientType === "OTHERPARTY"
              ? otherparty
              : contact.ClientType === "OTHERPARTY_SOLICITOR"
              ? otherparty_solicitor
              : null;
          const contactLength = customerData.length;

          var mobileBackgrounds = (contactIndex, className) => {
            return classNames(className, {
              "bg-gray-100 lg:bg-white": contactIndex % 2 === 1,
            });
          };

          // For loop so that entries with no email are placed last
          var tempCustomerData = customerData[0]?.details;
          for (let i = 0; i < tempCustomerData?.length; i++) {
            if (tempCustomerData[i].email.length == 0) {
              tempCustomerData[i].createdAt = "8888-02-01T02:39:38.350Z";
            } else {
              // console.log(tempCustomerData[i]);
            }
          }
          return (
            <>
              {skeletonLoad ? (
                <>
                  {contactLength !== 0
                    ? tempCustomerData
                        .sort((a, b) =>
                          moment.utc(a.createdAt).diff(moment.utc(b.createdAt))
                        )
                        .map((customerData, index) => {
                          return (
                            <div
                              className={mobileBackgrounds(contactIndex, "")}
                            >
                              <form
                                className={
                                  "flex flex-col lg:flex-row gap-4 w-full py-6 lg:py-0 px-6 lg:px-0 " +
                                  (customerData.length === index
                                    ? "lg:mb-10"
                                    : "") +
                                  (index === 0 ? "mt-4" : "")
                                }
                                onSubmit={(e) =>
                                  handleDelete(e, customerData.id)
                                }
                              >
                                <div className="flex flex-col flex-1 gap-1">
                                  {/* Skeleton */}
                                  <SkeletonTheme>
                                    <Skeleton />
                                  </SkeletonTheme>
                                  <SkeletonTheme height={"38px"}>
                                    <Skeleton />
                                  </SkeletonTheme>
                                </div>
                                <div className="flex flex-col flex-1 gap-1">
                                  <SkeletonTheme>
                                    <Skeleton />
                                  </SkeletonTheme>
                                  <SkeletonTheme height={"38px"}>
                                    <Skeleton />
                                  </SkeletonTheme>
                                </div>
                                <div className="flex flex-col flex-1 gap-1">
                                  <SkeletonTheme>
                                    <Skeleton />
                                  </SkeletonTheme>
                                  <SkeletonTheme height={"38px"}>
                                    <Skeleton />
                                  </SkeletonTheme>
                                </div>
                                <div className="flex flex-col gap-1 w-full lg:w-24 pt-6">
                                  <SkeletonTheme height={"38px"}>
                                    <Skeleton />
                                  </SkeletonTheme>
                                </div>
                              </form>
                            </div>
                          );
                        })
                    : null}
                </>
              ) : (
                <>
                  {/* ------- Populated Entries ------- */}
                  {contactLength !== 0
                    ? tempCustomerData
                        .sort((a, b) =>
                          moment.utc(a.createdAt).diff(moment.utc(b.createdAt))
                        )
                        .map((customerData, index) => {
                          let defaultName =
                            companyUsers[
                              companyUsers
                                .map((user) => {
                                  return user.email;
                                })
                                .indexOf(customerData.email)
                            ]?.name;

                          if (
                            customerData.name.length != 0 &&
                            customerData.name != defaultName
                          ) {
                            defaultName = customerData.name;
                          }

                          return (
                            <div
                              className={mobileBackgrounds(contactIndex, "")}
                            >
                              <form
                                className={
                                  "flex flex-col lg:flex-row gap-4 w-full py-6 lg:py-0 px-6 lg:px-0 " +
                                  (customerData.length === index
                                    ? "lg:mb-10"
                                    : "") +
                                  (index === 0 ? "mt-4" : "")
                                }
                                onSubmit={(e) => {
                                  prevDropdownValue.current =
                                    document.getElementsByName(
                                      customerData.id +
                                        contact.ClientTypeName +
                                        "-email"
                                    )[0].value;
                                  updateClientMatterContacts(
                                    true,
                                    customerData.id,
                                    { email: "" }
                                  );
                                  handleDelete(e, customerData.id);
                                }}
                              >
                                <div className="flex flex-col flex-1 gap-1">
                                  {width < 1024 || index === 0 ? (
                                    <label
                                      htmlFor={`${
                                        customerData.id + contact.ClientTypeName
                                      }-name`}
                                      className="font-semibold text-gray-500"
                                    >
                                      {contact.ClientTypeName} Name
                                    </label>
                                  ) : null}
                                  <input
                                    type="text"
                                    id={`${
                                      customerData.id + contact.ClientTypeName
                                    }-name`}
                                    key={`${
                                      customerData.id + contact.ClientTypeName
                                    }-name`}
                                    name={`${
                                      customerData.id + contact.ClientTypeName
                                    }-name`}
                                    placeholder={`${contact.ClientTypeName} Name`}
                                    defaultValue={defaultName}
                                    // defaultValue={
                                    //   companyUsers[
                                    //     (companyUsers.map((user)=>{
                                    //       return user.email;
                                    //     })).indexOf(customerData.email)
                                    //   ].name
                                    // }
                                    onFocus={(e) => {
                                      cancelOnChange.current = true;
                                    }}
                                    onBlur={(e) => {
                                      handleChange(e, customerData.id);
                                      cancelOnChange.current = false;
                                    }}
                                    onChange={(e) => {
                                      if (!cancelOnChange.current) {
                                        handleChange(e, customerData.id);
                                      }
                                    }}
                                    className="px-3 py-2 border border-gray-200 outline-none rounded text-base font-normal placeholder-gray-300 focus:outline-none focus:ring-2 ring-blue-500"
                                  ></input>
                                </div>
                                <div className="flex flex-col flex-1 gap-1">
                                  {width < 1024 || index === 0 ? (
                                    <label
                                      htmlFor={`${customerData.id}-number`}
                                      className="font-semibold text-gray-500"
                                    >
                                      {contact.ClientTypeName} Number
                                    </label>
                                  ) : null}
                                  <input
                                    type="text"
                                    id={`${customerData.id}-number`}
                                    key={`${customerData.id}-number`}
                                    name="number"
                                    placeholder={`${contact.ClientTypeName} Number`}
                                    onBlur={(e) =>
                                      handleChange(e, customerData.id)
                                    }
                                    defaultValue={customerData.number}
                                    className="px-3 py-2 border border-gray-200 outline-none rounded text-base font-normal placeholder-gray-300 focus:outline-none focus:ring-2 ring-blue-500"
                                  ></input>
                                </div>
                                <div className="flex flex-col flex-1 gap-1">
                                  {width < 1024 || index === 0 ? (
                                    <label
                                      htmlFor={`${customerData.id}-email`}
                                      className="font-semibold text-gray-500"
                                    >
                                      {contact.ClientTypeName} Email Address
                                    </label>
                                  ) : null}
                                  <Select
                                    //type="email"
                                    options={companyUsers.map((user) => {
                                      return {
                                        label: user.email,
                                        value: user.email,
                                      };
                                    })}
                                    id={
                                      customerData.id +
                                      contact.ClientTypeName +
                                      "-email"
                                    }
                                    key={
                                      customerData.id +
                                      contact.ClientTypeName +
                                      "-email"
                                    }
                                    name={
                                      customerData.id +
                                      contact.ClientTypeName +
                                      "-email"
                                    }
                                    placeholder={`${contact.ClientTypeName} Email Address`}
                                    onChange={(e) => {
                                      console.log("new e:", e);
                                      if (e.length == 0) {
                                        document.getElementsByName(
                                          customerData.id +
                                            contact.ClientTypeName +
                                            "-email"
                                        )[0].value = "";
                                        document.getElementsByName(
                                          customerData.id +
                                            contact.ClientTypeName +
                                            "-name"
                                        )[0].value = "";
                                      } else {
                                        document.getElementsByName(
                                          customerData.id +
                                            contact.ClientTypeName +
                                            "-email"
                                        )[0].value = e[0].value;

                                        let newName =
                                          companyUsers[
                                            companyUsers
                                              .map((user) => {
                                                return user.email;
                                              })
                                              .indexOf(e[0].value)
                                          ]?.name;
                                        document.getElementsByName(
                                          customerData.id +
                                            contact.ClientTypeName +
                                            "-name"
                                        )[0].value = newName;
                                        updateClientMatterContacts(
                                          false,
                                          customerData.id,
                                          { name: newName }
                                        );
                                      }
                                      console.log(
                                        document.getElementsByName(
                                          customerData.id +
                                            contact.ClientTypeName +
                                            "-email"
                                        )
                                      );
                                      handleChangeDropdown(
                                        e,
                                        "email",
                                        customerData.id,
                                        contact.ClientTypeName
                                      );
                                    }}
                                    multi={false}
                                    values={
                                      customerData.email != ""
                                        ? [
                                            {
                                              label: customerData.email,
                                              value: customerData.email,
                                            },
                                          ]
                                        : []
                                    }
                                    onDropdownOpen={() => {
                                      prevDropdownValue.current =
                                        document.getElementsByName(
                                          customerData.id +
                                            contact.ClientTypeName +
                                            "-email"
                                        )[0].value;
                                      console.log(
                                        "prevDropdownValue:\n",
                                        prevDropdownValue.current
                                      );

                                      currUserTypeSelected.current =
                                        contact.ClientTypeName.toUpperCase();
                                    }}
                                    className="px-3 py-2 border border-gray-200 outline-none rounded text-base font-normal placeholder-gray-300 focus:outline-none focus:ring-2 ring-blue-500"
                                  />
                                </div>
                                <div className="flex flex-col gap-1 w-full lg:w-24">
                                  {index === 0 ? (
                                    <>
                                      {width > 1024 ? (
                                        <label className="font-semibold text-white select-none">
                                          .
                                        </label>
                                      ) : null}
                                      {!loading ? (
                                        <Button
                                          block={true}
                                          size="large"
                                          onClick={() =>
                                            addContact(contact.ClientType)
                                          }
                                          variant="default-s"
                                          disabled={loading}
                                        >
                                          <span className="text-base font-semibold">
                                            Add
                                          </span>
                                        </Button>
                                      ) : (
                                        <Button
                                          block={true}
                                          size="large"
                                          variant="secondary"
                                          disabled={loading}
                                        >
                                          <span className="text-base font-semibold">
                                            Add
                                          </span>
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        block={true}
                                        size="large"
                                        type="submit"
                                        variant="danger"
                                        disabled={loading}
                                      >
                                        <span className="text-base font-semibold">
                                          Delete
                                        </span>
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </form>
                            </div>
                          );
                        })
                    : null}
                </>
              )}
            </>
          );
        })}
      </div>
    </>
  );
}

export default ContactInformation;
