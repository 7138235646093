import { API } from "aws-amplify";

const qGetCompanyUsers = `
  query companyUsers($companyId: String) {
    company(id: $companyId) {
      name
      users {
        items {
          id
          firstName
          lastName
          email
          userType
          contactNumber
          profilePicture
          businessName
          hasPortalAccess
          company{
            id
            name
          }
          teams {
            items {
              id
              name
            }
          }
          cognito {
            isEnabled
            userStatus
            userAttributes
          }
        }
      }
    }
  }
  `;

const qGetArchivedUsers = `
    query archivedUsers($companyId: String) {
      company(id: $companyId) {
        name
        users(isDeleted: true) {
          items {
            id
            firstName
            lastName
            email
            userType
            contactNumber
            profilePicture
            businessName
            hasPortalAccess
            company{
              id
              name
            }
            teams {
              items {
                id
                name
              }
            }
          }
        }
      }
  }`;

const qGetUserById = `
  query user($id: String) {
    user(id: $id) {
      company {
        id
        name
      }
      id
      email
      firstName
      lastName
      userType
      profilePictureKey
      profilePicture
      businessName
      preferredTimezone
      preferredDateFormat
      cognito {
        isEnabled
        userStatus
        userAttributes
      }
    }
  }
`;

const qGetUserByIdCM = `
  query user($id: String, $companyId: String, $limit: Int, $nextToken: String) {
    user(id: $id) {
      company {
        id
        name
      }
      id
      email
      firstName
      lastName
      userType
      profilePictureKey
      profilePicture
      businessName
      preferredTimezone
      preferredDateFormat
      teams {
        items {
          id
          name
          members {
            items {
              userType
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
      clientMatterAccess(companyId: $companyId, limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          id
          userType
          clientMatter {
            id
            client {
              id
              name
            }
            contacts {
              items {
                details {
                  email
                  id
                }
                type
              }
            }
            matter {
              id
              name
            }
          }
        }
      }
    }
  }
`;

// Teams Queries
const qListTeams = `
  query getTeamsByCompany($id: String) {
    company(id: $id) {
      teams {
        items {
          id
          name
          members {
            items {
              userType
              user {
                id
                firstName
                lastName
                userType
                profilePicture
              }
            }
          }
        }
      }
    }
  }
`;

const qGetTeamCM = `
  query getTeamMembers($id: ID, $limit: Int, $nextToken: String) {
    team(id: $id) {
      id
      name
      members {
        items {
          userType
          user {
            id
            firstName
            lastName
            userType
            profilePicture
          }
        }
      }
      clientMatters(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          id
          client {
            name
          }
          matter {
            name
          }
        }
      }
    }
  }`;

const qListClientMatters = `
  query companyClientMatterContacts($companyId: String, $limit: Int, $nextToken: String) {
    company(id: $companyId) {
      clientMatters(limit: $limit, nextToken: $nextToken, sortOrder: CREATED_DESC, isDeleted:false) {
        nextToken
        items {
          id
          client {
            name
          }
          matter {
            name
          }
          contacts {
            items {
              type
              details {
                id
                email
                name
                number
              }
            }
          }
        }
      }
    }
  }`;

const qListClientMattersWithLabels = `
  query companyClientMatterContacts($companyId: String, $limit: Int, $nextToken: String) {
    company(id: $companyId) {
      clientMatters(limit: $limit, nextToken: $nextToken, sortOrder: CREATED_DESC, isDeleted:false) {
        nextToken
        items {
          id
          lastOpenedAt
          createdAt
          client {
            name
          }
          matter {
            name
          }
          contacts {
            items {
              type
              details {
                id
                email
                name
                number
              }
            }
          }
          labels {
            items {
              id
              name
            }
          }
        }
      }
    }
  }`;

const qListClientMattersIdAndName = `
  query companyClientMatterIdAndName($companyId: String, $limit: Int, $nextToken: String) {
    company(id: $companyId) {
      clientMatters(limit: $limit, nextToken: $nextToken, sortOrder: CREATED_DESC, isDeleted:false) {
        nextToken
        items {
          id
          client {
            id
            name
          }
          matter {
            id
            name
          }
        }
      }
    }
  }`;

const qListArchivedClientMatters = `
  query companyClientMatterContacts($companyId: String, $limit: Int, $nextToken: String) {
    company(id: $companyId) {
      clientMatters(limit: $limit, nextToken: $nextToken, sortOrder: CREATED_DESC, isDeleted:true) {
        nextToken
        items {
          id
          client {
            name
          }
          matter {
            name
          }
          contacts {
            items {
              type
              details {
                id
                email
                name
                number
              }
            }
          }
        }
      }
    }
  }`;

const qListClient = `
  query listClient($companyId: String, $limit: Int, $nextToken: String) {
    company(id: $companyId) {
      clients(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          id
          name
        }
      }
    }
  }
`;

const qListMatter = `
  query listMatter($companyId: String, $limit: Int, $nextToken: String) {
    company(id: $companyId) {
      matters(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
          id
          name
        }
      }
    }
  }
`;

// ------ USER SECTION ------
export async function ListUsers(companyId) {
  try {
    const users = await API.graphql({
      query: qGetCompanyUsers,
      variables: {
        companyId: companyId,
      },
    });
    return users;
  } catch (error) {
    console.error(error.message);
  }
}

export async function ListArchivedUsers(companyId) {
  try {
    const users = await API.graphql({
      query: qGetArchivedUsers,
      variables: {
        companyId: companyId,
      },
    });
    return users;
  } catch (error) {
    console.error(error.message);
  }
}

export async function GetUser(userId) {
  try {
    const user = await API.graphql({
      query: qGetUserById,
      variables: {
        id: userId,
      },
    });
    return user;
  } catch (error) {
    console.error(error.message);
  }
}

export async function GetUserCM(userId, companyId) {
  let initialData = null;
  let items = [];
  const QueryUser = async (nextToken = null) => {
    try {
      const user = await API.graphql({
        query: qGetUserByIdCM,
        variables: {
          id: userId,
          companyId: companyId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(user);
      const { nextToken: newNextToken, items: newData } =
        user.data.user.clientMatterAccess;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = user;
      }
      if (newNextToken) {
        await QueryUser(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryUser().then(() => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        user: {
          ...initialData.data.user,
          clientMatterAccess: {
            items: items,
          },
        },
      },
    };
  });
  return initialData;
}

// ------ TEAMS SECTION ------

export async function ListTeams(companyId) {
  try {
    const teams = await API.graphql({
      query: qListTeams,
      variables: {
        id: companyId,
      },
    });
    return teams;
  } catch (error) {
    console.error(error.message);
  }
}

export async function ListTeamsCM(companyId) {
  let initialData = null;
  let items = [];
  try {
    await API.graphql({
      query: qListTeams,
      variables: {
        id: companyId,
      },
    }).then(async (results) => {
      initialData = results;
      const teamList = results?.data.company.teams.items;
      await Promise.all(
        teamList.map(async (team) => {
          const teamDetails = await GetTeam(team.id, companyId);
          items = [...items, teamDetails.data.team];
        })
      ).then(() => {
        initialData = {
          ...initialData,
          data: {
            ...initialData.data,
            company: {
              ...initialData.data.company,
              teams: {
                items,
              },
            },
          },
        };
      });
    });
    return initialData;
  } catch (error) {
    console.error(error.message);
  }
}

export async function GetTeam(teamId, companyId) {
  let initialData = null;
  let items = [];
  const QueryTeam = async (nextToken = null) => {
    try {
      const team = await API.graphql({
        query: qGetTeamCM,
        variables: {
          id: teamId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(team);
      const { nextToken: newNextToken, items: newData } =
        team.data.team.clientMatters;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = team;
      }
      if (newNextToken) {
        await QueryTeam(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryTeam().then(async () => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        team: {
          ...initialData.data.team,
          clientMatters: {
            items: items,
          },
        },
      },
    };
  });

  return initialData;
}

export async function GetTeamCM(teamId, companyId) {
  let initialData = null;
  let items = [];
  let members = [];
  const QueryTeam = async (nextToken = null) => {
    try {
      const team = await API.graphql({
        query: qGetTeamCM,
        variables: {
          id: teamId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(team);
      const { nextToken: newNextToken, items: newData } =
        team.data.team.clientMatters;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = team;
      }
      if (newNextToken) {
        await QueryTeam(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryTeam().then(async () => {
    // Get all member info with Client/Matter
    await Promise.all(
      initialData.data.team.members.items.map(async (users) => {
        const userWithCM = await GetUserCM(users.user.id, companyId);
        const userClientMatter = userWithCM.data.user.clientMatterAccess;
        members = [
          ...members,
          {
            ...users,
            user: { ...users.user, clientMatterAccess: userClientMatter },
          },
        ];
        console.log("members", members);
      })
    ).then(() => {
      initialData = {
        ...initialData,
        data: {
          ...initialData.data,
          team: {
            ...initialData.data.team,
            members: {
              items: members,
            },
            clientMatters: {
              items: items,
            },
          },
        },
      };
    });
  });
  return initialData;
}

// ------ CLIENT/MATTER SECTION ------
export async function ListClientMatters(companyId) {
  let initialData = null;
  let items = [];
  const QueryClientMatters = async (nextToken = null) => {
    try {
      const clientMatter = await API.graphql({
        query: qListClientMatters,
        variables: {
          companyId: companyId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(user);
      const { nextToken: newNextToken, items: newData } =
        clientMatter.data.company.clientMatters;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = clientMatter;
      }
      if (newNextToken) {
        await QueryClientMatters(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryClientMatters().then(() => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        company: {
          ...initialData.data.company,
          clientMatters: {
            items: items,
          },
        },
      },
    };
  });
  return initialData;
}

export async function ListClientMattersWithLabels(companyId) {
  let initialData = null;
  let items = [];
  const QueryClientMatters = async (nextToken = null) => {
    try {
      const clientMatter = await API.graphql({
        query: qListClientMattersWithLabels,
        variables: {
          companyId: companyId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(user);
      const { nextToken: newNextToken, items: newData } =
        clientMatter.data.company.clientMatters;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = clientMatter;
      }
      if (newNextToken) {
        await QueryClientMatters(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryClientMatters().then(() => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        company: {
          ...initialData.data.company,
          clientMatters: {
            items: items,
          },
        },
      },
    };
  });
  return initialData;
}

export async function ListClientMattersIdAndName(companyId) {
  let initialData = null;
  let items = [];
  const QueryClientMatters = async (nextToken = null) => {
    try {
      const clientMatter = await API.graphql({
        query: qListClientMattersIdAndName,
        variables: {
          companyId: companyId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(user);
      const { nextToken: newNextToken, items: newData } =
        clientMatter.data.company.clientMatters;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = clientMatter;
      }
      if (newNextToken) {
        await QueryClientMatters(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryClientMatters().then(() => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        company: {
          ...initialData.data.company,
          clientMatters: {
            items: items,
          },
        },
      },
    };
  });
  return initialData;
}

export async function ListArchivedClientMatters(companyId) {
  let initialData = null;
  let items = [];
  const QueryClientMatters = async (nextToken = null) => {
    try {
      const clientMatter = await API.graphql({
        query: qListArchivedClientMatters,
        variables: {
          companyId: companyId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(user);
      const { nextToken: newNextToken, items: newData } =
        clientMatter.data.company.clientMatters;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = clientMatter;
      }
      if (newNextToken) {
        await QueryClientMatters(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryClientMatters().then(() => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        company: {
          ...initialData.data.company,
          clientMatters: {
            items: items,
          },
        },
      },
    };
  });
  return initialData;
}

export async function ListClients(companyId) {
  let initialData = null;
  let items = [];
  const QueryClient = async (nextToken = null) => {
    try {
      const clients = await API.graphql({
        query: qListClient,
        variables: {
          companyId: companyId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(user);
      const { nextToken: newNextToken, items: newData } =
        clients.data.company.clients;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = clients;
      }
      if (newNextToken) {
        await QueryClient(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryClient().then(() => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        company: {
          ...initialData.data.company,
          clients: {
            items: items,
          },
        },
      },
    };
  });
  return initialData;
}

export async function ListMatters(companyId) {
  let initialData = null;
  let items = [];
  const QueryMatter = async (nextToken = null) => {
    try {
      const matters = await API.graphql({
        query: qListMatter,
        variables: {
          companyId: companyId,
          limit: "100",
          nextToken: nextToken,
        },
      });
      // console.log(user);
      const { nextToken: newNextToken, items: newData } =
        matters.data.company.matters;
      items = [...items, ...newData];
      if (initialData === null) {
        initialData = matters;
      }
      if (newNextToken) {
        await QueryMatter(newNextToken);
      }
    } catch (error) {
      console.error(error);
    }
  };
  await QueryMatter().then(() => {
    initialData = {
      ...initialData,
      data: {
        ...initialData.data,
        company: {
          ...initialData.data.company,
          matters: {
            items: items,
          },
        },
      },
    };
  });
  return initialData;
}
