import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import ToastNotification from "../toast-notification";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiCloudUpload, BiZoomIn, BiZoomOut } from "react-icons/bi";
import RemoveFileModal from "../file-bucket/remove-file-modal";
import { FaPaste, FaSync, FaSort } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import CreatableSelect from "react-select/creatable";
import { components, createFilter } from "react-select";
import ReactDOM from "react-dom";
// import getScrollYFromElementOrWindow from 'simply-utils/dom/getScrollYFromElementOrWindow';
// import tryUntil from 'simply-utils/utils/tryUntil';
import { HiPlus, HiMinus } from "react-icons/hi";
import { handleTextractSubscription, chunkLargePdfsForTextract } from "../../shared/utils/textExtractionUtils";
import { updateBackgroundOrders } from "../../shared/graphql/Backgrounds/mutations";
import {
  timezoneFormatter,
  datePickerDateFormatter,
} from "../../shared/userPreferredDateTime";
import momentTZ from "moment-timezone";
import html2pdf from "html2pdf.js";
import {
  WindowScroller,
  AutoSizer,
  defaultTableHeaderRenderer,
  defaultTableRowRenderer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  List,
  Table,
} from "react-virtualized";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import classNames from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./labelCell.css";
import {
  BsSortUpAlt,
  BsSortDown,
  BsInfoCircle
} from "react-icons/bs";
import EmptyRow from "./empty-row";
import { ModalParagraph } from "./modal";
import DownloadUnviewablFileModal from "./download-unviewable-file-modal";
import { API, Storage } from "aws-amplify";
import config from "../../aws-exports";
//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import { MdDragIndicator, MdClose } from "react-icons/md";

import RemoveModal from "../delete-prompt-modal";
import { Prompt } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
// import imgLoading from "../../assets/images/loading-circle.gif";
import "../../assets/styles/background.css";
import ScrollToTop from "react-scroll-to-top";
import UploadLinkModal from "../file-bucket/file-upload-modal";
import NoResultState from "../no-result-state";
import ReactTooltip from "react-tooltip";
import RFIEditor from "./rfi-editor";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
// import { ThemeProvider } from "@remirror/react";
import isMSdoc from "../../shared/msFileTypeChecker";
import googleLogin from "../../assets/images/gmail-print.png";

//import Button from "../shared-library/Button";
// import EditableBackgroundField from "./editableBackgroundField";
import Button from "../../shared/Button";
import EmailParser from "../../shared/EmailParser";
import { updateBackgroundFilesOrder } from "../../shared/graphql/Backgrounds/mutations";
import CustomColumn from "./custom-column";
import cloneDeep from "lodash/cloneDeep";
import { getBgList } from "../../shared/graphql/Briefs/queries";
import TableInfoBreakdown from "./table-info-breakdown.jsx";

import {
  BsFillFileEarmarkFill,
  BsFillFileEarmarkFontFill,
  BsFileEarmarkPdfFill,
  BsFillFileEarmarkExcelFill,
  BsFillFileEarmarkWordFill,
  BsFillFileEarmarkPptFill,
  BsFillFileEarmarkImageFill,
  BsFillFileEarmarkPlayFill,
  BsFillFileEarmarkMusicFill,
  BsThreeDots,
} from "react-icons/bs";
import { CircularProgressbar } from "react-circular-progressbar";
import SlateMention from "./slate-description";

import { FaFileCsv } from "react-icons/fa";

//right panel
import RightPanel from "./right-panel.jsx";
import { drop } from "lodash";
import AddPaymentModal from "./add-payment-modal.jsx";

import FilePreviewModal from "./file-preview-modal.jsx";

export let selectedRowsBGPass = [],
  selectedRowsBGFilesPass = [];

const TableInfo = ({
  backgroundAsc,
  backgroundDuplicate,
  backgroundDesc,
  sortByOrder2,
  background,
  files,
  wait,
  setIdList,
  setBackground,
  previousBackground,
  setPreviousBackground,
  checkAllState,
  setcheckAllState,
  checkedState,
  setCheckedState,
  settotalChecked,
  search,
  getId,
  setId,
  getBackground,
  matterId,
  selectedRowsBG,
  setSelectedRowsBG,
  ShowModalParagraph,
  setShowModalParagraph,
  paragraph,
  setParagraph,
  setAscDesc,
  ascDesc,
  setShowDeleteButton,
  activateButton,
  setSelectedRowsBGFiles,
  selectedRowsBGFiles,
  setSelectedId,
  selectedId,
  selectRow,
  setSelectRow,
  checkDate,
  checkDesc,
  checkDocu,
  pasteButton,
  setSrcIndex,
  client_name,
  matter_name,
  pageIndex,
  pageSize,
  pageSizeConst,
  loadMoreBackground,
  newRow,
  setPasteButton,
  setNewRow,
  loading,
  setLoading,
  maxLoading,
  sortByOrder,
  SortBydate,
  briefId,
  briefName,
  searchDescription,
  selectedItems,
  setSelectedItems,
  holdDelete,
  setHoldDelete,
  setTargetRow,
  targetRow,
  highlightRow,
  setHighlightRow,
  pastedRows,
  setPastedRows,
  checkedRows,
  setCheckedRows,
  setbindList,
  setCache,
  setMoveButton,
  moveButton,
  fontSize,
  bgLoading,
  setBgLoading,
  allowEditDate,
  allowEditDescription,
  allowEditComments,
  allowEditLabels,
  allowEditFiles,
  allowShowLabels,
  headerHeight,
  commentAccess,
  gmailFilter,
  allowDeleteFiles,
  allowDragNDrop,
  uploadedDocumentList,
  columns,
  setActiveColumns,
  columnIds,
  qSearchValue,
  pressedQSearch,
  totalHits,
  setTotalHits,
  qSearchPtr,
  setQSearchPtr,
  scrollElementRef,
  inRow,
  reducedTabsArray,
  setReducedTabsArray,
  reduceTabFilesToArray,
  reducedUploadedFilesArray,
  setReducedUploadedFilesArray,
  reduceUploadedFilesToArray,
  currLabelCellSelected,
  currLabelCellSelected2,
  handleChangeLabel,
  justChangedLabel,
  setJustChangedLabel,
  blockScroll,
  allowScroll,
  callhandleColumnCheckChangedNoEvent,
  showRightPanel,
  setShowRightPanel,
  fileView,
  setFileView,
  setWait,
  isSiteDiary,
  tableHeaderRef,
  // TANSTACK VIRTUALIZED
  vTablePaddingTop,
  vTablePaddingBottom,
  virtualRows,
  rows,
  tableContainerRef,
  totalSize,
  rowVirtualizer,
  setTotalBudgetAmount,
  setTotalCostAmount,
  totalBudgetAmount,
  totalCostAmount,
  openAiPrompts,
  setOpenAiPrompts,
  allowSummarize,
  coreMemory,
  setCoreMemory,
  willBulkSummarise,
  setWillBulkSummarise,
  selectedFilesBulkSummary,
  setSelectedFilesBulkSummary,
  interfaceRightPanel,
  setInterfaceRightPanel,
  rightPanelItemsBulkSummary,
  setRightPanelItemsBulkSummary,
  setUnreadNotifications,
  tableHeight,
  setFilePreviewItems,
  setShowFilePreview,
  handleSnippetEmail
}) => {
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const creatableSelectRef = useRef();
  const numOfRows = useRef(0);
  const prevRow = useRef(0);
  if (background.length > 0) {
    numOfRows.current = background.length;
  }

  //SANDBOX GMAIL ROWS
  const gmailSentIndex = [1, 4, 7, 9, 12, 15, 19, 21, 23, 25, 29, 33, 36, 38];
  const gmailNaIndex = [6, 11, 14, 18, 24, 28, 32, 37, 40, 41];
  const gmailUploadedIndex = [16, 26, 31];

  const adjustments = useRef(1);
  const handleRef = useCallback((ref) => {
    if (ref !== null) {
      ref.scrollToPosition =
        (scrollTop, adjustmentsP) =>
        // removed simply-utils lib
        () => {
          const scrollEl = window;
          if (scrollEl) {
            scrollEl.scrollTo({
              top: scrollTop + adjustmentsP,
              behavior: "smooth",
            });
            const updatedScrollTop = function (scrollEl) {
              return (
                (scrollEl === null || scrollEl === void 0
                  ? void 0
                  : scrollEl.scrollTop) ||
                scrollEl.scrollY ||
                0
              );
            };
            console.log(
              "scrollTop:",
              scrollTop,
              "\nupdatedScrollTop:",
              updatedScrollTop,
              "\nadjustment:",
              adjustmentsP
            );
            return updatedScrollTop === scrollTop;
          }
          // Try next
          return false;
        };
      ref.scrollToRow = (index) => {
        console.log(index, numOfRows.current);
        if (index / numOfRows.current < 0.2) {
          adjustments.current = Math.random() * 5;
        } else if (index / numOfRows.current < 0.5) {
          adjustments.current = Math.random() * 5 + 100;
        } else {
          adjustments.current = 150 + Math.random() * 5;
        }
        const scrollTop = ref.getOffsetForRow({ index, alignment: "center" });
        ref.scrollToPosition(scrollTop, adjustments.current);
      };
    }
  }, []);

  let temp = selectedRowsBG;
  let tempFiles = selectedRowsBGFiles;
  const { height, width } = useWindowDimensions();
  const [saving, setSaving] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [selected, setSelected] = useState("");
  const [descId, setDescId] = useState("");
  const [textDesc, setTextDesc] = useState("");
  const [descAlert, setDescAlert] = useState("");
  const [showRemoveFileModal, setshowRemoveFileModal] = useState(false);
  const [selectedFileBG, setselectedFileBG] = useState([]);
  const [highlightRows, setHighlightRows] = useState("bg-green-200");
  const [isShiftDown, setIsShiftDown] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const [holdReducedTabsArrayCopy, setHoldReducedTabsArrayCopy] =
    useState(null);
  const [selectedRowId, setSelectedRowID] = useState(null);
  const [goToFileBucket, setGoToFileBucket] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [holdPaste, setHoldPaste] = useState(false);
  const [bgInput, setBgInput] = useState([]);
  const [holdDeleteFile, setHoldDeleteFile] = useState(false);
  const bool = useRef(true);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [SelectedIndex, setSelectedIndex] = useState("");
  const [snippetId, setSnippetId] = useState(null);
  const [show, setShow] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [preview, setPreview] = useState([]);
  const [from, setFrom] = useState(null);
  const [dateEmail, setDateEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCC] = useState(null);
  const [iframeLoad, setIframeLoad] = useState(true);
  const [fileType, setFileType] = useState("");
  const [hoveredRow, setHoveredRow] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isActiveCell, setIsActiveCell] = useState(null);
  const [emailDateAsc, setEmailDateAsc] = useState(null);
  const [breakdownData, setBreakdownData] = useState([]);
  const [prevBreakdownData, setPrevBreakdownData] = useState([]);

  //labels
  const [labels, setLabels] = useState(null);
  const [briefList, setBriefList] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [taggedColumns, setTaggedColumns] = useState();
  const [inputDynamic, setInputDynamic] = useState();

  //right panel items
  const [rightPanelItems, setRightPanelItems] = useState([]);

  const [isOpenAddPayment, setIsOpenAddPayment] = useState(false);
  const [briefNameBreakdown, setBriefNameBreakdown] = useState(null);
  const [briefIdBreakdown, setBriefIdBreakdown] = useState(null);

  // const defaultColumnIds = columnIds;
  const defaultColumnIds = [0, 1, 2, 3, 4];

  const sortIconClass = "mx-auto inline-block cursor-pointer";
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 30,
      minHeight: 30,
    })
  );

  var moment = require("moment");

  const selectStyles = (base, state) => {
    console.log("select styles", base);
    return base;
  };

  const queryParams = new URLSearchParams(location.search);

  const hideToast = () => {
    setShowToast(false);
  };

  const handleCell = (column, id) => {
    setIsActiveCell(column);
    setSelectedCell(id);
  };

  // Please check with SFE first before uncommenting
  //   useEffect(() => {
  //     console.log("backgroundlist", background);
  //     if(ascDesc == null)
  // 	setBackground((backgroundDuplicate));
  //   }, [backgroundDuplicate, setBackground]);

  const showModal = (id, backgroundId, fileName) => {
    var tempIndex = [];
    tempIndex = [
      ...tempIndex,
      { id: id, backgroundId: backgroundId, fileName: fileName },
    ];
    setselectedFileBG(tempIndex);
    setshowRemoveFileModal(true);
    setSelectedFileId(id);
  };

  const handleModalClose = () => {
    setshowRemoveFileModal(false);
    setShowUploadModal(false);
    setShowRemoveRowModal(false);
  };

  const handleCheckboxChange = (position, event, id, date, details) => {
    const checkedId = selectRow.some((x) => x.id === id);
    if (!checkedId && event.target.checked) {
      const x = selectRow;
      x.push({ id: id, date: date, details: details });
      setSelectRow(x);
      setSrcIndex(position);
      setShowDeleteButton(true);
    }
    if (checkedId) {
      var x = selectRow.filter(function (sel) {
        return sel.id !== id;
      });
      setSelectRow(x);
      setSrcIndex("");
    }

    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    let tc = updatedCheckedState.filter((v) => v === true).length;
    settotalChecked(tc);

    if (tc !== background.length) {
      if (checkAllState) {
        setcheckAllState(false);
      }
    } else {
      if (!checkAllState) {
        setcheckAllState(true);
      }
    }
    if (event.target.checked) {
      if (!background.includes({ id: event.target.name })) {
        setId((item) => [...item, event.target.name]);
        if (temp.indexOf(temp.find((tempp) => tempp.id === id)) > -1) {
        } else {
          //edited part
          temp = [...temp, { id: id, fileName: position.toString() }];
          selectedRowsBGPass = temp;
          setSelectedRowsBG(temp);

          if (temp.length > 0) {
            setShowDeleteButton(true);
          } else {
            setShowDeleteButton(false);
          }
        }
      }
    } else {
      setId((item) => [...item.filter((x) => x !== event.target.name)]);
      if (temp.indexOf(temp.find((tempp) => tempp.id === id)) > -1) {
        temp.splice(temp.indexOf(temp.find((tempp) => tempp.id === id)), 1);
        setSelectedRowsBG(temp);
        selectedRowsBGPass = temp;
      }

      if (temp.length > 0) {
        setShowDeleteButton(true);
      } else {
        setShowDeleteButton(false);
      }
    }
  };

  useEffect(() => {
    getLabels();

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    setIdList(getId);
  }, [getId, isSiteDiary]);

  function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }

  /* MMA 880 and 680 fix remove if causes bug*/

  /** useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        let el = document.activeElement;

        if (el) {
          if (!isScrolledIntoView(el)) {
            el.blur();
          }
        }
      },
      { passive: true }
    );
  }, []);
  */

  /*End*/

  const bindList = useRef();

  useEffect(() => {
    console.log("CALLED AUTO ADJUST INITIAL");
    autoAdjustAllRow();
  }, []);

  useEffect(() => {
    if (bindList && cache) {
      setbindList(bindList);
      setCache(cache);
    }
  }, [bindList]);

  const autoAdjustRowHeight = (index) => {
    //bindList and cache must not be null
    console.log("INDEXS", index);

    if (bindList && cache) {
      //clear first
      cache?.current?.clear(index);
      bindList?.current?.recomputeRowHeights(index);
      //bindList?.current?.forceUpdateGrid(index); //remove becuase recomputeRowHeights already calls forceUpdate by default
    } else {
      console("List reference not found || cache not found!");
    }
  };
  useEffect(() => {
    autoAdjustAllRow();
  }, [fontSize]);

  const handleDescContent = (e, description, id, index) => {
    if (!descAlert) {
      const cleanDescription = handleDescription(description, index);
      setTextDesc(cleanDescription);
      setDescId(id);
      setDescAlert("");
    } else {
      setDescAlert("");
    }
    //autoAdjustRowHeight(index);
  };

  const handleChangeDesc = (event, index) => {
    setTextDesc(event.target.innerText, index);
  };

  const handleFontSize = (currentClass, fontSize) => {
    return classNames(currentClass, {
      "text-sm": fontSize === 0,
      "text-base": fontSize === 1,
      "text-lg": fontSize === 2,
    });
  };

  const handleDescription = (description, index) => {
    if (description) {
      var parsedDescription = description?.replace(
        /style="[^\"]*"/gi,
        `className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[div]+/g,
        `<div className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[span]+/g,
        `<span className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[p ]+/g,
        `<p className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[font ]+/g,
        `<p className="${handleFontSize("", fontSize)}"`
      );
      if (
        pressedQSearch &&
        totalHits > 0 &&
        typeof qSearchValue !== "undefined" &&
        qSearchValue.length < 100
      ) {
        parsedDescription = parsedDescription?.replace(
          new RegExp(qSearchValue, "g"),
          `<span class="bg-yellow-500">${qSearchValue}</span>`
        );
        //console.log("=>",parsedDescription);
        // console.log(inRow);
        if (index === inRow.current[0]) {
          console.log("STR", inRow.current);
          let idxSubstring = [
            ...parsedDescription.matchAll(new RegExp("bg-yellow-", "g")),
          ].map((a) => a.index);
          let idxSingle = idxSubstring[inRow.current[1]];

          let str =
            parsedDescription.substring(0, idxSingle) +
            "bg-green-" +
            parsedDescription.substring(idxSingle + 10);

          parsedDescription = `${str}`;
        }
      }

      return parsedDescription;
    }
  };

  const handleComment = (description) => {
    if (description) {
      var parsedDescription = description?.replace(
        /style="[^\"]*"/gi,
        `className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[div]+/g,
        `<div className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[span]+/g,
        `<span className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[p ]+/g,
        `<p className="${handleFontSize("", fontSize)}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[font ]+/g,
        `<p className="${handleFontSize("", fontSize)}"`
      );
      return parsedDescription;
    }
  };

  async function saveDescription({ id, description, origDescription }) {
    const success = await updateBackgroundDesc(id, { description }).then(
      async () => {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: "updated the background description",
            field: "Description",
            current: description,
            previous: origDescription,
            appModule: "BACKGROUND",
            rowId: id,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity params", params);
          console.log("addActivity result", result);
        });
      }
    );
  }

  useEffect(() => {
    if (saving === true) {
      window.onbeforeunload = () => "";
    }
    if (saving === false) {
      window.onbeforeunload = null;
    }
  }, [saving]);

  function extractText(desc) {
    const regex = /(<([^>]+)>)/gi;
    const newString = desc?.replace(regex, "");

    return newString;
  }

  const handleSaveDesc = async (e, description, date, id, index) => {
    console.log("check desc", extractText(e.target.innerText));

    let mentionMenuOpen = document.querySelector(".mention-menu");

    if (mentionMenuOpen) {
      return null;
    }

    if (e.target !== document.activeElement) {
      const origDescription = handleDescription(description, index);
      // const newDescription = handleDescription(e.target.innerHTML, index);
      //added new newDescription to cleanup text and only pass on "@Tab" without any numbers following it. Also deletes white spaces after @Tab instances
      const receivedDescription = handleDescription(e.target.innerText, index);
      const modifiedDescription = receivedDescription?.replace(
        /@Tab\s*\([^)]*\)\s*/gi,
        "@Tab "
      );
      // cleanup double spacing when user used only one space
      const modifiedDescriptionWithoutDoubleNewlines = modifiedDescription
        .replace(/\n{2,}/g, "\n")
        .replace(/~start~[\s\S]*?~end~/g, ""); //added new condition to prevent the mention element menu from being included in the description
      const newDescription = extractText(
        modifiedDescriptionWithoutDoubleNewlines
      );

      console.log("New Description", newDescription);

      // let newDescription = extractText(e.target.innerText);
      // newDescription = newDescription?.replace(/\&nbsp;/g, '').replace(/\n/g, '');

      //added new newDescription to cleanup text and only pass on "@Tab" without any numbers following it. Also deletes white spaces after @Tab instances

      console.log("Saved the text " + newDescription);
      console.log("textDesc", textDesc);

      //Causes MMA-680 bug Background: Description cursor relocates to the first word when switching tabs
      //Investigated by Jed
      /*
      const updateArr = background.map((obj) => {
        if (obj.id === id) {
          return { ...obj, description: e.target.innerHTML };
        }
        return obj;
      });
      setBackground(updateArr);
      */

      //background.recomputeRowHeights(index);

      {
        /*Added fix for both MMA 680 and 680 fix remove if causes bug*/
      }
      // if (!isScrolledIntoView(e.target)) {
      //   let foundIndex = background.findIndex((x) => x.id == id);
      //   background[foundIndex].description = e.target.innerHTML;
      //   Nag ccause po ito ng bug, nilipat ko sa last part nung code. Edited by: KJMF
      // }
      {
        /*End*/
      }

      if (textDesc?.length <= 0) {
        console.log("1st condition");
        // setDescAlert("Description can't be empty.");
        setDescAlert("");
        setalertMessage("Saving in progress...");
        setShowToast(true);
        const data = {
          description: newDescription,
        };
        const success = await updateBackgroundDesc(id, data).then(async () => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: briefId,
              activity: "updated the background description",
              field: "Description",
              current: newDescription,
              previous: origDescription,
              appModule: "BACKGROUND",
              rowId: id,
            },
          };

          await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
            setalertMessage("Successfully updated.");
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 1000);
          });
        });
        console.log("THIS IS ID", id);
      } else if (textDesc === description) {
        console.log("2nd condition");
        setDescAlert("");
        setalertMessage("No Changes");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 1000);
      } else {
        console.log("3rd Condition");
        setDescAlert("");
        setalertMessage("Saving in progress...");

        console.log(textDesc);
        // Start the background task
        const backgroundTask = async () => {
          try {
            const data = {
              description: newDescription,
            };

            const res = await updateBackgroundDesc(id, data);
            console.log(res);

            const params = {
              query: mCreateActivity,
              variables: {
                companyId: localStorage.getItem("companyId"),
                clientMatterId: matter_id,
                briefId: briefId,
                activity: "updated the background description",
                field: "Description",
                current: newDescription,
                previous: origDescription,
                appModule: "BACKGROUND",
                rowId: id,
              },
            };

            const result = await API.graphql(params);
            console.log("addActivity result", result);

            setalertMessage("Successfully updated.");
            autoAdjustRowHeight(index);
          } catch (err) {
            console.log("ERR", err);
          }
        };

        // Start the background task without blocking the UI
        backgroundTask();

        // Show the toast immediately
        setShowToast(true);

        // Hide the toast after a delay
        setTimeout(() => {
          setShowToast(false);
        }, 1000);
      }

      let foundIndex = background.findIndex((x) => x.id == id);
      background[foundIndex].description = e.target.innerText;

      //KJMF MMA 3586 RECORD ACTIVITY IF SANDBOX DATA IS CHANGED
      const mRecordSandboxDataEdit = `
        mutation createActivity(
          $companyId: ID,
          $activity: String,
          $appModule: AppModules,
          $field: String,
          $current: String,
          $previous: String,
          $rowId: String
        ) {
          activityCreate(
            activity: $activity
            companyId: $companyId
            appModule: $appModule
            previous: $previous
            field: $field
            current: $current
            rowId: $rowId
          ) {
            id
          }
        }
      `;

      //add activity when user exits the page
      var status = localStorage.getItem("isSandbox");

      if(status === "true"){
        const params = {
          query: mRecordSandboxDataEdit,
          variables: {
            companyId: localStorage.getItem("companyId"),
            activity: `Modified data in ${briefName} Page`,
            userId: localStorage.getItem("userId"),
            appModule: "SANDBOX",
          },
        };

        const addActivity = API.graphql(params).then((result) => {
          console.log(`Modified data in ${briefName} Page`, result);
        });
      }

    }

    /*
     * Removed this set state call because it prevents the Prompt component
     * from working properly. The Prompt component prevents the user
     * to leave the page while changes are still being made, hence why
     * there are times descriptions are not being saved.
     *
     * If problems persist, revert to this line of code.
     * (MMA-1930)
     */
    // setSaving(false);

    //** ADDED WINDOW RELOAD TO SIMULATE REFETCHING OF BACKGROUND AFTER SAVING DESC */
  };

  function handleEmailDate(item) {
    const file = item?.files?.items;
    const fileIndex = file.findIndex((file) => file.isGmailAttachment);
    if (fileIndex > -1) {
      var dateSent = file[fileIndex].createdAt;
      var convertedDate = moment(dateSent).format("MM/DD/YYYY");
      var convertedTime = moment(dateSent).format("hh:mm A");
      return (
        <div className="w-full">
          <p className="w-full">{convertedDate}</p>
          <p className="w-full">{convertedTime}</p>
        </div>
      );
    }
    return (
      <div>
        <p>N/A </p>
      </div>
    );
  }

  // const SortByEmailDate = async () => {
  // 	//MMA-1306: Add sort by email date and time
  // //	console.log("Email Date Asc is " + emailDateAsc)
  // 	if(background || background.length > 0 ){

  // 		if(emailDateAsc === null){
  // 			setBackgroundCopy(background)
  // 			const sortedBackground = background.slice().sort((a, b) => {
  // 				const fileA = a.files?.items.find(file => file.isGmailAttachment)
  // 				const fileB = b.files?.items.find(file => file.isGmailAttachment)

  // 				if (fileA && fileB){
  // 					return new Date(fileA.createdAt) - new Date(fileB.createdAt);
  // 				} else if (fileA) {
  // 				return -1;
  // 				} else if (fileB) {
  // 				return 1;
  // 				} else {
  // 				return 0;
  // 				}
  // 			})
  // 			// for(var i = 0; i < sortedBackground.length; i++){
  // 			// console.log(i + " ASC EMAIL DATE "+ JSON.stringify(sortedBackground[i].date));
  // 			// }
  // 			// console.log("---------------")
  // 			setEmailDateAsc(true);
  // 			setBackground(sortedBackground)
  // 		}

  // 		else if(emailDateAsc === true){
  // 			const sortedBackground = background.slice().sort((a, b) => {
  // 				const fileA = a.files?.items.find(file => file.isGmailAttachment)
  // 				const fileB = b.files?.items.find(file => file.isGmailAttachment)

  // 				if (fileA && fileB){
  // 					return new Date(fileB.createdAt) - new Date(fileA.createdAt);
  // 				} else if (fileA) {
  // 				return -1;
  // 				} else if (fileB) {
  // 				return 1;
  // 				} else {
  // 				return 0;
  // 				}
  // 			})
  // 			// for(var j = 0; j < sortedBackground.length; j++){
  // 			// console.log(j + " DESC EMAIL DATE "+ JSON.stringify(sortedBackground[j].date));
  // 			// }
  // 			// console.log("---------------")
  // 			setEmailDateAsc(false);
  // 			setBackground(sortedBackground)
  // 		}
  // 	else {
  // 		setEmailDateAsc(null)
  // 		setBackground(backgroundCopy)
  // 		// for(var k = 0; k < bgBeforeEmail.length; k++){
  // 		// console.log(k + " ORIGINAL EMAIL DATE "+ JSON.stringify(bgBeforeEmail[k].date));
  // 		// }
  // 		// console.log("---------------")
  // 	}

  // 	setTimeout(() => {
  // 		autoAdjustAllRow();
  // 	}, 400);

  // 	}

  // }

  const handleSaveTabElement = async (e, description, date, id, index) => {
    const handleMentions = (text) => {
      return text.replace(/@Ta?b?/gi, "@Tab");
    };
    if (e.target === document.activeElement) {
      const origDescription = handleDescription(description, index);
      const receivedDescription = handleDescription(e.target.innerText, index);

      const modifiedDescription = handleMentions(
        receivedDescription?.replace(/@Tab\s*\([^)]*\)\s*/gi, "@Tab ")
      );

      // cleanup double spacing when user used only one space
      const modifiedDescriptionWithoutDoubleNewlines =
        modifiedDescription.replace(/\n{2,}/g, "\n");
      const newDescription = extractText(
        modifiedDescriptionWithoutDoubleNewlines
      );

      // console.log("New Description", newDescription);

      const backgroundTask = async () => {
        try {
          const data = {
            description: newDescription,
          };

          const res = await updateBackgroundDesc(id, data);
          console.log(res);

          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: briefId,
              activity: "updated the background description",
              field: "Description",
              current: newDescription,
              previous: origDescription,
              appModule: "BACKGROUND",
              rowId: id,
            },
          };

          const result = await API.graphql(params);
          console.log("addActivity result", result);

          setalertMessage("Successfully updated.");
        } catch (err) {
          console.log("ERR", err);
        }
      };

      backgroundTask();

      let foundIndex = background.findIndex((x) => x.id == id);
      // background[foundIndex].description = e.target.innerText;
      background[foundIndex].description = newDescription;
    }
  };

  const handleSaveComment = async (e, comments, id, index) => {
    const origComments = handleComment(comments);
    setalertMessage("Saving in progress...");
    setShowToast(true);
    const data = {
      comments: e.target.innerHTML,
    };
    const success = await updateBackgroundComment(id, data).then(async () => {
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
          briefId: briefId,
          activity: "updated the background comments",
          field: "Comment",
          current: data.comments,
          previous: origComments,
          appModule: "BACKGROUND",
          rowId: id,
        },
      };
      const addActivity = await API.graphql(params).then((result) => {
        console.log("addActivity result", result);
      });
    });

    if (success) {
      setalertMessage("Successfully updated.");
      setShowToast(true);
    }
    setTimeout(() => {
      setShowToast(false);
    }, 1000);
    //autoAdjustRowHeight(index);

    let foundIndex = background.findIndex((x) => x.id === id);
    background[foundIndex].comments = e.target.innerText;
  };

  const handleChangeDate = async (selected, id, origDate, index) => {
    console.log("selected", selected);
    // console.log("origDate", origDate);
    // console.log("id", id);
    // console.log("index", index);
    const data = {
      date:
        selected !== null
          ? timezoneFormatter(
              selected,
              localStorage.getItem("preferredTimezone") == "null"
                ? momentTZ.tz.guess()
                : localStorage.getItem("preferredTimezone")
            )
          : null,
    };
    console.log("DATE", data);

    const origDateString =
      moment.utc(origDate).toISOString() !== null
        ? String(moment.utc(origDate).toISOString())
        : null;

    await updateBackgroundDate(id, data).then(async () => {
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
          briefId: briefId,
          activity: "changed the background date",
          field: "Date",
          current:
            data.date !== null ? moment.utc(data.date).toISOString() : null,
          previous: origDateString,
          appModule: "BACKGROUND",
          rowId: id,
        },
      };
      const addActivity = await API.graphql(params).then((result) => {
        console.log("addActivity result", result);
      });
    });

    const updatedOSArray = background.map((p) =>
      p.id === id ? { ...p, date: data.date } : p
    );

    setBackground(updatedOSArray);

    autoAdjustRowHeight(index); //Added Overlapping files

    //Added for previouseBackground (Search Functionality)
    const updatedOSArrayPrev = previousBackground.map((p) =>
      p.id === id ? { ...p, date: data.date } : p
    );

    setPreviousBackground(updatedOSArray);
   // getBackground();
  };

  const mUpdateBackgroundDate = `
    mutation updateBackground($id: ID, $date: AWSDateTime) {
      backgroundUpdate(id: $id, date: $date) {
        id
        date
      }
    }
  `;

  const mUpdateBackgroundDynamicData = `
  mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
    backgroundUpdate(id: $id, dynamicData: $dynamicData) {
      id
      dynamicData
    }
  }
  `;

  const mUpdateBackgroundDesc = `
  mutation updateBackground($id: ID, $description: String) {
    backgroundUpdate(id: $id, description: $description) {
      id
      description
    }
  }
`;

  const mUpdateBackgroundDescAndHistory = `
  mutation updateBackground($id: ID, $description: String, $promptHistory: AWSJSON) {
    backgroundUpdate(id: $id, description: $description, promptHistory: $promptHistory) {
      id
      description
      promptHistory
    }
  }
`;

  const mUpdateBackgroundHistory = `
  mutation updateBackground($id: ID, $promptHistory: AWSJSON) {
    backgroundUpdate(id: $id, promptHistory: $promptHistory) {
      id
      promptHistory
    }
  }
`;

  const mUpdateBackgroundComment = `
  mutation updateBackground($id: ID, $comments: String) {
    backgroundUpdate(id: $id, comments: $comments) {
      id
      comments
    }
  }
`;

  const EMAIL_PREVIEW = `
  query getEmailDetails($id: ID) {
    gmailMessage(id: $id) {
      id
      from
      date
      to
      cc
      bcc
      subject
      recipient
      receivedAt
      payload {
        id
        content
      }
    }
  }`;

  const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
    gmailMessage(id: $id) {
      payload {
        content
      }
    }
  }
  `;

  const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

  async function updateBackgroundDate(id, data) {
    console.log("updateBackgroundDate:", data);

    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDate,
          variables: {
            id: id,
            date:
              data.date !== null ? moment.utc(data.date).toISOString() : null,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }
  async function updateBackgroundDesc(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDesc,
          variables: {
            id: id,
            description: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  async function updateBackgroundDescAndHistory(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDescAndHistory,
          variables: {
            id: id,
            description: data.description,
            promptHistory: data.promptHistory,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  async function updateBackgroundHistory(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundHistory,
          variables: {
            id: id,
            promptHistory: data.promptHistory,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  async function updateBackgroundComment(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundComment,
          variables: {
            id: id,
            comments: data.comments,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const autoAdjustAllRow = async () => {
    try {
      if (bindList && cache) {
        cache?.current.clearAll();
        //while loop is recommended fastest loop

        var i = 0,
          len = background.length;
        while (i < len) {
          setTimeout(() => {
            bindList?.current?.recomputeRowHeights(len);
          }, 100);

          i++;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  function stripedTags(str) {
    const stripedStr = str?.replace(/<[^>]+>/g, "");
    return stripedStr;
  }

  // Function for updating the Row Order in the Database
  const handleRowOrderChangeDB = async (newRowArrangement) => {
    //POKS
    const mBulkUpdateBackgroundOrder = `
      mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
        backgroundBulkUpdateOrders(arrangement: $arrangement) {
          id
          order
        }
      }`;

    if (newRowArrangement && newRowArrangement.length > 0 && ascDesc === null) {
      const response = await API.graphql({
        query: mBulkUpdateBackgroundOrder,
        variables: {
          arrangement: newRowArrangement,
        },
      });
    }

    autoAdjustAllRow();
  };

  //Handling of changing row order using the Draggable Feature (React DnD / Hello Pangea)
  const handleDragEnd = async (e, index) => {
    console.log("DRAG END", e);

    let tempBackground = [...background];

    let [selectedRow] = tempBackground.splice(e.source.index, 1);

    tempBackground.splice(e.destination.index, 0, selectedRow);
    setBackground(tempBackground);

    //new function for multiple tabs reference
    const outputArray = reduceTabFilesToArray(tempBackground);
    setReducedTabsArray(outputArray);

    //new function for multiple tabs reference
    const res = tempBackground.map(({ id }, index) => ({
      id: id,
      order: index + 1,
    }));

    handleRowOrderChangeDB(res);
  };

  //Handling of changing the selected row/s order using the 'Move Row' button from the ActionButtons
  const handleMoveRow = (rowIndex) => {
    console.log("ROW INDEX ", rowIndex);

    //Copy of the Background array and local storage Stored Rows
    let tempBackground = [...background];

    const storedItemRows = JSON.parse(localStorage.getItem("selectedRows"));
    let tempStoredItemRows = [...storedItemRows];

    //Placeholder for the filtered and removed items
    let filteredBackgroundItems = [];
    let removedBackgroundItems = [];

    //Filter the current background to remove all the selected background by Id
    //Then put the removed items in the removedBackgroundItems array
    tempBackground.forEach((backgroundItem) => {
      let ifFound = tempStoredItemRows.findIndex(
        (storedItem) => storedItem?.id == backgroundItem?.id
      );
      //console.log("Found Index: ",ifFound)
      if (ifFound > -1) {
        removedBackgroundItems.push(backgroundItem);
        tempStoredItemRows.splice(ifFound, 1);
      } else {
        filteredBackgroundItems.push(backgroundItem);
      }
    });
    //console.log("FILTERED BACKGROUND ", filteredBackgroundItems);
    //console.log("REMOVED BACKGROUND ", removedBackgroundItems);

    //Move the selected rows to the specified index
    filteredBackgroundItems.splice(rowIndex, 0, ...removedBackgroundItems);

    //Update the order values to reflect the new arrangement
    const restore = filteredBackgroundItems.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
    setBackground(restore);
    setPreviousBackground(restore);
    console.log("New Background Arrangement: ", restore);

    //new function for multiple tabs reference
    const outputArray = reduceTabFilesToArray(restore);
    setReducedTabsArray(outputArray);

    //new function for multiple tabs reference
    const res = restore.map(({ id, order }) => ({
      id: id,
      order: order,
    }));

    //Save to DB the new order values
    handleRowOrderChangeDB(res);

    //Reset the states
    setShowDeleteButton(false);
    setPasteButton(false);
    setMoveButton(false);
    setcheckAllState(false);
    setSelectRow([]);
    setSelectedItems([]);
    setSelectedRowsBG([]);
    localStorage.removeItem("selectedRows");

    setalertMessage("Successfully moved rows");
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 1500);
  };

  const handleDragEndPopup = async (e, index) => {
    allowScroll();
    console.log(e);
    try {
      let labelsCopy = cloneDeep(labels);

      let [selectedOption] = labelsCopy.splice(e.source.index, 1);

      labelsCopy.splice(e.destination.index, 0, selectedOption);
      setLabels(labelsCopy);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeBackground = (id) => {
    setSelected(id);
  };

  const previewAndDownloadFile = async (id) => {
    console.log("previewAndDownloadFile: ", id);
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      console.log("s3ObjectKey", s3ObjectKey);

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        const newTab = window.open("", "_blank");
        // var encodedUrl = encodeURIComponent(downloadURL);
        // var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        // window.open(documentViewerUrl);
        if (newTab) {
          const iframe = document.createElement("iframe");
          iframe.src = downloadURL;
          iframe.style.width = "100%";
          iframe.style.height = "100%";
          iframe.style.border = "none";

          // newTab.document.body.appendChild(iframe);
        } else {
          console.error("Failed to open new tab with iframe.");
        }
      }
    });
  };

  const getEmailPayload = async (messageId) => {
    console.log("getEmailPayload()", messageId);
    if (messageId !== null) {
      const params = {
        query: qGetMessagePayload,
        variables: {
          id: messageId,
        },
      };

      const result = await API.graphql(params);
      console.log("getEmailPayload result:: ", result);
      if (result && result?.data?.gmailMessage?.payload) {
        let newPayload = { payload: [{ content: "" }] };
        console.log("getEmailPayload()", result);

        result?.data?.gmailMessage.payload?.forEach((data, index) => {
          newPayload = {
            payload: [
              {
                content: newPayload.payload[0].content + data?.content,
              },
            ],
          };
        });
        return newPayload;
        // return result?.data?.gmailMessage;
      } else {
        return "<b>PDF file has not been created properly.</b>";
      }
    } else {
      console.error(
        "Unexpected error in getting email payload",
        "MessageID:",
        messageId
      );
    }
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const previewAndDownloadPDF = async (id, gmailMessageId, subjectTitle) => {
    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: config.aws_user_gmail_attachments_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    var opt = {
      margin: [30, 50, 30, 50],
      filename: subjectTitle,
      image: { type: "png", quality: 0.95 },
      html2canvas: {
        dpi: 96,
        scale: 1,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: "#FFF",
      },
      addImage: { contentDataURL: "PNG" },
      jsPDF: { unit: "pt", format: "a4", orientation: "p" },
      pagebreak: {
        before: ".page-break",
        mode: ["avoid-all", "css", "legacy"],
      },
    };

    var content = `<span>`;
    content += `<img src=${googleLogin} alt="" /><hr></hr>`;
    content += `<h2><b>${subjectTitle}</b></h2><hr></hr><br />`;
    content += `<p>From: ${from}</p>`;
    content += `<p>Date: ${moment(dateEmail).format(
      "DD MMM YYYY, hh:mm A"
    )}</p>`;
    content += `<p>To: ${to}</p>`;
    content += cc ? `<p>CC: ${cc}</p>` : "";
    content += `</span>`;

    const emailPayload = await getEmailPayload(gmailMessageId);

    if (emailPayload) {
      const parsedEmail = EmailParser(
        JSON.parse(emailPayload.payload[0].content)
      );
      const htmlContent = parsedEmail.html;
      console.log("Original Content - ", emailPayload);
      // const htmlContent = emailPayload.payload
      //   .map((em) => em.content)
      //   .join("")
      //   .split('data":"')
      //   .pop()
      //   .split('"}')[0];

      content += htmlContent;
      // content += Base64.decode(htmlContent).replace("body{color:", "");

      console.log("Edited Content - ", content);

      await html2pdf()
        .from(content)
        .set(opt)
        .toPdf()
        .output("datauristring")
        .then(function (pdfAsString) {
          const preBlob = dataURItoBlob(pdfAsString);
          const file = new File([preBlob], subjectTitle, {
            type: "application/pdf",
          });
          console.log(file);

          var key = `${gmailMessageId}/${Number(new Date())}${file.name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            type = "application/pdf",
            size = file.size;

          // put objects to s3
          try {
            Storage.put(key, file, {
              contentType: type,
              progressCallback(progress) {
                console.log(progress);
              },
              errorCallback: (err) => {
                console.error("204: Unexpected error while uploading", err);
              },
            })
              .then((fd) => {
                const mUpdateMatterFileObjectKey = `
                    mutation updateMatterFile ($id: ID, $s3ObjectKey: String, $size: Int, $type: String) {
                      matterFileUpdate(id: $id, s3ObjectKey: $s3ObjectKey, size: $size, type: $type) {
                        id
                      }
                    }
                `;

                const params = {
                  query: mUpdateMatterFileObjectKey,
                  variables: {
                    id: id,
                    s3ObjectKey: fd.key,
                    size: parseInt(size),
                    type: type,
                  },
                };

                API.graphql(params).then(async (res) => {
                  console.log("Get Details", res);
                  const params = {
                    query: qGetFileDownloadLink,
                    variables: {
                      id: id,
                    },
                  };
                  await API.graphql(params).then(async (result) => {
                    const { type, downloadURL, s3ObjectKey } = result.data.file;
                    window.open(downloadURL);
                  });
                });
              })
              .catch((err) => {
                console.error("Unexpected error while uploading", err);
              });
          } catch (e) {
            const response = {
              error: e.message,
              errorStack: e.stack,
              statusCode: 500,
            };
            console.error("Unexpected error while uploading", response);
          }
        });
    }
  };

  const BACKGROUNDFILE_TAG_MUTATION = `
    mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
      backgroundFileTag(backgroundId: $backgroundId, files: $files) {
        id
      }
    }
  `;

  const [deleteRow, setDeleteRow] = useState([]);
  const [showRemoveRowModal, setShowRemoveRowModal] = useState(false);

  const showDeleteRowModal = (item) => {
    const ids = [
      {
        id: item.id,
        fileName: "",
      },
    ];

    setDeleteRow(ids);
    setShowRemoveRowModal(true);
  };

  var holdDeleteBrief;
  const [holdDeleteRow, setHoldDeleteRow] = useState(false);
  const [backgroundCopy, setBackgroundCopy] = useState([]);

  const handleDeleteRow = (item) => {
    var bgCopy = background;
    setBackgroundCopy(bgCopy);
    setHoldReducedTabsArrayCopy([...reducedTabsArray]);
    const updatedBgCopy = bgCopy.filter((x) => x.id !== item[0].id);
    setBackground(updatedBgCopy);

    //Added for previouseBackground (Search Functionality)
    const updatedBgCopyPrev = previousBackground.filter(
      (x) => x.id !== item[0].id
    );
    setPreviousBackground(updatedBgCopyPrev);

    setalertMessage(`Rows Deleted. Click HERE to undo action`);
    setShowToast(true);
    setHoldDeleteRow(true);
    const newArr = Array(background.length).fill(false);
    setCheckedState(newArr);
    window.onbeforeunload = function () {
      return "Changes you saved might not be saved.";
    };
    if (item.length === 0) {
      window.alert("Please select row.");
    } else {
      holdDeleteBrief = setTimeout(async () => {
        setShowToast(false);
        //Delete file permanently after 4 seconds
        if (bool.current) {
          //FIX FOR MMA-2255: Copied delete query from Filebucket to also delete labels
          const mDeleteFileAttachment = `
				mutation deleteBackgroundAttachments($backgroundId: ID, $files: [FileInput]) {
				backgroundFileTag(backgroundId: $backgroundId, files: $files) {
					id
				}
				}
			`;

          const mDeleteBackground = `
                mutation untagBriefBackground($briefId: ID, $background: [BackgroundInput]) {
                  briefBackgroundUntag(briefId: $briefId, background: $background) {
                    id
                  }
                }
                `;
          try {
            const request = API.graphql({
              query: mDeleteFileAttachment,
              variables: {
                backgroundId: item[0].id,
                files: [],
              },
            });
            const deletedId = await API.graphql({
              query: mDeleteBackground,
              variables: {
                briefId: briefId,
                background: { id: item[0].id },
              },
            }).then(async () => {
              if (item?.length > 1) {
                const params = {
                  query: mCreateActivity,
                  variables: {
                    companyId: localStorage.getItem("companyId"),
                    clientMatterId: matter_id,
                    briefId: briefId,
                    activity: "removed multiple backgrounds",
                    field: "Background",
                    appModule: "BACKGROUND",
                  },
                };
                const addActivity = await API.graphql(params).then((result) => {
                  console.log("addActivity result", result);
                });
              } else {
                const params = {
                  query: mCreateActivity,
                  variables: {
                    companyId: localStorage.getItem("companyId"),
                    clientMatterId: matter_id,
                    briefId: briefId,
                    activity: "removed a background",
                    field: "Background",
                    appModule: "BACKGROUND",
                  },
                };
                const addActivity = await API.graphql(params).then((result) => {
                  console.log("addActivity result", result);
                });
              }
            });
          } catch (e) {
            console.log("error here", e);
          }
          setalertMessage(`Successfully Deleted`);
          setShowToast(true);
        }
        window.onbeforeunload = null;
      }, 4000);

      const rowArrangement = bgCopy.map(({ id }, index) => ({
        id: id,
        order: index + 1,
      }));

      updateBackgroundOrders(rowArrangement);

      // For FE purposes: Update existing background array with the updated order data
      bgCopy.forEach((item, index) => {
        item.order = index + 1;
      });
      setShowRemoveRowModal(false);
    }
  };
  const handleDelete = async (item) => {
    setHoldDeleteFile(true);
    setalertMessage(`Deleting File. Click HERE to undo action`);
    setShowToast(true);
    setshowRemoveFileModal(false);

    setTimeout(() => {
      setShowToast(false);
    }, 3000);

    setTimeout(() => {
      if (bool.current) {
        deleteFileProper(item);
      } else {
        cancelDeleteFile();
      }
    }, 2000);
  };

  const deleteFileProper = async (item) => {
    console.log("handleDelete", item);
    const backgroundFilesOpt = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: item[0].backgroundId,
      },
    });

    // console.log("all background file attachment", backgroundFilesOpt);

    if (backgroundFilesOpt.data.background.files !== null) {
      const arrFileResult = backgroundFilesOpt.data.background.files.items.map(
        ({ id }) => ({
          id: id,
        })
      );

      const filteredArrFiles = arrFileResult.filter((i) => i.id !== item[0].id);

      const request = API.graphql({
        query: BACKGROUNDFILE_TAG_MUTATION,
        variables: {
          backgroundId: item[0].backgroundId,
          files: filteredArrFiles,
        },
      }).then(async () => {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: `removed a background file named "${item[0].fileName}"`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: item[0].backgroundId,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });
      });

      setTimeout(async () => {
        // list updated result files
        const backgroundFilesOptReq = await API.graphql({
          query: qlistBackgroundFiles,
          variables: {
            id: item[0].backgroundId,
          },
        });

        if (backgroundFilesOptReq.data.background.files !== null) {
          const newFilesResult =
            backgroundFilesOptReq.data.background.files.items.map(
              ({ id, name, description, gmailMessageId }) => ({
                id: id,
                name: name,
                description: description,
                gmailMessageId: gmailMessageId,
              })
            );

          const updateArrFiles = background.map((obj) => {
            if (obj.id === item[0].backgroundId) {
              return { ...obj, files: { items: newFilesResult } };
            }
            return obj;
          });

          setBackground(updateArrFiles);
          //Added for previouseBackground (Search Functionality)
          setPreviousBackground(updateArrFiles);
        }
      }, 500);
    }
    setalertMessage(`Successfully Deleted File.`);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
      setHoldDeleteFile(false);
    }, 500);
  };

  const cancelDeleteFile = () => {
    bool.current = false;
    setHoldDeleteFile(false);
  };

  function undoDeleteFile() {
    bool.current = false;
    setalertMessage(`Restored File.`);
    setShowToast(true);
    setHoldDeleteFile(false);

    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }

  //end

  setTimeout(() => {
    setHighlightRows("bg-white");

    if (queryParams.has("count")) {
      // queryParams.delete("count");
      // history.replace({
      //   search: queryParams.toString(),
      // });
      const back_id = "0";
      history.push(
        `${
          AppRoutes.BACKGROUND
        }/${back_id}/${matterId}/?matter_name=${utf8_to_b64(
          matter_name
        )}&client_name=${utf8_to_b64(client_name)}`
      );
    }
  }, 10000);

  function compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = new Date(a[key]);
      const varB = new Date(b[key]);
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  const handleFilesCheckboxChange = (
    event,
    id,
    files_id,
    background_id,
    name
  ) => {
    var temp = pastedRows;
    var temp2 = checkedRows;

    if (event.target.checked) {
      if (!files.includes({ uniqueId: event.target.name })) {
        if (
          tempFiles.indexOf(
            tempFiles.find((temppFiles) => temppFiles.id === id)
          ) > -1
        ) {
          // temp.map((x)=> x === background_id ? temp.splice(temp.indexOf(x), 1) : x)
          // setPastedRows(temp);
        } else {
          tempFiles = [
            ...tempFiles,
            {
              id: id,
              files: files_id,
              backgroundId: background_id,
              name: name,
            },
          ];
          selectedRowsBGFilesPass = tempFiles;

          // temp = [...temp, background_id+"/"+files_id];
          // console.log("selected", temp);

          temp2 = [...temp2, background_id + "/" + files_id];
          console.log("selected row+file id", temp2);
          setCheckedRows(temp2);
          // setPastedRows(temp);
          setSelectedRowsBGFiles(tempFiles);
        }
      }
    } else {
      if (
        tempFiles.indexOf(
          tempFiles.find((temppFiles) => temppFiles.id === id)
        ) > -1
      ) {
        tempFiles.splice(
          tempFiles.indexOf(
            tempFiles.find((temppFiles) => temppFiles.id === id)
          ),
          1
        );
        console.log("bgid", background_id);
        console.log("temp", temp);

        var pass = [];
        var pass2 = [];

        // for(var i = 0; i<temp.length; i++){
        //   var splitId = temp[i].split("/");
        //   console.log("firstsecond", splitId);
        //   if(splitId[0] === background_id && splitId[1] === files_id){
        //     pass = pass;
        //   }else{
        //     pass = [...pass, temp[i]];
        //   }
        // }

        for (var i = 0; i < temp2.length; i++) {
          var splitId = temp2[i].split("/");
          if (splitId[0] === background_id && splitId[1] === files_id) {
            pass2 = pass2;
          } else {
            pass2 = [...pass2, temp2[i]];
          }
        }
        console.log("selected row+file id", temp2);
        setCheckedRows(pass2);
        // setPastedRows(pass);
        setSelectedRowsBGFiles(tempFiles);
        selectedRowsBGFilesPass = tempFiles;
      }
    }
  };

  const qlistBackgroundFiles = `
  query getBackgroundByID($id: ID) {
    background(id: $id) {
      id
      date
      files {
        items {
          createdAt
          id
          details
          name
          s3ObjectKey
          gmailMessageId
          order
          size
        }
      }
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

  const qGetFileDownloadLink = `
  query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const pasteFilestoBackground = async (background_id, index) => {
    var temp = pastedRows;
    temp = [...temp, background_id];
    setPastedRows(temp);
    setLoading(true);
    let arrCopyFiles = [];
    let arrFileResult = [];
    const seen = new Set();

    const backgroundFilesOpt = await API.graphql({
      query: qlistBackgroundFiles,
      variables: {
        id: background_id,
      },
    });

    if (backgroundFilesOpt.data.background.files !== null) {
      arrFileResult = backgroundFilesOpt.data.background.files.items.map(
        ({ id, name }) => ({
          id: id,
          name: name,
        })
      );
    }
    console.log("selectedRowsBGFiles", selectedRowsBGFiles);

    arrCopyFiles = selectedRowsBGFiles.map(({ files, name }) => ({
      id: files,
      name: name,
    }));

    arrCopyFiles.push(...arrFileResult);

    const filteredArr = arrCopyFiles.filter((el) => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });
    console.log("BACKGROUND ID:", background_id);
    console.log("content-files", filteredArr);

    if (background_id !== null) {
      const request = await API.graphql({
        query: BACKGROUNDFILE_TAG_MUTATION,
        variables: {
          backgroundId: background_id,
          files: filteredArr,
        },
      });

      console.log("REQUEST FROM:", background_id, "--", request);

      console.log("pasted files:", filteredArr);

      // Activity Log
      Promise.all(
        arrCopyFiles.map(async (file) => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: briefId,
              activity: `copied a background file named "${file.name}"`,
              field: "File",
              appModule: "BACKGROUND",
              rowId: background_id,
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
          });
        })
      );

      // End of Activity Log

      const backgroundFilesOptReq = await API.graphql({
        query: qlistBackgroundFiles,
        variables: {
          id: background_id,
        },
      });

      console.log("checkthisss", backgroundFilesOptReq);

      if (backgroundFilesOptReq.data.background.files !== null) {
        const newFilesResult =
          backgroundFilesOptReq.data.background.files.items.map(
            ({ id, name, description }) => ({
              id: id,
              name: name,
              description: description,
            })
          );

        const updateArrFiles = background.map((obj) => {
          if (obj.id === background_id) {
            return { ...obj, files: { items: newFilesResult } };
          }
          return obj;
        });
        setBackground(updateArrFiles);
        //Added for previouseBackground (Search Functionality)
        setPreviousBackground(updateArrFiles);
      }
    }

    setSelectedId(background_id);
    setTimeout(() => {
      setLoading(false);
      setSelectedId(0);
    }, 500);
    autoAdjustRowHeight(index);
  };

  const mCreateBackground = `
    mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
      backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
        id
        createdAt
        date
        description
        order
        briefs {
          id
          name
        }
      }
    }
      `;

  const mBulkUpdateBackgroundOrder = `
      mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
        backgroundBulkUpdateOrders(arrangement: $arrangement) {
          id
          order
        }
      }`;

  const mDeleteBackground = `
  mutation untagBriefBackground($briefId: ID, $background: [BackgroundInput]) {
    briefBackgroundUntag(briefId: $briefId, background: $background) {
      id
    }
  }
  `;

  const handlePasteRow = async (targetIndex, action) => {
    if (action === "paste") {
      let tempBackground = [...background];
      let arrFileResultBG = [];

      setCheckedState(new Array(background.length).fill(false));

      const storedItemRows = JSON.parse(localStorage.getItem("selectedRows"));
      var counter = 1;

      console.log("LOOOK", storedItemRows);

      const tempBriefDetails = [{ id: briefId, name: briefName }];

      // Use Promise.all() to execute all API calls asynchronously
      await Promise.all(
        storedItemRows.map(async function (x) {
          // Use destructuring to get the relevant properties from createBackgroundRow.data.backgroundCreate
          const { createdAt, id, date, description, order } = (
            await API.graphql({
              query: mCreateBackground,
              variables: {
                briefs: tempBriefDetails,
                description: x.description,
                comments: "",
                date: x.date,
                files: { items: [] },
                order: counter++,
              },
            })
          ).data.backgroundCreate;

          var arrResult = {
            createdAt,
            id,
            files: { items: x.files.items },
            date,
            description,
            order,
            briefs: tempBriefDetails,
          };

          const arrId = [{ id }];

          bgInput.push({ id });
          arrFileResultBG.push(...[arrResult]);

          // Use Promise.all() to execute both API calls simultaneously
          await Promise.all([
            API.graphql({
              query: BACKGROUNDFILE_TAG_MUTATION,
              variables: {
                backgroundId: id,
                files: x.files.items.map((item) => ({
                  id: item.id,
                  name: item.name,
                })),
              },
            }),
          ]);
        })
      );

      // Activity Logging
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
          briefId: briefId,
          activity: `copied ${storedItemRows.length} background ${
            storedItemRows.length > 1 ? "rows" : "row"
          }`,
          field: "Background",
          appModule: "BACKGROUND",
        },
      };
      const addActivity = await API.graphql(params).then((result) => {
        console.log("addActivity result", result);
      });

      setTimeout(async function () {
        // console.log("Updated Arr", sortByOrder(arrFileResultBG));

        const reversedArr = sortByOrder(arrFileResultBG).reverse();

        for (const x of reversedArr) {
          var resultBG = {
            createdAt: x.createdAt,
            id: x.id,
            files: { items: x.files.items },
            date: x.date,
            description: x.description,
            order: x.order,
            briefs: x.briefs,
          };

          tempBackground.splice(targetIndex + 1, 0, resultBG);
        }

        setBackground(tempBackground);
        //Added for previouseBackground (Search Functionality)
        setPreviousBackground(tempBackground);
        setSelectRow([]);

        // console.log("tempBackground", tempBackground);
        const result = tempBackground.map(({ id }, index) => ({
          id: id,
          order: index,
        }));

        if (result && result.length > 0) {
          await API.graphql({
            query: mBulkUpdateBackgroundOrder,
            variables: {
              arrangement: result,
            },
          });
        }

        autoAdjustAllRow();
      }, 1500);

      setShowDeleteButton(false);
      setPasteButton(false);
      setMoveButton(false);
      setcheckAllState(false);
      setSelectRow([]);
      setSelectedItems([]);
      setSelectedRowsBG([]);
      localStorage.removeItem("selectedRows");

      setHoldPaste(true);
      setalertMessage("Successfully copied rows. Click HERE to undo action");
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
      }, 9000);
    } else if (action === "move") {
      //Old Implementation for Moving Rows
      //Go to line 1136 - handleMoveRow() for the new implementation
      let tempBackground = [...background];
      let arrFileResultBG = [];

      setCheckedState(new Array(background.length).fill(false));

      const storedItemRows = JSON.parse(localStorage.getItem("selectedRows"));
      var counter = 1;

      const tempBriefDetails = [{ id: briefId, name: briefName }];

      storedItemRows.map(async function (x) {
        const createBackgroundRow = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: tempBriefDetails,
            description: x.details,
            comments: "",
            date: x.date,
            files: { items: [] },
            order: counter++,
          },
        });

        var arrResult = {
          createdAt: createBackgroundRow.data.backgroundCreate.createdAt,
          id: createBackgroundRow.data.backgroundCreate.id,
          files: { items: x.files.items },
          date: createBackgroundRow.data.backgroundCreate.date,
          description: createBackgroundRow.data.backgroundCreate.description,
          order: createBackgroundRow.data.backgroundCreate.order,
        };

        console.log(createBackgroundRow.data.backgroundCreate);

        bgInput.push({ id: createBackgroundRow.data.backgroundCreate.id });
        arrFileResultBG.push(...[arrResult]);

        const request = await API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: createBackgroundRow.data.backgroundCreate.id,
            files: x.files.items.map((item) => ({
              id: item.id,
              name: item.name,
            })),
          },
        });

        API.graphql({
          query: mDeleteBackground,
          variables: {
            briefId: briefId,
            background: { id: x.id },
          },
        }).then(async (result) => {
          console.log(result);
          tempBackground = tempBackground.filter((obj) => obj.id !== x.id);

          // Activity Logging
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: briefId,
              activity: `moved ${storedItemRows.length} background ${
                storedItemRows.length > 1 ? "rows" : "row"
              } order`,
              field: "Background",
              appModule: "BACKGROUND",
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
          });

          setTimeout(async function () {
            sortByOrder(arrFileResultBG)
              .splice(0)
              .reverse()
              .map(async function (x) {
                var resultBG = {
                  createdAt: x.createdAt,
                  id: x.id,
                  files: { items: x.files.items },
                  date: x.date,
                  description: x.description,
                  order: x.order,
                };

                tempBackground.splice(targetIndex, 0, resultBG);

                setBackground(tempBackground);
                //Added for previouseBackground (Search Functionality)
                setPreviousBackground(tempBackground);
                setSelectRow([]);

                const result = tempBackground.map(({ id }, index) => ({
                  id: id,
                  order: index,
                }));

                await API.graphql({
                  query: mBulkUpdateBackgroundOrder,
                  variables: {
                    arrangement: result,
                  },
                });
              });

            setShowDeleteButton(false);
            setPasteButton(false);
            setMoveButton(false);
            setcheckAllState(false);
            setSelectRow([]);
            setSelectedItems([]);
            setSelectedRowsBG([]);
            localStorage.removeItem("selectedRows");

            //setHoldPaste(true);
            setalertMessage("Successfully moved rows");
            setShowToast(true);

            setTimeout(() => {
              setShowToast(false);
            }, 3000);

            autoAdjustAllRow();
          }, 1500);
        });
      });
    }
  };

  function undoDeleteRow() {
    setBackground(backgroundCopy);
    //Added for previouseBackground (Search Functionality)
    setPreviousBackground(backgroundCopy);
    clearTimeout(holdDeleteBrief);
    bool.current = false;
    setalertMessage(`Restoring Rows..`);
    setShowToast(true);
    const newArr = Array(background.length).fill(false);
    setCheckedState(newArr);
    setSelectedRowsBG([]);
    setSelectedItems([]);
    setcheckAllState(false);
    setHoldDelete(false);
    if (holdReducedTabsArrayCopy) {
      setReducedTabsArray(holdReducedTabsArrayCopy);
    }

    setTimeout(() => {
      setShowToast(false);
      // getBackground("Action Buttons undo action get background");
      autoAdjustAllRow();
      window.onbeforeunload = null;
    }, 2000);
  }
  function undoAction() {
    const newArr = Array(background.length).fill(false);
    setCheckedState(newArr);
    console.log("BI", bgInput);

    const deletedId = API.graphql({
      query: mDeleteBackground,
      variables: {
        briefId: briefId,
        background: bgInput,
      },
    });

    setSelectedRowsBG([]);
    setSelectedItems([]);
    setcheckAllState(false);
    setHoldPaste(false);
    setSelectRow([]);

    setTimeout(() => {
      setShowToast(false);

      getBackground("Undo Action get Background");
    }, 3000);
  }

  // const reOrderFiles = (array, tempBackground, targetIndex) => {
  //   const df = convertArrayToObject(array);

  //   tempBackground.splice(targetIndex + 1, 0, df.item);
  //   return setBackground(tempBackground);
  // };

  /*const handleBottomScroll = useCallback(() => {
    console.log("Reached bottom page " + Math.round(performance.now()));
    setTimeout(() => {
      setLoading(true);
    }, 200);
    setTimeout(() => {
      loadMoreBackground();
      setLoading(false);
    }, 400);
  });

  useBottomScrollListener(handleBottomScroll);*/

  const createObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

  const mUpdateMatterFileDesc = `
      mutation updateMatterFile ($id: ID, $details: String) {
        matterFileUpdate(id: $id, details: $details) {
          id
          details
        }
      }
  `;

  const mUpdateMatterFileDate = `
      mutation updateMatterFile ($id: ID, $date: AWSDateTime) {
        matterFileUpdate(id: $id, date: $date) {
          id
          date
        }
      }
  `;

  const handleSyncData = async (backgroundId, fileId) => {
    var filteredBackground = background.filter(function (item) {
      return item.id === backgroundId;
    });

    const dateRequest = API.graphql({
      query: mUpdateMatterFileDate,
      variables: {
        id: fileId,
        date:
          filteredBackground[0].date !== null &&
          filteredBackground[0].date !== "null" &&
          filteredBackground[0].date !== ""
            ? moment
                .utc(moment(new Date(filteredBackground[0].date), "YYYY-MM-DD"))
                .toISOString()
            : null,
      },
    });

    if (!dateRequest) {
      console.log("Error", dateRequest);
    }

    const descRequest = API.graphql({
      query: mUpdateMatterFileDesc,
      variables: {
        id: fileId,
        details: filteredBackground[0].description,
      },
    });

    if (!descRequest) {
      console.log("Error", descRequest);
    }

    setalertMessage(`Successfully synced to File Bucket `);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  //UPLOADING FILE THROUGH BG
  function attachFiles(itemid, index) {
    setShowUploadModal(true);
    setSelectedRowID(itemid);
    setSelectedIndex(index);
  }

  //single matter upload
  const mCreateMatterFile = `
  mutation createMatterFile ($matterId: ID, $s3ObjectKey: String, $size: Int, $type: String, $name: String, $order: Int) {
      matterFileCreate(matterId: $matterId, s3ObjectKey: $s3ObjectKey, size: $size, type: $type, name: $name, order: $order) {
        id
        name
        order
      }
    }
  `;

  var idTag = [];
  //UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
  const handleUploadLink = async (uf) => {
    await Promise.all(
      uf?.files.map(async (file) => {
        console.log("Uploaded file - ", file);
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: `added a background file named ${file.name}-(${file.size} byte)`,
            field: "File",
            appModule: "BACKGROUND",
            rowId: selectedRowId,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          // console.log("addActivity result", result);
        });
      })
    );
    // console.log("handleUploadLink uf", uf);
    var uploadedFiles = uf.files.map((f) => ({ ...f, matterId: matterId }));

    //Add order to new files
    var sortedFiles = uploadedFiles?.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    var addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));
    // console.log("SF",sortedFiles);
    // console.log("AO", addOrder);

    //insert in matter file list
    bulkCreateMatterFile(addOrder);

    //set background content
    setTimeout(() => {
      const backgroundFilesOptReq = API.graphql({
        query: qlistBackgroundFiles,
        variables: {
          id: selectedRowId,
        },
      }).then(async (result) => {
        var newFilesResult = result.data.background.files.items.map(
          ({
            createdAt,
            id,
            name,
            description,
            gmailMessageId,
            s3ObjectKey,
            order,
            size
          }) => ({
            createdAt: createdAt,
            id: id,
            name: name,
            description: description,
            gmailMessageId: gmailMessageId,
            s3ObjectKey: s3ObjectKey,
            order: order,
            size: size
          })
        );

        // Sort the newFilesResult array by createdAt in descending order
        newFilesResult?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        console.log("Items Uploaded", result.data.background.files.items);

        // Extract the files
        const files = result.data.background.files.items.map(file => ({
          id: file.id,
          s3ObjectKey: `public/${file.s3ObjectKey}`,
          s3Bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET // Ensure s3Bucket is set
        }));

        // Create the final structure with merged files
        const transformedData = [{
          backgroundId: selectedRowId,
          files: files
        }];

        var checkDateExists = true;

        console.log("res date >>> ", result);

        if (result.data.background.date === null || result.data.background.date === undefined || result.data.background.date === "") {
            checkDateExists = false;
        }

        async function processTransformedData(transformedData) {
          for (let i = transformedData.length - 1; i >= 0; i--) {
            const item = transformedData[i];
            const { backgroundId, files } = item;

            if (!files || files.length === 0) {
              // Remove the whole item if files is empty or null
              transformedData.splice(i, 1);
              continue;
            }

            for (let j = files.length - 1; j >= 0; j--) {
              const { id: fileId } = files[j];
              const result = await getBulkExtractedTextContent(
                backgroundId,
                fileId
              );
              if (result !== null && result) {
                // If extractedContent exists, remove the file from the array
                files.splice(j, 1);
              } else if (result === null) {
                console.log(`Error extracting text for fileId: ${fileId}`);
              }
            }

            if (files.length === 0) {
              // Remove the whole item if files is empty after processing
              transformedData.splice(i, 1);
            }
          }
          return transformedData;
        }

        // Call the function with the transformed data
        processTransformedData(transformedData)
        .then( async (updatedData) => {
          // Call textract API
          console.log("Updated Data >>> ", updatedData);
          console.log(">>>>>>", background);
          var index = background.findIndex(item => item.id === selectedRowId)
          var oldDate = background.find(item => item.id === selectedRowId);

          console.log("Index>>>>>>", index)
          await processExtraction(updatedData, selectedRowId, oldDate?.date, index, checkDateExists);

        })
        .catch((error) => {
          console.error(error);
        });

        var updateArrFiles = background.map((obj) => {
          if (obj.id === selectedRowId) {
            return { ...obj, files: { items: newFilesResult } };
          }
          return obj;
        });

        // Update order number based on uploaded file
        updateArrFiles.forEach((item) => {
          const orderFilesPerBackground = item?.files?.items?.map(
            ({ id }, index) => ({
              id: id,
              order: index + 1,
            })
          );

          // For FE purposes: Update existing background array with the updated order data
          const orderFilesPerBackgroundFE = item?.files?.items?.map(
            (
              {
                id,
                details,
                gmailMessage,
                gmailMessageId,
                isGmailAttachment,
                isGmailPDF,
                name,
                s3ObjectKey,
                size
              },
              index
            ) => ({
              id: id,
              name: name,
              details: details,
              gmailMessage: gmailMessage,
              gmailMessageId: gmailMessageId,
              isGmailAttachment: isGmailAttachment,
              isGmailPDF: isGmailPDF,
              s3ObjectKey: s3ObjectKey,
              order: index + 1,
              size: size
            })
          );

          if (
            orderFilesPerBackground !== undefined &&
            orderFilesPerBackground !== null
          ) {
            item.files.items = orderFilesPerBackgroundFE;
            updateBackgroundFilesOrder(orderFilesPerBackground);
          }
        });

        console.log("Set New Background", updateArrFiles);
        setBackground(updateArrFiles);
        autoAdjustRowHeight(SelectedIndex);
      });
    }, 2000);

    setalertMessage(`File has been added! Go to File bucket`);
    setShowToast(true);
    setGoToFileBucket(true);

    handleModalClose();
    setTimeout(() => {
      setShowToast(false);
      setGoToFileBucket(false);
    }, 5000);
    //getBackground();
    return "done";
  };

  const subscriptionRef = useRef(null);

  // async function processExtraction(checkBulkExtract, backgroundId) {
  //   if (!checkBulkExtract || checkBulkExtract.length === 0) {
  //     console.error("Invalid input: checkBulkExtract is null or empty");
  //     return;
  //   }

  //   const BULK_BACKGROUND_TEXTRACT_MUTATION = `
  //     mutation bulkBackgroundFileTextract($input: [BackgroundFileTextractInput]!) {
  //       bulkBackgroundFileTextract(input: $input)
  //     }`;

  //   const ON_TEXTRACT_PUBLISHED = `
  //     subscription onBackgroundFileTextractPublished($executionArn: String!) {
  //       onBackgroundFileTextractPublished(executionArn: $executionArn) {
  //         fileId
  //         extractedText
  //         executionArn
  //       }
  //     }
  //   `;

  //   const params = {
  //     query: BULK_BACKGROUND_TEXTRACT_MUTATION,
  //     variables: {
  //       input: checkBulkExtract,
  //     },
  //   };

  //   console.log("Params for GraphQL mutation:", params);

  //   try {
  //     const { data } = await API.graphql(params);

  //     if (data) {
  //       console.log("Preparing to extract text files", data);
  //       const parsed = JSON.parse(data.bulkBackgroundFileTextract);
  //       console.log("backgroundFileTextract", parsed);
  //       const body = JSON.parse(parsed.body);
  //       console.log("body", body);

  //       const executionArn = body.executionArn;
  //       console.log("executionArn", executionArn);

  //       subscriptionRef.current = API.graphql({
  //         query: ON_TEXTRACT_PUBLISHED,
  //         variables: { executionArn },
  //       }).subscribe({
  //         next: async ({ value }) => {
  //           const result = value?.data?.onBackgroundFileTextractPublished?.extractedText;

  //           if (!result || result === "ERROR IN TEXT EXTRACTION") {
  //             console.log("Error in text extraction");
  //           } else {
  //             console.log("Text extraction complete", result);
  //             const getSugegstedDate = await getSuggestedDates(result);
  //             console.log("getSugegstedDate >>> ", getSugegstedDate);
  //             if (getSugegstedDate !== "No date found") {
  //               await handleChangeDate(getSugegstedDate, backgroundId, "", 1);
  //             }
  //           }

  //           return result;
  //         },
  //         error: (error) => console.warn(error),
  //       });

  //       // return () => {
  //       //   if (subscriptionRef.current) subscriptionRef.current.unsubscribe();
  //       // };
  //     }
  //   } catch (error) {
  //     console.error("Error processing extraction", error);
  //   }
  // }

  async function getSuggestedDates(fileContent) {
    const endpoint = process.env.REACT_APP_CLAUDE_ENDPOINT;

    const requestBody = {
      model: process.env.REACT_APP_CLAUDE_MODEL,
      messages: [
        {
          role: "user",
          content: `Given the following document content, please identify and extract the most likely date associated with this document in a format on this example "Thu Jul 18 2024 00:00:00 GMT+0800 (Singapore Standard Time)"
        . If multiple dates are present, choose the one that seems most relevant to the document's creation or primary event and do not return anything but the date in that format. And If no clear date is found, respond with "No date found".  Here's the document content:\n\n${fileContent}`
        }
      ],
      max_tokens: 2000,
      temperature: 0
    };

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response Error:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Response Data:', data);

      if (data.content && data.content[0] && data.content[0].text) {
        console.log("will return >>", data.content[0].text.trim())

        return data.content[0].text.trim();

      }

    } catch (error) {
      console.error('Fetch error details:', error);
    }
  }

  async function processExtraction(checkBulkExtract, backgroundId, olddate, index, checkDateExists) {
    if (!checkBulkExtract || checkBulkExtract.length === 0) {
      console.error("Invalid input: checkBulkExtract is null or empty");
      return;
    }

    const BULK_BACKGROUND_TEXTRACT_MUTATION = `
      mutation bulkBackgroundFileTextract($input: [BackgroundFileTextractInput]!) {
        bulkBackgroundFileTextract(input: $input)
      }`

    const params = {
      query: BULK_BACKGROUND_TEXTRACT_MUTATION,
      variables: {
        input: checkBulkExtract,
      },
    };

    console.log("Params for GraphQL mutation:", params);

    try {
      const { data } = await API.graphql(params);

      if (data) {
        // console.log("Preparing to extract text files", data);
        const parsed = JSON.parse(data.bulkBackgroundFileTextract);
        // console.log("backgroundFileTextract", parsed);
        const body = JSON.parse(parsed.body);
        // console.log("body", body);

        const executionArn = body.executionArn;
        console.log("executionArn", executionArn);

        const response = await handleTextractSubscription(executionArn);

        let compiledResult = response.map((file) => file?.extractedFileContent || "").join(" ");

        console.log("Compiled Result", compiledResult)
        console.log("checkDateExists >>> ", checkDateExists);
        if (!checkDateExists) {
          const getSugegstedDate = await getSuggestedDates(compiledResult);
          console.log("getSugegstedDate >>> ", getSugegstedDate);
          if (getSugegstedDate !== "No date found") {
            await handleChangeDate(getSugegstedDate, backgroundId, olddate, index);
          }
        }

        return compiledResult;    
        
        // const subscription = await API.graphql({
        //   query: ON_TEXTRACT_PUBLISHED,
        //   variables: { executionArn },
        // }).subscribe({
        //   next: async ({ value }) => {
        //     const result = value?.data?.onBackgroundFileTextractPublished;
        //     console.log("Received text extraction response", result);

        //     if (
        //       resultArray.length === 0 &&
        //       result.nextIndex === -1 &&
        //       result.fileId === "[ERROR IN TEXTRACTION]"
        //     ) {
        //       console.error("Error in text extraction");
        //     } else {
        //       resultArray.push(result);

        //       if (result.nextIndex === -1) {
        //         console.log("Text extraction complete", resultArray);
        //         resultArray.push(result);

        //         let combinedArray =
        //           combineTextractSubscriptionResponse(resultArray);

        //         console.log("Combined Text Extraction array", combinedArray);

        //         let compiledResult = combinedArray.map((file) => file?.extractedFileContent || "").join(" ");

        //         console.log("Compiled Result", compiledResult)
        //         console.log("checkDateExists >>> ", checkDateExists);
        //         if (!checkDateExists) {
        //           const getSugegstedDate = await getSuggestedDates(compiledResult);
        //           console.log("getSugegstedDate >>> ", getSugegstedDate);
        //           if (getSugegstedDate !== "No date found") {
        //             await handleChangeDate(getSugegstedDate, backgroundId, olddate, index);
        //           }
        //         }

        //         if (subscription) subscription.unsubscribe();

        //         return compiledResult;    
        //       }
        //     }
        //   },
        //   error: (error) => console.warn(error),
        // });
      }
    } catch (error) {
      console.error("Error processing extraction", error);
    }
  }

  function combineTextractSubscriptionResponse(arr) {
    // Create a map to store combined file contents
    const fileMap = new Map();

    // Iterate through the array of objects
    arr.forEach(({ fileId, extractedFileContent, nextIndex }) => {
      // If fileId exists in the map, append the content
      if (fileMap.has(fileId)) {
        const fileData = fileMap.get(fileId);
        fileData.push({ extractedFileContent, nextIndex });
      } else {
        // Otherwise, add a new entry for the fileId
        fileMap.set(fileId, [{ extractedFileContent, nextIndex }]);
      }
    });

    // Combine the content for each fileId, placing the -1 (last element) at the end
    const result = [];
    fileMap.forEach((fileData, fileId) => {
      // Separate the last element
      const lastElement = fileData.find((item) => item.nextIndex === -1);
      // Filter out the last element and sort the remaining elements
      const sortedData = fileData
        .filter((item) => item.nextIndex !== -1)
        .sort((a, b) => a.nextIndex - b.nextIndex);

      // Combine all the contents, including the last element at the end
      let combinedContent = sortedData
        .map((item) => item.extractedFileContent)
        .join("");

      if (lastElement) {
        combinedContent += lastElement.extractedFileContent;
      }

      result.push({ fileId, extractedFileContent: combinedContent });
    });

    return result;
  }
  
  const getBulkExtractedTextContent = async (backgroundId, fileId) => {
    try {
      const {
        data: {
          backgroundFile: { extractedFileContent = "" },
        },
      } = await API.graphql({
        query: BACKGROUND_BY_BRIEF_ID_QUERY_EXTRACT,
        variables: {
          backgroundId: backgroundId,
          fileId: fileId,
        },
      });

      console.log("extracted file content >>> ", fileId);

      if (extractedFileContent) {
        return extractedFileContent;
      } else {
        console.log("No extracted content found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching extracted file contents:", error);
      return null;
    }
  };






  function createMatterFile(param) {
    //don't delete for single upload trial
    const request = API.graphql({
      query: mCreateMatterFile,
      variables: param,
    });

    console.log("result", request);
  }

  const mBulkCreateMatterFile = `
    mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
      matterFileBulkCreate(files: $files) {
        id
        name
        order
      }
    }
  `;

  async function bulkCreateMatterFile(param) {
    console.log("bulkCreateMatterFile");

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    setTimeout(async () => {
      const request = await API.graphql({
        query: mBulkCreateMatterFile,
        variables: {
          files: param,
        },
      });

      console.log("result", request);

      if (request.data.matterFileBulkCreate !== null) {
        request.data.matterFileBulkCreate.map((i) => {
          return (idTag = [...idTag, { id: i.id }]);
        });
      }

      console.log("iDTag", idTag);

      const BACKGROUNDFILE_TAG_MUTATION = `
      mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
          backgroundFileTag(backgroundId: $backgroundId, files: $files) {
            id
          }
        }
      `;

      //append in existing
      const qlistBackgroundFiles = `
    query getBackgroundByID($id: ID) {
      background(id: $id) {
        id
        date
        files {
          items {
            createdAt
            id
            details
            name
            gmailMessageId
            s3ObjectKey
            order
            size
          }
        }
        labels {
          items {
            id
            name
          }
        }
      }
    }`;

      let arrFiles = [];
      let arrFileResult = [];
      const seen = new Set();

      // console.log("MID/BID", background_id);

      const backgroundFilesOpt = await API.graphql({
        query: qlistBackgroundFiles,
        variables: {
          id: selectedRowId,
        },
      });

      console.log("CHECKDATA >> ", backgroundFilesOpt);

      if (backgroundFilesOpt.data.background.files !== null) {
        arrFileResult = backgroundFilesOpt.data.background.files.items.map(
          ({ id }) => ({
            id: id,
          })
        );

        idTag.push(...arrFileResult);
        console.log("updatedidtag", idTag);

        const filteredArr = idTag.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });

        console.log("rowid", selectedRowId);

        API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: selectedRowId,
            files: filteredArr,
          },
        });
      } else {
        API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: selectedRowId,
            files: idTag,
          },
        });
      }
    }, 1000);
    //return request;
  }

  const handleKeyUp = (e) => {
    if (e.key === "Shift" && isShiftDown) {
      setIsShiftDown(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Shift" && !isShiftDown) {
      setIsShiftDown(true);
    }
  };

  const handleSelectItem = (e, index, selectedItems) => {
    console.log("test");
    console.log("HANDLE SELECT ITEM SELECTED: ", selectedItems);
    const { value } = e.target;
    const nextValue = getNextValue(value);
    console.log("VALUE: ", value);
    setSelectedItems(nextValue);

    setLastSelectedItem(value);
    autoAdjustRowHeight(index);
    if (nextValue.length > 0) {
      const isf1 = background.filter((item) => nextValue.includes(item.id));
      const xBackground = isf1.map(({ id, date, description, files }) => ({
        id,
        date,
        description: description,
        files,
      }));
      setSelectRow(xBackground);
      setShowDeleteButton(true);
      setSrcIndex(index);

      const ids = xBackground.map(({ id }) => ({
        id,
        fileName: "",
      }));
      setSelectedRowsBG(ids);
      selectedRowsBGPass = ids;
    } else {
      setShowDeleteButton(false);
      setSelectRow([]);
      setSrcIndex("");
      setSelectedRowsBG([]);
      selectedRowsBGPass = [];
    }
    //autoAdjustRowHeight(index);
  };

  const getNextValue = (value) => {
    const hasBeenSelected = !selectedItems.includes(value);

    if (isShiftDown) {
      const newSelectedItems = getNewSelectedItems(value);

      const selections = [...new Set([...selectedItems, ...newSelectedItems])];

      if (!hasBeenSelected) {
        return selections.filter((item) => !newSelectedItems.includes(item));
      }

      return selections;
    }

    // if it's already in there, remove it, otherwise append it
    return selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value];
  };

  const getNewSelectedItems = (value) => {
    const currentSelectedIndex = background.findIndex(
      (item) => item.id === value
    );
    const lastSelectedIndex = background.findIndex(
      (item) => item.id === lastSelectedItem
    );

    return background
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
      )
      .map((item) => item.id);
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp, false);
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyUp, handleKeyDown]);

  // const handleAddRow = async () => {
  //   console.log("handleAddRow");
  //   const dateToday = moment
  //     .utc(moment(new Date(), "YYYY-MM-DD"))
  //     .toISOString();

  //   const mCreateBackground = `
  //       mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $briefs: [BriefInput]) {
  //         backgroundCreate(description: $description, comments: $comments, date: $date, briefs: $briefs) {
  //           id
  //         }
  //       }
  //   `;

  //   const tempBriefDetails = [{ id: briefId, name: briefName }];

  //   const createBackgroundRow = await API.graphql({
  //     query: mCreateBackground,
  //     variables: {
  //       briefs: tempBriefDetails,
  //       description: "",
  //       comments: "",
  //       date: null,
  //       brief: [],
  //     },
  //   });

  //   if (createBackgroundRow) {
  //     const result = {
  //       createdAt: dateToday,
  //       id: createBackgroundRow.data.backgroundCreate.id,
  //       description: "",
  //       date: null,
  //       order: 0,
  //       files: { items: [] },
  //     };

  //     let concatResult = background.concat(result);

  //     setBackground((background) => concatResult);

  //     const rowArrangement = concatResult.map(({ id }, index) => ({
  //       id: id,
  //       order: index + 1,
  //     }));

  //     const mUpdateBackgroundOrder = `
  //       mutation bulkUpdateBackgroundOrders($arrangement: [ArrangementInput]) {
  //         backgroundBulkUpdateOrders(arrangement: $arrangement) {
  //           id
  //           order
  //         }
  //       }`;

  //     if (rowArrangement && rowArrangement.length > 0) {
  //       const response = await API.graphql({
  //         query: mUpdateBackgroundOrder,
  //         variables: {
  //           arrangement: rowArrangement,
  //         },
  //       });
  //     }

  //     setcheckAllState(false);
  //     setCheckedState(new Array(concatResult.length).fill(false));
  //     setSelectedRowsBG([]);
  //     setShowDeleteButton(false);

  //     setBackground(concatResult);
  //   }
  // };

  function clearFocus() {
    document.activeElement.blur();
    console.log("cleared focus");
  }
  const handleAddRow = async (item) => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: briefId, name: briefName }];
      console.log(tempBriefDetails);

      // kjmf123
      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
          order: item.order + 1,
        },
      });

      if (createBackgroundRow) {
        const result = {
          createdAt: dateToday,
          id: createBackgroundRow.data.backgroundCreate.id,
          description: "",
          date: null,
          order: item.order + 1,
          files: { items: [] },
          labels: { items: [] },
          briefs: [{ id: briefId, name: briefName }],
        };

        setBackground((background) => sortByOrder(background.concat(result)));
        //Added for previouseBackground (Search Functionality)
        setPreviousBackground((background) =>
          sortByOrder(background.concat(result))
        );
        background.splice(item.order, 0, result);

        // Log Activity
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: "updated the background briefs",
            field: "Background",
            appModule: "BACKGROUND",
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });

        const rowArrangement = background.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement);

        // For FE purposes: Update existing background array with the updated order data
        background.forEach((item, index) => {
          item.order = index + 1;
        });

        setcheckAllState(false);
        setCheckedState(new Array(background.length).fill(false));
        setSelectedRowsBG([]);
        setShowDeleteButton(false);
        setBackground(background);
        //Added for previouseBackground (Search Functionality)
        setPreviousBackground(background);
        autoAdjustAllRow();
      }
    } catch (error) {
      console.error(error);
    }
  };

  function rowClicked(itemid) {
    const found = pastedRows.find(
      (element) => element.split("/")[0] === itemid
    );
    return found;
  }

  function rowOriginal(rowid) {
    const currRow = checkedRows.find(
      (element) => element.split("/")[0] === rowid
    );

    return currRow;
  }

  const [showUnviewableFileModal, setShowUnviewableFileModal] = useState(false);
  const [unviewableFileLink, setUnviewableFileLink] = useState("");

  const hoverEnterPreviewFile = async (
    rowId,
    fileId,
    isGmailPDF,
    gmailMessageId
  ) => {
    if (gmailMessageId !== null && gmailMessageId !== "") {
      const params = {
        query: EMAIL_PREVIEW,
        variables: {
          id: gmailMessageId,
        },
      };

      await API.graphql(params).then(async (result) => {
        console.log("EMAIL_PREVIEW", result);

        const newPayload = await getEmailPayload(gmailMessageId);

        const parsedEmail = EmailParser(
          JSON.parse(newPayload.payload[0].content)
        );

        setPreview(parsedEmail);
        setFrom(result.data.gmailMessage?.from);
        setDateEmail(result.data.gmailMessage?.date);
        setSubject(result.data.gmailMessage?.subject);
        setTo(result.data.gmailMessage?.to);
        setCC(result.data.gmailMessage?.cc);


        const params = {
          query: qGetFileDownloadLink,
          variables: {
            id: rowId,
          },
        };

        await API.graphql(params).then(async (result) => {
          const { type, downloadURL, s3ObjectKey } = result.data.file;
          setFileType(type);
          if (
            (type &&
              (type.includes("vnd.openxmlformats-officedocument") ||
                type.includes("application/msword"))) ||
            isMSdoc(s3ObjectKey)
          ) {
            console.log("")
            setSnippetId(rowId + "-" + fileId);
            setShow(true);

            var encodedUrl = encodeURIComponent(downloadURL);
            var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
            setDownloadLink(documentViewerUrl);
            setIframeLoad(true);
            setShowFilePreview(true);
          } else if (type.includes("image") || type.includes("pdf")) {
            setSnippetId(rowId + "-" + fileId);
            setShow(true);

            setDownloadLink(downloadURL);
            setIframeLoad(true);
            setShowFilePreview(true);
          } else {
            setUnviewableFileLink(downloadURL);
            setShowUnviewableFileModal(true);
          }

        });
      });

    } else {
      const params = {
        query: qGetFileDownloadLink,
        variables: {
          id: rowId,
        },
      };

      await API.graphql(params).then(async (result) => {
        const { type, downloadURL, s3ObjectKey } = result.data.file;
        setFileType(type);
        console.log("FileType::", type);
        if (
          (type &&
            (type.includes("vnd.openxmlformats-officedocument") ||
              type.includes("application/msword"))) ||
          isMSdoc(s3ObjectKey)
        ) {
          setSnippetId(rowId + "-" + fileId);
          setShow(true);

          var encodedUrl = encodeURIComponent(downloadURL);
          var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
          setDownloadLink(documentViewerUrl);
          setIframeLoad(true);
          setShowFilePreview(true);
        } else if (type.includes("image") || type.includes("pdf")) {
          setSnippetId(rowId + "-" + fileId);
          setShow(true);

          setDownloadLink(downloadURL);
          setIframeLoad(true);
          setShowFilePreview(true);
        } else {
          setUnviewableFileLink(downloadURL);
          setShowUnviewableFileModal(true);
        }

      });
    }
    // setFilePreviewItems([parentItem, items, iframeVariables]);
    // setShowFilePreview(true)
    //setShow(true)
  };

  const hoverClosePreviewFile = () => {
    setSnippetId(null);
    setShow(false);
    setDownloadLink("");
    setFrom(null);
    setDateEmail(null);
    setSubject(null);
    setTo(null);
    setCC(null);
    setPreview([]);
    setShowFilePreview(false)
  };

  //labels
  const { matter_id } = useParams();

  const BACKGROUND_TABLE_QUERY = `
  query getBackgroundTable($clientMatterId: ID!) {
      backgroundTable(clientMatterId: $clientMatterId) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
          presets {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }
  `;

  const listBriefs = `
  query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          labelId
        }
      }
    }
  }
  `;

  const getLabels = async () => {
    var labelsList2 = [];

    const params = {
      query: listBriefs,
      variables: {
        id: matter_id,
        limit: 100,
        nextToken: null,
      },
    };

    await API.graphql(params).then((brief) => {
      const briefsList = brief.data.clientMatter.briefs.items;
      for (var i = 0; i < briefsList.length; i++) {
        labelsList2 = [
          ...labelsList2,
          { label: briefsList[i].name, value: briefsList[i].id },
        ]; //name and briefid
      }

      if (isSiteDiary) {
        let labelsList3 = removeHBALabels(labelsList2);
        setBriefList(labelsList3);
        setLabels(labelsList3);
      } else {
        console.log(labelsList2);
        setBriefList(labelsList2);
        setLabels(labelsList2);
      }
    });
  };

  function removeHBALabels(objArray) {
    let removeLabels = [
      "Progress of Work",
      "Project Location",
      "Builder Details",
      "Contract Details",
    ];
    var result = objArray?.filter(
      (obj) => removeLabels.indexOf(obj.label) === -1
    );

    return result;
  }

  const tagLabel = async (evt, action, bgidd, content, briefs) => {
    const origBriefs = briefs;
    console.log(action);
    if (action.action === "select-option") {
      // Update Briefs Row
      console.log(origBriefs);
      var updatedBriefsArr = evt.map(function (item) {
        return { id: item.value, name: item.label };
      });
      // Tag Briefs to the old background
      await API.graphql({
        query: mUpdateBrief,
        variables: {
          id: bgidd,
          briefs: updatedBriefsArr,
        },
      }).then(async (result) => {
        console.log("res tagged brief", result);
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: "updated the background briefs",
            field: "Brief",
            current: updatedBriefsArr,
            previous: origBriefs,
            appModule: "BACKGROUND",
            rowId: bgidd,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });
      });

      // Optimized version of the code
      //var filterBriefArr = evt.map(item => item.value).join('');
      const selectedItem = action.option;

      if (briefId !== selectedItem.value) {
        const tempBriefDetails = [
          { id: selectedItem.value, name: selectedItem.label },
        ];
        console.log(tempBriefDetails);

        const createBackgroundRow = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: tempBriefDetails,
            comments: "",
            description: content.description,
            date: content.date,
          },
        });

        console.log(createBackgroundRow);

        // Tag Briefs to the new background row
        // console.log(updatedBriefsArr);
        // await API.graphql({
        //   query: mUpdateBrief,
        //   variables: {
        //     id: createBackgroundRow.data.backgroundCreate.id,
        //     briefs: updatedBriefsArr,
        //   },
        // }).then((result) => {
        //   console.log("res tagged brief", result);
        // });

        // Tag files to background

        const arrFileResult = content.files.items.map(({ id }) => ({
          id: id,
        }));

        await API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: createBackgroundRow.data.backgroundCreate.id,
            files: arrFileResult,
          },
        }).then((result) => {
          console.log("res tagged files", result);
        });
      }
    } else if (action.action === "remove-value") {
      // Update Briefs Row
      var updatedBriefsArr = evt.map(function (item) {
        return { id: item.value, name: item.label };
      });

      await API.graphql({
        query: mUpdateBrief,
        variables: {
          id: bgidd,
          briefs: updatedBriefsArr,
        },
      }).then((result) => {
        console.log("res tagged brief", result);
      });
    } else if (action.action === "create-option") {
      console.log("Value for created labels", action.option.value);
      console.log("Value for created matter", matter_id);

      if (isSiteDiary) {
        let removeLabels = [
          "Progress of Work",
          "Project Location",
          "Builder Details",
          "Contract Details",
        ];
        if (
          removeLabels.includes(action.option.label) ||
          removeLabels.includes(action.option.value)
        ) {
          alert("You can't put that as a label");
          return;
        }
      }
      await API.graphql({
        query: mCreateBrief,
        variables: {
          clientMatterId: matter_id,
          name: action.option.value,
          date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
          order: 0,
          columnIds: defaultColumnIds,
        },
      }).then(async (r) => {
        console.log("Create Labels - ", r);
        const newLabelId = r.data.briefCreate.id;
        const newLabelName = r.data.briefCreate.name;

        const tempBriefDetails = [{ id: newLabelId, name: newLabelName }];

        const backgroundTable = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj.presets.filter((preset) => preset.id !== newLabelId),
                { id: newLabelId, name: newLabelName },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });

          console.log("updateBriefTable", updateBriefTable);
        }

        var updatedBriefsArr = evt.map(function (item) {
          return { id: item.value, name: item.label };
        });

        await API.graphql({
          query: mUpdateBrief,
          variables: {
            id: bgidd,
            briefs: updatedBriefsArr,
          },
        }).then((result) => {
          console.log("res tagged brief", result);
        });

        const createBackgroundRow = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: tempBriefDetails,
            comments: "",
            description: content.description,
            date: content.date,
            files: content.files,
          },
        });

        // Tag Briefs to the new background row
        await API.graphql({
          query: mUpdateBrief,
          variables: {
            id: createBackgroundRow.data.backgroundCreate.id,
            briefs: updatedBriefsArr,
          },
        }).then((result) => {
          console.log("res tagged brief", result);
        });

        // Tag files to background

        const arrFileResult = content.files.items.map(({ id }) => ({
          id: id,
        }));

        await API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: createBackgroundRow.data.backgroundCreate.id,
            files: arrFileResult,
          },
        }).then((result) => {
          console.log("res tagged files", result);
          getLabels();
        });
      });
    }
    // getBackground("Tag Label get Background");
  };

  const mCreateLabel = `
    mutation createLabel($clientMatterId: String, $name: String) {
        labelCreate(clientMatterId:$clientMatterId, name:$name) {
            id
            name
        }
    }
  `;

  const mCreateBrief = `
  mutation createLabel($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }
  `;

  const listLabels = `
  query listLabels($clientMatterId: ID) {
    clientMatter(id: $clientMatterId) {
      labels {
        items {
          id
          name
        }
      }
    }
  }
`;

  const mUpdateBrief = `
  mutation tagBriefToBackground($id: ID, $briefs: [BriefInput]) {
    backgroundUpdate(id: $id, briefs: $briefs) {
      id
    }
  }
  `;

  function getRowLabels(rowLabels) {
    // console.log("rowlabels", rowLabels);
    var temp = [];

    if (rowLabels) {
      if (rowLabels.length > 0) {
        rowLabels.map((x) => {
          temp = [...temp, { label: getMinimizedLabel(x.name), value: x.id }];
          //ADDED FUNCTION TO MINIMIZE LABEL
        });

        // console.log("tempp", temp);
        return temp;
      } else {
        return [];
      }
    } else {
      return [];
    }

    // }
  }

  //MINIMIZES LABEL NAME TO 22 CHARACTERS
  function getMinimizedLabel(inputLabel) {
    return inputLabel && inputLabel.length > 23
      ? inputLabel.substring(0, 22) + "..."
      : inputLabel;
  }

  const longDescBriefsNameList = [
    "Site Diary",
    "Builder Details",
    "Development Approvals",
    "Tenders",
    "Building Permits",
    "Warranties and Certifications",
    "Work Safety Compliance",
    "Defects List",
    "Insurance",
    "Certificates",
  ];

  function getDescColMinWidth() {
    if (!longDescBriefsNameList.includes(briefName)) {
      return 400;
    } else {
      return 700;
    }
  }

  //MAKES DESC COLUMN FIXED SIZE DEPENDENT ON VIEW
  function getDescColMaxWidth() {
    if (width > 965.263) {
      if (!longDescBriefsNameList.includes(briefName)) {
        return 400;
      } else {
        return width - 900.263;
      }
    } else {
      return 200;
    }
  }

  const convertDate = (itemDate) => {
    if (itemDate !== null || itemDate !== "null" || itemDate !== "") {
      try {
        return new Date(moment.utc(new Date(itemDate)).local().format());
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  };

  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  // ChatGPT Function
  // const [engine, setEngine] = useState([{ value: "", label: "Select Engine" }]);
  const [loaderEmail, setLoaderEmail] = useState(null);

  const handleSaveCopyPromptDesc = async (id, val, index) => {
    const data = {
      description: val,
    };

    const success = await updateBackgroundDesc(id, data);
    if (success) {
      setalertMessage("Successfully updated.");
      setShowToast(true);

      const newArrDescription = background.map((bg) => {
        if (bg.id === id) {
          return {
            ...bg,
            description: val,
          };
        }
        return bg;
      });
      let clone = [...rightPanelItems];
      clone[0].description = val;
      setRightPanelItems(clone);
      setBackground(newArrDescription);
      //Added for previouseBackground (Search Functionality)
      setPreviousBackground(newArrDescription);
      autoAdjustRowHeight(index);
    }
    setTimeout(() => {
      setLoaderEmail(null);
      setShowToast(false);
    }, 1000);
  };

  const handleSavePromptDesc = async (id, val, index, newPromptHistory) => {
    const data = {
      description: val,
      promptHistory: newPromptHistory,
    };

    const success = await updateBackgroundDescAndHistory(id, data);
    if (success) {
      setalertMessage("Successfully updated.");
      setShowToast(true);

      const newArrDescription = background.map((bg) => {
        if (bg.id === id) {
          return {
            ...bg,
            description: val,
            promptHistory: newPromptHistory,
          };
        }
        return bg;
      });

      setBackground(newArrDescription);
      //Added for previouseBackground (Search Functionality)
      setPreviousBackground(newArrDescription);
      autoAdjustRowHeight(index);
    }
    setTimeout(() => {
      setLoaderEmail(null);
      setShowToast(false);
    }, 1000);
  };

  const handleSavePrompt = async (id, index, newPromptHistory, noToast) => {
    const data = {
      promptHistory: newPromptHistory,
    };

    const success = await updateBackgroundHistory(id, data);
    if (success) {
      if (!noToast) {
        setalertMessage("Successfully updated History Log.");
        setShowToast(true);
      }
      const newArrDescription = background.map((bg) => {
        if (bg.id === id) {
          return {
            ...bg,
            promptHistory: newPromptHistory,
          };
        }
        return bg;
      });

      setBackground(newArrDescription);
      //Added for previouseBackground (Search Functionality)
      setPreviousBackground(newArrDescription);
      autoAdjustRowHeight(index);
    }
    if (!noToast) {
      setTimeout(() => {
        setLoaderEmail(null);
        setShowToast(false);
      }, 1000);
    }
  };

  const handleCopyToBackground = async (
    item,
    id,
    message,
    index,
    promptObj
  ) => {
    console.group("handleCopyToBackground()");
    console.log("item:", item);
    console.log("id:", id);
    console.log("message:", message);
    console.log("index:", index);
    const backgroundDesc = background.filter((item) => item.id === id);
    let latestDesc = backgroundDesc[0]?.description;
    const origDescription = handleDescription(latestDesc, index);
    console.log("promptObj:", promptObj);
    console.groupEnd();
    try {
      //Copy To Background
      let itemCopy =
        latestDesc !== null && latestDesc.includes("Enter some text...")
          ? `${latestDesc.replace("Enter some text...", "")} \n \n${message}`
          : `${latestDesc === null ? "" : latestDesc} \n \n${message}`;
      console.log("ITEM COPY:", itemCopy);
      setLoaderEmail(id);
      await handleSaveCopyPromptDesc(id, itemCopy, index).then(async () => {
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: "updated the background description",
            field: "Description",
            current: message,
            previous: origDescription,
            appModule: "BACKGROUND",
            rowId: id,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });
      });
    } catch (err) {
      console.error(err);
      setLoaderEmail(null);
    } finally {
      setLoaderEmail(null);
    }
  };

  const createPrompt = `
    mutation createPrompt($companyId: ID, $clientMatterId: ID, $name: String, $prompt: String, $description: String) {
      openAIPromptsCreate(
        companyId: $companyId
        clientMatterId: $clientMatterId
        name: $name
        prompt: $prompt
        description: $description
      ) {
        id
        companyId
        name
        description
        prompt
      }
    }
  `;

  const handleCopyToMemory = async (
    item,
    id,
    memory,
    index,
    promptObj
  ) => {
    console.group("handleCopyToMemory()");
    const { response, prompt, name } = memory;
    console.log("item:", item);
    console.log("id:", id);
    console.log("memory:", memory);
    console.log("index:", index);
    console.log("promptObj:", promptObj);
    try {
      //Copy To Mmeory function
      const newCoreMemoryRes = await API.graphql({
        query: createPrompt,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
          name,
          prompt,
          description: response,
        },
      });

      const newCoreMemory = newCoreMemoryRes.data.openAIPromptsCreate;
      setCoreMemory((prevPrompts) => [...prevPrompts, newCoreMemory]);

    } catch (err) {
      console.error(err);
    }
  };

  function addPromptHistory(item, val, promptObj, fileName, fileId, aiApiResultId = null, aiApiTokenUsage = null) {
    const { promptHistory } = item;
    const { name, prompt } = promptObj;
    let promptHistoryParsed = promptHistory ? JSON.parse(promptHistory) : {};

    let lastKey = Object.keys(promptHistoryParsed).length;
    let newPromptHistory = JSON.stringify({
      ...promptHistoryParsed,
      [lastKey]: {
        name,
        prompt,
        date: Math.floor(Date.now() / 1000),
        response: val,
        fileName,
        fileId,
        aiApiResultId: aiApiResultId,
        aiApiTokenUsage: aiApiTokenUsage,
      },
    });
    return newPromptHistory;
  }

  const handleOpenAI = async (item, index, gmailMessageId, promptObj) => {
    const { id, description } = item;

    const origDescription = handleDescription(description, index);
    let message = "";
    let promptHistory;
    console.log("Description::", description);
    console.log("GmailMessageId::", gmailMessageId);

    let promptEmail = await getEmailPayload(gmailMessageId);

    console.log("Prompt Email::", promptEmail.payload);

    if (promptEmail.payload) {
      const parsedEmail = EmailParser(
        JSON.parse(promptEmail.payload[0].content)
      );
      promptEmail = parsedEmail.text;
      console.log("IF Parsed Email::", promptEmail);
    } else {
      promptEmail = origDescription;
      console.log("ELSE Parsed Email::", promptEmail);
    }

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 3000;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (promptEmail !== "") {
      setLoaderEmail(id);
      // Use template literals for instruction
      const MAX_SENTENCES = 5;
      const MAX_WORDS_PER_SENTENCE = 20;

      // Split the email into sentences
      const sentences = promptEmail?.split(/[.!?]/);

      // Filter and limit the sentences
      const filteredSentences = sentences?.filter(
        (sentence) => sentence?.trim() !== ""
      ); // Remove empty sentences

      // Join the filtered sentences to create the limited email content
      const limitedEmailContent = filteredSentences?.join(". ");

      const instruction = `${promptObj.prompt}\n\n${limitedEmailContent}`;

      console.log("Content::", instruction);

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = await response.json();
        console.log("Data", data);
        const choices = data.choices;
        console.log("Choices", choices);

        message = choices[0]?.message?.content
          ?.trim()
          .replace(/(\n)+$/, "")
          .replace(/\s+/g, " ");

        promptHistory = addPromptHistory(item, message, promptObj);
        // Destructure choices directly in the function call
        await handleSavePrompt(id, index, promptHistory).then(async () => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: briefId,
              activity: "updated the background history log",
              field: "History Log",
              current: description,
              previous: origDescription,
              appModule: "BACKGROUND",
              rowId: id,
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
          });
        });

        // if (rightPanelItems?.length !== 0) {
        //   let clone = [...rightPanelItems];
        //   clone[0].description = message;
        //   setRightPanelItems(clone);
        // }
      } catch (error) {
        console.error("Error", error);
        message = "Error encounted during summarization. Please try again.";
        promptHistory = addPromptHistory(item, message, promptObj);
        await handleSavePrompt(id, index, promptHistory);
      } finally {
        setLoaderEmail(null);
      }
    } else {
      setLoaderEmail(null);
    }
    return { message, promptHistory };
  };

  const handleOpenAISummaryLogs = async (
    item,
    index,
    gmailMessageId,
    promptObj
  ) => {
    const { id, description } = item;

    const origDescription = handleDescription(description, index);
    let message = "";
    let promptHistory;
    console.log("Description::", description);
    console.log("GmailMessageId::", gmailMessageId);

    let promptEmail = await getEmailPayload(gmailMessageId);

    console.log("Prompt Email::", promptEmail.payload);

    if (promptEmail.payload) {
      const parsedEmail = EmailParser(
        JSON.parse(promptEmail.payload[0].content)
      );
      promptEmail = parsedEmail.text;
      console.log("IF Parsed Email::", promptEmail);
    } else {
      promptEmail = origDescription;
      console.log("ELSE Parsed Email::", promptEmail);
    }

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 3000;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (promptEmail !== "") {
      setLoaderEmail("summarry");
      // Use template literals for instruction
      const MAX_SENTENCES = 5;
      const MAX_WORDS_PER_SENTENCE = 20;

      // Split the email into sentences
      const sentences = promptEmail?.split(/[.!?]/);

      // Filter and limit the sentences
      const filteredSentences = sentences?.filter(
        (sentence) => sentence?.trim() !== ""
      ); // Remove empty sentences

      // Join the filtered sentences to create the limited email content
      const limitedEmailContent = filteredSentences?.join(". ");

      const instruction = `${promptObj.prompt}\n\n${limitedEmailContent}`;

      console.log("Content::", instruction);

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = await response.json();
        console.log("Data", data);
        const choices = data.choices;
        console.log("Choices", choices);

        message = choices[0]?.message?.content
          ?.trim()
          .replace(/(\n)+$/, "")
          .replace(/\s+/g, " ");

        promptHistory = addPromptHistory(item, message, promptObj);
        // Destructure choices directly in the function call
        await handleSavePrompt(id, index, promptHistory).then(async () => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: briefId,
              activity: "updated the background history log",
              field: "Description",
              current: description,
              previous: origDescription,
              appModule: "BACKGROUND",
              rowId: id,
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
          });
        });

        // if (rightPanelItems?.length !== 0) {
        //   let clone = [...rightPanelItems];
        //   clone[0].description = message;
        //   setRightPanelItems(clone);
        // }
      } catch (error) {
        console.error("Error", error);
        message = "Error encounted during summarization. Please try again.";
        promptHistory = addPromptHistory(item, message, promptObj);
        await handleSavePrompt(id, index, promptHistory);
      } finally {
        setLoaderEmail(null);
      }
    } else {
      setLoaderEmail(null);
    }
    return { message, promptHistory };
  };

  const handleSuggestedPrompts = async (
    item,
    index,
    gmailMessageId,
    promptObj
  ) => {
    const { id, description } = item;

    let message = "";
    let promptHistory;

    let promptEmail = await getEmailPayload(gmailMessageId);

    if (promptEmail.payload) {
      const parsedEmail = EmailParser(
        JSON.parse(promptEmail.payload[0].content)
      );
      promptEmail = parsedEmail.text;
    }

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 3000;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (promptEmail !== "") {
      // Split the email into sentences
      const sentences = promptEmail?.split(/[.!?]/);

      // Filter and limit the sentences
      const filteredSentences = sentences?.filter(
        (sentence) => sentence?.trim() !== ""
      ); // Remove empty sentences

      // Join the filtered sentences to create the limited email content
      const limitedEmailContent = filteredSentences?.join(". ");

      const instruction = `${promptObj.prompt}\n\n${limitedEmailContent}`;

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = await response.json();
        const choices = data.choices;

        message = choices[0]?.message?.content
          ?.trim()
          .replace(/(\n)+$/, "")
          .replace(/\s+/g, " ");
      } catch (error) {
        console.error("Error", error);
      }
    }

    return { message, promptHistory };
  };

  // Summarise Files Extracted

  const handleSummariseFiles = async (text, promptObj, memory) => {
    let message = "";
    let aiApiResultId = "";
    let aiApiTokenUsage = [];

    const memoryContext = memory
    ?.filter(item => item?.prompt && item?.prompt?.trim() !== "")
    .map(item => item?.prompt);

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 3000;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (text !== "") {
      // Split the email into sentences
      const sentences = text?.split(/[.!?]/);

      // Filter and limit the sentences
      const filteredSentences = sentences?.filter(
        (sentence) => sentence?.trim() !== ""
      ); // Remove empty sentences

      // Join the filtered sentences to create the limited email content
      const limitedEmailContent = filteredSentences?.join(". ");

      //KJMF ADDED ALTERNATE PROMPTS
      // const instruction = `For context, I am creating a case chronology or affidavit and I want you to consider the following case overview: ${memoryContext}.\n\n${promptObj.prompt}\n\n${limitedEmailContent}`;
      const instruction = `${promptObj.prompt}\n\n${limitedEmailContent}\n\n
      Please consider the following context, if needed, when making a summary for the above:
      ${memoryContext}.\n\n`;
      //const instruction = `${promptObj.prompt}\n\n${limitedEmailContent}\n\nI am creating an affidavit or a case Chronology so consider the following context in giving your summary: ${memoryContext}.\n\n`;

      console.log("Instruction >>>>", instruction)

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = await response.json();
        const choices = data.choices;

        aiApiResultId = data.id;
        aiApiTokenUsage = data.usage;

        message = choices[0]?.message?.content
          ?.trim()
          .replace(/(\n)+$/, "")
          .replace(/\s+/g, " ");

      } catch (error) {
        console.error("Error", error);
        return { error };
      }
    }

    return { message, aiApiResultId, aiApiTokenUsage  };
  };

  const convertHTMLEntity = function (text) {
    const sp = document.createElement("span");

    if (!text) return "";

    const output = text
      ?.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        sp.innerHTML = entity;
        return sp.innerText;
      })
      .replace(/<br ?\/?>/g, "\n")
      .replace(/(<([^>]+)>)/gi, "");

    return output;
  };

  const chronologicalOrder = (files) => {
    files?.sort((file1, file2) => {
      const number1 = parseInt(file1?.name?.match(/\d+/));
      const number2 = parseInt(file2?.name?.match(/\d+/));

      const noNumbers1 = isNaN(number1);
      const noNumbers2 = isNaN(number2);

      if (noNumbers1 && noNumbers2) {
        // If both file names have no numbers, sort them alphabetically
        return file1?.name?.localeCompare(file2?.name);
      } else if (noNumbers1) {
        return 1;
      } else if (noNumbers2) {
        return -1;
      }

      return number1 - number2;
    });

    return files;
  };

  useEffect(() => {
    // if (columns && columns?.length > 0) {
    const sortedTaggedCols = sortByOrder(columns);
    // Check if the value of columns has changed
    if (JSON.stringify(sortedTaggedCols !== JSON.stringify(taggedColumns))) {
      setTaggedColumns(sortedTaggedCols);
    }
    // }

  }, [columns, background, taggedColumns]);

  useEffect(() => {
    // Clone the background array
    const updatedBackground = cloneDeep(background);

    updatedBackground.forEach((item) => {
      const orderFilesPerBackground = item?.files?.items
        ?.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }))
        ?.sort((a, b) => a.order - b.order);

      const orderFilesPerBackgroundFE = item?.files?.items
        ?.map(
          (
            {
              id,
              details,
              gmailMessage,
              gmailMessageId,
              isGmailAttachment,
              isGmailPDF,
              name,
              s3ObjectKey,
              order,
            },
            index
          ) => ({
            id: id,
            name: name,
            details: details,
            gmailMessage: gmailMessage,
            gmailMessageId: gmailMessageId,
            isGmailAttachment: isGmailAttachment,
            isGmailPDF: isGmailPDF,
            s3ObjectKey: s3ObjectKey,
            order: index + 1,
          })
        )
        ?.sort((a, b) => a.order - b.order);

      if (
        orderFilesPerBackground !== undefined &&
        orderFilesPerBackground !== null
      ) {
        // Update item.files.items with the latest order
        item.files.items = orderFilesPerBackgroundFE;
        //updateBackgroundFilesOrder(orderFilesPerBackground);
      }
    });
  }, [background]);

  // QUICK SEARCH CODE BLOCKS
  var hitsPerRow = useRef([]);
  useEffect(() => {
    if (typeof qSearchValue !== "undefined" || qSearchValue != "") {
      let totalTemp = 0;
      let bgLen = background.length;
      hitsPerRow.current = [...Array(bgLen).keys()].map((i) => 0);
      //setTotalHits(0);
      for (let i = 0; i < bgLen; i++) {
        let currHitsLen = background[i]?.description?.match(
          new RegExp(qSearchValue, "g")
        )?.length;
        if (
          typeof currHitsLen != "undefined" &&
          qSearchValue != "" &&
          typeof qSearchValue !== "undefined"
        ) {
          hitsPerRow.current[i] = currHitsLen;
          totalTemp += currHitsLen;
        }
      }
      setTotalHits(totalTemp);
      if (totalTemp > 0 && qSearchValue != "") {
        setQSearchPtr(1);
        prevRow.current = 0;
        // for (let j = 0; j < bgLen; j++) {
        //   if (hitsPerRow.current[j] > 0) {
        //     console.log("scroll",j);
        //     bindList?.current?.scrollToRow(j);
        //     break
        //   }
        // }
      }

      if (totalTemp == 0) {
        setQSearchPtr(0);
      }
      // if (totalHits == 0) {
      //   setQSearchPtr(0);
      // }
      //console.log(hitsPerRow.current);
    }
  }, [qSearchValue, background]);

  useEffect(() => {
    for (let j = 0; j < background.length; j++) {
      if (hitsPerRow.current[j] > 0) {
        console.log("scroll2", j);
        bindList?.current?.scrollToRow(j);
        break;
      }
    }
  }, [totalHits]);

  useEffect(() => {
    if (background.length > 0) {
      let subtotalTemp = qSearchPtr;
      let row = 0;
      if (qSearchPtr > 0) {
        for (let i = 0; i < hitsPerRow.current.length; i++) {
          subtotalTemp -= hitsPerRow.current[i];
          if (subtotalTemp <= 0) {
            row = i;
            inRow.current = [i, hitsPerRow.current[i] - subtotalTemp * -1 - 1];
            console.log("INROW=>", inRow.current);
            break;
          }
        }

        let duration = 3000;
        bindList?.current?.scrollToRow(row);
        setTimeout(() => {
          bindList.current?.scrollToRow(row);
        }, 1000);
        if (prevRow.current - row > 10) {
          setTimeout(() => {
            bindList.current?.scrollToRow(row);
          }, 2000);
        }

        let iter = Math.ceil(Math.abs(prevRow.current - row) / 30);
        for (let i = 0; i < iter; i++) {
          setTimeout(() => {
            bindList.current?.scrollToRow(row);
          }, duration);
          duration += 800;
        }
        prevRow.current = row;
      }
    }
  }, [qSearchPtr, hitsPerRow.current]);

  const handleDynamicChange = (e) => {
    setInputDynamic(e.target.value);
    console.log(e.target.value);
  };

  const updateDynamicData = async (type, accessorKey, value, row, id) => {
    console.log("ACCESSORKEY", accessorKey);
    console.log("TYPE", type);
    console.log("ROW", row);
    console.log("ID", id);
    console.log("VALUE", value);
    const tempParsed =
      typeof row.dynamicData === "string" && row.dynamicData !== null
        ? JSON.parse(row.dynamicData)
        : row.dynamicData || {};

    const tempDynamic = { ...tempParsed, [accessorKey]: value };
    console.log("ROW", row);
    console.log("tempDynamic", tempDynamic);

    const data =
      type === "DATE" ? { date: moment.utc(value).toISOString() || null } : {};
    console.log("DATE", data);
    if (type === "DATE") {
      tempDynamic[accessorKey] = moment.utc(data.date).toISOString();
    }

    const clonedRow = { ...row, dynamicData: { ...tempDynamic } }; // Deep clone dynamicData

    const updateData = await API.graphql({
      query: mUpdateBackgroundDynamicData,
      variables: {
        id: id,
        dynamicData: JSON.stringify(clonedRow.dynamicData), // Pass cloned dynamicData
      },
    }).then((result) => {
      console.log(
        "SUCCESS CHANGING DYNAMIC DATA",
        JSON.parse(result.data.backgroundUpdate.dynamicData)
      );

      const updatedBackground = background.map((x) =>
        x.id === id
          ? { ...x, dynamicData: result.data.backgroundUpdate.dynamicData }
          : x
      );

      console.log("Updated background", updatedBackground);
      getBackground("Update Dynamic Data");
      // setBackground(updatedBackground);

      // updatedBackground.forEach(item => {
      // 	// Parse dynamicData string into an object
      // 	const dynamicDataObject = JSON.parse(item.dynamicData);

      // 	// Iterate over the keys of dynamicDataObject
      // 	for (const key in dynamicDataObject) {
      // 	  if (dynamicDataObject.hasOwnProperty(key)) {
      // 		const value = parseFloat(dynamicDataObject[key]);
      // 		if (!isNaN(value)) {
      // 		  if (key === '12') {
      // 			sumBudgetAmount += value;
      // 		  } else if (key === '13') {
      // 			sumCostAmount += value;
      // 		  }
      // 		}
      // 	  }
      // 	}
      //   });

      //   console.log("Total Budget Amount:", sumBudgetAmount);
      //   console.log("Total Cost Amount:", sumCostAmount);

      //   setTotalBudgetAmount(sumBudgetAmount);
      //   setTotalCostAmount(sumCostAmount);
    });
    // Update table header of variations
    setUpdatedContent(false);
  };

  const [people, setPeople] = useState();
  const companyId = localStorage.getItem("companyId");
  const qGetTeams = `
  query getTeamsByCompany($id: String) {
    company(id: $id) {
      teams {
        items {
          id
          name
        }
      }
    }
  }
    `;

  const qGetTeamsWithMembers = `
  query getTeamMembers($id: ID) {
    team(id: $id) {
      id
      name
      members {
        items {
          user {
            id
            firstName
            lastName
            userType
            profilePicture
          }
        }
      }
    }
  }`;

  const COMPANY_USERS_QUERY = `
query companyUsers($id: String!){
  company(id: $id){
    users {
      items {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
}
`;

  async function fetchCompanyUsers() {
    //change the company id by the organzation you want to filter
    //if(companyId === "ee1b8bba-8bc1-4bfb-ad01-37db9e05d51e"){ //develop companyid
    // if(companyId === "c45f62bb-e91d-482d-9ddc-48cfebbb22b1"){ //uat companyid
    if (companyId === "7a7d3c83-4cb3-49cb-acef-82b7b71054eb") {
      //master companyid
      const res = await API.graphql({
        query: qGetTeams,
        variables: {
          id: companyId,
        },
      });

      var teamNames = res.data.company.teams.items;
      var specialTeamId = "";

      /* Finding the team id of the team named "Operations" */
      for (var i = 0; i < teamNames.length; i++) {
        if (teamNames[i].name?.toLowerCase() === "operations") {
          specialTeamId = teamNames[i].id;
          break;
        } else {
          specialTeamId = "0"; //placeholder
        }
      }

      if (specialTeamId === "0") {
        const { data } = await API.graphql({
          query: COMPANY_USERS_QUERY,
          variables: {
            id: companyId,
          },
        });

        const usersFullNames = data.company.users.items.map(
          ({ firstName, lastName, profilePicture }) => {
            return { firstName, lastName, profilePicture };
          }
        );
        setPeople(usersFullNames); //set regular list as options, null catcher
      } else {
        const res1 = await API.graphql({
          query: qGetTeamsWithMembers,
          variables: {
            id: specialTeamId,
          },
        });

        var members = res1.data.team.members.items;
        var memberArray = [];

        for (var j = 0; j < members.length; j++) {
          memberArray = [
            ...memberArray,
            {
              firstName: members[j].user.firstName,
              lastName: members[j].user.lastName,
              profilePicture: members[j].user.profilePicture,
            },
          ];
        }

        setPeople(memberArray); //filtered people
      }
    } else {
      const { data } = await API.graphql({
        query: COMPANY_USERS_QUERY,
        variables: {
          id: companyId,
        },
      });

      const usersFullNames = data.company.users.items.map(
        ({ firstName, lastName, profilePicture }) => {
          return { firstName, lastName, profilePicture };
        }
      );
      setPeople(usersFullNames); //set regular list as options
    }
  }

  const qGetFilesByMatter = `
query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
  matterFiles(isDeleted: $isDeleted, matterId: $matterId, nextToken: $nextToken, limit: $limit, sortOrder: $sortOrder) {
    items {
      id
      name
      type
    }
  }
}
`;

  const [fileTypeCollection, setFileTypeCollection] = useState([]);

  let getMatterFiles = async () => {
    const params = {
      query: qGetFilesByMatter,
      variables: {
        matterId: matter_id,
        isDeleted: false,
        nextToken: null,
        sortOrder: "DATE_ASC",
      },
    };
    await API.graphql(params).then((files) => {
      let matterFilesList = files?.data?.matterFiles?.items;
      //console.log("matterFilesList: TEST", matterFilesList);
      setFileTypeCollection(matterFilesList);
    });
  };

  useEffect(() => {
    if (background) {
      fetchCompanyUsers();
      getMatterFiles();
    }
  }, [background]);

  const StrictModeDroppable = ({ children, ...props }) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
      const animation = requestAnimationFrame(() => setEnabled(true));
      return () => {
        cancelAnimationFrame(animation);
        setEnabled(false);
      };
    }, []);
    if (!enabled) {
      return null;
    }
    return <Droppable {...props}>{children}</Droppable>;
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: false,
    trim: true,
    matchFrom: "any",
  };

  //Handles old data, which is when all the row data have a 0 value in order
  //Note: Client side only, the handleAddRow() handles all the DB mutations
  useEffect(() => {
    if (background.length > 0) {
      //console.log('bg not empty');
      if (background[0]?.order === 0) {
        background.forEach((item, index) => {
          item.order = index + 1;
        });
      }
    }
  }, [background]);

  const MyOption = (props) => {
    //console.log(props);
    if (props.children.includes('"')) {
      let rawInput = document.getElementById(
        "creatableSelect-" + currLabelCellSelected.current.split("-")[3]
      ).lastChild.firstChild.lastChild.firstChild.value;
      let numMatch = 0;
      ///console.log(props);
      //console.log(creatableSelectRef.current.props);
      //console.log(creatableSelectRef.current);
      for (let i = 0; i < props.options.length; i++) {
        if (
          (props.options[i].label,
          rawInput,
          props.options[i].label.toLowerCase().trim().includes(rawInput))
        ) {
          numMatch += 1;
        }
      }
      return (
        //#deebff
        <div
          className={
            (props.isFocused ? "activeOption" : "") +
            " createOption items-center flex flex-row justify-between border-dashed border-2 rounded-md"
          }
        >
          <FcInfo size={20} className="ml-1" />
          <components.Option {...props}>
            <div className="createOptionText mx-1 flex flex-col">
              {numMatch === 1 && (
                <div className="text-xs">No results were found.</div>
              )}
              <div>
                Click to <span className="font-bold">add {props.value}</span> on
                the list
              </div>
            </div>
          </components.Option>
          <AiFillCloseCircle
            color="#2196f3"
            size={20}
            className="mr-1"
            onClick={(e) => {
              console.log(
                document.getElementById(
                  "creatableSelect-" +
                    currLabelCellSelected.current.split("-")[3]
                )
              );
              //document.getElementById("creatableSelect-"+currLabelCellSelected.current.split("-")[3]).lastChild.firstChild.lastChild.firstChild.clear();
              props.setValue(props.getValue());
            }}
          />
        </div>
      );
    } else {
      return (
        <Draggable
          draggableId={props.innerProps.id}
          index={parseInt(props.innerProps.id.split("-")[4])}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
            >
              <components.Option {...props}>
                <div className="flex flex-row">
                  <div>
                    <MdDragIndicator
                      className="item-no mr-0 text-2xl text-gray-500 items-center	"
                      size={20}
                    />
                  </div>

                  <span className="items-center	">{props.label}</span>
                </div>
              </components.Option>
            </div>
          )}
        </Draggable>
      );
    }
  };

  const MyMenuList = (props) => {
    return (
      <div>
        <DragDropContext
          onBeforeDragStart={() => {}}
          onDragEnd={handleDragEndPopup}
        >
          <Droppable isDropDisabled={false} droppableId={props.selectProps.id}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <components.MenuList {...props}></components.MenuList>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  const MyNoOptionsMessage = (props) => {
    return (
      <div
        className={
          "noOption items-center flex flex-row justify-between border-dashed border-2 rounded-md"
        }
      >
        <FcInfo size={20} className="ml-1" />
        <components.NoOptionsMessage {...props}>
          <div className="noOptionText mx-1">All labels have been selected</div>
        </components.NoOptionsMessage>
        <FcInfo size={20} className="mr-1" />
      </div>
    );
  };

  // Define your padding value for the bottom
  const paddingBottom = 80;

  // Handle Scrolling for saving description
  useEffect(() => {
    // set when checkbox selected count
    const countChecked = selectedItems.length;

    if (countChecked === 0) {
      setMoveButton(false);
      setPasteButton(false);
      autoAdjustAllRow();
    }
  }, [selectedItems]);
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (focusedInput !== null) {
        const inputElement = document.getElementById(
          `${focusedInput}-description`
        );
        console.log(inputElement);
        if (inputElement) {
          inputElement.blur(); // Remove focus
        }
        setFocusedInput(null); // Clear the focused input
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [focusedInput]); ///INSERT SLATE FUNCTIONS HERE

  // Modal Add Payment Breakdown

  const CREATE_BRIEF_MUTATION = `
	mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
		briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
		id
		name
		date
		createdAt
		}
	}
	`;

  const UPDATE_BRIEF_PRESET_MUTATION = `
		mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
			briefUpdate(id: $id, columnIds: $columnIds){
					id
		}
	}
	`;

  const BACKGROUND_TABLE_CREATE = `
    mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
      backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
        }
        createdAt
        updatedAt
      }
    }
    `;

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
        query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
        brief(id: $id) {
        id
        backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
            items {
            id
            description
			      dynamicData
            comments
            date
            createdAt
            order
            files {
                items {
                id
                name
                }
            }
            }
            nextToken
        }
        }
    }
    `;

    const BACKGROUND_BY_BRIEF_ID_QUERY_EXTRACT = `
      query getBackgroundFile($backgroundId: ID, $fileId: ID) {
        backgroundFile(fileId: $fileId, backgroundId: $backgroundId) {
          extractedFileContent
        }
      }
    `;

  const qGetBackgroundsByBriefId = `query getBriefByID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
		brief(id: $id) {
		  id
		  name
		  columnIds
		  backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
			items {
			  id
			  description
			  comments
			  date
			  createdAt
			  order
			  dynamicData
			  email
			  files {
				items {
				  id
				  name
				  details
				  gmailMessageId
				  gmailMessage {
					gmailLabelIds
				  }
				  isGmailPDF
				  isGmailAttachment
				  s3ObjectKey
				  order
				  createdAt
				}
			  }
			  tabFiles {
					  fileIds
				  }
			  briefs {
				id
				name
			  }
			  labels {
				items {
				  id
				  name
				}
			  }
			}
			nextToken
		  }
		}
	  }
	  `;

  const handleOpenBreakdown = (item) => {
    const defaultColumnIds = [0, 1, 3];
    let order = 0;

    createBrief(item.id, order, defaultColumnIds);
  };

  const [approvedToDate, setApprovedToDate] = useState(null);
  const [updatedContent, setUpdatedContent] = useState(false);
  const fetchBackgroundData = async (id) => {
    try {
      const backgroundOpt = await API.graphql({
        query: qGetBackgroundsByBriefId,
        variables: {
          id: id,
          sortOrder: "DATE_ASC",
        },
      });

      return backgroundOpt.data.brief.backgrounds;
    } catch (error) {
      console.error("ERROR FETCHING BACKGROUND DATA:::", error.message);
      throw error;
    }
  };

  const getValueDynamicData = (dynamicData, type) => {
    if (dynamicData !== null && type) {
      const item = JSON.parse(dynamicData);
      const keys = Object.keys(item);
      const values = [];
      keys.forEach((key) => {
        values.push(item[key]);
      });

      if (type === "Variation Status") {
        return values[0];
      }

      if (type === "Variation Cost" || type === "Variation Cost ($)") {
        return values[1];
      }
    }
  };

  const getBackgrounds = async (id) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });

    if (backgroundOpt.data?.brief?.backgrounds?.items !== null) {
      var result = backgroundOpt?.data?.brief?.backgrounds?.items.map(
        ({ id, description, dynamicData, date, createdAt, order, files }) => {
          const variations = JSON.parse(dynamicData);
          // Populate custom column data inside of dynamic data
          console.log("Variation Dynamic Data:,", dynamicData);
          const variationStatus = getValueDynamicData(
            dynamicData,
            "Variation Status"
          );
          const variationCost = getValueDynamicData(
            dynamicData,
            "Variation Cost ($)"
          );

          return {
            createdAt: createdAt,
            id: id,
            description: description,
            dynamicData: variations,
            variationStatus:
              variations && variationStatus ? variationStatus : null,
            variationsCost:
              variations && variationCost ? parseInt(variationCost) : null,
            date: date,
            order: order,
            files: files,
          };
        }
      );
      procVariations(result);
    }
  };

  const procVariations = (items) => {
    let approvedToDateTotal = 0;
    items.map((item) => {
      if (item.variationsCost !== null && item.variationStatus === "Approved") {
        approvedToDateTotal = approvedToDateTotal + item.variationsCost;
      }
    });

    const formattedAmount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(approvedToDateTotal);
    setApprovedToDate(formattedAmount);
    setUpdatedContent(true);
  };

  useEffect(() => {
    // Limit running on other briefs
    if (!updatedContent) {
      // getBackgrounds(briefId);
    }
  }, [updatedContent]);

  const createBrief = async (briefName, order, columnIds) => {
    // Check if briefName is undefined
    // if (briefName === undefined) {
    // 	console.error("Brief name is undefined.");
    // 	return;
    // }

    // Append underscore to briefName for condition purposes
    const modifiedBriefName = "_" + briefName;

    await getBgList(matter_id).then(async (briefsList) => {
      try {
        console.log("briefsList", briefsList);

        // Check if modifiedBriefName exists in briefsList
        const briefExists = briefsList.some(
          (brief) => brief.name === modifiedBriefName
        );
        if (briefExists) {
          // Brief with the same name already exists, do nothing
          console.log(
            "Brief with the same name already exists:",
            modifiedBriefName
          );

          // Get the briefId of the existing brief
          const existingBrief = briefsList.find(
            (brief) => brief.name === modifiedBriefName
          );
          const backgroundId = existingBrief.id;
          console.log("Existing Brief ID:", backgroundId);

          setBriefNameBreakdown(modifiedBriefName);
          setBriefIdBreakdown(backgroundId);

          const backgroundOpt = await fetchBackgroundData(backgroundId); // Await here

          if (backgroundOpt) {
            console.log("BACKGROUND RESPONSE:", backgroundOpt.items);

            setBreakdownData(sortByOrder(backgroundOpt.items));
            setPrevBreakdownData(sortByOrder(backgroundOpt.items));
            setIsOpenAddPayment(!isOpenAddPayment);
          }
        } else {
          // Run CREATE_BRIEF_MUTATION since modifiedBriefName doesn't exist
          await API.graphql({
            query: CREATE_BRIEF_MUTATION,
            variables: {
              clientMatterId: matter_id,
              name: modifiedBriefName,
              order: order,
              columnIds: columnIds,
            }
          }).then(async (result) => {
            console.log("createBrief result", result);
            const briefId = result.data.briefCreate.id;
            const briefName = result.data.briefCreate.name;

            const tempBriefDetails = [{ id: briefId, name: briefName }];

            const createBackgroundRow = await API.graphql({
              query: mCreateBackground,
              variables: {
                briefs: tempBriefDetails,
                description: "",
                comments: "",
                date: null,
                order: 0,
              },
            }).then(async (result) => {
              console.log("createBackgroundRow result", result);
              const newBackground = result.data.backgroundCreate;
              console.log("newBackground", newBackground);
              console.log("Created brief preset", tempBriefDetails);
              const updatedBriefList = [...briefsList, ...tempBriefDetails];
              console.log("Updated preset list:", updatedBriefList);

              const backgroundOpt = await fetchBackgroundData(briefId); // Await here

              if (backgroundOpt) {
                console.log("BACKGROUND RESPONSE:", backgroundOpt.items);
                setBreakdownData(backgroundOpt.items);

                setBriefNameBreakdown(modifiedBriefName);
                setBriefIdBreakdown(briefId);

                // Set default column ids
                setDefaultBriefColumnIds(
                  sortByOrder(updatedBriefList),
                  matter_id,
                  modifiedBriefName
                );
              }
            });
          });
        }
      } catch (e) {
        console.error("ERROR at getBGList", e);
      }
      getBackground();
    });
  };

  async function setDefaultBriefColumnIds(
    briefs,
    clientMatterId,
    modifiedBriefName
  ) {
    const savePromises = [];
    let briefIds = [];

    console.log("SETTING DEFAULT COLS", briefs);

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        console.log("columnIds", columnIds);
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);

      console.log("updatedBriefIds", updatedBriefIds);
      const newDefaultColumn = updatedBriefIds?.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      console.log("Background Table for", clientMatterId, backgroundTable);

      if (backgroundTable?.data?.backgroundTable) {
        await setDefaultColumnBriefs(
          backgroundTable?.data?.backgroundTable,
          briefs,
          modifiedBriefName
        );
        setIsOpenAddPayment(!isOpenAddPayment);
      } else {
        console.log("Create Background Table for ", clientMatterId);

        const initialPresets = [];
        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          initialPresets.push(preset);
        }

        let backgroundCreate = await API.graphql({
          query: BACKGROUND_TABLE_CREATE,
          variables: {
            clientMatterId: clientMatterId,
            initialPresets: initialPresets,
          },
        });
        await setDefaultColumnBriefs(
          backgroundCreate?.data?.backgroundTableCreate,
          briefs,
          modifiedBriefName
        );
        setIsOpenAddPayment(!isOpenAddPayment);
      }
      // }
    } catch (error) {
      console.error(error);
    }
  }

  async function setDefaultColumnBriefs(
    backgroundTables,
    briefs,
    modifiedBriefName
  ) {
    console.group("setDefaultColumnBriefs");
    try {
      const { id, columns } = backgroundTables;

      let invoicePreset;
      let variationPreset;
      let provisionalSumPreset;
      let builderDetailsPreset;
      let contractDetailsPreset;
      let breakdownPreset;

      console.log("Current columns columns", columns);
      console.log("defaultColumnIds:", defaultColumnIds);

      if (id) {
        let presets = [];
        const commentsColPresets = [];

        for (const item of briefs) {
          console.log("ITEM IS:", item);
          const preset = { id: item.id, name: item.name };
          if (item.name == "Invoices") {
            invoicePreset = { id: item.id, name: item.name };
          }
          if (item.name == "Variations") {
            variationPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Provisional Sum") {
            provisionalSumPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Builder Details") {
            builderDetailsPreset = { id: item.id, name: item.name };
          }
          if (item.name == "Contract Details") {
            contractDetailsPreset = { id: item.id, name: item.name };
          }
          if (item.name == modifiedBriefName) {
            breakdownPreset = { id: item.id, name: item.name };
          }

          presets.push(preset);

          if (item?.hasCommentAccess) {
            const commentPreset = { ...preset };
            commentsColPresets.push(commentPreset);
          }
        }

        console.log("presets", presets);
        console.log("breakdownPreset:", breakdownPreset); // Log breakdownPreset

        let isInvoicePresent = false;
        let isVariationPresent = false;
        let isProvisionalSumPresent = false;
        let isBuilderDetailsPresent = false;
        let isContractDetailsPresent = false;
        let isBreakdownPresent = false;

        const defaultBriefs = columns.map((obj) => {
          var tag;
          if (
            obj.headerText == "Claim Status" ||
            obj.headerText == "Payment Status" ||
            obj.headerText == "Claim Amount ($)" ||
            obj.headerText == "Due Date for Claim"
          ) {
            tag = "Invoices";
            isInvoicePresent = true;
          }
          if (
            obj.headerText == "Variation Status" ||
            obj.headerText == "Variation Cost" ||
            obj.headerText == "Variation Cost ($)"
          ) {
            tag = "Variations";
            isVariationPresent = true;
          }

          if (
            obj.headerText == "Provisional Amount" ||
            obj.headerText == "Cost Amount" ||
            obj.headerText == "Description of Work" ||
            obj.headerText == "Paid to Date Amount" ||
            obj.headerText == "Action"
          ) {
            tag = "Provisional Sum";
            isProvisionalSumPresent = true;
          }

          if (obj.headerText == "Cost Amount") {
            tag = modifiedBriefName;
            isBreakdownPresent = true;
          }

          if (
            obj.headerText == "Builder Name" ||
            obj.headerText == "Builder Logo" ||
            obj.headerText == "Builder Contact Name" ||
            obj.headerText == "Builder Contact Email" ||
            obj.headerText == "Builder Contact Number"
          ) {
            tag = "Builder Details";
            isBuilderDetailsPresent = true;
          }

          if (
            obj.headerText == "Start Date" ||
            obj.headerText == "Contract Price" ||
            obj.headerText == "Contract Value"
          ) {
            isContractDetailsPresent = true;
            tag = "Contract Details";
          }

          console.log("obj.presets", obj.preset);
          const breakDownPresetList = presets.filter((preset) =>
            preset.name.includes("_")
          );
          console.log("Break Down Preset List:", breakDownPresetList);
          return {
            ...obj,
            presets:
              obj.headerText == "Label"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details" &&
                        preset.name !== "Variations" &&
                        preset.name !== "Provisional Sum" &&
                        preset.name !== "Budget" &&
                        !preset.name.includes("_")
                    )
                  : []
                : obj.headerText === "Cost Amount"
                ? [...obj.presets, ...breakDownPresetList]
                : obj.headerText == "Gmail" || obj.headerText === "Email Date"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details" &&
                        !preset.name.includes("_")
                    )
                  : []
                : isVariationPresent ||
                  isInvoicePresent ||
                  isProvisionalSumPresent
                ? obj.presets
                : isBuilderDetailsPresent || isContractDetailsPresent
                ? obj.presets.filter((preset) => preset.name === tag)
                : obj.headerText === "Description of Background" ||
                  obj.headerText === "Date"
                ? presets?.length
                  ? presets.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        preset.name !== "Builder Details"
                    )
                  : []
                : obj.headerText === "Document"
                ? presets?.length
                  ? presets?.filter(
                      (preset) =>
                        preset.name !== "Contract Details" &&
                        !preset.name.includes("_")
                    )
                  : []
                : presets,
          };
        });
        console.log("Default Briefs:", defaultBriefs);

        let newColumns = [...defaultBriefs];
        // setUpdatedColumns([...defaultBriefs]);

        console.log("isInvoicePresent", isInvoicePresent);
        console.log("isVariationPresent", isVariationPresent);
        console.log("isBuilderDetailsPresent", isBuilderDetailsPresent);
        console.log("isContractDetailsPresent", isContractDetailsPresent);
        console.log("isProvisionalSumPresent", isProvisionalSumPresent);
        console.log("isBreakdownPresent", isBreakdownPresent);

        console.log("Default Briefs", defaultBriefs);

        //Filter Columns & set it to default HBA presets
        let filterBriefs = [];
        defaultBriefs.map((obj) => {
          if (
            obj.headerText == "Claim Status" ||
            obj.headerText == "Payment Status" ||
            obj.headerText == "Claim Amount ($)" ||
            obj.headerText == "Due Date for Claim"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [invoicePreset],
            });
          }

          if (
            obj.headerText == "Variation Status" ||
            obj.headerText == "Variation Cost" ||
            obj.headerText == "Variation Cost ($)"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [variationPreset],
            });
          }

          // if (
          // 	obj.headerText == "Cost Amount"
          // ) {
          // 	filterBriefs.push({
          // 		...obj,
          // 		presets: [breakdownPreset],
          // 	});
          // }

          if (
            obj.headerText == "Builder Name" ||
            obj.headerText == "Builder Logo" ||
            obj.headerText == "Builder Contact Name" ||
            obj.headerText == "Builder Contact Email" ||
            obj.headerText == "Builder Contact Number"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [builderDetailsPreset],
            });
          }

          if (
            obj.headerText == "Start Date" ||
            obj.headerText == "Contract Price" ||
            obj.headerText == "Contract Value"
          ) {
            filterBriefs.push({
              ...obj,
              presets: [contractDetailsPreset],
            });
          }

          if (
            obj.headerText !== "Start Date" &&
            obj.headerText !== "Contract Price" &&
            obj.headerText !== "Contract Value" &&
            obj.headerText !== "Claim Status" &&
            obj.headerText !== "Payment Status" &&
            obj.headerText !== "Claim Amount ($)" &&
            obj.headerText !== "Due Date for Claim" &&
            obj.headerText !== "Variation Status" &&
            obj.headerText !== "Variation Cost" &&
            obj.headerText !== "Variation Cost ($)" &&
            obj.headerText !== "Builder Name" &&
            obj.headerText !== "Builder Logo" &&
            obj.headerText !== "Builder Contact Name" &&
            obj.headerText !== "Builder Contact Email" &&
            obj.headerText !== "Builder Contact Number" &&
            obj.headerText !== "Start Date" &&
            obj.headerText !== "Contract Price" &&
            obj.headerText !== "Contract Value"
          ) {
            filterBriefs.push({
              ...obj,
            });
          }
        });
        console.log("filtered Briefs:", filterBriefs);
        newColumns = filterBriefs;
        // }

        console.log(id, "new columns:", newColumns);

        const variables = {
          id,
          input: {
            columns: newColumns,
          },
        };

        console.log("VARIABLE IS:", variables);

        const updateBriefTable = await API.graphql({
          query: UPDATE_BACKGROUND_TABLE_MUTATION,
          variables,
        });

        console.log("updateBriefTable Columns", newColumns);

        // setUpdatedColumns(newColumns); // remove on parameters
      }
    } catch (error) {
      console.error(error);
      console.groupEnd("setDefaultColumnBriefs");
    }
    console.groupEnd("setDefaultColumnBriefs");
  }

  //Computing of total cost kjmf
  // const [briefWithCosts, setBriefWithCosts] = useState(null);
  // const [briefListId, setBriefListId] = useState(null);

  // const getBriefList = async () => {
  // 	const params = {
  // 		query: listBriefs,
  // 		variables: {
  // 			id: matter_id,
  // 			limit: 100,
  // 			nextToken: null,
  // 		},
  // 	};

  // 	await API.graphql(params).then((brief) => {
  // 		var bl = brief?.data?.clientMatter?.briefs?.items;
  // 		var bgPaymentList = [];

  // 		setBriefListId(bl);

  // 		bl.map((x)=> x?.name[0] === "_" ? bgPaymentList = [...bgPaymentList, x] : x)

  // 		console.log("paymentss", bgPaymentList)

  // 		var rows = [];

  // 		var totalCostList = [];

  // 		bgPaymentList.map(async (x, idx)=>{
  // 			var rowId = x.id;
  // 			await API.graphql({
  // 				query: qGetBackgroundsByBriefId,
  // 				variables: {
  // 				  id: x.id,
  // 				  nextToken: null,
  // 				  sortOrder: "ORDER_ASC",
  // 				  limit: null,
  // 				},
  // 			  }).then((result)=>{
  // 				console.log("check all", idx, result)
  // 				var contents = result?.data?.brief?.backgrounds?.items;
  // 				var contentTotalCost = 0;

  // 				contents.map((x)=>{
  // 					if(x.dynamicData !== null){
  // 						var parsedDD = JSON.parse(x.dynamicData)
  // 						contentTotalCost = contentTotalCost + parseFloat(parsedDD["12"]); //total cost per background generated from add payment
  // 					}
  // 					})

  // 				console.log("total cost", contentTotalCost);

  // 				totalCostList = [...totalCostList, {id: rowId, totalCost: contentTotalCost}]

  // 				setBriefWithCosts(totalCostList) //match rows here
  // 				console.log("Total total", totalCostList)
  // 			  });
  // 		})

  // 	});
  // };

  //   useEffect(() => {
  // 	if(briefListId === null){
  //     	getBriefList();
  // 	}
  //   }, [briefListId]);

  //   function getRowTotalCost(rowId){
  // 	  if(briefWithCosts !== null){
  // 		  briefWithCosts.map((x)=>{
  // 			if(x.id === rowId){
  // 				return(<div>{x.totalCost}</div>) ;
  // 			}else{
  // 				return(<div>No Payments Made</div>) ;
  // 			}
  // 		  })
  // 	  }
  //   }

  // Define a function to compute sumBudgetAmount and sumCostAmount for a single item
  // let sumBudgetAmount = 0;
  // let sumCostAmount = 0;

  // function computeSumForItem(item) {
  // 	// Parse dynamicData into an object
  // 	const dynamicDataObject = JSON.parse(item.dynamicData);

  // 	let sumBudgetAmountForItem = 0;
  // 	let sumCostAmountForItem = 0;

  // 	for (const key in dynamicDataObject) {
  // 		if (dynamicDataObject.hasOwnProperty(key)) {
  // 			const value = parseFloat(dynamicDataObject[key]);
  // 			if (!isNaN(value)) {
  // 				if (key === '12') {
  // 					sumBudgetAmountForItem += value;
  // 				} else if (key === '13') {
  // 					sumCostAmountForItem += value;
  // 				}
  // 			}
  // 		}
  // 	}

  // 	return { sumBudgetAmountForItem, sumCostAmountForItem };
  // }

  let sumBreakdownAmount = 0;

  function formatNumberWithCommas(number) {
    if (typeof number === "number" && !isNaN(number)) {
      return number.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return "0.00";
    }
  }

  //FILE SUMMARIZE UI
  const [summariseStart, setSummariseStart] = useState(false);

  const firstElementRef = useRef(null);

  // Function to scroll to the first element
  const scrollToFirstElement = () => {
    if (firstElementRef.current) {
      firstElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //LABELS
  const [showLabelsDropdown, setShowLabelsDropdown] = useState(false);
  const [selectedLabelId, setSelectedLabelId] = useState("");

  //create background for defecta_list values

  const [liabilityPeriodRowId, setliabilityPeriodRowId] = useState("");
  const [liabilityPeriodDescription, setliabilityPeriodDescription] = useState(null);


  //DEFECT LIST HIDDEN DETAILS
  useEffect(async ()=>{
      const params = {
        query: listBriefs,
        variables: {
          id: matter_id,
          limit: 100,
          nextToken: null,
        },
      };
  
      await API.graphql(params).then(async (brief) => {
        console.log("BL>>>>>>>>", brief.data.clientMatter.briefs.items)
        var briefList = brief.data.clientMatter.briefs.items;
        var createBrief = true;
        var createdBriefId = "";
  
        briefList.map((x)=> {
          if(x.name === "_Defects_Details"){
            createBrief = false;
            createdBriefId = x.id;
          }
        })
  
        //create storage for the items
        if(createBrief === true){
          //create a brief named DefectsDetails
          const defaultColumnIds = [0, 1, 3, 4];
          let order = 0;
  
          await API.graphql({
            query: CREATE_BRIEF_MUTATION,
            variables: {
              clientMatterId: matter_id,
              name: "_Defects_Details",
              order: order,
              columnIds: defaultColumnIds,
            }
          }).then(async (result)=>{
            //create row for storage
            var createdBriefId = result.data.briefCreate.id;
            var createdBriefName = result.data.briefCreate.name;
  
            const tempBriefDetails = [{ id: createdBriefId, name: createdBriefName }];
  
            const createNewRow = await API.graphql({
                query: mCreateBackground,
                variables: {
                  briefs: tempBriefDetails,
                  description: "",
                  comments: "",
                  date: null,
                  order: 0, // incrementing the order
                },
            })
            
            if(createNewRow){
              console.log("createNewRow", createNewRow)
              setliabilityPeriodRowId(createNewRow.data.backgroundCreate.id);
              setliabilityPeriodDescription(createNewRow.data.backgroundCreate.description)
            }
            
          })
        }else{
          //list all background in defects_list brief
          const backgroundOpt = await fetchBackgroundData(
            createdBriefId,
            null,
            10
          ).then((result)=>{
            console.log("All rows>>>", result.items[0] )
            setliabilityPeriodRowId(result?.items[0]?.id);
            setliabilityPeriodDescription(result?.items[0]?.description)
          });
        }
      })
  }, [])

  const handleSaveLiabilityPeriodDescription = async (text) => {
    const data = {
      description: text,
    };
    const success = await updateBackgroundDesc(liabilityPeriodRowId, data)
    console.log("success", success)
  }

  //INSURANCE HIDDEN DETAILS

  const [insuranceName, setInsuranceName] = useState(null);
  const [insuranceType, setInsuranceType] = useState(null);
  const [insuranceCost, setInsuranceCost] = useState(null);

  useEffect(async ()=>{
      const params = {
        query: listBriefs,
        variables: {
          id: matter_id,
          limit: 100,
          nextToken: null,
        },
      };

      await API.graphql(params).then(async (brief) => {
        console.log("BL>>>>>>>>", brief.data.clientMatter.briefs.items)
        var briefList = brief.data.clientMatter.briefs.items;
        var createBrief = true;
        var createdBriefId = "";

        briefList.map((x)=> {
          if(x.name === "_Insurance_Details"){
            createBrief = false;
            createdBriefId = x.id;
          }
        })

        //create storage for the items
        if(createBrief === true){
          //create a brief named DefectsDetails
          const defaultColumnIds = [0, 1, 3, 4];
          let order = 0;

          await API.graphql({
            query: CREATE_BRIEF_MUTATION,
            variables: {
              clientMatterId: matter_id,
              name: "_Insurance_Details",
              order: order,
              columnIds: defaultColumnIds,
            }
          }).then(async (result)=>{
            //create row for storage
            var createdBriefId = result.data.briefCreate.id;
            var createdBriefName = result.data.briefCreate.name;

            const tempBriefDetails = [{ id: createdBriefId, name: createdBriefName }];

            //Insurance name
            const createNewRow0 = await API.graphql({
                query: mCreateBackground,
                variables: {
                  briefs: tempBriefDetails,
                  description: "",
                  comments: "",
                  date: null,
                  order: 0, 
                },
            }).then((x)=>{
              if(x){
                setInsuranceName([x?.data?.backgroundCreate?.id, x?.data?.backgroundCreate?.description]);
              }
            })

            //Insurance Type
            const createNewRow1 = await API.graphql({
              query: mCreateBackground,
              variables: {
                briefs: tempBriefDetails,
                description: "",
                comments: "",
                date: null,
                order: 1, 
              },
            }).then((x)=>{
              if(x){
                setInsuranceType([x?.data?.backgroundCreate?.id, x?.data?.backgroundCreate?.description]);
              }
            })

            //Insurance cost
            const createNewRow2 = await API.graphql({
              query: mCreateBackground,
              variables: {
                briefs: tempBriefDetails,
                description: "",
                comments: "",
                date: null,
                order: 2, 
              },
            }).then((x)=>{
              if(x){
                setInsuranceCost([x?.data?.backgroundCreate?.id, x?.data?.backgroundCreate?.description]);
              }
            })
          })
        }else{
          //list all background in defects_list brief
          const backgroundOpt = await fetchBackgroundData(
            createdBriefId,
            null,
            10
          ).then((result)=>{
            console.log("All rows>>>", result.items )
            
            setInsuranceName([result?.items[0]?.id, result?.items[0]?.description]);
            setInsuranceType([result?.items[1]?.id, result?.items[1]?.description]);
            setInsuranceCost([result?.items[2]?.id, result?.items[2]?.description]);
          });
        }
      })
  }, [])

  const handleInsuranceDescription = async (text, insuranceId) => {
    const data = {
      description: text,
    };
    const success = await updateBackgroundDesc(insuranceId, data)
    console.log("success", success)
  }

  return (
    <>
      {/* Prevents user from going back while change is still being made. (MMA-1930)*/}
      <Prompt
        when={saving}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      {!bgLoading ? 
        (<> 
          {/* TOP DETAILS FOR DEFECTS LIST */}
          <div className={briefName === "Defects List" ? "h-30 w-full bg-gray-100 py-2 px-2" : "hidden"}>
            <div className="right-0 mr-36 float-right">
              <span className="flex pb-1"><p className="w-44 font-semibold mt-1">Defects Liability Period:</p> 
                <input 
                  type="text" 
                  className="p-1"
                  defaultValue={liabilityPeriodDescription ? liabilityPeriodDescription : ""}
                  // onChange={()=>}
                  onBlur={(e)=>{handleSaveLiabilityPeriodDescription(e.target.value)}}
                /> 
              </span>
              
            </div>
          </div>
          {/* TOP DETAILS FOR INSURANCE */}
          <div className={briefName === "Insurance" ? "h-30 w-full bg-gray-100 py-2 px-2" : "hidden"}>
            <div className="right-0 mr-36 float-right">
              <span className="flex pb-1"><p className="w-44 font-semibold mt-1">Insurance Name:</p> 
                <input 
                  type="text" 
                  className="p-1"
                  defaultValue={insuranceName ? insuranceName[1] : ""}
                  // onChange={()=>}
                  onBlur={(e)=>{handleInsuranceDescription(e.target.value, insuranceName[0])}}
                /> 
              </span>

              <span className="flex pb-1"><p className="w-44 font-semibold mt-1">Insurance Type:</p> 
                <input 
                  type="text" 
                  className="p-1"
                  defaultValue={insuranceType ? insuranceType[1] : ""}
                  // onChange={()=>}
                  onBlur={(e)=>{handleInsuranceDescription(e.target.value, insuranceType[0])}}
                /> 
              </span>

              <span className="flex pb-1"><p className="w-44 font-semibold mt-1">Insurance Cost:</p> 
                <input 
                  type="text" 
                  className="p-1"
                  defaultValue={insuranceCost ? insuranceCost[1] : ""}
                  // onChange={()=>}
                  onBlur={(e)=>{handleInsuranceDescription(e.target.value, insuranceCost[0])}}
                /> 
              </span>
              
            </div>
          </div>
        </>) : (<></>)
    
      }
      

      <div className="overflow-y-scroll h-full w-fit mb-0"
        id="mainDivScroll"
        style={{ overflowY: 'scroll',marginBottom: '0px' , width: '96%' }}
        ref={tableContainerRef}>
        <div className="-my-2 sm:-mx-6 lg:-mx-8"
          style={{
            height: `${totalSize}px`,
            position: 'relative',
          }}
        >
          <div className="min-w-full py-2 align-middle sm:px-6 lg:px-8" 
            style={{marginTop: -(tableHeaderRef?.current?.clientHeight || 20)}}>
            <div
              className="border-b border-gray-200 shadow align-center sm:rounded-lg"
              style={{ overflow: "auto" }}
            >
              {/* <button ref={firstElementRef} >Scroll to First Element</button> */}
              {bgLoading && wait ? (
                <>
                  
                  <table className="min-w-full text-xs divide-y divide-gray-200 table-fixed">
                    <thead className="z-20 bg-gray-100">
                      <tr>
                        <th className="w-5 px-2 py-4 text-center whitespace-nowrap">
                          <Skeleton count={1} />
                        </th>
                        <th className="w-8 px-2 py-4 text-center whitespace-nowrap">
                          <span className="">
                            <Skeleton count={1} />
                          </span>
                        </th>
                        <th className="w-5 px-2 py-4 text-center whitespace-nowrap">
                          <Skeleton count={1} />
                        </th>
                        <th className="w-auto px-2 py-4 text-center whitespace-nowrap">
                          <Skeleton count={1} />
                        </th>
                        <th className="w-auto px-2 py-4 text-center whitespace-nowrap">
                          <Skeleton count={1} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...Array(4).keys()].map((data, index) => {
                        return (
                          <tr key={`skeleton-loader-${index}`}>
                            <td className="w-5 p-2 align-top">
                              <div className="align-top">
                                <SkeletonTheme width={"40px"} height={"20px"}>
                                  <p>
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="w-8 p-2 align-top">
                              <div>
                                <SkeletonTheme height="40px" width="110px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="w-5 p-2 align-top">
                              <div className="align-top">
                                <SkeletonTheme width={"40px"} height={"20px"}>
                                  <p>
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="w-full p-2 align-top">
                              <div>
                                <SkeletonTheme>
                                  <p>
                                    <Skeleton count={4} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="w-auto p-2 align-top">
                              <div>
                                <SkeletonTheme height="40px" width="340px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                </>
              ) : background?.length !== 0 && !bgLoading ? (
                <>
                  <div>
                    <ScrollToTop
                      smooth
                      color="rgb(117, 117, 114);"
                      style={{ padding: "0.4rem" }}
                    />
                    {background !== null && background.length !== 0 ? (
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="tbl-backgrounds" type="PARENT">
                          {(provider) => (

                            <table
                              id="actualTable"
                              className=" min-w-full divide-y divide-gray-200 table-auto pb-20 "
                              style={{
                                overflow: "auto",

                              }}
                            >
                              <tbody
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                                className="bg-white divide-y divide-gray-200 pb-40"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  overflow: "auto",
                                }}
                              >
                                {virtualRows.map((virtualRow) => {
                                  const row = rows[virtualRow.index];
                                  const item = row.original;
                                  const index = row.index;
                                  const style = "";

                                  let itemBriefsArr = item.briefs?.map(
                                    (item) => item.name
                                  );

                                  let itemBriefsM1;

                                  let firstThreeLabels = [];
                                  let theRestLabels = [];
                                  if (item.briefs?.length > 3) {
                                    theRestLabels = cloneDeep(item.briefs);
                                    firstThreeLabels = theRestLabels?.splice(
                                      0,
                                      3
                                    );

                                    itemBriefsM1 = cloneDeep(itemBriefsArr);
                                    itemBriefsM1.shift();
                                    itemBriefsM1.shift();
                                    itemBriefsM1.shift();
                                    var itemBriefsHTML =
                                      itemBriefsM1.join("<br/>");
                                  }

                                  let popupInputNode = document.getElementById(
                                    "creatableSelect-" + index
                                  )?.lastChild?.firstChild?.lastChild
                                    ?.firstChild;
                                  const dynamicData =
                                    typeof item.dynamicData === "string"
                                      ? JSON.parse(item.dynamicData)
                                      : item.dynamicData;

                                  let fileList = [];
                                  item?.files?.items?.forEach((row) => {
                                    if (
                                      row.gmailMessage?.gmailLabelIds?.includes(
                                        "SENT"
                                      )
                                    ) {
                                      fileList.push("Sent");
                                    } else if (
                                      row.gmailMessage?.gmailLabelIds?.includes(
                                        "INBOX"
                                      )
                                    ) {
                                      fileList.push("Inbox");
                                    } else if (
                                      row.gmailMessage === null ||
                                      row.gmailMessageId === ""
                                    ) {
                                      fileList.push("Uploaded");
                                    }
                                  });
                                  fileList = fileList.filter(
                                    (item, index) =>
                                      fileList.indexOf(item) === index
                                  );
                                  //console.log("FILE LIST: ", fileList);
                                  let gmailStatus = "";
                                  for (let i = 0; i < fileList.length; i++) {
                                    gmailStatus = gmailStatus + fileList[i];
                                    if (i !== fileList.length - 1) {
                                      gmailStatus = gmailStatus + " / ";
                                    }
                                  }

                                  // console.log("backgroundList", item);

                                  // // Parse dynamicData into an object
                                  // const dynamicDataObject = JSON.parse(item.dynamicData);

                                  // for (const key in dynamicDataObject) {
                                  // 	if (dynamicDataObject.hasOwnProperty(key)) {
                                  // 		const value = parseFloat(dynamicDataObject[key]);
                                  // 		if (!isNaN(value)) {
                                  // 			if (key === '12') {
                                  // 				sumBudgetAmount += value;
                                  // 			} else if (key === '13') {
                                  // 				sumCostAmount += value;
                                  // 			}
                                  // 		}
                                  // 	}
                                  // }

                                  // console.log("Total Budget Amount:", sumBudgetAmount);
                                  // console.log("Total Cost Amount:", sumCostAmount);

                                  // Iterate through the item array and compute sumBudgetAmount and sumCostAmount for each item
                                  // for (const items of [item]) {
                                  // 	const { sumBudgetAmountForItem, sumCostAmountForItem } = computeSumForItem(items);

                                  // 	sumBudgetAmount += sumBudgetAmountForItem;
                                  // 	sumCostAmount += sumCostAmountForItem;
                                  // }

                                  // console.log("Total Budget Amount:", sumBudgetAmount);
                                  // console.log("Total Cost Amount:", sumCostAmount);

                                  sumBreakdownAmount +=
                                    item && typeof item.totalCost === "number"
                                      ? parseFloat(item.totalCost)
                                      : 0;

                                  return (
                                    <tr
                                      key={virtualRow.key}
                                      data-index={virtualRow.index}
                                      ref={(ref) => virtualRow.measureElement(ref)}
                                      style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: 'auto',
                                        width: '100%',
                                        transform: `translateY(${index === 0 ? '55px' : `${virtualRow.start + 20}px`})`,
                                      }}
                                    >

                                      {index === 0 && (
                                        <thead
                                          className={`${handleFontSize(
                                            "bg-white-100 z-10",
                                            fontSize
                                          )} ${
                                            briefName === "Budget"
                                              ? "hidden"
                                              : ""
                                          }`}
                                          style={{
                                            position: "sticky",
                                            zIndex: 10,
                                            width: "100%",
                                            color: "#8A8A8A",
                                          }}
                                          ref={tableHeaderRef}
                                          onClick={() => setIsActiveCell(null)}
                                        >
                                          {briefName === "Variations" && (
                                            <tr>
                                              <td
                                                colSpan={
                                                  taggedColumns.length + 1
                                                }
                                                className="items-center rounded-t-sm py-4 px-4 bg-hbLightGray text-hbGray  whitespace-nowrap w-full"
                                              >
                                                <div className="flex justify-center gap-2">
                                                  <p className="font-semibold">
                                                    Approved Variations Cost to
                                                    Date:{" "}
                                                  </p>
                                                  <span className="font-semibold text-hbBlue">
                                                    {approvedToDate}
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                          <tr
                                            style={{
                                              borderBottom: "1px solid #E4E7E7",
                                              borderTop: "1px solid #E4E7E7",
                                            }}
                                          >
                                            <th
                                              className={handleFontSize(
                                                "px-2 py-4 text-center whitespace-nowrap",
                                                fontSize
                                              )}
                                              style={{
                                                minWidth: "83.72px",
                                                maxWidth: "83.72px", //ADDED TO MATCH COL WIDTH
                                              }}
                                              onClick={() => {
                                                // console.log("Hello");
                                                // console.log(bindList.current.props.scrollToIndex);
                                                // bindList.current.scrollToRow(6);
                                                console.log(
                                                  document.getElementById(
                                                    "tableInfoList"
                                                  ).clientHeight
                                                );
                                              }}
                                            >
                                              No
                                            </th>

                                            {taggedColumns &&
                                              taggedColumns?.map(
                                                (column, index) => {
                                                  return parseInt(
                                                    column.accessorKey
                                                  ) === 0 ? ( // Date Column
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          key={`column-header-${index}`}
                                                          className={handleFontSize(
                                                            "px-0 py-0 text-center whitespace-nowrap",
                                                            fontSize
                                                          )}
                                                          style={{
                                                            minWidth: "120px",
                                                            maxWidth: "120px",
                                                          }}
                                                        >
                                                          {briefName ===
                                                          "Invoices"
                                                            ? "Date of Invoice"
                                                            : briefName ===
                                                              "Progress Photos"
                                                            ? "Date of Photo Taken"
                                                            : column.header}
                                                          &nbsp;
                                                          {ascDesc == null ? (
                                                            <>
                                                              <FaSort
                                                                className={
                                                                  sortIconClass
                                                                }
                                                                alt="Sort"
                                                                title="Sort"
                                                                onClick={
                                                                  SortBydate
                                                                }
                                                              />
                                                            </>
                                                          ) : ascDesc ===
                                                            true ? (
                                                            <>
                                                              <BsSortUpAlt
                                                                className={
                                                                  sortIconClass
                                                                }
                                                                alt="Sort"
                                                                title="Sort"
                                                                onClick={
                                                                  SortBydate
                                                                }
                                                              />
                                                              <span className="font-light text-xs" >ASC</span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <BsSortDown
                                                                className={
                                                                  sortIconClass
                                                                }
                                                                alt="Sort"
                                                                title="Sort"
                                                                onClick={
                                                                  SortBydate
                                                                }
                                                              />
                                                              <span className="font-light text-xs" >DESC</span>
                                                            </>
                                                          )}
                                                        </th>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 1 ? ( // Gmail Column
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={`${handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap",
                                                            fontSize
                                                          )} ${
                                                            briefName ===
                                                            "Budget"
                                                              ? "hidden"
                                                              : ""
                                                          }`}
                                                          style={{
                                                            width: "70px",
                                                            minWidth: "70px",
                                                          }}
                                                          data-tip={`Inbox(${
                                                            background.filter(
                                                              (bg) =>
                                                                bg?.files?.items?.some(
                                                                  (item) =>
                                                                    item.gmailMessage?.gmailLabelIds?.includes(
                                                                      "INBOX"
                                                                    )
                                                                )
                                                            ).length
                                                          }) | Sent(${
                                                            background.filter(
                                                              (bg) =>
                                                                bg?.files?.items?.some(
                                                                  (item) =>
                                                                    item.gmailMessage?.gmailLabelIds?.includes(
                                                                      "SENT"
                                                                    )
                                                                )
                                                            ).length
                                                          })`}
                                                          data-for="rowCount"
                                                        >
                                                          {column.header}
                                                        </th>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 2 ? ( // Description Column
                                                    <>
                                                      {column.visible && (
                                                        <th className="flex">
                                                          <div
                                                            className={handleFontSize(
                                                              "px-2 py-4 text-center whitespace-nowrap",
                                                              fontSize
                                                            )}
                                                            style={{
                                                              width: "100%",
                                                              minWidth: "100px",
                                                              overflow:
                                                                "hidden",
                                                              textOverflow:
                                                                "ellipsis",
                                                              // backgroundColor:
                                                              //   commentAccess
                                                              //     ? "green"
                                                              //     : "transparent", // apply background color conditionally
                                                            }}
                                                          >
                                                            {briefName ===
                                                            "Invoices"
                                                              ? "Description of Invoice"
                                                              : briefName ===
                                                                "Variations"
                                                              ? "Description of Variation"
                                                              : column.header}
                                                          </div>
                                                        </th>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 3 ? ( // Comments Column
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap",
                                                            fontSize
                                                          )}
                                                        >
                                                          {column.header}
                                                        </th>
                                                      )}
                                                    </>
                                                  ) : column.header ===
                                                    "Label" ? ( // Label Column
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap w-full",
                                                            fontSize
                                                          )}
                                                        >
                                                          {column.header}
                                                        </th>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 4 ? ( // Document Column
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={`${handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap",
                                                            fontSize
                                                          )} ${
                                                            briefName ===
                                                            "Budget"
                                                              ? "hidden"
                                                              : ""
                                                          }`}
                                                        >
                                                          {column.header}
                                                        </th>
                                                      )}
                                                    </>
                                                  ) : column.header ===
                                                    "Email Date" ? ( // Email Date Column
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap w-full",
                                                            fontSize
                                                          )}
                                                        >
                                                          {column.header}
                                                          {/*Uncomment to activate MMA-1306 */}
                                                          {/* {emailDateAsc ==
																														null ? (
																															<FaSort
																																className={
																																	sortIconClass
																																}
																																alt="Sort"
																																title="Sort"
																																onClick={
																																	SortByEmailDate
																																}
																															/>
																														) : emailDateAsc ===
																															true ? (
																															<BsSortUpAlt
																																className={
																																	sortIconClass
																																}
																																alt="Sort"
																																title="Sort"
																																onClick={
																																	SortByEmailDate
																																}
																															/>
																														) : (
																															<BsSortDown
																																className={
																																	sortIconClass
																																}
																																alt="Sort"
																																title="Sort"
																																onClick={
																																	SortByEmailDate
																																}
																															/>
																														)} */}
                                                        </th>
                                                      )}
                                                    </>
                                                  ) : (
                                                    // Dynamic Columns
                                                    !defaultColumnIds.includes(
                                                      column.accessorKey
                                                    ) && (
                                                      <>
                                                        {/* Dynamic Columns */}
                                                        {!defaultColumnIds.includes(
                                                          column.accessorKey
                                                        ) && (
                                                          <>
                                                            {column.header ===
                                                            "Cost Amount" ? (
                                                              // Render something specific when the header matches
                                                              <>
                                                                <th
                                                                  colSpan="2" // Set colspan based on the header
                                                                  className={handleFontSize(
                                                                    "px-2 py-4 text-center whitespace-nowrap w-full",
                                                                    fontSize
                                                                  )}
                                                                >
                                                                  Cost
                                                                </th>
                                                              </>
                                                            ) : column.header ===
                                                              "Provisional Amount" ? (
                                                              <>
                                                                <th
                                                                  colSpan="1" // Set colspan based on the header
                                                                  className={handleFontSize(
                                                                    "px-2 py-4 text-center whitespace-nowrap w-full",
                                                                    fontSize
                                                                  )}
                                                                >
                                                                  {briefName ===
                                                                  "Budget"
                                                                    ? "Budget Amount"
                                                                    : briefName ===
                                                                      "Provisional Sum"
                                                                    ? "Provisional Amount"
                                                                    : briefName ===
                                                                      "Prime Cost"
                                                                    ? "Prime Amount"
                                                                    : ""}
                                                                </th>
                                                              </>
                                                            ) : column.header ===
                                                              "Description of Work" ? (
                                                              // Render something specific when the header matches
                                                              <></>
                                                            ) : column.header ===
                                                              "Paid to Date Amount" ? (
                                                              // Render something specific when the header matches
                                                              <>
                                                                <th
                                                                  colSpan="2" // Set colspan based on the header
                                                                  className={handleFontSize(
                                                                    "px-2 py-4 text-center whitespace-nowrap w-full",
                                                                    fontSize
                                                                  )}
                                                                >
                                                                  Paid To Date
                                                                </th>
                                                              </>
                                                            ) : column.header ===
                                                              "Action" ? (
                                                              // Render something specific when the header matches
                                                              <></>
                                                            ) : (
                                                              // Render normally for other headers
                                                              <>
                                                                {column.visible && (
                                                                  <th
                                                                    className={handleFontSize(
                                                                      "px-2 py-4 text-center whitespace-nowrap w-full",
                                                                      fontSize
                                                                    )}
                                                                  >
                                                                    {column.header ===
                                                                    "Variation Cost"
                                                                      ? "Variation Cost ($)"
                                                                      : column.header}
                                                                  </th>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )
                                                  );
                                                }
                                              )}
                                            <th className="px-2 py-4 text-center whitespace-nowrap w-full"></th>
                                          </tr>
                                        </thead>
                                      )}

                                      {/* Sub Header for Invoices */}
                                      {briefName === "Invoices" && (
                                        <tr style={{ visibility: "collapse" }}>
                                          <th
                                            className={handleFontSize(
                                              "px-2 py-4 text-center whitespace-nowrap",
                                              fontSize
                                            )}
                                            style={{
                                              minWidth: "83.72px",
                                              maxWidth: "83.72px", //ADDED TO MATCH COL WIDTH
                                            }}
                                            onClick={() => {
                                              // console.log("Hello");
                                              // console.log(bindList.current.props.scrollToIndex);
                                              // bindList.current.scrollToRow(6);
                                              console.log(
                                                document.getElementById(
                                                  "tableInfoList"
                                                ).clientHeight
                                              );
                                            }}
                                          ></th>

                                          {taggedColumns &&
                                            taggedColumns?.map(
                                              (column, index) => {
                                                return parseInt(
                                                  column.accessorKey
                                                ) === 0 ? ( // Date Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        key={`column-header-${index}`}
                                                        className={handleFontSize(
                                                          "px-0 py-0 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                        style={{
                                                          minWidth: "120px",
                                                          maxWidth: "120px",
                                                        }}
                                                      >
                                                        {
                                                          // briefName === "Invoices" ? "Date of Invoice" : briefName === "Progress Photos" ? "Date of Photo Taken" : column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 1 ? ( // Gmail Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                        style={{
                                                          width: "70px",
                                                          minWidth: "70px",
                                                        }}
                                                        data-tip={`Inbox(${
                                                          background.filter(
                                                            (bg) =>
                                                              bg?.files?.items?.some(
                                                                (item) =>
                                                                  item.gmailMessage?.gmailLabelIds?.includes(
                                                                    "INBOX"
                                                                  )
                                                              )
                                                          ).length
                                                        }) | Sent(${
                                                          background.filter(
                                                            (bg) =>
                                                              bg?.files?.items?.some(
                                                                (item) =>
                                                                  item.gmailMessage?.gmailLabelIds?.includes(
                                                                    "SENT"
                                                                  )
                                                              )
                                                          ).length
                                                        })`}
                                                        data-for="rowCount"
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 2 ? ( // Description Column
                                                  <>
                                                    {column.visible && (
                                                      <th className="flex">
                                                        <div
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap",
                                                            fontSize
                                                          )}
                                                          style={{
                                                            width: "100%",
                                                            minWidth: "100px",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                              "ellipsis",
                                                          }}
                                                        >
                                                          {
                                                            // briefName === "Invoices" ? "Description of Invoice" : column.header
                                                          }
                                                        </div>
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 3 ? ( // Comments Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : column.header ===
                                                  "Label" ? ( // Label Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap w-full",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 4 ? ( // Document Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : column.header ===
                                                  "Email Date" ? ( // Email Date Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap w-full",
                                                          fontSize
                                                        )}
                                                      ></th>
                                                    )}
                                                  </>
                                                ) : (
                                                  // Dynamic Columns
                                                  !defaultColumnIds.includes(
                                                    column.accessorKey
                                                  ) && (
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap w-full",
                                                            fontSize
                                                          )}
                                                        >
                                                          {column.header}
                                                        </th>
                                                      )}
                                                    </>
                                                  )
                                                );
                                              }
                                            )}
                                          <th className="px-2 py-4 text-center whitespace-nowrap w-full"></th>
                                        </tr>
                                      )}

                                      {/* Sub Header for Variations */}
                                      {briefName === "Variations" && (
                                        <tr
                                          style={{
                                            visibility:
                                              index > 0
                                                ? "collapse"
                                                : "visible",
                                          }}
                                        >
                                          <th
                                            className={handleFontSize(
                                              "px-2 py-4 text-center whitespace-nowrap",
                                              fontSize
                                            )}
                                            style={{
                                              minWidth: "83.72px",
                                              maxWidth: "83.72px", //ADDED TO MATCH COL WIDTH
                                            }}
                                            onClick={() => {
                                              // console.log("Hello");
                                              // console.log(bindList.current.props.scrollToIndex);
                                              // bindList.current.scrollToRow(6);
                                              console.log(
                                                document.getElementById(
                                                  "tableInfoList"
                                                ).clientHeight
                                              );
                                            }}
                                          ></th>

                                          {taggedColumns &&
                                            taggedColumns?.map(
                                              (column, index) => {
                                                return parseInt(
                                                  column.accessorKey
                                                ) === 0 ? ( // Date Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        key={`column-header-${index}`}
                                                        className={handleFontSize(
                                                          "px-0 py-0 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                        style={{
                                                          minWidth: "120px",
                                                          maxWidth: "120px",
                                                        }}
                                                      >
                                                        {
                                                          // briefName === "Invoices" ? "Date of Invoice" : briefName === "Progress Photos" ? "Date of Photo Taken" : column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 1 ? ( // Gmail Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                        style={{
                                                          width: "70px",
                                                          minWidth: "70px",
                                                        }}
                                                        data-tip={`Inbox(${
                                                          background.filter(
                                                            (bg) =>
                                                              bg?.files?.items?.some(
                                                                (item) =>
                                                                  item.gmailMessage?.gmailLabelIds?.includes(
                                                                    "INBOX"
                                                                  )
                                                              )
                                                          ).length
                                                        }) | Sent(${
                                                          background.filter(
                                                            (bg) =>
                                                              bg?.files?.items?.some(
                                                                (item) =>
                                                                  item.gmailMessage?.gmailLabelIds?.includes(
                                                                    "SENT"
                                                                  )
                                                              )
                                                          ).length
                                                        })`}
                                                        data-for="rowCount"
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 2 ? ( // Description Column
                                                  <>
                                                    {column.visible && (
                                                      <th className="flex">
                                                        <div
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap",
                                                            fontSize
                                                          )}
                                                          style={{
                                                            width: "100%",
                                                            minWidth: "100px",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                              "ellipsis",
                                                          }}
                                                        >
                                                          {
                                                            // briefName === "Invoices" ? "Description of Invoice" : column.header
                                                          }
                                                        </div>
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 3 ? ( // Comments Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : column.header ===
                                                  "Label" ? ( // Label Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap w-full",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 4 ? ( // Document Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : column.header ===
                                                  "Email Date" ? ( // Email Date Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap w-full",
                                                          fontSize
                                                        )}
                                                      ></th>
                                                    )}
                                                  </>
                                                ) : (
                                                  // Dynamic Columns
                                                  !defaultColumnIds.includes(
                                                    column.accessorKey
                                                  ) && (
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={handleFontSize(
                                                            column.header ===
                                                              "Variation Cost ($)"
                                                              ? "inline-block px-2 py-4 text-center whitespace-nowrap w-full"
                                                              : "px-2 py-4 text-center whitespace-nowrap w-full",
                                                            fontSize
                                                          )}
                                                        >
                                                          {column.header !==
                                                            "Paid to Date Amount" &&
                                                          column.header !==
                                                            "Action" ? (
                                                            <p
                                                              style={{
                                                                visibility:
                                                                  "collapse",
                                                              }}
                                                            >
                                                              {column.header}
                                                            </p>
                                                          ) : column.header ===
                                                            "Paid to Date Amount" ? (
                                                            <p>
                                                              {" "}
                                                              &nbsp; &nbsp;
                                                              &nbsp; &nbsp;
                                                              &nbsp;
                                                              &nbsp;Amount
                                                              &nbsp; &nbsp;
                                                              &nbsp; &nbsp;
                                                              &nbsp; &nbsp;
                                                            </p>
                                                          ) : null}
                                                        </th>
                                                      )}
                                                    </>
                                                  )
                                                );
                                              }
                                            )}
                                          <th className="px-2 py-4 text-center whitespace-nowrap w-full"></th>
                                        </tr>
                                      )}

                                      {/* Sub Header for Provisional Sum */}
                                      {(briefName === "Provisional Sum" ||
                                        briefName === "Budget" ||
                                        briefName === "Prime Cost") && (
                                        <tr
                                          style={{
                                            visibility:
                                              index > 0
                                                ? "collapse"
                                                : "visible",
                                          }}
                                          className={`${
                                            briefName === "Budget"
                                              ? "hidden"
                                              : ""
                                          }`}
                                        >
                                          <th
                                            className={handleFontSize(
                                              "px-2 py-4 text-center whitespace-nowrap",
                                              fontSize
                                            )}
                                            style={{
                                              minWidth: "83.72px",
                                              maxWidth: "83.72px", //ADDED TO MATCH COL WIDTH
                                            }}
                                            onClick={() => {
                                              // console.log("Hello");
                                              // console.log(bindList.current.props.scrollToIndex);
                                              // bindList.current.scrollToRow(6);
                                              console.log(
                                                document.getElementById(
                                                  "tableInfoList"
                                                ).clientHeight
                                              );
                                            }}
                                          ></th>

                                          {taggedColumns &&
                                            taggedColumns?.map(
                                              (column, index) => {
                                                return parseInt(
                                                  column.accessorKey
                                                ) === 0 ? ( // Date Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        key={`column-header-${index}`}
                                                        className={handleFontSize(
                                                          "px-0 py-0 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                        style={{
                                                          minWidth: "120px",
                                                          maxWidth: "120px",
                                                        }}
                                                      >
                                                        {
                                                          // briefName === "Invoices" ? "Date of Invoice" : briefName === "Progress Photos" ? "Date of Photo Taken" : column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 1 ? ( // Gmail Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={`${handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )} ${
                                                          briefName === "Budget"
                                                            ? "hidden"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          width: "70px",
                                                          minWidth: "70px",
                                                        }}
                                                        data-tip={`Inbox(${
                                                          background.filter(
                                                            (bg) =>
                                                              bg?.files?.items?.some(
                                                                (item) =>
                                                                  item.gmailMessage?.gmailLabelIds?.includes(
                                                                    "INBOX"
                                                                  )
                                                              )
                                                          ).length
                                                        }) | Sent(${
                                                          background.filter(
                                                            (bg) =>
                                                              bg?.files?.items?.some(
                                                                (item) =>
                                                                  item.gmailMessage?.gmailLabelIds?.includes(
                                                                    "SENT"
                                                                  )
                                                              )
                                                          ).length
                                                        })`}
                                                        data-for="rowCount"
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 2 ? ( // Description Column
                                                  <>
                                                    {column.visible && (
                                                      <th className="flex">
                                                        <div
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap",
                                                            fontSize
                                                          )}
                                                          style={{
                                                            width: "100%",
                                                            minWidth: "100px",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                              "ellipsis",
                                                          }}
                                                        >
                                                          {
                                                            // briefName === "Invoices" ? "Description of Invoice" : column.header
                                                          }
                                                        </div>
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 3 ? ( // Comments Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : column.header ===
                                                  "Label" ? ( // Label Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap w-full",
                                                          fontSize
                                                        )}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : parseInt(
                                                    column.accessorKey
                                                  ) === 4 ? ( // Document Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={`${handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap",
                                                          fontSize
                                                        )} ${
                                                          briefName === "Budget"
                                                            ? "hidden"
                                                            : ""
                                                        }`}
                                                      >
                                                        {
                                                          // column.header
                                                        }
                                                      </th>
                                                    )}
                                                  </>
                                                ) : column.header ===
                                                  "Email Date" ? ( // Email Date Column
                                                  <>
                                                    {column.visible && (
                                                      <th
                                                        className={handleFontSize(
                                                          "px-2 py-4 text-center whitespace-nowrap w-full",
                                                          fontSize
                                                        )}
                                                      ></th>
                                                    )}
                                                  </>
                                                ) : (
                                                  // Dynamic Columns
                                                  !defaultColumnIds.includes(
                                                    column.accessorKey
                                                  ) && (
                                                    <>
                                                      {column.visible && (
                                                        <th
                                                          className={handleFontSize(
                                                            "px-2 py-4 text-center whitespace-nowrap w-full",
                                                            fontSize
                                                          )}
                                                        >
                                                          {column.header ===
                                                          "Provisional Amount"
                                                            ? ""
                                                            : column.header ===
                                                              "Variation Cost"
                                                            ? "Variation Cost ($)"
                                                            : column.header}
                                                        </th>
                                                      )}
                                                    </>
                                                  )
                                                );
                                              }
                                            )}
                                          <th className="px-2 py-4 text-center whitespace-nowrap w-full"></th>
                                        </tr>
                                      )}

                                      <Draggable
                                        key={item.id + "-" + index}
                                        draggableId={item.id + "-" + index}
                                        index={index}
                                        isDragDisabled={!allowDragNDrop || true}
                                      >
                                        {(provider, snapshot) => (
                                          <tr
                                            className={
                                              selectRow.find(
                                                (x) => x.id === item.id
                                              )
                                                ? "bg-green-300"
                                                : index === highlightRow - 1
                                                ? "bg-green-300"
                                                : ""
                                            }
                                            onMouseEnter={() =>
                                              setHoveredRow(item.id)
                                            }
                                            onMouseLeave={() =>
                                              setHoveredRow(null)
                                            }
                                            index={index}
                                            key={`row-${item.id}`}
                                            {...provider.draggableProps}
                                            ref={provider.innerRef}
                                            style={{
                                              ...provider.draggableProps.style,
                                              backgroundColor: justChangedLabel
                                                ? "rgba(255, 255, 239, 0.01)"
                                                : snapshot.isDragging ||
                                                  item.id === selected
                                                ? "rgba(255, 255, 239, 0.767)"
                                                : "",
                                              top: "auto !important",
                                              // borderBottom: "1px solid #E4E7E7",
                                              borderTop: "1px solid #E4E7E7",
                                              height:
                                                100 +
                                                (typeof item?.files?.items
                                                  ?.length === "number"
                                                  ? Math.min(
                                                      item?.files?.items.length,
                                                      6
                                                    ) * 30
                                                  : 0) +
                                                "px",
                                            }}
                                          >
                                            <td
                                              {...provider.dragHandleProps}
                                              className="w-8 px-1 py-3 align-top"
                                              style={{
                                                minWidth: "83.72px", //added MMA-1022
                                              }}
                                            >
                                              <div
                                                className={`flex items-center
																											${
                                                        hoveredRow ===
                                                          item.id ||
                                                        selectRow.find(
                                                          (x) =>
                                                            x.id === item.id
                                                        )
                                                          ? ""
                                                          : "justify-center"
                                                      }`}
                                              >
                                                {hoveredRow === item.id ||
                                                selectRow.find(
                                                  (x) => x.id === item.id
                                                ) ? (
                                                  <div
                                                    className={`flex items-center`}
                                                  >
                                                    {allowDragNDrop && (
                                                      <MdDragIndicator
                                                        className="item-no mr-0 text-2xl text-gray-500"
                                                        onClick={() =>
                                                          handleChangeBackground(
                                                            item.id
                                                          )
                                                        }
                                                      />
                                                    )}

                                                    <input
                                                      className="m-1 cursor-pointer"
                                                      onChange={
                                                        (e) =>
                                                          handleSelectItem(
                                                            e,
                                                            index
                                                          ) //Overlapping files
                                                      }
                                                      onClick={() =>
                                                        setIsActiveCell(null)
                                                      }
                                                      type="checkbox"
                                                      checked={selectedItems.includes(
                                                        item.id
                                                      )}
                                                      value={item.id}
                                                      id={`item-${item.id}`}
                                                    />
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                                <label
                                                  htmlFor="checkbox-1"
                                                  className={handleFontSize(
                                                    "font-medium text-gray-900 dark:text-gray-300 ml-1",
                                                    fontSize
                                                  )}
                                                >
                                                  {index + 1}
                                                  {/* &nbsp;&mdash;&nbsp; {item.order} */}
                                                </label>
                                              </div>
                                            </td>

                                            {taggedColumns &&
                                              taggedColumns?.map(
                                                (column, key) => {
                                                  return parseInt(
                                                    column.accessorKey
                                                  ) === 0 ? ( // Date Column
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          className="py-3 align-top"
                                                          {...provider.dragHandleProps}
                                                          style={{
                                                            width: "120px",
                                                            minWidth: "120px",
                                                            // border:
                                                            //   isActiveCell === "Date" &&
                                                            //   selectedCell === item.id
                                                            //     ? "1px solid #1CC1E9"
                                                            //     : "" ,
                                                            WebkitBoxShadow:
                                                              isActiveCell ===
                                                                "Date" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            MozBoxShadow:
                                                              isActiveCell ===
                                                                "Date" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            boxShadow:
                                                              isActiveCell ===
                                                                "Date" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                          }}
                                                          onClick={() =>
                                                            handleCell(
                                                              "Date",
                                                              item.id
                                                            )
                                                          }
                                                          onBlur={() =>
                                                            setIsActiveCell(
                                                              null
                                                            )
                                                          }
                                                        >
                                                          {briefName ===
                                                          "Budget" ? (
                                                            <p className="text-xs font-normal text-gray-500 mb-2">
                                                              Date
                                                            </p>
                                                          ) : null}
                                                          <div className="z-50 absolute"
                                                          //className="custom-datepicker-container"
                                                          >
                                                            {/* <input type="date" id="datePicker" name="date"></input> */}
                                                            <DatePicker
                                                              timeZoneOffsetInMinutes
                                                              utcOffset={0}
                                                              className={handleFontSize(
                                                                "border-none bg-transparent outline-none py-2 px-1 mb-5 z-50 text-sm text-center",
                                                                fontSize
                                                              )}
                                                              popperPlacement="bottom"
                                                              selected={
                                                                item.date !==
                                                                  null &&
                                                                item.date !==
                                                                  "" &&
                                                                item.date[0] !==
                                                                  "+"
                                                                  ? timezoneFormatter(
                                                                      item?.date,
                                                                      localStorage.getItem(
                                                                        "preferredTimezone"
                                                                      ) ==
                                                                        "null"
                                                                        ? momentTZ.tz.guess()
                                                                        : localStorage.getItem(
                                                                            "preferredTimezone"
                                                                          )
                                                                    )
                                                                  : null
                                                              }
                                                              dateFormat={datePickerDateFormatter(
                                                                localStorage.getItem(
                                                                  "userpreferredDateFormatType"
                                                                ) == "null"
                                                                  ? "MM-DD-YYYY"
                                                                  : localStorage.getItem(
                                                                      "userpreferredDateFormatType"
                                                                    )
                                                              )}
                                                              placeholderText="No Date"
                                                              onChange={(
                                                                selected
                                                              ) => {
                                                                handleChangeDate(
                                                                  selected,
                                                                  item.id,
                                                                  item.date,
                                                                  index
                                                                );
                                                              }}
                                                              //onChange={(e)=>console.log("format", e)}
                                                              disabled={
                                                                !allowEditDate
                                                              }
                                                              style={{
                                                                borderColor:
                                                                  "white",
                                                                zIndex: 1000
                                                              }}
                                                              //popperPlacement="bottom" // Optional: specify where the popper should appear
                                                              popperModifiers={[
                                                                {
                                                                  name: 'offset',
                                                                  options: {
                                                                    offset: [10, 10],
                                                                  },
                                                                },
                                                                {
                                                                  name: 'preventOverflow',
                                                                  options: {
                                                                    rootBoundary: 'viewport',
                                                                    tether: false,
                                                                    altAxis: true,
                                                                  },
                                                                },
                                                              ]}
                                                              portalId="root-portal" // Optional: specify a portal to render the calendar in
                                                              wrapperClassName="custom-datepicker-wrapper"

                                                            />
                                                          </div>
                                                        </td>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 1 ? (
                                                    // Gmail Column
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          className={`${
                                                            briefName ===
                                                            "Budget"
                                                              ? "hidden "
                                                              : ""
                                                          } py-3 align-top`}
                                                          {...provider.dragHandleProps}
                                                          style={{
                                                            //added MMA-1022
                                                            width: "70px",
                                                            minWidth: "70px",
                                                            textAlign: "center",
                                                            // maxWidth: "35px",
                                                            // backgroundColor: "green"
                                                            // border:
                                                            //   isActiveCell === "Gmail" &&
                                                            //   selectedCell === item.id
                                                            //     ? "1px solid #1CC1E9"
                                                            //     : ""
                                                            WebkitBoxShadow:
                                                              isActiveCell ===
                                                                "Gmail" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            MozBoxShadow:
                                                              isActiveCell ===
                                                                "Gmail" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            boxShadow:
                                                              isActiveCell ===
                                                                "Gmail" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                          }}
                                                          onClick={() =>
                                                            handleCell(
                                                              "Gmail",
                                                              item.id
                                                            )
                                                          }
                                                          onBlur={() =>
                                                            handleCell(
                                                              null,
                                                              null
                                                            )
                                                          }
                                                        >
                                                          {/* //kjmf edit */}
                                                          {client_name ===
                                                          "Sandbox" ? (
                                                            <p className="text-xs font-normal text-gray-500 mb-2">
                                                              {gmailSentIndex.includes(
                                                                index + 1
                                                              )
                                                                ? "Sent"
                                                                : gmailNaIndex.includes(
                                                                    index + 1
                                                                  )
                                                                ? "N/A"
                                                                : gmailUploadedIndex.includes(
                                                                    index + 1
                                                                  )
                                                                ? "Uploaded"
                                                                : "Inbox"}
                                                            </p>
                                                          ) : (
                                                            <p className="text-center break-words">
                                                              {gmailStatus !==
                                                              ""
                                                                ? gmailStatus
                                                                : item?.files
                                                                    ?.items
                                                                    ?.length ===
                                                                  0
                                                                ? "N/A"
                                                                : "Uploaded"}
                                                            </p>
                                                          )}
                                                        </td>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 2 ? (
                                                    // Description Column
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          {...provider.dragHandleProps}
                                                          className={
                                                            commentAccess
                                                              ? `px-2 py-3 align-top place-items-center
																																		${longDescBriefsNameList.includes(briefName) ? "w-full" : ""}` // Check if briefName is included on Long Description Column
                                                              : `px-2 py-3 align-top place-items-center
																																		${longDescBriefsNameList.includes(briefName) ? "w-full" : ""}` // Check if briefName is included on Long Description Column
                                                          }
                                                          style={{
                                                            minWidth:
                                                              getDescColMinWidth(),
                                                            maxWidth:
                                                              getDescColMaxWidth(), //700px
                                                            // border:
                                                            //   isActiveCell === "Description" &&
                                                            //   selectedCell === item.id
                                                            //     ? "1px solid #1CC1E9"
                                                            //     : ""
                                                            WebkitBoxShadow:
                                                              isActiveCell ===
                                                                "Description" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            MozBoxShadow:
                                                              isActiveCell ===
                                                                "Description" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            boxShadow:
                                                              isActiveCell ===
                                                                "Description" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                          }}
                                                          onClick={() => {
                                                            handleCell(
                                                              "Description",
                                                              item.id
                                                            );
                                                          }}
                                                          onBlur={() =>
                                                            handleCell(
                                                              null,
                                                              null
                                                            )
                                                          }
                                                        >
                                                          {briefName ===
                                                          "Budget" ? (
                                                            <p className="text-xs font-normal text-gray-500 mb-2">
                                                              Description
                                                            </p>
                                                          ) : null}
                                                          {/* <RFIEditor item={item} /> */}
                                                          {
                                                            loaderEmail ===
                                                            item.id ? (
                                                              <>
                                                                <SkeletonTheme>
                                                                  <Skeleton
                                                                    count={1}
                                                                  />
                                                                </SkeletonTheme>
                                                                <SkeletonTheme
                                                                  width={
                                                                    "150px"
                                                                  }
                                                                >
                                                                  <Skeleton
                                                                    count={1}
                                                                    className="mt-1"
                                                                  />
                                                                </SkeletonTheme>
                                                                <SkeletonTheme
                                                                  height={
                                                                    "10px"
                                                                  }
                                                                >
                                                                  <Skeleton
                                                                    className="text-xs"
                                                                    count={1}
                                                                  />
                                                                </SkeletonTheme>
                                                                <SkeletonTheme
                                                                  width={
                                                                    "150px"
                                                                  }
                                                                >
                                                                  <Skeleton
                                                                    count={1}
                                                                    className="mt-1"
                                                                  />
                                                                </SkeletonTheme>
                                                                <SkeletonTheme
                                                                  height={
                                                                    "38px"
                                                                  }
                                                                >
                                                                  <Skeleton
                                                                    count={1}
                                                                  />
                                                                </SkeletonTheme>
                                                              </>
                                                            ) : item &&
                                                              saveDescription &&
                                                              uploadedDocumentList ? (
                                                              <>
                                                                <SlateMention
                                                                  initialContent={
                                                                    item?.description
                                                                  }
                                                                  handleSaveDesc={
                                                                    handleSaveDesc
                                                                  }
                                                                  handleChangeDesc={
                                                                    handleChangeDesc
                                                                  }
                                                                  handleDescContent={
                                                                    handleDescContent
                                                                  }
                                                                  setSaving={
                                                                    setSaving
                                                                  }
                                                                  description={
                                                                    item?.description
                                                                  }
                                                                  date={
                                                                    item?.date
                                                                  }
                                                                  id={item?.id}
                                                                  itemIndex={
                                                                    index
                                                                  }
                                                                  background={
                                                                    background
                                                                  }
                                                                  reducedTabsArray={
                                                                    reducedTabsArray
                                                                  }
                                                                  reduceUploadedFilesToArray={
                                                                    reduceUploadedFilesToArray
                                                                  }
                                                                  reducedUploadedFilesArray={
                                                                    reducedUploadedFilesArray
                                                                  }
                                                                  extractText={
                                                                    extractText
                                                                  }
                                                                  handleSaveTabElement={
                                                                    handleSaveTabElement
                                                                  }
                                                                />
                                                              </>
                                                            ) : null
                                                            // (item && saveDescription && uploadedDocumentList) ?
                                                            //   <EditableBackgroundField item={item} save={saveDescription} uploadedDocumentList={uploadedDocumentList}/>
                                                            // : null
                                                          }
                                                        </td>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 3 ? (
                                                    // Comments Column
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          {...provider.dragHandleProps}
                                                          className={
                                                            commentAccess
                                                              ? "px-2 py-3 align-top place-items-center"
                                                              : "hidden px-2 py-3 align-top place-items-center"
                                                          }
                                                          style={{
                                                            // backgroundColor: "green",
                                                            width: "270px",
                                                            minWidth: "270px",
                                                            // border:
                                                            //   isActiveCell === "Comment" &&
                                                            //   selectedCell === item.id
                                                            //     ? "1px solid #1CC1E9"
                                                            //     : ""
                                                            WebkitBoxShadow:
                                                              isActiveCell ===
                                                                "Comment" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            MozBoxShadow:
                                                              isActiveCell ===
                                                                "Comment" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            boxShadow:
                                                              isActiveCell ===
                                                                "Comment" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                          }}
                                                          onClick={() =>
                                                            handleCell(
                                                              "Comment",
                                                              item.id
                                                            )
                                                          }
                                                          onBlur={() =>
                                                            handleCell(
                                                              null,
                                                              null
                                                            )
                                                          }
                                                        >
                                                          <div
                                                            className={handleFontSize(
                                                              "p-2 w-full font-poppins rounded-sm",
                                                              fontSize
                                                            )}
                                                            style={{
                                                              cursor: "auto",
                                                              outline: "none",
                                                              // outlineColor:
                                                              //  "rgb(204, 204, 204, 0.5)",
                                                              // outlineWidth:
                                                              //  "thin",
                                                              minWidth: "100px",
                                                              width: "100%",
                                                            }}
                                                            suppressContentEditableWarning
                                                            onClick={() =>
                                                              autoAdjustRowHeight(
                                                                index
                                                              )
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                handleComment(
                                                                  item.comments
                                                                ),
                                                            }}
                                                            onInput={() =>
                                                              autoAdjustRowHeight(
                                                                index
                                                              )
                                                            }
                                                            onBlur={(e) =>
                                                              handleSaveComment(
                                                                e,
                                                                item.comments,
                                                                item.id,
                                                                index
                                                              )
                                                            }
                                                            contentEditable={
                                                              allowEditComments
                                                            }
                                                          ></div>
                                                        </td>
                                                      )}
                                                    </>
                                                  ) : parseInt(
                                                      column.accessorKey
                                                    ) === 4 ? (
                                                    // Document Column
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          {...provider.dragHandleProps}
                                                          // className="w-full px-3 py-2 text-sm text-gray-500 align-top whitespace-nowrap" //added w-full and removed w-1/6 MMA-1022
                                                          className={`${
                                                            briefName ===
                                                            "Budget"
                                                              ? "hidden "
                                                              : ""
                                                          } px-3 py-2 text-sm text-gray-500 align-top whitespace-nowrap overflow-hidden max-w-md w-md truncate`}
                                                          style={{
                                                            // border:
                                                            //   isActiveCell === "Document" &&
                                                            //   selectedCell === item.id
                                                            //     ? "1px solid #1CC1E9"
                                                            //     : ""
                                                            WebkitBoxShadow:
                                                              isActiveCell ===
                                                                "Document" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            MozBoxShadow:
                                                              isActiveCell ===
                                                                "Document" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            boxShadow:
                                                              isActiveCell ===
                                                                "Document" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                          }}
                                                          onClick={() =>
                                                            handleCell(
                                                              "Document",
                                                              item.id
                                                            )
                                                          }
                                                          onFocus={() =>
                                                            handleCell(
                                                              "Document",
                                                              item.id
                                                            )
                                                          }
                                                          onBlur={() =>
                                                            handleCell(
                                                              null,
                                                              null
                                                            )
                                                          }
                                                        >
                                                          {selectRow.find(
                                                            (x) =>
                                                              x.id === item.id
                                                          ) && (
                                                            <div className="separator">
                                                              ROW SELECTED
                                                            </div>
                                                          )}
                                                          {!activateButton ? (
                                                            !activateButton &&
                                                            selectRow.find(
                                                              (x) =>
                                                                x.id === item.id
                                                            ) ? (
                                                              // <button onClick={()=>scrollToFirstElement()}>
                                                              //   scroll</button>
                                                              <></>
                                                            ) : (
                                                              <>
                                                              {/* <button onClick={()=>scrollToFirstElement()}>
                                                                scroll</button> */}
                                                                <BackgroundIconContainer
                                                                  fileTypeCollection={
                                                                    fileTypeCollection
                                                                  }
                                                                  key={item.id}
                                                                  item={item}
                                                                  draggableType={
                                                                    item.id +
                                                                    "-" +
                                                                    index
                                                                  }
                                                                  columnIndex={
                                                                    index
                                                                  }
                                                                  chronologicalOrder={
                                                                    chronologicalOrder
                                                                  }
                                                                  iframeVariables={{
                                                                    index,
                                                                    show,
                                                                    snippetId,
                                                                    downloadLink,
                                                                    iframeLoad,
                                                                    setIframeLoad,
                                                                    previewAndDownloadPDF,
                                                                    previewAndDownloadFile,
                                                                    handleOpenAI,
                                                                    hoverClosePreviewFile,
                                                                    fileType,
                                                                    subject,
                                                                    from,
                                                                    moment,
                                                                    dateEmail,
                                                                    cc,
                                                                    preview,
                                                                    to,
                                                                    hoverEnterPreviewFile,
                                                                    hoverClosePreviewFile,
                                                                  }}
                                                                  fileBucketIds={{
                                                                    matterId:
                                                                      matterId,
                                                                    briefId:
                                                                      briefId,
                                                                    utf8_to_b64:
                                                                      utf8_to_b64,
                                                                    matter_name:
                                                                      matter_name,
                                                                    utf8_to_b64:
                                                                      utf8_to_b64,
                                                                    client_name:
                                                                      client_name,
                                                                    handleUploadLink,
                                                                    setSelectedRowID,
                                                                    setSelectedIndex,
                                                                  }}
                                                                  attachFiles={
                                                                    attachFiles
                                                                  }
                                                                  optionsFunctionVariables={{
                                                                    showModal,
                                                                    allowDeleteFiles,
                                                                    activateButton,
                                                                  }}
                                                                  showRightPanel={
                                                                    showRightPanel
                                                                  }
                                                                  setShowRightPanel={
                                                                    setShowRightPanel
                                                                  }
                                                                  setInterfaceRightPanel={
                                                                    setInterfaceRightPanel
                                                                  }
                                                                  interfaceRightPanel={
                                                                    interfaceRightPanel
                                                                  }
                                                                  rightPanelItems={
                                                                    rightPanelItems
                                                                  }
                                                                  setRightPanelItems={
                                                                    setRightPanelItems
                                                                  }
                                                                  fileView={
                                                                    fileView
                                                                  }
                                                                  background={
                                                                    background
                                                                  }
                                                                  handleOpenAI={
                                                                    handleOpenAI
                                                                  }
                                                                  openAiPrompts={
                                                                    openAiPrompts
                                                                  }
                                                                  setOpenAiPrompts={
                                                                    setOpenAiPrompts
                                                                  }
                                                                  setSummariseStart={
                                                                    setSummariseStart
                                                                  }
                                                                  allowSummarize={
                                                                    allowSummarize
                                                                  }
                                                                  willBulkSummarise={willBulkSummarise}
                                                                  selectedFilesBulkSummary={selectedFilesBulkSummary}
                                                                  setSelectedFilesBulkSummary={setSelectedFilesBulkSummary}
                                                                  rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
                                                                  setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
                                                                  setFilePreviewItems={setFilePreviewItems}
                                                                  setShowFilePreview={setShowFilePreview}
                                                                  handleSnippetEmail={handleSnippetEmail}
                                                                />
                                                              </>
                                                            )
                                                          ) : (
                                                            <ManageFiles
                                                              item={item}
                                                              index={index}
                                                              checkedRows={
                                                                checkedRows
                                                              }
                                                              selectedRowsBGFiles={
                                                                selectedRowsBGFiles
                                                              }
                                                              rowOriginal={
                                                                rowOriginal
                                                              }
                                                              rowClicked={
                                                                rowClicked
                                                              }
                                                              handleFilesCheckboxChange={
                                                                handleFilesCheckboxChange
                                                              }
                                                              pasteFilestoBackground={
                                                                pasteFilestoBackground
                                                              }
                                                            />
                                                          )}
                                                          {item?.files
                                                            ?.items === null ||
                                                          item?.files?.items
                                                            .length === 0 ? (
                                                            <>
                                                              {/** Add something here */}
                                                            </>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </td>
                                                      )}
                                                    </>
                                                  ) : column.header ===
                                                    "Label" ? (
                                                    // Label Column
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          id={
                                                            "label-cell-no-" +
                                                            index
                                                          }
                                                          {...provider.dragHandleProps}
                                                          className={handleFontSize(
                                                            "px-2 py-2 text-center whitespace-nowrap w-full align-top",
                                                            fontSize
                                                          )}
                                                          style={{
                                                            // border:
                                                            // isActiveCell === "Label" &&
                                                            // selectedCell === item.id
                                                            //   ? "1px solid #1CC1E9"
                                                            //   : ""
                                                            WebkitBoxShadow:
                                                              isActiveCell ===
                                                                "Label" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            MozBoxShadow:
                                                              isActiveCell ===
                                                                "Label" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                            boxShadow:
                                                              isActiveCell ===
                                                                "Label" &&
                                                              selectedCell ===
                                                                item.id
                                                                ? "inset 0px 0px 0px 1px #1CC1E9"
                                                                : "",
                                                          }}
                                                          onMouseEnter={() => {
                                                            currLabelCellSelected2.current =
                                                              "label-cell-no-" +
                                                              index;
                                                          }}
                                                          onMouseLeave={() => {
                                                            currLabelCellSelected2.current =
                                                              "out";
                                                          }}
                                                          // onClick={() => {
                                                          //   handleCell(
                                                          //     "Label",
                                                          //     item.id
                                                          //   );

                                                          //   setTimeout(() => {
                                                          //     let cell =
                                                          //       document.getElementById(
                                                          //         "label-cell-no-" +
                                                          //           index
                                                          //       );
                                                          //     let cellHeight =
                                                          //       cell.offsetHeight;
                                                          //     console.log(
                                                          //       "cellHeight",
                                                          //       cellHeight
                                                          //     );
                                                          //     try {
                                                          //       let popup =
                                                          //         document.getElementById(
                                                          //           "label-popup-no-" +
                                                          //             index
                                                          //         );

                                                          //       if (
                                                          //         document.getElementById(
                                                          //           "label-cell-interior-no-" +
                                                          //             index
                                                          //         )?.style
                                                          //           .height < 50
                                                          //       ) {
                                                          //         document.getElementById(
                                                          //           "label-cell-interior-no-" +
                                                          //             index
                                                          //         ).style.height =
                                                          //           cellHeight -
                                                          //           24 +
                                                          //           "px";
                                                          //       }
                                                          //       //popup.style.display = "flex";
                                                          //       if (
                                                          //         cellHeight >
                                                          //         100
                                                          //       ) {
                                                          //         popup.style.marginTop =
                                                          //           (cellHeight -
                                                          //             70) *
                                                          //             -1 +
                                                          //           "px";
                                                          //       } else {
                                                          //         popup.style.marginTop =
                                                          //           "14px";
                                                          //       }
                                                          //       // let origClassList = Array.from(document.getElementById("label-cell-no-"+index)?.classList);

                                                          //       // if (currLabelCellSelected.current == "label-cell-no-"+index) {
                                                          //       //   if (origClassList.includes("selectedLabel")) {
                                                          //       //     if (currLabelCellSelected2.current == "out" || currLabelCellSelected2.current != currLabelCellSelected.current) {
                                                          //       //       document.getElementById(currLabelCellSelected.current).classList.remove("selectedLabel");
                                                          //       //     }
                                                          //       //   } else {
                                                          //       //     document.getElementById(currLabelCellSelected.current).classList.add("selectedLabel");
                                                          //       //   }
                                                          //       // } else {
                                                          //       //   if (currLabelCellSelected.current != 0) {
                                                          //       //     document.getElementById(currLabelCellSelected.current).classList.remove("selectedLabel");
                                                          //       //   }
                                                          //       //   document.getElementById("label-cell-no-"+index).classList.add("selectedLabel");
                                                          //       // }

                                                          //       currLabelCellSelected.current =
                                                          //         "label-cell-no-" +
                                                          //         index;

                                                          //       console.log(
                                                          //         "CLICKED LABEL CELL"
                                                          //       );
                                                          //       //blockScroll();
                                                          //       //console.log(document.getElementById("creatableSelect-"+index).lastChild.firstChild.lastChild);
                                                          //       document
                                                          //         .getElementById(
                                                          //           "creatableSelect-" +
                                                          //             index
                                                          //         )
                                                          //         .lastChild.firstChild.lastChild.firstChild.focus();
                                                          //     } catch (error) {}
                                                          //   }, 5);
                                                          // }}
                                                          // onBlur={() =>
                                                          //   setIsActiveCell(
                                                          //     null
                                                          //   )
                                                          // }
                                                          onBlur={() => {setShowLabelsDropdown(false);setSelectedLabelId("");console.log("left")}}
                                                          onClick={()=>{setShowLabelsDropdown(true);setSelectedLabelId(item.id);handleCell("Label", item.id);}}
                                                        >
                                                        <div
                                                          id={
                                                            "label-cell-selected-no-" + index
                                                          }
                                                          className="pt-4 text-xs flex flex-wrap " 
                                                        >
                                                          
                                                            {isActiveCell ===
                                                                "Label" &&
                                                                selectedCell ===
                                                                  item.id ? (
                                                                    <>
                                                                      {allowShowLabels && (
                                                                        <CreatableSelect
                                                                          onBlur={()=>setShowLabelsDropdown(false)}
                                                                          defaultValue={() =>
                                                                            getRowLabels(
                                                                              item.briefs
                                                                                ? item.briefs
                                                                                : null
                                                                            )
                                                                          }
                                                                          id={ "creatableSelect-" +  index }
                                                                          menuPortalTarget={ document.body }
                                                                          ref={ creatableSelectRef }
                                                                          // menuIsOpen={
                                                                          //   true
                                                                          // }
                                                                          options={
                                                                              labels
                                                                                ? labels.filter(( elem ) => {
                                                                                    return (item.briefs?.map((item) => item.name).indexOf(elem.label) < 0);
                                                                                  }) : []
                                                                          }
                                                                          filterOption={createFilter( filterConfig )}
                                                                          maxMenuHeight={ 222 }
                                                                          isClearable={ false }
                                                                          isSearchable
                                                                          openMenuOnClick={ true }
                                                                          isMulti
                                                                          onChange={( e, action ) => {
                                                                            if (
                                                                              e.length >
                                                                              0
                                                                            ) {
                                                                              item.briefs =
                                                                                e.map(
                                                                                  (
                                                                                    sel
                                                                                  ) => {
                                                                                    return {
                                                                                      id: sel.value,
                                                                                      name: sel.label,
                                                                                    };
                                                                                  }
                                                                                );
                                                                            } else {
                                                                              item.briefs =
                                                                                [];
                                                                            }

                                                                            tagLabel(
                                                                              e,
                                                                              action,
                                                                              item.id,
                                                                              item,
                                                                              item.briefs
                                                                            );
                                                                            handleChangeLabel();
                                                                          }}
                                                                          onClick={( e ) => {
                                                                            alert(e);
                                                                          }}
                                                                          isDisabled={ !allowEditLabels }
                                                                          placeholder=""
                                                                          className="ml-1 -mt-5 fixed h-full w-full text-sm bg-white outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring "
                                                                          styles={{
                                                                            control: (provided, state) => ({
                                                                              ...provided,
                                                                              borderColor: '#d3d3d3', // Lighter gray border color
                                                                              boxShadow: state.isFocused ? 'none' : 'none', // No shadow
                                                                              '&:hover': {
                                                                                borderColor: '#d3d3d3', // No change on hover
                                                                              },
                                                                              borderWidth: 1,
                                                                            }),
                                                                            multiValue: (provided) => ({
                                                                              ...provided,
                                                                              backgroundColor: '#f0f0f0', // Light gray background for selected items
                                                                              color: '#333', // Slightly darker gray text
                                                                            }),
                                                                            multiValueLabel: (provided) => ({
                                                                              ...provided,
                                                                              color: '#333', // Darker text color
                                                                            }),
                                                                            multiValueRemove: (provided) => ({
                                                                              ...provided,
                                                                              color: '#333',
                                                                              ':hover': {
                                                                                backgroundColor: 'transparent',
                                                                                color: '#000',
                                                                              },
                                                                            }),
                                                                            menu: (provided) => ({
                                                                              ...provided,
                                                                              border: '1px solid #d3d3d3', // Lighter gray border color for menu
                                                                              boxShadow: 'none', // No shadow
                                                                              borderRadius: 0, // No rounding for the menu
                                                                            }),
                                                                            option: (provided, state) => ({
                                                                              ...provided,
                                                                              display: 'flex',
                                                                              alignItems: 'center',
                                                                              color: '#000', // Darker text color when selected
                                                                              backgroundColor: state.isSelected
                                                                                ? '#e0f7fa' // Light blue background when selected
                                                                                : state.isFocused
                                                                                ? '#e0f7fa' // Light blue background when hovered
                                                                                : '#fff', // White background when not selected or hovered
                                                                              '&:active': {
                                                                                backgroundColor: '#e0f7fa', // Light blue background when active
                                                                              },
                                                                              zIndex: 0
                                                                            }),
                                                                            dropdownIndicator: (provided) => ({
                                                                              ...provided,
                                                                              color: '#333', // Darker gray dropdown indicator
                                                                            }),
                                                                          }}
                                                                        />
                                                                      )}
                                                                    </>
                                                                  ) : 
                                                                  (
                                                                    <>
                                                                      {
                                                                        item.briefs ? (
                                                                          item
                                                                            .briefs
                                                                            .length !=
                                                                          0 ? (
                                                                            item
                                                                              .briefs
                                                                              .length <=
                                                                            3 ? (
                                                                              item.briefs.map(
                                                                                (
                                                                                  elem,
                                                                                  index
                                                                                ) => {
                                                                                  return (
                                                                                    <span
                                                                                      className="mx-1 bg-gray-200 rounded-sm text-xs mt-1 z-10"
                                                                                      style={{
                                                                                        gap: "8px",
                                                                                        padding:
                                                                                          "4px 8px",
                                                                                        backgroundColor:
                                                                                          "#F3F3F3",
                                                                                        borderRadius:
                                                                                          "5px",
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        getRowLabels(
                                                                                          item.briefs
                                                                                        )[
                                                                                          index
                                                                                        ]
                                                                                          .label
                                                                                      }
                                                                                    </span>
                                                                                  );
                                                                                }
                                                                              )
                                                                            ) : (
                                                                              <>
                                                                                {firstThreeLabels.map(
                                                                                  (
                                                                                    elem,
                                                                                    index
                                                                                  ) => {
                                                                                    return (
                                                                                      <span
                                                                                        className="mx-1 bg-gray-200 rounded-sm text-xs mt-1 z-10"
                                                                                        style={{
                                                                                          gap: "8px",
                                                                                          padding:
                                                                                            "4px 8px",
                                                                                          backgroundColor:
                                                                                            "#F3F3F3",
                                                                                          borderRadius:
                                                                                            "5px",
                                                                                        }}
                                                                                      >
                                                                                        {
                                                                                          getRowLabels(
                                                                                            firstThreeLabels
                                                                                          )[
                                                                                            index
                                                                                          ]
                                                                                            .label
                                                                                        }
                                                                                      </span>
                                                                                    );
                                                                                  }
                                                                                )}
                                                                                <span
                                                                                  className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
                                                                                  style={{
                                                                                    gap: "8px",
                                                                                    padding:
                                                                                      "4px 8px",
                                                                                    backgroundColor:
                                                                                      "#F3F3F3",
                                                                                    borderRadius:
                                                                                      "5px",
                                                                                  }}
                                                                                  data-for={
                                                                                    "extra-labels" +
                                                                                    index
                                                                                  }
                                                                                  data-tip={
                                                                                    itemBriefsHTML
                                                                                  }
                                                                                >
                                                                                  +
                                                                                  {item
                                                                                    .briefs
                                                                                    .length -
                                                                                    3}
                                                                                </span>
                                                                                <ReactTooltip
                                                                                  id={
                                                                                    "extra-labels" +
                                                                                    index
                                                                                  }
                                                                                  html={
                                                                                    true
                                                                                  }
                                                                                />
                                                                              </>
                                                                            )
                                                                          ) : (
                                                                            <span>
                                                                              ㅤ
                                                                            </span>
                                                                          ) 
                                                                        ) : (
                                                                          <span>
                                                                            ㅤ
                                                                          </span>
                                                                        ) 
                                                                      }
                                                                    </>
                                                                  )
                                                            }
                                                        </div>
                                                        </td>
                                                        // KJMFEND
                                                      )}
                                                    </>
                                                  ) : column.header ===
                                                    "Email Date" ? ( // Email Date Column
                                                    //MMA-1307: Add Email Date and Time Sent Column
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          className="py-3 align-top text-center"
                                                          {...provider.dragHandleProps}
                                                          style={{
                                                            width: "120px",
                                                            minWidth: "120px",
                                                          }}
                                                        >
                                                          {item?.files
                                                            ?.items === null ||
                                                          item?.files?.items
                                                            .length === 0 ? (
                                                            <>
                                                              <div className="w-full">
                                                                <p className="w-full">
                                                                  N/A{" "}
                                                                </p>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {/* insert function here */}

                                                              {handleEmailDate(
                                                                item
                                                              )}
                                                            </>
                                                          )}
                                                        </td>
                                                      )}
                                                    </>
                                                  ) : (
                                                    // Dynamic Columns
                                                    <>
                                                      {column.visible && (
                                                        <td
                                                          className={handleFontSize(
                                                            // column.type === "DROPDOWN" ? "px-2 py-2 whitespace-nowrap align-top" : "px-2 py-2 whitespace-nowrap w-full align-top",
                                                            "px-2 py-2 whitespace-nowrap align-top",
                                                            fontSize
                                                          )}
                                                        >
                                                          {column.type ===
                                                          "DROPDOWN" ? (
                                                            <CustomColumn
                                                              briefName={
                                                                briefName
                                                              }
                                                              columnType={
                                                                column.type
                                                              }
                                                              accessorKey={
                                                                column.accessorKey
                                                              }
                                                              setInputDynamic={
                                                                setInputDynamic
                                                              }
                                                              row={item}
                                                              updateDynamicData={
                                                                updateDynamicData
                                                              }
                                                              options={
                                                                column.optionsText
                                                              }
                                                              id={item.id}
                                                              value={
                                                                dynamicData &&
                                                                dynamicData[
                                                                  column
                                                                    .accessorKey
                                                                ] !== undefined
                                                                  ? dynamicData[
                                                                      column
                                                                        .accessorKey
                                                                    ]
                                                                  : null
                                                              }
                                                              people={people}
                                                            />
                                                          ) : column.header ===
                                                            "Action" ? (
                                                            <>
                                                              {briefName ===
                                                                "Budget" &&
                                                              column.header ===
                                                                "Action" ? (
                                                                <p className="text-xs font-normal text-gray-500 mb-2">
                                                                  Action
                                                                </p>
                                                              ) : null}

                                                              <Button
                                                                variant="primary-s"
                                                                size="sm"
                                                                onClick={() =>
                                                                  handleOpenBreakdown(
                                                                    item
                                                                  )
                                                                }
                                                              >
                                                                Add Payment
                                                              </Button>
                                                            </>
                                                          ) : column.header ===
                                                            "Paid to Date Amount" ? (
                                                            <>
                                                              {briefName ===
                                                              "Budget" ? (
                                                                <p className="text-xs font-normal text-gray-500 mb-2">
                                                                  Paid to Date
                                                                  Amount
                                                                </p>
                                                              ) : null}

                                                              <Button
                                                                variant="primary-s"
                                                                size="sm"
                                                              >
                                                                {item?.totalCost
                                                                  ? `$ ${item?.totalCost?.toFixed(
                                                                      2
                                                                    )}`
                                                                  : "$ 0.00"}
                                                              </Button>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {briefName ===
                                                                "Budget" &&
                                                              column.header ===
                                                                "Provisional Amount" ? (
                                                                <p className="text-xs font-normal text-gray-500 mb-2">
                                                                  Budget Amount
                                                                </p>
                                                              ) : null}

                                                              {briefName ===
                                                                "Budget" &&
                                                              column.header ===
                                                                "Cost Amount" ? (
                                                                <p className="text-xs font-normal text-gray-500 mb-2">
                                                                  Cost Amount
                                                                </p>
                                                              ) : null}

                                                              {briefName ===
                                                                "Budget" &&
                                                              column.header ===
                                                                "Description of Work" ? (
                                                                <p className="text-xs font-normal text-gray-500 mb-2">
                                                                  Description of
                                                                  Work
                                                                </p>
                                                              ) : null}

                                                              <CustomColumn
                                                                briefName={
                                                                  briefName
                                                                }
                                                                columnType={
                                                                  column.type
                                                                }
                                                                accessorKey={
                                                                  column.accessorKey
                                                                }
                                                                setInputDynamic={
                                                                  setInputDynamic
                                                                }
                                                                row={item}
                                                                updateDynamicData={
                                                                  updateDynamicData
                                                                }
                                                                id={item.id}
                                                                value={
                                                                  dynamicData &&
                                                                  dynamicData[
                                                                    column
                                                                      .accessorKey
                                                                  ] !==
                                                                    undefined
                                                                    ? dynamicData[
                                                                        column
                                                                          .accessorKey
                                                                      ]
                                                                    : null
                                                                }
                                                                people={people}
                                                              />
                                                            </>
                                                          )}
                                                        </td>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            <td {...provider.dragHandleProps}>
                                              <div className="flex">
                                                <button
                                                  onClick={() => {
                                                    clearFocus();
                                                    handleAddRow(item);
                                                  }}
                                                >
                                                  <HiPlus
                                                    data-tip="Add row"
                                                    style={{
                                                      margin: "5px",
                                                      fontSize: "1.5rem",
                                                      color: "#A0AEC0",
                                                    }}
                                                  />
                                                </button>
                                                <button
                                                  onClick={() =>
                                                    showDeleteRowModal(item)
                                                  }
                                                >
                                                  <HiMinus
                                                    data-tip="Delete row"
                                                    style={{
                                                      margin: "5px",
                                                      fontSize: "1.5rem",
                                                      color: "#A0AEC0",
                                                    }}
                                                  />
                                                  <ReactTooltip place="left" />
                                                </button>
                                              </div>
                                            </td>
                                            
                                          </tr>
                                        )}
                                      </Draggable>
                                      {pasteButton && selectRow.length > 0 && (
                                        <tr
                                          style={{
                                            border: "rgb(0, 204, 0) 2px dashed",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handlePasteRow(index, "paste")
                                          }
                                          className="hover:bg-green-500 hover:text-white"
                                        >
                                          <td></td>
                                          <td></td>
                                          <td className="text-center">
                                            <p>PASTE HERE</p>
                                          </td>
                                          <td></td>
                                        </tr>
                                      )}

                                      {moveButton && selectRow.length > 0 && (
                                        <tr
                                          style={{
                                            border: "rgb(0, 204, 0) 2px dashed",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleMoveRow(index)}
                                          className="hover:bg-green-500 hover:text-white"
                                        >
                                          <td></td>
                                          <td></td>
                                          <td className="text-center">
                                            <p>MOVE HERE</p>
                                          </td>
                                          <td></td>
                                        </tr>
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <div className="left-0 p-5 px-5 py-1">
                        <div className="grid w-full px-1 py-1 mb-6 h-42 justify-items-center">
                          <NoResultState
                            searchKey={searchDescription}
                            message={
                              "Check the spelling, try a more general term or look up a specific File."
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <EmptyRow search={search} />
                </>
              )}
            </div>
          </div>
        </div>
        <div></div>
        <div className="p-2"></div>
      </div>
      {showUploadModal && (
        <UploadLinkModal
          title={""}
          handleSave={handleUploadLink}
          bucketName={matterId}
          handleModalClose={handleModalClose}
        />
      )}
      {ShowModalParagraph && (
        <ModalParagraph
          setShowModalParagraph={setShowModalParagraph}
          getBackground={getBackground}
          paragraph={paragraph}
          setParagraph={setParagraph}
          setCheckedState={setCheckedState}
          background={backgroundDuplicate}
          setSelectedRowsBG={setSelectedRowsBG}
          setShowDeleteButton={setShowDeleteButton}
          API={API}
          matterId={matterId}
          setcheckAllState={setcheckAllState}
          briefId={briefId}
          briefName={briefName}
          bindList={bindList}
          cache={cache}
          setBackground={setBackground}
          setBgLoading={setBgLoading}
          setWait={setWait}
        />
      )}
      {showRemoveFileModal && (
        <RemoveModal
          handleSave={handleDelete}
          handleModalClose={handleModalClose}
          selectedRowsBG={selectedFileBG}
        />
      )}

      {showRemoveRowModal && (
        <RemoveFileModal
          handleSave={handleDeleteRow}
          handleModalClose={handleModalClose}
          selectedRowsBG={deleteRow}
        />
      )}

      {showUnviewableFileModal && (
        <DownloadUnviewablFileModal
          unviewableFileLink={unviewableFileLink}
          setUnviewableFileLink={setUnviewableFileLink}
          setShowUnviewableFileModal={setShowUnviewableFileModal}
        />
      )}

      <AddPaymentModal
        isOpen={isOpenAddPayment}
        onClose={handleOpenBreakdown}
        allowScroll={allowScroll}
        blockScroll={blockScroll}
        backgroundAsc={backgroundAsc}
        backgroundDesc={backgroundDesc}
        sortByOrder2={sortByOrder2}
        backgroundDuplicate={backgroundDuplicate}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        client_name={client_name}
        matter_name={matter_name}
        wait={wait}
        setPasteButton={setPasteButton}
        setIdList={setIdList}
        background={breakdownData}
        uploadedDocumentList={uploadedDocumentList}
        ShowModalParagraph={ShowModalParagraph}
        setShowModalParagraph={setShowModalParagraph}
        files={files}
        setBackground={setBreakdownData}
        previousBackground={prevBreakdownData}
        setPreviousBackground={setPrevBreakdownData}
        checkAllState={checkAllState}
        setcheckAllState={setcheckAllState}
        checkedState={checkedState}
        setCheckedState={setCheckedState}
        settotalChecked={settotalChecked}
        setId={setId}
        getId={getId}
        search={search}
        matterId={matter_id}
        getBackground={getBackground}
        selectedRowsBG={selectedRowsBG}
        setSelectedRowsBG={setSelectedRowsBG}
        paragraph={paragraph}
        setParagraph={setParagraph}
        setShowDeleteButton={setShowDeleteButton}
        activateButton={activateButton}
        setAscDesc={setAscDesc}
        ascDesc={ascDesc}
        setSelectedRowsBGFiles={setSelectedRowsBGFiles}
        selectedRowsBGFiles={selectedRowsBGFiles}
        setSelectedId={setSelectedId}
        selectedId={selectedId}
        pasteButton={pasteButton}
        checkDate={checkDate}
        checkDesc={checkDesc}
        checkDocu={checkDocu}
        pageSize={pageSize}
        pageSizeConst={pageSizeConst}
        loadMoreBackground={loadMoreBackground}
        selectRow={selectRow}
        setSelectRow={setSelectRow}
        setSrcIndex={setSrcIndex}
        newRow={newRow}
        setNewRow={setNewRow}
        loading={loading}
        setLoading={setLoading}
        maxLoading={maxLoading}
        sortByOrder={sortByOrder}
        SortBydate={SortBydate}
        searchDescription={searchDescription}
        holdDelete={holdDelete}
        setHoldDelete={setHoldDelete}
        setTargetRow={setTargetRow}
        targetRow={targetRow}
        highlightRow={highlightRow}
        setHighlightRow={setHighlightRow}
        pastedRows={pastedRows}
        setPastedRows={setPastedRows}
        checkedRows={checkedRows}
        setCheckedRows={setCheckedRows}
        setbindList={setbindList}
        setCache={setCache}
        setMoveButton={setMoveButton}
        moveButton={moveButton}
        fontSize={fontSize}
        bgLoading={bgLoading}
        setBgLoading={setBgLoading}
        allowEditDate={allowEditDate}
        allowEditDescription={allowEditDescription}
        allowEditComments={allowEditComments}
        allowEditLabels={allowEditLabels}
        allowEditFiles={allowEditFiles}
        allowShowLabels={allowShowLabels}
        headerHeight={headerHeight}
        commentAccess={commentAccess}
        gmailFilter={gmailFilter}
        allowDeleteFiles={allowDeleteFiles}
        allowDragNDrop={allowDragNDrop}
        columns={[]}
        setActiveColumns={setActiveColumns}
        qSearchValue={qSearchValue}
        pressedQSearch={pressedQSearch}
        totalHits={totalHits}
        setTotalHits={setTotalHits}
        qSearchPtr={qSearchPtr}
        setQSearchPtr={setQSearchPtr}
        inRow={inRow}
        reducedTabsArray={reducedTabsArray}
        setReducedTabsArray={setReducedTabsArray}
        reduceTabFilesToArray={reduceTabFilesToArray}
        reducedUploadedFilesArray={reducedUploadedFilesArray}
        setReducedUploadedFilesArray={setReducedUploadedFilesArray}
        reduceUploadedFilesToArray={reduceUploadedFilesToArray}
        currLabelCellSelected={currLabelCellSelected}
        currLabelCellSelected2={currLabelCellSelected2}
        handleChangeLabel={handleChangeLabel}
        justChangedLabel={justChangedLabel}
        setJustChangedLabel={setJustChangedLabel}
        showRightPanel={showRightPanel}
        setShowRightPanel={setShowRightPanel}
        fileView={fileView}
        setFileView={setFileView}
        setWait={setWait}
        isSiteDiary={isSiteDiary}
        // TANSTACK VIRTUALIZED
        virtualRows={virtualRows}
        rows={rows}
        modalBriefName={briefName}
        briefName={briefNameBreakdown}
        briefId={briefIdBreakdown}
        setInterfaceRightPanel={
          setInterfaceRightPanel
        }
        interfaceRightPanel={
          interfaceRightPanel
        }
      >
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="text-center">
            <div className="mt-3 text-center sm:mt-0">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Payment Breakdown
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {/* <TableInfoBreakdown
							allowScroll={allowScroll}
							blockScroll={blockScroll}
							backgroundAsc={backgroundAsc}
							backgroundDesc={backgroundDesc}
							sortByOrder2={sortByOrder2}
							backgroundDuplicate={backgroundDuplicate}
							selectedItems={selectedItems}
							setSelectedItems={setSelectedItems}
							client_name={client_name}
							matter_name={matter_name}
							wait={wait}
							setPasteButton={setPasteButton}
							setIdList={setIdList}
							background={
							  [gmailFilter == "all"
								? breakdownData
								: gmailFilter == "sent"
								? breakdownData
									.filter((bg, i) => bg.files.items.length != 0)
									.filter((fl, i) => fl.files.length != 0)
									.filter((fls, i) =>
									  fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
										"SENT"
									  )
									)
								: gmailFilter == "inbox"
								? breakdownData
									.filter((bg) => bg.files.items.length != 0)
									.filter((fl) => fl.files.length != 0)
									.filter((fls) =>
									  fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
										"INBOX"
									  )
									)
								: breakdownData.filter((row) => {
									return row.files.items.length > 0; //for uploaded
								  })]
							}
							uploadedDocumentList={uploadedDocumentList}
							ShowModalParagraph={ShowModalParagraph}
							setShowModalParagraph={setShowModalParagraph}
							files={files}
							setBackground={setBackground}
							previousBackground={previousBackground}
							setPreviousBackground={setPreviousBackground}
							checkAllState={checkAllState}
							setcheckAllState={setcheckAllState}
							checkedState={checkedState}
							setCheckedState={setCheckedState}
							settotalChecked={settotalChecked}
							setId={setId}
							getId={getId}
							search={search}
							matterId={matter_id}
							getBackground={getBackground}
							selectedRowsBG={selectedRowsBG}
							setSelectedRowsBG={setSelectedRowsBG}
							paragraph={paragraph}
							setParagraph={setParagraph}
							setShowDeleteButton={setShowDeleteButton}
							activateButton={activateButton}
							setAscDesc={setAscDesc}
							ascDesc={ascDesc}
							setSelectedRowsBGFiles={setSelectedRowsBGFiles}
							selectedRowsBGFiles={selectedRowsBGFiles}
							setSelectedId={setSelectedId}
							selectedId={selectedId}
							pasteButton={pasteButton}
							checkDate={checkDate}
							checkDesc={checkDesc}
							checkDocu={checkDocu}
							pageSize={pageSize}
							pageSizeConst={pageSizeConst}
							loadMoreBackground={loadMoreBackground}
							selectRow={selectRow}
							setSelectRow={setSelectRow}
							setSrcIndex={setSrcIndex}
							newRow={newRow}
							setNewRow={setNewRow}
							loading={loading}
							setLoading={setLoading}
							maxLoading={maxLoading}
							sortByOrder={sortByOrder}
							SortBydate={SortBydate}
							searchDescription={searchDescription}
							holdDelete={holdDelete}
							setHoldDelete={setHoldDelete}
							setTargetRow={setTargetRow}
							targetRow={targetRow}
							highlightRow={highlightRow}
							setHighlightRow={setHighlightRow}
							pastedRows={pastedRows}
							setPastedRows={setPastedRows}
							checkedRows={checkedRows}
							setCheckedRows={setCheckedRows}
							setbindList={setbindList}
							setCache={setCache}
							setMoveButton={setMoveButton}
							moveButton={moveButton}
							fontSize={fontSize}
							bgLoading={bgLoading}
							setBgLoading={setBgLoading}
							allowEditDate={allowEditDate}
							allowEditDescription={allowEditDescription}
							allowEditComments={allowEditComments}
							allowEditLabels={allowEditLabels}
							allowEditFiles={allowEditFiles}
							allowShowLabels={allowShowLabels}
							headerHeight={headerHeight}
							commentAccess={commentAccess}
							gmailFilter={gmailFilter}
							allowDeleteFiles={allowDeleteFiles}
							allowDragNDrop={allowDragNDrop}
							columns={columns}
							setActiveColumns={setActiveColumns}
							qSearchValue={qSearchValue}
							pressedQSearch={pressedQSearch}
							totalHits={totalHits}
							setTotalHits={setTotalHits}
							qSearchPtr={qSearchPtr}
							setQSearchPtr={setQSearchPtr}
							inRow={inRow}
							reducedTabsArray={reducedTabsArray}
							setReducedTabsArray={setReducedTabsArray}
							reduceTabFilesToArray={reduceTabFilesToArray}
							reducedUploadedFilesArray={reducedUploadedFilesArray}
							setReducedUploadedFilesArray={setReducedUploadedFilesArray}
							reduceUploadedFilesToArray={reduceUploadedFilesToArray}
							currLabelCellSelected={currLabelCellSelected}
							currLabelCellSelected2={currLabelCellSelected2}
							handleChangeLabel={handleChangeLabel}
							justChangedLabel={justChangedLabel}
							setJustChangedLabel={setJustChangedLabel}
							showRightPanel={showRightPanel}
							setShowRightPanel={setShowRightPanel}
							fileView={fileView}
							setFileView={setFileView}
							setWait={setWait}
							isSiteDiary={isSiteDiary}
							// TANSTACK VIRTUALIZED
							virtualRows={virtualRows}
							rows={rows}
						/> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </AddPaymentModal>

      {showToast && (
        <div
          onClick={
            goToFileBucket
              ? () =>
                  (window.location = `${
                    AppRoutes.FILEBUCKET
                  }/${matterId}/000/?matter_name=${utf8_to_b64(
                    matter_name
                  )}&client_name=${utf8_to_b64(client_name)}`)
              : holdPaste
              ? () => undoAction()
              : holdDeleteFile
              ? () => undoDeleteFile()
              : holdDeleteRow
              ? () => undoDeleteRow()
              : null
          }
        >
          <ToastNotification title={alertMessage} hideToast={hideToast} />
        </div>
      )}

      <RightPanel
        show={showRightPanel}
        handleCopyToBackground={handleCopyToBackground}
        loaderEmail={loaderEmail}
        showRightPanel={showRightPanel}
        setShowRightPanel={setShowRightPanel}
        rightPanelItems={rightPanelItems}
        setRightPanelItems={setRightPanelItems}
        handleOpenAISummaryLogs={handleOpenAI}
        addPromptHistory={addPromptHistory}
        handleSavePrompt={handleSavePrompt}
        handleSuggestedPrompts={handleSuggestedPrompts}
        previewAndDownloadFile={previewAndDownloadFile}
        fileTypeCollection={fileTypeCollection}
        setOpenAiPrompts={setOpenAiPrompts}
        openAiPrompts={openAiPrompts}
        summarizeVariables={rightPanelItems}
        summariseStart={summariseStart}
        setSummariseStart={setSummariseStart}
        handleSummariseFiles={handleSummariseFiles}
        background={background}
        setBackground={setBackground}
        setPreviousBackground={setPreviousBackground}
        allowSummarize={allowSummarize}
        handleCopyToMemory={ handleCopyToMemory}
        coreMemory={coreMemory}
        setCoreMemory={setCoreMemory}
        interfaceRightPanel={interfaceRightPanel}
        setInterfaceRightPanel={setInterfaceRightPanel}
        selectedFilesBulkSummary={selectedFilesBulkSummary}
        rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
        setShowToast={setShowToast}
        setalertMessage={setalertMessage}
        updateBackgroundHistory={updateBackgroundHistory}
        setUnreadNotifications={setUnreadNotifications}
        setWillBulkSummarise={setWillBulkSummarise}
        setSelectedFilesBulkSummary={setSelectedFilesBulkSummary}
        setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
        getBackground={getBackground}
      />
    </>
  );
};

// A box which displays the files as icons
function BackgroundIconContainer({
  fileTypeCollection,
  item,
  draggableType,
  columnIndex,
  chronologicalOrder,
  iframeVariables,
  fileBucketIds,
  attachFiles,
  optionsFunctionVariables,
  showRightPanel,
  setShowRightPanel,
  rightPanelItems,
  setRightPanelItems,
  fileView,
  background,
  handleOpenAI,
  openAiPrompts,
  setOpenAiPrompts,
  setSummariseStart,
  allowSummarize,
  willBulkSummarise,
  selectedFilesBulkSummary,
  setSelectedFilesBulkSummary,
  setInterfaceRightPanel,
  interfaceRightPanel,
  rightPanelItemsBulkSummary,
  setRightPanelItemsBulkSummary,
  setFilePreviewItems,
  setShowFilePreview,
  handleSnippetEmail
}) {
  const [openFilesPopup, setOpenFilesPopup] = useState(false);
  const iconContainerRef = useRef(null);
  const [uploadBtnClicked2, setUploadBtnClicked2] = useState(false);
  const popupRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [percent, setPercent] = useState([]);

  const fileFormatIcons = {
    default: <BsFillFileEarmarkFill className="text-blue-400" />,
    txt: <BsFillFileEarmarkFontFill />,
    pdf: <BsFileEarmarkPdfFill className="text-red-600" />,
    docx: <BsFillFileEarmarkWordFill className="text-blue-500" />,
    "vnd.openxmlformats-officedocument.wordprocessingml.document": (
      <BsFillFileEarmarkWordFill className="text-blue-500" />
    ),
    xlsx: <BsFillFileEarmarkExcelFill className="text-green-500" />,
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
      <BsFillFileEarmarkExcelFill className="text-green-500" />
    ),
    pptx: <BsFillFileEarmarkPptFill className="text-orange-600" />,
    "vnd.openxmlformats-officedocument.presentationml.presentation": (
      <BsFillFileEarmarkPptFill className="text-orange-600" />
    ),
    csv: <FaFileCsv className="text-green-400" />,
    png: <BsFillFileEarmarkImageFill className="text-blue-700" />,
    jpg: <BsFillFileEarmarkImageFill className="text-orange-700" />,
    jpeg: <BsFillFileEarmarkImageFill className="text-orange-700" />,
    mp4: <BsFillFileEarmarkPlayFill className="text-blue-400" />,
    mov: <BsFillFileEarmarkPlayFill className="text-blue-400" />,
    mp3: <BsFillFileEarmarkMusicFill />,
  };

  const fileType = (items) =>
    fileTypeCollection
      ?.filter((fileItem) => fileItem.id === items.id)[0]
      ?.type?.split("/")[1];

  const optionsRef = useRef(null);
  // useEffect(() => {
  //   function isDescendant(parent, child) {
  //     let node = child;
  //     while (node !== null) {
  //       if (node === parent) {
  //         return true;
  //       }
  //       node = node.parentNode;
  //     }
  //     return false;
  //   }

  //   function handleClickOutside(event) {
  //     const isOptionsClick =
  //       optionsRef?.current && optionsRef?.current?.contains(event.target);
  //     const isInsidePopup =
  //       popupRef?.current && popupRef?.current.contains(event.target);
  //     const isInsideIconContainer = isDescendant(
  //       iconContainerRef?.current,
  //       event.target
  //     );
  //     if (!isOptionsClick && !isInsidePopup && !isInsideIconContainer) {
  //       setOpenFilesPopup(false);
  //     }
  //   }
  //   //document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     //document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [popupRef, optionsRef]);

  const {
    matterId,
    briefId,
    utf8_to_b64,
    matter_name,
    client_name,
    handleUploadLink,
    setSelectedRowID,
    setSelectedIndex,
  } = fileBucketIds;

  const uploadBtnRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      console.log("Files uploaded are " + files);
      setOpenFilesPopup(true);
      setDirectDrop(files);
    }
  };
  const [directDrop, setDirectDrop] = useState([]);
  return (
    <div className="relative">
      {fileView === 1 ? (
        //List View
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="w-90 flex flex-col"
        >
          <div className="flex-1">
            <BackgroundFilePopup
              key={item.id + "_1"}
              item={item}
              draggableType={draggableType}
              columnIndex={columnIndex}
              chronologicalOrder={chronologicalOrder}
              iframeVariables={iframeVariables}
              openFilesPopup={openFilesPopup}
              setUploadBtnClicked2={setUploadBtnClicked2}
              setOpenFilesPopup={setOpenFilesPopup}
              iconContainerRef={iconContainerRef}
              fileBucketIds={fileBucketIds}
              attachFiles={attachFiles}
              optionsFunctionVariables={optionsFunctionVariables}
              showRightPanel={showRightPanel}
              setShowRightPanel={setShowRightPanel}
              rightPanelItems={rightPanelItems}
              setRightPanelItems={setRightPanelItems}
              fileView={fileView}
              background={background}
              directDrop={directDrop}
              setDirectDrop={setDirectDrop}
              handleOpenAI={handleOpenAI}
              percent={percent}
              setPercent={setPercent}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              openAiPrompts={openAiPrompts}
              setOpenAiPrompts={setOpenAiPrompts}
              willBulkSummarise={willBulkSummarise}
              selectedFilesBulkSummary={selectedFilesBulkSummary}
              setSelectedFilesBulkSummary={setSelectedFilesBulkSummary}
              interfaceRightPanel={interfaceRightPanel}
              setInterfaceRightPanel={setInterfaceRightPanel}
              rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
              setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
              setFilePreviewItems={setFilePreviewItems}
              setShowFilePreview={setShowFilePreview}
              handleSnippetEmail={handleSnippetEmail}
            />
            <BackgroundFileList
              key={item.id}
              item={item}
              draggableType={draggableType}
              chronologicalOrder={chronologicalOrder}
              iframeVariables={iframeVariables}
              openFilesPopup={openFilesPopup}
              setOpenFilesPopup={setOpenFilesPopup}
              iconContainerRef={iconContainerRef}
              fileBucketIds={fileBucketIds}
              optionsFunctionVariables={optionsFunctionVariables}
              showRightPanel={showRightPanel}
              setShowRightPanel={setShowRightPanel}
              rightPanelItems={rightPanelItems}
              setRightPanelItems={setRightPanelItems}
              optionsRef={optionsRef}
              fileView={fileView}
              handleOpenAI={handleOpenAI}
              percent={percent}
              selectedFiles={selectedFiles}
              openAiPrompts={openAiPrompts}
              setOpenAiPrompts={setOpenAiPrompts}
              setSummariseStart={setSummariseStart}
              allowSummarize={allowSummarize}
              willBulkSummarise={willBulkSummarise}
              selectedFilesBulkSummary={selectedFilesBulkSummary}
              setSelectedFilesBulkSummary={setSelectedFilesBulkSummary}
              interfaceRightPanel={interfaceRightPanel}
              setInterfaceRightPanel={setInterfaceRightPanel}
              rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
              setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
              background={background}
              setFilePreviewItems={setFilePreviewItems}
              setShowFilePreview={setShowFilePreview}
              handleSnippetEmail={handleSnippetEmail}
            />
          </div>
        </div>
      ) : (
        <button
          ref={iconContainerRef}
          className="mt-3 "
          style={{ minWidth: `12rem` }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={() => setOpenFilesPopup(!openFilesPopup)}
        >
          <div
            className={`p-3 flex border-2 ${
              openFilesPopup
                ? "border-cyan-300"
                : "border-transparent hover:border-gray-200"
            }`}
            style={{
              overflow: "auto",
            }}
          >
            <ReactTooltip
              id="attachName"
              type="dark"
              place="top"
              effect="solid"
            />
            <div className=" flex-1 ml-5 flex justify-center gap-3">
              {chronologicalOrder(item?.files?.items).map((items, index) =>
                items && items?.length !== 0 && index < 4 ? (
                  <span key={index} data-tip={items.name} data-for="attachName">
                    {
                      //console.log(fileTypeCollection?.filter((ftc)=>ftc.id===items.id)[0]?.type?.split("/")[1])
                    }
                    {
                      //File Icon Implementation

                      fileFormatIcons[
                        Object.keys(fileFormatIcons).filter((key) =>
                          key.includes(fileType(items))
                        ).length
                          ? fileTypeCollection
                              ?.filter((ftc) => ftc.id === items.id)[0]
                              ?.type?.split("/")[1]
                          : "default"
                      ]
                    }
                  </span>
                ) : null
              )}
            </div>

            <div className=" w-7">
              {chronologicalOrder(item?.files?.items).length > 4 && (
                <span className="">
                  +{chronologicalOrder(item?.files?.items).length - 4}
                </span>
              )}
            </div>
          </div>
          <span className="text-xs text-gray-400">
            {" "}
            <b>Drag & Drop</b> to Upload
          </span>
        </button>
      )}

      {/* {openFilesPopup ? (
        <BackgroundFilePopup
          key={item.id + "_1"}
          item={item}
          draggableType={draggableType}
          columnIndex={columnIndex}
          chronologicalOrder={chronologicalOrder}
          iframeVariables={iframeVariables}
          openFilesPopup={openFilesPopup}
          setUploadBtnClicked2={setUploadBtnClicked2}
          setOpenFilesPopup={setOpenFilesPopup}
          iconContainerRef={iconContainerRef}
          fileBucketIds={fileBucketIds}
          attachFiles={attachFiles}
          optionsFunctionVariables={optionsFunctionVariables}
          showRightPanel={showRightPanel}
          setShowRightPanel={setShowRightPanel}
          rightPanelItems={rightPanelItems}
          setRightPanelItems={setRightPanelItems}
          fileView={fileView}
          background={background}
          directDrop={directDrop}
          setDirectDrop={setDirectDrop}
        />
      ) : null} */}
    </div>
  );
}

//Drop down for the upload button in List View
function BackgroundListUpload({
  popupRef,
  background,
  iframeVariables,
  fileBucketIds,
  item,
  columnIndex,
  attachFiles,
  setUploadBtnClicked2,
  setOpenFilesPopup,
  uploadBtnRef,
  willBulkSummarise,
  rightPanelItemsBulkSummary,
  setRightPanelItemsBulkSummary
}) {
  const {
    matterId,
    briefId,
    utf8_to_b64,
    matter_name,
    client_name,
    handleUploadLink,
    setSelectedRowID,
    setSelectedIndex,
  } = fileBucketIds;

  useEffect(() => {
    function handleClickOutside(event) {
      const isUploadClick =
        uploadBtnRef?.current && uploadBtnRef?.current?.contains(event.target);
      const isInsidePopup =
        popupRef?.current && popupRef?.current.contains(event.target);
      if (!isUploadClick && !isInsidePopup) {
        setUploadBtnClicked2(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, uploadBtnRef]);

  return (
    <div
      ref={popupRef}
      className={
        "absolute bg-white h-96 overflow-y-scroll p-3 w-80 flex flex-col gap-2 border rounded-md shadow-md z-50 cursor-default"
      }
      style={
        background?.length === iframeVariables.index + 1 ||
        background?.length - 1 === iframeVariables.index + 1
          ? { transform: `translate(-30%, -120%)` }
          : { transform: `translate(-30%, 0%)` }
      }
    >
      <p className=" p-1 border-b-2 border-gray-200 text-xs font-semibold text-gray-300">
        UPLOAD DIRECTLY OR &nbsp;
        <button
          className=" font-semibold text-cyan-400 "
          onClick={() =>
            (window.location = `${
              AppRoutes.FILEBUCKET
            }/${matterId}/${briefId}/?matter_name=${utf8_to_b64(
              matter_name
            )}&client_name=${utf8_to_b64(client_name)}&background_id=${
              item.id
            }`)
          }
        >
          UPLOAD FROM FILE BUCKET
        </button>
      </p>
      <BackgroundPopupFileUpload
        handleUploadLink={handleUploadLink}
        matterId={matterId}
        item={item}
        columnIndex={columnIndex}
        attachFiles={attachFiles}
        setUploadBtnClicked2={setUploadBtnClicked2}
        setOpenFilesPopup={setOpenFilesPopup}
        setSelectedRowID={setSelectedRowID}
        setSelectedIndex={setSelectedIndex}
        willBulkSummarise={willBulkSummarise}
        rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
        setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}

      />
    </div>
  );
}

// Dropdown popup that appears when clicking on the file icons container
function BackgroundFilePopup({
  item,
  draggableType,
  columnIndex,
  chronologicalOrder,
  iframeVariables,
  openFilesPopup,
  setUploadBtnClicked2,
  setOpenFilesPopup,
  iconContainerRef,
  fileBucketIds,
  attachFiles,
  optionsFunctionVariables,
  showRightPanel,
  setShowRightPanel,
  rightPanelItems,
  setRightPanelItems,
  fileView,
  background,
  directDrop,
  setDirectDrop,
  handleOpenAI,
  percent,
  setPercent,
  selectedFiles,
  setSelectedFiles,
  willBulkSummarise,
  selectedFilesBulkSummary,
  setSelectedFilesBulkSummary,
  interfaceRightPanel,
  setInterfaceRightPanel,
  rightPanelItemsBulkSummary,
  setRightPanelItemsBulkSummary,
  setFilePreviewItems,
  setShowFilePreview,
  handleSnippetEmail
}) {
  const {
    matterId,
    briefId,
    utf8_to_b64,
    matter_name,
    client_name,
    handleUploadLink,
    setSelectedRowID,
    setSelectedIndex,
  } = fileBucketIds;

  const { hoverClosePreviewFile } = iframeVariables;

  const popupRef = useRef(null);
  const optionsRef = useRef(null);
  const [uploadBtnClicked, setUploadBtnClicked] = useState(false);

  // useEffect(() => {
  //   function isDescendant(parent, child) {
  //     let node = child;
  //     while (node !== null) {
  //       if (node === parent) {
  //         return true;
  //       }
  //       node = node.parentNode;
  //     }
  //     return false;
  //   }

  //   function handleClickOutside(event) {
  //     var openNewTabElement = document.getElementById("openNewTab");
  //     const isOpenNewTabButton = event.target.contains(openNewTabElement);

  //     const isOptionsClick =
  //       optionsRef?.current && optionsRef?.current?.contains(event.target);
  //     const isInsidePopup =
  //       popupRef?.current && popupRef?.current?.contains(event.target);
  //     const isInsideIconContainer = isDescendant(
  //       iconContainerRef?.current,
  //       event.target
  //     );

  //     if (
  //       !isOptionsClick &&
  //       !isInsidePopup &&
  //       !isInsideIconContainer &&
  //       !isOpenNewTabButton
  //     ) {
  //       hoverClosePreviewFile();
  //       setOpenFilesPopup(false);
  //       setDirectDrop([]);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [popupRef, optionsRef]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgreen" : "",
    ...draggableStyle,
  });
  //MMA-2265: Returned Drag and Drop for File Upload
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      console.log("Files uploaded are " + files);
      setUploadBtnClicked(true);
      setDropTrigger(files);
    }
  };

  const [dropTrigger, setDropTrigger] = useState([]);
  //end of MMA-2265: Returned Drag and Drop for File Upload
  return (
    <div
      ref={popupRef}
      className={
        "  bg-white h-auto p-3 w-full gap-2 rounded-md z-30 cursor-default"
      }
      // style={
      //   (background?.length === iframeVariables.index + 1 &&
      //     background?.length > 2) ||
      //   (background?.length - 1 === iframeVariables.index + 1 &&
      //     background?.length > 3)
      //     ? { transform: `translate(-30%, -120%)` }
      //     : { transform: `translate(-30%, 0%)` }
      // }
      //onClick={()=>{console.log("bgl",background?.length);console.log("bgl2",iframeVariables.index+1);}}
    >
      <p className="hidden p-1 border-b-2 border-gray-200 text-xs font-semibold text-gray-300">
        UPLOAD DIRECTLY OR &nbsp;
        <button
          className=" font-semibold text-cyan-400 "
          onClick={() =>
            (window.location = `${
              AppRoutes.FILEBUCKET
            }/${matterId}/${briefId}/?matter_name=${utf8_to_b64(
              matter_name
            )}&client_name=${utf8_to_b64(client_name)}&background_id=${
              item.id
            }`)
          }
        >
          UPLOAD FROM FILE BUCKET
        </button>
      </p>

      {uploadBtnClicked || fileView || directDrop.length > 0 ? (
        <BackgroundPopupFileUpload
          handleUploadLink={handleUploadLink}
          matterId={matterId}
          item={item}
          columnIndex={columnIndex}
          attachFiles={attachFiles}
          setUploadBtnClicked2={setUploadBtnClicked2}
          setOpenFilesPopup={setOpenFilesPopup}
          setSelectedRowID={setSelectedRowID}
          setSelectedIndex={setSelectedIndex}
          dropTrigger={dropTrigger}
          setDirectDrop={setDirectDrop}
          directDrop={directDrop}
          percent={percent}
          setPercent={setPercent}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          willBulkSummarise={willBulkSummarise}
          rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
          setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}

        />
      ) : (
        <>
          {/* MMA-2265 Drag and Drop File Upload */}
          <div
            ref={drop}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => setUploadBtnClicked(true)}
            className="justify-center items-center h-36 mx-auto border p-4 border-dashed rounded-md border-cyan-400"
          >
            <BiCloudUpload className="mx-auto" size={70} />
            <p className="mx-auto text-sm text-center  text-cyan-700 font-semibold">
              Drag & drop files here or click to browse.
            </p>
            {/* <input type="file" value=""/> */}
          </div>
          <div className="flex-1 z-30">
            <BackgroundFileList
              key={item.id}
              item={item}
              draggableType={draggableType}
              chronologicalOrder={chronologicalOrder}
              iframeVariables={iframeVariables}
              openFilesPopup={openFilesPopup}
              setOpenFilesPopup={setOpenFilesPopup}
              iconContainerRef={iconContainerRef}
              optionsRef={optionsRef}
              fileBucketIds={fileBucketIds}
              optionsFunctionVariables={optionsFunctionVariables}
              showRightPanel={showRightPanel}
              setShowRightPanel={setShowRightPanel}
              rightPanelItems={rightPanelItems}
              setRightPanelItems={setRightPanelItems}
              fileView={fileView}
              selectedFiles={selectedFiles}
              percent={percent}
              handleOpenAI={handleOpenAI}
              willBulkSummarise={willBulkSummarise}
              selectedFilesBulkSummary={selectedFilesBulkSummary}
              setSelectedFilesBulkSummary={setSelectedFilesBulkSummary}
              interfaceRightPanel={interfaceRightPanel}
              setInterfaceRightPanel={setInterfaceRightPanel}
              rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
              setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
              setFilePreviewItems={setFilePreviewItems}
              setShowFilePreview={setShowFilePreview}
              handleSnippetEmail={handleSnippetEmail}
            />
          </div>
        </>
      )}
    </div>
  );
}

function BackgroundPopupFileUpload({
  handleUploadLink,
  matterId,
  item,
  columnIndex,
  attachFiles,
  setUploadBtnClicked2,
  setOpenFilesPopup,
  setSelectedRowID,
  setSelectedIndex,
  dropTrigger,
  setDirectDrop,
  directDrop,
  percent,
  setPercent,
  selectedFiles,
  setSelectedFiles,
  willBulkSummarise,
  rightPanelItemsBulkSummary,
  setRightPanelItemsBulkSummary
}) {
  const rejectFiles = [".config", ".exe", ".7z", ".dll", ".exe1", ".zvz"]; // list of rejected files

  const hiddenFileInput = useRef(null);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [uploadStart, setUploadStart] = useState(false);
  const [filesReadyForUpload, setFilesReadyForUpload] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const myCurrentRef = useRef(selectedFiles);
  const updateSelectedFiles = (data) => {
    myCurrentRef.current = data;
    setSelectedFiles(data);
  };

  useEffect(() => {
    var counter = 0;
    var dCounter = 0;
    if (dropTrigger.length > 0 && counter === 0) {
      onSelectFile(dropTrigger);
      counter = 1;
    }

    if (directDrop.length > 0 && dCounter === 0) {
      onSelectFile(directDrop);
      dCounter = 1;
    }
  }, []);

  const onSelectFile = (retrievedFiles) => {
    console.log("You are here ", ...retrievedFiles);
    if (!retrievedFiles || retrievedFiles.length === 0) {
      return;
    }
    const tempArr = [];

    [...retrievedFiles].forEach((file) => {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];

      const result = rejectFiles.find((item) =>
        item.includes(re.exec(file.name)[0])
      );
      const fileSize = file.size;

      if (result || fileSize > 2147483648) {
        setInvalidFiles((prev) => [
          ...prev,
          {
            data: file,
            url: URL.createObjectURL(file),
          },
        ]);
      } else {
        tempArr.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      }
    });

    updateSelectedFiles([...myCurrentRef.current, ...tempArr]);
    setFilesReadyForUpload(true); // Indicate that files are ready for upload
  };

  var temp = [];

  const drop = useRef(null);
  // MMA-2265: Returned Drag and Drop for File Upload
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const { files } = e.dataTransfer;

    if (files && files.length) {
      console.log("Files uploaded are " + files);
      onSelectFile(files);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // end of MMA-2265
  const handleUpload = async (bucketName) => {
    setUploadStart(true);

    var tempArr = [];
    selectedFiles.map((uf) => {
      tempArr = [...tempArr, uf];
    });

    updateSelectedFiles(tempArr);
    // setSelectedFiles(tempArr);

    let currDate = Number(new Date());
    var idxx = 0;
    tempArr.map(async (uf, index) => {
      if (uf.data.name.split(".").pop() == "docx") {
        var name = uf.data.name,
          size = uf.data.size,
          type =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          key = `${bucketName}/${currDate}${name
            ?.replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      } else {
        var name = uf.data.name,
          size = uf.data.size,
          type = uf.data.type,
          key = `${bucketName}/${currDate}${name
            ?.replaceAll(/\s/g, "")
            .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
          orderSelected = idxx,
          order = idxx;
      }
      idxx = idxx + 1;

      let pageCount;

      if (type === "application/pdf") {
        // Extract page count for PDF files
        const pdfBytes = await uf.data.arrayBuffer();
        pageCount = await chunkLargePdfsForTextract({pdfBytes, bucketName, name, currDate, type});
      }

      try {
        await Storage.put(key, uf.data, {
          ...(pageCount && {
            metadata: {
              "x-amz-meta-page-count": pageCount.toString(),
            },
          }),
          contentType: type,
          progressCallback(progress) {
            const progressInPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            // console.log(`Progress: ${progressInPercentage}%, ${uf.data.name}`);

            if (temp.length > selectedFiles.length) {
              for (var i = 0; i < selectedFiles.length; i++) {
                console.log(uf.data.name === temp[i].name);
                if (temp[i].name === uf.data.name) {
                  temp[i].prog = progressInPercentage;
                }
              }
            } else {
              temp = [
                ...temp,
                { prog: progressInPercentage, name: uf.data.name },
              ];
            }
            // console.log(temp);
            setPercent(temp);
          },
          errorCallback: (err) => {
            console.error("204: Unexpected error while uploading", err);
          },
        })
          .then((fd) => {
            var fileData = {
              s3ObjectKey: fd.key,
              size: parseInt(size),
              type: type,
              name: name.split(".").slice(0, -1).join("."),
              oderSelected: orderSelected,
              order: orderSelected,
            };

            setUploadedFiles((prevState) => ({
              files: [...prevState.files, fileData],
            }));
            // const filterSelectedfiles = myCurrentRef.current.filter(function (item) {
            //   return item.data.filename === name
            // })
            // hiddenFileInput.current && (hiddenFileInput.current.value = '');
            // console.log('Filter:', filterSelectedfiles)
            setDirectDrop([]);
          })
          .catch((err) => {
            console.error("220: Unexpected error while uploading", err);
          });
      } catch (e) {
        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };
        console.error("228: Unexpected error while uploading", response);
      }
    });
  };

  function handleClick(event) {
    hiddenFileInput.current.click();
  }

  function clickPopupUploadButton() {
    handleUpload(matterId);
    setSelectedRowID(item.id);
    setSelectedIndex(columnIndex);
  }

  // useEffect(() => {
  //   console.log("TESTNOW", uploadedFiles, selectedFiles);
  // }, [uploadedFiles, selectedFiles]);

  useEffect(() => {
    const uploadAndCheck = async () => {
      if (
        uploadedFiles.files.length === selectedFiles.length &&
        selectedFiles.length !== 0
      ) {

        console.log("Uploaded FIles >>> ", uploadedFiles);

        const result = await handleUploadLink(uploadedFiles);
        if (result === "done") {
          setUploadedFiles({ files: [] });
          updateSelectedFiles([]);
          hiddenFileInput.current && (hiddenFileInput.current.value = "");
          setOpenFilesPopup(false);
          setUploadBtnClicked2(false);
        }
      }
    };

    uploadAndCheck();
  }, [selectedFiles, uploadedFiles]);

  useEffect(() => {
    if (filesReadyForUpload) {
      clickPopupUploadButton();
      setFilesReadyForUpload(false); // Reset the state after triggering the upload
    }
  }, [filesReadyForUpload]);

  return (
    <>
      <input
        onChange={(e) => onSelectFile(e.target.files)}
        ref={hiddenFileInput}
        type="file"
        multiple="multiple"
        hidden
      />
      { willBulkSummarise === false &&
        <div
          ref={drop}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          className={`inline-flex flex-wrap w-full px-2 justify-center items-center mx-auto border border-dashed rounded-md transition-colors duration-300 ease-in-out ${
            isDragging ? "bg-gray-200" : "border-cyan-600"
          } hover:bg-gray-100`}
          onClick={handleClick}
        >
          <div className="inline-flex justify-center items-center">
            <BiCloudUpload className="px-2" size={50} />
            <div className="flex justify-center py-2 pr-2 text-center w-10/12 flex-wrap">
              <p className="text-sm text-cyan-400 font-semibold">
                Drag & drop your files here or click to browse.
              </p>


              {/* <p className="text-xs text-gray-400 font-semibold">
                Supported formats: PDF, DOCX, JPG. Max size: 25MB
              </p> */}

            </div>


              <Popover content={`Supported formats: PDF, DOCX, JPG.\nMax size: 25MB`}>
              <BsInfoCircle className="text-xs text-cyan-400 cursor-pointer mr-4 -ml-1 -mt-2" />
              </Popover>


          </div>
        </div>
      }
      {/* <div className="max-h-40 overflow-y-scroll"> // JC - OLD VERSION
        {selectedFiles.map((file, index) => {
          return (
            <div key={index} className="flex justify-between items-center">
              <p className=" pr-4 w-full truncate">{file.data.name}</p>
              <CircularProgressbar
                value={percent[index] ? parseInt(percent[index].prog) : 0}
                text={
                  percent[index] ? `${parseInt(percent[index].prog)}%` : "0%"
                }
                className="w-7 h-7"
              />
            </div>
          );
        })}
      </div> */}

      {/* <div className="hidden gap-2">
        <button
          // disabled={uploadStart}
          className={`hidden p-2 flex-1 rounded-md text-white ${
            uploadStart
              ? "bg-cyan-600 cursor-not-allowed"
              : "bg-cyan-400 hover:bg-cyan-300"
          } `}
          onClick={(e) => handleClick(e)}
        >
          Choose a file
        </button>
        {selectedFiles.length !== 0 && (
          <button
            // disabled={uploadStart}
            // className={`p-2 flex-1 rounded-md text-white ${
            //   uploadStart
            //     ? "bg-cyan-600 cursor-wait"
            //     : "bg-cyan-400 hover:bg-cyan-300"
            // } `}
            className={`p-2 flex-1 rounded-md text-white bg-cyan-400 hover:bg-cyan-300`}
            onClick={() => clickPopupUploadButton()}
          >
            Upload
          </button>
        )}
      </div> */}
    </>
  );
}

function BackgroundFileList({
  item,
  draggableType,
  chronologicalOrder,
  iframeVariables,
  openFilesPopup,
  setOpenFilesPopup,
  iconContainerRef,
  optionsRef,
  fileBucketIds,
  optionsFunctionVariables,
  showRightPanel,
  setShowRightPanel,
  rightPanelItems,
  setRightPanelItems,
  fileView,
  handleOpenAI,
  selectedFiles,
  percent,
  openAiPrompts,
  setOpenAiPrompts,
  setSummariseStart,
  allowSummarize,
  willBulkSummarise,
  selectedFilesBulkSummary,
  setSelectedFilesBulkSummary,
  interfaceRightPanel,
  setInterfaceRightPanel,
  rightPanelItemsBulkSummary,
  setRightPanelItemsBulkSummary,
  background,
  setFilePreviewItems,
  setShowFilePreview,
  handleSnippetEmail
}) {
  let idx = iframeVariables.index;

  const [totalSizeMB, setTotalSizeMB] = useState(0);
  const [isWithinLimit, setIsWithinLimit] = useState(true);

  const handleAddToBulkSummary = (rowId, fileId, fileDetails) => {
    console.log("fileDetails", fileDetails)
    setSelectedFilesBulkSummary((prevItems) => {
      // Check if the row exists
      const rowExists = prevItems.some(item => item.row === rowId);

      if (rowExists) {
        // Map over the items to update the existing row
        return prevItems.map(item =>
          item.row === rowId
            ? {
                ...item,
                files: item.files.includes(fileId)
                  ? item.files.filter(file => file !== fileId)
                  : [...item.files, fileId],
              }
            : item
        );
      } else {
        // If the row does not exist, add a new row with the file
        return [...prevItems, { row: rowId, files: [fileId] }];
      }
    });
    //THIS IS FOR PASSING THE DATA IN THE RIGHT PANEL
    setRightPanelItemsBulkSummary((prevItems) => {
        const rowExists = prevItems.some(item => item.row === rowId);

        if (rowExists) {
            return prevItems.map(item =>
                item.row === rowId
                    ? {
                          ...item,
                          files: chronologicalOrder(item.files.some(file => file.id === fileId)
                              ? item.files.filter(file => file.id !== fileId)
                              : [...item.files,
                                    {
                                        id: fileId,
                                        name: fileDetails.name,
                                        s3ObjectKey: fileDetails.s3ObjectKey,
                                        s3Bucket: fileDetails.gmailMessageId ? process.env.REACT_APP_S3_GMAIL_ATTACHMENT_BUCKET : process.env.REACT_APP_S3_UPLOAD_BUCKET,
                                        size: fileDetails.size,
                                    },
                                ])
                      }
                    : item
            );
        } else {
            return [
                ...prevItems,
                {
                    row: rowId,
                    rowDetails: item, // Add the new entity details
                    files: chronologicalOrder([
                        {
                            id: fileId,
                            name: fileDetails.name,
                            s3ObjectKey: fileDetails.s3ObjectKey,
                            s3Bucket:  fileDetails.gmailMessageId ? process.env.REACT_APP_S3_GMAIL_ATTACHMENT_BUCKET : process.env.REACT_APP_S3_UPLOAD_BUCKET,
                            size: fileDetails.size,
                        },
                    ]),
                },
            ];
        }
    });

  };

  const isFileChecked = (rowId, fileId) => {
    var itemList = selectedFilesBulkSummary;
    const row = itemList.find(item => item.row === rowId);
    return row ? row.files.includes(fileId) : false;
  };

  const handleSelectAll = (rowId, fileIds, selectAll) => {
    console.log("fileIds", fileIds)
    console.log("background", background)

    var fileDetailsArr=[];
    var fileIdsArr=[];

    fileDetailsArr = fileIds.map(file => ({
      id: file.id,
      s3ObjectKey: `${file.s3ObjectKey}`,
      s3Bucket: file.gmailMessageId ? process.env.REACT_APP_S3_GMAIL_ATTACHMENT_BUCKET : process.env.REACT_APP_S3_UPLOAD_BUCKET,
      size: file.size,
    }));

    var totalArr=[];
    var totalArrUi=[];

    background.map((x)=>{
      var filesPerRow = x?.files?.items;
      var collection=[];

      if(filesPerRow?.length > 0){
        filesPerRow.map((y)=>{


          collection = [...collection, {
            id: y.id,
            name: y.name,
            s3ObjectKey: `public/${y.s3ObjectKey}`,
            s3Bucket: y.gmailMessageId ? process.env.REACT_APP_S3_GMAIL_ATTACHMENT_BUCKET : process.env.REACT_APP_S3_UPLOAD_BUCKET,
            size: y.size
          }]
        })
        totalArr = [...totalArr, {row: x.id, rowDetails: x, files: collection}]
      }
    });

    background.map((x)=>{
      var filesPerRow = x?.files?.items;
      var collection=[];

      if(filesPerRow?.length > 0){
        filesPerRow.map((y)=>{
          collection = [...collection, y.id];
        })
        totalArrUi= [...totalArrUi, {row: x.id, files: collection }]
      }
    });

    if(selectAll){
      setRightPanelItemsBulkSummary(totalArr) //FOR DATA PASSING
      setSelectedFilesBulkSummary(totalArrUi) //FOR UI ONLY
    }else{
      setRightPanelItemsBulkSummary([]);
      setSelectedFilesBulkSummary([]);
    }

    // setSelectedFilesBulkSummary(
    //   prevItems => {
    //   const rowExists = prevItems.some(item => item.row === rowId);

    //   if (rowExists) {
    //     return prevItems.map(item =>
    //       item.row === rowId
    //         ? {
    //             ...item,
    //             files: selectAll ? fileIdsArr : [],
    //           }
    //         : item
    //     );
    //   } else if (selectAll) {
    //     return [...prevItems, { row: rowId, files: fileIdsArr }];
    //   } else {
    //     return prevItems;
    //   }
    // }
    // );

    //THIS IS FOR PASSING THE DATA IN THE RIGHT PANEL
    // setRightPanelItemsBulkSummary((prevItems) => {
    //   const rowExists = prevItems.some(item => item.row === rowId);

    //   if (rowExists) {
    //       return prevItems.map(item =>
    //           item.row === rowId
    //               ? {
    //                     ...item,
    //                     files: selectAll ? fileDetailsArr : [],
    //                 }
    //               : item
    //       );
    //   } else if (selectAll) {
    //       return [...prevItems, { row: rowId, files: fileDetailsArr }];
    //   } else {
    //       return prevItems;
    //   }
    // });

    // console.log("rightPanelItemsBulkSummary",rightPanelItemsBulkSummary)
  };

  const getFileCount = (rowId) => {
    var allFiles = selectedFilesBulkSummary;
    var totalFileCount = 0;

    allFiles?.map((x)=>{
      if(x.files.length > 0){
        totalFileCount = totalFileCount + x?.files?.length
      }
    })
    return totalFileCount;
    // const row = selectedFilesBulkSummary.find(item => item.row === rowId);
    // return row ? row.files.length : 0;
  };

  useEffect(() => {
    const calculateTotalSize = () => {
      let size = 0;
      let hasFiles = false;

      rightPanelItemsBulkSummary.forEach(item => {
        // Check rowDetails.files
        if (item.rowDetails && item.rowDetails.files && item.rowDetails.files.length > 0) {
          hasFiles = true;
          item.rowDetails.files.forEach(file => {
            if (file.items) {
              file.items.forEach(fileItem => {
                size += fileItem.size;
              });
            }
          });
        }

        // Check item.files
        if (item.files && item.files.length > 0) {
          hasFiles = true;
          item.files.forEach(file => {
            size += file.size;
          });
        }
      });

      const totalSizeMB = size / (1024 * 1024);
      setTotalSizeMB(hasFiles ? totalSizeMB.toFixed(2) : "0.00");
      setIsWithinLimit(totalSizeMB <= 50);
    };

    calculateTotalSize();

  }, [rightPanelItemsBulkSummary]);


  // Reference to the first table row
  // const firstElementRef = useRef(null);

  // // Function to scroll to the first element
  // const scrollToFirstElement = () => {
  //   if (firstElementRef.current) {
  //     firstElementRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };


  return (
    <div
      className="overflow-y-auto overflow-x-hidden w-80"
      style={{
        maxHeight: "250px",
      }}
    >
      {/* {idx === 0 &&
        <div
          ref={firstElementRef}
        >this is the top</div>
      }
       */}
            {willBulkSummarise === true && item?.files?.items?.length > 0
              // && idx === 0 
              ?
              <>
                <div className="w-full flex border-b-2 border-gray-500 border-solid py-1 static"
                >
                  
                  {idx === 0 ? (
                    <>
                      <input
                          type="checkbox"
                          onChange={(e) => handleSelectAll(item.id, item?.files?.items, e.target.checked)}
                          className=" bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      <p className="ml-1 text-xs font-semibold">Select All</p>
                      <p className="ml-2 text-xs font-semibold"> Files Selected: {getFileCount(item.id)}</p>
                    </>
                  ) : (
                    <>
                      <div className="w-48 flex">
                        <p className="ml-1 text-xs font-semibold w-16 "></p>
                        <p className="ml-2 text-xs font-semibold w-10"> Files Selected: {getFileCount(item.id)}</p>
                      </div>
                    </>
                  )}

                  {!isWithinLimit && totalSizeMB !== "0.00" ?
                      <p
                        className="ml-3 text-xs font-normal bg-blue-100 px-1 text-gray-400 border-gray-400 cursor-not-allowed"> Summarise Selected
                      </p>
                    :
                    (
                      <>
                        <p
                          onClick={() => {
                              setRightPanelItems([
                                item,
                                idx,
                                idx,
                                item.files?.items.gmailMessageId,
                                chronologicalOrder(item?.files?.items),
                              ]);
                            setSummariseStart(false);
                            setShowRightPanel(true); //PLS DONT REMOVE THE FUNCTION TO OPEN THE RIGHT PANEL HERE -KJMF
                            setInterfaceRightPanel('bulk');
                          }}
                          className="ml-2 text-xs font-normal bg-blue-100 px-1 text-blue-500 border border-blue-500 float-right mr-0"> Summarise Selected
                        </p>
                      </>
                    )
                  }
                </div>

                <div className="pt-1 pb-1 text-sm" >
                  <p>Total file size: {totalSizeMB} MB</p>
                  {!isWithinLimit && <p className="font-light text-sm" style={{ color: 'red' }}>File size selected exceeds 50MB limit!</p>}
                </div>
              </>
              :
              <div className="w-full flex ">
                {willBulkSummarise === true && item?.files?.items?.length > 0 ?
                  <div className="w-80 bg-blue-100"
                    //onClick={()=>{console.log("to top")}}
                  >
                    
                    {/* Scroll to top */}
                  </div>
                  : <div className="w-80"></div>
                }
                
              </div>
            }
      {/*
    // Uncomment the commented code in this implementation to enable drag and drop feature
    // This Droppable relies on the parent droppable at the top code so that it can be nested
    // The implementation for saving the order on dragEnd is in the handleDragend() function at the top with the parent droppable
    <Droppable
      droppableId={draggableType}
      type="CHILD"
    >
    {(provider) => ( /*}
    <div
      className="w-full h-full relative"
      {...provider.droppableProps}
      ref={provider.innerRef}
    > */}
      {selectedFiles.map((file, index) => {
        // JC - NEW VERSION
        return (
          <div
            key={index}
            className="flex max-w-sm justify-between items-center"
          >
            <p className=" pr-2 w-full truncate">{file.data.name}</p>
            <CircularProgressbar
              value={percent[index] ? parseInt(percent[index].prog) : 0}
              text={percent[index] ? `${parseInt(percent[index].prog)}%` : "0%"}
              className="w-7 h-7"
            />
          </div>
        );
      })}

      {chronologicalOrder(item?.files?.items).map((items, index, arr) => {
        const originalString = items?.name || ""; // Handle possible null or undefined
        const truncatedString = originalString.slice(0, 30);

        return (
          <>

          <div
            key={index}
            className={`flex justify-between py-1 ${
              index < arr.length - 1
                ? "border-b-2 border-gray-100 border-dashed"
                : ""
            }`}
            //{...provider.draggableProps} {...provider.dragHandleProps} ref={provider.innerRef}
            //style={getItemStyle(snapshot.isDragging, provider.draggableProps.style)}
          >

            <div className="w-full flex">
              <p
                className="w-full truncate"
                // onClick={() => {
                //   setRightPanelItems([
                //     item,
                //     idx,
                //     index,
                //     items.gmailMessageId,
                //     items,
                //   ]);
                //   setSummariseStart(false);
                //   setShowRightPanel(true); //PLS DONT REMOVE THE FUNCTION TO OPEN THE RIGHT PANEL HERE -KJMF
                // }}
              >
                <div className="flex">
                  {willBulkSummarise === true &&
                    <>
                    {/* <input
                      type="checkbox"
                      checked={isFileChecked(item.id, items.id)}
                      onChange={()=>{handleAddToBulkSummary(item.id, items.id)}}
                      className="bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    /> */}
                    <input
                      type="checkbox"
                      checked={isFileChecked(item.id, items.id)}
                      onChange={() => handleAddToBulkSummary(item.id, items.id, items)}
                    />
                    </>
                  }

                    <p className="ml-2 cursor-pointer"
                      onClick={() => {
                        // setFilePreviewItems([item, items, iframeVariables]);
                        // setShowFilePreview(true);
                      setRightPanelItems([
                        item,
                        idx,
                        index,
                        items.gmailMessageId,
                        items,
                        ]);
                      setSummariseStart(false);
                      setShowRightPanel(true); //PLS DONT REMOVE THE FUNCTION TO OPEN THE RIGHT PANEL HERE -KJMF
                      setInterfaceRightPanel('single');
                    }}
                  >
                {truncatedString}
                  </p>
                </div>
              </p>
              { willBulkSummarise === false &&
                <BackgroundFilePopupOptions
                  parentItem={item}
                  items={items}
                  optionsRef={optionsRef}
                  optionsFunctionVariables={optionsFunctionVariables}
                  summarizeVariables={[
                    item,
                    idx,
                    index,
                    items.gmailMessageId,
                    items,
                  ]}
                  setRightPanelItems={setRightPanelItems}
                  setShowRightPanel={setShowRightPanel}
                  handleOpenAI={handleOpenAI}
                  iframeVariables={iframeVariables}
                  fileView={fileView}
                  openAiPrompts={openAiPrompts}
                  setOpenAiPrompts={setOpenAiPrompts}
                  setSummariseStart={setSummariseStart}
                  allowSummarize={allowSummarize}
                  rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
                  setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
                  setInterfaceRightPanel={setInterfaceRightPanel}
                  setFilePreviewItems={setFilePreviewItems}
                  setShowFilePreview={setShowFilePreview}
                  handleSnippetEmail={handleSnippetEmail}
                />
              }
            </div>

            {/* <FilePreview
              item={item}
              items={items}
              iframeVariables={iframeVariables}
            /> */}

            {/* {provider.placeholder} */}
          </div>

          </>
        );
      })}
      {/* {provider.placeholder}
    </div>
    )}
  </Droppable> */}
    </div>


  );
}

export function BackgroundFilePopupOptions({
  parentItem,
  items,
  optionsRef,
  optionsFunctionVariables,
  iframeVariables,
  fileView,
  summarizeVariables,
  setRightPanelItems,
  setShowRightPanel,
  handleOpenAI,
  openAiPrompts,
  setOpenAiPrompts,
  setSummariseStart,
  allowSummarize,
  rightPanelItemsBulkSummary,
  setRightPanelItemsBulkSummary,
  setInterfaceRightPanel,
  setFilePreviewItems,
  setShowFilePreview,
  handleSnippetEmail
}) {
  const { show, hoverEnterPreviewFile, previewAndDownloadFile } =
    iframeVariables;
  const { showModal, allowDeleteFiles, activateButton } =
    optionsFunctionVariables;

  const qGetFileDownloadLink = `
    query getFileDownloadLink($id: ID) {
      file(id: $id) {
        downloadURL
        s3ObjectKey
        type
      }
    }`;

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCustomPromptInputs, setShowCustomPromptInputs] = useState(false);
  const [customPromptTitle, setCustomPromptTitle] = useState("");
  const [customPrompt, setCustomPrompt] = useState("");
  const [promptOptions, setPromptOptions] = useState(openAiPrompts);

  function openUrlInIframe(url) {
    const newTab = window.open("", "_blank");
    if (newTab) {
      const iframe = document.createElement("iframe");
      iframe.src = url;
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.border = "none";

      newTab.document.body.appendChild(iframe);
    } else {
      console.error("Failed to open new tab with iframe.");
    }
  }

  async function viewFile(items) {
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: items.id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        let encodedUrl = encodeURIComponent(downloadURL);
        let documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
        window.open(documentViewerUrl);
      } else {
        openUrlInIframe(downloadURL);
      }
    });
  }

  function previewFile(parent, item) {
    // setShow(true);
    hoverEnterPreviewFile(
      item.id,
      parent.id,
      item.isGmailPDF,
      item.gmailMessageId ? item.gmailMessageId : null
    )
  }

  async function downloadFile(items) {
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: items.id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { downloadURL } = result.data.file;
      window.open(downloadURL);
    });
  }

  async function summarize(option = null) {
    if (!option) {
      setShowDropdown(!showDropdown);
      return;
    }

    if (option === "Custom Prompt") {
      setShowCustomPromptInputs(!showCustomPromptInputs);
      return;
    }

    // let prompt = option.prompt;

    setRightPanelItems(summarizeVariables);
    setShowRightPanel(true);

    let { message, promptHistory } = await handleOpenAI(
      summarizeVariables[0],
      summarizeVariables[1],
      summarizeVariables[3],
      option
    );

    let clone = [...summarizeVariables];
    clone[0].description = message;
    clone[0].promptHistory = promptHistory;
    setRightPanelItems(clone);
  }

  const createPrompt = `
    mutation createPrompt($companyId: ID, $clientMatterId: ID, $name: String, $prompt: String, $description: String) {
      openAIPromptsCreate(
        companyId: $companyId
        clientMatterId: $clientMatterId
        name: $name
        prompt: $prompt
        description: $description
      ) {
        id
        companyId
        name
        description
        prompt
      }
    }
  `;

  async function handleGenerateSummary() {
    if (customPromptTitle && customPrompt) {
      const newPrompt = {
        name: customPromptTitle,
        prompt: customPrompt,
      };

      // Check if the prompt already exists to avoid duplication
      const existingPrompt = promptOptions.find(
        (option) =>
          option.name === newPrompt.name && option.prompt === newPrompt.prompt
      );

      console.log("NewPrompts", newPrompt);

      if (!existingPrompt) {
        summarize(newPrompt);
        setShowCustomPromptInputs(false);

        try {
          const newPromptRes = await API.graphql({
            query: createPrompt,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: " ",
              name: customPromptTitle,
              prompt: customPrompt,
            },
          });

          if (newPromptRes.errors) {
            console.error("Error in API response:", newPromptRes.errors);
          } else {
            const createdPrompt = newPromptRes.data.openAIPromptsCreate;
            console.log(createdPrompt);
            setPromptOptions((prevOptions) => [...prevOptions, createdPrompt]);
            setOpenAiPrompts((prevPrompts) => [...prevPrompts, createdPrompt]);
          }
        } catch (error) {
          console.error("API call failed:", error);
        }
      }

      // Clear customPrompt and customPromptTitle after handling the generation of summary
      setCustomPrompt("");
      setCustomPromptTitle("");

      setShowDropdown(false);
      setShowCustomPromptInputs(false);
    }
  }

  function deleteFile(item, items) {
    if (allowDeleteFiles && !activateButton) {
      showModal(items.id, item.id, items?.name);
    }
  }

  /**
  const buttonLabels = [
    {
      name: "View",
      onclick: () => previewFile(parentItem, items),
      classname: "",
    },
    // ...(summarizeVariables[3]
    //   ? [
    {
      name: "Summarise",
      onclick: () => handleSummarizeButton(parentItem, items),
      classname: "",
    }
    // ]: [])
    ,
    {
      name: "Delete",
      onclick: () => deleteFile(parentItem, items),
      classname: "text-red-500",
    },
  ];
  */
  const buttonLabels = [];

  const handleClick = () => {
    // console.log("Button clicked");
    // // setShowFilePreview(true);
    // console.log("showPreview set to true");
    // setFilePreviewItems([parentItem, items, iframeVariables]);
    // console.log("File preview items set");
    // previewFile(parentItem, items);
    // console.log("File preview called");

    handleSnippetEmail(items)
  };


  buttonLabels.push({
    name: "View",
    onclick: handleClick,
    classname: "",
  });

  // only display Summarise button if user has access to feature
  allowSummarize &&
    buttonLabels.push({
      name: "Summarise",
      onclick: () => {setInterfaceRightPanel('single');handleSummarizeButton(parentItem, items)},
      classname: "",
    });

  buttonLabels.push({
    name: "Delete",
    onclick: () => deleteFile(parentItem, items),
    classname: "text-red-500",
  });

  const conditionalClassNameFileView = fileView === 0 ? "w-1/2" : "w-1/4";

  useEffect(() => {
    setPromptOptions(openAiPrompts);
  }, [openAiPrompts]);

  let idx = iframeVariables.index;

  function handleSummarizeButton(parentItem, item) {
    const index = parentItem.files.items.findIndex(
      (file) => file.id === item.id
    );
    setShowRightPanel(true); //PLS DONT REMOVE THE FUNCTION TO OPEN THE RIGHT PANEL HERE -KJMF
    setRightPanelItems([parentItem, idx, index, item.gmailMessageId, item]);
    setSummariseStart(true);
  }

  const [originalWidth, setOriginalWidth] = useState();
  const [originalHeight, setOriginalHeight] = useState();

  return (
    <div ref={optionsRef} className="flex flex-col relative">
      <div className="flex space-x-2 relative">
        {buttonLabels.map((item, index) => (
          <button
            key={index}
            className={`px-2 py-1 text-xs ${item.classname} hover:bg-gray-200 rounded`}
            onClick={item.onclick}
          >
            {item.name}
          </button>
        ))}
      </div>

      {showDropdown && (
        <div
          className="mt-2 static z-30 bg-white border rounded shadow-lg w-114 overflow-visible -ml-56 "
          style={{ width: "420px" }}
        >
          <div
            className="absolute text-lg right-0 mt-2 mr-4 cursor-pointer"
            onClick={() => setShowDropdown(false)}
          >
            <AiFillCloseCircle />
          </div>
          <button
            className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-300 bg-gray-200"
            onClick={() => summarize("Custom Prompt")}
          >
            {!showCustomPromptInputs
              ? "Add Custom Prompt"
              : "Hide Custom Prompt"}
          </button>

          {showCustomPromptInputs === true ? (
            <>
              <hr />
              <div className="p-4">
                <input
                  type="text"
                  placeholder="Enter custom prompt title"
                  value={customPromptTitle}
                  onChange={(e) => setCustomPromptTitle(e.target.value)}
                  className="block w-full px-2 py-1 mb-2 border rounded"
                />
                <input
                  type="text"
                  placeholder="Enter custom prompt"
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  className="block w-full px-2 py-1 mb-2 border rounded"
                />
                <button
                  onClick={handleGenerateSummary}
                  className="w-full px-2 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                >
                  Generate Summary
                </button>
              </div>
            </>
          ) : (
            <>
              {promptOptions.map((option, index) => (
                <button
                  key={index}
                  className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                  onClick={() => {summarize(option)}}
                >
                  {option.name}
                </button>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}

function FilePreview({ item, items, iframeVariables }) {
// function FilePreview() {
  const {
    index,
    show,
    snippetId,
    downloadLink,
    iframeLoad,
    setIframeLoad,
    fileType,
    previewAndDownloadPDF,
    previewAndDownloadFile,
    handleOpenAI,
    hoverClosePreviewFile,
    subject,
    from,
    moment,
    dateEmail,
    cc,
    to,
    preview,
  } = iframeVariables;

  const [originalImgHeight, setOriginalImageHeight] = useState(0);
  const [originalImgWidth, setOriginalImageWidth] = useState(0);
  const [objectCountRef, setObjectCountRef] = useState({
    current: 0,
    state: "hidden",
  });

  function freezeBodyScroll() {
    if (objectCountRef.current === 0 && objectCountRef.state === "hidden") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 1, state: "auto" });
  }

  function freeBodyScroll() {
    if (objectCountRef.current === 1 && objectCountRef.state === "auto") {
      document.body.style.overflow = objectCountRef.state;
    }
    setObjectCountRef({ current: 0, state: "hidden" });
  }

  // useEffect(() => {
  //   if (show) {
  //     console.log("Background State:", objectCountRef.state);
  //     freezeBodyScroll();
  //   } else {
  //     console.log("Background State:", objectCountRef.state);
  //     freeBodyScroll();
  //   }
  // }, [show]);

  useEffect(() => {
    if (
      fileType.includes("image") &&
      originalImgHeight === 0 &&
      originalImgWidth === 0
    ) {
      const image = document.getElementById("imageModalCon");
      let width = image.clientWidth;
      let height = image.clientHeight;
      setOriginalImageHeight(height);
      setOriginalImageWidth(width);
    }
  }, [show]);

  return(
    show &&
    snippetId === items.id + "-" + item.id && (
      <div
        id="filePreviewPopup"
        // style={{
        //   overflow: "hidden",
        // }}
        // className="fixed inset-0 z-50 flex items-center justify-center max-x-none bg-black bg-opacity-50 overflow-y-auto"
        style={{
          position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            overflow: "hidden"
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "5px",
          maxWidth: "90%",
          maxHeight: "90%",
          overflowY: "auto"
        }}
        //className="bg-white rounded p-4 max-w-none mx-auto relative top-0 right-0 w-11/12 h-5/6"

        >
          <div className="flex items-center justify-between">
            <div className="order-first">
              {items?.gmailMessageId && <img src={googleLogin} alt="" />}
            </div>
            <div className="space-x-4">
              {items.s3ObjectKey === null &&
              items.isGmailPDF &&
              items.isGmailAttachment ? (
                <button
                  onClick={(e) =>
                    previewAndDownloadPDF(
                      items.id,
                      items.gmailMessageId ? items.gmailMessageId : null,
                      items.name
                    )
                  }
                  className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                >
                  {`Download Email PDF`}
                </button>
              ) : (
                <>

                  <button
                    id="openNewTab"
                    onClick={(e) => {
                      previewAndDownloadFile(items.id);
                    }}
                    className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                  >
                    {`Preview on new tab`}
                  </button>
                  {fileType.includes("image") && (
                    <>
                      <button
                className="inline-flex px-4 py-2 text-xs font-bold text-white bg-gray-500 rounded hover:bg-gray-700"
                onClick={() => {
                          const image =
                            document.getElementById("imageModalCon");
                          let width = image.clientWidth;
                          let height = image.clientHeight;
                          image.style.width = width + 10 + "px";
                          image.style.height = height + 10 + "px";
                        }}
                      >
                         Zoom In <BiZoomIn size={11}
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}/>
                      </button>
                      <button
                className="inline-flex px-4 py-2 text-xs font-bold text-white bg-gray-500 rounded hover:bg-gray-700"
                onClick={() => {
                          const image =
                            document.getElementById("imageModalCon");
                          let width = image.clientWidth;
                          let height = image.clientHeight;
                          if (originalImgHeight < height) {
                            image.style.width = width - 10 + "px";
                            image.style.height = height - 10 + "px";
                          }
                        }}
                      >
                        Zoom Out <BiZoomOut size={11}
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}/>
                      </button>
                    </>
                  )}
                </>
              )}

              {/* {items?.gmailMessageId && (
              <button
                className="px-4 py-2 text-xs font-bold text-white bg-green-500 rounded hover:bg-green-700"
                onClick={() =>
                  handleOpenAI(
                    item.id,
                    index,
                    item.description,
                    // items?.gmailMessageId
                  )
                }
              >
                {`Summarize Emails`}
              </button>
            )} */}

              <button
                className="inline-flex px-4 py-2 text-xs font-bold text-white bg-red-500 rounded hover:bg-red-700"
                type="button"
                aria-expanded="false"
                onClick={hoverClosePreviewFile}
              >
                Close
                <MdClose
                  size={11}
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                />
              </button>
            </div>
          </div>

          <div
            className="w-full h-full mt-2"
            style={{
              minHeight: "350px",
            }}
          >
            {items.isGmailPDF ? (
              <>
                <div id={"preview_" + items.gmailMessageId}>
                  {subject !== null ? (
                    <>
                      <h2>
                        <b>{item.subject}</b>
                      </h2>
                      <hr />
                      <br />
                      <p>{`From: ${from}`}</p>
                      <p>
                        {`Date: ${moment(dateEmail).format(
                          "DD MMM YYYY, hh:mm A"
                        )}`}
                      </p>
                      <p>{`Subject: ${subject}`}</p>
                      <p>{`To: ${to}`}</p>
                      {cc && <p>{`CC: ${cc}`}</p>}
                      <p
                        className="p-2 mt-8"
                        dangerouslySetInnerHTML={{
                          __html: preview.html,
                        }}
                      ></p>
                    </>
                  ) : (
                    <>
                      <SkeletonTheme width={"300px"}>
                        <p>
                          <Skeleton count={4} />
                        </p>
                      </SkeletonTheme>
                      <p className="p-2 mt-8">
                        <Skeleton count={15} />
                      </p>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="h-full pb-12">
                {iframeLoad && (
                  <SkeletonTheme height={"300px"} width={"100%"}>
                    <p>
                      <Skeleton count={1} />
                    </p>
                  </SkeletonTheme>
                )}

                {fileType.includes("image") ? (
                  <div className=" overflow-auto w-full h-full">
                    <img
                      id="imageModalCon"
                      className=" m-auto block object-contain h-full w-full"
                      onLoad={() => setIframeLoad(false)}
                      src={downloadLink}
                    />
                  </div>
                ) : (
                  <iframe
                    title={items.name}
                    src={downloadLink}
                    style={{
                      minHeight: "450px",
                      height: "100%",
                    }}
                    className="w-full "
                    onLoad={() => setIframeLoad(false)}
                  ></iframe>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}

function ManageFiles({
  item,
  index,
  checkedRows,
  selectedRowsBGFiles,
  rowOriginal,
  rowClicked,
  handleFilesCheckboxChange,
  pasteFilestoBackground,
}) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const manageButtonRef = useRef(null);

  return (
    <>
      <span className="relative" ref={manageButtonRef}>
        {/* <ReactTooltip
      id="buttonPaste"
      type="dark"
      place="bottom"
      effect="float"
        />  */}
        <button
          className={
            checkedRows.length === 0 ||
            rowOriginal(item.id) ||
            rowClicked(item.id)
              ? "w-60 bg-gray-300 border border-gray-500 text-gray-500 rounded-md py-2 px-4 mr-3 flex items-center justify-center  font-bold "
              : "w-60 bg-green-400 border border-transparent rounded-md py-2 px-4 mr-3 flex items-center justify-center  font-bold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }
          onClick={
            rowOriginal(item.id) || checkedRows.length === 0
              ? () => setOpenDropdown(!openDropdown)
              : () => {
                  pasteFilestoBackground(item.id, index);
                }
          }
          data-tip={"Cannot Paste Files here"}
          data-for="buttonPaste"
        >
          {" "}
          {checkedRows.length === 0
            ? "Select a file"
            : rowOriginal(item.id)
            ? "Copied"
            : rowClicked(item.id)
            ? "Pasted"
            : "Paste"}
          &nbsp;
          <FaPaste />
        </button>
        {openDropdown ? (
          <ManageFilesDropdown
            setOpenDropdown={setOpenDropdown}
            manageButtonRef={manageButtonRef}
            item={item}
            index={index}
            selectedRowsBGFiles={selectedRowsBGFiles}
            handleFilesCheckboxChange={handleFilesCheckboxChange}
          />
        ) : null}
      </span>
    </>
  );
}

function ManageFilesDropdown({
  setOpenDropdown,
  manageButtonRef,
  item,
  index,
  selectedRowsBGFiles,
  handleFilesCheckboxChange,
}) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    console.log("get me");
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        manageButtonRef.current &&
        !manageButtonRef.current.contains(event.target)
      ) {
        setOpenDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className="absolute flex flex-col z-50 w-full h-48 m-auto p-5 rounded-md bg-white shadow-md"
    >
      <p className="mb-2 py-1 border-b">Select Files to be Copied</p>

      <div className="w-full h-auto flex-1 flex flex-col gap-1 overflow-y-scroll">
        {item?.files?.items?.map((fileItem, index) => {
          return (
            <span key={index} className="flex gap-3 items-center">
              <input
                type="checkbox"
                onChange={(event) => {
                  handleFilesCheckboxChange(
                    event,
                    fileItem.id + item.id,
                    fileItem.id,
                    item.id,
                    fileItem?.name
                  );
                }}
                checked={selectedRowsBGFiles.some(
                  (item) => item.files === fileItem.id
                )}
              />
              <p className=" truncate">{fileItem.name}</p>
            </span>
          );
        })}
      </div>
    </div>
  );
}

const Popover = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef(null);
  const popoverRef = useRef(null);
  const [popoverStyle, setPopoverStyle] = useState({});

  useEffect(() => {
    function updatePosition() {
      if (triggerRef.current && popoverRef.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect();
        const popoverRect = popoverRef.current.getBoundingClientRect();

        const left = triggerRect.left + (triggerRect.width / 2) - (popoverRect.width / 2);
        // const top = triggerRect.bottom + window.scrollY + 5; // 5px gap below the icon

        setPopoverStyle({
          left: `${Math.max(0, left + 50)}px`,
          // top: `${top}px`,
        });
      }
    }

    if (isVisible) {
      // Use setTimeout to ensure the popover is rendered before calculating position
      setTimeout(updatePosition, 0);
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition);
    }

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [isVisible]);

  return (
    <span className="relative inline-block">
      <span
        ref={triggerRef}
        className="inline-block"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </span>
      {isVisible && (
        <div
          ref={popoverRef}
          className="fixed z-50 bg-white p-2 border border-gray-200 rounded-md shadow-lg -mt-2 text-xs text-gray-400 font-semibold"
          style={popoverStyle}
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
        >
          {
          content.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
          ))}
        </div>
      )}
    </span>
  );
};

export default TableInfo;
