import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import {
  BsArrowDownUp,
  BsInfoCircle,
  BsExclamationCircleFill,
  BsFillClockFill,
  BsCheck2Circle,
} from "react-icons/bs";
import { FiCopy, FiDollarSign } from "react-icons/fi";
import Button from "../../shared/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import budgetIcon from "../../assets/images/budget-icon.png";

import { TbClockCheck } from "react-icons/tb";
import { PiSealCheck } from "react-icons/pi";
import shieldDollar from "../../assets/images/shield-dollar.png";
import { FiEdit } from "react-icons/fi";
import { parseInt } from "lodash";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import CircularChart from "./circular-chart";

export default function Budget(props) {
  const history = useHistory();

  const { width } = useWindowDimensions()
  const { briefId, redirectUrl, provisionalSum } = props;

  function visitBrief() {
    history.push(redirectUrl);
  }

  const getValueDynamicData = (dynamicData, type) => {
    if(dynamicData !== null && type){
      const item = JSON.parse(dynamicData)
      const keys = Object.keys(item);
      const values = [];
      keys.forEach(key => {
          values.push(item[key]);
      });
      
      if(type === "Provisional Amount"    ){
        return values[0];
      }

      if(type === "Cost Amount" ){
        return values[1];
      }

      if(type === "Description of Work" ){
        return values[2];
      }

      if(type === "Paid to Date Amount" ){
        return values[3];
      }
    }
  }

  const BACKGROUND_BY_BRIEF_ID_QUERY = `
        query getBriefByID($limit: Int = 50, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
        brief(id: $id) {
        id
        backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
            items {
            id
            description
			      dynamicData
            comments
            date
            createdAt
            order
            files {
                items {
                id
                name
                }
            }
            }
            nextToken
        }
        }
    }
    `;

  const [totalProvisionalAmount, setTotalProvisionalAmount] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [rowsWithinProvisionalCost, setRowsWithinProvisionalCost] = useState(0);
  const [rowsExceededProvisionalCost, setRowsExceededProvisionalCost] = useState(0);


  const getBackgrounds = async (id) => {
    const backgroundOpt = await API.graphql({
      query: BACKGROUND_BY_BRIEF_ID_QUERY,
      variables: {
        id: id,
        nextToken: null,
        sortOrder: "ORDER_ASC",
      },
    });
  
    if (backgroundOpt.data?.brief?.backgrounds?.items !== null) {
      let filteredResult = backgroundOpt?.data?.brief?.backgrounds?.items.filter((row) => {
        if (row.dynamicData === null) return false;

        let dynamicData = row.dynamicData
        const provisionalAmount = getValueDynamicData(dynamicData, "Provisional Amount");
        const costAmount = getValueDynamicData(dynamicData, "Cost Amount");
        const paidToDateAmount = getValueDynamicData(dynamicData, "Paid to Date Amount");

        return (provisionalAmount !== null && provisionalAmount !== undefined && provisionalAmount !== "") ||
          (costAmount !== null && costAmount !== undefined && costAmount !== "") ||
          (paidToDateAmount !== null && paidToDateAmount !== undefined && paidToDateAmount !== "")
      })
      
      var result = filteredResult.map(
        ({ id, description, dynamicData, date, createdAt, order, files }) => {
          const provisionalSum = JSON.parse(dynamicData);
          // Populate custom column data inside of dynamic data
          console.log("Variation Dynamic Data:,", dynamicData);
          const provisionalAmount = getValueDynamicData(dynamicData, "Provisional Amount");
          const descriptionOfWork = getValueDynamicData(dynamicData, "Description of Work");
          const costAmount = getValueDynamicData(dynamicData, "Cost Amount");
          const paidToDateAmount = getValueDynamicData(dynamicData, "Paid to Date Amount");
  
          const provisionalAmountValue = provisionalSum && provisionalAmount ? parseInt(provisionalAmount) : 0;
          const costAmountValue = provisionalSum && costAmount ? parseInt(costAmount) : 0;
          const paidToDateAmountValue = provisionalSum && paidToDateAmount ? parseInt(paidToDateAmount) : 0;

          const isCostWithinProvisional = costAmountValue <= provisionalAmountValue;
          const isCostExceededProvisional = costAmountValue > provisionalAmountValue;

          return {
            createdAt: createdAt,
            id: id,
            description: description,
            dynamicData: provisionalSum,
            provisionalAmount: provisionalAmountValue,
            costAmount: costAmountValue,
            paidToDateAmount: paidToDateAmountValue,
            descriptionOfWork: provisionalSum && descriptionOfWork ? descriptionOfWork : null,
            date: date,
            order: order,
            files: files,
            isCostWithinProvisional: isCostWithinProvisional,
            isCostExceededProvisional: isCostExceededProvisional
          };
        }
      );
  
      const provisionalAmountSum = result.reduce((sum, item) => {
        return sum + (item.provisionalAmount || 0);
      }, 0);
  
      // const formattedProvisionalAmount = formatProvisionalAmount(provisionalAmountSum);
      const rowsWithinProvisionalCost = result.filter(item => item.isCostWithinProvisional).length;
      const rowsExceededProvisionalCost = result.filter(item => item.isCostExceededProvisional).length;
      setTotalProvisionalAmount(provisionalAmountSum);
      setTotalItems(result.length);
      setRowsWithinProvisionalCost(rowsWithinProvisionalCost);
      setRowsExceededProvisionalCost(rowsExceededProvisionalCost);
    }
  };
  

  useEffect(() => {
    getBackgrounds(briefId);
  }, []);

  return (
    <>
      <div
        className=" bg-white shadow rounded-lg border border-neutral-200 overflow-hidden"
        ref={provisionalSum}
      >
        <div className="w-full pt-6 px-8">
          <div className="relative">
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className="z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img src={budgetIcon} alt="Variations Icon" className="pb-2 w-11 h-auto"/>
            </div>
            <div 
              className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
              onClick={visitBrief}
              // onClick={()=>console.log("clicked")}
            >
              <FiEdit className="w-4 h-4"/>
            </div>
          </div>

          <div className="gap-1 z-20 relative">
            <div className="justify-start items-center gap-2 pb-5">
              <div className="inline-flex">
                <div className="text-black text-lg font-open-sans font-semibold mr-1">
                  Budget
                </div>
                {totalItems !== 0 ? (
                  <div>
                    <BsInfoCircle className="text-xs" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/** End Header Variations */}

        {totalItems !== 0 ? (
          <>
            <div className="px-4 mt-4 mb-2">
              <CircularChart
                type={"Budget"}
                width={width}
                totalItems={totalItems}
                total={totalProvisionalAmount}
                within={rowsWithinProvisionalCost}
                exceeded={rowsExceededProvisionalCost}
              />  
            </div>
            {/** End Counter Tiles */}
          </>
        ) : (
          <>
            <div className="px-6 pb-6 font-sans">
              <div className="mt-4 flex-col justify-start items-start gap-4 inline-flex">
                <div
                  className="w-full text-sm font-normal text-gray-600 leading-tight font-open-sans"
                  style={{ color: "#5A5A5A" }}
                >
                  Request changes or modifications to the original plan and keep
                  track of any variations and their corresponding costs and
                  approvals.
                </div>
              </div>
            </div>
            {/* <div class="w-full pb-6 px-4 flex-col justify-start items-start gap-3 inline-flex"></div> */}
          </>
        )}
      </div>
    </>
  );
}





