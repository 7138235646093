import { useEffect } from "react";
import React from "react";
import {
  // FaGoogle,
  FaArchive,
  FaTachometerAlt,
  FaUserCog,
  FaUsers,
  FaUsersCog,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImBooks } from "react-icons/im";
import { AppRoutes } from "../../constants/AppRoutes";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";


const mmaSidebar = [
  {
    order: 1,
    name: "DASHBOARD",
    title: "Matter Dashboard",
    path: AppRoutes.DASHBOARD,
    icon: <FaTachometerAlt style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 2,
    name: "ARCHIVES",
    title: "Archived Matters",
    path: AppRoutes.ARCHIVES,
    icon: <FaArchive  style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 3,
    name: "CONTACTS",
    title: "Contacts Dashboard",
    path: AppRoutes.CONTACTS,
    icon: <FaUsers style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 4,
    name: "USERTYPEACCESS",
    title: "User Type Access",
    path: AppRoutes.USERTYPEACCESS,
    icon: <FaUsersCog style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 5,
    name: "TASKS",
    title: "Tasklist Dashboard",
    path: AppRoutes.TASKS,
    icon: <ImBooks style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 6,
    name: "INBOX",
    title: "Email Integration",
    path: AppRoutes.INBOX,
    icon: <MdEmail style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },

  {
    order: 7,
    name: "ACCOUNTSETTINGS",
    title: "Profile Settings",
    path: AppRoutes.ACCOUNTSETTINGS,
    icon: <FaUserCog style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
];

const hbaSidebar = [
  {
    order: 1,
    name: "DASHBOARD",
    title: "Dashboard",
    path: AppRoutes.HBADASHBOARD,
    icon: <FaTachometerAlt style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  // {
  //   order: 2,
  //   name: "CONTACTS",
  //   title: "Contacts Dashboard",
  //   path: AppRoutes.CONTACTS,
  //   icon: <FaUsers style={{ color: "var(--mysteryGrey)" }} />,
  //   cName: "nav-text",
  // },
  // {
  //   order: 3,
  //   name: "USERTYPEACCESS",
  //   title: "User Type Access",
  //   path: AppRoutes.USERTYPEACCESS,
  //   icon: <FaUsersCog style={{ color: "var(--mysteryGrey)" }} />,
  //   cName: "nav-text",
  // },
  // {
  //   order: 4,
  //   name: "TASKS",
  //   title: "Tasklist Dashboard",
  //   path: AppRoutes.TASKS,
  //   icon: <ImBooks style={{ color: "var(--mysteryGrey)" }} />,
  //   cName: "nav-text",
  // },
  // {
  //   order: 5,
  //   name: "INBOX",
  //   title: "Email Integration",
  //   path: AppRoutes.INBOX,
  //   icon: <MdEmail style={{ color: "var(--mysteryGrey)" }} />,
  //   cName: "nav-text",
  // },
  {
    order: 6,
    name: "ACCOUNTSETTINGS",
    title: "Profile Settings",
    path: AppRoutes.ACCOUNTSETTINGS,
    icon: <FaUserCog style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
];

const obaSidebar = [
  {
    order: 1,
    name: "DASHBOARD",
    title: "Dashboard",
    path: AppRoutes.HBADASHBOARD,
    icon: <FaTachometerAlt style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 2,
    name: "CONTACTS",
    title: "Contacts Dashboard",
    path: AppRoutes.CONTACTS,
    icon: <FaUsers style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 3,
    name: "TASKS",
    title: "Tasklist Dashboard",
    path: AppRoutes.TASKS,
    icon: <ImBooks style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 4,
    name: "INBOX",
    title: "Email Integration",
    path: AppRoutes.INBOX,
    icon: <MdEmail style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
  {
    order: 5,
    name: "ACCOUNTSETTINGS",
    title: "Profile Settings",
    path: AppRoutes.ACCOUNTSETTINGS,
    icon: <FaUserCog style={{ color: "var(--mysteryGrey)" }} />,
    cName: "nav-text",
  },
];



const getSidebarData = (key) => {
    const domain = window.location.hostname;
    let sideBarData 
    // Check if the domain is in the obaDomains list
    if (obaDomains.includes(domain)) {
        sideBarData = obaSidebar;
    } 
    // If not in obaDomains, check if it's in the hbaDomains list
    else if (hbaDomains.includes(domain)) {
      const isOwnerBuilder = key === "OWNERBUILDER";
      sideBarData = isOwnerBuilder ? mmaSidebar : hbaSidebar;
    } 
    // If the domain is not in either list, default to mmaSidebar
    else {
        sideBarData = mmaSidebar;
    }
    

    return sideBarData
}

export default function useSidebarData(userCategory) {
    let value = getSidebarData(userCategory)

    return value ;
}