import { API } from "aws-amplify";
import { DefaultPhases } from "../constants/Phases";

// Change the const in HBABriefsConfig to edit default briefs and columns
import { defaultBriefs, defaultBriefsWithBackgrounds, hbaColumnsConfig } from "../constants/HBABriefsConfig";

const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
    id
    columns {
      id
      accessorKey
      headerText
      type
      enabled
      optionsText
      order
      presets {
      id
      name
      }
    }
    }
  }
`;

const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int], $assistantId: String, $phase: [Phase]) {
  briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds, assistantId: $assistantId, phase: $phase) {
    id
    name
    date
    createdAt,
    assistantId,
    order
    columnIds
  }
}`;

const mAddUserAcces = `
  mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
    briefAccessTypeTag(briefId:$briefId, userType:$userType) {
      id
    }
  }
  `;

const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
	backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
    id
    createdAt
    date
    description
    order
    briefs {
      id
      name
    }
	}
}`;

const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs {
      items {
        id
        name
      }
    }
  }
}`;

const BRIEF_ACCESS_TYPE = `
query getBriefByID($id: ID) {
  brief(id: $id) {
    id
    userTypeAccess
  }
}`;

const mCreateLabel = `mutation createLabel($clientMatterId: String, $name: String) {
  labelCreate(clientMatterId:$clientMatterId, name:$name) {
      id
      name
  }
}`;

const qListClientMatterLabels = `
  query clientMatterById($id: ID) {
    clientMatter (id: $id) {
      id
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

const ARCHIVED_BRIEFS_QUERY = `query getArchivedBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs(isDeleted: true){
      items {
        id
        name
      }
    }
  }
}`;
const defaultColumnIds = [0, 1, 2, 3];
const defaultUserAccessValues = [
  {
    label: "LEGALADMIN",
    value: "LEGALADMIN",
  },
  {
    label: "LAWYER",
    value: "LAWYER",
  },
];

// MOVED BRIEF AND COLUMN DEFINITION IN constants/HBABriefsConfig.jsx
export async function setDefaultColumnBriefs(backgroundTables, briefs) {
  console.group("setDefaultColumnBriefs");
  console.log(backgroundTables, briefs);

  try {
    const { id, columns } = backgroundTables;

    console.log("Current columns columns", columns);
    console.log("defaultColumnIds:", defaultColumnIds);

    let briefMap = new Map();
    if (id) {
      let presets = [];
      const commentsColPresets = [];
      const tempBreakdownPresets = [];

      for (const item of briefs) {
        const preset = { id: item.id, name: item.name };

        presets.push(preset);
        briefMap.set(item.name, [preset]);

        if (item.hasCommentAccess) {
          const commentPreset = { ...preset };
          commentsColPresets.push(commentPreset);
        }

        if (item.name.includes("_")) {
          tempBreakdownPresets.push(preset);
        }

        briefMap.set("Breakdown Presets", tempBreakdownPresets);
        briefMap.set("Comments Presets", commentsColPresets);
        briefMap.set("All Presets", presets);
      }

      // Filters out null columns
      let filteredColumns = [];
      columns.map((obj) => {
        if (obj.headerText === null) return false;

        // If column is not duplicate
        if (!filteredColumns.some((e) => e.headerText === obj.headerText)) {
          // Remove null and undefined presets
          let filteredCol = { ...obj };
          if (filteredCol?.presets) {
            filteredCol.presets = obj.presets.filter(
              (preset) => preset !== null && preset !== undefined
            );
          }

          filteredColumns.push(filteredCol);
        }
      });
      console.log("filteredColumns", filteredColumns);

      // Adds missing columns to current list of columns
      const completeHBAColumns = setMissingDefaultColumns(
        filteredColumns,
        briefMap
      );
      console.log("completeHBAColumns", completeHBAColumns);

      let defaultCols = [
        completeHBAColumns.find((col) => col.headerText === "Date"),
        completeHBAColumns.find((col) => col.headerText === "Gmail"),
        completeHBAColumns.find(
          (col) => col.headerText === "Description of Background"
        ),
        completeHBAColumns.find((col) => col.headerText === "Comments"),
        completeHBAColumns.find((col) => col.headerText === "Document"),
      ].filter((col) => col !== undefined && col !== null && col);

      // Sorts column with default column containing their default IDs.
      let sortedColumns = [
        ...defaultCols,
        ...completeHBAColumns
          .filter(
            (col) =>
              !defaultCols.some(
                (defaultCol) => col.headerText === defaultCol.headerText
              )
          )
          .sort((a, b) => a.order - b.order),
      ];

      let reorganizedColumns = sortedColumns.map((col, index) => ({
        ...col,
        accessorKey: index.toString(),
        id: index.toString(),
        order: index + 1,
      }));

      console.log("reorganizedColumns", reorganizedColumns);

      // Sets the default presets of each columns
      const newColumns = reorganizedColumns.map((obj) =>
        setDefaultColumnPresets(obj, briefMap)
      );

      console.log("newColumns with presets", newColumns);

      const updateBriefTable = await API.graphql({
        query: UPDATE_BACKGROUND_TABLE_MUTATION,
        variables: {
          id,
          input: {
            columns: newColumns,
          },
        },
      });

      console.log("updateBriefTable", updateBriefTable);
    }
  } catch (error) {
    console.error(error);
  } finally {
    console.groupEnd("setDefaultColumnBriefs");
  }
}

const setDefaultColumnPresets = (obj, briefMap) => {
  let presets = [];

  let columnConfig = hbaColumnsConfig[obj.headerText];
  let allPresets = briefMap.get("All Presets");

  if (columnConfig) {
    if (columnConfig.presetFilter) {
      presets = allPresets.filter(
        (preset) =>
          !columnConfig.presetFilter.some((filter) =>
            preset.name.includes(filter)
          )
      );
    } else {
      columnConfig.presets.map((preset) =>
        presets.push(...briefMap.get(preset))
      );
    }
  }

  let tempObj = {
    ...obj,
    ...(presets.length && { presets }),
  };

  return tempObj;
};

const setMissingDefaultColumns = (columns, briefMap) => {
  let allPresets = briefMap.get("All Presets");

  // Create a map of existing columns for quick lookup
  const existingColumnsMap = new Map(
    columns.map((col) => [col.headerText, col])
  );

  const newColumns = Object.entries(hbaColumnsConfig).map(
    ([defaultCol, columnConfig]) => {
      const matchingCol = existingColumnsMap.get(defaultCol);

      if (matchingCol) {
        existingColumnsMap.delete(defaultCol); // Remove from map to track unmatched columns
        return matchingCol;
      }

      const { presetFilter, ...restConfig } = columnConfig;

      let presets = presetFilter
        ? allPresets.filter(
            (preset) =>
              !columnConfig.presetFilter.some((filter) =>
                preset.name.includes(filter)
              )
          )
        : columnConfig.presets.flatMap((preset) => briefMap.get(preset) || []);

      return {
        enabled: true,
        headerText: defaultCol,
        ...restConfig,
        presets,
      };
    }
  );

  // Add any remaining original columns that weren't in hbaColumnsConfig
  newColumns.push(...existingColumnsMap.values());

  const sortedColumns = sortAndReorderColumns(newColumns);

  return sortedColumns;
};

function sortAndReorderColumns(columns) {
  // Separate columns with and without order
  const withOrder = columns.filter((col) => "order" in col);
  const withoutOrder = columns.filter((col) => !("order" in col));

  // Sort columns with order
  withOrder.sort((a, b) => a.order - b.order);

  // Renumber columns with order starting from 0
  withOrder.forEach((col, index) => {
    col.order = index;
  });

  // Add order to columns without it, continuing from the last number used
  withoutOrder.forEach((col, index) => {
    col.order = withOrder.length + index;
  });

  // Combine and sort all columns
  return [...withOrder, ...withoutOrder].sort((a, b) => a.order - b.order);
}

export async function createDefaultBriefs(
  clientMatterId,
  currentDate,
  briefsList
) {
  try {
    console.group("createDefaultBriefs");
    console.log("CREATING DEFAULT BRIEFS FOR CM_ID", clientMatterId);
    console.log("CURRENT BRIEFSLIST", briefsList);

    let briefs = {};
    let promisesArr = [];
    let currentBriefs = new Map();

    // FETCHES CURRENT BRIEF
    let appendedBriefs = await fetchBriefs(clientMatterId, briefsList);

    if (appendedBriefs) {
      for (let brief of appendedBriefs) {
        currentBriefs.set(brief.name, brief);
      }
    }
    console.log("appendedBriefs", appendedBriefs);

    // Creates Default Briefs and sets Default Access
    for (let defaultBrief of defaultBriefs) {
      if (!currentBriefs.get(defaultBrief)) {
        promisesArr.push(
          createBrief(defaultBrief, clientMatterId, currentDate, briefs).then(
            (response) =>
              Promise.all([
                handleAddUserAccess(
                  defaultUserAccessValues,
                  response?.data?.briefCreate.id
                ),
                handleBriefLabel(clientMatterId, defaultBrief),
              ])
          )
        );
      }
    }

    // Creates Brief, Background, and sets Default Access
    for (let defaultBrief of defaultBriefsWithBackgrounds) {
      if (!currentBriefs.get(defaultBrief)) {
        promisesArr.push(
          createBrief(defaultBrief, clientMatterId, currentDate, briefs).then(
            (response) =>
              Promise.all([
                createBackground(response?.data?.briefCreate),
                handleAddUserAccess(
                  defaultUserAccessValues,
                  response?.data?.briefCreate.id
                ),
                handleBriefLabel(clientMatterId, defaultBrief),
              ])
          )
        );
      }
    }

    if (briefsList) {
      const response = await API.graphql({
        query: qListClientMatterLabels,
        variables: {
          id: clientMatterId,
        },
      });

      let currLabels = response?.data?.clientMatter?.labels?.items;
      console.log("CURRENT LABELS", currLabels);
      for (let currBrief of briefsList) {
        promisesArr.push(
          handleBriefLabel(clientMatterId, currBrief.name, currLabels)
        );
        promisesArr.push(
          handleAddUserAccess(defaultUserAccessValues, currBrief.id, currBrief)
        );
        if (defaultBriefsWithBackgrounds.includes(currBrief.name)) {
          if (!(currBrief?.backgrounds?.items?.length > 0)) {
            console.log("Creating background for existing brief", currBrief);
            promisesArr.push(createBackground(currBrief));
          }
        }
      }
    }

    console.log("promisesArr", promisesArr.length);
    await Promise.all(promisesArr);
    console.log("created briefs", briefs);

    console.groupEnd("createDefaultBackground");
    return briefs;
  } catch (err) {
    console.log(err);
  }
}

export async function createContractBackground(contractsBrief) {
  console.group("createContractBackground");
  const fileList = [
    "Contract Terms And Conditions",
    "Architectural Drawings",
    "Engineer Drawings",
    "Specifications",
    "Builder's Insurance",
  ];

  const contractFiles = [];

  await Promise.all(
    fileList.map(async (file, index) => {
      try {
        const createContractBackground = await createBackground(
          contractsBrief,
          file,
          index + 1
        );

        console.log("createContractBackground", createContractBackground);

        // Store the result in the array
        contractFiles.push({
          id: createContractBackground?.data?.backgroundCreate?.id,
          order: createContractBackground?.data?.backgroundCreate?.order,
        });
      } catch (error) {
        // Handle errors if any
        console.error("Error in GraphQL query:", error);
      }
    })
  );

  var files = contractFiles.sort((a, b) => a?.order - b?.order);

  console.log("Contract Files", files);
  console.groupEnd("createContractBackground");

  return files;
}

async function createBrief(briefName, clientMatterId, date, briefs) {
  const phasesWithBrief = findPhasesWithBrief(DefaultPhases, briefName);
  let response = await API.graphql({
    query: CREATE_BRIEF_MUTATION,
    variables: {
      clientMatterId: clientMatterId,
      // assistantId: assistantId,
      name: briefName,
      date: date,
      order: 0,
      columnIds: defaultColumnIds,
      phase: phasesWithBrief || [],
    },
  });

  briefs[briefName.replace(/ /g, "_")] = response?.data?.briefCreate;
  return response;
}

async function createBackground(brief, description, order) {
  let params = {
    query: mCreateBackground,
    variables: {
      briefs: [
        {
          id: brief?.id,
          name: brief?.name,
        },
      ],
      ...(description && { description }), // Add description property conditionally
      ...(order && { order }), // Add order property conditionally
    },
  };
  return API.graphql(params);
}

async function fetchBriefs(clientMatterId, briefs) {
  try {
    var appendedBriefs;

    if (briefs) {
      let archivedBriefs = await API.graphql({
        query: ARCHIVED_BRIEFS_QUERY,
        variables: { id: clientMatterId },
      });

      appendedBriefs = [
        ...briefs,
        ...(archivedBriefs?.data?.clientMatter?.briefs?.items || []),
      ];
    } else {
      let [activeBriefs, archivedBriefs] = await Promise.all([
        API.graphql({
          query: BRIEFS_QUERY,
          variables: { id: clientMatterId },
        }),
        API.graphql({
          query: ARCHIVED_BRIEFS_QUERY,
          variables: { id: clientMatterId },
        }),
      ]);

      appendedBriefs = [
        ...(activeBriefs?.data?.clientMatter?.briefs?.items || []),
        ...(archivedBriefs?.data?.clientMatter?.briefs?.items || []),
      ];
    }

    return appendedBriefs;
  } catch (error) {
    console.error("Error fetching briefs:", error);
    throw error;
  }
}

function findPhasesWithBrief(defaultPhases, briefName) {
  // Filter the phases where the defaultBriefs array contains the briefName
  const matchingPhases = defaultPhases.filter((phase) =>
    phase.defaultBriefs.includes(briefName)
  );

  // Map the filtered phases to get their names
  return matchingPhases.map((phase) => phase.name);
}

//add user access
async function handleAddUserAccess(e, briefId, brief) {
  if (brief) {
    let currAccess = brief.userTypeAccess;

    if (currAccess?.constructor === Array) {
      return;
    }

    let param = {
      query: BRIEF_ACCESS_TYPE,
      variables: {
        briefId: briefId,
        userType: temp,
      },
    };
    let response = await API.graphql(param);
    currAccess = response?.data?.brief?.userTypeAccess;
  }

  var temp = e.map((item) => item.label);

  const params = {
    query: mAddUserAcces,
    variables: {
      briefId: briefId,
      userType: temp,
    },
  };

  await API.graphql(params);
}

async function handleBriefLabel(cmId, briefName, cmLabels) {
  // If label already exists, exit function
  if (cmLabels && cmLabels.find((label) => label.name === briefName)) {
    return;
  }

  console.log("Adding new label to", briefName);
  const createLabelParams = {
    query: mCreateLabel,
    variables: {
      clientMatterId: cmId,
      name: briefName,
    },
  };

  const result = await API.graphql(createLabelParams);
}
