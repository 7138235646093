import CreatableSelect from "react-select/creatable";
import anime from "animejs";
import React, { useEffect, useRef } from "react";
import { FiFolderPlus } from "react-icons/fi";
import { PeopleSelect } from "./people-select";
import { AiFillMail, AiOutlineLoading } from "react-icons/ai";

const CreateMatterModal = ({
  close,
  loading,
  focusOnChildInputTag,
  selectInputClientRef,
  clientsOptions,
  handleClientChanged,
  selectedClient,
  inputSaveClient,
  inputClient,
  setInputClient,
  setSaveClient,
  selectInputMatterRef,
  mattersOptions,
  handleMatterChanged,
  selectedMatter,
  inputSaveMatter,
  inputMatter,
  setInputMatter,
  setSaveMatter,
  setInputContacts,
  matterName,
  clientName,
  contactOptions,
  userTypeOptions,
  contactLoading,
  handleSubmit,
  setLoadingSave,
  loadingSave,
}) => {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);

  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  return (
    <div
      ref={modalContainer}
      onClick={() => close()}
      className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none opacity-0 focus:outline-none 0 backdrop-filter backdrop-blur-md"
    >
      <div
        ref={modalContent}
        className="z-50 inline-flex flex-col items-center justify-start h-auto max-w-md gap-8 p-6 scale-90 bg-white shadow opacity-0 rounded-xl "
        onClick={(e) => e.stopPropagation()}
      >
        {/* Modal Content */}
        <div
          className={
            loadingSave === true
              ? "inline-flex flex-col items-start justify-start w-full gap-5 opcity-50 cursor-wait"
              : "inline-flex flex-col items-start justify-start w-full gap-5"
          }
          disabled={loadingSave === true}
        >
          <div className="inline-flex items-center justify-center w-auto h-auto p-3 text-green-600 bg-green-100 border-8 rounded-full border-green-50">
            <FiFolderPlus size={20} />
          </div>
          <div className="inline-flex flex-col items-start justify-start w-full gap-2">
            <div className="text-lg font-semibold text-gray-900">
              Add New File
            </div>
            <div className="text-sm font-normal leading-tight text-gray-500 ">
              All files having or assigned using the same client matter file
              will be automatically saved here
            </div>
          </div>

          <div className="inline-flex flex-col items-start justify-start w-full gap-4">
            <div className="w-full flex-col justify-start items-start gap-1.5 inline-flex">
              <label className="text-sm font-normal text-gray-700" for="client">
                Client Name*
              </label>
              <CreatableSelect
                ref={selectInputClientRef}
                options={loading ? [] : clientsOptions}
                isClearable
                isSearchable
                onChange={handleClientChanged}
                value={selectedClient}
                className="relative z-50 w-full bg-white border-0 rounded shadow focus:outline-none focus:ring ring-blue-500"
                placeholder={
                  inputSaveClient !== null && inputSaveClient !== ""
                    ? inputSaveClient
                    : ""
                }
                inputValue={inputClient}
                onInputChange={setInputClient}
                onMenuClose={() => setSaveClient(inputClient)}
                onFocus={() => {
                  setInputClient(inputSaveClient);
                  setSaveClient("");
                }}
                blurInputOnSelect
              />
            </div>
            <div className="w-full flex-col justify-start items-start gap-1.5 inline-flex">
              <label className="text-sm font-normal text-gray-700" for="matter">
                Matter Name*
              </label>
              <CreatableSelect
                ref={selectInputMatterRef}
                options={loading ? [] : mattersOptions}
                isClearable
                isSearchable
                onChange={handleMatterChanged}
                value={selectedMatter}
                className="relative z-40 w-full bg-white border-0 rounded shadow focus:outline-none focus:ring ring-blue-500"
                placeholder={
                  inputSaveMatter !== null && inputSaveMatter !== ""
                    ? inputSaveMatter
                    : ""
                }
                inputValue={inputMatter}
                onInputChange={setInputMatter}
                onMenuClose={() => setSaveMatter(inputMatter)}
                onFocus={() => {
                  setInputMatter(inputSaveMatter);
                  setSaveMatter("");
                }}
                blurInputOnSelect
              />
            </div>

            {/* People Component */}
            <PeopleSelect
              setInputContacts={setInputContacts}
              ContactList={contactOptions}
              UserTypesList={userTypeOptions}
              contactLoading={contactLoading}
            />
          </div>
        </div>

        {/* Divider */}
        <div className="w-full h-px border border-neutral-200"></div>

        {/* Modal Action Buttons */}
        <div
          className={
            loadingSave === true
              ? "flex items-start justify-start w-full gap-3 cursor-wait"
              : "flex items-start justify-start w-full gap-3 cursor-default"
          }
        >
          <button
            className={
              loadingSave === true
                ? "flex items-center justify-center w-full gap-2 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow opacity-50 cursor-wait"
                : "flex items-center justify-center w-full gap-2 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow hover:bg-gray-100"
            }
            onClick={() => close()}
            disabled={loadingSave === true}
          >
            Cancel
          </button>
          <button
            className={`py-2 w-full bg-green-600 rounded-lg shadow border border-green-600 justify-center items-center gap-2 flex text-white text-base font-medium
            ${
              matterName === null || clientName === null
                ? "opacity-50 cursor-not-allowed"
                : loadingSave === true
                ? "opacity-50 cursor-wait"
                : ""
            }
            enable:hover:bg-white enabled:hover:text-green-600 `}
            onClick={() => {
              handleSubmit();
              setLoadingSave(true);
            }}
            disabled={
              matterName === null || clientName === null || loadingSave === true
                ? true
                : false
            }
          >
            {loadingSave === true ? (
              <>
                <AiOutlineLoading className="absolute rotate-center -ml-20" />{" "}
                Saving
              </>
            ) : (
              <>Save</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateMatterModal;
