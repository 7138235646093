import React, { useState, useEffect, useRef } from "react";
import { API, Storage } from "aws-amplify";
import { BsInfoCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Button from "../../shared/Button";
import sitediaryIcon from "../../assets/images/sitediary-icon.png";
import BackgroundPopupFileUpload from "./form-file-upload";
import { FaRegCheckCircle } from "react-icons/fa";
import ToastNotification from "../toast-notification";

const axios = require("axios");

const mUpdateBackgroundDynamicData = `
  mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
    backgroundUpdate(id: $id, dynamicData: $dynamicData) {
      id
      dynamicData
    }
  }
  `;

const mUpdateBackgroundDesc = `
  mutation updateBackground($id: ID, $description: String) {
    backgroundUpdate(id: $id, description: $description) {
      id
      description
    }
  }
`;

const getOpenAIPromptsByCompany = `
    query getOpenAIPromptsByCompany($companyId: ID!, $clientMatterId: ID) {
      openAIPromptsByCompany(
        companyId: $companyId
        clientMatterId: $clientMatterId
      ) {
        companyId
        id
        name
        description
        prompt
      }
    }
`;

export default function ContractReviewAndAdvice(props) {
  const {
    redirectUrl,
    matterId,
    clientMatterName,
    briefId,
    background,
    handleUploadLink,
    contractReviewInfo,
  } = props;

  // console.log("contractReviewInfo", contractReviewInfo);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const history = useHistory();
  const statuses = [
    "Processing Your Contract...",
    "Analyzing your contract...",
    "Generating your advice...",
    "Less than 6 minutes remaining...",
    "Successful! Generating PDF...",
  ];
  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
  const [displayedStatuses, setDisplayedStatuses] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [displayLoading, setShowDisplayLoading] = useState(false);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [pdfLink, setPdfLink] = useState(null);
  const [contextualSummary, setContextualSummary] = useState([]);

  //TOAST
  const [resultMessage, setResultMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isToastError, setIsToastError] = useState(false);

  // GENERATION STATUSES
  const currentStatusRef = useRef({
    uploadStatus: null,
    textractStatus: null,
    conversationStatus: null,
    pdfStatus: null,
  });
  const statusIntervalRef = useRef(null);

  const spinnerStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderTop: "4px solid #1e3a8a",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    animation: "spin 1s linear infinite",
    margin: "0 auto 20px auto", // Center the spinner and add space below it
  };

  useEffect(() => {
    if (isStarted && currentStatusIndex < statuses.length) {
      function updateStatus() {
        setDisplayedStatuses((prev) => [...prev, statuses[currentStatusIndex]]);
        setCurrentStatusIndex(currentStatusIndex + 1);
        clearInterval(statusIntervalRef.current);
      }

      statusIntervalRef.current = setInterval(() => {
        let { uploadStatus, textractStatus, conversationStatus, pdfStatus } =
          currentStatusRef.current;

        switch (currentStatusIndex) {
          // case 1:
          //   if (uploadStatus) {
          //     updateStatus();
          //   }
          // break;
          case 2:
            if (textractStatus) {
              updateStatus();
            }
            break;
          case 4:
            if (conversationStatus) {
              updateStatus();
            }
            break;
          case 5:
            if (pdfStatus) {
              updateStatus();
            }
            break;
          default:
            updateStatus();
            break;
        }
      }, 2000); // Change the interval time as needed
    }

    return () => clearInterval(statusIntervalRef.current);
  }, [currentStatusIndex, isStarted]);

  useEffect(() => {
    fetchContextualSummary();
    setName(contractReviewInfo?.fullName?.value);
    setEmail(contractReviewInfo?.emailAddress?.value);
  }, []);

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  function visitBrief() {
    history.push(redirectUrl);
  }

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  };

  function triggerToast(message, isError) {
    setResultMessage(message);
    setIsToastError(isError);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }

  const handleSubmit = async (e) => {
    console.group("Contract Review Process");
    e.preventDefault();
    // handleGeneratePDF();

    setIsSaving(true);

    //UI updates
    setShowDisplayLoading(true);
    setIsStarted(true);
    setDisplayedStatuses([statuses[0]]);
    setCurrentStatusIndex(1);

    try {
      // Assuming 'background' contains the necessary data including 'id'
      if (!background || !background.items[0].id) {
        throw new Error("Background data is missing or invalid");
      }

      // if (!name || !email) {
      //   let errMsg =
      //     "Unable to produce contract review. Please input the required fields";
      //   alert(errMsg);
      //   throw new Error(errMsg);
      // }

      if (!displayFiles?.length > 0) {
        let errMsg =
          "Unable to produce contract review. Please upload your contract";
        alert(errMsg);
        throw new Error(errMsg);
      }
      // Handle successful submission

      await new Promise((resolve) => setTimeout(resolve, 2000));

      console.log("displayFiles", displayFiles);
      if (
        displayFiles.some(
          (file) =>
            !file.extractedFileContent ||
            file.extractedFileContent === null ||
            file.extractedFileContent === ""
        )
      ) {
        let errMsg =
          "Text Extraction failed on one of the file/s. Please reupload the files";
        triggerToast(errMsg, true);
        throw new Error(errMsg);
      }

      currentStatusRef.current = {
        ...currentStatusRef.current,
        textractStatus: true,
      };
      // Perform Contract Review
      let response = await handleOpenaiAiConversation(
        name,
        email,
        displayFiles
      ).then((res) => {
        if (!res) {
          throw new Error("Failed to generate an advice");
        }
        currentStatusRef.current = {
          ...currentStatusRef.current,
          conversationStatus: true,
        };
        handleGeneratePDF(res);
        // console.log("result", res);
      });

      // Save review to Background Description
      await updateBackgroundDesc(background.items[0].id, response);

      // Handle generation of PDF
    } catch (error) {
      console.error("Submission error:", error);
      setIsSaving(false);
      setShowDisplayLoading(false);
      clearInterval(statusIntervalRef.current);
      // Handle error
    } finally {
      console.groupEnd("Contract Review Process");

      //setIsSaving(false);
      //setShowDisplayLoading(false);
    }
  };

  const autoSave = async (field, key, value) => {
    if (field === "emailAddress" && !validateEmail(value)) {
      alert("Email address is not in the correct format");
      return;
    }

    console.log(
      "background.items.dynamicData",
      background.items[0].dynamicData
    );

    const existingDynamicData = JSON.parse(background?.items[0]?.dynamicData);

    // Merge existing data with the new key-value pair
    const updatedDynamicData = {
      ...existingDynamicData,
      [key]: value,
    };

    console.log("updatedDynamicData", updatedDynamicData);
    try {
      await API.graphql({
        query: mUpdateBackgroundDynamicData,
        variables: {
          id: background.items[0].id,
          dynamicData: JSON.stringify(updatedDynamicData),
        },
      });
    } catch (error) {
      console.error("Error saving background:", error);
    }
  };

  const fetchContextualSummary = async () => {
    try {
      const response = await API.graphql({
        query: getOpenAIPromptsByCompany,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matterId,
        },
      });

      let context = response?.data?.openAIPromptsByCompany;

      if (context && context.length > 0) {
        setContextualSummary(
          context.reduce((acc, item) => {
            const { description } = item;
            if (
              description !== null &&
              description !== undefined &&
              description.trim() !== ""
            ) {
              acc.push(description);
            }
            return acc;
          }, [])
        );
      }
    } catch (error) {
      console.error("Error fetching background:", error);
    }
  };

  const handleGeneratePDF = async (content) => {
    const fileKey = `${clientMatterName
      .replaceAll(/[^a-zA-Z0-9.-]+|\.(?=.*\.)/g, " ")
      .replaceAll(/\s/g, " ")}_Contract_Review_and_Advice.pdf`;

    const data = JSON.stringify({
      content: content.replace(/\n/g, "<br>"),
      bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
      key: `public/${matterId}/${fileKey}`,
    });

    let config = {
      method: "post",
      url: process.env.REACT_APP_PDF_GENERATOR_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Link", JSON.stringify(response.data));
        console.log("Linklink", response.data.requestResult.PresignedUrl);
        currentStatusRef.current = {
          ...currentStatusRef.current,
          pdfStatus: true,
        };
        setTimeout(() => {
          setPdfLink(response.data.requestResult.PresignedUrl);
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async function updateBackgroundDesc(id, description) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDesc,
          variables: {
            id,
            description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  let conversationHistory = []; // Initialize conversation history

  async function handleOpenaiAiConversation(name, email, extractedFiles) {
    const validSummaries = contextualSummary.filter((ctx) => ctx.trim() !== "");
    let maxTokenUsePerMinute = 160000; // INCREASE WHEN CHANGING TIERS
    let tokenUsePerMinute = 0;

    console.log("Valid Summaries >> ", validSummaries);

    const model = process.env.REACT_APP_CLAUDE_MODEL;
    const endpoint = process.env.REACT_APP_CLAUDE_ENDPOINT;
    const stop = ["[END OF TEMPLATE]"];
    console.log("USING MODEL: ", model);

    // Define your array of instructions
    const instructions = [
      `I uploaded a new contract. To enable you to provide me with high-quality, detailed advice later, provide me with answers to the following questions based on the uploaded contract: 
1. Contract document: Provide the full, unexecuted contract document, including all schedules, attachments, and any special conditions.
2. Client details: Share basic information about the clients (owners), including their names, contact details, and the property address.
3. Project specifics: Offer details about the nature of the work (e.g., renovations, new build, additions) and any specific client concerns or priorities.
4. Relevant correspondence: Share any pertinent email correspondence or submissions from the client related to the contract review.
5. Applicable laws and regulations: Confirm the relevant jurisdiction and any specific laws or regulations that need to be considered (e.g., Home Building Act 1989 NSW).
6. Your standard practices: Provide information about your usual approach to contract reviews, including any standard clauses or recommendations you typically include.
7. Recent legal developments: Inform me of any recent changes in legislation or case law that might impact the advice.
8. Industry standards: Provide information on current industry standards or common practices in residential construction contracts.
9. Your firm's style guide: If you have a specific format or style for your advice letters, sharing this would help me align the output more closely with your firm's standards.

Here's the uploaded document content: 
${extractedFiles[0].extractedFileContent}`,
      `1. Consider the answers you provided to the nine questions.
2. Review and analyse the information from the three precedent advices uploaded in your project knowledge.
3. Refer to the Contract Advice Template with prompts to be completed in your project knowledge.
4. Based on the uploaded new contract, complete the prompts in the Contract Advice Template. Observe proper line spacing. Do not omit any part of the template.`];

    // Define the repeated instruction
    const continuationInstruction = `Continue your response following the last provided paragraph. Note that if you've reached the end of the Contract Advice Template, output "[END OF TEMPLATE]"`;

    // Add the repeated instruction 6 times to the instructions array
    for (let i = 0; i < 9; i++) {
      instructions.push(continuationInstruction);
    }

    const tokenUse = [];
    // Define role for the purpose of accuracy results
    // const systemRole = `You are a seasoned lawyer based in Sydney, Australia, and you give contract review and advice. You have a wealth of experience in residential construction contracts and are familiar with the Home Building Act 1989 NSW. You have a strong understanding of the legal requirements and industry standards in the construction sector.`;

    const systemRole = [
      {
        type: "text", 
        text: "You are a seasoned lawyer based in Sydney, Australia, and you give contract review and advice. You have a wealth of experience in residential construction contracts and are familiar with the Home Building Act 1989 NSW. You have a strong understanding of the legal requirements and industry standards in the construction sector."
      },
      {
        type: "text",
        text: `Here are the precedents and templates you have as your knowledgebase. 
        ${validSummaries
          .map((summary, index) => `Knowledgebase ${index + 1}: \n${summary}`)
          .join("\n\n")}`,
        cache_control: { type: "ephemeral" } // Optional: use cache control if needed
      }
    ];

    try {
      let allResponses = []; 
  
      for (let i = 0; i < instructions.length; i++) {
        const instruction = instructions[i];
        const userMessageCounter = i + 1;
  
        conversationHistory.push({
          role: "user",
          content: instruction
        });
  
        const requestBody = {
          model,
          messages: conversationHistory,
          max_tokens: 1000,
          temperature: 0,
          system: systemRole,
        };
  
        console.log(`User Instruction ${userMessageCounter}:`, instruction);
  
        const response = await fetch(endpoint, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        console.log("Raw Response >>> ", response);
  
        if (!response.ok) {
          const errorText = await response.text();
          console.error("Response Error:", errorText);
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }
  
        const data = await response.json();
  
        console.log("Response >>> ", data);
  
        tokenUse.push(data.usage);
        tokenUsePerMinute += data.usage.input_tokens + data.usage.output_tokens;
  
        setTimeout(() => {
          tokenUsePerMinute -= data.usage.input_tokens + data.usage.output_tokens;
          console.log("Current Token Use per Minute", tokenUsePerMinute);
        }, 60000);
  
        if (data && data.content && Array.isArray(data.content) && data.content[0] && data.content[0].text) {
          const assistantResponse = data.content[0].text.trim();
          console.log(`Assistant Response to User Message ${userMessageCounter}:`, assistantResponse);
  
          conversationHistory.push({
            role: "assistant",
            content: assistantResponse,
          });
  
          if (i !== 0) {
            if (stop.some((stop_sequence) => assistantResponse.includes(stop_sequence))) {
              allResponses.push(assistantResponse.replace("[END OF TEMPLATE]", ''));
              console.warn("Breaking early due to stop sequence");
              break;
            }
            allResponses.push(assistantResponse);
          }
        }
  
        let delay = (60000 * tokenUsePerMinute) / maxTokenUsePerMinute;
  
        console.log(`Current Token Use per Minute ${tokenUsePerMinute} out of ${160000}`);
        console.log(`Waiting for ${(delay / 1000).toFixed(2)} second/s`);
  
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
  
      const totalTokensSum = tokenUse.reduce(
        (sum, item) => sum + item.input_tokens + item.output_tokens,
        0
      );
  
      console.log("TOKEN USAGE");
      console.table(tokenUse);
      console.log("TOTAL USAGE", totalTokensSum);
  
      return allResponses.join("\n\n");
    } catch (error) {
      console.error("Error in handleOpenaiAiConversation:", error);
  
      const previousResponses = conversationHistory
        .filter((entry) => entry.role === "assistant")
        .slice(1)
        .map((entry) => entry.content);
  
      return previousResponses.length > 0
        ? previousResponses.join("\n\n")
        : `An error occurred while processing the request: ${error.message}`;
    }
  }

//   async function handleOpenaiAiConversation(name, email, extractedFiles) {

//     console.log("TESTRAWR contextualSummary", contextualSummary);

//     console.group("handleOpenaiAiConversation");
//     let tokenUse = [];
//     let conversationResponse;

//     const {
//       REACT_APP_CHATGPT_MODEL: model,
//       REACT_APP_OPENAI_ASSISTANT_ID: assistant_id,
//     } = process.env;
//     // const assistant_id = "asst_q3RyJHquXcdjmK7TXDvnLIfE"; // RANDOM
//     const precedentSummaries = [];
//     let template;
    
//     contextualSummary.map((context) => {
//       if(context.name === "Precedent Summary") {
//         precedentSummaries.push(context.description)
//       } else {
//         template = context.description;
//       }
//     })
//     // const convertedTextPayload = extractedText
//     //   .replace(/\\[bfnrtv0'"\\]|[\r\n]/g, " ")
//     //   .replace(/\s+/g, " ")
//     //   .trim();

//     const promptsArray = [];

//     const instructions = [
//     `1. Consider the answers you provided to the nine questions.
// 2. Review and analyse the information from the three precedent advices uploaded in your project knowledge.
// 3. Refer to the Contract Advice Template with prompts to be completed in your project knowledge.
// 4. Based on the uploaded new contract, complete the prompts in the Contract Advice Template. Observe proper line spacing. Do not omit any part of the template.
// Below are the precedents and template you have as your knowledgebase: 

// ${precedentSummaries.map((summary, index) => 
//   `Precedent ${index + 1}: \n${summary} \n\n`
// )}
// Contract Advice Template: \n${template}`
//     ];
//     // Initial Request for Contract Review Assistance
//     let initialRequestPrompt = `I require your assistance to prepare a contract review and advice for my client. You should remember these information for now. Please carefully read, analyse, and understand these information.`;
//     initialRequestPrompt += `\n\nContract: I will provide you with the contract of my client.`;
//     // initialRequestPrompt += `\n\nEmail from my client: I will provide you my client's email to me attaching the contract and other attachments related to the Contract.`;
//     initialRequestPrompt += `\n\nPrecedent Contract Review: To guide you, I will provide you a precedent for the same contract but for a different client.`;
//     initialRequestPrompt += `\n\nCan you assist me in preparing the contract review and advice? Start by performing an initial assessment of my request.`;

//     promptsArray.push(initialRequestPrompt);

//     if (Array.isArray(extractedFiles) && extractedFiles.length > 0) {
//       const validFiles = extractedFiles.filter(
//         (file) =>
//           file?.extractedFileContent && file.extractedFileContent.trim() !== ""
//       );

//       // Initial Contract Review
//       let contractReviewPrompt = `This is the Contract of my client. Read the content and memorise it for now. Perform an initial review of the Contract. I will message you further instructions after that.`;

//       contractReviewPrompt += `\n\n${validFiles
//         .map(
//           (file, i) =>
//             `Contract ${i + 1}: \n"${file.extractedFileContent
//               .replace(/\[bfnrtv0'"\]|[\r\n]/g, " ")
//               .replace(/\s+/g, " ")
//               .trim()}"\n`
//         )
//         .join("")}`;

//       promptsArray.push(contractReviewPrompt);

//       // Email and Attachments Review

//       console.log("contextualSummary", contextualSummary);
//       if (Array.isArray(contextualSummary) && contextualSummary.length > 0) {
//         const validSummaries = contextualSummary.filter(
//           (ctx) => ctx.trim() !== ""
//         );

//         // Precedent Contract Review
//         let precedentReviewPrompt = `This is the Precedent Contract Review. Read the content and memorise it for now. Perform an initial review of the Precedent Contract. I will message you further instructions after that.`;

//         precedentReviewPrompt += `\n\n${validSummaries
//           .map(
//             (ctx, i) =>
//               `Precedent Contract ${i + 1}: \n"${ctx
//                 .replace(/\[bfnrtv0'"\]|[\r\n]/g, " ")
//                 .replace(/\s+/g, " ")
//                 .trim()}"\n`
//           )
//           .join("")}`;

//         promptsArray.push(precedentReviewPrompt);

//         // Template Creation Using Precedent
//         let templateUsingPrecedentPrompt = `Using the Precedent Contract, create a template.`;
//         templateUsingPrecedentPrompt += `Ensure that the structure of the advice document follows a logical flow similar to the precedent. `;
//         templateUsingPrecedentPrompt += `Copy the exact provisions and words in the Precent Contract but leave prompts for the items that needs to be updated.`;

//         promptsArray.push(templateUsingPrecedentPrompt);

//         // Incorporate the template with the Contract details
//         let incorporateTemplatePrompt = `Using the template you provided above, update the variable prompts to reflect the attached Contract of my Client accordingly.`;
//         promptsArray.push(incorporateTemplatePrompt);
//       } else {
//         console.log("No contextual summary found.");
//         promptsArray.push(
//           "Using the given information, perform a review of the Contract. "
//         );
//       }
//     } else {
//       console.error("No extracted content found.");
//     }

//     console.log("promptsArray", promptsArray);

//     try {
//       // Creates conversation thread with initial instruction
//       const {
//         usage: initialUsage,
//         thread_id,
//         id: initial_run_id,
//       } = await handleOpenaiCall(
//         `https://api.openai.com/v1/threads/runs`,
//         "POST",
//         {
//           assistant_id,
//           model,
//           thread: {
//             messages: [{ role: "user", content: initialRequestPrompt }],
//           },
//           stream: true,
//           tool_choice: "none",
//         }
//       );

//       console.log("Created message: ", initialRequestPrompt);

//       const listMessagesRes = await handleOpenaiCall(
//         `https://api.openai.com/v1/threads/${thread_id}/messages`,
//         "GET"
//       );

//       conversationResponse = listMessagesRes.data.find(
//         (msg) => msg.run_id === initial_run_id
//       ).content[0].text.value;

//       console.log("Response: ", conversationResponse);

//       console.log("Successfully initialized thread", thread_id);
//       tokenUse.push(initialUsage);

//       try {
//         // Adds further messages unto thread
//         for (let i = 1; i < promptsArray.length; i++) {
//           await handleOpenaiCall(
//             `https://api.openai.com/v1/threads/${thread_id}/messages`,
//             "POST",
//             { role: "user", content: promptsArray[i] }
//           );

//           console.log("Created message: ", promptsArray[i]);

//           const runRes = await handleOpenaiCall(
//             `https://api.openai.com/v1/threads/${thread_id}/runs`,
//             "POST",
//             {
//               assistant_id,
//               stream: true,
//               model,
//               tool_choice: "none",
//               // max_prompt_tokens:
//               // max_completion_tokens:
//               // truncation_strategy:
//             }
//           );

//           tokenUse.push(runRes.usage);

//           await new Promise((resolve) => setTimeout(resolve, 500));

//           const listMessagesRes = await handleOpenaiCall(
//             `https://api.openai.com/v1/threads/${thread_id}/messages`,
//             "GET"
//           );

//           conversationResponse = listMessagesRes.data.find(
//             (msg) => msg.run_id === runRes.id
//           ).content[0].text.value;
//           console.log("Response: ", conversationResponse);
//         }
//       } catch (err) {
//         conversationResponse = null;
//         console.error(err);
//       }

//       await handleOpenaiCall(
//         `https://api.openai.com/v1/threads/${thread_id}`,
//         "DELETE"
//       );
//       console.log("Deleted Thread", thread_id);
//     } catch (err) {
//       console.err(err);
//       conversationResponse = null;
//     }

//     const totalTokensSum = tokenUse.reduce(
//       (sum, item) => sum + item.total_tokens,
//       0
//     );
//     console.table(tokenUse);
//     console.log("TOTAL USAGE", totalTokensSum);

//     console.groupEnd("handleOpenaiAiConversation");

//     return conversationResponse;
//   }

//   const handleOpenaiCall = async (endpoint, method, body) => {
//     const { REACT_APP_CHATGPT_API: apiKey } = process.env;

//     const response = await fetch(endpoint, {
//       method,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${apiKey}`,
//         "OpenAI-Beta": "assistants=v2",
//       },
//       ...(body ? { body: JSON.stringify(body) } : {}),
//     });

//     if (!response.ok) {
//       throw new Error(response.statusText);
//     }

//     // If api call is streamed
//     if (body?.stream) {
//       try {
//         const reader = response.body.getReader();
//         const decoder = new TextDecoder("utf-8");

//         // Start an asynchronous operation wrapped in a Promise.
//         let runRes = await new Promise((resolve, reject) => {
//           let result = ""; // Initialize an empty string to accumulate results.
//           // Begin reading data from the reader.
//           reader
//             .read()
//             .then(function processChunk({ done, value }) {
//               // If the read operation is complete, resolve the promise with the accumulated result.
//               if (done) {
//                 resolve(result);
//                 return;
//               }

//               // Decode the chunk of data received.
//               const chunk = decoder.decode(value);
//               // Split the chunk into payloads based on double newline separator.
//               const payloads = chunk.split("\n\n");

//               // Iterate over each payload
//               let isDone = false;
//               for (const payload of payloads) {
//                 // If the payload indicates completion, resolve the promise with the result.
//                 if (payload.includes("[DONE]")) {
//                   const eventData = result.split("data: ").slice(1).join();
//                   const eventJson = JSON.parse(eventData); // Parse the event data as JSON.

//                   resolve(eventJson);
//                   return;
//                 }

//                 // If the payload starts with 'event: thread.run.completed', update result with event data.
//                 if (
//                   payload.startsWith("event: thread.run.completed") ||
//                   isDone
//                 ) {
//                   isDone = true;
//                   result += payload;
//                 }
//               }

//               // Continue reading the next chunk of data.
//               reader.read().then(processChunk).catch(reject);
//             })
//             .catch(reject); // Catch any errors encountered during reading and reject the promise.
//         });

//         return runRes;
//       } catch (error) {
//         console.error("Error in streaming response:", error);
//         throw error;
//       }
//     }

//     const res = await response.json();

//     return res;
//   };

  // async function handleOpenaiAiConversationCompletions(extractedText) {
  //   console.group("TEST SUMMARY 1");

  //   console.log("GPT MODEL:", process.env.REACT_APP_CHATGPT_MODEL);

  //   let conversation = [];
  //   let tokenUse = [];

  //   for (let i = 0; i < promptsArray.length; i++) {
  //     let res = await handleOpenaiAiConversation(promptsArray[i], conversation);
  //     conversation.push({ role: "user", content: promptsArray[i] });
  //     conversation.push({ role: "assistant", content: res.message });
  //     console.log(`RESPONSE ${i + 1}:`, res.message);
  //     tokenUse.push(res.aiApiTokenUsage);
  //   }

  //   console.log("RESPONSE: ", conversation[conversation.length - 1].content);
  //   console.log("TOKEN USAGE");
  //   console.table(tokenUse);
  //   const totalTokensSum = tokenUse.reduce(
  //     (sum, item) => sum + item.total_tokens,
  //     0
  //   );
  //   console.log("TOTAL USAGE", totalTokensSum);

  //   console.groupEnd("TEST SUMMARY 1");
  // }
  return (
    <div className="bg-white shadow rounded-lg border border-neutral-200 overflow-hidden">
      <div className="w-full pt-6 px-8">
        <div className="relative">
          {[24, 40, 56, 72].map((size, index) => (
            <div
              key={size}
              className={`z-0 absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-${size} h-${size} bg-transparent rounded-full border border-1`}
              style={{
                borderColor: `rgba(243, 244, 246, ${1 - index * 0.25})`,
              }}
            ></div>
          ))}
        </div>
        <div className="bg-white flex justify-between items-center ">
          <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
            <img
              src={sitediaryIcon}
              alt="Contract Review and Advice Icon"
              className="pb-2 w-11 h-auto"
            />
          </div>
          <div
            className="p-2 rounded-lg border bg-white border-gray-300 justify-center items-center hover:bg-gray-100 cursor-pointer hover:text-gray-500"
            onClick={visitBrief}
          >
            <FiEdit className="w-4 h-4" />
          </div>
        </div>

        <div className="relative">
          <div className="inline-block w-full items-center gap-2 pb-5">
            <div className="inline-flex">
              <div className="text-black text-lg font-open-sans font-semibold mr-1 pb-2">
                Contract Review and Advice
              </div>
              <BsInfoCircle className="text-xs" />
            </div>
          </div>
        </div>

        {displayLoading === false ? (
          <>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  onBlur={(e) =>
                    autoSave(
                      `fullName`,
                      contractReviewInfo?.fullName?.accessorKey,
                      e.target.value
                    )
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={(e) =>
                    autoSave(
                      `emailAddress`,
                      contractReviewInfo?.emailAddress?.accessorKey,
                      e.target.value
                    )
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div className="mb-6">
                <BackgroundPopupFileUpload
                  briefId={briefId}
                  matterId={matterId}
                  item={background}
                  setIsSaving={setIsSaving}
                  setDisplayFiles={setDisplayFiles}
                  displayFiles={displayFiles}
                  triggerToast={triggerToast}
                />
              </div>

              <Button
                variant={isSaving === true ? "" : "hbBlue"}
                className={
                  isSaving === true
                    ? "py-2 mt-3 cursor-pointer bg-hbLightGray border border-hbLightGray text-hbBlue shadow-none hover:text-white py-2 mt-3 cursor-pointer mb-6"
                    : "py-2 mt-3 cursor-pointer mb-6"
                }
                block={"full"}
                disabled={isSaving}
                onClick={handleSubmit}
              >
                {isSaving ? "Submit" : "Submit"}
              </Button>
            </form>
          </>
        ) : (
          <div className="w-full h-full py-5 px-2">
            {pdfLink === null && <div style={spinnerStyle}></div>}
            {displayedStatuses.map((status, index) => (
              <div key={index} className="flex w-full">
                {index < currentStatusIndex - 1 ? (
                  <>
                    <FaRegCheckCircle
                      size={16}
                      className={"text-blue-400 mr-1 w-6"}
                    />{" "}
                    <p className="w-full  text-blue-400">{status}</p>
                  </>
                ) : index === 4 ? (
                  <>
                    <FaRegCheckCircle
                      size={16}
                      className={"text-green-500 mr-1 w-6"}
                    />{" "}
                    <p className="w-full text-green-500">{status}</p>
                  </>
                ) : (
                  <>
                    <FaRegCheckCircle
                      size={16}
                      className={"text-gray-700 mr-1 w-6"}
                    />{" "}
                    <p className="w-full  text-gray-700">{status}</p>
                  </>
                )}
              </div>
            ))}

            {pdfLink !== null ? (
              <div className="mt-2 w-full">
                <Button
                  variant={"hbBlue"}
                  className="w-full"
                  onClick={() => window.open(pdfLink)}
                >
                  Open Link
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      {showToast && resultMessage ? (
        <ToastNotification title={resultMessage} error={isToastError} />
      ) : null}
    </div>
  );
}
