import React from "react";
import { API } from "aws-amplify";
import { FaSort, FaSearch, FaArrowLeft } from "react-icons/fa";
import { BsSortUpAlt, BsSortDown, BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import ShareLinkButton from "../action-buttons/shareLink-button";
import Button from "../../shared/Button";
import { AppRoutes } from "../../constants/AppRoutes";
import { obaDomains, hbaDomains, mmaDomains } from "../../constants/AppDomains";
import { updateBackgroundOrders } from "../../shared/graphql/Backgrounds/mutations";

const MobileTableHeader = ({
  ascDesc,
  SortBydate,
  brief_id,
  matter_id,
  briefName,
  handleSearchDescriptionChange,
  sortByOrder,
  setBackground,
  setPreviousBackground,
  background,
}) => {
  let headerContent;

  var moment = require("moment");
  const history = useHistory();

  const mCreateBackground = `
		mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
		backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
			id
			createdAt
			date
			description
			order
		}
		}
		`;

  const handleAddRow = async () => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: brief_id, name: briefName }];
      console.log(tempBriefDetails);

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
        },
      });

      if (createBackgroundRow) {
        const result = {
          createdAt: dateToday,
          id: createBackgroundRow.data.backgroundCreate.id,
          description: "",
          date: null,
          order: 0,
          files: { items: [] },
          labels: { items: [] },
          briefs: [{ id: brief_id, name: briefName }],
        };

        setBackground((background) => sortByOrder(background.concat(result)));
        setPreviousBackground((background) =>
          sortByOrder(background.concat(result))
        );
        background.splice(0, 0, result);

        const rowArrangement = background.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement);

        // For FE purposes: Update existing background array with the updated order data
        background.forEach((item, index) => {
          item.order = index + 1;
        });

        setBackground(background);
        setPreviousBackground(background);
        // autoAdjustAllRow();

        // Added .5 secs delay for saving updated arrangement of rows before refreshing the page
        // Removed for MMA 2309
        // setTimeout(() => {
        //   //** ADDED WINDOW RELOAD TO SIMULATE REFETCHING OF BACKGROUND AFTER SAVING DESC */
        //   window.location.reload();
        // }, 500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle back button click
  const handleBackButtonClick = () => {
    const currUrl = window.location.hostname;
    const redirectRoute = mmaDomains.includes(currUrl)
      ? AppRoutes.DASHBOARD
      : hbaDomains.includes(currUrl)
      ? AppRoutes.HBADASHBOARD
      : AppRoutes.OBADASHBOARD;

    history.push(`${redirectRoute}`);
    // history.goBack(); // Go back to the last visited page
  };

  if (briefName === "Variations") {
    headerContent = (
      <>
        <h2 className="font-medium text-lg">Variations</h2>
        <p className="pb-2 text-gray-500 text-xs">
          Request changes or modifications to the original plan and keep track
          of any variations and their corresponding costs and approvals.
        </p>

        <div className="p-1 pr-4 pb-2 flex items-center gap-2">
          <button
            className="flex items-center text-gray-800 hover:text-gray-600"
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "8px",
            }}
            onClick={handleBackButtonClick}
          >
            <BsArrowLeft style={{ fontSize: "18px" }} className="" />
          </button>
          <div className="flex flex-grow items-center">
            {" "}
            {/* Updated this line */}
            <Button
              onClick={handleAddRow}
              className="px-3 py-2 bg-white text-white text-sm rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex whitespace-nowrap"
              variant="hbBlue"
            >
              <div className="font-semibold font-open-sans leading-tight">
                Add New
              </div>
            </Button>
            <div
              className="flex items-center rounded ml-2"
              style={{
                backgroundColor: "white",
                border: "1px solid #ddd",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <FaSearch className="text-gray-600 m-2" />
              <input
                className="relative self-center font-open-sans flex-1 flex-grow flex-shrink w-px h-8 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light" // Add flex-grow to occupy remaining space
                type="text"
                placeholder="Search anything..."
                style={{ border: "none" }} // Remove width: 100% here
                onChange={handleSearchDescriptionChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else if (briefName === "Contracts") {
    headerContent = (
      <>
        <h2 className="font-medium text-lg">Contracts</h2>
        <p className="pb-2 text-gray-500 text-xs">
          Securely submit your Home's construction paperwork.
        </p>

        <div className="p-1 pr-4 pb-2 flex items-center gap-2">
          <button
            className="flex items-center text-gray-800 hover:text-gray-600"
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "8px",
            }}
            onClick={handleBackButtonClick}
          >
            <BsArrowLeft style={{ fontSize: "18px" }} className="" />
          </button>
          <div className="flex flex-grow items-center">
            {" "}
            {/* Updated this line */}
            {/* <Button
					onClick={handleAddRow}
					className="px-2 py-1.5" // Removed unnecessary flex and items-center classes
					variant="hbBlue"
					style={{ minWidth: '70px' }} // Adjusted width to accommodate "Add New" text
					>
					AddNew
					</Button> */}
            <div
              className="flex items-center rounded ml-2"
              style={{
                backgroundColor: "white",
                border: "1px solid #ddd",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <FaSearch className="text-gray-600 m-2" />
              <input
                className="relative self-center font-open-sans flex-1 flex-grow flex-shrink w-px h-8 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light" // Add flex-grow to occupy remaining space
                type="text"
                placeholder="Search anything..."
                style={{ border: "none" }} // Remove width: 100% here
                onChange={handleSearchDescriptionChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else if (briefName === "Invoices") {
    headerContent = (
      <>
        <h2 className="font-medium text-lg">Invoices</h2>
        <p className="pb-2 text-gray-500 text-xs">
          Access and manage all your payment invoices in one place. Review
          detailed breakdowns of costs and track your payments with ease.
        </p>

        <div className="p-1 pr-4 pb-2 flex items-center justify-between">
          <button
            className="flex items-center text-gray-800 hover:text-gray-600"
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "8px",
            }}
            onClick={handleBackButtonClick}
          >
            <BsArrowLeft style={{ fontSize: "18px" }} className="" />
          </button>
          <div
            className="flex items-center rounded w-full ml-4"
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "5px",
            }}
          >
            <FaSearch className="text-gray-600 m-2" />
            <input
              className="relative self-center font-open-sans flex-1 flex-grow flex-shrink w-px h-8 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light" // Add flex-grow to occupy remaining space
              type="text"
              placeholder="Search anything..."
              style={{ border: "none" }}
              onChange={handleSearchDescriptionChange}
            />
          </div>
        </div>
      </>
    );
  } else if (briefName === "Site Diary") {
    headerContent = (
      <>
        <h2 className="font-medium text-lg">Site Diary</h2>
        <p className="pb-2 text-gray-500 text-xs">
          Log and manage plan changes, track variations, costs, and approvals
          efficiently in your construction project.
        </p>

        <div className="p-1 pr-4 pb-2 items-center flex gap-2">
          <button
            className="flex items-center text-gray-800 hover:text-gray-600"
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "8px",
            }}
            onClick={handleBackButtonClick}
          >
            <BsArrowLeft style={{ fontSize: "18px" }} className="" />
          </button>
          <div className="flex flex-grow items-center">
            {" "}
            {/* Updated this line */}
            <Button
              onClick={handleAddRow}
              className="px-3 py-2 bg-white text-white text-sm rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex whitespace-nowrap"
              variant="hbBlue"
            >
              <div className="font-semibold font-open-sans leading-tight">
                Add New
              </div>
            </Button>
            <div
              className="flex items-center rounded ml-2"
              style={{
                backgroundColor: "white",
                border: "1px solid #ddd",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <FaSearch className="text-gray-600 m-2" />
              <input
                className="relative self-center font-open-sans flex-1 flex-grow flex-shrink w-px h-8 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light" // Add flex-grow to occupy remaining space
                type="text"
                placeholder="Search anything..."
                style={{ border: "none" }} // Remove width: 100% here
                onChange={handleSearchDescriptionChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else if (briefName === "Progress Photos") {
    headerContent = (
      <>
        <h2 className="font-medium text-lg">Progress Photos</h2>
        <p className="pb-2 text-gray-500 text-xs">
          Easily capture and organize construction progress photos for quick
          reference and documentation.
        </p>

        <div className="p-1 pr-4 pb-2 flex items-center gap-2">
          <button
            className="flex items-center text-gray-800 hover:text-gray-600"
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "8px",
            }}
            onClick={handleBackButtonClick}
          >
            <BsArrowLeft style={{ fontSize: "18px" }} className="" />
          </button>
          <div className="flex flex-grow items-center">
            {" "}
            {/* Updated this line */}
            <Button
              onClick={handleAddRow}
              className="px-3 py-2 bg-white text-white text-sm rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex whitespace-nowrap"
              variant="hbBlue"
            >
              <div className="font-semibold font-open-sans leading-tight">
                Add New
              </div>
            </Button>
            <div
              className="flex items-center rounded ml-2"
              style={{
                backgroundColor: "white",
                border: "1px solid #ddd",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <FaSearch className="text-gray-600 m-2" />
              <input
                className="relative self-center font-open-sans flex-1 flex-grow flex-shrink w-px h-8 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light" // Add flex-grow to occupy remaining space
                type="text"
                placeholder="Search anything..."
                style={{ border: "none" }} // Remove width: 100% here
                onChange={handleSearchDescriptionChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else if (briefName === "Progress of work") {
    headerContent = (
      <>
        <h2 className="font-medium text-lg">Progress of Work</h2>
        <p className="pb-2 text-gray-500 text-xs">
          Track and update the status of construction tasks in real-time,
          ensuring efficient workflow management.
        </p>

        <div className="p-1 pr-4 pb-2 flex items-center gap-2">
          <button
            className="flex items-center text-gray-800 hover:text-gray-600"
            style={{
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "8px",
            }}
            onClick={handleBackButtonClick}
          >
            <BsArrowLeft style={{ fontSize: "18px" }} className="" />
          </button>
          <div className="flex flex-grow items-center">
            {" "}
            {/* Updated this line */}
            <Button
              onClick={handleAddRow}
              className="px-3 py-2 bg-white text-white text-sm rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex whitespace-nowrap"
              variant="hbBlue"
            >
              <div className="font-semibold font-open-sans leading-tight">
                Add New
              </div>
            </Button>
            <div
              className="flex items-center rounded ml-2"
              style={{
                backgroundColor: "white",
                border: "1px solid #ddd",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <FaSearch className="text-gray-600 m-2" />
              <input
                className="relative self-center font-open-sans flex-1 flex-grow flex-shrink w-px h-8 text-sm leading-normal border-0 rounded rounded-l-none outline-none border-grey-light" // Add flex-grow to occupy remaining space
                type="text"
                placeholder="Search anything..."
                style={{ border: "none" }} // Remove width: 100% here
                onChange={handleSearchDescriptionChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    headerContent = (
      <div className="flex">
        <div>
          <table className="bg-gray-200 mb-2">
            <thead>
              <tr className="flex flex-grow px-2 py-2 font-medium space-x-2">
                <th className="text-center">Item No.</th>
                <th className="flex text-center">
                  Date &nbsp;
                  {ascDesc == null ? (
                    <FaSort
                      className="cursor-pointer"
                      alt="Sort"
                      title="Sort"
                      onClick={SortBydate}
                    />
                  ) : ascDesc === true ? (
                    <BsSortUpAlt
                      className="cursor-pointer"
                      alt="Sort"
                      title="Sort"
                      onClick={SortBydate}
                    />
                  ) : (
                    <BsSortDown
                      className="cursor-pointer"
                      alt="Sort"
                      title="Sort"
                      onClick={SortBydate}
                    />
                  )}
                </th>
                <th>{briefName}</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="ml-1">
          <ShareLinkButton briefId={brief_id} matter_id={matter_id} />
        </div>
      </div>
    );
  }

  return headerContent;
};

export default MobileTableHeader;
