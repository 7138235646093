import React, { useState, useEffect, useRef } from "react";
import { CgTrash } from "react-icons/cg";
import Select from "react-select";
import { API } from "aws-amplify";
import anime from "animejs";
// import CreatableSelect from "react-select/creatable";
import Button from "../../shared/Button";
import { isMobileDevice } from "../../shared/mobileViewFunctions";
import humaReadableFormat from "../../shared/humanReadableFormat";
import { CgClose } from "react-icons/cg";
import { RiAddCircleFill } from "react-icons/ri";
import { MdAdd } from "react-icons/md";
import { Switch } from "@headlessui/react";
import { ListClientMatters, ListUsers } from "../../shared/graphql/queries";
import { hbaDomains } from "../../constants/AppDomains";

const USER_BY_COGNITO_QUERY = `query getUserByCognito($email: String) {
  userByCognito(email: $email) {
    isEnabled
    userAttributes
    userStatus
  }
}`;

const RESEND_INVITE_MUTATION = `mutation resendUserInvite($email: String) {
  userResendInvite(email: $email) {
    isEnabled
    userAttributes
    userStatus
  }
}`;

const INVITE_EXISTING_USER_MUTATION = `mutation inviteCompanyUser($email: String, $company: String, $invitee: String) {
  userCompanyInvite(email: $email, company: $company, invitee: $invitee)
}`;

const CREATE_COMPANY_USER_MUTATION = `mutation createUserCompany($companyId: ID, $hasPortalAccess: Boolean, $userId: ID, $userType: UserType, $userCategory: UserCategory) {
  userCompanyCreate(
    companyId: $companyId
    hasPortalAccess: $hasPortalAccess
    userId: $userId
    userType: $userType,
    userCategory: $userCategory
  ) {
    id
  }
}`;

const INVITE_USER_MUTATION = `mutation inviteUser($email: AWSEmail, $firstName: String, $lastName: String, $userType: UserType, $company: CompanyInput, $businessName: String, $hasPortalAccess:Boolean, $userCategory: UserCategory) {
    userInvite(
      email: $email
      firstName: $firstName
      lastName: $lastName
      userType: $userType
      company: $company
      businessName: $businessName
      hasPortalAccess: $hasPortalAccess
      userCategory: $userCategory
    ) {
      id
      email
    }
  }
`;

const TAG_ALL_USER_CLIENTMATTER_MUTATION = `mutation tagAlluserClientMatter($companyId: ID, $userId: ID, $userType: UserType) {
  userClientMatterTagAll(userId: $userId, userType: $userType, companyId: $companyId) {
    id
  }
}`;

const DEFAULT_USERTYPES_QUERY = `
    query getDefaultUserTypes {
    defaultUserType
  }`;

export default function AddContactModal({
  close,
  setContactList,
  ContactList,
  getContacts,
  setalertMessage,
  seterrortoast,
  setShowToast,
  width,
  emailInfo,
  setContactEmailList,
  setAreContactsAdded,
  contactEmailList,
  fromInboxMobile,
  fromComposeModal,
  UserTypes,
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  const [isDisabled, setisDisabled] = useState(false);
  const [IsHovering, setIsHovering] = useState(false);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState("");
  const [userTypeOptions, setUserTypeOptions] = useState([]);
  const [contactListOptions, setContactListOptions] = useState([]);
  const userId = useRef("");
  const domain = window.location.hostname;

  const getUserTypes = async () => {
    const params = {
      query: DEFAULT_USERTYPES_QUERY,
    };

    const result = await API.graphql(params);

    console.log("getUserTypes", result);

    return result.data.defaultUserType.map((userType) => ({
      value: userType,
      label: userType,
    }));
  };

  useEffect(() => {
    const fetchUserTypes = async () => {
      if (userTypeOptions.length === 0) {
        try {
          const ut = await getUserTypes();
          const utlist = ut.map((item) => ({
            ...item,
            label: humaReadableFormat(
              item.label
                .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
                .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
            ),
          }));

          setUserTypeOptions(utlist);
        } catch (e) {
          console.error("fetchUserTypes()", e);
        }
      }
    };

    const fetchContactList = async () => {
      console.log("fetchContactList");
      if (contactListOptions.length === 0) {
        try {
          const { data } = await ListUsers(localStorage.getItem("companyId"));

          // console.log(data);
          // console.log(data.company);
          // console.log(data.company.users);

          const companyUsers = data.company.users.items.map(
            ({ firstName, lastName, id }) => ({
              value: `${firstName} ${lastName}`,
              label: `${firstName} ${lastName}`,
              id,
            })
          );

          setContactListOptions(companyUsers);
        } catch (e) {
          console.error("fetchUserTypes()", e);
        }
      }
    };

    fetchUserTypes();
    fetchContactList();
  }, [userTypeOptions, contactListOptions]);

  useEffect((e) => {
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const handleDelete = (index) => {
    setInputData(InputData.filter((_, idx) => idx !== index));
  };

  // This is for inviting users from Gmail Inbox
  let defaultVal = {
    firstName: "",
    lastName: "",
    email: "",
  };
  if (emailInfo) {
    if (emailInfo?.length !== 0) {
      let names = emailInfo.from.split(" ");
      let emailDefault = names.pop().replace(/[<>]/g, "");

      defaultVal = {
        firstName: names.slice(0, -1).join(" "),
        lastName: names[names.length - 1],
        email: emailDefault,
      };
    }
  }

  const [InputData, setInputData] = useState([
    {
      firstName: defaultVal.firstName,
      lastName: defaultVal.lastName,
      company: {
        id: localStorage.getItem("companyId"),
        name: localStorage.getItem("company"),
      },
      email: fromComposeModal
        ? localStorage.getItem("addEmail")
        : defaultVal.email,
      userType: "",
      hasPortalAccess: false,
    },
  ]);

  const validate = (obj) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let invalidEmail = false;

    if (obj.email) {
      if (!emailRegex.test(obj.email)) {
        invalidEmail = true;
        setInvalidEmailMessage("Invalid email format.");
      } else {
        const emailExists = contactListOptions.some(
          ({ email }) => email === obj.email
        );
        if (emailExists) {
          invalidEmail = true;
          setInvalidEmailMessage("Email already exists.");
        } else {
          setInvalidEmailMessage("");
          invalidEmail = false;
        }
      }
    } else {
      invalidEmail = true;
    }

    // Check for invalid or missing properties
    if (!obj.firstName || !obj.lastName || invalidEmail || !obj.userType) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const validations = InputData.map((input) => validate(input));
    setisDisabled(validations.includes(false));
  }, [InputData]);

  const handleOnChange = (e, i, property) => {
    const { value } = e.target;
    const list = [...InputData];

    list[i][property] = typeof value === "object" ? value.value : value;

    setInputData(list);
    // console.log("InputData", InputData);
  };

  const handleOnChangeSwitch = (e, i, property) => {
    const value = e;
    const list = [...InputData];

    list[i][property] = typeof value === "object" ? value.value : value;

    setInputData(list);
    // console.log("InputData", InputData);
  };

  const handleSelectChange = (e, val, i, property) => {
    const list = [...InputData];
    list[i][property] = e.value;
    setInputData(list);
    // console.log("InputData", InputData);
  };

  async function inviteUser(data) {
    console.log("inviteUser");

    // Destructure data object for cleaner access to properties
    const {
      email,
      firstName,
      lastName,
      userType,
      company,
      businessName,
      hasPortalAccess,
    } = data;

    // Store companyId in a variable to avoid repeated access
    let companyId = localStorage.getItem("companyId");

    let request;
    try {
      // userCategory cannot be null or blank, otherwise it will cause an error in the backend
      const category = localStorage.getItem("userCategory");
      const userCategory =
        category && category !== "null" && hbaDomains.includes(domain)
          ? "HBA_HOMEOWNER"
          : "OWNERBUILDER";

      request = await API.graphql({
        query: INVITE_USER_MUTATION,
        variables: {
          email,
          firstName,
          lastName,
          userType,
          company,
          businessName,
          hasPortalAccess,
          userCategory: userCategory,
        },
      });

      console.log("inviteUser Mutation Result:", request?.data);
    } catch (error) {
      console.error("Error during user invitation:", error);
      throw error;
    }

    if (userType === "OWNER" || userType === "LEGALADMIN") {
      console.log("Added user is ", userType);
      try {
        const params = {
          query: TAG_ALL_USER_CLIENTMATTER_MUTATION,
          variables: {
            companyId,
            userId: request?.data?.userInvite?.id,
            userType,
          },
        };

        let result = await API.graphql(params);
        console.log("Tag all client matter Mutation Result:", result);
      } catch (error) {
        console.error("Error tagging user to client/matter:", error);
        throw error;
      }
    }

    return request;
  }

  async function createCompanyUser(data) {
    console.log("createCompanyUser", data);

    // userCategory cannot be null or blank, otherwise it will cause an error in the backend
    const category = localStorage.getItem("userCategory");
      const userCategory =
        category && category !== "null" && hbaDomains.includes(domain)
          ? "HBA_HOMEOWNER"
          : "OWNERBUILDER";

    const request = await API.graphql({
      query: CREATE_COMPANY_USER_MUTATION,
      variables: {
        userId: data.id,
        userType: data.userType,
        userCategory: userCategory,
        companyId: localStorage.getItem("companyId"),
        hasPortalAccess: data.hasPortalAccess,
      },
    });

    if (
      request &&
      (data.userType === "OWNER" || data.userType === "LEGALADMIN")
    ) {
      const params = {
        query: TAG_ALL_USER_CLIENTMATTER_MUTATION,
        variables: {
          companyId: localStorage.getItem("companyId"),
          userId: data.id,
          userType: data.userType,
        },
      };
      let result = await API.graphql(params);
      console.log("RESULT FROM TAGGING IS:", result);
    }
    console.log("RESULT FROM REQUEST IS:", request);
    return request;
  }

  async function getUserByCognito(email) {
    console.log("getUserByCognito");
    const { data } = await API.graphql({
      query: USER_BY_COGNITO_QUERY,
      variables: {
        email: email,
      },
    });

    return data.userByCognito;
  }

  async function resendUserInvite(email) {
    console.log("resendUserInvite");
    const { data } = await API.graphql({
      query: RESEND_INVITE_MUTATION,
      variables: {
        email: email,
      },
    });

    return data.userResendInvite ?? null;
  }

  async function userCompanyInvite(email) {
    console.log("userCompanyInvite");

    var firstName = localStorage.getItem("firstName"),
      lastName = localStorage.getItem("lastName"),
      companyName = localStorage.getItem("company");

    const { data } = await API.graphql({
      query: INVITE_EXISTING_USER_MUTATION,
      variables: {
        email: email,
        company: companyName,
        invitee: `${firstName} ${lastName}`,
      },
    });

    return data.userCompanyInvite ?? null;
  }

  const handleSubmit = async () => {
    try {
      let email;

      const filteredInputData = InputData.filter((x) => {
        email = x.email;
        return contactListOptions.every((contact) => contact.email !== x.email);
      });

      const invitePromises = filteredInputData.map(async (x) => {
        console.log("handleSubmit input:", x);

        const { userStatus, userAttributes } = await getUserByCognito(x.email);

        console.group("conditions");
        console.log("x.hasPortalAccess", x.hasPortalAccess);
        console.log("userStatus", userStatus);
        console.log("userAttributes", userAttributes);
        console.groupEnd();

        if (x.hasPortalAccess) {
          console.log("give access to user");
          if (userStatus === "UserNotFoundException") {
            // user does not exist
            console.log("user does not exist, create user and send invite");
            await inviteUser(x);
            // await createCompanyUser(x);
            return x.email;
          } else {
            const subAttribute = JSON.parse(userAttributes).find(
              (attr) => attr.Name === "sub"
            );
            const subId = subAttribute ? subAttribute.Value : null;
            x.id = subId;

            if (userStatus === "FORCE_CHANGE_PASSWORD") {
              // has pending invitation, resend invite
              console.log("user has pending invitation");
              console.log("resend invite, then add this user to company");
              await resendUserInvite(x.email);
            } else if (userStatus === "CONFIRMED") {
              console.log("user already exist, add this user to company");
              console.log("Send invite to Company Email");
              await userCompanyInvite(x.email);
            } else {
              return null;
            }

            await createCompanyUser(x);
            return x.email;
          }
        } else {
          if (userStatus === "UserNotFoundException") {
            // user does not exist
            console.log("user does not exist");
            console.log("create user but don't send invite");
            await inviteUser(x);
            return x.email;
          } else {
            const subAttribute = JSON.parse(userAttributes).find(
              (attr) => attr.Name === "sub"
            );

            const subId = subAttribute ? subAttribute.Value : null;
            x.id = subId;
            await createCompanyUser(x);
            return x.email;
          }
        }
      });

      // Wait for all invitePromises to resolve
      await Promise.all(invitePromises);

      // Then get the updated contacts
      if (getContacts) {
        await getContacts();
      }

      setalertMessage(`Successfully added to contacts!`);
      setShowToast(true);

      let emailList = [...contactEmailList, email];
      setContactEmailList(emailList);
      

      if (fromComposeModal) {
        let person = {};
        InputData.map((x) => {
          setContactEmailList((old) => [
            ...old,
            {
              name: `${x.firstName} ${x.lastName}`,
              emailAddress: `${x.email}`,
            },
          ]);
        });
      }
    } catch (e) {
      // Added a toast notification for error
      if (setAreContactsAdded) {
        setAreContactsAdded(false);
      }

      setalertMessage(`Error adding new contact(s)`);
      setShowToast(true);
      if (seterrortoast) {
        seterrortoast(true);
      }

      console.error("Error adding new contact(s)", e);
    }
  };

  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    function handleZoomChange() {
      const newZoomLevel = Math.round(window.devicePixelRatio * 100) / 100;
      setZoomLevel(newZoomLevel);
    }

    window.addEventListener("resize", handleZoomChange);
    handleZoomChange();

    return () => {
      window.removeEventListener("resize", handleZoomChange);
    };
  }, []);

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-101 outline-none focus:outline-none bg-black bg-opacity-60"
      >
        <div
          ref={modalContent}
          className={
            "p-10 flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen overflow-y-auto relative"
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-row">
            <div className="font-semibold text-gray-900 text-lg pb-2">
              Add Contact
            </div>
            <button
              onClick={() => {
                close();
              }}
              className="h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex justify-center items-center active:bg-gray-300 hover:bg-gray-300 absolute top-5 right-5"
            >
              <CgClose />
            </button>
          </div>
          <div className="text-gray-900 pb-2">
            Contacts with access to the portal will automatically receive
            invitation via email.
          </div>
          {InputData.map((x, i) => (
            <div className="py-4 flex flex-col">
              <div
                className={
                  "flex " +
                  (isMobileDevice(width) ? "w-full flex-col" : "flex-row")
                }
              >
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`First Name`}
                  </div>
                  <input
                    name={`firstName`}
                    type="text"
                    value={x.firstName}
                    className={
                      "rounded-md p-2 border border-gray-300 outline-0 " +
                      (isMobileDevice(width) ? "w-full" : "w-56")
                    }
                    onChange={(e) => handleOnChange(e, i, `firstName`)}
                  />
                </div>
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Last Name`}
                  </div>
                  <input
                    name={`lastName`}
                    type="text"
                    value={x.lastName}
                    className={
                      "rounded-md p-2 border border-gray-300 outline-0 " +
                      (isMobileDevice(width) ? "w-full" : "w-56")
                    }
                    onChange={(e) => handleOnChange(e, i, `lastName`)}
                  />
                </div>
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Email`}
                  </div>
                  <input
                    name={`email`}
                    type="text"
                    value={x.email}
                    className={
                      "rounded-md p-2 border border-gray-300 outline-0 " +
                      (isMobileDevice(width) ? "w-full" : "w-56")
                    }
                    onChange={(e) => handleOnChange(e, i, `email`)}
                  />
                  {invalidEmailMessage ? (
                    <div className="pt-1">
                      <small className="text-red-400">
                        {invalidEmailMessage}
                      </small>
                    </div>
                  ) : null}
                </div>
                {/* <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Team`}
                  </div>
                  <Select
                    name={`team`}
                    options={userTypeOptions}
                    type="text"
                    value={{
                      value: x.team,
                      label: x.team,
                    }}
                    onChange={(e, val) => handleSelectChange(e, val, i, `team`)}
                    className="rounded-md w-56 focus:border-gray-100 text-gray-400"
                  />
                </div> */}
              </div>
              <div
                className={
                  "flex " +
                  (isMobileDevice(width) ? "w-full flex-col" : "flex-row")
                }
              >
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`User Type`}
                  </div>
                  <Select
                    name={`userType`}
                    menuPortalTarget={document.body}
                    styles={{
                      container: (base) => ({
                        ...base,
                        zIndex: "99999",
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      // menu: (base, state) => ({
                      //   ...base,
                      //   maxHeight: zoomLevel >= 1.5 ? "12rem" : null,
                      //   overflowY:
                      //     state.selectProps.menuIsOpen && zoomLevel >= 1.5
                      //       ? "auto"
                      //       : null,
                      // }),
                    }}
                    options={userTypeOptions}
                    type="text"
                    value={{
                      value: x.userType,
                      label: x.userType,
                    }}
                    onChange={(e, val) =>
                      handleSelectChange(e, val, i, `userType`)
                    }
                    className={
                      "rounded-md focus:border-gray-100 text-gray-400 " +
                      (isMobileDevice(width) ? "w-full" : "w-56")
                    }
                  />
                </div>
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Business`}
                  </div>
                  <input
                    name={`businessName`}
                    type="text"
                    // value={localStorage.getItem("company")}
                    className={
                      "rounded-md p-2 border border-gray-300 outline-0 " +
                      (isMobileDevice(width) ? "w-full" : "w-56")
                    }
                    disabled={false}
                    onChange={(e) => handleOnChange(e, i, `businessName`)}
                  />
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col p-1">
                    <div className="text-sm font-medium text-gray-400 mb-2">
                      {`Allow Access to Portal`}
                    </div>
                    <div className="flex">
                      <Switch
                        checked={x.hasPortalAccess}
                        onChange={(e) => {
                          handleOnChangeSwitch(e, i, `hasPortalAccess`);
                        }}
                        className={`${
                          x.hasPortalAccess
                            ? "cursor-pointer bg-green-500"
                            : "bg-gray-300"
                        } relative inline-flex h-5 w-8 items-center rounded-full`}
                      >
                        <span
                          className={`${
                            x.hasPortalAccess
                              ? "translate-x-4"
                              : "translate-x-1"
                          } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                      <div className="pl-2">
                        {x.hasPortalAccess ? "Has access" : "No access"}
                      </div>
                    </div>
                  </div>
                  {isMobileDevice(width) ? (
                    <div className="flex flex-col">
                      <div className="opacity-0">a</div>
                      {InputData.length > 1 && (
                        <CgTrash
                          className="border border-gray-200 text-4xl p-2 cursor-pointer hover:bg-gray-100"
                          color={`lightcoral`}
                          onClick={() => handleDelete(i)}
                        />
                      )}
                    </div>
                  ) : null}
                </div>

                {/* <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Client Name`}
                  </div>
                  <Select
                    name={`clientname`}
                    options={userTypeOptions}
                    type="text"
                    value={{
                      value: x.clientname,
                      label: x.clientname,
                    }}
                    onChange={(e, val) =>
                      handleSelectChange(e, val, i, `clientname`)
                    }
                    className="rounded-md w-56 focus:border-gray-100 text-gray-400"
                  />
                </div> */}
                {/* <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Matter Name`}
                  </div>
                  <Select
                    name={`mattername`}
                    options={userTypeOptions}
                    type="text"
                    value={{
                      value: x.mattername,
                      label: x.mattername,
                    }}
                    onChange={(e, val) =>
                      handleSelectChange(e, val, i, `mattername`)
                    }
                    className="rounded-md w-56 focus:border-gray-100 text-gray-400"
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Client`}
                  </div>
                  <CreatableSelect
                    // options={clientsOptions}
                    isClearable
                    isSearchable
                    // onChange={handleClientChanged}
                    // value={selectedClient}
                    placeholder="Client"
                    className="w-56 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                  />
                </div>
*/}
                {/*
              <div className="flex flex-col p-1">
                  <div className="text-sm font-medium text-gray-400">
                    {`Matter`}
                  </div>
                    <CreatableSelect
                              // options={clientsOptions}
                      isClearable
                      isSearchable
                              // onChange={handleClientChanged}
                              // value={selectedClient}
                      placeholder="Enter Matter"
                      className=" w-56 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                    />
              </div>
*/}
              </div>

              <div
                className="flex flex-col p-1"
                style={{
                  display: isMobileDevice(width) ? "none" : "block",
                }}
              >
                {InputData.length > 1 && (
                  <CgTrash
                    className="border border-gray-200 text-4xl p-2 cursor-pointer hover:bg-gray-100"
                    color={`lightcoral`}
                    onClick={() => handleDelete(i)}
                  />
                )}
              </div>
            </div>
          ))}

          <button
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => {
              setisDisabled(true);
              setInputData([
                ...InputData,
                {
                  firstName: "",
                  lastName: "",
                  company: {
                    id: localStorage.getItem("companyId"),
                    name: localStorage.getItem("company"),
                  },
                  email: "",
                  usertype: "",
                  hasPortalAccess: false,
                },
              ]);
            }}
            className={
              "m-2 my-3 font-medium gap-1 mr-auto flex flex-row justify-center items-center text-md text-cyan-500 hover:text-cyan-300 cursor-pointer"
            }
          >
            Add More
            <RiAddCircleFill />
          </button>

          <div className="mr-auto ml-auto">
            <Button
              variant="secondary"
              size="medium"
              disabled={isDisabled}
              onClick={() => {
                //generateFinal();
                if (fromComposeModal) {
                  localStorage.setItem("addEmail", "");
                }
                if (setAreContactsAdded) {
                  setAreContactsAdded(true);
                }

                handleSubmit();
                close();
              }}
              className="p-2 px-4 gap-1 ml-auto"
            >
              Add Contact
              <MdAdd className="h-6 w-6" />
            </Button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
