import React, { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import { useHistory, useLocation, useParams } from "react-router-dom";

import webIllustration from "../../assets/images/sign-up-page-revamp/web-illustration.svg";
import dottedBg from "../../assets/images/sign-up-page-revamp/dotted-bg.svg";
import { FiMail } from "react-icons/fi";
import "../../assets/styles/Auth.css";

import { AppRoutes } from "../../constants/AppRoutes";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";

import SignIn from "./sign-in";
import SignUp from "./sign-up";
import ConfirmSignUp from "./confirm-sign-up";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import Otp from "./otp";

export default function Authentication() {
  const AuthState = {
    SignUp: "signup",
    SignOut: "signout",
    SignIn: "signin",
    Loading: "loading",
    SignedOut: "signedout",
    SignedIn: "signedin",
    SigningUp: "signingup",
    ConfirmSignUp: "confirmSignUp",
    confirmingSignUpCustomFlow: "confirmsignupcustomflow",
    ConfirmSignIn: "confirmSignIn",
    confirmingSignInCustomFlow: "confirmingsignincustomflow",
    VerifyingAttributes: "verifyingattributes",
    ForgotPassword: "forgotpassword",
    ResetPassword: "resettingpassword",
    SettingMFA: "settingMFA",
    TOTPSetup: "TOTPSetup",
    CustomConfirmSignIn: "customConfirmSignIn",
    VerifyContact: "verifyContact",
  };

  const [nextAuthState, setNextAuthState] = useState(
    localStorage?.getItem("authState") || AuthState.SignIn
  );
  const [authState, setAuthState] = useState(
    localStorage?.getItem("authState") || AuthState.SignIn
  );
  const [prevAuthState, setPrevAuthState] = useState(
    localStorage?.getItem("authState") || undefined
  );
  const [user, setUser] = useState();
  const { width } = useWindowDimensions();
  const bgRef = useRef();

  // For Progress Notification
  const [notifMessage, setNotifMessage] = useState("");
  const [notifShow, setNotifShow] = useState(false);
  const [notifVariant, setNotifVariant] = useState("default");

  const [loading, setLoading] = useState(false);
  const [authSession, setAuthSession] = useState();

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const srcCategory = params.get('src');
  const srcArticle = params.get('article');
  const srcWebsite = params.get('website');

  let src = srcCategory;
  let article = srcArticle;
  let website = srcWebsite;

  let queryStringVariables = `?src=${encodeURIComponent(src)}&article=${encodeURIComponent(article)}&website=${encodeURIComponent(website)}`;

  async function getCurrentSession() {
    try {
      const session = await Auth.currentSession();
      setAuthSession(session);
      setNextAuthState(AuthState.SignedIn);
    } catch (error) {
      if (error === "No current user") {
        console.log("No current user logged in.");
      } else {
        console.error("Error retrieving session:", error);
      }
    }
  }

  useEffect(() => {
    localStorage.removeItem("authState");
    console.log("Initialize Authentication ...");
    console.time("Initial Authentication Duration");

    // console.log("location.pathname", location.pathname);

    if (
      location.pathname === AppRoutes.REGISTRATION_BUDGET ||
      location.pathname === AppRoutes.REGISTRATION_WITHOUT_SLASH ||
      location.pathname === AppRoutes.REGISTRATION_WITH_SLASH
    ) {
      setNextAuthState(AuthState.SignUp);
    } else {
      if (!authSession) {
        getCurrentSession();
      }
    }
  }, [authSession]);

  useEffect(() => {
    if (prevAuthState !== nextAuthState) {
      localStorage.removeItem("SignInError");
      setNotifShow(false);
      setPrevAuthState(nextAuthState);
    }

    if (authState !== nextAuthState) {
      setAuthState(nextAuthState);
    }

    if (prevAuthState === "confirmSignUp" && nextAuthState === "signedin") {
      console.timeEnd("Initial Authentication Duration");
      console.log("End of Initialization.");
      history.push(`${AppRoutes.POSTREGISTRATION}${queryStringVariables}`);
    } else if (nextAuthState === "signedin") {
      console.timeEnd("Initial Authentication Duration");
      console.log("End of Initialization.");
      history.push(AppRoutes.POSTAUTHENTICATION);
    }
  }, [nextAuthState, authState, prevAuthState, history]);

  function clearLocalStorage() {
    console.log("clearLocalStorage");
    localStorage.clear();
  }

  const calculatePicturePosition = () => {
    if (width < 1100) return "initial";
    if (width < 1330) return "-20rem";
    if (width < 1600) return "-10rem";
    return "-2rem";
  };

  return (
    <div className="relative">
      <div className="container mx-auto md:px-16 grid md:grid-cols-2 grid-cols-1">
        <div className="py-32">
          <div className="flex flex-col cursor-default h-full">
            <div className="self-center md:self-auto md:-ml-4 select-none">
              <img
                className="object-scale-down absolute h-32 w-32 left-20 top-12 cursor-pointer"
                src={process.env.REACT_APP_HEADER_LOGO}
                alt=""
                onClick={() => {
                  setNotifShow(false);
                  // setNextAuthState(AuthState.SignIn);
                  window.location.href="/";
                }}
              />
            </div>
            {authState === AuthState.SignIn && (
              <div className="self-center md:self-auto w-4/5 md:max-w-2xl mt-10 md:mt-16">
                <div className="text-center md:text-left font-semibold text-hbBlue text-2xl md:text-5xl">
                  HOME BUILDING APP
                </div>
                <div className="text-center md:text-left mt-1 md:mt-2 font-normal text-base md:text-2xl text-hbGray">
                  Project Management Software for Homeowners, Owner Builders,
                  and Architect Superintendents
                </div>
              </div>
            )}
            <div
              className={`md:mr-4 w-4/5 md:w-full mx-auto flex flex-col items-center md:items-left ${
                authState === AuthState.SignIn ? "my-0" : "my-auto"
              }`}
            >
              {authState === AuthState.SignIn && (
                <SignIn
                  user={user}
                  setUser={setUser}
                  AuthState={AuthState}
                  setNextAuthState={setNextAuthState}
                  notifMessage={notifMessage}
                  notifShow={notifShow}
                  notifVariant={notifVariant}
                  setNotifMessage={setNotifMessage}
                  setNotifShow={setNotifShow}
                  setNotifVariant={setNotifVariant}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
              {authState === AuthState.SignUp && (
                <SignUp
                  user={user}
                  setUser={setUser}
                  AuthState={AuthState}
                  setNextAuthState={setNextAuthState}
                  notifMessage={notifMessage}
                  notifShow={notifShow}
                  notifVariant={notifVariant}
                  setNotifMessage={setNotifMessage}
                  setNotifShow={setNotifShow}
                  setNotifVariant={setNotifVariant}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
              {authState === AuthState.ConfirmSignUp && (
                <ConfirmSignUp
                  user={user}
                  setUser={setUser}
                  AuthState={AuthState}
                  setNextAuthState={setNextAuthState}
                  notifMessage={notifMessage}
                  notifShow={notifShow}
                  notifVariant={notifVariant}
                  setNotifMessage={setNotifMessage}
                  setNotifShow={setNotifShow}
                  setNotifVariant={setNotifVariant}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
              {authState === AuthState.ForgotPassword && (
                <ForgotPassword
                  user={user}
                  setUser={setUser}
                  AuthState={AuthState}
                  setNextAuthState={setNextAuthState}
                  notifMessage={notifMessage}
                  notifShow={notifShow}
                  notifVariant={notifVariant}
                  setNotifMessage={setNotifMessage}
                  setNotifShow={setNotifShow}
                  setNotifVariant={setNotifVariant}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
              {authState === AuthState.ResetPassword && (
                <ResetPassword
                  user={user}
                  setUser={setUser}
                  AuthState={AuthState}
                  setNextAuthState={setNextAuthState}
                  notifMessage={notifMessage}
                  notifShow={notifShow}
                  notifVariant={notifVariant}
                  setNotifMessage={setNotifMessage}
                  setNotifShow={setNotifShow}
                  setNotifVariant={setNotifVariant}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
              {authState === AuthState.confirmingSignInCustomFlow && (
                <Otp
                  user={user}
                  setUser={setUser}
                  AuthState={AuthState}
                  setNextAuthState={setNextAuthState}
                  notifMessage={notifMessage}
                  notifShow={notifShow}
                  notifVariant={notifVariant}
                  setNotifMessage={setNotifMessage}
                  setNotifShow={setNotifShow}
                  setNotifVariant={setNotifVariant}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
            </div>
          </div>
        </div>
        <div className="hidden md:block ml-16 h-screen bg-mysteryGray overflow-x-visible cursor-default">
          <div className="relative top-0 z-0" ref={bgRef}>
            <svg
              viewBox="0 0 900 400"
              height={`${bgRef.current?.offsetHeight}px`}
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              style={{ minHeight: "600px" }}
              className="fixed"
            >
              <path
                d="M0 60L75 100L150 64L225 69L300 96L375 109L450 91L525 73L600 78L675 55L750 105L825 60L900 91L900 0L825 0L750 0L675 0L600 0L525 0L450 0L375 0L300 0L225 0L150 0L75 0L0 0Z"
                fill="#d4d4d4"
              ></path>
              <path
                d="M0 123L75 141L150 109L225 132L300 145L375 163L450 132L525 141L600 127L675 114L750 145L825 96L900 141L900 89L825 58L750 103L675 53L600 76L525 71L450 89L375 107L300 94L225 67L150 62L75 98L0 58Z"
                fill="#dddddd"
              ></path>
              <path
                d="M0 181L75 213L150 190L225 190L300 213L375 213L450 208L525 213L600 204L675 177L750 208L825 177L900 190L900 139L825 94L750 143L675 112L600 125L525 139L450 130L375 161L300 143L225 130L150 107L75 139L0 121Z"
                fill="#e6e6e6"
              ></path>
              <path
                d="M0 316L75 312L150 316L225 294L300 348L375 352L450 330L525 303L600 366L675 298L750 298L825 325L900 307L900 188L825 175L750 206L675 175L600 202L525 211L450 206L375 211L300 211L225 188L150 188L75 211L0 179Z"
                fill="#efefef"
              ></path>
              <path
                d="M0 451L75 451L150 451L225 451L300 451L375 451L450 451L525 451L600 451L675 451L750 451L825 451L900 451L900 305L825 323L750 296L675 296L600 364L525 301L450 328L375 350L300 346L225 292L150 314L75 310L0 314Z"
                fill="#f8f8f8"
              ></path>
            </svg>
          </div>
          <div className="relative h-screen">
            {authState !== AuthState.SignUp ? (
              <svg
                width="719"
                height="433"
                viewBox="0 0 719 433"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fixed bottom-32 slide-in-blurred-bottom-1s left-1/2"
              >
                <g filter="url(#filter0_d_28_63)">
                  <path
                    d="M548.28 420.212C539.179 412.521 533.65 400.372 535.1 388.545C536.55 376.718 545.53 365.845 557.177 363.334C568.825 360.822 582.103 367.774 585.641 379.153C587.588 357.216 589.832 334.36 601.495 315.679C612.055 298.763 630.346 286.66 650.17 284.494C669.993 282.329 690.865 290.507 703.228 306.154C715.591 321.8 718.618 344.547 709.959 362.51C703.581 375.743 691.829 385.591 679.516 393.603C639.729 419.274 592.009 429.714 545.137 423.001L548.28 420.212Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M683.625 290.961C667.268 304.283 652.263 319.184 638.825 335.447C617.796 360.811 600.778 389.248 588.366 419.769C587.457 421.995 591.073 422.965 591.971 420.763C612.28 371.161 644.701 327.441 686.269 293.604C688.135 292.084 685.476 289.453 683.625 290.961Z"
                    fill="white"
                  />
                  <path
                    d="M232.85 418.122C245.35 407.559 252.943 390.872 250.952 374.628C248.961 358.383 236.627 343.45 220.629 340C204.631 336.551 186.394 346.1 181.534 361.728C178.86 331.597 175.778 300.205 159.759 274.546C145.254 251.313 120.132 234.688 92.9041 231.714C65.6766 228.74 37.0096 239.973 20.0288 261.463C3.04799 282.954 -1.10969 314.196 10.7832 338.869C19.5443 357.044 35.6853 370.571 52.597 381.575C106.551 416.683 173.482 431.325 237.167 421.953"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M46.9537 240.596C69.4197 258.895 90.0299 279.36 108.486 301.697C126.945 324.011 143.197 348.063 157.012 373.515C164.734 387.792 171.671 402.48 177.792 417.514C179.04 420.572 174.074 421.903 172.84 418.879C161.998 392.375 148.585 366.997 132.793 343.11C117.078 319.309 99.0909 297.088 79.0848 276.761C67.7693 265.269 55.8304 254.408 43.3223 244.227C40.7585 242.139 44.411 238.525 46.9537 240.596Z"
                    fill="white"
                  />
                  <path
                    d="M514.294 424.087H216.044V118.074C216.044 52.9678 269.012 0 334.118 0H396.22C461.326 0 514.294 52.9678 514.294 118.074V424.087Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M452.193 424.087H216.044V118.074C215.979 93.1271 223.878 68.8102 238.593 48.6643C239.135 47.925 239.676 47.1953 240.234 46.4735C247.352 37.1387 255.821 28.9178 265.364 22.0819C265.903 21.6897 266.444 21.3043 266.994 20.9188C274.979 15.393 283.617 10.8757 292.71 7.4698C293.25 7.26512 293.8 7.06004 294.35 6.86333C302.574 3.92829 311.1 1.91779 319.769 0.868975C320.307 0.795556 320.859 0.738105 321.41 0.680653C329.859 -0.222653 338.379 -0.222653 346.827 0.680653C347.378 0.738105 347.929 0.795549 348.473 0.86976C357.14 1.91865 365.664 3.92889 373.886 6.86337C374.436 7.06008 374.986 7.26515 375.528 7.47023C384.509 10.8332 393.045 15.283 400.945 20.7206C401.494 21.0976 402.044 21.483 402.585 21.8684C407.934 25.6867 412.958 29.9404 417.606 34.5862C421.334 38.3126 424.808 42.2848 428.004 46.476C428.56 47.1953 429.101 47.9251 429.642 48.6632C444.358 68.8093 452.258 93.1266 452.193 118.074V424.087Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M432.99 287.219C440.211 287.219 446.064 281.366 446.064 274.145C446.064 266.925 440.211 261.071 432.99 261.071C425.77 261.071 419.916 266.925 419.916 274.145C419.916 281.366 425.77 287.219 432.99 287.219Z"
                    fill="#184067"
                  />
                  <path
                    d="M489.861 414.922H479.843L475.077 376.282L489.863 376.282L489.861 414.922Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M472.687 412.059H492.007V424.223H460.523C460.523 422.626 460.837 421.044 461.449 419.568C462.06 418.092 462.956 416.751 464.086 415.622C465.215 414.492 466.556 413.596 468.032 412.985C469.508 412.374 471.09 412.059 472.687 412.059Z"
                    fill="#656565"
                  />
                  <path
                    d="M539.705 414.922H529.687L524.921 376.282L539.707 376.282L539.705 414.922Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M522.532 412.059H541.852V424.223H510.368C510.368 422.626 510.682 421.044 511.294 419.568C511.905 418.092 512.801 416.751 513.931 415.622C515.06 414.492 516.401 413.596 517.877 412.985C519.353 412.374 520.935 412.059 522.532 412.059Z"
                    fill="#656565"
                  />
                  <path
                    d="M439.607 276.735C438.886 275.673 438.407 274.466 438.203 273.199C438 271.932 438.076 270.636 438.428 269.402C438.779 268.168 439.397 267.025 440.237 266.056C441.078 265.086 442.121 264.312 443.293 263.789L477.347 169.929L494.104 179.074L455.221 268.77C455.969 270.797 455.948 273.028 455.161 275.04C454.374 277.053 452.877 278.706 450.952 279.688C449.027 280.67 446.809 280.911 444.718 280.367C442.628 279.823 440.809 278.531 439.607 276.735Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M534.589 294.372C533.476 293.734 532.516 292.859 531.777 291.81C531.038 290.76 530.539 289.562 530.313 288.298C530.088 287.035 530.142 285.737 530.473 284.497C530.803 283.257 531.401 282.105 532.225 281.121L521.675 181.833L540.745 182.704L545.129 280.367C546.691 281.861 547.65 283.875 547.825 286.029C548 288.182 547.379 290.325 546.08 292.051C544.78 293.777 542.893 294.967 540.775 295.395C538.657 295.823 536.456 295.459 534.589 294.372Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M504.167 152.544C515.251 152.544 524.236 143.558 524.236 132.474C524.236 121.39 515.251 112.405 504.167 112.405C493.083 112.405 484.097 121.39 484.097 132.474C484.097 143.558 493.083 152.544 504.167 152.544Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M534.46 271.791H473.955L474.028 271.32C474.136 270.616 484.811 200.688 476.938 177.811C476.148 175.563 476.217 173.102 477.133 170.902C478.049 168.702 479.746 166.919 481.897 165.895C493.151 160.596 514.752 154.07 532.984 169.906C535.667 172.275 537.762 175.235 539.104 178.553C540.445 181.871 540.996 185.456 540.714 189.024L534.46 271.791Z"
                    fill="#184067"
                  />
                  <path
                    d="M485.576 211.023L458.336 205.496L471.104 175.238C471.868 172.308 473.764 169.8 476.374 168.265C478.985 166.73 482.098 166.293 485.031 167.05C487.963 167.807 490.476 169.696 492.017 172.303C493.559 174.91 494.003 178.022 493.253 180.956L485.576 211.023Z"
                    fill="#184067"
                  />
                  <path
                    d="M520.464 221.284L518.826 183.744C517.584 176.687 521.624 170.016 527.837 168.926C534.052 167.839 540.12 172.734 541.367 179.842L547.523 214.92L520.464 221.284Z"
                    fill="#184067"
                  />
                  <path
                    d="M532.447 267.296C542.183 304.375 543.245 351.517 540.618 402.939L527.544 401.305L503.848 303.25L490.774 402.939L476.066 402.122C471.672 348.167 467.393 301.848 474.431 271.382L532.447 267.296Z"
                    fill="#656565"
                  />
                  <path
                    d="M517.39 147.984C513.646 151.973 506.693 149.832 506.205 144.383C506.167 143.96 506.17 143.535 506.213 143.112C506.465 140.699 507.86 138.508 507.526 135.959C507.45 135.325 507.214 134.721 506.839 134.203C503.856 130.208 496.852 135.99 494.036 132.374C492.31 130.156 494.339 126.664 493.014 124.186C491.266 120.914 486.086 122.528 482.838 120.736C479.224 118.743 479.44 113.198 481.819 109.826C484.72 105.713 489.807 103.518 494.83 103.201C499.854 102.885 504.843 104.243 509.532 106.071C514.861 108.147 520.145 111.017 523.424 115.703C527.412 121.401 527.795 129.061 525.801 135.724C524.588 139.777 520.448 144.728 517.39 147.984Z"
                    fill="#656565"
                  />
                  <path
                    d="M690.369 424.613H87.0917C86.8341 424.612 86.5874 424.51 86.4055 424.327C86.2237 424.145 86.1216 423.898 86.1216 423.64C86.1216 423.383 86.2237 423.136 86.4055 422.953C86.5874 422.771 86.8341 422.668 87.0917 422.667H690.369C690.627 422.668 690.874 422.771 691.056 422.953C691.237 423.136 691.34 423.383 691.34 423.64C691.34 423.898 691.237 424.145 691.056 424.327C690.874 424.51 690.627 424.612 690.369 424.613Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M387.232 335.429H281.005C279.606 335.437 278.262 334.889 277.266 333.907C276.27 332.924 275.705 331.586 275.694 330.187V93.0829C275.705 91.6839 276.27 90.3464 277.266 89.3636C278.262 88.3809 279.606 87.8333 281.005 87.8408H387.232C388.631 87.8333 389.975 88.3809 390.971 89.3636C391.967 90.3464 392.532 91.6839 392.543 93.0829V330.187C392.532 331.586 391.967 332.924 390.971 333.907C389.975 334.889 388.631 335.437 387.232 335.429Z"
                    fill="white"
                  />
                  <path
                    d="M392.543 164.56H275.694V166.195H392.543V164.56Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M392.951 257.803H276.103V259.437H392.951V257.803Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M314.099 87.8409H312.465V336.247H314.099V87.8409Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M354.955 87.8409H353.32V336.247H354.955V87.8409Z"
                    fill="#CCCCCC"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_28_63"
                    x="0"
                    y="0"
                    width="718.898"
                    height="432.986"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_28_63"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_28_63"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            ) : (
              <>
                <img className="fixed bottom-0" src={dottedBg} alt="dottedBg" />
                <img
                  className="shadow-2xl fixed z-10 overflow-hidden bottom-28 border-l-8 border-t-8 border-b-8 border-gray-900 rounded-2xl slide-in-blurred-right-1s"
                  style={{
                    right: calculatePicturePosition(),
                    marginLeft: width < 1100 ? "2rem" : "",
                  }}
                  src={webIllustration}
                  alt="illustration"
                />
                <img
                  className="fixed top-0 right-0 opacity-40"
                  src={dottedBg}
                  alt=""
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="cursor-pointer absolute bottom-10 left-10 hidden w-10/12 md:w-1/2">
        <p>© Make My Affidavit</p>
        <span className="ml-auto md:mr-10 flex flex-row gap-2 items-center">
          <FiMail />
          <p>help@mma.com</p>
        </span>
      </div>
    </div>
  );
}
