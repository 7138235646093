import React, { useState, useRef, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { Storage, API } from "aws-amplify";
import { BiCloudUpload } from "react-icons/bi";
import { AiOutlineLoading, AiOutlineCheck } from "react-icons/ai";
import { handleTextractSubscription, chunkLargePdfsForTextract } from "../../shared/utils/textExtractionUtils";

const BackgroundPopupFileUpload = ({
  matterId,
  data,
  // handleSaveEditsDirectly,
  setTempMemories,
  setContextualLibrary,
  handleToastNotification,
  setLoading,
  rowId,
  setUploadedIdList,
  uploadedIdList,
}) => {
  const rejectFiles = [
    "xlsx",
    "xls",
    "docx",
    "doc",
    ".config",
    ".exe",
    ".7z",
    ".dll",
    ".exe1",
    ".zvz",
  ];
  const hiddenFileInput = useRef(null);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [uploadStart, setUploadStart] = useState(false);
  const [filesReadyForUpload, setFilesReadyForUpload] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [columnIndex, setColumnIndex] = useState(0);
  const [uploadBtnClicked2, setUploadBtnClicked2] = useState(false);
  const [openFilesPopup, setOpenFilesPopup] = useState(false);
  const [selectedRowId, setSelectedRowID] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [dropTrigger, setDropTrigger] = useState([]);
  const [directDrop, setDirectDrop] = useState([]);
  const [percent, setPercent] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [extractingFile, setExtractingFile] = useState(false);
  const [progressCircleValue, setProgressCircleValue] = useState(0);
  const [progressContent, setProgressContent] = useState(
    "Uploading and extracting content..."
  );

  const myCurrentRef = useRef(selectedFiles);
  const subscriptionRef = useRef(null);

  //   useEffect(() => {
  //     getFilesUploaded();
  //   }, []);

  const updateSelectedFiles = (data) => {
    myCurrentRef.current = data;
    setSelectedFiles(data);
  };

  //   const mCreateActivity = `
  //   mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  //     activityCreate(
  //       activity: $activity
  //       briefId: $briefId
  //       clientMatterId: $clientMatterId
  //       companyId: $companyId
  //       previous: $previous
  //       field: $field
  //       current: $current
  //       appModule: $appModule
  //       rowId: $rowId
  //     ) {
  //       id
  //     }
  //   }`;

  //append in existing
  const qlistBackgroundFiles = `
  query getBackgroundByID($id: ID) {
    background(id: $id) {
      id
      date
      files {
        items {
          createdAt
          id
          details
          name
          gmailMessageId
          s3ObjectKey
          order
          size
        }
      }
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

  const BACKGROUND_BY_BRIEF_ID_QUERY_EXTRACT = `
      query getBackgroundFile($backgroundId: ID, $fileId: ID) {
        backgroundFile(fileId: $fileId, backgroundId: $backgroundId) {
          extractedFileContent
        }
      }
    `;

  const updatePromptFile = `
    mutation updatePrompt($companyId: ID!, $id: ID!, $description: String, $fileId: ID, $extractedFileContent: String) {
      openAIPromptsUpdate(
        companyId: $companyId
        id: $id
        description: $description
        fileId: $fileId
        extractedFileContent: $extractedFileContent
      ) {
        id
      }
    }
  `;

  async function handleSaveFile(editedPrompt) {
    setLoading(true);

    let params = {
      companyId: localStorage.getItem("companyId"),
      id: editedPrompt.id,
      fileId: editedPrompt.fileId,
      // extractedFileContent: editedPrompt.extractedFileContent,
      description: editedPrompt.extractedFileContent,
    };

    console.log("Saving ", params);

    const res = await API.graphql({
      query: updatePromptFile,
      variables: params,
    });

    console.log("Response", res.data.openAIPromptsUpdate);

    setTempMemories((prevPrompts) => {
      let res = prevPrompts.map((prompt) =>
        prompt.id === editedPrompt.id ? editedPrompt : prompt
      );

      setContextualLibrary(res);
      return res;
    });

    handleToastNotification("Successfully edited core memory", false);
    setLoading(false);
  }

  useEffect(() => {
    var counter = 0;
    var dCounter = 0;
    if (dropTrigger?.length > 0 && counter === 0) {
      onSelectFile(dropTrigger);
      counter = 1;
    }

    if (directDrop?.length > 0 && dCounter === 0) {
      onSelectFile(directDrop);
      dCounter = 1;
    }
  }, [dropTrigger, directDrop]);

  const onSelectFile = (retrievedFiles) => {
    setIsUploading(true);
    if (!retrievedFiles || retrievedFiles.length === 0) {
      return;
    }
    const tempArr = [];

    [...retrievedFiles].forEach((file) => {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];
      const fileType = file.type;

      const validFileTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];

      if (!validFileTypes.includes(fileType)) {
        setInvalidFiles((prev) => [
          ...prev,
          {
            data: file,
            url: URL.createObjectURL(file),
          },
        ]);
      } else {
        tempArr.push({
          data: file,
          url: URL.createObjectURL(file),
        });
      }
    });

    updateSelectedFiles([...myCurrentRef.current, ...tempArr]);
    setFilesReadyForUpload(true);
  };

  const handleUpload = async (bucketName) => {
    setUploadStart(true);

    var tempArr = [...selectedFiles];
    updateSelectedFiles(tempArr);

    let currDate = Number(new Date());

    var idxx = 0;
    tempArr.map(async (uf, index) => {
      const isDocx = uf.data.name.split(".").pop() === "docx";
      const name = uf.data.name;
      const size = uf.data.size;
      const type = isDocx
        ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        : uf.data.type;
      const key = `${bucketName}/${currDate}${name
        ?.replaceAll(/\s/g, "")
        .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`;
      const orderSelected = idxx;
      const order = idxx;
      idxx++;

      let pageCount;
      if (type === "application/pdf") {
        // Extract page count for PDF files
        const pdfBytes = await uf.data.arrayBuffer();
        pageCount = await chunkLargePdfsForTextract({pdfBytes, bucketName, name, currDate, type});
      }

      try {
        await Storage.put(key, uf.data, {
          contentType: type,
          ...(pageCount && {
            metadata: {
              "x-amz-meta-page-count": pageCount.toString(),
            },
          }),
          progressCallback(progress) {
            const progressInPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            setPercent((prev) => {
              const newTemp = [...prev];
              const index = newTemp.findIndex(
                (item) => item.name === uf.data.name
              );
              if (index !== -1) {
                newTemp[index].prog = progressInPercentage;
              } else {
                newTemp.push({
                  prog: progressInPercentage,
                  name: uf.data.name,
                });
              }
              return newTemp;
            });
          },
          errorCallback: (err) => {
            console.error("204: Unexpected error while uploading", err);
          },
        })
          .then((fd) => {
            var fileData = {
              s3ObjectKey: fd.key,
              size: parseInt(size),
              type: type,
              name: name.split(".").slice(0, -1).join("."),
              oderSelected: orderSelected,
              order: orderSelected,
            };

            setUploadedFiles((prevState) => ({
              files: [...prevState.files, fileData],
            }));
            setDirectDrop([]);
          })
          .catch((err) => {
            console.error("220: Unexpected error while uploading", err);
          });
      } catch (e) {
        const response = {
          error: e.message,
          errorStack: e.stack,
          statusCode: 500,
        };
        console.error("228: Unexpected error while uploading", response);
      }
    });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const clickPopupUploadButton = () => {
    handleUpload(matterId);
    // setSelectedRowID(item.id);
    setSelectedIndex(columnIndex);
  };

  useEffect(() => {
    const uploadAndCheck = async () => {
      console.log("uploadedFiles>>", uploadedFiles);
      if (
        uploadedFiles.files.length === selectedFiles?.length &&
        selectedFiles?.length !== 0
      ) {
        const result = await handleUploadLink(uploadedFiles);
        if (result === "done") {
          setUploadedFiles({ files: [] });
          updateSelectedFiles([]);
          hiddenFileInput.current && (hiddenFileInput.current.value = "");
          setOpenFilesPopup(false);
          setUploadBtnClicked2(false);
        }
      }
    };

    uploadAndCheck();
  }, [selectedFiles, uploadedFiles]);

  useEffect(() => {
    if (filesReadyForUpload) {
      clickPopupUploadButton();
      setFilesReadyForUpload(false);
    }
  }, [filesReadyForUpload]);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const { files } = e.dataTransfer;

    if (files?.length > 1) {
      alert("You can only select 1 file per row");
    } else {
      if (files && files.length) {
        onSelectFile(files);
      }
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  //UPLOAD FILES IN FILEBUCKET FROM BACKGROUND
  const handleUploadLink = async (uf) => {
    try {
      const uploadedFiles = uf.files.map((f) => ({ ...f, matterId }));
      console.log("uploadedFiles", uploadedFiles);

      const sortedFiles = uploadedFiles?.sort(
        (a, b) => b.orderSelected - a.orderSelected
      );
      const addOrder = sortedFiles.map((x) => ({ ...x, order: 0 }));

      const file = await bulkCreateMatterFile(addOrder);

      setExtractingFile(true);
      setProgressCircleValue(0);

      const transformedFile = {
        id: file.id,
        s3ObjectKey: `public/${file.s3ObjectKey}`,
        s3Bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
      };

      // Text extraction
      setProgressCircleValue(20);
      let extractedText;
      const extractionTimeout = 599940; // 3 minutes timeout
      const startTime = Date.now();

      const updateExtractionProgress = () => {
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(
          60,
          20 + (elapsedTime / extractionTimeout) * 80
        );
        setProgressCircleValue(Math.round(progress));
        // console.log("Extraction progress:", progress);
      };

      const extractionInterval = setInterval(updateExtractionProgress, 1000);

      try {
        extractedText = await Promise.race([
          processExtraction([{ files: [transformedFile] }]),
          new Promise((_, reject) =>
            setTimeout(
              () => reject(new Error("Extraction timed out")),
              extractionTimeout
            )
          ),
        ]);
      } catch (extractionError) {
        console.error("Extraction error or timeout:", extractionError);
        throw extractionError;
      } finally {
        clearInterval(extractionInterval);
      }

      // setProgressCircleValue(60);

      // setProgressContent("Extraction completed. Summarzing content...");

      let updatedMemory = {
        ...data,
        extractedFileContent: extractedText,
        description: extractedText,
        fileId: file.id,
      };

      // await measureTime('Saving extracted content', async () => {
      //   await handleSaveEditsDirectly(updatedMemory, "extractedFileContent");
      // });

      setProgressCircleValue(80);
      // Summarization
      // const { message } = await measureTime('Summarization', async () => {
      //   return await handleSummarizeFile(extractedText);
      // });

      // updatedMemory = { ...updatedMemory, description: extractedText };

      // await measureTime('Saving summary', async () => {
      //   await handleSaveEditsDirectly(updatedMemory, "description");
      // });

      // Final save
      // updatedMemory = { ...updatedMemory, fileId: file.id };

      await measureTime("Final save", async () => {
        await handleSaveFile(updatedMemory);
      });

      // All operations are complete, now update the states
      setProgressCircleValue(100);
      setProgressContent(
        "Successfully completed extraction and summarization!"
      );

      setTimeout(() => {
        setExtractingFile(false);
        setIsUploading(false);
        setProgressContent("Uploading and extracting content...");
      }, 2000);

      return "done";
    } catch (error) {
      console.error("Error in handleUploadLink:", error);
      setExtractingFile(false);
      setIsUploading(false);
      setProgressCircleValue(0);
      // Handle error (e.g., show error message to user)
      throw error;
    }
  };

  const measureTime = async (operation, callback) => {
    const start = performance.now();
    const result = await callback();
    const end = performance.now();
    console.log(`${operation} took ${(end - start).toFixed(2)} milliseconds`);
    return result;
  };

  async function processExtraction(checkBulkExtract) {
    if (!checkBulkExtract || checkBulkExtract.length === 0) {
      console.error("Invalid input: checkBulkExtract is null or empty");
      return;
    }

    const BULK_BACKGROUND_TEXTRACT_MUTATION = `
      mutation bulkBackgroundFileTextract($input: [BackgroundFileTextractInput]!) {
        bulkBackgroundFileTextract(input: $input)
      }`;

    const ON_TEXTRACT_PUBLISHED = `
      subscription onBackgroundFileTextractPublished($executionArn: String!) {
        onBackgroundFileTextractPublished(executionArn: $executionArn) {
          executionArn
          fileId
          extractedFileContent
          nextIndex
        }
      }
    `;

    const params = {
      query: BULK_BACKGROUND_TEXTRACT_MUTATION,
      variables: {
        input: checkBulkExtract,
      },
    };

    console.log("Params for GraphQL mutation:", params);
    let response = null;
    try {
      const { data } = await API.graphql(params);

      if (data) {
        // console.log("Preparing to extract text files", data);
        const parsed = JSON.parse(data.bulkBackgroundFileTextract);
        // console.log("backgroundFileTextract", parsed);
        const body = JSON.parse(parsed.body);
        // console.log("body", body);

        const executionArn = body.executionArn;
        console.log("executionArn", executionArn);

        let result = await handleTextractSubscription(executionArn);

        setProgressCircleValue(80);
        // setExtractingFile(false);
        // setIsUploading(false);
        // getFilesUploaded();

        response = result[0].extractedFileContent;

        // response = await new Promise(async (resolve, reject) => {
        //   const subscription = await API.graphql({
        //     query: ON_TEXTRACT_PUBLISHED,
        //     variables: { executionArn },
        //   }).subscribe({
        //     next: async ({ value }) => {
        //       const result = value?.data?.onBackgroundFileTextractPublished;
        //       console.log("Received text extraction response", result);
  
        //       if (
        //         resultArray.length === 0 &&
        //         result.nextIndex === -1 &&
        //         result.fileId === "[ERROR IN TEXTRACTION]"
        //       ) {                console.log("Error in text extraction");
        //         setExtractingFile(false);
        //         //   setIsSaving(false);
        //         reject(new Error("Error in text extraction"));
        //       } else {
        //         resultArray.push(result);

        //         if (result.nextIndex === -1) {
        //           console.log("Text extraction complete", resultArray);

        //           let combinedArray =
        //             combineTextractSubscriptionResponse(resultArray);
  
        //           // console.log("Text extraction complete", result);
        //           setProgressCircleValue(80);
        //           // setExtractingFile(false);
        //           // setIsUploading(false);
        //           // getFilesUploaded();

        //           if (subscription) {
        //             subscription.unsubscribe();
        //           }

        //           resolve(combinedArray[0].extractedFileContent);
        //         }
        //       }
        //     },
        //     error: (error) => {
        //       console.warn(error);
        //       reject(error);
        //     },
        //   });
        // });
      }
    } catch (error) {
      console.error("Error processing extraction", error);
      setExtractingFile(false);
    }

    return response;
  }

  const handleSummarizeFile = async (text) => {
    let message = "";
    let aiApiResultId = "";
    let aiApiTokenUsage = [];

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 3000;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (text !== "") {
      // Split the email into sentences
      const sentences = text?.split(/[.!?]/);

      // Filter and limit the sentences
      const filteredSentences = sentences?.filter(
        (sentence) => sentence?.trim() !== ""
      ); // Remove empty sentences

      // Join the filtered sentences to create the limited email content
      const limitedEmailContent = filteredSentences?.join(". ");

      const instruction = `Read the content of the document. Summarise it without losing key information. Note that it would be used as a contextual summary: \n"${limitedEmailContent}"`;
      console.log("Instruction:", instruction);

      try {
        const startTime = performance.now();

        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        const endTime = performance.now();
        const responseTime = endTime - startTime;

        console.log(
          `API response time: ${responseTime.toFixed(2)} milliseconds`
        );

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const data = await response.json();
        const choices = data.choices;

        aiApiResultId = data.id;
        aiApiTokenUsage = data.usage;

        message = choices[0]?.message?.content
          ?.trim()
          .replace(/(\n)+$/, "")
          .replace(/\s+/g, " ");
      } catch (error) {
        console.error("Error", error);
        return { error };
      }
    }

    return { message, aiApiResultId, aiApiTokenUsage };
  };

  // const getBulkExtractedTextContent = async (backgroundId, fileId) => {
  //   try {
  //     const {
  //       data: {
  //         backgroundFile: { extractedFileContent = "" },
  //       },
  //     } = await API.graphql({
  //       query: BACKGROUND_BY_BRIEF_ID_QUERY_EXTRACT,
  //       variables: {
  //         backgroundId: backgroundId,
  //         fileId: fileId,
  //       },
  //     });

  //     console.log("extracted file content >>> ", fileId);

  //     if (extractedFileContent) {
  //       return extractedFileContent;
  //     } else {
  //       console.log("No extracted content found.");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching extracted file contents:", error);
  //     return null;
  //   }
  // };

  const mBulkCreateMatterFile = `
    mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
      matterFileBulkCreate(files: $files) {
        id
        name
        order
        s3ObjectKey
      }
    }
  `;

  async function bulkCreateMatterFile(param) {
    console.log("bulkCreateMatterFile", param);
    var idTag = [];

    param.forEach(function (i) {
      delete i.oderSelected; // remove orderSelected
    });

    //upload in file bucket
    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    console.log("Done Uploading In File Bucket >>", request);

    //KJMF WILL COMMENT THIS IN THE FUTURE (When extracting is done)
    // setIsUploading(false);

    let result = request.data.matterFileBulkCreate;
    if (result !== null) {
      result.map((i) => {
        return (idTag = [...idTag, { id: i.id }]);
      });

      return result[0];
    }
  }

  //KJMF temporary var
  const [isUploading, setIsUploading] = useState(false);

  return (
    <>
      {isUploading === true ? (
        <div className="mt-2 max-h-40 w-48 overflow-y-scroll">
          {extractingFile === false && (
            <>
              {selectedFiles?.map((file, index) => (
                <div key={index} className="flex justify-between items-center">
                  <p className="pr-4 w-full truncate">{file.data.name}</p>
                  <CircularProgressbar
                    value={percent[index] ? parseInt(percent[index].prog) : 0}
                    text={
                      percent[index]
                        ? `${parseInt(percent[index].prog)}%`
                        : "0%"
                    }
                    className="w-7 h-7"
                  />
                </div>
              ))}
            </>
          )}

          {
            // selectedFiles.length === 0 &&
            extractingFile ? (
              <>
                <div className="flex items-center px-2 mr-1">
                  <CircularProgressbar
                    value={progressCircleValue}
                    text={`${progressCircleValue}%`}
                    className="w-8 h-8"
                  />
                  <span className="ml-2 text-sm font-normal">
                    {progressContent}
                  </span>
                </div>
              </>
            ) : null
          }
        </div>
      ) : (
        <>
          <input
            onChange={(e) =>
              e.target.files.length > 1
                ? alert("You can only select 1 file per row.")
                : onSelectFile(e.target.files)
            }
            ref={hiddenFileInput}
            type="file"
            multiple
            accept="application/pdf, image/png, image/jpeg, image/gif"
            hidden
            id="fileInput"
          />

          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className={`mr-2 inline-flex flex-wrap w-48 px-2 justify-center items-center mx-auto border border-dashed rounded-md transition-colors duration-300 ease-in-out ${
              isDragging ? "bg-gray-200" : "border-cyan-600"
            } hover:bg-gray-100`}
            onClick={
              isUploading === false
                ? handleClick
                : () => alert("Still processing files")
            }
            disabled={isUploading === true ? true : false}
          >
            <div className="inline-flex justify-center items-center">
              <BiCloudUpload className="px-2" size={50} />
              <p className="text-sm text-cyan-400 font-semibold">
                Drag & drop 1 file or click to browse.
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BackgroundPopupFileUpload;
