import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { BsInfoCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

import { datePickerDateFormatter } from "../../shared/userPreferredDateTime";
import MonthYearDatePicker from "../../shared/MonthYearDatePicker";
import ToastNotification from "../toast-notification";
import progressOfWorkIcon from "../../assets/images/progressOfWork-icon.png";

const moment = require("moment");

const CREATE_BACKGROUND_DATE_MUTATION = `
  mutation createBackground($date: AWSDateTime, $order: Int = 1, $briefs: [BriefInput]) {
    backgroundCreate(date: $date, order: $order, briefs: $briefs) {
      id
    }
  }`;

const UPDATE_BACKGROUND_DATE_MUTATION = `
  mutation updateBackground($id: ID, $date: AWSDateTime) {
    backgroundUpdate(id: $id, date: $date) {
      id
      date
    }
  }
`;

const UPDATE_BACKGROUND_DESCRIPTION_MUTATION = `
  mutation updateBackground($id: ID, $description: String) {
    backgroundUpdate(id: $id, description: $description) {
      id
      date
    }
  }`;

// const UPDATE_BACKGROUND_DYNAMIC_DATA_MUTATION = `
//   mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
//     backgroundUpdate(id: $id, dynamicData: $dynamicData) {
//       id
//       date
//     }
//   }
// `;

// const BACKGROUND_TABLE_QUERY = `
//   query getBackgroundTable($clientMatterId: ID!) {
//     backgroundTable(clientMatterId: $clientMatterId) {
//       id
//       columns {
//         id
//         accessorKey
//         headerText
//         enabled
//         type
//         optionsText
//         order
//         presets {
//           id
//           name
//         }
//       }
//       createdAt
//       updatedAt
//     }
//   }`;

export default function ProgressOfWork({
  items,
  redirectUrl,
  completionDateRef,
  handleChangeGetStartedProgress,
}) {
  const history = useHistory();
  const brief = items;

  const [completionDate, setCompletionDate] = useState();
  const [projectCompletion, setProjectCompletion] = useState();
  const [hasBackgroundDate, setHasBackgroundDate] = useState(false);
  const [hasBackgroundDescription, setHasBackgroundDescription] =
    useState(false);
  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const backgrounds = brief?.backgrounds?.items;

  const hideToast = () => {
    setShowToast(false);
  };

  // const getBackgroundTable = async () => {
  //   try {
  //     const response = await API.graphql({
  //       query: BACKGROUND_TABLE_QUERY,
  //       variables: {
  //         clientMatterId: clientMatterId,
  //       },
  //     });

  //     const columns = response.data.backgroundTable?.columns || [];
  //     const projectCompletionColumn = columns.find(
  //       (obj) => obj.headerText === "Project Completion"
  //     );

  //     if (projectCompletionColumn) {
  //       const projectCompletionValue = getValueById(
  //         parsedBackgroundData,
  //         projectCompletionColumn.accessorKey
  //       );

  //       console.log("projectCompletionValue", projectCompletionValue);
  //       setSavedProjectCompletion(projectCompletionValue);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching background table:", error);
  //   }
  // };

  useEffect(() => {
    if (completionDate) {
      if (backgrounds.length !== 0) {
        updateBackgroundDate(backgrounds[0]?.id, completionDate);
      } else {
        createBackgroundDate(items, completionDate);
        handleChangeGetStartedProgress("completionDate");
      }
    }

    if (projectCompletion) {
      if (backgrounds.length !== 0) {
        updateBackgroundDescription(backgrounds[0]?.id, projectCompletion);
      } else {
        createBackgroundDate(items, projectCompletion);
        handleChangeGetStartedProgress("completionDate");
      }
    }
  }, [completionDate, projectCompletion]);

  // async function createDefaultDynamicData(id) {
  //   await API.graphql({
  //     query: UPDATE_BACKGROUND_DATE_MUTATION,
  //     variables: {
  //       id: id,
  //       dynamicData: null,
  //     },
  //   });
  // }
  // function getValueById(data, id) {
  //   console.log("data:", data);
  //   const item = data[0]; // Assuming there's only one item in the array (only 1 row per brief)
  //   const dynamicData = item?.dynamicData;
  //   const matchingObject = dynamicData?.find((obj) => obj.id === id);

  //   if (matchingObject) {
  //     return matchingObject.value;
  //   } else {
  //     return null; // ID not found
  //   }
  // }

  useEffect(() => {
    const backgrounds = brief?.backgrounds?.items ?? [];

    if (backgrounds.length !== 0) {
      const { date, description } = backgrounds[0];

      if (date) {
        setCompletionDate(date);
        setHasBackgroundDate(true);
      } else {
        setHasBackgroundDate(false);
      }

      if (description && !description.includes("Enter some text...")) {
        setProjectCompletion(description);
        setHasBackgroundDescription(true);
      } else {
        setHasBackgroundDescription(false);
      }
    } else {
      setHasBackgroundDate(false);
      setHasBackgroundDescription(false);
    }
  }, [brief]);

  function visitBrief() {
    history.push(redirectUrl);
  }

  async function createBackgroundDate(brief, completionDate) {
    try {
      await API.graphql({
        query: CREATE_BACKGROUND_DATE_MUTATION,
        variables: {
          briefs: [{ name: brief.name, id: brief.id }],
          date:
            completionDate !== null
              ? moment.utc(completionDate).toISOString()
              : null,
        },
      });
      setHasBackgroundDate(true);
    } catch (e) {
      console.error(e.errors[0].message);
    }
  }

  async function updateBackgroundDate(id, completionDate) {
    try {
      await API.graphql({
        query: UPDATE_BACKGROUND_DATE_MUTATION,
        variables: {
          id: id,
          date:
            completionDate !== null
              ? moment.utc(completionDate).toISOString()
              : null,
        },
      });
      setHasBackgroundDate(true);
    } catch (e) {
      console.error(e.errors[0].message);
    }
  }

  async function updateBackgroundDescription(id, projectCompletion) {
    try {
      await API.graphql({
        query: UPDATE_BACKGROUND_DESCRIPTION_MUTATION,
        variables: {
          id: id,
          description: projectCompletion,
        },
      });
      setHasBackgroundDescription(true);
    } catch (e) {
      console.error(e.errors[0].message);
    }
  }

  const handleToastNotification = () => {
    setResultMessage("Details successfully updated");
    setShowToast(true);

    setTimeout(() => {
      hideToast();
    }, 1500);
  };

  const handleRecordActivity = () => {
    //KJMF MMA 3586 RECORD ACTIVITY IF SANDBOX DATA IS CHANGED
    const mRecordSandboxDataEdit = `
      mutation createActivity(
        $companyId: ID,
        $activity: String,
        $appModule: AppModules,
        $field: String,
        $current: String,
        $previous: String,
        $rowId: String
      ) {
        activityCreate(
          activity: $activity
          companyId: $companyId
          appModule: $appModule
          previous: $previous
          field: $field
          current: $current
          rowId: $rowId
        ) {
          id
        }
      }
    `;

    //add activity when user exits the page
    var status = localStorage.getItem("isSandbox");

    if (status === "true") {
      const params = {
        query: mRecordSandboxDataEdit,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: `Modified date in Progress of Work Tile`,
          userId: localStorage.getItem("userId"),
          appModule: "SANDBOX",
        },
      };

      API.graphql(params).then((result) => {
        console.log(`Modified date in Progress of Work Tile`, result);
      });
    }
  };

  return (
    <>
      <div
        className=" bg-white shadow rounded-lg border border-neutral-200 overflow-hidden"
        ref={completionDateRef}
        id={"ProgressOfWork"}
      >
        <div className="w-full pt-6 px-8">
          <div className="relative">
            <div
              className=" absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246)",
              }}
            ></div>
            <div
              className=" absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.75)",
              }}
            ></div>
            <div
              className=" absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.50)",
              }}
            ></div>
            <div
              className=" absolute top-2.5 left-2 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
              style={{
                borderColor: "rgb(243, 244, 246, 0.25)",
              }}
            ></div>
          </div>
          <div className="bg-white flex justify-between items-center ">
            <div className="text-justify text-blue-950 text-xl font-light font-open-sans leading-none">
              <img
                src={progressOfWorkIcon}
                alt="Progress of Work Icon"
                className="pb-2 w-11 h-auto"
              />
            </div>
            {(hasBackgroundDate || hasBackgroundDescription) && (
              <div
                className="p-2 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
                onClick={visitBrief}
              >
                <FiEdit className="w-4 h-4" />
              </div>
            )}
          </div>

          <div className="gap-1 relative">
            <div className="justify-start items-center gap-2 pb-5">
              <div className="inline-flex">
                <div className="text-black text-lg font-open-sans font-semibold mr-1">
                  Progress of work
                </div>
                {hasBackgroundDate || hasBackgroundDescription ? (
                  <div>
                    <BsInfoCircle className="text-xs" />
                  </div>
                ) : null}
              </div>

              {hasBackgroundDate || hasBackgroundDescription ? (
                <>
                  {hasBackgroundDate && (
                    <>
                      <div className=" text-cyan-900 text-sm font-open-sans font-semibold pt-1 pb-4">
                        Project Completion Date
                      </div>
                      <div className="w-1/4 md:w-1/3 lg:w-1/4 leading-tight"></div>
                      <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                        <div className="px-4 py-6 bg-gray-100 rounded-2xl flex-col justify-start items-start gap-1 flex">
                          <div className="self-stretch text-red-500 text-xs font-semibold font-open-sans leading-none">
                            {new Date(completionDate).toLocaleDateString(
                              "en-US",
                              {
                                weekday: "long",
                              }
                            )}
                          </div>
                          <div className="self-stretch text-black text-lg font-semibold font-open-sans leading-7">
                            {new Date(completionDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {projectCompletion && (
                    <>
                      <div className=" text-cyan-900 text-sm font-open-sans font-semibold py-4">
                        Project Completion
                      </div>
                      <div className="w-full leading-tight"></div>
                      <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                        <div className="p-4 bg-gray-100 rounded-xl flex-col justify-start items-start gap-1 flex w-full">
                          <div className="self-stretch text-black text-xs font-semibold font-open-sans leading-none">
                            {projectCompletion}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className="w-full text-sm font-normal font-open-sans text-gray-600 pt-6 leading-tight"
                  style={{ color: "#5A5A5A" }}
                >
                  Check the current status of the construction and see the
                  projected completion date. Stay informed about each stage of
                  the building process.
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex-col justify-start items-start">
          <div className="w-full justify-center items-center">
            <div className="w-full flex-col justify-start items-start gap-1.5">
              {hasBackgroundDate ? null : (
                <div className="w-full px-4 py-4 border-t border-neutral-200 z-50">
                  <MonthYearDatePicker
                    popperProps={{
                      positionFixed: true,
                    }}
                    popperPlacement="top-start"
                    className="border w-full rounded-lg text-sm p-3 font-open-sans border-gray-300 "
                    dateFormat={datePickerDateFormatter(
                      localStorage.getItem("userpreferredDateFormatType") ===
                        "null"
                        ? "MM-DD-YYYY"
                        : localStorage.getItem("userpreferredDateFormatType")
                    )}
                    selected={completionDate}
                    // placeholderText="Completion Date"
                    placeholderText={
                      localStorage.getItem("userpreferredDateFormatType") ===
                      "null"
                        ? "MM-DD-YYYY"
                        : localStorage.getItem("userpreferredDateFormatType")
                    }
                    onCalendarClose={(selected) => {
                      handleRecordActivity();
                      setCompletionDate(selected);
                      handleToastNotification();
                    }}
                    shouldCloseOnSelect={true}
                  />
                </div>
              )}

              {hasBackgroundDescription ? null : (
                <div className="w-full px-4 pb-4 z-50">
                  <input
                    type="text"
                    placeholder="Project Completion"
                    className="border w-full rounded-lg text-sm p-3 font-open-sans border-gray-300"
                    defaultValue={projectCompletion}
                    onBlur={(e) => {
                      setProjectCompletion(e.target.value);
                      handleRecordActivity();
                      handleToastNotification();
                    }}
                    style={{ resize: "none" }}
                  ></input>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showToast ? (
        <ToastNotification
          title={resultMessage}
          error={
            resultMessage === "Details successfully updated" ? false : true
          }
        />
      ) : null}
    </>
  );
}
