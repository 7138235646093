/* External Components/Packages */
import { API } from "aws-amplify";
import React, {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
// import CreatableSelect from "react-select/creatable";

/* Shared Components */
import { GetUserCM, ListUsers } from "../../shared/graphql/queries";
// import { hbaDomains, obaDomains } from "../../constants/AppDomains";
import { AppRoutes } from "../../constants/AppRoutes";
import Button from "../../shared/Button";
import AccessControl from "../../shared/accessControl";
import {
  isMobileDevice,
  useWindowDimensions,
} from "../../shared/mobileViewFunctions";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import ToastNotification from "../toast-notification";
import loadingAnimation from "../../assets/images/post-authentication-loading.gif";

/* Custom Functional Components */
import {
  addClientMatter,
  deleteMatterClient,
  getMatterList,
  isDupe,
  searchMatterClient,
  //listClientMatters,
  filterMatterList,
  BRIEFS_QUERY
} from "./actions";
import CreateMatterModal from "./create-matter-modal";
import DeleteMatterModal from "./delete-matters-modal";
import { initialState } from "./initialState";
import { ClientMatters } from "./matters-list";
import { clientMatterReducers } from "./reducers";
import SortMatterModal from "./sort-matter-modal";
// import { Welcome } from "./welcome";

/* Assets & Icons */
import { BiArrowToTop, BiMenu } from "react-icons/bi";
import { CgMathPlus } from "react-icons/cg";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import { RiFilter2Fill } from "react-icons/ri";
// import { TbArrowsSort } from "react-icons/tb";
// import dashboardGradient from "../../assets/images/dashboard-gradient.png";
// import imgDocs from "../../assets/images/docs.svg";
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Mobile.css";

import {
  MATTER_REQUEST,
  MATTER_SUCCESS,
  MATTER_ERROR,
  SEARCH_MATTER_REQUEST,
  SEARCH_MATTER_SUCCESS,
  SEARCH_MATTER_ERROR,
  CREATE_MATTER_REQUEST,
  CREATE_MATTER_SUCCESS,
  CREATE_MATTER_ERROR,
  DELETE_MATTER_REQUEST,
  DELETE_MATTER_SUCCESS,
  DELETE_MATTER_ERROR,
  CREATE_MATTER_DUPLICATE, //mma1010
} from "./constants";
import { set } from "animejs";

export const MatterContext = createContext();
var momentTZ = require("moment-timezone");
const userTimeZone = momentTZ.tz.guess();

export default function Dashboard() {
  const [matterlist, dispatch] = useReducer(clientMatterReducers, initialState);
  const [contactLoading, setContactLoading] = useState(true);
  const [ContactList, setContactList] = useState([]);
  const [UserTypesList, setUserTypesList] = useState([]);

  const [error, setError] = useState(false);
  const [userInfo, setuserInfo] = useState(null);
  const [mattersView, setmattersView] = useState("grid");
  const [searchMatter, setSearchMatter] = useState("");
  const [clientName, setclientName] = useState(null);
  const [matterName, setmatterName] = useState(null);

  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const [allowCreateMatter, setAllowCreateMatter] = useState(false);
  const [allowDeleteMatter, setAllowDeleteMatter] = useState(false);
  const [allowViewCreateUpdateMatter, setAllowViewCreateUpdateMatter] =
    useState(false);
  const [allowOpenFileBucket, setAllowOpenFileBucket] = useState(false);
  const [allowOpenRFI, setAllowOpenRFI] = useState(false);
  const [allowOpenBackground, setAllowOpenBackground] = useState(false);
  const [allowOpenMatter, setAllowOpenMattersOverview] = useState(false);
  const [allowOpenLabelsLibrary, setAllowOpenLabelsLibrary] = useState(false);

  const [clientsOptions, setClientsOptions] = useState();
  const [mattersOptions, setMattersOptions] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [selectedMatter, setSelectedMatter] = useState();

  const selectInputMatterRef = useRef();
  const selectInputClientRef = useRef();
  const [selectedClientMatter, setSelectedClientMatter] = useState();

  const [loadingSave, setLoadingSave] = useState(false);

  const [inputMatter, setInputMatter] = useState("");
  const [inputSaveMatter, setSaveMatter] = useState("");
  const [inputClient, setInputClient] = useState("");
  const [inputSaveClient, setSaveClient] = useState("");
  const [inputContacts, setInputContacts] = useState([]);
  const [activeButton, setActiveButton] = useState("Recently Viewed");

  const [clientMatterList, setClientMatterList] = useState(null);
  const [recentClientMatterList, setRecentClientMatterList] = useState(null);

  const [showCmNameOrder, setShowCmNameOrder] = useState(localStorage.getItem("cmNameOrder"));

  let history = useHistory();
  const bool = useRef(false);
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  // const [clientMatterNextToken, setClientMatterNextToken] = useState(null);

  //FORCE REDIRECTION TO HBA DASHBOARD
  const [redirectDashboard, setRedirectDashboard] = useState(false);

  var domain = window.location.hostname;

  const {
    listmatters,
    nextToken,
    loading,
    errorMatter,
    toastMessage,
    toast,
    toast2,
    isInitializingPortal,
  } = matterlist;

  // const uniqueClientMatters = removeDuplicateObjects(listmatters);

  //   const uniqueClientMatters = listmatters;

  //   // Assuming you have a variable to hold the merged list, let's call it mergedList
  //   let mergedList = [];

  //   // Function to merge two lists without duplicates
  //   const mergeLists = (list1, list2) => {
  // 	return [...list1, ...list2];
  //   };

  //   // Concatenate the existing list with the newly fetched list
  //   mergedList = mergeLists(mergedList, listmatters);

  //   // Now mergedList contains the appended list without duplicates
  //   console.log("Merged List:", mergedList);

  const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");
  var activeUserType = localStorage.getItem("userType");

  const [showCreateMatterModal, setShowCreateMatterModal] = useState(false);

  // MMA-2272 (Matters Dashboard: Ability to sort by Date Created)
  const [clientMatterListDateSorted, setClientMatterListDateSorted] = useState(
    []
  );
  const [isClientMatterFiltered, setIsClientMatterFiltered] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [clientMatterFilters, setClientMatterFilters] = useState({
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate())),
  });

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // This function handles the execution of client matter filters.
  // If filters are provided, it sets and applies them; otherwise, it resets to default filters.
  const handleExecuteFilter = async (filters) => {
    if (filters) {
      setClientMatterFilters({
        startDate: filters.startDate,
        endDate: filters.endDate,
      });

      getClientMatterFiltered(filters);
      // setIsClientMatterFiltered(true);
    } else {
      // Reset / Clear Filters
      const defaultFilter = {
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate())),
      };
      setClientMatterFilters(defaultFilter);
      getClientMatterFiltered(defaultFilter);
    }
  };

  // This function filters and sorts client matters based on a date range.
  // It filters matters that fall within the specified date range and sorts them by creation date.
  const getClientMatterFiltered = async (filters) => {
    if (!filters.isSortedByDateRange && !filters.isSortedByLastOpened) {
      return setIsClientMatterFiltered(false);
    } else {
      if (filters.isSortedByDateRange && filters.isSortedByLastOpened) {
        const sortedMatters = clientMatterList
          .filter((matter) => matter.lastOpenedAt)
          .filter((matter) => {
            // Convert the createdAt string to a Date object
            const createdAtDate = new Date(matter.createdAt);
            // Extract the year, month, and day components from the date
            const createdAtYear = createdAtDate.getFullYear();
            const createdAtMonth = createdAtDate.getMonth();
            const createdAtDay = createdAtDate.getDate();

            // Convert the startDate and endDate to the same year, month, and day components
            const startYear = filters.startDate.getFullYear();
            const startMonth = filters.startDate.getMonth();
            const startDay = filters.startDate.getDate();

            const endYear = filters.endDate.getFullYear();
            const endMonth = filters.endDate.getMonth();
            const endDay = filters.endDate.getDate();

            return (
              createdAtYear >= startYear &&
              createdAtYear <= endYear &&
              createdAtMonth >= startMonth &&
              createdAtMonth <= endMonth &&
              createdAtDay >= startDay &&
              createdAtDay <= endDay
            );
          })
          .sort((a, b) => {
            const dateA = new Date(a.lastOpenedAt);
            const dateB = new Date(b.lastOpenedAt);

            return dateB - dateA;
          });

        setIsClientMatterFiltered(true);

        return setClientMatterListDateSorted(sortedMatters);
      }

      if (filters.isSortedByDateRange) {
        const sortedMatters = clientMatterList
          .filter((matter) => {
            // Convert the createdAt string to a Date object
            const createdAtDate = new Date(matter.createdAt);
            // Extract the year, month, and day components from the date
            const createdAtYear = createdAtDate.getFullYear();
            const createdAtMonth = createdAtDate.getMonth();
            const createdAtDay = createdAtDate.getDate();

            // Convert the startDate and endDate to the same year, month, and day components
            const startYear = filters.startDate.getFullYear();
            const startMonth = filters.startDate.getMonth();
            const startDay = filters.startDate.getDate();

            const endYear = filters.endDate.getFullYear();
            const endMonth = filters.endDate.getMonth();
            const endDay = filters.endDate.getDate();

            return (
              createdAtYear >= startYear &&
              createdAtYear <= endYear &&
              createdAtMonth >= startMonth &&
              createdAtMonth <= endMonth &&
              createdAtDay >= startDay &&
              createdAtDay <= endDay
            );
          })
          .sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            return dateA - dateB;
          });

        setIsClientMatterFiltered(true);

        return setClientMatterListDateSorted(sortedMatters);
      }

      if (filters.isSortedByLastOpened) {
        const sortedMatters = clientMatterList
          .filter((matter) => matter.lastOpenedAt)
          .sort((a, b) => {
            const dateA = new Date(a.lastOpenedAt);
            const dateB = new Date(b.lastOpenedAt);

            return dateB - dateA;
          });

        setIsClientMatterFiltered(true);

        return setClientMatterListDateSorted(sortedMatters);
      }
    }
  };

  const handleClearMatterFilter = () => {
    setIsClientMatterFiltered(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const listClientMatters = `
query listClientMatters($companyId: String, $limit: Int, $nextToken: String, $search: String) {
  company(id: $companyId) {
    clientMatters (limit: $limit, nextToken: $nextToken, isDeleted: false, search: $search, sortOrder: CREATED_DESC) {
      items {
        id
        createdAt
        updatedAt
        lastOpenedAt
        description
        matterNumber
        client {
          id
          name
        }
        matter {
          id
          name
        }
      }
      nextToken
    }
  }
}
`;

  //Check all client matters
  const [allCM, setAllCM] = useState(null)
  useEffect(async () => {
    if(allCM === null){
      const clientMattersOpt = await API.graphql({
        query: listClientMatters,
        variables: {
          companyId: companyId
        },
      });

      var res = clientMattersOpt.data.company.clientMatters.items;
      // const uniqueNewlyFetchedMatters = removeDuplicateObjects(res);

      const apdMn = await filterMatterList(res);

      // const sortedMatter = [...apdMn].sort((a, b) => {
      //   const dateA = a?.createdAt ? new Date(a?.createdAt) : null;
      //   const dateB = b?.createdAt ? new Date(b?.createdAt) : null;
      //   return dateB - dateA;
      // });

      console.log("All clientmatters", apdMn)
      setAllCM(apdMn)

      // Call Clients and Matters functions, pass the uniqueNewlyFetchedMatters
      Clients(res);
      Matters(res);
      getUsers();
      getUserTypes();
      setContactLoading(false);
    }
   }, [allCM])

  //PAGINATION KJMF (with pictures)
  const [nextTokenVal, setNextTokenVal] = useState(null); //next token
  const [lastCMVal, setLastCMVal] = useState([]); //all of the clientmatters retrieved
  const [downloadLinks, setDownloadLinks] = useState(new Map());
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [lastResultCount, setLastResultCount] = useState(0);
  const [remClientMatters, setRemClientMatters] = useState([]);
  const [remRecentClientMatters, setRemRecentClientMatters] = useState([]);


  const previewAndDownloadFile = async (id) => {
    if (id === null) {
      throw new Error("ID cannot be null. Please provide a valid ID.");
    }

    const FILE_DOWNLOAD_LINK_QUERY = `
    query getFileDownloadLink($id: ID) {
      file(id: $id) {
        downloadURL
        s3ObjectKey
        type
      }
    }`;

    const params = {
      query: FILE_DOWNLOAD_LINK_QUERY,
      variables: {
        id: id,
      },
    };

    try {
      const result = await API.graphql(params);
      let { downloadURL, s3ObjectKey } = result.data.file;
      return downloadURL;
    } catch (error) {
      console.error("Error fetching download URL:", error);
      throw error;
    }
  };

  async function getMatterListPagination(dispatch, companyId){
  try {

    console.log("Download links", downloadLinks)
    //Loading ui on first visit
    if(nextTokenVal === ""){
      dispatch({          
        type: MATTER_REQUEST,
        payload: { loading: true },
      });
    }

    let limit = 12;
    var result = [];
    var loopToken;

    let nextToken = nextTokenVal;

    console.log("NEXT TOKEN ENTERED", nextToken)
  
      // do {
      //   var fetchLimit = limit - result.length;
  
      //   const clientMattersOpt = await API.graphql({
      //       query: listClientMatters,
      //       variables: {
      //         companyId: companyId,
      //         limit: fetchLimit,
      //         nextToken: nextToken ? nextToken : null // Pass nextToken if it's available
      //       },
      //   });
  
      //   let clientmatterItems = clientMattersOpt.data.company.clientMatters.items;
      //   console.log("retrieved Items", clientmatterItems)
      //   //const filteredClientmatters = await filterMatterList(clientmatterItems);
      //   result = [...result, ...clientmatterItems]
      //   console.log("combined items", result)
      //   nextToken = clientMattersOpt.data.company.clientMatters.nextToken;
      //   console.log("nextToken in loop", nextToken)
      // }while(result.length < limit && nextToken)

         const clientMattersOpt = await API.graphql({
            query: listClientMatters,
            variables: {
              companyId: companyId,
              limit: limit,
              nextToken: nextToken ? nextToken : null // Pass nextToken if it's available
            },
        });

        let clientmatterItems = clientMattersOpt.data.company.clientMatters.items;
        console.log("retrieved Items", clientmatterItems)
        //const filteredClientmatters = await filterMatterList(clientmatterItems);
        result = [...result, ...clientmatterItems]
        nextToken = clientMattersOpt.data.company.clientMatters.nextToken;
    
      setLastResultCount(clientmatterItems?.length)
      setNextTokenVal(nextToken)
      

    //Previously retrieved matters
    var allClientMatters = lastCMVal;

    //Add the newly retrieved batch to the list of matters
    allClientMatters = [...allClientMatters, ...result]
    console.log("Total Fetched", allClientMatters)
    
    //Update values of total items
    setLastCMVal(allClientMatters)

    //Apply default filters
    const apdMn = await filterMatterList(allClientMatters);

    dispatch({
      type: MATTER_SUCCESS,
      payload: { matterlist: allClientMatters || [], mostRecentBrief: null, nextToken: nextToken },
    });

    setIsLoadingData(false);
    setClientMatterList(allClientMatters);

    // var temp = allCM;

    // console.log("missing", temp.filter(n => !result.includes(n)))

    //GETTING OF PROJECT LOCATION PHOTOS
    var idlist = [];
    //Get the clientmatterIds of the newly retrieved clientmatters
    result.map(x=> idlist = [...idlist, x.id]);

    var cmIdLink = [];

    //Get project location briefs for each client matter
    for(var i=0; i<idlist?.length; i++){
      const params = {
        query: BRIEFS_QUERY,
        variables: {
          id: idlist[i],
        },
      };

      await API.graphql(params).then(async (briefsList) => {
        //console.log("data", idlist[i], briefsList)
        var projectlocationBrief = [];
        briefsList.data.clientMatter.briefs.items.map(x=> x.name === "Project Location" ? projectlocationBrief = x : x)

        const fileIds = [];

        if(projectlocationBrief?.backgrounds?.items?.length > 0){
          if(projectlocationBrief?.backgrounds?.items[0]){
            //check if has file attached
            var file = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.id;
            var name = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.name;

            //if there id file attached
            if(file){
              fileIds.push(file);
              const photolink = await previewAndDownloadFile(file);
              cmIdLink = [...cmIdLink, {id: idlist[i], link: photolink}];
              //console.log("there is pic", cmIdLink)
            }

          }
        }
      })
    }

    //Check if there is a link available in the batch
    if(cmIdLink?.length > 0){  
      setDownloadLinks((links) => {
        let newMap = new Map(links);
        cmIdLink.forEach((item) => {
          newMap.set(item.id, item.link);
        });
        return newMap;
      });
    }

    return allClientMatters;
  } catch (error) {
    dispatch({
      type: MATTER_ERROR,
      payload: error,
    });
  }
  }

  function getItemsOpenedInTheLast30Days(items) {
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // Compute 30 days in milliseconds
    const thirtyDaysAgo = new Date(Date.now() - thirtyDaysInMillis);

    return items.filter((item) => {
      if (item.lastOpenedAt === null) return false; // Skip items never opened
      const lastOpenedDate = new Date(item.lastOpenedAt);
      return lastOpenedDate >= thirtyDaysAgo;
    });
  }

  //SECOND VERSION OF PAGINATION
  //FIRST LOAD
  async function getClientMattersFirstLoad(dispatch, companyId, state, searchString){
    try {
      switch(state) {
        case "firstload":
          dispatch({
            type: MATTER_REQUEST,
            payload: { loading: true },
          });
          break;
        case "search":
          dispatch({
            type: SEARCH_MATTER_REQUEST,
            payload: { loading: true },
          });
          break;
      }
      
      var limit = 16;

      let apdMn = allCM; 
      if(apdMn === null || searchString){
        let params = {
          query: listClientMatters,
          variables: {
            companyId: companyId,
            ...(searchString && { 
              search: searchString.toLowerCase() 
            })
          },
        }
        const clientMattersOpt = await API.graphql(params);
  
        var res = clientMattersOpt.data.company.clientMatters.items;
        // const uniqueNewlyFetchedMatters = removeDuplicateObjects(res);
        apdMn = await filterMatterList(res);
      }

      let recentCM = getItemsOpenedInTheLast30Days([...apdMn]).sort(
        (a, b) => {
          const dateA = a?.lastOpenedAt ? new Date(a?.lastOpenedAt) : null;
          const dateB = b?.lastOpenedAt ? new Date(b?.lastOpenedAt) : null;
          return dateB - dateA;
        }
      );

      var recentFirstLoad = recentCM.slice(0, limit);
      //store others in clientmatter bucket
      var remRecentRes = recentCM.slice(limit, recentCM.length)

      console.log("Recent Data Displayed:", recentFirstLoad)
      console.log("Recent Data Remaining:", remRecentRes)

      // const sortedMatter = [...apdMn].sort((a, b) => {
      //   const dateA = a?.createdAt ? new Date(a?.createdAt) : null;
      //   const dateB = b?.createdAt ? new Date(b?.createdAt) : null;
      //   return dateB - dateA;
      // });

      //show first 16
      var firstLoad = apdMn.slice(0, limit);
      //store others in clientmatter bucket
      var remRes = apdMn.slice(limit, apdMn.length)

      console.log("Data Displayed:", firstLoad)
      console.log("Data Remaining:", remRes)

      switch(state) {
        case "firstload":
          dispatch({
            type: MATTER_SUCCESS,
            payload: { matterlist: firstLoad || [], mostRecentBrief: null, nextToken: null},
          });
          break;
        case "search":
          dispatch({
            type: SEARCH_MATTER_SUCCESS,
            payload: {
              matterlist: firstLoad,
            },
          });
          break;
      }

      setClientMatterList(firstLoad);
      setRemClientMatters(remRes)
      setRecentClientMatterList(recentFirstLoad);
      setRemRecentClientMatters(remRecentRes)
      setNextTokenVal("0");

      //GETTING OF PROJECT LOCATION PHOTOS
      var idlist = [];
      //Get the clientmatterIds of the newly retrieved clientmatters
      firstLoad.map(x => idlist.push(x.id));
      recentFirstLoad.map(x => idlist.push(x.id));
      idlist = [...new Set(idlist)];

      var cmIdLink = [];

      //Get project location briefs for each client matter
      for(var i=0; i<idlist?.length; i++){
        const params = {
          query: BRIEFS_QUERY,
          variables: {
            id: idlist[i],
          },
        };

        await API.graphql(params).then(async (briefsList) => {
          //console.log("data", idlist[i], briefsList)
          var projectlocationBrief = [];
          briefsList.data.clientMatter.briefs.items.map(x=> x.name === "Project Location" ? projectlocationBrief = x : x)

          const fileIds = [];

          if(projectlocationBrief?.backgrounds?.items?.length > 0){
            if(projectlocationBrief?.backgrounds?.items[0]){
              //check if has file attached
              var file = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.id;
              var name = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.name;

              //if there id file attached
              if(file){
                fileIds.push(file);
                const photolink = await previewAndDownloadFile(file);
                cmIdLink = [...cmIdLink, {id: idlist[i], link: photolink}];
              }

            }
          }
        })
      }

      //Check if there is a link available in the batch
      if(cmIdLink?.length > 0){  
        setDownloadLinks((links) => {
          let newMap = new Map(links);
          cmIdLink.forEach((item) => {
            newMap.set(item.id, item.link);
          });
          return newMap;
        });
      }
      
    } catch (error) {
      dispatch({
        type: MATTER_ERROR,
        payload: error,
      });
    }
  }

  //NEXT LOADS
  async function getClientMattersNextLoad(dispatch, companyId){
    try {
      var apdMn = remClientMatters;
      var limit = 16;

      if(apdMn?.length <= 16){
        var prevList = clientMatterList;
        var nextLoadData = [...prevList, ...apdMn]

        console.log("Data Displayed:", nextLoadData)
        console.log("Data Remaining:", [])

        dispatch({
          type: MATTER_SUCCESS,
          payload: { matterlist: nextLoadData || [], mostRecentBrief: null, nextToken: null},
        });
  
        setClientMatterList(nextLoadData);
        setRemClientMatters([])
        setNextTokenVal("0");
        setIsLoadingData(false);
      }else{
        //show next 16
        var nextLoad = apdMn.slice(0, limit);
        //store others in clientmatter bucket
        var remRes = apdMn.slice(limit, apdMn.length)

        var prevList = clientMatterList;
        var nextLoadData = [...prevList, ...nextLoad]

        console.log("Data Displayed:", nextLoadData)
        console.log("Data Remaining:", remRes)

        dispatch({
          type: MATTER_SUCCESS,
          payload: { matterlist: nextLoadData || [], mostRecentBrief: null, nextToken: null},
        });
  
        setClientMatterList(nextLoadData);
        setRemClientMatters(remRes)
        setNextTokenVal("0");
        

        //GETTING OF PROJECT LOCATION PHOTOS
        var idlist = [];
        //Get the clientmatterIds of the newly retrieved clientmatters
        nextLoad.map(x=> idlist = [...idlist, x.id]);

        var cmIdLink = [];

        //Get project location briefs for each client matter
        for(var i=0; i<idlist?.length; i++){
          const params = {
            query: BRIEFS_QUERY,
            variables: {
              id: idlist[i],
            },
          };

          await API.graphql(params).then(async (briefsList) => {
            //console.log("data", idlist[i], briefsList)
            var projectlocationBrief = [];
            briefsList.data.clientMatter.briefs.items.map(x=> x.name === "Project Location" ? projectlocationBrief = x : x)

            const fileIds = [];

            if(projectlocationBrief?.backgrounds?.items?.length > 0){
              if(projectlocationBrief?.backgrounds?.items[0]){
                //check if has file attached
                var file = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.id;
                var name = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.name;

                //if there id file attached
                if(file){
                  fileIds.push(file);
                  const photolink = await previewAndDownloadFile(file);
                  cmIdLink = [...cmIdLink, {id: idlist[i], link: photolink}];
                }

              }
            }
          })
        }

        //Check if there is a link available in the batch
        if(cmIdLink?.length > 0){  
          setDownloadLinks((links) => {
            let newMap = new Map(links);
            cmIdLink.forEach((item) => {
              newMap.set(item.id, item.link);
            });
            return newMap;
          });
        }

        setIsLoadingData(false);
      }

    } catch (error) {
      dispatch({
        type: MATTER_ERROR,
        payload: error,
      });
    }

  }

  //NEXT RECENT LOADS
  async function getRecentClientMattersNextLoad(dispatch, companyId){
    try {
      var apdMn = remRecentClientMatters;
      var prevList = recentClientMatterList;

      var limit = 16;

      if(apdMn?.length <= 16){
        var nextLoadData = [...prevList, ...apdMn]

        console.log("Recent Data Displayed:", nextLoadData)
        console.log("Recent Data Remaining:", [])

        dispatch({
          type: MATTER_SUCCESS,
          payload: { matterlist: nextLoadData || [], mostRecentBrief: null, nextToken: null},
        });
  
        setRecentClientMatterList(nextLoadData);
        setRemRecentClientMatters([])
        // setNextTokenVal("0");
        setIsLoadingData(false);
      }else{
        //show next 16
        var nextLoad = apdMn.slice(0, limit);
        //store others in clientmatter bucket
        var remRes = apdMn.slice(limit, apdMn.length)

        var nextLoadData = [...prevList, ...nextLoad]

        console.log("Recent Data Displayed:", nextLoadData)
        console.log("Recent Data Remaining:", remRes)

        dispatch({
          type: MATTER_SUCCESS,
          payload: { matterlist: nextLoadData || [], mostRecentBrief: null, nextToken: null},
        });
  
        setRecentClientMatterList(nextLoadData);
        setRemRecentClientMatters(remRes)
        // setNextTokenVal("0");
        

        //GETTING OF PROJECT LOCATION PHOTOS
        var idlist = [];
        //Get the clientmatterIds of the newly retrieved clientmatters
        nextLoad.map(x=> idlist = [...idlist, x.id]);

        var cmIdLink = [];

        //Get project location briefs for each client matter
        for(var i=0; i<idlist?.length; i++){
          const params = {
            query: BRIEFS_QUERY,
            variables: {
              id: idlist[i],
            },
          };

          await API.graphql(params).then(async (briefsList) => {
            //console.log("data", idlist[i], briefsList)
            var projectlocationBrief = [];
            briefsList.data.clientMatter.briefs.items.map(x=> x.name === "Project Location" ? projectlocationBrief = x : x)

            const fileIds = [];

            if(projectlocationBrief?.backgrounds?.items?.length > 0){
              if(projectlocationBrief?.backgrounds?.items[0]){
                //check if has file attached
                var file = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.id;
                var name = projectlocationBrief?.backgrounds?.items[0]?.files?.items[0]?.name;

                //if there id file attached
                if(file){
                  fileIds.push(file);
                  const photolink = await previewAndDownloadFile(file);
                  cmIdLink = [...cmIdLink, {id: idlist[i], link: photolink}];
                }

              }
            }
          })
        }

        //Check if there is a link available in the batch
        if(cmIdLink?.length > 0){  
          setDownloadLinks((links) => {
            let newMap = new Map(links);
            cmIdLink.forEach((item) => {
              newMap.set(item.id, item.link);
            });
            return newMap;
          });
        }

        setIsLoadingData(false);
      }

    } catch (error) {
      dispatch({
        type: MATTER_ERROR,
        payload: error,
      });
    }

  }

  const handleLoadMore = async () => {
    setIsLoadingData(true);
    console.log("nextTokenVal", nextTokenVal)
    // Recursively fetch the next page of items
    if (activeButton === "View All" && remClientMatters?.length > 0) {
      // const cmList = getMatterListPagination(dispatch, companyId);
      const cmList = getClientMattersNextLoad(dispatch, companyId);
    }
    if (activeButton === "Recently Viewed" && remRecentClientMatters?.length > 0) {
      const cmList = getRecentClientMattersNextLoad(dispatch, companyId);
    }
  }

  useEffect(async () => {
    if (userInfo === null) {
      let ls = {
        userId: localStorage.getItem("userId"),
        email: localStorage.getItem("email"),
        firstName: localStorage.getItem("firstName"),
        lastName: localStorage.getItem("lastName"),
        company: localStorage.getItem("company"),
        userType: localStorage.getItem("userType"),
      };
      setuserInfo(ls);
      console.log("ls", ls);
    }

    // Stored the last URL in localStorage
    const lastUrl = localStorage.getItem("lastUrlAccessed");
    const urlParams = new URLSearchParams(lastUrl);
    const shared = urlParams.get("shared");

    if (shared) {
      if (lastUrl !== "" && lastUrl !== null) {
        // Redirect the user to the last URL and remove localstorage item
        window.location.href = lastUrl.replace(/&shared.*/, "");
      }
    }

    featureAccessFilters();

    //FirstLoad
    if(clientMatterList === null){
      // var temp = getMatterList(dispatch, companyId, null).then((firstResult)=>{
      // var cmList = getMatterListPagination(dispatch, companyId).then((firstResult)=>{
      var cmList = getClientMattersFirstLoad(dispatch, companyId, "firstload").then((firstResult)=>{
        //console.log("first fetch", firstResult)
        //window.scrollTo(0, 0);
      });
    }
  }, []);

  const featureAccessFilters = async () => {
    const dashboardAccess = await AccessControl("DASHBOARD");
    if (dashboardAccess.status !== "restrict") {
      setAllowCreateMatter(
        dashboardAccess.data.features.includes("ADDCLIENTANDMATTER")
      );
      setAllowDeleteMatter(
        dashboardAccess.data.features.includes("DELETECLIENTANDMATTER")
      );
      setAllowViewCreateUpdateMatter(
        dashboardAccess.data.features.includes("VIEWCREATEUPDATEDETAILS")
      );
    } else {
      setAllowCreateMatter(
        dashboardAccess.data.features.includes("ADDCLIENTANDMATTER")
      );
      setAllowDeleteMatter(
        dashboardAccess.data.features.includes("DELETECLIENTANDMATTER")
      );
      setAllowViewCreateUpdateMatter(
        dashboardAccess.data.features.includes("VIEWCREATEUPDATEDETAILS")
      );
      console.log(dashboardAccess.message);
    }

    const mattersOverviewAccess = await AccessControl("MATTERSOVERVIEW");

    if (mattersOverviewAccess.status !== "restrict") {
      setAllowOpenMattersOverview(true);
    } else {
      console.log(mattersOverviewAccess.message);
    }

    const fileBucketAccess = await AccessControl("FILEBUCKET");

    if (fileBucketAccess.status !== "restrict") {
      setAllowOpenFileBucket(true);
    } else {
      console.log(fileBucketAccess.message);
    }

    const RFIAccess = await AccessControl("MATTERSRFI");

    if (RFIAccess.status !== "restrict") {
      setAllowOpenRFI(true);
    } else {
      console.log(RFIAccess.message);
    }

    const backgroundAccess = await AccessControl("BACKGROUND");

    if (backgroundAccess.status !== "restrict") {
      setAllowOpenBackground(true);
    } else {
      console.log(backgroundAccess.message);
    }

    const labelsLibraryAccess = await AccessControl("LABELS");

    if (labelsLibraryAccess.status !== "restrict") {
      setAllowOpenLabelsLibrary(true);
    } else {
      console.log(labelsLibraryAccess.message);
    }
  };

  const handleClientChanged = (newValue) => {
    console.log(newValue);
    if (newValue?.__isNew__) {
      addClients(newValue.label);
    } else {
      setclientName(newValue);
    }
  };

  const handleMatterChanged = (newValue) => {
    if (newValue?.__isNew__) {
      addMatters(newValue.label);
    } else {
      setmatterName(newValue);
    }
  };

  //KJMF - Autotag newly created client matter to OPS
  const [operations, setOperations] = useState(null);

  const mCreateActivity = `
		mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
		activityCreate(
			activity: $activity
			briefId: $briefId
			clientMatterId: $clientMatterId
			companyId: $companyId
			previous: $previous
			field: $field
			current: $current
			appModule: $appModule
			rowId: $rowId
		) {
			id
		}
		}`;

  useEffect(() => {
    getTeams();

    // Add tracker for when page has been accessed.
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        activity: `page has been accessed.`,
        appModule: "DASHBOARD",
        userId: localStorage.getItem("userId"),
      },
    };
    const addActivity = API.graphql(params).then((result) => {
      console.log("addActivity page access recorded", result);
    });
  }, []);

  //get operations team
  const qGetTeams = `
		query getTeamsByCompany($id: String) {
		company(id: $id) {
			teams {
			items {
				id
				name
			}
			}
		}
		}
	`;

  const getTeams = async () => {
    let params = {
      query: qGetTeams,
      variables: {
        id: companyId,
      },
    };

    await API.graphql(params).then(async (teams) => {
      try {
        console.log("teams", teams);
        if (teams.data.company == null) {
          console.log("teamlist is null", teams);
        } else {
          let TeamList = teams.data.company.teams.items;
          if (TeamList.length > 0 && TeamList !== null) {
            let operations = TeamList.filter((team) => {
              return team.name === "Operations";
            });
            if (operations.length > 0) {
              setOperations(operations[0]);
              // console.log("Getting ops members", operations);
            } else {
              console.log(
                "Client/Matter is not tagged. There are no teams named 'Operations'"
              );
            }
          }
        }
      } catch (e) {
        console.log("ERROR at getqwithteams", e);
      }
    });
  };

  //get team members
  const qGetTeamsWithMembers = `
	query getTeamMembers($id: ID, $companyId: String) {
		team(id: $id) {
      id
      name
      members {
        items {
          userType
          user {
            id
            firstName
            lastName
            userType
            profilePicture
            clientMatterAccess(companyId: $companyId) {
              items {
                id
                userType
                clientMatter {
                  id
                }
              }
            }
          }
        }
      }
		}
	}`;

  //tagging per user mutation
  const mTagClientMatter = `
		mutation tagUserClientMatterAccess($clientMatterAccess: [UserClientMatterAccessInput], $userId: ID, $companyId: ID) {
      userClientMatterTag(userId: $userId, clientMatterAccess: $clientMatterAccess, companyId: $companyId) {
        id
      }
		}
	`;

  //get team members + tag per member
  async function getTeamsWithMembers(teamId, newClientMatterId) {
    const params = {
      query: qGetTeamsWithMembers,
      variables: {
        id: teamId,
        companyId: localStorage.getItem("companyId"),
      },
    };

    const response = await API.graphql(params).then((response) => {
      const CurrentTeam = response.data?.team;
      console.log("response", response.data)
      console.log("team members", CurrentTeam?.members?.items);
      CurrentTeam?.members?.items.map(async (item) => {
        // console.log("user id", item.user.id);
        // console.log("usertype", item.user.userType);

        //get clientmatteraccess per user, set to graphql format
        var cmAccessParam = item.user?.clientMatterAccess?.items.map((x) => {
          return {
            clientMatterId: x.clientMatter?.id,
            userType: x.userType,
          };
        });
        //append new client matter
        var newData = {
          clientMatterId: newClientMatterId,
          userType: item.user.userType,
        };
        cmAccessParam.push(newData);

        // console.log("client matter access", cmAccessParam);
        const params = {
          query: mTagClientMatter,
          variables: {
            clientMatterAccess: cmAccessParam,
            userId: item.user.id,
            companyId: localStorage.getItem("companyId"),
          },
        };
        const result = await API.graphql(params);
        console.log("newly-tagged client matter", result);
      });
    });
  }

  //tag client matter per team
  const mTeamClientMatterTag = `
		mutation teamClientMatterTage($teamId: ID, $clientMatters: [ID]) {
			teamClientMatterTag(teamId: $teamId, clientMatters: $clientMatters) {
				id
			}
			}
		`;

  async function tagClientMatterPerTeam(id, cmIds) {
    const params = {
      query: mTeamClientMatterTag,
      variables: {
        teamId: id,
        clientMatters: cmIds,
      },
    };

    const response = await API.graphql(params).then((response) => {
      console.log("tagClientMatter", response);
    });
  }

  const qGetTeamsClientMatters = `
		query getTeamMembers($id: ID) {
			team(id: $id) {
			clientMatters {
				items {
				id
				client {
					name
				}
				matter {
					name
				}
				}
			}
			}
		}`;

  const mUpdateDateLastOpened = `mutation updateClientMatterLastOpened($id: ID, $lastOpenedAt: AWSDateTime) {
		clientMatterUpdate(id: $id, lastOpenedAt: $lastOpenedAt) {
			id
		}
	}`;

  const handleTaggingToOperations = async (id, newClientMatterId) => {
    if (id != null) {
      const params = {
        query: qGetTeamsClientMatters,
        variables: {
          id: id,
        },
      };

      // Getting all client matters in Operations
      const response = await API.graphql(params).then((response) => {
        const CurrentTeam = response.data.team;
        let filtered = CurrentTeam.clientMatters?.items.map((item) => {
          return item.id;
        });
        // Tag all the client matters along with the new client matter
        tagClientMatterPerTeam(operations?.id, [
          ...filtered,
          newClientMatterId,
        ]);
      });

      // Getting all client matters per user in Operations
      getTeamsWithMembers(operations?.id, newClientMatterId);
    }
  };

  const handleNewMatter = async () => {
    console.log("handleNewMatter()");
    setLoadingSave(true);
    let client = {
        id: clientName.value,
        name: clientName.label,
      },
      matter = {
        id: matterName.value,
        name: matterName.label,
      };

    if (await isDupe(client, matter, companyId, userId, dispatch)) {
      console.log("Duplicate Client/Matter Detected");
      setLoadingSave(false);
    } else {
      const cmId = addClientMatter(
        client,
        matter,
        companyId,
        userId,
        dispatch
      ).then(async (res) => {
        console.log("Client Matter Id: ", res);

        updateClientMatterLastOpened(res);
        setShowCreateMatterModal(false);

        // getMatterList(dispatch, companyId, null);
        //searchMatterClient(companyId, "", dispatch);

        if (inputContacts.length > 0) {
          inputContacts?.forEach(async (contact) => {
            // Get existing ClientMatter per user
            const contactInfo = await GetUserCM(
              contact.id,
              localStorage.getItem("companyId")
            );
            if (contactInfo) {
              let cmAccessParam =
                contactInfo.data.user.clientMatterAccess.items.map((cm) => {
                  return {
                    clientMatterId: cm.clientMatter?.id,
                    userType: cm.userType,
                  };
                });

              // Adding new client matter
              const newCM = {
                clientMatterId: res,
                userType: contact.type.value,
              };
              cmAccessParam.push(newCM);

              // Tagging client matter to user
              const params = {
                query: mTagClientMatter,
                variables: {
                  clientMatterAccess: cmAccessParam,
                  userId: contact.id,
                  companyId: localStorage.getItem("companyId"),
                },
              };
              const result = await API.graphql(params);
              console.log("New Client Matter tagged to user: ", result);
            }

            //getClientMattersFirstLoad(dispatch, companyId);
          });
        }

        //add activity
        const activityParams = {
          query: mCreateActivity,
          variables: {
            companyId: companyId,
            clientMatterId: res,
            activity: "Create client matter",
            appModule: "INBOX",
          },
        };

        await API.graphql(activityParams).then((result) =>
          console.log("activity result", result)
        );

        //tag to ops
        handleTaggingToOperations(operations?.id, res);

        //clear all
        setInputClient("");
        setInputMatter("");
        setSaveClient("");
        setSaveMatter("");
        //setLoadingSave(false);

        //getClientMattersFirstLoad(dispatch, companyId);

        window.location.reload();
      });
    }
  };

  const updateClientMatterLastOpened = async (clientmatter_id) => {
    await API.graphql({
      query: mUpdateDateLastOpened,
      variables: {
        id: clientmatter_id,
        lastOpenedAt: new Date().toISOString(),
      },
    });
  };

  const handleModalClose = () => {
    setshowDeleteModal(false);
  };

  const handleShowDeleteModal = (displayStatus, id) => {
    setshowDeleteModal(displayStatus, id);
    setSelectedClientMatter(id);
  };

  const handleDeleteModal = async () => {
    handleModalClose();
    const res = await deleteMatterClient(selectedClientMatter, dispatch, companyId).then((res)=>{
      window.location.reload();
    })
  };

  const Clients = async (clientMatterList) => {
    const uniqueClients = new Map();

    clientMatterList?.forEach(({ client }) => {
      if (client?.id && client?.name && !uniqueClients.has(client?.id)) {
        uniqueClients.set(client?.id, {
          value: client?.id,
          label: client?.name,
        });
      }
    });

    const result = Array.from(uniqueClients.values()).sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    // console.log("FILTERED CLIENTS OPTIONS", result);
    setClientsOptions(result);
  };

  const Matters = async (clientMatterList) => {
    const uniqueMatters = new Map();

    clientMatterList?.forEach(({ matter }) => {
      if (matter?.id && matter?.name && !uniqueMatters.has(matter?.id)) {
        uniqueMatters.set(matter?.id, {
          value: matter?.id,
          label: matter?.name,
        });
      }
    });

    const result = Array.from(uniqueMatters.values()).sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    // console.log("FILTERED MATTERS OPTIONS", result);
    setMattersOptions(result);
  };

  const addClient = `
		mutation addClient($companyId: String, $name: String) {
			clientCreate(companyId:$companyId, name:$name) {
				id
				name
			}
		}
		`;
  const addClients = async (data) => {
    let result;

    const companyId = localStorage.getItem("companyId");

    const addedClientList = await API.graphql({
      query: addClient,
      variables: {
        companyId: companyId,
        name: data,
      },
    });

    result = [addedClientList.data.clientCreate].map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    setclientName(result[0]);
  };

  const addMatter = `
		mutation addMatter($companyId: String, $name: String) {
			matterCreate(companyId:$companyId, name:$name) {
				id
				name
			}
		}
		`;
  const addMatters = async (data) => {
    let result;

    const companyId = localStorage.getItem("companyId");

    const addedMatterList = await API.graphql({
      query: addMatter,
      variables: {
        companyId: companyId,
        name: data,
      },
    });

    result = [addedMatterList.data.matterCreate].map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    setmatterName(result[0]);
  };

  // ** MMA-1557 Dashboard displays wrong client/matter after clearing the search bar
  // The error occurs due to rapid state changes in searchMatter, leading to frequent API calls and overloading the AWS Lambda function.
  // Adding a delay to the searchMatter function calls resolves the issue by debouncing the API calls and executing them after a brief pause.
  // One main reason why the error occurs when there is a large amount of client/matter data that is being queried
  //   useEffect(() => {
  //     const delayDebounceFn = setTimeout(() => {
  //       if (searchMatter.length >= 1) {
  //         searchMatterClient(companyId, searchMatter, dispatch);
  //       } else {
  //         getMatterList(dispatch, companyId);
  //       }
  //     }, 500); // delays getting the client/matter list in 0.200 seconds

  //     return () => clearTimeout(delayDebounceFn);
  //   }, [searchMatter]);

  const handleSearchMatterChange = (e) => {
    const inputValue = e.target.value;
    // setSearchMatter(inputValue);

    //console.log("handleSearchEnterChange()", e.key);
    if (e.key === "Enter") {
      setActiveButton("View All")
      console.log("Enter", searchMatter);
      // setRemClientMatters([]);
      // setAllCM([]);
      if (searchMatter.length >= 1) {
        getClientMattersFirstLoad(dispatch, companyId, "search", searchMatter)
      } 
      // else {
      //   if (inputValue === "") {
      //     // getMatterList(dispatch, companyId);
      //     // searchMatterClient(companyId, "", dispatch);
      //     getClientMattersFirstLoad(dispatch, companyId, "search")
      //   }
      // }
    } else {
      if (inputValue === "") {
        // getMatterList(dispatch, companyId);
        //searchMatterClient(companyId, "", dispatch);
        // setRemClientMatters([]);
        // setAllCM([]);
        getClientMattersFirstLoad(dispatch, companyId, "search")
      }
    }
  };

  // MMA-1861
  const qUserTypes = `
		query getDefaultUserTypes {
		defaultUserType
	}`;
  const getUsers = async () => {
    await ListUsers(localStorage.getItem("companyId")).then((res) => {
      if (res && res.data.company) {
        let usersList = res.data.company.users.items;

        usersList?.forEach((user) => {
          let name = user.firstName + " " + user.lastName;
          let newUser = {
            id: user.id,
            value: user.id + " " + name,
            label: name,
            profilePicture: user.profilePicture,
            type: null,
          };
          setContactList((prev) => [...prev, newUser]);

          // let newUserSelectState = {
          //   id: user.id,
          //   isButtonClicked: false,
          //   userTypeSelected: null
          // }
          // setUserSelectStates((prev) => [...prev, newUserSelectState])
        });
      } else {
        setContactList([]);
      }
    });
  };

  const getUserTypes = async () => {
    const colors = [
      // Colors for User Types
      "bg-pink-700",
      "bg-purple-700",
      "bg-blue-700",
      "bg-yellow-300",
      "bg-orange-500",
      "bg-green-500",
      "bg-cyan-400",
    ];

    await API.graphql({ query: qUserTypes }).then((res) => {
      if (res && res.data.defaultUserType) {
        let userTypesList = res.data.defaultUserType;

        console.log("User Types list: ", userTypesList);

        userTypesList?.forEach((userType, index) => {
          let newType = {
            value: userType,
            label: userType,
            color: colors[index],
          };
          setUserTypesList((prev) => [...prev, newType]);
        });
      } else {
        setUserTypesList([]);
      }
    });
  };

  ///////////////////////////////////////////////////////////////////////////
  // Comment or uncomment the code block below AND the useIdleTimer import statement to toggle session timeout

  // var timeoutId, timeoutNotif;
  // //Session timeout
  // const handleOnAction = (event) => {
  //   //function for detecting if user moved/clicked.
  //   //if modal is active and user moved, automatic logout (session expired)
  //   bool.current = false;
  //   if (showSessionTimeout) {
  //     setTimeout(() => {
  //       Auth.signOut().then(() => {
  //         clearLocalStorage();
  //         console.log("Sign out completed.");
  //         history.push("/");
  //       });

  //       function clearLocalStorage() {
  //         localStorage.removeItem("userId");
  //         localStorage.removeItem("email");
  //         localStorage.removeItem("firstName");
  //         localStorage.removeItem("lastName");
  //         localStorage.removeItem("userType");
  //         localStorage.removeItem("company");
  //         localStorage.removeItem("companyId");
  //         // localStorage.removeItem("access");
  //       }
  //     }, 3000);
  //   }

  //   clearTimeout(timeoutId);
  //   clearTimeout(timeoutNotif);
  // };

  // const handleOnIdle = (event) => {
  //   timeoutId = setTimeout(() => {
  //     // timeoutNotif = setTimeout(() => {
  //     //   setShowSessionTimeout(true);
  //     // }, 1000 * 300); //5 minutes to confirm

  //     // if (
  //     //   alert(
  //     //     "Session will expire in 5 minutes. Please confirm that you are still active before the session expires"
  //     //   )
  //     // ) {
  //     //   clearTimeout(timeoutNotif);
  //     //   clearTimeout(timeoutId);
  //     // }
  //     setShowSessionTimeout(true);
  //   }, 60000 * 120); //2 hours before session timeout
  // };

  // useIdleTimer({
  //   timeout: 60 * 40,
  //   onAction: handleOnAction,
  //   onIdle: handleOnIdle,
  //   debounce: 1000,
  // });

  /** MOBILE CONST */

  const { height, width } = useWindowDimensions();
  const [contentHeight, setContentHeight] = useState();
  const [showScrollButton, setShowScrollButton] = useState(false);

  function handleScrollEvent(e) {
    const top = e.target.scrollTop > 20;
    if (top) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }
  function handleScrollToTop() {
    let d = document.getElementById("mobileContent");
    d.scrollTo(0, 0);
  }

  useEffect(() => {
    if (userInfo) {
      var headerTag = document.getElementById("headerTag");
      setContentHeight(height - 40 - headerTag.offsetHeight);
    }
  }, [height, width, userInfo]);

  function focusOnChildInputTag(e) {
    var element = e.target;
    if (element.tagName == "INPUT") {
      element.focus();
    } else {
      var input = element.getElementsByTagName("input")[0];
      input.focus();
    }
  }

  function removeDuplicateObjects(array) {
    const uniqueObjects = [];
    const usedIds = [];

    array?.forEach((obj) => {
      if (!usedIds.includes(obj.id)) {
        uniqueObjects.push(obj);
        usedIds.push(obj.id);
      }
    });

    return uniqueObjects;
  }

  // Call only once when the page loads
  useEffect(() => {
    // Ensure uniqueness in the newly fetched list
    // const uniqueNewlyFetchedMatters = removeDuplicateObjects(listmatters);
    const uniqueNewlyFetchedMatters = listmatters;

    // Update clientMatterList state with the new value

    
    // setClientMatterList(uniqueNewlyFetchedMatters);
    // setRecentClientMatterList([])

    // Call Clients and Matters functions, pass the uniqueNewlyFetchedMatters
    // Clients(uniqueNewlyFetchedMatters);
    // Matters(uniqueNewlyFetchedMatters);
  }, [listmatters]);

  return userInfo ? (
    <>
      <ScrollToTop
        smooth
        component={
          <BiArrowToTop
            style={{ color: "white", display: "block", margin: "auto" }}
          />
        }
        className="sm:hidden scrollButton"
        style={{ borderRadius: "50%" }}
      />
      <div className="py-5 bg-gray-100 sm:flex-none sm:p-0 sm:bg-white sm:h-auto">
        <div className="text-right sm:hidden">
          <h1 id="headerTag" className="px-3 py-5 text-base font-bold">
            Matter Dashboard
          </h1>
        </div>
        <div
          className="flex flex-col gap-2 px-3 py-5 font-sans bg-gray-100 rounded-lg contentDiv sm:pl-12 sm:pr-8"
          style={{ height: width > 640 ? "" : contentHeight }}
        >
          {/* Dashboard Header Revamp */}
          {/* <div className="hidden overflow-hidden bg-gray-100 border sm:flex rounded-3xl">
						<div className="flex-1 pl-8 py-14">
							<div className="flex flex-col items-start justify-start w-full h-auto gap-3">
								<div className="text-lg font-semibold text-gray-900">
									{`Good day, ${userInfo.firstName}!`}
								</div>
								<div className="w-2/3 text-base font-normal text-justify text-gray-500">
								</div>
								<div className="flex w-2/3 px-3 sm:px-0">
									<span className="absolute z-10 items-center justify-center w-8 px-3 py-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-300">
										<IoIcons.IoIosSearch />
									</span>
									<input
										type="search"
										value={searchMatter}
										placeholder="Search Matter or Client ..."
										onChange={handleSearchMatterChange}
										className="relative w-full px-3 py-3 pl-10 text-sm placeholder-gray-500 bg-white border-0 rounded shadow outline-none focus:outline-none focus:ring-2 ring-blue-500 ring-offset-4"
									/>
									<button
										className="flex items-center justify-center gap-1 px-4 py-2 text-base font-medium text-white bg-green-600 border border-green-600 rounded shadow hover:bg-white hover:text-green-600 ml-1"
										onClick={() => {
											openDialog();
										}}
									>
										Filter
										<RiFilter2Fill />
									</button>
								</div>
							</div>
						</div>
						<div className="flex-1">
							<img
								className="h-full"
								src={dashboardGradient}
								alt="rightside-illustration"
							/>
						</div>
					</div> */}

          <div className="hidden overflow-hidden bg-gray-100 sm:flex rounded-3xl">
            <div className="flex-1 py-8">
              <div className="flex flex-col items-start justify-start w-full h-auto gap-2">
                {" "}
                {/* Increased gap */}
                <div className="flex items-center justify-between w-full">
                  <p className="text-xl font-medium text-gray-900 w-full">{`Welcome back, ${userInfo.firstName}!`}</p>{" "}
                  {/* Adjusted max width */}
                  <div className="w-full flex justify-end gap-4">
                    {" "}
                    {/* Container for search bar and Add New button */}
                    <div className="relative">
                      <div className="flex mr-4">
                        <input
                          type="search"
                          value={searchMatter}
                          placeholder="Search"
                          onKeyDown={(e) => {
                            if (e.target.value !== "") {
                              handleSearchMatterChange(e);
                            }
                          }}
                          onChange={(e) => {
                            setSearchMatter(e.target.value);
                            handleSearchMatterChange(e);
                          }}
                          className="px-3 py-3 pl-10 pr-8 text-sm placeholder-gray-500 bg-white border-0 rounded shadow outline-none focus:outline-none focus:ring-2 ring-blue-500 ring-offset-4 w-80"
                        />
                        <span
                          className="cursor-pointer mr-3 absolute top-0 right-0 z-10 flex items-center justify-center w-10 h-10 px-3 py-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-300"
                          onClick={() => {
                            openDialog();
                          }}
                        >
                          <RiFilter2Fill style={{ fontSize: "1.5em" }} />
                        </span>
                      </div>
                      <span className="absolute top-0 left-0 z-10 flex items-center justify-center w-10 h-10 px-3 py-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-300">
                        <IoIcons.IoIosSearch style={{ fontSize: "1.5em" }} />
                      </span>
                    </div>
                    {/* Dasboard Add Client Matter Button Revamp */}
                    {allowCreateMatter && (
                      <button
                        className="flex items-center justify-center gap-2 px-4 py-2 text-base font-medium text-white bg-green-600 border border-green-600 rounded-lg shadow hover:bg-white hover:text-green-600"
                        onClick={() => setShowCreateMatterModal(true)}
                      >
                        <CgMathPlus />
                        Add New
                      </button>
                    )}
                  </div>
                </div>
                <div className="">
                  <p className="text-sm text-gray-500">
                    Streamline your client management with this unified
                    dashboard, tailored for effortless collaboration and
                    meticulous control of account access.
                  </p>
                </div>
                <div className="w-2/3 text-base font-normal text-justify text-gray-500"></div>
              </div>
            </div>
          </div>

          <div className="bg-white p-4 rounded-xl">
            {/* Dasboard Action Bar Revamp */}
            <div className="items-center justify-between hidden px-5 md:px-0 sm:flex mb-6">
              <div className="inline-flex items-start justify-start gap-4 h-9">
                <div className="inline-flex flex-col items-center justify-center gap-4">
                  <div className="text-sm font-semibold leading-tight text-green-800">
                    All Files
                  </div>
                  <div className="self-stretch h-0.5 bg-green-800" />
                </div>
                <div className="inline-flex flex-col items-center justify-center gap-4">
                  <Link to={AppRoutes.ARCHIVES}>
                    <div className="text-sm font-normal leading-tight text-gray-500">
                      Archives
                    </div>
                    <div className="self-stretch h-0.5" />
                  </Link>
                </div>
                <div className="inline-flex flex-col items-center justify-center gap-4">
                  <Link to={AppRoutes.CLIENTMATTERLIBRARY}>
                    <div className="text-sm font-normal leading-tight text-gray-500">
                      Client Matter libraries
                    </div>
                    <div className="self-stretch h-0.5" />
                  </Link>
                </div>
                <div className="inline-flex flex-col items-center justify-center gap-4">
                  <Link to={AppRoutes.CATEGORYLIBRARY}>
                    <div className="text-sm font-normal leading-tight text-gray-500">
                      Category libraries
                    </div>
                    <div className="self-stretch h-0.5" />
                  </Link>
                </div>
              </div>
              {/* Dasboard Add Client Matter Button Revamp */}
              {/* {allowCreateMatter && (
								<div className="flex gap-2">
									<button
										className="flex items-center justify-center gap-2 px-4 py-2 text-base font-medium text-white bg-green-600 border border-green-600 rounded-lg shadow hover:bg-white hover:text-green-600"
										onClick={() => setShowCreateMatterModal(true)}
									>
										Add New
										<CgMathPlus />
									</button>
								</div>
							)} */}
            </div>

            <div className="bg-gray-100 mb-4 rounded-xl p-2">
              <button
                className={`px-4 py-2 text-sm font-medium rounded-lg ${
                  activeButton === "Recently Viewed"
                    ? "bg-white text-gray-600"
                    : "text-gray-400"
                }`}
                onClick={() => setActiveButton("Recently Viewed")}
              >
                Recently Viewed
              </button>
              <button
                className={`px-4 py-2 text-sm font-medium rounded-lg ${
                  activeButton === "View All"
                    ? "bg-white text-gray-600"
                    : "text-gray-400"
                }`}
                onClick={() => setActiveButton("View All")}
              >
                View All
              </button>
            </div>

            {/* Dasboard Create Matter Modal Revamp */}
            {showCreateMatterModal && (
              <CreateMatterModal
                close={() => setShowCreateMatterModal(false)}
                loading={loading}
                focusOnChildInputTag={focusOnChildInputTag}
                selectInputClientRef={selectInputClientRef}
                clientsOptions={clientsOptions}
                handleClientChanged={handleClientChanged}
                selectedClient={selectedClient}
                inputSaveClient={inputSaveClient}
                inputClient={inputClient}
                setInputClient={setInputClient}
                setSaveClient={setSaveClient}
                selectInputMatterRef={selectInputMatterRef}
                mattersOptions={mattersOptions}
                handleMatterChanged={handleMatterChanged}
                selectedMatter={selectedMatter}
                inputSaveMatter={inputSaveMatter}
                inputMatter={inputMatter}
                setInputMatter={setInputMatter}
                setSaveMatter={setSaveMatter}
                setInputContacts={setInputContacts}
                inputContacts={inputContacts}
                matterName={matterName}
                clientName={clientName}
                contactOptions={ContactList}
                userTypeOptions={UserTypesList}
                contactLoading={contactLoading}
                handleSubmit={handleSubmit(handleNewMatter)}
                setLoadingSave={setLoadingSave}
                loadingSave={loadingSave}
              />
            )}

            {/* MMA-2272 Display the sort matter modal */}
            <SortMatterModal
              isDialogOpen={isDialogOpen}
              closeDialog={closeDialog}
              clientMatterFilters={clientMatterFilters}
              handleExecuteFilter={handleExecuteFilter}
              handleClearMatterFilter={handleClearMatterFilter}
            />

            <div className="flex items-center justify-between w-full gap-4 sm:hidden mb-10">
              <div className="flex w-full sm:px-0 gap-1">
                <Button
                  variant="secondary-l"
                  size="xl"
                  type="button"
                  block="true"
                  onClick={() => setShowCreateMatterModal(true)}
                  className="h-10 font-medium mr-2"
                >
                  +
                </Button>
                <span className="absolute z-10 items-center justify-center w-8 px-3 py-3 text-base font-normal leading-snug text-center bg-transparent rounded text-blueGray-300 ml-12">
                  <IoIcons.IoIosSearch />
                </span>
                <input
                  type="Search"
                  value={searchMatter}
                  placeholder="Search Matter or Client ..."
                  onKeyDown={(e) => {
                    if (e.target.value !== "") {
                      handleSearchMatterChange(e);
                      // getClientMattersFirstLoad(dispatch, companyId)
                      // console.log("cleared")
                    }
                  }}
                  onChange={(e) => {
                    setSearchMatter(e.target.value);
                    handleSearchMatterChange(e);
                  }}
                  className="relative w-full px-3 py-3 pl-8 text-sm placeholder-gray-500 bg-white border-0 rounded shadow outline-none focus:outline-none focus:ring-2 ring-blue-500 ring-offset-4"
                />
                <button
                  className="flex items-center justify-center gap-2 px-4 py-2 text-base font-medium text-white bg-green-600 border border-green-600 rounded-lg shadow"
                  onClick={() => {
                    openDialog();
                  }}
                >
                  <RiFilter2Fill />
                </button>
                {/* BURGER MENU ICON */}
                {/* <Button
									size="xs"
									variant="default-s"
									className="h-full px-2 sm:hidden"
									onClick={()=>console.log("BURGER CLICKED")}
								>
									<BiMenu size={24}></BiMenu>
								</Button> */}

                {/* Hide the archive button for mobile */}
                {
                  isMobileDevice(width) ? <></> : 
                  <Link to={AppRoutes.ARCHIVES}>
                    <Button
                      size="xs"
                      variant="default-s"
                      className="h-full gap-3 px-3 sm:hidden"
                    >
                      <span className="flex w-full gap-3 font-bold">
                        <FaIcons.FaArchive />
                      </span>
                    </Button>
                  </Link>
                }
              </div>
            </div>

            <div
              id="mobileContent"
              onScroll={(e) => handleScrollEvent(e)}
              style={{ scrollBehavior: "smooth" }}
              className={
                "overflow-y-auto px-5 sm:px-0 w-full sm:w-auto pb-10 " +
                (mattersView === "grid"
                  ? "grid grid-flow-row auto-rows-max grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5"
                  : "grid grid-flow-row auto-rows-max gap-2")
              }
            >
              {showScrollButton && width < 640 ? (
                <>
                  <div
                    className="flex scrollButtonInner"
                    onClick={() => handleScrollToTop()}
                  >
                    <BiArrowToTop
                      style={{
                        color: "white",
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <MatterContext.Provider
                value={{
                  clientMatter: clientMatterList,
                  allClientMatter: allCM,
                  recentClientMatter: recentClientMatterList,
                  loading: loading,
                  error: errorMatter,
                  view: mattersView,
                  onShowDeleteModal: handleShowDeleteModal,
                  allowDeleteMatter: allowDeleteMatter,
                  allowViewCreateUpdateMatter: allowViewCreateUpdateMatter,
                  allowOpenMatter: allowOpenMatter,
                  allowOpenFileBucket: allowOpenFileBucket,
                  allowOpenBackground: allowOpenBackground,
                  allowOpenRFI: allowOpenRFI,
                  allowOpenLabelsLibrary: allowOpenLabelsLibrary,
                  clientMatterListDateSorted: clientMatterListDateSorted,
                  isClientMatterFiltered: isClientMatterFiltered,
                  activeButton: activeButton,
                  downloadLinks: downloadLinks,
                  showCmNameOrder: showCmNameOrder,
                  setShowCmNameOrder: setShowCmNameOrder
                }}
              >
                <ClientMatters />
              </MatterContext.Provider>
            </div>

            {(activeButton === "View All" && remClientMatters?.length > 0) ||
            (activeButton === "Recently Viewed" && remRecentClientMatters?.length > 0) ? (
              // nextTokenVal && nextTokenVal !== null ? (
              <div className="flex justify-center items-center pb-4">
                <button
                  className={isLoadingData === false 
                  ? "flex hover:bg-gray-200 bg-gray-50 border-2 border-gray-700 text-gray-900 font-medium p-2 rounded cursor-pointer"
                  : "flex bg-gray-100 border-2 border-gray-200 text-gray-200 font-medium p-2 rounded cursor-wait"}
                  
                  onClick={handleLoadMore}
                  disabled={isLoadingData}
                >
                  <FaIcons.FaArrowDown className="mr-2" /> {isLoadingData === false ? "Show More" : "Loading"}
                </button>
              </div>
            ) : (<></>)}

            {/* {clientMatterList && clientMatterList.length > 15 ? ( */}
            {/* {nextToken === null ? (
				<div className="flex justify-center items-center pb-10">
				<div className="border-t border-gray-300 w-1/4 mr-4"></div>
				<p className="text-gray-700 text-lg font-semibold">All caught up!</p>
				<div className="border-t border-gray-300 w-1/4 ml-4"></div>
				</div>
			) : (
				<div className="flex justify-center items-center pb-4">
				<button
					className="flex hover:bg-gray-200 bg-gray-50 border-2 border-gray-700 text-gray-900 font-medium p-2 rounded cursor-pointer"
					onClick={handleLoadMore}
				>
					<FaIcons.FaArrowDown className="mr-2" /> Load more
				</button>
				</div>
			)} */}
            {/* ) : null} */}

            {showDeleteModal && (
              <DeleteMatterModal
                handleSave={handleDeleteModal}
                handleModalClose={handleModalClose}
              />
            )}

            {toast && (
              <ToastNotification
                showToast={toast}
                errorMatter={errorMatter}
                error={error}
                title={toastMessage}
              />
            )}

            {/*For Errors MMA1010*/}
            {toast2 && (
              <ToastNotification
                showToast={toast}
                errorMatter={errorMatter}
                error={true}
                title={toastMessage}
              />
            )}
            {showSessionTimeout && <SessionTimeout />}

            {isInitializingPortal && (
              <div
                className={`fixed flex justify-center items-center bg-white w-screen h-screen left-0 top-0 right-0 bottom-0 z-50 cursor-wait`}
              >
              <div className="relative text-center">
                <img
                  src={loadingAnimation}
                  style={{
                    height: isMobileDevice(width) ? "20rem" : "80vh",
                  }}
                  className={`m-auto`}
                  alt="We're loading your Dashboard ..."
                />

                <p
                  className={`text-black font-open-sans text-xl font-semibold`}
                  style={{
                    fontSize: isMobileDevice(width) ? "1.125rem" : "3vh",
                    lineHeight: isMobileDevice(width) ? "1.75rem" : "2vh",
                  }}
                >
                  We're loading your Dashboard ...
                </p>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
}
