import React, { useEffect, useState } from "react";
// import { pageSelectedLabels } from "./index";
import { GrClose } from "react-icons/gr";
import Button from "../../shared/Button";
import { BsFillTrashFill } from "react-icons/bs";
import { RiAddCircleLine } from "react-icons/ri";
import ToastNotification from "../toast-notification";
import { API } from "aws-amplify";

import "../../assets/styles/multiselect-custom.css";
import "../../assets/styles/filter-labels.css";

import googleLogin from "../../assets/images/gmail-print.png";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { MdClose } from "react-icons/md";

export default function FilePreviewModal({
  item,
  items,
  iframeVariables,
  setShowFilePreview
}) {
    const {
        index,
        show,
        snippetId,
        downloadLink,
        iframeLoad,
        setIframeLoad,
        fileType,
        previewAndDownloadPDF,
        previewAndDownloadFile,
        handleOpenAI,
        hoverClosePreviewFile,
        subject,
        from,
        moment,
        dateEmail,
        cc,
        to,
        preview,
      } = iframeVariables;

    const [originalImgHeight, setOriginalImageHeight] = useState(0);
    const [originalImgWidth, setOriginalImageWidth] = useState(0);
    const [objectCountRef, setObjectCountRef] = useState({
        current: 0,
        state: "hidden",
    });

    
    useEffect(() => {
        if (
          fileType.includes("image") &&
          originalImgHeight === 0 &&
          originalImgWidth === 0
        ) {
          const image = document.getElementById("imageModalCon");
          let width = image?.clientWidth;
          let height = image?.clientHeight;
          setOriginalImageHeight(height);
          setOriginalImageWidth(width);
        }
      }, [show]);

      useEffect(() => {
        console.log("Details", item)
        console.log("Details", items)
        console.log("Details", iframeVariables)
        console.log("showDetails", show)
      }, []);

  return (
    show === true &&
    // // snippetId === items.id + "-" + item.id && 
    // (
    // show === true &&
      <>
          <div
            id="filePreviewPopup"
            className="fixed inset-0 z-50 flex items-center justify-center max-w-none bg-black bg-opacity-50 overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex-col flex bg-white rounded p-8 max-w-none mx-auto overflow-hidden w-11/12 h-5/6">
            <div className="flex items-center justify-between">
                <div className="order-first">
                {items?.gmailMessageId && <img src={googleLogin} alt="" />}
                </div>
                <div className="space-x-4">
                {items.s3ObjectKey === null &&
                items.isGmailPDF &&
                items.isGmailAttachment ? (
                    <button
                    onClick={(e) =>
                        previewAndDownloadPDF(
                        items.id,
                        items.gmailMessageId ? items.gmailMessageId : null,
                        items.name
                        )
                    }
                    className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                    >
                    {`Download Email PDF`}
                    </button>
                ) : (
                    <>

                    <button
                        id="openNewTab"
                        onClick={(e) => {
                        previewAndDownloadFile(items.id);
                        }}
                        className="px-4 py-2 text-xs font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                    >
                        {`Preview on new tab`}
                    </button>
                    {fileType.includes("image") && (
                        <>
                        <button
                    className="inline-flex px-4 py-2 text-xs font-bold text-white bg-gray-500 rounded hover:bg-gray-700"
                    onClick={() => {
                            const image =
                                document.getElementById("imageModalCon");
                            let width = image.clientWidth;
                            let height = image.clientHeight;
                            image.style.width = width + 10 + "px";
                            image.style.height = height + 10 + "px";
                            }}
                        >
                            Zoom In <BiZoomIn size={11}
                    style={{
                        marginLeft: "5px",
                        marginTop: "3px",
                    }}/>
                        </button>
                        <button
                    className="inline-flex px-4 py-2 text-xs font-bold text-white bg-gray-500 rounded hover:bg-gray-700"
                    onClick={() => {
                            const image =
                                document.getElementById("imageModalCon");
                            let width = image.clientWidth;
                            let height = image.clientHeight;
                            if (originalImgHeight < height) {
                                image.style.width = width - 10 + "px";
                                image.style.height = height - 10 + "px";
                            }
                            }}
                        >
                            Zoom Out <BiZoomOut size={11}
                    style={{
                        marginLeft: "5px",
                        marginTop: "3px",
                    }}/>
                        </button>
                        </>
                    )}
                    </>
                )}

                <button
                    className="inline-flex px-4 py-2 text-xs font-bold text-white bg-red-500 rounded hover:bg-red-700"
                    type="button"
                    aria-expanded="false"
                    onClick={hoverClosePreviewFile}
                >
                    Close
                    <MdClose
                    size={11}
                    style={{
                        marginLeft: "5px",
                        marginTop: "3px",
                    }}
                    />
                </button>
                </div>
            </div>

            <div
                className="w-full h-full mt-2"
                style={{
                minHeight: "350px",
                }}
            >
                {items.isGmailPDF ? (
                <>
                    <div id={"preview_" + items.gmailMessageId}>
                    {subject !== null ? (
                        <>
                        <h2>
                            <b>{item.subject}</b>
                        </h2>
                        <hr />
                        <br />
                        <p>{`From: ${from}`}</p>
                        <p>
                            {`Date: ${moment(dateEmail).format(
                            "DD MMM YYYY, hh:mm A"
                            )}`}
                        </p>
                        <p>{`Subject: ${subject}`}</p>
                        <p>{`To: ${to}`}</p>
                        {cc && <p>{`CC: ${cc}`}</p>}
                        <p
                            className="p-2 mt-8"
                            dangerouslySetInnerHTML={{
                            __html: preview.html,
                            }}
                        ></p>
                        </>
                    ) : (
                        <>
                        <SkeletonTheme width={"300px"}>
                            <p>
                            <Skeleton count={4} />
                            </p>
                        </SkeletonTheme>
                        <p className="p-2 mt-8">
                            <Skeleton count={15} />
                        </p>
                        </>
                    )}
                    </div>
                </>
                ) : (
                <div className="h-full pb-12">
                    {/* {iframeLoad && (
                    <SkeletonTheme height={"300px"} width={"100%"}>
                        <p>
                        <Skeleton count={1} />
                        </p>
                    </SkeletonTheme>
                    )} */}

                    {fileType.includes("image") ? (
                    <div className=" overflow-auto w-full h-full">
                        <img
                        id="imageModalCon"
                        className=" m-auto block object-contain h-full w-full"
                        onLoad={() => setIframeLoad(false)}
                        src={downloadLink}
                        />
                    </div>
                    ) : (
                    <iframe
                        title={items.name}
                        src={downloadLink}
                        style={{
                        minHeight: "450px",
                        height: "100%",
                        }}
                        className="w-full "
                        onLoad={() => setIframeLoad(false)}
                    ></iframe>
                    )}
                </div>
                )}
            </div>

            </div>
          </div>
      
        
      </>
    // )
  );
}

