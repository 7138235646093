import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import momentTZ from "moment-timezone";
import { AppRoutes } from "../../constants/AppRoutes";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";
import getQueryVariable from "../../shared/getQueryVariable";
// import { useAuthLoading } from "./authLoadingContext";
import loadingAnimation from "../../assets/images/post-authentication-loading.gif";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";

const APP_DEFAULTS_QUERY = `query getDefaults {
  pages {
    id
    name
    features {
      id
      name
    }
  }
  defaultUserType
}`;

const USER_DETAILS_QUERY = `query user($id: String) {
  user(id: $id) {
    company {
      id
      name
    }
    defaultCompany {
      id
      name
    }
    email
    firstName
    lastName
    userType
    userCategory
    profilePicture
    profilePictureKey
    businessName
    preferredTimezone
    preferredDateFormat
    isTwoFactorEnabled
    hasPortalAccess
    isDeleted
    history
    organisations {
      items {
        company {
          id
          name
          createdAt
          representative {
            id
            firstName
            lastName
          }
        }
        userCategory
        userType
        hasPortalAccess
        isDeleted
      }
    }
  }
}`;

const COMPANY_USERTYPES_QUERY = `query getCompanyUserTypes($companyId: String) {
  companyAccessType(companyId: $companyId) {
    userType
  }
}`;

const USER_ACCESS_QUERY = `query getPagesAndAccess($companyId: String, $userType: UserType) {
  companyAccessType(companyId: $companyId, userType: $userType) {
    id
    access {
      id
      name
      features {
        id
        name
      }
    }
  }
}`;

const CREATE_ACCESS_MUTATION = `mutation createCompanyAccessType($companyId: String, $userType: [UserType], $access: [AccessInput]){
  companyAccessTypeCreate(
    companyId: $companyId
    userType: $userType
    access: $access
  ) {
    id
  }
}`;

const UPDATE_ACCESS_MUTATION = `mutation updateCompanyAccessType($id: String, $access: [AccessInput]) {
  companyAccessTypeUpdate(
    id: $id
    access: $access
  ) {
    id
    access {
      id
      features {
        id
      }
    }
  }
}`;

const CREATE_ACTIVITY_MUTATION = `mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
    activity: $activity
    briefId: $briefId
    clientMatterId: $clientMatterId
    companyId: $companyId
    previous: $previous
    field: $field
    current: $current
    appModule: $appModule
    rowId: $rowId
  ) {
    id
  }
}`;

const domain = window.location.hostname;

export default function PostAuthentication({
  user,
  setUser,
  userId,
  setUserId,
  getCurrentSession,
}) {
  useEffect(() => {
    console.log("Start rendering Post-Authentication ...");
    console.time("Post Authentication Duration");
  }, []);

  const [error, setError] = useState(null);
  const { width } = useWindowDimensions();
  // const { startAuthLoading, authLoading } = useAuthLoading();

  let history = useHistory();
  const nextStep = getQueryVariable("next");

  let redirectToOnboarding = false;
  // console.log("nextStep", nextStep);
  if (nextStep) {
    redirectToOnboarding = true;
  }

  useEffect(() => {
    localStorage.removeItem("SignInError");

    const initializeAuth = async () => {
      try {
        // console.log("user", user);
        // console.log("userId", userId);

        await getCurrentSession();

        if (!userId) {
          await Auth.currentAuthenticatedUser({
            bypassCache: true,
          }).then(async (cognitoUser) => {
            setUser(cognitoUser.username);
            setUserId(cognitoUser.attributes["sub"]);
          });
        }
      } catch (error) {
        console.error(
          "An error occurred during authentication initialization:",
          error
        );
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    if (userId) {
      getAccount();
    }
  }, [userId]);

  const getAccount = async () => {
    try {
      const userData = await fetchAndStoreDetailsToLocal(userId);

      const dafaults = await API.graphql({
        query: APP_DEFAULTS_QUERY,
      });

      const pages = dafaults?.data?.pages;
      const defaultUserType = dafaults?.data?.defaultUserType;

      // console.log("pages", pages);
      // console.log("defaultUserType", defaultUserType);
      // console.log("-----------");

      const companyUserTypes = await API.graphql({
        query: COMPANY_USERTYPES_QUERY,
        variables: {
          companyId: userData.user["company"]["id"],
        },
      });

      const companyAccessTypes = companyUserTypes?.data?.companyAccessType;
      const mappedCompanyAccessTypes = companyAccessTypes.map(
        (i) => i.userType
      );

      const missingUserTypes = defaultUserType.filter(
        (item) => !mappedCompanyAccessTypes.includes(item)
      );

      // console.log("Default UserTypes", defaultUserType.sort());
      // console.log("Current UserTypes", mappedCompanyAccessTypes.sort());
      // console.log("missingUserTypes", missingUserTypes);

      if (missingUserTypes?.length > 0) {
        await API.graphql({
          query: CREATE_ACCESS_MUTATION,
          variables: {
            companyId: userData.user["company"]["id"],
            access: pages,
            userType: missingUserTypes,
          },
        });
      }

      const orgs = userData?.user?.organisations?.items;
      const accessibleOrgs = orgs
        .filter(
          (item) => item?.isDeleted !== true && item?.hasPortalAccess !== false
        )
        .sort(
          (a, b) =>
            new Date(a.company.createdAt) - new Date(b.company.createdAt)
        );

      if (accessibleOrgs?.length > 0) {
        const yourCreatedOrganization = accessibleOrgs.filter(
          (item) => item?.company?.representative?.id === userId
        );

        if (yourCreatedOrganization.length > 0) {
          // console.log("Organizations you own", yourCreatedOrganization);
          selectCompany(
            yourCreatedOrganization[0].company.id,
            yourCreatedOrganization[0].company.name,
            yourCreatedOrganization[0].userType,
            yourCreatedOrganization[0].hasPortalAccess,
            pages,
            userId
          );
        } else {
          selectCompany(
            accessibleOrgs[0].company.id,
            accessibleOrgs[0].company.name,
            accessibleOrgs[0].userType,
            accessibleOrgs[0].hasPortalAccess,
            pages,
            userId
          );
        }
      } else {
        // User is deleted on all companies he is involved
        localStorage.setItem("SignInError", "User does not exist.");
        callRedirect(AppRoutes.SIGNOUT);
      }
      // });
    } catch (e) {
      setError(e?.errors?.[0]?.message ?? 'An unknown error occurred');

      console.error(e);
      localStorage.setItem(
        "SignInError",
        "Error signing in. Please try again."
      );
      callRedirect(AppRoutes.SIGNOUT);
    }
  };
  // Function to fetch and store user details into local storage
  const fetchAndStoreDetailsToLocal = async (userId) => {
    try {
      const params = {
        query: USER_DETAILS_QUERY,
        variables: { id: userId },
      };

      const userInfoResponse = await API.graphql(params);

      // console.log("userInfoResponse", userInfoResponse);
      // Extract user data from the GraphQL response
      const userData = userInfoResponse.data;
      console.log("userData", userData);

      //Add new structure of user category
      // userCategory cannot be null or blank, otherwise it will cause an error in the backend
      var generalUserCategory = hbaDomains.includes(domain)
        ? "HBA_HOMEOWNER"
        : "OWNERBUILDER";

      userData?.user?.organisations?.items?.map((x) =>
        x.userCategory === null ? x : (generalUserCategory = x.userCategory)
      );
      // Check if setUser is a function and call it with the email of the user
      // if (typeof setUser === "function") {
      //   setUser(userData.user.email);
      // }

      // Proceed only if user data and the required nested properties exist
      if (userData && userData.user && userData.user.company) {
        // Construct a key-value pair object for all user details
        const userDetailsToStore = {
          userId,
          email: userData.user.email,
          firstName: userData.user.firstName,
          lastName: userData.user.lastName,
          userCategory:
            userData.user.userCategory === null
              ? generalUserCategory
              : userData.user.userCategory,
          history: userData.user.history
            ? JSON.stringify(userData.user.history.map((u) => JSON.parse(u)))
            : null,
          profilePicture: userData.user.profilePicture,
          companyId: userData.user.company.id,
          company: userData.user.company.name,
          userType: userData.user.userType,
          hasPortalAccess: userData.user.hasPortalAccess,
          defaultCompany: userData.user.defaultCompany
            ? JSON.stringify({
                id: userData.user.defaultCompany.id,
                name: userData.user.defaultCompany.name,
              })
            : null,
          isDeleted: userData.user.isDeleted,
          preferredTimezone:
            !userData.user.preferredTimezone ||
            userData.user.preferredTimezone === "null"
              ? momentTZ.tz.guess()
              : userData.user.preferredTimezone,
          userpreferredDateFormatType:
            !userData.user.preferredDateFormat ||
            userData.user.preferredDateFormat === "null"
              ? "DD-MM-YYYY"
              : userData.user.preferredDateFormat,
        };

        // Loop through each key in userDetailsToStore and save to local storage
        Object.keys(userDetailsToStore).forEach((key) => {
          const value = userDetailsToStore[key];
          // Only save to local storage if the value is not null
          if (value !== null) localStorage.setItem(key, value);
        });

        // Return the user data
        return userData;
      }
    } catch (error) {
      console.error("Error fetching and storing user details:", error);
    }
  };

  async function updateCompanyAccessType(id, access) {
    try {
      await API.graphql({
        query: UPDATE_ACCESS_MUTATION,
        variables: {
          id,
          access,
        },
      });
    } catch (e) {
      console.error(e);
      setError(e?.errors?.[0]?.message ?? 'An unknown error occurred');

      localStorage.setItem(
        "SignInError",
        "Error signing in. Please try again."
      );
      callRedirect(AppRoutes.SIGNOUT);
    }
  }

  const selectCompany = (
    cmpId,
    cmpName,
    userType,
    hasPortalAccess,
    defaultPages,
    userId
  ) => {
    const defaultCompany = JSON.parse(localStorage.getItem("defaultCompany"));
    //Check if there is a defaultCompany so that it redirects to that portal on user post-auth
    const companyId = defaultCompany ? defaultCompany.id : cmpId;
    const companyName = defaultCompany ? defaultCompany.name : cmpName;

    localStorage.setItem("companyId", companyId);
    localStorage.setItem("company", companyName);
    localStorage.setItem("userType", userType);
    localStorage.setItem("hasPortalAccess", hasPortalAccess);

    const params = {
      query: USER_ACCESS_QUERY,
      variables: {
        companyId: companyId,
        userType: userType,
      },
    };


    API.graphql(params).then(async (ua) => {
      if (ua.data.companyAccessType.length !== 0) {
        const userAccessId = ua.data.companyAccessType[0].id;
        const userAccess = ua.data.companyAccessType[0].access;

        // console.log("userAccess", userAccess);
        // console.log("defaultPages", defaultPages);
        const stringifyAllAccess = JSON.stringify(defaultPages);
        const stringifyUserAccess = JSON.stringify(userAccess);
        localStorage.setItem("access", stringifyUserAccess);

        // Define the user types that should redirect to HBADASHBOARD
        const hbaUserCategories = [
          "HBA_ARCHITECT_SUPERINTENDENT",
          "HBA_HOMEOWNER",
        ];

        if (
          userType === "OWNER" &&
          (stringifyAllAccess !== stringifyUserAccess ||
            stringifyUserAccess === "[]")
        ) {
          // console.log("Current Owner has limited access");
          await updateCompanyAccessType(userAccessId, defaultPages);

          // console.log("call updateCompanyAccessType");
          // console.log("set access to ", stringifyAllAccess);
          localStorage.setItem("access", stringifyAllAccess);

          const userCategory = localStorage.getItem("userCategory");

          // Check if the userType is in the HBA user types
          if (hbaDomains.includes(domain)) {
            if (redirectToOnboarding) {
              callRedirect(AppRoutes.GETTINGSTARTED); // HBA ONBOARDING
            } else if (
              userCategory === null ||
              userCategory === "" ||
              userCategory === undefined
            ) {
              //if no local data of user category, get the updated one
              const params = {
                query: USER_DETAILS_QUERY,
                variables: { id: userId },
              };
              const userInfoResponse = await API.graphql(params).then(
                (result) => {
                  const userData = result.data;

                  if (hbaUserCategories.includes(userData.user.userCategory)) {
                    callRedirect(AppRoutes.HBADASHBOARD); // HBA
                  } else {
                    callRedirect(AppRoutes.DASHBOARD);
                  }
                }
              );
            } else if (hbaUserCategories.includes(userCategory)) {
              callRedirect(AppRoutes.HBADASHBOARD); // HBA
            } else {
              // callRedirect(AppRoutes.DASHBOARD); // Redirect to default dashboard
              /** Update for updated sidebar component to refresh */
              callRedirect(AppRoutes.DASHBOARD);
            }
          } else if (obaDomains.includes(domain)) {
            callRedirect(AppRoutes.OBADASHBOARD); // OBA
          } else if (
            userCategory === null ||
            userCategory === "" ||
            userCategory === undefined
          ) {
            //if no local data of user category, get the updated one
            const params = {
              query: USER_DETAILS_QUERY,
              variables: { id: userId },
            };
            const userInfoResponse = await API.graphql(params).then(
              (result) => {
                const userData = result.data;

                if (hbaUserCategories.includes(userData.user.userCategory)) {
                  callRedirect(AppRoutes.HBADASHBOARD); // HBA
                } else {
                  callRedirect(AppRoutes.DASHBOARD);
                }
              }
            );
          } else {
            if (hbaUserCategories.includes(userCategory)) {
              callRedirect(AppRoutes.HBADASHBOARD); // Redirect to default dashboard
            } else {
              /** Update for updated sidebar component to refresh */
              callRedirect(AppRoutes.DASHBOARD);
            }
          }
        } else {
          localStorage.setItem("access", stringifyAllAccess);
          const userCategory = localStorage.getItem("userCategory");

          // Check if the domain is in the HBA domains list
          if (hbaDomains.includes(domain)) {
            // If redirectToOnboarding is true, redirect to the onboarding page
            if (redirectToOnboarding) {
              callRedirect(AppRoutes.GETTINGSTARTED); // HBA ONBOARDING
            } else if (
              userCategory === null ||
              userCategory === "" ||
              userCategory === undefined
            ) {
              //if no local data of user category, get the updated one
              const params = {
                query: USER_DETAILS_QUERY,
                variables: { id: userId },
              };
              const userInfoResponse = await API.graphql(params).then(
                (result) => {
                  const userData = result.data;

                  if (hbaUserCategories.includes(userData.user.userCategory)) {
                    callRedirect(AppRoutes.HBADASHBOARD); // HBA
                  } else {
                    callRedirect(AppRoutes.DASHBOARD);
                  }
                }
              );
            }
            // If the user is an OWNERBUILDER, redirect to the default dashboard; otherwise, redirect to the HBA dashboard
            else if (hbaUserCategories.includes(userCategory)) {
              callRedirect(AppRoutes.HBADASHBOARD); // Redirect to default dashboard
            } else {
              /** Update for updated sidebar component to refresh */
              callRedirect(AppRoutes.DASHBOARD);
            }
          }
          // If the domain is in the OBA domains list, redirect to the OBA dashboard
          else if (obaDomains.includes(domain)) {
            callRedirect(AppRoutes.OBADASHBOARD); // OBA
          }
          // If the domain is not in either list, redirect to the default dashboard
          else {
            // Redirect to default dashboard
            if (hbaUserCategories.includes(userCategory)) {
              callRedirect(AppRoutes.HBADASHBOARD); // Redirect to default dashboard
            } else {
              /** Update for updated sidebar component to refresh */
              callRedirect(AppRoutes.DASHBOARD);
            }
          }
        }
      } else {
        localStorage.setItem(
          "SignInError",
          "User has no access to the portal."
        );
        callRedirect(AppRoutes.SIGNOUT);
      }
    });
  };

  const callRedirect = (route) => {
    if (route !== "/signout") {
      const activityParams = {
        query: CREATE_ACTIVITY_MUTATION,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: `User ${localStorage.getItem(
            "firstName"
          )} ${localStorage.getItem("lastName")} logged in.`,
          field: "Authentication",
          appModule: "AUTH",
        },
      };

      API.graphql(activityParams);
    }

    console.timeEnd("Post Authentication Duration");
    console.log("End of Post-Authentication.");
    console.log(`Redirecting to ${route}`);
    console.log(" - - - - - - - - - - - - - - \n\n");

    history.push(route);
    // window.location.href = route;
  };

  // Inside your function component
  return (
    <>
      <div className={`flex justify-center items-center h-screen`}>
        <div className="relative text-center">
          <img
            src={loadingAnimation}
            style={{ height: isMobileDevice(width) ? "20rem" : "80vh" }}
            className={`m-auto`}
            alt="We're loading your Dashboard ..."
          />
          {!redirectToOnboarding && (
            <p
              className={`text-black font-open-sans text-xl font-semibold`}
              style={{
                fontSize: isMobileDevice(width) ? "1.125rem" : "3vh",
                lineHeight: isMobileDevice(width) ? "1.75rem" : "2vh",
              }}
            >
              We're loading your Dashboard ...
            </p>
          )}
        </div>
      </div>

      {error && <p>Oops! Something went wrong. {error}</p>}
    </>
  );
}
