import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import BlankState from "../dynamic-blankstate";
import ReactTooltip from "react-tooltip";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
// import { matter, witness_affidavits } from "./data-source";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import { AppRoutes } from "../../constants/AppRoutes";
import ToastNotification from "../toast-notification";
import ContentEditable from "react-contenteditable";
import AccessControl from "../../shared/accessControl";
import { API } from "aws-amplify";
import Dashboard from "./dashboard";
import Button from "../../shared/Button";
import "../../assets/styles/MattersOverview.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import BlurredFeature from "../../shared/BlurredFeature";
import BlankList from "../../assets/images/RFI_Blank_List.svg";
import { getBriefListPerPersona } from "../../constants/PersonaTiles";
import { DefaultPhases } from "../../constants/Phases";
import { hbaDomains } from "../../constants/AppDomains";

export default function MattersOverview() {
  const { id } = useParams();
  const textareaRef = useRef(null);
  //console.log("matter id: ", id);

  const [client, setClient] = useState("");
  const [clientID, setClientID] = useState("");
  const [matter, setMatter] = useState("");
  const [matterID, setMatterID] = useState("");
  const [matterNumber, setMatterNumber] = useState("");
  // const [people, setPeople] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [description, setDescription] = useState("");
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [allowOpenFileBucket, setAllowOpenFileBucket] = useState(false);
  const [allowOpenRFI, setAllowOpenRFI] = useState(false);
  const [allowOpenBackground, setAllowOpenBackground] = useState(false);
  const [allowOpenMatter, setAllowOpenMattersOverview] = useState(false);
  const [allowOpenLabelsLibrary, setAllowOpenLabelsLibrary] = useState(false);
  const [allowOpenDashboard, setAllowOpenDashboard] = useState(false);
  const [allowOpenContactInfo, setAllowOpenContactInfo] = useState(false);
  const [tilesDisplaySettings, setTilesDisplaySettings] = useState({});
  const [archivedBriefs, setArchivedBriefs] = useState([]);
  const [visibleBriefsList, setVisibleBriefsList] = useState([]);
  const [defaultPhases, setDefaultPhases] = useState(DefaultPhases);
  const [blurredHeader, setBlurredHeader] = useState("");
  const [blurredSubHeader, setBlurredSubHeader] = useState("");
  const hideToast = () => {
    setShowToast(false);
  };
  const activeUserType = localStorage.getItem("userType");

  const { height, width } = useWindowDimensions();

  const contentDiv = {
    margin: "0 0 0 65px",
  };

  const mainGrid = {
    display: "grid",
    gridtemplatecolumn: "1fr auto",
  };

  const qgetClientMatterById = `
  query clientMatterById($id: ID) {
    clientMatter (id: $id) {
      id
      client {
        name
        id
      }
      matter {
        name
        id
      }
      description
      matterNumber
    }
  }`;

  // const qgetUserAccess = `
  // query companyUsers($companyId: String) {
  //   company(id: $companyId) {
  //     name
  //     users {
  //       items {
  //         id
  //         firstName
  //         lastName
  //         profilePicture
  //         clientMatterAccess(companyId: $companyId) {
  //           items {
  //             userType
  //             clientMatter {
  //               id
  //               client {
  //                 name
  //                 id
  //               }
  //               matter {
  //                 name
  //                 id
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // `;

  const mupdateClientMatter = `
  mutation updateClientMatter($description: String, $id: ID) {
    clientMatterUpdate(description: $description, id: $id) {
      id
      description
    }
  }`;

  const mUpdateDateLastOpened = `mutation updateClientMatterLastOpened($id: ID, $lastOpenedAt: AWSDateTime) {
    clientMatterUpdate(id: $id, lastOpenedAt: $lastOpenedAt) {
      id
    }
  }`;

  const mupdateClientMatterNumber = `
  mutation updateClientMatterNumber($id: ID, $matterNumber:String) {
    clientMatterUpdate(id: $id, matterNumber: $matterNumber) {
      id
      matterNumber
    }
  }`;

  const ARCHIVED_BRIEFS_QUERY = `query getArchivedBriefsByClientMatter($id: ID) {
    clientMatter(id: $id) {
      briefs(isDeleted: true) {
        items {
          id
          name
        }
      }
    }
  }`;

  const ACTIVE_BRIEFS_QUERY = `query getActiveBriefsByClientMatter($id: ID) {
    clientMatter(id: $id) {
      briefs {
        items {
          id
          name
          userTypeAccess
          phase
        }
      }
    }
  }`;

  /*
   this function was added last August 2, 2023 for the ticket MMA-1764-Dashboard: Matter number for each client/matter
   this function will generate the matter number for each client matter make sure that client and matter is not null
   or atleast have a value if there is none it will cause an error thats why there is a conditional statement that
   checks client and matter to make sure its not empty to not accidently run the code with an empty client and matter.
  */
  // const generateClientMatterNumber = async () =>{
  //   try {
  //     // if the client && matter are not yet queried do not run it / or the state of client and matter is not yet initialized
  //     if(client && matter){
  //       // This code will only run if matterNumber is empty or null
  //       if(!matterNumber){
  //         let matterNumberString;
  //         if(client.length == 1){
  //           matterNumberString = client.substring(0, 1);
  //         }
  //         else if(client.length == 2){
  //           matterNumberString = client.substring(0, 2);
  //         }
  //         else if(client.length >= 3){
  //           matterNumberString = client.substring(0, 3);
  //         }
  //         matterNumberString = matterNumberString.toUpperCase().trim() + "-" + clientID.split('-')[0] + "/" + matterID.split('-')[0];
  //         const response = await API.graphql({
  //           query: mupdateClientMatterNumber,
  //           variables: {
  //             id: id,
  //             matterNumber: matterNumberString,
  //           },
  //         });
  //         console.log("GenerateClientMatterNumber Mutation response:", response.data);
  //       }
  //     }
  //   } catch (error) {
  //     console.log("Error occurred:", error);
  //   }
  // };

  const updateClientMatterLastOpened = async (clientmatter_id) => {
    await API.graphql({
      query: mUpdateDateLastOpened,
      variables: {
        id: clientmatter_id,
        lastOpenedAt: new Date().toISOString(),
      },
    });
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  const getClientMatterById = async (clientmatter_id) => {
    setSkeletonLoad(true);

    const response = await API.graphql({
      query: qgetClientMatterById,
      variables: {
        id: clientmatter_id,
      },
    }).then((response) => {
      console.log("response from getClientMatter ", response);
      setMatterNumber(response.data.clientMatter.matterNumber);
      setClient(response.data.clientMatter.client.name);
      setClientID(response.data.clientMatter.client.id);
      setMatter(response.data.clientMatter.matter.name);
      setMatterID(response.data.clientMatter.matter.id);
      setDescription(response.data.clientMatter.description);
    });
    setSkeletonLoad(false);
  };

  // const getUserAccess = async () => {
  //   const response = await API.graphql({
  //     query: qgetUserAccess,
  //     variables: {
  //       companyId: localStorage.getItem("companyId"),
  //       id: id,
  //     },
  //   }).then((response) => {
  //     console.log(
  //       "response from getUserAccess:",
  //       response.data.company.users.items
  //     );
  //     sortPeople(response.data.company.users.items);
  //   });
  // };

  useEffect(() => {
    getClientMatterById(id);
    // getUserAccess();
    updateClientMatterLastOpened(id);
  }, []);

  async function initBriefDisplay() {
    const archivedBriefsList = await getArchivedBriefs(id);
    const activeBriefsList = await getActiveBriefs(id);

    setArchivedBriefs(archivedBriefsList);

    featureAccessFilters(archivedBriefsList, activeBriefsList);
  }

  useEffect(() => {
    initBriefDisplay();
  }, []);

  useEffect(() => {
    handleInput();

    // // generateClientMatterNumber() must be placed in the useEffect to properly get the matter and client so the function runs smoothly
    // // this code was added last August 2, 2023 for the ticket MMA-1764-Dashboard: Matter number for each client/matter
    // if(!matterNumber){
    // this part will generate the matter number of the client/matter card
    // generateClientMatterNumber();
    // }
  }, [description, width]);

  const getArchivedBriefs = async (id) => {
    const params = {
      query: ARCHIVED_BRIEFS_QUERY,
      variables: {
        id: id,
      },
    };

    const uniqueArchivedBriefs = await API.graphql(params).then(async (ab) => {
      const briefs = ab?.data?.clientMatter?.briefs?.items;

      return Array.from(new Set(briefs.map((item) => item.name)));
    });

    return uniqueArchivedBriefs;
  };

  useEffect(() => {
    console.log("Visible Briefs List:", visibleBriefsList);
  }, [visibleBriefsList]);

  const getActiveBriefs = async (id) => {
    const params = {
      query: ACTIVE_BRIEFS_QUERY,
      variables: {
        id: id,
      },
    };

    const briefsListResponse = await API.graphql(params);
    const { briefs } = briefsListResponse?.data?.clientMatter || {};

    // Run the function with the original array
    const categorizedPhases = categorizeBriefsByPhase(briefs?.items);

    // console.log("Default Phases", DefaultPhases);
    // console.log("categorizedPhases", categorizedPhases);
    if (categorizedPhases.length !== 0) {
      setDefaultPhases(categorizedPhases);
    }

    const uniqueBriefNames = new Set();

    briefs?.items?.forEach((item) => {
      if (
        item.userTypeAccess.includes(activeUserType) ||
        activeUserType === "OWNER"
      ) {
        uniqueBriefNames.add(item.name);
      }
    });

    const uniqueActiveBriefs = [...uniqueBriefNames]; // Convert Set back to Array

    return uniqueActiveBriefs;
  };

  function categorizeBriefsByPhase(briefs) {
    // Master list of all phases with orders and labels
    const allPhases = [
      {
        name: "PRECONSTRUCTION",
        label: "Pre Construction",
        order: 1,
      },
      {
        name: "CONSTRUCTION",
        label: "Construction",
        order: 2,
      },
      {
        name: "HANDOVER",
        label: "Handover",
        order: 3,
      },
    ];
    // Initialize phases object with all phases
    const phases = allPhases.reduce((acc, phase) => {
      acc[phase.name] = { ...phase, defaultBriefs: [] };
      return acc;
    }, {});

    // Process each brief to organize them into phases
    briefs.forEach((brief) => {
      brief?.phase?.forEach((phaseName) => {
        // Add the brief's name to the phase's defaultBriefs if the phase exists
        if (phases[phaseName]) {
          phases[phaseName].defaultBriefs.push(brief.name);
        }
      });
    });

    // Convert the phases object to an array
    return Object.values(phases).sort((a, b) => a.order - b.order); // Sort by order
  }

  // Start Check access control
  const featureAccessFilters = async (archivedBriefsArr, activeBriefsArr) => {
    const [
      mattersOverviewAccess,
      inboxAccess,
      tasklistAccess,
      briefsAccess,
      backgroundAccess,
      fileBucketAccess,
      RFIAccess,
      labelsLibraryAccess
    ] = await AccessControl([
      "MATTERSOVERVIEW", "INBOX", "TASKS", "BRIEFS", 
      "BACKGROUND", "FILEBUCKET", "MATTERSRFI", "LABELS"
    ]);

    // Check access control for "MATTERSOVERVIEW" feature
    if (mattersOverviewAccess.status !== "restrict") {
      const mattersOverviewFeatures = mattersOverviewAccess.data?.features;
      setAllowOpenMattersOverview(true); // Allow opening "MATTERSOVERVIEW"

      await Promise.all([
        setAllowOpenDashboard(
          mattersOverviewFeatures.includes("SHOWDASHBOARDTAB")
        ),
        setAllowOpenContactInfo(
          mattersOverviewFeatures.includes("SHOWCONTACTINFORMATIONTAB")
        ),
      ]);
    } else {
      console.log(mattersOverviewAccess.message);
      setBlurredHeader("Matters Overview is not available.");
      setBlurredSubHeader(
        "Sorry, your user type is not authorised to access Matters Overview. Kindly contact your Admin to grant you access."
      );
    }

    // Dashboard (Briefs) Tile Displays
    const tilesDisplay = {
      displayInbox: inboxAccess.status !== "restrict",
      displayTasklist: tasklistAccess.status !== "restrict",
    };
    setTilesDisplaySettings(tilesDisplay);

    if (briefsAccess.status !== "restrict") {
      // Given arrays activeBriefsArr and archivedBriefsArr
      console.log("activeBriefsArr:", activeBriefsArr);
      console.log("archivedBriefsArr:", archivedBriefsArr);

      const currUrl = window.location.hostname;
      const visibleBriefsListArr = 
      activeBriefsArr.filter(
        (name) => !archivedBriefsArr.includes(name)
      );

      //MMA 3144
      // Automatically set to HOMEOWNERBUILDER for old portal that dont have userCategory in localStorage
      if (hbaDomains.includes(currUrl)) {
        let filteredBriefsPerPersona;
        filteredBriefsPerPersona = getBriefListPerPersona(visibleBriefsListArr);
        setVisibleBriefsList(filteredBriefsPerPersona);
      } else {
        setVisibleBriefsList(visibleBriefsListArr);
      }
    } else {
      console.log(briefsAccess.message);
    }

    // Check access control for "FILEBUCKET" feature
    if (fileBucketAccess.status !== "restrict") {
      setAllowOpenFileBucket(true); // Allow opening "FILEBUCKET"
    } else {
      console.log(fileBucketAccess.message);
    }

    // Check access control for "RFI" feature
    if (RFIAccess.status !== "restrict") {
      setAllowOpenRFI(true); // Allow opening "MATTERSRFI"
    } else {
      console.log(RFIAccess.message);
    }

    // Check access control for "BACKGROUND" feature
    if (backgroundAccess.status !== "restrict") {
      setAllowOpenBackground(true); // Allow opening "BACKGROUND"
    } else {
      console.log(backgroundAccess.message);
    }

    // Check access control for "LABELS / MANAGE USERACCESS" feature
    if (labelsLibraryAccess.status !== "restrict") {
      setAllowOpenLabelsLibrary(true); // Allow opening "MANAGE USERACCESS"
    } else {
      console.log(labelsLibraryAccess.message);
    }
  };

  // function sortPeople(people) {
  //   const filteredUsers = people.filter((user) => {
  //     return user.clientMatterAccess.items.some(
  //       (clientMatterAccess) => clientMatterAccess.clientMatter.id === id
  //     );
  //   });
  //   setPeople(filteredUsers);
  // }

  // FOR DESCRIPTION
  function handleInput() {
    if (!skeletonLoad) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }

  async function handleDescription(e) {
    const currentDesc = e.target.value;
    const response = await API.graphql({
      query: mupdateClientMatter,
      variables: {
        id: id,
        description: currentDesc,
      },
    }).then((response) => {
      console.log("Update description:", response);
      setResultMessage("Successfully updated description");
      setToastError(false);
      setShowToast(true);
      setTimeout(hideToast, 4000);
    });
  }

  return (
    <>
      <div className="contentDiv block relative">
        <header className="bg-gray-100 py-10 pl-10 sm:pl-5 sticky top-0 z-30">
          <div className="flex items-center gap-2">
            <Link to={`${AppRoutes.DASHBOARD}`}>
              <div className="flex flex-nowrap items-center gap-2">
                <span>
                  <MdArrowBackIos className="text-xl font-bold" />
                </span>
                <div className="flex flex-wrap gap-2">
                  {skeletonLoad ? (
                    <>
                      <div className="flex">
                        <div>
                          <SkeletonTheme height={"28px"} width={"120px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                        <div className="mx-3">
                          <SkeletonTheme height={"28px"} width={"120px"}>
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {localStorage.getItem("cmNameOrder") === null || localStorage.getItem("cmNameOrder") === "Client/Matter"
                      ? (<>
                          <span className="text-xl font-bold">{client}</span>
                          <span className="text-xl font-extralight text-gray-500">
                            {" "}
                            {matter}
                          </span>
                        </>)
                      : (<>
                          <span className="text-xl font-bold">{matter}</span>
                          <span className="text-xl font-extralight text-gray-500">
                            {" "}
                            {client}
                          </span>
                        </>)}
                      
                    </>
                  )}
                </div>
              </div>
            </Link>
          </div>
        </header>

        <main className="lg:px-6 font-sans rounded-lg">
          {/* Matter Description */}
          <section className="mt-6 mx-6 lg:mx-0">
            {/* <div className="mb-2">
              <span className="text-2xl font-semibold text-darkGray leading-9">{matter}</span>
            </div>
            <div className="mb-8">
              <span className="text-2xl font-semibold text-mysteryGray">{client}</span>
            </div> */}
            <div className="mb-6">
              {skeletonLoad ? (
                <SkeletonTheme>
                  <Skeleton count={3} />
                </SkeletonTheme>
              ) : (
                <textarea
                  className="text-base font-normal w-full"
                  ref={textareaRef}
                  key={`{$id}-description`}
                  dir="ltr"
                  defaultValue={description}
                  placeholder={"Click to add Description"}
                  onInput={handleInput}
                  onFocus={handleInput}
                  onBlur={(e) => {
                    handleInput(e);
                    handleDescription(e);
                  }}
                  style={{ resize: "none" }}
                  spellCheck={false}
                ></textarea>
              )}
            </div>

            {width < 640 ? (
              <>
                {allowOpenFileBucket ||
                allowOpenRFI ||
                allowOpenBackground ||
                allowOpenLabelsLibrary ||
                allowOpenDashboard ||
                allowOpenContactInfo ||
                activeUserType === "OWNER" ? (
                  <div className="mb-6">
                    <div className="flex flex-wrap mb-6 sm:mb-0">
                      {/* Dashboard Button */}
                      {allowOpenDashboard || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${AppRoutes.MATTERSOVERVIEW}/${id}`}
                            className="w-full cursor-not-allowed"
                          >
                            <Button
                              size="medium"
                              variant="default-s"
                              className="w-full shadow pointer-events-none"
                            >
                              Dashboard
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Contact Information Button */}
                      {allowOpenContactInfo || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${AppRoutes.CONTACTINFORMATION}/${id}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Contact Information
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Background Button */}
                      {allowOpenBackground || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${
                              AppRoutes.BRIEFS
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Background
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* File Bucket Button */}
                      {allowOpenFileBucket || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            to={`${
                              AppRoutes.FILEBUCKET
                            }/${id}/000/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              File Bucket
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* RFI Button */}
                      {allowOpenRFI || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${
                              AppRoutes.MATTERSRFI
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              RFI List
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/** Manage User Access Button */}
                      {allowOpenLabelsLibrary || activeUserType === "OWNER" ? (
                        <div className="w-full p-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${AppRoutes.LABELS}/${id}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              Manage User Access
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Add additional buttons here with w-full p-1 */}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <div className="grid grid-cols-6 gap-x-1 mb-6 sm:mb-0">
                  {/* Dashboard Button */}

                  {allowOpenFileBucket ||
                  allowOpenRFI ||
                  allowOpenBackground ||
                  allowOpenLabelsLibrary ||
                  allowOpenDashboard ||
                  allowOpenContactInfo ||
                  activeUserType === "OWNER" ? (
                    <>
                      {allowOpenDashboard || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${AppRoutes.MATTERSOVERVIEW}/${id}`}
                            className="w-full cursor-not-allowed"
                          >
                            <Button
                              size="medium"
                              variant="default-s"
                              className="w-full shadow pointer-events-none"
                            >
                              Dashboard
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Contact Information Button */}
                      {allowOpenContactInfo || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${AppRoutes.CONTACTINFORMATION}/${id}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Contact Information
                            </Button>
                          </Link>
                        </div>
                      ) : null}
                      {/* Background Button */}
                      {allowOpenBackground || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${
                              AppRoutes.BRIEFS
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              Background
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* File Bucket Button */}
                      {allowOpenFileBucket || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            to={`${
                              AppRoutes.FILEBUCKET
                            }/${id}/000/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                            className="w-full"
                          >
                            <Button size="medium" className="w-full shadow">
                              File Bucket
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* RFI Button */}
                      {allowOpenRFI || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${
                              AppRoutes.MATTERSRFI
                            }/${id}/?matter_name=${utf8_to_b64(
                              matter
                            )}&client_name=${utf8_to_b64(client)}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              RFI List
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/** Manage User Access Button */}
                      {allowOpenLabelsLibrary || activeUserType === "OWNER" ? (
                        <div className="col-span-1">
                          <Link
                            className="py-4 sm:p-0 border-b w-full sm:border-0"
                            to={`${AppRoutes.LABELS}/${id}`}
                          >
                            <Button size="medium" className="w-full shadow">
                              Manage User Access
                            </Button>
                          </Link>
                        </div>
                      ) : null}

                      {/* Add additional buttons here with col-span-1 */}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </section>
          {/* Contact Information */}
          <div className="text-center my-6 mx-4 sm:mx-0">
            <div
              className="separator-title relative text-center text-mysteryGray font-semibold"
              style={{ fontSize: "10px", letterSpacing: "1px" }}
            >
              SUMMARY
            </div>
          </div>
          <section className="mb-6">
            {skeletonLoad ? (
              <div className="pr-6">
                {[...Array(6).keys()].map((data, index) => (
                  <div className="flex mb-5" key={index}>
                    <div className="flex flex-col flex-1 gap-1 mr-2">
                      {/* Skeleton */}
                      <SkeletonTheme>
                        <Skeleton />
                      </SkeletonTheme>
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                    <div className="flex flex-col flex-1 gap-1 mr-2">
                      <SkeletonTheme>
                        <Skeleton />
                      </SkeletonTheme>
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                    <div className="flex flex-col flex-1 gap-1 mr-2">
                      <SkeletonTheme>
                        <Skeleton />
                      </SkeletonTheme>
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                    <div className="flex flex-col gap-1 w-full lg:w-24 pt-6">
                      <SkeletonTheme height={"38px"}>
                        <Skeleton />
                      </SkeletonTheme>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {!allowOpenDashboard ? (
                  <>
                    {/*<BlurredFeature
                    header={blurredHeader}
                    subheader={blurredSubHeader}
                  >
                    <ContactInformation
                      id={id}
                      setShowToast={setShowToast}
                      setResultMessage={setResultMessage}
                      setToastError={setToastError}
                      hideToast={hideToast}
                    />
                </BlurredFeature>*/}
                    <BlankState
                      displayText={blurredSubHeader}
                      noLink={true}
                      iconDisplay={BlankList}
                    />
                  </>
                ) : (
                  <Dashboard
                    tilesDisplaySettings={tilesDisplaySettings}
                    archivedBriefsList={archivedBriefs}
                    setVisibleBriefsList={setVisibleBriefsList}
                    visibleBriefsList={visibleBriefsList}
                    initBriefDisplay={initBriefDisplay}
                    defaultPhases={defaultPhases}
                  />
                )}
              </>
            )}
          </section>
        </main>
      </div>
      {showToast && resultMessage ? (
        <ToastNotification
          title={resultMessage}
          error={toastError}
          hideToast={hideToast}
        />
      ) : null}

      {/** Test Section */}
    </>
  );
}
