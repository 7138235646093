import React, { useEffect, forwardRef, useRef, useState } from "react";
import config from "../../../aws-exports";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import { FiTrash2 } from "react-icons/fi";

import {
  timezoneFormatter,
  datePickerDateFormatter,
} from "../../../shared/userPreferredDateTime";
import "../../onboarding/onboarding.css";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

import _ from "lodash";
import { useWindowDimensions } from "../../../shared/mobileViewFunctions";

//File icons
import iconAudio from "../../../assets/images/audio.png";
import iconDocs from "../../../assets/images/NewIcons/docs.png";
import iconHtml from "../../../assets/images/html.png";
import iconImg from "../../../assets/images/NewIcons/img.png";
import iconPdf from "../../../assets/images/NewIcons/pdf.png";
import iconPpt from "../../../assets/images/NewIcons/ppt.png";
import iconText from "../../../assets/images/NewIcons/text.png";
import iconUnknown from "../../../assets/images/Unknown.png";
import iconXls from "../../../assets/images/NewIcons/xls.png";
import iconVideo from "../../../assets/images/video.png";

//Timezone and date 
import { API, Storage } from "aws-amplify";
var momentTZ = require("moment-timezone");


const choicesContainerStyle = {
  list: "flex flex-col gap-3 max-w-xl",
  listHorizontal: "flex flex-wrap gap-3 gap-y-5 max-w-xl",
  discombobulated: "flex flex-row gap-2 flex-wrap justify-center max-w-5xl",
  grid: "flex flex-wrap justify-center gap-3 mx-auto",
  upload: "",
  scale: "",
  none: "",
};

const QuestionChoice = ({ children, className, style, preset, action }) => (
  <>
    {style === "discombobulated" && (
      <span
        className="mb-6 text-center text-black font-normal"
        onClick={action}
      >
        Select All that apply
      </span>
    )}

    {style === "grid" && preset === "multiple" && (
      <span className="mb-6 text-center text-black font-normal">
        Select all that apply
      </span>
    )}

    <div
      className={`${choicesContainerStyle[style]} ${className || ""}`}
      style={{
        maxWidth: style === "grid" ? "60rem" : "",
      }}
    >
      {children}
    </div>
  </>
);

/**
 * @param {string} style - Determines the class name to be used
 * @param {string} type - Determine what type of element to render
 * @param {string} sub - The subtext of the item
 * @param {string} value - The answer of the item
 * @param {boolean} active - If the item is active, it will be highlighted
 * @param {object} linearScale - The linear scale object
 * @param {function} action - The onClick function of the item
 * @param {string} className - The additional class name of the item
 * @param {string} preset - The preset of the item (multiple, single, etc.)
 * @param {string} children - The optionText of the item
 * @returns {JSX.Element}
 */
const Item = ({
  tempStore,
  children,
  className,
  action,
  style,
  preset,
  type,
  sub,
  active,
  value,
  linearScale,
  formData,
  placeHolder,
  options,
  toggleText,
  subText,
  icons, 
}) => {
  return (
    <>
      {typeFactory("option", type, {
        placeHolder,
        tempStore,
        children,
        className,
        action,
        style,
        preset,
        type,
        sub,
        active,
        value,
        linearScale,
        formData,
        options,
        toggleText,
        subText,
        icons
      })}
    </>
  );
};

const Corequisite = ({
  active,
  children,
  style,
  action,
  value,
  type,
  configuration,
}) => {
  return (
    <React.Fragment key={style + children}>
      {active &&
        typeFactory("corequisite", type, {
          children,
          action,
          style,
          type,
          active,
          value,
          configuration,
        })}
    </React.Fragment>
  );
};

QuestionChoice.Corequisite = Corequisite;
QuestionChoice.Item = Item;

export default QuestionChoice;

//=======================================================
// Input Types Components
//=======================================================

const typeFactory = (optionType, type, props) => {
  const inputTypes = {
    button: <ButtonChoice props={props} />,
    text: <TextInput optionType={optionType} props={props} />,
    number: <NumberInput optionType={optionType} props={props} />,
    longText: <LongText optionType={optionType} props={props} />,
    "linear-scale": <LinearScale props={props} />,
    uploadBox: <UploadBox props={props} />,
    datepicker: <PickDater props={props} />,
    datepickerToggleable: <ToggleablePickDater props={props} />,
    dropdown: <DropDownInput props={props} />,
    dropdowndate: <DropDownDateInput props={props} />,
    separateDatepicker: <SeparatePickDater props={props} />,
    summary: <Table props={props} />,
    email: <EmailInput props={props} />,
  };

  return inputTypes[type];
};

function ButtonChoice({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    subText,
    icons,
  } = props;

  const choicesItemStyle = {
    list: `px-4 py-2 text-base font-semibold rounded-lg text-white max-w-xl
      ${
        // active
        //   ? "bg-blue-800"
        //   : "text-blue-800 bg-blue-50 hover:bg-blue-800 hover:text-white"
        active
          ? "bg-hbBlue"
          : "text-hbBlue border-hbBlue bg-gray-200 hover:text-white hover:bg-hbBlue focus:ring-white"
      }`,

    grid: `px-4 py-2 font-semibold rounded-lg max-w-xl
      ${
        type !== "text" && type !== "longText"
          ? "flex flex-col gap-3 items-center justify-center text-gray-800" +
            (active
              ? " w-60 h-50 bg-gray-300 border-hbBlue"
              : " w-60 h-50 border-hbBlue bg-gray-100 hover:bg-gray-300 focus:ring-white")
          : " w-60 h-50 items-center justify-center"
      }`,

    discombobulated: `px-4 py-2 text-base font-semibold rounded-lg text-white max-w-xl
      ${
        // active
        //   ? "bg-blue-800"
        //   : "text-blue-800 bg-blue-50 hover:bg-blue-800 hover:text-white"
        active
          ? "bg-hbBlue"
          : "text-hbBlue border-hbBlue bg-gray-200 hover:text-white hover:bg-hbBlue focus:ring-white"
      }`,
    listHorizontal: `px-4 py-2 text-base w-full font-semibold rounded-lg text-white max-w-xl
    ${
      active
        ? "bg-green-800"
        : "text-green-800 bg-green-50 hover:bg-green-800 hover:text-white"
    }`,
  };

  return (
    <button
      className={`${choicesItemStyle[style]} ${className || ""}`}
      onClick={action}
    >
      {style === "grid" ? (
        <div
          className="flex-col items-center gap-2 flex p-2"
          style={{ pointerEvents: "none", maxWidth: "100%" }}
        >
          <span className="text-center w-50 h-40">
            <img 
              src={icons} 
              alt="Logo" 
              className="w-full h-full"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </span>
          <span className={`text-center text-lg font-bold`} style={{ maxWidth: "100%", width: "100%" }}>
            {children}
          </span>
          <span className={`text-center text-md font-light mt-3`} style={{ maxWidth: "100%", width: "100%" }}>
            {subText}
          </span>
          {sub && (
            <span className="text-center text-gray-500 text-sm font-normal" style={{ maxWidth: "100%", width: "100%" }}>
              {sub}
            </span>
          )}
        </div>
      ) : style === "discombobulated" ? (
        <span
          className="text-base font-normal"
          style={{ pointerEvents: "none", maxWidth: "100%" }}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </button>

  );  
}

function TextInput({ optionType, props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    choice,
    placeHolder,
  } = props;
  return (
    <div
      className={
        "flex flex-col gap-2  " +
        (style === "listHorizontal" ? "flex-grow" : "")
      }
    >
      {optionType === "corequisite" ? (
        <span className="text-slate-700 text-sm">{children}</span>
      ) : (
        <span className="text-slate-700 text-base">{children}</span>
      )}
      <input
        id="inputOnboarding"
        className={`px-4 py-2 text-base rounded-lg border`}
        type="text"
        onChange={action}
        value={value || ""}
        placeholder={placeHolder}
      />
      {/* <label className="text-slate-700 text-xs mt-0">{placeHolder}</label> */}
    </div>
  );
}

function EmailInput({ optionType, props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    choice,
    placeHolder,
  } = props;
  return (
    <div
      className={
        "flex flex-col gap-2 " +
        (style === "listHorizontal" ? "flex-grow" : "")
      }
    >
      {optionType === "corequisite" ? (
        <span className="text-slate-700 text-sm">{children}</span>
      ) : (
        <span className="text-slate-700 text-base">{children}</span>
      )}
      <input
        className={`px-4 py-2 text-base rounded-lg border`}
        type="email"
        onChange={action}
        value={value || ""}
        placeholder={placeHolder}
      />
      {/* <label className="text-slate-700 text-xs mt-0">{placeHolder}</label> */}
    </div>
  );
}

function NumberInput({ optionType, props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    choice,
    placeHolder,
  } = props;
  return (
    <div
      className={
        "flex flex-col gap-2  " +
        (style === "listHorizontal" ? "flex-grow" : "")
      }
    >
      {optionType === "corequisite" ? (
        <span className="text-slate-700 text-sm">{children}</span>
      ) : (
        <span className="text-slate-700 text-base">{children}</span>
      )}
      <input
        className={`px-4 py-2 text-base rounded-lg border`}
        type="number"
        onChange={action}
        value={value || ""}
        placeholder={placeHolder}
      />
      {/* <label className="text-slate-700 text-xs mt-0">{placeHolder}</label> */}
    </div>
  );
}

function LongText({ optionType, props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
  } = props;

  return (
    <div className="flex flex-col gap-2">
      {optionType === "corequisite" ? (
        <span className="text-slate-700 text-sm">{children}</span>
      ) : (
        <span className="text-slate-700 text-base">{children}</span>
      )}
      <textarea
        className={
          "px-4 py-2 text-base rounded-lg border resize-none " +
          (style === "grid" ? "h-full w-full" : "w-full h-36")
        }
        placeholder="Enter a description..."
        type="text"
        onChange={action}
        value={value || ""}
      />
    </div>
  );
}

function LinearScale({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
  } = props;

  const sliderLabel = [
    {
      mainText: "Very Little Impact",
      subText: "My work was hardly affected",
    },
    {
      mainText: "Minor Impact",
      subText:
        "There was a slight change, but my work remained mostly unaffected",
    },
    {
      mainText: "Moderate Impact",
      subText:
        "The changes had a noticeable impact on my work, but I could adapt",
    },
    {
      mainText: "Substantial Impact",
      subText:
        "The changes significantly influenced my work and required adjustments",
    },
    {
      mainText: "Significant Impact",
      subText:
        "The changes had a major impact on my work, leading to significant adjustments",
    },
  ];

  const urgency = linearScale.urgency;
  const minRange = linearScale?.choice.minRange;
  const maxRange = linearScale?.choice.maxRange;
  /**
   * Calculates the position of the selector, tooltip, and progress bar
   * @param {number} urgencyRange - The urgency range
   * @returns {number} - Returns the position of the selector, tooltip, and progress bar
   */

  const calculatePos = (urgencyRange) => {
    return Number(((urgencyRange - 1) * 100) / (maxRange - minRange));
  };
  const selectorStyle = {
    left: `calc(${calculatePos(urgency)}% + (${
      -4 - calculatePos(urgency) * 0.25
    }px))`,
  };
  const tooltipStyle = {
    left: `calc(${calculatePos(urgency)}% + (${
      7 - calculatePos(urgency) * 0.25
    }px))`,
  };
  const progressStyle = {
    width: `calc(${calculatePos(urgency)}% + (${
      2 - calculatePos(urgency) * 0.25
    }px))`,
  };

  return (
    <div className="flex flex-col items-center gap-7 w-full">
      <div className="flex-none w-full flex flex-col gap-2 relative">
        <div
          style={selectorStyle}
          className="w-9 h-9 px-3 py-2 bg-gray-900 rounded-lg flex-col inline-flex justify-center absolute bottom-7"
        >
          <div className="text-center text-white text-xs font-semibold">
            {linearScale.urgency}
          </div>
        </div>
        <div
          style={tooltipStyle}
          className="urgencyTooltip w-3.5 h-3.5 bg-gray-900 rounded-sm absolute bottom-6"
        ></div>
        <input
          className="urgencySlider"
          type="range"
          value={linearScale.urgency}
          min={minRange}
          max={maxRange}
          step="1"
          onChange={action}
        />
        <div style={progressStyle} className="urgencyProgress"></div>
      </div>
      <div
        className="grid justify-items-center gap-5 text-center"
        style={{
          gridTemplateColumns: `repeat(${
            maxRange - (minRange - 1)
          }, minmax(0, 1fr))`,
          width: "119%",
        }}
      >
        {sliderLabel.map((label, index) => (
          <div>
            <p className=" text-lg font-semibold">{label.mainText}</p>
            <span className=" text-gray-500">{label.subText}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function UploadBox({ props }) {
  const {
    tempStore,
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    uploadBox,
    placeHolder,
  } = props;

  const [file, setFile] = useState([]);
  const [answerFile, setAnswerFile] = useState({});
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState([]);

  useEffect(() => {
    if (value === false || value === null || value === undefined) {
      setAnswerFile({});
      return;
    }
    console.log("VALUE ", value);
    setAnswerFile(value);
    console.log("Upload Value", value);
  }, [value]);

  // there is no parameter in this function i only add because i will not save this into DB
  const handleUpload = async (files , isRemove, fileItem, fileKey) => {
    // const keyArray = handleUploadToS3(files);
    const keyArray = files;
    const answerFileLength = Object.keys(answerFile).length;
    const newFileObject = keyArray.reduce(
      (acc, curr, index) => (
        (acc[`file_${answerFileLength + index + 1}`] = curr), acc
      ),
      {}
    );
    
    if(isRemove){
      action({ ...answerFile, isRemove: true, fileName:fileItem.name, fileKey: fileKey });
      uploadButtonRef.current.value = "";
    }else{
      action({ ...answerFile, ...newFileObject });
    }

    setFile([]); // if u want to save to DB then remove this
    setUploading(false);
  };

  // const handleUploadToS3 = async (file) => {
  //     Storage.configure({
  //         region: config.aws_user_files_s3_bucket_region,
  //         bucket: config.aws_user_company_assets_s3_bucket,
  //         identityPoolId: config.aws_user_pools_id,
  //     });

  //     if (file.length === 0) {
  //         return;
  //     }

  //     const progressArray = Array(file.length).fill(0);
  //     setUploadPercent(progressArray);

  //     let fileLinks = [];

  //     for (let i = 0; i < file.length; i++) {
  //         let fileItem = file[i];
  //         console.log("File Name: ", fileItem.name);
  //         console.log("File Type: ", fileItem.type);
  //         console.log("File Item: ", fileItem);

  //         let name = fileItem.name,
  //             key = `${tempStore.companyId}/onboarding-files/${Number(
  //                 new Date()
  //             )}${name
  //                 .replaceAll(/\s/g, "")
  //                 .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`;

  //         await Storage.put(key, fileItem, {
  //             contentType: fileItem.type,
  //             progressCallback(progress) {
  //                 const percentage = (progress.loaded / progress.total) * 100;
  //                 progressArray[i] = percentage;
  //                 setUploadPercent([...progressArray]);
  //             },
  //         })
  //             .then((result) => {
  //                 console.log(result);
  //                 setFile((prevFile) => prevFile.filter((prev) => fileItem !== prev));
  //                 const removedPercent = [...uploadPercent];
  //                 removedPercent.splice(i, 1);
  //                 setUploadPercent(removedPercent);
  //                 fileLinks.push({
  //                     key: key,
  //                     name: fileItem.name,
  //                     type: fileItem.type,
  //                     size: fileItem.size,
  //                 });
  //             })
  //             .catch((err) => console.log(err));
  //     }
  //     setUploading(false);
  //     return fileLinks;
  // };

  useEffect(() => {
    if (file.length !== 0 && !uploading) {
      // Set uploading to true before triggering handleUpload
      // there is a function call here (handleUpload())
      setUploading(true);
      // and to ensure files are processed one by one
      // this set state is not here please see input type factory file inside the onboarding folder to see the set state
      setFile((prevFile) => {
        // remember there is no parameter here i only add because i will not save this into DB
        handleUpload(prevFile);
        return prevFile;
      });
    }
    // uploading is not included in the dependency array
    // i only add because i will not save this into DB
  }, [file, uploading]);

  const uploadButtonRef = useRef(null);

  const handleUploadClick = () => {
    uploadButtonRef.current.click();
  };

  const handleSelectFileUpload = (e) => {
    setFile((prevFile) => [...prevFile, ...e.target.files]);
  };

  const handleDropFileUpload = (newFile) => {
    setFile((prevFile) => [...prevFile, ...newFile]);
  };

  const uploadDropArea = useRef(null);

  useEffect(() => {
    const instance = uploadDropArea.current;

    instance.addEventListener("dragover", handleDragOver);
    instance.addEventListener("drop", handleDrop);
    instance.addEventListener("dragenter", handleDragEnter);
    instance.addEventListener("dragleave", handleDragLeave);

    return () => {
      instance.removeEventListener("dragover", handleDragOver);
      instance.removeEventListener("drop", handleDrop);
      instance.removeEventListener("dragenter", handleDragEnter);
      instance.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    console.log(files);
    if (files && files.length) {
      handleDropFileUpload(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== uploadDropArea.current) setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === uploadDropArea.current) setDragging(false);
  };

  const draggingStyle = {
    true: {
      dropArea: "bg-gray-100 border-2 ",
    },
    false: {
      dropArea: "border-2",
    },
  };

  const fileProperties = {
    acceptedFileTypes: ["PNG", "JPG", "PDF"],
    maxSize: "20mb",
  };

  return (
    <div className="flex flex-col w-full gap-3 py-3 bg-white rounded-md">
      <p className=" text-md text-gray-400 font-medium">{placeHolder}</p>
      <div
        ref={uploadDropArea}
        className={`flex flex-col items-center gap-3 w-full py-5 rounded-lg ${draggingStyle[dragging].dropArea}`}
      >
        <div className="grid place-content-center p-2 rounded-full bg-gray-100">
          <HiOutlineLightningBolt size={"1.5rem"} className="text-gray-600" />
        </div>

        <p className="text-gray-400">
          <button
            onClick={handleUploadClick}
            className=" text-hbBlue font-medium"
          >
            Click to upload
          </button>
          {` or drag and drop`}
        </p>

        <p className="text-gray-400">
          {`
            ${fileProperties.acceptedFileTypes.map((types, index) =>
              index !== fileProperties.acceptedFileTypes.length - 1
                ? ` ${types}`
                : ` or ${types}`
            )}
             (Max ${fileProperties.maxSize})
          `}
        </p>

        <input
          ref={uploadButtonRef}
          type="file"
          onChange={(e) => handleSelectFileUpload(e)}
          multiple
          hidden
        />
      </div>
      <div className="flex flex-col gap-2">
        {answerFile &&
          Object.keys(answerFile)?.map((fileKey, fileKeyIndex) => (
            <UploadFileCard
              key={fileKeyIndex}
              handleUpload={handleUpload}
              fileItem={answerFile[fileKey]}
              fileKey={fileKey}
              uploadProgress={100}
            />
          ))}
        {file &&
          file?.map((fileItem, fileIndex) => (
            <UploadFileCard
              key={fileIndex}
              handleUpload={handleUpload}
              fileItem={fileItem}
              uploadProgress={uploadPercent[fileIndex]}
            />
          ))}
      </div>
    </div>
  );
}

function UploadFileCard({ fileItem, fileKey, uploadProgress, handleUpload }) {

  const removeFile = async (fileItem) => {
    handleUpload([fileItem], true, fileItem, fileKey);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const progressStyle = {
    uploading: {
      border: "border-gray-100",
      checkmark: false,
    },
    done: {
      border: "border-hbBlue",
      checkmark: true,
    },
  };

  const getFileType = (type) => {
    if (type !== null && type !== undefined) {
      return type.split("/").slice(0, -1).join("/");
    }
  };

  return (
    <div
      className={`flex gap-4 w-full p-3 rounded-md border-2 ${
        progressStyle[uploadProgress === 100 ? "done" : "uploading"].border
      } `}
    >

      {/* KJMFEDIT ICONS*/}
      <div className="w-8 h-8 grid place-content-center rounded-full bg-hbDirtyWhite">
        {/* <HiOutlineLightningBolt size={"1.2rem"} className="text-hbBlue" /> */}
        {/* {fileItem?.type.split( "/" ).pop()} */}
        {getFileType(fileItem?.type) === "image" ? ( <img src={iconImg} size={"1.2rem"}/> ) 
            : getFileType( fileItem?.type ) === "audio" ? ( <img src={iconAudio} size={"1.2rem"} /> ) 
            : getFileType( fileItem?.type ) === "video" ? (<img src={iconVideo} size={"1.2rem"} /> ) 
            : getFileType( fileItem?.type ) ===  "text" ? ( <img src={iconText} size={"1.2rem"} /> ) 
            : getFileType( fileItem?.type ) === "application" && fileItem?.type.split( "." ).pop() ===  "sheet"  ? ( <img src={iconXls} size={"1.2rem"} /> ) 
            : getFileType( fileItem?.type ) === "application" && fileItem?.type.split( "." ).pop() === "document" ? ( <img src={iconDocs} size={"1.2rem"} /> ) 
            : getFileType( fileItem?.type ) === "application" && fileItem?.type.split( "." ).pop() ===  "presentation"  ? ( <img src={iconPpt} size={"1.2rem"} /> ) 
            : getFileType( fileItem?.type ) === "application" && fileItem?.type.split( "." ).pop() === "text" ? ( <img src={iconText} size={"1.2rem"} /> ) 
            : getFileType( fileItem?.type ) === "application" && fileItem?.type.split( "/" ).pop() === "pdf" ? ( <img src={iconPdf} size={"1.2rem"} /> ) 
            : ( <img src={iconUnknown} size={"1.2rem"} /> )
      }
      </div>

      <div className="flex-1 flex flex-col gap-1">
        <p className="break-all">{fileItem?.name}</p>
        <p>{formatBytes(fileItem?.size)}</p>
        <ProgressBar percentage={uploadProgress} />
      </div>

      <div className="grid place-content-center w-5 h-5 rounded-full">
        {progressStyle[uploadProgress === 100 ? "done" : "uploading"]
          .checkmark && (
          <FiTrash2 
          onClick={() => {removeFile(fileItem)}} 
          size={"1.4rem"} className=" text-hbGray rounded-full cursor-pointer hover:text-hbBlue " />
        )}
      </div>
    </div>
  );
}

function ProgressBar({ percentage }) {
  const progressCalculator = () => {
    return (percentage ? percentage.toFixed(0) : 0) + "%";
  };

  return (
    <div className="flex items-center gap-4 w-full">
      <div className="flex-1 flex items-center h-2 rounded-lg bg-hbLightGray">
        <div
          className="bg-hbBlue h-2 rounded-lg"
          style={{ width: progressCalculator() }}
        ></div>
      </div>
      <div className="">{progressCalculator()}</div>
    </div>
  );
}

function PickDater({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
    placeHolder,
  } = props;

  const handleDateFormat = () => {
    if (configuration?.isDatePickerMonthYear) {
      return "MM/yyyy";
    }
    return datePickerDateFormatter(
      localStorage.getItem("userpreferredDateFormatType") || "MM/dd/yyyy"
    );
  };

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div
      className="relative px-4 text-base w-full"
      style={{ paddingTop: "1.4rem", paddingBottom: "1.4rem" }}
    >
      <CiCalendar
        style={{ zIndex: 2, top: "20%" }}
        className="absolute left-3 w-6 h-6 text-gray-400 opacity-70"
      />
      <input
        value={value}
        className="pl-12 pr-4 h-full absolute top-0 left-0 text-base rounded-lg w-full border"
        wrapperClassName="h-full"
        onClick={onClick}
        ref={ref}
        onChange={onChange}
        placeholder={placeHolder}
        style={{ zIndex: 1 }}
      />
    </div>
  ));

  return (
    <div className={`${choicesContainerStyle[style]}`}>
      <span className="text-slate-700 text-base">{children}</span>
      <DatePicker
        id="date-picker"
        customInput={<CustomInput />}
        selected={value && timezoneFormatter(value)}
        showMonthYearPicker={configuration?.isDatePickerMonthYear}
        dateFormat={handleDateFormat()}
        onChange={action}
      />
      {/* <DatePicker
        className="px-4 py-2.5 text-base rounded-lg w-full border"
        selected={new Date()}
        showMonthYearPicker
        dateFormat="MM/yyyy"
      /> */}
    </div>

    /* <div className={`${choicesContainerStyle[style]}`}>
          <span className="text-slate-700 text-base">{children}</span>
          <div className="flex items-center border rounded-lg w-full">
            <span className="px-4">
              <CiCalendar className="w-6 h-6 text-gray-500" />
            </span>
            <span className="w-full">
              <DatePicker
                className="py-2.5 text-base rounded-lg w-full"
                wrapperClassName="w-full"
                placeholderText="Select a date"
                selected={value && timezoneFormatter(value)}
                showMonthYearPicker={configuration?.isDatePickerMonthYear}
                dateFormat={handleDateFormat()}
                onChange={action}
              />
            </span>
          </div>
        </div> */
  );
}

function ToggleablePickDater({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
    placeHolder,
    toggleText,
  } = props;

  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    if (!isEnabled) {
      action("clear");
    }
  }, [isEnabled]);

  const handleDateFormat = () => {
    if (configuration?.isDatePickerMonthYear) {
      return "MM/yyyy";
    }
    return datePickerDateFormatter(
      localStorage.getItem("userpreferredDateFormatType") || "MM/dd/yyyy"
    );
  };

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div
      className="relative px-4 text-base w-full"
      style={{ paddingTop: "1.4rem", paddingBottom: "1.4rem" }}
    >
      <CiCalendar
        style={{ zIndex: 2, top: "20%" }}
        className="absolute left-3 w-6 h-6 text-gray-400 opacity-70"
      />
      <input
        value={value}
        className="pl-12 pr-4 h-full absolute top-0 left-0 text-base rounded-lg w-full border"
        wrapperClassName="h-full"
        onClick={onClick}
        ref={ref}
        onChange={onChange}
        placeholder={placeHolder}
        style={{ zIndex: 1 }}
      />
    </div>
  ));

  return (
    <div className={`${choicesContainerStyle[style]}`}>
      <span className="text-slate-700 text-base">{children}</span>
      {isEnabled ? (
        <DatePicker
          id="date-picker"
          customInput={<CustomInput />}
          selected={value && timezoneFormatter(value)}
          showMonthYearPicker={configuration?.isDatePickerMonthYear}
          dateFormat={handleDateFormat()}
          onChange={action}
          disabled={!isEnabled}
        />
      ) : (
        <div className="w-full">
          <div
            style={{ height: "44.8px", marginBottom: "6px" }}
            className="flex border w-full rounded-lg bg-white text-gray-400"
          >
            <span className="block my-auto ml-4 text-base">No Date</span>
          </div>
        </div>
      )}

      {/* <DatePicker
        className="px-4 py-2.5 text-base rounded-lg w-full border"
        selected={new Date()}
        showMonthYearPicker
        dateFormat="MM/yyyy"
      /> */}
      <div className="py-2 ml-1 mt-4 fixed">
        <input type="checkbox" onChange={(e) => setIsEnabled((x) => !x)} />
        <span className="ml-3">{toggleText}</span>
      </div>
    </div>

    /* <div className={`${choicesContainerStyle[style]}`}>
          <span className="text-slate-700 text-base">{children}</span>
          <div className="flex items-center border rounded-lg w-full">
            <span className="px-4">
              <CiCalendar className="w-6 h-6 text-gray-500" />
            </span>
            <span className="w-full">
              <DatePicker
                className="py-2.5 text-base rounded-lg w-full"
                wrapperClassName="w-full"
                placeholderText="Select a date"
                selected={value && timezoneFormatter(value)}
                showMonthYearPicker={configuration?.isDatePickerMonthYear}
                dateFormat={handleDateFormat()}
                onChange={action}
              />
            </span>
          </div>
        </div> */
  );
}

const qUpdateUserTimezone = `mutation updateUser($email: AWSEmail!, $id: ID!, $preferredTimezone: String) {
  userUpdate(
    id: $id
    email: $email
    preferredTimezone: $preferredTimezone
  ) {
    id
  }
}`;

const qUpdateUserDateFormat = `mutation updateUser($email: AWSEmail!, $id: ID!, $preferredDateFormat: String) {
  userUpdate(
    id: $id
    email: $email
    preferredDateFormat: $preferredDateFormat
  ) {
    id
  }
}`;

function DropDownDateInput({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
    placeHolder,
  } = props;

    //GET TIMEZONES AND DATE FORMATS
    const [dateTimeFormatOptions, setDateTimeFormatOptions] = useState(null);
    const [defaultDateFormat, setDefaultDateFormat] = useState({label: "DD-MM-YYYY (04-06-2024)", value: "DD-MM-YYYY"}) //DEFAULT IS DD-MM-YYYY

    useEffect(() => {
      if(dateTimeFormatOptions === null){
        initializeDatePreferences();
      }
    }, [dateTimeFormatOptions]);

    function convertTimezoneToLabel(timezone) {
      return timezone !== undefined || timezone !== null
        ? timezone.replace("/", " - ").replaceAll("_", " ")
        : "None";
    }
    
    function convertToCurrentTime(format, today) {
      return format + " (" + momentTZ(today).format(format) + ")";
    }
    
    const initializeDatePreferences = () => {
      
      //INITIALIZING DATE FORMAT OPTIONS
      let dateFormats = [
        "MM-DD-YYYY",
        "MM/DD/YYYY",
        "MMM DD YYYY",
        "DD-MM-YYYY",
        "DD/MM/YYYY",
        "DD MMM YYYY",
      ];
      let today = new Date();
      let formatOptions = [];
      dateFormats.forEach((format) => {
        let object = {
          label: convertToCurrentTime(format, today),
          value: format,
        };
        formatOptions = [...formatOptions, object];
      });

      console.log("formatOptions", formatOptions)
      setDateTimeFormatOptions(formatOptions);
    };

  const handleChangeDateFormat = async (event) => {
    console.log("event", event)
    var userEmail = localStorage.getItem("email");
    var userId = localStorage.getItem("userId");

    try{
      const request = await API.graphql({
        query: qUpdateUserDateFormat,
        variables: {
          id: userId, 
          preferredDateFormat: event.value,
          email: userEmail
        },
      }).then(()=>{
        localStorage.setItem(
          "userpreferredDateFormatType", event.value
        )
      });
    } catch (error) {
      console.error(error);
    }
    console.log("Date format updated to ", event.value);
  };

  // console.log(value);
  const ans = dateTimeFormatOptions?.find((option) => option.value === value);
  // console.log(ans);

  return (
    <>
    <div className={`${choicesContainerStyle[style]}`}>
      <span className="text-slate-700 text-base">{children}</span>
        <Select
          styles={{
            placeholder: (baseStyles) => ({
              ...baseStyles,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              color: "rgb(17, 24, 39)",
              opacity: "0.5",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              fontSize: "1rem",
              lineHeight: "1.5rem",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: "rgb(229, 231, 235)",
              borderRadius: "0.5rem",
              padding: "5px",
            }),
          }}
          options={dateTimeFormatOptions ? dateTimeFormatOptions : []}
          onChange={(e)=>handleChangeDateFormat(e)}
          placeholder={placeHolder}
          defaultValue={defaultDateFormat}
        />

    </div>
    </>
  );
}

function DropDownInput({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
    placeHolder,
  } = props;

    //GET TIMEZONES AND DATE FORMATS
    const [timezoneOptions, setTimezoneOptions] = useState(null);
    const [defaultTimezone, setDefaultTimezone] = useState(momentTZ.tz.guess());
    const [correctTimezone, setCorrectTimezone] = useState(true);

    useEffect(() => {
      if(timezoneOptions === null){
        initializeDatePreferences();
      }
    }, [timezoneOptions]);

    function convertTimezoneToLabel(timezone) {
      return timezone !== undefined || timezone !== null
        ? timezone.replace("/", " - ").replaceAll("_", " ")
        : "None";
    }

    const initializeDatePreferences = () => {
      //INTIIALIZING TIMEZONE OPTIONS
      let TZNames = momentTZ.tz.names();
      let TZNamesConverted = [];
      let TZOptions = [];
    
      for (let i = 0; i < TZNames.length; i++) {
        TZNamesConverted = [
          ...TZNamesConverted,
          convertTimezoneToLabel(TZNames[i]),
        ];
        let object = {
          label: TZNamesConverted[i],
          value: TZNames[i],
        };
        TZOptions = [...TZOptions, object];
      }
      setTimezoneOptions(TZOptions);
    };

  //KJMF EDIT TIMEZONE
  const handleChangeTimezone = async (event) => {
    console.log("event", event)
    var userEmail = localStorage.getItem("email");
    var userId = localStorage.getItem("userId");

    try{
      const request = await API.graphql({
        query: qUpdateUserTimezone,
        variables: {
          id: userId, 
          preferredTimezone: event.value,
          email: userEmail
        },
      }).then(()=>{
        localStorage.setItem(
          "preferredTimezone", event.value
        )
      });
    } catch (error) {
      console.error(error);
    }
    console.log("Timezone updated to ", event.value);
  };

  const handleChangeTimezoneDefault = async () => {
    var userEmail = localStorage.getItem("email");
    var userId = localStorage.getItem("userId");
    
    try{
      const request = await API.graphql({
        query: qUpdateUserTimezone,
        variables: {
          id: userId, 
          preferredTimezone: defaultTimezone,
          email: userEmail
        },
      }).then(()=>{
        localStorage.setItem(
          "preferredTimezone", defaultTimezone
        )
      });
    } catch (error) {
      console.error(error);
    }
    console.log("Timezone updated to ", defaultTimezone);

  }

  return (
    <>
    {defaultTimezone !== null || defaultTimezone !== undefined || defaultTimezone !== "" ? (
      <div className={`${choicesContainerStyle[style]}`}>
        <span className="text-slate-700 text-base">{children}</span>
        <span className="flex">
          <input type="radio" id={'radioOnboardingTD1'} value={defaultTimezone} onClick={(e)=>{setCorrectTimezone(true); handleChangeTimezoneDefault();}} checked={correctTimezone === true ? true : false}/> 
          <p className="ml-5">Yes, this is correct.</p>
        </span>
        <span className="flex">
          <input type="radio" id={'radioOnboardingTD2'} value={null} name="gender" onClick={(e)=>{setCorrectTimezone(false);}} checked={correctTimezone === true ? false : true}/> 
          <p className="ml-5">I want to use a different timezone:</p>
        </span>
        {correctTimezone === false ? (
          <Select
            styles={{
              placeholder: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1rem",
                lineHeight: "1.5rem",
                color: "rgb(17, 24, 39)",
                opacity: "0.5",
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1rem",
                lineHeight: "1.5rem",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "rgb(229, 231, 235)",
                borderRadius: "0.5rem",
                padding: "5px",
              }),
            }}
            options={timezoneOptions ? timezoneOptions : []}
            onChange={(e)=>{console.log("eee", timezoneOptions); handleChangeTimezone(e);}}
            placeholder={placeHolder}
          />
        ) : (<></>)
        }
      </div>
    ) : (<>
      <div className={`${choicesContainerStyle[style]}`}>
        <span className="text-slate-700 text-base">We couldn't determine your current location. Please select your timezone.</span>
        <span className="flex">
          <input type="radio" value={defaultTimezone} onClick={(e)=>{setCorrectTimezone(true);}} checked={correctTimezone === true ? true : false}/> 
          <p className="ml-5">I want to use a different timezone:</p>
        </span>
        {/* <span className="flex">
          <input type="radio" value={null} name="gender" onClick={()=>setCorrectTimezone(false)} checked={correctTimezone === true ? false : true}/> 
          <p className="ml-5">I want to use a different timezone:</p>
        </span> */}
          <Select
            styles={{
              placeholder: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1rem",
                lineHeight: "1.5rem",
                color: "rgb(17, 24, 39)",
                opacity: "0.5",
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1rem",
                lineHeight: "1.5rem",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "rgb(229, 231, 235)",
                borderRadius: "0.5rem",
                padding: "5px",
              }),
            }}
            options={timezoneOptions ? timezoneOptions : []}
            onChange={(e)=>{console.log("eee", timezoneOptions); handleChangeTimezone(e);}}
            placeholder={placeHolder}
          />
      </div>
    </>)}
    </>
  );
}

function SeparatePickDater({ props }) {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
  } = props;

  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [notification, setNotification] = useState(false);
  const [yearsOption, setYearsOption] = useState(
    Array.apply(0, Array(100)).map((day, index) => {
      return {
        value: moment().year() + 10 - index,
        label: moment().year() + 10 - index,
      };
    })
  );
  const [monthsOption, setMonthsOption] = useState(
    moment.months().map((month, index) => {
      return {
        value: index,
        label: month,
      };
    })
  );
  const [daysOption, setDaysOption] = useState(
    Array.apply(0, Array(31)).map((day, index) => {
      return {
        value: index + 1,
        label: index + 1,
      };
    })
  );

  useEffect(() => {
    if (
      (typeof value === "string" || (selectedYear && selectedMonth)) &&
      new Date(value) != "Invalid Date"
    ) {
      const newValue = new Date(value);
      const year = selectedYear || moment(newValue).year(),
        month = selectedMonth || moment(newValue).month();

      let numOfDays = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth();
      setDaysOption(
        Array.apply(0, Array(numOfDays)).map((day, index) => {
          return {
            value: index + 1,
            label: index + 1,
          };
        })
      );
    }
  }, [selectedYear, selectedMonth]);

  const handleDateChange = (selectedYear, selectedMonth, selectedDay) => {
    /* Checks if value exists to jump in to the statement
     * without evaluating the other conditions
     */
    if (
      value ||
      /* 0 is a number, and it is a valid month (January) */
      (typeof selectedYear === "number" &&
        typeof selectedMonth === "number" &&
        typeof selectedDay === "number")
    ) {
      const year = selectedYear || moment(value).year(),
        month =
          selectedMonth === 0 ? 0 : selectedMonth || moment(value).month(),
        day =
          selectedDay ||
          (new Date(value) != "Invalid Date" ? moment(value).date() : 1); // 1 is the default day

      return action(
        moment([year, month, day])._d === "Invalid Date"
          ? handleInvalidDate()
          : moment([year, month, day])._d.toString()
      );
    }
  };

  const handleInvalidDate = () => {
    // Reset their state to avoid getting invalid date after another attempt
    setSelectedDay();
    setSelectedMonth();
    setSelectedYear();

    // Show notification
    setNotification(true);
    setTimeout(() => {
      setNotification(false);
    }, 3000);
    return "";
  };

  const customStyling = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: "0.5rem",
      paddingBottom: "0.19rem",
      paddingTop: "0.19rem",
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      display: "none",
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontWeight: "400",
      opacity: "0.8",
    }),
  };

  const selectDefaultValue = (option, dateType) => {
    if (
      value &&
      (typeof value === "string" || new Date(value) instanceof Date)
    ) {
      return option[option?.findIndex((obj) => obj.value === dateType)];
    }
    return null;
  };

  return (
    <section className="flex flex-col gap-3 w-full">
      <span>{children}</span>
      <div className={`${choicesContainerStyle[style]} w-full`}>
        <div style={{ flex: 1 }}>
          <Select
            key={
              selectDefaultValue(yearsOption, moment(new Date(value)).year()) ||
              "yearsOption"
            }
            placeholder="Year"
            options={yearsOption}
            onChange={(e) => {
              setSelectedYear(e.value);
              handleDateChange(e.value, selectedMonth, selectedDay);
            }}
            styles={customStyling}
            defaultValue={selectDefaultValue(
              yearsOption,
              moment(new Date(value)).year()
            )}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Select
            key={selectDefaultValue(
              monthsOption,
              moment(new Date(value)).month() || "monthsOption"
            )}
            placeholder="Month"
            options={monthsOption}
            onChange={(e) => {
              setSelectedMonth(e.value);
              handleDateChange(selectedYear, e.value, selectedDay);
            }}
            styles={customStyling}
            defaultValue={selectDefaultValue(
              monthsOption,
              moment(new Date(value)).month()
            )}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Select
            key={
              selectDefaultValue(daysOption, moment(new Date(value)).date()) ||
              "daysOption"
            }
            placeholder="Day"
            options={daysOption}
            onChange={(e) => {
              setSelectedDay(e.value);
              handleDateChange(selectedYear, selectedMonth, e.value);
            }}
            styles={customStyling}
            defaultValue={selectDefaultValue(
              daysOption,
              moment(new Date(value)).date()
            )}
          />
        </div>
      </div>
      {notification && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="w-full px-3 py-4 flex items-center bg-gradient-to-r to-red-300 from-red-200 rounded-xl shadow gap-5"
        >
          <BsFillInfoCircleFill className="w-4 h-4" />
          <h1 className="font-medium">Invalid Date</h1>
        </motion.div>
      )}
    </section>
  );
}

const Table = ({ props }) => {
  const {
    children,
    className,
    action,
    style,
    preset,
    type,
    sub,
    active,
    value,
    linearScale,
    configuration,
    formData,
  } = props;

  const { width } = useWindowDimensions();

  function getDescColWidth() {
    if (width > 965.263) {
      return width - 900.263;
    } else {
      return 200;
    }
  }

  const newFormData = [];

  /* Remove email and progress from formData and reconstruct
   * data to maintain order
   **/
  for (let data in formData) {
    if (data !== "Email" && data !== "Progress") {
      newFormData.push(formData[data]);
    }
  }
  newFormData.sort((a, b) => a?.order - b?.order);

  return (
    <div
      className="overflow-y-scroll no-scrollbar flex flex-col border-2 border-gray-300 shadow-lg rounded-lg"
      style={{ maxHeight: "30rem" }}
    >
      {/* START OF TABLE */}
      {width > 860 && (
        /* DESKTOP VERSION */
        <DesktopTable
          formData={newFormData}
          getDescColWidth={getDescColWidth}
        />
      )}
    </div>
  );
};

/* DESKTOP VERSION */
function DesktopTable({ formData, getDescColWidth }) {
  return (
    <div className={"flex h-full w-full flex-col gap-1"}>
      <table className="min-w-full divide-y divide-gray-200 rounded-md text-xs shadow">
        <thead className="z-20 rounded-tl-md bg-white border-b border-gray-300 shadow-sm sticky top-0">
          <tr>
            <th className="w-2/12 whitespace-nowrap rounded-tl-md px-0 py-0 text-center font-medium text-gray-400">
              No.
            </th>
            <th className="whitespace-nowrap px-2 py-4 text-center font-medium text-gray-400">
              Description of Background
            </th>
            <th className="w-3/12 whitespace-nowrap rounded-tr-md px-2 py-4 text-center xl:w-2/12 font-medium text-gray-400">
              Label
            </th>
          </tr>
        </thead>
        <tbody>
          {console.log(formData)}
          {Object.keys(formData).length > 0
            ? Object.entries(formData).map((key, value) => (
                <tr key={value} className="border-b-2">
                  <td className="align-top">
                    <div className="flex flex-row items-center justify-center gap-2 py-5">
                      <div className="flex flex-row items-center">
                        <span
                          className="text-sm font-medium"
                          style={{ marginTop: "0.07rem" }}
                        >
                          {value + 1}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    className="h-full p-3 text-justify align-top"
                    // style={{
                    //   minWidth: getDescColWidth(),
                    //   maxWidth: getDescColWidth(),
                    // }}
                  >
                    <div
                      className="w-full rounded-md p-2.5 flex flex-col gap-3"
                      style={{
                        cursor: "auto",
                        outlineColor: "rgb(204, 204, 204, 0.5)",
                        outlineWidth: "thin",
                        width: "100%",
                      }}
                      suppressContentEditableWarning
                    >
                      <section className="w-full flex flex-col gap-2">
                        <h1 className="font-medium text-gray-500 opacity-80">
                          Question:
                        </h1>
                        <p>{key[1]?.headerText}</p>
                      </section>
                      <section className="w-full flex flex-col gap-2">
                        <h1 className="font-medium text-gray-500 opacity-80">
                          Answer:
                        </h1>
                        <p>
                          {key[1]?.answers?.map((answer) =>
                            answer.answer instanceof Date
                              ? answer.answer.toDateString()
                              : answer.answer
                          )}{" "}
                        </p>
                      </section>
                    </div>
                  </td>
                  <td className="flex flex-col gap-2 px-3 py-3">
                    <span
                      className="bg-gray-100 rounded-md text-xs font-normal px-2 py-1"
                      style={{
                        width: "fit-content",
                      }}
                    >
                      Mirrored Email Page
                    </span>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
}
