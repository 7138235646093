import React, { useState, useParams, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import momentTZ from "moment-timezone";
import { dateFormatter } from "../../shared/userPreferredDateTime"; // Replace with the correct path
import { AiOutlineDownload } from "react-icons/ai";
import CreatableSelect from "react-select/creatable";
import { API, Storage } from "aws-amplify";

const MobileTableInfo = ({
  background,
  style,
  readMoreState,
  isReadMoreExpanded,
  setSaving,
  getRowLabels,
  handleDescContent,
  handleReadMoreState,
  handleChangeDesc,
  handleSaveDesc,
  handleSnippetEmail,
  virtualRows,
  rows,
  matter_id,
  briefId,
  isSiteDiary,
  handleChangeLabel,
  autoAdjustAllRow,
  tableContainerRef
}) => {
  const [openMoreLabels, setOpenMoreLabels] = useState(false);
  const [openMoreLabelsIndex, setOpenMoreLabelsIndex] = useState(null);
  const [labels, setLabels] = useState(null);
  var moment = require("moment");
  const defaultColumnIds = [0, 1, 2, 3, 4];

  const mUpdateBrief = `
  mutation tagBriefToBackground($id: ID, $briefs: [BriefInput]) {
    backgroundUpdate(id: $id, briefs: $briefs) {
      id
    }
  }
  `;

  const BACKGROUND_TABLE_QUERY = `
  query getBackgroundTable($clientMatterId: ID!) {
      backgroundTable(clientMatterId: $clientMatterId) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
          presets {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }
  `;

  const mCreateBrief = `
  mutation createLabel($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
      id
      name
      date
      createdAt
    }
  }
  `;

  const mCreateActivity = `
  mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
    activityCreate(
      activity: $activity
      briefId: $briefId
      clientMatterId: $clientMatterId
      companyId: $companyId
      previous: $previous
      field: $field
      current: $current
      appModule: $appModule
      rowId: $rowId
    ) {
      id
    }
  }`;

  const mCreateBackground = `
  mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
    backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
      id
      createdAt
      date
      description
      order
      briefs {
        id
        name
      }
    }
  }
    `;

    const BACKGROUNDFILE_TAG_MUTATION = `
    mutation tagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
        backgroundFileTag(backgroundId: $backgroundId, files: $files) {
          id
        }
      }
    `;

  const listBriefs = `
    query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
      clientMatter(id: $id) {
        briefs(limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            date
            order
            createdAt
            createdBy {
              id
              firstName
              lastName
            }
            updatedAt
            updatedBy {
              id
              firstName
              lastName
            }
            labelId
          }
        }
      }
    }
    `;

  const getLabels = async () => {
    
    var labelsList2 = [];

    const params = {
      query: listBriefs,
      variables: {
        id: matter_id,
        limit: 100,
        nextToken: null,
      },
    };

    

    await API.graphql(params).then((brief) => {
      const briefsList = brief.data.clientMatter.briefs.items;
      for (var i = 0; i < briefsList.length; i++) {
        labelsList2 = [
          ...labelsList2,
          { label: briefsList[i].name, value: briefsList[i].id },
        ]; //name and briefid
      }

      setLabels(labelsList2);
      
    });
  };

  useEffect(() => {
    if(labels===null){
      getLabels();
    }
  }, [labels]);

  const tagLabel = async (evt, action, bgidd, content, briefs) => {
    const origBriefs = briefs;
    console.log(action);
    if (action.action === "select-option") {
      // Update Briefs Row
      console.log(origBriefs);
      var updatedBriefsArr = evt.map(function (item) {
        return { id: item.value, name: item.label };
      });
      // Tag Briefs to the old background
      await API.graphql({
        query: mUpdateBrief,
        variables: {
          id: bgidd,
          briefs: updatedBriefsArr,
        },
      }).then(async (result) => {
        console.log("res tagged brief", result);
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: "updated the background briefs",
            field: "Brief",
            current: updatedBriefsArr,
            previous: origBriefs,
            appModule: "BACKGROUND",
            rowId: bgidd,
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });
      });

      // Optimized version of the code
      //var filterBriefArr = evt.map(item => item.value).join('');
      const selectedItem = action.option;

      if (briefId !== selectedItem.value) {
        const tempBriefDetails = [
          { id: selectedItem.value, name: selectedItem.label },
        ];
        console.log(tempBriefDetails);

        const createBackgroundRow = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: tempBriefDetails,
            comments: "",
            description: content.description,
            date: content.date,
          },
        });

        console.log(createBackgroundRow);

        // Tag Briefs to the new background row
        // console.log(updatedBriefsArr);
        // await API.graphql({
        //   query: mUpdateBrief,
        //   variables: {
        //     id: createBackgroundRow.data.backgroundCreate.id,
        //     briefs: updatedBriefsArr,
        //   },
        // }).then((result) => {
        //   console.log("res tagged brief", result);
        // });

        // Tag files to background

        const arrFileResult = content.files.items.map(({ id }) => ({
          id: id,
        }));

        await API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: createBackgroundRow.data.backgroundCreate.id,
            files: arrFileResult,
          },
        }).then((result) => {
          console.log("res tagged files", result);
        });
      }
    } else if (action.action === "remove-value") {
      // Update Briefs Row
      var updatedBriefsArr = evt.map(function (item) {
        return { id: item.value, name: item.label };
      });

      await API.graphql({
        query: mUpdateBrief,
        variables: {
          id: bgidd,
          briefs: updatedBriefsArr,
        },
      }).then((result) => {
        console.log("res tagged brief", result);
      });
    } else if (action.action === "create-option") {
      console.log("Value for created labels", action.option.value);
      console.log("Value for created matter", matter_id);

      if (isSiteDiary) {
        let removeLabels = [
          "Progress of Work",
          "Project Location",
          "Builder Details",
          "Contract Details",
        ];
        if (
          removeLabels.includes(action.option.label) ||
          removeLabels.includes(action.option.value)
        ) {
          alert("You can't put that as a label");
          return;
        }
      }
      await API.graphql({
        query: mCreateBrief,
        variables: {
          clientMatterId: matter_id,
          name: action.option.value,
          date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
          order: 0,
          columnIds: defaultColumnIds,
        },
      }).then(async (r) => {
        console.log("Create Labels - ", r);
        const newLabelId = r.data.briefCreate.id;
        const newLabelName = r.data.briefCreate.name;

        const tempBriefDetails = [{ id: newLabelId, name: newLabelName }];

        const backgroundTable = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj.presets.filter((preset) => preset.id !== newLabelId),
                { id: newLabelId, name: newLabelName },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });

          console.log("updateBriefTable", updateBriefTable);
        }

        var updatedBriefsArr = evt.map(function (item) {
          return { id: item.value, name: item.label };
        });

        await API.graphql({
          query: mUpdateBrief,
          variables: {
            id: bgidd,
            briefs: updatedBriefsArr,
          },
        }).then((result) => {
          console.log("res tagged brief", result);
        });

        const createBackgroundRow = await API.graphql({
          query: mCreateBackground,
          variables: {
            briefs: tempBriefDetails,
            comments: "",
            description: content.description,
            date: content.date,
            files: content.files,
          },
        });

        // Tag Briefs to the new background row
        await API.graphql({
          query: mUpdateBrief,
          variables: {
            id: createBackgroundRow.data.backgroundCreate.id,
            briefs: updatedBriefsArr,
          },
        }).then((result) => {
          console.log("res tagged brief", result);
        });

        // Tag files to background

        const arrFileResult = content.files.items.map(({ id }) => ({
          id: id,
        }));

        await API.graphql({
          query: BACKGROUNDFILE_TAG_MUTATION,
          variables: {
            backgroundId: createBackgroundRow.data.backgroundCreate.id,
            files: arrFileResult,
          },
        }).then((result) => {
          console.log("res tagged files", result);
          getLabels();
        });
      });
    }
    // getBackground("Tag Label get Background");
    // autoAdjustAllRow();
  };

  console.log("virtualRows mobile >>>", virtualRows);

  return (
    <div
      style={{ overflowY: 'auto', height: '800px', position: 'relative' }}
      ref={tableContainerRef}
    >
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const item = row.original;
        const index = row.index;

        return (
          <div
            // key={item.id}
            // className="flex flex-row w-full px-5"
            // style={{
            //   ...style,
            //   width: "100%",
            //   borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
            //   borderBottomStyle: "dashed",
            //   paddingTop: index === 0 ? 0 : 20,
            //   paddingBottom: 20,
            //   maxHeight: "350px",
            // }}
            key={virtualRow.key}
            data-index={virtualRow.index}
            ref={(ref) => virtualRow.measureElement(ref)}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 'auto',
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
              borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
              borderBottomStyle: "dashed",
              paddingTop: index === 0 ? 0 : 20,
              paddingBottom: 20,
              paddingLeft: 10,
            }}
          >
            <p className="font-semibold text-cyan-400">{index + 1}</p>
            <div className="ml-2 overflow-y-auto">
              {/* Date */}
              <p className="font-medium text-cyan-400">
                {item.date !== null && item.date !== ""
                  ? dateFormatter(item.date)
                  : "No date"}
              </p>
              {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
              <p
                id={item.id + ".desc"}
                className="absolute text-red-200 invisible pointer-events-none opacity-0"
                style={{
                  top: -10000,
                  zIndex: -1000,
                  marginRight: "20px",
                  wordBreak: "break-word",
                }}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></p>

              {/* Description */}
              <p
                id={item.id + ".descM"}
                className={`overflow-y-auto ${
                  !isReadMoreExpanded(item.id) ? "line-clamp-6" : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
                style={{
                  wordBreak: "break-word",
                  outlineColor: "rgb(204, 204, 204, 0.5)",
                  outlineWidth: "thin",
                }}
                contentEditable={true}
                suppressContentEditableWarning
                onClick={(event) => {
                  handleDescContent(event, item.description, item.id, index);
                  setSaving(true);
                }}
                onFocus={() => {
                  setSaving(true);
                  //To auto expand the div when editing
                  if (readMoreState.includes(item.id) === false) {
                    handleReadMoreState(item.id);
                  }
                }}
                onInput={(event) => handleChangeDesc(event, index)}
                onBlur={(e) => {
                  handleSaveDesc(
                    e,
                    item.description,
                    item.date,
                    item.id,
                    index
                  );
                  setSaving(false);
                }}
              ></p>

              {/* Read More Button */}
              {item.description?.length > 70 && (
                <button
                  id={item.id + ".descButton"}
                  className="text-cyan-400 mb-2"
                  onClick={() => {
                    handleReadMoreState(item.id);
                  }}
                >
                  {isReadMoreExpanded(item.id)
                    ? "hide..."
                    : "show all..."}
                </button>
              )}

              {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
              <p
                id={item.id + ".files"}
                className="absolute text-red-200 invisible pointer-events-none opacity-0 break-words"
                style={{
                  top: -10000,
                  zIndex: -1000,
                  marginRight: "20px",
                  lineHeight: "30px",
                  wordBreak: "break-word",
                }}
              >
                {item?.files?.items?.map((file) => (
                  <button
                    key={file.id}
                    className="font-extralight text-sm text-red-400 border rounded-lg px-2 mr-2 my-1"
                    onClick={() => handleSnippetEmail(file)}
                  >
                    {file.name}&nbsp;
                    <AiOutlineDownload className="text-gray-400 text-sm cursor-pointer inline-block" />
                  </button>
                ))}
              </p>

              {/* Files List */}
              <p
                className={
                  (isReadMoreExpanded(item.id) ? "" : "line-clamp-1") +
                  " break-words mb-5"
                }
                style={{
                  lineHeight: "30px",
                  wordBreak: "break-word",
                }}
              >
                {item.files.items.map((file) => (
                  <button
                    key={file.id}
                    className="font-extralight text-sm focus:text-cyan-400 focus:border-cyan-400 text-gray-400 border rounded-lg px-2 my-1"
                    onClick={() => handleSnippetEmail(file)}
                  >
                    {file.name}&nbsp;
                    <AiOutlineDownload className="text-sm cursor-pointer inline-block" />
                  </button>
                ))}
              </p>

              {/* Labels List */}
              <div className="">
                <button
                  className="pb-6 "
                  onClick={(e) => {
                    setOpenMoreLabels(!openMoreLabels);
                    setOpenMoreLabelsIndex(index);
                  }}
                >
                  <div
                    id={"label-cell-selected-no-" + index}
                    className="flex"
                  >
                    
                    {item?.briefs &&
                      item?.briefs?.length !== 0 &&
                      item?.briefs?.map((elem, innerIndex) =>
                        innerIndex < 3 ? (
                          <span
                            key={innerIndex}
                            className="mx-1 bg-gray-200 rounded-sm text-xs mt-1 block"
                            style={{
                              gap: "8px",
                              padding: "4px 8px",
                              backgroundColor: "#F3F3F3",
                              borderRadius: "5px",
                            }}
                          >
                            {getRowLabels(item?.briefs)[innerIndex].label}
                          </span>
                        ) : null
                      )}
                    {item?.briefs?.length > 3 && (
                      <span
                        className="mx-1 bg-gray-300 rounded-sm text-xs mt-1 z-10 block"
                        style={{
                          gap: "8px",
                          padding: "4px 8px",
                          borderRadius: "5px",
                        }}
                      >
                        +{item?.briefs?.length - 3}
                      </span>
                    )}
                  </div>
                </button>

                {openMoreLabels && openMoreLabelsIndex === index && (
                  // Move the modal outside of the button, but still inside the parent div
                  <div
                    key={index}
                    className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-20"
                  >
                    <div
                      className="w-full mx-5 max-h-96 p-3 flex flex-col gap-1 rounded-md  bg-white z-50"
                      style={{
                        boxShadow:
                          "0px 3px 38px 0px rgba(0,0,0,0.15), 0px 6px 12px 0px rgba(0,0,0,0.18)",
                      }}
                    >
                      <p className="px-1 py-1 text-xs text-gray-400 font-semibold border-b border-gray-500">
                        Labels
                      </p>
                      {/* {item?.briefs &&
                        item?.briefs?.length !== 0 &&
                        item?.briefs?.map((elem, innerIndex) => (
                          <span
                            key={innerIndex}
                            className="text-s w-full py-1 border-b border-dashed border-gray-300 truncate"
                          >
                            {getRowLabels(item?.briefs)[innerIndex].label}
                          </span>
                        ))} */}
                      {/* Add a close button or any other UI elements as needed */}
                      <div
                        className="z-30"
                      >
                        {/* {item?.briefs &&
                        item?.briefs?.length !== 0 &&
                        item?.briefs?.map((elem, innerIndex) => (
                          <span
                            key={innerIndex}
                            className="text-s w-full py-1 border-b border-dashed border-gray-300 truncate"
                          >
                            {getRowLabels(item?.briefs)[innerIndex].label}
                          </span>
                        ))}  */}
                        <CreatableSelect 
                          isMulti 
                          isClearable={
                            false
                          }
                          isSearchable
                          openMenuOnClick={
                            true
                          }
                          options={labels
                            ? labels.filter(
                                (
                                  elem
                                ) => {
                                  return (
                                    item.briefs
                                      ?.map(
                                        (
                                          item
                                        ) =>
                                          item.name
                                      )
                                      .indexOf(
                                        elem.label
                                      ) <
                                    0
                                  );
                                }
                              )
                            : []} 
                            defaultValue={() =>
                              getRowLabels(
                                item.briefs
                                  ? item.briefs
                                  : null
                              )
                            }
                            onChange={(e, action) => {
                              console.log(e);
                              if (e.length > 0) {
                              	item.briefs = e.map((sel) => {
                              		return {id: sel.value, name: sel.label};
                              	});
                              } else {
                                item.briefs =	[];
                              }
                              tagLabel(e,	action, item.id, item, item.briefs);
                              handleChangeLabel();
                            }}
                        />
                        {/* <CreatableSelect
                          className="w-full text-sm bg-white outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring z-45"
                          defaultValue={() =>
                            getRowLabels(
                              item.briefs
                                ? item.briefs
                                : null
                            )
                          }
                          menuPortalTarget={
                            document.body
                          }
                          options={
                            labels
                              ? labels.filter(
                                  (
                                    elem
                                  ) => {
                                    return (
                                      item.briefs
                                        ?.map(
                                          (
                                            item
                                          ) =>
                                            item.name
                                        )
                                        .indexOf(
                                          elem.label
                                        ) <
                                      0
                                    );
                                  }
                                )
                              : []
                          }
                          isClearable={
                            false
                          }
                          isSearchable
                          openMenuOnClick={
                            true
                          }
                          isMulti
                          styles={{
                            container:
                              (
                                base
                              ) => ({
                                ...base,
                                zIndex:
                                  "100",
                              }),
                            
                            valueContainer:
                              (
                                base
                              ) =>
                                ({
                                  ...base,
                                  flex: "none",
                                  border:
                                    "none",
                                  outline:
                                    "none",
                                  boxShadow:
                                    "none",
                                  paddingLeft:
                                    "1px",
                                }),
                            indicatorsContainer:
                              () => ({
                                display:
                                  "none",
                              }),
                            menu: (
                              base
                            ) => ({
                              ...base,
                              marginTop:
                                "30px",
                              border:
                                "none",
                              outline:
                                "none",
                              boxShadow:
                                "none",
                              borderLeft:
                                "1px solid rgb(209 213 219)",
                              borderRight:
                                "1px solid rgb(209 213 219)",
                              borderBottom:
                                "1px solid rgb(209 213 219)",
                              borderRadius:
                                "2px",
                              zIndex:
                                "900",
                            }),
                            menuList:
                              (
                                base
                              ) => ({
                                ...base,
                                paddingBottom:
                                  "0px",
                                zIndex:
                                  "900",
                              }),
                            option:
                              (
                                base
                              ) => ({
                                ...base,
                                borderBottom:
                                  "1px solid rgb(229 231 235)",
                                zIndex:
                                  "900",
                              }),
                          }}

                        /> */}
                      </div>
                      <button
                        onClick={() => {
                          setOpenMoreLabels(false);
                          setOpenMoreLabelsIndex(null);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileTableInfo;
