import { API } from "aws-amplify";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DropdownSelect from "react-dropdown-select";
import { toast } from "react-hot-toast";
import { useRootClose } from "react-overlays";
import CreatableSelect from "react-select/creatable";
// import { useWindowDimensions } from "../../shared/mobileViewFunctions";
// import ToastNotification from "../toast-notification";
// import Loading from "../loading/loading";
import Button from "../../shared/Button";
import EmailParser from "../../shared/EmailParser";
// import { Base64 } from "js-base64";
// import html2pdf from "html2pdf.js";
import { useMsal } from "@azure/msal-react";
import { AiFillFolderAdd, AiOutlineCheckCircle } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import {
  BiArrowBack,
  BiCheck,
  BiCopy,
  BiPaste,
  BiPencil,
} from "react-icons/bi";
import { BsPeopleFill, BsThreeDots } from "react-icons/bs";
import { FiArchive, FiUserCheck } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";
import { RiNumbersFill } from "react-icons/ri";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  // dateFormatter,
  dateTimeNoSecondsFormatter,
  // timeFormatter,
} from "../../shared/userPreferredDateTime";
import AddClientMatter from "./addClientMatterModal";
import ActionButtons from "./action-buttons";
import arrowUp from "../../assets/images/arrow-up.svg";
import arrowDown from "../../assets/images/arrow-down.svg";
import googleLogin from "../../assets/images/gmail-print.png";
import "../../assets/styles/Inbox.css";
import Select, { StylesConfig } from 'react-select';
// import ScrollToTop from "react-scroll-to-top";

// import {
//   CustomMenuList,
//   CustomValueContainer,
// } from "./custom-select-components";
import { BsFillPeopleFill } from "react-icons/bs";
import { CiCircleAlert } from "react-icons/ci";
import { MdDescription, MdPersonAdd } from "react-icons/md";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from "react-virtualized";
// import { AbsolutePosition } from "yjs";
import isMSdoc from "../../shared/msFileTypeChecker";
// import { BsJournalMedical } from "react-icons/bs";
//DropDown Button
// import CreatableSelect from "react-select/creatable";

import TableUnsavedWebEmailList from "./table-info-unsaved-components/web/unsaved-email-list";
import TableUnsavedWebEmailPreview from "./table-info-unsaved-components/web/unsaved-email-preview";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";
import debounce from "lodash/debounce";
import { getBriefListPerPersonaDropDown } from "../../constants/PersonaTiles";

import { GetUserCM, ListUsers } from "../../shared/graphql/queries";
import { FaExpandAlt } from "react-icons/fa";

var moment = require("moment");

// GRAPHQL QUERIES
// HERE
// const qUserClientMatter = `query getUserClientMatter($companyId: String, $userId: String) {
//   user (id: $userId) {
//     clientMatterAccess (companyId: $companyId, limit: 15) {
//       items {
//         userType
//         clientMatter {
//           id
//           client {
//             id
//             name
//           }
//           matter {
//             id
//             name
//           }
//         }
//       }
//     }
//   }
// }`;

const qGetContacts = `query companyUsers($companyId: String) {
  company(id: $companyId) {
    name
    users {
      items {
        id
        email
      }
    }
  }
}`;

const listBriefs = `query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs{
      items {
        id
        name
        userTypeAccess
        date
        order
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        backgrounds(limit: 50, sortOrder: ORDER_ASC) {
          items {
            description
          }
        }
      }
    }
  }
}`;

const BACKGROUND_TABLE_QUERY = `query getBackgroundTable($clientMatterId: ID!) {
  backgroundTable(clientMatterId: $clientMatterId) {
    id
    columns {
      id
      accessorKey
      headerText
      enabled
      type
      optionsText
      order
      presets {
        id
        name
      }
    }
    createdAt
    updatedAt
  }
}`;

const COMPANY_USERS_QUERY = `query companyUsers($id: String!){
  company(id: $id){
    users {
      items {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
}`;

const COMPANY_TEAMS_QUERY = `query getTeamsByCompany($id: String) {
  company(id: $id) {
    teams {
      items {
        id
        name
        members {
          items {
            userType
            user {
              id
              firstName
              lastName
              userType
              profilePicture
            }
          }
        }
      }
    }
  }
}`;

const TEAM_MEMBERS_QUERY = `query getTeamMembers($id: ID) {
  team(id: $id) {
    id
    name
    members {
      items {
        user {
          id
          firstName
          lastName
          userType
          profilePicture
        }
      }
    }
  }
}`;

// const contactsQuery = `query getUserByEmail($companyId: String, $email: String) {
//   userByEmail(companyId: $companyId, email: $email) {
//     clientMatterAccess(companyId: $companyId) {
//       items {
//         clientMatter {
//           id
//           client {
//             id
//             name
//           }
//           matter {
//             id
//             name
//           }
//         }
//       }
//     }
//   }
// }`;

const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
  gmailMessage(id: $id) {
    payload {
      content
    }
    threadId
  }
}`;

const qOutlookGetMessagePayload = `query qOutlookGetMessagePayload($id: ID) {
  outlookMessage(id: $id) {
    payload {
      content
    }
    threadId
  }
}`;

const qGmailGetFileDownloadLink = `query getAttachmentDownloadLink($id: String) {
  gmailAttachment(id: $id) {
    downloadURL
    s3ObjectKey
    type
  }
}`;

//QUERIES
const qGetOutlookMessage = `
  query getOutlookMessage($id: ID){
    outlookMessage(
      id: $id
      ) {
      assignee
      bcc
      cc
      attachments {
        items {
          id
          details
          name
          s3ObjectKey
          size
          type
          isDeleted
          isInline
          labels {
            items {
              id
              name
            }
          }
        }
      }
      date
      description
      from
      outlookLabelIds
      id
      internalDate
      notes
      priority
      receivedAt
      snippet
      subject
      to
      threadId
      labels {
        items {
          id
          name
        }
      }
      taskList {
        id
        isDeleted
      }
      clientMatters {
        items {
          id
          client {
            id
            name
          }
          matter {
            id
            name
          }
        }
      }
    }
  }`;

const qGetGmailMessage = `
  query getGmailMessage($id: ID) {
    gmailMessage(
      id: $id
    ) {
      id
      from
      to
      cc
      bcc
      subject
      date
      snippet
      labels {
        items {
          id
          name
        }
      }
      gmailLabelIds
      description
      taskList {
        id
        isDeleted
      }
      clientMatters {
        items {
          id
          client {
            id
            name
          }
          matter {
            id
            name
          }
          contacts {
            items {
              type
              details {
                id
                email
                name
                number
              }
            }
          }
        }
      }
      attachments {
        items {
          id
          details
          name
          s3ObjectKey
          size
          type
          labels {
            items {
              id
              name
            }
          }
          isDeleted
        }
      }
      assignee
      priority
      notes
      receivedAt
      internalDate
    }
  }`;

// GRAPHQL MUTATIONS
const mUpdateAttachmentDescription = `mutation updateGmailAttachment($details: String, $id: ID) {
  gmailMessageAttachmentUpdate(id: $id, details: $details) {
    id
    details
  }
}`;

const mUpdateRowDescription = `mutation saveGmailDescription($id: String, $description: String) {
  gmailMessageUpdate(id: $id, description: $description) {
    id
  }
}`;

const mTagEmailClientMatter = `mutation tagGmailMessageClientMatter($clientMatterId: ID, $gmailMessageId: String) {
  gmailMessageClientMatterTag(
    clientMatterId: $clientMatterId
    gmailMessageId: $gmailMessageId
  ) {
    id,
  }
}`;

const mUpdateAttachmentStatus = `mutation updateAttachment($id: ID, $isDeleted: Boolean) {
  gmailMessageAttachmentUpdate(id: $id, isDeleted: $isDeleted) {
    id
  }
}`;

const mCreateLabel = `mutation createLabel($clientMatterId: String, $name: String) {
  labelCreate(clientMatterId:$clientMatterId, name:$name) {
      id
      name
  }
}`;

const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
  briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
    id
    name
    date
    createdAt
  }
}`;

const mAddUserAcces = `mutation tagBriefUserTypeAccess($briefId:String, $userType: [UserType]) {
  briefAccessTypeTag(briefId:$briefId, userType:$userType) {
    id
  }
}`;

const UPDATE_BACKGROUND_TABLE_MUTATION = `mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
  backgroundTableUpdate(id: $id, input: $input) {
    id
    columns {
      id
      accessorKey
      headerText
      type
      enabled
      optionsText
      order
      presets {
        id
        name
      }
    }
  }
}`;

const mSaveNotes = `mutation updateGmailMessage($id: String, $priority: Int, $notes: String) {
  gmailMessageUpdate(id: $id, priority: $priority, notes: $notes) {
    id
  }
}`;

const mSavePriority = `mutation updateGmailMessage($id: String, $priority: Int, $notes: String) {
  gmailMessageUpdate(id: $id, priority: $priority, notes: $notes) {
    id
  }
}`;

const mSaveAssignees = `mutation saveGmailAssignee($id: String, $assignee: String) {
  gmailMessageUpdate(id: $id, assignee: $assignee) {
    id
  }
}`;

const mArchivedEmails = `mutation archiveGmailMessage($companyId: ID, $id: [ID]) {
  gmailMessageBulkSoftDelete(companyId: $companyId, id: $id) {
    id
  }
}`;

const mAddEmailLabel = `mutation saveGmailMessageLabel($gmailMessageId: String, $labelId: [ID]) {
  gmailMessageLabelTag(labelId: $labelId, gmailMessageId: $gmailMessageId) {
    id
  }
}`;

const mAddEmailAttachmentLabel = `mutation saveGmailAttachmentLabel($attachmentId: String, $labelId: [ID]) {
  gmailAttachmentLabelTag(attachmentId: $attachmentId, labelId: $labelId) {
    id
  }
}`;

const DEFAULT_USERTYPES_QUERY = `
    query getDefaultUserTypes {
    defaultUserType
  }`;

// OUTLOOK QUERIES
const mSaveNotesOutlook = `mutation updateOutlookMessage($id: String, $priority: Int, $notes: String) {
    outlookMessageUpdate(id: $id, priority: $priority, notes: $notes) {
      id
    }
  }`;

const mSavePriorityOutlook = `mutation updateOutlookMessage($id: String, $priority: Int, $notes: String) {
    outlookMessageUpdate(id: $id, priority: $priority, notes: $notes) {
      id
    }
  }`;

const mSaveAssigneesOutlook = `mutation saveOutlookAssignee($id: String, $assignee: String) {
    outlookMessageUpdate(id: $id, assignee: $assignee) {
      id
    }
  }`;

const mUpdateRowDescriptionOutlook = `mutation saveOutlookDescription($id: String, $description: String) {
  outlookMessageUpdate(id: $id, description: $description) {
    id
  }
}`;

const qOutlookGetFileDownloadLink = `query getOutlookAttachmentDownloadLink($id: String) {
    outlookAttachment(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

const mAddEmailLabelOutlook = `mutation saveOutlookMessageLabel($gmailMessageId: String, $labelId: [ID]) {
    outlookMessageLabelTag(labelId: $labelId, outlookMessageId: $gmailMessageId) {
      id
    }
  }`;

const mTagOutlookEmailClientMatter = `mutation tagOutlookMessageClientMatter($clientMatterId: ID, $gmailMessageId: String) {
    outlookMessageClientMatterTag(
      clientMatterId: $clientMatterId
      outlookMessageId: $gmailMessageId
    ) {
      id,
    }
  }`;

const mAddOutlookAttachmentLabel = `mutation saveOutlookAttachmentLabel($attachmentId: String, $labelId: [ID]) {
  outlookAttachmentLabelTag(attachmentId: $attachmentId, labelId: $labelId) {
    id
  }
}`;

const mUpdateOutlookAttachmentDescription = `mutation updateGmailAttachment($details: String, $id: ID) {
  outlookMessageAttachmentUpdate(id: $id, details: $details) {
    id
    details
  }
}`;

const mUpdateOutlookAttachmentStatus = `mutation updateAttachment($id: ID, $isDeleted: Boolean) {
  outlookMessageAttachmentUpdate(id: $id, isDeleted: $isDeleted) {
    id
  }
}`;

// const TASK_LIST_DATA_CREATE = `
// mutation taskListDataCreate($companyId: ID!, $input: TaskListDataInput!) {
//   taskListDataCreate(companyId: $companyId, input: $input) {
//     data
//     id
//     createdAt
//   }
// }
// `;

const TASK_LIST_DATA_UPDATE = `
mutation taskListDataUpdate($id: ID!, $input: TaskListDataInput!) {
  taskListDataUpdate(id: $id, input: $input) {
    data
    id
    createdAt
  }
}
`;

const TASKLIST_DATA_COUNT = `
query getTaskListsCount($companyId: ID!) {
  taskListDataCount(companyId: $companyId) {
    status
    count
  }
}`;

const mCreateActivity = `
mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
  activityCreate(
	activity: $activity
	briefId: $briefId
	clientMatterId: $clientMatterId
	companyId: $companyId
	previous: $previous
	field: $field
	current: $current
	appModule: $appModule
	rowId: $rowId
  ) {
	id
  }
}`;

const qListClientMatterLabels = `
  query clientMatterById($id: ID) {
    clientMatter (id: $id) {
      id
      labels {
        items {
          id
          name
        }
      }
    }
  }`;

const TableUnsavedInfo = ({
  taskListColumns,
  history,
  location,
  scrollIndex,
  handleScrollIndex,
  handleSelectedEmail,
  handleComposeUnsavedTabs,
  selectedUnsavedItems,
  setSelectedUnsavedItems,
  setSelectedArchivedItems,
  selectedArchivedItems,
  unSavedEmails,
  setUnsavedEmails,
  matterList,
  maxLoadingUnSavedEmail,
  getUnSavedEmails,
  emailFilters,
  labelsList,
  waitUnSaved,
  connectedEmail,
  userTimeZone,
  momentTZ,
  qGmailMessagesbyCompany,
  setAttachmentIsDeleted,
  setAttachmentId,
  lastCounter,
  selectedTaskList,
  setSelectedTaskList,
  selectedTaskRegister,
  setSelectedTaskRegister,
  taskRegisterList,
  setTaskRegisterList,
  onCreateNewOption,
  show,
  setShow,
  setShowToast,
  setResultMessage,
  setSaveLoading,
  sortByDate,
  setArchivedEmails,
  archivedEmails,
  setEmailContent,
  setShowPreview,
  setEmailInfo,
  setUnsavedForTaskListCtr,
  displayImageAttachments,
  bindList,
  searchGmail,
  isOutOfSync,
  setContactEmailList,
  contactEmailList,
  showOtherDetails,
  setShowOtherDetails,
  otherDetailsId,
  setOtherDetailsId,
  setWebAssignee,
  saveLoading,
  assigneeId,
  setAssigneeId,
  assigneeVal,
  setAssigneeVal,
  mobileLoading,
  setMobileLoading,
  priorityVal,
  setPriorityVal,
  notesVal,
  setNotesVal,
  toggleSent,
  setshowAddContactModal,
  fetchCompanyClientMatters,
  setEmailContentOpen,
  emailContentOpen,
  allowComposeEmail,
  showCopy,
  setShowCopy,
  showDetailsCopy,
  setShowDetailsCopy,
  pastedIds,
  setPastedIds,
  copyId,
  setCopyId,
  reloadKey,
  setReloadKey,
  onScroll,
  scrollPosition,
  recordScroll,
  setRecordScroll,
  setIsArchive,
  isArchive,
  isOutlookIntegrated,
  refreshToken,
  setRefreshToken,
  setIsOutOfSync,
  setConnectedEmail,
  connectedOutlookAccount,

  tasklistGmailIds,
  setTasklistGmailIds,
  savedForTaskListCtr,
  getArchivedEmails,
  setDoneAllocatingEmails,
  setpendingMessage,
  setPreviousUnsavedEmails,
  setPreviousSavedEmails,
  setPreviousArchivedEmails,
  setSearchGmail,
  selectAllArchived,
  selectAllSaved,
  selectAllUnsaved,
  setDisplayImageAttachments,
  selectAllCheck,
  setSelectAllCheck,
  handleExecuteFilter,
  savedEmails,
  setSavedEmails,
  getSavedEmails,
  selectedSavedItems,
  setSelectedSavedItems,
  openTab,
  fetchData,
  setWaitUnSaved,
  setWaitSaved,
  setWaitArchived,
  setOpenTab,

  unSavedForTaskListCtr,
  copyMore20,
  setShowMobileNotificationModal,
  setCopyMore20,

  setIsUpdatedClientMatter,
  isUpdatedClientMatter,
  setSelectedRawHtml,
  setSubject,
  setSelectedIsReplyDetails,
  setSelectedThreadId,
  setSelectedMessageId,

  saveProgress,
  setSaveProgress,
  vTablePaddingTop,
  vTablePaddingBottom,
  virtualRows,
  rows,
  tableContainerRef,
  totalSize,
  rowVirtualizer,
  loadingCM,

  setUnsavedEmailsCount,
  setSavedEmailsCount,
  setArchivedEmailsCount,
  Highlight,
}) => {
  // Scroll Position
  // const scrollRef = useRef();
  // useEffect(() => {
  //   scrollRef.current.scrollTop = scrollPosition;
  // }, [scrollPosition])

  var activeUserType = localStorage.getItem("userType");

  // Memoizing unsaved emails
  useMemo(() => unSavedEmails, [unSavedEmails]);
  useMemo(() => selectedTaskList, [selectedTaskList]);
  useMemo(() => selectedTaskRegister, [selectedTaskRegister]);
  useMemo(() => showCopy, [showCopy]);
  useMemo(() => show, [show]);

  // useMemo(() => snippetId, [snippetId]);
  // useMemo(() => ShowIndex,[ShowIndex]);
  // useMemo(() => detailsDivRef, [detailsDivRef])
  // useMemo(() => selectUnsavedEmailsDetails, [selectUnsavedEmailsDetails]);
  // useMemo(() => emailHTML, [emailHTML]);
  // useMemo(() => emailPreview ,[emailPreview])

  // STORE LOADING STATE OF SAVING CLIENT MATTERS AND LABELS IN MOBILE
  const [loadingClientMatters, setLoadingClientMatters] = useState([]);
  const [emailContentClientMatter, setEmailContentClientMatter] = useState([]);
  const [subjectClientMatter, setSubjectClientMatter] = useState([]);
  const [emailAddressesClientMatter, setEmailAddressesClientMatter] = useState([]);
  const [scoringCompleted, setScoringCompleted] = useState(false);
  const [cmSuggestion, setCmSuggestion] = useState(null)
  // const [cmSuggestionMobile, setCmSuggestionMobile] = useState([]);
  const [hasSuggestion, setHasSuggestion] = useState(false);
  const [mobileSuggestionIdList, setMobileSuggestionIdList] = useState([]);

//   const calculateScores = async () => {
//     // Create a map to hold the scores for each unique value
//     const scoreMap = new Map();

//     // Helper function to update scores, with a check to ensure the array or object is defined
//     const updateScores = (input, points) => {
//         if (Array.isArray(input)) {
//             input.forEach(item => {
//                 if (scoreMap.has(item.value)) {
//                     scoreMap.set(item.value, scoreMap.get(item.value) + points);
//                 } else {
//                     scoreMap.set(item.value, points);
//                 }
//             });
//         } else if (input && input.value) { // Check if input is a non-null object with a value
//             if (scoreMap.has(input.value)) {
//                 scoreMap.set(input.value, scoreMap.get(input.value) + points);
//             } else {
//                 scoreMap.set(input.value, points);
//             }
//         }
//     };

//     console.log("emailContentClientMatter", emailContentClientMatter);
//     console.log("emailAddressesClientMatter", emailAddressesClientMatter);
//     console.log("subjectClientMatter", subjectClientMatter);

//     // Update scores for each array/object with their respective points
//     updateScores(emailContentClientMatter, 25);
//     updateScores(emailAddressesClientMatter, 20);
//     updateScores(subjectClientMatter, 10);

//     // Convert the map to an array and sort by scores in descending order
//     const sortedScores = Array.from(scoreMap.entries()).sort((a, b) => b[1] - a[1]);

//     console.log("sortedScores", sortedScores);

//     // Limit to top 3 scores

//     const topScores = sortedScores?.length >= 3 ? sortedScores.slice(0, 3) : sortedScores;

//     let emailContent = Array.isArray(emailContentClientMatter) ? emailContentClientMatter : [emailContentClientMatter];
//     let subjectContent = Array.isArray(subjectClientMatter) ? subjectClientMatter : [subjectClientMatter];
//     let senderContent = Array.isArray(emailAddressesClientMatter) ? emailAddressesClientMatter : [emailAddressesClientMatter];

//     // Helper function to ensure variables are arrays
//     const ensureArray = (variable) => Array.isArray(variable) ? variable : [variable];

//     // Helper function to find the label
//     const findLabel = (value, subjectClientMatter, emailContentClientMatter, emailAddressesClientMatter) => {
//         const allItems = Array.isArray(subjectClientMatter) 
//             ? [...ensureArray(emailContentClientMatter), ...ensureArray(emailAddressesClientMatter), ...subjectClientMatter] 
//             : [...ensureArray(emailContentClientMatter), ...ensureArray(emailAddressesClientMatter), subjectClientMatter];
//         return allItems.find(item => item.value === value)?.label || 'Unknown';
//     };

//     // Map the top scores to an array of objects with labels and scores
//     const result = topScores.map(([value, score]) => {
//         const label = findLabel(value, subjectClientMatter, emailContentClientMatter, emailAddressesClientMatter);
//         return { value, label, score };
//     });

//     // Map the top scores to an array of objects with labels
//     const topSuggestion = topScores.map(([value, score]) => {
//         const label = findLabel(value, subjectClientMatter, emailContentClientMatter, emailAddressesClientMatter);
//         return { value, label };
//     });



//     console.log("Top Scores", topSuggestion);

//     setCmSuggestion(topSuggestion.length > 0 ? topSuggestion[0] : {value: "", label: "No matched result"});

//     //for mobile use only
//     // var allSuggestions = cmSuggestionMobile;

//     // allSuggestions = [...cmSuggestionMobile, {option: topSuggestion.length > 0 ? topSuggestion[0] :  matterList?.length > 0 ? matterList[0] : {}, id: selectUnsavedEmailsDetails?.id}]
//     // console.log("allSuggestions mobile:", allSuggestions)
//     // setCmSuggestionMobile(allSuggestions)

//     return result;
// };

  const calculateScores = () => {
    // Create a map to hold the scores for each unique value
    const scoreMap = new Map();

    // Helper function to update scores, with a check to ensure the array or object is defined
    const updateScores = (input, points) => {
      const calculateScore = (item) => points * (item.score ? item.score / 100 : 1);

      const processItem = (item) => {
        const score = calculateScore(item);
        scoreMap.set(item.value, (scoreMap.get(item.value) || 0) + score);
      };

      if (Array.isArray(input)) {
        input.forEach(processItem);
      } else if (input && input.value) {
        processItem(input);
      }
    };

    console.log("emailContentClientMatter", emailContentClientMatter)
    console.log("emailAddressesClientMatter", emailAddressesClientMatter)

    // Update scores for each array/object with their respective points
    updateScores(emailContentClientMatter, 35);
    updateScores(emailAddressesClientMatter, 20);

    // Convert the map to an array and sort by scores in descending order
    const sortedScores = Array.from(scoreMap.entries()).sort(
      (a, b) => b[1] - a[1]
    );

    console.log("sortedScores", sortedScores);

    // Limit to top 3 scores
    const topScores = sortedScores.slice(0, 3);

    // Helper function to ensure variables are arrays
    const ensureArray = (variable) =>
      Array.isArray(variable) ? variable : [variable];

    // Helper function to find the label
    const findLabel = (
      value,
      emailContentClientMatter,
      emailAddressesClientMatter
    ) => {
      const allItems = [
        ...ensureArray(emailContentClientMatter),
        ...ensureArray(emailAddressesClientMatter),
      ];
      return allItems.find((item) => item.value === value)?.label || "Unknown";
    };

    // Map the top scores to an array of objects with labels
    const topSuggestion = topScores.map(([value, score]) => {
      const label = findLabel(
        value,
        emailContentClientMatter,
        emailAddressesClientMatter
      );
      return { value, label };
    });

    console.log("topSuggestion", topSuggestion)
    setCmSuggestion(topSuggestion.length > 0 ? topSuggestion[0] : {value: "", label: "No matched result"});
  };


// const handleRemoveSuggestion = (rowId) => {
//   var mobileSuggList = cmSuggestionMobile;
//   const filteredItems = mobileSuggList.filter(item => item.id !== rowId);
//   // setCmSuggestionMobile(filteredItems);
// }

// const handleAddSuggestionId = (rowId) => {
//   var mobileSuggIdList = mobileSuggestionIdList;
//   const newItems = [...mobileSuggIdList, rowId];
//   setMobileSuggestionIdList(newItems);
// }

  useEffect(() => {
    handleDisplayImageAttachments(displayImageAttachments);

  }, [displayImageAttachments]);

  useEffect(() => {
    //console.log("useEffectWidth:", window.innerWidth);
    width = window.innerWidth;
    if (width > 844) {
      let tableHeight = document.getElementById("emailTableBody").offsetTop;
      setHeightOffset(tableHeight + 1);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    console.log("Updated unsavedEmails", unSavedEmails);
    console.log("Updated virtualRows", virtualRows);
  }, [unSavedEmails]);

  const handleDisplayImageAttachments = async (removeImage) => {
    const emailsWithImageAttachments = [];
    const emailsWithImageAttachmentsId = [];

    // Iterate over unsaved emails
    for (const email of unSavedEmails) {
      // Check if the email has attachments
      if (email.attachments?.items?.length > 0) {
        // Iterate over attachments
        for (const attachment of email.attachments.items) {
          // Check if the attachment is an image
          if (attachment.type.startsWith("image")) {
            emailsWithImageAttachments.push(attachment);
            emailsWithImageAttachmentsId.push(attachment.id);
          }
        }
      }
    }

    // Update unsaved emails
    const updatedUnsavedEmails = unSavedEmails?.map((email) => {
      const updatedItems = email.attachments?.items?.map((attachment) => {
        if (
          attachment.isDeleted !== removeImage &&
          emailsWithImageAttachmentsId?.includes(attachment.id)
        ) {
          return { ...attachment, isDeleted: removeImage };
        }
        return attachment;
      });

      return { ...email, attachments: { items: updatedItems } };
    });

    setUnsavedEmails(updatedUnsavedEmails);

    const selectedEmail = updatedUnsavedEmails.find(
      (item) => item.id === selectUnsavedEmailsDetails?.id
    )

    setSelectUnsavedEmailsDetails(selectedEmail);

    console.log("Updated selectedEmail", matterList);


    setMobileLoading(false);

    // Update status on the backend
    const updateAttachmentPromises = [];
    emailsWithImageAttachmentsId.forEach((id) => {
      const params = {
        query: isOutlookIntegrated
          ? mUpdateOutlookAttachmentStatus
          : mUpdateAttachmentStatus,
        variables: { id, isDeleted: removeImage },
      };

      updateAttachmentPromises.push(API.graphql(params)); // Collect all updateAttachmentPromises
    });

    try {
      await Promise.all(updateAttachmentPromises); // Wait for all API calls to finish
    } catch (error) {
      console.error(error.errors[0].message);
    }
  };

  // const handleScoring = async (gmailMessageId, gmailMessageSubject, type) => {
  //   let returnValue = "";
  //   let promptEmail;

  //   if (type === "email") {
  //       const emailPayload = await getEmailPayload(gmailMessageId);

  //       if (emailPayload.payload) {
  //           const parsedEmail = EmailParser(
  //               JSON.parse(emailPayload.payload[0].content)
  //           );
  //           promptEmail = parsedEmail.text;
  //       }
  //   } else if (type === "subject") {
  //       promptEmail = gmailMessageSubject;
  //   }

  //   const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } = process.env;
  //   const max_tokens = 3000;
  //   const endpoint = `https://api.openai.com/v1/chat/completions`;
  //   const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
  //   const temperature = 0.2;

  //   if (promptEmail !== "") {
  //       // Split the email into sentences
  //       const sentences = promptEmail?.split(/[.!?]/);

  //       // Filter and limit the sentences
  //       const filteredSentences = sentences.filter(
  //           (sentence) => sentence.trim() !== ""
  //       ); // Remove empty sentences

  //       // Join the filtered sentences to create the limited email content
  //       const limitedEmailContent = filteredSentences.join(". ");

  //       const instruction = `For context, I am trying to rank which clientmatters relates to the content. Please analyse the content and array of clientmatters which is the most suggested to use and return the specific array matched as response. Return only as array clientmatter with value and label example "{"value":"xxx","label":"xxx"}" if there are matched otherwise show "No matched result": .\n\n${JSON.stringify(matterList)}\n\n${limitedEmailContent}`;

  //       try {
  //           const response = await fetch(endpoint, {
  //               method: "POST",
  //               headers: {
  //                   "Content-Type": "application/json",
  //                   Authorization: `Bearer ${apiKey}`,
  //               },
  //               body: JSON.stringify({
  //                   model: engine,
  //                   messages: [{ role: "user", content: `${instruction}` }],
  //                   max_tokens,
  //                   stop,
  //                   temperature,
  //               }),
  //           });

  //           if (!response.ok) {
  //               throw new Error(response.statusText);
  //           }

  //           const data = await response.json();
  //           const choices = data.choices;

  //           returnValue = choices[0]?.message?.content
  //               ?.trim()
  //               .replace(/(\n)+$/, "")
  //               .replace(/\s+/g, " ");

  //       } catch (error) {
  //           console.error("Error", error);
  //           return { error };
  //       }
  //   }

  //   if (type === "email") {
  //       return { message: returnValue };
  //   } else if (type === "subject") {
  //       return { subject: returnValue };
  //   }
  // };

  const handleScoring = async (gmailMessageId, gmailMessageSubject) => {
    let returnValue = "";
    let promptEmail;

    if (promptEmail === "") return;

    const emailPayload = await getEmailPayload(gmailMessageId);

    const emailObject = {};
    if (emailPayload.payload) {
        const parsedEmail = EmailParser(
            JSON.parse(emailPayload.payload[0].content)
        );
        console.log("parsedEmail", parsedEmail)
        emailObject.body = parsedEmail.text;
    }
        
    emailObject.subject = gmailMessageSubject;

    returnValue = await handleOpenAICompute(emailObject);

    return returnValue;
  };

  const handleOpenAICompute = async (promptEmail) => {
    console.log("promptEmail", promptEmail);
    
    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } = process.env;
    const max_tokens = 3000;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (!promptEmail) {
      return null;
    }
  
    const sentences = promptEmail?.body?.split(/[.!?]/);
  
    // Filter and limit the sentences
    const filteredSentences = sentences.filter(
      (sentence) => sentence.trim() !== ""
    ); // Remove empty sentences
  
    // Join the filtered sentences to create the limited email content
    const limitedEmailContent = filteredSentences.join(". ");
  
    const matterListLabels = Array.from(
      new Set(matterList?.map((cm) => cm.label) ?? [])
    );

    console.log("limitedEmailContent", limitedEmailContent)
    const instruction = 
      `Given the following email content, score each of the client and matter names on a scale of 1 to 100 based on how well they correlate with the email provided. Return the results as a JSON array named "results" which contains 5 of the mostly related clientmatters and their relevance score (1-100) in the format: [{"client_matter": string, "score": int}]. \n\n` +
      `Client and Matter names (In this format: "Client Name/Matter Name"): ${matterListLabels.map((label, index) => ("\n" + (index + 1) + ". " + label))}\n\n` + 
      `Email Subject: ${promptEmail.subject}\n`+ 
      `Email Content: ${limitedEmailContent}\n`;

    console.log("instruction", instruction)

    const openAIParams = {
      model: engine,
      messages: [
        { role: "user", content: instruction },
      ],
      max_tokens: max_tokens,
      stop,
      temperature,
      response_format: { type: "json_object" },
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify(openAIParams),
      });

      console.log("response", response);
      const data = await response.json();

      if (!response.ok) {
        console.log("err", data);
        throw new Error(data);
      }
      console.log(`Used Tokens`, data.usage);

      const responseMessage = data.choices[0].message;

      let parsedResult = JSON.parse(responseMessage.content).results;
      console.log(`parsedResult`, parsedResult);

      let scoredMatterList = matterList.map((matter) => {
        let scoredMatter = parsedResult.find((scoredMatter) => scoredMatter.client_matter === matter.label);
        if (scoredMatter) {
          return {
            ...matter,
            score: scoredMatter.score,
          }
        }
        return null;
      }).filter((item) => item !== null);
      console.log(`scoredMatterList`, scoredMatterList);

      return scoredMatterList;

    } catch (error) {
      console.error("handleOpenAICompute: Summarized Email error:", error);
    }
  
    return "No matched result";
  };

  // const handleScoring = async () => {
  //   try {
  //     console.log("item selected >> ", selectUnsavedEmailsDetails);
  
  //     if (!selectUnsavedEmailsDetails) {
  //       throw new Error("No email details selected.");
  //     }
  
  //     const selectedEmail = unSavedEmails.find(
  //       (item) => item.id === selectUnsavedEmailsDetails.id
  //     );
  
  //     if (!selectedEmail) {
  //       throw new Error("Selected email not found in unsaved emails.");
  //     }
  
  //     console.log("GET DETAILS", selectedEmail);
  
  //     // Example Email Content
  //     const emailContent = `
  //       Test Sav Email
  //       from: Salvador Estidola Jr
  //       to: Developer Lophils
  
  //       This email is related to sav for testing
  
  //       Kind regards,
  //       Salvador Estidola Jr
  
  //       Lophils Inc.
  //       5th Floor Gateway Tower Building,
  //       Gen. Roxas Ave. cor Gen. Aguinaldo Ave.
  //       Araneta Center, Cubao, Quezon City, 1109
  //     `;
  
  //     // Execute the matching
  //     const matchedClientMatters = await matchEmailToClientmatters(matterList, emailContent);
  
  //     console.log("matched client matters >> ", matchedClientMatters);
  
  //   } catch (error) {
  //     console.error("Error in handleScoring:", error.message);
  //   }
  // };
  

  // Scoring Function
  // function scoreClientmatter(matterList, content) {
  //     const label = matterList.label.toLowerCase();
  //     const contents = content.toLowerCase();
  //     const words = label.split('/');
  //     let score = 0;
      
  //     words.forEach(word => {
  //         if (contents.includes(word)) {
  //             score += 1;
  //         }
  //     });
      
  //     return score;
  //   }

  //   // Match and Score Clientmatters
  //   function matchEmailToClientmatters(matterList, content) {
  //     const scores = matterList.map(cm => {
  //         return {
  //             ...cm,
  //             score: scoreClientmatter(cm, content)
  //         };
  //     });

  //     // Sort by score in descending order
  //     scores.sort((a, b) => b.score - a.score);

  //     return scores;
  //   }

  const ref = useRef([]);
  const [snippetId, setSnippetId] = useState(null);
  const [emailPreview, setEmailPreview] = useState(null);
  const [showSave, setShowSave] = useState(true);
  const [showRemoveTask, setShowRemoveTask] = useState(false);

  //GS MMA 2497 Causes Rerender
  const handleRootClose = useCallback(() => {
    console.log("handleRootClose()");
    setShow(false);
    setSnippetId(null);
    setShowIndex(-1);
    setEmailPreview(null);
    //console.log(forcescrollTop);
  }, []);
  const [selectedClientMatter, setSelectedClientMatter] = useState();
  const [isShiftDown, setIsShiftDown] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const companyId = localStorage.getItem("companyId");
  const [enabledArrays, setEnabledArrays] = useState([]);
  const [options, setOptions] = useState(null);
  const [ShowAddLabel, setShowAddLabel] = useState([{ index: 0, show: false }]);
  const [ShowLabelDescription, setShowLabelDescription] = useState(false);
  const [labelsOptions, setLabelsOptions] = useState([]);
  const [ShowIndex, setShowIndex] = useState(-1);
  const [loadLabels, setLoadLabels] = useState(false);
  const [loadLabelsIndex, setLoadLabelsIndex] = useState("");
  const [enableDropdown, setEnableDropdown] = useState(false);
  const [threadId, setThreadId] = useState("");
  const [messageId, setMessageId] = useState("");
  const [userTypes, setUserTypes] = useState([]);
  const [emailHTML, setEmailHTML] = useState("");
  const [cpyIsTaskList, setCpyIsTaskList] = useState(false);

  // const { height, width } = useWindowDimensions();
  var width = window.innerWidth;
  var height = window.innerHeight;
  const [heightOffset, setHeightOffset] = useState(160); // CHANGE DEFAULT VALUE IF FRONTEND CHANGES
  const detailsDivRef = useRef(null);

  const [forcescrollTop, setForceScrollTop] = useState();

  const [loaderEmail, setLoaderEmail] = useState(null);

  //COPY FUNCTION
  const [copyCm, setCopyCm] = useState("");
  const [copyAssignee, setCopyAssignee] = useState("");
  const [copyPriority, setCopyPriority] = useState("");
  const [copyNotes, setCopyNotes] = useState("");
  const [copyCmId, setCopyCmId] = useState("");

  const [cmDetails, setCmDetails] = useState([]);
  const [showCm, setShowCm] = useState(true);
  const [filterBriefsList, setFilterBriefsList] = useState([]);

  const [newlyAddedLabels, setNewlyAddedLabels] = useState([]);
  const [showCreateClientMatter, setShowCreateClientMatter] = useState(false);

  //UI REVAMP STATES
  const [selectUnsavedEmailsDetails, setSelectUnsavedEmailsDetails] = useState(
    []
  );
  const [selectedUnsavedIndex, setSelectedUnsavedIndex] = useState(null);
  const [clientMatterSuggestions, setClientMatterSuggestions] = useState([]);
  const [showTaskListDetails, setShowTaskListDetails] = useState(false);
  const [showTaskRegisterDetails, setShowTaskRegisterDetails] = useState(false);
  const [taskListId, setTaskListId] = useState("");
  const [textDesc, setTextDesc] = useState("");
  //const [showDetailsCopy, setShowDetailsCopy] = useState(false);
  const [currUrl, setCurrUrl] = useState(window.location.hostname);

  // MMA-2119
  // Mobile Buffer UI Indicator state to only update the seleted email label & Improve performance
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const MemoizedDropdownSelect = React.memo(DropdownSelect);
  const MemoizedCreatableSelect = React.memo(CreatableSelect);

  const [userEmailList, setUserEmailList] = useState([]);

  // MMA-2216
  const [selectedCopyIndex, setSelectedCopyIndex] = useState(-1);

  //Outlook integration
  const { accounts } = useMsal();

  const customAssigneePlaceholder = (
    <div style={{ display: "flex" }}>
      <BsFillPeopleFill />
      <span style={{ marginLeft: "10px" }}>Assignee</span>
    </div>
  );

  const customPriorityPlaceholder = (
    <div style={{ display: "flex" }}>
      <CiCircleAlert />
      <span style={{ marginLeft: "10px" }}>Priority</span>
    </div>
  );

  //Add tasklist button
  const [clicked, setClicked] = useState(true);
  const handleClick = () => {
    setClicked(!clicked);
  };

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 20,
    })
  );

  const [showRemoveButton, setShowRemoveButton] = useState(false);
  // const loadedLabels = useRef(false);
  const [loadedLabels, setLoadedLabels] = useState([]);

  function handlePop() {
    //handleSave
    // Perform save logic here
    setShowRemoveButton(true); // Show the "Remove to TaskList" button
  }

  function saveButton() {
    handlePop();
    handleSave();
  }

  function getMaxWidthNotes() {
    if (width < 844) {
      var element = document.getElementById("itemDiv");
      return element?.offsetWidth - 101;
    }
    return;
  }

  function getMaxWidthCM() {
    if (width < 844) {
      var element = document.getElementById("itemDiv");
      return element?.offsetWidth - 78;
    }
    return;
  }

  function handleRemove() {
    // Perform remove logic here
    setShowRemoveButton(false); // Hide the "Remove to TaskList" button
  }

  //GS MMA 2497 if causes rerender
  const handleMobileSnippet = useCallback(async (msgId, index) => {
    setShowPreview(true);
    const messageId = msgId.id;
    setSnippetId(messageId);
    const msgPreview = await getEmailPayload(messageId);
    setEmailInfo(msgId);
    setEmailContent(msgPreview);
    const parsedEmail = EmailParser(JSON.parse(msgPreview.payload[0].content));
    setPrompt(parsedEmail.html);
  }, []);

  //GS MMA 2497 Causes Rerender
  const handleSnippet = useCallback(async (msgId, index) => {
    if (width <= 844) {
      setShowPreview(true);
    }

    if (width > 844) {
      setShow(true);
    }

    const messageId = msgId.id;
    setSnippetId(messageId);

    try {
      // Fetch the email payload and parse it only once
      const msgPreview = await getEmailPayload(messageId);
      const parsedEmail = EmailParser(
        JSON.parse(msgPreview.payload[0].content)
      );

      const modifiedEmailContent = await replaceCidWithUrl(
        parsedEmail.html,
        selectUnsavedEmailsDetails?.attachments?.items
      );

      if (messageId) {
        if (width > 844) {
          setEmailPreview(msgPreview);
          setShowIndex(index);
          setPrompt(modifiedEmailContent);
          procEmailContent(msgPreview);
        } else {
          setEmailInfo(msgId);
          setEmailContent(msgPreview);
          setPrompt(modifiedEmailContent);
        }
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  }, []);

  //Save Button
  const [saved, setSaved] = useState(false);

  const handleSave = () => {
    setSaved(true); // Set saved state to true
  };

  const getInlineAttachmentUrl = async (id) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetFileDownloadLink
        : qGmailGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    const { data } = await API.graphql(params);

    const response = isOutlookIntegrated
      ? data?.outlookAttachment?.downloadURL
      : data?.gmailAttachment?.downloadURL;

    return response;
  };

  const removeAttachmentPartIds = (input) => {
    const lastDashIndex = input.lastIndexOf("-");
    const lastDotIndex = input.lastIndexOf(".");
    if (
      lastDashIndex !== -1 &&
      lastDotIndex !== -1 &&
      lastDashIndex < lastDotIndex
    ) {
      const beforeDash = input.substring(0, lastDashIndex);
      const afterDot = input.substring(lastDotIndex + 1);
      return beforeDash + "." + afterDot;
    }
    return input;
  };

  const replaceCidWithUrl = async (content, attachments) => {
    const mapping = []; // Array to store the mapping of attachment URLs and names

    if (attachments) {
      //  console.log("emailProc replaceCid: \n" + JSON.stringify(attachments))
      for (const attachment of attachments) {
        // Get the inline attachment URL asynchronously
        const url = await getInlineAttachmentUrl(attachment.id);
        // Add the URL and name to the mapping array
        mapping.push({
          url: url,
          name: attachment.isInline
            ? attachment.s3ObjectKey.substring(
                attachment.s3ObjectKey.lastIndexOf("/") + 1
              )
            : removeAttachmentPartIds(attachment.name),
        });
      }

      // console.log("emailProc mapping " + JSON.stringify(mapping))
    }

    let modifiedContent = content; // Variable to store the modified content

    if (isOutlookIntegrated) {
      mapping.forEach((item) => {
        const regexSrc = new RegExp(`src="cid:${item.name}"`, "g");
        modifiedContent = modifiedContent.replace(
          regexSrc,
          `src="${item.url}" alt="${item.name}`
        );
      });
    } else {
      mapping.forEach((item) => {
        // const srcRegex = new RegExp(`src="cid:${item.name}"`, "g");
        const regexSrc = new RegExp(`src="cid:[^"]*?" alt="${item.name}"`, "g");
        const regexAlt = new RegExp(`alt="${item.name}"`, "g");
        // Remove the original src attribute value
        // modifiedContent = modifiedContent.replace(srcRegex, "");
        // const altRegex = new RegExp(`alt="${item.name}"`, "g");
        // Replace the alt attribute with the new src attribute and the corresponding URL and name
        modifiedContent = modifiedContent.replace(
          regexSrc,
          `src="${item.url}"`
        );
        modifiedContent = modifiedContent.replace(
          regexAlt,
          `alt="${item.name}"`
        );
      });
    }

    // console.log("emailProc modifiedContent is: \n" + modifiedContent)
    return modifiedContent; // Return the modified content
  };

  // Handling email content to remove unsupportive characters
  // useEffect(() => {
  //   async function setEmailContent() {
  //     if (!emailPreview) return;

  //     const { payload } = emailPreview;
  //     if (!payload || payload.length === 0) return;

  //     const content = JSON.parse(payload[0].content);

  //     if (content.headers) {
  //       const messageIdHeader = content.headers.find(
  //         (header) => header.name.toLowerCase() === "message-id"
  //       );
  //       if (messageIdHeader) {
  //         setMessageId(messageIdHeader.value);
  //       }
  //     }

  //     const parsedEmail = EmailParser(JSON.parse(payload[0].content));
  //     const modifiedEmailContent = await replaceCidWithUrl(
  //       parsedEmail.html,
  //       selectUnsavedEmailsDetails?.attachments?.items
  //     );

  //     setEmailHTML(modifiedEmailContent);
  //   }

  //   setEmailContent();
  // }, [emailPreview]);

  //MMA-2351: useCallback makes the currentSelectUnsavedEmailDetails undefined, useRef is used to ensure that the latest version of that state is used.

  const selectUnsavedEmailsDetailsRef = useRef();
  useEffect(() => {
    // console.log("emailProc selectUnsavedEmailsDetails: " + JSON.stringify(selectUnsavedEmailsDetails))

    selectUnsavedEmailsDetailsRef.current = selectUnsavedEmailsDetails;
  }, [selectUnsavedEmailsDetails]);

  const procEmailContent = useCallback(async (emailPreview) => {
    if (!emailPreview) return;

    const { payload } = emailPreview;
    if (!payload || payload.length === 0) return;

    const content = JSON.parse(payload[0].content);

    if (content.headers) {
      const messageIdHeader = content.headers.find(
        (header) => header.name.toLowerCase() === "message-id"
      );
      if (messageIdHeader) {
        setMessageId(messageIdHeader.value);
        setSelectedMessageId(messageIdHeader.value); // FOR COMPOSE FUNCTION
      }
    }
    //MMA-2351: useCallback makes the currentSelectUnsavedEmailDetails undefined, useRef is used to ensure that the latest version of that state is used.
    const currentSelectUnsavedEmailsDetails =
      selectUnsavedEmailsDetailsRef.current;

    const parsedEmail = EmailParser(JSON.parse(payload[0].content));
    const modifiedEmailContent = await replaceCidWithUrl(
      parsedEmail.html,
      currentSelectUnsavedEmailsDetails?.attachments?.items
    );
    // console.log("emailProc content: " + JSON.stringify(currentSelectUnsavedEmailsDetails))
    setEmailHTML(modifiedEmailContent);
    setSelectedRawHtml(modifiedEmailContent); // FOR COMPOSE FUNCTION
  }, []);

  const handleAddContact = (msgId, index) => {
    setEmailInfo(msgId);
    setshowAddContactModal(true);
  };

  const getEmailPayload = useCallback(async (messageId) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetMessagePayload
        : qGetMessagePayload,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);
    let newPayload = { payload: [{ content: "" }] };

    const message = isOutlookIntegrated
      ? result?.data?.outlookMessage
      : result?.data?.gmailMessage;

    setThreadId(message.threadId);
    setSelectedThreadId(message.threadId);
    message.payload?.forEach((data, index) => {
      newPayload = {
        payload: [
          {
            content: newPayload.payload[0].content + data?.content,
          },
        ],
      };
    });

    return newPayload;
  }, []);

  //GS MMA 2497 Causes Rerender
  useRootClose(ref, handleRootClose, {
    disabled: !show,
  });

  const handleKeyEvent = (e) => {
    if (e.key === "Shift") {
      if (e.type === "keydown") {
        // Shift key is pressed
      } else if (e.type === "keyup") {
        // Shift key is released
      }
    }
  };

  const handleSelectItem = async (e, counter, priority, notes) => {
    const { id, checked } = e.target;
    //To set priority to 1 if there is no selected value
    if (priority === null) {
      let newPriority = 1;
      unSavedEmails?.map((x) => (x.id === id ? (x.priority = newPriority) : x));

      unSavedEmails?.map((x) => (x.id === id ? (x.notes = notes) : x));
      const result = await API.graphql({
        query: isOutlookIntegrated ? mSavePriorityOutlook : mSavePriority, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
        variables: {
          id: id,
          priority: newPriority,
          notes: notes,
        },
      });
    }
    console.log(id);
    console.log(priority);
    console.log("SCROLL INDEX", scrollIndex);

    setSelectedUnsavedItems([...selectedUnsavedItems, id]);

    if (!checked) {
      setSelectedUnsavedItems(
        selectedUnsavedItems?.filter((item) => item !== id)
      );
    }
  };

  const hideToast = () => {
    setShowToast(false);
  };

  const handleSaveDesc = async (e, id, rowId, index) => {
    const data = {
      id: id,
      description: e.target.innerHTML,
    };

    const success = await updateAttachmentDesc(data);
    if (success) {
      var objIndex = unSavedEmails.findIndex((obj) => obj.id === rowId);

      const itemsAttachments = unSavedEmails[objIndex].attachments.items?.map(
        (x) => (x.id === id ? { ...x, details: e.target.innerHTML } : x)
      );

      var updateArrAttachment = unSavedEmails?.map((obj) => {
        if (obj.id === rowId) {
          return { ...obj, attachments: { items: itemsAttachments } };
        }
        return obj;
      });

      setUnsavedEmails(updateArrAttachment);
      setMobileLoading(false);
      setResultMessage("Successfully updated.");
      setShowToast(true);
      autoAdjustRowHeight(index);
    }
  };

  async function updateAttachmentDesc(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: isOutlookIntegrated
            ? mUpdateOutlookAttachmentDescription
            : mUpdateAttachmentDescription,
          variables: {
            id: data.id,
            details: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  // useEffect(() => {
  //   console.log("Loaded Label State: ", loadedLabels);
  // },[loadedLabels])

  // useEffect(() => {
  //   console.log("Loaded Client Matters State: ", loadingClientMatters);
  // },[loadingClientMatters])

  const handleClientMatter = async (
    e,
    gmailMessageId,
    rowId,
    gMail,
    rsIndex
  ) => {
    setIsUpdatedClientMatter(true);
    setSelectedUnsavedItems([...selectedUnsavedItems, gmailMessageId]);
    console.log("E VALUE IS:", e.value);
    if (e.value || e.value !== null) {
      setLoadLabels(true);
      setLoadLabelsIndex(rowId);
      console.log("ENTERED E VALUE");

      let queryVariables = {
        query: isOutlookIntegrated
          ? mTagOutlookEmailClientMatter
          : mTagEmailClientMatter,
        variables: {
          clientMatterId: e.value,
          gmailMessageId: gmailMessageId,
        },
      };

      console.time("handleClientMatter");
      await API.graphql(queryVariables).then(async function (result) {
        var objIndex = unSavedEmails.findIndex(
          (obj) => obj.id === gmailMessageId
        );
        var temp = unSavedEmails;

        if (temp[objIndex].clientMatters !== null) {
          temp[objIndex].clientMatters.items = [
            {
              id: e.value,
              client: { id: "", name: e.label.split("/")[0] },
              matter: { id: "", name: e.label.split("/")[1] },
            },
          ];
          console.log("SET UNSAVED EMAILS:", unSavedEmails);
          setUnsavedEmails(temp);
        }
        console.log("SELECT UNSAVED HERE 1:", selectUnsavedEmailsDetails);
        if (selectUnsavedEmailsDetails) {
          const tl = API.graphql({
            query: TASKLIST_DATA_COUNT,
            variables: {
              companyId,
            },
          });
          const tlDataCount = tl?.data?.taskListDataCount?.find(
            (obj) => obj.status === "total"
          );

          const taskListNextUniqueId = tlDataCount?.count
            ? parseInt(tlDataCount?.count, 10)
            : null;

          const clientName =
            selectUnsavedEmailsDetails.clientMatters?.items[0]?.client.name;
          const matterName =
            selectUnsavedEmailsDetails.clientMatters?.items[0]?.matter.name;
          const clientMatterName = `${clientName}/${matterName}`;

          const varInput = {
            isDeleted: false,
            dueDate: new Date(),
            priority: selectUnsavedEmailsDetails.priority || 1,
            uniqueId: taskListNextUniqueId,
            clientMatterId:
              selectUnsavedEmailsDetails.clientMatters?.items[0]?.id || "",
            gmailMessageId: gmailMessageId,
            data: JSON.stringify({
              0:
                selectUnsavedEmailsDetails.clientMatters?.items?.length > 0
                  ? clientMatterName
                  : "",
              1: selectUnsavedEmailsDetails.subject || "",
              2: selectUnsavedEmailsDetails.from || "",
              3: selectUnsavedEmailsDetails.date || "",
              4: "",
              6: selectUnsavedEmailsDetails.notes || "",
              7: "Outstanding",
              8: selectUnsavedEmailsDetails.priority || 1,
              9: selectUnsavedEmailsDetails.assignee || "",
              10: new Date(),
              11: new Date(),
              registers: "",
            }),
          };
          console.log(
            "selectUnsavedEmailsDetails is:",
            selectUnsavedEmailsDetails
          );

          try {
            console.group("Update Tasklist if set");
            console.log(
              "Tasklist ID",
              selectUnsavedEmailsDetails?.taskList?.id
            );

            if (selectUnsavedEmailsDetails?.taskList?.id) {
              const params = {
                id: selectUnsavedEmailsDetails?.taskList?.id,
                input: varInput,
              };

              console.log("Params:", params);
              await API.graphql({
                query: TASK_LIST_DATA_UPDATE,
                variables: params,
              }).then((result) => {
                console.log("Result:", result);
              });

              console.groupEnd();
            }
          } catch (error) {
            console.error("Error in Update Tasklist:", error);
          }
        }

        // Auto tag Background Label when selecting clientmatter.
        let taggedLabel = null;
        let taggedLabelName = null;
        let taggedLabelIdName = null;

        // Find the matching label in the labelsList array
        const matchedLabels = labelsList.find(
          (labels) => labels.cmid === e.value
        );

        if (matchedLabels) {
          const { labelsExtracted } = matchedLabels;

          if (labelsExtracted && labelsExtracted.length !== 0) {
            // Remove condition || currUrl === "localhost" for testing purposes locally
            var deFaultVariable = "Mirrored Email Page";

            // include || currUrl === "localhost"
            if (hbaDomains.includes(currUrl)) {
              deFaultVariable = "Site Diary";
            }

            // Filter out labels with name "Mirrored Email Page"
            const filteredLabels = labelsExtracted?.filter(
              (item) => item.name === deFaultVariable
            );
            taggedLabel = filteredLabels?.map((x) => x.id);
            taggedLabelName = filteredLabels?.map((x) => x.name);

            // console.log("Filtered Tagged Label", labelsExtracted);

            // Extract non-background labels
            const nonBackgroundLabels = labelsExtracted?.filter(
              (item) => item.name !== deFaultVariable
            );

            taggedLabelIdName = nonBackgroundLabels?.map((x) => ({
              id: x.id,
              label: x.name,
            }));

            // Remove duplicate labels
            taggedLabelIdName = removeDuplicateLabels(taggedLabelIdName);
          } else {
            taggedLabelIdName = [];
          }
        }

        // console.log("Changing ClientMatters", taggedLabel);
        // console.log("Changing ClientMatters 2", taggedLabelIdName);

        if (taggedLabelIdName && taggedLabelIdName.length !== 0) {
          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: e.value,
            },
          });

          if (backgroundTable?.data?.backgroundTable) {
            const { id, columns } = backgroundTable?.data?.backgroundTable;

            const updatedBriefs = columns?.map((obj) => {
              if (parseInt(obj.accessorKey) !== 3) {
                const presetsToUse = [
                  ...obj?.presets?.filter(
                    (preset) => preset.id !== taggedLabelIdName[0].id
                  ),
                  {
                    id: taggedLabelIdName[0].id,
                    name: taggedLabelIdName[0].label,
                  },
                ];

                return { ...obj, presets: presetsToUse };
              } else {
                return obj;
              }
            });

            const variables = {
              id,
              input: {
                columns: updatedBriefs,
              },
            };

            const updateBriefTable = await API.graphql({
              query: UPDATE_BACKGROUND_TABLE_MUTATION,
              variables,
            });

            // console.log("updateBriefTable", updateBriefTable);
          }
        }

        //(async function() {
        if (taggedLabel && taggedLabel.length !== 0) {
          // Save Labels to Attachments
          if (unSavedEmails[objIndex]?.attachments?.items?.length !== 0) {
            unSavedEmails[objIndex]?.attachments?.items?.map((x) => {
              API.graphql({
                query: isOutlookIntegrated
                  ? mAddOutlookAttachmentLabel
                  : mAddEmailAttachmentLabel,
                variables: {
                  labelId: taggedLabel[0],
                  //labelId: optionHandle,
                  attachmentId: x.id,
                },
              });
            });
          }

          unSavedEmails[objIndex]?.attachments?.items?.map((x) => {
            x.labels.items = [
              {
                id: taggedLabel[0],
                name: taggedLabelName[0],
              },
            ];
          });

          // Save Background Label to existing labels
          await API.graphql({
            query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
            variables: {
              labelId: taggedLabel[0],
              //labelId: optionHandle,
              gmailMessageId: gmailMessageId,
            },
          }).then(async (result) => {
            // console.log("Result add email label", result);

            /** Remove this query whenever runs it refreshes the state
          // Save Background Label to existing labels
          await API.graphql({
            query: mAddEmailLabel,
            variables: {
              labelId: taggedLabel[0],
              //labelId: optionHandle,
              gmailMessageId: gmailMessageId,
            },
          }).then(async (result) => {
            console.log("Result add email label", result);
            /** Remove this query whenever runs it refreshes the state
             * const params = {
              query: qGmailMessagesbyCompany,
              variables: {
                id: companyId,
                isSaved: false,
                isDeleted: false,
                email: connectedEmail,
                userTimeZone: userTimeZone,
                startDate:
                  emailFilters.startDate != null
                    ? momentTZ(emailFilters.startDate, userTimeZone).format(
                        "YYYY-MM-DD"
                      )
                    : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
                endDate:
                  emailFilters.endDate != null
                    ? momentTZ(emailFilters.endDate, userTimeZone).format(
                        "YYYY-MM-DD"
                      )
                    : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
              },
            };

            */

            //await API.graphql(params).then((result) => {

            /* if (toggleSent === true) {
                emailList = result.data.company.gmailMessages.items.filter(
                  (gm, i) => gm.gmailLabelIds?.includes("SENT")
                );
              } else {
                emailList = result.data.company.gmailMessages.items.filter(
                  (gm, i) => gm.gmailLabelIds?.includes("INBOX")
                );
              }*/

            // Update array labels when Client/Matter is changed
            var tempLabels = unSavedEmails;

            if (tempLabels[objIndex].labels !== null) {
              tempLabels[objIndex].labels.items = [
                {
                  id: taggedLabel[0],
                  name: taggedLabelName[0],
                },
              ];
            }

            /*
               REMOVED THIS BECAUSE IT CAUSES THE SCREEN
               TO AUTOMATICALLY MOVE UP ON USER INPUT.
               IF THIS CHANGE CAUSES ANY UNWANTED RESULTS, PLEASE REVERT.
               ALSO USED AUTOADJUSTALLROW AS A SUBSTITUTE.
               REMOVE IT AS WELL IF A REVERT IS NEEDED (START OF MMA-1868)
            */

            // autoAdjustRowHeight(rowId);
            setMobileLoading(false);
            setLoadLabels(false);
            setLoadLabelsIndex("");

            // Auto Tagged Labels based on updated result
            handleEmailClick(selectedEmail);

            const selectedEmailList = tempLabels.find(
              (email) => email.id === selectedEmail
            );
            setSelectUnsavedEmailsDetails(selectedEmailList);

            //});

            setResultMessage("Successfully updated.");
            setShowToast(true);
          });
        } else {
          console.log("Processing creating new labels...");
          await API.graphql({
            query: mCreateLabel,
            variables: {
              clientMatterId: e.value,
              name: "Mirrored Email Page",
            },
          }).then(async (result) => {
            // Save Labels to Attachments
            if (unSavedEmails[objIndex]?.attachments?.items?.length !== 0) {
              unSavedEmails[objIndex]?.attachments?.items?.map((x) => {
                const getResult = API.graphql({
                  query: isOutlookIntegrated
                    ? mAddOutlookAttachmentLabel
                    : mAddEmailAttachmentLabel,
                  variables: {
                    labelId: result.data.labelCreate.id,
                    attachmentId: x.id,
                  },
                });
              });
            } else {
              console.log("No Attachments");
            }

            await API.graphql({
              query: isOutlookIntegrated
                ? mAddEmailLabelOutlook
                : mAddEmailLabel,
              variables: {
                labelId: result.data.labelCreate.id,
                gmailMessageId: gmailMessageId,
              },
            }).then(async (results) => {
              /**
               * const params = {
                query: qGmailMessagesbyCompany,
                variables: {
                  id: companyId,
                  isSaved: false,
                  isDeleted: false,
                  email: connectedEmail,
                  userTimeZone: userTimeZone,
                  startDate:
                    emailFilters.startDate != null
                      ? momentTZ(emailFilters.startDate, userTimeZone).format(
                          "YYYY-MM-DD"
                        )
                      : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
                  endDate:
                    emailFilters.endDate != null
                      ? momentTZ(emailFilters.endDate, userTimeZone).format(
                          "YYYY-MM-DD"
                        )
                      : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
                },
              };

              await API.graphql(params).then((result) => {
                var emailList;
                if (toggleSent === true) {
                  emailList = result.data.company.gmailMessages.items.filter(
                    (gm, i) => gm.gmailLabelIds?.includes("SENT")
                  );
                } else {
                  emailList = result.data.company.gmailMessages.items.filter(
                    (gm, i) => gm.gmailLabelIds?.includes("INBOX")
                  );
                }
              */

              // Update array labels when Client/Matter is changed
              var tempLabels = unSavedEmails;

              if (tempLabels[objIndex].labels !== null) {
                tempLabels[objIndex].labels.items = [
                  {
                    id: result.data.labelCreate.id,
                    name: "Mirrored Email Page",
                  },
                ];
              }

              //setUnsavedEmails(emailList);
              setMobileLoading(false);
              /*
               CONTINUATION OF MMA-1868.
               PLEASE CHECK LINE 1027 FOR MORE INFO.
              */
              // autoAdjustRowHeight(rowId);
              autoAdjustAllRow();
              setLoadLabels(false);
              setLoadLabelsIndex("");

              // Auto Tagged Labels based on updated result
              handleEmailClick(selectedEmail);
              const selectedEmailList = tempLabels.find(
                (email) => email.id === selectedEmail
              );
              setSelectUnsavedEmailsDetails(selectedEmailList);
              //});

              setResultMessage("Successfully updated.");
              setShowToast(true);
            });
          });
        }
      });
      console.timeEnd("handleClientMatter");

      let temp = [...enabledArrays];
      temp = [...temp, gmailMessageId];
      setEnabledArrays(temp);

      if (width < 844) {
        bindList?.current?.forceUpdateGrid();
      }
    }
    setLoadLabels(false);
    setEnableDropdown(false);
    /*
      CONTINUATION OF MMA-1868.
      PLEASE CHECK LINE 1027 FOR MORE INFO.
    */
    autoAdjustAllRow();
  };

  //call tasklist items
  const [tasklistItems, setTasklistItems] = useState(null);
  const [inputTaskListArray, setInputTaskListArray] = useState([]);

  useEffect(() => {
    if (tasklistItems === null) {
      getTasklistItems();
    }
  }, [tasklistItems]);

  const TASK_LIST_DATA_QUERY = `
    query taskListData($companyId: ID!, $limit: Int, $nextToken: String) {
      taskListData(companyId: $companyId, limit: $limit, nextToken: $nextToken) {
        items {
          id
          data
          clientMatterId
          gmailMessageId
          createdAt
          isDeleted
          updatedAt
        }
        nextToken
      }
    }
  `;

  const getTasklistItems = async () => {
    const result = await API.graphql({
      query: TASK_LIST_DATA_QUERY,
      variables: {
        companyId,
        isDeleted: false,
      },
    });

    console.log("TASKLIST LIST", result?.data?.taskListData?.items);

    setTasklistItems(result?.data?.taskListData?.items);
  };

  // Optimized handle client matter function
  const handleClientMatter1 = async (e, gmailMessageId, rowId) => {
    //setSaveLoading(true); //loading screen to avoid clicks
    setIsUpdatedClientMatter(true);
    if (!selectedUnsavedItems.includes(gmailMessageId)) {
      setSelectedUnsavedItems([...selectedUnsavedItems, gmailMessageId]);
    }

    if (e.value || e.value !== null) {
      setLoadLabels(true);
      setLoadLabelsIndex(rowId);

      let queryVariables = {
        query: isOutlookIntegrated
          ? mTagOutlookEmailClientMatter
          : mTagEmailClientMatter,
        variables: {
          clientMatterId: e.value,
          gmailMessageId: gmailMessageId,
        },
      };

      try {
        const result = await API.graphql(queryVariables);

        console.log("RES", result);
        var temp = unSavedEmails;

        var objIndex = unSavedEmails.findIndex(
          (obj) => obj.id === gmailMessageId
        );

        if (objIndex !== -1 && unSavedEmails[objIndex].clientMatters !== null) {
          unSavedEmails[objIndex] = {
            ...unSavedEmails[objIndex],
            clientMatters: {
              items: [
                {
                  id: e.value,
                  client: { id: "", name: e.label.split("/")[0] },
                  matter: { id: "", name: e.label.split("/")[1] },
                },
              ],
            },
          };

          // Check if the data has changed before updating the state
          if (
            JSON.stringify(unSavedEmails[objIndex]) !==
            JSON.stringify(unSavedEmails)
          ) {
            setUnsavedEmails([...unSavedEmails]); // Create a shallow copy of the array
            setSaveLoading(false);
          }
        }
      } catch (error) {
        // Handle the error appropriately
        console.error("Error:", error);
      }
      setLoadingClientMatters((prev) =>
        prev.filter((e) => e !== gmailMessageId)
      );

      console.log("SELECT UNSAVED HERE 1:", selectUnsavedEmailsDetails);
      if (selectUnsavedEmailsDetails) {
        //I replaced this with tasklistItems.length instead -KJMF
        // const tl = API.graphql({
        //   query: TASKLIST_DATA_COUNT,
        //   variables: {
        //     companyId,
        //   },
        // }).then(async (res)=>{
        //   const tlDataCount = res?.data?.taskListDataCount?.find(
        //     (obj) => obj.status === "total"
        //   );

        //   const taskListNextUniqueId = tlDataCount?.count
        //   ? parseInt(tlDataCount?.count, 10)
        //   : null;
        // });

        const clientName =
          selectUnsavedEmailsDetails.clientMatters?.items[0]?.client.name;
        const matterName =
          selectUnsavedEmailsDetails.clientMatters?.items[0]?.matter.name;
        const clientMatterName = `${clientName}/${matterName}`;

        const varInput = {
          isDeleted: false,
          dueDate: new Date(),
          priority: selectUnsavedEmailsDetails.priority || 1,
          uniqueId: tasklistItems ? tasklistItems?.length : null,
          clientMatterId:
            selectUnsavedEmailsDetails.clientMatters?.items[0]?.id || "",
          gmailMessageId: gmailMessageId,
          data: JSON.stringify({
            0:
              selectUnsavedEmailsDetails.clientMatters?.items?.length > 0
                ? clientMatterName
                : "",
            1: selectUnsavedEmailsDetails.subject || "",
            2: selectUnsavedEmailsDetails.from || "",
            3: selectUnsavedEmailsDetails.date || "",
            4: "",
            6: selectUnsavedEmailsDetails.notes || "",
            7: "Outstanding",
            8: selectUnsavedEmailsDetails.priority || 1,
            9: selectUnsavedEmailsDetails.assignee || "",
            10: new Date(),
            11: new Date(),
            registers: "",
          }),
        };

        //If existing in tasklist
        if (selectUnsavedEmailsDetails?.taskList?.id) {
          const data = {
            id: selectUnsavedEmailsDetails?.taskList?.id,
            input: varInput,
          };

          const success = await updateClientMatter(data).then(
            async (result) => {
              console.log("\n\n----------", result);
              console.group("updateClientMatter()");

              const params = {
                query: mCreateActivity,
                variables: {
                  companyId: localStorage.getItem("companyId"),
                  // clientMatterId: matter_id,
                  // briefId: showBriefId,
                  activity: "updated the description",
                  field: "Client Matter",
                  // current: data.description,
                  // previous: origDescription,
                  appModule: "INBOX",
                  rowId: rowId,
                },
              };
              const addActivity = await API.graphql(params).then((result) => {
                console.log("addActivity result", result);
              });
            }
          );
        }
      }

      // Auto tag Background Label when selecting clientmatter.
      let taggedLabel = null;
      let taggedLabelName = null;
      let taggedLabelIdName = null;

      // Find the matching label in the labelsList array
      const matchedLabels = labelsList.find(
        (labels) => labels.cmid === e.value
      );

      console.log("Matched Labels", matchedLabels);

      if (matchedLabels) {
        const { labelsExtracted } = matchedLabels;

        if (labelsExtracted && labelsExtracted.length !== 0) {
          // Remove condition || currUrl === "localhost" for testing purposes locally
          var deFaultVariable = "Mirrored Email Page";

          // include || currUrl === "localhost"
          if (hbaDomains.includes(currUrl)) {
            deFaultVariable = "Site Diary";
          }

          // Filter out labels with name "Mirrored Email Page"
          const filteredLabels = labelsExtracted?.filter(
            (item) => item.name === deFaultVariable
          );
          taggedLabel = filteredLabels?.map((x) => x.id);
          taggedLabelName = filteredLabels?.map((x) => x.name);

          console.log("Filtered Tagged Label", labelsExtracted);

          // Extract non-background labels
          const nonBackgroundLabels = labelsExtracted?.filter(
            (item) => item.name !== deFaultVariable
          );
          taggedLabelIdName = nonBackgroundLabels?.map((x) => ({
            id: x.id,
            label: x.name,
          }));

          // Remove duplicate labels
          taggedLabelIdName = removeDuplicateLabels(taggedLabelIdName);
        } else {
          taggedLabelIdName = [];
        }
      }

      if (taggedLabelIdName && taggedLabelIdName.length !== 0) {
        const backgroundTable = API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: e.value,
          },
        }).then(async (res) => {
          if(res?.data?.backgroundTable){
            console.log("why", res)
            const { id, columns } = res?.data?.backgroundTable;
  
            const updatedBriefs = columns?.map((obj) => {
              if (parseInt(obj.accessorKey) !== 3) {
                const presetsToUse = [
                  ...obj?.presets?.filter(
                    (preset) => preset.id !== taggedLabelIdName[0].id
                  ),
                  {
                    id: taggedLabelIdName[0].id,
                    name: taggedLabelIdName[0].label,
                  },
                ];
  
                return { ...obj, presets: presetsToUse };
              } else {
                return obj;
              }
            });
  
            const variables = {
              id,
              input: {
                columns: updatedBriefs,
              },
            };
  
            const updateBriefTable = API.graphql({
              query: UPDATE_BACKGROUND_TABLE_MUTATION,
              variables,
            });
  
            console.log("updateBriefTable", updateBriefTable);
          }
         
        });
      }

      // Start automation selecting labels related to the email
      if (taggedLabel && taggedLabel.length !== 0) {
        // Save Labels to Attachments
        if (unSavedEmails[objIndex]?.attachments?.items?.length !== 0) {
          await Promise.all(
            unSavedEmails[objIndex]?.attachments?.items?.map(async (x) => {
              await API.graphql({
                query: isOutlookIntegrated
                  ? mAddOutlookAttachmentLabel
                  : mAddEmailAttachmentLabel,
                variables: {
                  labelId: taggedLabel[0],
                  attachmentId: x.id,
                },
              });
            })
          );

          // Update the labels in the attachments directly
          unSavedEmails[objIndex].attachments.items.forEach((x) => {
            x.labels.items = [
              {
                id: taggedLabel[0],
                name: taggedLabelName[0],
              },
            ];
          });
        }

        // Save Background Label to existing labels
        const labelResult = await API.graphql({
          query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
          variables: {
            labelId: taggedLabel[0],
            gmailMessageId: gmailMessageId,
          },
        });
        console.log("Result add email label", labelResult);

        // Update array labels when Client/Matter is changed
        if (unSavedEmails[objIndex].labels !== null) {
          unSavedEmails[objIndex].labels.items = [
            {
              id: taggedLabel[0],
              name: taggedLabelName[0],
            },
          ];
        }

        setMobileLoading(false);
        setLoadLabelsIndex("");

        // Auto Tagged Labels based on updated result
        handleEmailClick(selectedEmail);

        const selectedEmailList = unSavedEmails.find(
          (email) => email.id === selectedEmail
        );
        setSelectUnsavedEmailsDetails(selectedEmailList);

        setResultMessage("Successfully updated.");
        setShowToast(true);
      } else {
        var deFaultVariable = "Mirrored Email Page";

        // include || currUrl === "localhost"
        if (hbaDomains.includes(currUrl)) {
          deFaultVariable = "Site Diary";
        }

        console.log("Processing creating new labels...");
        const createLabelResult = await API.graphql({
          query: mCreateLabel,
          variables: {
            clientMatterId: e.value,
            name: deFaultVariable,
          },
        });

        // Save Labels to Attachments
        if (unSavedEmails[objIndex]?.attachments?.items?.length !== 0) {
          await Promise.all(
            unSavedEmails[objIndex]?.attachments?.items?.map(async (x) => {
              await API.graphql({
                query: isOutlookIntegrated
                  ? mAddOutlookAttachmentLabel
                  : mAddEmailAttachmentLabel,
                variables: {
                  labelId: createLabelResult.data.labelCreate.id,
                  attachmentId: x.id,
                },
              });
            })
          );
        } else {
          console.log("No Attachments");
        }

        // Save Background Label to existing labels
        const labelResult = await API.graphql({
          query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
          variables: {
            labelId: createLabelResult.data.labelCreate.id,
            gmailMessageId: gmailMessageId,
          },
        });

        // Update array labels when Client/Matter is changed
        if (unSavedEmails[objIndex].labels !== null) {
          unSavedEmails[objIndex].labels.items = [
            {
              id: createLabelResult.data.labelCreate.id,
              name: deFaultVariable,
            },
          ];
        }

        setMobileLoading(false);
        setLoadLabelsIndex("");

        // Auto Tagged Labels based on updated result
        handleEmailClick(selectedEmail);

        const selectedEmailList = unSavedEmails.find(
          (email) => email.id === selectedEmail
        );
        setSelectUnsavedEmailsDetails(selectedEmailList);

        setResultMessage("Successfully updated.");
        setShowToast(true);
      }
      //});

      let tempArr = [...enabledArrays];
      temp = [...tempArr, gmailMessageId];
      setEnabledArrays(tempArr);

      // if (width < 844) {
      //   bindList?.current?.forceUpdateGrid();
      // }
    }
    setLoadLabels(false);
    setLoadedLabels((prev) => prev.filter((e) => e !== gmailMessageId));
    setEnableDropdown(false);
    //setSaveLoading(false);
  };

  const handleAutoPopulate = async (e, gmailMessageId, rowId, gMail) => {
    if (e?.value !== null && gMail.clientMatters?.items?.length === 0) {
      setLoadLabels(true);
      setLoadLabelsIndex(rowId);
      console.log("E VALUE: ", e.value);
      API.graphql({
        query: isOutlookIntegrated
          ? mTagOutlookEmailClientMatter
          : mTagEmailClientMatter,
        variables: {
          clientMatterId: e.value,
          gmailMessageId: gmailMessageId,
        },
      }).then(async function (result) {
        var objIndex = unSavedEmails.findIndex(
          (obj) => obj.id === gmailMessageId
        );
        unSavedEmails[objIndex].clientMatters.items = [
          {
            id: e.value,
            client: { id: "", name: e.label.split("/")[0] },
            matter: { id: "", name: e.label.split("/")[1] },
          },
        ];

        // Auto tag Background Label when selecting clientmatter.
        var mainLabels = labelsList;
        var taggedLabel = null;
        var taggedLabelIdName = null;
        if (labelsList.length > 0 || labelsList !== null) {
          for (var i = 0; i < labelsList.length; i++) {
            if (labelsList[i].cmid === e.value) {
              if (labelsList[i].labelsExtracted.length !== 0) {
                taggedLabel = labelsList[i].labelsExtracted
                  ?.filter((item) => item.name === "Mirrored Email Page")
                  ?.map((x) => x.id);

                // label automation related to the email
                taggedLabelIdName = removeDuplicateLabels(
                  labelsList[i].labelsExtracted
                    ?.filter((item) => item.name !== "Mirrored Email Page")
                    ?.map((x) => ({ id: x.id, label: x.name }))
                );
              }
            }
          }
        }

        // Start automation selecting labels related to the email
        let msgPreview = await getEmailPayload(gmailMessageId);
        const parsedEmail = EmailParser(
          JSON.parse(msgPreview.payload[0].content)
        );
        msgPreview = parsedEmail.text;

        const prompt = JSON.stringify(taggedLabelIdName);
        const email = msgPreview;

        const {
          REACT_APP_CHATGPT_API: apiKey,
          REACT_APP_CHATGPT_MODEL: engine,
        } = process.env;

        const max_tokens = 3000;
        const endpoint = `https://api.openai.com/v1/chat/completions`;
        const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
        const temperature = 0.2;

        if (prompt !== "") {
          const instruction = `Check the labels data below \n\n\n${prompt}\n\n and list labels has a similar word in this email, please show only the value of the id, label in the response enclosing with {} and do not duplicate labels \n\n${email}\n\n`;

          console.log("labels instructions: ", instruction);

          try {
            const response = await fetch(endpoint, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiKey}`,
              },
              body: JSON.stringify({
                model: engine,
                messages: [{ role: "user", content: `${instruction}` }],
                max_tokens,
                stop,
                temperature,
              }),
            });

            var optionHandle = null;
            const getClientMatterId = (await response.json()).choices[0]
              ?.message?.content;

            const matchesIdName = getClientMatterId
              .match(/\{([^}]+)\}/g)
              .join(", ");

            if (!matchesIdName || matchesIdName.trim() === "") {
              console.log("AI cannot find the connections between emails.");
            } else {
              // Split the JSON data into individual objects
              const objIdName = matchesIdName.split("},{");

              // Add missing square brackets to create a valid JSON array
              const arrResult = `[${objIdName.join(",")}]`;

              // Parse the JSON array
              const parseData = JSON.parse(arrResult);

              // Extract only the id values
              optionHandle = parseData?.map((item) => item.id);

              optionHandle.push(taggedLabel);

              //setResultMessage("Successfully auto-populated labels.");
              //setShowToast(true);

              //console.log(optionHandle);
              optionHandle = [...new Set(optionHandle)];
            }

            if (!response.ok) {
              throw new Error(response.statusText);
            }
          } catch (error) {
            console.error("Error", error);
          }
        }

        console.log("AUTOMATED TAGGING", optionHandle);

        if (taggedLabel.length > 0 || taggedLabel !== null) {
          console.log("TAG BACKGROUND LABEL");

          // Save Labels to Attachments
          if (unSavedEmails[objIndex]?.attachments?.items?.length !== 0) {
            unSavedEmails[objIndex]?.attachments?.items?.map((x) => {
              const getResult = API.graphql({
                query: isOutlookIntegrated
                  ? mAddOutlookAttachmentLabel
                  : mAddEmailAttachmentLabel,
                variables: {
                  labelId: optionHandle,
                  attachmentId: x.id,
                },
              });
            });
          } else {
            console.log("No Attachments");
          }

          // Save Background Label to existing labels
          //if (gMail.labels.items.length === 0) {

          console.log(optionHandle);
          API.graphql({
            query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
            variables: {
              labelId: optionHandle,
              gmailMessageId: gmailMessageId,
            },
          }).then((result) => {
            // run updated labels query
            const params = {
              query: qGmailMessagesbyCompany,
              variables: {
                id: companyId,
                isSaved: false,
                isDeleted: false,
                email: connectedEmail,
                userTimeZone: userTimeZone,
                startDate:
                  emailFilters.startDate != null
                    ? momentTZ(emailFilters.startDate, userTimeZone).format(
                        "YYYY-MM-DD"
                      )
                    : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
                endDate:
                  emailFilters.endDate != null
                    ? momentTZ(emailFilters.endDate, userTimeZone).format(
                        "YYYY-MM-DD"
                      )
                    : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
              },
            };

            API.graphql(params).then((result) => {
              var emailList;
              if (toggleSent === true) {
                emailList = result?.data?.company?.gmailMessages?.items?.filter(
                  (gm, i) => gm.gmailLabelIds?.includes("SENT")
                );
              } else {
                emailList = result?.data?.company?.gmailMessages?.items?.filter(
                  (gm, i) => gm?.gmailLabelIds?.includes("INBOX")
                );
              }
              //console.log(emailList);

              setUnsavedEmails(emailList);
              setMobileLoading(false);
              autoAdjustRowHeight(rowId);
              setLoadLabels(false);
              setLoadLabelsIndex("");
            });

            /* setResultMessage("Successfully updated.");
              setShowToast(true); */
          });
          //}
        } else {
          console.log("NO BACKGROUND LABEL");
          API.graphql({
            query: mCreateLabel,
            variables: {
              clientMatterId: e.value,
              name: "Mirrored Email Page",
            },
          }).then((result) => {
            // Save Labels to Attachments
            if (unSavedEmails[objIndex]?.attachments?.items?.length !== 0) {
              unSavedEmails[objIndex]?.attachments?.items?.map((x) => {
                const getResult = API.graphql({
                  query: isOutlookIntegrated
                    ? mAddOutlookAttachmentLabel
                    : mAddEmailAttachmentLabel,
                  variables: {
                    labelId: result.data.labelCreate.id,
                    attachmentId: x.id,
                  },
                });
              });
            } else {
              console.log("No Attachments");
            }

            API.graphql({
              query: isOutlookIntegrated
                ? mAddEmailLabelOutlook
                : mAddEmailLabel,
              variables: {
                labelId: result.data.labelCreate.id,
                gmailMessageId: gmailMessageId,
              },
            }).then((result) => {
              // run updated labels query
              const params = {
                query: qGmailMessagesbyCompany,
                variables: {
                  id: companyId,
                  isSaved: false,
                  isDeleted: false,
                  email: connectedEmail,
                  userTimeZone: userTimeZone,
                  startDate:
                    emailFilters.startDate != null
                      ? momentTZ(emailFilters.startDate, userTimeZone).format(
                          "YYYY-MM-DD"
                        )
                      : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
                  endDate:
                    emailFilters.endDate != null
                      ? momentTZ(emailFilters.endDate, userTimeZone).format(
                          "YYYY-MM-DD"
                        )
                      : momentTZ(new Date(), userTimeZone).format("YYYY-MM-DD"),
                },
              };

              API.graphql(params).then((result) => {
                var emailList;
                if (toggleSent === true) {
                  emailList =
                    result?.data?.company?.gmailMessages?.items?.filter(
                      (gm, i) => gm.gmailLabelIds?.includes("SENT")
                    );
                } else {
                  emailList =
                    result?.data?.company?.gmailMessages?.items?.filter(
                      (gm, i) => gm.gmailLabelIds?.includes("INBOX")
                    );
                }

                setUnsavedEmails(emailList);
                setMobileLoading(false);
                autoAdjustRowHeight(rowId);
                setLoadLabels(false);
                setLoadLabelsIndex("");
              });

              /* setResultMessage("Successfully updated.");
              setShowToast(true); */
            });
          });
        }
      });

      let temp = [...enabledArrays];
      temp = [...temp, gmailMessageId];
      setEnabledArrays(temp);

      if (width < 844) {
        bindList?.current?.forceUpdateGrid();
      }
    }
  };

  const handleDescContent = (e, description, id, index) => {
    const cleanDescription = handleDescription(description);
    setTextDesc(cleanDescription);
  };

  const handleDescription = (description) => {
    if (description) {
      var parsedDescription = description?.replace(
        /style="[^\"]*"/gi,
        `className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[div]+/g,
        `<div className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[span]+/g,
        `<span className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[p ]+/g,
        `<p className="${""}"`
      );
      parsedDescription = parsedDescription.replace(
        /<[font ]+/g,
        `<p className="${""}"`
      );
      return parsedDescription;
    }
  };

  const handleChangeDesc = (event, index) => {
    setTextDesc(event.currentTarget.textContent);
    console.log("TEXT DESC:", textDesc);
  };

  const handleSaveMainDesc = async (e, description, id, rowId) => {
    console.log(selectUnsavedEmailsDetails);
    const matter_id =
      selectUnsavedEmailsDetails?.clientMatters?.items[0]?.matter.id;
    console.log(id);
    if (e !== document.activeElement) {
      const origDescription = description;
      let newDescription = e.currentTarget.innerText;

      if (selectUnsavedEmailsDetails) {
        if (selectUnsavedEmailsDetails.hasOwnProperty("id")) {
          if (selectUnsavedEmailsDetails.id === id) {
            var temp = selectUnsavedEmailsDetails;
            temp.description = e.target.innerHTML;

            setSelectUnsavedEmailsDetails(temp);
          }
        }
      }

      if (textDesc?.length <= 0) {
        const data = {
          id: id,
          description: newDescription,
        };
        const success = await updateRowDesc(data).then(async () => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              // briefId: showBriefId,
              activity: "updated the e-mail description",
              field: "Description",
              current: data.description,
              previous: origDescription,
              appModule: "INBOX",
              rowId: rowId,
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
          });
        });

        console.log("THIS IS ID", id);
        if (success) {
          setResultMessage("Successfully updated.");
          setShowToast(true);
          setMobileLoading(false);
          /*
            CONTINUATION OF MMA-1868.
            PLEASE CHECK LINE 1027 FOR MORE INFO.
          */
          // autoAdjustRowHeight(rowId);
          autoAdjustAllRow();
        }
      } else if (textDesc === description) {
        console.log("2nd condition");
      } else {
        console.log("3rd Condition");

        const data = {
          id: id,
          description: newDescription,
        };
        const success = await updateRowDesc(data)
          .then(async (res) => {
            console.log(res);
            const params = {
              query: mCreateActivity,
              variables: {
                companyId: localStorage.getItem("companyId"),
                clientMatterId: matter_id,
                // briefId: showBriefId,
                activity: "updated the email description",
                field: "Description",
                current: data.description,
                previous: origDescription,
                appModule: "INBOX",
                rowId: rowId,
              },
            };
            const addActivity = await API.graphql(params).then((result) => {
              console.log("addActivity result", result);
            });
          })
          .catch((err) => {
            console.log("ERR", err);
          });
        if (success) {
          setResultMessage("Successfully updated.");
          setShowToast(true);
          setMobileLoading(false);
          /*
              CONTINUATION OF MMA-1868.
              PLEASE CHECK LINE 1027 FOR MORE INFO.
            */
          // autoAdjustRowHeight(rowId);
          autoAdjustAllRow();
        }
      }
    }
  };

  // async function updateClientMatter(data) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       console.log("UPDATING TASKLIST WITH:", data.input);
  //       const taskListQuery = {
  //         query: TASK_LIST_DATA_UPDATE,
  //         variables: {
  //           // id: selectUnsavedEmailsDetails?.taskList?.id || "",
  //           id: data.id,
  //           input: data.input,
  //         },
  //       };
  //       const req = API.graphql(taskListQuery).then((result) => {
  //         console.log("THIS IS THE END RESULT:", result);
  //       });
  //       resolve(req)
  //     } catch (error) {
  //       reject("ERROR IS:", error);
  //     }
  //   })
  // }

  async function updateClientMatter(data) {
    try {
      console.log("UPDATING TASKLIST INPUT:", data.input);

      const taskListMutation = {
        query: TASK_LIST_DATA_UPDATE,
        variables: {
          id: data.id,
          input: data.input,
        },
      };
      console.log("UPDATING TASKLIST MUTATION:", taskListMutation);

      const result = await API.graphql(taskListMutation);
      console.log("THIS IS THE END RESULT:", result);
      return result;
    } catch (error) {
      throw new Error("UPDATING TASKLIST ERROR:" + error);
    }
  }

  async function updateRowDesc(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: isOutlookIntegrated
            ? mUpdateRowDescriptionOutlook
            : mUpdateRowDescription,
          variables: {
            id: data.id,
            description: data.description,
          },
        });

        const newArrDescription = unSavedEmails?.map((emails) => {
          if (emails.id === data.id) {
            return { ...emails, description: data.description };
          }

          return emails;
        });
        setUnsavedEmails(newArrDescription);

        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  function checkArrLength(arrLength) {
    try {
      if (arrLength?.items && Array.isArray(arrLength.items)) {
        // added Array.isArray to check for correct data type
        const arr = arrLength.items.length > 0;
        return arr;
      } else {
        return false;
      }
    } catch (e) {
      console.log("error in uat data", e);
      return false;
    }
  }

  function checkEnable(id) {
    const arr = enabledArrays.find((element) => element === id);
    return arr;
  }

  const filterNewlyAddedLabels = (obj, clientMatterId) => {
    if (
      clientMatterId.length > 0 &&
      obj.client == clientMatterId[0].client.id &&
      obj.matter == clientMatterId[0].matter.id
    ) {
      return true;
    }
    return false;
  };

  /**
   * Filters duplicate labels based on specific conditions.
   *
   * @param {Array} arr - The array of labels to filter.
   * @param {Object} e - The label object to compare.
   * @param {Array} clientMatterId - An array containing client and matter IDs.
   * @returns {boolean} - True if the label is a duplicate, false otherwise.
   */
  const filterDuplicateLabels = (arr, e, clientMatterId) => {
    // Check if the values, client ID, and matter ID match
    if (
      arr.value === e.value &&
      arr.client === clientMatterId[0].client.id &&
      arr.matter === clientMatterId[0].matter.id
    ) {
      return true; // The label is a duplicate
    }
    return false; // The label is not a duplicate
  };

  const getUserTypes = async () => {
    try {
      const params = {
        query: DEFAULT_USERTYPES_QUERY,
      };

      const userTypes = await API.graphql(params);
      const defaultUserType = userTypes?.data?.defaultUserType;

      if (defaultUserType) {
        setUserTypes(defaultUserType);
      }
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  const handleAddLabel = async (e, gmid, index, clientMatterId) => {
    console.log(
      "handleAddLabel args:\ne: ",
      e,
      "\ngmid: ",
      gmid,
      "\nindex: ",
      index,
      "\nclientMatterId: ",
      clientMatterId
    );
    const selectedLabels = [];
    const taggedLabels = [];
    const taggedLabelSave = [];
    setLoadLabels(true);

    console.log("======e.length:", e.length);

    // LOOP
    for (let i = 0; i < e.length; i++) {
      if (e[i].value !== e[i].label) {
        console.log("handleAddLabel flowcontrol 1 start");
        //selectedLabels.push(e[i].value);
        taggedLabels.push({ id: e[i].value, name: e[i].label });

        // taggedLabelSave.push(e[i].value);
        // console.log("Added Labels1", taggedLabelSave, "len:", taggedLabelSave.length);

        // // Save Background Label to existing labels
        // await API.graphql({
        //   query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
        //   variables: {
        //     labelId: taggedLabelSave,
        //     gmailMessageId: gmid,
        //   },
        // }).then(async (result) => {
        //   console.log("Result add email label", result);
        //   //console.log("unsaved Emails", unSavedEmails);
        // });

        const createLabelParams = {
          query: mCreateLabel,
          variables: {
            clientMatterId: clientMatterId[0].id,
            name: e[i].label,
          },
        };

        const result = await API.graphql(createLabelParams);

        selectedLabels.push(result.data.labelCreate.id);
        // taggedLabels.push({ id: result.data.labelCreate.id, name: e[i].label });
        // const rebuildObject = {
        //   label: e[i].label,
        //   value: result.data.labelCreate.id,
        // };

        // console.log("Added Labels", taggedLabels);
        // const findDuplicates = (arr) =>
        //   arr.filter((lbl, i) => arr.indexOf(lbl) === i);
        // setNewlyAddedLabels((currNewlyAddedLabels) => {
        //   return findDuplicates([...currNewlyAddedLabels, rebuildObject]);
        // });

        console.log("handleAddLabel flowcontrol 1 end");
      } else {
        console.log("handleAddLabel flowcontrol 2 start");
        const createLabelParams = {
          query: mCreateLabel,
          variables: {
            clientMatterId: clientMatterId[0].id,
            name: e[i].label,
          },
        };

        const result = await API.graphql(createLabelParams);
        console.log("created", result);

        const createBriefParams = {
          query: CREATE_BRIEF_MUTATION,
          variables: {
            clientMatterId: clientMatterId[0].id,
            name: e[i].label,
            date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
            order: 0,
            columnIds: [0, 1, 2, 3, 4],
          },
        };

        const brief = await API.graphql(createBriefParams);
        const getID = brief.data.briefCreate.id;

        const backgroundTable = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: clientMatterId[0].id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns?.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj?.presets?.filter((preset) => preset.id !== getID),
                { id: getID, name: e[i].label },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });

          console.log("updateBriefTable", updateBriefTable);
        }

        if (userTypes?.includes(e[i].label.toUpperCase())) {
          console.log("BRIEF NAME", true);
          const bId = getID;
          const temp = ["LEGALADMIN", "LAWYER"];
          const tempFinal = temp?.includes(e[i].label.toUpperCase())
            ? temp
            : ["LEGALADMIN", "LAWYER", e[i].label.toUpperCase()];

          const params = {
            query: mAddUserAcces,
            variables: {
              briefId: bId,
              userType: tempFinal,
            },
          };

          // Perform the necessary action with params
        }

        selectedLabels.push(result.data.labelCreate.id);
        taggedLabels.push({ id: result.data.labelCreate.id, name: e[i].label });
        const rebuildObject = {
          label: e[i].label,
          value: result.data.labelCreate.id,
        };

        console.log("Added Labels2", taggedLabels);
        const findDuplicates = (arr) =>
          arr?.filter((lbl, i) => arr.indexOf(lbl) === i);
        setNewlyAddedLabels((currNewlyAddedLabels) => {
          return findDuplicates([...currNewlyAddedLabels, rebuildObject]);
        });

        console.log("handleAddLabel flowcontrol 2 end");
      }
    }
    // END LOOP

    const labelId = e.length > 0 ? selectedLabels : [];

    let queryVariables = {
      query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
      variables: {
        labelId: labelId,
        gmailMessageId: gmid,
      },
    };
    const result = await API.graphql(queryVariables);

    const newArrLabels = unSavedEmails;

    newArrLabels.forEach((emails) => {
      if (emails.id === gmid && emails.labels !== null) {
        emails.labels.items = taggedLabels;
      }
    });

    console.log("PRINTING ALL LABELS", [...labelsOptions, ...newlyAddedLabels]);
    setUnsavedEmails(newArrLabels);
    setMobileLoading(false);
    /*
      CONTINUATION OF MMA-1868.
      PLEASE CHECK LINE 1027 FOR MORE INFO.
    */
    // autoAdjustRowHeight(index);
    autoAdjustAllRow();
    // loadedLabels.current = false;
    setLoadedLabels((prev) => prev.filter((e) => e !== gmid));

    setLoadLabels(false);
  };

  // Optimized handle add label function
  const handleAddLabel1 = async (e, gmid, index, clientMatterId, action) => {
    console.group("handleAddLabel1");
    console.log(
      "handleAddLabel args:\ne: ",
      e,
      "\ngmid: ",
      gmid,
      "\nindex: ",
      index,
      "\nclientMatterId: ",
      clientMatterId
    );

    const selectedLabels = [];
    const taggedLabels = [];
    const taggedLabelSave = [];

    console.log("======e.length:", e.length);
    const origArray = [...e];

    var currentlabels = unSavedEmails.find((e) => e.id === gmid)?.labels?.items;
    currentlabels = currentlabels ? currentlabels : [];

    // FILTER CURRENT LABELS TO CONTAIN ONLY UNREMOVED LABELS
    const currentLabelsWithoutRemovedLabels = currentlabels.filter(
      (unsavedLabel) => e.some((label) => unsavedLabel.id === label.value)
    );

    // ADD CURRENT LABELS TO TAG
    selectedLabels.push(...currentLabelsWithoutRemovedLabels.map((e) => e.id));
    taggedLabels.push(...currentLabelsWithoutRemovedLabels);

    // FILTER E TO CONTAIN ONLY ADDED LABELS
    e = e.filter(
      (label) =>
        !currentlabels.some((unsavedLabel) => unsavedLabel.id === label.value)
    );
    if (hbaDomains.includes(currUrl) || currUrl === "localhost") {
      let filtered = removeHBALabels(e);
      if (filtered.length < e.length) {
        alert("You can't add that as a Label");
        e = filtered;
      }
    }

    // EARLY EXIT CONDITION IF THERE ARE NO CHANGES WITHIN LABELS
    // This is needed because when changing the value (selectUnsavedEmailsDetails)
    // of the dropdownSelect, it triggers the onChange callback when
    // calling setState to selectUnsavedEmailsDetails
    if (
      e.length === 0 &&
      currentlabels.length === currentLabelsWithoutRemovedLabels.length
    ) {
      setLoadLabels(false);
      return;
    }

    try {
      for (const item of e) {
        if (item.value !== item.label) {
          console.log("handleAddLabel flowcontrol 1 start");

          selectedLabels.push(item.value);
          taggedLabels.push({
            id: item.value,
            name: item.label,
          });

          const params = {
            query: mCreateActivity,
            variables: {
              companyId: companyId,
              activity: "Add Label",
              field: "Label",
              appModule: "INBOX",
              previous: origArray,
            },
          };
          const addActivity = await API.graphql(params).then((result) =>
            console.log("addActivity", result)
          );
          console.log("handleAddLabel flowcontrol 1 end");
        } else {
          console.log("handleAddLabel flowcontrol 2 start");

          const createLabelParams = {
            query: mCreateLabel,
            variables: {
              clientMatterId: clientMatterId[0].id,
              name: item.label,
            },
          };

          const result = await API.graphql(createLabelParams);
          console.log("created", result);

          const createBriefParams = {
            query: CREATE_BRIEF_MUTATION,
            variables: {
              clientMatterId: clientMatterId[0].id,
              name: item.label,
              date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
              order: 0,
              columnIds: [0, 1, 2, 3, 4],
            },
          };

          const brief = await API.graphql(createBriefParams);
          const getID = brief.data.briefCreate.id;

          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId[0].id,
            },
          });

          if (backgroundTable?.data?.backgroundTable) {
            const { id, columns } = backgroundTable?.data?.backgroundTable;

            const updatedBriefs = columns?.map((obj) => {
              if (parseInt(obj.accessorKey) !== 3) {
                const presetsToUse = [
                  ...obj?.presets?.filter((preset) => preset.id !== getID),
                  { id: getID, name: item.label },
                ];

                return { ...obj, presets: presetsToUse };
              } else {
                return obj;
              }
            });

            const variables = {
              id,
              input: {
                columns: updatedBriefs,
              },
            };

            const updateBriefTable = await API.graphql({
              query: UPDATE_BACKGROUND_TABLE_MUTATION,
              variables,
            });

            console.log("updateBriefTable", updateBriefTable);
          }

          if (userTypes?.includes(item.label.toUpperCase())) {
            console.log("BRIEF NAME", true);
            const bId = getID;
            const temp = ["LEGALADMIN", "LAWYER"];
            const tempFinal = temp?.includes(item.label.toUpperCase())
              ? temp
              : ["LEGALADMIN", "LAWYER", item.label.toUpperCase()];

            const params = {
              query: mAddUserAcces,
              variables: {
                briefId: bId,
                userType: tempFinal,
              },
            };

            // Perform the necessary action with params
          }

          selectedLabels.push(result.data.labelCreate.id);
          taggedLabels.push({
            id: result.data.labelCreate.id,
            name: item.label,
          });

          console.log("handleAddLabel flowcontrol 2 end");
        }
      }

      let queryVariables = {
        query: isOutlookIntegrated ? mAddEmailLabelOutlook : mAddEmailLabel,
        variables: {
          labelId: selectedLabels,
          gmailMessageId: gmid,
        },
      };
      const result = await API.graphql(queryVariables);
      console.log("result", result);

      const newArrLabels = unSavedEmails;
      console.log("taggedLabels", taggedLabels);

      newArrLabels.forEach((emails) => {
        if (emails.id === gmid) {
          emails.labels.items = taggedLabels;
        }
      });

      console.log("PRINTING ALL LABELS", [
        ...labelsOptions,
        ...newlyAddedLabels,
      ]);
      setUnsavedEmails(newArrLabels);
      setMobileLoading(false);

      // autoAdjustRowHeight(index);
      autoAdjustAllRow();
      // loadedLabels.current = false;
      setLoadedLabels((prev) => prev.filter((e) => e !== gmid));

      setLoadLabels(false);
    } catch (error) {
      console.error("Error in handleAddLabel:", error);
    }
    console.groupEnd("handleAddLabel1");
  };

  const handleAddEmailAttachmentLabel = async (
    e,
    atid,
    rowId,
    index,
    clientMatterId
  ) => {
    var selectedLabels = [];
    var taggedLabels = [];

    for (var i = 0; i < e.length; i++) {
      if (e[i].value !== e[i].label) {
        //IF LABEL IS NOT NEW
        selectedLabels = [...selectedLabels, e[i].value];
        taggedLabels = [...taggedLabels, { id: e[i].value, name: e[i].label }];
      } else {
        const createBriefParams = {
          query: CREATE_BRIEF_MUTATION,
          variables: {
            clientMatterId: clientMatterId[0].id,
            name: e[i].label,
            date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
            order: 0,
            columnIds: [0, 1, 2, 3, 4],
          },
        };

        const brief = await API.graphql(createBriefParams);
        const getID = brief.data.briefCreate.id;

        const backgroundTable = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: clientMatterId[0].id,
          },
        });

        if (backgroundTable?.data?.backgroundTable) {
          const { id, columns } = backgroundTable?.data?.backgroundTable;

          const updatedBriefs = columns?.map((obj) => {
            if (parseInt(obj.accessorKey) !== 3) {
              const presetsToUse = [
                ...obj?.presets?.filter((preset) => preset.id !== getID),
                { id: getID, name: e[i].label },
              ];

              return { ...obj, presets: presetsToUse };
            } else {
              return obj;
            }
          });

          const variables = {
            id,
            input: {
              columns: updatedBriefs,
            },
          };

          const updateBriefTable = await API.graphql({
            query: UPDATE_BACKGROUND_TABLE_MUTATION,
            variables,
          });

          console.log("updateBriefTable", updateBriefTable);
        }

        const createLabelParams = {
          query: mCreateLabel,
          variables: {
            clientMatterId: clientMatterId[0].id,
            name: e[i].label,
          },
        };

        const result = await API.graphql(createLabelParams);
        console.log("created", result);

        if (userTypes?.includes(e[i].label.toUpperCase())) {
          console.log("BRIEF NAME", true);
          const bId = getID;
          const temp = ["LEGALADMIN", "LAWYER"];
          const tempFinal = temp?.includes(e[i].label.toUpperCase())
            ? temp
            : ["LEGALADMIN", "LAWYER", e[i].label.toUpperCase()];

          const params = {
            query: mAddUserAcces,
            variables: {
              briefId: bId,
              userType: tempFinal,
            },
          };

          // Perform the necessary action with params
        }

        selectedLabels.push(result.data.labelCreate.id);
        taggedLabels.push({ id: result.data.labelCreate.id, name: e[i].label });
        const rebuildObject = {
          label: e[i].label,
          value: result.data.labelCreate.id,
        };
        const pendingArray = [...newlyAddedLabels, rebuildObject];
        const findDuplicates = (arr) =>
          arr?.filter((lbl, i) => arr.indexOf(lbl) === i);
        setNewlyAddedLabels(findDuplicates(pendingArray));
      }
    }

    if (e.length > 0) {
      const result = await API.graphql({
        query: isOutlookIntegrated
          ? mAddOutlookAttachmentLabel
          : mAddEmailAttachmentLabel,
        variables: {
          labelId: selectedLabels,
          attachmentId: atid,
        },
      });
    } else {
      const result = await API.graphql({
        query: isOutlookIntegrated
          ? mAddOutlookAttachmentLabel
          : mAddEmailAttachmentLabel,
        variables: {
          labelId: [],
          attachmentId: atid,
        },
      });
    }

    var objIndex = unSavedEmails?.findIndex((obj) => obj.id === rowId);

    const itemsAttachments = unSavedEmails[objIndex]?.attachments?.items?.map(
      (x) => (x.id === atid ? { ...x, labels: { items: taggedLabels } } : x)
    );

    const updateArrAttachment = unSavedEmails;
    updateArrAttachment?.map((obj) => {
      if (obj.id === rowId) {
        obj.attachments.items = itemsAttachments;
      }
    });

    console.log("updateArr", updateArrAttachment);

    setUnsavedEmails(updateArrAttachment);
    setMobileLoading(false);
    autoAdjustRowHeight(index);
  };

  const defaultLabels = (items) => {
    if (items !== null) {
      const newOptions = items?.map(({ id: value, name: label }) => ({
        value,
        label,
      }));
      console.log("DEFAULT LABELS", newOptions);
      return newOptions;
    } else {
      return [];
    }
  };

  const previewAndDownloadFile = async (id) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetFileDownloadLink
        : qGmailGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = isOutlookIntegrated
        ? result.data.outlookAttachment
        : result.data.gmailAttachment;

      if (
        (type &&
          (type?.includes("vnd.openxmlformats-officedocument") ||
            type?.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  const handleDeleteAttachment = async (id, rowId, index, val, e) => {
    var copyy = selectUnsavedEmailsDetails;
    copyy.attachments.items[index].isDeleted = val;
    setSelectUnsavedEmailsDetails(copyy);

    const params = {
      query: isOutlookIntegrated
        ? mUpdateOutlookAttachmentStatus
        : mUpdateAttachmentStatus,
      variables: {
        id: id,
        isDeleted: val,
      },
    };

    await API.graphql(params).then((result) => {
      setAttachmentIsDeleted(val);
      setAttachmentId(index);

      var objIndex = unSavedEmails.findIndex((obj) => obj.id === rowId);

      const itemsAttachments = unSavedEmails[objIndex]?.attachments?.items?.map(
        (x) => (x.id === id ? { ...x, isDeleted: val } : x)
      );

      var updateArrAttachment = unSavedEmails?.map((obj) => {
        if (obj.id === rowId) {
          return { ...obj, attachments: { items: itemsAttachments } };
        }
        return obj;
      });
      setSelectUnsavedEmailsDetails(unSavedEmails[objIndex]);

      setUnsavedEmails(updateArrAttachment);
      setMobileLoading(false);
      console.log("cccc", unSavedEmails[objIndex]);
    });
  };

  const handleOnKeyupRows = (e, rowId) => {
    autoAdjustRowHeight();
  };

  const autoAdjustRowHeight = (index) => {
    //bindList and cache must not be null
    if (bindList && cache) {
      cache?.current.clear(index);
      bindList?.current?.recomputeRowHeights(index);
      console.log("success readjust");
      console.log("index ->", index);
    } else {
      console("List reference not found || cache not found!");
    }

    //adjustAllRow when height of this row is adjusted
    autoAdjustAllRow();
  };

  const autoAdjustAllRow = async () => {
    try {
      if (bindList && cache) {
        cache?.current.clearAll();
        //while loop is recommended fastest loop

        var i = 0,
          len = unSavedEmails.length;
        while (i < len) {
          setTimeout(() => {
            bindList?.current?.recomputeRowHeights(i);
          }, 100);

          i++;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const CustomClearIndicator = () => null;

  //Reduce rerendering
  useEffect(() => {
    autoAdjustAllRow();
  }, [unSavedEmails, show, width]);

  useEffect(() => {
    if (userTypes.length === 0) {
      getUserTypes();
    }

    console.log("userTypes:", userTypes);
  }, [userTypes]);

  const getBriefs = async (matter_id) => {
    console.log("matterid", matter_id);
    const params = {
      query: listBriefs,
      variables: {
        id: matter_id,
        limit: 300,
        nextToken: null,
      },
    };

    await API.graphql(params).then((brief) => {
      const briefsList = brief.data.clientMatter.briefs.items;
      const returnBrief = briefsList
        ?.filter((value) => value !== undefined)
        ?.map((item) => {
          if (item?.userTypeAccess?.includes(activeUserType)) {
            return item;
          }
        });
      setFilterBriefsList(returnBrief);
    });
  };

  const handleOnMenuOptions = async (clientMatterId) => {
    var selectedClientId = clientMatterId[0].id;
    var mainLabels = labelsList;

    const params = {
      query: listBriefs,
      variables: {
        id: selectedClientId,
        limit: 300,
        nextToken: null,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief.data.clientMatter.briefs.items;

      const returnBrief = await briefsList
        ?.filter((value) => value !== undefined)
        ?.map((item) => {
          if (
            item?.userTypeAccess?.includes(activeUserType) ||
            activeUserType === "OWNER"
          ) {
            return item;
          }
        });
      
      console.log('BRIEF LIST::', briefsList )

      const filteredUndefinedBriefs = returnBrief?.filter(
        (value) => value !== undefined
      );

      console.log("after brieflist")

      if (filteredUndefinedBriefs?.length !== 0) {

        console.log("filteredUndefinedBriefs", filteredUndefinedBriefs)
        console.log("mainLabels", mainLabels)

        const newOptions = filteredUndefinedBriefs?.map(({ id: value, name: label }) => ({
          value,
          label,
        }));

        let filteredOptions = removeDuplicateLabels(newOptions);
        console.log('FILTERED BRIEFLIST::', filteredOptions)
        if (hbaDomains.includes(currUrl) || currUrl === "localhost") {
          filteredOptions = filterHBALabels(filteredOptions);
        }
              
        setEnableDropdown(true);
        setLabelsOptions(filteredOptions);

      //  mainLabels?.map( (item) => {
          // if (item.labelsExtracted !== undefined) {
          //   console.log("entered", item.labelsExtracted)
          //   console.log("item.cmid", item.cmid)
          //   console.log("selectedClientId", selectedClientId)

          //   if (item.cmid === selectedClientId) { //this line is causing the labels not to show
          //     console.log("Matched")
          //     var filteredDataPoints = [];

          //     if (activeUserType !== "OWNER") {
          //       filteredDataPoints = item.labelsExtracted
          //         ?.filter((extractLabel) =>
          //           filteredUndefinedBriefs.some(
          //             (filteredLabel) =>
          //               extractLabel.name === filteredLabel.name
          //           )
          //         )
          //         ?.map((item) => ({ id: item.id, name: item.name }));
          //       console.log("total array", filteredDataPoints);
          //     } else {
          //       filteredDataPoints = item.labelsExtracted;
          //     }

          //     const newOptions = await filteredDataPoints?.map(
          //       ({ id: value, name: label }) => ({
          //         value,
          //         label,
          //       })
          //     );

          //     let filteredOptions = removeDuplicateLabels(newOptions);
          //     console.log('FILTERED BRIEFLIST::', filteredOptions)
          //     if (hbaDomains.includes(currUrl) || currUrl === "localhost") {
          //       filteredOptions = filterHBALabels(filteredOptions);
          //     }
              
          //     setEnableDropdown(true);
          //     setLabelsOptions(filteredOptions);
          //   }else{
          //     console.log("No match", briefsList)
          //   }
          // } else {
          //   console.log("None")

          //   return [];
          // }
        // });
      } else {
        // setLabelsOptions([]);
        console.log("No assigned usetypeaccess for this clientmatter briefs");
        const newOptions = briefsList?.map(({ id: value, name: label }) => ({
          value,
          label,
        }));
        let filteredOptions = removeDuplicateLabels(newOptions);
        if (hbaDomains.includes(currUrl) || currUrl === "localhost") {
          filteredOptions = filterHBALabels(filteredOptions);
        }
        setLabelsOptions(filteredOptions);
        console.log("Tagged by default all related briefs");
      }
    });
  };

  function removeHBALabels(objArray) {
    let removeLabels = [
      "Progress of Work",
      "Project Location",
      "Builder Details",
      "Contract Details",
    ];
    var result = objArray?.filter(
      (obj) => removeLabels.indexOf(obj.label) === -1
    );

    return result;
  }

  function filterHBALabels(objArray) {
    let removeLabels = [
      "Progress of Work",
      "Project Location",
      "Builder Details",
      "Contract Details",
    ];

    var result = objArray?.map((obj) => {
      if (removeLabels.indexOf(obj.label) !== -1)
        return { ...obj, disabled: true };
      return obj;
    });

    return result;
  }
  function removeDuplicateLabels(objArray) {
    var uniqueLabels = [];
    var result = objArray?.filter(function (obj) {
      if (uniqueLabels.indexOf(obj.label) === -1) {
        uniqueLabels.push(obj.label);
        return true;
      } else {
        return false;
      }
    });
    return result;
  }

  // MMA-1983: Add unsaved emails to Tasklist Dashboard
  const handleSelectForTaskList = async (
    e,
    selectUnsavedEmailsDetails,
    itemid,
    action
  ) => {
    const { id, checked } = e.target;
    console.log("Clicked tasklist button");
    console.log("action:", action);

    if (action === "add") {
      await saveNotesIdLocalStorage(itemid);
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: companyId,
          activity: `Email added to task list`,
          appModule: "INBOX",
        },
      };

      // Await the API.graphql call
      const addActivity = await API.graphql(params);
      console.log("add activity result", addActivity, itemid);
    }

    if (action === "remove") {
      await removeNotesIdLocalStorage(itemid);
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: companyId,
          activity: `Email remove to task list`,
          appModule: "INBOX",
        },
      };

      // Await the API.graphql call
      const removeActivity = await API.graphql(params);
      console.log("remove activity result", removeActivity, itemid);
    }

    if (width > 844) {
      console.log("Executed on Desktop");
      if (selectedTaskList?.includes(itemid)) {
        setSelectedTaskList(
          selectedTaskList?.filter((item) => item !== itemid)
        );
      } else {
        setSelectedTaskList([...selectedTaskList, itemid]);
      }
      autoAdjustAllRow();
    } else {
      console.log("Executed on Mobile");
      if (!selectedTaskList.includes(itemid)) {
        setSelectedTaskList([...selectedTaskList, itemid]);
        setSelectedGreen((prevState) => {
          if (!prevState.includes(itemid)) {
            return [...prevState, itemid];
          }
          return prevState;
        });
      }
      if (!checked) {
        console.log(selectUnsavedEmailsDetails);
        savePriority(
          { value: "", label: "" },
          itemid,
          selectUnsavedEmailsDetails.notes
        );
        setSelectedTaskList(
          selectedTaskList?.filter(
            (item) => item !== selectUnsavedEmailsDetails.id
          )
        );
        setSelectedGreen(
          selectedGreen?.filter(
            (item) => item !== selectUnsavedEmailsDetails.id
          )
        );
        console.log("to tasklist", selectedTaskList);
      }
      autoAdjustAllRow();
    }
  };

  // // MMA-1981 - Task Register
  // const handleSelectForTaskRegister = (e, counter, itemid) => {
  //   if (selectedTaskRegister.find((item) => item.id === itemid)) {
  //     setSelectedTaskRegister((prev) =>
  //       prev?.filter((item) => item.id !== itemid)
  //     );
  //     autoAdjustAllRow();
  //     //console.log("2594 you are here")
  //   } else {
  //     const { id } = e.target;
  //     if (selectedTaskRegister.find((item) => item.id === id)) {
  //       setSelectedTaskRegister((prev) =>
  //         prev?.filter((item) => item.id !== id)
  //       );
  //       //console.log("2594 removing " + e.target.id + " " + itemid);
  //       console.log(selectedTaskRegister?.filter((item) => item.id !== id));
  //     } else {
  //       setSelectedTaskRegister((prev) => [...prev, { id, registers: [] }]);
  //       //  console.log("2594 adding " + e.target.id + " " + itemid);
  //       console.log([...selectedTaskRegister, { id, registers: [] }]);
  //     }

  //     // Disable the other checkbox on checking this box
  //     // if (selectedTaskList.includes(id)) {
  //     //   setSelectedTaskList(selectedTaskList.filter((item) => item !== id));
  //     // }
  //     autoAdjustAllRow();
  //   }
  // };

  // MMA-1981 - Task Register
  const handleSelectForTaskRegister = (e, counter, itemid) => {
    if (selectedTaskRegister.find((item) => item.id === itemid)) {
      setSelectedTaskRegister((prev) =>
        prev?.filter((item) => item.id !== itemid)
      );
      localStorage.setItem(
        "taskRegisterIds",
        JSON.stringify(
          selectedTaskRegister.filter((item) => item.id !== itemid)
        )
      );
      autoAdjustAllRow();
    } else {
      const { id } = e.target;
      if (selectedTaskRegister.find((item) => item.id === id)) {
        setSelectedTaskRegister((prev) =>
          prev?.filter((item) => item.id !== id)
        );
        localStorage.setItem(
          "taskRegisterIds",
          JSON.stringify(selectedTaskRegister.filter((item) => item.id !== id))
        );
      } else {
        const updatedTaskRegister = [
          ...selectedTaskRegister,
          { id, registers: [] },
        ];
        setSelectedTaskRegister(updatedTaskRegister);
        localStorage.setItem(
          "taskRegisterIds",
          JSON.stringify(updatedTaskRegister)
        );
      }

      autoAdjustAllRow();
    }
  };

  // Temp save, no api yet
  const [taskRegisters, setTaskRegisters] = useState([]);

  const handleCreateTaskRegister = (id, newRegister) => {
    // Add functionality that when new register is added, it should be added to the entire library
    setTimeout(() => {
      const newOption = {
        label: newRegister,
        value: newRegister.toLowerCase().replace(/\W/g, ""),
      };
      setTaskRegisterList((prev) => [...prev, newOption]);
      setTaskRegisters((prev) => [...prev, { id, registers: [newOption] }]);
    }, 0);
  };

  // const saveTaskRegister = (id, registers) => {
  //   console.log("Tasklist Register", taskRegisterList);
  //   console.log(selectedTaskList);
  //   console.log(selectedTaskRegister);
  //   console.log("saving ", id, registers);
  //   setSelectedTaskRegister((prev) =>
  //     prev?.map((item) => (item.id === id ? { id, registers } : item))
  //   );
  //   autoAdjustAllRow();

  //   console.log("selectedTaskRegister", selectedTaskRegister);
  // };

  const saveTaskRegister = (id, registers) => {
    console.log("Tasklist Register", taskRegisterList);
    console.log(selectedTaskList);
    console.log(selectedTaskRegister);
    console.log("saving ", id, registers);

    // Check if selectedTaskRegister is in local storage
    const storedTaskRegistersJSON = localStorage.getItem("gmailTaskRegisters");
    const storedTaskRegisters = storedTaskRegistersJSON
      ? JSON.parse(storedTaskRegistersJSON)
      : [{ id, registers }];
    // Check if there is a duplicate entry
    const isDuplicate = storedTaskRegisters.some((item) => item.id === id);

    if (isDuplicate) {
      // If duplicate, update the existing entry
      const updatedTaskRegisters = storedTaskRegisters.map((item) =>
        item.id === id ? { id, registers } : item
      );
      localStorage.setItem(
        "gmailTaskRegisters",
        JSON.stringify(updatedTaskRegisters)
      );
    } else {
      // If not a duplicate, add a new entry
      const updatedTaskRegisters = [...storedTaskRegisters, { id, registers }];
      localStorage.setItem(
        "gmailTaskRegisters",
        JSON.stringify(updatedTaskRegisters)
      );
    }

    setSelectedTaskRegister((prev) =>
      prev?.map((item) => (item.id === id ? { id, registers } : item))
    );
    autoAdjustAllRow();

    console.log("selectedTaskRegister", selectedTaskRegister);
  };

  const getTaskRegisters = (id) => {
    const email = taskRegisters.find((itemId) => itemId === id);
    console.log(taskRegisters);
    return email && email.registers ? email.registers : [];
  };

  const selectStyle = {
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 50,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(3600deg)",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 50,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "white" : provided.backgroundColor,
    }),
  };

  const selectStyle1 = {
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 50,
      //height: "120px",
      //overflow: "hidden",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(3600deg)",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 50,
      //height: "120px",
      //overflow: "hidden",
    }),
  };

  //archive functions

  const handleSingleArchive = async (itemId, index) => {
    setSaveLoading(true);

    function checkId(id) {
      return id === itemId;
    }

    function checkId1(id) {
      return id !== itemId;
    }

    // Added from Archived Email
    let arrArchivedEmails = unSavedEmails?.filter((x) => checkId(x.id));
    var arrByDates = sortByDate(archivedEmails.concat(arrArchivedEmails));
    setArchivedEmails(arrByDates);
    setArchivedEmailsCount((count) => count + 1);

    // Remove from Unsaved Emails
    let arrRemoveUnSavedEmails = unSavedEmails?.filter((x) => checkId1(x.id));
    console.log(arrRemoveUnSavedEmails, "<- Future");
    console.log(unSavedEmails, "<- Current");
    setUnsavedEmails(sortByDate(arrRemoveUnSavedEmails));
    setUnsavedEmailsCount((count) => count - 1);

    handleScrollIndex(index);
    autoAdjustAllRow();
    setIsArchive(true);
    setMobileLoading(false);

    await API.graphql({
      query: mArchivedEmails,
      variables: {
        companyId: companyId,
        id: itemId,
      },
    }).then((result) => {
      setSaveLoading(false);
      setResultMessage("Successfully archived an email.");
      setShowToast(true);
      setSelectedArchivedItems([]);
    });
  };

  //KJMF COPY NEW
  const [copyDetails, setCopyDetails] = useState([]);

  const handleCopy = async (rowId, cm, rowDetails) => {
    console.log("check being copied", rowDetails);
    setProcPaste(true);
    setCopyDetails(rowDetails);

    try {
      console.log("cm", rowDetails);
      var cmname = cm.items[0].client?.name + "/" + cm.items[0].matter?.name;
      var cmAssignee = rowDetails.assignee;
      var cmPriority = rowDetails.priority;
      var cmNotes = rowDetails.notes;

      /* Reconstruct labels from provided user data to enable
        passing of data to handleAddLabel function for later */
      const reconstructLabels = [];
      if (rowDetails.labels) {
        for (let i = 0; i < rowDetails.labels.items.length; i++) {
          reconstructLabels.push({
            value: rowDetails.labels.items[i].id,
            label: rowDetails.labels.items[i].name,
          });
        }
      }

      //selected for saving emails
      // if (!selectedUnsavedItems?.includes(rowId)) {
      //   setSelectedUnsavedItems([...selectedUnsavedItems, rowId]);
      // }

      // console.log("currently selected", selectedUnsavedItems)

      // ADDS DEFAULT TASKREGISTER
      // if (!selectedTaskRegister.find((item) => item.id === rowId)) {
      //   setSelectedTaskRegister((prev) => [...prev, { id: rowId, registers: [] } ]);
      //   saveTaskRegister(rowId, [taskRegisterList.find(
      //       (register) =>
      //         register?.label.toLowerCase().replace(/ /g, "") === "tasklist"
      //     ),
      //   ]);
      // }

      setCmDetails(reconstructLabels);
      setCopyCmId(cm.items[0].id);
      setCopyCm(cmname);

      setCopyAssignee(cmAssignee);
      setCopyPriority(cmPriority);
      setCopyNotes(cmNotes);

      setShowCopy(false);
      setCopyId(rowId);
      /*
        CONTINUATION OF MMA-1868.
        PLEASE CHECK LINE 1027 FOR MORE INFO.
      */
    } catch (error) {
      console.error(`Encountered an error for Copying Email \n${error}`);
    }
  };

  //IMPROVED SPEED OF SINGLE PASTE
  const [procPaste, setProcPaste] = useState(false);

  // useEffect(() => {
  //   /* Creating an array of all the elements with the class name 'saved-for-tasklist' */
  //   const forTaskListCheckboxClass = Array.from(
  //     document.getElementsByClassName("unsaved-for-tasklist")
  //   );

  //   let count = [];
  //   forTaskListCheckboxClass.forEach((checkbox) => {
  //     if (
  //       checkbox.checked &&
  //       // && !checkbox.disabled
  //       !count.includes(checkbox.id)
  //     ) {
  //       count.push(checkbox.id);
  //     }
  //   });
  //   setSelectedGreen(count);
  //   setUnsavedForTaskListCtr(count.length);
  // }, [unSavedEmails]);

  // if(selectedTaskList.includes(gmailMessageId)){
  //   setUnsavedForTaskListCtr(selectedTaskRegister.length);
  // }

  const [selectedGreen, setSelectedGreen] = useState([]);

  useEffect(() => {
    setUnsavedForTaskListCtr(selectedGreen.length);
    console.log("ctr", selectedGreen.length, selectedTaskList);
  }, [selectedGreen]);

  // useEffect(() => {
  //   console.log("current val procPaste ->", procPaste);
  // }, [procPaste]);

  useEffect(() => {
    console.group("SELECTED TASK REGISTER AND TASK LIST");
    console.log(selectedTaskRegister);
    console.log(selectedTaskList);
    console.groupEnd();

    // Save selectedTaskRegister to local storage
    // const existingTaskRegister = JSON.parse(localStorage.getItem("taskRegisterIds"));

    // if (existingTaskRegister !== null && existingTaskRegister.length !== 0) {
    // If local storage has a non-null and non-empty array, use it
    // localStorage.setItem("taskRegisterIds", JSON.stringify(selectedTaskRegister));
    // }
  }, [selectedTaskRegister, selectedTaskList]);

  const handlePaste = async (gmailMessageId, rowId) => {
    console.log("To be pasted", copyDetails);
    setProcPaste(true);

    try {
      //include tile id to the pasted state
      setPastedIds((currPastedIds) => {
        if (!currPastedIds?.includes(gmailMessageId)) {
          return [...currPastedIds, gmailMessageId];
        }
        return currPastedIds;
      });
      console.log(pastedIds);

      //check if a tiled is set to be copied
      if (copyCmId !== "") {
        //labels buffering UI
        setLoadLabels(true);
        setLoadLabelsIndex(rowId);

        const result = await API.graphql({
          query: isOutlookIntegrated
            ? mTagOutlookEmailClientMatter
            : mTagEmailClientMatter,
          variables: {
            clientMatterId: copyCmId,
            gmailMessageId: gmailMessageId,
          },
        }).then((res) => {
          //update frontend UI values
          var objIndex = unSavedEmails.findIndex(
            (obj) => obj.id === gmailMessageId
          );

          unSavedEmails[objIndex].clientMatters.items = [
            {
              id: copyCmId,
              client: { id: "", name: copyCm.split("/")[0] },
              matter: { id: "", name: copyCm.split("/")[1] },
            },
          ];
        });

        toast.success("Successfully pasted");

        //save copied labels
        handleAddLabel(
          cmDetails,
          gmailMessageId,
          rowId,
          unSavedEmails[rowId]?.clientMatters?.items
        );
        //save copied assignee
        handleCopySaveAssignee(copyDetails.assignee, gmailMessageId);
        //save copied priority and notes
        handleCopySavePriorityNotes(
          copyDetails.priority,
          copyDetails.notes,
          gmailMessageId
        );

        setEnabledArrays((currEnabledArrays) => {
          return [...currEnabledArrays, gmailMessageId];
        });

        setSelectedUnsavedItems((currentSelectedUnsavedItems) => {
          /**
           * Checks if the newly inserted item exists already in the list, if not,
           * it will now check if the copied item is checked so that it can copy the
           * state of the copied item (MMA-2231)
           **/
          if (
            !currentSelectedUnsavedItems?.includes(gmailMessageId) &&
            currentSelectedUnsavedItems?.includes(copyId)
          ) {
            return [...currentSelectedUnsavedItems, gmailMessageId];
          }
          return currentSelectedUnsavedItems;
        });
        // checks for checkbox state of tasklist of the copied email
        /* Checks if the copied item exists in the list */
        //WIP:
        if (selectedTaskList?.includes(copyId)) {
          saveNotesIdLocalStorage(gmailMessageId);
          if (!selectedTaskList.includes(gmailMessageId)) {
            setSelectedTaskList((prevState) => [...prevState, gmailMessageId]);
            setSelectedGreen((prevState) => {
              if (!prevState.includes(gmailMessageId)) {
                return [...prevState, gmailMessageId];
              }
              return prevState;
            });
          }
        } else {
          /**
           * Checks if copied item doesn't exist,
           * then unchecks every item where it was pasted to
           **/
          setSelectedTaskList((current) => {
            let temp = current.filter((item) => item !== gmailMessageId);
            return temp;
          });
        }

        // checks for checkbox state of taskregister of the copied email
        if (selectedTaskRegister?.some((item) => item.id === copyId)) {
          console.log("task register");
          const setter = { target: { id: gmailMessageId, checked: true } };
          // handleSelectForTaskRegister(setter);
          if (
            !selectedTaskRegister.find((item) => item.id === gmailMessageId)
          ) {
            setSelectedTaskRegister((prev) => [
              ...prev,
              { id: gmailMessageId, registers: [] },
            ]);
          }
          saveTaskRegister(gmailMessageId, processTaskRegister(copyId));
        } else {
          /**
           * Checks if copied item doesn't exist,
           * then unchecks every item where it was pasted to
           **/
          setSelectedTaskRegister((current) => {
            if (Array.isArray(current)) {
              let temp = current.filter((item) => item.id !== gmailMessageId);
              return temp;
            }
            return current;
          });
          // handleSelectForTaskRegister(setter, null, copyId);
        }
      }

      // MMA-2219 Fix for Infinite updating of labels when data of email pasted to another email
      setTimeout(() => {
        setLoadLabels(false);
      }, 300);

      //Create activity last
      const params = {
        query: mCreateActivity,
        variables: {
          companyId: companyId,
          activity: `copied email`,
          clientMatterId: copyCmId,
          appModule: "INBOX",
          rowId: rowId,
        },
      };
      const addActivity = API.graphql(params).then((result) =>
        console.log("add activity result", result, rowId)
      );
    } catch (error) {
      console.error(`Pasting an Email encountered an Error: \n${error}`);
    }

    autoAdjustAllRow();
  };

  const handleCopyToClipboard = (toCopy) => {
    let textToCopy = ``;

    if (
      toCopy.clientMatters.items[0].client.name !== null &&
      toCopy.clientMatters.items[0].matter.name !== null
    ) {
      textToCopy += `Client Matter: ${toCopy.clientMatters.items[0].client.name}/${toCopy.clientMatters.items[0].matter.name}`;
    } else {
      textToCopy += `Client Matter: No Client Matter Yet`;
    }

    //Check for assignees
    if (toCopy.assignee !== null) {
      if (toCopy.assignee.length > 1) {
        let assignee = toCopy.assignee.replace(",", ", ");
        textToCopy += `\nAssignee: ${assignee}`;
      } else {
        textToCopy += `\nAssignee: ${toCopy.assignee}`;
      }
    } else {
      textToCopy += `\nAssignee: No Assignees Yet`;
    }

    //Check for priority
    toCopy.priority !== null
      ? (textToCopy += `\nPriority: ${toCopy.priority}`)
      : (textToCopy += `\nPriority: No Priority Set`);

    //Check for notes
    toCopy.notes !== null && toCopy.notes !== ""
      ? (textToCopy += `\nNotes: ${toCopy.notes}`)
      : (textToCopy += `\nNotes: No Notes Yet`);

    //Set all the necessary state variables before returning
    setShowCopy(false);
    setCopyId(toCopy.id);

    return navigator.clipboard.writeText(textToCopy);
  };

  //assignee
  const [people, setPeople] = useState([]);
  const [extraPeople, setExtraPeople] = useState([]);

  useEffect(() => {
    if (people && people.length == 0) {
      fetchCompanyUsers();
      console.log("initilize assignee/people list");
    }
  }, []);

  async function fetchCompanyUsers() {
    var assignees = [];
    const res = await API.graphql({
      query: COMPANY_TEAMS_QUERY,
      variables: {
        id: companyId,
      },
    });

    // Insert teams and its members in assignee
    res?.data?.company?.teams?.items?.map(
      (x) =>
        (assignees = [
          ...assignees,
          {
            label: `${x.name}`,
            value: x?.members?.items?.map(
              (y) => `${y.user.firstName} ${y.user.lastName}`
            ),
          },
        ])
    );

    var teamNames = res?.data?.company?.teams?.items ?? [];

    if (companyId === "7a7d3c83-4cb3-49cb-acef-82b7b71054eb") {
      //lop master company id
      var specialTeamId = "";

      /* Finding the team id of the team named "Operations" */
      for (var i = 0; i < teamNames.length; i++) {
        if (teamNames[i].name?.toLowerCase() === "operations") {
          specialTeamId = teamNames[i].id;
          break;
        } else {
          specialTeamId = "0"; //placeholder
        }
      }

      if (specialTeamId === "0") {
        const { data } = await API.graphql({
          query: COMPANY_USERS_QUERY,
          variables: {
            id: companyId,
          },
        });

        data?.company?.users?.items?.map(
          (x) =>
            (assignees = [
              ...assignees,
              {
                label: `${x.firstName} ${x.lastName}`,
                value: `${x.firstName} ${x.lastName}`,
              },
            ])
        );

        setPeople(assignees); //set regular list as options, null catcher
        setExtraPeople(assignees);
      } else {
        const res1 = await API.graphql({
          query: TEAM_MEMBERS_QUERY,
          variables: {
            id: specialTeamId,
          },
        });

        var members = res1.data.team.members.items;
        var teamNames = res1.data.team.name;

        for (var j = 0; j < members.length; j++) {
          assignees = [
            ...assignees,
            {
              label: `${members[j].user.firstName} ${members[j].user.lastName}`,
              value: `${members[j].user.firstName} ${members[j].user.lastName}`,
            },
          ];
        }

        setPeople(assignees); //filtered people
        setExtraPeople(assignees);
      }

      console.log("peoplelist", assignees);
      console.log(teamNames);
    } else {
      const { data } = await API.graphql({
        query: COMPANY_USERS_QUERY,
        variables: {
          id: companyId,
        },
      });

      // Initialize assignees only once outside the forEach

      data?.company?.users?.items?.forEach((user) => {
        // Push new objects directly into the assignees array
        assignees.push({
          label: `${user.firstName} ${user.lastName}`,
          value: user.firstName + " " + user.lastName, // Concatenation is clearer in this case
        });
      });

      setPeople(assignees); // set regular list as options
      setExtraPeople(assignees);
      console.log("peoplelist", assignees); // combine logs if necessary
    }
  }

  const getAssignee = (data) => {
    console.log("getAssignee details", data);

    if (data && (data.length > 0 || data !== null || data !== undefined)) {
      try {
        const people = data?.split(",");
        const temp = people?.map((x) => ({ label: x, value: x }));
        console.log("option", temp);
        return temp;
      } catch (e) {
        console.error(e);
        return null;
      }
    } else {
      return null;
    }
  };

  /*
  const [userClientMatter, setUserClientMatter] = useState({});
  useEffect(()=> {
    console.log("Getting User CLient Matter USEEFFECT")
    getUserClientMatter("leandrinmacalinao@gmail.com");
  },[])

  const getUserClientMatter = async (email) => {
    const data = await API.graphql({
      query: contactsQuery,
      variables: {
        companyId: companyId,
        email: email,
      },
    });
    //console.log("DATA: ", data.data.userByEmail.clientMatterAccess);
    return data.data.userByEmail.clientMatterAccess;
  };
  */

  const handleCopySaveAssignee = async (name, gmailId) => {
    let newPeople = name;

    unSavedEmails?.map((x) =>
      x.id === gmailId ? (x.assignee = newPeople) : x
    );

    if (!newPeople) return;

    const result = await API.graphql({
      query: isOutlookIntegrated ? mSaveAssigneesOutlook : mSaveAssignees, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
      variables: {
        id: gmailId,
        assignee: newPeople,
      },
    });
  };

  //removes members from the options after selecting a team
  const renderPeople = (newPeople) => {
    setPeople(newPeople);
  };

  /*
   *  Dynamically sets the options (on every focus) for each creatableselect instance (MMA-1840).
   *  This solves two problems and does the following:
   *  1.  Dynamically removes the selected options from the options list
   *      without affecting the other creatableselect instances.
   *  2.  Dynamically sets the options list based on the selected options.
   */

  // Function to dynamically set people based on an item
  const dynamicSetPeople = (item) => {
    console.log("dynamicSetPeople()", item);
    // Get the assignee value from the item
    const assigneeValue = getAssignee(item.assignee);

    // Check if the assigneeValue exists and has a length greater than zero
    if (assigneeValue?.length > 0) {
      // If assigneeValue exists, assign it to arrPeople
      let arrPeople = assigneeValue;

      // Filter out people not present in assigneeValue from extraPeople
      const newPeople = extraPeople?.filter(
        (obj) =>
          // Check if neither the value nor label exists in arrPeople
          !arrPeople.some((item) => item.value === obj.value) &&
          !arrPeople.some((item) => item.label === obj.label)
      );

      // Set the newPeople as the value for the setPeople function
      setPeople(newPeople);
    } else {
      // If assigneeValue is undefined or has a length of zero, set extraPeople as the value for setPeople
      setPeople(extraPeople);
    }

    // Log assigneeValue and the full item.assignee for debugging or information
    // console.log("Assignee", getAssignee(item.assignee));
    // console.log("Full", item.assignee);
  };

  const saveAssignee = async (e, em) => {
    console.log("saveAssignee()", e);
    autoAdjustAllRow();
    let selectedPeople = "";
    let newPeople = [];

    if (e.length > 0) {
      for (var i = 0; i < e.length; i++) {
        if (e[i].label !== null) {
          selectedPeople = selectedPeople + `${e[i].value},`; //convert to string
        }
      }
      //Prevents members from duplicating
      var arrPeople = [...new Set(selectedPeople.split(","))];
      const constructOptions = arrPeople?.map((x) => ({ label: x, value: x }));

      newPeople = extraPeople?.filter(
        (obj) =>
          !constructOptions.some(
            (item) => item.value === obj.value || item.label === obj.label
          )
      );

      // setPeople(prevPeople => (prevPeople.length !== newPeople.length ? newPeople : prevPeople));

      /*
       * Removed this because this causes an issue wherein
       * users can't re-select the already selected assignee/s
       * on another email (MMA-1840)
       *
       * Implemented a new solution above for filtering out
       * the selected assignee/s within groups.
       *
       * Revert to this if the new solution causes issues.
       */

      // Join the array elements into a string, separated by commas
      selectedPeople = arrPeople
        .filter((person) => person && person.trim())
        .join(",");
    }

    console.log("Selected assignees--->", selectedPeople);
    unSavedEmails.map((x) =>
      x.id === em.id ? (x.assignee = selectedPeople) : x
    );

    const result = await API.graphql({
      query: isOutlookIntegrated ? mSaveAssigneesOutlook : mSaveAssignees, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
      variables: {
        id: em.id,
        assignee: selectedPeople,
      },
    }).then(function () {
      dynamicSetPeople(em);
    });
  };

  //priority

  const handleCopySavePriorityNotes = async (priority, notes, gmailId) => {
    let newPriority = priority;
    let newNotes = notes;

    unSavedEmails.map((x) =>
      x.id === gmailId ? (x.priority = newPriority) : x
    );

    unSavedEmails.map((x) => (x.id === gmailId ? (x.notes = newNotes) : x));

    if (!(newPriority || newNotes)) return;

    const result = await API.graphql({
      query: isOutlookIntegrated ? mSavePriorityOutlook : mSavePriority, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
      variables: {
        id: gmailId,
        priority: newPriority,
        notes: newNotes,
      },
    });

    console.log("success ", result);
  };

  const savePriority = async (e, gmailId, notes) => {
    let newPriority =
      e.value !== "" && e.value !== null ? parseInt(e.value, 10) : 1;
    let newNotes = notes;

    unSavedEmails.map((x) =>
      x.id === gmailId ? (x.priority = newPriority) : x
    );

    unSavedEmails.map((x) => (x.id === gmailId ? (x.notes = newNotes) : x));

    const result = await API.graphql({
      query: isOutlookIntegrated ? mSavePriorityOutlook : mSavePriority, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
      variables: {
        id: gmailId,
        priority: newPriority,
        notes: newNotes,
      },
    });

    // console.log("success", result);
    // // console.log("unsaved", unSavedEmails);

    autoAdjustAllRow();
  };

  //notes mobile
  const [showNote, setShowNote] = useState(false);
  const [noteId, setNoteId] = useState("");

  // ChatGPT Function
  // const [response, setResponse] = useState(null);
  const [prompt, setPrompt] = useState("");
  // const [engine, setEngine] = useState([{ value: "", label: "Select Engine" }]);

  const handleSavePromptDesc = async (id, val, index) => {
    const data = {
      id: id,
      description: val,
    };
    const success = await updateRowDesc(data);
    if (success) {
      setResultMessage("Successfully updated.");
      setShowToast(true);

      const newArrDescription = unSavedEmails.map((emails) => {
        if (emails.id === id) {
          return { ...emails, description: val };
        }

        return emails;
      });

      setUnsavedEmails(newArrDescription);

      const filteredArr = newArrDescription?.filter((item) => {
        return item.id === data.id; // Fix the condition to check if item.data and data.id exist
      });

      filteredArr.map((emails) => {
        handleEmailClick(emails.id);
        console.log("filteredArr", emails.id);
        setSelectUnsavedEmailsDetails(emails);
      });

      setMobileLoading(false);
      autoAdjustRowHeight(index);
      setLoaderEmail(null);
    }
  };

  const handleOpenAI = async (id, index) => {
    const fwdEmail = "---------- Forwarded message ---------";
    let promptEmail = convertHTMLEntity(prompt);

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 2500;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (promptEmail !== "") {
      setLoaderEmail(id);
      // Use template literals for instruction
      const instruction = `Summarize the email from a third-person point of view in one paragraph, with a maximum of 5 sentences and a maximum of 20 words per sentence, while excluding the signature of the original sender: \n\n ""\n${promptEmail}\n""`;

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        console.log(response);

        // Destructure choices directly in the function call
        handleSavePromptDesc(
          id,
          (await response.json()).choices[0]?.message?.content
            ?.trim()
            .replace(/(\n)+$/, "")
            .replace(/\s+/g, " "),
          index
        );
      } catch (error) {
        console.error("Error", error);
      } finally {
        setLoaderEmail(null);
      }
    } else {
      setLoaderEmail(null);
    }
  };

  const convertHTMLEntity = function (text) {
    const sp = document.createElement("span");

    if (!text) return "";

    const output = text
      .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        sp.innerHTML = entity;
        return sp.innerText;
      })
      .replace(/<br ?\/?>/g, "\n")
      .replace(/(<([^>]+)>)/gi, "");

    return output;
  };

  const [notesChanged, setNotesChanged] = useState(null);
  const [taskPriority, setTaskPriority] = useState(null);
  const [isNoteChange, setIsNoteChange] = useState(false);
  const [notesIdChange, setNotesIdChange] = useState(null);
  const [notesArray, setNotesArray] = useState([]);

  useEffect(() => {
    const items = unSavedEmails.map((item) =>
      item.notes === null ? "" : item.notes
    );
    setNotesArray(items);
  }, [unSavedEmails, procPaste]);

  const handleCopySaveNotes = async (notes, priority, gmailId) => {
    let newNotes = notes;
    let newPriority = priority;

    unSavedEmails.map((x) => (x.id === gmailId ? (x.notes = newNotes) : x));

    unSavedEmails.map((x) =>
      x.id === gmailId ? (x.priority = newPriority) : x
    );

    const result = await API.graphql({
      query: isOutlookIntegrated ? mSaveNotesOutlook : mSaveNotes, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
      variables: {
        id: gmailId,
        priority: newPriority,
        notes: newNotes,
      },
    });

    console.log("save notes", result);
  };
  const saveNotes = async (e, gmailId, newPriority) => {
    let newNotes;
    if (width > 844) {
      console.log("Task Description Desktop value ->", e.target.innerHTML);
      newNotes = notesChanged || e.target.innerHTML;
    } else {
      console.log("Task Description Mobile value ->", e.target.innerHTML);
      newNotes = notesChanged || e.target.innerHTML;
    }

    unSavedEmails.map((x) => (x.id === gmailId ? (x.notes = newNotes) : x));

    unSavedEmails.map((x) =>
      x.id === gmailId ? (x.priority = newPriority) : x
    );

    const result = await API.graphql({
      query: isOutlookIntegrated ? mSaveNotesOutlook : mSaveNotes, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
      variables: {
        id: gmailId,
        priority: newPriority,
        notes: newNotes,
      },
    });

    console.log("save notes", result);
  };

  const saveNotesOnScroll = async () => {
    if (notesChanged !== null) {
      let newNotes = notesChanged;

      unSavedEmails.map((x) =>
        x.id === notesIdChange ? (x.notes = newNotes) : x
      );

      unSavedEmails.map((x) =>
        x.id === notesIdChange ? (x.priority = taskPriority) : x
      );

      const result = await API.graphql({
        query: isOutlookIntegrated ? mSaveNotesOutlook : mSaveNotes, // IF LOGGED IN OUTLOOK THEN USE OUTLOOK MUTATION
        variables: {
          id: notesIdChange,
          priority: taskPriority,
          notes: newNotes,
        },
      });
      setNotesChanged(null);
      setNotesIdChange(null);
      setTaskPriority(null);
    }
  };

  const removeNotesIdLocalStorage = async (gmailId) => {
    const modifiedNotesTaskList = Array.isArray(
      JSON.parse(localStorage.getItem("modifiedTaskNotes"))
    )
      ? JSON.parse(localStorage.getItem("modifiedTaskNotes"))
      : [];

    localStorage.setItem(
      "modifiedTaskNotes",
      JSON.stringify(modifiedNotesTaskList?.filter((item) => item !== gmailId))
    );

    setSelectedTaskList(
      modifiedNotesTaskList?.filter((item) => item !== gmailId)
    );

    autoAdjustAllRow();
  };

  const saveNotesIdLocalStorage = async (gmailId) => {
    const modifiedTaskNotes = Array.isArray(
      JSON.parse(localStorage.getItem("modifiedTaskNotes"))
    )
      ? JSON.parse(localStorage.getItem("modifiedTaskNotes"))
      : [];

    const notIncludesNoteId = !modifiedTaskNotes?.includes(gmailId);
    if (notIncludesNoteId) {
      localStorage.setItem(
        "modifiedTaskNotes",
        JSON.stringify([...modifiedTaskNotes, gmailId])
      );
      console.log("selectedTaskList ->", selectedTaskList);
    }

    autoAdjustAllRow();
  };

  // Save notesArray to local storage
  const saveNotesToLocalStorage = (notes) => {
    localStorage.setItem("notes", JSON.stringify(notes));
  };

  // Fetch notesArray from local storage on component mount
  useEffect(() => {
    const storedNotes = localStorage.getItem("notes");
    if (storedNotes) {
      setNotesArray(JSON.parse(storedNotes));
    }
  }, []);

  const handleChangeNotes = (e, itemIndex) => {
    const updatedNotesArray = [...notesArray];
    updatedNotesArray[itemIndex] = e.target.value;
    console.log("Updated notesArray:", updatedNotesArray);
    setNotesArray(updatedNotesArray);
    // Save updated notesArray to local storage
    saveNotesToLocalStorage(updatedNotesArray);
  };

  // const handleChangeNotes = (e, itemIndex) => {
  //   const updatedNotesArray = [...notesArray];
  //   updatedNotesArray[itemIndex] = e.target.value;
  //   console.log("Updated notesArray:", updatedNotesArray);
  //   setNotesArray(updatedNotesArray);
  // };

  // const [contactList, setContactList] = useState([]);

  // const getClientMatterById = async (userId) => {
  //   const res = await API.graphql({
  //     query: qUserClientMatter,
  //     variables: {
  //       companyId: companyId,
  //       userId: userId,
  //     },
  //   });

  //   var temp = [];

  //   console.log("ressss");

  //   res.data.user.clientMatterAccess.items.map((y) => {
  //     temp = [
  //       ...temp,
  //       {
  //         value: y.clientMatter.id,
  //         label: `${y.clientMatter?.client?.name}/${y.clientMatter?.matter?.name}`,
  //       },
  //     ];
  //     console.log("temp state", temp);
  //   });

  //   setClientMatterSuggestions(temp);
  //   console.log(clientMatterSuggestions);

  //   //return data;
  // };
  // const [userEmailList, setUserEmailList] = useState({});

  //Contacts email list
  useEffect(() => {
    if (contactEmailList.length === 0) {
      getContactsEmail();
    }
  }, []);

  const getContacts = async () => {
    const data = await API.graphql({
      query: qGetContacts,
      variables: {
        companyId: companyId,
      },
    });
    return data;
  };

  const getContactsEmail = async () => {
    try {
      let contacts = await ListUsers(localStorage.getItem("companyId"));
      let emailList = [];
      let userEmailId = []

      contacts.data.company.users.items.map((x) => {
        emailList = [...emailList, x.email];
      });

      contacts.data.company.users.items.map((x) => {
        userEmailId = [...userEmailId, {email: x.email, id: x.id}];
      });

      setContactEmailList(emailList);
      setUserEmailList(userEmailId)
    } catch (e) {
      console.log("ERROR getContacts", e);
    }
  };

  // MOBILE DESCRIPTION REF
  const mobileDescRef = Array(unSavedEmails.length).fill(null);

  const handleDescInputMobile = (e, index) => {
    e.preventDefault();
    if (
      mobileDescRef[index] == null ||
      mobileDescRef[index] !== e.target.clientHeight
    ) {
      autoAdjustRowHeight(index);
    }
    mobileDescRef[index] = e.target.clientHeight;
  };
  // END OF MOBILE DESCRIPTION

  const [showSelect, setShowSelect] = useState(false);
  const [autoCount, setAutoCount] = useState(0);

  const [clientMatterState, setClientMatterState] = useState([]);

  const autoPopulate = async () => {
    setAutoCount(0);

    // Calculate getFilteredClientMatterAccess once outside the loop
    const filteredClientMatterAccess = clientMatterState.map((contact) => {
      const accessItems = contact?.clientMatterAccess?.items;
      if (accessItems) {
        return accessItems.map((item) => ({
          id: item.clientMatter.id,
          client: item.clientMatter?.client?.name,
          matter: item.clientMatter?.matter?.name,
        }));
      }
      return [];
    });

    const emailOnlySet = new Set(
      unSavedEmails.map((item) =>
        item.from.substring(
          item.from.indexOf("<") + 1,
          item.from.lastIndexOf(">")
        )
      )
    );

    // Process multiple items in parallel
    const promises = unSavedEmails.map(async (item, index) => {
      const emailOnly = item.from.substring(
        item.from.indexOf("<") + 1,
        item.from.lastIndexOf(">")
      );

      const foundIndex = filteredClientMatterAccess.findIndex((access) =>
        access.some((item) => item.email === emailOnly)
      );

      if (foundIndex !== -1) {
        const getFilteredClientMatterAccess =
          filteredClientMatterAccess[foundIndex];

        const msgPreview = await getEmailPayload(item.id);
        const contact = clientMatterState[foundIndex];

        handleOpenAIClientMatterFilter(
          JSON.stringify(getFilteredClientMatterAccess),
          msgPreview,
          item.id,
          index,
          item
        );

        if (contact.clientMatterAccess.items.length === 1) {
          const optionHandle = {};
          const accessItem = contact.clientMatterAccess.items[0];

          optionHandle.value = accessItem.clientMatter.id;
          optionHandle.label = `${accessItem.clientMatter.client}/${accessItem.clientMatter.matter}`;

          handleAutoPopulate(optionHandle, item.id, index, item);
          setResultMessage("Successfully auto-populated client/matter.");
          setShowToast(true);
        }
      }
    });

    await Promise.all(promises);
  };

  useEffect(() => {
    if (copyMore20) {
      let temp = [];
      unSavedEmails?.map((x) => {
        temp = [...temp, x.id];
      });
      console.log("unsavedEmails -> ", unSavedEmails);
      //console.log("APPLY TO ALL present: " + temp);
      let copyIndex = temp.indexOf(copyId);
      for (let i = 0; i < temp.length; i++) {
        if (i !== copyIndex) {
          console.log(temp[i]);
          handlePaste(temp[i], i);
        }
      }
      console.log("pastedIds: ", pastedIds);
      //setUnsavedForTaskListCtr(temp.length);
      // temp = temp.filter(
      //   (id) => {
      //     return id !== copyId;
      //   }
      // );
      // console.log("APPLY TO W/O ID: " + temp);
      // console.log("APPLY TO ALL copyId: " + copyId);

      // setCopyId("");
      // setPastedIds([]);
      // setShowCopy(true);
      setCopyMore20(false);
    }
  }, [copyMore20]);
  const handleOpenAIClientMatterFilter = async (
    clientMatter,
    msgPreview,
    idRow,
    indexRow,
    rowDetails
  ) => {
    const prompt = clientMatter;
    const parsedEmail = EmailParser(JSON.parse(msgPreview.payload[0].content));
    const email = parsedEmail.text;
    // const email = removeHtmlTags(Base64.decode(
    //   msgPreview.payload
    //     .map((em) => em.content)
    //     .join("")
    //     .split('data":"')
    //     .pop()
    //     .split('"}')[0]
    // ).replace("body{color:", ""));

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 3000;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (prompt !== "") {
      // Previous prompt used
      //const instruction = `Check the client or matter data below \n\n\n${prompt}\n\n and identify which client or matter has a similar word in this email, please show only the value of the id, client, matter in the response enclosing with {} \n\n${email}\n\n`;

      const instruction = `Check the client or matter data below \n\n\n${prompt}\n\n and identify client or matter has a similar word in this email, please show only the value of the id, client, matter in the response enclosing with {} \n\n${email}\n\n`;

      //console.log(instruction);

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        let optionHandle = [];
        const getClientMatterId = (await response.json()).choices[0]?.message
          ?.content;

        console.log("ClientMatter", getClientMatterId);

        if (!getClientMatterId || getClientMatterId.trim() === "") {
          console.log("AI cannot find the connections between emails.");
          handleAutoPopulate(optionHandle, idRow, indexRow, rowDetails);
        } else {
          const dataClientMatterId = JSON.parse(getClientMatterId);

          optionHandle.value = dataClientMatterId.id;
          optionHandle.label =
            dataClientMatterId.client + "/" + dataClientMatterId.matter;

          handleAutoPopulate(optionHandle, idRow, indexRow, rowDetails);
          setResultMessage("Successfully auto-populated client/matter.");
          setShowToast(true);
        }

        if (!response.ok) {
          throw new Error(response.statusText);
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };

  function removeHtmlTags(str) {
    return str.replace(/<[^>]*>?/gm, "");
  }

  function doesContactExist(from) {
    if (!from) return;

    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    const emailMatch = from.match(emailPattern);
    
    if (emailMatch) {
      const email = emailMatch[0];
      return contactEmailList.includes(email);
    }

    return false;
  }

  //Changes color of highlighted emails
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [prevSelectedEmails, setPrevSelectedEmails] = useState([]);

  useEffect(() => {
    const storedSelectedEmails = localStorage.getItem("selectedEmails");
    if (storedSelectedEmails) {
      setPrevSelectedEmails(JSON.parse(storedSelectedEmails));
    }
  }, []);

  const handleEmailClick = (id) => {
    console.log("handleEmailClick()", id);
    setPrevSelectedEmails((prev) => [...prev, id]);
    setSelectedEmail(id);
    localStorage.setItem(
      "selectedEmails",
      JSON.stringify(prevSelectedEmails.concat(id))
    );
  };

  const handleRowClick = useCallback((msgId, bool, item, index) => {
    setShowTaskListDetails(bool);
    setShowTaskRegisterDetails(bool);
    //GS MMA 2497 Causes rerender
    setSelectUnsavedEmailsDetails(item);
    setSelectedUnsavedIndex(index);
    handleSnippet(item, index);
    setSelectedIsReplyDetails(item); // FOR COMPOSE FUNCTION
    setSubject(item.subject); // FOR COMPOSE FUNCTION
    handleEmailClick(msgId);
    handleEmailOpen(true);
  }, []);

  const initOpenEmail = async (msgId) => {
    const params = {
      query: isOutlookIntegrated ? qGetOutlookMessage : qGetGmailMessage,
      variables: {
        id: msgId,
      },
    };

    await API.graphql(params).then((result) => {
      let item = isOutlookIntegrated
        ? result.data.outlookMessage
        : result.data.gmailMessage;

      let index;
      for (let i = 0; i < unSavedEmails.length; i++) {
        if (unSavedEmails[i].id === msgId) {
          index = i;
          break;
        }
      }

      if (index === undefined) {
        throw new Error("Could not find index of selected Email");
      }

      console.log("handleRowClick", msgId, false, item, index);
      handleRowClick(msgId, false, item, index);
    });
  };

  useEffect(() => {
    if (unSavedEmails?.length > 0 && location?.state?.selectedEmail?.id) {
      try {
        let msgId = location.state.selectedEmail.id;
        history.replace({ state: {} });
        initOpenEmail(msgId);
      } catch (e) {
        console.error("Error Initializing Email", e);
      }
    }
  }, [unSavedEmails]);

  const handleEmailOpen = async (bool) => {
    setEmailContentOpen(bool);
  };

  const handleShowDetailsCopy = (bool) => {
    setShowDetailsCopy(bool);
  };

  const handleShowTaskListDetails = (bool, msgId) => {
    setShowTaskListDetails(bool);
    setTaskListId(msgId);
  };

  const handleDoneBtn = (boolCpyTaskList, cpyId, pstId, boolShowCpy) => {
    if (cpyIsTaskList) {
      setCpyIsTaskList(boolCpyTaskList);
    }
    setCopyId(cpyId);
    setPastedIds(pstId);
    setShowCopy(boolShowCpy);
  };

  const handleShowRemoveTask = (bool) => {
    setShowRemoveTask(bool);
  };

  const handleShowCreateClientMatter = (bool) => {
    setShowCreateClientMatter(bool);
  };

  const handleEnableDropDown = (bool) => {
    setEnableDropdown(bool);
  };

  useEffect(() => {
    const fetchScoringData = async () => {
      try {
        console.group("fetchScoringData")
        setScoringCompleted(false);
        if (selectedEmail) {
          handleComposeUnsavedTabs(
            isUpdatedClientMatter,
            false,
            true,
            selectUnsavedEmailsDetails,
            threadId,
            messageId,
            emailHTML
          );
  
          setCmSuggestion({value: "", label: "Loading matches..."});
    
          console.log("matterlist", matterList);
    
          if (!matterList?.length) throw new Error("Matter List is empty or hasn't loaded yet");
  
          if (!selectUnsavedEmailsDetails.clientMatters || !selectUnsavedEmailsDetails.clientMatters.items || selectUnsavedEmailsDetails.clientMatters.items.length === 0) {
            // Email and Subject Content Client Matter
            let json = await handleScoring(selectUnsavedEmailsDetails.id, selectUnsavedEmailsDetails.subject);
            setEmailContentClientMatter(json)
  
            //Contacts
            let emailAdd = handleGetSenderClientmatters(selectUnsavedEmailsDetails.from);
    
            setScoringCompleted(true);
          }
        }
        console.groupEnd("fetchScoringData")
      } catch (err) {
        console.error("Failed to compute suggested client matter: ", err);
        setCmSuggestion({value: "", label: "No matched result"});
      } finally {
        console.groupEnd("fetchScoringData")
      }
    };
  
    // IF suggestedCM does not exist, then fetchCurrent
    if (selectUnsavedEmailsDetails?.suggestedCM) {
      if (typeof selectUnsavedEmailsDetails?.suggestedCM === "string") {
        setCmSuggestion(JSON.parse(selectUnsavedEmailsDetails.suggestedCM));
      } else {
        setCmSuggestion(selectUnsavedEmailsDetails.suggestedCM);
      }
    } else {
      fetchScoringData();
    }

    // fetchScoringData();

  }, [selectedEmail]);
  
  useEffect( async () => {
    if (scoringCompleted) {
      const scores = await calculateScores();
      console.log('Client matters with scores in descending order:', scores);
    }
  }, [scoringCompleted, emailContentClientMatter, subjectClientMatter]);

  const handleGetSenderClientmatters = async (from) => {
    const regex = /<([^>]+)>/;
    const senderEmail = from.match(regex);

    if(senderEmail){
      console.log("sender", senderEmail[1]);
      let senderId;

      userEmailList?.map((x)=> 
        x.email == senderEmail[1] ? 
          senderId = x.id 
        : x
      )

      if(senderId){ //If email is in contacts, get related client matters
        let clientmatterList = await getUserClientMatter(senderId)
        setEmailAddressesClientMatter(clientmatterList);
        // console.log("FinalCMList", clientmatterList) //set this in the state
      }

      // console.log("sender id", senderId)
    }
  }

  //get contacts kjmf
  const getUserClientMatter = async (matchedUserId) => {
    console.log("getUserClientMatter()");
    const { data } = await GetUserCM(matchedUserId, companyId);

    const clientMatters = data?.user?.clientMatterAccess?.items ?? [];
    let formattedCMList = [];

    clientMatters.map((x)=>{
      formattedCMList = [...formattedCMList, 
        {value: x.clientMatter.id, label: `${x?.clientMatter?.client?.name}/${x?.clientMatter?.matter?.name}`}
      ]
    })

    return formattedCMList;
  };


  const currentDate = new Date();

  function handleCopyText(content) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          setResultMessage("Content copied.");
          setShowToast(true);
          setTimeout(() => {
            hideToast(false);
          }, 2000);
        })
        .catch((error) => {
          setResultMessage("Error copying content.");
          setShowToast(true);
          console.error("Failed to copy content:", error);
          setTimeout(() => {
            hideToast(false);
          }, 2000);
        });
    } else {
      console.log("Failed to copy content");
      setShowToast(true);
      setTimeout(() => {
        hideToast(false);
      }, 2000);
    }
  }

  function applyBoldStyle() {
    var elements = document.getElementsByTagName("b");

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      var innerTags = element.querySelectorAll("i, u");

      for (var j = 0; j < innerTags.length; j++) {
        var innerTag = innerTags[j];
        innerTag.style.fontWeight = "bold";
      }
    }
  }

  // function processTaskRegister(itemId) {
  //   const updatedval = selectedTaskRegister.find(
  //     (register) => register.id === itemId
  //   );

  //   //console.log("proccessTasklist()", updatedval);
  //   if (updatedval?.registers?.length > 0) {
  //     //console.log(updatedval, "<- updated p1");
  //     return updatedval?.registers;
  //   } else {
  //     return taskRegisterList.find(
  //       (register) =>
  //         register?.label.toLowerCase().replace(/ /g, "") === "tasklist"
  //     );
  //   }
  // }

  function processTaskRegister(itemId) {
    const storedTaskRegisters =
      JSON.parse(localStorage.getItem("gmailTaskRegisters")) || [];

    const updatedVal = storedTaskRegisters.find(
      (register) => register.id === itemId
    );

    if (updatedVal?.registers?.length > 0) {
      return updatedVal.registers;
    } else {
      // If no specific registers found for the itemId, default to tasklist
      return taskRegisterList.find(
        (register) =>
          register?.label.toLowerCase().replace(/ /g, "") === "tasklist"
      );
    }
  }

  function processEmailHtml(emailHtml) {
    var tempElement = document.createElement("div");
    tempElement.innerHTML = emailHtml;
    applyBoldStyle(tempElement);
    var processedHtml = tempElement.innerHTML;

    // Use the processed HTML as needed
    return processedHtml;
  }

  // Set Scroll Position of Mobile
  const [rowIndex, setRowIndex] = useState(-1);

  // Signout Google to clear cache
  function handleSignOut(event) {
    const removeRefreshToken = `
    mutation removeRefreshToken($email: String) {
      gmailDisconnect(email: $email) {
        id
      }
    }
    `;
    const params = {
      query: removeRefreshToken,
      variables: {
        email: localStorage.getItem("connectedGmailAccount"),
      },
    };

    API.graphql(params).then(() => {
      localStorage.removeItem("connectedGmailAccount");
      window.location.reload();
    });
  }

  //DUMMY DATA FOR SUGGESTIONS
  // const [cmSuggestion, setCmSuggestion] = useState(matterList[0] ? matterList[0] : {})
  const customStyles = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'blue'
    }),
    singleValue: provided => ({
      ...provided,
      color: 'blue'
    }),
  }

  const customStyles1 = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black'
    }),
    singleValue: provided => ({
      ...provided,
      color: 'black'
    })
  }
  // useEffect( async () => {

    
  //   console.log("emailContent >>> ", emailContentOpen);

  // }, [emailContentOpen, matterList])

  //DESCRIPTION BOX EXPAND
  const [isExpandedDesc, setIsExpandedDesc] = useState(false);

  // Function to sort matterList based on cmSuggestion
  const getSortedMatterList = (matterList, cmSuggestion) => {
    if (!matterList) return [];
    if (!cmSuggestion) return matterList;
    const suggestionIndex = matterList.findIndex(item => item.value === cmSuggestion.value);
    if (suggestionIndex === -1) return matterList;
    return [cmSuggestion, ...matterList.filter(item => item.value !== cmSuggestion.value)];
  };

  const sortedMatterList = getSortedMatterList(matterList, cmSuggestion);

  // RETURN STATEMENT OF COMPONENT
  return isOutOfSync ? (
    <>
      <table
        id="table-el"
        className="min-w-2/5 border-slate-100 table-fixed divide-y divide-gray-200 border-b-2 border-l-2 border-r-2 text-xs"
      >
        <tbody>
          <tr>
            <td className="p-4">
              <p>
                An error has occurred. Please re-login your account to continue
                syncing emails.
              </p>

              <div
                className="cursor-pointer flex px-2 py-2 text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                style={{ flexDirection: "row" }}
                onClick={(e) => handleSignOut(e)}
              >
                <div className="flex items-center">
                  <FcGoogle />
                </div>
                <div className="flex font-medium ml-2">Sign Out</div>
                {/* Sign Out - {localStorage.getItem("connectedGmailAccount")} */}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  ) : (
    <>
      {width > 844 ? (
        <>
          {/* {console.log("emails to render: ", unSavedEmails)} */}
          {/* left-hand-screen */}
          <div
            id="emailTableBody"
            className="grid grid-cols-4 overflow-y-hidden"
            style={{ height: height - heightOffset + "px" }}
          >
            <div
              ref={tableContainerRef}
              className={
                "overflow-y-auto overflow-x-hidden inboxScroll " +
                (waitUnSaved ? "overflow-y-hidden" : "overflow-y-auto")
              }
              style={{
                height: height - heightOffset + "px",
              }}
            >
              <table
                id="table-el"
                className="divide-y w-full table-fixed h-full divide-gray-200 text-xs overflow-y-auto"
                // style={{ width: width * 0.28 + "px" }}
              >
                <thead></thead>
                <tbody className="divide-y divide-gray-200 w-full">
                  {waitUnSaved ? (
                    <>
                      {[...Array(10).keys()].map((data, index) => {
                        return (
                          <tr className="flex w-full">
                            <td className="w-10 flex p-2 items-center justify-center">
                              <div className="align-center">
                                <SkeletonTheme width={"20px"} height={"20px"}>
                                  <p>
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>

                            <td className="flex-1 grow p-2 align-top">
                              <div>
                                <SkeletonTheme height="55px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="w-20 flex-none p-2 align-top">
                              <div>
                                <SkeletonTheme height="55px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <TableUnsavedWebEmailList
                      bindList={bindList}
                      clientMatterState={clientMatterState}
                      unSavedEmails={unSavedEmails}
                      selectedEmail={selectedEmail}
                      prevSelectedEmails={prevSelectedEmails}
                      pastedIds={pastedIds}
                      copyId={copyId}
                      detailsDivRef={detailsDivRef}
                      saveLoading={saveLoading}
                      handleSelectItem={handleSelectItem}
                      selectedUnsavedItems={selectedUnsavedItems}
                      selectedTaskList={selectedTaskList}
                      autoAdjustAllRow={autoAdjustAllRow}
                      handleCopy={handleCopy}
                      handlePaste={handlePaste}
                      handleRowClick={handleRowClick}
                      lastCounter={lastCounter}
                      currentDate={currentDate}
                      showCopy={showCopy}
                      loadLabels={loadLabels}
                      handleEmailOpen={handleEmailOpen}
                      vTablePaddingTop={vTablePaddingTop}
                      vTablePaddingBottom={vTablePaddingBottom}
                      virtualRows={virtualRows}
                      rows={rows}
                      tableContainerRef={tableContainerRef}
                      rowVirtualizer={rowVirtualizer}
                      Highlight={Highlight}
                      searchGmail={searchGmail}
                    />
                  )}
                </tbody>
              </table>
            </div>

            {/* RIGHT SIDE OF SCREEN */}
            {emailContentOpen &&
            selectUnsavedEmailsDetails &&
            unSavedEmails?.length > 0 ? (
              <div
                className="overflow-hidden h-full col-span-3 flex flex-col border-t border-l border-gray-200 p-4 pb-0"
                style={
                  {
                    // position: "sticky",
                    // width: (width * 0.62 - 40) + "px",
                    // height: height * heightMul + "px",
                  }
                }
                ref={detailsDivRef}
              >
                <div className="px-2">
                  <div className="flex pt-4">
                    <span className="flex-grow text-left font-bold">
                      {/* {selectUnsavedEmailsDetails?.subject} */}
                      <Highlight text={selectUnsavedEmailsDetails?.subject} highlight={searchGmail} classNames="flex-grow text-left font-bold" />
                    </span>
                    <span className="text-right">
                      {dateTimeNoSecondsFormatter(
                        new Date(selectUnsavedEmailsDetails?.date)
                      )}
                    </span>
                  </div>
                  <div className="mt-2 mb-3 flex">
                    {/* Bug description => if using split("<")[0] in selectUnsavedEmailsDetails.from, the page is freezing */}
                    {/* <span className="flex-grow text-left">{`from ${selectUnsavedEmailsDetails.from.split("<")[0]}`}</span> */}
                    <span className="flex-grow text-left">
                      <span className="text-left font-light text-gray-400">
                        from
                      </span>
                      :{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handleCopyText(selectUnsavedEmailsDetails.from)
                        }
                      >
                        {/* {selectUnsavedEmailsDetails?.from} */}
                        <Highlight text={selectUnsavedEmailsDetails?.from} highlight={searchGmail} classNames="cursor-pointer" />
                      </span>
                      <br />
                      <span className="text-left font-light text-gray-400">
                        to
                      </span>
                      :{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handleCopyText(selectUnsavedEmailsDetails.to)
                        }
                      >
                        {/* {selectUnsavedEmailsDetails?.to} */}
                        <Highlight text={selectUnsavedEmailsDetails?.to} highlight={searchGmail} classNames="cursor-pointer" />
                      </span>
                      <br />
                      {selectUnsavedEmailsDetails?.cc && (
                        <>
                          <span className="text-left font-light text-gray-400">
                            cc:{" "}
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              handleCopyText(selectUnsavedEmailsDetails.cc)
                            }
                          >
                            {/* {selectUnsavedEmailsDetails.cc} */}
                            <Highlight text={selectUnsavedEmailsDetails?.cc} highlight={searchGmail} classNames="cursor-pointer" />
                          </span>
                        </>
                      )}
                      {selectUnsavedEmailsDetails?.bcc && (
                        <>
                          <span className="text-left font-light text-gray-400">
                            bcc:{" "}
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              handleCopyText(selectUnsavedEmailsDetails.bcc)
                            }
                          >
                            {/* {selectUnsavedEmailsDetails.bcc} */}
                            <Highlight text={selectUnsavedEmailsDetails?.bcc} highlight={searchGmail} classNames="cursor-pointer" />
                          </span>
                        </>
                      )}
                    </span>

                    {/* Bug description => index undefined */}
                    {toggleSent === false && !doesContactExist(selectUnsavedEmailsDetails?.from) ? (
                      <span
                        className="primary_light focus:outline-none ml-4 inline-flex cursor-pointer gap-x-2 rounded text-[12px] text-xs font-normal text-secondary text-opacity-90 hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        onClick={(e) =>
                          handleAddContact(
                            selectUnsavedEmailsDetails,
                            selectedUnsavedIndex
                          )
                        }
                      >
                        Add sender to contacts
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr />
                </div>
                <div
                  className="inboxScroll h-full overflow-y-hidden grid"
                  style={{ gridTemplateColumns: "1fr 1px 1fr" }}
                >
                  <div className="inboxScrollInner overflow-y-auto p-5 pr-4">
                    {/* <div className="mb-11">
                        <div className="mt-11 flex">
                          <span className="flex-grow text-left">
                            Describe Email
                          </span>
                          <button
                            className="rounded bg-green-500 px-4 py-2 text-right text-xs font-bold text-white hover:bg-green-700"
                            onClick={() =>
                              handleOpenAI(
                                selectUnsavedEmailsDetails?.id,
                                selectedUnsavedIndex
                              )
                            }
                          >
                            {`Summarize Email`}
                          </button>
                        </div>
                      </div> */}

                    {/* CLIENT MATTERS */}
                    <div className="mb-5">
                      <div className="w-full">
                        {/* {loadLabels ? (
                            <div className="items-center w-full py-1 text-green-400 align-top">
                              Updating..
                            </div>
                          ) : (
                            <Select
                              create={false}
                              options={matterList}
                              searchable={true}
                              dropdownHandle={true}
                              onChange={(options, e) => {
                                if (enableDropdown) {
                                  handleClientMatter(
                                    options[0],
                                    selectUnsavedEmailsDetails?.id,
                                    selectedUnsavedIndex,
                                    selectUnsavedEmailsDetails
                                  );
                                }
                              }}
                              onDropdownOpen={() => {
                                console.log("Enable dropdown onchange");
                                setEnableDropdown(true);
                              }}
                              values={unSavedEmails[
                                selectedUnsavedIndex
                              ]?.clientMatters?.items?.map(
                                (item_clientMatter, index) => ({
                                  value: item_clientMatter?.id,
                                  label:
                                    item_clientMatter?.client?.name +
                                    "/" +
                                    item_clientMatter?.matter?.name,
                                })
                              )}
                              className="relative float-right w-full py-5 mb-3 ml-2 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                              placeholder="Client/Matter"
                            />
                              )}*/}

                        <CreatableSelect
                          isCreatable={false}
                          options={sortedMatterList || []}
                          dropdownHandle={true}
                          onChange={(e) => {
                            // Desktop!!
                            //if (enableDropdown) {
                            handleClientMatter1(
                              e,
                              selectUnsavedEmailsDetails?.id,
                              selectedUnsavedIndex,
                              selectUnsavedEmailsDetails
                            );
                            //}
                          }}
                          defaultValue={unSavedEmails[selectedUnsavedIndex]?.clientMatters?.items?.length === 0 && cmSuggestion?
                            
                            cmSuggestion
                            :
                            // cmSuggestion
                            unSavedEmails[
                              selectedUnsavedIndex
                            ]?.clientMatters?.items?.map(
                              (item_clientMatter, index) => ({
                                value: item_clientMatter?.id,
                                label:
                                  item_clientMatter?.client?.name +
                                  "/" +
                                  item_clientMatter?.matter?.name,
                              })
                            )
                          }
                          value={unSavedEmails[selectedUnsavedIndex]?.clientMatters?.items?.length === 0 && cmSuggestion ?
                            
                            cmSuggestion
                            :
                            unSavedEmails[
                            selectedUnsavedIndex
                          ]?.clientMatters?.items?.map(
                            (item_clientMatter, index) => ({
                              value: item_clientMatter?.id,
                              label:
                                item_clientMatter?.client?.name +
                                "/" +
                                item_clientMatter?.matter?.name,
                            })
                          )}
                          className={unSavedEmails[selectedUnsavedIndex]?.clientMatters?.items?.length === 0 ?
                            " outline-none placeholder-blue-400 text-blue-600 focus:outline-none relative float-right mb-4 w-full rounded border-0 bg-white text-sm focus:ring"
                            :
                            " outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none relative float-right mb-4 w-full rounded border-0 bg-white text-sm focus:ring"
                          }
                          placeholder={
                            hbaDomains.includes(currUrl)
                              ? "Project Name"
                              : "Client/Matter"
                          }
                          closeMenuOnSelect={true}
                          blurInputOnSelect={false}
                          noOptionsMessage={() => "No result found"}
                          isValidNewOption={() => false}
                          styles={unSavedEmails[selectedUnsavedIndex]?.clientMatters?.items?.length === 0 ? customStyles : ""}
                        />

                      </div>
                      {unSavedEmails[selectedUnsavedIndex]?.clientMatters?.items?.length > 0 ? (
                          <></>
                        ) : (
                          <>
                            {cmSuggestion?.length === 0 ? (<>No suggestions found</>) : (
                              <div className="w-full relative py-2 mb-2 pb-4">
                                <p className="right-0 float-right -mt-1 text-green-500 cursor-pointer"
                                
                                onClick={()=>
                                  {console.log("cmSuggestion", cmSuggestion);
                                  handleClientMatter1(
                                    cmSuggestion ? cmSuggestion : null,
                                    selectUnsavedEmailsDetails?.id,
                                    selectedUnsavedIndex,
                                    selectUnsavedEmailsDetails
                                  )
                                }}>Confirm</p>
                              </div>
                            )}
                          </>
                        )
                      }
                      {/* <p onClick={console.log("matterlist", matterList[0])}>test</p> */}
                      <div className="w-full">
                        {clientMatterSuggestions?.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            {clientMatterSuggestions.map((x) => {
                              <button
                                className="placeholder-blueGray-300 text-blueGray-600 relative m-1 w-full rounded border-2 border-gray-300 bg-white text-sm shadow focus:ring"
                                onClick={() => {
                                  handleClientMatter1(
                                    clientMatterSuggestions,
                                    selectUnsavedEmailsDetails.id,
                                    selectedUnsavedIndex
                                  );
                                }}
                              >
                                TEST
                              </button>;
                            })}
                          </>
                        )}
                      </div>
                    </div>

                    {/* EMAIL LABEL */}
                    <div className="mt-5 mb-5">
                      {loadLabels ? (
                        <div className=" w-full items-center py-1 align-top text-green-400">
                          Updating..
                        </div>
                      ) : (
                        <>
                          <div className="mb-1" disabled={true}>
                            <DropdownSelect
                              values={selectUnsavedEmailsDetails?.labels?.items.map(
                                (item_label) => ({
                                  value: item_label?.id,
                                  label: item_label?.name,
                                })
                              )}
                              multi={true}
                              clearable={false}
                              options={
                                hbaDomains.includes(currUrl)
                                  ? getBriefListPerPersonaDropDown([
                                      ...labelsOptions,
                                      ...newlyAddedLabels?.filter((cml, i) =>
                                        filterNewlyAddedLabels(
                                          cml,
                                          selectUnsavedEmailsDetails
                                            ?.clientMatters?.items
                                        )
                                      ),
                                    ])
                                  : [
                                      ...labelsOptions,
                                      ...newlyAddedLabels?.filter((cml, i) =>
                                        filterNewlyAddedLabels(
                                          cml,
                                          selectUnsavedEmailsDetails
                                            ?.clientMatters?.items
                                        )
                                      ),
                                    ].filter(
                                      (item) => !item.label.includes("_")
                                    )
                              }
                              //options={matterList}
                              onDropdownOpen={async (e) => {
                                await handleOnMenuOptions(
                                  selectUnsavedEmailsDetails?.clientMatters
                                    ?.items
                                );
                                setEnableDropdown(true);
                                // loadedLabels.current = false;
                                setLoadedLabels((prev) =>
                                  prev.filter(
                                    (e) => e !== selectUnsavedEmailsDetails?.id
                                  )
                                );
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              keepSelectedInList={false}
                              onChange={(e) => {
                                console.log("xX-2-Xx");
                                //if (enableDropdown) {
                                //console.log(e[0]);
                                if (
                                  !loadedLabels.find(
                                    (e) => e === selectUnsavedEmailsDetails?.id
                                  )
                                ) {
                                  // loadedLabels.current = true;
                                  setLoadLabels(true);
                                  setLoadedLabels((prev) => [
                                    ...prev,
                                    selectUnsavedEmailsDetails?.id,
                                  ]);
                                  handleAddLabel1(
                                    e,
                                    selectUnsavedEmailsDetails?.id,
                                    selectedUnsavedIndex,
                                    selectUnsavedEmailsDetails?.clientMatters
                                      ?.items
                                  );
                                }

                                //}
                              }}
                              create={true} // SET TO TRUE IF WANT TO ENABLE THE CREATION OF NEW LABELS
                              disabled={
                                saveLoading
                                  ? true
                                  : // : checkArrLength(item.clientMatters?.items?.length) || checkEnable(item.id)
                                  checkArrLength(
                                      selectUnsavedEmailsDetails?.clientMatters
                                    ) === true ||
                                    checkEnable(selectUnsavedEmailsDetails?.id)
                                  ? false
                                  : true
                              }
                              searchable={true}
                              onDropdownClose={() => {
                                console.log("CLOSE");
                                setEnableDropdown(false);
                                // loadedLabels.current = true;
                                // setLoadedLabels((prev) => [...prev, selectUnsavedEmailsDetails?.id]);
                              }}
                              placeholder="Labels"
                              color="#8a8a8a"
                              className="outline-none background-slate-500 placeholder-blueGray-300 text-blueGray-600 focus:outline-none mt-2 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                            />
                          </div>
                        </>
                      )}
                    </div>

                    {/* DESCRIPTION EMAIL KJMF1*/}
                    <div className="mt-5">
                      <p className="float-right flex pb-1 cursor-pointer"
                        onClick={()=>{setIsExpandedDesc(!isExpandedDesc)}}
                      > 
                        <FaExpandAlt size={15}/>
                      </p>
                      <p
                        className="font-poppins mt-2 mb-2 w-full whitespace-normal break-words rounded p-2"
                        style={{
                          border: "solid 1px #c4c4c4",
                          outlineColor: "rgb(204, 204, 204, 0.5)",
                          outlineWidth: "thin",
                          overflow: "auto",
                          height: isExpandedDesc === true ? "300px" : "100px",
                          maxWidth: width <= 540 ? "18rem" : "50rem",
                          backgroundColor: saveLoading
                            ? "rgb(204, 204, 204, 0.5)"
                            : "white",
                        }}
                        suppressContentEditableWarning
                        dangerouslySetInnerHTML={{
                          __html: selectUnsavedEmailsDetails?.description,
                        }}
                        onClick={(event) => {
                          console.log(
                            "desc",
                            selectUnsavedEmailsDetails?.description
                          );
                          handleDescContent(
                            event,
                            selectUnsavedEmailsDetails?.description,
                            selectUnsavedEmailsDetails?.id
                          );
                        }}
                        onInput={(event) => {
                          console.log("type", event.currentTarget.innerText);
                          handleChangeDesc(event);
                        }}
                        onBlur={(e) => {
                          handleSaveMainDesc(
                            e,
                            selectUnsavedEmailsDetails?.description,
                            selectUnsavedEmailsDetails?.id,
                            selectedUnsavedIndex
                          );
                          setTextDesc(e.currentTarget.innerText);
                        }}
                        contentEditable={saveLoading ? false : true}
                      ></p>
                    </div>

                    {/* TASK LIST */}
                    {showTaskListDetails === false ? (
                      <div className="z-10 flex">
                        {/*  */}
                        <div className="flex font-semibold w-full">
                          <div className="flex flex-col w-full gap-y-2">
                            <span
                              className="flex pt-4 pb-3 text-sm"
                              onClick={(e) => {
                                setShowTaskListDetails(true);
                                setTaskListId(selectUnsavedEmailsDetails?.id);
                              }}
                            >
                              <img src={arrowDown} alt="DOWN"></img>&nbsp;
                              {selectedTaskList?.includes(
                                selectUnsavedEmailsDetails?.id
                              ) ||
                              !(
                                selectUnsavedEmailsDetails?.taskList?.id ==
                                  null ||
                                selectUnsavedEmailsDetails?.taskList?.id == ""
                              )
                                ? "Show Task Details"
                                : "Add To Tasklist"}
                            </span>
                          </div>
                          <br />
                          {/* {selectedTaskList.includes(selectUnsavedEmailsDetails.id) ||
                            selectUnsavedEmailsDetails.notes !== null || selectUnsavedEmailsDetails.priority !== null || selectUnsavedEmailsDetails.assignee !== null
                          ? (
                            <>
                              <div>
                                <button
                                  className={
                                    selectUnsavedEmailsDetails.assignee !== null ||
                                    selectUnsavedEmailsDetails.priority !== null
                                    ? "ml-2 flex text-white font-bold p-2  rounded items-center justify-center bg-green-400"
                                    : "ml-2 flex text-white font-bold p-2  rounded items-center justify-center bg-gray-400"
                                  }
                                  onClick={() => {
                                    setShowOtherDetails(true);
                                    setOtherDetailsId(selectUnsavedEmailsDetails.id);
                                    setWebAssignee(selectUnsavedEmailsDetails.assignee);
                                    setAssigneeId(selectUnsavedEmailsDetails.id);
                                    setAssigneeVal(selectUnsavedEmailsDetails.assignee);
                                    setPriorityVal(selectUnsavedEmailsDetails.priority);
                                    setNotesVal(selectUnsavedEmailsDetails.notes);
                                  }}
                                                      >
                                  <BiPencil size={16} />
                                </button>
                              </div>
                            </>
                          ) : (<></>) */}
                        </div>
                      </div>
                    ) : (
                      // Show Task Allocation Details
                      taskListId === selectUnsavedEmailsDetails?.id && (
                        <div
                          id="AddToTasklistSection"
                          className="z-20 flex flex-col w-full"
                        >
                          <span
                            className="z-20 flex pt-4 pb-3 text-sm"
                            onClick={() => {
                              setShowTaskListDetails(false);
                              setTaskListId("");
                            }}
                          >
                            <img src={arrowUp} alt="UP"></img>&nbsp;
                            {selectedTaskList?.includes(
                              selectUnsavedEmailsDetails?.id
                            ) ||
                            !(
                              selectUnsavedEmailsDetails?.taskList?.id ==
                                null ||
                              selectUnsavedEmailsDetails?.taskList?.id == ""
                            )
                              ? "Show Task Details"
                              : "Add To Tasklist"}
                          </span>
                          <div className="z-20 mb-0 flex flex-col w-full gap-2 items-start">
                            <div className="w-full">
                              <CreatableSelect
                                options={people}
                                defaultValue={getAssignee(
                                  selectUnsavedEmailsDetails.assignee
                                )}
                                className="z-40"
                                noOptionsMessage={() => "No result found"}
                                isValidNewOption={() => false}
                                menuPortalTarget={document.body}
                                styles={selectStyle}
                                menuPlacement="bottom"
                                menuShouldScrollIntoView={true}
                                placeholder="Select Assignee"
                                isMulti
                                components={{
                                  ClearIndicator: CustomClearIndicator,
                                }}
                                closeMenuOnSelect={false}
                                blurInputOnSelect={false}
                                onChange={(e) =>
                                  saveAssignee(e, selectUnsavedEmailsDetails)
                                }
                                onFocus={() => {
                                  dynamicSetPeople(selectUnsavedEmailsDetails);
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <CreatableSelect
                                options={[
                                  { value: 1, label: 1 },
                                  { value: 2, label: 2 },
                                  { value: 3, label: 3 },
                                ]}
                                defaultValue={
                                  selectUnsavedEmailsDetails?.priority !== null
                                    ? {
                                        label:
                                          selectUnsavedEmailsDetails?.priority,
                                        value:
                                          selectUnsavedEmailsDetails?.priority,
                                      }
                                    : { label: 1, value: 1 }
                                }
                                classNa
                                me="z-40"
                                noOptionsMessage={() => "No result found"}
                                isValidNewOption={() => false}
                                menuPlacement="bottom"
                                menuPortalTarget={document.body}
                                styles={selectStyle}
                                menuShouldScrollIntoView={true}
                                placeholder="Select Priority"
                                onChange={(e) =>
                                  savePriority(
                                    e,
                                    selectUnsavedEmailsDetails?.id,
                                    selectUnsavedEmailsDetails?.notes
                                  )
                                }
                              />
                            </div>
                            <div
                              rows={1}
                              className="overflow w-full font-poppins resize-none rounded border border-gray-300 bg-white text-gray-500 px-2 py-2"
                              style={{ minHeight: "38px" }}
                              suppressContentEditableWarning
                              dangerouslySetInnerHTML={{
                                __html:
                                  selectUnsavedEmailsDetails?.notes !== null
                                    ? selectUnsavedEmailsDetails?.notes
                                    : "",
                              }}
                              onBlur={(e) =>
                                saveNotes(
                                  e,
                                  selectUnsavedEmailsDetails?.id,
                                  selectUnsavedEmailsDetails?.priority
                                )
                              }
                              onInput={(e) => {
                                handleChangeNotes(e);
                                autoAdjustAllRow();
                              }}
                              contentEditable={true}
                            />
                            <div className="flex w-full gap-2">
                              {(selectedTaskList?.includes(
                                selectUnsavedEmailsDetails?.id
                              ) ||
                                !selectUnsavedEmailsDetails?.taskList?.id ==
                                  null ||
                                !selectUnsavedEmailsDetails?.taskList?.id ==
                                  "") && (
                                <div className="flex gap-2">
                                  <button
                                    className={
                                      "flex items-center justify-center rounded border p-2  w-full " +
                                      (showRemoveTask ||
                                      selectUnsavedEmailsDetails?.taskList
                                        ?.isDeleted
                                        ? "border-red-300 bg-gray-50 text-red-300 cursor-not-allowed"
                                        : "border-red-500 bg-white text-red-500")
                                    }
                                    onClick={(e) => {
                                      // if (
                                      //   selectedTaskList.includes(
                                      //     selectUnsavedEmailsDetails.id
                                      //   )
                                      // )
                                      handleSelectForTaskList(
                                        e,
                                        selectUnsavedEmailsDetails,
                                        selectUnsavedEmailsDetails.id,
                                        "remove"
                                      );
                                      setShowRemoveTask(true);
                                    }}
                                    disabled={
                                      showRemoveTask ||
                                      selectUnsavedEmailsDetails?.taskList
                                        ?.isDeleted
                                    }
                                  >
                                    {showRemoveTask ||
                                    selectUnsavedEmailsDetails?.taskList
                                      ?.isDeleted
                                      ? "Deleted"
                                      : "Remove"}
                                  </button>

                                  {/* MMA 2251 GS - Added a second button for archived tasklist, that are to save modification via adding in selectedTaskList state */}
                                  {selectUnsavedEmailsDetails?.taskList
                                    ?.isDeleted &&
                                  !selectedTaskList?.includes(
                                    selectUnsavedEmailsDetails?.id
                                  ) ? (
                                    <button
                                      className="flex items-center justify-center rounded border border-green-500 bg-white p-2 text-green-500 w-full"
                                      onClick={(e) => {
                                        if (
                                          !selectedTaskList?.includes(
                                            selectUnsavedEmailsDetails.id
                                          )
                                        )
                                          handleSelectForTaskList(
                                            e,
                                            selectUnsavedEmailsDetails,
                                            selectUnsavedEmailsDetails.id,
                                            "add"
                                          );
                                        //setShowSave(false)
                                      }}
                                    >
                                      Save
                                    </button>
                                  ) : null}
                                </div>
                              )}
                              {/* MMA-1985 FIX - Removed 2nd Save button */}

                              {!(
                                selectedTaskList?.includes(
                                  selectUnsavedEmailsDetails?.id
                                ) ||
                                !selectUnsavedEmailsDetails?.taskList?.id ==
                                  null ||
                                !selectUnsavedEmailsDetails?.taskList?.id == ""
                              ) && (
                                <button
                                  className="flex items-center justify-center rounded border border-green-500 bg-white p-2 px-5 text-green-500"
                                  onClick={(e) => {
                                    if (
                                      !selectedTaskList?.includes(
                                        selectUnsavedEmailsDetails.id
                                      )
                                    )
                                      handleSelectForTaskList(
                                        e,
                                        selectUnsavedEmailsDetails,
                                        selectUnsavedEmailsDetails.id,
                                        "add"
                                      );

                                    // AQS
                                    setSelectedTaskRegister((prev) => [
                                      ...prev,
                                      {
                                        id: selectUnsavedEmailsDetails.id,
                                        registers: [],
                                      },
                                    ]);
                                    saveTaskRegister(
                                      selectUnsavedEmailsDetails.id,
                                      [
                                        taskRegisterList.find(
                                          (register) =>
                                            register?.label
                                              .toLowerCase()
                                              .replace(/ /g, "") === "tasklist"
                                        ),
                                      ]
                                    );
                                    //setShowSave(false)
                                  }}
                                >
                                  Save
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    {/* TASK REGISTER */}
                    <div className="gap-5 justify-center relative">
                      <label
                        className="text-sm flex gap-1 whitespace-nowrap py-3 w-full"
                        style={{ width: "fitContent" }}
                      >
                        {showTaskRegisterDetails ? (
                          <img src={arrowUp} alt="UP" />
                        ) : (
                          <img src={arrowDown} alt="DOWN" />
                        )}
                        Task Register
                        <input
                          className="mr-1 cursor-pointer absolute opacity-0"
                          onChange={(e) => {
                            if (
                              !selectedTaskRegister.find(
                                (item) =>
                                  item.id === selectUnsavedEmailsDetails.id
                              )
                            ) {
                              setSelectedTaskRegister((prev) => [
                                ...prev,
                                {
                                  id: selectUnsavedEmailsDetails.id,
                                  registers: [],
                                },
                              ]);
                              saveTaskRegister(selectUnsavedEmailsDetails.id, [
                                taskRegisterList.find(
                                  (register) =>
                                    register?.label
                                      .toLowerCase()
                                      .replace(/ /g, "") === "tasklist"
                                ),
                              ]);
                            }
                            setShowTaskRegisterDetails((e) => !e);
                            // handleSelectForTaskRegister(
                            //   e,
                            //   selectUnsavedEmailsDetails.clientMatters
                            //     ?.items.length,
                            //   selectUnsavedEmailsDetails.id
                            // );
                          }}
                          type="checkbox"
                          value={selectUnsavedEmailsDetails.id}
                          id={selectUnsavedEmailsDetails.id}
                          // checked={selectedTaskRegister.includes(
                          //   item.id
                          // )}
                          checked={selectedTaskRegister.some(
                            (register) =>
                              register.id === selectUnsavedEmailsDetails.id
                          )}
                        />
                      </label>

                      {showTaskRegisterDetails && (
                        <div className="flex w-full">
                          <div className="w-full mb-3">
                            <CreatableSelect
                              // key={item.id}
                              options={taskRegisterList}
                              className="focus:z-40 flex-4 w-full"
                              noOptionsMessage={() => "No options found"}
                              menuPlacement="top"
                              closeMenuOnSelect={false}
                              defaultValue={() =>
                                processTaskRegister(
                                  selectUnsavedEmailsDetails?.id
                                )
                              }
                              // value={selectedTaskRegister?.flatMap((values) => {
                              //   if (
                              //     selectUnsavedEmailsDetails?.id === values.id
                              //   ) {
                              //     return Array.isArray(values.registers)
                              //       ? [...values.registers]
                              //       : [values.registers];
                              //   }
                              //   return []; // Return an empty array if the condition is not met
                              // })}
                              menuPortalTarget={document.body}
                              styles={selectStyle}
                              menuShouldScrollIntoView={true}
                              placeholder="Select Task Register"
                              isMulti
                              onChange={
                                (values) =>
                                  saveTaskRegister(
                                    selectUnsavedEmailsDetails.id,
                                    values
                                  )
                                // (e) => console.log(e)
                              }
                              components={{
                                ClearIndicator: CustomClearIndicator,
                              }}
                              blurInputOnSelect={false}
                              // onFocus={() => dynamicSetPeople(item)}
                              onCreateOption={(newRegister) => {
                                handleCreateTaskRegister(
                                  selectUnsavedEmailsDetails.id,
                                  newRegister
                                );
                                onCreateNewOption(newRegister);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <hr />
                    {/* COMPOSE EMAIL */}
                    {/* REPLY AND FORWARD TEMPORARILY DISABLED*/}
                    {/* {allowComposeEmail && (
                        <button
                          className="w-full py-3 my-2 flex items-center justify-center rounded-md bg-green-500 p-2 px-3 text-white hover:bg-green-400"
                          onClick={() => {
                            handleComposeUnsavedTabs(
                              isUpdatedClientMatter,
                              true,
                              true,
                              selectUnsavedEmailsDetails,
                              threadId,
                              messageId,
                              emailHTML
                            );
                          }}
                        >
                          <span className="mr-1 flex font-semibold ">
                            <BiPencil />
                            Compose
                          </span>
                        </button>
                      )} */}
                    {/* COPY EMAIL */}
                    {!showCopy ? (
                      <>
                        <button
                          onClick={() => {
                            let temp = [];
                            unSavedEmails.map((x) => {
                              temp = [...temp, x.id];
                            });
                            //console.log("APPLY TO ALL present: " + temp);
                            let copyIndex = temp.indexOf(copyId);
                            for (let i = 0; i < temp.length; i++) {
                              if (i !== copyIndex) {
                                handlePaste(temp[i], i);
                              }
                            }
                            autoAdjustAllRow();
                            console.log("pastedIds: ", pastedIds);
                          }}
                          className="block w-full text-secondary-l text-sm font-medium py-3 px-3 rounded-md bg-blue-400"
                        >
                          Copy to All
                        </button>
                        <button
                          onClick={() => {
                            // if (cpyIsTaskList) {
                            //   setCpyIsTaskList(false);
                            // }
                            setCopyId("");
                            setPastedIds([]);
                            setShowCopy(true);
                            setCopyDetails([]);
                            console.log(
                              "will be saved in tasklist",
                              selectedTaskList,
                              selectedUnsavedItems
                            );
                            autoAdjustAllRow();
                            setProcPaste(false);
                          }}
                          className="block w-full text-secondary-l text-sm font-medium mt-3 py-3 px-3 rounded-md bg-green-500"
                        >
                          Done
                        </button>
                        <hr className="bg-gray-300 mt-2" />
                      </>
                    ) : (
                      unSavedEmails[selectedUnsavedIndex]?.clientMatters?.items
                        ?.length > 0 && (
                        <>
                          <button
                            className="w-full block text-sm font-medium flex items-center justify-center rounded-md bg-green-500 py-3 px-3 text-white hover:bg-green-400"
                            onClick={() => {
                              autoAdjustAllRow();
                              handleCopy(
                                unSavedEmails[selectedUnsavedIndex]?.id,
                                unSavedEmails[selectedUnsavedIndex]
                                  ?.clientMatters,
                                unSavedEmails[selectedUnsavedIndex]
                              );
                            }}
                          >
                            Copy
                          </button>
                          <hr className="bg-gray-300 mt-2" />
                        </>
                      )
                    )}

                    {/* UNSAVE EMAIL AND SAVE TO TASKLIST*/}
                    {showCopy && (
                      <ActionButtons
                        setUnsavedEmailsCount={setUnsavedEmailsCount}
                        setSavedEmailsCount={setSavedEmailsCount}
                        setArchivedEmailsCount={setArchivedEmailsCount}
                        taskListColumns={taskListColumns}
                        selectedUnsavedItems={selectedUnsavedItems}
                        setSelectedUnsavedItems={setSelectedUnsavedItems}
                        selectedArchivedItems={selectedArchivedItems}
                        setSelectedArchivedItems={setSelectedArchivedItems}
                        taskRegisterList={taskRegisterList}
                        getUnSavedEmails={getUnSavedEmails}
                        unSavedEmails={unSavedEmails}
                        setUnsavedEmails={setUnsavedEmails}
                        setResultMessage={setResultMessage}
                        setShowToast={setShowToast}
                        saveLoading={saveLoading}
                        setSaveLoading={setSaveLoading}
                        sortByDate={sortByDate}
                        archivedEmails={archivedEmails}
                        setArchivedEmails={setArchivedEmails}
                        setSelectedTaskList={setSelectedTaskList}
                        selectedTaskList={selectedTaskList}
                        selectedTaskRegister={selectedTaskRegister}
                        setSelectedTaskRegister={setSelectedTaskRegister}
                        displayImageAttachments={displayImageAttachments}
                        setEmailContentOpen={setEmailContentOpen}
                        setMobileLoading={setMobileLoading}
                        showDetailsCopy={showDetailsCopy}
                        setShowDetailsCopy={setShowDetailsCopy}
                        showCopy={showCopy}
                        setShowCopy={setShowCopy}
                        searchGmail={searchGmail}
                        isOutlookIntegrated={isOutlookIntegrated}
                        emailFilters={emailFilters}
                        tasklistGmailIds={tasklistGmailIds}
                        setTasklistGmailIds={setTasklistGmailIds}
                        unSavedForTaskListCtr={unSavedForTaskListCtr}
                        savedForTaskListCtr={savedForTaskListCtr}
                        getArchivedEmails={getArchivedEmails}
                        setDoneAllocatingEmails={setDoneAllocatingEmails}
                        setpendingMessage={setpendingMessage}
                        setPreviousUnsavedEmails={setPreviousUnsavedEmails}
                        setPreviousSavedEmails={setPreviousSavedEmails}
                        setPreviousArchivedEmails={setPreviousArchivedEmails}
                        setSearchGmail={setSearchGmail}
                        selectAllArchived={selectAllArchived}
                        selectAllSaved={selectAllSaved}
                        selectAllUnsaved={selectAllUnsaved}
                        setDisplayImageAttachments={setDisplayImageAttachments}
                        selectAllCheck={selectAllCheck}
                        setSelectAllCheck={setSelectAllCheck}
                        handleExecuteFilter={handleExecuteFilter}
                        savedEmails={savedEmails}
                        setSavedEmails={setSavedEmails}
                        getSavedEmails={getSavedEmails}
                        selectedSavedItems={selectedSavedItems}
                        setSelectedSavedItems={setSelectedSavedItems}
                        openTab={openTab}
                        fetchData={fetchData}
                        setWaitUnSaved={setWaitUnSaved}
                        setWaitSaved={setWaitSaved}
                        setWaitArchived={setWaitArchived}
                        setOpenTab={setOpenTab}
                        saveProgress={saveProgress}
                        setSaveProgress={setSaveProgress}
                      />
                    )}
                  </div>
                  <div className="my-5 border-l border-gray-300"></div>
                  <div className="inboxScrollInner overflow-y-auto p-5 pr-4">
                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: processEmailHtml(emailHTML),
                      }}
                    ></p> */}
                    <Highlight text={processEmailHtml(emailHTML)} highlight={searchGmail} classNames={"inboxScrollInner overflow-y-auto p-5 pr-4"} />
                    <hr className="border mt-5" />
                    <div className="mt-5 mb-5 flex w-full flex-col">
                      <div className="flex justify-between">
                        <p>Attachments and Labels</p>
                        <ActionButtons
                          setUnsavedEmailsCount={setUnsavedEmailsCount}
                          setSavedEmailsCount={setSavedEmailsCount}
                          setArchivedEmailsCount={setArchivedEmailsCount}
                          taskListColumns={taskListColumns}
                          selectedUnsavedItems={selectedUnsavedItems}
                          setSelectedUnsavedItems={setSelectedUnsavedItems}
                          selectedSavedItems={selectedSavedItems}
                          setSelectedSavedItems={setSelectedSavedItems}
                          selectedArchivedItems={selectedArchivedItems}
                          setSelectedArchivedItems={setSelectedArchivedItems}
                          taskRegisterList={taskRegisterList}
                          openTab={openTab}
                          fetchData={fetchData}
                          setWaitUnSaved={setWaitUnSaved}
                          setWaitSaved={setWaitSaved}
                          setWaitArchived={setWaitArchived}
                          setOpenTab={setOpenTab}
                          getUnSavedEmails={getUnSavedEmails}
                          getSavedEmails={getSavedEmails}
                          unSavedEmails={unSavedEmails}
                          setUnsavedEmails={setUnsavedEmails}
                          savedEmails={savedEmails}
                          setSavedEmails={setSavedEmails}
                          setResultMessage={setResultMessage}
                          setShowToast={setShowToast}
                          saveLoading={saveLoading}
                          setSaveLoading={setSaveLoading}
                          setDoneAllocatingEmails={setDoneAllocatingEmails}
                          setpendingMessage={setpendingMessage}
                          sortByDate={sortByDate}
                          getArchivedEmails={getArchivedEmails}
                          archivedEmails={archivedEmails}
                          setArchivedEmails={setArchivedEmails}
                          setSelectedTaskList={setSelectedTaskList}
                          selectedTaskList={selectedTaskList}
                          selectedTaskRegister={selectedTaskRegister}
                          setSelectedTaskRegister={setSelectedTaskRegister}
                          tasklistGmailIds={tasklistGmailIds}
                          setTasklistGmailIds={setTasklistGmailIds}
                          unSavedForTaskListCtr={unSavedForTaskListCtr}
                          savedForTaskListCtr={savedForTaskListCtr}
                          displayImageAttachments={displayImageAttachments}
                          setDisplayImageAttachments={
                            setDisplayImageAttachments
                          }
                          selectAllCheck={selectAllCheck}
                          setSelectAllCheck={setSelectAllCheck}
                          setEmailContentOpen={setEmailContentOpen}
                          selectAllArchived={selectAllArchived}
                          selectAllSaved={selectAllSaved}
                          selectAllUnsaved={selectAllUnsaved}
                          Updated
                          upstream
                          setMobileLoading={setMobileLoading}
                          showDetailsCopy={showDetailsCopy}
                          setShowDetailsCopy={setShowDetailsCopy}
                          showCopy={showCopy}
                          setShowCopy={setShowCopy}
                          searchGmail={searchGmail}
                          setSearchGmail={setSearchGmail}
                          isOutlookIntegrated={isOutlookIntegrated}
                          setPreviousUnsavedEmails={setPreviousUnsavedEmails}
                          setPreviousSavedEmails={setPreviousSavedEmails}
                          setPreviousArchivedEmails={setPreviousArchivedEmails}
                          emailFilters={emailFilters}
                          handleExecuteFilter={handleExecuteFilter}
                          hideAttachmentOnly={true}
                          saveProgress={saveProgress}
                          setSaveProgress={setSaveProgress}
                        />
                      </div>
                      {selectUnsavedEmailsDetails?.attachments?.items?.map(
                        (item_attach, indexAttachments) => (
                          <React.Fragment key={item_attach.id}>
                            <div className="flex-grow mb-4">
                              <div className="align-center mt-2 flex">
                                <div
                                  className="focus:outline-none mr-1 inline-flex w-16 flex-none cursor-pointer items-center rounded bg-gray-100 px-2 py-1 text-xs font-semibold text-opacity-90 hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                                  id={item_attach?.id}
                                  title={item_attach?.name}
                                  onClick={() =>
                                    previewAndDownloadFile(item_attach?.id)
                                  }
                                >
                                  {item_attach?.name.substring(0, 10)}
                                  {item_attach?.name?.length >= 10 ? "..." : ""}
                                </div>
                                {loadLabels ? (
                                  <div className="flex-1 items-center py-1 align-top text-green-400">
                                    Updating..
                                  </div>
                                ) : (
                                  <div className="flex-1 py-1 align-top">
                                    <>
                                      <DropdownSelect
                                        // values={item_attach?.labels?.items?.map(
                                        //   (att) => ({
                                        //     value: att?.id,
                                        //     label: att?.name,
                                        //   })
                                        // )}
                                        values={selectUnsavedEmailsDetails?.labels?.items.map(
                                          (item_label) => ({
                                            value: item_label?.id,
                                            label: item_label?.name,
                                          })
                                        )}
                                        menuPlacement={
                                          selectedUnsavedIndex >=
                                            unSavedEmails.length - 2 &&
                                          unSavedEmails.length > 2
                                            ? "top"
                                            : "bottom"
                                        }
                                        multi={true}
                                        clearable={false}
                                        searchable={true}
                                        options={[
                                          ...labelsOptions,
                                          ...newlyAddedLabels?.filter(
                                            (cml, i) =>
                                              filterNewlyAddedLabels(
                                                cml,
                                                selectUnsavedEmailsDetails
                                                  ?.clientMatters?.items
                                              )
                                          ),
                                        ]}
                                        create={true} // SET TO TRUE IF WANT TO ENABLE THE CREATION OF NEW LABELS
                                        disabled={
                                          saveLoading
                                            ? true
                                            : checkArrLength(
                                                selectUnsavedEmailsDetails?.clientMatters
                                              ) === true ||
                                              checkEnable(
                                                selectUnsavedEmailsDetails?.id
                                              )
                                            ? false
                                            : true
                                        }
                                        onChange={(e) => {
                                          if (enableDropdown) {
                                            handleAddEmailAttachmentLabel(
                                              e,
                                              item_attach?.id,
                                              selectUnsavedEmailsDetails?.id,
                                              selectUnsavedEmailsDetails?.index
                                            );
                                          }
                                        }}
                                        onDropdownOpen={async (e) => {
                                          console.log('Mobile')
                                          await handleOnMenuOptions(
                                            selectUnsavedEmailsDetails
                                              ?.clientMatters?.items
                                          );
                                        }}
                                        color="#8a8a8a"
                                        onDropdownClose={() => {
                                          console.log("CLOSE");
                                          setEnableDropdown(false);
                                        }}
                                        placeholder="Labels"
                                        className="outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                                      />
                                    </>
                                  </div>
                                )}

                                {item_attach?.isDeleted === false ||
                                item_attach?.isDeleted === null ? (
                                  <span
                                    className="flex justify-center items-center ml-2 w-14 cursor-pointer text-xs hover:text-red-700"
                                    onClick={(e) =>
                                      handleDeleteAttachment(
                                        item_attach?.id,
                                        selectUnsavedEmailsDetails?.id,
                                        indexAttachments,
                                        true,
                                        e
                                      )
                                    }
                                  >
                                    Remove
                                  </span>
                                ) : (
                                  <span
                                    className="flex justify-center items-center ml-2 w-14 cursor-pointer text-xs hover:text-red-700"
                                    onClick={(e) =>
                                      handleDeleteAttachment(
                                        item_attach.id,
                                        selectUnsavedEmailsDetails?.id,
                                        indexAttachments,
                                        false,
                                        e
                                      )
                                    }
                                  >
                                    Show
                                  </span>
                                )}
                              </div>
                              <div
                                className={
                                  !item_attach?.isDeleted ||
                                  item_attach?.isDeleted === null
                                    ? "font-poppins mt-1 h-full w-full whitespace-normal rounded-sm p-2"
                                    : "font-poppins mt-1 h-full w-full whitespace-normal rounded-sm bg-gray-300 p-2"
                                }
                                style={{
                                  border: "solid 1px #c4c4c4",
                                  cursor: "auto",
                                  outlineColor: "rgb(204, 204, 204, 0.5)",
                                  outlineWidth: "thin",
                                }}
                                suppressContentEditableWarning
                                dangerouslySetInnerHTML={{
                                  __html: item_attach.details,
                                }}
                                onBlur={(e) =>
                                  handleSaveDesc(
                                    e,
                                    item_attach?.id,
                                    selectUnsavedEmailsDetails?.id,
                                    selectedUnsavedIndex
                                  )
                                }
                                onInput={(e) =>
                                  handleOnKeyupRows(e, selectedUnsavedIndex)
                                }
                                contentEditable={
                                  !item_attach?.isDeleted ||
                                  item_attach?.isDeleted === null
                                    ? true
                                    : false
                                }
                              ></div>
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // NOTE: Default Message
              <>
                <div className="border-gray-200 border-t border-l  w-full col-span-3 flex flex-col items-center justify-start ">
                  <>
                    {selectedUnsavedItems.length > 0 ? (
                      <div className="mt-10 inline-flex flex-wrap gap-2">
                        <span className="text-lg font-semibold text-gray-500">
                          {selectedUnsavedItems.length}
                        </span>
                        <span className="text-lg font-semibold text-gray-500">
                          Total Selected Items
                        </span>
                      </div>
                    ) : (
                      <span className="mt-10 text-lg font-semibold text-gray-500">
                        No Emails Selected
                      </span>
                    )}
                  </>
                  <div className="mt-7 h-2 w-64 rounded-3xl bg-gray-300"></div>
                  <div className="mt-9 text-center">
                    {/* <p>0 / 80000</p> */}
                  </div>
                </div>
              </>
            )}
          </div>
          {/*{maxLoadingUnSavedEmail ? (
        <>
          <div className="flex items-center justify-center mt-5">
            All Data has been loaded
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-center mt-5">
            <img src={imgLoading} alt="" width={50} height={100} />
          </div>
        </>
      )}*/}

          {showCreateClientMatter && (
            <AddClientMatter
              close={() => setShowCreateClientMatter(false)}
              clientMatters={matterList}
              fetchCompanyClientMatters={fetchCompanyClientMatters}
            />
          )}
        </>
      ) : (
        //mobile view -kjmf//
        <>
          <table
            id="table-el"
            className=" border-slate-100 min-w-full table-fixed divide-y divide-gray-200 border-b-2 border-l-2 border-r-2 text-xs"
            style={{ width: "100%" }}
          >
            <tbody
              className="divide-y divide-gray-200 bg-white"
              style={{ width: "100%", 
                height: totalSize
              }}
            >
              
              {!mobileLoading && !waitUnSaved ? (
                <>
                  {virtualRows.map((row) => {
                    const item = unSavedEmails[row.index];
                    return (
                      <div
                        // id="mobileContent"
                        ref={(e) => row.measureElement(e)}
                        scrollTop={
                          scrollPosition === -1
                            ? undefined
                            : scrollPosition * 310
                        }
                        scrollToIndex={scrollIndex}
                        // scrollTop={scrollPosition}
                        onScroll={() => {
                          if (scrollPosition !== -1) {
                            onScroll(-1);
                          }

                          if (notesIdChange !== null) {
                            saveNotesOnScroll();
                          }
                        }}
                        // height={totalSize}
                        // width={width}
                        // className="overflow-y-scroll h-48"
                      >
                        <tr
                          className="test123 w-full p-1 pt-0 block "
                          key={row.key}
                          onClick={() => autoAdjustAllRow()} //Just in case user clicks card
                        >
                          <div
                            id="itemDiv"
                            className={
                              copyId === item.id
                                ? "mt-1 flex w-full cursor-pointer rounded-lg border border-blue-500 bg-blue-100 px-2 py-2 "
                                : pastedIds?.includes(item.id)
                                ? "mt-1 block w-full cursor-pointer rounded-lg border border-green-500 bg-green-100 px-2 py-2 "
                                : "mt-1 block w-full cursor-pointer rounded-lg border border-gray-200 bg-gray-100 px-2 py-2 " +
                                  (item.clientMatters?.items?.length > 0
                                    ? ""
                                    : "pb-7")
                            }
                            style={{
                              width: "95vw",
                            }}
                          >
                            <div className="block" id="clickedDiv">
                              <div className="flex h-fit">
                                <div className="w-5 flex-none">
                                  {item.clientMatters?.items?.length > 0 ? (
                                    <input
                                      key={item.id}
                                      className="mr-1 cursor-pointer"
                                      onChange={(e) => {
                                        handleSelectItem(
                                          e,
                                          item.clientMatters?.items?.length,
                                          item.priority,
                                          item.notes
                                        );
                                        handleSelectedEmail(row.index);
                                        handleScrollIndex(-1);
                                        console.log(item);
                                      }}
                                      type="checkbox"
                                      value={item.id}
                                      id={item.id}
                                      checked={selectedUnsavedItems?.includes(
                                        item.id
                                      )}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="w-90 flex-1">
                                  <div
                                    onClick={(e) =>
                                      // handleSnippet(item, index)
                                      handleMobileSnippet(item, row.index)
                                    }
                                  >
                                    <p className="font-semibold">
                                      {/* {item.subject} */}
                                      <Highlight text={item?.subject} highlight={searchGmail} classNames="font-semibold" /> 
                                    </p>
                                    <p className="text-xs">
                                      <Highlight text={item?.from} highlight={searchGmail} classNames="text-xs" /> at{" "}
                                      {dateTimeNoSecondsFormatter(
                                        new Date(item?.date)
                                      )}
                                    </p>
                                  </div>
                                  <p
                                    className="text-xs font-semibold"
                                    style={{ zIndex: 999999 }}
                                  >
                                    {showCopy === true ? (
                                      <>
                                        <div
                                          className="max-w-72"
                                          style={{
                                            maxWidth:
                                              width <= 540 ? "18rem" : "24rem",
                                            zIndex: 40,
                                          }}
                                        >
                                          {loadingClientMatters.find(
                                            (e) => e === item?.id
                                          ) || loadingCM === true ? (
                                            <div className="relative">
                                              <MemoizedDropdownSelect
                                                className="relative float-right z-50 outline-none background-slate-500 text-green-500 focus:outline-none mb-2.5 w-full rounded border-0 bg-white text-xs shadow focus:ring placeholder-opacity-100"
                                                // className="placeholder-blueGray-300 focus:z-40 focus:outline-none relative float-right mb-2 w-full rounded border-0 background-slate-500 text-green-500 text-xs focus:ring"
                                                disabled={true}
                                                placeholder=""
                                                value={{
                                                  value: null,
                                                  label: "",
                                                }}
                                              />
                                              <div className="absolute z-10 text-green-500 font-bold top-0 left-0 m-3 opacity-60">
                                                UPDATING..
                                              </div>
                                            </div>
                                          ) : (
                                            <CreatableSelect
                                              isCreatable={false}
                                              options={matterList}
                                              dropdownHandle
                                              onChange={(e) => {
                                                setLoadingClientMatters(
                                                  (prev) => [...prev, item?.id]
                                                );
                                                setLoadedLabels((prev) => [
                                                  ...prev,
                                                  item?.id,
                                                ]);
                                                handleClientMatter1(
                                                  e,
                                                  item?.id,
                                                  row.index,
                                                  item
                                                );
                                                setSelectedIndex(row.index);
                                              }}
                                              // value={unSavedEmails[
                                              //   row.index
                                              // ]?.clientMatters?.items.map(
                                              //   (item) => ({
                                              //     value: item.id,
                                              //     label: `${item?.client?.name}/${item?.matter?.name}`,
                                              //   })
                                              // )}
                                              value={unSavedEmails[row.index]?.clientMatters?.items?.length === 0 
                                                ? unSavedEmails[row.index].suggestedCM
                                                : unSavedEmails[row.index]?.clientMatters?.items?.map((item_clientMatter, index) => ({
                                                    value: item_clientMatter?.id,
                                                    label:
                                                      item_clientMatter?.client?.name +
                                                      "/" +
                                                      item_clientMatter?.matter?.name,
                                                  })
                                                )}
                                                defaultValue={unSavedEmails[row.index]?.clientMatters?.items?.length === 0 
                                                  ? unSavedEmails[row.index].suggestedCM
                                                  : unSavedEmails[row.index]?.clientMatters?.items?.map((item_clientMatter, index) => ({
                                                      value: item_clientMatter?.id,
                                                      label:
                                                        item_clientMatter?.client?.name +
                                                        "/" +
                                                        item_clientMatter?.matter?.name,
                                                    })
                                                )}
                                              //className="outline-none placeholder-blueGray-300 text-blueGray-600 focus:z-40 focus:outline-none relative float-right mb-2 w-full rounded border-0 bg-white text-sm focus:ring"
                                              className={unSavedEmails[selectedUnsavedIndex]?.clientMatters?.items?.length === 0 
                                                  ? "outline-none placeholder-blue-400 text-blue-600 focus:outline-none relative float-right mb-4 w-full rounded border-0 bg-white text-sm focus:ring"
                                                  : "outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none relative float-right mb-4 w-full rounded border-0 bg-white text-sm focus:ring"
                                              }
                                              placeholder={
                                                hbaDomains.includes(currUrl)
                                                  ? "Project Name"
                                                  : `Client/Matter`
                                              }
                                              menuPlacement={
                                                row.index === 0
                                                  ? "bottom"
                                                  : "top"
                                              }
                                              isSearchable
                                              closeMenuOnSelect
                                              blurInputOnSelect={false}
                                              noOptionsMessage={() =>
                                                `No ${
                                                  hbaDomains.includes(currUrl)
                                                    ? "Project Name"
                                                    : "Client/Matter"
                                                } found.`
                                              }
                                              isValidNewOption={() => false}
                                              styles={unSavedEmails[row.index]?.clientMatters?.items?.length === 0 ? customStyles : customStyles1}
                                            />
                                          )}
                                        </div>
                                        
                                        {unSavedEmails[row.index].suggestedCM && unSavedEmails[row.index]?.clientMatters?.items?.length === 0 &&
                                          <span className="right-0 float-right -mt-1 text-black cursor-pointer bg-green-300 h-8 px-2 rounded-md my-1 mr-6"
                                          onClick={()=>
                                          {
                                            console.log("unSavedEmails[row.index].suggestedCM", unSavedEmails[row.index].suggestedCM)
                                            handleClientMatter1(
                                              unSavedEmails[row.index]?.suggestedCM,
                                              unSavedEmails[row.index].id,
                                              row.index,
                                              unSavedEmails[row.index]
                                            );
                                          }
                                        }>
                                          <p className="mt-2">Confirm</p>
                                        </span>
                                        
                                        }

                                        {/* <span className="right-0 float-right -mt-1 text-black cursor-pointer bg-green-300 h-8 px-2 rounded-md my-1 mr-6"
                                          onClick={()=>
                                          {
                                            console.log("unSavedEmails[row.index].suggestedCM", unSavedEmails[row.index].suggestedCM)
                                            // handleClientMatter1(
                                            //   unSavedEmails[row.index]?.suggestedCM,
                                            //   unSavedEmails[row.index].id,
                                            //   row.index,
                                            //   unSavedEmails[row.index]
                                            // );
                                          }
                                        }>
                                          <p className="mt-2">Confirm</p>
                                        </span> */}
                                        {/* {unSavedEmails[row.index]?.clientMatters?.items?.length > 0 ? (
                                          <></>
                                          ) : (
                                            <div className="w-full relative py-2 mb-2 pb-4 ">
                                              {!mobileSuggestionIdList.includes(item.id) ?
                                                (
                                                  <span className="right-0 float-right -mt-1 text-black cursor-pointer bg-blue-300 h-8 px-2 rounded-md my-1 mr-6"
                                                    onClick={()=>
                                                      {
                                                        setSelectUnsavedEmailsDetails(item);
                                                        setSelectedUnsavedIndex(row.index);
                                                        setSelectedEmail(item.id);
                                                        setHasSuggestion(!hasSuggestion);
                                                        handleAddSuggestionId(item.id);
                                                        console.log("selected", item.id)
                                                      }
                                                    }>
                                                      <p className="mt-2">Suggest Client/Matter</p>
                                                    </span>
                                                )
                                                :
                                                (
                                                  cmSuggestionMobile.find(x => x.id === item.id) &&
                                                  <div>
                                                    <span className="right-0 float-right -mt-1 text-black cursor-pointer bg-green-300 h-8 px-2 rounded-md my-1 mr-6"
                                                      onClick={()=>
                                                      {
                                                        console.log("cmSuggestion", cmSuggestion)
                                                        handleClientMatter1(
                                                          cmSuggestion ? cmSuggestion : null,
                                                          selectUnsavedEmailsDetails?.id,
                                                          selectedUnsavedIndex,
                                                          selectUnsavedEmailsDetails
                                                        );
                                                        handleRemoveSuggestion(item.id)
                                                      }
                                                    }>
                                                      <p className="mt-2">Confirm</p>
                                                    </span>
                                                    <span className="px-3 float-right"
                                                      onClick={()=>{handleRemoveSuggestion(item.id);setHasSuggestion(!hasSuggestion)}}
                                                    >
                                                      Hide Suggestion
                                                    </span>
                                                    
                                                  </div>
                                                )
                                              }
                                              
                                            </div>
                                          )
                                        } */}
                                        {/** Start Main Email Labels  */}
                                        <div
                                          className="max-w-72"
                                          style={{
                                            maxWidth:
                                              width <= 540 ? "18rem" : "24rem",
                                          }}
                                          disabled={true}
                                        >
                                          {/* {loadedLabels.find((e) => e === item?.id) ? ( */}
                                          {!loadedLabels ? (
                                            // loadLabels &&
                                            // selectedIndex ==
                                            //   index ? (
                                            // Buffer UI when user selected/change a new matter {console.log(`SelectedIndex: ${selectedIndex} Index: ${index}`)}
                                            <div className="relative">
                                              <MemoizedDropdownSelect
                                                className="absolute z-1 outline-none background-slate-500 text-green-500 focus:outline-none z-100 mt-2 w-full rounded border-0 bg-white text-xs shadow focus:ring placeholder-opacity-100"
                                                disabled={true}
                                                placeholder=""
                                              />
                                              <div className="absolute z-2 text-green-500 font-bold top-11 left-0 m-3 opacity-60">
                                                UPDATING..
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <DropdownSelect
                                                dropdownPosition={
                                                  row.index === 0
                                                    ? "bottom"
                                                    : "top"
                                                }
                                                values={unSavedEmails[
                                                  row.index
                                                ]?.labels?.items.map(
                                                  (item_label) => ({
                                                    value: item_label?.id,
                                                    label: item_label?.name,
                                                  })
                                                )}
                                                multi={true}
                                                clearable={false}
                                                options={[
                                                  ...labelsOptions,
                                                  ...newlyAddedLabels?.filter(
                                                    (cml, i) =>
                                                      filterNewlyAddedLabels(
                                                        cml,
                                                        unSavedEmails[row.index]
                                                          ?.clientMatters?.items
                                                      )
                                                  ),
                                                ]}
                                                onDropdownOpen={async (e) => {
                                                  await handleOnMenuOptions(
                                                    unSavedEmails[row.index]
                                                      ?.clientMatters?.items
                                                  );
                                                  setEnableDropdown(true);
                                                  // loadedLabels.current = false;
                                                  setLoadedLabels((prev) =>
                                                    prev.filter(
                                                      (e) => e !== item?.id
                                                    )
                                                  );
                                                }}
                                                keepSelectedInList={false}
                                                onChange={(e) => {
                                                  if (
                                                    !loadedLabels.find(
                                                      (e) => e === item?.id
                                                    )
                                                  ) {
                                                    // loadedLabels.current = true;
                                                    setLoadedLabels((prev) => [
                                                      ...prev,
                                                      item?.id,
                                                    ]);
                                                    setSelectedIndex(row.index);
                                                    handleAddLabel1(
                                                      e,
                                                      item?.id,
                                                      selectedUnsavedIndex,
                                                      unSavedEmails[row.index]
                                                        ?.clientMatters?.items
                                                    );
                                                  }
                                                }}
                                                create={true} // SET TO TRUE IF WANT TO ENABLE THE CREATION OF NEW LABELS
                                                disabled={
                                                  saveLoading
                                                    ? true
                                                    : checkArrLength(
                                                        unSavedEmails[row.index]
                                                          ?.clientMatters
                                                      ) === true ||
                                                      checkEnable(item?.id)
                                                    ? false
                                                    : true
                                                }
                                                searchable={true}
                                                onDropdownClose={() => {
                                                  console.log("CLOSE");
                                                  setEnableDropdown(false);
                                                  // loadedLabels.current = true;
                                                  // setLoadedLabels((prev) => [...prev, item?.id]);
                                                }}
                                                placeholder="Labels"
                                                color="#8a8a8a"
                                                className="outline-none background-slate-500 placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 mt-2 w-full rounded border-0 bg-white text-xs shadow focus:ring "
                                              />
                                            </>
                                          )}
                                        </div>
                                        {/** End Main Email Labels  */}
                                      </>
                                    ) : (
                                      <>
                                        {item?.clientMatters?.items &&
                                        item?.clientMatters?.items.length >
                                          0 ? (
                                          // CARDS
                                          <div
                                            className="flex flex-col gap-2"
                                            style={{
                                              maxWidth:
                                                width <= 540
                                                  ? "18rem"
                                                  : "24rem",
                                            }}
                                          >
                                            <div
                                              className="truncate rounded-sm bg-white py-2.5 px-2.5"
                                              style={{
                                                border:
                                                  "1px solid rgba(5,5,5,0.3)",
                                                maxWidth: getMaxWidthCM(),
                                              }}
                                            >
                                              {item?.clientMatters?.items[0]
                                                ?.client?.name +
                                                "/" +
                                                item?.clientMatters?.items[0]
                                                  ?.matter?.name}
                                            </div>
                                            {item?.labels?.items?.length ? (
                                              <div
                                                className="flex flex-wrap rounded-sm bg-white py-1.5 px-2"
                                                style={{
                                                  border:
                                                    "1px solid rgba(5,5,5,0.3)",
                                                  gap: "0.30rem",
                                                }}
                                              >
                                                {item?.labels?.items?.length >
                                                  0 &&
                                                  item?.labels?.items?.map(
                                                    (item_label, index) => (
                                                      <div
                                                        className="flex items-center gap-1.5 rounded-sm px-2 text-white"
                                                        style={{
                                                          backgroundColor:
                                                            "#8A8A8A",
                                                          paddingTop: "0.20rem",
                                                          paddingBottom:
                                                            "0.20rem",
                                                        }}
                                                      >
                                                        <div className="text-white">
                                                          {index + 1 + "."}
                                                        </div>
                                                        <div>
                                                          {item_label?.name}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            ) : (
                                              <div
                                                className="flex flex-wrap gap-1 rounded-sm bg-white px-2.5"
                                                style={{
                                                  border:
                                                    "1px solid rgba(5,5,5,0.3)",
                                                  paddingTop: "0.6rem",
                                                  paddingBottom: "0.6rem",
                                                }}
                                              >
                                                No Selected Labels
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <>
                                            <div
                                              className="flex flex-col gap-2 opacity-70"
                                              style={{
                                                maxWidth:
                                                  width <= 540
                                                    ? "18rem"
                                                    : "24rem",
                                              }}
                                            >
                                              <div
                                                className="truncate rounded-sm bg-white py-2.5 px-2.5"
                                                style={{
                                                  border:
                                                    "1px solid rgba(5,5,5,0.3)",
                                                }}
                                              >
                                                No Selected Client Matter
                                              </div>

                                              <div
                                                className="flex flex-wrap gap-1 rounded-sm bg-white py-2.5 px-2.5"
                                                style={{
                                                  border:
                                                    "1px solid rgba(5,5,5,0.3)",
                                                }}
                                              >
                                                No Selected Labels
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </p>
                                  {/* EMAIL DESCRIPTION */}
                                  <p
                                    className="font-poppins mt-2 whitespace-normal break-words rounded p-2 "
                                    style={{
                                      border: "solid 1px #c4c4c4",
                                      //cursor: "auto",
                                      outlineColor: "rgb(204, 204, 204, 0.5)",
                                      outlineWidth: "thin",
                                      //minHeight: "35px",
                                      overflow: "auto",
                                      height: "100px",

                                      maxWidth:
                                        width <= 540 ? "18rem" : "24rem",
                                      backgroundColor: saveLoading
                                        ? "rgb(204, 204, 204, 0.5)"
                                        : "white",
                                    }}
                                    suppressContentEditableWarning
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                    // onInput={(event) => {
                                    //   console.log(
                                    //     "type",
                                    //     event.currentTarget.innerText
                                    //   );
                                    //   handleChangeDesc(event);
                                    // }}

                                    onBlur={(e) => {
                                      handleSaveMainDesc(
                                        e,
                                        item.description,
                                        item.id,
                                        row.index
                                      );
                                    }}
                                    contentEditable={saveLoading ? false : true}
                                  ></p>

                                  {showCopy === false ? (
                                    <>
                                      <div className="mt-1 text-xs font-semibold">
                                        {selectedTaskList?.includes(
                                          item.id
                                        ) && (
                                          <>
                                            {item?.assignee ? (
                                              <div className="mt-2">
                                                <div className="flex w-full ">
                                                  <BsPeopleFill className="mt-2" />
                                                  <p className="className=z-40 ml-2 flex-1 font-semibold text-gray-600">
                                                    <div
                                                      className="pl-2 pr-11 flex flex-wrap rounded-sm bg-white py-1"
                                                      style={{
                                                        border:
                                                          "1px solid rgba(5,5,5,0.3)",
                                                        gap: "0.20rem",
                                                      }}
                                                    >
                                                      {item?.assignee
                                                        .split(",")
                                                        .map((item, index) => {
                                                          return (
                                                            <div
                                                              className="flex items-center gap-1.5 rounded-sm px-2 text-black"
                                                              style={{
                                                                backgroundColor:
                                                                  "#E6E6E6",
                                                                paddingTop:
                                                                  "0.20rem",
                                                                paddingBottom:
                                                                  "0.20rem",
                                                              }}
                                                            >
                                                              <div
                                                                className="text-black"
                                                                style={{
                                                                  fontSize:
                                                                    "85%",
                                                                }}
                                                              >
                                                                {index +
                                                                  1 +
                                                                  "."}
                                                              </div>
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    "85%",
                                                                }}
                                                              >
                                                                {item}
                                                              </div>
                                                            </div>
                                                          );
                                                        })}
                                                    </div>
                                                  </p>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="mt-2 ">
                                                <div className="flex w-full ">
                                                  <BsPeopleFill className="mt-2" />
                                                  <p className="className=z-40 ml-2 mt-2 flex-1 font-semibold text-gray-600">
                                                    No Assignees Yet.
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                            {item?.priority ? (
                                              <div className="mt-2 ">
                                                <div className="flex w-full items-center">
                                                  <RiNumbersFill className="" />
                                                  <div
                                                    className="pl-2 ml-2.5 w-full pr-11 flex items-center flex-wrap rounded-sm bg-white py-2.5"
                                                    style={{
                                                      border:
                                                        "1px solid rgba(5,5,5,0.3)",
                                                      gap: "0.20rem",
                                                    }}
                                                  >
                                                    {item?.priority}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="mt-2 ">
                                                <div className="flex w-full ">
                                                  <RiNumbersFill className="mt-2 " />
                                                  <p className="className=z-40 ml-2 mt-2 flex-1 font-semibold text-gray-600">
                                                    No Priority Yet.
                                                  </p>
                                                </div>
                                              </div>
                                            )}

                                            {item?.notes ? (
                                              <div className="mt-2 ">
                                                <div className="flex w-full ">
                                                  <MdDescription className="mt-2" />
                                                  <p
                                                    dangerouslySetInnerHTML={{
                                                      __html: `${item?.notes}`,
                                                    }}
                                                    className="className=z-40 ml-2 mt-2 flex-1 text-gray-600"
                                                    style={{
                                                      maxWidth:
                                                        getMaxWidthNotes(),
                                                    }}
                                                  >
                                                    {/* {item?.notes.replace(
                                                        /<[^>]*>|&nbsp;/gm,
                                                        " "
                                                      )} */}
                                                  </p>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="mt-2 ">
                                                <div className="flex w-full ">
                                                  <MdDescription className="mt-2" />
                                                  <p className="className=z-40 ml-2 mt-2 flex-1 font-semibold text-gray-600">
                                                    No Notes Yet.
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}

                                        {item.clientMatters?.items?.length >
                                        0 ? (
                                          <div className="flex gap-8">
                                            <label
                                              className={
                                                selectedTaskList?.includes(
                                                  item.id
                                                )
                                                  ? "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold text-green-500"
                                                  : "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold "
                                              }
                                            >
                                              Tasklist
                                              <input
                                                disabled={procPaste}
                                                key={item.id}
                                                name="tasklist"
                                                className="unsaved-for-tasklist mr-1 cursor-pointer text-lg"
                                                onChange={(e) => {
                                                  console.log("clicked");
                                                  // autoAdjustRowHeight(
                                                  //   item.id
                                                  // );
                                                  // DISPLAY TASK REGISTER IF TASK LIST BUTTON IS ACTIVATED
                                                  if (
                                                    e.target.checked &&
                                                    !selectedTaskRegister.find(
                                                      (obj) =>
                                                        obj.id === item.id
                                                    )
                                                  )
                                                    setSelectedTaskRegister(
                                                      (prev) => [
                                                        ...prev,
                                                        {
                                                          id: item.id,
                                                          registers: [],
                                                        },
                                                      ]
                                                    );
                                                  // HIDE TASK REGISTER IF TASK LIST BUTTON IS DEACTIVATED
                                                  else if (
                                                    !e.target.checked &&
                                                    selectedTaskRegister.find(
                                                      (obj) =>
                                                        obj.id === item.id
                                                    )
                                                  )
                                                    setSelectedTaskRegister(
                                                      (prev) =>
                                                        prev?.filter(
                                                          (obj) =>
                                                            obj.id !== item.id
                                                        )
                                                    );

                                                  handleSelectForTaskList(
                                                    e,
                                                    item,
                                                    item.id,
                                                    e.target.checked
                                                      ? "add"
                                                      : "remove"
                                                  );

                                                  saveTaskRegister(item.id, [
                                                    taskRegisterList.find(
                                                      (register) =>
                                                        register?.label
                                                          .toLowerCase()
                                                          .replace(/ /g, "") ===
                                                        "tasklist"
                                                    ),
                                                  ]);
                                                }}
                                                type="checkbox"
                                                value={item.id}
                                                id={item.id}
                                                checked={selectedTaskList?.includes(
                                                  item.id
                                                )}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <label
                                              className={
                                                selectedTaskRegister?.includes(
                                                  item.id
                                                )
                                                  ? "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold text-green-500"
                                                  : "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold "
                                              }
                                            >
                                              Task Register
                                              <input
                                                key={item.id}
                                                name="taskregister"
                                                className="unsaved-for-tasklist mr-1 cursor-pointer text-lg"
                                                onChange={(e) => {
                                                  // autoAdjustRowHeight(
                                                  //   item.id
                                                  // );
                                                  handleSelectForTaskRegister(
                                                    e,
                                                    item.clientMatters?.items
                                                      .length,
                                                    item?.id
                                                  );
                                                  saveTaskRegister(item.id, [
                                                    taskRegisterList.find(
                                                      (register) =>
                                                        register?.label
                                                          .toLowerCase()
                                                          .replace(/ /g, "") ===
                                                        "tasklist"
                                                    ),
                                                  ]);
                                                }}
                                                type="checkbox"
                                                value={item.id}
                                                id={item.id}
                                                checked={selectedTaskRegister.some(
                                                  (register) =>
                                                    register.id === item.id
                                                )}
                                                // checked={selectedTaskList?.includes(
                                                //   item.id
                                                // )}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="mt-1 text-xs font-semibold">
                                        {selectedTaskList?.includes(
                                          item.id
                                        ) && (
                                          <>
                                            <div className="mt-2">
                                              <div className="flex w-full ">
                                                <BsPeopleFill className="mt-2" />
                                                <CreatableSelect
                                                  key={item.id}
                                                  options={people}
                                                  defaultValue={() =>
                                                    getAssignee(item.assignee)
                                                  }
                                                  className="focus:z-40 ml-3 flex-1"
                                                  noOptionsMessage={() =>
                                                    "No result found"
                                                  }
                                                  isValidNewOption={() => false}
                                                  menuPlacement="top"
                                                  closeMenuOnSelect={false}
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                  styles={selectStyle}
                                                  menuShouldScrollIntoView={
                                                    true
                                                  }
                                                  placeholder="Select Assignee"
                                                  isMulti
                                                  onChange={(e) =>
                                                    saveAssignee(e, item)
                                                  }
                                                  components={{
                                                    ClearIndicator:
                                                      CustomClearIndicator,
                                                  }}
                                                  blurInputOnSelect={false}
                                                  onFocus={() =>
                                                    dynamicSetPeople(item)
                                                  }
                                                  onBlur={() => {
                                                    if (
                                                      !selectedTaskList?.includes(
                                                        selectUnsavedEmailsDetails?.id
                                                      )
                                                    )
                                                      handleSelectForTaskList(
                                                        {
                                                          target: {
                                                            id: "",
                                                            checked: true,
                                                          },
                                                        },
                                                        item,
                                                        item.id,
                                                        "add"
                                                      );
                                                    //setShowSave(false)
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="mt-2 ">
                                              <div className="flex w-full ">
                                                <RiNumbersFill className="mt-2 " />
                                                <CreatableSelect
                                                  options={[
                                                    {
                                                      value: 1,
                                                      label: 1,
                                                    },
                                                    {
                                                      value: 2,
                                                      label: 2,
                                                    },
                                                    {
                                                      value: 3,
                                                      label: 3,
                                                    },
                                                  ]}
                                                  defaultValue={
                                                    item.priority !== null
                                                      ? {
                                                          label: item.priority,
                                                          value: item.priority,
                                                        }
                                                      : (() => {
                                                          /***
                                                           * If item.priority is null, make an
                                                           * API call to make it 1 by default.
                                                           * This is the cleanest and best solution
                                                           * for this problem so far. It avoids
                                                           * additional changes on other elements
                                                           * to show the default value of 1.
                                                           ***/
                                                          savePriority(
                                                            {
                                                              label: 1,
                                                              value: 1,
                                                            },
                                                            item?.id,
                                                            item?.notes
                                                          );

                                                          return {
                                                            label: 1,
                                                            value: 1,
                                                          };
                                                        })()
                                                  }
                                                  className="focus:z-40 ml-3 flex-1"
                                                  noOptionsMessage={() =>
                                                    "No result found"
                                                  }
                                                  isValidNewOption={() => false}
                                                  menuPlacement="top"
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                  styles={selectStyle}
                                                  menuShouldScrollIntoView={
                                                    true
                                                  }
                                                  placeholder="Select Priority"
                                                  onChange={(e) =>
                                                    savePriority(
                                                      e,
                                                      item.id,
                                                      item.notes
                                                    )
                                                  }
                                                  onInput={(e) => {
                                                    autoAdjustAllRow();
                                                  }}
                                                  onBlur={() => {
                                                    if (
                                                      !selectedTaskList?.includes(
                                                        selectUnsavedEmailsDetails?.id
                                                      )
                                                    )
                                                      handleSelectForTaskList(
                                                        {
                                                          target: {
                                                            id: "",
                                                            checked: true,
                                                          },
                                                        },
                                                        item,
                                                        item.id,
                                                        "add"
                                                      );
                                                    //setShowSave(false)
                                                  }}
                                                />
                                              </div>
                                            </div>

                                            <div className="mt-2 flex">
                                              <MdDescription className="mt-2" />
                                              <div
                                                type="text"
                                                style={{ minHeight: "38px" }}
                                                rows={5}
                                                onPaste={(e) => {
                                                  e.preventDefault();
                                                  const text = (
                                                    e.originalEvent || e
                                                  ).clipboardData.getData(
                                                    "text/plain"
                                                  );
                                                  document.execCommand(
                                                    "insertText",
                                                    false,
                                                    text
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setIsNoteChange(false);
                                                  saveNotes(
                                                    e,
                                                    item.id,
                                                    item.priority
                                                  );
                                                }}
                                                suppressContentEditableWarning
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item?.notes !== null
                                                      ? item?.notes
                                                      : "",
                                                }}
                                                contentEditable
                                                // value={item?.notes !== null
                                                //   ? item?.notes
                                                //   : ""}
                                                // onChange={(e) => handleChangeNotes(e, row.index)}
                                                onInput={(e) => {
                                                  setIsNoteChange(true);
                                                  setNotesIdChange(
                                                    `${item.id}`
                                                  );
                                                  setTaskPriority(
                                                    item.priority
                                                  );
                                                  autoAdjustAllRow();
                                                }}
                                                className="overflow w-full font-poppins resize-none rounded border-2 border-gray-300 bg-white text-gray-500 px-2 py-2 ml-3"
                                              />

                                              {/* Note Change */}
                                              {isNoteChange &&
                                              notesIdChange === item.id ? (
                                                <button
                                                  className="mt-10 flex flex items-center justify-center absolute right-4 rounded text-green-300 bg-white py-1 px-1 border-solid border border-green-300"
                                                  style={{
                                                    height: "24px",
                                                  }}
                                                  onClick={() =>
                                                    saveNotes(
                                                      notesChanged,
                                                      item.id,
                                                      item.priority
                                                    )
                                                  }
                                                >
                                                  <span>
                                                    <BiCheck />
                                                  </span>
                                                </button>
                                              ) : null}
                                            </div>
                                          </>
                                        )}

                                        {JSON.parse(
                                          localStorage.getItem(
                                            "taskRegisterIds"
                                          )
                                        )?.some(
                                          (register) => register.id === item.id
                                        ) && (
                                          <>
                                            <div className="mt-2 ">
                                              <div className="flex w-full gap-2">
                                                <CreatableSelect
                                                  key={item.id}
                                                  options={taskRegisterList}
                                                  className="focus:z-40 flex-1"
                                                  noOptionsMessage={() =>
                                                    "No options found"
                                                  }
                                                  menuPlacement="top"
                                                  closeMenuOnSelect={false}
                                                  defaultValue={() =>
                                                    processTaskRegister(item.id)
                                                  }
                                                  menuPortalTarget={
                                                    document.body
                                                  }
                                                  styles={selectStyle}
                                                  menuShouldScrollIntoView={
                                                    true
                                                  }
                                                  placeholder="Select Task Register"
                                                  isMulti
                                                  onChange={(values) => {
                                                    saveTaskRegister(
                                                      item.id,
                                                      values
                                                    );
                                                  }}
                                                  components={{
                                                    ClearIndicator:
                                                      CustomClearIndicator,
                                                  }}
                                                  blurInputOnSelect={false}
                                                  onFocus={() =>
                                                    dynamicSetPeople(item)
                                                  }
                                                  onCreateOption={(
                                                    newRegister
                                                  ) => {
                                                    handleCreateTaskRegister(
                                                      item.id,
                                                      newRegister
                                                    );
                                                    onCreateNewOption(
                                                      newRegister
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        {item.clientMatters?.items?.length >
                                        0 ? (
                                          <div className="flex gap-8">
                                            <label
                                              className={
                                                selectedTaskList?.includes(
                                                  item.id
                                                )
                                                  ? "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold text-green-500"
                                                  : "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold "
                                              }
                                            >
                                              Tasklist
                                              <input
                                                key={item.id}
                                                name="tasklist"
                                                className="unsaved-for-tasklist mr-1 cursor-pointer text-lg"
                                                onChange={(e) => {
                                                  console.log("temp", item);
                                                  autoAdjustAllRow();
                                                  // DISPLAY TASK REGISTER IF TASK LIST BUTTON IS ACTIVATED
                                                  if (
                                                    e.target.checked &&
                                                    !selectedTaskRegister.find(
                                                      (obj) =>
                                                        obj.id === item.id
                                                    )
                                                  ) {
                                                    setSelectedTaskRegister(
                                                      (prev) => [
                                                        ...prev,
                                                        {
                                                          id: item.id,
                                                          registers: [],
                                                        },
                                                      ]
                                                    );

                                                    // Update localStorage
                                                    const updatedTaskRegister =
                                                      [
                                                        ...selectedTaskRegister,
                                                        {
                                                          id: item.id,
                                                          registers: [],
                                                        },
                                                      ];
                                                    localStorage.setItem(
                                                      "taskRegisterIds",
                                                      JSON.stringify(
                                                        updatedTaskRegister
                                                      )
                                                    );
                                                  }

                                                  // HIDE TASK REGISTER IF TASK LIST BUTTON IS DEACTIVATED
                                                  else if (
                                                    !e.target.checked &&
                                                    selectedTaskRegister.find(
                                                      (obj) =>
                                                        obj.id === item.id
                                                    )
                                                  ) {
                                                    setSelectedTaskRegister(
                                                      (prev) =>
                                                        prev?.filter(
                                                          (obj) =>
                                                            obj.id !== item.id
                                                        )
                                                    );

                                                    // Update localStorage
                                                    const updatedTaskRegister =
                                                      selectedTaskRegister.filter(
                                                        (obj) =>
                                                          obj.id !== item.id
                                                      );
                                                    localStorage.setItem(
                                                      "taskRegisterIds",
                                                      JSON.stringify(
                                                        updatedTaskRegister
                                                      )
                                                    );
                                                  }

                                                  handleSelectForTaskList(
                                                    e,
                                                    item,
                                                    item.id,
                                                    e.target.checked
                                                      ? "add"
                                                      : "remove"
                                                  );
                                                  saveTaskRegister(item.id, [
                                                    taskRegisterList.find(
                                                      (register) =>
                                                        register?.label
                                                          .toLowerCase()
                                                          .replace(/ /g, "") ===
                                                        "tasklist"
                                                    ),
                                                  ]);
                                                }}
                                                type="checkbox"
                                                value={item.id}
                                                id={item.id}
                                                checked={selectedTaskList?.includes(
                                                  item.id
                                                )}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                            <label
                                              className={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "taskRegisterIds"
                                                  )
                                                )?.includes(item.id)
                                                  ? "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold text-green-500"
                                                  : "custom-checkmark text-s relative mt-2 ml-1 flex pl-6 font-semibold "
                                              }
                                            >
                                              Task Register
                                              <input
                                                key={item.id}
                                                name="taskregister"
                                                className="unsaved-for-tasklist mr-1 cursor-pointer text-lg"
                                                onChange={(e) => {
                                                  autoAdjustAllRow();
                                                  handleSelectForTaskRegister(
                                                    e,
                                                    item.clientMatters?.items
                                                      .length,
                                                    item?.id
                                                  );
                                                }}
                                                type="checkbox"
                                                value={item.id}
                                                id={item.id}
                                                checked={JSON.parse(
                                                  localStorage.getItem(
                                                    "taskRegisterIds"
                                                  )
                                                )?.some(
                                                  (register) =>
                                                    register.id === item.id
                                                )}
                                              />
                                              <span className="checkmark"></span>
                                            </label>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="h-fit w-10 flex-none">
                                  <div>
                                    <p className="top-0 flex">
                                      <GrAttachment className="mt-1 text-xs" />
                                      {
                                        item?.attachments?.items?.filter(
                                          (attachment) =>
                                            !attachment?.details?.includes(
                                              "EMAIL_PDF_VERSION"
                                            )
                                        ).length
                                      }{" "}
                                      {" >"}
                                    </p>
                                  </div>

                                  {showCopy === true ? (
                                    <>
                                      {item.clientMatters?.items?.length > 0 ? (
                                        <div>
                                          {/* Single Copying Button */}
                                          <BiCopy
                                            size={19}
                                            className="mt-2 ml-3 font-bold text-gray-500"
                                            onClick={() => {
                                              handleCopy(
                                                item.id,
                                                item.clientMatters,
                                                item
                                              );
                                              console.log("Copy clicked", item);
                                              autoAdjustAllRow();
                                              // handleCopy(
                                              //   item.id,
                                              //   item.clientMatters,
                                              //   item
                                              // );
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {copyId === item.id &&
                                      item.clientMatters?.items?.length > 0 ? (
                                        <div>
                                          {/* <BiCheckCircle
                                              size={19}
                                              className="mt-2 ml-3 font-bold text-gray-500"
                                              onClick={() => {
                                                autoAdjustAllRow();
                                                setCopyId("");
                                                setPastedIds([]);
                                                setShowCopy(true);
                                              }}
                                            /> */}
                                        </div>
                                      ) : (
                                        <>
                                          {pastedIds?.includes(item.id) ? (
                                            <div>
                                              <BiPaste
                                                size={18}
                                                className={
                                                  loadLabels
                                                    ? "ml-3 mt-2 font-bold text-gray-100"
                                                    : "ml-3 mt-2 font-bold text-gray-500"
                                                }
                                                onClick={() => {
                                                  setRowIndex(row.index);
                                                  handlePaste(
                                                    item.id,
                                                    row.index
                                                  );
                                                }}
                                                disabled={
                                                  loadLabels ? true : false
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <div>
                                              <BiPaste
                                                size={18}
                                                className={
                                                  loadLabels
                                                    ? "ml-3 mt-2 font-bold text-gray-500"
                                                    : "ml-3 mt-2 font-bold text-gray-500"
                                                }
                                                onClick={() => {
                                                  setRowIndex(row.index);
                                                  handlePaste(
                                                    item.id,
                                                    row.index
                                                  );
                                                }}
                                                disabled={
                                                  loadLabels ? true : false
                                                }
                                              />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                  {!doesContactExist(item.from) ? (
                                    toggleSent === false ? (
                                      <Button
                                        variant="gray-l"
                                        onClick={(e) =>
                                          handleAddContact(item, row.index)
                                        }
                                        className="mt-2 ml-2"
                                      >
                                        <MdPersonAdd className="cursor-pointer text-gray-700" />
                                      </Button>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    // mma - 1836
                                    <Button
                                      variant="green-l"
                                      disabled
                                      className="mt-2 ml-2 border-green-700"
                                    >
                                      <FiUserCheck className="text-green-700" />
                                    </Button>
                                  )}
                                  <Button
                                    variant="danger-l"
                                    onClick={() =>
                                      handleSingleArchive(item.id, row.index)
                                    }
                                    className="mt-2 ml-2 hover:bg-danger-l"
                                  >
                                    <FiArchive className="cursor-pointer text-danger" />
                                  </Button>
                                  {/* </>
                                ):(<></>)} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </tr>
                      </div>
                    );
                  })}

                  {selectedUnsavedItems.length !== 0 || showCopy === false ? (
                    // used to add space (after selecting email) when the bottom was reached
                    <div class="block p-8" id="bottomFiller"></div>
                  ) : null}
                </>
              ) : (
                <>
                  {(virtualRows?.length > 10
                    ? virtualRows
                    : [...Array(5).keys()]
                  )?.map((data, index) => {
                    let height = 310;
                    if (data.size) {
                      height = data.size;
                    }
                    return (
                      <tr
                        className="test123 block w-full p-1"
                        style={{ height: `${height}px` }}
                      >
                        <div className="h-full w-full rounded-lg border border-gray-200 bg-gray-100 p-2 pr-4">
                          <div className="flex w-full flex-row gap-2">
                            <div>
                              <SkeletonTheme width={"15px"}>
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            </div>
                            <div className="gap-2 flex w-full flex-col">
                              <SkeletonTheme>
                                <Skeleton className="text-xs mb-1" count={2} />
                              </SkeletonTheme>
                              <SkeletonTheme height={"36px"}>
                                <Skeleton className="text-xs mb-1" count={2} />
                              </SkeletonTheme>
                              <SkeletonTheme height={"100px"}>
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            </div>
                            <div className="gap-2 flex flex-col">
                              <SkeletonTheme width={"28px"} height={"16px"}>
                                <Skeleton className="mb-1" count={1} />
                              </SkeletonTheme>
                              <SkeletonTheme width={"28px"} height={"28px"}>
                                <Skeleton className="mb-1" count={2} />
                              </SkeletonTheme>
                            </div>
                          </div>
                        </div>
                      </tr>
                    );
                  })}
                </>
              )}
              {/* iterate items here end */}
             
            </tbody>
          </table>
          {showCopy === false ? (
            <>
              <div>
                <span className="flex w-1/2 fixed bottom-3 left-1/2 ">
                  <button
                    onClick={() => {
                      setCopyId("");
                      setPastedIds([]);
                      setShowCopy(true);
                      setCopyDetails([]);
                      console.log(
                        "will be saved in tasklist",
                        selectedTaskList,
                        selectedUnsavedItems
                      );
                      autoAdjustAllRow();
                      setProcPaste(false);
                    }}
                    className="flex-grow text-secondary-l text-base font-medium ml-2 mr-2 py-1 rounded-md bg-green-500"
                  >
                    Done
                  </button>
                </span>
                <span className="flex w-1/2 fixed bottom-3 left-0/2 ">
                  <button
                    onClick={() => {
                      //autoAdjustAllRow();
                      let temp = [];
                      unSavedEmails?.map((x) => {
                        temp = [...temp, x.id];
                      });

                      if (temp.length > 20) {
                        console.log("Paste to more than 20 emails");
                        console.log("temp.length -> ", temp.length);
                        setShowMobileNotificationModal(true);
                        // Display a confirmation message here
                        if (!copyMore20) {
                          return; // User cancelled the operation
                        }
                      }

                      if (temp.length <= 20 && !copyMore20) {
                        console.log("Paste to less than or equal 20 emails");
                        let copyIndex = temp.indexOf(copyId);
                        for (let i = 0; i < temp.length; i++) {
                          if (i !== copyIndex) {
                            handlePaste(temp[i], i);
                          }
                        }
                        console.log("pastedIds: ", pastedIds);
                      }
                    }}
                    className="flex-grow text-secondary-l text-base font-medium ml-2 mr-2 py-1 rounded-md bg-blue-400"
                  >
                    Copy to All
                  </button>
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default TableUnsavedInfo;
