import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Select from "react-select";
import { RiFileInfoLine, RiFilterFill, RiFilterOffLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Button from "../../shared/Button";
import { CgClose } from "react-icons/cg";
import { FaTimes } from "react-icons/fa";
import {
  datePickerDateFormatter,
  dateFormatter,
  timezoneFormatter,
} from "../../shared/userPreferredDateTime";
// import Select from "react-select/creatable";

const SENDER_BY_COMPANY_QUERY = `query getEmailSenderByCompany($companyId: String, $email: String) {
  gmailMessages(companyId: $companyId, email: $email) {
    from
    recipient
    to
    cc
    bcc
  }
}`;

const SENDER_BY_EMAIL_OUTLOOK_QUERY = `query SENDER_BY_EMAIL_OUTLOOK_QUERY($email: String!) {
  outlookMessages(email: $email) {
    from
    to
    cc
    bcc
  }
}`;

const companyId = localStorage.getItem("companyId");

export default function FiltersModal(props) {
  const [startDate, setStartDate] = useState(
    props.currentFilter.startDate || null
  );
  const [endDate, setEndDate] = useState(props.currentFilter.endDate || null);
  const [invalidState, setInvalidState] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [searchSubject, setSearchSubject] = useState(
    props.currentFilter.subject || ""
  );
  const connectedEmail = props.connectedEmail;

  //Sender Filter
  const [searchSender, setSearchSender] = useState(
    props.currentFilter.sender || ""
  );
  const [selectedSender, setSelectedSender] = useState(null);
  const [senderList, setSenderList] = useState([]);

  //Recipient Filter
  const [searchRecipient, setSearchRecipient] = useState(
    props.currentFilter.recipient || ""
  );
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [recipientList, setRecipientList] = useState([]);

  const [searchHasAttachments, setSearchHasAttachments] = useState(
    props.currentFilter.hasAttachments || ""
  );

  //Date Range Filter
  const [oneMonth, setOneMonth] = useState();
  const [oneDay, setOneDay] = useState();
  const [threeDays, setThreeDays] = useState();
  const [sevenDays, setSevenDays] = useState();

  /*
  const [searchContent, setSearchContent] = useState("");  
  const [searchAttachment, setSearchAttachment] = useState("");
  */

  useEffect(() => {
    /* Sets initial values of dateOptions */
    let dependentTime = new Date(startDate);
    let forMonth = new Date(startDate);
    setOneDay(new Date(dependentTime.setDate(dependentTime.getDate())));
    setThreeDays(new Date(dependentTime.setDate(dependentTime.getDate() + 3)));
    setSevenDays(new Date(dependentTime.setDate(dependentTime.getDate() + 4)));
    setOneMonth(new Date(forMonth.setMonth(forMonth.getMonth() + 1)));
  }, []);

  /* Instantiate options for 'To' dropdown */
  const dateOptions = [
    { value: oneDay, label: "Today" },
    { value: threeDays, label: "3 Days" },
    { value: sevenDays, label: "7 Days" },
    { value: oneMonth, label: "1 Month" },
  ];

  /* Styling for our Select component */
  const selectStyle = {
    option: (base, selectProps) => ({
      ...base,
      backgroundColor:
        selectProps.isFocused && !selectProps.isSelected
          ? "transparent"
          : base.backgroundColor,
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 40,
      margin: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(3600deg)",
      paddingRight: "0px",
      paddingLeft: "0px",
      color: "text-gray-600",
      display: "none",
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      padding: 0,
      margin: 0,
      height: 34,
      minHeight: 34,
      borderColor: "rgba(209, 213, 219, 1)",
    }),
    placeholder: (base, state) => ({
      ...base,
      fontSize: "0.76rem",
      paddingTop: "0.12rem",
      color: "#374151",
    }),
    menu: (base) => ({
      ...base,
      marginTop: 0,
      padding: 0,
      margin: 0,
      width: "max-content",
      minWidth: "100%",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "0.76rem",
    }),
  };

  /* Checks if endDate is previously modified in the current filter */
  const isEndDateOccupied = () => {
    if (endDate) {
      /* Calculate the difference in days between the previous startDate and endDate */
      const oneDay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor((endDate - startDate) / oneDay);

      /* Returns previously selected preset */
      if (daysDifference === 0) return "Today";

      if (daysDifference === 3) return "3 Days";

      if (daysDifference === 7) return "7 Days";

      if (daysDifference > 25) return "1 Month";
    }
  };

  const handleStartDateChange = (selected) => {
    if (endDate !== null) {
      /* Calculate the difference in days between the previous startDate and endDate */
      const oneDay = 24 * 60 * 60 * 1000;
      const daysDifference = Math.floor((endDate - startDate) / oneDay);
      let newEndDate = new Date(selected.getTime());

      if (daysDifference > 7) newEndDate.setMonth(newEndDate.getMonth() + 1);
      else
        newEndDate = new Date(newEndDate.getTime() + daysDifference * oneDay);

      setEndDate(newEndDate);
    }

    /* Because .setMonth is needed and to avoid mutation 
    and miscalculation, we must declare two of the same instance */
    const dependentTime = new Date(selected);
    const forMonth = new Date(selected);

    setStartDate(selected);

    /* Updates the options */
    setOneDay(new Date(dependentTime.setDate(dependentTime.getDate())));
    setThreeDays(new Date(dependentTime.setDate(dependentTime.getDate() + 3)));
    setSevenDays(new Date(dependentTime.setDate(dependentTime.getDate() + 4)));
    setOneMonth(new Date(forMonth.setMonth(forMonth.getMonth() + 1)));
  };

  const handleEndDateChange = (selected) => {
    setEndDate(selected);
  };

  // const { handleSubmit } = useForm();

  const handleModalClose = () => {
    props.closeFiltersModal();
  };

  const handleClearFilter = () => {
    props.executeFilter(null);
    if (props.width > 844) props.setEmailContentOpen(false);
  };

  const handleSave = () => {
    let filters = {
      startDate: startDate,
      endDate: endDate,
      subject: searchSubject,
      sender: searchSender,
      recipient: searchRecipient,
      hasAttachments: searchHasAttachments,
    };
    props.setMobileLoading(true);
    props.executeFilter(filters);
    if (props.width > 844) props.setEmailContentOpen(false);
    handleModalClose();
  };

  useEffect(() => {
    if (
      startDate !== null &&
      startDate !== "" &&
      endDate !== null &&
      endDate !== ""
    ) {
      if (Date.parse(startDate) > Date.parse(endDate)) {
        setInvalidDateRange(true);
        setInvalidState(true);
      } else {
        setInvalidDateRange(false);
        setInvalidState(false);
      }
    } else {
      setInvalidState(true);
    }
  }, [startDate, endDate, senderList, recipientList]);

  useEffect(() => {
    if (senderList.length === 0 && recipientList.length === 0) {
      if (props.isOutlookIntegrated) getOutlookEmail();
      else getGmailEmail();
    }
  }, []);

  const getGmailEmail = async () => {
    const params = {
      query: SENDER_BY_COMPANY_QUERY,
      variables: {
        companyId: companyId,
        email: connectedEmail,
      },
    };
    console.log("GMAIL MESSAGES PARAMS", params);

    const { data } = await API.graphql(params);

    if (data) {
      console.log("GMAIL MESSAGES", data);
      const arrMessages = data.gmailMessages;

      const uniqueFromItems = extractUniqueSenderEmails(arrMessages);
      const uniqueRecipientItems = extractUniqueRecipientEmails(
        arrMessages
      );

      console.log("GMAIL SENDER UNIQUE", uniqueFromItems);
      console.log("GMAIL RECIPIENTS UNIQUE", uniqueRecipientItems);

      setSenderList(uniqueFromItems);
      setRecipientList(uniqueRecipientItems);

      const defaultSender = uniqueFromItems?.find(
        (item) => item.value === searchSender
      );
      const defaultRecipient = uniqueRecipientItems?.find(
        (item) => item.value === searchRecipient
      );

      setSelectedSender(defaultSender);
      setSelectedRecipient(defaultRecipient);
    }
  };

  function extractUniqueSenderEmails(emails) {
    if (!emails || emails.length === 0) return [];

    const uniqueEmails = {};

    emails.forEach((item) => {
      const from = item.from;
      if (from) {
        const email =
          from.match(
            /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
          )?.[0] || from;
        const name = from.match(/^(.*?)(?=\s<|\s*$)/)?.[1]?.trim() || email;

        uniqueEmails[email] = name.replace('"', "").replace(/['"]+/g, "");
      }
    });

    const result = Object.entries(uniqueEmails).map(([email, name]) => ({
      label: name === email ? name : `${name} <${email}>`,
      value: email,
    }));

    result.sort((a, b) => a.label.localeCompare(b.label));
    return result;
  }

  function extractUniqueRecipientEmails(emails) {
    if (!emails || emails.length === 0) return [];

    // CHECK IF LIST OF RECIPIENTS ARE IN OUTLOOK FORMAT
    const mergedRecipients = props.isOutlookIntegrated
      ? [
          ...new Set(
            emails.flatMap((obj) =>
              Object.values(obj)
                .flat()
                .filter((val) => typeof val === "string" && val.includes("@"))
            )
          ),
        ]
      : [
          ...new Set(
            emails.flatMap((obj) =>
              Object.values(obj).filter(
                (val) => typeof val === "string" && val.includes("@")
              )
            )
          ),
        ];

    const flatRecipients = mergedRecipients.flatMap((item) => item.split(","));
    const uniqueEmails = {};

    flatRecipients.forEach((item) => {
      const em = item;
      if (em) {
        const email =
          em.match(
            /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
          )?.[0] || em;
        const name = em.match(/^(.*?)(?=\s<|\s*$)/)?.[1]?.trim() || email;

        uniqueEmails[email] = name.replace('"', "").replace(/['"]+/g, "");
      }
    });

    const result = Object.entries(uniqueEmails).map(([email, name]) => ({
      label: name === email ? name : `${name} <${email}>`,
      value: email,
    }));

    result.sort((a, b) => a.label.localeCompare(b.label));

    return result;
  }

  //OUTLOOK FUNCTIONS
  const getOutlookEmail = async () => {
    const OUTLOOK_SENDERS = {
      query: SENDER_BY_EMAIL_OUTLOOK_QUERY,
      variables: {
        //accessToken: token,
        email: props.connectedOutlookAccount,
      },
    };

    const arr = await API.graphql(OUTLOOK_SENDERS);

    if (arr) {
      const arrMessages = arr.data.outlookMessages;
      console.log("ARR SENDERS", arrMessages);

      const uniqueFromItems = extractUniqueSenderEmails(arrMessages);
      const uniqueRecipientItems = extractUniqueRecipientEmails(
        arrMessages
      );

      setSenderList(uniqueFromItems);
      setRecipientList(uniqueRecipientItems);

      const defaultSender = uniqueFromItems?.find(
        (item) => item.value === searchSender
      );
      const defaultRecipient = uniqueRecipientItems?.find(
        (item) => item.value === searchRecipient
      );

      setSelectedSender(defaultSender);
      setSelectedRecipient(defaultRecipient);
    }
  };

  return (
    <>
      {/* <form> */}
      <div
        className="outline-none focus:outline-none fixed inset-0 flex items-center justify-center overflow-y-auto overflow-x-hidden"
        style={{ zIndex:9999 }}
      >
        <div className="relative my-6 mx-auto w-full max-w-lg">
          <div className="outline-none focus:outline-none relative flex w-full flex-col rounded-lg border-0 bg-white p-10 shadow-lg">
            <div className="flex items-start justify-between  rounded-t">
              <h3 className="pb-2 text-lg font-semibold text-gray-900">
                Manage Filters
              </h3>
              <button
                className="ml-auto flex h-8 w-8 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-100 hover:bg-gray-300"
                onClick={handleModalClose}
              >
                <CgClose />
              </button>
            </div>
            <div className="relative flex-auto pt-5">
              <p className="text-sm font-bold">DATE RANGE</p>
              <div className="grid grid-cols-2 gap-4 pt-2">
                <div className="relative w-full flex-auto">
                  <div className="text-sm font-medium text-gray-400">
                    {`From`}
                  </div>
                  <div className="relative my-1 z-50">
                    {/* <div className="absolute pin-r pin-t mt-4 mr-5 ml-2 text-purple-lighter">
                      <RiFileInfoLine />
                    </div> */}
                    <DatePicker
                      popperProps={{
                        positionFixed: true,
                      }}
                      style={{zIndex:999}}
                      className=" w-full rounded border border-gray-300 py-2 px-2.5 text-xs"
                      dateFormat={datePickerDateFormatter(
                        localStorage.getItem("userpreferredDateFormatType")
                      )}
                      selected={
                        startDate === null
                          ? null
                          : timezoneFormatter(
                              startDate,
                              localStorage.getItem("preferredTimezone")
                            )
                      }
                      popperClassName="custom-popper"
                      placeholderText="Start Date"
                      onChange={(selected) => {
                        handleStartDateChange(selected);
                        console.log("Start Date selected: ", startDate);
                      }}
                      popperPlacement="bottom" 
                      popperModifiers={[
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 0],
                          },
                        },
                        {
                          name: 'preventOverflow',
                          options: {
                            rootBoundary: 'viewport',
                            tether: false,
                            altAxis: true,
                          },
                        },
                      ]}
                      portalId="root-portal"
                      wrapperClassName="custom-datepicker-wrapper"
                    />
                  </div>
                </div>

                <div className="relative w-full flex-auto">
                  <div className="text-sm font-medium text-gray-400">
                    {`To`}
                  </div>
                  <div className="relative my-1">
                    <div className="pin-r pin-t text-purple-lighter absolute mt-4 mr-5 ml-2"></div>
                    <Select
                      isSearchable={false}
                      options={dateOptions}
                      placeholder={endDate ? isEndDateOccupied() : null}
                      isValidNewOption={() => false}
                      styles={selectStyle}
                      onChange={(selected) => {
                        handleEndDateChange(selected.value);
                        console.log("End Date selected: ", endDate);
                      }}
                      components={{ IndicatorSeparator: () => null }}
                    />
                  </div>
                </div>
              </div>
              {invalidDateRange && (
                <div className="pt-1">
                  <small className="text-red-400">
                    End date should not be earlier than start date.
                  </small>
                </div>
              )}
            </div>
            <div className="relative flex-auto pt-5">
              <p className="text-sm font-bold">Subject</p>
              <div className="grid grid-cols-1 pt-1">
                <input
                  type="text"
                  className="border-grey-light relative h-10 w-full flex-1 flex-shrink flex-grow self-center rounded rounded-l-none border px-3 text-sm leading-normal"
                  placeholder="Filter by Subject ..."
                  onChange={(e) => setSearchSubject(e.target.value)}
                  value={searchSubject}
                />
              </div>
            </div>

            <div className="relative flex-auto pt-5">
              <p className="text-sm font-bold">Sender</p>
              <div className="grid grid-cols-1 pt-1 relative">
                <Select
                  name={`senderList`}
                  options={senderList}
                  value={selectedSender}
                  type="text"
                  onChange={(selected) => {
                    setSearchSender(selected ? selected.value : null); // Update searchSender properly
                    setSelectedSender(selected);
                  }}
                  className="relative h-10 w-full flex-1 flex-shrink flex-grow self-center rounded rounded-l-none text-sm leading-normal"
                  placeholder="Filter by Sender ..."
                />
                {searchSender && (
                  <span>
                    <FaTimes
                      className="absolute  top-4 right-12 w-6 h-3.5"
                      style={{ color: "#374151" }}
                      onClick={() => {
                        setSearchSender("");
                        setSelectedSender(null);
                      }}
                    />
                  </span>
                )}
              </div>
            </div>

            {/* </div> */}

            {/* <div className="relative pt-5 flex-auto">
                <p className="font-bold text-sm">Content</p>
                <div className="grid grid-cols-1 pt-1">
                  <input
                    type="text"
                    className="w-full flex-shrink flex-grow leading-normal flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 self-center relative text-sm"
                    placeholder="Filter by email content..."
                    onChange={(e) => setSearchContent(e.target.value)}
                    value={searchContent}
                  />
                </div>
              </div>
              <div className="relative pt-5 flex-auto">
                <p className="font-bold text-sm">Attachment</p>
                <div className="grid grid-cols-1 pt-1">
                  <input
                    type="text"
                    className="w-full flex-shrink flex-grow leading-normal flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 self-center relative text-sm"
                    placeholder="Filter by filename..."
                    onChange={(e) => setSearchAttachment(e.target.value)}
                    value={searchAttachment}
                  />
                </div>
              </div> */}

            <div className="relative flex-auto pt-5">
              <p className="text-sm font-bold">Recipient</p>
              <div className="grid grid-cols-1 pt-1 relative">
                <Select
                  name={`userType`}
                  options={recipientList}
                  value={selectedRecipient}
                  type="text"
                  onChange={(selected) => {
                    setSearchRecipient(selected ? selected.value : null);
                    setSelectedRecipient(selected);
                  }}
                  className="relative h-10 w-full flex-1 flex-shrink flex-grow self-center rounded rounded-l-none text-sm leading-normal"
                  placeholder="Filter by Recipient ..."
                />
                {searchRecipient && (
                  <span>
                    <FaTimes
                      className="absolute  top-4 right-12 w-6 h-3.5"
                      style={{ color: "#374151" }}
                      onClick={() => {
                        setSearchRecipient("");
                        setSelectedRecipient(null);
                      }}
                    />
                  </span>
                )}
              </div>
            </div>

            <div className="relative flex-auto pt-5">
              <p className="text-sm font-bold">
                <input
                  aria-describedby="has-attachment-checkbox"
                  type="checkbox"
                  checked={searchHasAttachments}
                  onChange={(e) => setSearchHasAttachments(e.target.checked)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 -mb-2 mr-2"
                />
                Has Attachments
              </p>
            </div>

            <div className="flex items-center justify-center gap-2 rounded-b p-6">
              {props.currentFilter.startDate && props.currentFilter.endDate && (
                <Button
                  size="xs"
                  variant="danger"
                  className="mr-1 mb-1 px-6 py-3"
                  type="button"
                  onClick={handleClearFilter}
                >
                  Clear Filters <RiFilterOffLine />
                </Button>
              )}

              <Button
                size="xs"
                variant="secondary"
                className="mr-1 mb-1 px-6 py-3"
                type="submit"
                disabled={invalidState}
                onClick={handleSave}
              >
                Apply Filters <RiFilterFill />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="fixed inset-0 bg-black opacity-25"
        style={{ zIndex: 60 }}
      ></div>
      {/* </form> */}
    </>
  );
}
