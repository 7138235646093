// package imports
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { Link, useParams, useHistory , useLocation} from "react-router-dom";
import { API, Auth, Storage } from "aws-amplify";
// import { useIdleTimer } from "react-idle-timer";
// import dateFormat from "dateformat";
// import { BitlyClient } from "bitly-react";
// icons
import DownloadUnviewablFileModal from "./download-unviewable-file-modal";
import ContractReviewNotificationModal from "./contract-review-notification-modal";
import {
  AiOutlineFolderOpen,
  AiOutlineDownload,
  AiOutlineUp,
  AiOutlineDown,
} from "react-icons/ai";
import { FaBell } from "react-icons/fa";
import { FaPaste, FaSync, FaSort } from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import { BiArrowToTop } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import {
  // BsFillTrashFill,
  // BsFillBucketFill,
  BsSortUpAlt,
  BsSortDown,
} from "react-icons/bs";
// local imports
import { AppRoutes } from "../../constants/AppRoutes";
// import { Base64 } from "js-base64";
import html2pdf from "html2pdf.js";
import TableInfo from "./table-info";
import TableInfoBudget from "./table-info-budget";
import TableInfoInvoices from "./table-info-invoices";
import ActionButtons from "./action-buttons";
import ToastNotification from "../toast-notification";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import SessionTimeout from "../session-timeout/session-timeout-modal";
// import BreadCrumb from "../breadcrumb/breadcrumb";
import BlankStateMobile from "../mobile-blank-state";
import MobileHeader from "../mobile-header";
import config from "../../aws-exports";
import EmailParser from "../../shared/EmailParser";

import AccessControl from "../../shared/accessControl";
import "../../assets/styles/BackgroundPage.css";
import "../../assets/styles/Mobile.css";
import "../../assets/styles/BlankState.css";
import googleLogin from "../../assets/images/gmail-print.png";
import Illustration from "../../assets/images/no-data.svg";
import isMSdoc from "../../shared/msFileTypeChecker";
import MobileEmailPreview from "./MobileEmailPreview";
import Button from "../../shared/Button";
import { CgChevronLeft, CgFormatSlash } from "react-icons/cg";
import { FaTachometerAlt, FaSlash, FaSearchengin } from "react-icons/fa";
import { AiOutlineLineChart, AiOutlinePlus } from "react-icons/ai";
import { BsBucket, BsFilter } from "react-icons/bs";
import { MdEdit, MdClear } from "react-icons/md";
// import { BsFillFilterSquareFill } from "react-icons/bs";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CreatableSelect from "react-select/creatable";
import FiltersModal from "./filters-modal.jsx";
import DownloadButton from "../action-buttons/download-button";
import ShareLinkButton from "../action-buttons/shareLink-button";
import { getBgList } from "../../shared/graphql/Briefs/queries";
import MobileTableInfo from "./mobile-table-info";
import VariationsContentComponent from "./mobile-variation-table";
import ContractsContentComponent from "./mobile-contracts-table";
import InvoicesContentComponent from "./mobile-invoices-table";
import SiteDiaryContentComponent from "./mobile-sitediary-table";
import ProgressPhotosContentComponent from "./mobile-progressphotos-table";
import ProgressOfWorkContentComponent from "./mobile-progressofwork-table";
import MobileTableHeader from "./mobile-table-header";
import {
  qGetFileDownloadLink,
  mUpdateBriefName,
  qGetBackgroundsByBriefId,
  qGetBriefsByClientMatter,
  qListBackground,
  qGetMessagePayload,
  qColumns,
  EMAIL_PREVIEW,
  UPDATE_BRIEF_PRESET_MUTATION,
  UPDATE_BRIEF_TABLE_MUTATION,
} from "./mutations";
import {
  WindowScroller,
  AutoSizer,
  defaultTableHeaderRenderer,
  defaultTableRowRenderer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  List,
  Table,
} from "react-virtualized";
import Header from "./Header";
import cloneDeep from "lodash/cloneDeep";
// import CreateDocumentPage from "./create-document-page";
import useScrollBlock from "./useScrollBlock";
import { dateFormatter } from "../../shared/userPreferredDateTime";
import CreateDocument from "../action-buttons/create-document";
import { obaDomains, hbaDomains, mmaDomains } from "../../constants/AppDomains";
// import { SidebarData } from "../sidebar/SidebarData";
// MMA-2337 imports
import { updateBackgroundOrders } from "../../shared/graphql/Backgrounds/mutations";
import getQueryVariable from "../../shared/getQueryVariable";
// TANSTACK VIRTUALIZED
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  // useSortBy,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import useSidebarData from "../sidebar/useSidebarData.js";
import FilePreviewModal from "./file-preview-modal.jsx";

const Background = () => {
  const [blockScroll, allowScroll] = useScrollBlock();
  //const [matterList, setClientMattersList] = useState([]);
  const [background, setBackground] = useState([]);
  const [previousBackground, setPreviousBackground] = useState([]);
  const [files, setFiles] = useState([]);
  const [idList, setIdList] = useState([]);
  const [getId, setId] = useState([{}]);
  const [fileMatter, setFileMatter] = useState([]);
  const params = useParams();
  const { matter_id, brief_id, back_id } = params;
  const [checkAllState, setcheckAllState] = useState(false);
  const [search, setSearch] = useState("");
  const [ShowModalParagraph, setShowModalParagraph] = useState(false);
  const [selectRow, setSelectRow] = useState([]);
  const [newRow, setNewRow] = useState([{}]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [srcIndex, setSrcIndex] = useState("");
  const [checkedState, setCheckedState] = useState(
    new Array(background.length).fill(false)
  );
  const [selectedId, setSelectedId] = useState(0);
  const [totalChecked, settotalChecked] = useState(0);
  const [selectedRowsBG, setSelectedRowsBG] = useState([]);
  const [paragraph, setParagraph] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [ascDesc, setAscDesc] = useState(null);
  const [activateButton, setActivateButton] = useState(false);
  const [pasteButton, setPasteButton] = useState(false);
  const [moveButton, setMoveButton] = useState(false);
  const [selectedRowsBGFiles, setSelectedRowsBGFiles] = useState([]);
  const [wait, setWait] = useState(false);
  const [uploadedDocumentList, setUploadedDocumentList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  // let selectedRowsBG = [];

  const [checkNo, setCheckNo] = useState(true);
  const [checkDate, setCheckDate] = useState(true);
  const [checkDesc, setCheckDesc] = useState(true);
  const [checkDocu, setCheckDocu] = useState(true);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageIndex, setPageIndex] = useState(1);
  const [vNextToken, setVnextToken] = useState(null);
  const [vPrevToken, setVprevToken] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxLoading, setMaxLoading] = useState(false);
  const [bgLoading, setBgLoading] = useState(true);
  const [briefName, setBriefName] = useState("");
  const [briefId, setBriefId] = useState("");
  const [validationAlert, setValidationAlert] = useState("");
  const [alertMessage, setalertMessage] = useState();
  const [showToast, setShowToast] = useState(false);
  const [checkedStateShowHide, setCheckedStateShowHide] = useState([]);
  const [searchDescription, setSearchDescription] = useState();
  // const [shareLink, setShareLink] = useState("");
  const [holdDelete, setHoldDelete] = useState(false);
  const [targetRow, setTargetRow] = useState("");
  const [highlightRow, setHighlightRow] = useState(null);

  const [pastedRows, setPastedRows] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [bindList, setbindList] = useState(null);
  const [cache, setCache] = useState(null);

  const [backgroundChanged, setBackgroundChanged] = useState(false);
  const [fontSize, setFontSize] = useState(0);
  const [fileView, setFileView] = useState(1);

  const [allowEditDate, setAllowEditDate] = useState(false);
  const [allowEditDescription, setAllowEditDescription] = useState(false);
  const [allowEditLabels, setAllowEditLabels] = useState(false);
  const [allowEditFiles, setAllowEditFiles] = useState(false);
  const [allowEditComments, setAllowEditComments] = useState(false);
  const [allowDeleteRows, setAllowDeleteRows] = useState(false);
  const [allowAddRows, setAllowAddRows] = useState(false);
  const [allowShowLabels, setAllowShowLabels] = useState(false);
  const [allowDragNDrop, setAllowDragNDrop] = useState(false);
  const [allowDeleteFiles, setAllowDeleteFiles] = useState(false);
  const [allowManageFiles, setAllowManageFiles] = useState(false);
  const [allowAddParagraph, setAllowAddParagraph] = useState(false);
  const [allowDownload, setAllowDownload] = useState(false);
  const [allowCreateDocument, setAllowCreateDocument] = useState(false);
  const [allowManagePrompts, setAllowManagePrompts] = useState(false);
  const [showHideManagePromptsState, setShowHideManagePromptsState] =
    useState(false);
  const [showHideContextualMemoryState, setShowHideContextualMemoryState] =
    useState(false);
  const [allowSummarize, setAllowSummarize] = useState(false);
  // For Mobile Email Preview
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState([]);
  const [from, setFrom] = useState(null);
  const [dateEmail, setDateEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCC] = useState(null);
  const [snippetId, setSnippetId] = useState(null);
  const [isGmailPDF, setIsGmailPDF] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringId, setIsHoveringId] = useState(null);
  const [commentAccess, setCommentAccess] = useState("");
  const [briefs, setBriefs] = useState([]);
  //To enable editing of background on mobile
  const [saving, setSaving] = useState(false);
  const [textDesc, setTextDesc] = useState("");

  const [gmailFilter, setgmailFilter] = useState("all");
  const [presetColumnIds, setPresetColumnIds] = useState([]);

  const [columns, setColumns] = useState();
  const [activeColumns, setActiveColumns] = useState();

  const [tableId, setTableId] = useState();

  //tab markers
  const [reducedTabsArray, setReducedTabsArray] = useState([]);
  const [reducedUploadedFilesArray, setReducedUploadedFilesArray] = useState(
    []
  );

  let history = useHistory();
  const check = useRef(false);
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);
  const [backgroundColumnIds, setBackgroundColumnIds] = useState([]);

  const defaultColumns = [0, 1, 2, 3, 4];

  const [pressedQSearch, setPressedQSearch] = useState(false);
  const [qSearchValue, setQSearchValue] = useState(undefined);
  const [totalHits, setTotalHits] = useState(0);
  const [qSearchPtr, setQSearchPtr] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredLabels, setFilteredLabels] = useState([]);
  const [labels, setLabels] = useState(null);
  const [invalidState, setInvalidState] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [showHideFontMenuState, setShowHideFontMenuState] = useState(false);
  const [showhideState, setShowHideState] = useState(false);
  const [tableColumnList, setTableColumnList] = useState(null);
  const [columnCount, setColumnCount] = useState(null);
  const [baseTablePadding, setBaseTablePadding] = useState("p-1");

  const [totalBudgetAmount, setTotalBudgetAmount] = useState(0);
  const [totalCostAmount, setTotalCostAmount] = useState(0);
  const [totalBreakdownAmount, setTotalBreakdownAmount] = useState(0);

  //for labels filters
  const [filterBackground, setFilterBackground] = useState([]);

  //Right Panel GPT
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [interfaceRightPanel, setInterfaceRightPanel] = useState("bulk");

  // Get last URL Accessed
  localStorage.setItem("lastUrlAccessed", window.location.href);

  // MMA-2659
  const [isSiteDiary, setIsSiteDiary] = useState(false);
  const [currUrl, setCurrUrl] = useState(window.location.hostname);

  //BULK SUMMARISE VARS
  const [willBulkSummarise, setWillBulkSummarise] = useState(false);
  const [selectedFilesBulkSummary, setSelectedFilesBulkSummary] = useState([]);
  const [rightPanelItemsBulkSummary, setRightPanelItemsBulkSummary] = useState(
    []
  );

  //Contract Advice
  const [showContractAdviceNotif, setShowContractAdviceNotif] = useState(false);

  let SidebarData = useSidebarData(localStorage.getItem("userCategory"));
  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  const domain = window.location.hostname;

  //NOTIFICATION BULK SUMMARY
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  // const [unreadNotifications, setUnreadNotifications] = useState([{
  //   message: "Summarisation completed for 1 files",
  //   bulkResult: [{
  //     message: "Error extracting",
  //     backgroundId: "",
  //     fileId: "",
  //     fileName: "File.pdf",
  //     extractedText: "",
  //   }, {
  //     message: "Error summarising",
  //     backgroundId: "",
  //     fileId: "",
  //     fileName: "File.docs",
  //     extractedText: "",
  //   }],
  // }]);

  const [readNotifications, setReadNotifications] = useState([]);

  //FILE PREVIEW WEB
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [filePreviewItems, setFilePreviewItems] = useState(null);
  const [showUnviewable, setShowUnviewable] = useState(false);
  const [unviewableFileLink, setUnviewableFileLink] = useState("");

  useEffect(() => {
    if (showNotificationDropdown) {
      setUnreadNotifications([]);
      setReadNotifications((arr) => [...arr, ...unreadNotifications]);
    }
  }, [showNotificationDropdown]);

  //TRACK NUMBER OF VISITS IN HBA-SANDBOX
  var status = localStorage.getItem("isSandbox");

  const mRecordSandboxVisit = `
  mutation createActivity(
    $companyId: ID,
    $activity: String,
    $appModule: AppModules,
    $field: String,
    $current: String,
    $previous: String,
    $rowId: String
  ) {
    activityCreate(
      activity: $activity
      companyId: $companyId
      appModule: $appModule
      previous: $previous
      field: $field
      current: $current
      rowId: $rowId
    ) {
      id
    }
  }
`;

  //record number of seconds per page
  const [startTime, setStartTime] = useState(0);

  useEffect(async () => {
    var bgName = "";
      const params = {
        query: qGetBriefsByClientMatter,
        variables: {
          id: matter_id,
          limit: 50,
          nextToken: null,
        },
      };

      await API.graphql(params).then((brief) => {
        console.log("ressult", brief);

        var allBriefs = brief?.data?.clientMatter?.briefs?.items;

        allBriefs.map((x) => {
          if (x.id === brief_id) {
            let briefName = x.name;
            let originalString = briefName?.replace(/(<([^>]+)>)/gi, "");
            let final = originalString?.replace(/\&nbsp;/g, " ");
            bgName = final;
          }
        });
      });

    // Set the start time when the page is first loaded
    const initialStartTime = Date.now();
    setStartTime(initialStartTime);

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && status === "true") {
        const endTime = Date.now();
        const timeSpent = Math.floor((endTime - initialStartTime) / 1000); // Convert milliseconds to seconds
        console.log(`User spent ${timeSpent} seconds on the page before it became hidden.`);
        recordActivity(timeSpent, bgName)
        // Here you can send the timeSpent data to your server if needed
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  

  const recordActivity = async (seconds, name) => {
        const params1 = {
          query: mRecordSandboxVisit,
          variables: {
            companyId: localStorage.getItem("companyId"),
            activity: `${name} Page. Time spent: ${seconds}`,
            userId: localStorage.getItem("userId"),
            appModule: "SANDBOX",
          },
        };

        const addActivity = API.graphql(params1).then((result) => {
          console.log(`User spent ${seconds} seconds on ${name}`);
        });

        console.log("Result >>",addActivity)
        
  };


  // KJMF MMA 3587 and 3588 - Number of interactions / Navigations in different tiles
  useEffect(async () => {
    if (status === "true") {
      var bgName = "";
      const params = {
        query: qGetBriefsByClientMatter,
        variables: {
          id: matter_id,
          limit: 50,
          nextToken: null,
        },
      };

      await API.graphql(params).then((brief) => {
        console.log("ressult", brief);

        var allBriefs = brief?.data?.clientMatter?.briefs?.items;

        allBriefs.map((x) => {
          if (x.id === brief_id) {
            let briefName = x.name;
            let originalString = briefName?.replace(/(<([^>]+)>)/gi, "");
            let final = originalString?.replace(/\&nbsp;/g, " ");
            bgName = final;
          }
        });
      });

      //add activity when user exits the page
      const params1 = {
        query: mRecordSandboxVisit,
        variables: {
          companyId: localStorage.getItem("companyId"),
          activity: `Visited ${bgName} Page`,
          userId: localStorage.getItem("userId"),
          appModule: "SANDBOX",
        },
      };

      const addActivity = API.graphql(params1).then((result) => {
        console.log(`Visited ${bgName} Page`, result);
      });
    }
  }, []);

  // Added way to determine if bg is Site Diary
  const qGetClientMatterById = `
		query clientMatterById($id: ID) {
			clientMatter (id: $id) {
				id
				client {
					name
					id
				}
				matter {
					name
					id
				}
				description
				matterNumber
			}
		}`;

  // MMA-2337: File Bucket: Labels not updating from created brief rows
  const qGetFilesByMatter = `
		query getFilesByMatter($isDeleted: Boolean, $limit: Int, $matterId: ID, $nextToken: String, $sortOrder: OrderBy) {
		matterFiles(isDeleted: $isDeleted, matterId: $matterId, nextToken: $nextToken, limit: $limit, sortOrder: $sortOrder) {
			items {
			id
			name
			details
			date
			s3ObjectKey
			gmailMessageId
			isGmailPDF
			labels {
				items {
				id
				name
				}
			}
			backgrounds {
				items {
				id
				files{
					items{
					id
					}
				}
				order
				description
				date
				createdAt
				briefs {
					id
					name
					userTypeAccess
				}
				}
			}
			createdAt
			order
			type
			size
			}
			nextToken
		}
		}
	`;
  const mCreateLabel = `
		mutation createLabel($clientMatterId: String, $name: String) {
			labelCreate(clientMatterId:$clientMatterId, name:$name) {
				id
				name
			}
		}
		`;
  const qBriefByName = `
		query getBriefByName($clientMatterId: ID, $name: String) {
			briefByName(clientMatterId: $clientMatterId, name: $name) {
			id
			labelId
			}
		}
		`;
  const mCreateBrief = `
		mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
			briefCreate(clientMatterId: $clientMatterId, date: $date, name: $name, order: $order, columnIds: $columnIds) {
			id
			name
			date
			createdAt
			}
		}
		`;
  const mUpdateBrief = `
		mutation updateBrief($id: ID, $labelId: ID) {
			briefUpdate(id: $id, labelId: $labelId) {
			id
			}
		}
		`;
  const mCreateBackground = `
		mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
		backgroundCreate(description: $description, comments: $comments, date: $date, order: $order, briefs: $briefs) {
			id
		}
		}
		`;
  const mUpdateBackgroundFile = `
		mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
		backgroundFileTag(backgroundId: $backgroundId, files: $files) {
			id
		}
		}
		`;

  const getOpenAIPromptsByCompany = `
    query getOpenAIPromptsByCompany($companyId: ID!, $clientMatterId: ID) {
      openAIPromptsByCompany(
        companyId: $companyId
        clientMatterId: $clientMatterId
      ) {
        companyId
        id
        name
        description
        prompt
      }
    }
`;

  const getContextualLibrary = `
    query getOpenAIPromptsByCompany($companyId: ID!, $clientMatterId: ID) {
      openAIPromptsByCompany(
        companyId: $companyId
        clientMatterId: $clientMatterId
      ) {
        companyId
        id
        name
        description
        prompt
        extractedFileContent
        fileId
      }
    }
  `;

  const createPrompt = `
    mutation createPrompt($companyId: ID, $clientMatterId: ID, $name: String, $prompt: String, $description: String) {
      openAIPromptsCreate(
        companyId: $companyId
        clientMatterId: $clientMatterId
        name: $name
        prompt: $prompt
        description: $description
      ) {
        id
        companyId
        name
        description
        prompt
      }
    }
  `;

  const [isCreateBriefClicked, setIsCreateBriefClicked] = useState(false);
  const [newLabel, setNewLabel] = useState(null);
  const [newLabelValue, setNewLabelValue] = useState(null);
  const [matterFiles, setMatterFiles] = useState([]);
  const [filteredMatterFiles, setFilteredMatterFiles] = useState([]);
  const [executeLabelChange, setExecuteLabelChange] = useState(false);
  const [openAiPrompts, setOpenAiPrompts] = useState(null);
  const [coreMemory, setCoreMemory] = useState(null);

  const sortOrder = "ORDER_ASC";

  const updateLabel = (newValue) => {
    setNewLabel(newValue);
  };
  const updateLabelValue = (newValue) => {
    setNewLabelValue(newValue);
  };

  // console.log("MATTER FILES FETCHED IN BACKGROUND: ", matterFiles);
  // console.log("New LABEL:", newLabel);
  // console.log("New LABEL VALUE:", newLabelValue);
  // console.log("SELECTED ITEMS: ", selectedItems);
  // console.log("SELECTED FILTERED FILES: ", filteredMatterFiles);
  // console.log("BACKGROUND FILES:", background);

  let getMatterFiles = async (next) => {
    const params = {
      query: qGetFilesByMatter,
      variables: {
        matterId: matter_id,
        isDeleted: false,
        limit: 1500,
        nextToken: next === 1 ? null : vNextToken,
        sortOrder: sortOrder,
      },
    };
    await API.graphql(params).then((files) => {
      let matterFilesList = files?.data?.matterFiles?.items;
      // console.log("matterFilesList in BACKGROUND :", matterFilesList);
      /// setVnextToken(files?.data?.matterFiles?.nextToken);
      // setFiles(sortByOrder(matterFilesList));
      setMatterFiles(matterFilesList); // no need to use sortByOrder
      // setMaxLoading(false);
      // applyUserAccess(matterFilesList);
    });
  };

  useEffect(() => {
    autoAdjustAllRow();
  }, [background, filterBackground]);

  const handleLabelChanged = async (id, e, existingLabels, index) => {
    // console.log("event", e, "id", id);
    // console.log("index", index);
    var labelsList = [];

    if (e.label) {
      if (e.__isNew__) {
        await API.graphql({
          query: mCreateLabel,
          variables: {
            clientMatterId: matter_id,
            name: e.label,
          },
        }).then((r) => {
          console.log("createLabel", r);

          const newLabelId = r.data.labelCreate.id,
            newLabelName = r.data.labelCreate.name;

          console.log("newLabelId", newLabelId);
          console.log("newLabelName", newLabelName);

          let updateLabel = labels;
          updateLabel.push({
            value: newLabelId,
            label: newLabelName,
          });

          // Remove duplicates using Set
          const uniqueLabels = Array.from(
            new Set(updateLabel.map((label) => label.value))
          ).map((value) => updateLabel.find((label) => label.value === value));

          setLabels(uniqueLabels);

          labelsList = [
            ...labelsList,
            {
              id: newLabelId,
              name: newLabelName,
            },
          ];
          const data = createBackgroundFromLabel(
            id,
            {
              id: newLabelId,
              name: newLabelName,
            },
            true
          );
        });
      } else {
        labelsList = [...labelsList, { id: e.value, name: e.label }];

        try {
          const data = await createBackgroundFromLabel(id, {
            id: e.value,
            name: e.label,
          });
        } catch (e) {
          console.log("error", e);
        }
      }
    }
  };

  const createBackgroundFromLabel = async (row_id, label, isNew) => {
    // console.log("ROW_ID", row_id, "INSIDE FROM LABEL", label);
    var returnBriefId, returnBgId, returnFileDate;

    const mf = matterFiles.filter((item) => row_id.includes(item.id)); // get row details
    // check if brief already exists
    let briefNameExists = false;
    const getBriefByName = await API.graphql({
      query: qBriefByName,
      variables: {
        clientMatterId: matter_id,
        name: label.name,
      },
    });

    let briefId = getBriefByName.data.briefByName.id,
      existingBriefNameLabel = getBriefByName.data.briefByName.labelId;

    if (briefId !== "" && briefId !== null) {
      briefNameExists = true;
    }

    if (isNew) {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
          columnIds: [0, 1, 2, 3, 4],
        };

        const createBrief = await API.graphql({
          query: mCreateBrief,
          variables: params,
        });

        console.log("createBrief", createBrief);
        briefId = createBrief.data.briefCreate.id;

        returnBriefId = createBrief.data.briefCreate.id;

        updatePresetsForNewlyCreatedBrief(createBrief, label.name);
      } else {
        console.log("existingBriefNameLabel", existingBriefNameLabel);
        if (existingBriefNameLabel === null) {
          const params = {
            id: briefId,
            labelId: label.id,
          };

          await API.graphql({
            query: mUpdateBrief,
            variables: params,
          });
        }

        returnBriefId = briefId;
      }

      const fileId = row_id,
        fileDetails = mf[0].details,
        fileDate =
          mf[0].date != null
            ? moment
                .utc(moment(new Date(mf[0].date), "YYYY-MM-DD"))
                .toISOString()
            : null;

      // Create Background
      const createBackground = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: [{ name: label.name, id: briefId }],
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      returnBgId = createBackground.data.backgroundCreate.id;
      returnFileDate = fileDate;
      console.log("createBackground", createBackground);
      if (createBackground.data.backgroundCreate.id !== null) {
        // Tag File to Background
        await API.graphql({
          query: mUpdateBackgroundFile,
          variables: {
            backgroundId: createBackground.data.backgroundCreate.id,
            files: [{ id: fileId }],
          },
        });
      }
    } else {
      if (!briefNameExists) {
        const params = {
          clientMatterId: matter_id,
          name: label.name,
          date: null,
          order: 0,
          labelId: label.id,
        };

        // Create Brief
        const createBrief = await API.graphql({
          query: mCreateBrief,
          variables: params,
        });

        console.log("createBrief", createBrief);
        briefId = createBrief.data.briefCreate.id;

        updatePresetsForNewlyCreatedBrief(createBrief, label.name);
      } else {
        console.log(label.name === null ? "null" : label.name);
        if (existingBriefNameLabel === null) {
          // Tag Label to Brief
          await API.graphql({
            query: mUpdateBrief,
            variables: {
              id: briefId,
              labelId: label.id,
            },
          });
        }
      }

      const fileId = mf[0].id,
        fileDetails = mf[0].details,
        fileDate =
          mf[0].date != null
            ? moment
                .utc(moment(new Date(mf[0].date), "YYYY-MM-DD"))
                .toISOString()
            : null;

      // Create Background
      const createBackground = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: [{ name: label.name, id: briefId }],
          description: fileDetails,
          comments: "",
          date: fileDate,
        },
      });

      returnBgId = createBackground.data.backgroundCreate.id;
      returnFileDate = fileDate;
      console.log("createBackground", createBackground);
      if (createBackground.data.backgroundCreate.id !== null) {
        // Tag File to Background
        await API.graphql({
          query: mUpdateBackgroundFile,
          variables: {
            backgroundId: createBackground.data.backgroundCreate.id,
            files: [{ id: fileId }],
          },
        });
      }
    }

    // var briefId;
    // for (let i=0; i<Briefs.length; i++) {
    //   if (Briefs[i].name === e.label) {
    //     briefId = Briefs[i].id;
    //     break
    //   }
    // }

    const backgroundOptNew = await API.graphql({
      query: qGetBackgroundsByBriefId,
      variables: {
        id: briefId,
        nextToken: null,
        sortOrder: "ORDER_ASC",
        limit: null,
      },
    }).catch((error) => {
      console.log("ERROR FETCHING BACKGROUND DATA: RESTART PAGE");
    });

    if (backgroundOptNew?.data?.brief?.backgrounds?.items !== null) {
      var result = backgroundOptNew?.data.brief.backgrounds.items.map(
        ({
          id,
          description,
          comments,
          date,
          createdAt,
          order,
          files,
          labels,
          briefs,
          dynamicData,
          email,
        }) => ({
          createdAt: createdAt,
          id: id,
          description: description,
          comments: comments,
          date: date,
          order: order,
          files: files,
          labels: labels,
          briefs: briefs,
          email: email,
          dynamicData,
        })
      );

      if (result !== null) {
        const rowArrangement = result.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        console.log("!!! rowArrangement", rowArrangement);

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement).then(() => {
          getMatterFiles(1);
        });

        console.log("!!! done updateBackgroundOrders");
      }
    }

    // return returnBriefId;

    // var indx = matterFiles.findIndex((x) => x.id === row_id);
    // const add = {
    //   briefs: [{id: returnBriefId,name: label.name }],
    //   id: returnBgId,
    //   createdAt: returnFileDate,
    //   date: returnFileDate,
    //   description: "",
    //   order: 0,
    // };

    // matterFiles[indx].backgrounds.items = [
    //   ...matterFiles[indx].backgrounds.items,
    //   add,
    // ];

    console.log("updated", matterFiles);
  };

  const updatePresetsForNewlyCreatedBrief = async (brief, briefName) => {
    const backgroundTable = await API.graphql({
      query: BACKGROUND_TABLE_QUERY,
      variables: {
        clientMatterId: matter_id,
      },
    });

    if (backgroundTable?.data?.backgroundTable) {
      const { id, columns } = backgroundTable?.data?.backgroundTable;

      const updatedBriefs = columns.map((obj) => {
        if (parseInt(obj.accessorKey) !== 3) {
          const presetsToUse = [
            ...obj.presets.filter(
              (preset) => preset.id !== brief.data.briefCreate.id
            ),
            { id: brief.data.briefCreate.id, name: briefName },
          ];

          return { ...obj, presets: presetsToUse };
        } else {
          return obj;
        }
      });

      const variables = {
        id,
        input: {
          columns: updatedBriefs,
        },
      };

      const updateBriefTable = await API.graphql({
        query: UPDATE_BACKGROUND_TABLE_MUTATION,
        variables,
      });

      console.log("updateBriefTable", updateBriefTable);
    }
  };

  useEffect(() => {
    if (wait) {
      getMatterFiles(1);
    }
  }, [wait]);

  useEffect(async () => {
    if (openAiPrompts === null) {
      const response = await API.graphql({
        query: getOpenAIPromptsByCompany,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: " ",
        },
      });

      let prompts = response?.data?.openAIPromptsByCompany;
      if (prompts.length > 0) {
        setOpenAiPrompts(prompts);
      } else {
        const newPromptRes = await API.graphql({
          query: createPrompt,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: " ",
            name: "Summary",
            prompt:
              "Summarize the email from a third-person point of view in one paragraph, with a maximum of 10 sentences, while excluding the signature of the original sender",
          },
        });
        const newPrompt = newPromptRes.data.openAIPromptsCreate;
        setOpenAiPrompts([newPrompt]);
      }
    }
  }, []);

  useEffect(async () => {
    if (coreMemory === null) {
      const response = await API.graphql({
        query: getContextualLibrary,
        variables: {
          companyId: localStorage.getItem("companyId"),
          clientMatterId: matter_id,
        },
      });

      let coreMemories = response?.data?.openAIPromptsByCompany;
      console.log("Core Memories", coreMemories);
      setCoreMemory(coreMemories);
    }
  }, []);

  useEffect(() => {
    if (isCreateBriefClicked) {
      let updatedMatterFiles = matterFiles.filter((item) => {
        return item.backgrounds.items.some((background) =>
          selectedItems.includes(background.id)
        );
      });
      setFilteredMatterFiles(updatedMatterFiles);

      setIsCreateBriefClicked(false);
      setExecuteLabelChange(true);
    }
  }, [isCreateBriefClicked]);

  useEffect(() => {
    if (executeLabelChange) {
      for (const matter in filteredMatterFiles) {
        console.log("WORKING!!!");
        // console.log("MATTERS: ", filteredMatterFiles[matter]);
        handleLabelChanged(
          filteredMatterFiles[matter].id,
          {
            label: newLabel,
            value: newLabelValue,
            __isNew__: true,
          },
          filteredMatterFiles[matter].labels.items,
          filteredMatterFiles[matter].order - 1
        );
      }
      setExecuteLabelChange(false);
    }
  }, [executeLabelChange]);

  /*-------------------------- MMA-2337 END --------------------------*/

  var moment = require("moment");

  // function test() {
  //   console.log("hello");
  // }

  // useEffect(() => {
  //   console.log("BACKGROUND IS HEREEEE:", background);
  // }, [background]);

  //getting labels
  const getLabels = async () => {
    await getBgList(matter_id).then(async (briefsList) => {
      try {
        console.log("briefsList", briefsList);
        if (briefsList === null || briefsList?.length === 0) {
          console.log("set labels", briefsList);
          setLabels([]);
        } else {
          var temp = [];
          briefsList.map((x) => {
            // temp = [...temp, {value: x.id, label: x.name}]
            temp = [...temp, x.name];
          });
          console.log("temp 123", temp);
          setLabels(temp);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  useEffect(() => {
    if (labels === null) {
      getLabels();
    }
  }, []);

  // GraphQL queries and mutations
  const qGetFileDownloadLink = `query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const mUpdateBriefName = `mutation updateBriefName($id: ID, $name: String) {
    briefUpdate(id: $id, name: $name) {
      id
    }
  }`;

  const qGetBriefsByClientMatter = `query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          date
          order
          hasCommentAccess
          columnIds
        }
      }
    }
  }`;

  const qListBackground = `query listBackground($id: ID, $limit: Int, $nextToken: String, $sortOrder: OrderBy) {
    clientMatter(id: $id) {
      id
      backgrounds (limit: $limit, nextToken: $nextToken, sortOrder:$sortOrder) {
        items {
          id
          description
          comments
          date
          createdAt
          order
          dynamicData
          files {
            items {
              id
              details
              name
              type
              gmailMessageId
              isGmailPDF
              isGmailAttachment
              s3ObjectKey
              order
              createdAt
            }
          }
          labels {
            items {
              id
              name
            }
          }
        }
        nextToken
      }
    }
  }`;

  const UPDATE_BRIEF_PRESET_MUTATION = `
  mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    briefUpdate(id: $id, columnIds: $columnIds){
      id
    }
  }
  `;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `
  mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }
  `;

  const qGetBackgroundsByBriefId = `query getBriefByID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
    brief(id: $id) {
      id
      name
      columnIds
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          description
          comments
          date
          createdAt
          order
          dynamicData
          email
          files {
            items {
              id
              name
              details
              gmailMessageId
              gmailMessage {
                gmailLabelIds
              }
              isGmailPDF
              isGmailAttachment
              s3ObjectKey
              order
              createdAt
              size
              extractedFileContent
            }
          }
          tabFiles {
			      fileIds
		      }
          briefs {
            id
            name
          }
          labels {
            items {
              id
              name
            }
          }
          promptHistory
        }
        nextToken
      }
    }
  }
  `;

  const EMAIL_PREVIEW = `
      query getEmailDetails($id: ID) {
        gmailMessage(id: $id) {
          id
          from
          date
          to
          cc
          bcc
          subject
          recipient
          receivedAt
          payload {
            id
            content
          }
        }
      }`;

  const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
      gmailMessage(id: $id) {
        payload {
          content
        }
      }
    }
    `;

  const BACKGROUND_TABLE_QUERY = `
  query getBackgroundTable($clientMatterId: ID!) {
    backgroundTable(clientMatterId: $clientMatterId) {
      id
      columns {
        id
        accessorKey
        headerText
        enabled
        type
        optionsText
        order
        presets {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
}`;

  const BACKGROUND_TABLE_CREATE = `
    mutation createBackgroundTable($clientMatterId: ID!) {
      backgroundTableCreate(clientMatterId: $clientMatterId) {
        id
        columns {
          id
          accessorKey
          headerText
          enabled
          type
          optionsText
          order
        }
        createdAt
        updatedAt
      }
    }
    `;

  const mCreateActivity = `
    mutation createActivity($companyId: ID, $clientMatterId: ID, $briefId: ID, $activity: String, $field: String, $current: String, $previous: String, $appModule: AppModules, $rowId: String) {
      activityCreate(
        activity: $activity
        briefId: $briefId
        clientMatterId: $clientMatterId
        companyId: $companyId
        previous: $previous
        field: $field
        current: $current
        appModule: $appModule
        rowId: $rowId
      ) {
        id
      }
    }`;

  const mUpdateBackgroundDesc = `
  mutation updateBackground($id: ID, $description: String) {
    backgroundUpdate(id: $id, description: $description) {
      id
      description
    }
  }
`;

  const selectStyle = {
    option: (base, selectProps) => ({
      ...base,
      backgroundColor:
        selectProps.isFocused && !selectProps.isSelected
          ? "transparent"
          : base.backgroundColor,
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 40,
      margin: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: "rotate(3600deg)",
      paddingRight: "0px",
      paddingLeft: "0px",
      color: "text-gray-600",
    }),
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      border: 0,
      padding: 0,
      margin: 0,
      fontWeight: "600",
      fontSize: "0.8rem",
      textDecoration: "underline",
      color: "#374151",
    }),
    menu: (base) => ({
      ...base,
      marginTop: 0,
      padding: 0,
      margin: 0,
      right: "2vw",
      width: "max-content",
      minWidth: "100%",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    placeholder: (base, state) => ({
      ...base,
      fontWeight: "600",
      fontSize: "0.8rem",
      paddingTop: "0.1rem",
      textDecoration: "underline",
      color: "#374151",
    }),
    input: (base, state) => ({
      ...base,
      color: "transparent",
    }),
    singleValue: (base, state) => ({
      ...base,
      fontWeight: "600",
      fontSize: "0.8rem",
      paddingTop: "0.1rem",
      textDecoration: "underline",
      color: "#374151",
    }),
  };
  // const test = utf8_to_b64('0');

  function visitBrief(id) {
    // history.push(`${AppRoutes.BACKGROUND}/${id}`);
    // const back_id = "0";
    history.push(
      `${AppRoutes.BACKGROUND}/${back_id}/${matter_id}/${id}/?matter_name=${m_name}&client_name=${c_name}&back=true&url=${isUrl}`
    );
    window.location.reload();
  }

  const handleSelectChange = (selectedOption) => {
    // Assuming you have access to the corresponding item ID
    const selectedItem = briefs.find(
      (item) => item.name === selectedOption.value
    );

    if (selectedItem) {
      setBackgroundChanged(!backgroundChanged);
      visitBrief(selectedItem.id);
    }
  };

  function sortByAccesorKey(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) => a.accessorKey - b.accessorKey);
    } else {
      sort = arr;
    }

    return sort;
  }

  const fetchTable = async () => {
    const fetchData = async () => {
      try {
        const result = await API.graphql({
          query: BACKGROUND_TABLE_QUERY,
          variables: {
            clientMatterId: matter_id,
          },
        });

        let columnNames = result?.data?.backgroundTable?.columns?.map(
          (elem) => elem.headerText
        );

        if (result.data.backgroundTable) {
          console.log(
            "is Label present?",
            columnNames.indexOf("Label") > -1 ? "YES" : "NO"
          );

          console.log(
            "is Email Date present?",
            columnNames.indexOf("Email Date") > -1 ? "YES" : "NO"
          );

          const tableColumns = result.data.backgroundTable.columns;

          if (
            // columnNames.indexOf("Label") > -1 &&
            columnNames.indexOf("Email Date") > -1
          ) {
            const taggedTableColumns = result.data.backgroundTable?.columns
              .map((c) => {
                let columnObj;
                if (c.type === "DROPDOWN") {
                  columnObj = {
                    accessorKey: c.id,
                    header: c.headerText,
                    type: c.type,
                    enabled: c.enabled,
                    order: c.order,
                    presets: c.presets,
                    optionsText: c.optionsText ? c.optionsText : [],
                  };
                } else {
                  columnObj = {
                    accessorKey: c.id,
                    header: c.headerText,
                    type: c.type,
                    enabled: c.enabled,
                    presets: c.presets,
                    order: c.order,
                  };
                }

                if (
                  columnObj.presets &&
                  parseInt(columnObj.accessorKey) !== 3
                ) {
                  if (columnObj?.presets?.length === 0) {
                    return columnObj;
                  }
                  if (
                    columnObj?.presets?.some(
                      (preset) => preset?.id === brief_id
                    )
                  ) {
                    return columnObj;
                  }
                } else {
                  if (parseInt(columnObj.accessorKey) !== 3) {
                    columnObj.presets = [
                      {
                        id: brief_id,
                        name: bgName,
                      },
                    ];
                    return columnObj;
                  }
                }
              })
              .filter((obj) => obj !== undefined);

            const columnIds = taggedTableColumns.map((obj) =>
              parseInt(obj.accessorKey)
            );

            const mergedWithDefaultCols = columnIds;

            const defaultHiddenColumns = ["Email Date"];

            if (
              hbaDomains.includes(
                process.env.REACT_APP_DOMAIN.replace("https://", "")
              )
            ) {
              defaultHiddenColumns.push("Label");
            }

            const filteredTaggedCols = taggedTableColumns
              .filter(
                (obj) =>
                  mergedWithDefaultCols.includes(parseInt(obj.accessorKey)) &&
                  obj.enabled
              )
              .map((column) => ({
                ...column,
                visible: defaultHiddenColumns.some(
                  (hideColumn) => column.header === hideColumn
                )
                  ? false
                  : true,
              }));

            setColumns(filteredTaggedCols);
            setActiveColumns(filteredTaggedCols);
            setTableId(result.data.backgroundTable?.id);

            console.log("Filtered->", filteredTaggedCols);
          } else {
            // if (columnNames.indexOf("Label") === -1) {
            //   addLabelColumn(tableColumns, result.data.backgroundTable.id);
            // }
            if (columnNames.indexOf("Email Date") === -1) {
              addEmailDateColumn(tableColumns, result.data.backgroundTable.id);
            }
          }
        } else {
          console.log("Initialize Background Table");
          createTable();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial call to fetch data
    await fetchData();
  };

  async function addLabelColumn(columns, tableId) {
    var tableColumns;
    var type = "READONLY";
    var formData = {
      headerText: "Label",
    };
    console.group("addLabelColumn()");
    //const tableId = localStorage.getItem("task-list-table-id");
    let presetsFromFirst = sortByAccesorKey(columns)[0].presets.filter(
      (item) => item.name !== "Variations" && item.name !== "Invoices"
    );
    const columnsToSet = sortByAccesorKey(columns);

    // .map((c) => {
    // 	//delete c.id;
    // 	//delete c.accessorKey;
    // 	console.log("C IS:", c);
    // 	c.headerText = c.header;
    // 	delete c.header;
    // 	return c;
    // });

    console.log("formData", formData);
    console.log("columnsToSet", columnsToSet);

    const data = await API.graphql({
      query: UPDATE_BACKGROUND_TABLE_MUTATION,
      variables: {
        id: tableId,
        input: {
          columns: [
            ...columnsToSet,
            {
              headerText: formData.headerText,
              accessorKey: columnsToSet.length.toString(),
              type: type.toUpperCase(),
              enabled: true,
              optionsText:
                type === "Dropdown"
                  ? formData.options.map((opt) => opt.content)
                  : [],
              order: columns.length + 1,
              presets: presetsFromFirst,
            },
          ],
        },
      },
    })
      .then((result) => {
        console.log("SUCCESS ADDING COLUMN", result);
        tableColumns = sortByOrder(
          result.data.backgroundTableUpdate.columns
        ).map((c) => {
          if (c.type === "DROPDOWN") {
            return {
              accessorKey: c.id,
              headerText: c.headerText,
              type: c.type,
              enabled: c.enabled,
              order: c.order,
              presets: c.presets,
              optionsText: c.optionsText ? c.optionsText : [],
            };
          }
          return {
            accessorKey: c.id,
            headerText: c.headerText,
            type: c.type,
            order: c.order,
            enabled: c.enabled,
            presets: c.presets,
          };
        });

        console.log("tableColumns:", tableColumns);
      })
      .then(() => {
        switchLabelColumn(tableColumns, tableId);
      });
    console.groupEnd();
  }

  async function switchLabelColumn(columns, tableId) {
    console.log("**********switchLabelColumn");

    console.log(columns);

    var newColumns = cloneDeep(columns);
    var newColumnNames = newColumns.map((elem) => elem.headerText);
    var idxDocument = newColumnNames.indexOf("Document");
    var idxLabel = newColumnNames.indexOf("Label");

    var popped = cloneDeep(newColumns[idxLabel]);
    newColumns.splice(idxLabel, 1);
    newColumns.splice(idxDocument, 0, popped);

    console.log(newColumns);

    const newColumnOrder = newColumns.map((column, index) => {
      column.order = index + 1;
      return column;
    });

    const columnsToSetApi = sortByAccesorKey(newColumnOrder).map(
      ({ accessorKey, headerText, ...rest }) => {
        return {
          accessorKey: accessorKey,
          headerText: headerText,
          ...rest,
        };
      }
    );

    // console.log("columnsToSetApi", columnsToSetApi);

    if (columnsToSetApi.length > 0 && tableId) {
      const data = await API.graphql({
        query: UPDATE_BRIEF_TABLE_MUTATION,
        variables: {
          id: tableId,
          input: {
            columns: columnsToSetApi,
          },
        },
      }).then((result) => {
        const tableColumns = result?.data?.backgroundTableUpdate?.columns?.map(
          (c) => {
            if (c.type === "DROPDOWN") {
              return {
                accessorKey: c.accessorKey,
                headerText: c.headerText,
                type: c.type,
                enabled: c.enabled,
                order: c.order,
                presets: c.presets,
                optionsText: c.optionsText ? c.optionsText : [],
              };
            }
            return {
              accessorKey: c.accessorKey,
              headerText: c.headerText,
              type: c.type,
              order: c.order,
              presets: c.presets,
              enabled: c.enabled,
            };
          }
        );
        setTimeout(() => {
          fetchTable();
        }, 3000);
      });
    }
  }

  let reloadCalled = false;

  async function addEmailDateColumn(columns, tableId) {
    if (reloadCalled) return; // Check if reload has been called before

    var tableColumns;
    var type = "READONLY";
    var formData = {
      headerText: "Email Date",
    };
    console.group("addEmailDateColumn()");
    let presetsFromFirst = sortByAccesorKey(columns)[0].presets.filter(
      (item) => item.name !== "Variations" && item.name !== "Invoices"
    );
    const columnsToSet = sortByAccesorKey(columns);
    // .map((c) => {
    // 	c.headerText = c.header;
    // 	delete c.header;
    // 	return c;
    // });

    console.log("formData", formData);
    console.log("columnsToSet", columnsToSet);

    const data = await API.graphql({
      query: UPDATE_BACKGROUND_TABLE_MUTATION,
      variables: {
        id: tableId,
        input: {
          columns: [
            ...columnsToSet,
            {
              headerText: formData.headerText,
              accessorKey: columnsToSet.length.toString(),
              type: type.toUpperCase(),
              enabled: true,
              optionsText:
                type === "Dropdown"
                  ? formData.options.map((opt) => opt.content)
                  : [],
              order: columns.length + 1,
              presets: presetsFromFirst,
            },
          ],
        },
      },
    })
      .then((result) => {
        console.log("SUCCESS ADDING EMAIL DATE COLUMN", result);
        tableColumns = sortByOrder(
          result.data.backgroundTableUpdate.columns
        ).map((c) => {
          if (c.type === "DROPDOWN") {
            return {
              accessorKey: c.id,
              headerText: c.headerText,
              type: c.type,
              enabled: c.enabled,
              order: c.order,
              presets: c.presets,
              optionsText: c.optionsText ? c.optionsText : [],
            };
          }
          return {
            accessorKey: c.id,
            headerText: c.headerText,
            type: c.type,
            order: c.order,
            enabled: c.enabled,
            presets: c.presets,
          };
        });

        console.log("tableColumns:", tableColumns);
      })
      .then(() => {
        //switchLabelColumn(tableColumns, tableId);
        reloadCalled = true; // Set the flag to true after reload is called
        window.location.reload(true);
      });
    console.groupEnd();
  }

  const createTable = async () => {
    console.log("Create Background Table for ", matter_id);
    const { data } = await API.graphql({
      query: BACKGROUND_TABLE_CREATE,
      variables: {
        clientMatterId: matter_id,
      },
    });
    console.log("!!! createTable:", data);

    if (data?.backgroundTableCreate) {
      await fetchTable();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("background-menu-display") == "false") {
      setShowMenu(false);
    }

    // Add tracker for when page has been accessed.
    const params = {
      query: mCreateActivity,
      variables: {
        companyId: localStorage.getItem("companyId"),
        clientMatterId: matter_id,
        briefId: brief_id,
        activity: `page has been accessed.`,
        appModule: "BACKGROUND",
        userId: localStorage.getItem("userId"),
      },
    };
    const addActivity = API.graphql(params).then((result) => {
      console.log("addActivity page access recorded", result);
    });
  }, []);

  function reduceTabFilesToArray(array) {
    // Function to check if two arrays are equal
    function arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a?.length !== b?.length) return false;

      // Check if every item in 'a' exists in 'b'
      for (let i = 0; i < a?.length; i++) {
        if (!b?.includes(a[i])) return false;
      }

      return true;
    }
    const resultArray = [];
    const processedArrays = [];
    let key = 1;

    array?.forEach((item, index) => {
      for (let i = 0; i < item?.tabFiles?.length; i++) {
        const fileIds = item?.tabFiles[i]?.fileIds;

        if (!fileIds) {
          continue;
        }

        if (fileIds?.length === 0) {
          continue; // Skip adding to resultArray if fileIds.length is 0
        }

        // Check if the same fileIds array is already processed and added to the result
        const isDuplicate = processedArrays?.some((arr) =>
          arraysEqual(arr, fileIds)
        );

        if (!isDuplicate) {
          processedArrays?.push(fileIds); // Add the current fileIds array to the processed array
          const existingIndex = resultArray.findIndex(
            (obj) => obj.hasOwnProperty(key) && arraysEqual(obj[key], fileIds)
          );

          if (existingIndex !== -1) {
            const existingObj = resultArray[existingIndex];
            existingObj[key] = [...new Set([...existingObj[key], ...fileIds])];
          } else {
            const newObj = { [key]: fileIds };
            resultArray.push(newObj);
          }

          key++;
        }
      }
    });

    return resultArray;
  }

  useEffect(() => {
    console.group("USEEFFECT BIND CALLING GETBACKGROUND");
    getBackground("Bind UseEffect get Background");
    // getUploadedFiles(1);
    featureAccessFilters();
    if (bgName === null) {
      getBriefs();
    }

    getBriefs();
    fetchTable();

    console.groupEnd();
  }, []);

  function reduceUploadedFilesToArray(array) {
    const allFiles = [];

    for (let index = 0; index < array.length; index++) {
      const rootObj = array[index];
      const order = rootObj.order || null;
      const items = rootObj.files?.items || [];

      for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
        const item = items[itemIndex];
        const newItem = {
          ...item,
          backgroundIndex: rootObj.order,
        };
        allFiles.push(newItem);
      }
    }

    return allFiles;
  }

  useEffect(() => {
    if (background) {
      const outputReducedTabFiles = reduceTabFilesToArray(background);
      setReducedTabsArray(outputReducedTabFiles);

      const outputAllFiles = reduceUploadedFilesToArray(background);
      setReducedUploadedFilesArray(outputAllFiles);
    }
  }, [background]);

  const hideToast = () => {
    setShowToast(false);
  };

  const [bgName, setBGName] = useState(null);

  const [backgroundAsc, setBackgroundAsc] = useState();
  const [backgroundDesc, setBackgroundDesc] = useState();
  const [backgroundDuplicate, setBackgroundDuplicate] = useState([]);

  // useEffect(() => {
  //   if (backgroundAsc === undefined && backgroundDesc === undefined && backgroundDuplicate === undefined) {
  //     try {
  //       const fetchSortedBackground = async () => {
  //         const backgroundAscending = await API.graphql({
  //           query: qGetBackgroundsByBriefId,
  //           variables: {
  //             id: brief_id,
  //             nextToken: null,
  //             sortOrder: "DATE_ASC",
  //           },
  //         });

  //         const backgroundDescending = await API.graphql({
  //           query: qGetBackgroundsByBriefId,
  //           variables: {
  //             id: brief_id,
  //             nextToken: null,
  //             sortOrder: "DATE_DESC",
  //           },
  //         });

  //         const backgroundOpt = await API.graphql({
  //           query: qGetBackgroundsByBriefId,
  //           variables: {
  //             id: brief_id,
  //             nextToken: isMobileDevice(width) ? vNextToken : null,
  //             sortOrder: "ORDER_ASC",
  //             limit: isMobileDevice(width) ? 50 : null,
  //           },
  //         }).catch((error) => {
  //           console.log("ERROR FETCHING BACKGROUND DATA: RESTART PAGE", error);
  //         });

  //         let result;
  //         if (backgroundOpt?.data.brief.backgrounds.items !== null) {
  //           result = backgroundOpt?.data.brief.backgrounds.items;
  //           setBackgroundDuplicate(result);
  //         }

  //         let resultAsc;
  //         if (backgroundAscending?.data.brief.backgrounds.items !== null) {
  //           resultAsc = backgroundAscending?.data.brief.backgrounds.items;
  //           setBackgroundAsc(resultAsc);
  //         }

  //         let resultDesc;
  //         if (backgroundDescending?.data.brief.backgrounds.items !== null) {
  //           resultDesc = backgroundDescending?.data.brief.backgrounds.items;
  //           setBackgroundDesc(resultDesc);
  //         }
  //       }

  //       fetchSortedBackground();
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // }, [backgroundAsc, backgroundDesc, backgroundDuplicate, ascDesc]);

  const fetchBackgroundDateAsc = async (id, nextToken, limit) => {
    try {
      console.log("::Asc::", id, nextToken, limit);
      const backgroundOpt = await API.graphql({
        query: qGetBackgroundsByBriefId,
        variables: {
          id: id,
          nextToken: nextToken,
          sortOrder: "DATE_ASC",
          limit: limit,
        },
      });

      let result = backgroundOpt?.data.brief.backgrounds.items;
      console.log(result);
      return result;
    } catch (error) {
      console.error("ERROR FETCHING BACKGROUND DATA:::", error.message);
      window.location.reload(); // reload since at this point any background fetched in the end will have problems
      return null;
    }
  };

  // const fetchBackgroundDateDesc = async (id, nextToken, limit) => {
  //   try {
  //     console.log("::Desc::", id, nextToken, limit);
  //     const backgroundOpt = await API.graphql({
  //       query: qGetBackgroundsByBriefId,
  //       variables: {
  //         id: id,
  //         nextToken: nextToken,
  //         sortOrder: "DATE_DESC",
  //         limit: limit,
  //       },
  //     });

  //     let result = backgroundOpt?.data.brief.backgrounds.items;
  //     console.log(result);
  //     return result;
  //   } catch (error) {
  //     console.error("ERROR FETCHING BACKGROUND DATA:::", error.message);
  //     window.location.reload(); // reload since at this point any background fetched in the end will have problems
  //     return null;
  //   }
  // };

  const fetchBackgroundData = async (id, nextToken, limit) => {
    try {
      console.log("Fetching background data:", id, nextToken, limit);
      const response = await API.graphql({
        query: qGetBackgroundsByBriefId,
        variables: {
          id: id,
          nextToken: nextToken,
          sortOrder: "DATE_ASC",
          limit: limit,
        },
      });

      return response.data.brief.backgrounds;
    } catch (error) {
      console.error("Error fetching background data:", error.message);
      throw error;
    }
  };

  const backgroundIsSet = useRef(false);
  const gettingBackgroundInitial = useRef(false); // So that getBackground when currentToken is null is not continuously called

  const listBriefs = `
  query getBriefsByClientMatter($id: ID, $limit: Int, $nextToken: String) {
    clientMatter(id: $id) {
      briefs(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          labelId
        }
      }
    }
  }
  `;

  //redirecthere KJMF
  const getBackground = async (
    from,
    currentToken = null,
    fetchedIds = new Set(),
    retryCount = 0
  ) => {
    console.log("Getting background");

    if (!gettingBackgroundInitial.current || currentToken != null) {
      gettingBackgroundInitial.current = true;
      try {
        setWait(true);

        const limit = 50;
        const backgroundOpt = await fetchBackgroundData(
          brief_id,
          currentToken,
          limit
        );

        if (backgroundOpt) {
          console.log("Background response:", backgroundOpt);

          const newItems = backgroundOpt.items.filter((item) => {
            if (!fetchedIds.has(item.id)) {
              fetchedIds.add(item.id);
              return true;
            }
            return false;
          });

          const finalItems = newItems
            .sort((a, b) => a.order - b.order)
            .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

          console.log("Background items:", finalItems);

          setBackground(sortByOrder2(finalItems));
          setFilterBackground(sortByOrder2(finalItems));

          updateBackgroundOrders(
            finalItems.map(({ id }, index) => ({ id, order: index + 1 }))
          );

          //INSERT CODE KJMF

          const params = {
            query: listBriefs,
            variables: {
              id: matter_id,
              limit: 100,
              nextToken: null,
            },
          };

          await API.graphql(params).then((brief) => {
            var bl = brief?.data?.clientMatter?.briefs?.items;

            var bgPaymentList = []; //list of row ids with payments

            bl.map((x) =>
              x?.name[0] === "_" ? (bgPaymentList = [...bgPaymentList, x]) : x
            ); //brief id generated. also the row id

            console.log("List of briefs", bgPaymentList);

            var totalCostList = [];

            // Define a function to compute sumBudgetAmount and sumCostAmount for a single item
            let sumBudgetAmount = 0;
            let sumCostAmount = 0;
            let sumBreakdownAmount = 0;

            if (bgPaymentList?.length > 0) {
              console.log("with payments");
              bgPaymentList.map(async (x, idx) => {
                var toBeStored = x.name.substring(1);
                var rowId = x.id;

                await API.graphql({
                  query: qGetBackgroundsByBriefId,
                  variables: {
                    id: x.id,
                    nextToken: null,
                    sortOrder: "ORDER_ASC",
                    limit: null,
                  },
                }).then((result) => {
                  console.log("check all", idx, result);
                  var contents = result?.data?.brief?.backgrounds?.items;
                  var contentTotalCost = 0;

                  console.log("contents", contents);
                  contents.map((x) => {
                    if (x.dynamicData !== null) {
                      var parsedDD = JSON.parse(x.dynamicData);

                      var idxcounter = 0;

                      while (parsedDD[idxcounter] === undefined) {
                        console.log("parseddd", parsedDD[idxcounter]);
                        idxcounter++;

                        if (idxcounter > 20) {
                          break;
                        }
                      }

                      contentTotalCost =
                        contentTotalCost + parseFloat(parsedDD[idxcounter]); //total cost per background generated from add payment
                    }
                  });

                  totalCostList = [
                    ...totalCostList,
                    { id: toBeStored, totalCost: contentTotalCost },
                  ];

                  finalItems.map((x, idx) => {
                    if (x.id === toBeStored) {
                      finalItems[idx] = {
                        ...finalItems[idx],
                        totalCost: contentTotalCost,
                      };
                    }
                  });

                  setBackground(sortByOrder2(finalItems));
                  setFilterBackground(sortByOrder2(finalItems));
                });
              });
            } else {
              console.log("without payments");
              setBackground(sortByOrder2(finalItems));
              setFilterBackground(sortByOrder2(finalItems));
            }

            API.graphql({
              query: BACKGROUND_TABLE_QUERY,
              variables: {
                clientMatterId: matter_id,
              },
            })
              .then((result) => {
                finalItems.forEach((item) => {
                  // Parse dynamicData string into an object
                  const dynamicDataObject = JSON.parse(item.dynamicData);

                  sumBreakdownAmount +=
                    item && typeof item.totalCost === "number"
                      ? parseFloat(item.totalCost)
                      : 0;

                  var accessorKeyProv = 0;
                  var accessorKeyCost = 0;

                  let provisionalAmountColumn =
                    result?.data?.backgroundTable?.columns?.find(
                      (elem) => elem.headerText === "Provisional Amount"
                    );

                  let costAmountColumn =
                    result?.data?.backgroundTable?.columns?.find(
                      (elem) => elem.headerText === "Cost Amount"
                    );

                  accessorKeyProv = provisionalAmountColumn?.accessorKey;
                  accessorKeyCost = costAmountColumn?.accessorKey;
                  // console.log("LoggingKey", dynamicDataObject);

                  for (const key in dynamicDataObject) {
                    if (dynamicDataObject.hasOwnProperty(key)) {
                      const value = parseFloat(dynamicDataObject[key]);
                      if (!isNaN(value)) {
                        if (key === accessorKeyProv) {
                          sumBudgetAmount += value;
                        } else if (key === accessorKeyCost) {
                          sumCostAmount += value;
                        }
                      }
                    }
                  }

                  // console.log("Total Budget Amount:", sumBudgetAmount);
                  // console.log("Total Cost Amount:", sumCostAmount);
                  // console.log("Total Breakdown Amount:", sumBreakdownAmount);

                  // setTotalBudgetAmount(sumBudgetAmount);
                  // setTotalCostAmount(sumCostAmount);
                });

                console.log("Total Budget Amount:", sumBudgetAmount);
                console.log("Total Cost Amount:", sumCostAmount);
                console.log("Total Breakdown Amount:", sumBreakdownAmount);

                setTotalBudgetAmount(sumBudgetAmount);
                setTotalCostAmount(sumCostAmount);
              })
              .catch((error) => {
                console.log("Error:", error);
              });

            // Iterate over the keys of dynamicDataObject
            // for (const key in dynamicDataObject) {
            //       if (dynamicDataObject.hasOwnProperty(key)) {
            //         const value = parseFloat(dynamicDataObject[key]);
            //         if (!isNaN(value)) {
            //           if (key === '11') {
            //             sumBudgetAmount += value;
            //           } else if (key === '12') {
            //             // sumBudgetAmount += value;
            //             sumCostAmount += value;
            //         }
            //       }
            //     }
            //   }
          });
          //END

          if (
            backgroundOpt.nextToken &&
            backgroundOpt.nextToken !== currentToken
          ) {
            await getBackground(from, backgroundOpt.nextToken, fetchedIds);
          } else {
            if (!backgroundIsSet.current) {
              backgroundIsSet.current = true;
              setWait(false);
              setBgLoading(false);
              // setTimeout(() => autoAdjustAllRow(background), 3000);
              setMaxLoading(false);
              backgroundIsSet.current = false;
              gettingBackgroundInitial.current = false;
            }
          }
        } else {
          setTimeout(() => {
            if (!bgLoading) {
              console.error("Background data not found");
              setBgLoading(false);
              setMaxLoading(false);
            }
          }, 10000);
        }
      } catch (error) {
        console.error("Error fetching background data:", error.message);

        if (retryCount < 10) {
          console.log("Retrying fetch...", retryCount);
          await getBackground(from, currentToken, fetchedIds, retryCount + 1);
        } else {
          console.error("Max retry count reached. Cannot fetch data.");
          setBgLoading(false);
          setMaxLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setTableColumnList(activeColumns);
  }, [activeColumns]);

  // Optional: If you want to log the updated values
  useEffect(() => {
    if (columnCount === 4) {
      setBaseTablePadding("p-4 pl-12 pr-12");
    } else if (columnCount === 3) {
      setBaseTablePadding("p-4 pl-24 pr-24");
    } else if (columnCount === 2) {
      setBaseTablePadding("p-4 pl-48 pr-48");
    } else if (columnCount === 1) {
      setBaseTablePadding("p-4 pl-64 pr-64");
    }
  }, [activeColumns, tableColumnList]);

  const getBriefs = async () => {
    const params = {
      query: qGetBriefsByClientMatter,
      variables: {
        id: matter_id,
        limit: 50,
        nextToken: null,
      },
    };

    await API.graphql(params).then((brief) => {
      setBriefs(brief.data.clientMatter.briefs.items);
      const matterFilesList = brief.data.clientMatter.briefs.items;
      var getCommentStatus = matterFilesList.filter(function (x) {
        return x.id === brief_id;
      });

      setCommentAccess(
        getCommentStatus[0]?.hasCommentAccess === null
          ? false
          : getCommentStatus[0]?.hasCommentAccess
      );

      matterFilesList.map((x) => {
        if (x.id === brief_id) {
          let briefName = x.name;
          let originalString = briefName?.replace(/(<([^>]+)>)/gi, "");
          let final = originalString?.replace(/\&nbsp;/g, " ");
          setBGName(final);
        }
      });
    });
  };

  useEffect(() => {
    if (bgName) {
      const response = API.graphql({
        query: qGetClientMatterById,
        variables: {
          id: matter_id,
        },
      }).then((response) => {
        if (response?.data?.clientMatter?.client?.name === "Default") {
          if (bgName === "Site Diary" && hbaDomains.includes(currUrl)) {
            setIsSiteDiary(true);
          }
        }
      });
    }
  }, [bgName]);

  const loadMoreBackground = async () => {
    // console.log("LOAD MORE");
    // if (brief_id === "000") {
    //   // Remove this condition after migration
    //   if (vNextToken !== null && !loading) {
    //     setLoading(true);
    //     let result = [];
    //     const backgroundOpt = await API.graphql({
    //       query: qListBackground,
    //       variables: {
    //         id: matter_id,
    //         limit: 50,
    //         nextToken: vNextToken,
    //         sortOrder: "ORDER_ASC",
    //       },
    //     });
    //     setVnextToken(backgroundOpt.data.clientMatter.backgrounds.nextToken);
    //     if (backgroundOpt.data.clientMatter.backgrounds.items !== null) {
    //       result = backgroundOpt.data.clientMatter.backgrounds.items.map(
    //         ({
    //           id,
    //           description,
    //           date,
    //           createdAt,
    //           order,
    //           files,
    //           dynamicData,
    //           tabFiles,
    //         }) => ({
    //           createdAt: createdAt,
    //           id: id,
    //           description: description,
    //           date: date,
    //           order: order,
    //           files: files,
    //           dynamicData: dynamicData,
    //           tabFiles: tabFiles,
    //         })
    //       );
    //       if (background !== "") {
    //         setTimeout(() => {
    //           setLoading(false);
    //           setMaxLoading(false);
    //           let arrConcat = background.concat(result);
    //           console.log("BACKGROUND IS:", arrConcat);
    //           setBackground([...new Set(sortByOrder(arrConcat))]);
    //           setPreviousBackground([...new Set(sortByOrder(arrConcat))]);
    //           // setBackground(result);
    //         }, 1000);
    //       }
    //     }
    //   } else {
    //     setMaxLoading(true);
    //   }
    // } else {
    //   if (vNextToken !== null && !loading) {
    //     setLoading(true);
    //     let result = [];
    //     const backgroundOpt = await API.graphql({
    //       query: qGetBackgroundsByBriefId,
    //       variables: {
    //         id: brief_id,
    //         limit: 50,
    //         nextToken: vNextToken,
    //         sortOrder: "ORDER_ASC",
    //       },
    //     });
    //     var arrConcatPrevToken = vPrevToken.concat(vNextToken);
    //     setVprevToken([...new Set(arrConcatPrevToken)]);
    //     var nextToken = backgroundOpt.data.brief.backgrounds.nextToken;
    //     setVnextToken(nextToken);
    //     if (backgroundOpt.data.brief.backgrounds.items !== null) {
    //       result = backgroundOpt.data.brief.backgrounds.items.map(
    //         ({
    //           id,
    //           description,
    //           date,
    //           createdAt,
    //           order,
    //           files,
    //           dynamicData,
    //           tabFiles,
    //         }) => ({
    //           createdAt: createdAt,
    //           id: id,
    //           description: description,
    //           date: date,
    //           order: order,
    //           files: files,
    //           dynamicData: dynamicData,
    //           tabFiles: tabFiles,
    //         })
    //       );
    //       if (background !== "") {
    //         setLoading(false);
    //         setMaxLoading(false);
    //         var arrConcat = background.concat(result);
    //         if (searchDescription !== "") {
    //           arrConcat = background
    //             .concat(result)
    //             .filter((x) =>
    //               x.description
    //                 .toLowerCase()
    //                 .includes(searchDescription.toLowerCase())
    //             );
    //         }
    //         setBackground([...new Set(sortByOrder(arrConcat))]);
    //         setPreviousBackground([...new Set(sortByOrder(arrConcat))]);
    //         //setBackground(result);
    //       }
    //     }
    //   } else {
    //     setMaxLoading(true);
    //   }
    // }
    // setTimeout(() => {
    //   autoAdjustAllRow();
    // }, 1000);
  };

  // const loadPrevBackground = async () => {
  //   if (vPrevToken.length > 1 && !loading) {
  //     setLoading(true);
  //     let result = [];

  //     const lastTokenFrom = vPrevToken.slice(-1);

  //     const updatedToken = vPrevToken.filter(
  //       (x) => !new Set(lastTokenFrom).has(x)
  //     );

  //     const lastTokenQuery = updatedToken.slice(-1);

  //     const backgroundOpt = await API.graphql({
  //       query: qGetBackgroundsByBriefId,
  //       variables: {
  //         id: brief_id,
  //         limit: 50,
  //         nextToken: lastTokenQuery[0],
  //         sortOrder: "ORDER_ASC",
  //       },
  //     });

  //     setVprevToken(updatedToken);
  //     setVnextToken(backgroundOpt.data.brief.backgrounds.nextToken);

  //     if (backgroundOpt.data.brief.backgrounds.items !== null) {
  //       result = backgroundOpt.data.brief.backgrounds.items.map(
  //         ({ id, description, date, createdAt, order, files }) => ({
  //           createdAt: createdAt,
  //           id: id,
  //           description: description,
  //           date: date,
  //           order: order,
  //           files: files,
  //         })
  //       );

  //       if (background !== "") {
  //         setLoading(false);
  //         setMaxLoading(false);

  //         var arrConcat = background.concat(result);

  //         if (searchDescription !== "") {
  //           // console.log("searchDescription", searchDescription);

  //           arrConcat = background
  //             .concat(result)
  //             .filter((x) =>
  //               x.description
  //                 .toLowerCase()
  //                 .includes(searchDescription.toLowerCase())
  //             );
  //         }

  //         setBackground(result);
  //       }
  //     }
  //   } else {
  //     // console.log("Last Result!- NEW");
  //     setMaxLoading(true);
  //   }
  // };

  ///////////////////////////////////////////////////////////////////////////
  // Comment or uncomment the code block below AND the useIdleTimer import statement to toggle session timeout

  // var timeoutId, timeoutNotif;

  // const handleOnAction = (event) => {
  //   if (width < 844) {
  //     loadMoreBackground();
  //   }

  //   //function for detecting if user moved/clicked.
  //   //if modal is active and user moved, automatic logout (session expired)
  //   //check.current = false;
  //   if (showSessionTimeout) {
  //     setTimeout(() => {
  //       Auth.signOut().then(() => {
  //         clearLocalStorage();
  //         history.push("/");
  //       });

  //       function clearLocalStorage() {
  //         localStorage.removeItem("userId");
  //         localStorage.removeItem("email");
  //         localStorage.removeItem("firstName");
  //         localStorage.removeItem("lastName");
  //         localStorage.removeItem("userType");
  //         localStorage.removeItem("company");
  //         localStorage.removeItem("companyId");
  //       }
  //     }, 3000);
  //   }

  //   clearTimeout(timeoutId);
  //   clearTimeout(timeoutNotif);
  // };

  // const handleOnIdle = (event) => {
  //   // CHANGED FROM (WIDTH < 844)
  //   if (isMobileDevice(width)) {
  //     loadMoreBackground();
  //   }

  //   timeoutId = setTimeout(() => {
  //     setShowSessionTimeout(true);
  //   }, 60000 * 120); //2 hours before session timeout
  // };

  // useIdleTimer({
  //   timeout: 60 * 40,
  //   onAction: handleOnAction,
  //   onIdle: handleOnIdle,
  //   debounce: 1000,
  // });
  // const [backgroundAsc,setBackgroundAsc] = useState();
  // const [backgroundDesc,setBackgroundDesc] = useState();
  // const [backgroundDuplicate,setBackgroundDuplicate] = useState();
  useEffect(() => {
    if (backgroundAsc === undefined && backgroundDesc === undefined) {
      try {
        const fetchSortedBackground = async () => {
          const backgroundAscending = await API.graphql({
            query: qGetBackgroundsByBriefId,
            variables: {
              id: brief_id,
              nextToken: null,
              sortOrder: "DATE_ASC",
            },
          });

          const backgroundDescending = await API.graphql({
            query: qGetBackgroundsByBriefId,
            variables: {
              id: brief_id,
              nextToken: null,
              sortOrder: "DATE_DESC",
            },
          });

          const backgroundOpt = await API.graphql({
            query: qGetBackgroundsByBriefId,
            variables: {
              id: brief_id,
              nextToken: isMobileDevice(width) ? vNextToken : null,
              sortOrder: "ORDER_ASC",
              limit: isMobileDevice(width) ? 50 : null,
            },
          }).catch((error) => {
            console.log("ERROR FETCHING BACKGROUND DATA: RESTART PAGE", error);
          });

          let resultAsc;
          if (backgroundAscending?.data.brief.backgrounds.items !== null) {
            resultAsc = backgroundAscending?.data.brief.backgrounds.items;
            setBackgroundAsc(resultAsc);
          }

          let resultDesc;
          if (backgroundDescending?.data.brief.backgrounds.items !== null) {
            resultDesc = backgroundDescending?.data.brief.backgrounds.items;
            setBackgroundDesc(resultDesc);
          }
        };

        fetchSortedBackground();
      } catch (error) {
        console.error(error);
      }
    }
  }, [backgroundAsc, backgroundDesc, ascDesc]);

  const SortBydate = async () => {
    console.group("SortBydate()");
    let result = [];
    setWait(false); // trigger loading ...
    setBgLoading(true);

    if (ascDesc == null) {
      // Ascending
      // sortbydate
      setAscDesc(true);
      localStorage.setItem("dateAscDesc", true);
      console.log(
        "Sort by Asc",
        background.sort((a, b) => new Date(a.date) - new Date(b.date))
      );
      setBackground([]);

      setTimeout(() => {
        setBackground(
          background.sort((a, b) => new Date(a.date) - new Date(b.date))
        );
        setBgLoading(false);
      }, 100);
      setWait(true);
    } else if (ascDesc === true) {
      setAscDesc(false);
      localStorage.setItem("dateAscDesc", false);
      // Descending
      // sortbydate
      console.log(
        "Desc",
        background.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
      setBackground([]);
      setTimeout(() => {
        setBackground(
          background.sort((a, b) => new Date(b.date) - new Date(a.date))
        );
        setBgLoading(false);
      }, 100);
      setWait(true);
    } else if (!ascDesc) {
      // console.log("CALLED GETBACKGROUD IN SORTBYDATE");
      setAscDesc(null); // default to sort by order
      localStorage.setItem("dateAscDesc", null);
      //getBackground("Sort By Date");
      // sort by order
      console.log("null", sortByOrder(background));
      setBackground([]);
      setTimeout(() => {
        setBackground(sortByOrder(background));
        setBgLoading(false);
      }, 100);
      setWait(true);
    }
    //Overlapping files fix //added MMA-1019

    setTimeout(() => {
      autoAdjustAllRow();
    }, 400);

    console.groupEnd();
  };

  function sortByOrder(arr) {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }

    return sort;
  }

  const handleShowHideManagePrompts = () => {
    if (showHideManagePromptsState) {
      setShowHideManagePromptsState(false);
    } else {
      setShowHideManagePromptsState(true);
    }
  };

  const handleShowHideContextualMemory = () => {
    if (showHideContextualMemoryState) {
      setShowHideContextualMemoryState(false);
    } else {
      setShowHideContextualMemoryState(true);
    }
  };
  useState(false);
  const handleManageFiles = () => {
    setPastedRows([]);
    setCheckedRows([]);
    setSelectedRowsBGFiles([]);
    setActivateButton(!activateButton);

    //if ending manage files process
    if (activateButton) {
      console.log("TRIGGER BUTTON");
      // getBriefs();
      // fetchTable();
      getBackground();

      // window.location.reload();
    }
  };

  let pageSizeConst = pageSize >= pageTotal ? pageTotal : pageSize;

  const getPaginateItems = async (action) => {
    let pageList = 20;

    if (action === "next") {
      setPageIndex(pageIndex + pageList);
      setPageSize(pageSize + pageList);
    } else if (action === "prev") {
      setPageIndex(pageIndex - pageList);
      setPageSize(pageSize - pageList);
    }
  };

  function getParameterByName(name, url = window.location.href) {
    name = name?.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2]?.replace(/\+/g, " "));
  }

  const handleMouseOver = (event) => {
    setIsHovering(true);
    setIsHoveringId(event.target.dataset.info);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setIsHoveringId(null);
  };

  function b64_to_utf8(str) {
    try {
      // Convert base64 to percent-encoded string
      const percentEncodedStr = atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("");

      // Decode percent-encoded string to utf-8
      return decodeURIComponent(percentEncodedStr);
    } catch (e) {
      console.error("Error decoding base64 string", e);
      return null;
    }
  }

  const m_name = getQueryVariable("matter_name");
  const c_name = getQueryVariable("client_name");
  const matter_name = b64_to_utf8(m_name);
  const client_name = b64_to_utf8(c_name);
  const isUrl = getParameterByName("url");

  function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str));
  }

  const handleNameContent = (e, name, id) => {
    if (!validationAlert) {
      setBriefName(!name ? "" : name);
      setBriefId(id);
      setValidationAlert("");
    } else {
      setBriefName("");
    }
  };

  //Updating brief name

  const handleOnChangeBiefName = (e) => {
    setBriefName(e.currentTarget.textContent);
  };

  const handleSaveBriefName = (e, name, id) => {
    const originalString = briefName?.replace(/(<([^>]+)>)/gi, "");
    const final = originalString?.replace(/\&nbsp;/g, " ");

    if (briefName.length <= 0) {
      setValidationAlert("Brief Name is required");
      setBGName(bgName);
    } else if (briefName === name) {
      setValidationAlert("");
      const data = {
        id,
        name: e.target.innerHTML,
      };
      updateBriefName(data);
      setBGName(final);

      setalertMessage(`Successfully updated Background title`);
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
        setalertMessage("");
      }, 1000);
    } else {
      setValidationAlert("");
      const data = {
        id,
        name: e.target.innerHTML,
      };
      updateBriefName(data);
      setBGName(final);

      setalertMessage(`Successfully updated Background title`);
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
        setalertMessage("");
      }, 1000);
    }
  };

  async function updateBriefName(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBriefName,
          variables: {
            id: data.id,
            name: data.name,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const autoAdjustAllRow = async () => {
    // try {
    //   if (bindList && cache) {
    //     cache?.current.clearAll();
    //     //while loop is recommended fastest loop
    //     var i = 0,
    //       len = background.length;
    //     var duration = 100;
    //     while (i < len) {
    //       setTimeout(() => {
    //         bindList?.current?.recomputeRowHeights(0); // Previous code: The argument went from len to 0
    //       }, duration); // Previous code: duration = 100, with no increments
    //       duration += 50;
    //       i++;
    //     }
    //   }
    // } catch (e) {
    //   // console.log(e);
    // }
  };

  useEffect(() => {
    if (searchDescription !== undefined) {
      // console.log("Search Description Null: " + searchDescription === null);
      // console.log("USEEFFECT CALLING FILTER RECORD");
      filterRecord(searchDescription);
      // console.log("SEARCH DESCRIPTION" + searchDescription);
    }
  }, [searchDescription]);

  const handleSearchDescriptionChange = (e) => {
    // handleSave();
    setSearchDescription(e?.target?.value);

    //setBackground(previousBackground);
  };

  const filterRecord = (v) => {
    console.log("Filter Record");

    if (v === "") {
      // Refresh page if necessary
      // console.log("FILTER RECORDDDDDDDD");
      //setVnextToken(null);
      getBackground();
      // console.log(background.length);
      // if (ascDesc === true && backgroundAsc != undefined) {
      //   setBackground(backgroundAsc);
      // } else if (ascDesc === false && backgroundDesc != undefined) {
      //   setBackground(backgroundDesc);
      // } else if (ascDesc === null && backgroundDuplicate != undefined) {
      //   setBackground(backgroundDuplicate);
      // }
      // setBackground(previousBackground);
      setTimeout(() => {
        autoAdjustAllRow(background);
      }, 1);
    } else {
      //Temporary fix to search by using previousBackground as the original data source
      //Change/remove the setPreviousBackground() in table-info, action-button, and this index if planning to rewrite the filter/search
      //Filter background
      // const filterRecord = previousBackground.filter((x) => {
      //   let boolean = false;

      //   //Filter description
      //   boolean =
      //     boolean || x.description?.toLowerCase().includes(v.toLowerCase());

      //   //Filter files
      //   x.files.items.map((y) => {
      //     boolean = boolean || y.name?.toLowerCase().includes(v.toLowerCase());
      //   });

      //   // Filter Date
      //   let date = moment(new Date(x.date)).format("DD MMM YYYY");
      //   boolean = boolean || date.toLowerCase().includes(v.toLowerCase());

      //   return boolean;
      // });
      const uniqueIds = new Set();
      const filterRecord = background.filter((x, index, self) => {
        let boolean = false;

        // Filter description
        boolean =
          boolean || x.description?.toLowerCase().includes(v.toLowerCase());

        // Filter files
        x.files.items.map((y) => {
          boolean = boolean || y.name?.toLowerCase().includes(v.toLowerCase());
        });

        // Filter Date
        let date = moment(new Date(x.date)).format("DD MMM YYYY");
        boolean = boolean || date.toLowerCase().includes(v.toLowerCase());

        // Check if the current element (x) is unique in the array
        if (!uniqueIds.has(x.id)) {
          uniqueIds.add(x.id); // Add the id to the Set to mark it as seen
          return boolean; // Return true if the element should be included
        }

        return false;

        // console.log(x.id);
        // return boolean ; // Return true if the element should be included
      });
      setBackground(filterRecord);
      setTimeout(() => {
        autoAdjustAllRow(filterRecord);
      }, 1);
    }
  };

  // const bitly = new BitlyClient("e1540f03fd3f2318262342ac1a0d144e5407f7be", {});
  /* To be used when tinyurl is required
  const convertUrl = async () => {
    let result;
    try {
      result = await bitly.shorten('https://develop.d3bhf42tem9b8e.amplifyapp.com/background/407e3619-cb0d-4bee-9f63-1258c0e00686/4dd99da7-f8c3-4fdd-8af5-3d2237180e9e/?matter_name=TWF0dGVyIDE=&client_name=Q2xpZW50IDE=');
    } catch (e) {
      throw e;
    }
    setShareLink(result.url);
  }
  */

  const handleSnippetEmail = async (file) => {
    setSelectedFile(file);
    setShow(true);
    if (file.isGmailPDF) {
      setIsGmailPDF(true);
      // setSnippetId(gmailMessageId);
      const params = {
        query: EMAIL_PREVIEW,
        variables: {
          id: file.gmailMessageId,
        },
      };

      await API.graphql(params).then((result) => {
        console.log("ineed2", result);
        let newPayload = { payload: [{ content: "" }] };

        result?.data?.gmailMessage?.payload?.forEach((data, index) => {
          newPayload = {
            payload: [
              {
                content:
                  newPayload.payload[0].content +
                  result?.data?.gmailMessage?.payload[index]?.content,
              },
            ],
          };
        });
        const parsedEmail = EmailParser(
          JSON.parse(newPayload.payload[0].content)
        );

        setPreview(parsedEmail.html);
        setFrom(result.data.gmailMessage?.from);
        setDateEmail(result.data.gmailMessage?.date);
        setSubject(result.data.gmailMessage?.subject);
        setTo(result.data.gmailMessage?.to);
        setCC(result.data.gmailMessage?.cc);
      });
    } else {
      setIsGmailPDF(false);
      const params = {
        query: qGetFileDownloadLink,
        variables: {
          id: file.id,
        },
      };

      await API.graphql(params).then(async (result) => {
        console.log("ineed1", result);

        const { type, downloadURL, s3ObjectKey } = result.data.file;

        if (
          (type &&
            (type.includes("vnd.openxmlformats-officedocument") ||
              type.includes("application/msword"))) ||
          isMSdoc(s3ObjectKey)
        ) {
          var encodedUrl = encodeURIComponent(downloadURL);
          var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
          setDownloadLink(documentViewerUrl);
        } else if (type.includes("image") || type.includes("pdf")) {
          setShow(true);
          setDownloadLink(downloadURL);
        } else {
          setShow(false);
          setDownloadLink(downloadURL);
          setUnviewableFileLink(downloadURL);
          setShowUnviewable(true);
        }
      });
    }
  };

  const handleClose = () => {
    setShow(false);
    setDownloadLink("");
    setSubject(null);
  };

  const previewAndDownloadFile = async (id) => {
    // has PDF already
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const { type, downloadURL, s3ObjectKey } = result.data.file;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const getEmailPayload = async (messageId) => {
    // console.log("getEmailPayload()");
    const params = {
      query: qGetMessagePayload,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);
    if (result) {
      let newPayload = { payload: [{ content: "" }] };
      // console.log("getEmailPayload()", result);

      result?.data?.gmailMessage.payload?.forEach((data, index) => {
        newPayload = {
          payload: [
            {
              content:
                newPayload.payload[0].content +
                result?.data?.gmailMessage?.payload[index]?.content,
            },
          ],
        };
      });
      return newPayload;
    }
  };

  const previewAndDownloadPDF = async (id, gmailMessageId, subjectTitle) => {
    // For PDF creation

    Storage.configure({
      region: config.aws_user_files_s3_bucket_region,
      bucket: config.aws_user_gmail_attachments_s3_bucket,
      identityPoolId: config.aws_user_pools_id,
    });

    var opt = {
      margin: [30, 50, 30, 50],
      filename: subjectTitle,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        dpi: 96,
        scale: 1,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: "#FFF",
      },
      jsPDF: { unit: "pt", format: "a4", orientation: "p" },
      pagebreak: {
        before: ".page-break",
        mode: ["avoid-all", "css", "legacy"],
      },
    };

    var content = `<span>`;
    content += `<img src=${googleLogin} alt="" /><hr></hr>`;
    content += `<h2><b>${subjectTitle}</b></h2><hr></hr><br />`;
    content += `<p>From: ${from}</p>`;
    content += `<p>Date: ${moment(dateEmail).format(
      "DD MMM YYYY, hh:mm A"
    )}</p>`;
    content += `<p>To: ${to}</p>`;
    content += cc ? `<p>CC: ${cc}</p>` : "";
    content += `</span>`;

    const emailPayload = await getEmailPayload(gmailMessageId);

    if (emailPayload) {
      const parsedEmail = EmailParser(
        JSON.parse(emailPayload.payload[0].content)
      );
      const htmlContent = parsedEmail.html;
      // console.log("Original Content - ", emailPayload);
      // const htmlContent = emailPayload.payload
      //   .map((em) => em.content)
      //   .join("")
      //   .split('data":"')
      //   .pop()
      //   .split('"}')[0];

      content += htmlContent;
      // content += Base64.decode(htmlContent).replace("body{color:", "");

      await html2pdf()
        .from(content)
        .set(opt)
        .toPdf()
        .output("datauristring")
        .then(function (pdfAsString) {
          const preBlob = dataURItoBlob(pdfAsString);
          const file = new File([preBlob], subjectTitle, {
            type: "application/pdf",
          });
          // console.log(file);

          var key = `${gmailMessageId}/${Number(new Date())}${file.name
              ?.replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            type = "application/pdf",
            size = file.size;

          // put objects to s3
          try {
            Storage.put(key, file, {
              contentType: type,
              progressCallback(progress) {
                // console.log(progress);
              },
              errorCallback: (err) => {
                console.error("204: Unexpected error while uploading", err);
              },
            })
              .then((fd) => {
                const mUpdateMatterFileObjectKey = `
                    mutation updateMatterFile ($id: ID, $s3ObjectKey: String, $size: Int, $type: String) {
                      matterFileUpdate(id: $id, s3ObjectKey: $s3ObjectKey, size: $size, type: $type) {
                        id
                      }
                    }
                `;

                const params = {
                  query: mUpdateMatterFileObjectKey,
                  variables: {
                    id: id,
                    s3ObjectKey: fd.key,
                    size: parseInt(size),
                    type: type,
                  },
                };

                API.graphql(params).then(async (res) => {
                  // console.log("Get Details", res);
                  const params = {
                    query: qGetFileDownloadLink,
                    variables: {
                      id: id,
                    },
                  };
                  await API.graphql(params).then(async (result) => {
                    const { type, downloadURL, s3ObjectKey } = result.data.file;
                    window.open(downloadURL);
                  });
                });
              })
              .catch((err) => {
                console.error("Unexpected error while uploading", err);
              });
          } catch (e) {
            const response = {
              error: e.message,
              errorStack: e.stack,
              statusCode: 500,
            };
            console.error("Unexpected error while uploading", response);
          }
        });
    }
  };

  const featureAccessFilters = async () => {
    const { data, status, message } = await AccessControl("BACKGROUND");
    const backgroundFeatures = data?.features;
    if (status !== "restrict") {
      console.log("backgroundFeatures", backgroundFeatures);
      await Promise.all([
        setAllowEditDate(backgroundFeatures.includes("EDITDATE")),
        setAllowEditDescription(backgroundFeatures.includes("EDITDESCRIPTION")),
        setAllowEditComments(backgroundFeatures.includes("EDITCOMMENTS")),
        setAllowEditLabels(backgroundFeatures.includes("EDITLABELS")),
        setAllowEditFiles(backgroundFeatures.includes("EDITFILES")),
        setAllowDeleteRows(backgroundFeatures.includes("DELETEROWS")),
        setAllowAddRows(backgroundFeatures.includes("ADDROWS")),
        setAllowShowLabels(backgroundFeatures.includes("SHOWLABELS")),
        setAllowDragNDrop(backgroundFeatures.includes("DRAGNDROPROWS")),
        setAllowDeleteFiles(backgroundFeatures.includes("DELETEFILES")),
        setAllowManageFiles(backgroundFeatures.includes("MANAGEFILES")),
        setAllowManagePrompts(backgroundFeatures.includes("MANAGEPROMPTS")),
        setAllowSummarize(backgroundFeatures.includes("SUMMARISEFILE")),
        setAllowAddParagraph(backgroundFeatures.includes("ADDPARAGRAPH")),
        setAllowDownload(backgroundFeatures.includes("DOWNLOAD")),
        setAllowCreateDocument(backgroundFeatures.includes("CREATEDOCUMENT")),
      ]);
    } else {
      // console.log(message);
    }
  };

  const checkFormat = (str) => {
    var check = str;
    check = check?.replace("%20", " "); //returns my_name
    return check;
  };

  const formatModular = (str, numberOfLetters) => {
    var check = str || "";
    check = check.split(" ");

    var shortenedStr = check.slice(0, 5);
    shortenedStr = shortenedStr.join(" ");

    if (shortenedStr.length > numberOfLetters) {
      return shortenedStr.substring(0, numberOfLetters) + "..";
    }

    if (check.length > shortenedStr.split(" ")) {
      return shortenedStr + "..";
    }

    return shortenedStr;
  };

  const style = {
    paddingLeft: "0rem",
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  /* MOBILE CONST */
  const [contentHeight, setContentHeight] = useState();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isAllFilesSelectedButton, setIsAllFilesSelectedButton] =
    useState(true);
  const { height, width } = useWindowDimensions();
  const [readMoreState, setReadMoreState] = useState([]);

  function countLines(tag) {
    var divHeight = tag.offsetHeight;
    var lineHeight = parseInt(
      window.getComputedStyle(tag).getPropertyValue("line-height")
    );
    var lines = Math.round(divHeight / lineHeight);
    return lines;
  }

  const cacheM = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 30,
      minHeight: 30,
    })
  );

  const bindlistM = useRef();

  useEffect(() => {
    console.log("readmorestate:", readMoreState);
    try {
      if (background != null) {
        //!isMobileDevice(width)
        background?.map((data) => {
          var descTag = document.getElementById(data.id + ".desc");
          var fileTag = document.getElementById(data.id + ".files");
          if (descTag !== null) {
            var descLines = countLines(descTag);
            var fileLines = countLines(fileTag);
            var descButtonTag = document.getElementById(
              data.id + ".descButton"
            );
            // if ((descLines > 6 || fileLines > 1) && descButtonTag.style) {
            //   descButtonTag.style.display = "inline-block";
            // } else {
            //   descButtonTag.style.display = "none";
            // }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [background, readMoreState, width, textDesc]);

  function handleScrollEvent(e) {
    const top = e.target.scrollTop > 20;
    if (top) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }
  function handleScrollToTop() {
    let d = document.getElementById("mobileContent");
    d.scrollTo(0, 0);
  }

  function handleReadMoreState(fileId) {
    console.log("handleReadMoreState");
    if (
      readMoreState.find((temp) => {
        return temp === fileId;
      }) === undefined
    ) {
      console.log("addReadMore");
      setReadMoreState([...readMoreState, fileId]);
    } else {
      setReadMoreState((current) =>
        current.filter((id) => {
          return id !== fileId;
        })
      );
    }
  }

  function isReadMoreExpanded(fileId) {
    return (
      readMoreState.find((temp) => {
        return temp === fileId;
      }) !== undefined
    );
  }

  const sortByOrder2 = (arr) => {
    let sort;

    if (arr) {
      sort = arr.sort((a, b) =>
        a.order === null || b.order === null
          ? a
          : a.order - b.order === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : a.order - b.order
      );
    } else {
      sort = arr;
    }

    let finalItems = sort.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );

    return finalItems;
  };

  const [headerHeight, setHeaderHeight] = useState(0);
  const [actionButtonsPos, setActionButtonsPos] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const headerRef = useRef(null);
  const actionButtonsRef = useRef(null);
  const currLabelCellSelected = useRef(0);
  const currLabelCellSelected2 = useRef("out");

  // [indexOfRow, indexOfSubstring]
  const inRow = useRef([0, 0]);

  useLayoutEffect(() => {
    setHeaderHeight(
      headerRef.current.clientHeight + actionButtonsRef.current.clientHeight
    );
    setActionButtonsPos(headerRef.current.clientHeight);
    setTableHeight(
      document.documentElement.clientHeight +
        100 -
        (headerHeight + actionButtonsRef.current.clientHeight)
    );
  });

  const [showDocumentCreator, setDocumentCreator] = useState(false);

  /* TO ENABLE EDITING OF DESCRIPTION ON MOBILE */

  const handleDescContent = (e, description, id, index) => {
    const cleanDescription = handleDescription(description);
    setTextDesc(cleanDescription);
  };

  const handleChangeDesc = (event, index) => {
    setTextDesc(handleDescription(event.currentTarget.textContent));
  };

  const handleDescription = (description) => {
    if (description) {
      var parsedDescription = description?.replace(
        /style="[^\"]*"/gi,
        `className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[div]+/g,
        `<div className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[span]+/g,
        `<span className="${""}"`
      );
      parsedDescription = parsedDescription?.replace(
        /<[p ]+/g,
        `<p className="${""}"`
      );
      parsedDescription = parsedDescription.replace(
        /<[font ]+/g,
        `<p className="${""}"`
      );
      return parsedDescription;
    }
  };

  async function updateBackgroundDesc(id, data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mUpdateBackgroundDesc,
          variables: {
            id: id,
            description: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  function extractText(desc) {
    const regex = /(<([^>]+)>)/gi;
    const newString = desc?.replace(regex, "");

    return newString;
  }

  const handleSaveDesc = async (e, description, date, id, index) => {
    console.log("handleSaveDesc()");
    if (e.target !== document.activeElement) {
      const origDescription = handleDescription(description);
      let newDescription = handleDescription(e.target.innerHTML);
      newDescription = extractText(newDescription);
      newDescription = newDescription?.replace(/\&nbsp;/g, "");

      if (textDesc?.length <= 0) {
        const data = {
          description: newDescription,
        };
        const success = await updateBackgroundDesc(id, data).then(async () => {
          const params = {
            query: mCreateActivity,
            variables: {
              companyId: localStorage.getItem("companyId"),
              clientMatterId: matter_id,
              briefId: briefId,
              activity: "updated the background description",
              field: "Description",
              current: data.description,
              previous: origDescription,
              appModule: "BACKGROUND",
              rowId: id,
            },
          };
          const addActivity = await API.graphql(params).then((result) => {
            console.log("addActivity result", result);
          });
        });
        console.log("THIS IS ID", id);
      } else if (textDesc === description) {
        console.log("2nd condition");
      } else {
        console.log("3rd Condition");

        const data = {
          description: newDescription,
        };
        const success = await updateBackgroundDesc(id, data)
          .then(async (res) => {
            console.log(res);
            const params = {
              query: mCreateActivity,
              variables: {
                companyId: localStorage.getItem("companyId"),
                clientMatterId: matter_id,
                briefId: briefId,
                activity: "updated the background description",
                field: "Description",
                current: data.description,
                previous: origDescription,
                appModule: "BACKGROUND",
                rowId: id,
              },
            };
            const addActivity = await API.graphql(params).then((result) => {
              console.log("addActivity result", result);
            });
          })
          .catch((err) => {
            console.log("ERR", err);
          });
      }

      let foundIndex = background.findIndex((x) => x.id == id);
      background[foundIndex].description = e.target.innerHTML;
    }
  };

  const handleDateFilterModal = () => {
    // setStartDate(null);
    // setEndDate(null);

    if (showDateFilter === true) {
      setShowDateFilter(false);
    } else {
      setShowDateFilter(true);
    }
  };

  const handleFilterLabels = async () => {
    var filLabels = filteredLabels;
    var filteredBg = [];
    var originalBg = filterBackground;

    console.log("original", filterBackground);

    // const result = getBackground().then(()=>{
    if (filLabels.length > 0) {
      for (var i = 0; i < filterBackground.length; i++) {
        for (var j = 0; j < filterBackground[i].briefs.length; j++) {
          if (filLabels.includes(filterBackground[i].briefs[j].name)) {
            filteredBg = [...filteredBg, filterBackground[i]];
            break;
          }
        }
      }
      setBackground(filteredBg);
      setPreviousBackground(filteredBg);
      autoAdjustAllRow();
      console.log("filteredBg", filteredBg);
    } else {
      getBackground("Filter Label get background");
      autoAdjustAllRow();
    }
    // })
  };

  //Mobile Labels
  function getRowLabels(rowLabels) {
    // console.log("rowlabels", rowLabels);
    var temp = [];

    if (rowLabels) {
      if (rowLabels.length > 0) {
        rowLabels.map((x) => {
          temp = [...temp, { label: getMinimizedLabel(x.name), value: x.id }];
          //ADDED FUNCTION TO MINIMIZE LABEL
        });

        // console.log("tempp", temp);
        return temp;
      } else {
        return [];
      }
    } else {
      return [];
    }

    // }
  }

  //MINIMIZES LABEL NAME TO 22 CHARACTERS
  function getMinimizedLabel(inputLabel) {
    return inputLabel && inputLabel.length > 23
      ? inputLabel.substring(0, 22) + "..."
      : inputLabel;
  }

  function filterProper(filLabels, filteredBg) {}

  const handleClearFilter = () => {
    setEndDate(null);
    setStartDate(null);
    setBackground(previousBackground);
    setgmailFilter("all");
    setFilteredLabels([]);
    getBackground("Handle clear filter get background");
    setShowDateFilter(false);
  };

  const handleSave = async () => {
    var newBackground = [];
    console.log("bg", background);
    console.log("start", startDate);
    console.log("end", endDate);
    console.log("Labels", filteredLabels);

    // Filter out value searched

    // if (searchDescription !== "") {
    // 	const uniqueIds = new Set();
    // 	newBackground = previousBackground.filter((x) => {
    // 		let boolean = false;

    // 		// Filter description
    // 		if (x.hasOwnProperty("description")) {
    // 			boolean =
    // 				boolean ||
    // 				x.description
    // 					.toLowerCase()
    // 					.includes(searchDescription.toLowerCase());
    // 		}

    // 		// Filter files
    // 		if (x.hasOwnProperty("files")) {
    // 			x.files.items.map((y) => {
    // 				boolean =
    // 					boolean ||
    // 					y.name?.toLowerCase().includes(searchDescription.toLowerCase());
    // 			});
    // 		}

    // 		// Filter Date
    // 		let date = moment(new Date(x.date)).format("DD MMM YYYY");
    // 		boolean =
    // 			boolean ||
    // 			date.toLowerCase().includes(searchDescription.toLowerCase());

    // 		// Check if the current element (x) is unique in the array
    // 		if (!uniqueIds.has(x.id)) {
    // 			uniqueIds.add(x.id); // Add the id to the Set to mark it as seen
    // 			return boolean; // Return true if the element should be included
    // 		}

    // 		return false;

    // 		// console.log(x.id);
    // 		// return boolean ; // Return true if the element should be included
    // 	});
    // }
    if (startDate && endDate) {
      console.log("ENTERING DATE FILTER");
      var myStartDate = new Date(startDate);
      myStartDate.setDate(myStartDate.getDate() + 1);
      var myEndDate = new Date(endDate);
      myEndDate.setDate(myEndDate.getDate() + 1);
      var startD = myStartDate.toISOString().split("T")[0]; //yyyy-mm-dd format
      var endD = myEndDate.toISOString().split("T")[0];

      //iterate through original background
      for (const row of previousBackground) {
        if (row.date !== null) {
          var currDate = moment.utc(row.date).local();
          var compareDate = currDate.toISOString().split("T")[0];

          if (startD === endD) {
            if (startD === compareDate) {
              newBackground = [...newBackground, row];
            }
          } else if (startD <= compareDate && endD >= compareDate) {
            newBackground = [...newBackground, row];
          }
        }
      }
    } else {
      //used previousBackground as basis for the original background
      console.log("first NB is:", newBackground);
      newBackground = previousBackground;
    }
    console.log("second NB is:", newBackground);
    //filter out labels
    if (filteredLabels.length > 0) {
      newBackground = newBackground.filter((row) => {
        // Access Label Array If has label
        if (row.briefs.length > 0) {
          //Iterate through all selected label filters
          for (const labelFilter of filteredLabels) {
            for (const label of row.briefs) {
              if (label.name === labelFilter) {
                return true;
              }
            }
          }
          //if row does not contain any filters from labelFilter
          return false;
        } else {
          //row does not have any filters
          return false;
        }
      });
      console.log("third NB is:", newBackground);
    }

    console.log("Last NB is:", newBackground);
    newBackground.sort((a, b) => a.order - b.order);
    let finalItems = newBackground.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
    setBackground(finalItems);
    // setPreviousBackground(newBackground);
    setShowDateFilter(false);
    autoAdjustAllRow();
  };

  const toggleMenu = () => {
    localStorage.setItem("background-menu-display", (!showMenu).toString());
    setShowMenu(!showMenu);
  };

  const conditionMenuStyle = showMenu
    ? "flex items-center w-24"
    : "flex items-center w-20";

  const handleShowHide = () => {
    if (showhideState) {
      setShowHideState(false);
    } else {
      setShowHideState(true);
    }
  };

  const handleShowHideFontMenu = () => {
    if (showHideFontMenuState) {
      setShowHideFontMenuState(false);
    } else {
      setShowHideFontMenuState(true);
    }
  };

  const handleColumnCheckChanged = (event, accessorKey, data) => {
    console.log("handleColumnCheckChanged()");
    console.log("event", event);
    console.log("data", data);

    const newColumnSettings = tableColumnList.map((column, index) => {
      if (column.accessorKey === accessorKey) {
        column.visible = event.target.checked;
      }
      return column;
    });

    const checkedCount = newColumnSettings.filter(
      (column) => column.visible
    ).length;
    setColumnCount(checkedCount);

    setShowHideState(true);
    console.log("newColumnSettings", newColumnSettings);

    setTableColumnList(newColumnSettings);
    setActiveColumns(newColumnSettings);
    autoAdjustAllRow(); //Added fix overlapping files
  };

  const [justChangedLabel, setJustChangedLabel] = useState(false);
  const handleChangeLabel = () => {
    setJustChangedLabel(true);
    setTimeout(() => {
      setJustChangedLabel(false);
    }, 5);
  };

  const handleCheckAll = (e) => {
    const ids = background.map(({ id }) => ({
      id,
      fileName: "",
    }));
    if (e.target.checked) {
      setSelectedItems(background.map((x) => x.id));
      setcheckAllState(true);
      setShowDeleteButton(true);
      setSelectRow(background.map((x) => x));
      setSelectedRowsBG(ids);
    } else {
      setSelectedItems([]);
      setcheckAllState(false);
      setShowDeleteButton(false);
      setSelectRow([]);
      setSelectedRowsBG([]);
    }
    //Overlapping files

    autoAdjustAllRow();
  };
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  // Function to update viewport width
  const updateViewportWidth = () => {
    setViewportWidth(window.innerWidth);
  };

  // TANSTACK VIRTUALIZED
  const tableContainerRef = useRef(null);

  const table = useReactTable({
    data: background,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const tableHeaderRef = useRef();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    parentRef: tableContainerRef,
    count: rows.length,
    overscan: 10,
    estimateSize: useCallback(() => (width > 844 ? 100 : 310), []),
    paddingStart: tableHeaderRef?.current?.clientHeight || 20
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  const totalSize = rowVirtualizer.getTotalSize();

  const vTablePaddingTop =
    virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const vTablePaddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0)
      : 0;

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", updateViewportWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateViewportWidth);
    };
  }, [background]);

  //Add row
  const handleAddRow = async () => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: brief_id, name: bgName }];
      console.log(tempBriefDetails);

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
        },
      });

      if (createBackgroundRow) {
        const result = {
          createdAt: dateToday,
          id: createBackgroundRow.data.backgroundCreate.id,
          description: "",
          date: null,
          order: 0,
          files: { items: [] },
          labels: { items: [] },
          briefs: [{ id: briefId, name: briefName }],
          dynamicData: null,
        };
        setBackground([]);

        setBackground((background) => sortByOrder(background.concat(result)));
        setPreviousBackground((background) =>
          sortByOrder(background.concat(result))
        );
        background.splice(0, 0, result);

        // Log Activity
        const params = {
          query: mCreateActivity,
          variables: {
            companyId: localStorage.getItem("companyId"),
            clientMatterId: matter_id,
            briefId: briefId,
            activity: "updated the background briefs",
            field: "Background",
            appModule: "BACKGROUND",
          },
        };
        const addActivity = await API.graphql(params).then((result) => {
          console.log("addActivity result", result);
        });

        const rowArrangement = background.map(({ id }, index) => ({
          id: id,
          order: index + 1,
        }));

        /** Start - Update background orders */
        updateBackgroundOrders(rowArrangement);

        // For FE purposes: Update existing background array with the updated order data
        background.forEach((item, index) => {
          item.order = index + 1;
        });

        setcheckAllState(false);
        setCheckedState(new Array(background.length).fill(false));
        setSelectedRowsBG([]);
        setShowDeleteButton(false);
        setMaxLoading(false);
        setBackground(sortByOrder(background));
        setPreviousBackground(sortByOrder(background));
        autoAdjustAllRow();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sortParentObjects = (data) => {
    // Create a map to store the index of each fileId in files.items
    const fileIdIndexMap = new Map();

    // Populate the fileIdIndexMap with fileId and their corresponding index
    data.forEach((obj) => {
      obj.items.files.items.forEach((file, index) => {
        fileIdIndexMap.set(file.id, index);
      });
    });

    // Sort the parent objects based on the index of their fileId in files.items
    const sortedData = data.sort((a, b) => {
      const indexA = fileIdIndexMap.get(a.fileId);
      const indexB = fileIdIndexMap.get(b.fileId);
      return indexA - indexB;
    });

    return sortedData;
  };

  const handleCopyAllToBackground = async (data) => {
    console.log("handleCopyAllToBackground()", data?.bulkResult);

    const proccessedInput = sortParentObjects(data?.bulkResult);
    console.log("proccessedInput", proccessedInput);

    try {
      const groupedData = new Map();

      proccessedInput.forEach((obj) => {
        console.log(obj);
        const { backgroundId, fileName, summarizedContent, items } = obj;
        const { description = "" } = items;

        if (groupedData.has(backgroundId)) {
          const existingObj = groupedData.get(backgroundId);
          existingObj.currentDescription += `\n\n Filename: ${fileName}\n\n ${summarizedContent}`;
        } else {
          groupedData.set(backgroundId, {
            backgroundId,
            previousDescription: description,
            currentDescription: `${description?.replace(
              "Enter some text...",
              ""
            )}\n\n Filename: ${fileName}\n\n ${summarizedContent}`,
          });
        }
      });

      const dedupedData = Array.from(groupedData.values());

      const updatePromises = dedupedData.map(
        async ({ backgroundId, previousDescription, currentDescription }) => {
          const cleanDescription = handleDescription(
            currentDescription?.trim()
          );
          const params = {
            query: mUpdateBackgroundDesc,
            variables: {
              id: backgroundId,
              description: cleanDescription,
            },
          };

          await API.graphql(params).then(async (result) => {
            let foundIndex = background.findIndex((x) => x.id == backgroundId);

            // console.log("foundIndex", foundIndex);
            // console.log("background[foundIndex]", background[foundIndex]);
            // console.log("Description", handleDescription(currentDescription));

            background[foundIndex].description = cleanDescription;

            console.log("background[foundIndex]", background[foundIndex]);

            const params = {
              query: mCreateActivity,
              variables: {
                companyId: localStorage.getItem("companyId"),
                clientMatterId: matter_id,
                briefId: briefId,
                activity: "updated the background description",
                field: "Description",
                current: currentDescription,
                previous: previousDescription,
                appModule: "BACKGROUND",
                rowId: backgroundId,
              },
            };
            await API.graphql(params).then((result) => {
              console.log("addActivity result", result);
            });
          });
        }
      );

      await Promise.all(updatePromises);
      setBackground([]);
      setBackground(sortByOrder2(background));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div
        className={
          "sm:shadow-lg sm:rounded bg-white-100 sm:bg-white h-screen overflow-hidden z-30 p-0 pl-1 pb-5 contentDiv sm:pb-0 w-full flex flex-col"
        }
        id="MainDiv"
        // style={{ display: !showDocumentCreator ? "block" : "none" }}
        // onClick={()=>{
        //   console.log("CLICKED OUTSIDE LABEL");
        //   if (currLabelCellSelected.current != 0) {
        //     if (currLabelCellSelected2.current == "out" || currLabelCellSelected2.current != currLabelCellSelected.current) {
        //       try {
        //         document.getElementById(currLabelCellSelected.current).classList.remove("selectedLabel");
        //         let y = currLabelCellSelected.current.split("-");
        //         y[1] = "popup";
        //         if (document.getElementById(y.join("-"))) {
        //           document.getElementById(y.join("-")).style.display = "none";
        //         }
        //       } catch (error) {

        //       }

        //       handleChangeLabel();
        //       //document.getElementsByTagName("body")[0].classList.remove("tempLockScroll");
        //       //allowScroll();
        //     }
        //   }
        // }}
      >
        <div
          ref={headerRef}
          className="bg-white pt-8 pb-5 sticky pl-8 sm:pl-0 -top-20 py-0 px-6 hidden sm:flex items-center bg-white gap-2 z-30 pt-1 pb-1 w-full"
        >
          <Link
            to={
              hbaDomains.includes(currUrl) &&
              localStorage.getItem("userCategory") === "OWNERBUILDER"
                ? `${AppRoutes.MATTERSOVERVIEW}/${matter_id}` //If HBA and OWNERBUILDER
                : hbaDomains.includes(currUrl) &&
                  (localStorage.getItem("userCategory") ===
                    "HBA_ARCHITECT_SUPERINTENDENT" ||
                    localStorage.getItem("userCategory") === "HBA_HOMEOWNER")
                ? AppRoutes.HBADASHBOARD //If HBA and AS/HO
                : obaDomains.includes(currUrl)
                ? `${AppRoutes.OBADASHBOARD}` //If OBA redirect to OBA dashboard
                : `${AppRoutes.BRIEFS}/${matter_id}/?matter_name=${m_name}&client_name=${c_name}&back_page=true` //If MMA redirect to previous page
            }
            className="w-8 ml-3 py-5 cursor-pointer"
            onClick={() =>
              console.log("userCategory", localStorage.getItem("userCategory"))
            }
          >
            <CgChevronLeft />
          </Link>
          {hbaDomains.includes(currUrl) && (
            <div className="w-full flex flex-row justify-between items-center">
              <p>
                <span className=" text-grey-600 font-bold text-base">
                  {bgName}
                </span>
              </p>
              <div className="flex items-center relative">
                <input
                  type="search"
                  placeholder="Search for anything"
                  onChange={handleSearchDescriptionChange}
                  className={
                    showMenu
                      ? "text-xs px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring h-9 w-52 pr-10 pl-8"
                      : "text-xs px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring h-9 w-48 pr-10 pl-8"
                  }
                />

                <span className="z-10 leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-sm items-center justify-center w-8 top-1/2 transform -translate-y-1/2 left-2">
                  <IoIcons.IoIosSearch />
                </span>

                {showMenu && (
                  <span className="z-10 font-normal text-center text-blueGray-300 bg-transparent rounded text-sm items-center justify-center absolute top-1/2 transform -translate-y-1/2 right-2">
                    <BsFilter
                      size={25}
                      className="ml-0.5 cursor-pointer"
                      onClick={() => {
                        getLabels();
                        handleDateFilterModal();
                      }}
                    />
                  </span>
                )}

                {allowDownload && (
                  <DownloadButton
                    matter_id={matter_id}
                    briefId={brief_id}
                    briefName={bgName}
                    ascDesc={ascDesc}
                  />
                )}

                <CreateDocument
                  reducedTabsArray={reducedTabsArray}
                  reducedUploadedFilesArray={reducedUploadedFilesArray}
                  background={background}
                />
              </div>
            </div>
          )}
          {mmaDomains.includes(currUrl) && (
            <div
              style={{ width: "90vw" }}
              className="flex flex-row items-center -ml-4 -mt-1"
            >
              {/** Start Header Navigation */}
              <div className="mt-1">
                <div className="flex">
                  {showMenu && (
                    <p>
                      <span className=" text-grey-600">
                        {localStorage.getItem("cmNameOrder") === null ||
                        localStorage.getItem("cmNameOrder") ===
                          "Client/Matter" ? (
                          <>
                            <span className="text-xl font-bold">
                              {client_name}
                            </span>
                            <span className="text-xl font-extralight text-gray-500">
                              {" / "}
                              {matter_name}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="text-xl font-bold">
                              {matter_name}
                            </span>
                            <span className="text-xl font-extralight text-gray-500">
                              {" / "}
                              {client_name}
                            </span>
                          </>
                        )}
                      </span>
                    </p>
                  )}
                </div>
                {/* BREADCRUMBS */}
                <div className="flex ">
                  {/* breadcrumb */}
                  {/* <Link to={dashboardPath}>
                    <div className="flex w-18">
                      {showMenu && <AiOutlineLineChart className="text-sm" />}
                      <p className="font-medium hidden sm:block text-xs">
                        &nbsp;DASHBOARD
                      </p>
                    </div>
                  </Link>
                  {showMenu && <span>/</span>} */}

                  {/* <Link
                    to={`${AppRoutes.FILEBUCKET}/${matter_id}/000/?matter_name=${m_name}&client_name=${c_name}`}
                  >
                    <div
                      className={conditionMenuStyle}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {showMenu && <BsBucket className="text-sm" />}
                      <p
                        className="font-medium hidden sm:block text-xs"
                        style={{ margin: "0", paddingLeft: "5px" }}
                      >
                        FILEBUCKET
                      </p>
                    </div>
                  </Link>

                  {showMenu && <span>/</span>} */}
                  <div className="flex ">
                    <Link
                      to={`${AppRoutes.BRIEFS}/${matter_id}/?matter_name=${m_name}&client_name=${c_name}&back_page=true`}
                    >
                      <div className="flex">
                        {showMenu && (
                          <HiOutlineDocumentDuplicate className="text-sm" />
                        )}
                        <p className="font-normal hidden sm:block text-xs">
                          BACKGROUND
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div className="text-md font-semibold px-2 -mt-1 ">|</div>

                  <div className="flex">
                    <p className="font-semibold hidden sm:block text-xs w-40">
                      {" "}
                      {bgName}{" "}
                    </p>
                  </div>
                </div>
              </div>

              {/* {!showMenu && (
                <p className="font-medium hidden sm:block text-xs cursor-pointer">
                  {" "}
                  |{" "}
                </p>
              )} */}

              {/* COLLAPSED MENU BOX */}
              {!showMenu && (
                <div className="ml-2 h-10 border border-left flex w-96 mx-1 py-1 rounded-md">
                  {/* INPUT BOX CHECKALL */}
                  <div className="px-1 border-r border-gray-400 border-solid">
                    <input
                      name="check_all"
                      id="check_all"
                      aria-describedby="checkbox-1"
                      type="checkbox"
                      checked={checkAllState ? true : false}
                      onChange={handleCheckAll}
                      className="mt-2 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>

                  {/* ADD ROW */}
                  <div
                    className="px-1 text-center text-xs cursor-pointer border-r border-gray-400 border-solid hover:bg-gray-100"
                    onClick={handleAddRow}
                  >
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      ADD
                    </p>
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      ROW
                    </p>
                  </div>

                  {/* ADD PARAGRAPH */}
                  <div
                    className="px-1 text-center text-xs cursor-pointer border-r border-gray-400 border-solid hover:bg-gray-100"
                    onClick={() => setShowModalParagraph(true)}
                  >
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      ADD
                    </p>
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      PARAGRAPH
                    </p>
                  </div>

                  {/* SHOW/HIDE COLUMNS */}
                  <div
                    className="px-1 text-center text-xs cursor-pointer border-r border-gray-400 border-solid hover:bg-gray-100"
                    onClick={() => handleShowHide()}
                  >
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      SHOW/HIDE
                    </p>
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      COLUMNS
                    </p>
                    {showhideState && (
                      <div className="w-64 h-38 z-500 bg-white absolute mt-2 rounded border-0 shadow outline-none z-40 text-left">
                        <p className="px-2 py-2 text-gray-400 text-x font-semibold">
                          TABLE COLUMN OPTIONS
                        </p>

                        {tableColumnList !== null &&
                          tableColumnList?.map((data) => (
                            <div className="px-2 py-1" key={data.accessorKey}>
                              <input
                                type="checkbox"
                                name={data.header}
                                className="cursor-pointer"
                                checked={data.visible}
                                onChange={(event) =>
                                  handleColumnCheckChanged(
                                    event,
                                    data.accessorKey,
                                    data
                                  )
                                }
                              />
                              &nbsp; {data.header}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>

                  <div
                    className="px-1 text-center text-xs cursor-pointer border-r border-gray-400 border-solid hover:bg-gray-100"
                    onClick={() => handleManageFiles()}
                  >
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      MANAGE
                    </p>
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      FILES
                    </p>
                  </div>

                  <div
                    className="px-1 text-center text-xs cursor-pointer border-r border-gray-400 border-solid hover:bg-gray-100"
                    onClick={() => handleShowHideFontMenu()}
                  >
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      FONT
                    </p>
                    <p
                      className="font-medium"
                      style={{
                        fontSize: "9px",
                      }}
                    >
                      SIZE
                    </p>
                    {showHideFontMenuState && (
                      <div
                        className={
                          viewportWidth <= 1590
                            ? "w-72 bg-white absolute mt-10 rounded border shadow z-50"
                            : "w-72 bg-white absolute mt-40 rounded border shadow z-50"
                        }
                      >
                        <p className="px-2 py-2 text-gray-400 font-semibold text-xs">
                          Font Size
                        </p>
                        <div className="p-2">
                          <div
                            className={`px-2 py-1 rounded-lg ${
                              fontSize === 0 ? "bg-gray-600 text-white" : ""
                            } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                            onClick={() => setFontSize(0)}
                          >
                            Small{" "}
                            <span className="mt-1 float-right italic text-xs">
                              (default)
                            </span>
                          </div>
                          <div
                            className={`px-2 py-1 rounded-lg ${
                              fontSize === 1 ? "bg-gray-600 text-white" : ""
                            } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                            onClick={() => setFontSize(1)}
                          >
                            Medium
                          </div>
                          <div
                            className={`px-2 py-1 rounded-lg ${
                              fontSize === 2 ? "bg-gray-600 text-white" : ""
                            } hover:bg-gray-600 hover:rounded-full cursor-pointer`}
                            onClick={() => setFontSize(2)}
                          >
                            Large
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {allowManagePrompts && (
                    <div
                      className="px-1 text-center text-xs cursor-pointer hover:bg-gray-100"
                      onClick={() => handleShowHideManagePrompts()}
                    >
                      <p
                        className="font-medium"
                        style={{
                          fontSize: "9px",
                        }}
                      >
                        MANAGE
                      </p>
                      <p
                        className="font-medium"
                        style={{
                          fontSize: "9px",
                        }}
                      >
                        PROMPTS
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/** Start Header buttons */}
              <div
                className={`flex items-start justify-end rounded-md w-full mb-1 pr-12 mt-2 mr-2
                }`}
              >
                <div className="relative">
                  <input
                    type="search"
                    placeholder="Search for anything"
                    onChange={handleSearchDescriptionChange}
                    className={
                      showMenu
                        ? "text-xs px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring h-9 w-52 pr-10 pl-8"
                        : "text-xs px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded border-0 shadow outline-none focus:outline-none focus:ring h-9 w-52 pr-10 pl-8"
                    }
                  />

                  <span className="z-10 leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-sm items-center justify-center w-8 top-1/2 transform -translate-y-1/2 left-2">
                    <IoIcons.IoIosSearch />
                  </span>

                  {showMenu && (
                    <span className="z-10 font-normal text-center text-blueGray-300 bg-transparent rounded text-sm items-center justify-center absolute top-1/2 transform -translate-y-1/2 right-2">
                      <BsFilter
                        size={25}
                        className="ml-0.5 cursor-pointer"
                        onClick={() => {
                          getLabels();
                          handleDateFilterModal();
                        }}
                      />
                    </span>
                  )}
                </div>

                <span className="z-10 font-normal text-center text-blueGray-300 bg-transparent rounded text-sm justify-center hidden">
                  <FaSearchengin
                    size={28}
                    className="ml-1 mt-1 cursor-pointer"
                    onClick={() => {
                      setPressedQSearch(!pressedQSearch);
                    }}
                  />
                </span>

                {allowDownload && (
                  <DownloadButton
                    matter_id={matter_id}
                    briefId={brief_id}
                    briefName={bgName}
                    ascDesc={ascDesc}
                  />
                )}

                <CreateDocument
                  reducedTabsArray={reducedTabsArray}
                  reducedUploadedFilesArray={reducedUploadedFilesArray}
                  background={background}
                />

                {(back_id === "0" || back_id === "3") && (
                  <>
                    <ShareLinkButton briefId={brief_id} matter_id={matter_id} />
                    <Button
                      onClick={toggleMenu}
                      className="h-9 w-26 text-xs py-2 ml-1 mb-1"
                    >
                      {showMenu ? "Hide Menu" : "Show Menu"}
                    </Button>
                  </>
                )}

                {/* Notification button hidden -kjmf*/}
                <div className="">
                  <Button
                    onClick={() =>
                      setShowNotificationDropdown(!showNotificationDropdown)
                    }
                    className="py-2 px-4 ml-2 mb-1"
                  >
                    <FaBell />
                    {unreadNotifications?.length > 0 && (
                      <GoDotFill className="absolute text-red-600 ml-2 -mt-2" />
                    )}
                  </Button>

                  {showNotificationDropdown &&
                    (readNotifications.length > 0 ? (
                      <div className="absolute h-48 bg-white border flex gap-y-1 flex-col p-3 -ml-56 w-72 overflow-y-auto rounded shadow z-50">
                        {readNotifications.map((notif, index) => (
                          <div key={index}>
                            <p className="font-medium">{notif.message}</p>
                            <span
                              onClick={() => {
                                handleCopyAllToBackground(notif);
                                setShowNotificationDropdown(false);
                              }}
                              className="px-2 text-xs text-blue-400 float-left cursor-pointer"
                            >
                              Copy All to Background
                            </span>
                            {notif.bulkResult.map((result, resultIndex) =>
                              result.message ? (
                                <p key={resultIndex} className="font-medium">
                                  {`${result.message} ${result.fileName}`}
                                </p>
                              ) : null
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="absolute h-20 bg-white border flex gap-y-1 flex-col p-3 -ml-56 w-72 overflow-y-auto rounded shadow z-50">
                        <p>No unread notifications to display.</p>
                      </div>
                    ))}
                </div>
              </div>
              {/** End Header buttons */}
              {/* </div> */}
              {/** End Header Navigation */}
            </div>
          )}
        </div>
        {/* <div className="hidden sm:block px-6 mt-5 -ml-1">
            <button className="bg-white hover:bg-gray-100 text-black font-semibold py-2.5 px-4 rounded inline-flex items-center border-0 shadow outline-none focus:outline-none focus:ring mb-3"
              onClick={() => history.goBack()}>
              <MdArrowBackIos />
              Back
            </button>
        </div> */}
        <div
          style={{ position: "sticky", top: "0" }}
          className="mt-5 z-20 ml-4 bg-gray-100 sm:bg-white hidden sm:block"
        ></div>
        <div className="hidden sm:block py-5 bg-gray-100 sm:bg-white z-30 absolute mt-0 ml-0">
          <div className="flex font-bold text-3xl"></div>
        </div>
        {/* <div className="hidden sm:block">
            <BreadCrumb
              matterId={matter_id}
              client_name={client_name}
              matter_name={matter_name}
              briefId={brief_id}
            />
          </div> */}
        <div className="bg-gray-100 sm:hidden z-0">
          {/* MOBILE VIEW OF HEADER */}
          <MobileHeader
            height={height}
            width={width}
            matter_name={matter_name}
            client_name={client_name}
            setContentHeight={setContentHeight}
            background_name={bgName}
          />
          <div className="justify-end">
            <nav
              aria-label="Breadcrumb"
              //style={style}
              className="pb-5 justify-end "
            >
              <ol className="px-5 flex items-right sm:space-x-2 lg:max-w-7xl lg:px-8 items-center justify-end">
                <li className="text-sm">
                  <span className="text-xs sm:px-1 sm:flex uppercase sm:normal-case sm:text-sm sm:no-underline font-medium text-gray-400 sm:text-gray-500">
                    <AiOutlineFolderOpen className="hidden sm:inline-block" />
                    <span className="sm:inline hidden">&nbsp;&nbsp;</span>
                    {client_name !== "null" ? (
                      <Link
                        aria-current="page"
                        className="text-xs uppercase sm:normal-case sm:mr-2 sm:text-sm font-semibold sm:no-underline text-gray-400 sm:text-gray-900"
                        to={`${AppRoutes.BRIEFS}/${matter_id}/?matter_name=${m_name}&client_name=${c_name}`}
                      >
                        Background
                      </Link>
                    ) : (
                      <span className="text-xs uppercase sm:normal-case sm:mr-2 sm:text-sm font-semibold text-gray-400 sm:text-gray-900">
                        Background
                      </span>
                    )}
                    <span className="sm:inline hidden font-medium">
                      &nbsp;Page
                    </span>{" "}
                  </span>
                </li>
                <FaSlash className="h-2 w-4 text-gray-400 rotate-45 transform" />
                <li className="text-sm sm:hidden">
                  <CreatableSelect
                    isSearchable={false}
                    options={briefs.map((item) => ({
                      value: item.name,
                      label:
                        width > 300
                          ? formatModular(item.name, 15)
                          : formatModular(item.name, 6),
                    }))}
                    dropdownHandle={true}
                    defaultValue={
                      width > 300
                        ? formatModular(bgName, 15)
                        : formatModular(bgName, 6)
                    }
                    placeholder={
                      width > 300
                        ? formatModular(bgName, 15)
                        : formatModular(bgName, 6)
                    }
                    styles={selectStyle}
                    className="-ml-2 uppercase"
                    onChange={handleSelectChange}
                    noOptionsMessage={() => null}
                    isValidNewOption={() => false}
                    components={{ IndicatorSeparator: () => null }}
                  />
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {/* kjmf uncomment if error in table size */}
        <div
          // className={baseTablePadding}
          className={`w-fit h-full flex flex-col ${
            isMobileDevice(width) ? "p-1" : "p-5 pb-0 pr-3"
          }`}
          // Remove for updated mobile UI
          // style={{
          //   backgroundColor: "#F9F9F9",
          // }}
        >
          <ActionButtons
            setIsCreateBriefClicked={setIsCreateBriefClicked}
            updateLabel={updateLabel}
            updateLabelValue={updateLabelValue}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            background={background}
            setBackground={setBackground}
            setPreviousBackground={setPreviousBackground}
            idList={idList}
            checkAllState={checkAllState}
            setcheckAllState={setcheckAllState}
            checkedState={checkedState}
            setCheckedState={setCheckedState}
            totalChecked={totalChecked}
            settotalChecked={settotalChecked}
            setId={setId}
            search={search}
            setSearch={setSearch}
            getId={getId}
            matterId={matter_id}
            getBackground={getBackground}
            selectedRowsBG={selectedRowsBG}
            setSelectedRowsBG={setSelectedRowsBG}
            setShowModalParagraph={setShowModalParagraph}
            paragraph={paragraph}
            showDeleteButton={showDeleteButton}
            setShowDeleteButton={setShowDeleteButton}
            activateButton={activateButton}
            setactivateButton={setActivateButton}
            handleManageFiles={handleManageFiles}
            checkNo={checkNo}
            setCheckNo={setCheckNo}
            checkDate={checkDate}
            setCheckDate={setCheckDate}
            checkDesc={checkDesc}
            setCheckDesc={setCheckDesc}
            checkDocu={checkDocu}
            setCheckDocu={setCheckDocu}
            checkedStateShowHide={checkedStateShowHide}
            setCheckedStateShowHide={setCheckedStateShowHide}
            pageTotal={pageTotal}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageSizeConst={pageSizeConst}
            getPaginateItems={getPaginateItems}
            selectRow={selectRow}
            setSelectRow={setSelectRow}
            setPasteButton={setPasteButton}
            pasteButton={pasteButton}
            setSrcIndex={setSrcIndex}
            srcIndex={srcIndex}
            setNewRow={setNewRow}
            newRow={newRow}
            setMaxLoading={setMaxLoading}
            sortByOrder={sortByOrder}
            briefId={brief_id}
            briefName={bgName}
            client_name={client_name}
            matter_name={matter_name}
            holdDelete={holdDelete}
            setHoldDelete={setHoldDelete}
            setTargetRow={setTargetRow}
            targetRow={targetRow}
            highlightRow={highlightRow}
            setHighlightRow={setHighlightRow}
            pastedRows={pastedRows}
            setPastedRows={setPastedRows}
            cache={cache}
            bindList={bindList}
            setMoveButton={setMoveButton}
            moveButton={moveButton}
            handleSearchDescriptionChange={handleSearchDescriptionChange}
            fontSize={fontSize}
            setFontSize={setFontSize}
            fileView={fileView}
            setFileView={setFileView}
            allowDeleteRows={allowDeleteRows}
            allowAddRows={allowAddRows}
            allowAddParagraph={
              back_id === "0" || back_id === "3" ? allowAddParagraph : false
            }
            allowManageFiles={
              back_id === "0" || back_id === "3" ? allowManageFiles : false
            }
            allowManagePrompts={
              back_id === "0" || back_id === "3" ? allowManagePrompts : false
            }
            allowSummarize={
              back_id === "0" || back_id === "3" ? allowSummarize : false
            }
            handleShowHideManagePrompts={handleShowHideManagePrompts}
            showHideManagePromptsState={showHideManagePromptsState}
            setShowHideManagePromptsState={setShowHideManagePromptsState}
            contextualLibrary={coreMemory}
            setContextualLibrary={setCoreMemory}
            handleShowHideContextualMemory={handleShowHideContextualMemory}
            showHideContextualMemoryState={showHideContextualMemoryState}
            setShowHideContextualMemoryState={setShowHideContextualMemoryState}
            allowDownload={allowDownload}
            allowCreateDocument={allowCreateDocument}
            gmailFilter={gmailFilter}
            setgmailFilter={setgmailFilter}
            actionButtonsRef={actionButtonsRef}
            actionButtonsPos={actionButtonsPos}
            briefs={briefs}
            activeColumns={activeColumns}
            setActiveColumns={setColumns}
            tableId={tableId}
            setTableId={setTableId}
            createDocumentState={{ showDocumentCreator, setDocumentCreator }}
            background_name={bgName}
            pressedQSearch={pressedQSearch}
            setPressedQSearch={setPressedQSearch}
            showMenu={showMenu}
            reducedTabsArray={reducedTabsArray}
            setReducedTabsArray={setReducedTabsArray}
            reduceTabFilesToArray={reduceTabFilesToArray}
            columnCount={columnCount}
            setColumnCount={setColumnCount}
            setBaseTablePadding={setBaseTablePadding}
            reduceUploadedFilesToArray={reduceUploadedFilesToArray}
            setReducedUploadedFilesArray={setReducedUploadedFilesArray}
            reducedUploadedFilesArray={reducedUploadedFilesArray}
            backId={back_id}
            loading={loading}
            openAiPrompts={openAiPrompts}
            setOpenAiPrompts={setOpenAiPrompts}
            willBulkSummarise={willBulkSummarise}
            setWillBulkSummarise={setWillBulkSummarise}
            showNotificationDropdown={showNotificationDropdown}
            setShowNotificationDropdown={setShowNotificationDropdown}
          />
          {/* DESKTOP VIEW OF CONTENTS */}
          {/* CHANGED FROM WIDTH > 844 ? */}
          {!isMobileDevice(width) ? (
            <>
              {bgName === "Budget" ? (
                <TableInfoBudget
                  allowScroll={allowScroll}
                  blockScroll={blockScroll}
                  backgroundAsc={backgroundAsc}
                  backgroundDesc={backgroundDesc}
                  sortByOrder2={sortByOrder2}
                  backgroundDuplicate={backgroundDuplicate}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  client_name={client_name}
                  matter_name={matter_name}
                  wait={wait}
                  setPasteButton={setPasteButton}
                  setIdList={setIdList}
                  background={
                    gmailFilter == "all"
                      ? background
                      : gmailFilter == "sent"
                      ? background
                          .filter((bg, i) => bg.files.items.length != 0)
                          .filter((fl, i) => fl.files.length != 0)
                          .filter((fls, i) =>
                            fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
                              "SENT"
                            )
                          )
                      : gmailFilter == "inbox"
                      ? background
                          .filter((bg) => bg.files.items.length != 0)
                          .filter((fl) => fl.files.length != 0)
                          .filter((fls) =>
                            fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
                              "INBOX"
                            )
                          )
                      : background.filter((row) => {
                          return row.files.items.length > 0; //for uploaded
                        })
                  }
                  uploadedDocumentList={uploadedDocumentList}
                  ShowModalParagraph={ShowModalParagraph}
                  setShowModalParagraph={setShowModalParagraph}
                  fileMatter={fileMatter}
                  setFileMatter={setFileMatter}
                  files={files}
                  setFiles={setFiles}
                  setMaxLoading={setMaxLoading}
                  setBackground={setBackground}
                  previousBackground={previousBackground}
                  setPreviousBackground={setPreviousBackground}
                  checkAllState={checkAllState}
                  setcheckAllState={setcheckAllState}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  totalChecked={totalChecked}
                  settotalChecked={settotalChecked}
                  setId={setId}
                  getId={getId}
                  search={search}
                  matterId={matter_id}
                  getBackground={getBackground}
                  selectedRowsBG={selectedRowsBG}
                  setSelectedRowsBG={setSelectedRowsBG}
                  paragraph={paragraph}
                  setParagraph={setParagraph}
                  showDeleteButton={showDeleteButton}
                  setShowDeleteButton={setShowDeleteButton}
                  handleManageFiles={handleManageFiles}
                  setActivateButton={setActivateButton}
                  activateButton={activateButton}
                  setAscDesc={setAscDesc}
                  ascDesc={ascDesc}
                  setSelectedRowsBGFiles={setSelectedRowsBGFiles}
                  selectedRowsBGFiles={selectedRowsBGFiles}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  pasteButton={pasteButton}
                  checkNo={checkNo}
                  checkDate={checkDate}
                  checkDesc={checkDesc}
                  checkDocu={checkDocu}
                  checkedStateShowHide={checkedStateShowHide}
                  setCheckedStateShowHide={setCheckedStateShowHide}
                  pageTotal={pageTotal}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  pageSizeConst={pageSizeConst}
                  loadMoreBackground={loadMoreBackground}
                  selectRow={selectRow}
                  setSelectRow={setSelectRow}
                  setSrcIndex={setSrcIndex}
                  srcIndex={srcIndex}
                  newRow={newRow}
                  setNewRow={setNewRow}
                  loading={loading}
                  setLoading={setLoading}
                  maxLoading={maxLoading}
                  sortByOrder={sortByOrder}
                  SortBydate={SortBydate}
                  briefId={brief_id}
                  briefName={bgName}
                  searchDescription={searchDescription}
                  holdDelete={holdDelete}
                  setHoldDelete={setHoldDelete}
                  setTargetRow={setTargetRow}
                  targetRow={targetRow}
                  highlightRow={highlightRow}
                  setHighlightRow={setHighlightRow}
                  pastedRows={pastedRows}
                  setPastedRows={setPastedRows}
                  checkedRows={checkedRows}
                  setCheckedRows={setCheckedRows}
                  setbindList={setbindList}
                  setCache={setCache}
                  setMoveButton={setMoveButton}
                  moveButton={moveButton}
                  fontSize={fontSize}
                  bgLoading={bgLoading}
                  setBgLoading={setBgLoading}
                  allowEditDate={allowEditDate}
                  allowEditDescription={allowEditDescription}
                  allowEditComments={allowEditComments}
                  allowEditLabels={allowEditLabels}
                  allowEditFiles={allowEditFiles}
                  allowShowLabels={allowShowLabels}
                  headerHeight={headerHeight}
                  commentAccess={commentAccess}
                  gmailFilter={gmailFilter}
                  allowDeleteFiles={allowDeleteFiles}
                  allowDragNDrop={allowDragNDrop}
                  columns={columns}
                  setActiveColumns={setActiveColumns}
                  columnIds={backgroundColumnIds}
                  qSearchValue={qSearchValue}
                  pressedQSearch={pressedQSearch}
                  totalHits={totalHits}
                  setTotalHits={setTotalHits}
                  qSearchPtr={qSearchPtr}
                  setQSearchPtr={setQSearchPtr}
                  inRow={inRow}
                  reducedTabsArray={reducedTabsArray}
                  setReducedTabsArray={setReducedTabsArray}
                  reduceTabFilesToArray={reduceTabFilesToArray}
                  reducedUploadedFilesArray={reducedUploadedFilesArray}
                  setReducedUploadedFilesArray={setReducedUploadedFilesArray}
                  reduceUploadedFilesToArray={reduceUploadedFilesToArray}
                  currLabelCellSelected={currLabelCellSelected}
                  currLabelCellSelected2={currLabelCellSelected2}
                  handleChangeLabel={handleChangeLabel}
                  justChangedLabel={justChangedLabel}
                  setJustChangedLabel={setJustChangedLabel}
                  showRightPanel={showRightPanel}
                  setShowRightPanel={setShowRightPanel}
                  fileView={fileView}
                  setFileView={setFileView}
                  setWait={setWait}
                  isSiteDiary={isSiteDiary}
                  // TANSTACK VIRTUALIZED
                  vTablePaddingTop={vTablePaddingTop}
                  vTablePaddingBottom={vTablePaddingBottom}
                  virtualRows={virtualRows}
                  rows={rows}
                  tableContainerRef={tableContainerRef}
                  rowVirtualizer={rowVirtualizer}
                  setTotalBudgetAmount={setTotalBudgetAmount}
                  setTotalCostAmount={setTotalCostAmount}
                  totalBudgetAmount={totalBudgetAmount}
                  totalCostAmount={totalCostAmount}
                />
              ) : bgName === "Invoices" ? (
                <TableInfoInvoices
                tableHeaderRef={tableHeaderRef}
                tableHeight={tableHeight}
                allowScroll={allowScroll}
                blockScroll={blockScroll}
                backgroundAsc={backgroundAsc}
                backgroundDesc={backgroundDesc}
                sortByOrder2={sortByOrder2}
                backgroundDuplicate={backgroundDuplicate}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                client_name={client_name}
                matter_name={matter_name}
                wait={wait}
                setPasteButton={setPasteButton}
                setIdList={setIdList}
                background={
                  gmailFilter == "all"
                    ? background
                    : gmailFilter == "sent"
                    ? background
                        .filter((bg, i) => bg.files.items.length != 0)
                        .filter((fl, i) => fl.files.length != 0)
                        .filter((fls, i) =>
                          fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
                            "SENT"
                          )
                        )
                    : gmailFilter == "inbox"
                    ? background
                        .filter((bg) => bg.files.items.length != 0)
                        .filter((fl) => fl.files.length != 0)
                        .filter((fls) =>
                          fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
                            "INBOX"
                          )
                        )
                    : background.filter((row) => {
                        return row.files.items.length > 0; //for uploaded
                      })
                }
                uploadedDocumentList={uploadedDocumentList}
                ShowModalParagraph={ShowModalParagraph}
                setShowModalParagraph={setShowModalParagraph}
                fileMatter={fileMatter}
                setFileMatter={setFileMatter}
                files={files}
                setFiles={setFiles}
                setMaxLoading={setMaxLoading}
                setBackground={setBackground}
                previousBackground={previousBackground}
                setPreviousBackground={setPreviousBackground}
                checkAllState={checkAllState}
                setcheckAllState={setcheckAllState}
                checkedState={checkedState}
                setCheckedState={setCheckedState}
                totalChecked={totalChecked}
                settotalChecked={settotalChecked}
                setId={setId}
                getId={getId}
                search={search}
                matterId={matter_id}
                getBackground={getBackground}
                selectedRowsBG={selectedRowsBG}
                setSelectedRowsBG={setSelectedRowsBG}
                paragraph={paragraph}
                setParagraph={setParagraph}
                showDeleteButton={showDeleteButton}
                setShowDeleteButton={setShowDeleteButton}
                handleManageFiles={handleManageFiles}
                setActivateButton={setActivateButton}
                activateButton={activateButton}
                setAscDesc={setAscDesc}
                ascDesc={ascDesc}
                setSelectedRowsBGFiles={setSelectedRowsBGFiles}
                selectedRowsBGFiles={selectedRowsBGFiles}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                pasteButton={pasteButton}
                checkNo={checkNo}
                checkDate={checkDate}
                checkDesc={checkDesc}
                checkDocu={checkDocu}
                checkedStateShowHide={checkedStateShowHide}
                setCheckedStateShowHide={setCheckedStateShowHide}
                pageTotal={pageTotal}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageSizeConst={pageSizeConst}
                loadMoreBackground={loadMoreBackground}
                selectRow={selectRow}
                setSelectRow={setSelectRow}
                setSrcIndex={setSrcIndex}
                srcIndex={srcIndex}
                newRow={newRow}
                setNewRow={setNewRow}
                loading={loading}
                setLoading={setLoading}
                maxLoading={maxLoading}
                sortByOrder={sortByOrder}
                SortBydate={SortBydate}
                briefId={brief_id}
                briefName={bgName}
                searchDescription={searchDescription}
                holdDelete={holdDelete}
                setHoldDelete={setHoldDelete}
                setTargetRow={setTargetRow}
                targetRow={targetRow}
                highlightRow={highlightRow}
                setHighlightRow={setHighlightRow}
                pastedRows={pastedRows}
                setPastedRows={setPastedRows}
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
                setbindList={setbindList}
                setCache={setCache}
                setMoveButton={setMoveButton}
                moveButton={moveButton}
                fontSize={fontSize}
                bgLoading={bgLoading}
                setBgLoading={setBgLoading}
                allowEditDate={allowEditDate}
                allowEditDescription={allowEditDescription}
                allowEditComments={allowEditComments}
                allowEditLabels={allowEditLabels}
                allowEditFiles={allowEditFiles}
                allowShowLabels={allowShowLabels}
                allowSummarize={allowSummarize}
                headerHeight={headerHeight}
                commentAccess={commentAccess}
                gmailFilter={gmailFilter}
                allowDeleteFiles={allowDeleteFiles}
                allowDragNDrop={allowDragNDrop}
                columns={columns}
                setActiveColumns={setActiveColumns}
                columnIds={backgroundColumnIds}
                qSearchValue={qSearchValue}
                pressedQSearch={pressedQSearch}
                totalHits={totalHits}
                setTotalHits={setTotalHits}
                qSearchPtr={qSearchPtr}
                setQSearchPtr={setQSearchPtr}
                inRow={inRow}
                reducedTabsArray={reducedTabsArray}
                setReducedTabsArray={setReducedTabsArray}
                reduceTabFilesToArray={reduceTabFilesToArray}
                reducedUploadedFilesArray={reducedUploadedFilesArray}
                setReducedUploadedFilesArray={setReducedUploadedFilesArray}
                reduceUploadedFilesToArray={reduceUploadedFilesToArray}
                currLabelCellSelected={currLabelCellSelected}
                currLabelCellSelected2={currLabelCellSelected2}
                handleChangeLabel={handleChangeLabel}
                justChangedLabel={justChangedLabel}
                setJustChangedLabel={setJustChangedLabel}
                showRightPanel={showRightPanel}
                setShowRightPanel={setShowRightPanel}
                fileView={fileView}
                setFileView={setFileView}
                setWait={setWait}
                isSiteDiary={isSiteDiary}
                // TANSTACK VIRTUALIZED
                vTablePaddingTop={vTablePaddingTop}
                vTablePaddingBottom={vTablePaddingBottom}
                virtualRows={virtualRows}
                rows={rows}
                tableContainerRef={tableContainerRef}
                rowVirtualizer={rowVirtualizer}
                setTotalBudgetAmount={setTotalBudgetAmount}
                setTotalCostAmount={setTotalCostAmount}
                totalBudgetAmount={totalBudgetAmount}
                totalCostAmount={totalCostAmount}
                openAiPrompts={openAiPrompts}
                setOpenAiPrompts={setOpenAiPrompts}
                coreMemory={coreMemory}
                setCoreMemory={setCoreMemory}
                willBulkSummarise={willBulkSummarise}
                setWillBulkSummarise={setWillBulkSummarise}
                selectedFilesBulkSummary={selectedFilesBulkSummary}
                setSelectedFilesBulkSummary={setSelectedFilesBulkSummary}
                setInterfaceRightPanel={setInterfaceRightPanel}
                interfaceRightPanel={interfaceRightPanel}
                rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
                setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
                setUnreadNotifications={setUnreadNotifications}
                setFilePreviewItems={setFilePreviewItems}
                setShowFilePreview={setShowFilePreview}
                handleSnippetEmail={handleSnippetEmail}
                />
              ) : (
                <TableInfo
                  tableHeaderRef={tableHeaderRef}
                  tableHeight={tableHeight}
                  allowScroll={allowScroll}
                  blockScroll={blockScroll}
                  backgroundAsc={backgroundAsc}
                  backgroundDesc={backgroundDesc}
                  sortByOrder2={sortByOrder2}
                  backgroundDuplicate={backgroundDuplicate}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  client_name={client_name}
                  matter_name={matter_name}
                  wait={wait}
                  setPasteButton={setPasteButton}
                  setIdList={setIdList}
                  background={
                    gmailFilter == "all"
                      ? background
                      : gmailFilter == "sent"
                      ? background
                          .filter((bg, i) => bg.files.items.length != 0)
                          .filter((fl, i) => fl.files.length != 0)
                          .filter((fls, i) =>
                            fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
                              "SENT"
                            )
                          )
                      : gmailFilter == "inbox"
                      ? background
                          .filter((bg) => bg.files.items.length != 0)
                          .filter((fl) => fl.files.length != 0)
                          .filter((fls) =>
                            fls.files.items[0].gmailMessage?.gmailLabelIds?.includes(
                              "INBOX"
                            )
                          )
                      : background.filter((row) => {
                          return row.files.items.length > 0; //for uploaded
                        })
                  }
                  uploadedDocumentList={uploadedDocumentList}
                  ShowModalParagraph={ShowModalParagraph}
                  setShowModalParagraph={setShowModalParagraph}
                  fileMatter={fileMatter}
                  setFileMatter={setFileMatter}
                  files={files}
                  setFiles={setFiles}
                  setMaxLoading={setMaxLoading}
                  setBackground={setBackground}
                  previousBackground={previousBackground}
                  setPreviousBackground={setPreviousBackground}
                  checkAllState={checkAllState}
                  setcheckAllState={setcheckAllState}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  totalChecked={totalChecked}
                  settotalChecked={settotalChecked}
                  setId={setId}
                  getId={getId}
                  search={search}
                  matterId={matter_id}
                  getBackground={getBackground}
                  selectedRowsBG={selectedRowsBG}
                  setSelectedRowsBG={setSelectedRowsBG}
                  paragraph={paragraph}
                  setParagraph={setParagraph}
                  showDeleteButton={showDeleteButton}
                  setShowDeleteButton={setShowDeleteButton}
                  handleManageFiles={handleManageFiles}
                  setActivateButton={setActivateButton}
                  activateButton={activateButton}
                  setAscDesc={setAscDesc}
                  ascDesc={ascDesc}
                  setSelectedRowsBGFiles={setSelectedRowsBGFiles}
                  selectedRowsBGFiles={selectedRowsBGFiles}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  pasteButton={pasteButton}
                  checkNo={checkNo}
                  checkDate={checkDate}
                  checkDesc={checkDesc}
                  checkDocu={checkDocu}
                  checkedStateShowHide={checkedStateShowHide}
                  setCheckedStateShowHide={setCheckedStateShowHide}
                  pageTotal={pageTotal}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  pageSizeConst={pageSizeConst}
                  loadMoreBackground={loadMoreBackground}
                  selectRow={selectRow}
                  setSelectRow={setSelectRow}
                  setSrcIndex={setSrcIndex}
                  srcIndex={srcIndex}
                  newRow={newRow}
                  setNewRow={setNewRow}
                  loading={loading}
                  setLoading={setLoading}
                  maxLoading={maxLoading}
                  sortByOrder={sortByOrder}
                  SortBydate={SortBydate}
                  briefId={brief_id}
                  briefName={bgName}
                  searchDescription={searchDescription}
                  holdDelete={holdDelete}
                  setHoldDelete={setHoldDelete}
                  setTargetRow={setTargetRow}
                  targetRow={targetRow}
                  highlightRow={highlightRow}
                  setHighlightRow={setHighlightRow}
                  pastedRows={pastedRows}
                  setPastedRows={setPastedRows}
                  checkedRows={checkedRows}
                  setCheckedRows={setCheckedRows}
                  setbindList={setbindList}
                  setCache={setCache}
                  setMoveButton={setMoveButton}
                  moveButton={moveButton}
                  fontSize={fontSize}
                  bgLoading={bgLoading}
                  setBgLoading={setBgLoading}
                  allowEditDate={allowEditDate}
                  allowEditDescription={allowEditDescription}
                  allowEditComments={allowEditComments}
                  allowEditLabels={allowEditLabels}
                  allowEditFiles={allowEditFiles}
                  allowShowLabels={allowShowLabels}
                  allowSummarize={allowSummarize}
                  headerHeight={headerHeight}
                  commentAccess={commentAccess}
                  gmailFilter={gmailFilter}
                  allowDeleteFiles={allowDeleteFiles}
                  allowDragNDrop={allowDragNDrop}
                  columns={columns}
                  setActiveColumns={setActiveColumns}
                  columnIds={backgroundColumnIds}
                  qSearchValue={qSearchValue}
                  pressedQSearch={pressedQSearch}
                  totalHits={totalHits}
                  setTotalHits={setTotalHits}
                  qSearchPtr={qSearchPtr}
                  setQSearchPtr={setQSearchPtr}
                  inRow={inRow}
                  reducedTabsArray={reducedTabsArray}
                  setReducedTabsArray={setReducedTabsArray}
                  reduceTabFilesToArray={reduceTabFilesToArray}
                  reducedUploadedFilesArray={reducedUploadedFilesArray}
                  setReducedUploadedFilesArray={setReducedUploadedFilesArray}
                  reduceUploadedFilesToArray={reduceUploadedFilesToArray}
                  currLabelCellSelected={currLabelCellSelected}
                  currLabelCellSelected2={currLabelCellSelected2}
                  handleChangeLabel={handleChangeLabel}
                  justChangedLabel={justChangedLabel}
                  setJustChangedLabel={setJustChangedLabel}
                  showRightPanel={showRightPanel}
                  setShowRightPanel={setShowRightPanel}
                  fileView={fileView}
                  setFileView={setFileView}
                  setWait={setWait}
                  isSiteDiary={isSiteDiary}
                  // TANSTACK VIRTUALIZED
                  vTablePaddingTop={vTablePaddingTop}
                  vTablePaddingBottom={vTablePaddingBottom}
                  virtualRows={virtualRows}
                  rows={rows}
                  tableContainerRef={tableContainerRef}
                  rowVirtualizer={rowVirtualizer}
                  setTotalBudgetAmount={setTotalBudgetAmount}
                  setTotalCostAmount={setTotalCostAmount}
                  totalBudgetAmount={totalBudgetAmount}
                  totalCostAmount={totalCostAmount}
                  openAiPrompts={openAiPrompts}
                  setOpenAiPrompts={setOpenAiPrompts}
                  coreMemory={coreMemory}
                  setCoreMemory={setCoreMemory}
                  willBulkSummarise={willBulkSummarise}
                  setWillBulkSummarise={setWillBulkSummarise}
                  selectedFilesBulkSummary={selectedFilesBulkSummary}
                  setSelectedFilesBulkSummary={setSelectedFilesBulkSummary}
                  setInterfaceRightPanel={setInterfaceRightPanel}
                  interfaceRightPanel={interfaceRightPanel}
                  rightPanelItemsBulkSummary={rightPanelItemsBulkSummary}
                  setRightPanelItemsBulkSummary={setRightPanelItemsBulkSummary}
                  setUnreadNotifications={setUnreadNotifications}
                  setFilePreviewItems={setFilePreviewItems}
                  setShowFilePreview={setShowFilePreview}
                  handleSnippetEmail={handleSnippetEmail}
                />
              )}
            </>
          ) : (
            <>
              {/* MOBILE VIEW OF CONTENTS */}
              <MobileTableHeader
                SortBydate={SortBydate}
                ascDesc={ascDesc}
                brief_id={brief_id}
                matter_id={matter_id}
                // matter_name={matter_name} // not needed at the moment
                // client_name={client_name} // not needed at the moment
                briefName={bgName}
                handleSearchDescriptionChange={handleSearchDescriptionChange}
                background={background}
                sortByOrder={sortByOrder}
                setBackground={setBackground}
                setPreviousBackground={setPreviousBackground}
              />
              {/* <div className="flex">
                <div>
                  <table className="bg-gray-200 mb-2">
                    <thead>
                      <tr className="flex flex-grow px-2 py-2 font-medium space-x-2">
                        <th className="text-center">Item No.</th>
                        <th className="flex text-center">
                          Date &nbsp;
                          {ascDesc == null ? (
                            <FaSort
                              className="cursor-pointer"
                              alt="Sort"
                              title="Sort"
                              onClick={SortBydate}
                            />
                          ) : ascDesc === true ? (
                            <BsSortUpAlt
                              className="cursor-pointer"
                              alt="Sort"
                              title="Sort"
                              onClick={SortBydate}
                            />
                          ) : (
                            <BsSortDown
                              className="cursor-pointer"
                              alt="Sort"
                              title="Sort"
                              onClick={SortBydate}
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="ml-auto right-0">
                  <ShareLinkButton briefId={brief_id} matter_id={matter_id} />
                </div>
              </div> */}
              {/* {wait ? (
                <> */}
              {background?.length === 0 ? (
                <div className="bg-white rounded-lg py-5 mr-5 flex min-h-screen">
                  <div
                    className="relative flex flex-col h-min w-full"
                    style={{ scrollBehavior: "smooth" }}
                  >
                    <BlankStateMobile
                      header={"There are no items to show in this view."}
                      content={
                        "Any added files in the desktop will appear here"
                      }
                      svg={Illustration}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="bg-white rounded-lg py-5 mr-5 flex"
                  style={{ height: contentHeight }}
                >
                  <div
                    id="mobileContent"
                    onScroll={(e) => handleScrollEvent(e)}
                    className="relative flex flex-col overflow-y-auto h-min w-full"
                    style={{ scrollBehavior: "smooth" }}
                  >
                    {showScrollButton ? (
                      <>
                        <div
                          className="scrollButtonInner flex"
                          onClick={() => handleScrollToTop()}
                        >
                          <BiArrowToTop
                            style={{
                              color: "white",
                              display: "block",
                              margin: "auto",
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="" style={{ height: window.innerHeight }}>
                      {(() => {
                        switch (bgName) {
                          case "Variations":
                            return (
                              <VariationsContentComponent
                                background={background}
                                readMoreState={readMoreState}
                                isReadMoreExpanded={isReadMoreExpanded}
                                setSaving={setSaving}
                                getRowLabels={getRowLabels}
                                handleDescContent={handleDescContent}
                                handleReadMoreState={handleReadMoreState}
                                handleChangeDesc={handleChangeDesc}
                                handleSaveDesc={handleSaveDesc}
                                handleSnippetEmail={handleSnippetEmail}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                columns={columns}
                              />
                            );
                          case "Contracts":
                            return (
                              <ContractsContentComponent
                                background={background}
                                readMoreState={readMoreState}
                                isReadMoreExpanded={isReadMoreExpanded}
                                setSaving={setSaving}
                                getRowLabels={getRowLabels}
                                handleDescContent={handleDescContent}
                                handleReadMoreState={handleReadMoreState}
                                handleChangeDesc={handleChangeDesc}
                                handleSaveDesc={handleSaveDesc}
                                handleSnippetEmail={handleSnippetEmail}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                columns={columns}
                                getBackground={getBackground}
                                brief_id={brief_id}
                                briefName={bgName}
                                sortByOrder={sortByOrder}
                                setBackground={setBackground}
                                setPreviousBackground={setPreviousBackground}
                                matter_id={matter_id}
                              />
                            );

                          case "Invoices":
                            return (
                              <InvoicesContentComponent
                                background={background}
                                readMoreState={readMoreState}
                                isReadMoreExpanded={isReadMoreExpanded}
                                setSaving={setSaving}
                                getRowLabels={getRowLabels}
                                handleDescContent={handleDescContent}
                                handleReadMoreState={handleReadMoreState}
                                handleChangeDesc={handleChangeDesc}
                                handleSaveDesc={handleSaveDesc}
                                handleSnippetEmail={handleSnippetEmail}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                columns={columns}
                                getBackground={getBackground}
                                brief_id={brief_id}
                                briefName={bgName}
                                sortByOrder={sortByOrder}
                                setBackground={setBackground}
                                setPreviousBackground={setPreviousBackground}
                                matter_id={matter_id}
                              />
                            );

                          case "Site Diary":
                            return (
                              <SiteDiaryContentComponent
                                background={background}
                                readMoreState={readMoreState}
                                isReadMoreExpanded={isReadMoreExpanded}
                                setSaving={setSaving}
                                getRowLabels={getRowLabels}
                                handleDescContent={handleDescContent}
                                handleReadMoreState={handleReadMoreState}
                                handleChangeDesc={handleChangeDesc}
                                handleSaveDesc={handleSaveDesc}
                                handleSnippetEmail={handleSnippetEmail}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                columns={columns}
                              />
                            );

                          case "Progress Photos":
                            return (
                              <ProgressPhotosContentComponent
                                background={background}
                                readMoreState={readMoreState}
                                isReadMoreExpanded={isReadMoreExpanded}
                                setSaving={setSaving}
                                getRowLabels={getRowLabels}
                                handleDescContent={handleDescContent}
                                handleReadMoreState={handleReadMoreState}
                                handleChangeDesc={handleChangeDesc}
                                handleSaveDesc={handleSaveDesc}
                                handleSnippetEmail={handleSnippetEmail}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                columns={columns}
                                setShowToast={setShowToast}
                                setalertMessage={setalertMessage}
                                setBackground={setBackground}
                                briefs={briefs}
                                matterId={matter_id}
                              />
                            );

                          case "Progress of work":
                            return (
                              <ProgressOfWorkContentComponent
                                background={background}
                                readMoreState={readMoreState}
                                isReadMoreExpanded={isReadMoreExpanded}
                                setSaving={setSaving}
                                getRowLabels={getRowLabels}
                                handleDescContent={handleDescContent}
                                handleReadMoreState={handleReadMoreState}
                                handleChangeDesc={handleChangeDesc}
                                handleSaveDesc={handleSaveDesc}
                                handleSnippetEmail={handleSnippetEmail}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                columns={columns}
                              />
                            );
                          // Add more cases for other backgrounds as needed
                          default:
                            return (
                              <MobileTableInfo
                                background={background}
                                readMoreState={readMoreState}
                                isReadMoreExpanded={isReadMoreExpanded}
                                setSaving={setSaving}
                                getRowLabels={getRowLabels}
                                handleDescContent={handleDescContent}
                                handleReadMoreState={handleReadMoreState}
                                handleChangeDesc={handleChangeDesc}
                                handleSaveDesc={handleSaveDesc}
                                handleSnippetEmail={handleSnippetEmail}
                                vTablePaddingTop={vTablePaddingTop}
                                vTablePaddingBottom={vTablePaddingBottom}
                                virtualRows={virtualRows}
                                rows={rows}
                                tableContainerRef={tableContainerRef}
                                rowVirtualizer={rowVirtualizer}
                                columns={columns}
                                matter_id={matter_id}
                                briefId={briefId}
                                isSiteDiary={isSiteDiary}
                                handleChangeLabel={handleChangeLabel}
                                autoAdjustAllRow={autoAdjustAllRow}
                              />
                            );
                        }
                      })()}
                    </div>

                    {false &&
                      background.map((item, index, arr) => (
                        <div className="w-full px-5" key={item.id}>
                          <div
                            className="flex flex-row"
                            style={{
                              borderBottomWidth:
                                index + 1 !== arr.length ? 2 : 0,
                              borderBottomStyle: "dashed",
                              paddingTop: index === 0 ? 0 : 20,
                              paddingBottom: 20,
                            }}
                          >
                            <p className="font-semibold text-cyan-400">
                              {index + 1}
                            </p>
                            <div className="ml-2">
                              <p className="font-medium text-cyan-400">
                                {item.date !== null && item.date !== ""
                                  ? dateFormatter(item.date)
                                  : "No date"}
                              </p>
                              {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
                              <p
                                id={item.id + ".desc"}
                                className="absolute text-red-200 invisible pointer-events-none opacity-0"
                                style={{
                                  top: -10000,
                                  zIndex: -1000,
                                  marginRight: "20px",
                                  wordBreak: "break-word",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></p>

                              <p
                                className={
                                  isReadMoreExpanded(item.id)
                                    ? ""
                                    : "line-clamp-6"
                                }
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                                style={{
                                  wordBreak: "break-word",
                                  outlineColor: "rgb(204, 204, 204, 0.5)",
                                  outlineWidth: "thin",
                                }}
                                contentEditable={true}
                                suppressContentEditableWarning
                                onClick={(event) => {
                                  handleDescContent(
                                    event,
                                    item.description,
                                    item.id,
                                    index
                                  );
                                  setSaving(true);
                                }}
                                onFocus={() => {
                                  setSaving(true);
                                  //To auto expand the div when editing
                                  if (
                                    readMoreState.includes(item.id) === false
                                  ) {
                                    handleReadMoreState(item.id);
                                  }
                                }}
                                onInput={(event) =>
                                  handleChangeDesc(event, index)
                                }
                                onBlur={(e) => {
                                  handleSaveDesc(
                                    e,
                                    item.description,
                                    item.date,
                                    item.id,
                                    index
                                  );
                                  setSaving(false);
                                }}
                              ></p>
                              <button
                                id={item.id + ".descButton"}
                                className="text-cyan-400 mb-2 inline-block"
                                onClick={() => handleReadMoreState(item.id)}
                              >
                                {isReadMoreExpanded(item.id)
                                  ? "read less..."
                                  : "read more..."}
                              </button>
                              {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
                              <p
                                id={item.id + ".files"}
                                className="absolute text-red-200 invisible pointer-events-none opacity-0 break-words"
                                style={{
                                  top: -10000,
                                  zIndex: -1000,
                                  marginRight: "20px",
                                  lineHeight: "30px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {item.files.items.map((file) => (
                                  <button
                                    key={file.id}
                                    className="font-extralight text-sm text-red-400 border rounded-lg px-2 mr-2 my-1"
                                    onClick={() => handleSnippetEmail(file)}
                                  >
                                    {file.name}&nbsp;
                                    <AiOutlineDownload className="text-gray-400 text-sm cursor-pointer inline-block" />
                                  </button>
                                ))}
                              </p>
                              <p
                                className={
                                  (isReadMoreExpanded(item.id)
                                    ? ""
                                    : "line-clamp-1") + " break-words"
                                }
                                style={{
                                  lineHeight: "30px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {item.files.items.map((file) => (
                                  <button
                                    key={file.id}
                                    className="font-extralight text-sm focus:text-cyan-400 focus:border-cyan-400 text-gray-400 border rounded-lg px-2 mr-2 my-1"
                                    onClick={() => handleSnippetEmail(file)}
                                  >
                                    {file.name}&nbsp;
                                    <AiOutlineDownload className="text-sm cursor-pointer inline-block" />
                                  </button>
                                ))}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {/* </>
              ) : (
                <>
                  <div
                    className="bg-white rounded-lg py-5 mr-5 flex"
                    style={{ height: contentHeight }}
                  >
                    <div className="w-full">
                      <div className="p-5  items-center top-0">
                        <SkeletonTheme width={"275px"} height={"25px"}>
                          <Skeleton className="mb-1" count={2} />
                        </SkeletonTheme>
                      </div>
                      <div className="p-5  items-center top-0">
                        <SkeletonTheme width={"275px"} height={"25px"}>
                          <Skeleton className="mb-1" count={2} />
                        </SkeletonTheme>
                      </div>
                      <div className="p-5  items-center top-0">
                        <SkeletonTheme width={"275px"} height={"25px"}>
                          <Skeleton className="mb-1" count={2} />
                        </SkeletonTheme>
                      </div>
                      <div className="p-5  items-center top-0">
                        <SkeletonTheme width={"275px"} height={"25px"}>
                          <Skeleton className="mb-1" count={2} />
                        </SkeletonTheme>
                      </div>
                      <div className="p-5  items-center top-0">
                        <SkeletonTheme width={"275px"} height={"25px"}>
                          <Skeleton className="mb-1" count={2} />
                        </SkeletonTheme>
                      </div>
                      <div className="p-5  items-center top-0">
                        <SkeletonTheme width={"275px"} height={"25px"}>
                          <Skeleton className="mb-1" count={2} />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
            </>
          )}
        </div>

        {show && (
          <MobileEmailPreview
            show={show}
            setShow={setShow}
            snippetId={snippetId}
            gmailMessageId={snippetId}
            preview={preview}
            from={from}
            dateEmail={dateEmail}
            subject={subject}
            to={to}
            cc={cc}
            downloadLink={downloadLink}
            setDownloadLink={setDownloadLink}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            previewAndDownloadFile={previewAndDownloadFile}
            previewAndDownloadPDF={previewAndDownloadPDF}
            handleClose={handleClose}
          />
        )}
        {showUnviewable && (
          <DownloadUnviewablFileModal
            unviewableFileLink={unviewableFileLink}
            setUnviewableFileLink={setUnviewableFileLink}
            setShowUnviewableFileModal={setShowUnviewable}
          />
        )}

        {showContractAdviceNotif && (
          <ContractReviewNotificationModal
          // unviewableFileLink={unviewableFileLink}
          // setUnviewableFileLink={setUnviewableFileLink}
          // setShowUnviewableFileModal={setShowUnviewable}
          />
        )}

        {showToast && (
          <ToastNotification title={alertMessage} hideToast={hideToast} />
        )}
        {showSessionTimeout && <SessionTimeout />}
      </div>
      {/* <div style={{display: showDocumentCreator ? 'initial' : 'none'}}>
        <CreateDocumentPage showState={showDocumentCreator} changeState={setDocumentCreator}/>
      </div> */}
      {pressedQSearch && (
        <div className="z-50 searchBar overflow-y-hidden fixed top-3 right-3 flex flex-row items-center rounded-md border border-gray-500 h-10 w-80 p-1	">
          <input
            type="text"
            value={qSearchValue}
            className="outline-none  relative h-9 w-px flex-1 flex-shrink flex-grow self-center rounded rounded-l-none border-0 pl-1 text-sm leading-normal"
            placeholder="Search keywords"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                document.getElementById("nextBtn").dispatchEvent(
                  new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                  })
                );
              }
            }}
            onChange={(e) => {
              setQSearchValue(e.target.value);
              if (e.target.value == "") {
                setQSearchPtr(0);
              }
            }}
            onInput={(e) => {}}
            onKeyUp={(e) => {}}
            style={{ maxWidth: "100%" }} // Set the maximum width here
          />
          {qSearchPtr > 0 && typeof qSearchValue !== "undefined" ? (
            <div>
              {qSearchPtr}/{totalHits}
            </div>
          ) : (
            <div>0/0</div>
          )}
          <div
            className="text-3xl text-gray-700 -mt-1 -mb-1"
            style={{ userSelect: "none" }}
          >
            |
          </div>
          <AiOutlineUp
            size={19}
            className="mx-1 cursor-pointer hover:bg-gray-200 rounded-2xl"
            onClick={() => {
              if (qSearchPtr > 1) {
                setQSearchPtr((prevPtr) => {
                  return (prevPtr -= 1);
                });
              }
            }}
          />
          <AiOutlineDown
            size={19}
            className="mx-1 cursor-pointer hover:bg-gray-200 rounded-2xl"
            id="nextBtn"
            onClick={() => {
              if (qSearchPtr < totalHits) {
                setQSearchPtr((prevPtr) => {
                  return (prevPtr += 1);
                });
              }
            }}
          />
          <MdClear
            size={19}
            className="mx-1 cursor-pointer hover:bg-gray-200 rounded-2xl"
            onClick={() => {
              setPressedQSearch(false);
              setQSearchValue(undefined);
            }}
          />
        </div>
      )}

      {showFilePreview && (
        <FilePreviewModal
          item={filePreviewItems[0]}
          items={filePreviewItems[1]}
          iframeVariables={filePreviewItems[2]}
          setShowFilePreview={setShowFilePreview}
        />
      )}

      {show && (
        <MobileEmailPreview
          show={show}
          setShow={setShow}
          snippetId={snippetId}
          gmailMessageId={snippetId}
          preview={preview}
          from={from}
          dateEmail={dateEmail}
          subject={subject}
          to={to}
          cc={cc}
          downloadLink={downloadLink}
          setDownloadLink={setDownloadLink}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          previewAndDownloadFile={previewAndDownloadFile}
          previewAndDownloadPDF={previewAndDownloadPDF}
          handleClose={handleClose}
        />
      )}

      {showDateFilter && (
        <FiltersModal
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          filteredLabels={filteredLabels}
          setFilteredLabels={setFilteredLabels}
          labels={labels}
          handleSave={handleSave}
          handleClearFilter={handleClearFilter}
          invalidState={invalidState}
          setInvalidState={setInvalidState}
          invalidDateRange={invalidDateRange}
          setInvalidDateRange={setInvalidDateRange}
          handleDateFilterModal={handleDateFilterModal}
          showDateFilter={showDateFilter}
          setShowDateFilter={setShowDateFilter}
          getBackground={() => getBackground()}
          handleFilterLabels={handleFilterLabels}
          gmailFilterProp={gmailFilter}
          setgmailFilterProp={setgmailFilter}
        />
      )}
    </>
  );
};

//===============================================================
//Each Row of the Background Brief as a seperate Card Component
//===============================================================
function BackgroundBriefCard({
  background,
  style,
  item,
  index,
  readMoreState,
  isReadMoreExpanded,
  setSaving,
  getRowLabels,
  handleDescContent,
  handleReadMoreState,
  handleChangeDesc,
  handleSaveDesc,
  handleSnippetEmail,
  virtualRows,
  rows,
}) {
  const [openMoreLabels, setOpenMoreLabels] = useState(false);

  // virtualRows.map((virtualRow) => {
  //   const row = rows[virtualRow.index];
  //   const item = row.original;
  //   const index = row.index;

  return (
    <div
      key={item.id}
      className="flex flex-row w-full px-5"
      style={{
        ...style,
        width: "100%",
        borderBottomWidth: index + 1 !== background?.length ? 2 : 0,
        borderBottomStyle: "dashed",
        paddingTop: index === 0 ? 0 : 20,
        paddingBottom: 20,
        maxHeight: "350px",
      }}
    >
      <p className="font-semibold text-cyan-400">{index + 1}</p>
      <div className="ml-2 overflow-y-auto">
        {/* Date */}
        <p className="font-medium text-cyan-400">
          {item.date !== null && item.date !== ""
            ? dateFormatter(item.date)
            : "No date"}
        </p>
        {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
        <p
          id={item.id + ".desc"}
          className="absolute text-red-200 invisible pointer-events-none opacity-0"
          style={{
            top: -10000,
            zIndex: -1000,
            marginRight: "20px",
            wordBreak: "break-word",
          }}
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
        ></p>

        {/* Description */}
        <p
          id={item.id + ".descM"}
          className={`overflow-y-auto ${
            !isReadMoreExpanded(item.id) ? "line-clamp-6" : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
          style={{
            wordBreak: "break-word",
            outlineColor: "rgb(204, 204, 204, 0.5)",
            outlineWidth: "thin",
          }}
          contentEditable={true}
          suppressContentEditableWarning
          onClick={(event) => {
            handleDescContent(event, item.description, item.id, index);
            setSaving(true);
          }}
          onFocus={() => {
            setSaving(true);
            //To auto expand the div when editing
            if (readMoreState.includes(item.id) === false) {
              handleReadMoreState(item.id);
            }
          }}
          onInput={(event) => handleChangeDesc(event, index)}
          onBlur={(e) => {
            handleSaveDesc(e, item.description, item.date, item.id, index);
            setSaving(false);
          }}
        ></p>

        {/* Read More Button */}
        {item.description?.length > 70 && (
          <button
            id={item.id + ".descButton"}
            className="text-cyan-400 mb-2 inline-block"
            onClick={() => {
              handleReadMoreState(item.id);
            }}
          >
            {isReadMoreExpanded(item.id) ? "read less..." : "read more..."}
          </button>
        )}

        {/* INVISIBLE DIV TO GET INITIAL DIV HEIGHT */}
        <p
          id={item.id + ".files"}
          className="absolute text-red-200 invisible pointer-events-none opacity-0 break-words"
          style={{
            top: -10000,
            zIndex: -1000,
            marginRight: "20px",
            lineHeight: "30px",
            wordBreak: "break-word",
          }}
        >
          {item.files.items.map((file) => (
            <button
              key={file.id}
              className="font-extralight text-sm text-red-400 border rounded-lg px-2 mr-2 my-1"
              onClick={() => handleSnippetEmail(file)}
            >
              {file.name}&nbsp;
              <AiOutlineDownload className="text-gray-400 text-sm cursor-pointer inline-block" />
            </button>
          ))}
        </p>

        {/* Files List */}
        <p
          className={
            (isReadMoreExpanded(item.id) ? "" : "line-clamp-1") + " break-words"
          }
          style={{
            lineHeight: "30px",
            wordBreak: "break-word",
          }}
        >
          {item.files.items.map((file) => (
            <button
              key={file.id}
              className="font-extralight text-sm focus:text-cyan-400 focus:border-cyan-400 text-gray-400 border rounded-lg px-2 mr-2 my-1"
              onClick={() => handleSnippetEmail(file)}
            >
              {file.name}&nbsp;
              <AiOutlineDownload className="text-sm cursor-pointer inline-block" />
            </button>
          ))}
        </p>

        {/* Labels List */}
        <button
          className="w-full"
          onClick={() => setOpenMoreLabels(!openMoreLabels)}
        >
          <div
            id={"label-cell-selected-no-" + index}
            className="pt-3 text-xs flex flex-wrap " //justify-center
          >
            {item?.briefs &&
              item?.briefs?.length != 0 &&
              item?.briefs?.map((elem, index) =>
                index < 3 ? (
                  <span
                    key={index}
                    className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
                    style={{
                      gap: "8px",
                      padding: "4px 8px",
                      backgroundColor: "#F3F3F3",
                      borderRadius: "5px",
                    }}
                  >
                    {getRowLabels(item?.briefs)[index].label}
                  </span>
                ) : null
              )}
            {item?.briefs?.length > 3 && (
              <span
                className="mx-1 bg-gray-200 rounded-sm text-xs mt-1"
                style={{
                  gap: "8px",
                  padding: "4px 8px",
                  backgroundColor: "#F3F3F3",
                  borderRadius: "5px",
                }}
              >
                +{item?.briefs?.length - 3}
              </span>
            )}
          </div>
        </button>
      </div>
      {openMoreLabels && (
        <div
          className="absolute bottom-0 w-52 h-auto p-3 flex flex-col gap-1 rounded-md overflow-y-auto bg-white z-50"
          style={{
            boxShadow:
              "0px 3px 38px 0px rgba(0,0,0,0.15), 0px 6px 12px 0px rgba(0,0,0,0.18)",
            transform: "translateY(95%)",
            maxHeight: "12rem",
          }}
        >
          <p className="px-1 py-1 text-xs text-gray-400 font-semibold border-b border-gray-500">
            Labels
          </p>
          {item?.briefs &&
            item?.briefs?.length != 0 &&
            item?.briefs?.map((elem, index) => (
              <span
                key={index}
                className="text-s w-full py-1 border-b border-dashed border-gray-300 truncate"
              >
                {getRowLabels(item?.briefs)[index].label}
              </span>
            ))}
        </div>
      )}
    </div>
  );

  // })
}

export default Background;
