import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { MdRestore } from "react-icons/md";
import { CgChevronLeft } from "react-icons/cg";
import { IoIosSearch } from "react-icons/io";
import dateFormat from "dateformat";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Button from "../../shared/Button";
import RestoreMatterModal from "./restore-matter-modal";
import ToastNotification from "../toast-notification";
import {
  isMobileDevice,
  useWindowDimensions,
} from "../../shared/mobileViewFunctions";
import { dateFormatter } from "../../shared/userPreferredDateTime";
// import { SidebarData } from "../sidebar/SidebarData";
import BlankState from "../dynamic-blankstate/index.jsx";
import useSidebarData from "../sidebar/useSidebarData.js";

export default function ArchivedClientMatters() {
  const [clientMatters, setClientMatters] = useState([]);
  const [activeMatters, setActiveMatters] = useState([]);
  const [skeletalLoading, setSkeletalLoading] = useState(true);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [matterIdState, setMatterIdState] = useState();
  const [matterNameState, setMatterNameState] = useState();
  const [clientNameState, setClientNameState] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [filteredClientMatter, setFilteredSearchClientMatter] = useState([]);
  const { height, width } = useWindowDimensions();
  const [contentHeight, setContentHeight] = useState();



  let SidebarData = useSidebarData(localStorage.getItem('userCategory'))
  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  const qArchivedMatters = `
    query listArchivedClientMatters($id: String, $isDeleted: Boolean, $sortOrder: OrderBy = CREATED_DESC) {
        company(id: $id) {
          clientMatters(isDeleted: $isDeleted, sortOrder: $sortOrder) {
            items {
              id
              createdAt
              updatedAt
              client {
                id
                name
              }
              matter {
                id
                name
              }
            }
          }
        }
      }`;

  const qRestoreClientMatter = `
  mutation restoreClientMatter($id: ID, $isDeleted: Boolean) {
    clientMatterSoftDeleteOrRestore(id: $id, isDeleted: $isDeleted)
  }`;

  const getArchivedMatters = () => {
    setSkeletalLoading(true);
    const Data = API.graphql({
      query: qArchivedMatters,
      variables: {
        id: localStorage.getItem("companyId"),
        isDeleted: true,
      },
    }).then((result) => {
      setClientMatters(result.data.company.clientMatters.items);
      setFilteredSearchClientMatter(result.data.company.clientMatters.items);
      console.log("Archives ", result);
      setSkeletalLoading(false);
    });
  };

  const getActiveMatters = () => {
    const Data = API.graphql({
      query: qArchivedMatters,
      variables: {
        id: localStorage.getItem("companyId"),
        isDeleted: false,
      },
    }).then((result) => {
      let tempActive = [];
      console.log("Result Active: ", result.data.company.clientMatters.items);
      result.data.company.clientMatters.items.map((item) => {
        let cmLabels = item?.client?.name + "/" + item?.matter?.name;
        tempActive.push(cmLabels);
      });
      setActiveMatters(tempActive);
      console.log("active matters", activeMatters);
      setSkeletalLoading(false);
    });
  };

  const restoreArchivedMatter = (matterId) => {
    const Data = API.graphql({
      query: qArchivedMatters,
      variables: {
        id: localStorage.getItem("companyId"),
        isDeleted: false,
      },
    }).then((result) => {
      let tempActive = [];
      console.log("Result Active: ", result.data.company.clientMatters.items);
      result.data.company.clientMatters.items.map((item) => {
        let cmLabels = item?.client?.name + "/" + item?.matter?.name;
        tempActive.push(cmLabels);
      });
      console.log("active matters", activeMatters);

      const restoreChecker = clientNameState + "/" + matterNameState;
      if (tempActive.includes(restoreChecker)) {
        console.log("found");
        setToastMessage(restoreChecker + "already exists in active matters");
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 3000);
      } else {
        console.log("not found");
        const data = API.graphql({
          query: qRestoreClientMatter,
          variables: {
            id: matterId,
            isDeleted: false
          },
        }).then((result) => {
          console.log("RESTORED: ", result);
          getArchivedMatters();
          setToastMessage("Successfully restored Client/Matter");
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 3000);
        });
      }
    });
  };

  const handleSearchClientMatterChange = (e) => {
    const filterClientMatter = clientMatters.filter(
      (clientMatter) =>
        clientMatter?.matter?.name
          .toUpperCase()
          .includes(e.target.value.toUpperCase()) ||
        clientMatter?.client?.name
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
    );
    setFilteredSearchClientMatter(filterClientMatter);
  };

  const handleRestore = (id, matterName, clientName) => {
    setMatterIdState(id);
    setMatterNameState(matterName);
    setClientNameState(clientName);
    setShowRestoreModal(true);
  };

  useEffect(() => {
    getArchivedMatters();
    getActiveMatters();
  }, []);

  useEffect(() => {
    var headerTag = document.getElementById("headerTag");
    setContentHeight(height - 20 - headerTag.offsetHeight);
  }, [height, width]);

  return (
    <>
      <div className="p-5 bg-gray-100 sm:ml-16 sm:p-0 sm:pl-5 sm:bg-white overflow-hidden min-h-screen">
        <div
          id="headerTag"
          className="z-10 sm:z-auto flex z-12 justify-between pb-5 pt-10 px-3 sm:px-6 items-center fixed sm:sticky w-full top-0 right-5 bg-gray-100 sm:bg-white overflow-hidden"
        >
          <div className="flex gap-2 w-full items-center justify-end sm:justify-start">
            <Link to={dashboardPath}>
              <CgChevronLeft />
            </Link>
            <h1 className="font-bold text-right text-base sm:text-2xl">
              Archived Matters
            </h1>
          </div>
          {/* Desktop Search */}
          <div className="hidden w-1/2 mb-2 sm:px-2 sm:flex">
            <span
              style={{ zIndex: "8" }}
              className="leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 py-3 px-3"
            >
              <IoIosSearch />
            </span>
            <input
              type="search"
              placeholder="Search Matter or Client ..."
              onChange={handleSearchClientMatterChange}
              className="px-3 py-3 placeholder-gray-500 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring-2 ring-blue-500 ring-offset-4 w-full pl-10"
            />
          </div>
        </div>
        <div
          className="bg-white rounded-md mt-16 sm:mt-0 py-5 px-6 h-fit"
          style={{ minHeight: isMobileDevice(width) ? contentHeight : "" }}
        >
          {/* Mobile Search */}
          <div className="flex w-full mb-2 sm:px-2 sm:hidden">
            <span
              style={{ zIndex: "8" }}
              className="leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 py-3 px-3"
            >
              <IoIosSearch />
            </span>
            <input
              type="search"
              placeholder="Search Matter or Client ..."
              onChange={handleSearchClientMatterChange}
              className="px-3 py-3 placeholder-gray-500 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring-2 ring-blue-500 ring-offset-4 w-full pl-10"
            />
          </div>
          <div className="flex flex-col sm:grid sm:grid-cols-4">
            {skeletalLoading ? (
              <>
                {[...Array(16).keys()].map((item) => (
                  <>
                    <div className="h-auto w-auto sm:w-auto bg-gray-200 my-2 sm:m-2 rounded-lg p-5 hover:shadow-md cursor-pointer">
                      <div className="flex justify-between">
                        <div>
                          <SkeletonTheme height={"20px"} width={"150px"}>
                            <Skeleton />
                          </SkeletonTheme>
                          <SkeletonTheme height={"15px"} width={"100px"}>
                            <Skeleton />
                          </SkeletonTheme>
                        </div>

                        <SkeletonTheme height={"20px"} width={"20px"}>
                          <Skeleton />
                        </SkeletonTheme>
                      </div>
                      <div className="text-xs mt-4">
                        <SkeletonTheme height={"20px"} width={"200px"}>
                          <Skeleton />
                        </SkeletonTheme>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : filteredClientMatter !== null ? (
              filteredClientMatter?.length === 0 ? (
              <>
                <div className="ml-7 py-5">No result found</div>
              </>
              ) : (
                <>
                  {filteredClientMatter.map((item, index) => (
                  <>
                    <div className="h-auto w-full sm:w-auto bg-gray-200 my-2 sm:m-2 rounded-lg p-5 hover:shadow-md cursor-pointer">
                      <div className="flex justify-between">
                        <div>
                          <p className="font-medium">{item?.matter?.name}</p>
                          <p className="font-light">{item?.client?.name}</p>
                        </div>

                        <Button
                          variant="primary-l"
                          className="rounded-full cursor-pointer hover:bg-gray-50 p-2"
                          onClick={() => {
                            //restoreArchivedMatter(item?.id);
                            handleRestore(
                              item?.id,
                              item?.matter?.name,
                              item?.client?.name
                            );
                          }}
                        >
                          <MdRestore />
                        </Button>
                      </div>
                      <div className="text-xs mt-4">
                        Created on {dateFormatter(item.createdAt)}
                      </div>
                    </div>
                  </>
                  ))}
                </>
              )
            ) : ( <></> )}
          </div>
        </div>
      </div>
      {showRestoreModal && (
        <RestoreMatterModal
          handleRestore={() => restoreArchivedMatter(matterIdState)}
          handleClose={() => setShowRestoreModal(false)}
        />
      )}

      {showToast && <ToastNotification title={toastMessage} />}
      {showErrorToast && (
        <ToastNotification title={toastMessage} error={true} />
      )}
    </>
  );
}
