import React, { useState, useEffect, useRef } from "react";
import anime from "animejs";
import { FiEdit } from "react-icons/fi";
import { IoCaretDown } from "react-icons/io5";
import { isMobileDevice } from "../../../shared/mobileViewFunctions";
import { MdSave, MdClose } from "react-icons/md";
import { API } from "aws-amplify";
import { CgClose, CgTrash } from "react-icons/cg";
import Select, { components } from "react-select";
import { RiAddCircleFill } from "react-icons/ri";
import { Tab } from "@headlessui/react";
import TeamClientMatter from "./teams-client-matter";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { GetTeamCM, GetUserCM } from "../../../shared/graphql/queries";

const companyId = localStorage.getItem("companyId");

const options = [
  { value: "No Selected", label: "No Selected" },
  { value: "Thomas Anderson", label: "Thomas Anderson" },
  { value: "Erin Drinkwater", label: "Erin Drinkwater" },
  { value: "Freddy", label: "Newandyke" },
];

const options2 = [
  { value: "No Selected", label: "No Selected" },
  { value: "Lawyer", label: "Lawyer" },
  { value: "Owner", label: "Owner" },
];

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

const LOCAL_STORAGE_KEY = "clientApp.teams";

const mTeamClientMatterTag = `
  mutation teamClientMatterTage($teamId: ID, $clientMatters: [ID]) {
    teamClientMatterTag(teamId: $teamId, clientMatters: $clientMatters) {
      id
    }
  }
`;

const mTagClientMatter = `
    mutation tagUserClientMatterAccess($clientMatterAccess: [UserClientMatterAccessInput], $userId: ID, $companyId: ID) {
      userClientMatterTag(userId: $userId, clientMatterAccess: $clientMatterAccess, companyId: $companyId) {
        id
      }
    }
  `;

// const qGetTeamsWithMembers = `
//   query getTeamMembers($id: ID, $companyId: String) {
//   team(id: $id) {
//     id
//     name
//     members {
//       items {
//         userType
//         user {
//           id
//           firstName
//           lastName
//           userType
//           profilePicture
//           clientMatterAccess(companyId: $companyId) {
//             items {
//               id
//               userType
//               clientMatter {
//                 id
//                 client {
//                   id
//                   name
//                 }
//                 matter {
//                   id
//                   name
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     clientMatters {
//       items {
//         id
//         client {
//           name
//         }
//         matter {
//           name
//         }
//       }
//     }
//   }
// }`;

const qUserClientMatter = `
  query getUserClientMatter($companyId: String, $userId: String) {
    user (id: $userId) {
      id
      profilePicture
      clientMatterAccess (companyId: $companyId) {
        items {
          userType
          clientMatter {
            id
            client {
              id
              name
            }
            matter {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default function TeamsEditModal({
  close,
  setTeamList,
  TeamList,
  CurrentTeam,
  getCompanyUsers,
  CompanyUsers,
  UserTypes,
  tagTeamMember,
  getTeams,
  setisLoading,
  setalertMessage,
  setShowToast,
  setTeamOptions,
  width,
}) {
  const modalContainer = useRef(null);
  const modalContent = useRef(null);
  const [isEditing, setisEditing] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [TeamName, setTeamName] = useState("");
  const [IsHovering, setIsHovering] = useState(false);
  const [InputData, setInputData] = useState([]);
  const [Image, setImage] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [FinalData, setFinalData] = useState([]);
  const inputFile = useRef(null);
  const [Options, setOptions] = useState([]);
  const [Length, setLength] = useState(0);
  const [tabOpen, setTabOpen] = useState(0);
  const [clientMatterList, setClientMatterList] = useState([]);
  const [inputCMList, setInputCMList] = useState([]);
  const [loading, setLoading] = useState(false);

  const mUpdateTeam = `mutation updateTeam($id: ID, $name: String) {
  teamUpdate(name: $name, id: $id) {
    id
  }
} `;

  const updateTeamName = async (id, name) => {
    const teamName = await API.graphql({
      query: mUpdateTeam,
      variables: {
        name: name,
        id: id,
      },
    });

    console.log("mUpdateTeamName", teamName);
    if (teamName) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (teamid, members) => {
    setisDisabled(true);
    setLoading(true);
    console.log("TeamList", TeamList);
    let foundIndex = TeamList.findIndex((x) => x.id === CurrentTeam.id);

    let team = {
      id: TeamList[foundIndex].id,
      name: TeamName.replace("'s team", ""),
      members: {
        items: InputData.map((x) => {
          return { user: x.user, userType: x.userType };
        }),
      },
      clientMatters: {
        items: inputCMList,
      },
    };

    TeamList[foundIndex] = team;

    setTeamList(TeamList);

    console.log("will tag new members", InputData);
   

    const validations =
      InputData && InputData.map((input, i) => validate(input, i));
    const validationsCM =
      inputCMList && inputCMList.map((input, i) => validateCM(input, i));

    const doWork = async () => {
      if (validations.includes(true)) {
        const sFinalData = FinalData.map((data) => {
          return {
            userId: data.userId,
            userType: data.userType,
          };
        });
        console.log("sFinalData", sFinalData);
        await tagTeamMember(CurrentTeam.id, sFinalData)
          .then(async () => {
            await tagClientMatter(CurrentTeam.id);
          })
          .then(async () => {});
        await updateTeamName(CurrentTeam.id, TeamName);
      }
      // if (validationsCM.includes(true) && !(validations.includes(true))) {
      //   await tagClientMatter(CurrentTeam.id)
      //   .then(async () => {
      //     await refreshData()
      //   })
      // }
    };
    await doWork().then(async () => {
      await refreshData();
      setalertMessage("Successfully edited the details");
      setShowToast(true);
      getTeams();
      close();
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    });

    //setisLoading(true);
    //await getTeams();
    //setTimeout(() => {
    //setisLoading(false);
    //}, 1500);
  };

  useEffect(() => {
    console.log("FinalData", FinalData);
  }, [FinalData]);

  let isOperationInProgress = false;

  async function tagClientMatter(id) {
    // Check if the operation is already in progress
    if (isOperationInProgress) {
      console.log("Operation is already in progress");
      return; // Prevent executing the function again
    }

    // Set the operation status to "in progress"
    isOperationInProgress = true;

    // Register the beforeunload event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    try {
      const cmIds = inputCMList.map((inputItem) => inputItem.id);
      const params = {
        query: mTeamClientMatterTag,
        variables: {
          teamId: id,
          clientMatters: cmIds,
        },
      };

      setShowToast(true);
      setalertMessage("Currently saving details..");

      const response = await API.graphql(params);
      console.log("tagClientMatter", response);

      const { items } = CurrentTeam.members;
      const { clientMatters } = CurrentTeam;
      const companyId = localStorage.getItem("companyId");

      await Promise.all(
        items.map(async (users) => {
          const currentClientMatter = users.user.clientMatterAccess?.items.map(
            (item) => ({
              clientMatterId: item.clientMatter.id,
              userType: item.userType,
            })
          );

          const filteredClientMatter = currentClientMatter.filter(
            (clientMatter) =>
              !clientMatters?.items.some(
                (team) => team.id === clientMatter.clientMatterId
              )
          );

          // kjmfEDIT
          const clientMatterAccessParam = filteredClientMatter;

          console.log("@@@@@", clientMatterAccessParam)
          const params = {
            query: mTagClientMatter,
            variables: {
              clientMatterAccess: clientMatterAccessParam,
              userId: users.user.id,
              companyId,
            },
          };
          const response = await API.graphql(params);
          console.log("old user saveTagClientMatter", response);
        })
      );

      await Promise.all(
        FinalData.map(async (users) => {
          const currentClientMatter = users.clientMatterAccess.items.map(
            (item) => ({
              clientMatterId: item.clientMatter.id,
              userType: item.userType,
            })
          );

          const filteredClientMatter = currentClientMatter.filter(
            (clientMatter) =>
              !clientMatters?.items.some(
                (team) => team.id === clientMatter.clientMatterId
              )
          );

          inputCMList.forEach((item) => {
            const existingItem = filteredClientMatter.find(
              (data) =>
                data.clientMatterId === item.id &&
                data.userType === users.userType
            );
            if (!existingItem) {
              filteredClientMatter.push({
                clientMatterId: item.id,
                userType: users.userType,
              });
            }
          });

          const clientMatterAccessParam = filteredClientMatter;
          const params = {
            query: mTagClientMatter,
            variables: {
              clientMatterAccess: clientMatterAccessParam,
              userId: users.userId,
              companyId,
            },
          };
          const response = await API.graphql(params);
          console.log("saveTagClientMatter", response);
        })
      );

      setisDisabled(true);
      setLoading(false);

      // Set the operation status to "completed"
      isOperationInProgress = false;

      // Unregister the beforeunload event listener
      //window.removeEventListener("beforeunload", handleBeforeUnload);
    } catch (error) {
      console.error(error);

      // Set the operation status to "error"
      isOperationInProgress = false;

      // Unregister the beforeunload event listener
      //window.removeEventListener("beforeunload", handleBeforeUnload);
    } finally {
      // Unregister the beforeunload event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);

      setTimeout(() => {
        setShowToast(false);
        close();
      }, 2000);
    }
  }

  function handleBeforeUnload(event) {
    // Cancel the default prompt message
    event.preventDefault();

    // Customize the prompt message
    event.returnValue =
      "The operation is still in progress. Are you sure you want to leave the page?";
  }

  async function refreshData() {
    // const params = {
    //   query: qGetTeamsWithMembers,
    //   variables: {
    //     id: CurrentTeam.id,
    //     companyId: localStorage.getItem("companyId"),
    //   },
    // };
    // const response = await API.graphql(params)
    const response = await GetTeamCM(
      CurrentTeam.id,
      localStorage.getItem("companyId")
    ).then((response) => {
      const CurrentTeam = response.data.team;
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(
          CurrentTeam.members.items.map((x) => {
            console.log("USERUSER", x);
            return {
              userType: x.userType,
              user: {
                firstName: x.user.firstName,
                lastName: x.user.lastName,
                userType: x.user.userType,
                id: x.user.id,
                profilePicture: x.user.profilePicture,
                clientMatterAccess: x.user.clientMatterAccess,
              },
            };
          })
        )
      );
      const stored = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      console.log("stored", stored);
      if (stored?.length > 0) {
        setInputData(stored);
      }

      setTeamName(CurrentTeam.name);
      // setImage(CurrentTeam.image);
      setLength(CurrentTeam.members.items.length);
      setInputCMList(CurrentTeam.clientMatters?.items);
      console.log("Company Users", CompanyUsers);
      console.log("INSIDE User Types", UserTypes);
    });
  }

  useEffect(() => {
    if (CurrentTeam) {
      const inputDataSetting = CurrentTeam?.members?.items.map((x) => {
        console.log("USERUSER", x);
        return {
          userType: x.userType,
          user: {
            firstName: x.user.firstName,
            lastName: x.user.lastName,
            userType: x.user.userType,
            id: x.user.id,
            profilePicture: x.user.profilePicture,
            clientMatterAccess: x.user.clientMatterAccess,
          },
        };
      });
      setInputData(inputDataSetting);
      setTeamName(CurrentTeam.name);
      // setImage(CurrentTeam.image);
      setLength(CurrentTeam.members.items.length);
      setInputCMList(CurrentTeam.clientMatters?.items);
      console.log("Company Users", CompanyUsers);
      console.log("INSIDE User Types", UserTypes);
    }
  }, [CurrentTeam]);

  useEffect((e) => {
    console.log("CURRENT TEAM", CurrentTeam);
    anime({
      targets: modalContainer.current,
      opacity: [0, 1],
      duration: 100,
      easing: "easeInOutQuad",
      complete: () => {
        anime({
          targets: modalContent.current,
          scale: [0.9, 1],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutQuad",
        });
      },
    });
  }, []);

  const RowCard = ({ image, member }) => {
    function userInitial(user) {
      var assigneeInitials = "";
      if ((user.firstName + user.lastName).toLowerCase() === "john dela cruz") {
        assigneeInitials = "JDC";
      } else {
        assigneeInitials += user.firstName ? user.firstName?.charAt(0) : "";
        assigneeInitials += user.lastName ? user.lastName?.charAt(0) : "";
      }
      return assigneeInitials;
    }
    const assigneeInitials = userInitial(member.user);
    return (
      <>
        <div className="flex flex-row text-sm text-gray-500 items-center gap-4 py-2">
          <div className="mr-2 bg-gray-500 flex flex-none text-white text-md font-medium w-8 h-8 rounded-full justify-center items-center cursor-default ">
            {member.user.profilePicture === null ? (
              <>{assigneeInitials.toUpperCase()}</>
            ) : (
              <img
                className="rounded-full w-8 h-8"
                src={member.user.profilePicture}
              />
            )}
          </div>
          {/* <img
            alt={`avatar`}
            src={image}
            width={34}
            height={34}
            className={"rounded-full "}
          ></img> */}
          <div>{member.user.firstName + " " + member.user.lastName}</div>
          <div className="ml-auto uppercase rounded-2xl bg-gray-200 font-semibold px-2 py-1 text-xs text-black">
            {member.userType}
          </div>
        </div>
      </>
    );
  };

  const ChangesHaveMade = (obj, i) => {
    console.log("HIT");
    console.log("obj", obj, "at index:", i);

    if (CurrentTeam.members.items[i]) {
      console.log(
        "CurrentTeam.members.items[i]",
        CurrentTeam.members.items[i].user
      );
      if (
        obj.firstName !== CurrentTeam.members.items[i].user.firstName ||
        obj.lastName !== CurrentTeam.members.items[i].user.lastName ||
        obj.userType !== CurrentTeam.members.items[i].user.userType ||
        TeamName !== CurrentTeam.name
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (obj.firstName !== "" && obj.lastName !== "" && obj.userType !== "") {
        return true;
      }
      return false;
    }
  };

  const validate = (obj, i) => {
    //Detect if null && changes have been made
    //console.log("CALLED");
    //console.log("VALIDATE OBJ", obj);

    console.log("ilen", InputData.length);
    console.log("currlen", CurrentTeam.members.items.length);
    if (CurrentTeam.members.items.length > InputData.length) {
      console.log("HIT |TRUE");
      return true;
    }

    let changes = ChangesHaveMade(obj, i);
    console.log("Changes", changes);
    if (
      obj.firstName !== "" &&
      obj.lastName !== "" &&
      obj.userType !== "" &&
      TeamName &&
      changes
    ) {
      return true;
    } else {
      return false;
    }
  };
  const validateNull = (obj, i) => {
    if (obj.firstName !== "" && obj.lastName !== "" && obj.userType !== "") {
      return true;
    } else {
      return false;
    }
  };

  const ChangesHaveMadeCM = (obj, i) => {
    console.log("HIT");
    console.log("obj", obj, "at index:", i);

    if (CurrentTeam.clientMatters.items[i]) {
      console.log(
        "CurrentTeam.clientMatters.items[i]",
        CurrentTeam.clientMatters.items[i]
      );
      if (obj.id !== CurrentTeam.clientMatters.items[i].id) {
        return true;
      } else {
        return false;
      }
    } else {
      if (obj.client?.name !== "" && obj.matter?.name !== "") {
        return true;
      }
      return false;
    }
  };

  const validateCM = (obj, i) => {
    //Detect if null && changes have been made
    //console.log("CALLED");
    //console.log("VALIDATE OBJ", obj);

    console.log("ilen", inputCMList.length);
    console.log("currlen", CurrentTeam.clientMatters.items.length);
    if (CurrentTeam.clientMatters.items.length > inputCMList.length) {
      console.log("HIT |TRUE");
      return true;
    }

    let changes = ChangesHaveMadeCM(obj, i);
    console.log("Changes", changes);
    if (obj.client?.name !== "" && obj.matter?.name !== "" && changes) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("IDATA", InputData);
    const validations =
      InputData && InputData.map((input, i) => validate(input, i));
    const nullValues =
      InputData && InputData.map((input, i) => validateNull(input, i));
    let oFinal = InputData.map((input) => {
      let final = {
        userId: input.user.id,
        userType: input.userType,
        clientMatterAccess: input.user.clientMatterAccess,
      };

      return final;
    });
    setFinalData(oFinal);
    setisDisabled(!validations?.includes(true));
    if (nullValues?.includes(false)) {
      setisDisabled(true);
    }
    console.log(validations);
  }, [InputData, TeamName, Image, InputData.length]);

  useEffect(() => {
    console.log("FINALDATA", FinalData);
  }, [FinalData]);

  useEffect(() => {
    console.log("inputCMList", inputCMList);
    const validationsCM =
      inputCMList && inputCMList.map((input, i) => validateCM(input, i));
    setisDisabled(!validationsCM.includes(true));
  }, [inputCMList]);

  useEffect(() => {
    let data = InputData.map((input) => {
      return input.user.id;
    });

    setOptions(CompanyUsers.filter((x) => !data.includes(x.id)));
  }, [InputData, CompanyUsers]);

  const buildName = (value) => {
    console.log(value.split(" "));
    let arr = value.split(" ");
    if (arr.length > 2) {
      return {
        firstName: arr[0] + " " + arr[1],
        lastName: arr[2],
      };
    } else {
      return {
        firstName: arr[0],
        lastName: arr[1],
      };
    }
  };

  const handleSelectChange = async (e, val, i, property) => {
    const list = [...InputData];
    if (property === "name") {
      let name = buildName(e.value);
      list[i].user["firstName"] = name.firstName;
      list[i].user["lastName"] = name.lastName;
      list[i].user["id"] = e.id;
    } else {
      list[i][property] = e.value;
    }

    if (!list[i].user?.clientMatterAccess) {
      const response = await GetUserCM(e.id, localStorage.getItem("companyId"));
      console.log("Handle select", response.data);

      list[i].user.clientMatterAccess =
        response?.data?.user?.clientMatterAccess;
      list[i].userType = response?.data?.user?.userType;
    }
    setInputData(list);
  };

  const handleDelete = (index) => {
    setInputData(InputData.filter((_, idx) => idx !== index));
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IoCaretDown className="text-sm" />
        </components.DropdownIndicator>
      )
    );
  };

  const AddMore = (id) => {
    return (
      <button
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          setisDisabled(true);
          setIsHovering(false);
          if (tabOpen === 0) {
            setInputData([
              ...InputData,
              {
                userType: "",
                user: {
                  id: uuidv4(),
                  firstName: "",
                  lastName: "",
                  userType: "",
                },
              },
            ]);
          }
          if (tabOpen === 1) {
            setInputCMList([
              ...inputCMList,
              {
                id: uuidv4(),
                client: {
                  id: uuidv4(),
                  name: "",
                },
                matter: {
                  id: uuidv4(),
                  name: "",
                },
              },
            ]);
            console.log("inputCMList", inputCMList);
          }
        }}
        className={
          "m-2 my-3 font-medium gap-1 mr-auto flex flex-row justify-center items-center text-md text-cyan-500 hover:text-cyan-300 cursor-pointer"
        }
      >
        Add More
        <RiAddCircleFill />
      </button>
    );
  };

  const SaveButton = () => {
    return (
      <button
        onClick={() => {
          /* 
         
          */
          handleSubmit();
        }}
        className={
          isDisabled
            ? "border border-gray-200 ml-auto rounded-md bg-green-200 text-white flex flex-row justify-center items-center gap-2font-normal px-6 py-1.5 mt-2 hover:bg-green-200 gap-2 cursor-default"
            : "border border-gray-200 ml-auto rounded-md bg-green-400 text-white flex flex-row justify-center items-center gap-2font-normal px-6 py-1.5 mt-2 hover:bg-green-200 gap-2"
        }
        disabled={isDisabled}
      >
        Save Changes
        <MdSave color={`white`} />
      </button>
    );
  };

  const EditTeamButton = () => {
    return (
      <button
        onClick={() => setisEditing(true)}
        className="ml-auto rounded-md flex flex-row justify-center items-center gap-2 bg-cyan-500 text-white font-normal hover:bg-cyan-300 px-4 py-1.5"
      >
        {isMobileDevice(width) ? "Edit" : "Edit Team"}
        <FiEdit />
      </button>
    );
  };

  const CancelButton = () => {
    return (
      <button
        onClick={() => {
          setisEditing(false);
          setInputCMList(CurrentTeam.clientMatters?.items);
        }}
        className="border border-gray-200 rounded-md bg-white flex flex-row justify-center items-center gap-2 text-black font-normal px-4 py-1.5 hover:bg-gray-200"
      >
        Cancel {isMobileDevice(width) ? "" : " Editing"} <FiEdit />
      </button>
    );
  };
  const customStyles = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: "white",
        color: "black",
      };
    },
  };

  return (
    <>
      <div
        ref={modalContainer}
        onClick={() => close()}
        className="opacity-0 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-60 "
      >
        <div
          ref={modalContent}
          className="p-10 pb-0 flex flex-col bg-white rounded-lg opacity-0 scale-90 max-h-screen overflow-y-auto relative"
          style={{
            minWidth: isMobileDevice(width) ? "100%" : "750px",
            maxWidth: isMobileDevice(width) ? "750px" : "auto",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-row">
            <div className="text-lg font-semibold pb-2">Edit Team</div>
            <button
              onClick={() => {
                close();
              }}
              className="h-8 w-8 cursor-pointer rounded-full bg-gray-100 flex flex-row justify-center items-center hover:bg-gray-300 top-5 right-5 absolute"
            >
              <CgClose />
            </button>
          </div>

          {/*Profile*/}
          {!CurrentTeam ? (
            <div className="flex flex-row items-start py-8 gap-4">
              <div className="w-12 h-12 md:w-16 md:h-16">
                <SkeletonTheme height="64px" width="64px">
                  <Skeleton circle={true} />
                </SkeletonTheme>
              </div>
              <div className={`flex flex-col justify-start gap-1 items-start`}>
                <div className="text-base font-semibold flex flex-row gap-2">
                  <SkeletonTheme height="24px" width="150px">
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </div>
                <div className="px-2 uppercase rounded-full bg-gray-200 font-semibold p-0.5 text-xs ">
                  <SkeletonTheme height="16px" width="70px">
                    <Skeleton count={1} />
                  </SkeletonTheme>
                </div>
              </div>
              <div className="ml-auto">
                <SkeletonTheme height="33px" width="128px">
                  <Skeleton count={1} />
                </SkeletonTheme>
              </div>
            </div>
          ) : (
            <div className="flex flex-row items-start py-8 gap-4">
              <input
                type="file"
                id="file"
                accept="image/*"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    setImage(URL.createObjectURL(event.target.files[0]));
                  }
                }}
              />
              <div
                onClick={() => {
                  if (isEditing) {
                    inputFile.current.click();
                  }
                }}
                className={`${isEditing && "cursor-pointer relative"}`}
              >
                {isEditing && (
                  <div
                    className="p-0.5 rounded-sm absolute z-20"
                    style={{
                      left: isMobileDevice(width) ? "40px" : "60px",
                      top: isMobileDevice(width) ? "30px" : "50px",
                    }}
                  >
                    <FiEdit />
                  </div>
                )}
                <div className="w-12 h-12 md:w-16 md:h-16">
                  <input
                    type="file"
                    id="file"
                    accept="image/png, image/jpeg"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        setImage(URL.createObjectURL(event.target.files[0]));
                        setSelectedPhoto(event.target.files[0]);
                      }
                    }}
                  />
                  <div className="relative flex bg-gray-500 text-white h-full w-full rounded-full text-2xl justify-center items-center cursor-pointer">
                    <div className="absolute top-0 left-0 rounded-full w-12 h-12 md:w-16 md:h-16 z-20 transition-opacity opacity-100 md:opacity-0 md:hover:opacity-70">
                      {Image !== null ? (
                        <div
                          className="absolute top-0 -right-3 rounded-full w-7 h-7 z-30 bg-danger transition-opacity"
                          onClick={() => {
                            setSelectedPhoto(null);
                            setImage(null);
                          }}
                        >
                          <div className="flex items-center justify-center w-full h-full">
                            <MdClose />
                          </div>
                        </div>
                      ) : null}

                      <div
                        className="rounded-full w-12 h-12 md:w-16 md:h-16 absolute z-20 bg-black transition-opacity opacity-0 hover:opacity-40 md:hover:opacity-100 md:opacity-100"
                        onClick={() => {
                          inputFile.current.click();
                        }}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          <FiEdit className="" />
                        </div>
                      </div>
                    </div>
                    {Image === null || Image === "" ? (
                      <>{CurrentTeam.name?.charAt(0).toUpperCase()}</>
                    ) : (
                      <img className="h-full w-full rounded-full" src={Image} />
                    )}
                  </div>
                </div>
                {/* <img
                className={`z-10 rounded-full ` + (isEditing && " opacity-70 hover:opacity-50 active:opacity-50")}
                src={Image}
                alt={`prop`}
                style={{ 
                  objectFit: "cover",
                  width: (isMobileDevice(width) ? "50px" : "70px"),
                  height: (isMobileDevice(width) ? "50px" : "70px"),
                }}
              ></img> */}
              </div>

              <div className={`flex flex-col justify-start gap-1 items-start`}>
                <div className="text-base font-semibold flex flex-row gap-2">
                  {`${CurrentTeam.name}'s Team`}
                </div>
                <div className="px-2 uppercase rounded-full bg-gray-200 font-semibold p-0.5 text-xs ">{`${CurrentTeam.members.items.length} members `}</div>
              </div>
              <div className="ml-auto">
                {isEditing ? <CancelButton /> : <EditTeamButton />}
              </div>
            </div>
          )}

          <div
            className={isEditing ? "flex flex-col pt-4" : "flex flex-col pt-4"}
          >
            {isEditing ? (
              <>
                <div className="flex flex-col p-1 w-full mb-4">
                  <div className="text-sm font-medium text-gray-400">
                    {`Rename your team`}
                  </div>
                  <input
                    name={`teamName`}
                    type="text"
                    value={TeamName}
                    className="rounded-md p-2 w-full border border-gray-300 outline-0"
                    onChange={(e) => {
                      setTeamName(e.target.value);
                    }}
                    required
                  />
                </div>
              </>
            ) : null}

            <Tab.Group
              onChange={(index) => {
                console.log("tabOpen", index);
                setTabOpen(index);
              }}
            >
              <Tab.List className="mb-4">
                <Tab>
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "font-medium px-4 py-2 rounded-full bg-gray-200"
                          : "font-medium px-4 py-2 rounded-full bg-white text-black"
                      }
                    >
                      Members
                    </button>
                  )}
                </Tab>
                <Tab>
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "font-medium px-4 py-2 rounded-full bg-gray-200"
                          : "font-medium px-4 py-2 rounded-full bg-white text-black"
                      }
                    >
                      Client / Matter
                    </button>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  {!isEditing ? (
                    <>
                      {InputData &&
                        InputData.map((member, i) => (
                          <RowCard
                            image={member.user.profilePicture}
                            member={member}
                            key={i}
                          />
                        ))}
                    </>
                  ) : (
                    <>
                      {InputData &&
                        InputData.map((x, i) => (
                          <div
                            className={
                              "flex  " +
                              (isMobileDevice(width) ? "flex-col" : "flex-row")
                            }
                            key={x.user.id}
                          >
                            <div className="flex flex-col p-1">
                              <div className="text-sm font-medium text-gray-400">{`Member ${
                                i + 1
                              }`}</div>
                              <Select
                                components={{
                                  IndicatorSeparator: () => null,
                                  DropdownIndicator: DropdownIndicator,
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                  container: (base) => ({
                                    ...base,
                                    zIndex: "99999",
                                  }),
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  menuList: (provided) => ({
                                    ...provided,
                                    maxHeight: "7rem",
                                  }),
                                }}
                                name={`name`}
                                options={Options}
                                type="text"
                                value={{
                                  value:
                                    x.user.firstName + " " + x.user.lastName,
                                  label:
                                    x.user.firstName + " " + x.user.lastName,
                                }}
                                isDisabled={!isEditing}
                                onChange={(e) =>
                                  handleSelectChange(e, x, i, `name`)
                                }
                                className={
                                  "rounded-md focus:border-gray-100 text-gray-400 " +
                                  (isMobileDevice(width) ? "w-full" : "w-80")
                                }
                              />
                            </div>
                            <div className="flex flex-col p-1">
                              <div className="text-sm font-medium text-gray-400">{`User Type`}</div>
                              <Select
                                menuPortalTarget={document.body}
                                styles={{
                                  container: (base) => ({
                                    ...base,
                                    zIndex: "99999",
                                  }),
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  // menu: (base) => ({
                                  //   ...base,
                                  //   maxHeight:'7rem',
                                  //   overflowY: 'auto',
                                  // }),
                                }}
                                components={{
                                  IndicatorSeparator: () => null,
                                  DropdownIndicator: DropdownIndicator,
                                }}
                                name={`userType`}
                                options={UserTypes}
                                type="text"
                                isDisabled={!isEditing}
                                value={{
                                  value: x.userType,
                                  label: x.userType,
                                }}
                                onChange={(e) =>
                                  handleSelectChange(e, x, i, `userType`)
                                }
                                className={
                                  "rounded-md focus:border-gray-100 text-gray-400 bg-white " +
                                  (isMobileDevice(width) ? "w-full" : "w-80")
                                }
                              />
                            </div>
                            <div className="flex flex-col p-1">
                              {isMobileDevice(width) ? (
                                <></>
                              ) : (
                                <div className="opacity-0">1</div>
                              )}
                              {InputData.length > 1 && (
                                <CgTrash
                                  className="border border-gray-200 text-4xl p-2 cursor-pointer hover:bg-gray-100"
                                  color={`lightcoral`}
                                  onClick={() => handleDelete(i)}
                                />
                              )}
                              {isMobileDevice(width) ? (
                                <div className="opacity-0">1</div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </Tab.Panel>
                <Tab.Panel>
                  <TeamClientMatter
                    CurrentTeam={CurrentTeam}
                    isEditing={isEditing}
                    inputCMList={inputCMList}
                    setInputCMList={setInputCMList}
                    handleSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </Tab.Panel>
              </Tab.Panels>
              {isEditing && <AddMore />}
            </Tab.Group>
          </div>

          <div
            className="sticky bottom-0 pb-10 bg-white"
            style={{ zIndex: 9999999 }}
          >
            {isEditing && <SaveButton />}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
