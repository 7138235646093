import {
  BrowserRouter as Router,
  Switch,
  Route,
  // useHistory,
  Redirect,
} from "react-router-dom";

import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

import { AppRoutes } from "./constants/AppRoutes";
import Authentication from "./components/authentication";
import Dashboard from "./components/dashboard";
import MattersOverview from "./components/matters-overview";
import ContactInformation from "./components/contact-information";
import UserTypeAccess from "./components/usertype-access";
import AccountSettings from "./components/account-settings";
import MattersRFI from "./components/matters-rfi-page";
import Contacts from "./components/contacts";
import PostRegistration from "./components/authentication/post-registration";
import PostAuthentication from "./components/authentication/post-authentication";
import GettingStarted from "./components/authentication/getting-started";
import Navbar from "./components/sidebar";
import Signout from "./components/authentication/signout";
import FileBucket from "./components/file-bucket";
import Background from "./components/background";
import Labels from "./components/labels";
import { Inbox } from "./components/inbox";
import EmailIntegrationAuth from "./components/authentication/email-integration-authentication";
import RFIPage from "./components/rfi-page";
import Briefs from "./components/briefs";
import ArchivedBriefs from "./components/briefs/archived";
import TaskList from "./components/task-list";
import Admin from "./components/admin";
// import PortalsList from "./components/authentication/portals-list";
import ArchivedClientMatters from "./components/dashboard/archived";
import ClientMatterLibrary from "./components/clientmatter-library";

import Onboarding from "./components/onboarding/";
// import OnboardingFormBuilder from "./components/onboarding/form-builder";
// import OnboardingSubmissions from "./components/onboarding/submissions";
import FormBuilder from "./components/form-builder";
import FormDashboard from "./components/form-dashboard";
import FormDetails from "./components/form-details";
import FormQuestionnaire from "./components/form";

import SessionTimeout from "./components/session-timeout/session-timeout-modal";
import CreateDocumentPage from "./components/background/create-document-page";
import CategoryLibrary from "./components/category-library";

import HBADashboard from "./components/hba-dashboard";
import OBADashboard from "./components/oba-dashboard";

// import Poc from "./components/poc/chatgpt";
import Design from "./shared/StyleGuide";
import ContentMachine from "./components/content-machine";
import TypeFormPOC from "./components/type-form";

const Routes = () => {
  //Current user instance
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);

  const currentRoute = window.location.pathname;

  //Blacklisted user
  const pubUsername = "dev+public@lophils.com";

  useEffect(() => {
    if (!user && !userId) {
      getCurrentSession();
    }
  }, [user, userId]);

  const getCurrentSession = async () => {
    console.log("getCurrentSession()");
    await Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        setUser(cognitoUser.username);
        setUserId(cognitoUser.attributes["sub"]);
      })
      .catch((error) => {
        // Handle the error

        if (error === "The user is not authenticated") {
          console.log(error);

          if (
            currentRoute !== "/" &&
            !currentRoute.includes("registration") &&
            currentRoute !== AppRoutes.SIGNOUT
          ) {
            window.location.href = AppRoutes.SIGNOUT;
          }
        } else {
          console.error("Authentication Error:", error);
        }
      });
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Authentication} />
        <Route exact path={AppRoutes.REGISTRATION_WITHOUT_SLASH} component={Authentication} />
        <Route
          exact
          path={AppRoutes.REGISTRATION_BUDGET}
          component={Authentication}
        />
        <Route
          exact
          path={AppRoutes.POSTREGISTRATION}
          component={PostRegistration}
        />
        <Route
          exact
          path={`${AppRoutes.GETTINGSTARTED}/:step`}
          component={GettingStarted}
        />
        <Redirect
          from={`${AppRoutes.GETTINGSTARTED}`}
          to={`${AppRoutes.GETTINGSTARTED}/1`}
        />

        {/* Since this route is responsible for redirecting the user to the
			  dashboard page after logging in/signing up, I've used it as a workaround
			  for a problem I've faced. In short, it sets a value to the user state
			  variable above which in turn will be validated by this routing system.
			  Please don't remove it, or the 404 page will cause issues */}
        <Route
          exact
          path={AppRoutes.POSTAUTHENTICATION}
          render={(props) => (
            <PostAuthentication
              {...props}
              user={user}
              setUser={setUser}
              userId={userId}
              setUserId={setUserId}
              getCurrentSession={getCurrentSession}
            />
          )}
        />
        <Route exact path={AppRoutes.SIGNOUT} component={Signout} />
        <Route
          exact
          path={`${AppRoutes.ONBOARDING}/:step`}
          component={Onboarding}
        />

        {/* Temporary Route for Onboarding Form Builder */}
        {/* <Route
          exact
          path={`${AppRoutes.ONBOARDINGFORMBUILDER}/`}
          component={OnboardingFormBuilder}
        /> */}
        {/* In charge of redirecting the user when they try to access
			  the onboarding page through the /public/onboarding link */}
        <Redirect
          from={`${AppRoutes.ONBOARDING}`}
          to={`${AppRoutes.ONBOARDING}/1`}
        />
        <Route exact path={`${AppRoutes.FORM}`} component={FormQuestionnaire} />
        <Route exact path={`${AppRoutes.ERROR}/`} component={SessionTimeout} />
        {/* <Route exact path={AppRoutes.PORTALSLIST} component={PortalsList} /> */}
        <Route
          exact
          path={AppRoutes.CONTENTMACHINE}
          component={ContentMachine}
        />

        {/* Determines whether or not the user came from the onboarding page */}
        {user !== pubUsername ? (
          <>
            <Navbar />
            <Switch>
              <Route exact path={AppRoutes.DASHBOARD} component={Dashboard} />
              <Route
                exact
                path={AppRoutes.HBADASHBOARD}
                component={HBADashboard}
              />
              <Route
                exact
                path={AppRoutes.OBADASHBOARD}
                component={OBADashboard}
              />
              <Route
                exact
                path={AppRoutes.ARCHIVES}
                component={ArchivedClientMatters}
              />
              <Route
                exact
                path={`${AppRoutes.MATTERSOVERVIEW}/:id`}
                component={MattersOverview}
              />
              <Route
                exact
                path={`${AppRoutes.CONTACTINFORMATION}/:id`}
                component={ContactInformation}
              />
              <Route
                exact
                path={AppRoutes.USERTYPEACCESS}
                component={UserTypeAccess}
              />
              <Route
                exact
                path={`${AppRoutes.MATTERSRFI}/:matter_id`}
                component={MattersRFI}
              />
              <Route
                exact
                path={`${AppRoutes.RFIPAGE}/:matter_id`}
                component={RFIPage}
              />
              <Route exact path={AppRoutes.CONTACTS} component={Contacts} />
              <Route
                exact
                path={AppRoutes.ACCOUNTSETTINGS}
                component={AccountSettings}
              />
              <Route
                exact
                path={`${AppRoutes.FILEBUCKET}/:matter_id/:background_id`}
                component={FileBucket}
              />
              <Route
                exact
                path={`${AppRoutes.BACKGROUND}/:back_id/:matter_id/:brief_id`}
                component={Background}
              />
              <Route
                exact
                path={`${AppRoutes.BACKGROUND}/:back_id/:matter_id/:brief_id/create-document`}
                component={CreateDocumentPage}
              />
              <Route
                exact
                path={`${AppRoutes.BRIEFS}/:matter_id`}
                component={Briefs}
              />
              <Route
                exact
                path={`${AppRoutes.ARCHIVEDBACKGROUND}/:matter_id`}
                component={ArchivedBriefs}
              />
              <Route
                exact
                path={`${AppRoutes.LABELS}/:matter_id`}
                component={Labels}
              />
              <Route exact path={`${AppRoutes.INBOX}`} component={Inbox} />

              <Route
                exact
                path={`${AppRoutes.CLIENTMATTERLIBRARY}`}
                component={ClientMatterLibrary}
              />
              <Route
                exact
                path={`${AppRoutes.CATEGORYLIBRARY}`}
                component={CategoryLibrary}
              />
              <Route
                exact
                path={`${AppRoutes.AUTH}`}
                component={EmailIntegrationAuth}
              />
              <Route exact path={`${AppRoutes.TASKS}`} component={TaskList} />
              <Route exact path={`${AppRoutes.DESIGN}`} component={Design} />
              <Route exact path={`${AppRoutes.ADMIN}`} component={Admin} />
              <Route
                exact
                path={`${AppRoutes.TYPEFORM}`}
                component={TypeFormPOC}
              />
              {/* Temporary Route for MMA Form Details */}
              <Route
                exact
                path={`${AppRoutes.FORMRESULTS}`}
                component={FormDetails}
              />
              {/* Temporary Route for MMA Form Dashboard */}
              <Route
                exact
                path={`${AppRoutes.FORMDASHBOARD}/`}
                component={FormDashboard}
              />
              {/* Temporary Route for MMA Form Builder */}
              <Route
                exact
                path={`${AppRoutes.FORMBUILDER}`}
                component={FormBuilder}
              />

              <Redirect to={AppRoutes.ERROR} />
            </Switch>
          </>
        ) : (
          // Redirects the user to the 404/Error page if they try to access restricted pages
          <Redirect to={AppRoutes.ERROR} />
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
