import React, { useState, useEffect, useRef } from "react";
import ToastNotification from "../toast-notification";
import { FaTimes } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { BsFillTrashFill } from "react-icons/bs";
import Button from "../../shared/Button";

export default function RemoveSummaryModal(props) {
  const handleModalClose = () => {
    props.handleModalClose();
  };

  const prompData = props.openAiPrompts.find(
    (item) => item.id === props.selectedPromptId
  );
  // console.log("Items", props.selectedPromptId);

  const handleDelete = async () => {
    props.handleSave(props.selectedPromptId);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
            <div className="items-center">
              <div className="flex items-center justify-center p-6 rounded-b">
                <BsFillTrashFill className="text-3xl items-center" />
              </div>
              <div className="flex items-center justify-center py-1 rounded-b">
                <p className="text-lg font-semibold">Delete Core Memory</p>
              </div>
              <div className="flex items-center justify-center rounded-b mb-5">
                <div className="px-5 w-full flex flex-col items-center justify-center text-md">
                  {prompData && prompData.prompt !== null ? (
                    <>
                      <p className="text-center">
                        <p className="italic">{`${prompData.prompt}`}</p>
                      </p>
                    </>
                  ) : (
                    <p className="text-lg font-semibold">Summary</p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-end p-6 rounded-b">
                <div className="px-5 w-full flex items-center justify-center text-md">
                  <Button
                    variant="default"
                    className="mr-2 py-2 px-4 "
                    onClick={handleModalClose}
                  >
                    Cancel &nbsp; <TiCancel />
                  </Button>

                  <Button
                    variant="danger"
                    className="ml-2 py-2 px-4 "
                    type="button"
                    onClick={() => handleDelete()}
                    // disabled={rowsToDelete?.length > 0 ? false : true}
                  >
                    Delete Permanently &nbsp; <FaTimes />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
