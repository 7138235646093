import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import "../../assets/styles/Auth.css";
import Button from "../../shared/Button";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AiFillMail, AiOutlineLoading } from "react-icons/ai";
import ProgressNotification from "./progress-notification";
import { AppRoutes } from "../../constants/AppRoutes";

export default function SignIn({
  // user,
  setUser,
  AuthState,
  setNextAuthState,
  notifMessage,
  notifShow,
  notifVariant,
  setNotifMessage,
  setNotifShow,
  setNotifVariant,
  loading,
  setLoading,
}) {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [emailInput, setEmailInput] = useState("");

  useEffect(() => {
    const errorValue = localStorage.getItem("SignInError");
    if (errorValue && errorValue !== "null" && errorValue !== "undefined") {
      setNotifMessage(errorValue);
      setNotifVariant("error");
      setNotifShow(true);
      window.scrollTo(0, 0);
    }
  }, []);

  const handleResend = async () => {
    Auth.resendSignUp(emailInput)
      .then((data) => {
        // console.log("Confirmation code resent successfully", data);
        setUser({ username: emailInput });
        setNextAuthState(AuthState.ConfirmSignUp);
        setNotifShow(false);
      })
      .catch((error) => {
        console.error("Error resending confirmation code", error);
        Auth.signOut();
      });
  };

  function handlePasswordVisibility(event) {
    event.preventDefault();
    setPasswordVisibility(!passwordVisibility);
  }

  async function handleSignIn(event) {
    // console.group("handleSignIn() start...");
    // console.time();

    event.preventDefault();
    setLoading(true);

    try {
      localStorage.removeItem("SignInError");
      const signInResponse = await Auth.signIn(
        emailInput,
        event.target.password.value
      );

      // console.timeEnd();
      // console.groupEnd();
      // console.log("signInResponse --> ", signInResponse);
      setUser(signInResponse);

      if (signInResponse?.signInUserSession) {
        setNextAuthState(AuthState.SignedIn);
      } else if (signInResponse?.challengeName === "CUSTOM_CHALLENGE") {
        // console.group("2FA Checker() start...");
        // console.log("2FA Enabled? ", signInResponse.challengeParam);
        // console.time();
        if (signInResponse.challengeParam?.skipMFA === "true") {
          const verifiedUser = await Auth.sendCustomChallengeAnswer(
            signInResponse,
            "000000"
          );
          setNextAuthState(
            verifiedUser
              ? AuthState.SignedIn
              : AuthState.confirmingSignInCustomFlow
          );
        } else {
          setNextAuthState(AuthState.confirmingSignInCustomFlow);
        }
        // console.timeEnd();
        // console.groupEnd();
      } else {
        setNextAuthState(AuthState.ResetPassword);
      }
    } catch (error) {
      setNotifMessage(error.message);
      setNotifVariant("error");
      setNotifShow(true);
      window.scrollTo(0, 0);
      Auth.signOut();
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="w-full fade-in-fwd">
        <div>
          <div className="w-full mt-10">
            <form onSubmit={handleSignIn}>
              <div className="flex flex-col" style={{ maxWidth: "500px" }}>
                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <ProgressNotification
                      className=""
                      variant={notifVariant}
                      message={notifMessage}
                      show={notifShow}
                      handleResend={handleResend}
                    />
                    <label
                      htmlFor="signIn-email"
                      className="text-xl font-normal text-darkGray"
                    >
                      Email
                    </label>
                    <div className="mt-2 relative flex flex-col">
                      <input
                        type="email"
                        id="signIn-email"
                        key="signIn-email"
                        name="username"
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                        placeholder="Email Address"
                        className="pl-14 md:pl-16 pr-3 h-14 md:h-16 py-2 border-4 border-neutral-100 outline-none rounded-xl text-base md:text-xl text-hbGray font-normal placeholder-gray-300 focus:outline-none focus:ring-2 ring-hbBlue"
                        //style={{paddingLeft: "72px"}}
                        required
                      />
                      <label htmlFor="signIn-email">
                        <div className="absolute top-1/2 transform -translate-y-1/2 left-4 border-r-2 pr-2">
                          <AiFillMail className="text-2xl md:text-4xl text-hbGray" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <label
                    htmlFor="signIn-password"
                    className="text-xl font-normal text-darkGray"
                  >
                    Password
                  </label>
                  <div className="mt-2 relative flex flex-col">
                    <input
                      type={passwordVisibility ? "text" : "password"}
                      id="signIn-password"
                      key="signIn-password"
                      name="password"
                      placeholder="Password"
                      className="pl-14 md:pl-16 pr-14 h-14 md:h-16 py-2 border-4 border-neutral-100 outline-none rounded-xl text-base md:text-xl text-hbGray font-normal placeholder-gray-300 focus:outline-none focus:ring-2 ring-hbBlue"
                      //style={{paddingLeft: "72px"}}
                      required
                    />
                    <label htmlFor="signIn-password">
                      <div className="absolute top-1/2 transform -translate-y-1/2 left-4 border-r-2 pr-2">
                        <RiLockPasswordFill className="text-2xl md:text-4xl text-hbGray" />
                      </div>
                    </label>
                    <button
                      className="absolute right-6 top-1/2 transform -translate-y-1/2"
                      type="button"
                      onClick={(e) => handlePasswordVisibility(e)}
                    >
                      {passwordVisibility ? (
                        <FaEyeSlash className="text-2xl text-hbGray" />
                      ) : (
                        <FaEye className="text-2xl text-hbGray" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="mt-4 text-left md:text-right">
                  <span className="text-base font-normal text-hbGray">
                    Forgot Password?
                  </span>
                  <button
                    className="ml-1 text-base font-normal text-hbBlue"
                    type="button"
                    onClick={() => {
                      setNotifShow(false);
                      setNextAuthState(AuthState.ForgotPassword);
                    }}
                  >
                    Reset Password
                  </button>
                </div>
                <div className="flex flex-col-reverse md:grid md:grid-cols-2 mt-8 items-center">
                  <div className="md:col-span-1 mt-3 md:mt-0">
                    <span className="text-base font-normal text-hbGray">
                      No Account?
                    </span>
                    <button
                      className="ml-1 text-base font-normal text-hbBlue"
                      type="button"
                      onClick={() => {
                        setNotifShow(false);
                        // setNextAuthState(AuthState.SignUp);
                        window.location.href=AppRoutes.REGISTRATION_WITHOUT_SLASH;
                      }}
                    >
                      Register
                    </button>
                  </div>
                  <div className="md:col-span-1 w-full ">
                    <Button
                      type="submit"
                      variant="hbBlue"
                      block={true}
                      size={"xl"}
                      className="h-16 rounded-xl"
                      disabled={loading}
                    >
                      <span className="relative text-xl font-semibold">
                        {loading ? (
                          <AiOutlineLoading className="absolute rotate-center -left-6 top-1" />
                        ) : null}
                        CONTINUE
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
