import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import Loading from "../loading/loading";
import CreatableSelect from "react-select/creatable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useRootClose } from "react-overlays";
import EmailParser from "../../shared/EmailParser";
import html2pdf from "html2pdf.js";
import googleLogin from "../../assets/images/gmail-print.png";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  WindowScroller,
} from "react-virtualized";
import { MdClose } from "react-icons/md";
import ActionButtons from "./action-buttons";
import arrowUp from "../../assets/images/arrow-up.svg";
import arrowDown from "../../assets/images/arrow-down.svg";
import { BsFillCircleFill } from "react-icons/bs";
import { MdArrowBackIos } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { AiFillCheckCircle } from "react-icons/ai";
import isMSdoc from "../../shared/msFileTypeChecker";
import { useWindowDimensions } from "../../shared/mobileViewFunctions";
import Select from "react-dropdown-select";
import {
  dateTimeNoSecondsFormatter,
  dateFormatter,
  timeFormatter,
} from "../../shared/userPreferredDateTime";
import { useMsal } from "@azure/msal-react";
import { hbaDomains, obaDomains } from "../../constants/AppDomains";

var moment = require("moment");

const mUpdateAttachmentDescription = `mutation MyMutation($details: String, $id: ID) {
    gmailMessageAttachmentUpdate(id: $id, details: $details) {
      id
      details
    }
  }`;

const mTagEmailClientMatter = `
  mutation tagGmailMessageClientMatter($clientMatterId: ID, $gmailMessageId: String) {
    gmailMessageClientMatterTag(
      clientMatterId: $clientMatterId
      gmailMessageId: $gmailMessageId
    ) {
      id
    }
  }`;

const mUpdateRowDescription = `mutation saveGmailDescription($id: String, $description: String) {
  gmailMessageUpdate(id: $id, description: $description) {
    id
  }
}`;

const qGetFileDownloadLink = `
query getAttachmentDownloadLink($id: String) {
  gmailAttachment(id: $id) {
    downloadURL
    s3ObjectKey
    type
  }
}`;

const qGetMessagePayload = `query getPayloadByMessageId($id: ID) {
  gmailMessage(id: $id) {
    payload {
      content
    }
  }
}
`;

/// OUTLOOK QUERIES AND MUTATIONS

const mUpdateAttachmentDescriptionOutlook = `mutation updateOutlookAttachment($details: String, $id: ID) {
  outlookMessageAttachmentUpdate(id: $id, details: $details) {
    id
    details
  }
}`;

const mTagOutlookEmailClientMatter = `mutation tagOutlookMessageClientMatter($clientMatterId: ID, $outlookMessageId: String) {
  outlookMessageClientMatterTag(
    clientMatterId: $clientMatterId
    outlookMessageId: $outlookMessageId
  ) {
    id,
  }
}`;

const mUpdateRowDescriptionOutlook = `mutation saveOutlookDescription($id: String, $description: String) {
  outlookMessageUpdate(id: $id, description: $description) {
    id
  }
}`;

const qOutlookGetFileDownloadLink = `query getOutlookAttachmentDownloadLink($id: String) {
  outlookAttachment(id: $id) {
    downloadURL
    s3ObjectKey
    type
  }
}`;

const qOutlookGetMessagePayload = `query qOutlookGetMessagePayload($id: ID) {
  outlookMessage(id: $id) {
    payload {
      content
    }
    threadId
  }
}`;

const TableArchivedInfo = ({
  taskListColumns,
  selectedArchivedItems,
  setSelectedArchivedItems,
  archivedEmails,
  waitArchived,
  show,
  setShow,
  setShowToast,
  setResultMessage,
  setEmailInfo,
  setEmailContent,
  setShowPreview,
  isOutOfSync,
  mobileLoading,
  setMobileLoading,
  setshowAddContactModal,
  setArchivedEmails,
  bindList,
  searchGmail,
  filterRecord,
  saveLoading,
  onScroll,
  scrollPosition,
  isOutlookIntegrated,

  emailFilters,
  setShowCopy,
  showDetailsCopy,
  setShowDetailsCopy,
  showCopy,
  selectedTaskRegister,
  setSelectedTaskRegister,
  displayImageAttachments,
  setSaveLoading,
  sortByDate,
  unSavedEmails,
  setUnsavedEmails,
  getUnSavedEmails,
  selectedUnsavedItems,
  setSelectedUnsavedItems,

  unSavedForTaskListCtr,
  savedForTaskListCtr,
  getArchivedEmails,
  setDoneAllocatingEmails,
  setpendingMessage,
  setPreviousUnsavedEmails,
  setPreviousSavedEmails,
  setPreviousArchivedEmails,
  setSearchGmail,
  selectAllArchived,
  selectAllSaved,
  selectAllUnsaved,
  setDisplayImageAttachments,
  selectAllCheck,
  setSelectAllCheck,
  handleExecuteFilter,
  getSavedEmails,
  openTab,
  fetchData,
  setWaitUnSaved,
  setWaitSaved,
  setWaitArchived,
  setOpenTab,

  selectedSavedItems,
  setSelectedSavedItems,
  tasklistGmailIds,
  setTasklistGmailIds,
  setSelectedTaskList,
  selectedTaskList,
  taskRegisterList,
  savedEmails,
  setSavedEmails,

  saveProgress,
  setSaveProgress,
  vTablePaddingTop,
  vTablePaddingBottom,
  virtualRows,
  rowVirtualizer,
  rows,
  totalSize,
  tableContainerRef,

  setUnsavedEmailsCount,
  setSavedEmailsCount,
  setArchivedEmailsCount,
  Highlight,
}) => {
  const { accounts } = useMsal();
  const ref = useRef([]);
  // const [show, setShow] = useState(false);
  const [snippetId, setSnippetId] = useState(null);
  const [emailPreview, setEmailPreview] = useState(null);
  const handleRootClose = () => {
    setShow(false);
    setSnippetId(null);
    setShowIndex(-1);
    setEmailPreview(null);
  };
  const [selectedClientMatter, setSelectedClientMatter] = useState();
  const [isShiftDown, setIsShiftDown] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const [emailHTML, setEmailHTML] = useState("");

  const companyId = localStorage.getItem("companyId");

  const [selectArchivedEmailsDetails, setselectArchivedEmailsDetails] =
    useState([]);
  const [selectedArchivedIndex, setSelectedArchivedIndex] = useState(null);
  const [emailContentOpen, setEmailContentOpen] = useState(false);

  const [loaderEmail, setLoaderEmail] = useState(null);

  const [ShowIndex, setShowIndex] = useState(-1);

  // const { height, width } = useWindowDimensions();
  var width = window.innerWidth;
  var height = window.innerHeight;
  const [heightOffset, setHeightOffset] = useState(160); // CHANGE DEFAULT VALUE IF FRONTEND CHANGES
  const detailsDivRef = useRef(null);

  const [clientMatterState, setClientMatterState] = useState([]);
  const [prompt, setPrompt] = useState("");
  const openScreenSize = height - 180;

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  const handleMobileSnippet = async (msgId, index) => {
    setShowPreview(true);
    const messageId = msgId.id;
    setSnippetId(messageId);
    const msgPreview = await getEmailPayload(messageId);
    setEmailInfo(msgId);
    setEmailContent(msgPreview);
    const parsedEmail = EmailParser(JSON.parse(msgPreview.payload[0].content));
    setPrompt(parsedEmail.html);
  };

  const handleSnippet = async (msgId, index) => {
    setShow(true);
    if (width <= 844) {
      setShowPreview(true);
    }
    const messageId = msgId.id;
    setSnippetId(messageId);
    const msgPreview = await getEmailPayload(messageId);

    // if (messageId) {
    //   setEmailPreview(msgPreview);
    //   setSnippetId(messageId);
    //   setShow(true);
    //   setShowIndex(index);
    // }

    if (messageId) {
      if (width > 844) {
        setEmailPreview(msgPreview);
        setShowIndex(index);
      } else {
        setEmailInfo(msgId);
        setEmailContent(msgPreview);
        setShowPreview(true);
      }
    }
  };

  // Use the shared EmailParser function ../../shared/EmailParser
  /**
  function EmailParser(response) {
    function decode(input) {
      // const text = new Buffer.from(input, 'base64').toString('ascii')
      // return decodeURIComponent(escape(text))
      return Base64.decode(input.replace(/-/g, "+").replace(/_/g, "/"));
    }

    function decode_alternative(input) {
      // this way does not escape special "B" characters
      // const text = Buffer.from(input, 'base64').toString('ascii')
      // return decodeURIComponent(escape(text))

      return Base64.decode(input.replace(/-/g, "+").replace(/_/g, "/"));
    }

    const result = {
      text: "",
      html: "",
      attachments: [],
    };

    let parts = [response];

    while (parts.length) {
      let part = parts.shift();

      if (part.parts) parts = parts.concat(part.parts);

      if (part.mimeType === "text/plain") result.text = decode(part.body.data);

      if (part.mimeType === "text/html") result.html = decode(part.body.data);

      if (part.body.attachmentId) {
        result.attachments.push({
          partId: part.partId,
          mimeType: part.mimeType,
          filename: part.filename,
          body: part.body,
        });
      }
    }

    return result;
  }
  */

  function hasContactCheck(item) {
    const contacts = clientMatterState;
    let emailOnly = item.from.substring(
      item.from.indexOf("<") + 1,
      item.from.lastIndexOf(">")
    );
    let contactFound = false;
    contacts.map((item, i) => {
      if (contacts[i].email.includes(emailOnly)) {
        contactFound = true;
      }
    });
    return contactFound;
  }

  const getInlineAttachmentUrl = async (id) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetFileDownloadLink
        : qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    const { data } = await API.graphql(params);

    return isOutlookIntegrated
      ? data?.outlookAttachment?.downloadURL
      : data?.gmailAttachment?.downloadURL;
  };

  const removeAttachmentPartIds = (input) => {
    const lastDashIndex = input.lastIndexOf("-");
    const lastDotIndex = input.lastIndexOf(".");
    if (
      lastDashIndex !== -1 &&
      lastDotIndex !== -1 &&
      lastDashIndex < lastDotIndex
    ) {
      const beforeDash = input.substring(0, lastDashIndex);
      const afterDot = input.substring(lastDotIndex + 1);
      return beforeDash + "." + afterDot;
    }
    return input;
  };

  const replaceCidWithUrl = async (content, attachments) => {
    const mapping = []; // Array to store the mapping of attachment URLs and names

    if (attachments) {
      for (const attachment of attachments) {
        // Get the inline attachment URL asynchronously
        const url = await getInlineAttachmentUrl(attachment.id);

        // Add the URL and name to the mapping array
        mapping.push({
          url: url,
          name: attachment.isInline
            ? attachment.s3ObjectKey.substring(
                attachment.s3ObjectKey.lastIndexOf("/") + 1
              )
            : removeAttachmentPartIds(attachment.name),
        });
      }
    }

    let modifiedContent = content; // Variable to store the modified content

    if (isOutlookIntegrated) {
      mapping.forEach((item) => {
        const regexSrc = new RegExp(`src="cid:${item.name}"`, "g");
        modifiedContent = modifiedContent.replace(
          regexSrc,
          `src="${item.url}" alt="${item.name}`
        );
      });
    } else {
      mapping.forEach((item) => {
        // const srcRegex = new RegExp(`src="cid:${item.name}"`, "g");
        const regexSrc = new RegExp(`src="cid:[^"]*?" alt="${item.name}"`, "g");
        const regexAlt = new RegExp(`alt="${item.name}"`, "g");
        // Remove the original src attribute value
        // modifiedContent = modifiedContent.replace(srcRegex, "");
        // const altRegex = new RegExp(`alt="${item.name}"`, "g");
        // Replace the alt attribute with the new src attribute and the corresponding URL and name
        modifiedContent = modifiedContent.replace(
          regexSrc,
          `src="${item.url}"`
        );
        modifiedContent = modifiedContent.replace(
          regexAlt,
          `alt="${item.name}"`
        );
      });
    }
    // console.log("emailProc modifiedContent is: \n" + modifiedContent)
    return modifiedContent; // Return the modified content
  };

  useEffect(() => {
    async function setEmailPreview() {
      if (!emailPreview) return;

      const parsedEmail = EmailParser(
        JSON.parse(emailPreview.payload[0].content)
      );
      const modifiedEmailContent = await replaceCidWithUrl(
        parsedEmail.html,
        selectArchivedEmailsDetails?.attachments?.items
      );

      setEmailHTML(modifiedEmailContent);

      // const messageId = parsedEmail.headers['message-id'];
      // setMessageId(messageId);
    }

    setEmailPreview();
  }, [emailPreview]);

  const handleAddContact = (msgId, index) => {
    setEmailInfo(msgId);
    setshowAddContactModal(true);
  };

  const handleOpenAI = async (id, index) => {
    const fwdEmail = "---------- Forwarded message ---------";
    let promptEmail = convertHTMLEntity(prompt);
    // if (promptEmail.includes(fwdEmail)) {
    //   promptEmail = promptEmail.substring(0, promptEmail.indexOf(fwdEmail));
    // }

    const { REACT_APP_CHATGPT_API: apiKey, REACT_APP_CHATGPT_MODEL: engine } =
      process.env;

    const max_tokens = 2200;
    const endpoint = `https://api.openai.com/v1/chat/completions`;
    const stop = ["Regards", "Sincerely", "Cheers", "Kind regards"];
    const temperature = 0.2;

    if (promptEmail !== "") {
      setLoaderEmail(id);
      // Use template literals for instruction
      const instruction = `Summarize the email from a third-person point of view in one paragraph, with a maximum of 10 sentences, while excluding the signature of the original sender: \n\n """\n${promptEmail}\n"""`;
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            model: engine,
            messages: [{ role: "user", content: `${instruction}` }],
            max_tokens,
            stop,
            temperature,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        // Destructure choices directly in the function call
        handleSavePromptDesc(
          id,
          (await response.json()).choices[0]?.message?.content
            ?.trim()
            .replace(/(\n)+$/, "")
            .replace(/\s+/g, " "),
          index
        );
      } catch (error) {
        console.error("Error", error);
      } finally {
        setLoaderEmail(null);
      }
    } else {
      setLoaderEmail(null);
    }
  };

  const convertHTMLEntity = function (text) {
    const sp = document.createElement("span");

    if (!text) return "";

    const output = text
      .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        sp.innerHTML = entity;
        return sp.innerText;
      })
      .replace(/<br ?\/?>/g, "\n")
      .replace(/(<([^>]+)>)/gi, "");

    return output;
  };

  const handleSavePromptDesc = async (id, val, index) => {
    const data = {
      id: id,
      description: val,
    };
    const success = await updateRowDesc(data);
    if (success) {
      setResultMessage("Successfully updated.");
      setShowToast(true);

      const newArrDescription = archivedEmails.map((emails) => {
        if (emails.id === id) {
          return { ...emails, description: val };
        }

        return emails;
      });
      setArchivedEmails(newArrDescription);
      setMobileLoading(false);
      autoAdjustRowHeight(index);
      setLoaderEmail(null);
    }
  };

  const getEmailPayload = async (messageId) => {
    console.log("getEmailPayload()");
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetMessagePayload
        : qGetMessagePayload,
      variables: {
        id: messageId,
      },
    };

    const result = await API.graphql(params);
    let newPayload = { payload: [{ content: "" }] };

    const message = isOutlookIntegrated
      ? result?.data?.outlookMessage
      : result?.data?.gmailMessage;

    message.payload?.forEach((data, index) => {
      newPayload = {
        payload: [
          {
            content: newPayload.payload[0].content + data?.content,
          },
        ],
      };
    });

    return newPayload;
  };

  useRootClose(ref, handleRootClose, {
    disabled: !show,
  });

  const handleKeyUp = (e) => {
    if (e.key === "Shift" && isShiftDown) {
      setIsShiftDown(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Shift" && !isShiftDown) {
      setIsShiftDown(true);
    }
  };

  const handleSelectItem = (e) => {
    const { id, checked } = e.target;
    setSelectedArchivedItems([...selectedArchivedItems, id]);
    if (!checked) {
      setSelectedArchivedItems(
        selectedArchivedItems.filter((item) => item !== id)
      );
    }
  };

  const handleClientMatterChanged = (newValue) => {
    console.log(newValue);
  };

  const hideToast = () => {
    setShowToast(false);
  };

  const handleSaveDesc = async (e, id) => {
    const data = {
      id: id,
      description: e.target.innerHTML,
    };
    const success = await updateAttachmentDesc(data);
    if (success) {
      setResultMessage("Successfully updated.");
      setShowToast(true);
    }
  };

  async function updateAttachmentDesc(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: isOutlookIntegrated
            ? mUpdateAttachmentDescriptionOutlook
            : mUpdateAttachmentDescription,
          variables: {
            id: data.id,
            details: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const handleSaveMainDesc = async (e, id) => {
    const data = {
      id: id,
      description: e.target.innerHTML,
    };
    const success = await updateRowDesc(data);
    if (success) {
      setResultMessage("Successfully updated.");
      setShowToast(true);
    }
  };

  async function updateRowDesc(data) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: isOutlookIntegrated
            ? mUpdateRowDescriptionOutlook
            : mUpdateRowDescription,
          variables: {
            id: data.id,
            description: data.description,
          },
        });
        resolve(request);
      } catch (e) {
        reject(e.errors[0].message);
      }
    });
  }

  const handleClientMatter = async (e, gmailMessageId) => {
    const request = API.graphql({
      query: isOutlookIntegrated
        ? mTagOutlookEmailClientMatter
        : mTagEmailClientMatter,
      variables: {
        clientMatterId: e.value,
        gmailMessageId: gmailMessageId,
      },
    });
  };

  /**
  const handleDownload = async (gmailMessageId, subject) => {
    var opt = {
      margin: [30, 30, 30, 30],
      filename: subject,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        dpi: 96,
        scale: 1,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: "#FFF",
      },
      jsPDF: { unit: "pt", format: "a4", orientation: "p" },
      pagebreak: { before: ".page-break", avoid: "img" },
    };

    const emailPayload = await getEmailPayload(gmailMessageId);

    if (emailPayload) {
      const parsedEmail = EmailParser(
        JSON.parse(emailPayload.payload[0].content)
      );
      const htmlContent = parsedEmail.html;
      // const htmlContent = emailPayload.payload
      //   .map((em) => em.content)
      //   .join("")
      //   .split('data":"')
      //   .pop()
      //   .split('"}')[0];

      var content = document.getElementById("preview_" + gmailMessageId);
      // content.innerHTML += Base64.decode(htmlContent).replace(
      //   "body{color:",
      //   ""
      // );
      content.innerHTML += htmlContent;

      html2pdf()
        .set(opt)
        .from(content)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          var i = 0;

          for (i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              pdf.internal.pageSize.width - 100,
              pdf.internal.pageSize.height - 30,
              "Page " + i + " of " + totalPages
            );
          }
          window.open(pdf.output("bloburl"), "_blank");
        });
    }
  };
  */

  const autoAdjustRowHeight = (index) => {
    //bindList and cache must not be null
    if (bindList && cache) {
      cache?.current.clear(index);
      bindList?.current?.recomputeRowHeights(index);
      console.log("success readjust");
    } else {
      console("List reference not found || cache not found!");
    }
    if (/* width < 844 && */ searchGmail && (searchGmail !== null || "")) {
      filterRecord(searchGmail);
    }
  };

  const previewAndDownloadFile = async (id) => {
    const params = {
      query: isOutlookIntegrated
        ? qOutlookGetFileDownloadLink
        : qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    await API.graphql(params).then(async (result) => {
      const attachment = isOutlookIntegrated
        ? result.data.outlookAttachment
        : result.data.gmailAttachment;
      const { type, downloadURL, s3ObjectKey } = attachment;

      if (
        (type &&
          (type.includes("vnd.openxmlformats-officedocument") ||
            type.includes("application/msword"))) ||
        isMSdoc(s3ObjectKey)
      ) {
        var encodedUrl = encodeURIComponent(downloadURL);
        var documentViewerUrl = `https://docs.google.com/gview?url=${encodedUrl}&embeded=true`;
        window.open(documentViewerUrl);
      } else {
        window.open(downloadURL);
      }
    });
  };

  // function checkStatus(itemId, tasklistId){
  //   if(tasklistId !== ''){
  //     return true;
  //   }else if(selectedTaskList.includes(itemId)){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }

  //Changes color of highlighted emails
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [prevSelectedEmails, setPrevSelectedEmails] = useState([]);

  useEffect(() => {
    const storedSelectedArchivedEmails = localStorage.getItem(
      "selectedArchivedEmail"
    );
    if (storedSelectedArchivedEmails) {
      setPrevSelectedEmails(JSON.parse(storedSelectedArchivedEmails));
    }
  }, []);

  useEffect(() => {
    //console.log("useEffectWidth:", window.innerWidth);
    width = window.innerWidth;
    if (width > 844) {
      let tableHeight = document.getElementById("emailTableBody").offsetTop;
      setHeightOffset(tableHeight + 1);
    }
  }, [window.innerWidth]);

  const handleEmailClick = (id) => {
    setPrevSelectedEmails(prevSelectedEmails.concat(id));
    setSelectedEmail(id);
    localStorage.setItem(
      "selectedArchivedEmail",
      JSON.stringify(prevSelectedEmails.concat(id))
    );
  };

  // Time format
  const currentDate = moment().local().format("YYYY-MM-DD");
  const itemDate = moment(selectArchivedEmailsDetails?.internalDate, "x")
    .local()
    .format("YYYY-MM-DD");

  return isOutOfSync ? (
    <>
      <table
        id="table-el"
        className="min-w-full text-xs border-b-2 border-l-2 border-r-2 divide-y divide-gray-200 table-fixed border-slate-100"
      >
        <tbody>
          <tr>
            <td className="p-4">
              An error has occurred. Please re-login your account to continue
              syncing emails.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  ) : (
    <>
      {width > 844 ? (
        //WEB VERSION//
        <>
          {/* //left-hand-screen */}
          <div
            id="emailTableBody"
            className="grid grid-cols-4 flex overflow-y-hidden"
            style={{ height: height - heightOffset + "px" }}
          >
            <div
              ref={tableContainerRef}
              className={
                "overflow-y-auto overflow-x-hidden inboxScroll " +
                (waitArchived ? "overflow-y-hidden" : "overflow-y-auto")
              }
              style={{
                height: height - heightOffset + "px",
              }}
            >
              <table
                id="table-el"
                className="divide-y w-full table-fixed divide-gray-200 text-xs"
                // style={{ width: width * 0.28 + "px" }}
              >
                <thead
                  className="z-10 hidden bg-white"
                  style={{ position: "sticky", top: "120px" }}
                >
                  <tr>
                    <th className="w-8 px-2 py-4 font-medium text-center whitespace-nowrap"></th>
                    <th className="w-3/12 px-2 py-4 font-medium text-center whitespace-nowrap">
                      Email Details
                    </th>
                    <th className="w-3/12 px-2 py-4 font-medium text-center whitespace-nowrap">
                      Attachments and Description
                    </th>
                    <th className="w-2/12 px-2 py-4 font-medium text-center whitespace-nowrap">
                      Background Labels
                    </th>
                    <th className="w-3/12 px-2 py-4 font-medium text-center whitespace-nowrap">
                      Client Matter
                    </th>
                    <th className="w-1/12 px-2 py-4 font-medium text-center whitespace-nowrap"></th>
                  </tr>
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  style={{ width: "100%" }}
                >
                  {waitArchived ? (
                    <>
                      {[...Array(10).keys()].map((data, index) => {
                        return (
                          <tr className="flex">
                            <td className="w-10 flex p-2 items-center justify-center">
                              <div className="align-center">
                                <SkeletonTheme width={"20px"} height={"20px"}>
                                  <p>
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>

                            <td className="flex-1 grow p-2 align-top">
                              <div>
                                <SkeletonTheme height="55px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                            <td className="flex-none w-20 p-2 align-top">
                              <div>
                                <SkeletonTheme height="55px">
                                  <p className="mb-2">
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        height: rowVirtualizer.getTotalSize(),
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          transform: `translateY(${
                            virtualRows[0]?.start || 0
                          }px)`,
                        }}
                      ></div>
                      {virtualRows.map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        const item = row.original;
                        const index = row.index;

                        return (
                          <tr
                            id={"desktopArchived" + row.id}
                            ref={(e) => virtualRow.measureElement(e)}
                            data-index={virtualRow.index}
                            style={{
                              height: `${virtualRow.size}px`,
                              transform: `translateY(${virtualRow.start}px)`,
                            }}
                            key={virtualRow.key}
                            className="absolute top-0 left-0 w-full border-gray-200 border-b border-r"
                          >
                            {/* LEFT SIDE OF SCREEN */}
                            <td
                              className={`checkBox h-full w-2/5 py-5 pr-2 text-center align-top cursor-pointer ${
                                selectedEmail === item.id ? "bg-gray-200" : ""
                              } ${
                                prevSelectedEmails.includes(item.id)
                                  ? "bg-gray-100"
                                  : ""
                              }`}
                              onClick={() => {
                                handleEmailClick(item.id);
                                setselectArchivedEmailsDetails(item);
                                handleSnippet(item, index);
                                setSelectedArchivedIndex(index);
                                detailsDivRef?.current?.scrollTo({
                                  top: 0,
                                  behavior: "instant",
                                });
                              }}
                            >
                              <div className="flex w-full">
                                <div className="w-10 flex justify-center items-center">
                                  <input
                                    disabled={saveLoading ? true : false}
                                    key={item.id}
                                    className={
                                      saveLoading ? "" : "cursor-pointer"
                                    }
                                    onChange={(e) =>
                                      handleSelectItem(
                                        e,
                                        item.clientMatters?.items?.length
                                      )
                                    }
                                    type="checkbox"
                                    value={item.id}
                                    id={item.id}
                                    checked={selectedArchivedItems.includes(
                                      item.id
                                    )}
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    setEmailContentOpen(true);
                                  }}
                                  className="w-full grow"
                                >
                                  <div className="flex">
                                    <div
                                      className={
                                        "w-full pb-2 text-sm font-normal text-left " +
                                        (selectedEmail === item.id
                                          ? "font-medium"
                                          : "")
                                      }
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item?.from?.split("<")[0]}
                                      <Highlight
                                        text={item?.from?.split("<")[0]}
                                        highlight={searchGmail}
                                      />
                                    </div>
                                    <div className="flex flex-row justify-end flex-none w-20 mr-2">
                                      <div>
                                        {item?.taskList?.id == null ||
                                        item?.taskList?.id == "" ? (
                                          <></>
                                        ) : (
                                          <AiFillCheckCircle
                                            size={16}
                                            className="text-green-500"
                                          />
                                        )}
                                      </div>
                                      <div>
                                        {item?.attachments?.items?.some(
                                          (attachment) =>
                                            !attachment.details.includes(
                                              "EMAIL_PDF_VERSION"
                                            )
                                        ) ? (
                                          <GrAttachment
                                            size={14}
                                            className="fill-gray-300"
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="content-end flex-none text-xs text-right w-18">
                                        {moment(item?.internalDate, "x")
                                          .local()
                                          .isSame(currentDate, "day")
                                          ? timeFormatter(new Date(item?.date))
                                          : dateFormatter(new Date(item?.date))}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="w-full text-xs font-normal text-left">
                                    {item?.subject}
                                  </div> */}
                                  <div className="grid grid-cols-1 w-full">
                                    {/*   <div
                                      className="mr-1 pb-px mb-1 w-auto whitespace-auto inline-block max-w-full whitespace-normal rounded-sm text-left text-xs font-light"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item?.snippet !== ""
                                            ? item?.snippet
                                            : item?.subject,
                                        // __html: `${item?.snippet
                                        //   ?.split(" ")
                                        //   .slice(0, 10)
                                        //   .join(" ")}${
                                        //   item?.snippet?.split(" ").length >
                                        //   10
                                        //     ? "..."
                                        //     : ""
                                        // }`,
                                      }}
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    />
                                  </div> */}
                                    <div
                                      className="w-auto inline-block max-w-full rounded-sm text-left text-xs font-light overflow-hidden"
                                      style={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <Highlight
                                        text={
                                          item?.snippet !== ""
                                            ? item?.snippet
                                            : item?.subject
                                        }
                                        highlight={searchGmail}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </div>
                    // <AutoSizer>
                    //   {({ width, height }) => (
                    //     <List
                    //       className="inboxScroll overflow-y-scroll"
                    //       width={width}
                    //       height={height}
                    //       rowHeight={
                    //         archivedEmails.length === 1
                    //           ? "100"
                    //           : cache.current.rowHeight
                    //       }
                    //       deferredMeasurementCache={cache.current}
                    //       rowCount={archivedEmails.length}
                    //       rowRenderer={({ key, index, style, parent }) => {
                    //         const item = archivedEmails[index];
                    //         return (
                    //           <CellMeasurer
                    //             key={item.id + "-" + index}
                    //             cache={cache.current}
                    //             parent={parent}
                    //             rowIndex={index}
                    //             columnIndex={0}
                    //           >
                    //             <tr
                    //               style={{
                    //                 ...style,
                    //                 width: "100%",
                    //                 height: "100%",
                    //                 border: "1px solid #f0f0f0",
                    //               }}
                    //               key={key}
                    //             >
                    //               {/* LEFT SIDE OF SCREEN */}
                    //               <td
                    //                 className={`checkBox h-full w-2/5 py-5 pr-2 text-center align-top cursor-pointer ${
                    //                   selectedEmail === item.id
                    //                     ? "bg-gray-200"
                    //                     : ""
                    //                 } ${
                    //                   prevSelectedEmails.includes(item.id)
                    //                     ? "bg-gray-100"
                    //                     : ""
                    //                 }`}
                    //                 onClick={() => {
                    //                   handleEmailClick(item.id);
                    //                   setselectArchivedEmailsDetails(item);
                    //                   handleSnippet(item, index);
                    //                   setSelectedArchivedIndex(index);
                    //                   detailsDivRef?.current?.scrollTo({
                    //                     top: 0,
                    //                     behavior: "instant",
                    //                   });
                    //                 }}
                    //               >
                    //                 <div className="flex w-full">
                    //                   <div className="w-10 flex justify-center items-center">
                    //                     <input
                    //                       disabled={saveLoading ? true : false}
                    //                       key={item.id}
                    //                       className={
                    //                         saveLoading ? "" : "cursor-pointer"
                    //                       }
                    //                       onChange={(e) =>
                    //                         handleSelectItem(
                    //                           e,
                    //                           item.clientMatters?.items?.length
                    //                         )
                    //                       }
                    //                       type="checkbox"
                    //                       value={item.id}
                    //                       id={item.id}
                    //                       checked={selectedArchivedItems.includes(
                    //                         item.id
                    //                       )}
                    //                     />
                    //                   </div>
                    //                   <div
                    //                     onClick={() => {
                    //                       setEmailContentOpen(true);
                    //                     }}
                    //                     className="w-full grow"
                    //                   >
                    //                     <div className="flex">
                    //                       <div className="w-full pb-2 text-sm font-normal text-left">
                    //                         {item?.from?.split("<")[0]}
                    //                       </div>
                    //                       <div className="flex flex-row justify-end flex-none w-20 mr-2">
                    //                     <div>
                    //                       {item?.taskList?.id == null ||
                    //                       item?.taskList?.id == "" ? (
                    //                         <></>
                    //                       ) : (
                    //                         <AiFillCheckCircle
                    //                           size={16}
                    //                           className="text-green-500"
                    //                         />
                    //                       )}
                    //                     </div>
                    //                     <div>
                    //                       {item?.attachments?.items?.some(
                    //                         (attachment) =>
                    //                           !attachment.details.includes(
                    //                             "EMAIL_PDF_VERSION"
                    //                           )
                    //                       ) ? (
                    //                         <GrAttachment
                    //                           size={14}
                    //                           className="fill-gray-300"
                    //                         />
                    //                       ) : (
                    //                         <></>
                    //                       )}
                    //                     </div>
                    //                     <div className="content-end flex-none text-xs text-right w-18">
                    //                       {moment(item?.internalDate, "x")
                    //                         .local()
                    //                         .isSame(currentDate, "day")
                    //                         ? timeFormatter(new Date(item?.date))
                    //                         : dateFormatter(new Date(item?.date))}
                    //                     </div>
                    //                   </div>
                    //                     </div>

                    //                     {/* <div className="w-full text-xs font-normal text-left">
                    //                       {item?.subject}
                    //                     </div> */}
                    //                     <div className="grid grid-cols-1 w-full">
                    //                       <div
                    //                         className="mr-2 w-auto whitespace-auto inline-block max-w-full whitespace-normal rounded-sm text-left text-xs font-light"
                    //                         dangerouslySetInnerHTML={{
                    //                         __html: item?.snippet !== ""
                    //                           ? item?.snippet
                    //                           : item?.subject
                    //                         // __html: `${item?.snippet
                    //                         //   ?.split(" ")
                    //                         //   .slice(0, 10)
                    //                         //   .join(" ")}${
                    //                         //   item?.snippet?.split(" ").length >
                    //                         //   10
                    //                         //     ? "..."
                    //                         //     : ""
                    //                         // }`,
                    //                         }}
                    //                         style={{
                    //                           whiteSpace: "nowrap",
                    //                           overflow: "hidden",
                    //                           textOverflow: "ellipsis"
                    //                         }}/>
                    //                     </div>
                    //                   </div>
                    //                 </div>
                    //               </td>
                    //             </tr>
                    //           </CellMeasurer>
                    //         );
                    //       }}
                    //     />
                    //   )}
                    // </AutoSizer>
                  )}
                </tbody>
              </table>
            </div>

            {emailContentOpen ? (
              //right-hand screen
              <div
                className="overflow-hidden h-full col-span-2 flex flex-col border-t border-l border-gray-200 p-4 pb-0"
                style={{
                  position: "sticky",
                  top: "40px",
                  // width: (width * 0.62 - 40) + "px",
                  // height: height * heightMul + "px",
                }}
                ref={detailsDivRef}
              >
                <div className="px-2">
                  <div className="flex pt-4">
                    <span className="flex-grow text-left font-bold">
                      {/* {selectArchivedEmailsDetails.subject} */}
                      <Highlight
                        text={selectArchivedEmailsDetails?.subject}
                        highlight={searchGmail}
                        classNames="flex-grow text-left font-bold"
                      />
                    </span>
                    <span className="text-right">
                      {dateTimeNoSecondsFormatter(
                        new Date(selectArchivedEmailsDetails?.date)
                      )}
                    </span>
                  </div>
                  <div className="my-2 flex">
                    <span className="flex-grow text-left">
                      {/* {selectArchivedEmailsDetails?.from} */}
                      <Highlight
                        text={selectArchivedEmailsDetails?.from}
                        highlight={searchGmail}
                        classNames="flex-grow text-left"
                      />
                    </span>
                  </div>
                </div>
                <hr />

                <div
                  className="inboxScroll h-full overflow-y-hidden grid"
                  style={{ gridTemplateColumns: "1fr 1px 1fr" }}
                >
                  <div className="inboxScrollInner overflow-y-auto p-5 pr-4">
                    {/* <div className="mb-11">
                        <div className="mt-11 flex">
                          <span className="flex-grow text-left">
                            Describe Email
                          </span>
                          <button
                            className="rounded bg-green-500 px-4 py-2 text-right text-xs font-bold text-white hover:bg-green-700"
                            onClick={() =>
                              handleOpenAI(
                                selectUnsavedEmailsDetails?.id,
                                selectedUnsavedIndex
                              )
                            }
                          >
                            {`Summarize Email`}
                          </button>
                        </div>
                      </div> */}

                    {/* CLIENT MATTERS */}
                    <div className="mb-5">
                      <p>
                        {hbaDomains.includes(window.location.hostname)
                          ? "Project Name"
                          : "Client Matter"}
                      </p>
                      <div className="mt-3 w-full">
                        <p className="font-semibold">
                          {
                            selectArchivedEmailsDetails?.clientMatters?.items[0]
                              ?.client?.name
                          }{" "}
                          /{" "}
                          {
                            selectArchivedEmailsDetails?.clientMatters?.items[0]
                              ?.matter?.name
                          }
                        </p>
                      </div>
                    </div>

                    {/* EMAIL LABEL */}
                    <div className="mt-5 mb-5">
                      <p>Label</p>
                      <div className="mb-1" disabled={true}>
                        <Select
                          values={selectArchivedEmailsDetails?.labels?.items.map(
                            (item_label) => ({
                              value: item_label?.id,
                              label: item_label?.name,
                            })
                          )}
                          multi={true}
                          options={[]}
                          disabled={true}
                          placeholder="Labels"
                          color="#8a8a8a"
                          className="outline-none background-slate-900 placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 mt-2 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                        />
                      </div>
                    </div>

                    {/* DESCRIPTION EMAIL */}
                    <div className="mt-5">
                      <p>Description</p>
                      <p
                        className="font-poppins mt-2 mb-2 w-full whitespace-normal break-words rounded bg-gray-200 p-2"
                        style={{
                          border: "solid 1px #c4c4c4",
                          outlineColor: "rgb(204, 204, 204, 0.5)",
                          outlineWidth: "thin",
                          overflow: "auto",
                          height: "100px",
                          maxWidth: width <= 540 ? "18rem" : "50rem",
                        }}
                        suppressContentEditableWarning
                        dangerouslySetInnerHTML={{
                          __html: selectArchivedEmailsDetails?.description,
                        }}
                        onBlur={(e) => {
                          handleSaveMainDesc(
                            e,
                            selectArchivedEmailsDetails?.id,
                            selectArchivedEmailsDetails
                          );
                        }}
                        contentEditable={false}
                      ></p>
                    </div>

                    {/* TASKLIST */}
                    {selectArchivedEmailsDetails?.taskList?.id == null ||
                    selectArchivedEmailsDetails?.taskList?.id == "" ? (
                      <></>
                    ) : (
                      <p className="flex text-sm font-semibold text-green-500">
                        Already in Tasklist{" "}
                        <AiFillCheckCircle className="ml-1 rounded-full text-green-500" />
                      </p>
                    )}
                    <hr className="mt-5" />

                    {/* SAVE AND ARCHIVE EMAIL */}
                    <ActionButtons
                      setUnsavedEmailsCount={setUnsavedEmailsCount}
                      setSavedEmailsCount={setSavedEmailsCount}
                      setArchivedEmailsCount={setArchivedEmailsCount}
                      taskListColumns={taskListColumns}
                      selectedUnsavedItems={selectedUnsavedItems}
                      setSelectedUnsavedItems={setSelectedUnsavedItems}
                      selectedArchivedItems={selectedArchivedItems}
                      setSelectedArchivedItems={setSelectedArchivedItems}
                      getUnSavedEmails={getUnSavedEmails}
                      unSavedEmails={unSavedEmails}
                      setUnsavedEmails={setUnsavedEmails}
                      setResultMessage={setResultMessage}
                      setShowToast={setShowToast}
                      saveLoading={saveLoading}
                      setSaveLoading={setSaveLoading}
                      sortByDate={sortByDate}
                      archivedEmails={archivedEmails}
                      setArchivedEmails={setArchivedEmails}
                      selectedTaskRegister={selectedTaskRegister}
                      setSelectedTaskRegister={setSelectedTaskRegister}
                      displayImageAttachments={displayImageAttachments}
                      setEmailContentOpen={setEmailContentOpen}
                      setMobileLoading={setMobileLoading}
                      showDetailsCopy={showDetailsCopy}
                      setShowDetailsCopy={setShowDetailsCopy}
                      showCopy={showCopy}
                      setShowCopy={setShowCopy}
                      searchGmail={searchGmail}
                      isOutlookIntegrated={isOutlookIntegrated}
                      emailFilters={emailFilters}
                      unSavedForTaskListCtr={unSavedForTaskListCtr}
                      savedForTaskListCtr={savedForTaskListCtr}
                      getArchivedEmails={getArchivedEmails}
                      setDoneAllocatingEmails={setDoneAllocatingEmails}
                      setpendingMessage={setpendingMessage}
                      setPreviousUnsavedEmails={setPreviousUnsavedEmails}
                      setPreviousSavedEmails={setPreviousSavedEmails}
                      setPreviousArchivedEmails={setPreviousArchivedEmails}
                      setSearchGmail={setSearchGmail}
                      selectAllArchived={selectAllArchived}
                      selectAllSaved={selectAllSaved}
                      selectAllUnsaved={selectAllUnsaved}
                      setDisplayImageAttachments={setDisplayImageAttachments}
                      selectAllCheck={selectAllCheck}
                      setSelectAllCheck={setSelectAllCheck}
                      handleExecuteFilter={handleExecuteFilter}
                      getSavedEmails={getSavedEmails}
                      openTab={openTab}
                      fetchData={fetchData}
                      setWaitUnSaved={setWaitUnSaved}
                      setWaitSaved={setWaitSaved}
                      setWaitArchived={setWaitArchived}
                      setOpenTab={setOpenTab}
                      selectedSavedItems={selectedSavedItems}
                      setSelectedSavedItems={setSelectedSavedItems}
                      tasklistGmailIds={tasklistGmailIds}
                      setTasklistGmailIds={setTasklistGmailIds}
                      setSelectedTaskList={setSelectedTaskList}
                      selectedTaskList={selectedTaskList}
                      taskRegisterList={taskRegisterList}
                      savedEmails={savedEmails}
                      setSavedEmails={setSavedEmails}
                      saveProgress={saveProgress}
                      setSaveProgress={setSaveProgress}
                    />
                  </div>
                  <div className="my-5 border-l border-gray-300"></div>
                  <div className="inboxScrollInner overflow-y-auto p-5 pr-4">
                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html: emailHTML,
                      }}
                    ></p> */}
                    <Highlight
                      text={emailHTML}
                      highlight={searchGmail}
                      classNames={"inboxScrollInner overflow-y-auto p-5 pr-4"}
                    />
                    <hr className="border mt-5" />
                    <div className="mt-5 mb-5 flex w-full flex-col">
                      <div className="flex justify-between">
                        <p>Attachments and Labels</p>
                        <ActionButtons
                          setUnsavedEmailsCount={setUnsavedEmailsCount}
                          setSavedEmailsCount={setSavedEmailsCount}
                          setArchivedEmailsCount={setArchivedEmailsCount}
                          taskListColumns={taskListColumns}
                          selectedUnsavedItems={selectedUnsavedItems}
                          setSelectedUnsavedItems={setSelectedUnsavedItems}
                          selectedSavedItems={selectedSavedItems}
                          setSelectedSavedItems={setSelectedSavedItems}
                          selectedArchivedItems={selectedArchivedItems}
                          setSelectedArchivedItems={setSelectedArchivedItems}
                          taskRegisterList={taskRegisterList}
                          openTab={openTab}
                          fetchData={fetchData}
                          setWaitUnSaved={setWaitUnSaved}
                          setWaitSaved={setWaitSaved}
                          setWaitArchived={setWaitArchived}
                          setOpenTab={setOpenTab}
                          getUnSavedEmails={getUnSavedEmails}
                          getSavedEmails={getSavedEmails}
                          unSavedEmails={unSavedEmails}
                          setUnsavedEmails={setUnsavedEmails}
                          savedEmails={savedEmails}
                          setSavedEmails={setSavedEmails}
                          setResultMessage={setResultMessage}
                          setShowToast={setShowToast}
                          saveLoading={saveLoading}
                          setSaveLoading={setSaveLoading}
                          setDoneAllocatingEmails={setDoneAllocatingEmails}
                          setpendingMessage={setpendingMessage}
                          sortByDate={sortByDate}
                          getArchivedEmails={getArchivedEmails}
                          archivedEmails={archivedEmails}
                          setArchivedEmails={setArchivedEmails}
                          setSelectedTaskList={setSelectedTaskList}
                          selectedTaskList={selectedTaskList}
                          selectedTaskRegister={selectedTaskRegister}
                          setSelectedTaskRegister={setSelectedTaskRegister}
                          tasklistGmailIds={tasklistGmailIds}
                          setTasklistGmailIds={setTasklistGmailIds}
                          unSavedForTaskListCtr={unSavedForTaskListCtr}
                          savedForTaskListCtr={savedForTaskListCtr}
                          displayImageAttachments={displayImageAttachments}
                          setDisplayImageAttachments={
                            setDisplayImageAttachments
                          }
                          selectAllCheck={selectAllCheck}
                          setSelectAllCheck={setSelectAllCheck}
                          setEmailContentOpen={setEmailContentOpen}
                          selectAllArchived={selectAllArchived}
                          selectAllSaved={selectAllSaved}
                          selectAllUnsaved={selectAllUnsaved}
                          setMobileLoading={setMobileLoading}
                          showDetailsCopy={showDetailsCopy}
                          setShowDetailsCopy={setShowDetailsCopy}
                          showCopy={showCopy}
                          setShowCopy={setShowCopy}
                          searchGmail={searchGmail}
                          setSearchGmail={setSearchGmail}
                          isOutlookIntegrated={isOutlookIntegrated}
                          setPreviousUnsavedEmails={setPreviousUnsavedEmails}
                          setPreviousSavedEmails={setPreviousSavedEmails}
                          setPreviousArchivedEmails={setPreviousArchivedEmails}
                          emailFilters={emailFilters}
                          handleExecuteFilter={handleExecuteFilter}
                          hideAttachmentOnly={true}
                          saveProgress={saveProgress}
                          setSaveProgress={setSaveProgress}
                        />
                      </div>
                      {selectArchivedEmailsDetails?.attachments?.items?.map(
                        (item_attach, indexAttachments) => (
                          <React.Fragment key={item_attach.id}>
                            <div className="flex-grow mb-4">
                              <div className="align-center mt-2 flex justify-between">
                                <div
                                  className="focus:outline-none mr-1 inline-flex w-16 flex-none cursor-pointer items-center rounded bg-gray-100 px-2 py-1 text-xs font-semibold text-opacity-90 hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                                  id={item_attach?.id}
                                  title={item_attach?.name}
                                  onClick={() =>
                                    previewAndDownloadFile(item_attach?.id)
                                  }
                                >
                                  {item_attach?.name.substring(0, 10)}
                                  {item_attach?.name?.length >= 10 ? "..." : ""}
                                </div>
                                <Select
                                  values={item_attach?.labels?.items?.map(
                                    (att) => ({
                                      value: att?.id,
                                      label: att?.name,
                                    })
                                  )}
                                  multi={true}
                                  options={[]}
                                  disabled={true}
                                  color="#8a8a8a"
                                  placeholder="Labels"
                                  className="flex-1 grow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none z-100 w-full rounded border-0 bg-white text-xs shadow focus:ring"
                                />
                              </div>
                              <div
                                className={
                                  "font-poppins mt-1 h-full w-full whitespace-normal rounded-sm bg-gray-200 p-2"
                                }
                                style={{
                                  border: "solid 1px #c4c4c4",
                                  cursor: "auto",
                                  outlineColor: "rgb(204, 204, 204, 0.5)",
                                  outlineWidth: "thin",
                                }}
                                suppressContentEditableWarning
                                dangerouslySetInnerHTML={{
                                  __html: item_attach.details,
                                }}
                                contentEditable={false}
                              ></div>
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="px-5 py-5">
                  <hr />
                  <br />
                  <div className="flex">
                    <span className="flex-grow font-bold text-left">
                      {selectArchivedEmailsDetails?.subject}
                    </span>
                    <span className="text-right">
                      {dateTimeNoSecondsFormatter(
                        new Date(selectArchivedEmailsDetails?.date)
                      )}{" "}
                    </span>
                  </div>
                  <div className="flex my-2">
                    <span className="flex-grow text-left">
                      {selectArchivedEmailsDetails?.from}
                    </span>
                  </div>
                  <hr />
                  <br />
                  <div className="mb-11">
                    <p
                      className="p-2 mt-8"
                      dangerouslySetInnerHTML={{
                        __html: emailHTML,
                      }}
                    ></p>
                    <div className="flex mt-11">
                      <span className="flex-grow text-left">
                        Describe Email
                      </span>
                      <button
                        className="px-4 py-2 text-xs font-bold text-right text-white bg-green-500 rounded hover:bg-green-700"
                        onClick={() =>
                          handleOpenAI(
                            selectArchivedEmailsDetails?.id,
                            selectedArchivedIndex
                          )
                        }
                      >
                        {`Summarize Email`}
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-5">
                    <p>Description</p>
                    <p
                      className="w-full p-2 mt-2 mb-2 break-words whitespace-normal bg-gray-200 rounded font-poppins"
                      style={{
                        border: "solid 1px #c4c4c4",
                        outlineColor: "rgb(204, 204, 204, 0.5)",
                        outlineWidth: "thin",
                        overflow: "auto",
                        height: "100px",
                        maxWidth: width <= 540 ? "18rem" : "50rem",
                      }}
                      suppressContentEditableWarning
                      dangerouslySetInnerHTML={{
                        __html: selectArchivedEmailsDetails?.description,
                      }}
                      onBlur={(e) => {
                        handleSaveMainDesc(
                          e,
                          selectArchivedEmailsDetails?.id,
                          selectedArchivedIndex
                        );
                      }}
                      contentEditable={false}
                    ></p>
                  </div>

                  <hr className="border-2 border-dotted" />
                  <div className="mt-5 mb-5">
                    <p>Client Matter</p>
                    <div className="w-full mt-3">
                      <p className="font-semibold">
                        {
                          selectArchivedEmailsDetails?.clientMatters?.items[0]
                            ?.client?.name
                        }{" "}
                        /{" "}
                        {
                          selectArchivedEmailsDetails?.clientMatters?.items[0]
                            ?.matter?.name
                        }
                      </p>
                    </div>
                  </div>

                  <hr className="border-2 border-dotted" />
                  <div className="mt-5 mb-5">
                    <p>Label</p>
                    <div className="mb-1" disabled={true}>
                      <Select
                        values={selectArchivedEmailsDetails?.labels?.items.map(
                          (item_label) => ({
                            value: item_label?.id,
                            label: item_label?.name,
                          })
                        )}
                        multi={true}
                        options={[]}
                        disabled={true}
                        placeholder="Labels"
                        color="#8a8a8a"
                        className="w-full mt-2 text-xs bg-white border-0 rounded shadow outline-none background-slate-900 placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring z-100"
                      />
                    </div>
                  </div>

                  <hr className="border-2 border-dotted" />
                  <div className="mt-5 mb-5 ">
                    <p>Attachments and Labels</p>
                    {selectArchivedEmailsDetails?.attachments?.items?.map(
                      (item_attach, indexAttachments) => (
                        <React.Fragment key={item_attach.id}>
                          <div className="flex-grow">
                            <div className="flex mt-2 align-center">
                              <div
                                className="inline-flex items-center flex-none w-16 px-2 py-1 mr-1 text-xs font-semibold bg-gray-100 rounded cursor-pointer text-opacity-90 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                                id={item_attach?.id}
                                title={item_attach?.name}
                                onClick={() =>
                                  previewAndDownloadFile(item_attach?.id)
                                }
                              >
                                {item_attach?.name.substring(0, 10)}
                                {item_attach?.name?.length >= 10 ? "..." : ""}
                              </div>
                              <div
                                className={
                                  "mr-2 p-2 w-full h-full font-poppins rounded-sm float-right bg-gray-200 whitespace-normal"
                                }
                                style={{
                                  border: "solid 1px #c4c4c4",
                                  cursor: "auto",
                                  outlineColor: "rgb(204, 204, 204, 0.5)",
                                  outlineWidth: "thin",
                                }}
                                suppressContentEditableWarning
                                dangerouslySetInnerHTML={{
                                  __html: item_attach?.details,
                                }}
                                contentEditable={false}
                              ></div>

                              <Select
                                values={item_attach?.labels?.items?.map(
                                  (att) => ({
                                    value: att?.id,
                                    label: att?.name,
                                  })
                                )}
                                multi={true}
                                options={[]}
                                disabled={true}
                                color="#8a8a8a"
                                placeholder="Labels"
                                className="w-full mb-1 text-xs bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring z-100"
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )}
                  </div>
                  <br />
                </div> */}
              </div>
            ) : (
              //NOTE: Default Message
              <>
                <div className="border-gray-200 border-t border-l  w-full col-span-2 flex flex-col items-center justify-start ">
                  <>
                    {selectedArchivedItems.length > 0 ? (
                      <div className="mt-10 inline-flex flex-wrap gap-2">
                        <span className="text-lg font-semibold text-gray-500">
                          {selectedArchivedItems.length}
                        </span>
                        <span className="text-lg font-semibold text-gray-500">
                          Total Selected Items
                        </span>
                      </div>
                    ) : (
                      <span className="mt-10 text-lg font-semibold text-gray-500">
                        No Emails Selected
                      </span>
                    )}
                  </>
                  <div className="bg-gray-300 w-64 h-2 mt-7 rounded-3xl"></div>
                  <div className="mt-9 text-center">
                    {/* <p>0 / 80000</p> */}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* {showToast && resultMessage && (
            <ToastNotification title={resultMessage} hideToast={hideToast} />
          )} */}
        </>
      ) : (
        //MOBILE VERSION -KJMF//
        <>
          <table
            id="table-el"
            className="border-slate-100 min-w-full table-fixed h-full divide-y divide-gray-200 border-b-2 border-l-2 border-r-2 text-xs"
          >
            <tbody
              className="divide-y divide-gray-200 bg-white"
              style={{ width: "100%", height: totalSize }}
            >
              {!mobileLoading && !waitArchived ? (
                <>
                  {virtualRows.map((row) => {
                    const item = archivedEmails[row.index];
                    return (
                      <div
                        className="pb-1"
                        id="mobileContent"
                        ref={(e) => row.measureElement(e)}
                        scrollTop={
                          scrollPosition === -1
                            ? undefined
                            : scrollPosition * 310
                        }
                        onScroll={() => {
                          if (scrollPosition !== -1) {
                            onScroll(-1);
                          }
                        }}
                        // width={width}
                        // height={totalSize}
                      >
                        {show && snippetId === item.id ? (
                          <tr
                            className="fixed block w-full h-full"
                            key={row.key}
                            ref={(el) => (ref.current[row.index] = el)}
                          >
                            <div
                              className="fixed block w-full h-screen px-2 py-2 mt-1 ml-1 overflow-auto bg-gray-100 border border-gray-200 rounded-lg cursor-pointer"
                              style={{
                                top: 0,
                                width: "95vw",
                              }}
                              onClick={(e) => e.stopPropagation()}
                              id="scTop"
                            >
                              <div className="py-5 overflow-x-scroll overflow-y-scroll test123">
                                <div
                                  className="fixed z-40 block mb-4"
                                  style={{
                                    top: 0,
                                    position: "sticky",
                                  }}
                                >
                                  <div className="flex items-center justify-between mx-2">
                                    <button
                                      onClick={handleRootClose}
                                      className=""
                                    >
                                      <div className="p-2 mb-4 font-bold bg-white border border-gray-100 rounded-lg shadow-sm">
                                        <MdArrowBackIos className="float-left text-xl" />
                                        Back
                                      </div>
                                    </button>
                                    {/* <p className="ml-10 font-bold text-blue-400 text-s">
                    <input
                      key={item.id}
                      className="-ml-10 cursor-pointer"
                      onChange={(e) =>
                        handleSelectForTaskList(
                          e,
                          item.clientMatters.items.length
                        )
                      }
                      type="checkbox"
                      value={item.id}
                      id={item.id}
                      checked={checkStatus(item.id, item.taskList.id)}
                      disabled={true}
                    /> Add To Tasklist </p> */}
                                  </div>

                                  {/* <div><p className="text-lg text-semibold">{item.clientMatters.items[0].client.name} / {item.clientMatters.items[0].matter.name}</p></div> */}
                                </div>
                                <div
                                  className="max-h-screen overflow-x-hidden overflow-y-hidden test123"
                                  style={{
                                    maxHeight: openScreenSize,
                                  }}
                                >
                                  {emailPreview ? (
                                    <>
                                      <p>{`From: ${(
                                        <Highlight
                                          text={item?.from}
                                          highlight={searchGmail}
                                          classNames="font-semibold"
                                        />
                                      )}`}</p>
                                      <p>
                                        {`Date: ${moment(item.internalDate, "x")
                                          .local()
                                          .format("DD MMM YYYY, hh:mm A")}`}
                                      </p>
                                      <p>{`Subject: ${(
                                        <Highlight
                                          text={item?.subject}
                                          highlight={searchGmail}
                                          classNames="font-semibold"
                                        />
                                      )}`}</p>
                                      <p>{`To: ${(
                                        <Highlight
                                          text={item?.to}
                                          highlight={searchGmail}
                                          classNames="font-semibold"
                                        />
                                      )}`}</p>
                                      {item.cc && (
                                        <p>{`CC: ${(
                                          <Highlight
                                            text={item?.cc}
                                            highlight={searchGmail}
                                            classNames="font-semibold"
                                          />
                                        )}`}</p>
                                      )}

                                      <p
                                        className="p-2 mt-8"
                                        dangerouslySetInnerHTML={{
                                          __html: (
                                            <Highlight
                                              text={emailHTML}
                                              highlight={searchGmail}
                                              classNames="font-semibold"
                                            />
                                          ),
                                        }}
                                      ></p>
                                    </>
                                  ) : (
                                    <Loading />
                                  )}
                                </div>
                              </div>
                            </div>
                          </tr>
                        ) : (
                          <tr
                            className={
                              show ? "w-full hidden px-1" : "w-full block px-1"
                            }
                            key={row.key}
                            style={{
                              height: "auto",
                            }} //This fixed the scroll and jitter issues
                          >
                            <div
                              className={
                                "block w-full px-2 py-2 mt-1 bg-gray-100 border border-gray-200 rounded-lg cursor-pointer " +
                                (row.index === archivedEmails.length - 1
                                  ? "mb-16"
                                  : "")
                              }
                              style={{
                                width: "95vw",
                              }}
                            >
                              <div className="block">
                                <div className="flex">
                                  <div className="flex-none w-5">
                                    <input
                                      key={item.id}
                                      className="mr-1 cursor-pointer"
                                      onChange={(e) =>
                                        handleSelectItem(
                                          e,
                                          item.clientMatters.items.length
                                        )
                                      }
                                      type="checkbox"
                                      value={item.id}
                                      id={item.id}
                                      checked={selectedArchivedItems.includes(
                                        item.id
                                      )}
                                    />
                                  </div>
                                  <div
                                    className="flex-1 w-90"
                                    onClick={(e) =>
                                      // handleSnippet(item, index)
                                      handleMobileSnippet(item, row.index)
                                    }
                                  >
                                    <p className="font-semibold">
                                      {/* {item.subject} */}
                                      <Highlight
                                        text={item?.subject}
                                        highlight={searchGmail}
                                        classNames="font-semibold"
                                      />
                                    </p>
                                    <p className="text-xs">
                                      <Highlight
                                        text={item?.from}
                                        highlight={searchGmail}
                                        classNames="font-semibold"
                                      />{" "}
                                      at{" "}
                                      {dateTimeNoSecondsFormatter(item?.date)}
                                    </p>
                                    {/* Email Description */}
                                    {item.description !== null &&
                                    item.description !== undefined &&
                                    item.description !== "" ? (
                                      <div
                                        className="items-center w-full mt-1 text-xs break-words whitespace-normal rounded-sm font-poppins whitespace-auto"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></div>
                                    ) : (
                                      ""
                                    )}
                                    <p className="mt-1 text-xs font-semibold">
                                      {item.clientMatters.items.length > 0 ? (
                                        <span className="flex nowrap">
                                          <BsFillCircleFill className="text-green-400 text-xs w-4 pt-0.5" />
                                          <p className="pl-1 text-xs font-semibold text-green-500 break-words">
                                            Assigned
                                            <span
                                              className="text-xs font-semibold"
                                              style={{
                                                color: "#454545",
                                              }}
                                            >
                                              {" "}
                                              to clientmatter:{" "}
                                              {
                                                item.clientMatters.items[0]
                                                  .client.name
                                              }{" "}
                                              /{" "}
                                              {
                                                item.clientMatters.items[0]
                                                  .matter.name
                                              }
                                            </span>
                                          </p>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                    <p className="text-xs font-semibold">
                                      {item.taskList.id !== "" ? (
                                        <span className="flex nowrap">
                                          <BsFillCircleFill className="text-green-400 text-xs w-4 pt-0.5" />
                                          <p className="pl-1 text-xs font-semibold break-words">
                                            Added in tasklist
                                          </p>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex-none w-10">
                                    <p className="flex">
                                      <GrAttachment className="mt-1 text-xs" />{" "}
                                      {
                                        item?.attachments?.items?.filter(
                                          (attachment) =>
                                            !attachment.details.includes(
                                              "EMAIL_PDF_VERSION"
                                            )
                                        ).length
                                      }{" "}
                                      {" >"}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tr>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {(virtualRows?.length > 10
                    ? virtualRows
                    : [...Array(5).keys()]
                  )?.map((data, index) => {
                    let height = 310;
                    if (data.size) {
                      height = data.size;
                    }
                    return (
                      <tr
                        className="test123 block w-full p-1"
                        style={{ height: `${height}px` }}
                      >
                        <div className="h-full w-full rounded-lg border border-gray-200 bg-gray-100 p-2 pr-4">
                          <div className="h-full w-full flex flex-row gap-2">
                            <div>
                              <SkeletonTheme width={"15px"}>
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            </div>
                            <div className="flex w-full flex-col">
                              <SkeletonTheme>
                                <Skeleton className="text-xs mb-1" count={2} />
                              </SkeletonTheme>
                              <SkeletonTheme>
                                <Skeleton
                                  containerClassName="h-full grow block mb-2"
                                  className="h-full"
                                  count={1}
                                />
                              </SkeletonTheme>
                            </div>
                            <div className="gap-2 flex flex-col">
                              <SkeletonTheme width={"28px"} height={"16px"}>
                                <Skeleton className="mb-1" count={1} />
                              </SkeletonTheme>
                            </div>
                          </div>
                        </div>
                      </tr>
                    );
                  })}
                </>
              )}
              {/* iterate items here end */}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
export default TableArchivedInfo;
