import "../../assets/styles/AccountSettings.css";
import "./contacts.css";

import { CgChevronLeft, CgSortAz, CgSortZa, CgTrash } from "react-icons/cg";
import { alphabetArray } from "../../constants/Alphabet";
import { FaEdit, FaSort, FaUsers } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import anime from "animejs";
import { API } from "aws-amplify";
import AddContactModal from "./add-contact-modal";
import DeleteModal from "./delete-modal";
// import User from "./user";
import ContactInformationModal from "./contact-information-modal";
// import { useIdleTimer } from "react-idle-timer";
import SessionTimeout from "../session-timeout/session-timeout-modal";
import Skeleton from "react-loading-skeleton";
import { SkeletonTheme } from "react-loading-skeleton";

import TeamsTab from "./teams-tab/teams-tab";
import _, { get, set } from "lodash";

import AddTeamModal from "./add-team-revamp-modal";
import ToastNotification from "../toast-notification";
import Button from "../../shared/Button";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import humaReadableFormat from "../../shared/humanReadableFormat";
import PortalAccessConfirmationModal from "./portal-access-confirmation-modal";
import MattersTab from "./matters-tab/matters-tab";
import MatterInformationModal from "./matters-tab/matter-information-modal";
import AddMatterModal from "./add-matter-modal";
// import AddClientMatterModal from "../inbox/addClientMatterModal";
import {
  ListUsers,
  ListTeams,
  ListArchivedUsers,
  ListClientMatters,
  GetUserCM,
} from "../../shared/graphql/queries";
// import { UpdateUser } from "../../shared/graphql/mutations";
import Spinner from "../../shared/Spinner";
import ArchivedUsersTab from "./archived-users-tab/archived-users-tab";
// import { SidebarData } from "../sidebar/SidebarData";
import useSidebarData from "../sidebar/useSidebarData";

// TANSTACK VIRTUALIZED
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  // useSortBy,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

export default function Contacts() {
  const [showAddContactModal, setshowAddContactModal] = useState(false);
  const [
    showPortalAccessConfirmationModal,
    setshowPortalAccessConfirmationModal,
  ] = useState({
    toggle: false,
    prompt: "",
    data: null,
  });
  const handleModalClose = () => {
    setshowAddContactModal(false);
  };
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);

  const rows = useRef(null);
  const refLetters = useRef([]); //added Refletters to fix scrolling
  const [shortcutSelected, setShortcutSelected] = useState("");

  const [ShowDeleteModal, setShowDeleteModal] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [ActiveMenu, setActiveMenu] = useState("Contacts");
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [errortoast, seterrortoast] = useState(null);
  const [ActiveLetter, setActiveLetter] = useState("a");
  const [sortBy, setSortBy] = useState("firstName");
  //Delete Function variables
  const [isToDelete, setisToDelete] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompanyId, setUserCompanyId] = useState("");

  const [ContactList, setContactList] = useState(null);
  const [areContactsAdded, setAreContactsAdded] = useState(false);

  const [ShowEditModal, setShowEditModal] = useState(false); //added Edit Modal
  const [CurrentUser, setCurrentUser] = useState({}); //Added current User

  const [loading, setloading] = useState(true);

  const [defaultCompany, setDefaultCompany] = useState("");
  const [contactsAlphabet, setContactsAlphabet] = useState([]);
  const [teamsAlphabet, setTeamsAlphabet] = useState([]);
  const [mattersAlphabet, setMattersAlphabet] = useState([]);
  const [ShowAddTeamModal, setShowAddTeamModal] = useState(false);
  const [TeamList, setTeamList] = useState([]);
  const [ShowBurst, setShowBurst] = useState(false);
  const [CompanyUsers, setCompanyUsers] = useState([]);
  const [ArchivedUsers, setArchivedUsers] = useState([]);
  const [archivedUsersAlphabet, setArchivedUsersAlphabet] = useState([]);
  const [UserTypes, setUserTypes] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [showEditMatterModal, setShowEditMatterModal] = useState(false);
  const [selectedMatter, setSelectedMatter] = useState([]);
  const [showAddMatterModal, setShowAddMatterModal] = useState(false);
  const [addedMatter, setAddedMatter] = useState(false);
  const [skeletonMatter, setSkeletonMatter] = useState(true);
  const [matters, setMatters] = useState([]);
  const [matterSortBy, setMatterSortBy] = useState("Client");

  const hideToast = () => {
    setShowToast(false);
  };

  const { height, width } = useWindowDimensions();

  let SidebarData = useSidebarData(localStorage.getItem("userCategory"));
  const dashboardPath = SidebarData.find(
    (item) => item.name === "DASHBOARD"
  )?.path;

  const DEFAULT_USERTYPES_QUERY = `query getDefaultUserTypes {
    defaultUserType
  }`;

  const TAG_TEAM_MEMBER_MUTATION = `mutation tagTeamMember($teamId: ID, $members: [MemberInput]) {
    teamMemberTag(teamId: $teamId, members: $members) {
      id
    }
  }`;

  const TAG_ALL_USER_CLIENTMATTER_MUTATION = `mutation tagAlluserClientMatter($companyId: ID, $userId: ID, $userType: UserType) {
    userClientMatterTagAll(userId: $userId, userType: $userType, companyId: $companyId) {
      id
    }
  }`;

  const RESEND_INVITE_MUTATION = `mutation resendUserInvite($email: String) {
    userResendInvite(email: $email) {
      isEnabled
      userAttributes
      userStatus
    }
  }`;

  const REMOVE_PORTAL_ACCESS_MUTATION = `mutation removeUserPortalAccess($id: ID, $companyId: ID) {
    userRemovePortalAccess(companyId: $companyId, id: $id)
  }`;

  const UPDATE_COMPANY_USER_MUTATION = `mutation updateUserCompany($companyId: ID, $hasPortalAccess: Boolean, $userId: ID) {
    userCompanyUpdate(
      companyId: $companyId
      hasPortalAccess: $hasPortalAccess
      userId: $userId
    ) {
      id
    }
  }`;

  const INVITE_EXISTING_USER_MUTATION = `mutation inviteCompanyUser($email: String, $company: String, $invitee: String) {
    userCompanyInvite(email: $email, company: $company, invitee: $invitee)
  }`;

  const getMatters = async () => {
    try {
      const result = await ListClientMatters(localStorage.getItem("companyId"));
      setMatters(result.data.company.clientMatters.items);
      setSkeletonMatter(false);
    } catch (e) {
      console.error("getMatters()", e);
    }
  };

  useEffect(() => {
    getMatters();
    // getIsTwoFactorAuthBool()
  }, []);

  const tagTeamMember = async (teamId, members) => {
    // If there are no members provided, no need to proceed with the API call.
    if (!teamId) {
      return;
    }

    try {
      const response = await API.graphql({
        query: TAG_TEAM_MEMBER_MUTATION,
        variables: { teamId, members },
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getArchivedUsers = async () => {
    try {
      // if (ArchivedUsers?.length) {
      //   return; // No need to proceed further if ArchivedUsers is already populated
      // }

      const archivedUsers = await ListArchivedUsers(
        localStorage.getItem("companyId")
      );

      if (archivedUsers?.data?.company?.users?.items.length > 0) {
        let tempUserList = [...archivedUsers.data.company.users.items];

        let tempUserListFiltered = tempUserList.filter(
          (element, index, self) =>
            index === self.findIndex((e) => e.id === element.id)
        );

        tempUserListFiltered?.sort((a, b) =>
          a.firstName.localeCompare(b.firstName)
        );
        tempUserListFiltered?.forEach((x) => {
          x.firstName =
            x.firstName.charAt(0).toUpperCase() +
            x.firstName.slice(1).toLowerCase();
        });

        setArchivedUsers(tempUserListFiltered);
      }
    } catch (e) {
      console.error("getArchivedUsers()", e);
    }
  };

  useEffect(() => {
    if (ArchivedUsers) {
      // Sort and format user names
      let tempUserList = [...ArchivedUsers];

      // Set states
      let archivedUsersAlphabet = tempUserList
        ?.map((user) => user.firstName[0])
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();
      setArchivedUsersAlphabet(archivedUsersAlphabet); // Sort and format user names
    }
  }, [ArchivedUsers]);

  const getUserTypes = async () => {
    const params = {
      query: DEFAULT_USERTYPES_QUERY,
    };

    await API.graphql(params).then((userTypes) => {
      if (userTypes.data.defaultUserType) {
        // console.log("userTypes", userTypes.data.defaultUserType);
        userTypes.data.defaultUserType.map((userType) => {
          let oUserType = {
            value: userType,
            label: userType,
          };
          setUserTypes((prev) => [...prev, oUserType]);
        });
      }
    });
  };
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  //Added 3 seconds turning to light blue when adding an entry
  //Added fix for scrolling issue
  useEffect(
    (e) => {
      anime({
        targets: rows.current,
        opacity: [0.4, 1],
        duration: 1500,
        easing: "cubicBezier(.5, .05, .1, .3)",
      });

      refLetters.current = refLetters.current.slice(0, alphabetArray.length);
    },
    [ContactList]
  );

  useEffect(() => {
    if (setTimeout) {
      setTimeout(() => {
        setShowToast(false);
        // console.log("truee");
      }, 3000);
    }
  }, [showToast]);

  let getContacts = async () => {
    setloading(true);

    await Promise.all([getArchivedUsers(), getActiveContacts()]);

    setAreContactsAdded(false);
    setloading(false);
  };

  const getActiveContacts = async () => {
    try {
      const usersResponse = await ListUsers(localStorage.getItem("companyId"));
      if (usersResponse) {
        const users = usersResponse.data.company?.users.items || [];
        const tempUserList = [...users]; // Make a copy to avoid modifying the original array

        // Process company users

        let tempUserListFiltered = tempUserList.filter(
          (element, index, self) =>
            index === self.findIndex((e) => e.id === element.id)
        );

        const companyUsers = tempUserListFiltered.map((user) => {
          const name = `${user.firstName} ${user.lastName}`;
          return {
            value: name,
            label: name,
            id: user.id,
          };
        });

        // Sort and format user names
        tempUserListFiltered?.sort((a, b) =>
          a.firstName.localeCompare(b.firstName)
        );
        tempUserListFiltered?.forEach((x) => {
          x.firstName =
            x.firstName.charAt(0).toUpperCase() +
            x.firstName.slice(1).toLowerCase();
        });

        // Set states
        setCompanyUsers(companyUsers);

        const businessName = users.map((user) => user.businessName);
        setDefaultCompany(businessName);

        setContactList(tempUserListFiltered);
      }
    } catch (e) {
      console.error("getContacts()", e);
    }
  };

  useEffect(() => {
    if (ContactList) {
      let tempUserList = [...ContactList];

      let contactsAlphabet = tempUserList
        ?.map((user) => user.firstName[0])
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

      setContactsAlphabet(contactsAlphabet);
    }
  }, [ContactList]);

  const getOwnersLAs = (contacts) => {
    console.group(
      "Get all current Owners and LAs that has no Client/Matters tagged.",
      contacts
    );
    var owner_la = contacts.filter(
      (user) => user.userType === "OWNER" || user.userType === "LEGALADMIN"
    );

    // console.log("OWNERS & LAs: ", owner_la);

    var noTaggedClientMatters = owner_la.filter(
      (user) => user?.clientMatterAccess?.items?.length === 0
    );

    // console.log("No Current Client Matters", noTaggedClientMatters);
    tagAllClientMatters(noTaggedClientMatters);
    console.groupEnd();
  };

  const tagAllClientMatters = async (users) => {
    // console.log("tagAllClientMatters()");
    if (users) {
      return await Promise.all(
        users.map((u) => {
          const vars = {
            companyId: localStorage.getItem("companyId"),
            userId: u.id,
            userType: u.userType,
          };

          let params = {
            query: TAG_ALL_USER_CLIENTMATTER_MUTATION,
            variables: vars,
          };

          return new Promise((resolve, reject) => {
            API.graphql(params)
              .then((response) => resolve(response.data))
              .catch(reject);
          });
        })
      );
    }
  };

  let getTeamOptions = async () => {
    // let params = {
    //   query: qGetTeams,
    //   variables: {
    //     id: localStorage.getItem("companyId"),
    //   },
    // };

    // const teams = await API.graphql(params);
    const teams = await ListTeams(localStorage.getItem("companyId"));
    // console.log("getTeams", teams);

    const fin = teams.data.company.teams.items.map((team) => {
      return { value: team.name, label: team.name, id: team.id };
    });

    setTeamOptions(fin);
  };

  let getTeams = async () => {
    // console.log("Company ID", localStorage.getItem("companyId"));
    setTeamList([]);
    //clear first when called
    // let params = {
    //   query: qGetTeams,
    //   variables: {
    //     id: localStorage.getItem("companyId"),
    //   },
    // };

    // await API.graphql(params).then(async (teams) => {
    await ListTeams(localStorage.getItem("companyId")).then(async (teams) => {
      try {
        // console.log("teams", teams);
        if (teams.data.company == null) {
          setTeamList([]);
          // console.log("teamlist is null", teams);
        } else {
          // console.log("Successfully set team", teams.data.company.teams.items);
          //setTeamList(teams.data.company.teams.items);

          //get the actual team
          //let teamList = [];
          //teamList = [];

          //await addTeam(teams, teamList);

          teams.data.company.teams.items.map(async (team) => {
            // console.log("settingTeam ", team);
            setTeamList((prev) => [...prev, team]);
          });
        }
      } catch (e) {
        console.error("ERROR at getTeams()", e);
      }
    });
  };

  const handleEditModal = async (user) => {
    // Get data from GetUserCM
    const result = await GetUserCM(user.id, localStorage.getItem("companyId"));

    const { clientMatterAccess, teams } = result?.data?.user;

    // Process teams for the current user
    const filteredTeams = teams.items.map((item) => ({
      ...item,
      members: {
        items: item.members.items.filter(
          (member) => member.user.id === user.id
        ),
      },
    }));

    user.teams = { items: filteredTeams };

    if (clientMatterAccess?.items?.length) {
      user.clientMatterAccess = clientMatterAccess;
    } else if (["OWNER", "LEGALADMIN"].includes(user.userType)) {
      await tagAllClientMatters([user]);
      // After tagging all client matters, there is no need to call handleEditModal again.
    }

    // const actualHasPortalAccess = getAccessStatus(
    //   user?.hasPortalAccess,
    //   user?.cognito?.userStatus
    // );
    // user.hasPortalAccess =
    //   actualHasPortalAccess ===
    //   (("Has Access" && user?.hasPortalAccess) ||
    //     ("Pending Invite" && user?.hasPortalAccess))
    //     ? true
    //     : false;

    setCurrentUser(user);
    setShowEditModal(true);
  };

  const handleDeleteModal = (id, email) => {
    setisToDelete(id);
    setUserEmail(email);
    setUserCompanyId(localStorage.getItem("companyId"));
    setShowDeleteModal(true);
  };

  let history = useHistory();

  const [ascDesc, setAscDesc] = useState(null);
  const [isSortByFirstName, setIsSortByFirstName] = useState(false);
  const [isSortByUserType, setIsSortByUserType] = useState(false);
  const [isSortByCompany, setIsSortByCompany] = useState(false);
  const [alphabetArrayState, setAlphabetArrayState] = useState(alphabetArray);

  const handleSortByName = () => {
    let sortedList = [...ContactList];

    if (ascDesc === null) {
      // descending
      sortedList = sortedList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
      contactsAlphabet.sort().reverse();
      setAlphabetArrayState(alphabetArrayState.reverse());
      setIsSortByUserType(true);
      setIsSortByCompany(true);
      setAscDesc(true);
      // console.log(alphabetArrayState);
    } else if (ascDesc === true) {
      sortedList = sortedList.sort((a, b) =>
        b.firstName.localeCompare(a.firstName)
      );
      contactsAlphabet.sort();
      alphabetArray.sort();
      setAscDesc(null);
      // hide 2 buttons
      setIsSortByUserType(false);
      setIsSortByCompany(false);
      // console.log(alphabetArrayState);
    }
    // } else if (!ascDesc) {
    //   setAscDesc(null);
    //   sortedList = sortedList.sort((a, b) =>
    //     a.firstName.localeCompare(b.firstName)
    //   );
    //   contactsAlphabet.sort();
    //   alphabetArray.sort();
    //   //Appear 2 buttons
    //   setIsSortByUserType(false);
    //   setIsSortByCompany(false);
    // }

    setContactList(sortedList);
    setSortBy("firstName");
    setIsSortByFirstName(false);
  };

  const handleSortByUserType = () => {
    let sortedList = [...ContactList];

    if (ascDesc === null) {
      sortedList = sortedList.sort((a, b) =>
        a.userType.localeCompare(b.userType)
      );

      setAscDesc(true);
      // hide 2 buttons
      setIsSortByCompany(true);
      setIsSortByFirstName(true);
      setSortBy("userType");
    } else if (ascDesc === true) {
      // descending
      sortedList = sortedList.sort((a, b) =>
        b.userType.localeCompare(a.userType)
      );
      setAscDesc(false);

      // hide 2 buttons
      setIsSortByFirstName(true);
      setIsSortByCompany(true);
      setSortBy("userType");
    } else if (!ascDesc) {
      setAscDesc(null);
      sortedList = sortedList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
      contactsAlphabet.sort();
      alphabetArray.sort();
      setIsSortByFirstName(false);
      setIsSortByCompany(false);
      handleSortByName();
    }

    setContactList(sortedList);
    setIsSortByUserType(false);
  };

  const handleSortByCompanyName = () => {
    let sortedList = [...ContactList];
    if (ascDesc === null) {
      // ascending
      sortedList = sortedList.sort((a, b) => {
        if (!a.businessName && !b.businessName) return 0;
        if (!a.businessName) return 1;
        if (!b.businessName) return -1;
        return a.businessName.localeCompare(b.businessName);
      });
      setAscDesc(true);
      // hide 2 buttons
      setIsSortByUserType(true);
      setIsSortByFirstName(true);
      setSortBy("company");
    } else if (ascDesc === true) {
      // descending

      sortedList = sortedList.sort((a, b) => {
        if (!a.businessName && !b.businessName) return 0;
        if (!a.businessName) return 1;
        if (!b.businessName) return -1;
        return b.businessName.localeCompare(a.businessName);
      });
      setAscDesc(false);
      // hide 2 buttons
      setIsSortByUserType(true);
      setIsSortByFirstName(true);
      setSortBy("company");
    } else if (!ascDesc) {
      setAscDesc(null);
      sortedList = sortedList.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
      contactsAlphabet.sort();
      alphabetArray.sort();
      setIsSortByUserType(false);
      setIsSortByFirstName(false);
      handleSortByName();
    }

    setContactList(sortedList);
    setIsSortByCompany(false);
  };

  const scrollToView = (target) => {
    let virtualItems = rowsModel.map((row) => ({
      ...row.original,
      index: row.index,
    }));

    let matchedLetter = virtualItems.find(
      (item) => item.firstName?.charAt(0)?.toUpperCase() === target
    );

    if (!matchedLetter) return;

    let matchedVirtualRow = rowVirtualizer.measurementsCache.find(
      (row) => row.index === matchedLetter.index
    );

    tableContainerRef.current.scroll({
      left: 0,
      top: matchedVirtualRow.start + 100,
      behavior: "smooth",
    }); //added fixed scrolling
  };

  useEffect(() => {
    // console.log("Calling get contacts");
    //tagTeamMember("008b94a4-8300-421e-9a5b-a5ed2424262f", []);

    if (ContactList == null && ActiveMenu === "Contacts") {
      getContacts();
    }

    getTeams();
    getUserTypes();
    getTeamOptions();
    // getArchivedUsers();

    window.addEventListener(
      "scroll",
      () => {
        //Fixed Issue Scrolling ang setting active letter
        const currentScrollPos = window.pageYOffset;

        refLetters.current.map((ref, i) => {
          if (ref === null) {
            refLetters.current.splice(refLetters.current.indexOf(ref), 1);
          }
        });

        refLetters.current.forEach((ref, i) => {
          if (ref !== null && ref !== undefined) {
            // let top = ref.offsetTop + 100;
            // let bottom =
            //   refLetters.current.length - 1 === i
            //     ? refLetters.current[0].offsetTop + 100
            //     : refLetters.current[i + 1].offsetTop + 100;
            // if (currentScrollPos >= top && currentScrollPos <= bottom) {
            //   setShortcutSelected(String(ref.id));
            // }
          } else {
          }
        });
      },
      { passive: true }
    );
  }, []);

  const handleInitials = (firstName, lastName) => {
    const fullName = `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`;
    let assigneeInitials = "";

    if (fullName === "john dela cruz") {
      assigneeInitials = "JDC";
    } else {
      assigneeInitials += firstName ? firstName.charAt(0) : "";
      assigneeInitials += lastName ? lastName.charAt(0) : "";
    }

    return assigneeInitials.toUpperCase();
  };

  function getMobileContentHeight() {
    return height - 165;
  }

  const allowPortalAccess = async (contact) => {
    try {
      if (contact) {
        const { data } = await API.graphql({
          query: UPDATE_COMPANY_USER_MUTATION,
          variables: {
            userId: contact.id,
            companyId: contact.company.id,
            hasPortalAccess: true,
          },
        });

        console.log(contact);
        const { userStatus, userAttributes } = contact.cognito;

        console.log("userStatus", userStatus);
        console.log("userAttributes", userAttributes);

        if (data.userCompanyUpdate) {
          if (userStatus === "FORCE_CHANGE_PASSWORD") {
            // has pending invitation, resend invite
            console.log("user has pending invitation");
            console.log("resend invite, then add this user to company");
            await resendPortalAccess(contact);
          } else if (userStatus === "CONFIRMED") {
            console.log("user already exist, add this user to company");
            console.log("Send invite to Company Email");
            await userCompanyInvite(contact.email);
          }

          // await createCompanyUser(x);
        }
      }
    } catch (e) {
      console.error("allowPortalAccess()", e);
    }
  };

  async function userCompanyInvite(email) {
    console.log("userCompanyInvite");

    var firstName = localStorage.getItem("firstName"),
      lastName = localStorage.getItem("lastName"),
      companyName = localStorage.getItem("company");

    const sendInvite = await API.graphql({
      query: INVITE_EXISTING_USER_MUTATION,
      variables: {
        email: email,
        company: companyName,
        invitee: `${firstName} ${lastName}`,
      },
    });

    console.log("sendInvite --> ", sendInvite);
    if (sendInvite) {
      setalertMessage("Invitation has been sent.");
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        getContacts();
      }, 5000);
    }
  }

  const removePortalAccess = async (contact) => {
    try {
      if (contact) {
        const { data } = await API.graphql({
          query: REMOVE_PORTAL_ACCESS_MUTATION,
          variables: {
            id: contact.id,
            companyId: contact.company.id,
          },
        });

        if (data.userRemovePortalAccess) {
          setalertMessage("Successfully Removed Access");
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
            getContacts();
          }, 5000);
        }
      }
    } catch (e) {
      console.error("removePortalAccess()", e);
    }
  };

  const resendPortalAccess = async (contact) => {
    try {
      if (contact) {
        const resendInvite = await API.graphql({
          query: RESEND_INVITE_MUTATION,
          variables: {
            email: contact.email,
          },
        });

        if (resendInvite) {
          setalertMessage("Updated Invitation has been sent.");
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
            getContacts();
          }, 5000);
        }
      }
    } catch (e) {
      console.error("resendPortalAccess()", e);
    }
  };

  useEffect(() => {
    pageOverflowControl(ShowEditModal);
  }, [ShowEditModal]);

  const pageOverflowControl = (isEditModalOpen) => {
    if (isEditModalOpen) {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    }
  };

  /*
   * Used useRef instead of useState to make a deep copy of CurrentUser
   * because when using the latter, the copied object is being changed
   * along with the new copy. Since I've implemented a mutating solution
   * for maintaining data across tabs, it would cost me a lot more to re-
   * implement a solution I'm not even sure would even work. Apologies in
   * advance. (MMA-1942)
   */
  let proxyUser = useRef(_.cloneDeep(CurrentUser));

  const getAccessStatusDisplay = (hasPortalAccess, cognitoStatus) => {
    const status = getAccessStatus(hasPortalAccess, cognitoStatus);

    return (
      <p
        className={
          status === "No Access"
            ? "text-gray-400"
            : status === "Pending Invite"
              ? "text-orange-500"
              : ""
        }
      >
        {status}
      </p>
    );
  };

  const getAccessStatus = (hasPortalAccess, cognitoStatus) => {
    let status = "";

    // if (hasPortalAccess !== false) {
    //   if (cognitoStatus === "CONFIRMED") {
    //     status = "Has Access";
    //   } else if (cognitoStatus === "FORCE_CHANGE_PASSWORD") {
    //     status = "Pending Invite";
    //   } else {
    //     status = "No Access";
    //   }
    // } else {
    //   if (cognitoStatus === "FORCE_CHANGE_PASSWORD") {
    //     status = "Pending Invite";
    //   } else {
    //     status = "No Access";
    //   }
    // }

    if (hasPortalAccess !== false && cognitoStatus === "CONFIRMED") {
      status = "Has Access";
    } else if (
      hasPortalAccess === true &&
      cognitoStatus === "FORCE_CHANGE_PASSWORD"
    ) {
      status = "Pending Invite";
    } else {
      status = "No Access";
    }

    return status;
  };

  /**
   * Checks if the user is part of the team
   * @param {JSX.Element} children - The element to be rendered if the user is part of the team
   * @param {Object} t - The team object
   * @returns {JSX.Element} - The element to be rendered if the user is part of the team
   */
  const TeamOptionsCheck = ({ children, t }) => {
    return TeamOptions.some((option) => option?.label === t?.name)
      ? children
      : null;
  };

  const handleActiveMenu = (menu) => {
    if (menu === "Contacts" && ActiveMenu !== "Contacts") {
      setActiveMenu("Contacts");
      setAscDesc(null);
      alphabetArray.sort((a, b) => a.localeCompare(b));
      setContactsAlphabet((prev) => prev.sort((a, b) => a.localeCompare(b)));
    } else if (menu === "Teams" && ActiveMenu !== "Teams") {
      setActiveMenu("Teams");
      setAscDesc(null);
      alphabetArray.sort((a, b) => a.localeCompare(b));
      setTeamsAlphabet((prev) => prev.sort((a, b) => a.localeCompare(b)));
    } else if (menu === "Matters" && ActiveMenu !== "Matters") {
      setActiveMenu("Matters");
      setAscDesc(null);
      alphabetArray.sort((a, b) => a.localeCompare(b));
      setMattersAlphabet((prev) => prev.sort((a, b) => a.localeCompare(b)));
    } else if (
      menu === "Archived Contacts" &&
      ActiveMenu !== "Archived Contacts"
    ) {
      setActiveMenu("Archived Contacts");
      setAscDesc(null);
      alphabetArray.sort((a, b) => a.localeCompare(b));
      setArchivedUsersAlphabet((prev) =>
        prev.sort((a, b) => a.localeCompare(b))
      );
    }
  };

  function handleCloseModal(e) {
    setShowEditModal(false);
    console.log("handleCloseModal");
    // removed this because it was causing the page to refresh
    // getContacts();
  }

  // TANSTACK VIRTUALIZED
  const tableContainerRef = useRef(null);
  const tableBodyRef = useRef(null);

  const table = useReactTable({
    data: ContactList ?? [],
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows: rowsModel } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    parentRef: tableContainerRef,
    // Added condition for loading
    //getItemKey: (index) => (data.length !== 0 ? data[index]?.id : index),
    count: rowsModel.length,
    overscan: width < 844 ? 5 : 10,
    // estimateSize: useCallback(() => 310 , []), // THIS IS AN ESTIMATE FOR WEB, ADD CONDITION FOR MOBILE
    estimateSize: useCallback(() => (width > 844 ? 81 : 310), []), // THIS IS AN ESTIMATE FOR WEB, ADD CONDITION FOR MOBILE
    // paddingStart: tableBodyRef?.current?.getBoundingClientRect()?.top || 0,
    //getTotalSize: () => height,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  return (
    <>
      {areContactsAdded && <Spinner />}
      <main
        style={{
          ...(!isMobileDevice(width) ? { paddingLeft: "90px" } : {}),
        }}
        className={
          "w-full p-2 relative " +
          (isMobileDevice(width)
            ? "bg-gray-100 pt-5"
            : "h-full bg-white pt-0 overflow-y-auto")
        }
        ref={!isMobileDevice(width) ? tableContainerRef : null}
      >
        {/* header */}
        <div
          className={
            "top-0 flex items-center gap-2 z-10 " +
            (isMobileDevice(width)
              ? "static transparent p-5 pt-3"
              : "sticky bg-white py-2 pt-4")
          }
        >
          <div
            onClick={() => history.replace(dashboardPath)}
            className="w-8 py-5 cursor-pointer"
            style={{
              display: isMobileDevice(width) ? "none" : "block",
            }}
          >
            <CgChevronLeft />
          </div>
          <div className="w-full">
            <p className={isMobileDevice(width) ? " w-full text-right" : ""}>
              <span className="text-lg font-bold">Contacts Dashboard</span>{" "}
              <span
                className="text-lg font-light"
                style={{
                  display: isMobileDevice(width) ? "none" : "inline",
                }}
              >
                {" "}
                of {localStorage.getItem("firstName")}{" "}
                {localStorage.getItem("lastName")}
              </span>
            </p>
            <div
              className="items-center gap-3 text-gray-500"
              style={{
                display: isMobileDevice(width) ? "none" : "flex",
              }}
            >
              <FaUsers />
              <p className="font-semibold">Contacts</p>
            </div>
          </div>
        </div>

        {/* Desk top tabs and action buttons */}
        {!isMobileDevice(width) && (
          <div>
            <div className="flex justify-between items-center border-b-2 border-gray-200 ">
              {/* tabs */}
              <div className="flex items-center gap-x-8 w-max ml-2">
                <p
                  onClick={() => {
                    handleActiveMenu("Contacts");
                  }}
                  className={`py-5 border-b-2 flex items-center gap-x-3 border-transparent cursor-pointer font-medium ${
                    ActiveMenu === "Contacts" && "border-gray-700 "
                  }`}
                >
                  Contacts{" "}
                  <span className="text-sm rounded-full flex items-center justify-center font-semibold">
                    {ContactList && ContactList.length}
                  </span>
                </p>
                <p
                  onClick={() => {
                    handleActiveMenu("Teams");
                  }} //handleActiveMenu to Teams
                  className={`py-5 border-b-2 flex items-center gap-x-3 border-transparent cursor-pointer font-medium ${
                    ActiveMenu === "Teams" && "border-gray-700"
                  }`}
                >
                  Teams{" "}
                  <span
                    className={`text-sm rounded-full flex items-center justify-center font-semibold `}
                  >
                    {TeamList && TeamList.length}
                  </span>
                </p>
                <p
                  onClick={() => {
                    handleActiveMenu("Matters");
                  }} //handleActiveMenu to Teams
                  className={`py-5 border-b-2 flex items-center gap-x-3 border-transparent cursor-pointer font-medium ${
                    ActiveMenu === "Matters" && "border-gray-700"
                  }`}
                >
                  Matters{" "}
                  <span
                    className={`text-sm rounded-full flex items-center justify-center font-semibold `}
                  >
                    {matters.length}
                  </span>
                </p>

                <p
                  onClick={() => {
                    handleActiveMenu("Archived Contacts");
                  }}
                  className={`py-5 border-b-2 flex items-center gap-x-3 border-transparent cursor-pointer font-medium ${
                    ActiveMenu === "Archived Contacts" && "border-gray-700 "
                  }`}
                >
                  Archived Contacts{" "}
                  <span className="text-sm rounded-full flex items-center justify-center font-semibold">
                    {ArchivedUsers && ArchivedUsers.length}
                  </span>
                </p>
              </div>
              {/* action buttons */}
              {/* Hide the action button for Archived Contacts Tab
                  since there's currently no need for it as of now (MMA-2374). */}
              {ActiveMenu !== "Archived Contacts" && (
                <div className="flex items-center gap-x-2">
                  <Button
                    onClick={() => {
                      ActiveMenu === "Contacts"
                        ? setshowAddContactModal(true)
                        : ActiveMenu === "Teams"
                          ? setShowAddTeamModal(true)
                          : setShowAddMatterModal(true);
                    }}
                    variant="secondary-l"
                    size="medium"
                  >
                    {` ${
                      ActiveMenu === "Contacts"
                        ? "Add Contact"
                        : ActiveMenu === "Teams"
                          ? "Add Team"
                          : "Add Matter"
                    } `}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {/* main content */}
        <div
          className={
            isMobileDevice(width)
              ? "p-2 pt-0 bg-white rounded-lg"
              : "relative h-max w-full flex gap-x-2 py-5"
          }
        >
          {/* alphabet array */}
          <div
            className="px-3 py-2 "
            style={{
              display: isMobileDevice(width) ? "none" : "block",
            }}
          >
            <div className="sticky top-20 flex flex-col gap-y-1 pt-5">
              {alphabetArray.map((letter) => {
                // check if letter is in dummy array

                /*
                const isLetter =
                  ContactList &&
                  ContactList.some((user) => user.firstName.startsWith(letter));
                */

                return (
                  <p
                    key={"b" + letter}
                    onClick={(e) => {
                      //To prevent double setting shortcut selecting only set if user is in bottom of screen
                      if (
                        window.innerHeight + window.scrollY >=
                        document.body.offsetHeight
                      ) {
                        setShortcutSelected(letter);
                      }
                      scrollToView(letter);
                    }}
                    style={{
                      transform: `translateX(${
                        letter === shortcutSelected ? "10px" : "0px"
                      })`,
                    }}
                    className={`text-center text-gray-400 cursor-pointer transition-all font-bold  hover:scale-110 hover:text-blue-600 ${
                      shortcutSelected === letter && "text-cyan-500"
                    }`}
                  >
                    {letter}
                  </p>
                );
              })}
            </div>
          </div>

          {/* MOBILE TABS */}
          {isMobileDevice(width) && (
            <div className="flex justify-between px-1 items-center border-b-2 border-gray-200 ">
              <div className="flex items-center gap-x-5 w-max">
                <p
                  onClick={() => {
                    handleActiveMenu("Contacts");
                  }}
                  className={`py-5 border-b-2 flex items-center gap-x-2 border-transparent cursor-pointer font-medium ${
                    ActiveMenu === "Contacts" && "border-gray-700 "
                  }`}
                >
                  Contacts{" "}
                  {ContactList && (
                    <span className="text-xs rounded-full flex px-1 bg-gray-50 border-2 items-center justify-center">
                      {ContactList.length}
                    </span>
                  )}
                </p>
                <p
                  onClick={() => {
                    handleActiveMenu("Teams");
                  }} //handleActiveMenu to Teams
                  className={`py-5 border-b-2 flex items-center gap-x-2 border-transparent cursor-pointer font-medium ${
                    ActiveMenu === "Teams" && "border-gray-700"
                  }`}
                >
                  Teams{" "}
                  {TeamList && (
                    <span className="rounded-full text-xs flex px-1 bg-gray-50 border-2 items-center justify-center">
                      {TeamList.length}
                    </span>
                  )}
                </p>
                <p
                  onClick={() => {
                    handleActiveMenu("Matters");
                  }} //handleActiveMenu to Teams
                  className={`py-5 border-b-2 flex items-center gap-x-2 border-transparent cursor-pointer font-medium ${
                    ActiveMenu === "Matters" && "border-gray-700"
                  }`}
                >
                  Matters{" "}
                  {TeamList && (
                    <span className="rounded-full text-xs flex px-1 bg-gray-50 border-2 items-center justify-center">
                      {matters.length}
                    </span>
                  )}
                </p>
              </div>
              {/* action buttons */}
              <div className="flex items-center gap-x-5">
                {/*  <button
                onClick={() => {
                  ActiveMenu === "Contacts"
                    ? setshowAddContactModal(true)
                    : setShowAddTeamModal(true);
                }}
                className="py-2 px-4 bg-green-400 rounded w-max font-semibold text-white"
              > */}
                {/* {` ${ActiveMenu === "Contacts" ? "Add Contact" : "Add Team"} `} */}
                {/* </button> */}
                {/* Updated Button */}
                <Button
                  onClick={() => {
                    ActiveMenu === "Contacts"
                      ? setshowAddContactModal(true)
                      : ActiveMenu === "Teams"
                        ? setShowAddTeamModal(true)
                        : setShowAddMatterModal(true);
                  }}
                  variant="secondary-l"
                  size="medium"
                >
                  +
                </Button>
              </div>
            </div>
          )}
          {/* table */}
          {ActiveMenu === "Contacts" ? (
            isMobileDevice(width) ? (
              <>
                {/* MOBILE TABLE */}
                <div>
                  <div
                    className="mt-1 overflow-y-auto relative"
                    ref={tableContainerRef}
                    style={{
                      height: getMobileContentHeight(),
                    }}
                  >
                    {loading ? (
                      [...Array(5).keys()].map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div>
                              <div className="flex flex-row justify-center items-center">
                                <SkeletonTheme width={"30px"} height={"30px"}>
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                                <div
                                  className="w-full border-b-2 border-dashed"
                                  style={{
                                    height: "1px",
                                  }}
                                ></div>
                              </div>
                              <div className="flex flex-col gap-2">
                                <div className="ml-5 rounded-lg px-2 py-2 gap-3 hover:shadow-md active:shadow-md transition-shadow">
                                  <SkeletonTheme width={"100%"} height={"70px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                  <SkeletonTheme width={"100%"} height={"70px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                  <SkeletonTheme width={"100%"} height={"70px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <div style={{ height: totalSize }}>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            transform: `translateY(${
                              virtualRows[0]?.start || 0
                            }px)`,
                          }}
                        ></div>
                        {/* {ContactList && ContactList.map((contact, index) => { */}
                        {virtualRows.map((virtualRow) => {
                          const row = rowsModel[virtualRow.index];
                          const contact = row.original;
                          const index = row.index;
                          let letter = ContactList[index].firstName
                            .charAt(0)
                            .toUpperCase();
                          let prevLetter = ContactList[index - 1]?.firstName
                            ?.charAt(0)
                            ?.toUpperCase();

                          let idx = contactsAlphabet.indexOf(letter);

                          return (
                            <div
                              key={virtualRow.key}
                              className="absolute top-0 left-0 w-full pb-2"
                              ref={(e) => virtualRow.measureElement(e)}
                              data-index={virtualRow.index}
                              style={{
                                // height: `${virtualRow.size}px`,
                                transform: `translateY(${virtualRow.start}px)`,
                              }}
                            >
                              {letter !== prevLetter && (
                                <div className="flex flex-row justify-center items-center">
                                  <p className="pr-2 font-medium">{letter}</p>
                                  <div
                                    className="w-full border-b-2 border-dashed"
                                    style={{
                                      height: "1px",
                                    }}
                                  ></div>
                                </div>
                              )}
                              <div
                                onClick={() => handleEditModal(contact)}
                                key={letter + "" + index}
                                className="ml-5 bg-gray-100 rounded-lg px-2 py-2 gap-3 hover:shadow-md active:shadow-md transition-shadow"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns:
                                    "32px minmax(0, 1fr) 18px",
                                }}
                              >
                                <div
                                  className="flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center"
                                  style={{ flexShrink: "0" }}
                                >
                                  {contact.profilePicture === null ? (
                                    <>
                                      {handleInitials(
                                        contact.firstName,
                                        contact.lastName
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="rounded-full w-8 h-8"
                                      src={contact.profilePicture}
                                      alt={contact.name}
                                    />
                                  )}
                                </div>
                                <div>
                                  <p className="font-medium break-words">
                                    {contact.firstName} {contact.lastName}{" "}
                                  </p>
                                  <p className="text-cyan-400 font-medium break-words">
                                    {contact.userType}
                                  </p>
                                  <p className="text-xs break-words">
                                    {contact.email}
                                  </p>
                                  {contact.teams?.items.map((t) => (
                                    <div
                                      key={t.id}
                                      className="flex items-center py-1 break-words"
                                    >
                                      <TeamOptionsCheck t={t}>
                                        <p className="font-semibold text-xs rounded-full bg-gray-200 px-2 py-1 break-words whitespace-normal">
                                          {t.name}
                                        </p>
                                      </TeamOptionsCheck>
                                    </div>
                                  ))}{" "}
                                </div>
                                <div className="grow flex items-start justify-end">
                                  <button
                                    className={
                                      contact.id ===
                                      localStorage.getItem("userId")
                                        ? "hidden"
                                        : " text-red-400 w-max font-semibold rounded-full hover:bg-gray-200"
                                    }
                                  >
                                    <CgTrash
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteModal(
                                          contact.id,
                                          contact.email,
                                          contact.company
                                        );
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full py-2">
                {/* DESKTOP VIEW */}
                <div
                  className={
                    loading
                      ? "w-full text-left border-separate border-spacing-y-3"
                      : "w-full text-left "
                  }
                >
                  {/* headers */}
                  <div className="sticky bg-white z-10" style={{ top: "74px" }}>
                    <div className="w-full grid grid-cols-11">
                      <div className="p-2 col-span-2">
                        <div className="flex items-center gap-x-1">
                          Name
                          {/* {ascDesc === ? (
                            <CgSortAz
                              onClick={handleSortByName}
                              className={`${
                                sortByName ? "hidden" : ""
                              } text-xl cursor-pointer hover:text-gray-500`}
                            />
                          ) : (
                            <CgSortZa
                              onClick={handleSortByName}
                              className={`${
                                sortByName ? "hidden" : ""
                              } text-xl cursor-pointer hover:text-gray-500`}
                            />
                          )} */}
                          {ascDesc === null ? (
                            <CgSortZa
                              onClick={handleSortByName}
                              className={`${
                                isSortByFirstName ? "hidden" : ""
                              } text-xl cursor-pointer hover:text-gray-500`}
                            />
                          ) : ascDesc === true ? (
                            <CgSortAz
                              onClick={handleSortByName}
                              className={`${
                                isSortByFirstName ? "hidden" : ""
                              } text-xl cursor-pointer hover:text-gray-500`}
                            />
                          ) : (
                            <CgSortZa
                              onClick={handleSortByName}
                              className={`${
                                isSortByFirstName ? "hidden" : ""
                              } text-xl cursor-pointer hover:text-gray-500`}
                            />
                          )}
                        </div>
                      </div>
                      <div className="p-2 col-span-3">Email</div>
                      <div className="p-2">Team</div>
                      <div className="p-2 flex items-center gap-x-1">
                        User Type
                        {/* {IsSortedReverse ? (
                          <CgSortAz
                            onClick={handleSortByUserType}
                            className={`${
                              sortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : (
                          <CgSortZa
                            onClick={handleSortByUserType}
                            className={`${
                              sortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        )} */}
                        {ascDesc === null ? (
                          <FaSort
                            onClick={handleSortByUserType}
                            className={`${
                              isSortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : ascDesc === true ? (
                          <CgSortZa
                            onClick={handleSortByUserType}
                            className={`${
                              isSortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : (
                          <CgSortAz
                            onClick={handleSortByUserType}
                            className={`${
                              isSortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        )}
                      </div>
                      <div className="p-2 flex items-center gap-x-1">
                        Company{" "}
                        {/* {IsSortedReverse ? (
                          <CgSortAz
                            onClick={handleSortByCompanyName}
                            className={`${
                              sortByCompanyName ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : (
                          <CgSortZa
                            onClick={handleSortByCompanyName}
                            className={`${
                              sortByUserType ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        )} */}
                        {ascDesc === null ? (
                          <FaSort
                            onClick={handleSortByCompanyName}
                            className={`${
                              isSortByCompany ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : ascDesc === true ? (
                          <CgSortZa
                            onClick={handleSortByCompanyName}
                            className={`${
                              isSortByCompany ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        ) : (
                          <CgSortAz
                            onClick={handleSortByCompanyName}
                            className={`${
                              isSortByCompany ? "hidden" : ""
                            } text-xl cursor-pointer hover:text-gray-500`}
                          />
                        )}
                      </div>
                      <div className="p-2">Portal Access</div>
                      <div className="p-2 col-span-2" />
                    </div>
                  </div>
                  {/* content Changes here*/}
                  <div
                    className="relative"
                    ref={tableBodyRef}
                    style={{
                      height: totalSize,
                      width: "100%",
                    }}
                  >
                    {loading ? (
                      [...Array(5).keys()].map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="p-2 pt-5">
                              <SkeletonTheme width={"24px"} height={"24px"}>
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            </div>
                            <div className="pb-5 grid-cols-11 grid w-full gap-x-2">
                              <div className="pl-2 -pr-2 col-span-2">
                                <div className="flex items-center gap-x-2 mb-1">
                                  <div className="rounded-full">
                                    <SkeletonTheme
                                      width={"2rem"}
                                      height={"2rem"}
                                    >
                                      <Skeleton
                                        className="rounded-full"
                                        count={1}
                                      />
                                    </SkeletonTheme>
                                  </div>
                                  <div className="w-full mr-3">
                                    <SkeletonTheme height={"30px"}>
                                      <Skeleton count={1} />
                                    </SkeletonTheme>
                                  </div>
                                </div>
                              </div>
                              <div className="pl-1 col-span-3">
                                <div className="mr-3">
                                  <SkeletonTheme height={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton className="rounded-2xl" count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton className="rounded-2xl" count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              </div>

                              <div className="col-span-2 flex items-center justify-end gap-x-2 mr-3">
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"50px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                              </div>
                            </div>
                            <div className="pb-5 grid-cols-11 grid w-full gap-x-2">
                              <div className="pl-2 -pr-2 col-span-2">
                                <div className="flex items-center gap-x-2 mb-1">
                                  <div className="rounded-full">
                                    <SkeletonTheme
                                      width={"2rem"}
                                      height={"2rem"}
                                    >
                                      <Skeleton
                                        className="rounded-full"
                                        count={1}
                                      />
                                    </SkeletonTheme>
                                  </div>
                                  <div className="w-full mr-3">
                                    <SkeletonTheme height={"30px"}>
                                      <Skeleton count={1} />
                                    </SkeletonTheme>
                                  </div>
                                </div>
                              </div>
                              <div className="pl-1 col-span-3">
                                <div className="mr-3">
                                  <SkeletonTheme height={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton className="rounded-2xl" count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton className="rounded-2xl" count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              </div>

                              <div className="col-span-2 flex items-center justify-end gap-x-2 mr-3">
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"50px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                              </div>
                            </div>
                            <div className="grid-cols-11 grid w-full gap-x-2">
                              <div className="pl-2 -pr-2 col-span-2">
                                <div className="flex items-center gap-x-2 mb-1">
                                  <div className="rounded-full">
                                    <SkeletonTheme
                                      width={"2rem"}
                                      height={"2rem"}
                                    >
                                      <Skeleton
                                        className="rounded-full"
                                        count={1}
                                      />
                                    </SkeletonTheme>
                                  </div>
                                  <div className="w-full mr-3">
                                    <SkeletonTheme height={"30px"}>
                                      <Skeleton count={1} />
                                    </SkeletonTheme>
                                  </div>
                                </div>
                              </div>
                              <div className="pl-1 col-span-3">
                                <div className="mr-3">
                                  <SkeletonTheme height={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton className="rounded-2xl" count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton className="rounded-2xl" count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              </div>
                              <div className="pl-1">
                                <SkeletonTheme height={"30px"}>
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              </div>

                              <div className="col-span-2 flex items-center justify-end gap-x-2 mr-3">
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"50px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                                <div className="h-full">
                                  <SkeletonTheme height={"30px"} width={"30px"}>
                                    <Skeleton count={1} />
                                  </SkeletonTheme>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })
                    ) : sortBy === "firstName" ? (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            transform: `translateY(${
                              virtualRows[0]?.start || 0
                            }px)`,
                          }}
                        ></div>
                        {/* {ContactList && ContactList.map((contact, index) => { */}
                        {virtualRows.map((virtualRow) => {
                          const row = rowsModel[virtualRow.index];
                          const contact = row.original;
                          const index = row.index;
                          let letter = ContactList[index].firstName
                            .charAt(0)
                            .toUpperCase();
                          let prevLetter = ContactList[index - 1]?.firstName
                            ?.charAt(0)
                            ?.toUpperCase();

                          let idx = contactsAlphabet.indexOf(letter);

                          return (
                            <div
                              key={virtualRow.key}
                              className="absolute top-0 left-0 w-full"
                              ref={(e) => virtualRow.measureElement(e)}
                              data-index={virtualRow.index}
                              style={{
                                // height: `${virtualRow.size}px`,
                                transform: `translateY(${virtualRow.start}px)`,
                              }}
                            >
                              {letter !== prevLetter && (
                                <div
                                  ref={(ref) => {
                                    refLetters.current[idx] = ref;
                                  }}
                                  id={letter}
                                  key={letter}
                                  className="pt-4 px-2 flex items-center gap-x-2"
                                >
                                  <p
                                    className={`${
                                      shortcutSelected == letter
                                        ? "text-cyan-500 font-bold"
                                        : "text-gray-700 font-semibold"
                                    }  text-lg `}
                                  >
                                    {letter}
                                  </p>
                                </div>
                              )}
                              <div
                                ref={(ref) => {
                                  if (contact.isNewlyAdded) {
                                    rows.current.push(ref);
                                  }
                                }}
                                key={contact.id}
                                className={
                                  "grid grid-cols-11 items-center " +
                                  (contact.isNewlyAdded
                                    ? "opacity-100 bg-cyan-100"
                                    : "opacity-100 " +
                                      (virtualRow.index % 2 === 0
                                        ? "bg-neutral-50"
                                        : ""))
                                }
                              >
                                <div className="p-2 col-span-2 flex items-center gap-x-2 ">
                                  <div className="inline-flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center">
                                    {contact.profilePicture === null ? (
                                      <>
                                        {handleInitials(
                                          contact.firstName,
                                          contact.lastName
                                        )}
                                      </>
                                    ) : (
                                      <img
                                        className="rounded-full w-8 h-8"
                                        src={contact.profilePicture}
                                      />
                                    )}
                                  </div>
                                  <p className="font-semibold max-w-40 min-w-min">
                                    {contact.firstName} {contact.lastName}
                                  </p>
                                </div>
                                <div className="p-2 col-span-3 break-words">
                                  {contact.email}
                                </div>
                                <div className="p-2">
                                  {contact.teams?.items.map((t) => (
                                    <div
                                      key={t.id}
                                      className="flex items-center py-1"
                                    >
                                      <TeamOptionsCheck t={t}>
                                        <p className="text-center font-semibold text-xs rounded-3xl bg-gray-200 px-1 py-1">
                                          {t.name}
                                        </p>
                                      </TeamOptionsCheck>
                                    </div>
                                  ))}{" "}
                                </div>
                                <div className="p-2 flex items-center gap-x-2">
                                  <p className="text-center font-semibold text-xs rounded-3xl bg-blue-100 px-2 py-1">
                                    {humaReadableFormat(
                                      contact.userType
                                        .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
                                        .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
                                    )}
                                  </p>
                                </div>
                                <div className="p-2">
                                  {contact.businessName
                                    ? contact.businessName
                                    : "N/A"}
                                </div>
                                <div className="p-2">
                                  {getAccessStatusDisplay(
                                    contact.hasPortalAccess,
                                    contact.cognito?.userStatus
                                  )}
                                </div>

                                <div className="p-2 col-span-2 flex items-center justify-end gap-x-3">
                                  {getAccessStatus(
                                    contact.hasPortalAccess,
                                    contact.cognito?.userStatus
                                  ) === "No Access" ? (
                                    <button
                                      onClick={() => {
                                        setshowPortalAccessConfirmationModal({
                                          toggle: true,
                                          prompt: "allow",
                                          data: contact,
                                        });
                                      }}
                                      className="h-10 font-semibold justify-center items-center text-white bg-green-500 rounded-md text-xs cursor-pointer border border-green-500 hover:bg-white hover:text-green-500"
                                      style={{ width: "120px" }}
                                    >
                                      Give Access
                                    </button>
                                  ) : getAccessStatus(
                                      contact.hasPortalAccess,
                                      contact.cognito?.userStatus
                                    ) === "Has Access" ? (
                                    <button
                                      onClick={() => {
                                        setshowPortalAccessConfirmationModal({
                                          toggle: true,
                                          prompt: "remove",
                                          data: contact,
                                        });
                                      }}
                                      className="h-10 font-semibold justify-center items-center text-white bg-red-500 rounded-md text-xs cursor-pointer border border-red-500 hover:bg-white hover:text-red-500"
                                      style={{ width: "120px" }}
                                    >
                                      Remove Access
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setshowPortalAccessConfirmationModal({
                                          toggle: true,
                                          prompt: "resend",
                                          data: contact,
                                        });
                                      }}
                                      className="h-10 font-semibold justify-center items-center text-white bg-orange-500 rounded-md text-xs cursor-pointer border border-orange-500 hover:bg-white hover:text-orange-500"
                                      style={{ width: "120px" }}
                                    >
                                      Resend Invite
                                    </button>
                                  )}

                                  <button
                                    title="Edit"
                                    className="ml-1 w-max font-semibold text-gray-500 rounded-full"
                                  >
                                    <FaEdit
                                      onClick={() => handleEditModal(contact)}
                                    />
                                  </button>
                                  <button
                                    title="Delete"
                                    className={
                                      contact.id ===
                                      localStorage.getItem("userId")
                                        ? "invisible"
                                        : "text-red-400 w-max font-semibold rounded-full"
                                    }
                                  >
                                    <CgTrash
                                      onClick={() =>
                                        handleDeleteModal(
                                          contact.id,
                                          contact.email,
                                          contact.company
                                        )
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            transform: `translateY(${
                              virtualRows[0]?.start || 0
                            }px)`,
                          }}
                        ></div>
                        {/* {ContactList && ContactList.map((contact, index) => { */}
                        {virtualRows.map((virtualRow) => {
                          const row = rowsModel[virtualRow.index];
                          const contact = row.original;
                          const index = row.index;

                          return (
                            <div
                              key={virtualRow.key}
                              className="absolute top-0 left-0 w-full"
                              ref={(e) => virtualRow.measureElement(e)}
                              data-index={virtualRow.index}
                              style={{
                                // height: `${virtualRow.size}px`,
                                transform: `translateY(${virtualRow.start}px)`,
                              }}
                            >
                              <div
                                ref={(ref) => {
                                  if (contact.isNewlyAdded) {
                                    rows.current.push(ref);
                                  }
                                }}
                                key={contact.id}
                                className={
                                  "grid grid-cols-11 items-center " +
                                  (contact.isNewlyAdded
                                    ? "opacity-100 bg-cyan-100"
                                    : "opacity-100 " +
                                      (virtualRow.index % 2 === 0
                                        ? "bg-neutral-50"
                                        : ""))
                                }
                              >
                                <div className="p-2 col-span-2 flex items-center gap-x-2 ">
                                  <div className="inline-flex bg-gray-500 text-white w-8 h-8 rounded-full text-md font-medium justify-center items-center">
                                    {contact.profilePicture === null ? (
                                      <>
                                        {handleInitials(
                                          contact.firstName,
                                          contact.lastName
                                        )}
                                      </>
                                    ) : (
                                      <img
                                        className="rounded-full w-8 h-8"
                                        src={contact.profilePicture}
                                      />
                                    )}
                                  </div>
                                  <p className="font-semibold max-w-40 min-w-min">
                                    {contact.firstName} {contact.lastName}
                                  </p>
                                </div>
                                <div className="p-2 col-span-3 break-words">
                                  {contact.email}
                                </div>
                                <div className="p-2">
                                  {contact.teams?.items.map((t) => (
                                    <div
                                      key={t.id}
                                      className="flex items-center py-1"
                                    >
                                      <TeamOptionsCheck t={t}>
                                        <p className="text-center font-semibold text-xs rounded-3xl bg-gray-200 px-1 py-1">
                                          {t.name}
                                        </p>
                                      </TeamOptionsCheck>
                                    </div>
                                  ))}{" "}
                                </div>
                                <div className="p-2 flex items-center gap-x-2">
                                  <p className="text-center font-semibold text-xs rounded-3xl bg-blue-100 px-2 py-1">
                                    {humaReadableFormat(
                                      contact.userType
                                        .replace("OTHERPARTY", "OTHER_PARTY") // add label separator
                                        .replace("LEGALADMIN", "LEGAL_ADMIN") // add label separator
                                    )}
                                  </p>
                                </div>
                                <div className="p-2">
                                  {contact.businessName
                                    ? contact.businessName
                                    : "N/A"}
                                </div>
                                <div className="p-2">
                                  {getAccessStatusDisplay(
                                    contact.hasPortalAccess,
                                    contact.cognito?.userStatus
                                  )}
                                </div>

                                <div className="p-2 col-span-2 flex items-center justify-end gap-x-3">
                                  {getAccessStatus(
                                    contact.hasPortalAccess,
                                    contact.cognito?.userStatus
                                  ) === "No Access" ? (
                                    <button
                                      onClick={() => {
                                        setshowPortalAccessConfirmationModal({
                                          toggle: true,
                                          prompt: "allow",
                                          data: contact,
                                        });
                                      }}
                                      className="h-10 font-semibold justify-center items-center text-white bg-green-500 rounded-md text-xs cursor-pointer border border-green-500 hover:bg-white hover:text-green-500"
                                      style={{ width: "120px" }}
                                    >
                                      Give Access
                                    </button>
                                  ) : getAccessStatus(
                                      contact.hasPortalAccess,
                                      contact.cognito?.userStatus
                                    ) === "Has Access" ? (
                                    <button
                                      onClick={() => {
                                        setshowPortalAccessConfirmationModal({
                                          toggle: true,
                                          prompt: "remove",
                                          data: contact,
                                        });
                                      }}
                                      className="h-10 font-semibold justify-center items-center text-white bg-red-500 rounded-md text-xs cursor-pointer border border-red-500 hover:bg-white hover:text-red-500"
                                      style={{ width: "120px" }}
                                    >
                                      Remove Access
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setshowPortalAccessConfirmationModal({
                                          toggle: true,
                                          prompt: "resend",
                                          data: contact,
                                        });
                                      }}
                                      className="h-10 font-semibold justify-center items-center text-white bg-orange-500 rounded-md text-xs cursor-pointer border border-orange-500 hover:bg-white hover:text-orange-500"
                                      style={{ width: "120px" }}
                                    >
                                      Resend Invite
                                    </button>
                                  )}

                                  <button
                                    title="Edit"
                                    className="ml-1 w-max font-semibold text-gray-500 rounded-full"
                                  >
                                    <FaEdit
                                      onClick={() => handleEditModal(contact)}
                                    />
                                  </button>
                                  <button
                                    title="Delete"
                                    className={
                                      contact.id ===
                                      localStorage.getItem("userId")
                                        ? "invisible"
                                        : "text-red-400 w-max font-semibold rounded-full"
                                    }
                                  >
                                    <CgTrash
                                      onClick={() =>
                                        handleDeleteModal(
                                          contact.id,
                                          contact.email,
                                          contact.company
                                        )
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          ) : ActiveMenu === "Teams" ? (
            <TeamsTab
              teams={TeamList}
              setAlphabetArrayState={setAlphabetArrayState}
              alphabetArrayState={alphabetArrayState}
              teamsAlphabet={teamsAlphabet}
              setTeamsAlphabet={setTeamsAlphabet}
              shortcutSelected={shortcutSelected}
              ascDesc={ascDesc}
              setAscDesc={setAscDesc}
              sortBy={sortBy}
              setSortBy={setSortBy}
              refLetters={refLetters}
              ContactList={ContactList}
              setContactList={setContactList}
              ShowBurst={ShowBurst}
              getTeams={getTeams}
              setalertMessage={setalertMessage}
              setShowToast={setShowToast}
              UserTypes={UserTypes}
              CompanyUsers={CompanyUsers}
              isLoading={isLoading}
              setisLoading={setisLoading}
              tagTeamMember={tagTeamMember}
              getMobileContentHeight={getMobileContentHeight}
            />
          ) : ActiveMenu === "Matters" ? (
            <>
              <MattersTab
                matters={matters}
                setAlphabetArrayState={setAlphabetArrayState}
                alphabetArrayState={alphabetArrayState}
                mattersAlphabet={mattersAlphabet}
                setMattersAlphabet={setMattersAlphabet}
                ascDesc={ascDesc}
                setAscDesc={setAscDesc}
                setShowEditMatterModal={setShowEditMatterModal}
                setSelectedMatter={setSelectedMatter}
                getMatters={getMatters}
                setAddedMatter={setAddedMatter}
                addedMatter={addedMatter}
                refLetters={refLetters}
                shortcutSelected={shortcutSelected}
                skeletonLoad={skeletonMatter}
                setMatters={setMatters}
                setSkeletonLoad={setSkeletonMatter}
                sortBy={matterSortBy}
                setSortBy={setMatterSortBy}
              />
            </>
          ) : ActiveMenu === "Archived Contacts" ? (
            <ArchivedUsersTab
              setContactList={setContactList}
              isMobileDevice={isMobileDevice}
              width={width}
              getMobileContentHeight={getMobileContentHeight}
              loading={loading}
              getContacts={getContacts}
              ArchivedUsers={ArchivedUsers}
              setAlphabetArrayState={setAlphabetArrayState}
              alphabetArrayState={alphabetArrayState}
              archivedUsersAlphabet={archivedUsersAlphabet}
              setArchivedUsersAlphabet={setArchivedUsersAlphabet}
              handleInitials={handleInitials}
              TeamOptionsCheck={TeamOptionsCheck}
              ascDesc={ascDesc}
              setAscDesc={setAscDesc}
              handleSortByName={handleSortByName}
              isSortByFirstName={isSortByFirstName}
              CgSortAz={CgSortAz}
              CgSortZa={CgSortZa}
              handleSortByCompanyName={handleSortByCompanyName}
              getAccessStatus={getAccessStatus}
              handleSortByUserType={handleSortByUserType}
              isSortByUserType={isSortByUserType}
              isSortByCompany={isSortByCompany}
              sortBy={sortBy}
              refLetters={refLetters}
              shortcutSelected={shortcutSelected}
              rows={rows}
              humaReadableFormat={humaReadableFormat}
              getAccessStatusDisplay={getAccessStatusDisplay}
              setArchivedUsers={setArchivedUsers}
              setSortBy={setSortBy}
            />
          ) : null}
        </div>
        {ShowDeleteModal && (
          <DeleteModal
            close={() => setShowDeleteModal(false)}
            setArchivedUsers={setArchivedUsers}
            toDeleteid={isToDelete}
            userEmail={userEmail}
            userCompanyId={userCompanyId}
            setContactList={setContactList}
            ContactList={ContactList}
            getContacts={getContacts}
            setalertMessage={setalertMessage}
            setShowToast={setShowToast}
          />
        )}
        {ShowEditModal && (
          <ContactInformationModal
            TeamList={TeamList}
            ContactList={ContactList}
            setContactList={setContactList}
            handleCloseModal={handleCloseModal}
            close={() => {
              setShowEditModal(false);
              getContacts();
            }}
            user={CurrentUser}
            proxyUser={proxyUser}
            tagTeamMember={tagTeamMember}
            TeamOptions={TeamOptions}
            UserTypes={UserTypes}
            setalertMessage={setalertMessage}
            setShowToast={setShowToast}
            setTeamOptions={setTeamOptions}
            seterrortoast={seterrortoast}
            getTeams={getTeams}
            width={width}
          />
        )}
      </main>

      {showPortalAccessConfirmationModal.toggle && (
        <PortalAccessConfirmationModal
          close={() =>
            setshowPortalAccessConfirmationModal({
              toggle: false,
              prompt: "",
              data: null,
            })
          }
          prompt={showPortalAccessConfirmationModal.prompt}
          data={showPortalAccessConfirmationModal.data}
          removePortalAccess={(dt) => removePortalAccess(dt)}
          allowPortalAccess={(dt) => allowPortalAccess(dt)}
          resendPortalAccess={(dt) => resendPortalAccess(dt)}
        />
      )}

      {showAddContactModal && (
        <AddContactModal
          close={() => setshowAddContactModal(false)}
          setContactList={setContactList}
          ContactList={ContactList}
          getContacts={getContacts}
          setalertMessage={setalertMessage}
          seterrortoast={seterrortoast}
          setShowToast={setShowToast}
          setAreContactsAdded={setAreContactsAdded}
          width={width}
          UserTypes={UserTypes}
        />
      )}
      {ShowAddTeamModal && (
        <AddTeamModal
          close={() => setShowAddTeamModal(false)}
          setTeamList={setTeamList}
          TeamList={TeamList}
          getContacts={getContacts}
          setShowBurst={setShowBurst}
          getTeams={getTeams}
          UserTypes={UserTypes}
          CompanyUsers={CompanyUsers}
          tagTeamMember={tagTeamMember}
          setisLoading={setisLoading}
          width={width}
        />
      )}
      {showAddMatterModal && (
        <AddMatterModal
          close={() => {
            setShowAddMatterModal(false);
          }}
          clientMatters={matters}
          getMatters={getMatters}
          setAddedMatter={setAddedMatter}
          matters={matters}
        />
      )}
      {showEditMatterModal && (
        <MatterInformationModal
          close={() => setShowEditMatterModal(false)}
          matter={selectedMatter}
          width={width}
          setMatter={setSelectedMatter}
          getMatters={getMatters}
          matters={matters}
          UserTypes={UserTypes}
        />
      )}
      {showToast && (
        <ToastNotification
          title={alertMessage}
          error={errortoast}
          hideToast={hideToast}
        />
      )}
      {showSessionTimeout && <SessionTimeout />}
    </>
  );
}
