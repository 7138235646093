import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import ToastNotification from "../toast-notification";
import { Auth, API } from "aws-amplify";
import "../../assets/styles/AccountSettings.css";
import { MdArrowForwardIos } from "react-icons/md";
import Select from "react-dropdown-select";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
    FiToggleLeft,
    FiToggleRight
} from "react-icons/fi";
import { FaTachometerAlt } from "react-icons/fa";
import { CgChevronLeft } from "react-icons/cg";
import Button from "../../shared/Button"
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";

export default function ClientMatterLibrary() {
    const { matter_id } = useParams();
    let history = useHistory();
    const companyId = localStorage.getItem("companyId");
    var moment = require("moment");
    const { height, width } = useWindowDimensions();

    const [clientList, setClientList] = useState(null);
    const [matterList, setMatterList] = useState(null);
    const [showOther, setShowOther] = useState(false);
    const [clientName, setClientName] = useState("");
    const [matterName, setMatterName] = useState("");
    const [originalClient, setOriginalClient] = useState("");
    const [originalMatter, setOriginalMatter] = useState("");
    const [show, setShow] = useState(true);
    const [showId, setShowId] = useState("");


    useEffect(() => {
        getClients();
        getMatters();
    }, []);

    const contentDiv = {
        marginLeft: "65px",
    };

    const mainGrid = {
        display: "grid",
        gridtemplatecolumn: "1fr auto",
    };

    //get client list
    const listClient = `
    query listClient($companyId: String) {
        company(id: $companyId) {
        clients {
            items {
            id
            name
            }
        }
        }
    }
    `;

    const getClients = async () => {
        const clients = await API.graphql({
            query: listClient,
            variables: {
            companyId: companyId,
            },
        });

        console.log("Client List", clients.data.company.clients);

        var temp = [];
        clients.data.company.clients.items.map(
            (x) => temp = [...temp, x]
        )
        setClientList(temp);
    }

    //get matter list
    const listMatter = `
    query listMatter($companyId: String) {
        company(id: $companyId) {
            matters {
            items {
                id
                name
            }
            }
        }
    }
    `;

    const getMatters = async () => {
        const matters = await API.graphql({
            query: listMatter,
            variables: {
              companyId: companyId,
            },
        });
    
        console.log("Matter List", matters.data.company.matters);
    
        var temp = [];
        matters.data.company.matters.items.map(
            (x) => temp = [...temp, x]
        )
        setMatterList(temp);
    }

    //changing values
    const handleChangeClientName = (e) => {
        setClientName(e.currentTarget.textContent);
    };

    const handleChangeMatterName = (e) => {
        setMatterName(e.currentTarget.textContent);
    };

    //saving values
    const mSaveClient = 
    `mutation updateClient($id: ID, $name: String) {
        clientUpdate(id: $id, name: $name) {
          id
        }
      }
    `;

    const mSaveMatter = 
    `mutation updateMatter($id: ID, $name: String) {
        matterUpdate(id: $id, name: $name) {
          id
        }
      }`;


    const saveClient = async (cid) => {
        if(clientName === "" || clientName === null || stringValPatternValidation(clientName) === true){
            alert("Client/Matter name cannot be blank");
            setShow(false);
            var temp = clientList;
            temp[0].name = originalClient;
            setClientList(temp);
            getClients().then(()=>setShow(true));  
        }else{
            var newClient = await API.graphql({
                query: mSaveClient,
                variables: {
                  id: cid,
                  name: clientName
                },
            });
            
        }
    }

    const saveMatter = async (mid) => {
        if(matterName === "" || matterName === null || stringValPatternValidation(matterName) === true){
            alert("Client/Matter name cannot be blank");
            setShow(false);
            var temp = matterList;
            temp[0].name = originalMatter;
            setMatterList(temp);
            getMatters().then(()=>setShow(true));
        }else{
            var newMatter = await API.graphql({
                query: mSaveMatter,
                variables: {
                id: mid,
                name: matterName
                },
            });
        }
    }

    function stringValPatternValidation(stringVal){
        return ( 
            stringVal.trim().length === 0
          );
    }

  return (
    <>
    {width > 844 ?
    (
    <>
    {/* WEB VIEW */}
    <div style={contentDiv}>
        <div className="flex flex-col ml-6">
            <div className="sticky hidden pl-12 sm:pl-0 top-0 py-4 sm:flex items-center gap-2 bg-white z-10">
              <div
                onClick={() => history.goBack()}
                className="w-8 py-5 cursor-pointer"
              >
                <CgChevronLeft /> 
              </div>
              <div>
                <p className="flex flex-col">
                  <span className="text-lg font-bold flex">
                        {showOther === false ? 
                            (<>
                                <FiToggleRight
                                    onClick={()=>setShowOther(!showOther)}
                                    size={20}
                                    className="mr-2 mt-1"
                                />
                                Clients Library 
                            </>)
                        : 
                            (<>
                                <FiToggleLeft
                                    onClick={()=>setShowOther(!showOther)}
                                    size={20}
                                    className="mr-2 mt-1"
                                />
                                Matters Library
                            </>)
                        }
                        
                  </span>
                  <span className=" text-grey-600">
                    Manage Clients and Matters 
                  </span>
                </p>
                <div className="flex items-center gap-3 text-gray-500 mt-2">
                  <Link to="/dashboard">
                    <div className="flex items-center gap-3">
                      <FaTachometerAlt />
                      <p className="hidden sm:block font-semibold ">
                        Dashboard
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
        </div>

        <div className=" bg-gray-50 h-100 p-5 mx-10">
            <div className="flex">
                <p>Display Setting:</p>
                    <CreatableSelect
                        className="w-48 ml-5 -mt-2"
                        options={[{label: "Client/Matter", value: "Client/Matter"},{label: "Matter/Client", value: "Matter/Client"}]}
                        onChange={(e)=>localStorage.setItem("cmNameOrder", e.value)}    
                        defaultValue={{label: localStorage.getItem("cmNameOrder") ? localStorage.getItem("cmNameOrder") : "Client/Matter",
                                value: localStorage.getItem("cmNameOrder") ? localStorage.getItem("cmNameOrder") : "Client/Matter"}}                    
                    />
            </div>
            

        </div>
 
        <div className="p-5 ml-5">
            <table className="min-w-full divide-y divide-gray-200 border flex-1">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-5 text-left text-xs font-medium text-gray-500 tracking-wider w-2/6"
                    >
                      {showOther === false ? "Client List" : "Matter List"}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {showOther === false ?   
                    clientList?.map((data, index) => (
                        <tr 
                            key={data?.id}
                        >
                            <td className="p-2 flex">
                                <p className="w-10 mt-2">{index+1}</p>
                                {show === true ?
                                (
                                <div
                                    className={`p-1 border border-2 border-gray-300 overflow resize-none w-full bg-white rounded font-poppins h-max-1 py-1 ml-1 mt-1`}
                                    suppressContentEditableWarning
                                    dangerouslySetInnerHTML={{
                                        __html: data?.name
                                    }}
                                    onBlur={(e) =>{
                                        console.log("Client Name Saved");
                                        saveClient(data?.id);
                                    }}
                                    onInput={(e) => {
                                        handleChangeClientName(e);
                                    }}
                                    onClick={() => {
                                        setClientName(data?.name)
                                        setOriginalClient(data?.name)
                                        setShowId(data?.id)
                                    }}
                                    contentEditable={true}
                                ></div>
                                ) : (
                                <>
                                <div className="w-full">
                                    <SkeletonTheme height={"30px"}>
                                        <Skeleton count={1} />
                                    </SkeletonTheme>
                                </div>
                                </>
                                )}
                            </td>
                        </tr>   
                        ))
                    :
                    matterList?.map((data, index) => (
                        <tr 
                            key={data?.id}
                        >
                            <td className="p-2 flex">
                                <p className="w-10 mt-2"> {index+1}</p>
                                {show === true ?
                                (
                                <div
                                    className={`p-1 border border-2 border-gray-300 overflow resize-none w-full bg-white rounded font-poppins h-max-1 py-1 ml-1 mt-1`}
                                    suppressContentEditableWarning
                                    dangerouslySetInnerHTML={{
                                        __html: data?.name
                                    }}
                                    onBlur={(e) =>{
                                        console.log("Matter Name Saved");
                                        saveMatter(data?.id)
                                    }}
                                    onInput={(e) => {
                                        handleChangeMatterName(e);
                                    }}
                                    onClick={() => {
                                        setMatterName(data?.name)
                                        setOriginalMatter(data?.name)
                                        setShowId(data?.id)
                                    }}
                                    contentEditable={true}
                                ></div>
                                ) : (
                                    <>
                                    <div className="w-full">
                                        <SkeletonTheme height={"30px"}>
                                            <Skeleton count={1} />
                                        </SkeletonTheme>
                                    </div>
                                    </>
                                )}
                            </td>
                        </tr>   
                        )) 
                } 
                </tbody>
            </table>
        </div>
    </div>
    </>
    )
    :
    (
    <>
    {/* MOBILE VIEW */}
    </>
    )
    }
    </>
  );
}
