import React, { useState, useEffect, useRef } from "react";
import ToastNotification from "../toast-notification";
import { FaTimes } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { FaDownload } from "react-icons/fa";
import Button from "../../shared/Button";

export default function ContractReviewNotificationModal({
//   setShowUnviewableFileModal,
//   unviewableFileLink,
//   setUnviewableFileLink,
}) {
  function handleModalClose() {
    // setUnviewableFileLink("");
    // setShowUnviewableFileModal(false);
  }

//   function handleDownloadFile() {
//     window.open(unviewableFileLink);
//     handleModalClose();
//   }

  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const hideToast = () => {
    setShowToast(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
            <div className="items-center">
              
              <div className="items-center rounded-b mb-5">
                <div className="px-20 w-full items-center text-center text-md py-20">
                 Processing Your Contract...
                </div>
              </div>

              {/* <div className="flex items-center justify-end p-6 rounded-b">
                <div className="px-5 w-full flex items-center gap-x-5 justify-center text-md">
                  <Button
                    block={true}
                    size="large"
                    variant="danger-l"
                    onClick={() => {
                      handleModalClose();
                    }}
                  >
                    <span className="text-base font-semibold">
                      Cancel &nbsp;
                    </span>
                    <TiCancel />
                  </Button>

                  <Button
                    block={true}
                    size="large"
                    variant="primary-l"
                    onClick={() => {
                      handleDownloadFile();
                    }}
                  >
                    <span className="w-24 text-base font-semibold">
                      Download
                    </span>
                    <FaDownload />
                  </Button>
                </div>
              </div> */}
              {showToast && resultMessage && (
                <ToastNotification
                  title={resultMessage}
                  hideToast={hideToast}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
