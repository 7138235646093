import { API } from "aws-amplify";
const moment = require("moment");

/** Start Background List */
const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs {
      items {
        id
        name
        userTypeAccess
        date
        order
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        backgrounds(limit: 50, sortOrder: ORDER_ASC) {
          items {
            id
            description
            date
            dynamicData
            files {
              items {
                id
                name
              }
            }
          }
        }
        hasCommentAccess
        columnIds
        phase
      }
    }
  }
}`;

const FILTER_BRIEFS_BY_DATE_QUERY = `query getBriefsByClientMatter($id: ID, $filterByDate: BriefsFilterByDateInput) {
  clientMatter(id: $id) {
    briefs (filterByDate: $filterByDate){
      items {
        id
        name
        userTypeAccess
        date
        order
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        backgrounds(limit: 50, sortOrder: ORDER_ASC) {
          items {
            id
            description
            date
            dynamicData
            files {
              items {
                id
                name
              }
            }
          }
        }
        hasCommentAccess
        columnIds
        phase
      }
    }
  }
}`;

const ARCHIVED_BRIEFS_QUERY = `
query getBriefsByClientMatter($id: ID) {
  clientMatter(id: $id) {
    briefs(isDeleted: true){
      items {
        id
        name
        userTypeAccess
        date
        order
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        hasCommentAccess
        columnIds
      }
    }
  }
}
`;

export async function getArchivedBgList(matter_id) {
  try {
    const params = {
      query: ARCHIVED_BRIEFS_QUERY,
      variables: { id: matter_id },
    };

    const response = await API.graphql(params);
    return response?.data?.clientMatter?.briefs?.items;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}

export async function getBgList(matter_id, filter) {
  console.log("getBgList()", matter_id, filter);
  try {
    let params = {
      query: BRIEFS_QUERY,
      variables: {
        id: matter_id,
      },
    };

    if (filter) {
      params = {
        query: FILTER_BRIEFS_BY_DATE_QUERY,
        variables: {
          id: matter_id,
          filterByDate: {
            filterBy: filter?.filterType,
            startDate: moment(new Date(filter?.dateRange?.start)).format(
              "YYYY-MM-DD"
            ),
            endDate: moment(new Date(filter?.dateRange?.end)).format(
              "YYYY-MM-DD"
            ),
          },
        },
      };
    }

    const response = await getBriefs(params);
    console.log("response", response);
    return response?.data?.clientMatter?.briefs?.items;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}

async function getBriefs(params) {
  console.log("getBriefs()");
  try {
    return await API.graphql(params);
  } catch (error) {
    console.error(error.message);
    throw error;
  }
}
