import React, { useEffect, useState, useRef } from "react";
import { API, Storage } from "aws-amplify";
import config from "../../aws-exports";
import { LuHardHat } from "react-icons/lu";
import UploadIcon from "../../assets/images/hba-upload-icon.svg";
import Button from "../../shared/Button";
// import isMSdoc from "../../shared/msFileTypeChecker";
import ToastNotification from "../toast-notification";
import buildersIcon from "../../assets/images/builder-icon.png";
import uploadIcon from "../../assets/images/upload-icon.png";
import { IoCloseOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import DeleteMatterModal from "../dashboard/delete-matters-modal";
import { FaRegCopy, FaPercent } from "react-icons/fa";

export default function BuilderDetails({
  buildInfo,
  setBuildInfo,
  logoURL,
  setLogoURL,
  handleLogoDrop,
  handleDragOver,
  setShowRightPanel,
  clientMatterId,
  builderLogo,
  setBuilderLogo,
  builderName,
  setBuilderName,
  builderContactName,
  setBuilderContactName,
  setBuilderABN,
  setBuilderLicenseNumber,
  builderContactEmail,
  setBuilderContactEmail,
  builderContactNumber,
  setBuilderContactNumber,
  builderAddress,
  setBuilderAddress,
  builderMargin,
  setBuilderMargin,
  handleChangeGetStartedProgress,
  setLogoId,
  logoId,
  logo,
  setLogo,
  isSandbox,
}) {
  Storage.configure({
    region: config.aws_user_files_s3_bucket_region,
    bucket: config.aws_user_files_s3_bucket,
    identityPoolId: config.aws_user_pools_id,
  });

  var moment = require("moment");
  const [backgroundList, setBackgroundList] = useState([]);
  const [backgroundTableCols, setBackgroundTableCols] = useState([]);
  // const [column, setcolumn] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });

  const [showToast, setShowToast] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const hideToast = () => {
    setShowToast(false);
  };

  //LOGO
  const [uploadedLogo, setUploadedLogo] = useState(null);

  //Builder email and phone copy func
  const [builderEmailCopy, setBuilderEmailCopy] = useState("Copy");
  const [builderContactCopy, setBuilderContactCopy] = useState("Copy");

  //For activity log
  const [hasEdits, setHasEdits] = useState(false);

  useEffect(() => {
    if (backgroundList.length === 0) {
      getBriefs();
    }
  }, []);

  const defaultColumnIds = [0, 1, 2, 3, 4];

  const mUpdateBackgroundFile = `mutation addBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileTag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }`;

  const mUpdateBackgroundDynamicData = `mutation updateBackground($id: ID, $dynamicData: AWSJSON) {
    backgroundUpdate(id: $id, dynamicData: $dynamicData) {
      id
      dynamicData
    }
  }`;

  const mBulkCreateMatterFile = `mutation bulkCreateMatterFile ($files: [MatterFileInput]) {
    matterFileBulkCreate(files: $files) {
      id
      name
      order
    }
  }`;

  const BRIEFS_QUERY = `query getBriefsByClientMatter($id: ID) {
    clientMatter(id: $id) {
      briefs {
        items {
          id
          name
          userTypeAccess
          date
          order
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          updatedAt
          updatedBy {
            id
            firstName
            lastName
          }
          backgrounds(limit: 50, sortOrder: ORDER_ASC) {
            items {
              id
              description
              date
              dynamicData
              files {
                items {
                  createdAt
                  id
                  name
                }
              }
            }
          }
          hasCommentAccess
          columnIds
        }
      }
    }
  }`;

  const BACKGROUND_TABLE_QUERY = `query getBackgroundTable($clientMatterId: ID!) {
    backgroundTable(clientMatterId: $clientMatterId) {
      id
      columns {
        id
        accessorKey
        headerText
        enabled
        type
        optionsText
        order
        presets {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }`;

  const CREATE_BRIEF_MUTATION = `mutation createBrief($clientMatterId: String, $date: AWSDateTime, $name: String, $order: Int, $columnIds: [Int]) {
    briefCreate(
      clientMatterId: $clientMatterId
      date: $date
      name: $name
      order: $order
      columnIds: $columnIds
    ) {
      id
      name
      date
      createdAt
      order
      columnIds
    }
  }`;

  const mCreateBackground = `mutation createBackground($description: String, $comments: String, $date: AWSDateTime, $order: Int, $briefs: [BriefInput]) {
    backgroundCreate(
      description: $description
      comments: $comments
      date: $date
      order: $order
      briefs: $briefs
    ) {
      id
      createdAt
      date
      description
      order
    }
  }
  `;

  const UPDATE_BRIEF_PRESET_MUTATION = `mutation updateBriefPresets($id: ID, $columnIds: [Int]) {
    briefUpdate(id: $id, columnIds: $columnIds){
      id
    }
  }`;

  const UPDATE_BACKGROUND_TABLE_MUTATION = `mutation backgroundTableUpdate($id: ID!, $input: BackgroundTableInput!) {
    backgroundTableUpdate(id: $id, input: $input) {
      id
      columns {
        id
        accessorKey
        headerText
        type
        enabled
        optionsText
        order
        presets {
          id
          name
        }
      }
    }
  }`;

  // const BACKGROUND_TABLE_CREATE = `
  //   mutation createBackgroundTable($clientMatterId: ID!, $initialPresets: [BriefInput]) {
  //   backgroundTableCreate(clientMatterId: $clientMatterId, initialPresets: $initialPresets) {
  //       id
  //       columns {
  //       id
  //       accessorKey
  //       headerText
  //       enabled
  //       type
  //       optionsText
  //       order
  //       }
  //       createdAt
  //       updatedAt
  //   }
  //   }
  // `;

  const qGetFileDownloadLink = `query getFileDownloadLink($id: ID) {
    file(id: $id) {
      downloadURL
      s3ObjectKey
      type
    }
  }`;

  const UNTAG_BACKGROUND_FILE_MUTATION = `mutation untagBackgroundFile($backgroundId: ID, $files: [FileInput]) {
    backgroundFileUntag(backgroundId: $backgroundId, files: $files) {
      id
    }
  }`;

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (uploadedFiles?.files?.length > 0) {
      handleUploadLink(uploadedFiles);
    }
    console.log("uploadedFiles", uploadedFiles);
  }, [uploadedFiles]);

  const handleAddRow = async (briefId, briefName) => {
    try {
      const dateToday = moment
        .utc(moment(new Date(), "YYYY-MM-DD"))
        .toISOString();

      const tempBriefDetails = [{ id: briefId, name: briefName }];
      console.log(tempBriefDetails);

      const createBackgroundRow = await API.graphql({
        query: mCreateBackground,
        variables: {
          briefs: tempBriefDetails,
          description: "",
          comments: "",
          date: null,
        },
      });

      console.log("created new row", createBackgroundRow);
      getBriefs();
    } catch (error) {
      console.error(error);
    }
  };

  async function setDefaultColumnBriefs(backgroundTables, briefs) {
    try {
      const { id, columns } = backgroundTables;
      let builderDetailsPreset;
      if (id) {
        let presets = [];
        const commentsColPresets = [];

        for (const item of briefs) {
          const preset = { id: item.id, name: item.name };
          if (item.name == "Builder Details") {
            builderDetailsPreset = { id: item.id, name: item.name };
          }

          presets.push(preset);

          if (item.hasCommentAccess) {
            const commentPreset = { ...preset };
            commentsColPresets.push(commentPreset);
          }
        }

        let isBuilderDetailsPresent = false;

        // set updated columns to get the customised columns
        // setBackgroundTableCols(columns);

        const defaultBriefs = columns.map((obj) => {
          console.log("columns", obj, obj.id);
          console.log("defaultColumnIds:", defaultColumnIds);

          if (
            obj.headerText == "Builder Name" ||
            obj.headerText == "Builder Logo" ||
            obj.headerText == "Builder Contact Name" ||
            obj.headerText == "Builder Contact Email" ||
            obj.headerText == "Builder Contact Number"
          ) {
            isBuilderDetailsPresent = true;
          }

          let tag = "Builder Details";

          //??

          if (
            obj.headerText == "Variation Status" ||
            obj.headerText == "Variation Cost" ||
            obj.headerText == "Variation Cost ($)"
          ) {
            tag = "Variations";
          } else if (
            obj.headerText == "Claim Status" ||
            obj.headerText == "Payment Status" ||
            obj.headerText == "Claim Amount ($)" ||
            obj.headerText == "Due Date for Claim"
          ) {
            tag = "Invoices";
          }

          return {
            ...obj,
            presets:
              obj.headerText == "Builder Name" ||
              obj.headerText == "Builder Contact Name" ||
              obj.headerText == "Builder Contact Email" ||
              obj.headerText == "Builder Contact Number"
                ? obj.presets.filter((preset) => preset.name === tag)
                : obj.headerText == "Gmail" ||
                  obj.headerText == "Label" ||
                  obj.headerText === "Email Date"
                ? obj?.presets?.filter(
                    (preset) =>
                      preset.name !== "Variations" &&
                      preset.name !== "Invoices" &&
                      preset.name !== "Builder Details"
                  ) ?? []
                : obj.headerText == "Gmail" ||
                  obj.headerText == "Label" ||
                  obj.headerText === "Email Date" ||
                  obj.headerText === "Description of Background" ||
                  obj.headerText === "Date"
                ? obj?.presets?.filter(
                    (preset) => preset.name !== "Builder Details"
                  ) ?? []
                : obj.presets,
          };
        });

        let newColumns;

        if (!isBuilderDetailsPresent) {
          newColumns = [
            ...defaultBriefs,
            {
              accessorKey: defaultBriefs.length.toString(),
              id: defaultBriefs.length.toString(),
              presets: builderDetailsPreset,
              headerText: "Builder Name",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: defaultBriefs.length,
            },
            {
              accessorKey: (defaultBriefs.length + 1).toString(),
              id: (defaultBriefs.length + 1).toString(),
              presets: builderDetailsPreset,
              headerText: "Builder Contact Name",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: defaultBriefs.length + 1,
            },
            {
              accessorKey: (defaultBriefs.length + 2).toString(),
              id: (defaultBriefs.length + 2).toString(),
              presets: builderDetailsPreset,
              headerText: "Builder Contact Email",
              type: "TEXT",
              enabled: true,
              optionsText: [],
              order: defaultBriefs.length + 2,
            },
            {
              accessorKey: (defaultBriefs.length + 3).toString(),
              id: (defaultBriefs.length + 3).toString(),
              presets: builderDetailsPreset,
              headerText: "Builder Contact Number",
              type: "NUMBER",
              enabled: true,
              optionsText: [],
              order: defaultBriefs.length + 3,
            },
          ];
        } else {
          newColumns = defaultBriefs;
        }

        console.log(id, "new columns:", newColumns);

        const variables = {
          id,
          input: {
            columns: newColumns,
          },
        };

        setBackgroundTableCols(newColumns);

        console.log("VARIABLE ARE:", variables);

        const updateBriefTable = await API.graphql({
          query: UPDATE_BACKGROUND_TABLE_MUTATION,
          variables,
        });

        console.log("updateBriefTable", updateBriefTable);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function setDefaultBriefColumnIds(briefs, clientMatterId) {
    const savePromises = [];
    let briefIds = [];

    for (const { columnIds, id, name } of briefs) {
      if (!columnIds || columnIds?.length === 0) {
        savePromises.push(
          API.graphql({
            query: UPDATE_BRIEF_PRESET_MUTATION,
            variables: { columnIds: defaultColumnIds, id },
          })
        );
      }

      briefIds.push({ id: id, name: name });
    }

    try {
      const updatedBriefIds = await Promise.all(savePromises);
      const newDefaultColumn = updatedBriefIds.map((obj) => ({
        id: obj.data?.briefUpdate?.id,
      }));

      const backgroundTable = await API.graphql({
        query: BACKGROUND_TABLE_QUERY,
        variables: {
          clientMatterId: clientMatterId,
        },
      });

      if (backgroundTable?.data?.backgroundTable) {
        // await setDefaultColumnBriefs(
        //   backgroundTable.data.backgroundTable,
        //   briefs
        // );
        setBackgroundTableCols(backgroundTable?.data?.backgroundTable?.columns);
        // const taggedTableColumns =
        //   backgroundTable?.data?.backgroundTable?.columns
        //     .map((c) => {
        //       let columnObj;
        //       if (c.type === "DROPDOWN") {
        //         columnObj = {
        //           accessorKey: c.id,
        //           header: c.headerText,
        //           type: c.type,
        //           enabled: c.enabled,
        //           order: c.order,
        //           presets: c.presets,
        //           optionsText: c.optionsText ? c.optionsText : [],
        //         };
        //       } else {
        //         columnObj = {
        //           accessorKey: c.id,
        //           header: c.headerText,
        //           type: c.type,
        //           enabled: c.enabled,
        //           presets: c.presets,
        //           order: c.order,
        //         };
        //       }

        //       if (columnObj.presets && parseInt(columnObj.accessorKey) !== 3) {
        //         if (columnObj.presets.length === 0) {
        //           return columnObj;
        //         }
        //         if (
        //           columnObj.presets.some(
        //             (preset) => preset.name === "Builder Details"
        //           )
        //         ) {
        //           return columnObj;
        //         }
        //       }
        //     })
        //     .filter((obj) => obj !== undefined);

        // const columnIds = taggedTableColumns.map((obj) =>
        //   parseInt(obj.accessorKey)
        // );

        // const mergedWithDefaultCols = columnIds;

        // // Exclude disabled/hidden columns on the briefs settings
        // // Unticks Email Date column on first load:

        // const filteredTaggedCols = taggedTableColumns
        //   .filter(
        //     (obj) =>
        //       mergedWithDefaultCols.includes(parseInt(obj.accessorKey)) &&
        //       obj.enabled
        //   )
        //   .map((column) => ({
        //     ...column,
        //     visible: column.header === "Email Date" ? false : true,
        //   }));

        // setcolumn(filteredTaggedCols);
      } else {
        // const initialPresets = [];
        // for (const item of briefs) {
        //   const preset = { id: item.id, name: item.name };
        //   initialPresets.push(preset);
        // }
        // let backgroundCreate = await API.graphql({
        //   query: BACKGROUND_TABLE_CREATE,
        //   variables: {
        //     clientMatterId: clientMatterId,
        //     initialPresets: initialPresets,
        //   },
        // });
        // await setDefaultColumnBriefs(
        //   backgroundCreate.data.backgroundTableCreate,
        //   briefs
        // );
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getValueById(data, id) {
    const item = data[0]; // Assuming there's only one item in the array (only 1 row per brief)
    const dynamicData = item?.dynamicData;
    const matchingObject = dynamicData?.find((obj) => obj.id === id);

    if (matchingObject) {
      return matchingObject.value;
    } else {
      return null; // ID not found
    }
  }

  const getBriefs = async () => {
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      const builderDetailsBrief = briefsList?.find(
        (brief) => brief.name === "Builder Details"
      );

      if (builderDetailsBrief) {
        setBackgroundList(builderDetailsBrief?.backgrounds?.items);

        console.log("Array List", builderDetailsBrief?.backgrounds?.items);

        let unparsedData = builderDetailsBrief?.backgrounds?.items;

        const parsedData = unparsedData?.map((item) => {
          // Assuming item?.files?.items is an array of objects or null/undefined.
          const attachedLogo = item?.files?.items;

          if (Array.isArray(attachedLogo) && attachedLogo.length > 0) {
            // Sort logos if there's more than one.
            if (attachedLogo.length > 0) {
              attachedLogo.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA; // Sort by date descending
              });
            }

            // Extract the ID of the first logo after sorting.
            const { id } = attachedLogo[0];

            // Set the logo ID and process the file.
            setLogoId(id);
            previewAndDownloadFile(id)
              .then((fileData) => {
                // Do something with the fileData
                console.log("preview link", fileData.downloadURL);
                // Assuming setBuilderLogo and setLogoURL are state setters
                setBuilderLogo(fileData.downloadURL);
                setLogoURL(fileData.downloadURL);
              })
              .catch((error) => {
                // Handle any errors
                console.error(error); // Log the actual error object
              });
          }

          if (item.dynamicData) {
            const dynamicDataObject = JSON.parse(item?.dynamicData);
            const dynamicDataArray = Object.keys(dynamicDataObject).map(
              (key) => ({
                id: key,
                value: dynamicDataObject[key],
              })
            );
            return {
              ...item,
              dynamicData: dynamicDataArray,
            };
          }
        });

        if (parsedData[0] !== undefined && parsedData[0].length !== 0) {
          const backgroundTable = await API.graphql({
            query: BACKGROUND_TABLE_QUERY,
            variables: {
              clientMatterId: clientMatterId,
            },
          });

          if (backgroundTable?.data?.backgroundTable) {
            let buildInfo = {
              builderName: null,
              builderLogo: null,
              builderContactName: null,
              builderContactEmail: null,
              builderContactNumber: null,
              builderAddress: null,
              builderMargin: null,
            };

            let backgroundTableInfo =
              backgroundTable?.data?.backgroundTable.columns?.map((obj) => {
                if (obj.headerText === "Builder Name") {
                  console.log("Builder Name", parsedData);
                  setBuilderName(getValueById(parsedData, obj.accessorKey));
                  let builderName = getValueById(parsedData, obj.accessorKey);
                  buildInfo.builderName = builderName;
                  allDynamicData.current[obj.accessorKey] = builderName
                    ? builderName
                    : "";
                }

                if (obj.headerText === "ABN (Australian Business Number)") {
                  console.log("ABN (Australian Business Number)", parsedData);
                  setBuilderABN(getValueById(parsedData, obj.accessorKey));
                  let builderABN = getValueById(parsedData, obj.accessorKey);
                  buildInfo.builderABN = builderABN;
                  allDynamicData.current[obj.accessorKey] = builderABN
                    ? builderABN
                    : "";
                }

                if (obj.headerText === "Builder's License Number") {
                  console.log("Builder's License Number", parsedData);
                  setBuilderLicenseNumber(
                    getValueById(parsedData, obj.accessorKey)
                  );
                  let builderLicenseNumber = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                  buildInfo.builderLicenseNumber = builderLicenseNumber;
                  allDynamicData.current[obj.accessorKey] = builderLicenseNumber
                    ? builderLicenseNumber
                    : "";
                }

                if (obj.headerText === "Builder Contact Name") {
                  console.log("Builder Contact Name", obj.accessorKey);
                  setBuilderContactName(
                    getValueById(parsedData, obj.accessorKey)
                  );
                  let builderContactName = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                  buildInfo.builderContactName = builderContactName;
                  allDynamicData.current[obj.accessorKey] = builderContactName
                    ? builderContactName
                    : "";
                }

                if (obj.headerText === "Builder Contact Email") {
                  console.log("Builder Contact Email", obj.accessorKey);
                  setBuilderContactEmail(
                    getValueById(parsedData, obj.accessorKey)
                  );
                  let builderContactEmail = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                  buildInfo.builderContactEmail = builderContactEmail;
                  allDynamicData.current[obj.accessorKey] = builderContactEmail
                    ? builderContactEmail
                    : "";
                }

                if (obj.headerText === "Builder Contact Number") {
                  console.log("Builder Contact Number", obj.accessorKey);
                  setBuilderContactNumber(
                    getValueById(parsedData, obj.accessorKey)
                  );
                  let builderContactNumber = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                  buildInfo.builderContactNumber = builderContactNumber;
                  allDynamicData.current[obj.accessorKey] = builderContactNumber
                    ? builderContactNumber
                    : "";
                }

                if (obj.headerText === "Builder Address") {
                  console.log("Builder Address", parsedData);
                  setBuilderAddress(getValueById(parsedData, obj.accessorKey));
                  let builderAddress = getValueById(
                    parsedData,
                    obj.accessorKey
                  );
                  buildInfo.builderAddress = builderAddress;
                  allDynamicData.current[obj.accessorKey] = builderAddress
                    ? builderAddress
                    : "";
                }

                if (obj.headerText === "Builder's Margin") {
                  console.log("Builder's Margin", parsedData);
                  setBuilderMargin(getValueById(parsedData, obj.accessorKey));
                  let builderMargin = getValueById(parsedData, obj.accessorKey);
                  buildInfo.builderMargin = builderMargin;
                  allDynamicData.current[obj.accessorKey] = builderMargin
                    ? builderMargin
                    : "";
                }

                return getValueById(parsedData, obj.accessorKey);
              });

            console.log("INFOS: ", buildInfo);
            setBuildInfo(buildInfo);
          }
        }
      }

      if (!builderDetailsBrief) {
        let createBuilderDetails = await API.graphql({
          query: CREATE_BRIEF_MUTATION,
          variables: {
            clientMatterId: clientMatterId,
            name: "Builder Details",
            date: moment.utc(moment(new Date(), "YYYY-MM-DD")).toISOString(),
            order: 0,
            columnIds: defaultColumnIds,
          },
        });

        const briefCreateData = createBuilderDetails?.data?.briefCreate;

        handleAddRow(briefCreateData.id, briefCreateData.name);

        console.log("return ->", briefCreateData.id);

        getBriefs();
      } else {
        console.log("has briefs", briefsList);
        setDefaultBriefColumnIds(briefsList, clientMatterId);
      }
    });
  };

  const getUpdatedBrief = async () => {
    const params = {
      query: BRIEFS_QUERY,
      variables: {
        id: clientMatterId,
      },
    };

    await API.graphql(params).then(async (brief) => {
      const briefsList = brief?.data?.clientMatter?.briefs?.items;
      const builderDetailsBrief = briefsList.find(
        (brief) => brief.name === "Builder Details"
      );

      console.log("found", builderDetailsBrief);
      setBackgroundList(builderDetailsBrief.backgrounds.items);
    });
  };

  const allDynamicData = useRef({});

  const updateDynamicData = async (type, accessorKey, value, row, id) => {
    console.log("ACCESSORKEY", accessorKey);
    console.log("TYPE", type);
    console.log("ROW", row);
    console.log("ID", id);
    console.log("VALUE", value);
    const tempParsed =
      typeof row.dynamicData === "string" && row.dynamicData !== null
        ? JSON.parse(row.dynamicData)
        : row.dynamicData || {};

    const tempDynamic = { ...tempParsed, [accessorKey]: value };

    const tempRow = { ...row, dynamicData: tempDynamic };
    console.log("TEMPORARY ROW", tempRow.dynamicData[accessorKey]);
    allDynamicData.current = {
      ...allDynamicData.current,
      [accessorKey]: tempRow.dynamicData[accessorKey],
    };

    handleSaveInfo(id);
    // setAllDynamicData((prevState) => ({ ...prevState, [accessorKey]: tempRow.dynamicData[accessorKey] }) );
  };

  const handleSaveBuilderInfo = async (header, val) => {
    console.log("handleSaveBuilderInfo");

    const relevantHeaders = [
      "Builder Name",
      "ABN (Australian Business Number)",
      "Builder's License Number",
      "Builder Logo",
      "Builder Contact Name",
      "Builder Contact Email",
      "Builder Contact Number",
      "Builder Address",
      "Builder's Margin",
    ];

    // Filter out the columns that match the header we are interested in.
    const interestedColumns = backgroundTableCols.filter(
      (obj) =>
        relevantHeaders.includes(obj.headerText) && obj.headerText === header
    );

    // Execute updateDynamicData for each matched column
    for (const obj of interestedColumns) {
      // Since this function is assumed to be asynchronous, we need to await its result.
      await updateDynamicData(
        obj.type,
        obj.accessorKey,
        val,
        backgroundList[0],
        backgroundList[0].id
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  };

  const hiddenLogoInput = useRef(null);

  const fetchImageUrlFromS3 = async (s3ObjectKey) => {
    try {
      const url = await Storage.get(s3ObjectKey, { download: false });
      return url;
    } catch (error) {
      console.error("Error fetching image URL from S3", error);
      return null;
    }
  };

  const handleLogoUpload = () => {
    console.log("trigger select file to upload...");
    hiddenLogoInput.current.click();
  };

  async function handleUpload(e) {
    // setUploadedLogo(e.target.files)
    console.log("handleUpload()", e.target.files);
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      console.log("there is image", selectedFiles[0]);
      const areAllFilesImages = Array.from(selectedFiles).every((file) =>
        /\.(jpg|jpeg|png|gif)$/i.test(file.name)
      );

      if (areAllFilesImages) {
        const objUrl = URL.createObjectURL(selectedFiles[0]);
        setUploadedLogo(e.target.files[0]);
        setLogo(selectedFiles);
        setBuilderLogo(objUrl);
        setLogoURL(objUrl);

        console.group("will upload");
        console.log("uploadedLogo", uploadedLogo);
        console.log("logo", logo);
        console.log("logoURL", logoURL);
        console.log("builderLogo", builderLogo);
        console.log("logoId", logoId);

        console.groupEnd();

        if (e.target.files[0]) {
          console.log("uploadedLogo is not null, logoId is null");
          // console.log("bg id", backgroundList);

          // let logoUrl = logo && logo.length ? await handleLogoChange() : null;
          // setBuilderLogo(logoUrl);

          const logoChange = await handleLogoChange(e.target.files[0]);
          if (logoChange) {
            setBuilderLogo(logoChange);
            handleSaveInfo(backgroundList[0].id);
          }
        }
      } else {
        alert("Please select only image files (JPEG, PNG, GIF, etc.).");
        e.target.value = null;
      }
    } else {
      alert("Please select at least one file.");
    }
  }

  const handleLogoChange = async (uploadedLogo) => {
    setLogoURL(URL.createObjectURL(uploadedLogo));
    console.log("entered", URL.createObjectURL(uploadedLogo));
    // setBuilderLogo(URL.createObjectURL(logo[0]));

    let uploadFile = []; // Create an array to store the selected files

    // for (let i = 0; i < uploadedLogo.length; i++) {
    //   const file = uploadedLogo;
    //   uploadFile.push(file);
    // }
    uploadFile = [uploadedLogo];

    console.log("next 00 uploadFile", uploadFile);

    var idxx = 0;

    let result = await Promise.all(
      uploadFile.map(async (uf, index) => {
        console.log("ufs", clientMatterId);

        if (uf.name.split(".").pop() == "docx") {
          var name = uf.name,
            size = uf.size,
            type =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            key = `${clientMatterId}/${Number(new Date())}${name
              .replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        } else {
          var name = uf.name,
            size = uf.size,
            type = uf.type,
            key = `${clientMatterId}/${Number(new Date())}${name
              .replaceAll(/\s/g, "")
              .replaceAll(/[^a-zA-Z.0-9]+|\.(?=.*\.)/g, "")}`,
            orderSelected = idxx,
            order = idxx;
        }

        idxx = idxx + 1;

        try {
          return await Storage.put(key, uf, {
            contentType: type,
            progressCallback(progress) {
              const progressInPercentage = Math.round(
                (progress.loaded / progress.total) * 100
              );
              console.log(`Progress: ${progressInPercentage}%, ${uf.name}`);
            },
            errorCallback: (err) => {
              console.error("204: Unexpected error while uploading", err);
            },
          })
            .then(async (fd) => {
              var fileData = {
                s3ObjectKey: fd.key,
                size: parseInt(size),
                type: type,
                name: name.split(".").slice(0, -1).join("."),
                oderSelected: orderSelected,
                order: orderSelected,
              };
              let result = await fetchImageUrlFromS3(fileData.s3ObjectKey).then(
                (imageUrl) => {
                  if (imageUrl) {
                    console.log("Image URL:", imageUrl);
                    // allDynamicData.current = {...allDynamicData.current, 13: imageUrl }
                    // setAllDynamicData((prevState) => ({ ...prevState, 13: imageUrl }) );
                  }
                  return imageUrl;
                }
              );

              setUploadedFiles((prevState) => ({
                files: [...prevState.files, fileData],
              }));

              console.log(fileData);
              return result;
            })
            .catch((err) => {
              console.error("220: Unexpected error while uploading", err);
            });
        } catch (e) {
          const response = {
            error: e.message,
            errorStack: e.stack,
            statusCode: 500,
          };
        }
      })
    );

    return result[0];
  };

  const handleUploadLink = async (uf) => {
    console.log("handleUploadLink()", uf);
    var uploadedFiles = uf.files.map((f) => ({
      ...f,
      matterId: clientMatterId,
    }));

    //add order to new files
    var sortedFiles = uploadedFiles.sort(
      (a, b) => b.oderSelected - a.oderSelected
    );

    console.log("Uploaded Files", sortedFiles);

    await createMatterFile(sortedFiles);

    console.log(`File successfully uploaded!`);
  };

  async function createMatterFile(param) {
    param.forEach(function (i) {
      delete i.oderSelected;
    });

    const request = await API.graphql({
      query: mBulkCreateMatterFile,
      variables: {
        files: param,
      },
    });

    console.log("createMatterFile", request);

    tagFileToBackground(request);
  }

  const tagFileToBackground = async (param) => {
    if (
      !param ||
      !param?.data ||
      !param?.data?.matterFileBulkCreate ||
      !Array.isArray(param?.data?.matterFileBulkCreate)
    ) {
      // Check if the 'param' object and its properties are valid
      console.error(
        "Invalid 'param' object. It should have a 'data' property with 'matterFileBulkCreate' as an array."
      );
      return;
    }

    // Assuming 'param' is an object that has been defined earlier in the code.
    if (!(param?.data?.matterFileBulkCreate?.length > 0)) {
      console.error(
        "'matterFileBulkCreate' array is empty. Nothing to process."
      );
      return;
    }

    // If further logic depends on 'arrFiles', we should define it after checking the condition above.
    const arrFiles = param.data.matterFileBulkCreate.map(({ id }) => ({ id }));

    const requestBackgroundFile = API.graphql({
      query: mUpdateBackgroundFile,
      variables: {
        backgroundId: backgroundList[0].id,
        files: arrFiles,
      },
    });

    console.log(requestBackgroundFile);
  };

  const previewAndDownloadFile = (id) => {
    console.log("preview and download file: ", id);
    const params = {
      query: qGetFileDownloadLink,
      variables: {
        id: id,
      },
    };

    return API.graphql(params).then((result) => {
      return result.data.file; // Assuming the data structure returned has a "file" property
    });
  };

  const handleToastNotification = () => {
    setResultMessage("Details successfully updated");
    setShowToast(true);

    setTimeout(() => {
      hideToast();
    }, 1500);
  };

  async function handleSaveInfo(id) {
    if (builderContactEmail && !validateEmail(builderContactEmail)) {
      window.alert("Email address is not in the correct format");
      return;
    }

    const buildInfo = {
      builderName,
      builderContactName,
      builderContactEmail,
      builderContactNumber,
      builderAddress,
      builderMargin,
    };

    try {
      const dynamicDataResult = await API.graphql({
        query: mUpdateBackgroundDynamicData,
        variables: {
          id: id,
          dynamicData: JSON.stringify(allDynamicData.current),
        },
      });
      console.log("Dynamic Data", allDynamicData.current);
      console.log(
        "SUCCESS CHANGING DYNAMIC DATA",
        JSON.parse(dynamicDataResult.data.backgroundUpdate.dynamicData)
      );

      handleChangeGetStartedProgress("builderDetails");
      handleToastNotification();
      getBriefs();
      // console.log("INFO: ", buildInfo);
      // setBuildInfo(buildInfo);
    } catch (error) {
      console.error("Error updating builder info:", error);
    }
  }

  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const handleModalClose = () => {
    setshowDeleteModal(false);
  };

  const handleDeleteModal = async () => {
    console.log("handleDeleteModal()");
    // handleSaveInfo(backgroundList[0].id);

    console.group("call untagBackgroundFile");
    const { data: untagBackgroundFile } = await API.graphql({
      query: UNTAG_BACKGROUND_FILE_MUTATION,
      variables: {
        backgroundId: backgroundList[0].id,
        files: [{ id: logoId }],
      },
    });

    console.log(untagBackgroundFile);
    console.groupEnd();

    if (untagBackgroundFile) {
      setLogoId(null);
      setLogoURL(null);
      setBuilderLogo(null);
      setLogo(null);
      setUploadedLogo(null);
      handleModalClose();
      handleToastNotification();
    }
  };

  const handleRecordActivity = () => {
    //KJMF MMA 3586 RECORD ACTIVITY IF SANDBOX DATA IS CHANGED
    const mRecordSandboxDataEdit = `
      mutation createActivity(
        $companyId: ID,
        $activity: String,
        $appModule: AppModules,
        $field: String,
        $current: String,
        $previous: String,
        $rowId: String
      ) {
        activityCreate(
          activity: $activity
          companyId: $companyId
          appModule: $appModule
          previous: $previous
          field: $field
          current: $current
          rowId: $rowId
        ) {
          id
        }
      }
    `;

    //add activity when user exits the page
    var status = localStorage.getItem("isSandbox");

    if (status === "true") {
      if (hasEdits === true) {
        const params = {
          query: mRecordSandboxDataEdit,
          variables: {
            companyId: localStorage.getItem("companyId"),
            activity: `Modified data in Builder Details Modal`,
            userId: localStorage.getItem("userId"),
            appModule: "SANDBOX",
          },
        };

        const addActivity = API.graphql(params).then((result) => {
          console.log(`Modified data in Builder Details Modal`, result);
        });
      }
    }

    setShowRightPanel(false);
  };

  return (
    <>
      {width > 844 ? (
        <div className="p-3 z-10">
          <div className="flex items-start justify-between w-full pl-2">
            <div className="pl-3 pr-2.5 pt-2.5 pb-3 bg-red-100 rounded-3xl border-4 border-red-50">
              <div className="text-justify text-red-600 text-lg font-normal leading-tight">
                <LuHardHat />
              </div>
            </div>
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 flex relative">
            <h3 className="pb-2 text-lg font-semibold text-gray-900">
              Builder Details
            </h3>
          </div>

          {/* INPUT BUILDER'S NAME */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1 text-gray-900">
              {isSandbox === true ? "Who is your builder?" : "Builder's Name"}
            </p>
            <div className="justify-center items-center mx-auto">
              <input
                placeholder="Builder's Name"
                type="text"
                className="p-3 border rounded-md border-gray-400 w-full"
                defaultValue={
                  buildInfo?.builderName ? buildInfo.builderName : ""
                }
                onBlur={(e) => {
                  setBuilderName(e.target.value);
                  setHasEdits(true);
                  handleSaveBuilderInfo("Builder Name", e.target.value);
                }}
                style={{ resize: "none" }}
              ></input>
              {isSandbox === true && (
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  Please provide the name of the company or individual
                  responsible for construction.
                </p>
              )}
            </div>
          </div>

          {/* INPUT BUILDER'S ABN */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">
              ABN (Australian Business Number)
            </p>
            <div className="justify-center items-center mx-auto">
              <input
                type="text"
                // placeholder="Name"
                className="p-3 border rounded-md border-gray-400 w-full h-11"
                defaultValue={buildInfo?.builderABN ? buildInfo.builderABN : ""}
                onBlur={(e) => {
                  setHasEdits(true);
                  setBuilderABN(e.target.value);
                  handleSaveBuilderInfo(
                    "ABN (Australian Business Number)",
                    e.target.value
                  );
                }}
                style={{ resize: "none" }}
              ></input>
            </div>
          </div>

          {/* INPUT BUILDER'S License Number */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">Builder's License Number</p>
            <div className="justify-center items-center mx-auto">
              <input
                type="text"
                // placeholder="Name"
                className="p-3 border rounded-md border-gray-400 w-full h-11"
                defaultValue={
                  buildInfo?.builderLicenseNumber
                    ? buildInfo.builderLicenseNumber
                    : ""
                }
                onBlur={(e) => {
                  setHasEdits(true);
                  setBuilderLicenseNumber(e.target.value);
                  handleSaveBuilderInfo(
                    "Builder's License Number",
                    e.target.value
                  );
                }}
                style={{ resize: "none" }}
              ></input>
            </div>
          </div>

          {/* INPUT LOGO */}
          <div className=" items-start justify-between w-full py-1 px-2 flex flex-col mb-2 relative">
            <p className="pb-1 text-gray-900">Logo</p>
            {logoURL === null ? (
              <div
                onDrop={handleLogoDrop}
                onDragOver={handleDragOver}
                className="justify-center items-center h-36 mx-auto border w-full px-3 rounded-md border-gray-400 content-center"
              >
                <img
                  src={UploadIcon}
                  className="my-3 mx-auto m-auto"
                  alt="icon"
                />
                <div className="text-center w-full mb-3 m-auto items-center">
                  <div
                    className="mx-auto text-sm text-center  text-hbBlue font-semibold cursor-pointer hover:underline"
                    onClick={handleLogoUpload}
                  >
                    Click to Upload or drag & drop
                  </div>
                  <p className="text-gray-400 font-normal mx-auto">
                    SVG, PNG, JPG or GIF &#40;max: 160x160px&#41;
                  </p>
                </div>
                <input
                  type="file"
                  ref={hiddenLogoInput}
                  onChange={(e) => {
                    // setUploadedLogo(e.target.files);
                    handleUpload(e);
                    setHasEdits(true);
                  }}
                  value=""
                  className="hidden w-full"
                  multiple={false}
                />
              </div>
            ) : (
              <div
                onDrop={handleLogoDrop}
                onDragOver={handleDragOver}
                className="justify-center items-center max-h-44 mx-auto border w-full border-dashed rounded-md border-gray-400"
              >
                <button
                  className="p-1 rounded-full absolute right-0 top-5 z-30 bg-danger transition-opacity"
                  onClick={() => {
                    setshowDeleteModal(true);
                    setHasEdits(true);
                  }}
                >
                  <MdClose className="text-white" />
                </button>

                <img
                  src={logoURL}
                  className="rounded-md p-1 object-cover w-full h-40 m-auto"
                  alt="upload"
                />
              </div>
            )}
          </div>

          <div className=" items-start justify-between w-full py-2 px-2 flex relative">
            <h3 className="pb-2 text-lg font-semibold text-gray-900">
              Builder Contact Details
            </h3>
          </div>

          {/* INPUT BUILDER'S CONTACT NAME */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">
              {isSandbox === true
                ? "Who is your main point of contact at the building company?"
                : "Name"}
            </p>
            <div className="justify-center items-center mx-auto">
              <input
                type="text"
                placeholder="Name"
                className="p-3 border rounded-md border-gray-400 w-full h-11"
                defaultValue={
                  buildInfo?.builderContactName
                    ? buildInfo.builderContactName
                    : ""
                }
                onBlur={(e) => {
                  setHasEdits(true);
                  setBuilderContactName(e.target.value);
                  handleSaveBuilderInfo("Builder Contact Name", e.target.value);
                }}
                style={{ resize: "none" }}
              ></input>
              {isSandbox === true && (
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  This could be a project manager or a representative you'll be
                  in direct contact with.
                </p>
              )}
            </div>
          </div>

          {/* INPUT BUILDER'S CONTACT EMAIL */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">
              {isSandbox === true ? "What's their email address?" : "Email"}
            </p>
            <div className="justify-center items-center mx-auto">
              <div className="flex">
                <input
                  id="builder-email"
                  placeholder="Email"
                  type="text"
                  className={
                    isSandbox === true
                      ? "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto pr-16"
                      : "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto"
                  }
                  defaultValue={
                    buildInfo?.builderContactEmail
                      ? buildInfo.builderContactEmail
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderContactEmail(e.target.value);
                    handleSaveBuilderInfo(
                      "Builder Contact Email",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
                {isSandbox === true && (
                  <button
                    className="bg-white absolute h-11 right-0 w-14 p-1 rounded-r-md border border-gray-400 mr-2 flex items-center align-center"
                    onClick={() => {
                      console.log(
                        document.getElementById("builder-email").value
                      );
                      navigator.clipboard.writeText(
                        document.getElementById("builder-email").value
                      );
                      setBuilderContactCopy("Copy");
                      setBuilderEmailCopy("Copied");
                    }}
                  >
                    {builderEmailCopy === "Copy" && <FaRegCopy size={12} />}
                    <p className="text-xs font-normal font-open-sans ml-1">
                      {builderEmailCopy}
                    </p>
                  </button>
                )}
              </div>
              {isSandbox === true && (
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  This could be a project manager or a representative you'll be
                  in direct contact with.
                </p>
              )}
            </div>
          </div>

          {/* INPUT BUILDER'S CONTACT NUMBER */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">Phone Number</p>
            <div className="justify-center items-center mx-auto">
              <div className="flex">
                <input
                  id="builder-contact"
                  placeholder="Phone Number"
                  type="text"
                  className={
                    isSandbox === true
                      ? "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto pr-16"
                      : "p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto"
                  }
                  defaultValue={
                    buildInfo?.builderContactNumber
                      ? buildInfo.builderContactNumber
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderContactNumber(e.target.value);
                    handleSaveBuilderInfo(
                      "Builder Contact Number",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
                {isSandbox === true && (
                  <button
                    className="bg-white absolute h-11 right-0 w-14 p-1 rounded-r-md border border-gray-400 mr-2 flex items-center align-center"
                    onClick={() => {
                      console.log(
                        document.getElementById("builder-contact").value
                      );
                      navigator.clipboard.writeText(
                        document.getElementById("builder-contact").value
                      );
                      setBuilderContactCopy("Copied");
                      setBuilderEmailCopy("Copy");
                    }}
                  >
                    {builderContactCopy === "Copy" && <FaRegCopy size={12} />}
                    <p className="text-xs font-normal font-open-sans ml-1">
                      {builderContactCopy}
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* INPUT BUILDER'S Address */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">Builder Address</p>
            <div className="justify-center items-center mx-auto">
              <input
                type="text"
                // placeholder="Name"
                className="p-3 border rounded-md border-gray-400 w-full h-11"
                defaultValue={
                  buildInfo?.builderAddress ? buildInfo.builderAddress : ""
                }
                onBlur={(e) => {
                  setHasEdits(true);
                  setBuilderAddress(e.target.value);
                  handleSaveBuilderInfo("Builder Address", e.target.value);
                }}
                style={{ resize: "none" }}
              ></input>
            </div>
          </div>

          {/* INPUT BUILDER'S Margin */}
          <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
            <p className="pb-1  text-gray-900">Builder's Margin</p>
            <div className="justify-center items-center mx-auto">
              <div className="flex">
                <input
                  id="builder-contact"
                  // placeholder="Phone Number"
                  type="text"
                  className="p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto pr-16"
                  defaultValue={
                    buildInfo?.builderMargin ? buildInfo.builderMargin : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderMargin(e.target.value);
                    handleSaveBuilderInfo("Builder's Margin", e.target.value);
                  }}
                  style={{ resize: "none" }}
                ></input>

                <button className="bg-white absolute h-11 right-0 w-14 p-1 rounded-r-md border border-gray-400 mr-2 flex items-center align-center">
                  <p className="text-xs font-normal font-open-sans m-auto">
                    <FaPercent size={14} />
                  </p>
                </button>
              </div>
            </div>
          </div>

          {/* BOTTOM BUTTONS */}
          <div className="p-4 gap-x-2 flex border-t">
            <Button
              className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
              block={"full"}
              onClick={() => {
                // handleSaveInfo(backgroundList[0]?.id);
                // setShowRightPanel(false);
                handleRecordActivity();
              }}
            >
              <span className="h-fit w-fit font-semibold"> Close </span>
            </Button>
          </div>
        </div>
      ) : (
        //mobile builder details
        <div className="z-10">
          <div className="p-3 pt-4 pb-16">
            <div className="flex justify-between pb-3">
              <div className="flex items-center w-full pl-2">
                <img src={buildersIcon} alt="Builder' Icon" className="pb-2" />
                <h3 className="pb-2 text-lg font-semibold text-gray-900 pl-2">
                  Builder Details
                </h3>
              </div>
              <IoCloseOutline
                className="text-2xl text-gray-500"
                onClick={() => setShowRightPanel(false)}
              />
            </div>

            {/* INPUT BUILDER'S NAME */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal">
                Who is your builder?
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  placeholder="Builder's Name"
                  type="text"
                  className="py-2 px-3 border rounded-md border-gray-300 w-full mb-1"
                  defaultValue={
                    buildInfo?.builderName ? buildInfo.builderName : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderName(e.target.value);
                    handleSaveBuilderInfo("Builder Name", e.target.value);
                  }}
                  style={{ resize: "none" }}
                ></input>
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  Please provide the name of the company or individual
                  responsible for construction.
                </p>
              </div>
            </div>

            {/* INPUT BUILDER'S ABN */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal">
                ABN (Australian Business Number)
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  // placeholder="Builder's Name"
                  type="text"
                  className="py-2 px-3 border rounded-md border-gray-300 w-full mb-1"
                  defaultValue={
                    buildInfo?.builderABN ? buildInfo.builderABN : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderABN(e.target.value);
                    handleSaveBuilderInfo(
                      "ABN (Australian Business Number)",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
              </div>
            </div>

            {/* INPUT BUILDER'S License Number */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal">
                Builder's License Number
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  // placeholder="Builder's Name"
                  type="text"
                  className="py-2 px-3 border rounded-md border-gray-300 w-full mb-1"
                  defaultValue={
                    buildInfo?.builderLicenseNumber
                      ? buildInfo.builderLicenseNumber
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderLicenseNumber(e.target.value);
                    handleSaveBuilderInfo(
                      "Builder's License Number",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
              </div>
            </div>

            {/* INPUT LOGO */}
            <div className=" items-start justify-between w-full py-1 px-2 flex flex-col mb-4 relative">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                Logo
              </p>
              {logoURL === null ? (
                <div
                  onDrop={handleLogoDrop}
                  onDragOver={handleDragOver}
                  className="flex justify-center flex-col items-center h-32 mx-auto border w-full px-3 rounded-xl border-gray-300 content-center"
                >
                  <img src={uploadIcon} className="mb-2" alt="icon" />
                  <div className="flex flex-col justify-center text-center items-center w-full">
                    <p className="text-sm text-center font-open-sans">
                      <span
                        className="text-hbBlue font-semibold cursor-pointer hover:underline font-open-sans"
                        onClick={handleLogoUpload}
                      >
                        Click to upload
                      </span>{" "}
                      or drag and drop
                    </p>
                    <p className="text-gray-400 font-normal font-open-sans mx-auto">
                      SVG, PNG, JPG or GIF &#40;max: 160x160px&#41;
                    </p>
                  </div>
                  <input
                    type="file"
                    ref={hiddenLogoInput}
                    onChange={(e) => {
                      // setUploadedLogo(e.target.files);
                      setHasEdits(true);
                      handleUpload(e);
                    }}
                    value=""
                    className="hidden w-full"
                    multiple={false}
                  />
                </div>
              ) : (
                <div
                  onDrop={handleLogoDrop}
                  onDragOver={handleDragOver}
                  className="justify-center items-center h-32 mx-auto border w-full border-dashed rounded-md border-gray-400"
                >
                  <button
                    className="p-1 rounded-full absolute right-0 top-5 z-30 bg-danger transition-opacity"
                    onClick={() => {
                      setHasEdits(true);
                      setshowDeleteModal(true);
                      //handleRecordActivity();
                    }}
                  >
                    <MdClose className="text-white" />
                  </button>

                  <img
                    src={logoURL}
                    className="rounded-md p-1 object-cover w-full h-32 m-auto"
                    alt="upload"
                  />
                </div>
              )}
            </div>

            {/* INPUT BUILDER'S CONTACT NAME */}
            <div className=" items-start justify-between w-full pb-1 pt-4 px-2 mb-2 relative border-t">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                Who is your main point of contact at the building company?
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  type="text"
                  placeholder="Builder Representative's Name"
                  className="py-2 px-3 mb-1 border rounded-md border-gray-300 w-full"
                  defaultValue={
                    buildInfo?.builderContactName
                      ? buildInfo.builderContactName
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderContactName(e.target.value);
                    handleSaveBuilderInfo(
                      "Builder Contact Name",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  This could be a project manager or a representative you'll be
                  in direct contact with.
                </p>
              </div>
            </div>

            {/* INPUT BUILDER'S CONTACT EMAIL */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                What's their email address?
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  placeholder="Email Address"
                  type="text"
                  className="py-2 px-3 mb-1 border rounded-md border-gray-300 w-full"
                  defaultValue={
                    buildInfo?.builderContactEmail
                      ? buildInfo.builderContactEmail
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderContactEmail(e.target.value);
                    handleSaveBuilderInfo(
                      "Builder Contact Email",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
                <p className="text-light text-gray-600 font-open-sans text-justify">
                  This could be a project manager or a representative you'll be
                  in direct contact with.
                </p>
              </div>
            </div>

            {/* INPUT BUILDER'S CONTACT NUMBER */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal text-gray-900">
                What's their phone number?
              </p>
              <div className="justify-center items-center mx-auto">
                <input
                  placeholder="Phone Number"
                  type="number"
                  className="py-2 px-3 mb-1 border rounded-md border-gray-300 w-full"
                  defaultValue={
                    buildInfo?.builderContactNumber
                      ? buildInfo.builderContactNumber
                      : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderContactNumber(e.target.value);
                    handleSaveBuilderInfo(
                      "Builder Contact Number",
                      e.target.value
                    );
                  }}
                  style={{ resize: "none" }}
                ></input>
              </div>
            </div>

            {/* INPUT BUILDER Address */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1 font-open-sans font-normal">Builder Address</p>
              <div className="justify-center items-center mx-auto">
                <input
                  // placeholder="Builder's Name"
                  type="text"
                  className="py-2 px-3 border rounded-md border-gray-300 w-full mb-1"
                  defaultValue={
                    buildInfo?.builderAddress ? buildInfo.builderAddress : ""
                  }
                  onBlur={(e) => {
                    setHasEdits(true);
                    setBuilderAddress(e.target.value);
                    handleSaveBuilderInfo("Builder Address", e.target.value);
                  }}
                  style={{ resize: "none" }}
                ></input>
              </div>
            </div>

            {/* INPUT BUILDER's Margin */}
            <div className=" items-start justify-between w-full py-1 px-2 mb-2 relative">
              <p className="pb-1  text-gray-900">Builder's Margin</p>
              <div className="justify-center items-center mx-auto">
                <div className="flex">
                  <input
                    id="builder-contact"
                    // placeholder="Phone Number"
                    type="text"
                    className="p-3 border rounded-md border-gray-400 w-full h-11 overflow-x-auto pr-16"
                    defaultValue={
                      buildInfo?.builderMargin ? buildInfo.builderMargin : ""
                    }
                    onBlur={(e) => {
                      setHasEdits(true);
                      setBuilderMargin(e.target.value);
                      handleSaveBuilderInfo("Builder's Margin", e.target.value);
                    }}
                    style={{ resize: "none" }}
                  ></input>

                  <button className="bg-white absolute h-11 right-0 w-14 p-1 rounded-r-md border border-gray-400 mr-2 flex items-center align-center">
                    <p className="text-xs font-normal font-open-sans m-auto">
                      <FaPercent size={14} />
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* BOTTOM BUTTONS */}
          <div className="fixed p-4 px-6 gap-x-4 flex bottom-0 border-t w-full justify-center bg-white">
            <Button
              className="py-2 cursor-pointer bg-white border-black border-2 text-hbBlue hover:text-white shadow-none"
              block={"full"}
              onClick={() => {
                // handleSaveInfo(backgroundList[0]?.id);
                // setShowRightPanel(false);
                handleRecordActivity();
              }}
            >
              <span className="h-fit w-fit font-semibold"> Close </span>
            </Button>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <DeleteMatterModal
          handleSave={handleDeleteModal}
          handleModalClose={handleModalClose}
        />
      )}

      {showToast ? (
        <ToastNotification
          title={resultMessage}
          error={
            resultMessage === "Details successfully updated" ? false : true
          }
        />
      ) : null}
    </>
  );
}
