import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import loadingAnimation from "../../assets/images/post-authentication-loading.gif";

export default function PostRegistration() {
  const [error, setError] = useState(null);
  const { width } = useWindowDimensions();

  const getDefaults = `query getDefaults {
    defaultUserType
    pages {
      id
      name
      features {
        id
        name
      }
    }
  }`;

  const mCreateCompany = `mutation createCompany($name: String, $representative: RepresentativeInput){
    companyCreate(
      name: $name
      representative: $representative
    ) {
      id
      name
    }
  }`;

  const mCreateCompanyAccessType = `mutation createCompanyAccessType($companyId: String, $userType: [UserType], $access: [AccessInput]){
    companyAccessTypeCreate(
      companyId: $companyId
      userType: $userType
      access: $access
    ) {
      id
    }
  }`;

  const mCreateUser = `mutation createUser($id: ID!, $email: AWSEmail, $firstName: String, $lastName: String, $userType: UserType, $company: CompanyInput, $businessName: String, $hasPortalAccess: Boolean){
    userCreate(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      userType: $userType
      company: $company
      businessName: $businessName
      hasPortalAccess: $hasPortalAccess
    ) {
      id
    }
  }`;

  const mCreateAppSubscription = `mutation createAppSubscription($companyId: ID, $registeredUserId: ID, $email: AWSEmail, $remainingTrialDays: Int, $tier: Tier, $registrationSourceCategory: String, $registrationSource: String, $registrationSourceWebsite: String) {
    appSubscriptionCreate(companyId: $companyId, registeredUserId: $registeredUserId, email: $email, remainingTrialDays: $remainingTrialDays, tier: $tier, registrationSourceCategory: $registrationSourceCategory, registrationSource: $registrationSource, registrationSourceWebsite: $registrationSourceWebsite){
      id
    }
  }`;

  let history = useHistory();

  let nextStep = "onboarding";
  let nextQueryVariable = `?next=${encodeURIComponent(nextStep)}`;

  // Get the query parameters from the Registration URL
  const params = new URLSearchParams(window.location.search);
  const srcCategory = params.get("src");
  const srcArticle = params.get("article");
  const srcWebsite = params.get("website");

  // Decode URI components
  const decodedSrcCategory = srcCategory
    ? decodeURIComponent(srcCategory)
    : null;
  const decodedSrcArticle = srcArticle ? decodeURIComponent(srcArticle) : null;
  const decodedSrcWebsite = srcWebsite ? decodeURIComponent(srcWebsite) : null;

  console.log("Decoded parameters:");
  console.log("src:", decodedSrcCategory);
  console.log("article:", decodedSrcArticle);
  console.log("website:", decodedSrcWebsite);

  let src = decodedSrcCategory;
  let article = decodedSrcArticle;
  let website = decodedSrcWebsite;

  useEffect(() => {
    let getUser = async () => {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then((cognitoUserInfo) => {
          setAccountDetails(cognitoUserInfo);
        });
      } catch (e) {
        setError(e);
      }
    };
    getUser();
  });

  const setAccountDetails = async (cognitoUserInfo) => {
    const dafaults = await API.graphql({
      query: getDefaults,
    });

    const pages = dafaults.data.pages;
    const usertypes = dafaults.data.defaultUserType;

    const company = {
      name: cognitoUserInfo.attributes["custom:company_name"],
      representative: {
        id: cognitoUserInfo.attributes["sub"],
        firstName: cognitoUserInfo.attributes["given_name"],
        lastName: cognitoUserInfo.attributes["family_name"],
      },
    };

    const user = {
      id: cognitoUserInfo.attributes["sub"],
      firstName: cognitoUserInfo.attributes["given_name"],
      lastName: cognitoUserInfo.attributes["family_name"],
      email: cognitoUserInfo.attributes["email"],
      company: company,
      userType: "OWNER",
      access: pages,
      businessName: "",
      hasPortalAccess: true,
    };

    createAccount(company, pages, usertypes, user);
  };

  async function createAccount(company, pageAccess, usertypes, user) {
    try {
      // Create company and ensure it's successful before proceeding.
      const companyResponse = await createCompany(company);
      const companyId = companyResponse.data.companyCreate.id;

      // No need to mutate the original user object.
      const newUser = { ...user, company: companyResponse.data.companyCreate };

      // Handle company access type creation with no dependency on its result.
      createCompanyAccessType(companyId, pageAccess, usertypes).catch(
        (error) => {
          console.error("Failed to create company access type:", error);
        }
      );

      // Create the user.
      await createUser(newUser);
      await createAppSubscription(companyId, newUser.id, newUser.email);

      // Navigate to next page after user creation.
      history.push(`${AppRoutes.POSTAUTHENTICATION}${nextQueryVariable}`);
    } catch (error) {
      // Handle any errors that occurred during company or user creation.
      setError(error.errors[0].message);
    }
  }

  function createCompany(company) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mCreateCompany,
          variables: company,
        });

        resolve(request);
      } catch (e) {
        setError(e.errors[0].message);
        reject(e.errors[0].message);
      }
    });
  }

  async function createCompanyAccessType(companyId, pageAccess, usertypes) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mCreateCompanyAccessType,
          variables: {
            companyId: companyId,
            access: pageAccess,
            userType: usertypes,
          },
        });

        resolve(request);
      } catch (e) {
        setError(e.errors[0].message);
        reject(e.errors[0].message);
      }
    });
  }

  async function createUser(user) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mCreateUser,
          variables: user,
        });

        resolve(request);
      } catch (e) {
        setError(e.errors[0].message);
        reject(e.errors[0].message);
      }
    });
  }

  async function createAppSubscription(companyId, userId, email) {
    return new Promise((resolve, reject) => {
      try {
        const request = API.graphql({
          query: mCreateAppSubscription,
          variables: {
            companyId: companyId,
            registeredUserId: userId,
            email: email,
            remainingTrialDays: 14,
            tier: "TRIAL",
            registrationSourceCategory: src,
            registrationSource: article,
            registrationSourceWebsite: website,
          },
        });

        resolve(request);
      } catch (e) {
        setError(e.errors[0].message);
        reject(e.errors[0].message);
      }
    });
  }

  return (
    <>
      {/* {!redirectToOnboarding && ( */}
      <div className={`flex justify-center items-center h-screen`}>
        <div className="relative text-center">
          <img
            src={loadingAnimation}
            style={{ height: isMobileDevice(width) ? "20rem" : "80vh" }}
            className={`m-auto`}
            alt="We're loading your Dashboard ..."
          />
          {/* <p
              className={`text-black font-open-sans text-xl font-semibold ${
                isMobileDevice(width) ? "text-lg" : "text-3xl"
              }`}
            >
              We're loading your Dashboard ...
            </p> */}
        </div>
      </div>
      {/* )} */}
      {error && <p>Oops! Something went wrong. {error}</p>}
    </>
  );
}
