//Libraries
import { useContext, useEffect, useState, useRef } from 'react'

//Local Hooks, Functions, Reducers
import StoreProvider from './reducers/StoreProvider'
import { FormDetailsContext } from "./reducers/StoreProvider"

export default function FormOverview({key}){

  const topNavRef = useRef(null)
  const [currentTopNavHeight, setCurrentTopNavHeight] = useState(60)

  useEffect(() => {
    const topNavHeight = topNavRef?.current?.clientHeight;
    setCurrentTopNavHeight(topNavHeight)
  }, [topNavRef]);

  const handleFormLink = () => {
    console.log('Navigate to ', key)
  }

  return(
    <StoreProvider>
      <div
        className="w-full h-screen max-h-screen flex flex-col bg-neutral-100 overflow-x-hidden"
        style={{
          margin:"0 0 0 76px"
        }}
      >
        <TopNav topNavRef={topNavRef}/>
        <div
          className='flex-1 w-full p-16 flex gap-5'
          style={{
            maxHeight: `calc(100vh - ${currentTopNavHeight}px)`
          }}
        >
          {/* <Categories /> */}
          <div
            className='flex-1 flex flex-col gap-3'
          >
            <ActionBar />
            <FormsContainer />

          </div>

        </div>
      </div>
    </StoreProvider>

  )
}

function TopNav({topNavRef}){


  return(
    <div
      ref={topNavRef}
      className="sticky z-30 top-0 w-full py-4 px-4 flex justify-between bg-white"
    >
      <div
        className="flex items-center gap-3"
      >
        {/* <FaWpforms/> */}
        <p
          className="text-xl font-medium"
        >
          Forms
        </p>
      </div>

    </div>
  )
}

function ActionBar(){

  return(
    <div
      className='w-full flex justify-between '
    >

    </div>
  )
  }
  
function FormsContainer(){
  const {state, dispatch} = useContext(FormDetailsContext)
  

  return(
    <div
      className='flex-1 flex flex-col gap-2 overflow-y-auto'
    >

    </div>
  )
}