import React, { useEffect, useRef, useState } from "react";
import UploadImage from "../../assets/images/upload-photo.svg";
import sample1 from "../../assets/images/progress-photo-examples/sample-1.jpg";
import sample2 from "../../assets/images/progress-photo-examples/sample-2.jpg";
import sample3 from "../../assets/images/progress-photo-examples/sample-3.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper-bundle";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import Button from "../../shared/Button";
import { GrCircleInformation } from "react-icons/gr";
import RightPanel from "./right-panel.jsx";
import ReactTooltip from "react-tooltip";
import { Storage } from "aws-amplify";
import { API, Auth } from "aws-amplify";
import {
  datePickerDateFormatter,
  timezoneFormatter,
} from "../../shared/userPreferredDateTime";
import momentTZ from "moment-timezone";
import uploadIcon from "../../assets/images/upload-icon.png";
import {
  useWindowDimensions,
  isMobileDevice,
} from "../../shared/mobileViewFunctions";
import { HiOutlinePlusSm } from "react-icons/hi";
import { GoArrowLeft } from "react-icons/go";
import { set } from "react-hook-form";
import { FiEdit } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { IoSaveSharp } from "react-icons/io5";
import { IoMove } from "react-icons/io5";

SwiperCore.use([Pagination]);

export default function ProgressPhotos({
  handleUploadLink,
  handleProgressPhotoUpload,
  progressPhotoBrief,
  modalChoicesEnum,
  matterId,
  setModalState,
  setShowRightPanel,
  uploadImage,
  setUploadImage,
  imageURL,
  setImageURL,
  datePicked,
  setDatePicked,
  description,
  setDescription,
  uploadedFiles,
  setUploadedFiles,
  invalidFiles,
  setInvalidFiles,
  selectedFiles,
  _setSelectedFiles,
  uploadStart,
  setUploadStart,
  photos,
  setPhotos,
  handleChangeGetStartedProgress,
  isImageFile,
  progressPhotosDetails,
  redirectUrl,
  handleUpload,
  handleUploadSample,
  rejectFiles,
  saveButtonClicked,
  clientMatterId,
  activePhase,
}) {
  const [viewExample, setViewExample] = useState(initUseExample());
  // const [useExample, setUseExample] = useState(initUseExample());
  const [useExample, setUseExample] = useState(false);
  const [photoDescription, setPhotoDescription] = useState("");
  const [progressPhotosItems, setProgressPhotosItems] = useState([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [formattedDate, setFormattedDate] = useState("");
  const [progressPhotoId, setProgressPhotoId] = useState();

  // Generates a random unique id
  function guidGenerator(digits) {
    let id = "";
    for (let i = 0; i < digits; i++) {
      id += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return id;
  }

  // MMA - 2386: Trigger rerender by setting new ID everytime
  // active phase changes to prevent render bug swiper
  useEffect(() => {
    setProgressPhotoId(guidGenerator(10));
  }, [activePhase]);

  const qGetBackgroundFilesbyBriefID = `
	query getBackgroundFilesByBriefID($limit: Int, $nextToken: String, $id: ID, $sortOrder: OrderBy) {
		brief(id: $id) {
      id
      name
      backgrounds(limit: $limit, nextToken: $nextToken, sortOrder: $sortOrder) {
        items {
          id
          description
          date
          createdAt
          order
          files {
            items {
              id
              name
              details
              isGmailPDF
              isGmailAttachment
              s3ObjectKey
              order
              createdAt
              downloadURL
            }
          }
        }
        nextToken
      }
    }
	}`;

  const mUpdateMatterFileDesc = `
  mutation updateMatterFile ($id: ID, $details: String) {
    matterFileUpdate(id: $id, details: $details) {
      id
      details
    }
  }
`;

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    // Set initial photo description on mount
    if (photos?.length > 0 && progressPhotosItems?.length > 0) {
      const transformedBackgroundPhotos = photos.flatMap((item) => {
        return item?.files?.items.map((fileItem) => ({
          ...item,
          file: fileItem,
        }));
      });

      transformedBackgroundPhotos.sort((a, b) => a.order - b.order);
      const currentPhotoId = transformedBackgroundPhotos[currentPhotoIndex]?.id;

      // Find the item with the same id in progressPhotosItems
      const currentItem = photos?.find((item) => item.id === currentPhotoId);

      // Set the description based on the current item
      setPhotoDescription(currentItem?.description || "");
      setDatePicked(currentItem?.date);
    }
  }, [currentPhotoIndex, progressPhotosItems, photos]);

  useEffect(() => {
    const items = progressPhotosDetails?.backgrounds?.items;
    setProgressPhotosItems(items);
    console.log("ProgressPhotoItems are: ", items);
  }, [progressPhotosDetails]);

  const [formattedHour, setFormattedHour] = useState("");
  const [formattedMinute, setFormattedMinute] = useState("");

  useEffect(() => {
    if (datePicked) {
      const userPreferredDateFormatType = localStorage.getItem(
        "userpreferredDateFormatType"
      );

      const preferredTimezone = localStorage.getItem("preferredTimezone");

      var format = datePickerDateFormatter(userPreferredDateFormatType);
      const timezoneToUse =
        preferredTimezone === "null" ? momentTZ.tz.guess() : preferredTimezone;

      const formattedDatePicked = timezoneFormatter(datePicked, timezoneToUse);
      const date = new Date(formattedDatePicked);

      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      let month = date.getMonth();

      if (format) {
        format = format
          .replace(
            "MMM",
            format.includes("MMM")
              ? date.toLocaleString("en-US", { month: "short" })
              : String(month + 1).padStart(2, "0")
          )
          .replace(
            "MM",
            format.includes("MM")
              ? String(month + 1).padStart(2, "0")
              : date.toLocaleString("en-US", { month: "short" })
          )
          .replace("dd", String(date.getDate()).padStart(2, "0"))
          .replace("yyyy", date.getFullYear());
      }

      setFormattedDate(format);
      setFormattedHour(date.getHours());
      if (date.getMinutes() == 0) {
        setFormattedMinute("00");
      } else if (date.getMinutes() < 10) {
        setFormattedMinute("0" + date.getMinutes());
      } else {
        setFormattedMinute(date.getMinutes());
      }
    }
  }, [datePicked]);

  useEffect(() => {
    console.log("Current items are:", progressPhotosItems);
  }, [progressPhotosItems]);

  useEffect(() => {
    // Find the item with the same id in progressPhotosItems
    const currentItem = progressPhotosItems?.find(
      (item) => item.id === photos[currentPhotoIndex]?.id
    );

    // Set the description based on the current item
    // setPhotoDescription(currentItem?.description || "");
  }, [currentPhotoIndex, progressPhotosItems, photos]);

  function handleViewExample() {
    if (viewExample === false) {
      setViewExample(true);
    } else setViewExample(false);
  }

  //* I added this function to make the description cutoff depending on the length of the description

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const file = files[0];
      if (!isImageFile(file.name)) {
        alert(
          `File ${file.name} is not an image. Only images can be uploaded.`
        );
        setImageURL(null); // Set image URL back to null
        return;
      }

      setShowRightPanel(true);
      setModalState(modalChoicesEnum.PROGRESS_PHOTO);
      console.log("Images uploaded are " + file.name);
      setImageURL(URL.createObjectURL(file));
      setUploadImage(files);
    }
  };

  const history = useHistory();

  function visitBrief() {
    history.push(redirectUrl);
  }

  function setLocalUseExample() {
    let propertyPhoto = localStorage.getItem("propertyPhoto");
    let companyId = localStorage.getItem("companyId");
    var arr = propertyPhoto ? JSON.parse(propertyPhoto) : [];
    arr.push(companyId);
    localStorage.setItem("propertyPhoto", JSON.stringify(arr));
  }

  function initUseExample() {
    let propertyPhoto = localStorage.getItem("propertyPhoto");
    let companyId = localStorage.getItem("companyId");
    try {
      if (propertyPhoto) {
        let parsed = JSON.parse(propertyPhoto);
        return parsed.includes(companyId);
      }
    } catch (e) {
      console.error(e);
      localStorage.removeItem("propertyPhoto");
      return false;
    }
    return false;
  }
  const [editPhotoPos, setEditPhotoPos] = useState(null);

  const handleSavePosition = async (x, y) => {
    document.body.style.cursor = "wait";

    const res = await API.graphql({
      query: mUpdateMatterFileDesc,
      variables: {
        id: editPhotoPos.id,
        details: `${x}% ${y}%`,
      },
    });

    const response = await API.graphql({
      query: qGetBackgroundFilesbyBriefID,
      variables: {
        id: progressPhotoBrief.id,
        limit: null,
        nextToken: null,
        sortOrder: "DATE_DESC",
      },
    });

    let brief = response.data;
    let retrievedRow = brief.brief.backgrounds?.items;

    // Filter the retrievedRow array to only contain rows with images
    if (retrievedRow.length > 0) {
      const filteredItems = retrievedRow
        .filter((item) => {
          // Check if at least one file in the files array is an image
          return item.files.items.some((file) => isImageFile(file.s3ObjectKey));
        })
        .sort((a, b) => a.order - b.order);

      setPhotos(filteredItems);
    }
    setEditPhotoPos(null);
    document.body.style.cursor = "default";
  };

  function parseImagePosition(objectPosition) {
    if (!objectPosition || objectPosition === "") {
      return null;
    }
    const arr = objectPosition.split(" ");
    const parsedArr = arr.map((pos) => parseInt(pos));
    return parsedArr.some((pos) => isNaN(pos)) ? null : parsedArr;
  }

  const convertToImageFile = async (staticFile, name) => {
    // Fetch the static file using the URL
    const response = await fetch(staticFile);
    // Convert the response to a blob
    const blob = await response.blob();
    // Create a File object from the blob
    const file = new File([blob], `${name}.png`, { type: "image/png" });
    return file;
  };

  async function handleUseExamplev2() {
    const sample1File = await convertToImageFile(sample1, "Sample Progress 1");
    const sample2File = await convertToImageFile(sample2, "Sample Progress 2");
    const sample3File = await convertToImageFile(sample3, "Sample Progress 3");

    handleUploadDummyImage([sample1File, sample2File, sample3File]);
    handleUploadSample(clientMatterId);
  }

  const handleUploadDummyImage = (files) => {
    if (!files || !files.length) return;

    const tempArr = [];

    for (let file of files) {
      console.log("RETRIEVED FILE", file);
      if (!file.type.includes("image")) {
        window.alert("Please input an image file");
        return;
      }

      let objUrl = URL.createObjectURL(file);

      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file.name)[0];

      const result = rejectFiles.find((item) =>
        item.includes(re.exec(file.name)[0])
      );
      const fileSize = file.size;

      if (result || fileSize > 2147483648) {
        invalidFiles.push({
          data: file,
          url: objUrl,
        });
      } else {
        tempArr.push({
          data: file,
          url: objUrl,
        });
      }
    }

    selectedFiles.current = [...selectedFiles.current, ...tempArr];
    console.log("selectedFiles", selectedFiles.current);
  };

  return (
    <>
      {!isMobileDevice(width) ? (
        viewExample === false && photos.length === 0 ? (
          <div
            className="overflow-hidden bg-gray-200 border flex rounded-2xl font-open-sans w-auto border-1 h-96"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <div className="m-auto text-center">
              <img src={UploadImage} className="mx-auto mb-5" alt="upload" />
              <p className="font-semibold font-open-sans">
                Ready to add your first photo?
              </p>
              <p className="font-semibold text-xs font-open-sans">
                Drag photos or videos anywhere to upload
              </p>
              <div className="flex mt-3 font-open-sans">
                <div className="mx-2 font-open-sans">
                  <Button
                    className="py-2 cursor-pointer bg-hbLightGray border border-hbLightGray text-hbBlue shadow-none hover:text-white font-open-sans"
                    block={"full"}
                    onClick={handleViewExample}
                  >
                    <span className="h-fit w-36 font-semibold font-open-sans">
                      {" "}
                      View Example{" "}
                    </span>
                  </Button>
                </div>
                <div className="mx-2">
                  <Button
                    variant="hbBlue"
                    className="py-2 cursor-pointer shadow-none font-open-sans"
                    block={"full"}
                    onClick={() => {
                      setModalState(modalChoicesEnum.PROGRESS_PHOTO);
                      setShowRightPanel(true);
                    }}
                  >
                    <span className="w-36 font-semibold font-open-sans">
                      {" "}
                      Add{" "}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          //VIEW EXAMPLE CONTENTS
          <>
            <div className="relative overflow-hidden bg-white font-open-sans rounded-2xl border border-1 h-96">
              {editPhotoPos ? (
                <AdjustableImage
                  img={editPhotoPos?.downloadURL}
                  handleSavePosition={handleSavePosition}
                  position={parseImagePosition(editPhotoPos?.details)}
                  className="w-full h-64 relative cursor-move"
                />
              ) : (
                <Swiper
                  key={progressPhotoId}
                  modules={[Pagination]}
                  slidesPerView={"auto"}
                  pagination={{ clickable: true }}
                  style={{
                    "--swiper-pagination-color": "#133352",
                  }}
                  onSlideChange={(swiper) =>
                    setCurrentPhotoIndex(swiper.activeIndex)
                  }
                  initialSlide={currentPhotoIndex} // Set initial slide to 0 index
                >
                  {photos?.length === 0 ? (
                    <div className="h-full items-center justify-center font-open-sans">
                      <SwiperSlide>
                        <img
                          src={sample1}
                          alt="img"
                          className="object-cover rounded-[30px] w-full h-64"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={sample2}
                          alt="img"
                          className="object-cover rounded-[30px] w-full h-64"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={sample3}
                          alt="img"
                          className="object-cover rounded-[30px] w-full h-64"
                        />
                      </SwiperSlide>
                    </div>
                  ) : (
                    <div className="h-full items-center justify-center">
                      {photos
                        // .sort((a, b) => b.order - a.order)
                        .map((background, index) => (
                          <>
                            {background.files.items.map((item, pIndex) => {
                              if (
                                isImageFile(
                                  background.files.items[pIndex]?.s3ObjectKey
                                )
                              ) {
                                return (
                                  <SwiperSlide
                                    key={`${index}_${background?.files?.items[pIndex]?.id}`}
                                  >
                                    <img
                                      //brief.backgrounds.items[x].files.items[x]
                                      src={
                                        background.files.items[pIndex]
                                          ?.downloadURL
                                      }
                                      alt="img"
                                      style={{
                                        objectPosition:
                                          background.files.items[pIndex]
                                            ?.details,
                                      }}
                                      className="object-cover rounded-[30px] w-full h-64"
                                    />
                                    <div
                                      className="absolute top-4 right-4 rounded-full cursor-pointer"
                                      onClick={(e) => {
                                        setEditPhotoPos(
                                          background.files.items[pIndex]
                                        );
                                      }}
                                    >
                                      <IoMove
                                        className="absolute top-2 right-2 text-hbBlue hover:cursor-pointer hover:text-white text-3xl"
                                        style={{
                                          transform: "translate(50%, -50%)",
                                        }}
                                      />
                                    </div>
                                  </SwiperSlide>
                                );
                              }
                            })}
                          </>
                        ))}
                    </div>
                  )}
                </Swiper>
              )}
              <div className="bg-hbDirtyWhite w-full flex py-3 font-open-sans z-50">
                <div className="m-2">
                  <p className="text-lg ml-2 mb-2 font-semibold font-open-sans">
                    Progress Photos
                  </p>

                  <p className="text-sm ml-2 mb-2 font-semibold font-open-sans">
                    {truncateText(photoDescription, 100)}
                    {photoDescription === "" &&
                      "Add a description to your progress photo"}
                    {photoDescription.trim() === "Enter some text..." &&
                      setPhotoDescription(
                        "Add a description to your progress photo"
                      )}
                  </p>
                  <p className="text-sm ml-2 mb-2 font-open-sans">
                    {datePicked !== null ? formattedDate : "No Date"}
                  </p>
                </div>
                <div className="mt-3">
                  <GrCircleInformation
                    className="font-open-sans"
                    data-tip="<b>Progress Photos</b><br/>View the latest photos to see the real-time progress of <br/>your home construction. Monitor the developments as <br/>your dream home comes to life."
                  />
                  <ReactTooltip
                    type="dark"
                    place="right"
                    html={true}
                    scrollHide
                    effect="solid"
                  />
                </div>
                {useExample === false && photos.length === 0 ? (
                  <div className="ml-auto right-0 mt-14 mx-3 flex font-open-sans">
                    <div className="mx-2">
                      <Button
                        className="py-2 cursor-pointer bg-hbLightGray border border-hbLightGray text-hbBlue hover:text-white shadow-none font-open-sans"
                        block={"full"}
                        onClick={handleViewExample}
                      >
                        <span className="h-fit w-36 font-semibold"> Back </span>
                      </Button>
                    </div>
                    <div className="mx-2">
                      <Button
                        variant="hbBlue"
                        className="py-2 cursor-pointer shadow-none font-open-sans"
                        block={"full"}
                        onClick={() => {
                          // setUseExample(true);
                          // setLocalUseExample();
                          handleUseExamplev2();
                          // handleChangeGetStartedProgress("propertyPhoto");
                        }}
                        disabled={saveButtonClicked}
                      >
                        <span className="w-36 font-semibold">
                          {" "}
                          Use Example{" "}
                        </span>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="ml-auto right-0 mt-14 mx-3 flex font-open-sans h-8">
                    <div
                      className="p-2 pb-6 rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
                      onClick={visitBrief}
                    >
                      <FiEdit className="w-4 h-4" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )
      ) : (
        //mobile progress photos
        <div className="overflow-hidden">
          {viewExample === false && photos.length === 0 ? (
            <div
              className="overflow-hidden flex flex-col justify-center items-center rounded-2xl font-open-sans w-auto border-1 h-80 bg-gray-50"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              {/* CIRCLE DESIGN IN UPLOAD ICON */}
              <div className="relative">
                <div
                  className="absolute top-5 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(234,236,240, 0.75)",
                  }}
                ></div>
                <div
                  className="absolute top-5 transform -translate-x-1/2 -translate-y-1/2 w-40 h-40 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(234,236,240, 0.75)",
                  }}
                ></div>
                <div
                  className="absolute top-5 transform -translate-x-1/2 -translate-y-1/2 w-56 h-56 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(234,236,240, 0.60)",
                  }}
                ></div>
                <div
                  className="absolute top-5 transform -translate-x-1/2 -translate-y-1/2 w-72 h-72 bg-transparent rounded-full border border-1"
                  style={{
                    borderColor: "rgb(234,236,240, 0.50)",
                  }}
                ></div>
              </div>
              <div className="flex flex-col justify-center items-center z-40 relative">
                <img src={uploadIcon} className="mb-2" alt="icon" />
                <p className="font-semibold font-open-sans">
                  Ready to add your first photo?
                </p>
                <p className="font-normal text-xs font-open-sans">
                  Drag photos or videos anywhere to upload
                </p>
              </div>
              <div className="flex font-open-sans gap-x-4 justify-center pt-6 z-40 relative">
                <Button
                  className="px-3 py-2 cursor-pointer border-hbLightGray text-hbBlue shadow-none hover:text-white font-open-sans font-medium rounded-md"
                  block={true}
                  onClick={handleViewExample}
                >
                  View Example
                </Button>
                <Button
                  variant="hbBlue"
                  className="px-3 py-2 cursor-pointer shadow-none rounded-md"
                  block={true}
                  onClick={() => {
                    visitBrief();
                    // setModalState(modalChoicesEnum.PROGRESS_PHOTO);
                    // setShowRightPanel(true);
                  }}
                >
                  <HiOutlinePlusSm />
                  <span className="font-open-sans font-medium">Add</span>
                </Button>
              </div>
            </div>
          ) : (
            //VIEW EXAMPLE CONTENTS
            <div className="overflow-hidden flex flex-col justify-center items-center rounded-2xl font-open-sans w-full border-1 h-80 bg-gray-50">
              <div className="relative overflow-hidden bg-white font-open-sans rounded-2xl h-80 w-full">
                {editPhotoPos ? (
                  <AdjustableImage
                    img={editPhotoPos?.downloadURL}
                    handleSavePosition={handleSavePosition}
                    position={parseImagePosition(editPhotoPos?.details)}
                    className="w-full h-56 relative cursor-move"
                  />
                ) : (
                  <Swiper
                    key={progressPhotoId}
                    modules={[Pagination]}
                    slidesPerView={"auto"}
                    pagination={{ clickable: true }}
                    style={{
                      "--swiper-pagination-color": "#133352",
                    }}
                    onSlideChange={(swiper) =>
                      setCurrentPhotoIndex(swiper.activeIndex)
                    }
                    initialSlide={currentPhotoIndex} // Set initial slide to 0 index
                  >
                    {photos?.length === 0 ? (
                      <div className="h-56 items-center justify-center font-open-sans ">
                        <SwiperSlide>
                          <img
                            src={sample1}
                            alt="img"
                            className="object-cover rounded-[30px] w-full h-56 "
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={sample2}
                            alt="img"
                            className="object-cover rounded-[30px] w-full h-56 "
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            src={sample3}
                            alt="img"
                            className="object-cover rounded-[30px] w-full h-56 "
                          />
                        </SwiperSlide>
                      </div>
                    ) : (
                      <div className="h-56 items-center justify-center w-full">
                        {photos
                          // .sort((a, b) => b.order - a.order)
                          .map((background, index) => (
                            <>
                              {background.files.items.map((item, pIndex) => {
                                if (
                                  isImageFile(
                                    background.files.items[pIndex]?.s3ObjectKey
                                  )
                                ) {
                                  return (
                                    <SwiperSlide
                                      key={`${index}_${background?.files?.items[pIndex]?.id}`}
                                    >
                                      <img
                                        //brief.backgrounds.items[x].files.items[x]
                                        src={
                                          background.files.items[pIndex]
                                            ?.downloadURL
                                        }
                                        alt="img"
                                        style={{
                                          objectPosition:
                                            background.files.items[pIndex]
                                              ?.details,
                                        }}
                                        className="object-cover rounded-[30px] w-full h-56"
                                      />
                                      <div
                                        className="absolute top-4 right-4 rounded-full cursor-pointer"
                                        onClick={(e) => {
                                          setEditPhotoPos(
                                            background.files.items[pIndex]
                                          );
                                        }}
                                      >
                                        <IoMove
                                          className="absolute top-2 right-2 text-hbBlue hover:cursor-pointer hover:text-white text-3xl"
                                          style={{
                                            transform: "translate(50%, -50%)",
                                          }}
                                        />
                                      </div>
                                    </SwiperSlide>
                                  );
                                }
                              })}
                            </>
                          ))}
                      </div>
                    )}
                  </Swiper>
                )}
                <div className="bg-hbDirtyWhite w-full py-2 font-open-sans h-full">
                  <div className="m-2 ml-4 mb-2 w-full">
                    <div className="flex flex-row items-center">
                      <span className="text-base font-semibold font-open-sans mr-2">
                        Progress Photos
                      </span>
                      <GrCircleInformation />
                    </div>
                  </div>
                  {useExample === false && photos.length === 0 ? (
                    <div className="flex flex-row px-4 w-full h-8">
                      <div className="w-3/5 flex flex-col justify-between">
                        {/* {truncateText(photoDescription, 100)} */}
                        {photoDescription === "" && (
                          <>
                            <p className="text-xs font-semibold font-open-sans mb-1">
                              Completion of Slab
                            </p>
                            <p className="text-xs font-normal font-open-sans">
                              {datePicked !== null
                                ? formattedDate +
                                  " at " +
                                  (formattedHour > 12
                                    ? formattedHour - 12
                                    : formattedHour) +
                                  ":" +
                                  formattedMinute +
                                  (formattedHour >= 12 ? " PM" : " AM")
                                : "No Date"}
                            </p>
                          </>
                        )}
                        {/* {photoDescription.trim() === "Enter some text..." && setPhotoDescription("Add a description to your progress photo")} */}
                      </div>
                      <div className="ml-auto flex font-open-sans gap-x-2">
                        <Button
                          className="py-2 px-2 cursor-pointer border border-hbLightGray text-hbBlue hover:text-gray-700 shadow-none text-xs"
                          block={true}
                          onClick={handleViewExample}
                        >
                          <GoArrowLeft />
                        </Button>
                        <Button
                          variant="hbBlue"
                          className="py-2 px-2 w-28 cursor-pointer shadow-none font-open-sans text-xs"
                          block={true}
                          onClick={() => {
                            // setUseExample(true);
                            // setLocalUseExample();
                            // handleChangeGetStartedProgress("propertyPhoto");
                            handleUseExamplev2();
                          }}
                          disabled={saveButtonClicked}
                        >
                          Use Example
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row px-4 w-full h-8">
                      <div className="w-full flex flex-row justify-between">
                        {/* {truncateText(photoDescription, 100)} */}
                        {photoDescription === "" ? (
                          <div className="flex flex-col w-3/5">
                            <p className="text-xs font-semibold font-open-sans mb-1">
                              Completion of Slab
                            </p>
                            <p className="text-xs font-normal font-open-sans">
                              {datePicked !== null
                                ? formattedDate +
                                  " at " +
                                  (formattedHour > 12
                                    ? formattedHour - 12
                                    : formattedHour) +
                                  ":" +
                                  formattedMinute +
                                  (formattedHour >= 12 ? " PM" : " AM")
                                : "No Date"}
                            </p>
                          </div>
                        ) : (
                          <div className="flex flex-col w-3/5">
                            <p className="text-xs font-semibold font-open-sans mb-1">
                              {truncateText(photoDescription, 40)}
                            </p>
                            <p className="text-xs font-normal font-open-sans">
                              {datePicked !== null
                                ? formattedDate +
                                  " at " +
                                  (formattedHour > 12
                                    ? formattedHour - 12
                                    : formattedHour) +
                                  ":" +
                                  formattedMinute +
                                  (formattedHour >= 12 ? " PM" : " AM")
                                : "No Date"}
                            </p>
                          </div>
                        )}
                        <Button
                          className="mr-4 shadow-none px-2 py-2 items-center rounded-lg border bg-white border-gray-300 hover:bg-gray-100 cursor-pointer hover:text-gray-500"
                          onClick={() => {
                            visitBrief();
                            // setModalState(modalChoicesEnum.PROGRESS_PHOTO);
                            // setShowRightPanel(true);
                          }}
                        >
                          <FiEdit className="w-4 h-4" />
                        </Button>
                        {/* {photoDescription.trim() === "Enter some text..." && setPhotoDescription("Add a description to your progress photo")} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const AdjustableImage = ({ img, position, handleSavePosition, className }) => {
  const [tempPositionX, setTempPositionX] = useState(
    position?.length > 0 ? position[0] : 50
  );
  const [tempPositionY, setTempPositionY] = useState(
    position?.length > 0 ? position[1] : 50
  );

  const parentDiv = useRef();
  const elmnt = useRef();
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;

    document.body.style.cursor = "move";
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:

    setTempPositionX((x) => {
      return Math.max(0, Math.min(100, x + pos1));
    });
    setTempPositionY((y) => {
      return Math.max(0, Math.min(100, y + pos2));
    });
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
    document.body.style.cursor = "default";
  }

  return (
    <div className={className} ref={parentDiv}>
      <img
        ref={elmnt}
        onMouseDown={(e) => dragMouseDown(e)}
        src={img}
        alt="img"
        className="absolute object-cover w-full h-full"
        style={{
          objectPosition: `${tempPositionX}% ${tempPositionY}%`,
        }}
      />
      <div
        className="absolute top-4 right-4 rounded-full cursor-pointer"
        onClick={() => {
          handleSavePosition(tempPositionX, tempPositionY);
        }}
      >
        <IoSaveSharp
          className="absolute top-2 right-2 text-hbBlue hover:cursor-pointer hover:text-white text-3xl"
          style={{
            transform: "translate(50%, -50%)",
          }}
        />
      </div>
    </div>
  );
};
