// If a new tile is supposed to be added to HBA, edit these const to add it to every HBA portal

// Add the name of the brief here if it's supposed to be initialized without a background
export const defaultBriefs = [
  "Variations",
  "Provisional Sum",
  "Budget",
  "Invoices",
  "Progress Photos",
  "Progress of work",
  "Project Location",
  "Site Diary",
  "Prime Cost",
  "Tenders",
  "Certificates",
  "Insurance",
  "Defects List",
  "Development Approvals",
  "Building Permits",
  "Warranties and Certifications",
  "Work Safety Compliance",
  "Contracts",
];

// Add the name of the brief here if it's supposed to be initialized with a background
export const defaultBriefsWithBackgrounds = [
  "Builder Details",
  "Contract Details",
  "Owner Details",
  "Contract Review and Advice",
  "Cost and Disclosure",
];

// Add the columns of the briefs here

// NOTE:
// If it's a default column that needs to be added to all briefs or be hidden on specific briefs
// indicate within the presetFilter which briefs they should not appear in

// If it's a column that needs to appear only on specific briefs, indicate within the presets
// attribute which briefs they should appear in

// Both the presetFilter and presets attribute should not exist within the same column
export const hbaColumnsConfig = {
  Date: {
    presetFilter: ["Contract Details", "Builder Details", "Owner Details"],
    type: "DATE",
  },
  Gmail: {
    presetFilter: ["Contract Details", "Builder Details", "Owner Details", "_"],
    type: "READONLY",
  },
  "Description of Background": {
    presetFilter: ["Contract Details", "Builder Details", "Owner Details"],
    type: "TEXTAREA",
  },
  Comments: {
    presets: ["Comments Presets"],
    type: "TEXTAREA",
  },
  // "Email Date": {
  //   presetFilter: ["Contract Details", "Builder Details", "_"],
  // },
  Document: {
    presetFilter: ["Contract Details", "_"],
    type: "READONLY",
  },
  Label: {
    presetFilter: [
      "Contract Details",
      "Builder Details",
      "Owner Details",
      "Variations",
      "Provisional Sum",
      "Budget",
      "Prime Cost",
      "_",
    ],
    type: "READONLY",
    optionsText: [],
  },
  "Percentage": {
    presets: ["Invoices"],
    type: "NUMBER",
    optionsText: [],
  },
  "Claim Amount ($)": {
    presets: ["Invoices"],
    type: "NUMBER",
    optionsText: [],
  },
  "Due Date for Claim": {
    presets: ["Invoices"],
    type: "DATE",
    optionsText: [],
  },
  "Claim Status": {
    presets: ["Invoices"],
    type: "DROPDOWN",
    optionsText: ["Claimed", "Unclaimed"],
  },
  "Payment Status": {
    presets: ["Invoices"],
    type: "DROPDOWN",
    optionsText: ["Paid", "Unpaid"],
  },
  "Variation Status": {
    presets: ["Variations"],
    type: "DROPDOWN",
    optionsText: ["Approved", "For Approval"],
  },
  "Variation Cost ($)": {
    presets: ["Variations"],
    type: "NUMBER",
    optionsText: [],
  },
  "Provisional Amount": {
    presets: ["Provisional Sum", "Budget", "Prime Cost"],
    type: "NUMBER",
    optionsText: [],
  },
  "Cost Amount": {
    presets: ["Provisional Sum", "Budget", "Prime Cost", "Breakdown Presets"],
    type: "NUMBER",
    optionsText: [],
  },
  "Description of Work": {
    presets: ["Provisional Sum", "Budget", "Prime Cost"],
    type: "TEXT",
    optionsText: [],
  },
  "Paid to Date Amount": {
    presets: ["Provisional Sum", "Variations", "Budget", "Prime Cost"],
    type: "NUMBER",
    optionsText: [],
  },
  Action: {
    presets: ["Provisional Sum", "Variations", "Budget", "Prime Cost"],
    type: "TEXT",
    optionsText: [],
  },
  "Builder Name": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Builder Contact Name": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  // "Builder Logo": {
  //   presets: ["Builder Details"],
  // },
  "ABN (Australian Business Number)": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Builder's License Number": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Builder Contact Email": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Builder Contact Number": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Builder Address": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Builder's Margin": {
    presets: ["Builder Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Start Date": {
    presets: ["Contract Details"],
    type: "DATE",
    optionsText: [],
  },
  "Contract Price": {
    presets: ["Contract Details"],
    type: "NUMBER",
    optionsText: [],
  },
  "Contract Value": {
    presets: ["Contract Details"],
    type: "NUMBER",
    optionsText: [],
  },
  "Full Name": {
    presets: ["Contract Review and Advice", "Cost and Disclosure", "Owner Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Email Address": {
    presets: ["Contract Review and Advice", "Cost and Disclosure", "Owner Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Address": {
    presets: ["Owner Details"],
    type: "TEXT",
    optionsText: [],
  },
  "Phone Number": {
    presets: ["Owner Details"],
    type: "NUMBER",
    optionsText: [],
  },
  "Defect Status": {
    presets: ["Defects List"],
    type: "DROPDOWN",
    optionsText: ["Reported", "Under Investigation", "In Progress", "Resolved", "Disputed"],
  },
  "Priority": {
    presets: ["Defects List"],
    type: "DROPDOWN",
    optionsText: ["1", "2", "3"],
  },
  "Resolution Date": {
    presets: ["Defects List"],
    type: "DATE",
    optionsText: [],
  },
  "Lot Number": {
    presets: ["Project Location"],
    type: "TEXT",
    optionsText: [],
  },
  "GST Amount": {
    presets: ["Contract Details"],
    type: "NUMBER",
    optionsText: [],
  },
  "Contract Type": {
    presets: ["Contract Details"],
    type: "TEXT",
    optionsText: [],
  },
};
